import React, { ChangeEventHandler, FunctionComponent } from 'react';
import styled from 'styled-components';
import { Field, useFormState } from 'react-final-form';
import {
  PartialForm,
  RadioContainer,
} from '../../shared/UnderwritingPartialForm';
import { RadioLabel } from '../../shared/UnderwritingPartialForm';
import { RadioButton } from '@fattmerchantorg/truffle-components';
import { Portfolio } from '../../../../../util/catan.util';

const SubPageHeader = styled.span`
  display: block;
  font-size: 14px;
  margin: 0 0 8px;
`;

interface PortfolioSelectionProps {
  onChangePortfolio: ChangeEventHandler<HTMLInputElement>;
  portfolios?: Portfolio[];
}

export const PortfolioSelection: FunctionComponent<
  PortfolioSelectionProps
> = props => {
  const { portfolios, onChangePortfolio } = props;
  const { values } = useFormState();

  return (
    <PartialForm data-testid="uw-status-submit-portfolio-selection">
      <SubPageHeader>Select a Portfolio</SubPageHeader>
      <>
        {portfolios?.map(({ payfac_id, payfac_name }, index) => {
          return (
            <RadioLabel
              key={`${payfac_name}=${index}`}
              data-selected={values.portfolioId === payfac_id}
              data-testid={`uw-status-submit-portfolio-label-${payfac_id}`}
            >
              <Field
                name="portfolioId"
                id={payfac_name}
                data-testid={payfac_name}
                type="radio"
                component="input"
                value={payfac_id}
              >
                {props => {
                  return (
                    <RadioContainer>
                      <RadioButton
                        {...props.input}
                        onChange={e => {
                          props.input.onChange(e);
                          onChangePortfolio(e);
                        }}
                        label={
                          payfac_name.toLowerCase() === 'stax payfac'
                            ? `${payfac_name} (Stax Engine)`
                            : payfac_name
                        }
                      />
                    </RadioContainer>
                  );
                }}
              </Field>
            </RadioLabel>
          );
        })}
      </>
    </PartialForm>
  );
};
