import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ContainerProps } from './Container.types';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const Container: FunctionComponent<ContainerProps> = props => {
  return <StyledContainer {...props} />;
};

Container.defaultProps = {
  responsive: true
};
