import React, { FunctionComponent } from 'react';
import { useTheme } from 'styled-components';
import { Copy } from './DevTools';

export const StaxTheme: FunctionComponent = () => {
  const { colors } = useTheme() as any;

  return (
    <>
      <h2>Stax Theme</h2>
      <table className="table table-sm table-dark">
        <thead className="thead-light">
          <tr>
            <th style={{ width: 100 }}>Code Snippet</th>
            <th style={{ width: 100 }}>Name</th>
            <th style={{ width: 100 }}>Shade</th>
            <th style={{ width: 100 }}>Value</th>
            <th>Color</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(colors.core).map((staxEntry, key) => {
            const colorName = staxEntry[0];
            const shades = colors.core[colorName];

            // Color has single value.
            if (typeof shades === 'string') {
              return (
                <ViewColor
                  colorName={colorName}
                  shade={null}
                  value={shades}
                  key={key}
                ></ViewColor>
              );
            } else {
              // Color has multiple shades.
              return Object.entries(shades).map((staxShade, key) => {
                const [shade, { hex: shadeValue }] = staxShade as any;
                return (
                  <ViewColor
                    colorName={colorName}
                    shade={shade}
                    value={shadeValue.toString()}
                    key={key}
                  ></ViewColor>
                );
              });
            }
          })}
        </tbody>
      </table>
    </>
  );
};

interface IViewColorProps {
  colorName: string;
  shade: string;
  value: string;
}
const ViewColor: FunctionComponent<IViewColorProps> = ({
  colorName,
  shade,
  value,
}) => {
  let codeShade = '';
  if (shade) {
    codeShade = `[${shade}]`;
  }

  let codeSnippet = `\${({ theme }) => theme.colors.core.${colorName}${codeShade}};`;

  return (
    <tr>
      <td>
        <Copy val={codeSnippet} label="Code Snippet" />
      </td>
      <td className="align-middle">{colorName}</td>
      <td className="align-middle">{shade}</td>
      <td className="align-middle">{value}</td>
      <td>
        <div style={{ background: value, height: 32 }}>&nbsp;</div>
      </td>
    </tr>
  );
};
