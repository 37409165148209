import { CollectionResponse } from '@fattmerchantorg/types-omni';
import { API, DB } from '@fattmerchantorg/types-engine';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Container, FilterButton, Row } from './datatable/table-styles';
import { useLocation } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { catanapi } from '../../api/catan';
import {
  useAsyncEffect,
  useAuthToken,
  usePermissions,
  useToaster,
  useRouteMatchMerchantId,
} from '../../hooks';
import { formatCurrency } from '../../util';
import {
  cleanCollectionQuery,
  CollectionQuery,
  ExportCollectionQuery,
} from '../shared';
import { history } from '../../history';
import { parse, stringify } from '../../util/api.util';
import { formatDate, toISOString, endOfDay } from '../../util/date.util';
import { startOfYesterday } from 'date-fns/esm';

import { SettlementApprovalWizardModal } from './SettlementApprovalWizardModal';
import {
  DropdownContent,
  SmallPrimaryButton,
  SmallSecondaryButton,
  StatusPill,
  Text,
  TextField,
  ToggleSelector,
  Tooltip,
  Dropdown,
} from '@fattmerchantorg/truffle-components';
import { SettlementsDataTable } from './datatable/SettlementsDataTable';
import { Icon } from '@fattmerchantorg/truffle-components';
import { generateLoadingRows } from '../../util/datatable.util';
import { ModalContext, SelectedMerchantStore, openModal } from '../../context';
import { SettlementsFilterModal } from './filtermodals/SettlementsFilterModal';
import { CSVDownload } from 'react-csv';
import { mapSettlementStateToStatus } from '../../util/settlement.util';
import { AdjustmentTransactionsModal } from '../transactions/AdjustmentTransactions/AdjustmentTransactionsModal';
import { getMerchantMids } from '../transactions/AdjustmentTransactions/AdjustmentTransactions.util';
import { AdjustmentTransactionType } from '../transactions/AdjustmentTransactions/AdjustmentTransactions.types';

interface GetSettlements {
  (query: CollectionQuery): Promise<CollectionResponse<API.Settlement>>;
}

interface GetExportSettlements {
  (query: ExportCollectionQuery): Promise<CollectionResponse<API.Settlement>>;
}
interface GetBrands {
  (): Promise<CollectionResponse<DB.Brand>>;
}

interface GetPayfacs {
  (): Promise<CollectionResponse<DB.PayFac>>;
}

const StyledTextField = styled(TextField)`
  + div {
    color: red;
  }

  min-width: 418px;

  + button {
    top: 42%;
  }
`;
const BoldText = styled(Text)`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.1667;
  color: #ffffff;
`;

const ApprovalWizardButtonBase = styled(SmallPrimaryButton)`
  background-color: ${({ theme }) => theme.primaryColor};
  margin-left: 1rem;
  padding: 10px 16px;
  color: ${({ theme }) => theme.black};
  &:hover {
    color: ${({ theme }) => theme.black};
  }
`;

const StyledExclamationIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.status.red[500].hex};
`;

const StyledToggleSelector = styled(ToggleSelector)`
  height: 33px;
  > div {
    border-color: #435e70;
  }
`;

const columns = [
  {
    Header: 'Merchant',
    accessor: 'company_name',
    style: { width: '200px' },
  },
  {
    Header: '',
    id: 'settlement_hold',
    accessor: row => {
      if (row.state === 'HOLD') {
        return row.state;
      }
      return row.risk_hold;
    },
    disableSortBy: true,
    style: { width: '34px', padding: 0, textAlign: 'center' },
    Cell: cell =>
      cell.value ? (
        <Tooltip
          content={cell.value === 'HOLD' ? 'Settlement Hold' : 'Risk Hold'}
        >
          <StyledExclamationIcon icon={['far', 'exclamation-triangle']} />
        </Tooltip>
      ) : null,
  },
  {
    Header: 'Status',
    accessor: 'state',
    disableSortBy: true,
    style: {
      textAlign: 'left',
      width: '100px',
      paddingLeft: 0,
      paddingRight: 0,
    },
    Cell: cell =>
      cell.value ? (
        <StatusPill {...mapSettlementStateToStatus(cell.value)} />
      ) : null,
  },
  {
    Header: 'Created',
    accessor: 'created_at',
    style: { width: '200px' },
    Cell: cell => <span>{formatDate(cell.value, 'MM/dd/yyyy h:mm b')}</span>,
  },
  {
    Header: 'Brand',
    accessor: 'brand_name',
    disableSortBy: true,
    style: { width: '130px' },
  },
  {
    Header: 'Count',
    accessor: 'transaction_count',
    disableSortBy: true,
    style: { textAlign: 'right', width: '80px' },
  },
  {
    Header: 'Gross Total',
    accessor: 'gross_total',
    style: { textAlign: 'right', width: '130px' },
    Cell: cell => <span>{formatCurrency(cell.value)}</span>,
  },
  {
    Header: 'Fees',
    accessor: 'fees',
    disableSortBy: true,
    style: { textAlign: 'right', width: '130px' },
    Cell: cell => <span>{formatCurrency(cell.value)}</span>,
  },
  {
    Header: 'Net Total',
    accessor: 'net_total',
    style: { textAlign: 'right', width: '130px' },
    Cell: cell => <span>{formatCurrency(cell.value)}</span>,
  },
];

const pageSizeOptions = [
  { value: 10, label: 'Show 10' },
  { value: 20, label: 'Show 20' },
  { value: 50, label: 'Show 50' },
  { value: 100, label: 'Show 100' },
];

interface SelectionOption {
  label: any;
  value: DB.SettlementState | 'ALL' | 'ERROR';
}

const settlementStateOptions: SelectionOption[] = [
  {
    label: 'All',
    value: 'ALL',
  },
  {
    label: 'Pending',
    value: 'PENDING',
  },
  {
    label: 'Error',
    value: 'ERROR',
  },
  {
    label: 'Approved',
    value: 'APPROVED',
  },
];

const LightTextLink: React.FunctionComponent<any> = withTheme(props => {
  const { theme, children, style, href, target, ...rest } = props;
  return (
    <a
      {...rest}
      href={href}
      target={target}
      style={{ color: '#fff', ...style }}
    >
      {children}
    </a>
  );
});

const ContextMenu = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li a,
  li button {
    display: block;
    background: none;
    border: 0;
    padding: 4px 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    text-align: left;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.core.white[0].hex};

    &:hover {
      background: ${({ theme }) => theme.component.table.borderColor};
    }
  }
`;

const IconButton = styled.button`
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  width: 30px;
  height: 30px;
  line-height: 24px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.core.white[0].hex};
`;

export const Settlements: FunctionComponent = () => {
  const [loading, setLoading] = useState(false);
  const [catanQueryData, setCatanQueryData] = useState<
    CollectionResponse<API.Settlement> | undefined
  >();
  const [catanQueryExportData, setCatanQueryExportData] = useState<
    CollectionResponse<API.Settlement> | undefined
  >();
  const [catanBrandData, setCatanBrandData] = useState<
    CollectionResponse<DB.Brand> | undefined
  >();
  const [catanPayfacData, setCatanPayfacData] = useState<
    CollectionResponse<DB.PayFac> | undefined
  >();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeFiltersCount, setActiveFiltersCount] = useState(0);

  const { modalDispatch } = useContext(ModalContext);
  const { toaster, toast } = useToaster();
  const { search, pathname } = useLocation();
  const authToken = useAuthToken();
  const { permit } = usePermissions();
  let keyword = useRef('').current;
  const merchantId = useRouteMatchMerchantId();

  const allowApprovalWizard = permit('godview', 'approvalWizard', 'write');
  const canManageAdjustmentTransactions = permit(
    'godview',
    'engineAdjustmentTransactions',
    'write'
  );
  const { state: selectedMerchantState } = useContext(SelectedMerchantStore);

  const query = useMemo((): CollectionQuery => {
    return {
      ...parse(search),
    };
  }, [search]);
  const handleQueryChange = useCallback(
    (newQuery: Partial<CollectionQuery> = {}) => {
      const newSearch = stringify(
        cleanCollectionQuery({ ...query, ...newQuery })
      );
      history.push(`${pathname}?${newSearch}`);
    },
    [query, pathname]
  );

  // update the query to include the new merchant id with default values
  useAsyncEffect(() => {
    handleQueryChange({
      keyword: merchantId,
      page: query.page ?? 1,
      approvalState: query.approvalState,
      startDate:
        query.startDate !== formatDate(startOfYesterday(), 'yyyy-MM-dd')
          ? query.startDate
          : formatDate(startOfYesterday(), 'yyyy-MM-dd'),
      endDate:
        query.endDate !== formatDate(endOfDay(new Date()), 'yyyy-MM-dd')
          ? query.endDate
          : formatDate(endOfDay(new Date()), 'yyyy-MM-dd'),
    });
  }, [merchantId]);

  const getData: GetSettlements = useCallback(
    collectionQuery =>
      catanapi.get(authToken, '/settlements', {
        ...collectionQuery,
        startDate: toISOString(collectionQuery.startDate),
        endDate: toISOString(collectionQuery.endDate),
        // timezone,
      }),
    [authToken]
  );

  const getExportData: GetExportSettlements = useCallback(
    exportCollectionQuery =>
      catanapi.get(authToken, '/settlements-export', {
        ...exportCollectionQuery,
        startDate: toISOString(exportCollectionQuery.startDate),
        endDate: toISOString(exportCollectionQuery.endDate),
        // timezone,
      }),
    [authToken]
  );

  const getSettlementsData = async () => {
    setLoading(true);
    try {
      const exportData = await getExportData(query);
      setCatanQueryExportData(exportData);
      setLoading(false);
    } catch (e) {
      setCatanQueryExportData(undefined);
      setLoading(false);
    } finally {
      setCatanQueryExportData(undefined);
    }
  };

  const getBrandData: GetBrands = useCallback(
    () => catanapi.get(authToken, '/brands'),
    [authToken]
  );

  const getPayfacData: GetPayfacs = useCallback(
    () => catanapi.get(authToken, '/payfacs'),
    [authToken]
  );

  useEffect(() => {
    setActiveFiltersCount(Object.keys(query).length);
  }, [query]);

  useAsyncEffect(async () => {
    // do not load initial data if merchantId is null and search is empty
    if (merchantId && Object.keys(search).length === 0) {
      return null;
    }
    setLoading(true);
    try {
      const data = await getData(query);
      setCatanQueryData(data);
      const brandList = await getBrandData();
      setCatanBrandData(brandList);
      const payfacList = await getPayfacData();
      setCatanPayfacData(payfacList);
      setLoading(false);
    } catch (e) {
      setCatanQueryData(undefined);
      setCatanBrandData(undefined);
      setCatanPayfacData(undefined);
      setLoading(false);
      toaster(
        toast.error(
          'Could not get the settlements. Please retry later!',
          'OOps, Something went wrong'
        )
      );
    }
  }, [authToken, search, pathname]);

  return (
    <>
      {/* This En Quad (space) character is required to keep the nav bar
       margin align with the container in the `merchant/:merchantId/settlement` route */}
      <div>&#8192;</div>
      <Container>
        <Row>
          <BoldText as={'h4'}>Settlements</BoldText>
          <div>
            <SmallSecondaryButton
              icon={['fa', 'download']}
              onClick={getSettlementsData}
            >
              <span style={{ padding: '2px' }}>Export</span>
            </SmallSecondaryButton>
            {catanQueryExportData?.data != null ? (
              <CSVDownload
                style={{ color: 'white', textDecoration: 'none' }}
                data={catanQueryExportData?.data ?? []}
                filename="settlements-exports.csv"
                target="_blank"
              />
            ) : null}
            {allowApprovalWizard && (
              <ApprovalWizardButtonBase
                icon={['fas', 'stars']}
                onClick={() =>
                  history.push(`/settlements/approve${history.location.search}`)
                }
              >
                <span>Approval Wizard</span>
              </ApprovalWizardButtonBase>
            )}
            {canManageAdjustmentTransactions && merchantId ? (
              <Dropdown
                trigger={(setRef, isOpen, setIsOpen) => (
                  <IconButton
                    ref={setRef}
                    style={{ marginLeft: 'auto' }}
                    onClick={e => {
                      e.stopPropagation();
                      setIsOpen(!isOpen);
                    }}
                  >
                    <Icon
                      style={{ color: '#FFFFFF' }}
                      icon={['fas', 'ellipsis-v']}
                    ></Icon>
                  </IconButton>
                )}
              >
                <DropdownContent style={{ minWidth: '190px', width: 'auto' }}>
                  <ContextMenu>
                    <ul>
                      <li onClick={() => openAdjustmentsModal('CREDIT')}>
                        <LightTextLink>Credit Adjustment</LightTextLink>
                      </li>
                      <li onClick={() => openAdjustmentsModal('DEBIT')}>
                        <LightTextLink>Debit Adjustment</LightTextLink>
                      </li>
                    </ul>
                  </ContextMenu>
                </DropdownContent>
              </Dropdown>
            ) : (
              ''
            )}
          </div>
        </Row>
        <Row>
          <StyledToggleSelector
            options={settlementStateOptions}
            defaultSelected={query.approvalState?.[0]}
            onChange={(e, value) => {
              handleQueryChange({ approvalState: [value.value], page: 1 });
            }}
          />
          <div style={{ display: 'flex' }}>
            <StyledTextField
              placeholder={'Search by merchant name or MID'}
              type="search"
              onChange={(e, value) => {
                keyword = e?.target?.value;
                if (e?.target?.value === '') {
                  handleQueryChange({ keyword: '', page: 1 });
                }
              }}
              onKeyPress={event =>
                event.key === 'Enter' &&
                handleQueryChange({ keyword: keyword, page: 1 })
              }
              onSearch={() => handleQueryChange({ keyword: keyword, page: 1 })}
            />
            {/* Filter Button */}
            <FilterButton
              onClick={() =>
                modalDispatch(
                  openModal(SettlementsFilterModal, {
                    isOpen: true,
                    query,
                    handleQueryChange,
                    getData,
                    authToken,
                    catanQueryData,
                    brandList: catanBrandData,
                    payfacList: catanPayfacData,
                  })
                )
              }
            >
              <Icon icon={['fas', 'filter']} />
              <span>Filter ({activeFiltersCount})</span>
            </FilterButton>
          </div>
        </Row>

        {allowApprovalWizard && (
          <SettlementApprovalWizardModal
            isOpen={pathname.includes('/approve')}
          />
        )}
        <SettlementsDataTable
          data={{
            columns,
            rows: loading ? generateLoadingRows() : catanQueryData?.data ?? [],
          }}
          count={catanQueryData?.last_page ?? 0}
          total={catanQueryData?.total ?? 0}
          currentPage={catanQueryData?.current_page ?? 0}
          defaultPageSize={catanQueryData?.per_page ?? 20}
          lastPage={catanQueryData?.last_page ?? 0}
          loading={loading}
          pageSizeOptions={pageSizeOptions}
          handleQueryChange={handleQueryChange}
          onRowsChanged={onRowsChanged}
        />
      </Container>
    </>
  );

  function onRowsChanged(rows) {
    const tmp = { ...catanQueryData };
    tmp.data = rows;
    setCatanQueryData(tmp);
  }

  function openAdjustmentsModal(type: AdjustmentTransactionType) {
    modalDispatch(
      openModal(AdjustmentTransactionsModal, {
        source: 'global',
        action: 'CREATE',
        type,
        authToken,
        merchant: {
          id: selectedMerchantState?.merchant?.id,
          name: selectedMerchantState?.merchant?.company_name,
          companyId: selectedMerchantState?.registration?.external_company_id,
          mids: getMerchantMids(selectedMerchantState.gateways.data),
        },
        onDone: async () => {
          setLoading(true);
          try {
            const data = await getData(query);
            setCatanQueryData(data);
          } catch (e) {}
          setLoading(false);
        },
      })
    );
  }
};
