import React, { FC } from 'react';
import { Row, Col } from 'reactstrap';
import { AsyncDataStatus } from '../../@types';
import { LoadingSpan } from '../shared';
import { GrossSalesData } from './useTransactionChartHook/useTransactionChartHook';
import { StatisticsRouteResponse } from '../../api';
import { StatisticsPill } from './components/TransactionsWidget/StatisticsPill';
import { BoldText } from './components/shared/BoldText';
import { TransactionStatisticsTitle } from './components/shared/TruffleText';
import styled, { withTheme } from 'styled-components';
import { formatWithComma } from '../../util/format.util';

const DataText: FC<{
  data: GrossSalesData;
  summaryKey: keyof StatisticsRouteResponse['summary'];
  dollarSign?: boolean;
}> = props => {
  switch (props.data.status) {
    case AsyncDataStatus.INITIAL:
    case AsyncDataStatus.ERROR:
      return <BoldText fontSize={24}>----</BoldText>;
    case AsyncDataStatus.LOADING:
      return (
        <BoldText fontSize={24}>
          <LoadingSpan as="span" style={{ display: 'block' }} />
        </BoldText>
      );
    case AsyncDataStatus.IDLE:
      // Display "$0.00" or "0" when there's no data.
      const summaryKeyValue: string | undefined = props.data.data
        .selectedTimeRange.summary[props.summaryKey]
        ? props.data.data.selectedTimeRange.summary[props.summaryKey].toString()
        : undefined;

      // Zero scenario.
      if (!summaryKeyValue) {
        return (
          <BoldText fontSize={24}>{props.dollarSign ? '$0.00' : '0'}</BoldText>
        );
      }

      // Integer scenario (add commas).
      if (props.summaryKey === 'count') {
        return (
          <BoldText fontSize={24}>
            {`${formatWithComma(parseInt(summaryKeyValue))}`}
          </BoldText>
        );
      }

      // Default/Currency scenario.
      return (
        <BoldText fontSize={24}>
          {`${props.dollarSign ? '$' : ''}${summaryKeyValue}`}
        </BoldText>
      );
  }
};

const DividingLine = withTheme(
  styled.hr`
    border-top: 1px solid ${({ theme }) => theme.white};
  `
);

export const VolumeCountAverageColumn: FC<{
  data: GrossSalesData;
  selectedTimespan: string;
}> = ({ data, selectedTimespan }) => {
  return (
    <Row>
      <Col className="pt-4 pr-0">
        <StatisticsPill
          data={data}
          summaryKey="sum_formatted"
          selectedTimespan={selectedTimespan}
          data-testid="transactions-volume-stat-pill"
        />
        {TransactionStatisticsTitle('Volume')}
        <DataText data={data} summaryKey="sum_formatted" dollarSign />
        <DividingLine />
        <StatisticsPill
          data={data}
          summaryKey="count"
          selectedTimespan={selectedTimespan}
          data-testid="transactions-count-stat-pill"
        />
        {TransactionStatisticsTitle('Count')}
        <DataText data={data} summaryKey="count" />
        <DividingLine />
        <StatisticsPill
          data={data}
          summaryKey="average_formatted"
          selectedTimespan={selectedTimespan}
          data-testid="transactions-average-stat-pill"
        />
        {TransactionStatisticsTitle('Average')}
        <DataText data={data} summaryKey="average_formatted" dollarSign />
      </Col>
    </Row>
  );
};
