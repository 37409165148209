import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { SelectedMerchantStore } from '../../../../context';
import { UnderwritingSection } from '../shared/UnderwritingSection';
import { Icon, TextLink } from '@fattmerchantorg/truffle-components';
import { formatDate } from '../../../../util/date.util';
import { usePermissions } from '../../../../hooks';
import { Registration } from '@fattmerchantorg/types-omni';
import { faFileSignature } from '@fortawesome/pro-solid-svg-icons';
import { CountersignModal } from './CountersignModal';
import { MerchantSignatureHistory } from './MerchantSignatureHistory';

const CountersignContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: -12px 0;
`;

const CountersignRow = styled.div`
  display: flex;
  padding: 4px 0;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  scroll-snap-align: start;

  span {
    font-size: 14px;
    line-height: 20px;
  }

  a {
    font-size: 14px;
  }
  :last-child {
    padding-bottom: 0px;
  }
`;
const Unfinished = styled.span`
  color: #ff4646;
`;
const Completed = styled.span`
  color: #28cb35;
`;

const CountersignApplicationLink = styled(TextLink)`
  font-size: 14px;
`;

const renderSignatureStatus = (signatureStatus: string | null) => {
  if (signatureStatus === 'SIGNED') {
    return 'Ready to Counter-Sign';
  }
  return signatureStatus || 'Not Viewed';
};

export const Countersign = () => {
  const {
    state: { registration },
  } = useContext(SelectedMerchantStore);
  const { permit } = usePermissions();
  const isPermittedToRunVerification = permit('godview', 'runVerification');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const renderCountersignDate = (
    isPermittedToRunVerification: boolean,
    registration: Registration
  ) => {
    if (registration?.countersigned_at) {
      return formatDate(registration?.countersigned_at);
    }
    if (
      isPermittedToRunVerification &&
      registration?.signature_status === 'SIGNED'
    ) {
      return (
        <>
          <CountersignModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
          <CountersignApplicationLink
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            <Icon icon={faFileSignature} />
            Countersign Application
          </CountersignApplicationLink>
        </>
      );
    }
    return '-';
  };

  return (
    <UnderwritingSection title="Countersign" id="underwriting-countersing">
      {({ setToolTipContent }) => {
        setToolTipContent('Countersign Status.');

        return (
          <CountersignContainer>
            <CountersignRow>
              <span>Signature Status</span>
              <span>
                {renderSignatureStatus(registration?.signature_status)}
              </span>
            </CountersignRow>
            <CountersignRow>
              <span>Merchant Signature Date</span>
              <span>
                <MerchantSignatureHistory />
              </span>
            </CountersignRow>
            <CountersignRow>
              <span>Countersign Date</span>
              <span>
                {renderCountersignDate(
                  isPermittedToRunVerification,
                  registration
                )}
              </span>
            </CountersignRow>
            <CountersignRow>
              <span>Payfac Agreement</span>
              <span>
                {registration?.application_agreed_at ? (
                  <Completed>
                    <Icon icon={['fas', 'check']} fixedWidth /> Completed
                  </Completed>
                ) : (
                  <Unfinished>
                    <Icon icon={['fas', 'times']} fixedWidth /> Unfinished
                  </Unfinished>
                )}
              </span>
            </CountersignRow>
            <CountersignRow>
              <span>Experian Agreement</span>
              <span>
                {registration?.credit_inquiry_electronic_signature
                  ?.timestamp ? (
                  <Completed>
                    <Icon icon={['fas', 'check']} fixedWidth /> Completed
                  </Completed>
                ) : (
                  <Unfinished>
                    <Icon icon={['fas', 'times']} fixedWidth /> Unfinished
                  </Unfinished>
                )}
              </span>
            </CountersignRow>
          </CountersignContainer>
        );
      }}
    </UnderwritingSection>
  );
};
