import React, { FC } from 'react';
import styled from 'styled-components';
import { PaymentMethod, Transaction } from '@fattmerchantorg/types-engine/DB';
import {
  Drawer,
  Text,
  StatusPill,
  Icon,
  TableHead,
  TableBody,
} from '@fattmerchantorg/truffle-components';
import { faShieldCheck } from '@fortawesome/pro-regular-svg-icons';
import { DrawerProps } from '@fattmerchantorg/truffle-components/dist/Drawer/Drawer';
import { CustomerInfoWidget } from './CustomerInfoWidget';
import { CopyButton, LoadingSpan, Separator as USeparator } from '../../shared';
import { formatCurrency, formatTruncatedId } from '../../../util/format.util';

import { FunctionComponent, useState } from 'react';
import { generateCardCell, generateSubTypeCell } from './SettlementDetails';
import { Identity } from '@fattmerchantorg/types-engine/API';
import { Customer } from '@fattmerchantorg/types-omni';
import { formatDate } from '../../../util/date.util';
import { catanapi } from '../../../api/catan';
import { useAsyncEffect, useAuthToken } from '../../../hooks';
import {
  mapTransactionType,
  mapAdjustmentTransactionSubType,
  isAdjustmentTransaction as isAdjustmentTransactionUtil,
} from '../../../util/transaction.util';

const Section = styled.div`
  padding: 16px 16px 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:first-child) {
    margin-top: 16px;
  }

  small {
    font-size: 12px;
  }

  > *:not(:first-child) {
    margin-left: 8px;
  }
`;
const TransactionHistoryRowSelect = styled.tr`
  background-color: #22504c;
  animation: pulsate 1s infinite alternate;
  --webkit-animation: pulsate 1s infinite alternate;

  @keyframes pulsate {
    from {
      border: 1px solid rgba(117, 214, 151, 0);
    }
    to {
      box-shadow: 0px 0px 6px 0px #75d69700;
      border: 1px solid rgba(117, 214, 151, 1);
    }
  }
`;

const TransactionHistoryTable = styled.table`
  width: 100%;
  margin-top: 3px;
  margin-bottom: 66px;

  > tr {
    > td {
      padding-top: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.core.gray[600].hex};
    }

    > td:last-child {
      text-align: right;

      > div {
        margin-left: auto;
      }
    }

    > td:nth-child(2) {
      text-align: center;
    }
  }
  tbody > tr {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  tbody > tr > td {
    padding-bottom: 5px;
    padding-top: 5px;
  }
`;

const LineItemRow = styled(Row)`
  > *:last-child {
    margin-left: auto;
  }
`;
const PaddingText = styled(Text)`
  padding-left: 1em;
`;

const WidthText = styled(Text)`
  width: 120px;
`;

const DateRowContainer = styled(LineItemRow)`
  align-items: baseline;
  flex-direction: column;

  > small {
    margin-left: 2px !important;
  }
`;
const NoMarginLoadingSpan = styled(LoadingSpan)`
  margin-left: -17px !important;
`;

const NoMarginSpanDateContainer = styled.div`
  display: flex;
`;

const DateTextPadding = styled.span`
  padding-right: 1em;
`;

const NoMarginSpan = styled.span`
  margin-left: -17px !important;
  flex-direction: row;
`;

const TransactionRedirectIcon = styled(Icon)<{ disabled: boolean }>`
  position: relative;
  float: left;
  cursor: ${({ disabled }) => (disabled ? '' : 'pointer')};
  color: ${({ disabled }) =>
    disabled ? 'gray' : ({ theme }) => theme.colors.status.blue[500].hex};
`;

export const replaceAndCapitilizeStrings = (
  str: string,
  replaceStr: string
) => {
  if (str.length > 2) {
    let replaceReg = new RegExp(replaceStr, 'g');
    str = str.toLowerCase().replace(replaceReg, ' ');
    const arr = str.split(' ');
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(' ');
  } else {
    return str;
  }
};

const DateRow: FunctionComponent<{
  transaction?: Transaction;
  loading?: boolean;
  isAdjustment?: boolean;
}> = props =>
  props.loading ? (
    <Row>
      <NoMarginLoadingSpan height="24px" width="160px" />
    </Row>
  ) : props.isAdjustment ? (
    <DateRowContainer>
      <NoMarginSpan>
        <NoMarginSpanDateContainer>
          <DateTextPadding>
            <Text as="h6">Adjustment Date:</Text>
          </DateTextPadding>
          <DateTextPadding>
            <Text as="p">
              {props?.transaction?.created_at
                ? formatDate(
                    props?.transaction?.created_at,
                    'MM/dd/yyyy | h:mm b'
                  )
                : '--'}
            </Text>
          </DateTextPadding>
        </NoMarginSpanDateContainer>
      </NoMarginSpan>
      {props.children}
    </DateRowContainer>
  ) : (
    <DateRowContainer>
      <NoMarginSpan>
        <NoMarginSpanDateContainer>
          <DateTextPadding>
            <Text as="h6">Batch Date:</Text>
          </DateTextPadding>
          <DateTextPadding>
            <Text as="p">
              {props?.transaction?.batched_at
                ? formatDate(props?.transaction?.batched_at, 'MM/dd/yyyy')
                : '--'}
            </Text>
          </DateTextPadding>
        </NoMarginSpanDateContainer>
      </NoMarginSpan>
      <NoMarginSpan>
        <NoMarginSpanDateContainer>
          <DateTextPadding>
            <Text as="h6">Transacted Date:</Text>
          </DateTextPadding>
          <DateTextPadding>
            <Text as="p">
              {props?.transaction?.transacted_at
                ? formatDate(
                    props?.transaction?.transacted_at,
                    'MM/dd/yyyy | h:mm b'
                  )
                : '--'}
            </Text>
          </DateTextPadding>
        </NoMarginSpanDateContainer>
      </NoMarginSpan>
      {props.children}
    </DateRowContainer>
  );

const TableDateRow: FunctionComponent<{
  transaction?: Transaction;
  loading?: boolean;
}> = props =>
  props.loading ? (
    <Row>
      <LoadingSpan height="24px" width="160px" />
    </Row>
  ) : (
    <DateRowContainer>
      <NoMarginSpan>
        {formatDate(props?.transaction?.created_at, 'MM/dd/yyyy | h:mm b')}
      </NoMarginSpan>
      {props.children}
    </DateRowContainer>
  );

const CopyAuthCodeButton = styled(CopyButton)`
  color: ${({ theme }) => theme.colors.core.gray[200].hex};
  font-size: 16px;

  > span:last-child {
    margin-left: 4px;
  }
`;

const AuthCode: FunctionComponent<{
  transaction: Transaction;
}> = props => {
  const authCode = props.transaction?.authorization_code;
  return authCode ? (
    <CopyAuthCodeButton
      variant="plain"
      content={authCode}
      tooltip="Copy Transaction Auth Code to clipboard"
      icon={<Icon icon={faShieldCheck} />}
    >
      <span style={{ marginRight: '-20px' }}>
        {formatTruncatedId(authCode, 6)}
      </span>
    </CopyAuthCodeButton>
  ) : null;
};

const Separator = styled(USeparator)`
  padding-top: 16px;
`;

const DrawerBgColour = styled(Drawer)`
  background-color: #213745;
  border-radius: 0;
`;

/** Props accepted by the DetailsModal component */
type TransactionDrawerProps = Partial<DrawerProps> &
  Pick<DrawerProps, 'open'> & {
    getData:
      | (Transaction &
          PaymentMethod &
          Identity &
          Customer & {
            fee_description: string;
          })
      | null;
  };

export const TransactionDrawer: FC<TransactionDrawerProps> = props => {
  const authToken = useAuthToken();
  const { getData, ...drawerProps } = props;
  const [childTransactions, setChildTransactions] = useState(null);
  const [loadAssTrans, setLoadAssTrans] = useState(true);
  const [loadTrans, setLoadTrans] = useState(true);
  const [transaction, setTransaction] = useState(null);
  const [currentSelectedFee, setCurrentSettledFee] = useState(null);
  const [assTransId, setAssTransId] = useState(null);
  const [isAdjustmentTransaction, setIsAdjustmentTransaction] = useState(true);
  const FeeDetailShowTypes = ['RECURRING', 'FEE'];
  const AssociatedTransactionTypes = [
    'CHARGE',
    'DISPUTE',
    'FEE',
    'ACHRETURN',
    'CREDIT',
    'PAYOUT',
  ];
  const RefundDetailShowTypes = ['REFUND'];

  useAsyncEffect(async () => {
    // set transaction to null if getdata result is null
    if (getData == null) {
      setTransaction(null);
      setChildTransactions(null);
      setAssTransId(null);
      setLoadTrans(true);
      setLoadAssTrans(true);
      setIsAdjustmentTransaction(false);
    }
    // Get each Transaction information from API
    // If transaction type is fee we will need to get the parent transaction id
    if (getData != null) {
      let id = null;
      if (getData?.type === 'FEE' && getData?.parent_transaction_id) {
        setCurrentSettledFee(getData?.transaction_id);
        id = getData?.parent_transaction_id;
      } else {
        id = getData?.transaction_id;
      }
      const settlementFeeChargeTransaction = await catanapi.get(
        authToken,
        `/settlement-transaction/${id}`
      );
      setTransaction(settlementFeeChargeTransaction);
      setLoadTrans(false);
    }
  }, [authToken, loadTrans, getData]);
  useAsyncEffect(async () => {
    // Get Associated Transaction List from API
    let id = null;
    if (getData != null && transaction != null) {
      id = transaction?.parent_transaction_id || transaction?.transaction_id;
      const settlementParentTransaction = await catanapi.get(
        authToken,
        `/settlements/associated-transactions/${id}`
      );
      setChildTransactions(settlementParentTransaction?.data);
      if (settlementParentTransaction?.data?.length === 0) {
        setAssTransId(0);
      }
      setLoadAssTrans(false);
      if (isAdjustmentTransactionUtil(transaction)) {
        setIsAdjustmentTransaction(true);
      }
    }
  }, [authToken, transaction, loadAssTrans, getData]);

  return (
    <DrawerBgColour
      {...drawerProps}
      anchor="right"
      title="Transaction Details"
      data-testid="settlements-transaction-details-modal"
      customStyles={{
        borderRadius: 0,
        padding: 0,
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <>
        <Section>
          <>
            {loadTrans ? (
              <Row>
                <LoadingSpan height="42px" width="130px" />
                <LoadingSpan height="24px" width="68px" />
              </Row>
            ) : (
              <LineItemRow>
                <Text as="h2">{formatCurrency(transaction?.amount)}</Text>
                <StatusPill {...mapTransactionType(transaction?.type)} />
                {isAdjustmentTransaction ? <Text as="h5">Adjustment</Text> : ''}
                <CopyButton
                  content={transaction?.transaction_id}
                  tooltip={
                    <span>
                      Copy <strong>Transaction ID</strong> to Clipboard
                      <br />
                      {`${transaction?.transaction_id}`}
                    </span>
                  }
                >
                  <Icon icon={['fas', 'copy']} size="1x" />
                </CopyButton>
              </LineItemRow>
            )}
            {!FeeDetailShowTypes.includes(transaction?.type) ? (
              <Section>
                <DateRow
                  loading={loadTrans}
                  transaction={transaction}
                  isAdjustment={isAdjustmentTransaction}
                >
                  <AuthCode transaction={transaction} />
                </DateRow>
              </Section>
            ) : (
              ''
            )}
          </>
        </Section>
        <Separator />
        {(AssociatedTransactionTypes.includes(transaction?.type) ||
          RefundDetailShowTypes.includes(transaction?.type)) &&
        !FeeDetailShowTypes.includes(transaction?.type) &&
        !isAdjustmentTransaction ? (
          <>
            <Section>
              <Text as="h5">Customer Details</Text>
              <CustomerInfoWidget loading={loadTrans} customer={transaction} />
            </Section>
            <Separator />
          </>
        ) : (
          ''
        )}

        {FeeDetailShowTypes.includes(transaction?.type) ? (
          <>
            <Section>
              <Text as="h5">Fee Details</Text>
              <Row>
                <WidthText as="span">Fee Description</WidthText>
                {loadTrans ? (
                  <Row>
                    <LoadingSpan height="42px" width="130px" />
                  </Row>
                ) : (
                  <PaddingText as="span">
                    {transaction?.fee_description ?? '--'}
                  </PaddingText>
                )}
              </Row>
              <Row>
                <WidthText as="span">Fee Descriptor</WidthText>
                {loadTrans ? (
                  <Row>
                    <LoadingSpan height="42px" width="130px" />
                  </Row>
                ) : (
                  <PaddingText as="span">
                    {transaction?.subtype ?? '--'}
                  </PaddingText>
                )}
              </Row>
            </Section>
          </>
        ) : (
          ''
        )}

        {(AssociatedTransactionTypes.includes(transaction?.type) ||
          RefundDetailShowTypes.includes(transaction?.type)) &&
        !FeeDetailShowTypes.includes(transaction?.type) &&
        !isAdjustmentTransaction ? (
          <>
            <Section>
              <Text as="h5">Payment Method</Text>
              {loadTrans ? (
                <Row>
                  <LoadingSpan height="24px" width="100%" />
                </Row>
              ) : (
                <LineItemRow>
                  <span style={{ marginLeft: '0px' }}>
                    {generateCardCell(
                      transaction?.card_brand,
                      transaction?.last_four,
                      transaction?.method_type,
                      false,
                      true
                    )}
                  </span>

                  {transaction?.expiration ? (
                    <span>
                      Expires {transaction.expiration.slice(0, 2)}/
                      {transaction.expiration.slice(8, 10)}
                    </span>
                  ) : null}
                </LineItemRow>
              )}
            </Section>
            <Separator />
          </>
        ) : (
          ''
        )}

        {isAdjustmentTransaction ? (
          <>
            <Section>
              <Text as="h5">Adjustment</Text>
              <Row>
                <WidthText as="span">Category</WidthText>
                {loadTrans ? (
                  <Row>
                    <LoadingSpan height="42px" width="130px" />
                  </Row>
                ) : (
                  <PaddingText as="span">
                    {mapAdjustmentTransactionSubType(
                      transaction?.subtype
                    ).replace(' Adjustment', '') ?? '--'}
                  </PaddingText>
                )}
              </Row>
              <Row>
                <WidthText as="span">Description</WidthText>
                {loadTrans ? (
                  <Row>
                    <LoadingSpan height="42px" width="130px" />
                  </Row>
                ) : (
                  <PaddingText as="span">
                    {transaction?.meta?.description ?? '--'}
                  </PaddingText>
                )}
              </Row>
              {transaction?.parent_transaction_id ? (
                <Row>
                  <WidthText as="span">Associated Transaction</WidthText>
                  {loadTrans ? (
                    <Row>
                      <LoadingSpan height="42px" width="130px" />
                    </Row>
                  ) : (
                    <Row>
                      <PaddingText as="span">
                        {transaction?.parent_transaction_id ?? '--'}
                      </PaddingText>
                      <TransactionRedirectIcon
                        icon={['fas', 'external-link']}
                        onClick={() => {
                          transaction?.settlement_id &&
                            window.open(
                              `#/settlements/${transaction?.settlement_id}/details?detailId=${transaction?.parent_transaction_id}`,
                              '_blank'
                            );
                        }}
                        disabled={!transaction?.settlement_id}
                      />
                    </Row>
                  )}
                </Row>
              ) : (
                ''
              )}
              <Row>
                <WidthText as="span">Created By</WidthText>
                {loadTrans ? (
                  <Row>
                    <LoadingSpan height="42px" width="130px" />
                  </Row>
                ) : (
                  <PaddingText as="span">
                    {transaction?.meta?.userId ?? '--'}
                  </PaddingText>
                )}
              </Row>
            </Section>
          </>
        ) : (
          ''
        )}

        {AssociatedTransactionTypes.includes(transaction?.type) &&
        assTransId == null &&
        !isAdjustmentTransaction ? (
          <Section>
            <Text as="h5">Associated Transactions </Text>
            <TransactionHistoryTable>
              <TableHead>
                <tr style={{ fontSize: '12px' }}>
                  <th
                    style={{
                      paddingTop: '1em',
                      paddingBottom: '1em',
                      paddingLeft: '4px',
                    }}
                  >
                    {' '}
                    Batch Date{' '}
                  </th>
                  <th>Type</th>
                  <th>SubType</th>
                  <th
                    style={{
                      float: 'right',
                      marginLeft: '1rem',
                      lineHeight: '4em',
                    }}
                  >
                    Amount
                  </th>
                </tr>
              </TableHead>
              <TableBody>
                {loadAssTrans ? (
                  <tr>
                    <td>
                      <TableDateRow loading={loadAssTrans} />
                    </td>
                    <td>
                      <LoadingSpan height="24px" width="68px" />
                    </td>
                    <td>
                      <LoadingSpan height="24px" width="68px" />
                    </td>
                    <td>
                      <LoadingSpan height="24px" width="140px" />
                    </td>
                  </tr>
                ) : (
                  childTransactions.map((t, i) =>
                    currentSelectedFee !== null &&
                    t.transaction_id === currentSelectedFee ? (
                      <TransactionHistoryRowSelect key={i}>
                        <td style={{ paddingLeft: '22px' }}>
                          <DateRow transaction={t} />
                        </td>
                        <td>
                          <StatusPill {...mapTransactionType(t?.type)} />
                        </td>
                        <td>
                          <span>
                            {t.fee_description ??
                              replaceAndCapitilizeStrings(
                                generateSubTypeCell(t.subtype),
                                '_'
                              )}
                          </span>
                        </td>
                        <td style={{ float: 'right', marginRight: '5px' }}>
                          {`- ${formatCurrency(t.amount)}`}
                        </td>
                      </TransactionHistoryRowSelect>
                    ) : (
                      <tr key={i}>
                        <td style={{ paddingLeft: '22px' }}>
                          <DateRow transaction={t} />
                        </td>
                        <td>
                          <StatusPill {...mapTransactionType(t?.type)} />
                        </td>
                        <td>
                          <span>
                            {replaceAndCapitilizeStrings(
                              generateSubTypeCell(t.subtype),
                              '_'
                            )}
                          </span>
                        </td>
                        <td style={{ float: 'right', marginRight: '5px' }}>
                          {`- ${formatCurrency(t.amount)}`}
                        </td>
                      </tr>
                    )
                  )
                )}
              </TableBody>
            </TransactionHistoryTable>
          </Section>
        ) : (
          ''
        )}
      </>
    </DrawerBgColour>
  );
};
