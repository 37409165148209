import { DB } from '@fattmerchantorg/types-engine';
import { formatCapitalCase, ordinal } from '../../../util';
import { catanapi } from '../../../api';
import { ToastContext } from '../../../context';
import {
  Billing,
  FeeDescription,
  PaymentMethod,
} from '@fattmerchantorg/types-engine/DB';
import {
  billingProfileChannelName,
  billingProfileSubChannelName,
  getCompanyBilling,
} from '../../../util/catan.util';

// This is a utility type that allows you to "overwrite" properties on a type
// with the assumption that it's to be used in cases where you know more than
// the compiler.
export type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;

export type ChannelSelectOption = {
  label: string;
  value: DB.Billing['channel'];
  isDisabled?: boolean;
};

export type PaymentMethodSelectOption = {
  label: string;
  value: DB.Billing['deposit_account_id'] | DB.Billing['fees_account_id'];
};

export type FeeTypeOption = {
  label: string;
  value: DB.FeeDescription['fee_description'];
};

export const channelSelectOptions: ChannelSelectOption[] = [
  { label: 'Card Present', value: 'CARDPRESENT' },
  { label: 'Card Not Present', value: 'CARDNOTPRESENT' },
  { label: 'Bank', value: 'BANK' },
  { label: 'PayPal', value: 'PAYPAL' },
  { label: 'All', value: 'ALL' },
];

export const monthlySelectOptions = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 },
];

export type SubchannelSelectOption = {
  label: string;
  value: DB.Billing['subchannel'];
};

/**
 * Return a list of all subchannel options.
 * @param channelSelectOption Provide a channel select option to return only the
 * appropriate subchannels for theprovided channel
 */
export const subchannelSelectOptions = (
  channel?: DB.Billing['channel']
): SubchannelSelectOption[] => {
  const allOptions: SubchannelSelectOption[] = [
    { label: 'Credit', value: 'CREDIT' },
    { label: 'Debit', value: 'DEBIT' },
    { label: 'American Express', value: 'AMERICANEXPRESS' },
    { label: 'Visa', value: 'VISA' },
    { label: 'Mastercard', value: 'MASTERCARD' },
    { label: 'Discover', value: 'DISCOVER' },
    { label: 'All', value: 'ALL' },
  ];

  const paypalOptions: SubchannelSelectOption[] = [
    { label: 'All', value: 'ALL' },
    { label: 'PayPal', value: 'PAYPAL' },
    { label: 'Venmo', value: 'VENMO' },
  ];

  if (channel) {
    switch (channel) {
      case 'PAYPAL':
        return paypalOptions;
      case 'CARDPRESENT':
      case 'CARDNOTPRESENT':
      case 'ALL':
        return allOptions;
      case 'BANK':
        return [];
    }
  }

  // If you just want a list of subchannels, don't pass in a
  // channel
  if (channel === undefined) {
    return allOptions;
  }

  // Handle other scenarios with empty array so we don't show improper values in
  // the UI by mistake.
  return [];
};

export type BillingCycleDaySelectOptions = {
  label: string;
  value: number;
};

export const billingCycleDaySelectOptions = () => {
  const options = [];
  for (let i = 1; i <= 28; i++) {
    options.push({ label: ordinal(i), value: i });
  }

  return options;
};

export const getSubChannels = (
  channel: DB.Billing['channel']
): DB.Billing['subchannel'][] => {
  const allSubChannels: DB.Billing['subchannel'][] = [
    'ALL',
    'VISA',
    'DISCOVER',
    'MASTERCARD',
    'AMERICANEXPRESS',
    'PAYPAL',
    'VENMO',
    'ACH',
    'DEBIT',
    'CREDIT',
  ];

  switch (channel) {
    case 'ALL':
      return allSubChannels;
    case 'CARDPRESENT':
    case 'CARDNOTPRESENT':
      return allSubChannels.filter(value => value !== 'ACH');
    case 'CREDITCARD':
      return ['VISA', 'MASTERCARD', 'DISCOVER', 'CREDIT'];
    case 'DEBITCARD':
      return ['VISA', 'MASTERCARD', 'DISCOVER', 'DEBIT'];
    case 'BANK':
      return ['ACH'];
    case 'PAYPAL':
      return ['PAYPAL', 'VENMO'];
  }
};

// TODO: Finds an existing Billing Profile that matches a provided configuration
export const fetchBillingProfile = (
  type: DB.Billing['type'],
  channel: DB.Billing['channel'],
  subchannel: DB.Billing['subchannel']
): Promise<DB.Billing> => {
  return;
};

// TODO: Add return type
export const getEnginePaymentMethods = async (
  authToken: string,
  companyId: string,
  toast: ToastContext['toast'],
  toaster: ToastContext['toaster']
) => {
  try {
    if (!companyId) {
      return;
    }
    return await catanapi.get(
      authToken,
      `/companies/${companyId}/payment-methods`
    );
  } catch (error) {
    toaster(toast.error(error, 'Unable to retrieve payment methods.'));
    return false;
  }
};

export const getBillingProfileByCompanyIdAndTypeMethods = async (
  authToken: string,
  companyId: string,
  type: string
) => {
  try {
    return (
      await getCompanyBilling(authToken, companyId, {
        type: [type as DB.BillingType],
      })
    ).data;
  } catch (error) {
    return false;
  }
};
export const getFeeTypes = async (
  authToken: string,
  toast: ToastContext['toast'],
  toaster: ToastContext['toaster']
) => {
  try {
    return await catanapi.get(authToken, '/fee-descriptions', {});
  } catch (error) {
    toaster(toast.error(error, 'Unable to retrieve fee descriptions.'));
    return false;
  }
};

export const getFlattenedBillingType = (billing: Billing): string => {
  switch (billing.type) {
    case 'TRANSACTION':
      return 'Transaction';
    case 'RECURRING':
      return 'Recurring Fee';
    case 'DISPUTE':
      return 'Dispute Fee';
    case 'RESERVES':
      return 'Reserves';
  }
  return '';
};

const billingProfileSortOrder = ['all+all', 'any+all', 'all+any', 'any+any'];

export const groupTransactionBillingProfiles = (
  profiles: Billing[]
): GroupedBillingProfiles => {
  const grouped: GroupedBillingProfiles = {};
  const sorted = [...profiles];
  sorted.sort((a, b) => {
    const ak = `${a.channel === 'ALL' ? 'All' : 'any'}+${
      a.subchannel === 'ALL' ? 'All' : 'any'
    }`.toLowerCase();
    const bk = `${b.channel === 'ALL' ? 'All' : 'any'}+${
      b.subchannel === 'ALL' ? 'All' : 'any'
    }`.toLowerCase();
    if (billingProfileSortOrder.indexOf(ak) === -1) return 1;
    if (billingProfileSortOrder.indexOf(bk) === -1) return -1;
    return (
      billingProfileSortOrder.indexOf(ak) - billingProfileSortOrder.indexOf(bk)
    );
  });
  sorted.forEach(b => {
    const name = getFlattenedTransactionProfileName(b);
    if (!grouped[name]) {
      grouped[name] = [];
    }
    if (b.per_transaction_amount === 0) {
      grouped[name].push(b);
    }
    grouped[name].push(b);
  });
  return grouped;
};

export const groupRecurringBillingProfiles = (
  profiles: BillingWithFeeDescription[]
): GroupedBillingProfiles => {
  const grouped: GroupedBillingProfiles = {};
  profiles.map(b => {
    if (b.fee_description) {
      const name = `${b.fee_description}`;
      if (!grouped[name]) {
        grouped[name] = [];
      }
      grouped[name].push(b);
    }
    return false;
  });
  return grouped;
};

export type GroupedBillingProfiles = {
  [key: string]: Billing[];
};

export type BillingWithFeeDescription = Billing & FeeDescription;

export const getFlattenedTransactionProfileName = (
  profile: Billing
): string => {
  const name = `${
    profile?.channel
      ? billingProfileChannelName[profile.channel]
      : billingProfileChannelName.ALL
  } ${
    profile?.subchannel
      ? billingProfileSubChannelName[profile.subchannel]
      : billingProfileSubChannelName.ALL
  }`
    .replace(billingProfileChannelName.ALL, '')
    .trim();
  return name;
};

export const getFeeExtraInfo = (profile: Billing): string => {
  if (
    profile &&
    (profile.per_transaction_amount !== 0 || profile.fee_percent > 0)
  ) {
    return [
      formatCapitalCase(profile.billing_cycle),
      profile.is_gross_settlement === false ? 'Net' : 'Gross',
    ].join(' - ');
  }
};

export const getFeeValue = (profile: Billing): string => {
  if (profile) {
    const fees: string[] = [];
    const percent = profile.fee_percent ? profile.fee_percent * 100 : 0;
    const flat = profile.per_transaction_amount ?? 0;
    const cap = profile.fee_cap_amount ?? 0;
    if (percent) {
      const percentString = Math.round(percent * 100) / 100;
      const capFormatted = formatFeeAmount(cap, 0);
      const capString = cap ? ` (${capFormatted} Cap)` : '';
      fees.push(`${percentString}%${capString}`);
    }
    if (flat) {
      fees.push(formatFeeAmount(flat));
    }
    return fees.join(' + ');
  }
  return '--';
};

export const formatFeeAmount = (
  amount: number,
  precision: number = 2
): string => {
  const cents = amount * 100;
  if (amount === 0) {
    return `0.00`;
  } else if (cents >= 100) {
    let dollar: string = amount + '';
    if (precision) {
      dollar = (cents / 100).toFixed(precision);
    } else {
      const bits = (amount + '').split('.');
      if (bits.length === 2 && parseInt(bits.pop()) > 0) {
        dollar = (cents / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    }
    return `$${dollar}`;
  }
  return `${Math.round(cents)}\xA2`;
};

export function getPaymentMethodDisplayName(
  paymentMethod?: Partial<PaymentMethod>
): string {
  if (!paymentMethod) return '--';
  let display = paymentMethod?.nickname?.trim();
  if (display) {
    display =
      (paymentMethod.issuing_bank_name ?? paymentMethod.person_name) +
      ` **${paymentMethod.last_four}`;
  }
  return display ?? '--';
}

const billingCycleMap = {
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  ANNUALLY: 'Annually',
};

export const formatBillingCycleString = (
  billingCycle: DB.Billing['billing_cycle']
): string | null => billingCycleMap[billingCycle] ?? null;

export const channelAndSubchannelText = (
  channel?: string,
  subchannel?: string
): string => `${channel ?? ''}${subchannel ? ' ' + subchannel : ''}`;
