import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import {
  BaseModal,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '@fattmerchantorg/truffle-components';

const StyledContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  position: relative;
`;

const StyledFooter = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  width: 100%;
  button[disabled] svg circle {
    stroke: ${({ theme }) => theme.colors.core.gray[400].hex};
  }
`;

const StyledHeader = styled(ModalHeader)`
  h1 {
    font-weight: bold;
  }
` as typeof ModalHeader;

type GenericModalProps = {
  isOpen: boolean;
  title?: string;
  buttons?: React.ReactNode;
  buttonsPosition?: 'left' | 'right' | 'center';
  onClose?: () => void;
  hasHeader?: boolean;
  hasFooter?: boolean;
};

const buttonsPositionFlex: Record<
  GenericModalProps['buttonsPosition'],
  string
> = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

export const GenericModal: FunctionComponent<GenericModalProps> = props => {
  const {
    isOpen,
    title,
    buttons,
    onClose,
    children,
    buttonsPosition = 'center',
    hasHeader = true,
    hasFooter = true,
  } = props;

  return (
    <BaseModal
      isOpen={isOpen}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      style={{
        overlay: { pointerEvents: 'auto' },
        content: { maxWidth: '480px' },
      }}
    >
      {hasHeader && <StyledHeader title={title} onClose={onClose} />}
      <ModalContent>
        <StyledContent>{children}</StyledContent>
      </ModalContent>
      {hasFooter && (
        <ModalFooter>
          <StyledFooter
            style={{ justifyContent: buttonsPositionFlex[buttonsPosition] }}
          >
            {buttons}
          </StyledFooter>
        </ModalFooter>
      )}
    </BaseModal>
  );
};
