import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { RegistrationAuditLog } from '@fattmerchantorg/types-omni';
import { get } from '../../../../util/object.util';
import { TextLink, StatusPill } from '@fattmerchantorg/truffle-components';
import { format } from 'date-fns';
import { StatusPillStatus } from '@fattmerchantorg/truffle-components/dist/StatusPill/StatusPill';

const ReportRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  scroll-snap-align: start;

  span {
    font-size: 12px;
  }

  a {
    font-size: 14px;
  }

  > * {
    margin: 0 16px;

    &:first-child {
      margin: 0 16px 0 0;
    }
  }
`;

const InternalLogRow = styled.span`
  font-size: 14px !important;
  font-weight: 400;
`;

interface ReportGroupProps {
  reports: RegistrationAuditLog[];
  groupTimestamp: Date;
  onViewReport?: React.MouseEventHandler<HTMLAnchorElement>;
}

const CreditScorePill: React.FC<{ score: number }> = props => {
  const { score } = props;
  const status: StatusPillStatus =
    score < 600 ? 'error' : score < 675 ? 'warning' : 'success';
  return (
    <StatusPill
      label={Number.isInteger(score) ? score.toString() : '-'}
      status={status}
      disabled={!Number.isInteger(score)}
    />
  );
};

export const AuditLogExperianRow: FunctionComponent<
  ReportGroupProps
> = props => {
  const { groupTimestamp, reports, onViewReport } = props;

  const rawScore = get(reports[reports.length - 1], 'result.riskModel.0.score');
  let score: number = null;
  if (rawScore) {
    const intScore = Number.parseInt(rawScore);
    if (!isNaN(intScore)) {
      score = intScore;
    }
  }

  const firstName = (
    get(
      reports[reports.length - 1],
      'result.consumerIdentity.name.0.firstName'
    ) || ''
  ).toLowerCase();
  const middleName = (
    get(
      reports[reports.length - 1],
      'result.consumerIdentity.name.0.middleName'
    ) || ''
  ).toLowerCase();
  const surname = (
    get(
      reports[reports.length - 1],
      'result.consumerIdentity.name.0.surname'
    ) || ''
  ).toLowerCase();
  const identityName = `${firstName} ${middleName} ${surname}`;

  const ReportIdentity = () => (
    <>
      {onViewReport ? (
        <TextLink
          onClick={onViewReport}
          data-testid="audit-log-experian-detail-link"
          style={{ textTransform: 'capitalize' }}
        >
          {identityName}
        </TextLink>
      ) : (
        <span style={{ fontSize: '14px', textTransform: 'capitalize' }}>
          {identityName}
        </span>
      )}
      <br />
    </>
  );

  return (
    <ReportRow>
      <InternalLogRow>
        {identityName.trim().length > 0 && <ReportIdentity />}
        {format(groupTimestamp, 'MM/dd/yyyy hh:mm a')}
      </InternalLogRow>
      <CreditScorePill score={score} />
    </ReportRow>
  );
};
