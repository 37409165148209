import React from 'react';
import { FunctionComponent } from 'react';
import styled, { withTheme } from 'styled-components';
import { mapOnboardingStages } from '../../../../util/dashboard.util';

import {
  RegistrationFlowTileTitle,
  RegistrationFlowTileNumber,
} from '../shared/TruffleText';

const StyledLink = withTheme(
  styled.a`
    color: #fff;
    display: flex;

    &:hover {
      color: #fff;
    }

    span {
      flex: 1;
    }

    i {
      margin-top: 4px;
      color: ${({ theme }) => theme.colors.core.blue[500].hex};
    }
  `
);

export const OnboardingStageTile: FunctionComponent<{
  label: string;
  count: number;
  startDate: string;
  endDate: string;
}> = ({ label, count, startDate, endDate }) => {
  // Use existing utility to translate the readable status into the status for the query param.
  const labelToStatusDictionary = mapOnboardingStages(null);
  const { key: statusForQueryParam } = labelToStatusDictionary.find(
    s => s.step === label
  );

  return (
    <>
      <StyledLink
        href={`/#/merchants?registration_onboarding_status=${statusForQueryParam}&registration_updated_at_start_date=${startDate}&registration_updated_at_end_date=${endDate}`}
      >
        <span>{RegistrationFlowTileTitle(label)}</span>
        <i className="fas fa-chevron-right"></i>
      </StyledLink>
      {RegistrationFlowTileNumber(count)}
    </>
  );
};
