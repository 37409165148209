import {
  GodviewPermissions,
  PermissionReadWrite,
} from '@fattmerchantorg/types-omni';
import styled from 'styled-components';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  Switch,
  useLocation,
} from 'react-router-dom';
import { coreapi, permissionsapi } from '../../../api';
import {
  AuthStore,
  PermissionsStore,
  SelectedMerchantProvider,
  SelectedMerchantStore,
  updateAuthMerchant,
  updateAuthUser,
  updatePermissions,
} from '../../../context';
import {
  redirects,
  getNavRoutes,
  settlementRoutes,
  businessInfoRoutes,
  merchantNonSidebarRoutes,
} from '../private';
import {
  Sidebar,
  Navbar,
  ChildNavbar,
  CHILD_NAVBAR_WIDTH,
  COLLAPSED_SIDE_BAR_WIDTH,
  EXPANDED_SIDE_BAR_WIDTH,
} from '../../main';
import { history } from '../../../history';
import {
  useAsyncEffect,
  useAutomaticModeSwitch,
  usePendo,
  usePermissions,
  useToaster,
} from '../../../hooks';
import { isTokenExpired } from '../../../util/jwt.util';
import { isEmpty } from '../../../util/object.util';
import { DevTools } from '../../devtools/DevTools';

import { SelectedMerchantFetcher } from '../../main/components/SelectedMerchantFetcher';
import { FullPageLoader } from '../../shared';
import { Onboarding } from '@fattmerchantorg/types-engine/DB';
import { getCompanyOnboardings } from '../../../util/catan.util';

const ChildNavContainer = styled.div`
  position: relative;
  padding-left: ${CHILD_NAVBAR_WIDTH};
`;

const MainContent = styled.div`
  padding-left: ${COLLAPSED_SIDE_BAR_WIDTH}px;

  #sidenav-main:hover + & #child-nav-bar {
    left: ${EXPANDED_SIDE_BAR_WIDTH - COLLAPSED_SIDE_BAR_WIDTH}px;
  }
`;

const PrivateRoute: FunctionComponent<RouteProps> = props => {
  const { component: Component, render, ...rest } = props;
  const {
    state: { status },
  } = useContext(AuthStore);

  return (
    <Route
      {...rest}
      render={props => {
        if (status === 'notauthed') {
          return <Redirect to="/logout" />;
        } else {
          return render ? render(props) : <Component {...props} />;
        }
      }}
    />
  );
};

export const PermissionGatedRoute: FunctionComponent<
  RouteProps & {
    permitParams?:
      | ['godview', keyof GodviewPermissions, PermissionReadWrite]
      | undefined;
    redirectPath?: string | undefined;
  }
> = props => {
  const { component: Component, render, ...rest } = props;
  const { permit } = usePermissions();
  const {
    state: { merchant },
  } = useContext(SelectedMerchantStore);

  useAutomaticModeSwitch(rest.path.toString(), merchant);
  usePendo();

  return (
    <Route
      {...rest}
      render={props => {
        if (rest.permitParams && !permit(...rest.permitParams)) {
          return <Redirect to={rest.redirectPath ?? '/error/403'} />;
        } else {
          return render ? (
            render(props)
          ) : Component ? (
            <Component {...props} />
          ) : null;
        }
      }}
    />
  );
};

const App: FunctionComponent<RouteComponentProps<any>> = props => {
  const {
    dispatch: permissionsDispatch,
    state: { permissions },
  } = useContext(PermissionsStore);
  const location = useLocation();
  const { permit } = usePermissions();
  const { dispatch: authDispatch, state: authState } = useContext(AuthStore);
  const { state: merchantState } = useContext(SelectedMerchantStore);

  const { toaster, toast } = useToaster();
  const [appsApprovedOnboardings, setAppsApprovedOnboardings] = useState<
    Onboarding[] | undefined
  >();

  const engineCompanyId = merchantState?.registration?.external_company_id;

  const { authToken } = authState;
  useAsyncEffect(async () => {
    if (engineCompanyId) {
      try {
        const onboardings = await getCompanyOnboardings(
          authToken,
          engineCompanyId,
          { processorName: ['APPS'] }
        );
        setAppsApprovedOnboardings(onboardings.data);
      } catch (e) {}
    }
  }, [authToken, engineCompanyId]);

  const tokenExpired: boolean | null = authToken
    ? isTokenExpired(authToken)
    : null;

  useEffect(() => {
    if (tokenExpired) {
      history.push('/logout');
    }
  }, [tokenExpired]);

  useAsyncEffect(async () => {
    if (authToken) {
      // get updated user & merchant
      const { user, merchant } = await coreapi.get(authToken, '/self');
      authDispatch(updateAuthUser(user));
      authDispatch(updateAuthMerchant(merchant));
    }
  }, [authToken]);

  useAsyncEffect(async () => {
    if (authToken) {
      try {
        const [permissions] = await permissionsapi.get(authToken, '/self');
        if (isEmpty(permissions)) {
          throw new Error('This user has no permissions.');
        }
        permissionsDispatch(updatePermissions(permissions));
      } catch (error) {
        // handle case where permissions is empty or doesn't load
        console.error(error);
        toaster(
          toast.error(
            error,
            'There was a problem retrieving user permissions.',
            { lifetime: 'forever' }
          )
        );
        // Redirect to dedicated error page
        history.push(`/error/${error.status || '403'}`);
      }
    }
  }, [authToken]);

  const navRoutes = useMemo(
    () =>
      getNavRoutes({
        merchantState,
        authState,
        appsEnabled: appsApprovedOnboardings?.length > 0,
      }),
    [merchantState, authState, appsApprovedOnboardings]
  );

  const routes = [
    ...settlementRoutes,
    ...businessInfoRoutes,
    ...navRoutes,
    ...merchantNonSidebarRoutes,
  ];

  if (isEmpty(permissions) || tokenExpired) {
    // Display page loader while the request for goduser permissions is still pending
    // OR as a placeholder before we perform logout-redirect effect (triggered by expired auth).
    return <FullPageLoader />;
  }

  // If the hostname is in this array, then "dev tools" will become enabled.
  const enableDevToolsForHosts: string[] = ['localhost']; // Ex: ['localhost','connectdev.fattlabs.com']

  return (
    <>
      <Sidebar {...props} routes={navRoutes} />
      {permit('godview', 'accessSubmerchants', 'read') && (
        <SelectedMerchantFetcher />
      )}

      <MainContent className="main-content">
        {!['/settings', '/merchant'].some(r =>
          location.pathname?.includes(r)
        ) && <Navbar />}

        <Switch>
          {routes.map((route, key) =>
            route.component ? (
              <PermissionGatedRoute
                key={key}
                path={route.path}
                component={route.component}
                permitParams={route.permitParams}
                redirectPath={route.redirectPath}
              />
            ) : null
          )}

          {routes.reduce((acc, route, key) => {
            if (route.childRoutes)
              return [
                ...acc,
                ...route.childRoutes.map((childRoute, childKey) => (
                  <PermissionGatedRoute
                    key={`${key}-${childKey}`}
                    path={childRoute.path}
                    component={childRoute.component}
                    permitParams={childRoute.permitParams}
                    redirectPath={childRoute.redirectPath}
                    render={props => (
                      <ChildNavContainer>
                        <ChildNavbar
                          title={route.childMenuTitle}
                          routes={route.childRoutes}
                        />
                        <childRoute.component {...props} />
                      </ChildNavContainer>
                    )}
                  />
                )),
              ];

            return acc;
          }, [])}

          {redirects.map((redirect, key) => (
            <Redirect key={key} from={redirect.from} to={redirect.to} />
          ))}
        </Switch>

        {enableDevToolsForHosts.includes(window.location.hostname) &&
          !location.pathname?.includes('/settings') &&
          !process.env.REACT_APP_DISABLE_DEV_TOOLS && <DevTools />}
      </MainContent>
    </>
  );
};

export const PrivateRoutes = () => (
  <SelectedMerchantProvider>
    <PrivateRoute path="/" component={App} />
  </SelectedMerchantProvider>
);
