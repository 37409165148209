import React, { ReactNode, FunctionComponent, useState } from 'react';
import copy from 'copy-to-clipboard';
import { Tooltip } from '@fattmerchantorg/truffle-components';
import { Button, ButtonProps } from '../Button';
import styled from 'styled-components';

type CopyButtonProps = Omit<ButtonProps, 'onClick'> & {
  tooltip?: ReactNode;
  content: string;
};

const StyledButton = styled(Button)`
  color: ${({ theme }) => theme.colors.status.blue[500].hex};
`;

export const CopyButton: FunctionComponent<CopyButtonProps> = props => {
  const { content, tooltip, children, ...buttonProps } = props;

  const [clicked, setClicked] = useState(false);
  const tooltipContent = clicked ? 'Copied' : tooltip;

  return (
    <StyledButton
      variant="link"
      inline={true}
      {...buttonProps}
      onClick={() => {
        setClicked(true);
        copy(content);
      }}
    >
      <Tooltip
        content={tooltipContent}
        disabled={!tooltip}
        hideOnClick={false}
        duration={[null, 250]}
        onHide={() => {
          setTimeout(
            () => setClicked(false),
            // 250ms is the duration of the hide animation, as we specified above
            251
          );
        }}
      >
        {children as any}
      </Tooltip>
    </StyledButton>
  );
};

CopyButton.defaultProps = {
  children: <i className="far fa-copy" />,
  tooltip: 'Copy to clipboard',
};
