import React, { FunctionComponent, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { RouteItem } from '../../../routes/models/RouteItem.model';
import { Steps, Step } from '../../../shared';

const Container = styled.div`
  width: 100%;
  max-width: 50rem;
`;

export const ImporterSteps: FunctionComponent<{
  routes: RouteItem[];
}> = props => {
  const { routes } = props;
  const { pathname } = useLocation();

  const currentIndex = useMemo((): number => {
    return routes.findIndex(route => {
      const routeSubPath = (route.path as string).split('/').pop();
      const currentSubPath = (pathname as string).split('/').pop();
      return routeSubPath === currentSubPath;
    });
  }, [routes, pathname]);

  return (
    <Container>
      <Steps current={currentIndex}>
        {routes.map((route, index) => (
          <Step key={index} index={index}>
            {route.name}
          </Step>
        ))}
      </Steps>
    </Container>
  );
};
