import FeesSection from './sections/FeesSection';
import ProcessingDetailsSection from './sections/ProcessingDetailsSection';
import CustomerDetailsSection from './sections/CustomerDetailsSection';
import DisputesDetailsSection from './sections/DisputesDetailsSection';
import InvoiceDetailsSection from './sections/InvoiceDetailsSection';
import TransactionHistorySection from './sections/TransactionHistorySection';

import {
  CustomerDetailsSectionData,
  ProcessingDetailsSectionData,
  InvoiceDetailsSectionData,
  FeesSectionData,
  DisputesSectionData,
  TransactionHistorySectionData,
  sectionDataIdentifiers,
  drawerTypes,
  AdjustmentSectionData,
  AdjustmentProcessingDetailsSectionData,
} from './AdvancedDetailDrawer.types';
import AdjustmentSection from './sections/AdjustmentSection';
import AdjustmentProcessingDetailsSection from './sections/AdjustmentProcessingDetailsSection';

type DrawerConfig = {
  title: string;
  testId: string;
  sections: (keyof typeof individualSectionConfigs)[];
};

/**
 * DRAWER CONFIGURATION
 * title:
 *   Title of your drawer that will show at the top
 * testId:
 *   Will be piped down to the Drawer element for testing
 * header:
 *   React Component that will render the header of the drawer
 * sections:
 *   Array of reusable Section Components to render in the drawer body.
 *   The order of the sections in the array determines the order they will
 *   render in the drawer.
 * footer:
 *   React Component that will render the footer of the drawer
 */
export const DRAWER_CONFIGS: {
  [key in keyof typeof drawerTypes]: DrawerConfig;
} = {
  Transaction: {
    title: 'Transaction Details',
    testId: 'transaction-details-drawer',
    sections: [
      'ProcessingDetails',
      'CustomerDetails',
      'InvoiceDetails',
      'TransactionHistory',
      'ProcessingFees',
      'AdditionalFees',
      'Disputes',
    ],
  },
  Adjustment: {
    title: 'Transaction Details',
    testId: 'adjustment-details-drawer',
    sections: ['AdjustmentProcessingDetails', 'Adjustment'],
  },
  Refund: {
    title: 'Refund',
    testId: 'refund-details-drawer',
    sections: [
      'ProcessingDetails',
      'CustomerDetails',
      'InvoiceDetails',
      'TransactionHistory',
      'AdditionalFees',
      'Disputes',
    ],
  },
  DisputeTransaction: {
    title: 'Transaction Details',
    testId: 'dispute-details-drawer',
    sections: [
      'ProcessingDetails',
      'CustomerDetails',
      'AdditionalFees',
      'Disputes',
    ],
  },
  Fee: {
    title: 'Fee Details',
    testId: 'fee-details-drawer',
    sections: [
      'ProcessingDetails',
      'CustomerDetails',
      'ProcessingFees',
      'AdditionalFees',
    ],
  },
  /* TODO: Uncomment these drawer configs as we progress with development */
  // RecurringFee: {
  //   title: 'Recurring Fee Details',
  //   testId: 'recurring-fee-details-drawer',
  //   sections: ['ProcessingDetails', 'AdditionalFees'],
  // },
};

// Just a utility type that constrains the
export type CombinedData = {
  ProcessingDetailsSectionData?: ProcessingDetailsSectionData;
  ProcessingFeesSectionData?: FeesSectionData;
  AdditionalFeesSectionData?: FeesSectionData;
  CustomerDetailsSectionData?: CustomerDetailsSectionData;
  InvoiceDetailsSectionData?: InvoiceDetailsSectionData;
  TransactionHistorySectionData?: TransactionHistorySectionData;
  DisputesSectionData?: DisputesSectionData;
  AdjustmentSectionData?: AdjustmentSectionData;
  AdjustmentProcessingDetailsSectionData?: AdjustmentProcessingDetailsSectionData;
};

type SectionConfig = {
  key: string;
  component: React.FC<any>;
  // This is where we call out which interface the section
  requiredDataKey: keyof CombinedData;
  id?: string;
  name?: string;
};

export const individualSectionConfigs: { [key: string]: SectionConfig } = {
  ProcessingDetails: {
    key: 'processing',
    component: ProcessingDetailsSection,
    requiredDataKey: sectionDataIdentifiers.ProcessingDetailsSectionData,
  },
  CustomerDetails: {
    key: 'customer',
    component: CustomerDetailsSection,
    requiredDataKey: sectionDataIdentifiers.CustomerDetailsSectionData,
  },
  InvoiceDetails: {
    key: 'invoice',
    component: InvoiceDetailsSection,
    requiredDataKey: sectionDataIdentifiers.InvoiceDetailsSectionData,
  },
  TransactionHistory: {
    key: 'transactionHistory',
    component: TransactionHistorySection,
    requiredDataKey: sectionDataIdentifiers.TransactionHistorySectionData,
  },
  ProcessingFees: {
    key: 'processingFees',
    component: FeesSection,
    requiredDataKey: sectionDataIdentifiers.ProcessingFeesSectionData,
  },
  AdditionalFees: {
    key: 'additionalFees',
    component: FeesSection,
    requiredDataKey: sectionDataIdentifiers.AdditionalFeesSectionData,
  },
  Disputes: {
    key: 'disputes',
    component: DisputesDetailsSection,
    requiredDataKey: sectionDataIdentifiers.DisputesSectionData,
  },
  Adjustment: {
    key: 'adjustment',
    component: AdjustmentSection,
    requiredDataKey: sectionDataIdentifiers.AdjustmentSectionData,
  },
  AdjustmentProcessingDetails: {
    key: 'adjustmentProcessingDetails',
    component: AdjustmentProcessingDetailsSection,
    requiredDataKey:
      sectionDataIdentifiers.AdjustmentProcessingDetailsSectionData,
  },
};
