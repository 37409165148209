import styled from 'styled-components';
import React, { FC } from 'react';
export const ErrorSpan = styled.span`
  font-size: 11px;
  margin: 0;
  width: 100%;

  text-align: left;
  color: #be1e2d;
  display: none;

  animation: fade-in 0.3s ease-in-out both, slide-down 0.3s ease-in-out both;

  &.-visible {
    display: block;
  }
`;

export type GeneralErrorProps = {
  isVisible?: boolean;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
>;

export const GeneralError: FC<GeneralErrorProps> = (props) => {
  const { isVisible = true, ...spanProps } = props;
  return (
    <ErrorSpan className={isVisible ? '-visible' : ''} {...spanProps}>
      {props.children}
    </ErrorSpan>
  );
};
