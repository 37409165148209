import { Select } from '@fattmerchantorg/truffle-components';
import { Image } from '@fattmerchantorg/types-omni';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { components } from 'react-select';
import { Form, FormGroup } from 'reactstrap';
import styled, { withTheme } from 'styled-components';
import AllBrandsIcon from '../../../assets/img/brand/all-brands.svg';
import {
  PermissionsStore,
  SelectedMerchantStore,
  updateSelectedBrandSwitcherOption,
  updateSelectedMerchant,
} from '../../../context';
import {
  createAllBrandsValue,
  isAllBrandsSelected,
} from '../../../util/brandSwitcher.util';

const BIG_LOGO_SIZE = '28px';

export const StyledBigAllBrandsLogo = styled.img`
  height: ${BIG_LOGO_SIZE};
  max-width: ${BIG_LOGO_SIZE};
  display: inline-block;
`;

export const StyledBigLogo = styled.img`
  height: ${BIG_LOGO_SIZE};
  max-width: ${BIG_LOGO_SIZE};
  padding: 5px;
  display: inline-block;
  background-color: white;
  border-radius: 4px;
`;

const SMALL_LOGO_SIZE = '24px';

const StyledSmallAllBrandsLogo = styled.img`
  height: ${SMALL_LOGO_SIZE};
  max-width: ${SMALL_LOGO_SIZE};
  display: inline-block;
`;

const StyledSmallLogo = styled.img`
  height: ${SMALL_LOGO_SIZE};
  max-width: ${SMALL_LOGO_SIZE};
  padding: 3px;
  display: inline-block;
  background-color: white;
  border-radius: 4px;
`;

export const StyledBigInitial = styled.div`
  min-height: ${BIG_LOGO_SIZE};
  min-width: ${BIG_LOGO_SIZE};
  max-height: ${BIG_LOGO_SIZE};
  max-width: ${BIG_LOGO_SIZE};
  padding: 5px;
  line-height: 18px;
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  align-items: center;
  color: ${({ theme }) => theme.black} !important;
  background: ${({ theme }) => theme.colors.core.gray[200].hex} !important;
  border-radius: 4px;
  font-weight: 400;
`;

const StyledSmallInitial = styled.div`
  min-height: ${SMALL_LOGO_SIZE};
  min-width: ${SMALL_LOGO_SIZE};
  max-height: ${SMALL_LOGO_SIZE};
  max-width: ${SMALL_LOGO_SIZE};
  padding: 3px;
  line-height: 18px;
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  align-items: center;
  color: ${({ theme }) => theme.black} !important;
  background: ${({ theme }) => theme.colors.core.gray[200].hex} !important;
  border-radius: 4px;
  font-weight: 400;
`;

const OptionText = styled.div`
  margin-left: 8px;
`;

const CenteredFlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

type Option = {
  label: string;
  /** Must match the enum value in AirTable  */
  value: string;
  squareLogo?: Image;
};

const CustomOption = props => {
  return (
    <components.Option {...props}>
      <span>{props.label}</span>
    </components.Option>
  );
};

const BrandSelect = withTheme(styled(Select)`
  flex: 1;

  width: 200px;
  margin-left: 8px;

  // The styles below will get simplified when Truffle allows
  // more styles to be overridable (in progress).
  div div {
    color: ${({ theme }) => theme.white};
  }

  .truffle-select__dropdown-indicator,
  .truffle-select__dropdown-indicator:hover {
  }

  .truffle-select__control,
  .truffle-select__menu {
    border: 0px;

    .truffle-select__option--is-selected,
    .truffle-select__option:hover {
      border-radius: 2px;
    }
  }
`);

const customStyles = {
  option: (provided, state) => {
    const optionStyling = {
      ...provided,
      padding: '4px',
      fontSize: '12px',
      color: state.theme.white,
      borderRadius: '4px',
      marginTop: '8px',
    };

    if (state.data.label === 'All Brands') {
      optionStyling.fontSize = '14px';
      optionStyling.lineHeight = '28px';
    }

    if (state.isSelected) {
      optionStyling.fontWeight = 700;
      optionStyling.backgroundColor = `${state.theme.colors.core.green[800].hex} !important`;
    }

    if (state.isDisabled) {
      optionStyling.color = state.theme.colors.core.gray[200].hex;
      optionStyling.backgroundColor = state.theme.colors.core.gray[500].hex;
      optionStyling['&:hover'] = {
        cursor: 'not-allowed',
      };
    }

    return optionStyling;
  },
  menu: provided => ({
    ...provided,
    backgroundColor: '#1C2F3B', // Gray/900
    paddingLeft: '8px',
    paddingRight: '8px',
    boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.25)',
  }),
  singleValue: (provided, state) => {
    const selectionStyling = {
      ...provided,
      opacity: state.isDisabled ? 0.5 : 1,
      transition: 'opacity 300ms',
      fontWeight: 700,
    };

    return selectionStyling;
  },
  valueContainer: (provided, state) => ({
    ...provided,
    height: BIG_LOGO_SIZE,
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.theme.black,
    minHeight: BIG_LOGO_SIZE,
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: '28px',
  }),
};

export const BrandSwitcher = () => {
  const location = useLocation();
  const {
    state: { selectedBrandSwitcherOption },
  } = useContext(SelectedMerchantStore);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [userBrandsOptions, setUserBrandsOptions] = useState([]);
  const history = useHistory();
  const { pathname, search, hash } = useLocation();

  const {
    state: { brands: userBrands },
  } = useContext(PermissionsStore);

  const {
    dispatch,
    state: { merchant: selectedMerchant },
  } = useContext(SelectedMerchantStore);

  /**
   * Runs once when permissions are fetched.
   * Sets up and initializes `Brand Switcher`.
   */
  useEffect(() => {
    if (!userBrands) return;
    const _userBrandsAsOptions: Option[] = userBrands.map(userBrand => {
      const squareLogo = userBrand.logoSquare?.[0];

      return {
        label: userBrand.displayName,
        value: userBrand.name,
        squareLogo,
      };
    });

    // The default option "All" has a value of all user's brands concatenated.
    // The decision was made so fattquery's caching system continues to work
    // when wanting to fetch "brands=all" via query url.
    //
    // Ex: {label: "All", value: "fattmerchant,stax,bigtime" }
    const valueForAllBrands = createAllBrandsValue(userBrands);
    _userBrandsAsOptions.unshift({
      value: valueForAllBrands,
      label: 'All Brands',
    });

    setUserBrandsOptions(_userBrandsAsOptions); // Sets available options.
    //check if the current selected brand is still part of the user brands else set AllBrands
    setSelectedBrand(
      (userBrands.find(br => br.name === selectedBrandSwitcherOption) &&
        selectedBrandSwitcherOption) ||
        valueForAllBrands
    ); // Sets selected value.

    dispatch(
      updateSelectedBrandSwitcherOption(
        selectedBrandSwitcherOption || valueForAllBrands
      )
    ); // Sends to context.
  }, [userBrands, dispatch, selectedBrandSwitcherOption]);

  const isSettingsPage = location.pathname.includes('settings');
  useEffect(() => {
    if (!userBrands.length) return;

    // Cannot have the brand set to "All Brands" on the settings page
    if (isSettingsPage && isAllBrandsSelected(selectedBrand, userBrands)) {
      setSelectedBrand(userBrands[0].name); // Sets selected value.
      dispatch(updateSelectedBrandSwitcherOption(userBrands[0].name)); // Sends to context.
    }
  }, [userBrands, dispatch, isSettingsPage, selectedBrand]);

  /**
   * Runs when the user makes a new `Brand Switcher` selection.
   * 1) Push `Brand Switch` selection to context.
   * 2) Redirect to `Dashboard` OR reset to first search page if on the `Merchants` page.
   */
  const handleBrandSwitch = (selectedBrand: {
    value: string;
    label: string;
  }) => {
    const { value } = selectedBrand;
    setSelectedBrand(value);
    dispatch(updateSelectedBrandSwitcherOption(value));

    if (!value.includes(selectedMerchant?.brand)) {
      // Close merchant navigation.
      dispatch(updateSelectedMerchant(null));
    }

    // Allow "/merchants" and "/settings" to stay on the same page when changing brand switcher.
    if (pathname === '/merchants') {
      // test: ['?test=1&page=NA', '?test=1&page=11', '?page=1', '?test=1&page=1&end=true', '?page=11&test=1'].map(s => ([/(&page=\d+)|(page=\d+&)|(\?page=\d+$)/.test(s), s, '=>', s.replace(/(&page=\d+)|(page=\d+&)|(\?page=\d+$)/, '')]))
      const searchWithoutPage = search.replace(
        /(&page=\d+)|(page=\d+&)|(\?page=\d+$)/,
        ''
      );
      history.push(`${pathname}${searchWithoutPage}${hash}`);
    } else if (!isSettingsPage) {
      let url = `/dashboard`;
      history.push(url);
    }
  };

  /* Determine if the current page should render the Brand Switcher */
  const isMerchantSpecificPage: boolean =
    location.pathname.substring(0, 10) === '/merchant/';
  const isSettlementsPage = location.pathname.includes('settlements');
  // Do not show Brand Switcher on Settlements page or on submerchant-specific pages.
  const currentPageNeedsBrandSwitcher: boolean =
    !isMerchantSpecificPage && !isSettlementsPage;

  return (
    <>
      <Form className="form-inline">
        <FormGroup>
          <BrandSelect
            styles={customStyles}
            options={userBrandsOptions}
            onChange={handleBrandSwitch}
            menuPortalTarget={null}
            components={{
              CustomOption,
            }}
            formatOptionLabel={(
              option: Option,
              { context }: { context: string }
            ) => {
              if (context === 'menu') {
                return (
                  <CenteredFlexDiv>
                    {option.label === 'All Brands' ? (
                      <StyledSmallAllBrandsLogo alt="" src={AllBrandsIcon} />
                    ) : !!option.squareLogo?.url ? (
                      <StyledSmallLogo src={option.squareLogo?.url} alt="" />
                    ) : (
                      <StyledSmallInitial>
                        {option.label.charAt(0)}
                      </StyledSmallInitial>
                    )}
                    <OptionText>{option.label}</OptionText>
                  </CenteredFlexDiv>
                );
              }

              return (
                <CenteredFlexDiv>
                  {option.label === 'All Brands' ? (
                    <StyledBigAllBrandsLogo alt="" src={AllBrandsIcon} />
                  ) : !!option.squareLogo?.url ? (
                    <StyledBigLogo src={option.squareLogo?.url} alt="" />
                  ) : (
                    <StyledBigInitial>
                      {option.label.charAt(0)}
                    </StyledBigInitial>
                  )}
                  <OptionText>{option.label}</OptionText>
                </CenteredFlexDiv>
              );
            }}
            value={userBrandsOptions.find(o => o.value === selectedBrand)}
            /**
             * Disable Brand Switcher unless they have more than '1' brand.
             * We check for length greater than '2' because "All Brands" is already in the options list.
             */
            isDisabled={!userBrandsOptions || !currentPageNeedsBrandSwitcher}
            // Cannot have the brand set to "All Brands" on the settings page
            isOptionDisabled={option =>
              isSettingsPage && option.label === 'All Brands'
            }
            hideSelectedOptions={false}
            isClearable={false}
          />
        </FormGroup>
      </Form>
    </>
  );
};
