import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';

import { Field } from '../Field/Field';
import { showOnlyLastFour } from '../../../util/string.util';
import { ToggleableFieldProps } from './ToggleableField.types';
import { Group } from '../Group';
import { Button } from '../Button';

const Label = styled.label`
  font-size: 12px;
  line-height: 16px;
`;

const Value = styled.span`
  font-size: 110%;
  font-weight: bold;
`;

const Container = styled(Group)`
  button.toggle {
    transition: none;
  }
`;

export function ToggleableField<
  FieldValue = any,
  T extends HTMLElement = HTMLElement
>(props: ToggleableFieldProps<FieldValue, T>): JSX.Element {
  const { defaultMode = 'display', toggleable = true, secure, ...rest } = props;
  const { showLastFourChars } = rest;
  const [mode, setMode] = useState(defaultMode);

  useEffect(() => {
    setMode(defaultMode);
  }, [defaultMode]);

  const renderValue = useCallback(
    (value: any) => {
      switch (true) {
        case secure && !!value:
          return value.replace(/./g, '*');
        case showLastFourChars && !!value:
          return showOnlyLastFour(value, true);
        case !!value:
          return value;
        default:
          return '-';
      }
    },
    [secure, showLastFourChars]
  );

  return (
    <Container direction="column">
      {props.label && mode === 'display' && <Label>{props.label}</Label>}
      <Group direction="row" space="0.5rem">
        {mode === 'display' ? (
          <Field {...rest}>
            {props => <Value>{renderValue(props.input.value)}</Value>}
          </Field>
        ) : (
          <Field {...rest} />
        )}
        {toggleable && (
          <Button
            size="tiny"
            id={`edit-button-${props.name}`}
            data-testid={`edit-button-${props.name}`}
            variant="outline"
            border="rounded"
            className="toggle"
            motif={mode === 'edit' ? 'success' : 'tertiary'}
            icon={mode === 'edit' ? 'fas fa-check' : 'fas fa-pencil-alt'}
            onClick={() => setMode(mode === 'edit' ? 'display' : 'edit')}
          />
        )}
      </Group>
    </Container>
  );
}
