import { FormErrors } from '../../../../@types';
import { Registration } from '@fattmerchantorg/types-omni';

export const pricingInfoFields = [
  'credit_qual_trans_fee',
  'credit_mid_trans_fee',
  'credit_non_trans_fee',
  'amex_qual_trans_fee',
  'amex_mid_trans_fee',
  'amex_non_trans_fee',
  'credit_qual_disc_rate',
  'credit_mid_disc_rate',
  'credit_non_disc_rate',
  'amex_qual_disc_rate',
  'amex_mid_disc_rate',
  'amex_non_disc_rate',
] as const;

export type PricingInfoFields = typeof pricingInfoFields[number];

export type PricingInfoFieldsValues = Pick<Registration, PricingInfoFields>;

export type PricingInfoFieldsErrors = FormErrors<PricingInfoFieldsValues>;
