import React, { useCallback } from 'react';
import {
  Form as FinalForm,
  AnyObject,
  FormRenderProps,
} from 'react-final-form';
import styled from 'styled-components';
import { useToaster } from '../../../hooks';
import { FormProps } from './Form.types';

const StyledForm = styled.form`
  button {
    margin: 1rem 0;
  }

  > * {
    margin-bottom: 1rem;
  }

  @media (max-width: 1000px) {
    > * {
      margin-bottom: 0px;
    }
  }
`;

export function Form<FormValues = AnyObject>(props: FormProps<FormValues>) {
  const { children, showToastOnFailedSubmit = true, ...restOfProps } = props;
  const { toast, toaster } = useToaster();

  const renderChildren = useCallback(
    (formProps: FormRenderProps<FormValues>) => {
      return typeof children === 'function' ? children(formProps) : children;
    },
    [children]
  );

  return (
    <FinalForm {...restOfProps}>
      {formProps => {
        return (
          <StyledForm
            onSubmit={e => {
              if (formProps.invalid && showToastOnFailedSubmit) {
                const content = Object.keys(formProps.errors).reduce(
                  (sum, key) =>
                    `${sum}${key}: ${JSON.stringify(formProps.errors[key])}\n`,
                  ''
                );
                toaster(toast.error(content, 'Submit failed - form errors'));
              }

              formProps.handleSubmit(e);
            }}
          >
            {renderChildren(formProps)}
          </StyledForm>
        );
      }}
    </FinalForm>
  );
}

export { FormSpy } from 'react-final-form';
