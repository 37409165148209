import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  statementLoadingIndividualStateKeys,
  useStatementLoadingState,
} from '../../../../context/statements';
import { ProgressBar } from './progress-bar';
import { BoldText } from '../../../truffle-dashboard/components/shared/BoldText';

const loadingTextInterval = 3000;
const loadingTickInterval = 500;

const loadingTexts = [
  'Summarizing Sales',
  'Fetching Fees',
  'Digesting Deposits',
  'Constructing Credit Card Volumes',
  'Aggregating ACH Transactions',
  'Preparing Processing Volumes',
  'Reconciling Refunds',
  'Distilling Disputes',
];

export const Loading: FunctionComponent = () => {
  const {
    statementLoadingTimeoutDuration: loadingTimeout,
    statementLoadingState: { completedCount },
  } = useStatementLoadingState();

  const [loadingTicks, setLoadingTicks] = useState(0);
  const [loadingTextIdx, setLoadingTextIdx] = useState(0);

  useEffect(() => {
    // Increment tick count for progress bar
    const interval = setInterval(() => {
      setLoadingTicks(t => {
        const newTicks = t + 1;
        if (newTicks % (loadingTextInterval / loadingTickInterval) === 0)
          setLoadingTextIdx(idx =>
            idx === loadingTexts.length - 1 ? 0 : idx + 1
          );
        return newTicks;
      });
    }, loadingTickInterval);

    return () => clearInterval(interval);
  }, [completedCount]);

  const loadingProgress =
    (completedCount / statementLoadingIndividualStateKeys.length) * 100;
  const timeoutProgress =
    (loadingTicks / (loadingTimeout / loadingTickInterval)) *
    (100 - loadingProgress);
  const progress = loadingProgress + timeoutProgress;

  return (
    <div>
      <BoldText fontSize={24}>{loadingTexts[loadingTextIdx]}</BoldText>
      <ProgressBar percent={progress} />
    </div>
  );
};
