import React, { createContext, useReducer } from 'react';
import get from 'lodash/get';
import {
  UnderwritingState,
  UnderwritingStore,
  UnderwritingProviderProps,
  UnderwritingAction,
} from './UnderwritingContext.types';

export const UnderwritingContext = createContext({} as UnderwritingStore);

const underwritingInitialState: UnderwritingState = {
  merchantId: null,
  applicationSubmitErrors: [],
  auditLogs: null,
  onboardingLogs: null,
  autoverificationResult: null,
};

const underwritingReducer = (
  state: UnderwritingState,
  action: UnderwritingAction
): UnderwritingState => {
  switch (action.type) {
    case 'SET_APPLICATION_SUBMIT_ERRORS':
      return {
        ...state,
        applicationSubmitErrors: get(
          action.payload,
          'applicationSubmitErrors',
          []
        ),
      };
    case 'SET_AUDIT_LOGS':
      return {
        ...state,
        merchantId: get(action.payload, 'merchantId', null),
        auditLogs: get(action.payload, 'auditLogs', null),
      };
    case 'SET_ONBOARDING_LOGS':
      return {
        ...state,
        merchantId: get(action.payload, 'merchantId', null),
        onboardingLogs: get(action.payload, 'onboardingLogs', null),
      };
    case 'SET_AUTOVERIFICATION_RESULT':
      return {
        ...state,
        autoverificationResult: get(
          action.payload,
          'autoverificationResult',
          null
        ),
      };
    default:
      return state;
  }
};

export const UnderwritingProvider = (props: UnderwritingProviderProps) => {
  const { children, initialState = {} } = props;
  const [underwritingState, underwritingDispatch] = useReducer(
    underwritingReducer,
    {
      ...underwritingInitialState,
      ...initialState,
    }
  );

  const localContext: UnderwritingStore = {
    underwritingState,
    underwritingDispatch,
  };

  return (
    <UnderwritingContext.Provider value={localContext}>
      {children}
    </UnderwritingContext.Provider>
  );
};

export const UnderwritingConsumer = UnderwritingContext.Consumer;

export const useUnderwritingContext = () => {
  const ctx = React.useContext(UnderwritingContext);

  if (ctx === undefined) {
    throw new Error(`UnderwritingContext is undefined`);
  }

  return ctx;
};
