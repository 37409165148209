import { StatusPill, Tooltip } from '@fattmerchantorg/truffle-components';
import { StatusPillStatus } from '@fattmerchantorg/truffle-components/dist/StatusPill/StatusPill';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React, { FC } from 'react';
import styled, { withTheme } from 'styled-components';
import { AsyncDataStatus } from '../../../../@types';
import { StatisticsRouteResponse } from '../../../../api';
import { GrossSalesData } from '../../useTransactionChartHook';
import {
  IPillConfigurations,
  PillConfigurations,
  TooltipContentTypes,
} from './types';
import {
  calculatePercentageChangeMetric,
  getTooltipContent,
} from './StatisticsPill.util';

// Pill configurations.
const pillConfigurations: IPillConfigurations = {
  default: { status: 'success', icon: ['fas', 'info-circle'], disabled: true },
  positive: { status: 'success', icon: ['fas', 'caret-up'], disabled: false },
  negative: { status: 'error', icon: ['fas', 'caret-down'], disabled: false },
};

const PillWrapper = withTheme(
  styled.div`
    float: right;
    font-size: 12px;

    > span > span {
      border-radius: 8px;
      background: ${({ theme }) => theme.black};
      border: 1px solid ${({ theme }) => theme.colors.core.gray[500].hex};
    }

    * {
      font-family: Roboto;
      font-weight: 700;
    }
  `
);

export const StatisticsPill: FC<{
  data: GrossSalesData;
  summaryKey: keyof StatisticsRouteResponse['summary'];
  selectedTimespan: string | null;
}> = props => {
  // Default values for "empty" pill.
  let defaultConfiguration: PillConfigurations = 'default';
  let defaultLabel: string = 'N/A';
  let tooltipContent: string = getTooltipContent('NO_DATA');

  switch (props.data.status) {
    case AsyncDataStatus.INITIAL:
    case AsyncDataStatus.ERROR:
    case AsyncDataStatus.LOADING:
      return null; // Don't show UI.
    case AsyncDataStatus.IDLE:
      // Display default "N/A" configuration when there's no data.
      if (!props.data.data.selectedTimeRange.summary) {
        return (
          <PillWrapper>
            <StatusPill
              label={defaultLabel}
              status={pillConfigurations.default.status as StatusPillStatus}
              icon={pillConfigurations.default.icon as IconProp}
              disabled={pillConfigurations.default.disabled}
            />
          </PillWrapper>
        );
      }

      const selectedSummaryKey: string = props.data.data.selectedTimeRange
        .summary[props.summaryKey]
        ? props.data.data.selectedTimeRange.summary[props.summaryKey].toString()
        : '0';

      const currentValue: number = parseInt(
        selectedSummaryKey.toString().replace(/,/g, '') // Remove comma from formatted number. Ex: 1,000.
      );

      const comparativeSummaryKey: string = props.data.data.comparativeTimeRange
        .summary[props.summaryKey]
        ? props.data.data.comparativeTimeRange.summary[
            props.summaryKey
          ].toString()
        : '0';

      const previousValue: number = parseInt(
        comparativeSummaryKey.toString().replace(/,/g, '') // Remove comma from formatted number. Ex: 1,000.
      );

      // "Percentage Change" formula.
      let percentageDiff: number = calculatePercentageChangeMetric(
        currentValue,
        previousValue
      );

      // Only display positive/negative stats if there exist
      // data for the current and previous subjects.
      if (currentValue && previousValue) {
        if (percentageDiff >= 0) {
          defaultConfiguration = 'positive';
        } else {
          // Flip negative percentages so they don't display the "-" sign.
          percentageDiff *= -1;
          defaultConfiguration = 'negative';
        }

        // Round to 2 decimals.
        defaultLabel = `${percentageDiff.toFixed(2)}%`;

        // Get tooltip content.
        tooltipContent = getTooltipContent(
          props.selectedTimespan as TooltipContentTypes
        );
      }

      // Pill styles based on math.
      const { status, icon, disabled } =
        pillConfigurations[defaultConfiguration];

      return (
        <PillWrapper>
          <Tooltip content={tooltipContent}>
            <StatusPill
              label={defaultLabel}
              status={status as StatusPillStatus}
              icon={icon as IconProp}
              disabled={disabled}
            />
          </Tooltip>
        </PillWrapper>
      );
  }
};
