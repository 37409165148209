import React, { useState } from 'react';
import styled from 'styled-components';

const ReadMoreSpan = styled.span`
  //width: 100%;
  line-height: 20px;
  margin-left: 39px;
  margin-right: 16px;
  &[data-isreadmore='true'] {
    div {
      width: 100%;
    }

    [id^='content'] {
      display: inline-block;
    }

    [id^='preview'] {
      display: none;
    }
  }

  &[data-isreadmore='false'] {
    [id^='content'] {
      display: none;
    }

    [id^='preview'] {
      display: inline-block;
    }
  }
`;

const MoreButton = styled.button`
  outline: none;
  border: none;
  padding: 0 !important;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.status.blue[500].hex};
  background: transparent;
  text-decoration: underline;
  text-align: right;
  float: right;
  line-height: 20px;
  height: 20px;
`;

const EngagementContent = (props: {
  engagementId: string;
  contentText: string;
}) => {
  const { engagementId, contentText } = props;

  const shouldReadMore: boolean = contentText.split(' ').length > 20;
  const [isReadMore, setIsReadMore] = useState(!shouldReadMore);

  return (
    <ReadMoreSpan data-isreadmore={isReadMore}>
      <div id={`content_${engagementId}`}>
        <span>{contentText}</span>
        {shouldReadMore ? (
          <MoreButton onClick={() => setIsReadMore(false)}>see less</MoreButton>
        ) : null}
      </div>

      <div id={`preview_${engagementId}`}>
        {contentText.split(' ').slice(0, 20).join(' ')}
        ...
        <MoreButton onClick={() => setIsReadMore(true)}>see more</MoreButton>
      </div>
    </ReadMoreSpan>
  );
};

export default EngagementContent;
