import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  TextField,
  Select,
  Checkbox,
  BaseModal,
  PrimaryButton,
  SecondaryButton,
  Icon,
} from '@fattmerchantorg/truffle-components';
import { FormDivider } from '../../underwriting/components/shared/UnderwritingPartialForm';

import {
  FieldWrap,
  FieldWrapCustom,
  modalWidthClass,
  StyledContent,
  StyledHeader,
  StyledModalFooter,
  StyledText,
  WrapperText,
} from '../SettingsPagesStylings';

import {
  PermissionsStore,
  SelectedMerchantStore,
  updateSelectedBrandSwitcherOption,
} from '../../../context';
import { useAuthToken, usePermissions, useToaster } from '../../../hooks';
import { AllBrandsResponse, coreapi, permissionsapi } from '../../../api';
import { generatePassword } from './password-util';
import { Field, Form as FinalForm } from 'react-final-form';
import { User } from '@fattmerchantorg/types-omni/types/User';
import {
  checkEmail,
  validateBrands,
  validateName,
} from './TeamMemberModal.util';
import { RoleDetailsResponse } from '../roles/RoleDetails';
import styled from 'styled-components';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface Option {
  label?: string;
  value?: string;
}

interface FormState {
  id?: string;
  name?: string;
  email?: string;
  role?: Option;
  brands?: Array<Option>;
  primaryBrand?: Option;
  mfa_checked?: boolean;
}

interface Props {
  isOpen: boolean;
  setIsOpen: (e: boolean) => void;
  fetchUsers?: () => void;
  getUser?: () => void;
  initialUser?: User;
  isEditMode?: boolean;
}

const InfoSection = styled.div`
  padding: 8px 16px;
  height: 36px;
  margin-right: 16px;
  background-color: ${({ theme }) => theme.colors.status.blue[500].hex};
  display: flex;
  gap: 16px;
  align-items: center;
  flex-direction: row;
  margin-bottom: 6px;
`;

const StyledBrand = styled.span`
  background-color: ${({ theme }) => theme.colors.status.blue[500].hex};
  text-decoration: underline;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'not-allowed')};
`;

export const TeamMemberModal: FC<Props> = ({
  isOpen,
  setIsOpen,
  fetchUsers,
  getUser,
  initialUser,
  isEditMode = false,
}) => {
  const {
    state: { brands: userBrands },
  } = useContext(PermissionsStore);
  const {
    dispatch,
    state: { selectedBrandSwitcherOption },
  } = useContext(SelectedMerchantStore);

  const { toast, toaster } = useToaster();
  const authToken = useAuthToken();
  const { permit } = usePermissions();

  const [brandOptions, setBrandOptions] = useState<Option[]>(() =>
    userBrands?.map(brandItem => ({
      label: brandItem.displayName,
      value: brandItem.name,
    }))
  );

  const [roleOptions, setRoleOptions] = useState<Option[]>([]);

  const canSeeAllBrands = permit('godview', 'canSeeAllBrands', 'write');

  const fetchAllBrands = useCallback(async () => {
    try {
      const allBrandsOptions: AllBrandsResponse = await permissionsapi.get(
        authToken,
        `/allBrands`
      );
      setBrandOptions(
        allBrandsOptions.map(br => ({
          label: br.displayName,
          value: br.name,
        }))
      );
    } catch (error) {
      toaster(
        toast.error(
          error,
          'There was a problem fetching all brands information.'
        )
      );
    }
  }, [authToken, toast, toaster]);

  const fetchRoles = useCallback(async () => {
    try {
      const result = await permissionsapi.get<RoleDetailsResponse[]>(
        authToken,
        `/roles/${selectedBrandSwitcherOption}`
      );
      setRoleOptions(
        result?.map(globalRole => ({
          label: globalRole?.role?.role_name,
          value: globalRole?.role.id,
        }))
      );
    } catch (error) {
      toaster(
        toast.error(error, 'There was a problem fetching the roles plans.')
      );
    }
  }, [authToken, toast, toaster, selectedBrandSwitcherOption]);

  useEffect(() => {
    if (canSeeAllBrands) {
      fetchAllBrands();
    }
    fetchRoles();
  }, [canSeeAllBrands, fetchAllBrands, fetchRoles]);

  const initialFormValues = useMemo(() => {
    return {
      ...initialUser,
      primaryBrand: initialUser?.brand && {
        label:
          brandOptions.find(br => br.value === initialUser?.brand)?.label ||
          initialUser?.brand,
        value: initialUser?.brand,
      },
      brands: initialUser?.brands?.map(
        br => brandOptions.filter(l => l.value === br)[0]
      ),
      role: initialUser?.role_id && {
        label: initialUser?.role_name,
        value: initialUser?.role_id,
      },
    };
  }, [brandOptions, initialUser]);

  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = useCallback(
    async (formValues: FormState) => {
      if (!formValues) return;
      setIsSaving(true);
      let selectedBrandsForUser = formValues.brands.map(br => br.value);
      if (formValues.primaryBrand) {
        selectedBrandsForUser = selectedBrandsForUser.filter(
          br => br !== formValues.primaryBrand.value
        );
        selectedBrandsForUser.unshift(formValues.primaryBrand.value);
      }
      if (!isEditMode) {
        const generatedPassword = generatePassword();
        try {
          await coreapi.post<User>(authToken, `/user`, {
            name: formValues.name,
            email: formValues.email,
            brands: selectedBrandsForUser,
            password: generatedPassword,
            password_confirmation: generatedPassword,
            system_admin: true,
            mfa_enabled: formValues.mfa_checked,
            role_id: formValues.role?.value,
            send_new_connect_user_email: true, // PHO-2328
            link_magic_merchant: true,
          });
          setIsOpen(false);
          fetchUsers();
        } catch (error) {
          toaster(toast.error(error, `There was a problem creating the user.`));
        } finally {
          setIsSaving(false);
        }
      } else {
        try {
          await coreapi.put<User>(authToken, `/user/${formValues?.id}`, {
            name: formValues.name,
            email: formValues.email,
            brands: selectedBrandsForUser,
            system_admin: true,
            role_id: formValues.role?.value,
          });
          setIsOpen(false);
          getUser();
        } catch (error) {
          toaster(toast.error(error, `There was a problem updating the user.`));
        } finally {
          setIsSaving(false);
        }
      }
    },
    [authToken, fetchUsers, getUser, isEditMode, setIsOpen, toast, toaster]
  );

  const readOnly = useMemo(
    () => isEditMode && initialUser?.brand !== selectedBrandSwitcherOption,
    [initialUser, isEditMode, selectedBrandSwitcherOption]
  );

  const isAbleToSwitchBrand = useMemo(
    () => userBrands.find(br => br.name === initialUser?.brand),
    [initialUser, userBrands]
  );

  const handleSwitch = () => {
    if (isAbleToSwitchBrand)
      dispatch(updateSelectedBrandSwitcherOption(initialUser?.brand));
  };

  return (
    <BaseModal
      style={modalWidthClass}
      title="Add Team Member"
      onClose={() => {
        setIsOpen(false);
      }}
      isOpen={isOpen}
    >
      <StyledHeader
        title={isEditMode ? 'Edit Team Member' : 'Add Team Member'}
        onClose={() => setIsOpen(false)}
      />

      <StyledContent>
        <FinalForm<FormState>
          onSubmit={handleSubmit}
          initialValues={initialFormValues}
        >
          {({ handleSubmit, form, hasValidationErrors, dirty, values }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                {readOnly && (
                  <InfoSection>
                    <Icon icon={faInfoCircle as IconProp} />
                    <StyledText
                      fontSize={'14px'}
                      fontHeight={'20px'}
                      fontWeight={'700'}
                    >
                      User can only be updated by admins of{' '}
                      <StyledBrand
                        isClickable={isAbleToSwitchBrand}
                        onClick={handleSwitch}
                      >
                        {initialUser?.brand}
                      </StyledBrand>
                    </StyledText>
                  </InfoSection>
                )}

                <Field name="name" validate={validateName}>
                  {props => (
                    <FieldWrap>
                      <TextField
                        {...props.input}
                        className="user-name"
                        placeholder="Name"
                        error={props.meta.dirty && !!props.meta.error}
                        helperText={props.meta.dirty && props.meta.error}
                        label={'Name'}
                        disabled={readOnly}
                      />
                    </FieldWrap>
                  )}
                </Field>

                <Field
                  name="email"
                  validate={value =>
                    checkEmail(value, initialUser?.email, authToken)
                  }
                  debouncedValidate
                >
                  {props => (
                    <FieldWrap>
                      <TextField
                        {...props.input}
                        className="user-email"
                        placeholder="Email"
                        error={props.meta.dirty && !!props.meta.error}
                        helperText={props.meta.dirty && props.meta.error}
                        label={'Email'}
                        type="email"
                        disabled={readOnly}
                      />
                    </FieldWrap>
                  )}
                </Field>

                <Field name="role">
                  {props => (
                    <FieldWrap>
                      <Select
                        {...props.input}
                        label="Role"
                        name="role"
                        menuPosition="fixed"
                        placeholder="Select a role"
                        options={roleOptions}
                        menuShouldScrollIntoView={true}
                        isDisabled={readOnly}
                      />
                    </FieldWrap>
                  )}
                </Field>

                <Field name="brands" validate={validateBrands}>
                  {props => (
                    <FieldWrap>
                      <Select
                        {...props.input}
                        label="Brand"
                        name="brands"
                        isMulti={true}
                        menuPosition="fixed"
                        placeholder="Select Brand"
                        options={brandOptions}
                        menuShouldScrollIntoView={true}
                        isDisabled={readOnly}
                      />
                    </FieldWrap>
                  )}
                </Field>
                {values?.brands?.length > 1 && (
                  <Field name="primaryBrand">
                    {props => (
                      <FieldWrap>
                        <Select
                          {...props.input}
                          label="Primary Brand"
                          name="primaryBrand"
                          menuPosition="fixed"
                          placeholder="Select Brand"
                          options={values.brands}
                          menuShouldScrollIntoView={true}
                          isDisabled={readOnly}
                        />
                      </FieldWrap>
                    )}
                  </Field>
                )}

                {!isEditMode && (
                  <>
                    <FieldWrapCustom>
                      <FormDivider />
                    </FieldWrapCustom>
                    <Field name="mfa_checked" type="checkbox">
                      {props => (
                        <>
                          <FieldWrap>
                            <WrapperText>Additional Options</WrapperText>
                            <Checkbox
                              {...props.input}
                              label="Enable Multi-Factor Authentication"
                            />
                          </FieldWrap>
                        </>
                      )}
                    </Field>
                  </>
                )}
                <StyledModalFooter>
                  <SecondaryButton
                    onClick={e => {
                      e.preventDefault();
                      setIsOpen(false);
                      form.reset();
                    }}
                  >
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    type="submit"
                    disabled={
                      isEditMode
                        ? !dirty || hasValidationErrors
                        : hasValidationErrors
                    }
                    loading={isSaving}
                  >
                    {isEditMode ? 'Save' : 'Add'}
                  </PrimaryButton>
                </StyledModalFooter>
              </form>
            );
          }}
        </FinalForm>
      </StyledContent>
    </BaseModal>
  );
};
