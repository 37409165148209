import React, { useState } from 'react';
import { TableSectionV3 } from '../styles/table-section-component-v3';
import { Text } from '@fattmerchantorg/truffle-components';
import { StatementV3Props } from '../util/statement-v3-utils';
import {
  WidgetWrapper,
  FlexibleColumns,
  WidgetHeader,
  DashboardWidget,
  WidgetTitle,
} from '../styles/dashboard-styles';
import styled from 'styled-components';
import currency from 'currency.js';
import { useAsyncEffect, useAuthToken } from '../../../../hooks';
import { GetFeeStatementsCardProcessingVolumesResponse } from '@fattmerchantorg/types-omni';
import { queryapi } from '../../../../api';
import {
  LoadingState,
  useStatementLoadingState,
} from '../../../../context/statements';
import { TextContainer } from '../../components';

const StyledWidgetWrapper = styled(WidgetWrapper)`
  position: relative;
  z-index: 1;
  padding: 8px 16px;
  border: 1px solid ${props => props.theme.colors.core.gray[800].hex};
  background-color: ${props => props.theme.colors.core.gray[900].hex};
`;

const WidgetItemsContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;

  div {
    &:not(:first-child) {
      padding: 0px 0px 0px 8px;
    }
  }
`;

const CardBrandsContainer = styled(WidgetItemsContainer)`
  grid-template-columns: auto auto auto auto auto;
`;

const Title = styled(WidgetTitle)`
  font-size: 10px;
  font-weight: 700;
  line-height: 18px;
`;

const WidgetFlexibleColumns = styled(FlexibleColumns)`
  padding: 0px 0px 16px 0px;
`;

const WidgetItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const WidgetItemSecondaryText = styled(Text)`
  padding: 0px 0px 8px 0px;
  font-size: 12px;
  font-weight: 700;
  color: ${props => props.theme.white};
`;

const Header = styled(WidgetHeader)`
  margin: 0px 0px 10px;
`;

const AmountText = styled(Text)`
  font-size: 18px;
  padding: 0px 0px 8px 0px;
  font-weight: 700;
  color: ${props => props.theme.colors.core.green[400].hex};
`;

const NoDataText = styled.div`
  font-size: 14px;
  color: ${props => props.theme.colors.core.gray[500].hex};
  text-align: center;
  padding: 16px 0px;
`;

export const CardProcessingVolumeSection: React.FC<
  StatementV3Props
> = props => {
  const { startDate, endDate } = props;
  const authToken = useAuthToken();

  const [data, setData] =
    useState<GetFeeStatementsCardProcessingVolumesResponse['data']>(null);
  const { cardProcessingVolumesLoading, setCardProcessingVolumesLoading } =
    useStatementLoadingState();

  useAsyncEffect(async () => {
    if (
      cardProcessingVolumesLoading === LoadingState.Loading &&
      authToken &&
      startDate &&
      endDate
    ) {
      try {
        const query = { startDate, endDate };
        const data =
          await queryapi.get<GetFeeStatementsCardProcessingVolumesResponse>(
            authToken,
            'statement/v3/volumes/card-processing',
            query
          );

        setData(data.data);
        setCardProcessingVolumesLoading(LoadingState.Completed);
      } catch (error) {
        setCardProcessingVolumesLoading(LoadingState.Failed);
      }
    }
  }, [cardProcessingVolumesLoading, authToken, startDate, endDate]);

  const paymentMethodsDebit = data?.groupByPaymentMethod?.find(
    paymentMethod =>
      paymentMethod?.bin_type === 'DEBIT' && paymentMethod?.method === 'card'
  );

  const paymentMethodsCredit = data?.groupByPaymentMethod?.find(
    paymentMethod =>
      paymentMethod?.bin_type === 'CREDIT' && paymentMethod?.method === 'card'
  );

  const paymentMethodsAch = data?.groupByPaymentMethod?.find(
    paymentMethod => paymentMethod?.method === 'bank'
  );

  const transactionTypesCardPresent = data?.groupByTransactionType?.find(
    transactionTypes =>
      transactionTypes?.method === 'card' &&
      transactionTypes?.is_card_present === 1
  );

  const transactionTypesCardNotPresent = data?.groupByTransactionType?.find(
    transactionTypes =>
      transactionTypes?.method === 'card' &&
      transactionTypes?.is_card_present === 0
  );

  const transactionTypesAch = data?.groupByTransactionType?.find(
    transactionTypes => transactionTypes?.method === 'bank'
  );

  const amexCardBrand = data?.groupByCardBrand?.find(
    cardBrand => cardBrand?.card_type === 'amex'
  );

  const discoverCardBrand = data?.groupByCardBrand?.find(
    cardBrand => cardBrand?.card_type === 'discover'
  );

  const mastercardCardBrand = data?.groupByCardBrand?.find(
    cardBrand => cardBrand?.card_type === 'mastercard'
  );

  const visaCardCardBrand = data?.groupByCardBrand?.find(
    cardBrand => cardBrand?.card_type === 'visa'
  );

  const otherCardCardBrand = data?.groupByCardBrand?.find(
    cardBrand => cardBrand?.card_type === 'other'
  );

  if (cardProcessingVolumesLoading !== LoadingState.Completed || !data)
    return null;

  return (
    <TableSectionV3>
      <TextContainer fontWeight="bold" paddingBottom="8px">
        <Text as="h5">Card Processing Volume Summary</Text>
      </TextContainer>

      <WidgetFlexibleColumns>
        <StyledWidgetWrapper>
          <DashboardWidget data-testid="payment-methods-widget">
            <Header>
              <Title>PAYMENT METHODS</Title>
            </Header>
            <WidgetItemsContainer>
              <WidgetItem>
                <AmountText as="span">
                  {paymentMethodsDebit?.total_amt
                    ? currency(paymentMethodsDebit?.total_amt).format()
                    : currency(0).format()}
                </AmountText>
                <WidgetItemSecondaryText as="span">
                  Debit Card
                </WidgetItemSecondaryText>
              </WidgetItem>
              <WidgetItem>
                <AmountText as="span">
                  {paymentMethodsCredit?.total_amt
                    ? currency(paymentMethodsCredit?.total_amt).format()
                    : currency(0).format()}
                </AmountText>
                <WidgetItemSecondaryText as="span">
                  Credit Card
                </WidgetItemSecondaryText>
              </WidgetItem>
              <WidgetItem>
                <AmountText as="span">
                  {paymentMethodsAch?.total_amt
                    ? currency(paymentMethodsAch?.total_amt).format()
                    : currency(0).format()}
                </AmountText>
                <WidgetItemSecondaryText as="span">ACH</WidgetItemSecondaryText>
              </WidgetItem>
            </WidgetItemsContainer>
          </DashboardWidget>
        </StyledWidgetWrapper>
        <StyledWidgetWrapper>
          <DashboardWidget data-testid="transactions-type-widget">
            <Header>
              <Title>TRANSACTION TYPES</Title>
            </Header>
            <WidgetItemsContainer>
              <WidgetItem>
                <AmountText as="span">
                  {transactionTypesCardPresent?.total_amt
                    ? currency(transactionTypesCardPresent?.total_amt).format()
                    : currency(0).format()}
                </AmountText>
                <WidgetItemSecondaryText as="span">
                  Card Present
                </WidgetItemSecondaryText>
              </WidgetItem>
              <WidgetItem>
                <AmountText as="span">
                  {transactionTypesCardNotPresent?.total_amt
                    ? currency(
                        transactionTypesCardNotPresent?.total_amt
                      ).format()
                    : currency(0).format()}
                </AmountText>
                <WidgetItemSecondaryText as="span">
                  Card Not Present
                </WidgetItemSecondaryText>
              </WidgetItem>
              <WidgetItem>
                <AmountText as="span">
                  {transactionTypesAch?.total_amt
                    ? currency(transactionTypesAch?.total_amt).format()
                    : currency(0).format()}
                </AmountText>
                <WidgetItemSecondaryText as="span">ACH</WidgetItemSecondaryText>
              </WidgetItem>
            </WidgetItemsContainer>
          </DashboardWidget>
        </StyledWidgetWrapper>
      </WidgetFlexibleColumns>

      <WidgetFlexibleColumns>
        <StyledWidgetWrapper>
          <DashboardWidget data-testid="card-brands-widget">
            <Header>
              <Title>CARD BRANDS</Title>
            </Header>

            {data?.groupByCardBrand && data?.groupByCardBrand.length ? (
              <CardBrandsContainer>
                <WidgetItem>
                  <AmountText as="span">
                    {amexCardBrand?.total_amt
                      ? currency(amexCardBrand?.total_amt).format()
                      : currency(0).format()}
                  </AmountText>
                  <WidgetItemSecondaryText as="span">
                    AMEX
                  </WidgetItemSecondaryText>
                </WidgetItem>
                <WidgetItem>
                  <AmountText as="span">
                    {discoverCardBrand?.total_amt
                      ? currency(discoverCardBrand?.total_amt).format()
                      : currency(0).format()}
                  </AmountText>
                  <WidgetItemSecondaryText as="span">
                    Discover
                  </WidgetItemSecondaryText>
                </WidgetItem>
                <WidgetItem>
                  <AmountText as="span">
                    {mastercardCardBrand?.total_amt
                      ? currency(mastercardCardBrand?.total_amt).format()
                      : currency(0).format()}
                  </AmountText>
                  <WidgetItemSecondaryText as="span">
                    Mastercard
                  </WidgetItemSecondaryText>
                </WidgetItem>
                <WidgetItem>
                  <AmountText as="span">
                    {visaCardCardBrand?.total_amt
                      ? currency(visaCardCardBrand?.total_amt).format()
                      : currency(0).format()}
                  </AmountText>
                  <WidgetItemSecondaryText as="span">
                    Visa
                  </WidgetItemSecondaryText>
                </WidgetItem>
                <WidgetItem>
                  <AmountText as="span">
                    {otherCardCardBrand?.total_amt
                      ? currency(otherCardCardBrand?.total_amt).format()
                      : currency(0).format()}
                  </AmountText>
                  <WidgetItemSecondaryText as="span">
                    Other
                  </WidgetItemSecondaryText>
                </WidgetItem>
              </CardBrandsContainer>
            ) : (
              <NoDataText>No Cards Processed</NoDataText>
            )}
          </DashboardWidget>
        </StyledWidgetWrapper>
      </WidgetFlexibleColumns>
    </TableSectionV3>
  );
};
