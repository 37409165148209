import React from 'react';

export default errors => {
  return (
    <div>
      <strong>Errors:</strong>
      {errors.map((error, index) => (
        <ul key={`${error.code}-${index}`}>
          <li>
            <strong>Code:</strong> {error.code}
          </li>
          <li style={{ marginLeft: '15px' }}>
            <strong>Field:</strong> {error.field}
          </li>
          <li style={{ marginLeft: '15px' }}>
            <strong>Description:</strong> {error.text}
          </li>
        </ul>
      ))}
    </div>
  );
};
