import {
  Chip,
  Drawer,
  Icon,
  Modal,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  Table,
  TextField,
  ToggleSelector,
} from '@fattmerchantorg/truffle-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const ContentSeparator = styled.div`
  display: flex;
`;

export const SettingsHeaderContent = styled.div`
  box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 8px;
  padding: 24px 24px 8px 24px;
  display: flex;
  flex-direction: column;
`;
export const SettingsHeaderBreadcrumbs = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  a {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.status.blue[500].hex};

    svg {
      margin-right: 8px;
    }

    &:hover {
      opacity: 0.7;
    }
  }
`;
export const SettingsHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SettingsHeaderTeamContent = styled.div`
  box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 8px;
  padding: 24px 24px 8px 24px;
`;
export const SettingsHeaderContentTitle = styled.div`
  flex-grow: 1;
`;
export const SettingsHeaderContentTitleText = styled.div`
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 0;
  align-items: center;
`;
export const SettingsHeaderContentActions = styled.div`
  display: flex;
  & * + * {
    margin-left: 16px;
  }
`;
export const SettingsSubheader = styled.div`
  font-size: 14px;
  padding-top: 4px;
  padding-bottom: 12px;
`;
export const SettingsSectionHeader = styled.div`
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.core.gray[600].hex};
  margin-bottom: 8px;
  line-height: 24px;
`;
export const SettingsSectionContent = styled.div`
  & + & {
    margin-top: 16px;
  }

  label {
    margin-top: 8px;
  }
`;

export const SettingsSectionInfoText = styled.p`
  font-size: 12px;
  line-height: 18px;
`;
export const SettingsSection = styled.div`
  & ${SettingsSectionContent} + ${SettingsSectionHeader} {
    margin-top: 53px;
  }
`;
export const FormLabel = styled.label`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
  margin-top: 24px;
  font-weight: bold;

  &:first-of-type {
    margin-top: 0;
  }
`;
export const DividingLine = styled.hr`
  border-top: 1px solid ${({ theme }) => theme.colors.core.gray[600].hex};
  margin-top: 0;
  margin-bottom: 16px;
`;
export const VerticalDividingLine = styled.div`
  width: 1px;
  background-color: ${({ theme }) => theme.colors.core.gray[600].hex};
`;

export const PageContent = styled.div`
  width: 100%;
`;
export const MainContent = styled.div`
  width: 100%;
  padding: 24px;
`;
export const InputSubheader = styled.div`
  color: ${({ theme }) => theme.colors.core.gray[100].hex};
  font-size: 14px;
  padding-bottom: 8px;
`;
export const TextFieldWrapper = styled.div`
  max-width: 320px;
`;

export const WrapperMarginTop = styled.div`
  margin-top: 24px;
`;

export const ColorDiv = styled.div`
  width: 17px;
  height: 17px;
  background-color: ${({ color }) => color};
`;

export const TextFieldWrapperColor = styled.div`
  max-width: 124px;
  position: relative;
`;

export const StyledSpanError = styled.span`
  position: absolute;
  font-weight: 400;
  font-size: 12px;
  margin-top: 3px;
  width: 200px;
  color: ${({ theme }) => theme.colors.status.red[500].hex};
`;

export const ColorTextField = styled(TextField)`
  text-align: center;
`;

export const StyledSpanInfo = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  width: 252px;
  height: 18px;
  color: ${({ theme }) => theme.colors.core.gray[100].hex};
  margin-top: 8px;
`;

export const StyledFileUploader = styled(TextField)`
  text-align: center;
  color: ${({ theme }) => theme.colors.core.gray[200].hex};
  max-width: 124px;
`;

export const CustomButton = styled.button`
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 10px;
  min-width: 123px;
  max-width: 100%;
  height: 35px;
  background: ${({ theme }) => theme.colors.core.gray[800].hex};
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.core.gray[600].hex};
`;

export const StyledPlaceHolder = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme, isUploaded }) =>
    isUploaded ? 'white' : theme.colors.core.gray[200].hex};
`;

export const StyledLogoContainer = styled.div`
  width: 400px;
  height: 149px;
  padding-top: 7px;
  padding-bottom: 5px;
  border-right: 1px solid;
  border-color: ${({ theme }) => theme.colors.core.gray[600].hex};
`;

export const LogoContainer = styled.div`
  padding-left: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoPreviewContainer = styled.div`
  width: ${({ small }) => (small ? '48px' : '215px')};
  height: ${({ small }) => (small ? '48px' : '75px')};
  border: 1px dashed;
  border-color: ${({ theme }) => theme.colors.core.gray[100].hex};
  background: ${({ color }) => color};
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: white;
`;

export const Wrapper = styled.div`
  max-width: ${({ maxWidth }) => maxWidth};
`;

export const LogoImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const CenterVertical = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1px;
`;

export const SpanInfo = styled.div`
  color: ${({ theme }) => theme.colors.core.gray[400].hex};
  font-size: 12px;
  line-height: 18px;
`;

export const StyledFontAwesomeImgIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.core.gray[400].hex};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WrapperDiv = styled.div`
  width: 100%;
  margin-top: 15px;
`;

export const StyledToggleSelector = styled(ToggleSelector)`
  display: flex;
  height: 33px;
  > div {
    border-color: #435e70;
  }
`;

export const PermissionsRow = styled.div`
  display: flex;
  height: 32px;
  align-items: center;
  padding-left: 6px;
`;

export const StyledTextField = styled(TextField)`
  + div {
    color: red;
  }

  min-width: 418px;
  width: 31px;

  + button {
    top: 42%;
  }
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.colors.core.green[400].hex};
  padding: 6px 16px;
  box-sizing: border-box;
  line-height: 21px;
  width: 173px;
  height: 33px;
  :hover {
    background-color: ${({ theme }) => theme.colors.core.green[400].hex};
  }
`;

export const WrapperText = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 700;
`;

export const StyledBoxShadow = styled.div`
  box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 8px;
  padding-bottom: 6px;
`;

export const StyledModal = styled(Modal)`
  > div:first-child {
    > div {
      padding-top: 14px;
      padding-left: 16px;
      padding-bottom: 10px;
      height: 48px;
    }
  }
  h1 {
    font-size: 16px;
    line-height: 24px;
    font-family: 'Roboto';
    font-weight: 700;
  }
`;

export const FieldWrap = styled.div`
  margin-bottom: 9px;
  width: 237px;
`;

export const FieldWrapFullWidth = styled.div`
  margin-bottom: 9px;
  width: 100%;
  padding-right: 16px;
`;

export const FieldWrapCustom = styled.div`
  margin-bottom: 9px;
  margin-top: 20px;
`;

export const modalWidthClass = {
  overlay: {
    zIndex: 100,
  },
  content: {
    width: '480px',
    gridTemplateRows: '48px 1fr',
    overflow: 'auto',
  },
};

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 13px;
`;

export const BlueIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.core.blue[500].hex};
`;

export const StyledContent = styled.div`
  padding-left: 16px;
  padding-top: 16px;
`;

export const StyledModalFooter = styled(ModalFooter)`
  position: sticky;
  bottom: 0;
  z-index: 4;
  background-color: ${({ theme }) => theme.colors.core.gray[800].hex};
  display: flex;
  justify-content: flex-end;
  gap: 4px;
  > *:first-child {
    margin-right: 16px;
  }
`;

export const StyledHeader = styled(ModalHeader)`
  position: sticky;
  top: 0;
  z-index: 4;
  background-color: ${({ theme }) => theme.colors.core.gray[800].hex};

  h1 {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const StyledDangerPrimaryButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.colors.status.red[500].hex};
  :hover {
    background-color: ${({ theme }) => theme.colors.status.red[500].hex};
  }
`;

export const BottomBar = styled.div`
  background-color: ${({ theme }) => theme.colors.core.gray[800].hex};
  z-index: 5;
  box-shadow: -2px -5px 6px 0px rgba(0, 0, 0, 0.12);
  padding: 16px;
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

export const StyledDrawer = styled(Drawer)`
  max-width: 520px;
  min-width: 520px;
  background-color: ${({ theme }) => theme.colors.core.gray[800].hex};
  > *:first-child {
    background-color: ${({ theme }) => theme.colors.core.gray[800].hex};
  }
`;

export const DrawerCss = {
  borderRadius: 0,
  padding: 0,
  overflowX: 'hidden',
  display: 'flex',
  flexDirection: 'column',
};

export const StyledText = styled.div`
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ fontHeight }) => fontHeight};
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

export const StyledHeaderContainer = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export const StyledBrandSection = styled.div`
  padding-left: 16px;
  padding-bottom: 4px;
  width: 100%;
  min-height: 70px;
`;

export const StyledInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledStatus = styled.div`
  padding-top: 8px;
  margin-left: auto;
`;

export const StyledEmail = styled.div`
  font-size: 14px;
  line-height: 21px;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.status.blue[500].hex};
`;
export const BrandChip = styled(Chip)`
  color: black;
  margin-right: 4px;
  margin-bottom: 5px;
  background-color: ${({ theme }) => theme.colors.core.green[400].hex};
`;

export const RedIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.status.red[500].hex};
`;

export const GreenIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.status.green[500].hex};
`;

export const StyledSecurityInfo = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  gap: 6px;
  align-items: center;
`;

export const StyledEmailContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
`;

export const ColorPickerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 175px;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const PricingHeader = styled.div`
  color: ${({ theme }) => theme.colors.core.gray[200].hex};
  font-size: 36px;
`;

export const BorderButtom = styled.hr`
  border-top: 1px solid ${({ theme }) => theme.colors.core.gray[600].hex};
  margin-top: 0;
  margin-bottom: 6px;
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.core.gray[600].hex};
  margin-bottom: 12px;
  padding-bottom: 5px;
`;

export const StyledTable = styled(Table)`
  margin-bottom: 40px;
`;

export const IconButton = styled.button`
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.core.white[0].hex};
`;

export const StarIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  padding-bottom: 1px;
  margin-left: 4px;
  color: ${({ theme, isHovered, isSelected }) =>
    isSelected
      ? theme.colors.status.yellow[500].hex
      : isHovered
      ? theme.colors.status.yellow[500].hex
      : theme.colors.core.gray[500].hex};
`;

export const StyledLabelText = styled.label`
  color: ${({ theme }) => theme.colors.core.gray[200].hex};
  font-size: 14px;
  margin-bottom: 0px;
`;

export const FlexRow = styled.div`
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledHideIcon = styled.div`
  span {
    svg {
      color: ${({ theme }) => theme.colors.status.blue[500].hex};
      margin-right: 4px;
    }
  }
  color: ${({ theme }) => theme.colors.status.blue[500].hex};
  font-size: 14px;
  font-weight: 700;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const ClickedArea = styled.label`
  cursor: pointer;
  width: 100%;
`;
