import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Icon } from '@fattmerchantorg/truffle-components';
import { ReportEntity } from './ExternalVerification.types';
import { ButtonSpinner } from '../../../shared';

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  right: 16px;
`;

const StatusWrapper = styled.span`
  background: #062333;
  border: 1px solid #627684;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 0px 8px;
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
`;
const Success = styled(StatusWrapper)`
  color: #28cb35;
`;

const Failed = styled(StatusWrapper)`
  color: #ff4646;
`;

interface AutoVerificationStatusProps {
  autoverificationResult?: 'PASS' | 'FAIL' | null;
  runningVerification: ReportEntity | null;
}

export const AutoVerificationStatus: FunctionComponent<
  AutoVerificationStatusProps
> = props => {
  const { autoverificationResult, runningVerification } = props;
  return (
    <FlexBox data-testid={'auto-verification-status-wrapper'}>
      <span>Auto Verification Status</span>
      {['business', 'person'].includes(runningVerification) ? (
        <div data-testid={'auto-verification-status-spinner'}>
          <ButtonSpinner />
        </div>
      ) : autoverificationResult === 'PASS' ? (
        <Success data-testid={'auto-verification-pass-status'}>
          <Icon icon={['fas', 'check']} fixedWidth /> Pass
        </Success>
      ) : (
        <Failed data-testid={'auto-verification-fail-status'}>
          <Icon icon={['fas', 'times']} fixedWidth /> Fail
        </Failed>
      )}
    </FlexBox>
  );
};
