import {
  TicketsAction,
  TicketsActionType,
  TicketsCountType,
} from './Tickets.types';

export function updateTicketsCount(
  ticketsCount: TicketsCountType | null
): TicketsAction {
  return {
    type: TicketsActionType.SWITCH_BRAND,
    payload: ticketsCount,
  };
}
