import React, { useState } from 'react';
import styled from 'styled-components';
import { Text } from '@fattmerchantorg/truffle-components';
import { CollectionResponse } from '@fattmerchantorg/types-omni';
import { Link as RRDLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import currency from 'currency.js';
import { TableSectionV3 } from '../styles/table-section-component-v3';
import { StatementV3Props } from '../util/statement-v3-utils';
import {
  LoadingState,
  useStatementLoadingState,
  useStatementSummaryState,
} from '../../../../context/statements';
import { useAsyncEffect, useAuthToken } from '../../../../hooks';
import { queryapi } from '../../../../api';
import { format } from '../../../../util/date.util';
import { TableNullState, linkStyles } from '../styles';
import { TextContainer } from '../../components';

export type DepositsSummary = {
  total_transactions: number | null;
  credit_amount: number | null;
  debit_amount: number | null;
};

export type DepositTransaction = {
  batched_at: string;
  batch_id: string;
  total_transactions: number;
  deposit_amount: number | null;
  withdrawal_amount: number | null;
  is_deposit_rejected: number;
  is_withdrawal_rejected: number;
  has_multiple_deposits: number;
  has_multiple_withdrawals: number;
};

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Link = styled(RRDLink)`
  ${linkStyles}
` as typeof RRDLink;

const Table = styled(TableSectionV3)`
  /** Override the global styles from _anchor.scss for the first section links */
  > table > tbody > tr > td:first-child {
    > a {
      color: ${props => props.theme.colors.status.blue['500'].hex};
      text-decoration: underline;
      cursor: pointer;
    }
  }
  /** The second row of the table should be aligned left */
  > table > tbody > tr > td,
  > table > thead > tr > th {
    &:nth-child(2) {
      text-align: left;
    }
  }

  > table > tbody > tr > td.negativeAmount,
  > table > tfoot > tr > td.negativeAmount {
    color: ${props => props.theme.colors.status.red['500'].hex};
  }
`;

export const DepositsSection: React.FC<StatementV3Props> = props => {
  const { startDate, endDate, merchantId } = props;
  const authToken = useAuthToken();
  const { updateTotalDeposited } = useStatementSummaryState();
  const { depositsLoading, setDepositsLoading } = useStatementLoadingState();

  const [data, setData] = useState<DepositTransaction[]>();
  const [summary, setSummary] = useState<DepositsSummary>();

  useAsyncEffect(async () => {
    if (
      depositsLoading === LoadingState.Loading &&
      authToken &&
      startDate &&
      endDate
    ) {
      try {
        const query = { startDate, endDate };
        const [dataResponse, summaryResponse] = await Promise.all([
          queryapi.get<CollectionResponse<DepositTransaction>>(
            authToken,
            'settlements',
            { ...query, perPage: 500, page: 1 } // NOTE: 500 is the chosen upper bound limit as we’ve seen from research that merchants don’t have this amount of settlement records in a month, so it’s a safe upper bound limit (See notes here https://fattmerchant.atlassian.net/browse/PHO-2975?focusedCommentId=167312).
          ),
          queryapi.get<DepositsSummary>(
            authToken,
            'settlements/summary',
            query
          ),
        ]);

        dataResponse && setData(dataResponse.data);
        if (summaryResponse && summaryResponse[0]) {
          const summary = summaryResponse[0];
          setSummary(summary);
          updateTotalDeposited(
            currency(+summary?.credit_amount).subtract(+summary?.debit_amount)
              .value
          );
        }
        setDepositsLoading(LoadingState.Completed);
      } catch (error) {
        setDepositsLoading(LoadingState.Failed);
      }
    }
  }, [depositsLoading, authToken, startDate, endDate]);

  if (depositsLoading !== LoadingState.Completed || !data || !summary)
    return null;

  const totalAmountDeposited = currency(+summary?.credit_amount).subtract(
    Math.abs(summary?.debit_amount)
  ).value;

  return (
    <Table>
      <SectionHeader>
        <TextContainer fontWeight="bold" paddingBottom="8px">
          <Text as="h5">Deposits</Text>
        </TextContainer>
        <Link
          target="_blank"
          to={`/merchant/${merchantId}/deposits?startDate=${startDate}&endDate=${endDate}`}
        >
          <FontAwesomeIcon icon={['far', 'angle-right']} />
        </Link>
      </SectionHeader>
      <table data-testid="statementV3-deposits">
        <colgroup>
          <col style={{ width: '5%' }} />
          <col style={{ width: '15%' }} />
          <col style={{ width: '70%' }} />
        </colgroup>
        <thead>
          <tr>
            <th>Date</th>
            <th>ID</th>
            <th>Net Amount Deposited</th>
          </tr>
        </thead>
        <tbody>
          {data.map((datum, i) => {
            const amountDeposited = currency(+datum.deposit_amount).subtract(
              Math.abs(datum.withdrawal_amount)
            ).value;
            return (
              <tr key={i} className="tableRows">
                <td>{format(new Date(datum.batched_at), 'MMM dd')}</td>
                <td>
                  <Link
                    target="_blank"
                    to={`/merchant/${merchantId}/deposits?startDate=${startDate}&endDate=${endDate}&detailId=${datum.batch_id}`}
                  >
                    {datum.batch_id}
                  </Link>
                </td>
                <td className={amountDeposited < 0 ? 'negativeAmount' : ''}>
                  {currency(amountDeposited).format()}
                </td>
              </tr>
            );
          })}
        </tbody>
        {!!data.length && (
          <tfoot>
            <tr>
              <td>
                <strong>Total</strong>
              </td>
              <td></td>
              <td className={totalAmountDeposited < 0 ? 'negativeAmount' : ''}>
                <strong>{currency(totalAmountDeposited).format()}</strong>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
      {!data.length && <TableNullState>No Deposits</TableNullState>}
    </Table>
  );
};
