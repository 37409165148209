import get from 'lodash/get';
import axios, { AxiosRequestConfig } from 'axios';
import { getHeaders } from '../util/api.util';
import {
  CoreFinixConfigurations,
  LitleFinixConfigurations,
} from '../@types/UnderwritingStatus';
import { Auth } from '@fattmerchantorg/types-omni';
import { ProcessorValue } from '../components/underwriting/components/status/submit/SubmitApplicationForm.types';

export interface FinixApplication {
  /**
   * The unique string identifying this `Application` in the Finix API.
   * Prefixed with the letters, `"AP"`, followed by a string of random characters and digits.
   */
  id: string;
  /**
   * The human readable name of the Application in the Finix API.
   */
  name: string;
}

export const getFinixApplications = async (
  authToken: Auth['token'],
  credentialId: string | null
) => {
  let url: string = `${process.env.REACT_APP_ONBOARDING_URL}/finix/applications`;
  if (credentialId) url += `?credentialId=${credentialId}`;
  const config: AxiosRequestConfig = { headers: getHeaders(authToken) };

  try {
    const result = await axios.get<FinixApplication[]>(url, config);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getFinixMid = async (
  merchantId: string,
  authToken: Auth['token']
) => {
  const url: string = `${process.env.REACT_APP_ONBOARDING_URL}/finix/merchant/${merchantId}`;
  const config: AxiosRequestConfig = { headers: getHeaders(authToken) };

  try {
    const finixMerchant = await axios.get(url, config);

    return get(
      finixMerchant,
      'data.processor_details.merchant_ident_string',
      ''
    );
  } catch (error) {
    throw error;
  }
};

/** LITLE_V1 is "Litle" and VANTIV_V1 is "Core" */
export type FinixProcessorEnum = 'LITLE_V1' | 'VANTIV_V1';

export const provisionFinixMerchant = (
  registration: any,
  processor: FinixProcessorEnum,
  processorName: ProcessorValue,
  applicationId: string,
  credentialId: string,
  configuration: CoreFinixConfigurations | LitleFinixConfigurations,
  processingInfoType: string,
  authToken: Auth['token']
) => {
  const url: string = `${process.env.REACT_APP_ONBOARDING_URL}/finix/merchant`;
  const body: any = {
    configuration,
    registration,
    processor,
    processorName,
    applicationId,
    credentialId,
    processingInfoType,
  };
  const config: AxiosRequestConfig = { headers: getHeaders(authToken) };
  return axios.post(url, body, config);
};

export const getFinixStatus = async (finixMerchantID: string, auth: Auth) => {
  const url: string = `${process.env.REACT_APP_ONBOARDING_URL}/finix/merchant/${finixMerchantID}`;
  const config: AxiosRequestConfig = { headers: getHeaders(auth.token) };

  try {
    const result = await axios.get(url, config);
    return result.data;
  } catch (error) {
    throw error;
  }
};
