import React, { useContext, useState, useCallback } from 'react';
import styled from 'styled-components';
import Omni from '@fattmerchantorg/types-omni';
import { UnderwritingSection } from './shared/UnderwritingSection';
import { SelectedMerchantStore } from '../../../context';
import { onboardingapi } from '../../../api';
import { useAsyncEffect, useAuthToken, useToaster } from '../../../hooks';
import { formatTitleCase } from '../../../util/format.util';
import { formatDate } from '../../../util/date.util';

export interface RequiredDocumentsProps {}

const CardContent = styled.div`
  display: flex;
  width: 100%;
`;

const DocsContainer = styled.div`
  border-right: 1px solid ${({ theme }) => theme.lightNeutralOutline};
  flex-basis: 100%;
  padding: 0 16px;
  &:first-child {
    padding: 0;
  }
  &:last-child {
    border-right: 0;
  }
`;
const Title = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
`;

const UpdatedAt = styled.span`
  color: #8d9799;
  margin-left: 20px;
`;

export function RequiredDocuments(props: RequiredDocumentsProps) {
  const {
    state: { merchantId },
  } = useContext(SelectedMerchantStore);
  const authToken = useAuthToken();
  const { toaster, toast } = useToaster();

  const [registrationDocuments, setRegistrationDocuments] = useState<{
    [K in Omni.RegistrationDocumentSubject]?: Omni.RegistrationDocument[];
  }>({});

  const [cols, setCols] = useState<string[]>([]);
  const [savingKeys, setSavingKeys] = useState<string[]>([]);
  const getRegistrationDocuments = useCallback(
    async (merchantId: string, authToken: string) => {
      try {
        const r = await onboardingapi.get(
          authToken,
          `/merchant/${merchantId}/registration/documents?fill_missing=true&group_by=subject`
        );

        setRegistrationDocuments(r);

        const cols = Object.keys(r);
        setCols(cols);
      } catch (error) {
        toaster(
          toast.error(
            error,
            'There was a problem loading the registration documents.'
          )
        );
      }
    },
    [toaster, toast]
  );
  const setRegistrationDocumentIsEnabled = useCallback(
    async (r: Omni.RegistrationDocument, isEnabled: boolean) => {
      const savingKey = `${r.subject}/${r.type}`;
      setSavingKeys(s => [...s, savingKey]);

      try {
        await onboardingapi.put(
          authToken,
          `/merchant/${merchantId}/registration/documents/${r.subject}/${r.type}`,
          { is_enabled: isEnabled }
        );
      } catch (error) {
        toaster(
          toast.error(
            error,
            'There was a problem loading the registration documents.'
          )
        );
      }

      await getRegistrationDocuments(merchantId, authToken);

      setSavingKeys(s => s.filter(k => k !== savingKey));
    },
    [merchantId, authToken, getRegistrationDocuments, toaster, toast]
  );

  useAsyncEffect(() => {
    getRegistrationDocuments(merchantId, authToken);
  }, [merchantId, authToken]);

  return (
    <UnderwritingSection
      title="Required Documents"
      id="underwriting-required-documents"
    >
      {({ setToolTipContent }) => {
        setToolTipContent(
          `Documents to support failed verifications or other minimum requirements`
        );
        return (
          <CardContent>
            {cols.map(t => (
              <DocsContainer key={`cols${t}`}>
                <Title>{formatTitleCase(t.replace(/_/g, ' '))}</Title>
                {registrationDocuments[t]?.map(
                  (r: Omni.RegistrationDocument, i: number) => {
                    //const uploadedAt = getUploadedAt(r);
                    const key = `${r.subject}/${r.type}`;
                    const isSaving = savingKeys.includes(key);
                    // if we're saving this item, it means we're toggling it
                    // so invert the value
                    // otherwise, use the uninverted value
                    const isChecked = isSaving ? !r.is_enabled : !!r.is_enabled;

                    return (
                      <div key={i}>
                        <div>
                          {/* @TODO: replace with truffle Checkbox once it exposes the "id" property */}
                          <input
                            type="checkbox"
                            id={key}
                            data-testid={key}
                            disabled={isSaving}
                            checked={isChecked}
                            onChange={e =>
                              setRegistrationDocumentIsEnabled(
                                r,
                                e.target.checked
                              )
                            }
                          />
                          <label htmlFor={key} style={{ marginLeft: '8px' }}>
                            {r.title}{' '}
                          </label>
                        </div>
                        {r.files?.[0]?.created_at ? (
                          <UpdatedAt>
                            Uploaded{' '}
                            {formatDate(
                              r.files[0].created_at,
                              'MMM d, yyyy  h:mm a'
                            )}
                          </UpdatedAt>
                        ) : null}
                      </div>
                    );
                  }
                )}
              </DocsContainer>
            ))}
          </CardContent>
        );
      }}
    </UnderwritingSection>
  );
}
