import { CollectionResponse } from '@fattmerchantorg/types-omni';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { catanapi } from '../../api/catan';
import styled from 'styled-components';
import { API, DB } from '@fattmerchantorg/types-engine';
import { cleanCollectionQuery, CollectionQuery, PageHeader } from '../shared';
import { SelectedMerchantStore } from '../../context';
import { generateLoadingRows } from '../../util/datatable.util';
import { useReserveFormSubmission } from './useReserveFormSubmission';
import {
  Icon,
  RadioButton,
  SmallPrimaryButton,
  SmallSecondaryButton,
  StatusPill,
  Text,
  TextField,
  TextLink,
  Tooltip,
} from '@fattmerchantorg/truffle-components';
import { useHistory, useLocation } from 'react-router-dom';
import { parse, stringify } from '../../util/api.util';
import { formatCurrency } from '../../util';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { StatusPillStatus } from '@fattmerchantorg/truffle-components/dist/StatusPill/StatusPill';
import { faCaretLeft, faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { Field, Form } from 'react-final-form';
import {
  sendOpenSelected,
  useAsyncEffect,
  useAuthToken,
  useModalReducer,
  useRouteMatchMerchantId,
  useToaster,
} from '../../hooks';
import {
  ReserveInfoFieldsErrors,
  ReserveInfoFieldsValues,
} from './ReserveInfoForm.Types';
import * as reserveInfoFormUtil from './ReserveInfoForm.util';
import { JournalDataTable } from './JournalDataTable';
import { formatDate, toISOString } from '../../util/date.util';
import {
  EditModalContext,
  initialEditModalState,
  ReleaseReserveModal,
} from './ReleaseReserveModal';
import get from 'lodash/get';

interface getJournal {
  (query: CollectionQuery): Promise<CollectionResponse<API.Journal>>;
}

const ReserveInfoContainer = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  position: absolute;
  width: 100%;
  height: 82px;
  left: 100px;
  top: 260px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }
`;
const JournalTableContainer = styled.div`
  position: absolute;
  width: 96%;
  top: 400px;
  left: 100px;
`;
const ReserveType = styled.div`
  position: static;
  width: 150px;
  height: 76px;
  left: 216px;
  top: 0px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;
const ReserveAmmount = styled.div`
  position: static;
  width: 157px;
  height: 82px;
  top: 0px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  //line-height: 20px;
  margin: 0px 24px;
  span {
    height: 1em;
    display: inline-block;
  }
`;

const LineBreak = styled.div`
  position: static;
  width: 100px;
  height: 0px;
  left: 159px;
  top: 80px;
  align-self: center;
  /* Gray/600 */

  border: 1px solid #435e70;
  transform: rotate(-90deg);
`;

const LineBreakTwo = styled.div`
  position: static;
  width: 100px;
  height: 0px;
  left: 493.33px;
  top: 80px;
  align-self: center;
  /* Gray/600 */

  border: 1px solid #435e70;
  transform: rotate(-90deg);
`;

const LineBreakThree = styled.div`
  position: static;
  width: 100px;
  height: 0px;
  left: 620.33px;
  top: 80px;
  align-self: center;
  /* Gray/600 */

  border: 1px solid #435e70;
  transform: rotate(-90deg);
`;

const WithdrawPercent = styled.div`
  position: static;
  width: 105px;
  height: 82px;
  left: 517.33px;
  top: 0px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  span {
    height: 1em;
    display: inline-block;
  }
`;
const InfoForRes = styled.div`
  position: static;
  width: 400px;
  height: 48px;
  left: 644.33px;
  top: 100px;
`;

const BalanceTarget = styled.div`
  position: static;
  width: auto;
  block-size: fit-content;
  margin-right: inherit;
  display: inline-block;
  left: 0px;
  top: 10px;
  height: 100%;
  white-space: nowrap;
  overflow-x: initial;
  .resrve-not-met-text {
    font-weight: bold;
    color: #f8dc3d;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
  }
  .resrve-met-text {
    font-weight: bold;
    color: #28cb35;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
  }
`;

const ButtonContainer = styled.div`
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85px;
`;

const Divider = styled.div`
  width: 10px;
  height: auto;
  display: inline-block;
`;
const Value = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  align-items: baseline;
`;

const TitleBar = styled.div`
  /* title bar */
  grid-area: tt;

  /* Auto layout */
  float: left;
  justify-content: space-between;
  flex-direction: row;
  padding: 16px 24px;

  position: absolute;
  width: 97%;
  top: 55px;
  left: 80px;
  /* Stax Black */

  background: #062333;
  box-shadow: 0px 5px 8px 8px rgba(0, 0, 0, 0.12);
`;

const BottomFloat = styled.div`
  width: 100%;
  display: inline-block;
  font-size: 12pt;
`;

const RightDiv = styled.div`
  float: left;
`;

let reserveType: Array<any>;

reserveType = [
  {
    type: 'Capped Reserve',
    default: true,
  },
];

export const ReservesInfo: FunctionComponent = () => {
  const history = useHistory();
  const authToken = useAuthToken();
  const [catanQueryData, setCatanQueryData] = useState<
    CollectionResponse<API.Journal> | undefined
  >();
  const [editModalState, editModalDispatch] = useModalReducer<EditModalContext>(
    initialEditModalState
  );
  const { toaster, toast } = useToaster();
  const { search, pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const merchantId = useRouteMatchMerchantId();

  const {
    state: { registration, merchant, billingProfiles, reserves },
  } = useContext(SelectedMerchantStore);
  const [typeSet, setType] = React.useState<string>('Capped Reserve');

  const reservesBillingProfile = billingProfiles.data?.find(
    billing => billing.type === 'RESERVES'
  );
  const activeReserve = reserves.data?.find(
    reserve => reserve.status === 'ACTIVE'
  );

  const combinedData = { ...reservesBillingProfile, ...activeReserve };

  function mapSettlementStateToStatus(
    state: DB.SettlementState | DB.PayoutState
  ): {
    label: string;
    status: StatusPillStatus;
    icon: FontAwesomeIconProps['icon'];
  } {
    switch (state) {
      case 'APPROVED':
        return {
          label: 'Released',
          status: 'success',
          icon: ['fas', 'check'],
        };
    }
  }

  const columns = [
    {
      Header: 'Type',
      accessor: '"Settlement"',
      style: { width: '200px' },
      Cell: props => (
        <>
          Settlement &nbsp;
          <Icon
            onClick={() => {
              history.push({
                pathname: `/settlements/${props.row.original.meta.settlement_id}/details`,
              });
            }}
            style={{ color: '#009BF2', height: '14px', width: '14px' }}
            icon={faExternalLink}
          />
        </>
      ),
    },
    {
      Header: 'Date',
      accessor: 'meta.settlement_date',
      style: { width: '200px' },
      Cell: cell => <span>{formatDate(cell.value, 'MM/dd/yyyy')}</span>,
    },
    {
      Header: 'Status',
      accessor: 'meta.balance',
      disableSortBy: true,
      style: {
        textAlign: 'left',
        width: '100px',
        paddingLeft: 0,
        paddingRight: 0,
      },
      Cell: cell =>
        cell.value ? (
          <StatusPill {...mapSettlementStateToStatus('APPROVED')} />
        ) : null,
    },
    {
      Header: 'Amount',
      accessor: 'meta.setlement_total',
      style: { textAlign: 'right', width: '130px' },
      Cell: cell => <span>{formatCurrency(cell.value)}</span>,
    },
    {
      Header: 'Target Withdraw',
      accessor: 'meta.reserve_percent_volume',
      style: { textAlign: 'right', width: '130px' },
      Cell: cell => <span>{cell.value * 100}%</span>,
    },
    {
      Header: 'Added to Reserve',
      accessor: `meta.sign`,
      disableSortBy: true,
      style: { textAlign: 'right', width: '130px' },

      Cell: props => (
        <>
          {props.row.original.meta.sign}
          {formatCurrency(props.row.original.amount)}
        </>
      ),
    },
    {
      Header: 'Balance / Target',
      accessor: 'meta.balance / meta.balance',
      style: { textAlign: 'right', width: '130px' },
      Cell: props => (
        <>
          <b>{formatCurrency(props.row.original.meta.balance)}</b>/
          {formatCurrency(props.row.original.meta.target)}
        </>
      ),
    },
    {
      Header: 'Reserve Rule',
      accessor: 'meta.target',
      style: { textAlign: 'right', width: '130px' },
      Cell: props => (
        <>
          {props.row.original.meta.reserve_percent_days > 0 ? (
            <span>
              {props.row.original.meta.reserve_percent_volume * 100}% &nbsp; -
              &nbsp;
              {props.row.original.meta.reserve_percent_days}&nbsp; Days
            </span>
          ) : (
            <span>{formatCurrency(props.row.original.meta.target)}</span>
          )}
        </>
      ),
    },
  ];
  const pageSizeOptions = [
    { value: 10, label: 'Show 10' },
    { value: 20, label: 'Show 20' },
    { value: 50, label: 'Show 50' },
    { value: 100, label: 'Show 100' },
  ];

  const query = useMemo((): CollectionQuery => {
    return {
      ...parse(search),
    };
  }, [search]);

  const handleQueryChange = useCallback(
    (newQuery: Partial<CollectionQuery> = {}) => {
      const newSearch = stringify(
        cleanCollectionQuery({ ...query, ...newQuery })
      );
      history.push(`${pathname}?${newSearch}`);
    },
    [query, history, pathname]
  );

  const { onSubmit, onUpdate } = useReserveFormSubmission({
    companyId: get(registration, 'external_company_id', merchantId),
    merchantId: merchantId,
    payloadFormatter: reserveInfoFormUtil.mapFormValuesToPayload,
  });
  const validateForm = (
    formValues: ReserveInfoFieldsValues
  ): ReserveInfoFieldsErrors => {
    const errors = {
      ...reserveInfoFormUtil.validateFormValues(formValues),
    } as ReserveInfoFieldsErrors;

    return errors;
  };

  const mustBeNumber = value =>
    isNaN(value) ? `Please provide a numeric quantity` : undefined;
  const required = value => (value ? undefined : '');

  const minValue = min => value =>
    isNaN(value) || value > min ? undefined : `Must be greater than ${min}`;

  const maxValue = max => value =>
    isNaN(value) || value < max
      ? undefined
      : `Reserve must be less than ${max}`;

  const composeValidators =
    (...validators) =>
    value =>
      validators.reduce(
        (error, validator) => error || validator(value),
        undefined
      );
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  const normalizePercent = value => {
    if (!value) return value;
    if (value < 1) {
      return value * 100;
    }
  };
  const getData: getJournal = useCallback(
    collectionQuery =>
      catanapi.get(authToken, `/company/${merchantId}/journal`, {
        ...collectionQuery,
        startDate: toISOString(collectionQuery.startDate),
        endDate: toISOString(collectionQuery.endDate),
        // timezone,
      }),
    [authToken, merchantId]
  );

  useAsyncEffect(async () => {
    setLoading(true);
    try {
      const data = await getData(query);
      setCatanQueryData(data);
      setLoading(false);
    } catch (e) {
      setCatanQueryData(undefined);
      setLoading(false);
      toaster(
        toast.error(
          'Could not get the Journal Info. Please retry later!',
          'OOps, Something went wrong'
        )
      );
    }
  }, [authToken, search, pathname]);
  return (
    <Form
      onSubmit={reservesBillingProfile ? onUpdate : onSubmit}
      initialValues={{
        ...reserveInfoFormUtil.mapRegistrationToInitialFormValues(
          reservesBillingProfile ? combinedData : null
        ),
      }}
      validate={formValues => {
        const validationErrors = {
          ...validateForm(formValues),
        } as ReserveInfoFieldsErrors;
        return validationErrors;
      }}
      mutators={{
        setFormValues: ([values], state, utils) => {
          Object.keys(values).forEach(key => {
            const value = values[key];
            utils.changeValue(state, key, () => value);
          });
        },
      }}
      render={({
        handleSubmit,
        form,
        submitting,
        pristine,
        values,
        hasValidationErrors,
      }) => (
        <form onSubmit={handleSubmit}>
          <>
            <div>
              <TitleBar>
                <PageHeader title="" />
                <RightDiv>
                  <TextLink
                    onClick={() => {
                      history.push({
                        pathname: `/merchant/${registration?.merchant_id}/businessinfo`,
                      });
                    }}
                    style={{ fontWeight: 700 }}
                    data-testid="back-link"
                  >
                    <Icon
                      style={{
                        color: '#009BF2',
                        paddingTop: '50%',
                        width: '8px',
                        height: '20px',
                      }}
                      icon={faCaretLeft}
                    />
                    Business Info
                  </TextLink>
                  <Text as="h3">
                    <b>Reserves</b>
                  </Text>
                  <Text as="p">View or edit the merchant’s reserves below</Text>
                </RightDiv>
                <ButtonContainer>
                  {reservesBillingProfile ? onUpdate : onSubmit}
                  {reservesBillingProfile ? null : (
                    <SmallSecondaryButton
                      type="button"
                      onClick={() => {
                        history.push({
                          pathname: `/merchant/${registration?.merchant_id}/businessinfo`,
                        });
                      }}
                    >
                      Cancel
                    </SmallSecondaryButton>
                  )}

                  <Divider></Divider>
                  {reservesBillingProfile ? null : (
                    <SmallPrimaryButton
                      type="submit"
                      disabled={!values.reserve_percent_volume}
                    >
                      Create Reserve
                    </SmallPrimaryButton>
                  )}

                  {combinedData.reserve_flat_amount ===
                    values.reserve_flat_amount &&
                  combinedData.reserve_percent_days ===
                    values.reserve_percent_days &&
                  combinedData.reserve_percent_volume ===
                    values.reserve_percent_volume ? (
                    reservesBillingProfile ? (
                      <SmallPrimaryButton
                        type="button"
                        onClick={() => {
                          editModalDispatch(
                            sendOpenSelected({ BalanceTarget })
                          );
                        }}
                        disabled={!values.balance}
                      >
                        Release Reserve
                      </SmallPrimaryButton>
                    ) : null
                  ) : reservesBillingProfile ? (
                    <div>
                      <SmallSecondaryButton
                        type="button"
                        onClick={() => {
                          history.push({
                            pathname: `/merchant/${registration?.merchant_id}/businessinfo`,
                          });
                        }}
                      >
                        Cancel
                      </SmallSecondaryButton>
                      <Divider />
                      <SmallPrimaryButton
                        type="submit"
                        disabled={!values.reserve_percent_volume}
                      >
                        Save Changes
                      </SmallPrimaryButton>
                    </div>
                  ) : null}
                </ButtonContainer>
              </TitleBar>

              <ReserveInfoContainer>
                <BalanceTarget>
                  <Text as="p">Balance / Target</Text>
                  <Value>
                    {values.balance > 0 && (
                      <div
                        className={`${
                          values.balance >= values.target
                            ? 'resrve-met-text'
                            : 'resrve-not-met-text'
                        }`}
                      >
                        {formatter.format(values.balance)}
                      </div>
                    )}
                    <Text as="h3">{!values.balance && <b>$0.00&nbsp;</b>}</Text>

                    {values.target > 0 ||
                      (values.reserve_flat_amount > 0 && (
                        <p>
                          /{' '}
                          {values.target > 0
                            ? formatter.format(values.target)
                            : formatter.format(values.reserve_flat_amount)}
                        </p>
                      ))}
                    {values.reserve_flat_amount > 0 && values.target > 0 && (
                      <p>
                        /{' '}
                        {values.target > 0
                          ? formatter.format(values.target)
                          : formatter.format(values.reserve_flat_amount)}
                      </p>
                    )}
                    {!values.reserve_flat_amount && !values.target && (
                      <p> / $0.00</p>
                    )}
                  </Value>
                  <BottomFloat>
                    {values.balance < values.target && (
                      <Text as="p" data-testid="partially-funded-reserve">
                        Partially Funded
                      </Text>
                    )}
                    {values.balance >= values.target && (
                      <Text as="p" data-testid="fully-funded-reserve">
                        Fully Funded
                      </Text>
                    )}
                    {values.balance <= 0 ||
                      (!values.balance && (
                        <Text as="p" data-testid="not-funded-reserve">
                          Not Funded
                        </Text>
                      ))}
                  </BottomFloat>
                </BalanceTarget>
                <LineBreak></LineBreak>

                <ReserveType>
                  <Text as="p">
                    Reserve Type&nbsp;
                    <Tooltip
                      content={
                        <>
                          <span>
                            <b>Capped Reserve:</b> Reserve based on specific
                            amount
                          </span>
                        </>
                      }
                    >
                      <Icon
                        style={{ color: '#BDC9CC' }}
                        icon={['far', 'info-circle']}
                      />
                    </Tooltip>
                  </Text>

                  {reserveType?.map(type => {
                    return (
                      <Field
                        name="type"
                        id={type.type}
                        data-testid={type.type}
                        type="radio"
                        component="input"
                        value={type.type}
                      >
                        {props => {
                          return (
                            <RadioButton
                              {...props.input}
                              checked={typeSet === type.type && true}
                              label={type.type}
                              onChange={e => {
                                props.input.onChange(e);

                                setType(e.target.value);
                              }}
                            />
                          );
                        }}
                      </Field>
                    );
                  })}
                </ReserveType>

                <ReserveAmmount>
                  <span>
                    <Text as="p">
                      Reserve Rule&nbsp;
                      <Tooltip
                        content={
                          <>
                            <span>
                              <b>Capped Reserve:</b> determined by what is typed
                              in `Amount` field.
                            </span>
                          </>
                        }
                      >
                        <Icon
                          style={{ color: '#BDC9CC' }}
                          icon={['far', 'info-circle']}
                        />
                      </Tooltip>
                    </Text>
                  </span>
                  <Field
                    name="reserve_flat_amount"
                    validate={composeValidators(minValue(0), mustBeNumber)}
                    formatOnBlur
                  >
                    {({ input, meta }) => (
                      <div>
                        <TextField
                          {...input}
                          placeholder="0.00"
                          type="number"
                          label="Amount"
                          inputPrefix={
                            <Icon
                              style={{
                                bottom: '3px',
                                position: 'relative',
                              }}
                              icon={['fas', 'dollar-sign']}
                            />
                          }
                          required
                        />
                        {meta.error && meta.touched && (
                          <span>{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </ReserveAmmount>

                <LineBreakTwo></LineBreakTwo>
                <WithdrawPercent>
                  <span>
                    <Text as="p">
                      Withdraw&nbsp;
                      <Tooltip
                        content={
                          <span>
                            <b>Withdraw:</b> percent withdrawn from a settlement
                          </span>
                        }
                      >
                        <Icon
                          style={{ color: '#BDC9CC' }}
                          icon={['far', 'info-circle']}
                        />
                      </Tooltip>
                    </Text>
                  </span>
                  <Field
                    name="reserve_percent_volume"
                    //parse={parse}
                    validate={composeValidators(
                      required,
                      mustBeNumber,
                      minValue(0),
                      maxValue(100)
                    )}
                    format={v => normalizePercent(v)}
                  >
                    {({ input, meta }) => (
                      <div>
                        <TextField
                          {...input}
                          placeholder="0"
                          type="number"
                          label="Percent"
                          inputSuffix={
                            <Icon
                              style={{
                                bottom: '3px',
                                position: 'relative',
                              }}
                              icon={['fas', 'percent']}
                            />
                          }
                          required
                        />

                        {meta.error && meta.touched && (
                          <span>{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </WithdrawPercent>
                <LineBreakThree></LineBreakThree>
                <InfoForRes>
                  <div>
                    {values.reserve_percent_days === null &&
                    values.reserve_flat_amount === 0 ? (
                      <Text as="p">
                        <b>Reserve is inactive, Reserve amount is</b>
                        {values.reserve_flat_amount > 0 && (
                          <b>
                            &nbsp;
                            {formatter.format(values.reserve_flat_amount)}
                          </b>
                        )}
                        {!values.reserve_flat_amount && <b>&nbsp;$0.00</b>}.
                      </Text>
                    ) : (
                      <Text as="p">
                        {values.reserve_percent_volume < 1 &&
                          values.reserve_percent_volume > 0 && (
                            <b>{values.reserve_percent_volume * 100}%&nbsp;</b>
                          )}
                        {values.reserve_percent_volume >= 1 && (
                          <b>{values.reserve_percent_volume}%&nbsp;</b>
                        )}
                        {!values.reserve_percent_volume && <b>0%&nbsp;</b>}of
                        the settlement is withdrawn and put into reserves until
                        the balance reaches&nbsp;
                        {values.reserve_flat_amount > 0 && (
                          <b>{formatter.format(values.reserve_flat_amount)}</b>
                        )}
                        {!values.reserve_flat_amount && <b>$0.00</b>}.
                      </Text>
                    )}
                  </div>
                </InfoForRes>
              </ReserveInfoContainer>
              <JournalTableContainer>
                {values.target >= 0 && (
                  <JournalDataTable
                    data={{
                      columns,
                      rows: loading
                        ? generateLoadingRows()
                        : catanQueryData?.data ?? [],
                    }}
                    count={catanQueryData?.last_page ?? 0}
                    total={catanQueryData?.total ?? 0}
                    currentPage={catanQueryData?.current_page ?? 0}
                    defaultPageSize={catanQueryData?.per_page ?? 20}
                    lastPage={catanQueryData?.last_page ?? 0}
                    loading={loading}
                    pageSizeOptions={pageSizeOptions}
                    handleQueryChange={handleQueryChange}
                    onRowsChanged={onRowsChanged}
                  />
                )}
              </JournalTableContainer>
              <ReleaseReserveModal
                balance={combinedData.balance?.toString()}
                company_name={merchant?.company_name}
                billing_id={combinedData.billing_id}
                status={editModalState.status}
                modalDispatch={editModalDispatch}
              />
            </div>
          </>
        </form>
      )}
    />
  );
  function onRowsChanged(rows) {
    const tmp = { ...catanQueryData };
    tmp.data = rows;
    setCatanQueryData(tmp);
  }
};
