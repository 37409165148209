import React, { FunctionComponent, InputHTMLAttributes } from 'react';
import { Field } from 'react-final-form';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCloudUpload } from '@fortawesome/pro-solid-svg-icons';
import {
  CustomButton,
  HiddenInput,
  StyledFontAwesomeIcon,
  StyledPlaceHolder,
} from './SettingsPagesStylings';
import { FormValues } from './BrandData';
import { Brand } from '@fattmerchantorg/types-omni';
import { useToaster } from '../../hooks';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  values: FormValues;
  currentBrand: Brand;
  isSmallLogo?: boolean;
}

export const FileUploader: FunctionComponent<Props> = ({
  name,
  values,
  currentBrand,
  isSmallLogo = false,
  ...props
}) => {
  const { toast, toaster } = useToaster();

  const hiddenFileInput = React.useRef(null);

  const handleClick = event => {
    event.preventDefault();
    hiddenFileInput.current.value = null;
    hiddenFileInput.current.click();
  };

  const handleUpload = (target, onChange) => {
    const file = target.files[0];
    if (file?.type !== 'image/png') {
      target.value = '';
      toaster(toast.error(`PNG is the only accepted file type. `));
    } else {
      onChange(target.files[0]);
    }
  };

  return (
    <Field<FileList> name={name}>
      {({ input: { value, onChange, ...input } }) => (
        <>
          <CustomButton onClick={handleClick}>
            <StyledFontAwesomeIcon size="sm" icon={faCloudUpload as IconProp} />
            <StyledPlaceHolder>
              {values?.[name]
                ? values?.[name].name
                : currentBrand?.[name]?.[0]
                ? currentBrand?.[name]?.[0].filename
                : 'Choose a File'}
            </StyledPlaceHolder>
          </CustomButton>
          <HiddenInput
            {...input}
            type="file"
            onChange={({ target }) => {
              handleUpload(target, onChange);
            }}
            ref={hiddenFileInput}
            accept="image/png"
            {...props}
          />
        </>
      )}
    </Field>
  );
};
