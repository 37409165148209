import React, { FunctionComponent, useContext, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { Widget } from '../../shared/Widget';
import { formatPhoneNumber, formatCityState } from '../../../util/format.util';
import { SelectedMerchantStore } from '../../../context';
import { CopyButton, CurrenciesIndicator } from '../../shared';
import { AssumeLink } from '../AssumeLink';
import { CloneMerchantForm } from './CloneMerchantForm';
import { TextLink, BaseModal, Icon } from '@fattmerchantorg/truffle-components';
import { faClone } from '@fortawesome/pro-solid-svg-icons';
import { usePermissions } from '../../../hooks';

const Container = styled(Widget)`
  i {
    margin-right: 0.25rem;
    width: 16px;
    text-align: center;
  }
`;

const MerchantActionsHeader = styled.p`
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 0.5rem;
`;

const MerchantActionsRow = styled.div`
  a:not(:last-child) {
    margin-right: 10px;
    &::after {
      content: '\\a0';
      padding-right: 10px;
      display: inline-block;
      border-right: 1px solid #bdc9cc;
    }
  }
`;

const ContentTop = withTheme(
  styled.div`
    color: white;
    padding: 2rem 1.25rem;
    background: ${({ theme }) => theme.colors.core.green[800].hex};
    border-radius: 3px 3px 0 0;

    h2 {
      color: white;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
    }

    i {
      color: #8898aa !important;
    }

    > *:not(:last-child) {
      margin-bottom: 16px;
    }
  `
);

const ContentBottom = styled.div`
  color: #02436b;
  padding: 1.25rem 1rem;
  border-radius: 0 0 3px 3px;
`;

const TruncateLine = styled.div`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const BusinessInfoWidget: FunctionComponent = () => {
  const { state } = useContext(SelectedMerchantStore);
  const { merchant } = state;
  const copyMerchantId = 'copyId';
  const [cloneMerchantOpen, setCloneMerchantOpen] = useState(false);
  const { permit } = usePermissions();

  if (!merchant) return null;

  return (
    <>
      <Container>
        <ContentTop>
          <h2>
            <TruncateLine>{merchant.company_name}</TruncateLine>
          </h2>
          <div className="text-muted">
            <TruncateLine>
              {merchant.id}{' '}
              <CopyButton
                id={copyMerchantId}
                data-testid={copyMerchantId}
                content={merchant.id}
              />
            </TruncateLine>
          </div>
          <CurrenciesIndicator currencies={merchant.currency ?? ['USD']} />
        </ContentTop>
        <ContentBottom>
          <TruncateLine>
            <i className="fas fa-phone" />
            {formatPhoneNumber(merchant.contact_phone)}
          </TruncateLine>
          <TruncateLine>
            <i className="fas fa-envelope" />
            {merchant.contact_email}
          </TruncateLine>
          <TruncateLine>
            <i className="fas fa-map-marker-alt" />
            {merchant.address_1} {merchant.address_2}{' '}
            {formatCityState(merchant.address_city, merchant.address_state)}{' '}
            {merchant.address_zip}
          </TruncateLine>
          <hr style={{ margin: '.5rem 0' }} />
          <MerchantActionsHeader>Merchant Actions</MerchantActionsHeader>

          <MerchantActionsRow>
            {permit('godview', 'clone', 'write') && (
              <TextLink
                onClick={() => setCloneMerchantOpen(true)}
                style={{ fontWeight: 700 }}
                data-testid="clone-merchant-link"
              >
                <Icon icon={faClone} />
                Clone
              </TextLink>
            )}
            <AssumeLink merchant={merchant} />
          </MerchantActionsRow>
        </ContentBottom>
      </Container>
      <BaseModal
        isOpen={cloneMerchantOpen}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setCloneMerchantOpen(false)}
        onClose={() => setCloneMerchantOpen(false)}
        footer={null}
        style={{
          content: {
            width: '40%',
            gridTemplate: `"header" 48px "content" 1fr`,
          },
        }}
      >
        <CloneMerchantForm
          onModalClose={() => setCloneMerchantOpen(false)}
          merchant={merchant}
        />
      </BaseModal>
    </>
  );
};
