import get from 'lodash/get';
import axios from 'axios';
import { VerifyError } from '../@types';
import { getHeaders } from '../util/api.util';
import { Auth, Registration } from '@fattmerchantorg/types-omni';
import { Signer } from '../components/underwriting/components/external-verification/ExternalVerification.types';

export interface TincheckReport {
  EntityType: string;
  TinCheckResponse: {
    TinNameResponse: { Code: number; Description: string };
    EinResponse: { Code: number; Description: string };
    DmfResponse: { Code: number; Description: string };
    TinCheckApiCallStatus: { Status: any; CallsRemaining: any };
  };
}

const stripSpecialCharacters = (str: string): string =>
  str.replace(/[\W_]/gi, '');

/**
 * network request for obtaining tincheck status for business or person
 * @param {Object} registration registration form containing values of input fields
 * @param {String} merchantId id for inserting into registration audit log table
 * @param {String} entityType 'person' or 'business' for tincheck request
 */
export const postToTinCheck = async (
  registration: Registration,
  merchantId: string,
  entityType: string,
  authToken: Auth['token'],
  signer?: Signer
): Promise<
  { report: TincheckReport; servicesRan: string[] } | VerifyError[] | Error
> => {
  const companyName = get(registration, 'business_legal_name');
  const businessTaxId = get(registration, 'business_tax_id');

  // If signer is passed in that means we are running tincheck on a beneficial
  // owner instead of the principal owner, so we pass along the signer values to
  // tincheck instead.
  const firstName = signer
    ? get(signer, 'first_name')
    : get(registration, 'first_name');
  const lastName = signer
    ? get(signer, 'last_name')
    : get(registration, 'last_name');
  const ownerTaxId = signer
    ? get(signer, 'user_ssn')
    : get(registration, 'user_ssn');

  try {
    const tincheckResponse = await axios({
      url: `${process.env.REACT_APP_ONBOARDING_URL}/tincheck`,
      data: {
        businessTaxId: businessTaxId
          ? stripSpecialCharacters(businessTaxId)
          : undefined,
        ownerTaxId: ownerTaxId ? stripSpecialCharacters(ownerTaxId) : undefined,
        companyName,
        merchantId,
        entityType,
        firstName,
        lastName,
      },
      method: 'post',
      headers: getHeaders(authToken),
    });
    return tincheckResponse.data;
  } catch (error) {
    const requiredFields: VerifyError[] = get(
      error,
      'response.data.report.errors',
      false
    );
    return !!requiredFields ? requiredFields : error;
  }
};
