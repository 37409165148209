import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { SearchCollection } from '../shared';
import { history } from '../../history';
import {
  Icon,
  SmallPrimaryButton,
  Text,
  Tooltip,
} from '@fattmerchantorg/truffle-components';
import styled from 'styled-components';
import { coreapi } from '../../api';
import {
  useAuthToken,
  useRouteMatchMerchantId,
  useSearchState,
  useToaster,
  useUpdateNumber,
} from '../../hooks';
import { toISOString } from '../../util/date.util';
import {
  closeModal,
  openModal,
  useBankAccountsPageModalReducer,
} from './components/modals';

import {
  CollectionResponse,
  FundingAccount,
} from '@fattmerchantorg/types-omni';

import {
  checkAccountTypeUpdate,
  getNickname,
} from './util/BankAccountsPage.util';
import { BankAccountDrawer } from './components/drawer/BankAccountDrawer';
import { BankAccountStatusPill } from './components';
import { BankAccountImage } from './components/BankAccountImage';
import { BankAccountsPageModals } from './components/BankAccountsPageModals';
import { ColumnProps } from '../shared';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 5px 8px -4px black;
  padding: 16px 24px;
`;

const HeaderSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  justify-content: center;
  align-items: flex-start;
  font-size: 14px;

  h3 {
    line-height: 42px;
    font-weight: bold;
  }
`;

const TopSpan = styled.span`
  color: ${({ theme }) => theme.colors.status.blue[500].hex};
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const BankNameCell = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > *:first-child {
    padding-right: 8px;
  }
`;

const CellSpan = styled.span`
  display: flex;
  justify-content: flex-start;
`;

const EditCell = styled.span`
  display: flex;
  justify-content: flex-end;
`;

const EditIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.core.gray[200].hex};
`;

const SuccessIconContainer = styled.span`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.status.green[500].hex};
`;

const CollectionWrapper = styled.div`
  padding-top: 16px;
`;

export function mapFlagsType(type: string): string {
  if (type) {
    if (type.includes('FEE')) {
      return 'Deposit & Fees';
    } else if (type.includes('IS_TRUST')) {
      return 'Trust';
    }
  }
  //default to just being deposit
  return 'Deposit';
}

function renderPrimarycell(flags: string) {
  if (flags?.includes('PRIMARY')) {
    return (
      <Tooltip content="Primary Account">
        <SuccessIconContainer>
          <Icon icon={['fas', 'check']} />
        </SuccessIconContainer>
      </Tooltip>
    );
  } else return null;
}

export const BankAccountsPage: FunctionComponent = () => {
  const merchantId = useRouteMatchMerchantId();
  const authToken = useAuthToken();
  const [modalState, modalDispatch] = useBankAccountsPageModalReducer();
  const { toast, toaster } = useToaster();
  const [selectedAccount, setSelectedAccount] = useState<FundingAccount>();
  const [detailId, setDetailId] = useSearchState('detailId');
  const incrementUpdateNumber = useUpdateNumber()[1];
  const [editBankAccountPayload, setEditBankAccountPayload] =
    useState<Partial<FundingAccount>>();
  const [numberOfActiveFeeAccounts, setNumberOfActiveFeeAccounts] =
    useState<number>(0);

  const columns: ColumnProps<FundingAccount>[] = useMemo(
    () => [
      {
        id: 'primaryId',
        Header: '',
        accessor: 'flags',
        style: { padding: '12px 8px' },
        Cell: cell => renderPrimarycell(cell.value),
        loadingWidth: '18px',
        disableSortBy: true,
      },
      {
        Header: 'Bank',
        accessor: 'bank_name',
        loadingWidth: '5rem',
        style: { padding: '9px 0px' },
        Cell: cell => (
          <BankNameCell>
            <BankAccountImage bankName={cell.value} />
            {cell.value}
          </BankNameCell>
        ),
        disableSortBy: true,
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: cell =>
          cell.value ? (
            <BankAccountStatusPill account={cell.row?.original} />
          ) : null,
        loadingWidth: '5rem',
      },
      {
        Header: 'Account #',
        accessor: 'last_four',
        loadingWidth: '15rem',
        style: { textAlign: 'right' },
        Cell: cell => (
          <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {cell.value && '**' + cell.value}
          </span>
        ),
        disableSortBy: true,
      },
      {
        Header: 'Routing #',
        accessor: 'bank_routing_number',
        loadingWidth: '5rem',
        style: { textAlign: 'right' },
        disableSortBy: true,
      },
      {
        Header: 'Account Holder',
        accessor: 'person_name',
        loadingWidth: '8rem',
        disableSortBy: true,
      },
      {
        Header: 'Type',
        accessor: 'flags',
        style: {
          textAlign: 'left',
        },
        Cell: cell => <CellSpan>{mapFlagsType(cell.value)}</CellSpan>,
        disableSortBy: true,
      },
      {
        Header: 'Nickname',
        accessor: 'name',
        style: {
          textAlign: 'left',
        },
        Cell: cell => <CellSpan>{cell.value || '--'}</CellSpan>,
        disableSortBy: true,
      },
      {
        id: 'more',
        Header: '',
        accessor: 'id',
        style: {
          textAlign: 'left',
        },
        disableSortBy: true,
        Cell: cell => (
          <EditCell onClick={() => setDetailId(cell.value)}>
            <EditIcon icon={['fas', 'ellipsis-h']} />
          </EditCell>
        ),
      },
    ],
    [setDetailId]
  );

  const fetchBankAccount = useCallback(
    (accountId: string) =>
      coreapi.get(
        authToken,
        `/merchant/${merchantId}/funding-account/${accountId}`
      ),
    [authToken, merchantId]
  );

  const fetchFeeAndActiveAccounts = useCallback(async () => {
    const res = await coreapi.get<CollectionResponse<FundingAccount>>(
      authToken,
      `/merchant/${merchantId}/funding-account`,
      { active: 1, flags: 'FEE' }
    );

    setNumberOfActiveFeeAccounts(res.data.length);
  }, [authToken, merchantId]);

  const getData = useCallback(
    collectionQuery => {
      fetchFeeAndActiveAccounts();
      return coreapi.get<CollectionResponse<FundingAccount>>(
        authToken,
        `/merchant/${merchantId}/funding-account`,
        {
          ...collectionQuery,
          startDate: toISOString(collectionQuery.startDate),
          endDate: toISOString(collectionQuery.endDate),
        }
      );
    },
    [authToken, merchantId, fetchFeeAndActiveAccounts]
  );

  const handleCreateBankAccount = async (payload: Partial<FundingAccount>) => {
    modalDispatch(openModal('add', 'saving'));
    try {
      const account: FundingAccount = await coreapi.post<FundingAccount>(
        authToken,
        `/merchant/${merchantId}/funding-account`,
        payload
      );

      setSelectedAccount(account);

      if (account?.status === 'PENDED') {
        modalDispatch(openModal('add', 'pended'));
      } else {
        modalDispatch(openModal('add', 'approved'));
        toaster(
          toast.success(
            `Bank account ${getNickname(account)} successfully added.`
          )
        );
      }
    } catch (error) {
      modalDispatch(openModal('add', 'error'));
      toaster(toast.error(error.error ?? error));
    } finally {
      incrementUpdateNumber();
    }
  };

  const handleEditBankAccount = useCallback(
    async (
      id: string,
      payload: Partial<FundingAccount>,
      operation: 'rejected' | 'deactivated' | 'updated' | 'approved' = 'updated'
    ) => {
      try {
        const account = await coreapi.put<FundingAccount>(
          authToken,
          `/merchant/${merchantId}/funding-account/${id}`,
          payload
        );

        toaster(
          toast.success(
            `Bank account ${getNickname(account)} successfully ${operation}.`,
            `ACCOUNT ${operation.toUpperCase()}`
          )
        );

        modalDispatch(closeModal());

        return account;
      } catch (error) {
        toaster(
          toast.error(error, `There was a problem updating the account.`)
        );
      } finally {
        incrementUpdateNumber();
      }
    },
    [
      authToken,
      merchantId,
      modalDispatch,
      toaster,
      toast,
      incrementUpdateNumber,
    ]
  );

  const handleConfirmEditBankAccount = useCallback(
    async (
      id: string,
      payload: Partial<FundingAccount>,
      operation: 'rejected' | 'deactivated' | 'updated' | 'approved' = 'updated'
    ) => {
      modalDispatch(closeModal());
      setEditBankAccountPayload(payload);

      // Confirm if bank account FEE & PRIMARY types are changing. Display confirmation modal if they are.
      if (
        checkAccountTypeUpdate(selectedAccount?.flags, payload?.flags) === false
      ) {
        handleEditBankAccount(id, payload, operation);
      } else {
        modalDispatch(openModal('update'));
      }
    },
    [handleEditBankAccount, selectedAccount, modalDispatch]
  );

  return (
    <Container>
      <HeaderContainer>
        <HeaderSubContainer>
          <TopSpan
            onClick={() => history.push(`/merchant/${merchantId}/businessinfo`)}
          >
            <span style={{ display: 'flex', paddingRight: '8px' }}>
              <Icon icon={['fas', 'caret-left']} />
            </span>
            <span>Business Info</span>
          </TopSpan>
          <Text as="h3">Bank Accounts</Text>
          <Text as="span">View or edit the merchant's bank accounts below</Text>
        </HeaderSubContainer>

        <SmallPrimaryButton
          icon={['fas', 'plus']}
          onClick={() => modalDispatch(openModal('add', 'form'))}
        >
          Add Bank Account
        </SmallPrimaryButton>
      </HeaderContainer>

      <BankAccountsPageModals
        numberOfActiveFeeAccounts={numberOfActiveFeeAccounts}
        selectedAccount={selectedAccount}
        editBankAccountPayload={editBankAccountPayload}
        modalState={modalState}
        modalDispatch={modalDispatch}
        onAdd={handleCreateBankAccount}
        onEdit={handleEditBankAccount}
        onConfirmEdit={handleConfirmEditBankAccount}
      />

      <BankAccountDrawer
        getData={merchantId ? fetchBankAccount : null}
        updateData={merchantId ? handleEditBankAccount : null}
        open={!!detailId}
        onClose={() => setDetailId(null)}
        onEdit={account => {
          setSelectedAccount(account);
          modalDispatch(openModal('edit'));
        }}
        onApprove={account => {
          setSelectedAccount(account);
          modalDispatch(openModal('approve'));
        }}
        onReject={account => {
          setSelectedAccount(account);
          modalDispatch(openModal('reject'));
        }}
        onDeactivate={account => {
          setSelectedAccount(account);
          modalDispatch(openModal('deactivate'));
        }}
      />

      <CollectionWrapper>
        <SearchCollection<FundingAccount>
          // if we don't have a merchant id yet
          // we're not ready to get data
          getData={merchantId ? getData : null}
          columns={columns}
          blacklist={['detailId']}
          onRowClick={a => setDetailId(a.id)}
        />
      </CollectionWrapper>
    </Container>
  );
};
