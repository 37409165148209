import React, { FunctionComponent, useContext } from 'react';
import { NavItem } from 'reactstrap';
import styled, { useTheme } from 'styled-components';
import { faFlask } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  userClickedModeToggleSwitch,
  AuthStore,
  deriveAuthMode,
  OptimisticUIStatuses,
  updateOptimisticUIStatus,
} from '../../../context';
import Switch from 'react-switch';
import useOptimisticToggle from '../../../hooks/useOptimisticToggle';
import { StyledLabel, IconWrapper } from './Sidebar';

const StyledNavItem = styled(NavItem)`
  padding: 8px 0;
  background-color: ${({ theme }) => theme.colors.core.gray[800].hex};
  color: #ffffff;
  text-align: center;

  /* Flask border */
  .test-mode-icon .fa-primary {
    fill: #fff;
  }

  /* Flask liquid */
  .test-mode-icon .fa-secondary {
    fill: #c11e9b;
    opacity: 1; /* Needed for secondary color */
  }
`;

const SwitchLabelCommon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 8px !important;
`;

const SwitchOnLabel = styled(SwitchLabelCommon)`
  color: #fff;
`;

const SwitchOffLabel = styled(SwitchLabelCommon)`
  color: #111e52;
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TestModeMenu: FunctionComponent = () => {
  const { state: authState, dispatch: authDispatch } = useContext(AuthStore);
  const mode = deriveAuthMode(authState);

  // Optimistic Toogle hook.
  const { optimisticState, handleOptimisticSwitchChange } = useOptimisticToggle(
    mode,
    () => authDispatch(userClickedModeToggleSwitch()),
    () => authDispatch(updateOptimisticUIStatus(OptimisticUIStatuses.Ok)),
    authState.optimisticUIStatus
  );

  const purple = (useTheme() as any).colors.core.purple[500].hex;

  return (
    <StyledNavItem data-testid="test-mode-navigation">
      <div>
        <IconWrapper>
          <FontAwesomeIcon
            className="test-mode-icon"
            icon={faFlask as IconProp}
          />
        </IconWrapper>
        <StyledLabel>
          <SwitchWrapper>
            <div style={{ paddingRight: 8 }}>Test Mode</div>
            <Switch
              data-testid="test-mode-switch"
              checked={optimisticState}
              onChange={handleOptimisticSwitchChange}
              offColor="#D8D8D8"
              onColor={purple}
              offHandleColor="#fff"
              onHandleColor="#fff"
              height={24}
              width={48}
              borderRadius={24}
              uncheckedIcon={<SwitchOffLabel>Off</SwitchOffLabel>}
              checkedIcon={<SwitchOnLabel>On</SwitchOnLabel>}
              disabled={
                authState.optimisticUIStatus === OptimisticUIStatuses.Loading
              }
            />
          </SwitchWrapper>
        </StyledLabel>
      </div>
    </StyledNavItem>
  );
};
