import React, { FunctionComponent } from 'react';
import { PageHeader } from '../shared';
import { SupportTickets } from './SupportTickets';
import NewSupportTicketModal from './NewSupportTicketModal';
import ImportHubspotTicketsModal from './ImportHubspotTicketsModal';
import styled from 'styled-components';

const StyledPageHeader = styled(PageHeader)`
  justify-content: space-between;
`;

export const SupportTicketsPage: FunctionComponent = () => {
  return (
    <>
      <StyledPageHeader title="Support">
        <div>
          <NewSupportTicketModal />
          <ImportHubspotTicketsModal />
        </div>
      </StyledPageHeader>

      <SupportTickets />
    </>
  );
};
