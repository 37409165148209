import styled from 'styled-components';

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: column;
  }

  span {
    font-size: 10px;
  }

  h5 {
    margin-bottom: 4px;
  }

  img {
    margin: 4px 0;
    height: 32px;
  }
`;
