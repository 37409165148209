import React, { FunctionComponent, useEffect, useState } from 'react';
import { DateInputProps } from './DateInput.types';
import { Input } from '../Input';

function formatValueToDate(value: string) {
  const chunks = value.split('-');
  return chunks[1] + '/' + chunks[2] + '/' + chunks[0];
}

function formatReadableDateToValue(date: string) {
  const chunks = date.split('/');
  return chunks[2] + '-' + chunks[0] + '-' + chunks[1];
}

export const DateInput: FunctionComponent<DateInputProps> = props => {
  const { ...rest } = props;
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(formatReadableDateToValue(props.value));
  }, [props.value]);

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setValue(value);

    // manually override event value with our own formatted value
    const fauxEvent = {
      ...e,
      target: {
        ...e.target,
        value: formatValueToDate(value),
      },
    };

    if (props.onBlur)
      props.onBlur(fauxEvent as React.FocusEvent<HTMLInputElement>);
    if (props.onChange)
      props.onChange(fauxEvent as React.ChangeEvent<HTMLInputElement>);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(value);
  };

  return (
    <Input
      {...rest}
      active
      value={value}
      type="date"
      placeholder=""
      onBlur={onBlur}
      onChange={onChange}
    />
  );
};

DateInput.defaultProps = {
  type: 'text',
};
