import { FormErrors } from '../../../../@types';
import { Registration } from '@fattmerchantorg/types-omni';

export const ownerSignerInfoFields = [
  'principal_owners_name',
  'title',
  'first_name',
  'last_name',
  'user_dob',
  'user_ssn',
  'phone_number',
  'email',
  'owner_address_1',
  'owner_address_2',
  'owner_address_city',
  'owner_address_state',
  'owner_address_zip',
  'owner_address_country',
  'meta',
  'meta.representatives.0.*',
] as const;

export type OwnerSignerInfoFields = typeof ownerSignerInfoFields[number];

export type OwnerSignerInfoFieldsValues = Pick<
  Registration,
  Exclude<OwnerSignerInfoFields, 'meta.representatives.0.*'>
> &
  Pick<Registration['meta'], 'ownership_percentage'>;

export type OwnerSignerInfoFieldsErrors =
  FormErrors<OwnerSignerInfoFieldsValues>;
