import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ResponsivePaddingProps } from './ResponsivePadding.types';

export const StyledPadding = styled.div`
  @media (min-width: 1000px) {
    padding: ${(props: ResponsivePaddingProps) => props.desktop};
  }

  @media (max-width: 1000px) {
    padding: ${(props: ResponsivePaddingProps) => props.mobile};
  }
`;

export const ResponsivePadding: FunctionComponent<
  ResponsivePaddingProps
> = props => {
  return <StyledPadding {...props} />;
};
