import moment from 'moment';
import { TooltipContentTypes } from './types';

/**
 * Formula that calculates "percentage change" metric between 2 values.
 * @param currentValue Main numeric subject. Ex: today's volume in numeric format.
 * @param previousValue Secondary value to compare against. Ex: yesterday's volume in numeric format.
 */
export function calculatePercentageChangeMetric(
  currentValue: number,
  previousValue: number
): number {
  // "Percentage Change" formula.
  const percentageDiff: number =
    ((currentValue - previousValue) / Math.abs(previousValue)) * 100;

  return percentageDiff;
}

/**
 * Gets tooltip content by type.
 * @param tooltipContentType
 */
export function getTooltipContent(
  tooltipContentType: TooltipContentTypes
): string {
  let content: string = '';

  switch (tooltipContentType) {
    case 'TODAY':
      const now = moment().format('LT');
      content = `Comparing data from start of day to ${now} yesterday and today.`;
      break;
    case 'YESTERDAY':
      content = 'Comparing data from yesterday to the day before yesterday.';
      break;
    case 'THIS_WEEK':
      content =
        'Comparing data from Sunday at 12am through the present time with data in the same period of last week.';
      break;
    case 'LAST_WEEK':
      content =
        'Comparing data from all of last week (Sun-Sat) to the entire week before that one.';
      break;
    case 'THIS_MONTH':
      content =
        'Comparing data month to date with with the previous month to the same day and time.';
      break;
    case 'LAST_MONTH':
      content = 'Comparing last month’s data to the month before it.';
      break;
    case 'THIS_YEAR':
      content =
        'Comparing data year to date with with the previous year to the same day.';
      break;
    case 'LAST_YEAR':
      content = 'Comparing last year’s data to the year before it.';
      break;
    case 'NO_DATA':
      content = 'There is no data for the previous period.';
      break;
  }

  return content;
}
