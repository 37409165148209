import React, { useState, useEffect, useContext } from 'react';
import { UnderwritingSection } from '../shared/UnderwritingSection';
import { SelectedMerchantStore } from '../../../../context';
import styled from 'styled-components';
import { Icon } from '@fattmerchantorg/truffle-components';
import { Tooltip } from '@fattmerchantorg/truffle-components';
import { DBALocationAddressFormModal } from './DBALocationAddressFormModal';
import { ModalContext } from '../../../../context';
import { formatPhoneNumber } from '../../../../util/format.util';
import { EditToggle } from '../shared/EditToggle';
export interface BillingAddressProps {}
const DivIcon = styled.div`
  padding-right: 8px;
  float: left;
`;
const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;
const DivRow = styled.div`
  width: 100%;
  display: flex;
  padding: 0 0 8px 0;
  border-bottom: 0px solid ${({ theme }) => theme.lightNeutralOutline};
`;

export function DBALocationAddress(props: BillingAddressProps) {
  const {
    dispatch,
    state: { merchantId, registration },
  } = useContext(SelectedMerchantStore);
  const { modalDispatch } = useContext(ModalContext);

  const [regState, setRegState] = useState(registration);
  const [mId, setmId] = useState(merchantId);

  useEffect(() => {
    setRegState(registration);
    setmId(merchantId);
  }, [registration, merchantId]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        component: DBALocationAddressFormModal,
        props: {
          isOpen: true,
          registration: regState,
          merchantId: mId,
          merchantDispatch: dispatch,
        },
      },
    });
  };

  const address = {
    address_1: registration?.business_location_address_1,
    address_2: registration?.business_location_address_2,
    city: registration?.business_location_address_city,
    state: registration?.business_location_address_state,
    zip: registration?.business_location_address_zip,
    country: registration?.business_location_address_country,
    phone: registration?.business_location_phone_number,
    fax: registration?.business_location_fax,
    email: registration?.business_location_email,
  };
  return (
    <UnderwritingSection
      title="DBA Location Address"
      data-testid="underwriting-dba-location-address"
      id="dbaLocationAddressCard"
    >
      <div>
        <DivRow>
          <EditToggle onClick={handleClick}>
            <Icon icon={['fas', 'pencil']} />
          </EditToggle>
          <DivIcon>
            <Tooltip content="Address">
              <Icon icon={['fas', 'map-marker-alt']} />
            </Tooltip>
          </DivIcon>
          <AddressContainer>
            <span>{address.address_1}</span>
            <span> {address.address_2}</span>
            <span>
              {address.city} {address.state}
            </span>
            <span> {address.zip}</span>
            <span> {address.country}</span>
          </AddressContainer>
        </DivRow>
        <DivRow>
          <DivIcon>
            <Tooltip content="Phone">
              <Icon icon={['fas', 'phone']} />
            </Tooltip>
          </DivIcon>
          <AddressContainer>
            <a href={address.phone ? `tel://${address.phone}` : null}>
              {formatPhoneNumber(address.phone) || '-'}
            </a>
          </AddressContainer>
        </DivRow>
        <DivRow>
          <DivIcon>
            <Tooltip content="Fax">
              <Icon icon={['fas', 'fax']} />
            </Tooltip>
          </DivIcon>
          <AddressContainer>
            <a href={address.fax ? `tel://${address.fax}` : null}>
              {formatPhoneNumber(address.fax) || '-'}
            </a>
          </AddressContainer>
        </DivRow>
        <DivRow>
          <DivIcon>
            <Tooltip content="Email">
              <Icon icon={['fas', 'envelope']} />
            </Tooltip>
          </DivIcon>
          <AddressContainer>
            <a
              href={address.email ? `mailto:${address.email}` : null}
              data-testid="uw-email"
            >
              {address.email || '-'}
            </a>
          </AddressContainer>
        </DivRow>
      </div>
    </UnderwritingSection>
  );
}
