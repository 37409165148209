import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Container } from '../datatable/table-styles';
import { SettlementsDetailsDataTable } from '../datatable/SettlementsDetailsDataTable';
import { DetailsDataTablePayouts } from '../datatable/PayoutsDetailsDataTable';
import { generateLoadingRows } from '../../../util/datatable.util';
import {
  cleanCollectionQuery,
  CollectionQuery,
  ExportCollectionQuery,
  FilterButton,
  SpinnerButton,
} from '../../shared';
import { parse, stringify } from '../../../util/api.util';
import { history } from '../../../history';
import { useLocation, useParams } from 'react-router-dom';
import { CollectionResponse } from '@fattmerchantorg/types-omni';
import { API, DB } from '@fattmerchantorg/types-engine';
import styled from 'styled-components';
import get from 'lodash/get';
import Switch from 'react-switch';
import startOfYesterday from 'date-fns/startOfYesterday';
import { endOfDay } from 'date-fns';

import {
  useAsyncEffect,
  useAuthToken,
  usePermissions,
  useToaster,
  useRouteMatchMerchantId,
  useSearchState,
} from '../../../hooks';
import {
  Checkbox,
  Dropdown,
  DropdownContent,
  Icon,
  SmallPrimaryButton,
  SmallSecondaryButton,
  StatusPill,
  Tabs,
  Text,
  Tooltip,
} from '@fattmerchantorg/truffle-components';
import { catanapi } from '../../../api/catan';
import { formatDate, toISOString } from '../../../util/date.util';
import { formatCurrency } from '../../../util';
import { SettlementApprovalResponse } from '../../../@types';
import { CSVDownload } from 'react-csv';
import copy from 'copy-to-clipboard';
import {
  ModalContext,
  SelectedMerchantStore,
  openModal,
} from '../../../context';
import { SettlementTransactionsFilterModal } from './filtermodals/SettlementTransactionsFilterModal';
import { TransactionDrawer } from './TransactionDrawer';
import { PayoutDrawer } from './PayoutDrawer';
import {
  mapSettlementStateToColor,
  mapSettlementStateToStatus,
} from '../../../util/settlement.util';
import {
  mapTransactionType,
  mapAdjustmentTransactionSubType,
  isAdjustmentTransaction,
} from '../../../util/transaction.util';
import { mapFundsType } from '../../../util/payout.util';
import { faSync } from '@fortawesome/pro-solid-svg-icons';
import { TransactionDetailDrawer } from '../../shared/AdvancedDetailDrawer/TransactionDetailDrawer';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { drawerTypes } from '../../shared/AdvancedDetailDrawer/AdvancedDetailDrawer.types';
import { mapFeeName } from '../../shared/AdvancedDetailDrawer/TransactionDetailDrawer.util';
export const PayoutsContext = React.createContext<DB.Payout[]>([
  {},
] as DB.Payout[]);

const DetailsContainer = styled.div`
  overflow: hidden;
`;
const ApprovalButton = styled(SmallPrimaryButton)`
  background-color: ${({ theme }) => theme.primaryColor};
  margin-left: 8px;
  height: 34px;
  color: ${({ theme }) => theme.black};
  &:hover {
    color: ${({ theme }) => theme.black};
  }
`;
const LightTextLink = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px 12px;

  cursor: pointer;
  width: 193px;
  height: 32px;

  background: rgba(242, 242, 242, 0.1);
  border-radius: 2px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;

const LightText = styled.div`
  width: 180px;
  height: 20px;

  /* Body/Body 2-Regular */

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;

  /* Gray/0 */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

const SectionHeaderIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.core.gray[400].hex};
  position: relative;
  top: 2px;
  margin-left: 4px;
`;

const BoldText = styled(Text)`
  color: ${({ theme, state }) => get(theme, state)};
  font-size: ${({ as }) => (as === 'h4' ? '1.5rem' : undefined)};
  font-weight: 700;
  line-height: 20px;
  text-align: right;
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.linkColor};
`;

const IconButton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  width: 16px;
  height: 30px;
  margin-bottom: 16px;
  margin-right: 8px;
`;

const CenteredCell = styled.div`
  text-align: left;

  img {
    width: 34px;
    height: 24px;
  }
`;

const ActionFrame = styled.div`
  /* Frame 1073 */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;
const EnableDecimalIcon = styled.div`
  /* ellipsis-v */

  width: 18px;
  height: 18px;
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 18px;

  text-align: center;
`;

const CardNumberSpan = styled.span`
  margin-left: 8px;
`;

const TopSection = styled.section`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto;
  grid-column-gap: 16px;
  margin: 16px 0 0px;
`;

const SectionWrap = styled.div`
  padding: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
const CustomGrid = styled.div`
  display: flex;
  align-items: center;
  width: 130px;
`;

const DivRightSeparator = styled.div`
  border-right: 1px solid ${({ theme }) => theme.lightNeutralOutline};
  margin: 0 8px;
`;
const DivRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 0 16px;
  border-bottom: 0px solid ${({ theme }) => theme.lightNeutralOutline};
`;

const HeaderDivRow = styled(DivRow)`
  align-items: center;
`;

const HederActions = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 95px;
  height: 33px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

const DivLeft = styled.div`
  text-align: left;
`;
const DivRight = styled.div`
  display: flex;
  text-align: right;

  > button > span:last-child {
    margin: 0 0 4px 4px;
  }
  max-height: 40px;
`;

const Div50 = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${DivRow}:last-of-type {
    padding: 0;
  }
`;

const SwitchDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 0 0px;
  border-bottom: 0px solid ${({ theme }) => theme.lightNeutralOutline};
`;
const DrawerText = styled.span`
  float: left;
  margin-top: 3px;
`;
const SwitchButton = styled(Switch)`
  margin-left: 20px;
`;

const PayoutTypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PayoutTypeIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledCardLogo = styled.img`
  width: 34px;
  height: auto;
`;

export const CardSection = styled.section`
  position: relative;
  background-color: #213745;
  padding: 16px 24px;
  border-radius: 2px;
  line-height: 1.5;

  p,
  span {
    font-size: 14px;
  }

  a {
    text-decoration: underline;
    color: #009bf2;
  }
`;
const SettlementDetailsContainer = styled.div`
  display: flex;

  > ${Container} {
    flex: 1;
  }
`;

const PayoutIndicatorIcon = styled.span`
  padding-left: 20px;
`;

const TopDetailDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  gap: 516px;
  height: 94px;
  background: ${({ theme }) => theme.black};
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.12);
`;

const TopHeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  height: 62px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const TopBackNav = styled.div`
  min-width: 91px;
  height: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  order: 0;
  flex-grow: 0;
`;

const TopHeaderCard = styled.div`
  margin-bottom: 16px;
`;

const NavHeaderIcon = styled(Icon)`
  position: relative;
  color: ${({ theme }) => theme.colors.status.blue[500].hex};
  top: -0.5px;
`;
const ProfileRedirectIcon = styled(Icon)`
  position: relative;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.status.blue[500].hex};
`;

const SettlementWrapperContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
  min-width: 258px;
  height: 42px;
  flex: none;
  order: 1;
  flex-grow: 0;
`;

const SettlementBottomWrapperContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const SettlementFilteredButton = styled(FilterButton)`
  padding: 0px 0px;
  line-height: 0px;
  margin-top: 0px;
  margin-left: 0px;
  float: none;
`;
const SettlementIdText = styled.div`
  width: 86px;
  height: 21px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  cursor: pointer;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.status.blue[500].hex};
  flex: none;
  order: 1;
  flex-grow: 0;
`;
const SettlementHoldDivider = styled.div`
  height: 0px;
  border: 1px solid ${({ theme }) => theme.colors.core.gray[500].hex};
  margin-bottom: 20px;
  margin-top: 24px;
`;
const SettlementWrapperTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  color: ${({ theme }) => theme.colors.core.white[0].hex};
`;

const SettlementBanner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 16px;
  gap: 16px;
  height: 36px;
  background: ${({ theme }) => theme.colors.status.red[500].hex};
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 16px 0;
`;

const SettlementWrapperNavigationText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.status.blue[500].hex};
`;

const columnsPayout = [
  {
    Header: 'ID',
    accessor: 'payout_id',
    disableSortBy: true,
  },
  {
    Header: 'Created',
    accessor: 'created_at',
    Cell: cell => <span>{formatDate(cell.value, 'MM/dd/yyyy | h:mm b')}</span>,
  },
  {
    Header: 'Reference Number',
    accessor: 'reference_number',
    disableSortBy: true,
  },
  {
    Header: 'Type',
    accessor: 'type',
    Cell: cell => {
      const isReattempt = cell.cell.row.original?.is_reattempted === true;
      return (
        <React.Fragment>
          <PayoutTypeContainer>
            <React.Fragment>{cell.value}</React.Fragment>
            <PayoutTypeIconContainer>
              {isReattempt && (
                <PayoutIndicatorIcon>
                  <Tooltip content="Manually Re-attempted">
                    <Icon icon={faSync} size="sm" color="#BDC9CC" />
                  </Tooltip>
                </PayoutIndicatorIcon>
              )}
            </PayoutTypeIconContainer>
          </PayoutTypeContainer>
        </React.Fragment>
      );
    },
  },
  {
    Header: 'State',
    accessor: 'state',
    Cell: cell => <StatusPill {...mapFundsType(cell.value)} />,
  },
  {
    Header: 'Total',
    accessor: 'amount',
    Cell: cell => <span>{formatCurrency(cell.value)}</span>,
  },
];
const pageSizeOptions = [
  { value: 10, label: 'Show 10' },
  { value: 20, label: 'Show 20' },
  { value: 50, label: 'Show 50' },
  { value: 100, label: 'Show 100' },
];

export const generateSubTypeCell = (feeDesc: string, subType?: string) => {
  return feeDesc ? feeDesc : subType ? mapFeeName(subType) : '--';
};

export const generateCardCell = (
  cardType: DB.PaymentMethod['card_brand'],
  lastFour: DB.PaymentMethod['last_four'],
  methodType: DB.PaymentMethod['method_type'],
  isDefault: boolean,
  isDrawer: boolean
) => {
  const cardTypesToImagePaths = {
    VISA: require('../../../assets/img/icons/cardbrands/visa.svg'),
    AMERICANEXPRESS: require('../../../assets/img/icons/cardbrands/amex.svg'),
    DISCOVER: require('../../../assets/img/icons/cardbrands/discover.svg'),
    MASTERCARD: require('../../../assets/img/icons/cardbrands/mastercard.svg'),
    BANK: require('../../../assets/img/icons/bankimages/bank.svg'),
  };

  const cardTypeHasImage = cardTypesToImagePaths.hasOwnProperty(
    cardType === null ? methodType : cardType
  );

  if ((!cardType && !methodType) || !cardTypeHasImage) {
    return (
      // fallback if no payment method is found
      <CenteredCell>
        <BoldText style={{ paddingLeft: '10px' }} as={'span'}>
          --
        </BoldText>
        <CardNumberSpan>{lastFour}</CardNumberSpan>
      </CenteredCell>
    );
  }

  return (
    <CenteredCell>
      <Tooltip
        content={
          cardType === null ? methodType.toUpperCase() : cardType.toUpperCase()
        }
      >
        <StyledCardLogo
          src={cardTypesToImagePaths[cardType === null ? methodType : cardType]}
        />
      </Tooltip>
      <CardNumberSpan>{`${
        isDrawer
          ? cardType === null
            ? methodType.toUpperCase() + ' ending in '
            : cardType.toUpperCase() + ' ending in '
          : ''
      }${lastFour}${isDefault ? ' (Default)' : ''}`}</CardNumberSpan>
    </CenteredCell>
  );
};

interface getSettlementTransactions {
  (query: CollectionQuery): Promise<
    CollectionResponse<API.SettlementTransaction>
  >;
}
interface getSettlementExportTransactions {
  (query: ExportCollectionQuery): Promise<
    CollectionResponse<API.SettlementTransaction>
  >;
}
interface getPayoutTransactions {
  (query: CollectionQuery): Promise<
    // TODO: replace with engine API type
    CollectionResponse<
      API.Payout &
        Pick<
          API.PaymentMethod,
          'issuing_bank_name' | 'bank_account_type' | 'person_name' | 'routing'
        > & { bank_account_number_last_four: string | null }
    >
  >;
}

export const SettlementDetails: React.FC = () => {
  const authToken = useAuthToken();
  const [transactionsLoading, setTransactionsLoading] = useState(false);
  const [showDecimalValues, setDecimalValues] = useState(false);
  const [payoutsLoading, setPayoutsLoading] = useState(false);
  const [settlementDetailsLoading, setSettlementDetailsLoading] =
    useState(false);
  const [approving, setApproving] = useState(false);
  const [settlementDetails, setSettlementDetails] = useState<
    API.Settlement | undefined
  >();
  const { search, pathname } = useLocation();
  const { toaster, toast } = useToaster();
  const { settlementId } = useParams<{ settlementId: string }>();
  const [viewTab, setViewTab] = useState('transactions');
  const [transactionDetail, setTransactionDetail] = useState(null);
  const [detailId, setDetailId] = useSearchState<string>('detailId');
  const [transactionDetailType, setTransactionDetailType] = useState(null);
  const [payoutDetail, setPayoutDetail] = useState(null);
  const { permit } = usePermissions();
  const { modalDispatch } = useContext(ModalContext);
  const { state: selectedMerchantState } = useContext(SelectedMerchantStore);
  const merchantId = useRouteMatchMerchantId();
  const allowApprovalWizard = permit('godview', 'approvalWizard', 'write');
  const isPendingApproval = settlementDetails?.state === 'PENDING';
  const isApproved = settlementDetails?.state === 'APPROVED';

  const [catanQueryData, setCatanQueryData] = useState<
    CollectionResponse<API.SettlementTransaction> | undefined
  >();

  const [catanTransactionExportData, setCatanTransactionExportData] = useState<
    CollectionResponse<API.SettlementTransaction> | undefined
  >();

  const [catanQueryDataPayouts, setCatanQueryDataPayouts] = useState<
    CollectionResponse<API.Payout> | undefined
  >();

  const query = useMemo((): CollectionQuery => {
    return {
      ...parse(search),
    };
  }, [search]);

  const handleQueryChange = useCallback(
    (newQuery: Partial<CollectionQuery> = {}) => {
      const newSearch = stringify(
        cleanCollectionQuery({ ...query, ...newQuery })
      );
      history.push(`${pathname}?${newSearch}`);
    },
    [query, pathname]
  );

  const getTransactionData: getSettlementTransactions = useCallback(
    collectionQuery =>
      catanapi.get(authToken, `/settlements/${settlementId}/transactions`, {
        ...collectionQuery,
        startDate: toISOString(collectionQuery.startDate),
        endDate: toISOString(collectionQuery.endDate),
        // timezone,
      }),
    [authToken, settlementId]
  );

  const getExportTransactionData: getSettlementExportTransactions = useCallback(
    ExportCollectionQuery =>
      catanapi.get(
        authToken,
        `/settlements-export/${settlementId}/transactions`,
        {
          ...ExportCollectionQuery,
          startDate: toISOString(ExportCollectionQuery.startDate),
          endDate: toISOString(ExportCollectionQuery.endDate),
          // timezone,
        }
      ),
    [authToken, settlementId]
  );

  const getPayoutData: getPayoutTransactions = useCallback(
    collectionQuery =>
      catanapi.get(authToken, `/settlements/${settlementId}/payouts`, {
        ...collectionQuery,
        // timezone,
      }),
    [authToken, settlementId]
  );
  const getSettlementTransactionsData = async () => {
    try {
      const { page, per_page, ...settlementExportQuery } = query;
      const exportData = await getExportTransactionData(settlementExportQuery);
      setCatanTransactionExportData(exportData);
    } catch (e) {
      setCatanTransactionExportData(undefined);
    } finally {
      setCatanTransactionExportData(undefined);
    }
  };

  const getData = async () => {
    if (viewTab === 'transactions') {
      await fetchTransactions();
    } else if (viewTab === 'funds') {
      await fetchPayouts();
    }
  };

  const fetchTransactions = async () => {
    setTransactionsLoading(true);
    delete query['detailId'];
    try {
      const data = await getTransactionData(query);
      setCatanQueryData(data);
    } catch (e) {
      console.log('error', e);
    }
    setTransactionsLoading(false);
  };

  const fetchPayouts = async () => {
    setPayoutsLoading(true);
    try {
      const payoutData = await getPayoutData({});
      setCatanQueryDataPayouts(payoutData);
    } catch (e) {}
    setPayoutsLoading(false);
  };

  useAsyncEffect(async () => {
    await fetchSettlementDetails();
  }, [authToken, settlementId]);

  useAsyncEffect(async () => {
    await getData();
  }, [authToken, search, pathname]);

  useAsyncEffect(async () => {
    if (catanQueryData || catanQueryDataPayouts) {
      if (
        viewTab === 'funds' &&
        (!catanQueryDataPayouts?.data ||
          catanQueryDataPayouts?.data?.length === 0)
      ) {
        await fetchPayouts();
      } else if (
        viewTab === 'transactions' &&
        (!catanQueryData?.data || catanQueryData?.data?.length === 0)
      ) {
        await fetchTransactions();
      }
    }
  }, [viewTab]);

  useAsyncEffect(async () => {
    if (catanQueryData && detailId) {
      const transaction = await catanapi.get(
        authToken,
        `/settlement-transaction/${detailId}`
      );
      if (transaction) {
        setTransactionDetail(transaction);
        setTransactionDetailType(
          formatTransactionType(transaction as API.Transaction)
        );
      }
    }
  }, [catanQueryData, detailId]);

  const canHoldSettlement = () => {
    const canApprove =
      settlementDetails?.state !== 'APPROVED' &&
      permit('godview', 'overrideRiskHold', 'write');
    const isHoldable =
      settlementDetails?.state === 'FUNDED' ||
      settlementDetails?.state === 'APPROVED' ||
      settlementDetails?.state === 'REJECTED';
    return canApprove && !isHoldable;
  };

  const { available: hasAdvancedDetailDrawer } = useFeatureFlag(
    'AdvancedDetailDrawer',
    'StaxConnect'
  );

  let dropdownToggler: React.Dispatch<React.SetStateAction<boolean>>;
  function closeDropdown(state) {
    if (typeof dropdownToggler === 'function') {
      setDecimalValues(state);
      dropdownToggler(false);
    }
  }
  const columns = [
    {
      id: 'selection',
      Header: ({ getToggleAllRowsSelectedProps }) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Checkbox
            {...getToggleAllRowsSelectedProps()}
            customStyles={{ wrapper: { marginBottom: '0' } }}
          />
        </div>
      ),
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Checkbox
            {...row.getToggleRowSelectedProps()}
            customStyles={{ wrapper: { marginBottom: '0' } }}
          />
        </div>
      ),
      disableSortBy: true,
      style: {
        width: 38,
        padding: 0,
        textAlign: 'center',
      },
    },
    {
      Header: 'Batch Date',
      accessor: 'batched_at',
      Cell: cell => (
        <span>{formatDate(cell.value, 'MM/dd/yyyy | h:mm b') ?? '--'}</span>
      ),
      loadingWidth: '9rem',
    },
    {
      Header: 'Processor',
      accessor: 'processor_name',
      loadingWidth: '5rem',
    },
    {
      Header: 'MID',
      accessor: 'processor_mid',
      loadingWidth: '15rem',
    },
    {
      Header: 'Type',
      accessor: 'type',
      Cell: cell => <StatusPill {...mapTransactionType(cell.value)} />,
      loadingWidth: '5rem',
    },
    {
      Header: 'SubType',
      accessor: 'fee_description',
      Cell: cell => (
        <span>
          {isAdjustmentTransaction(cell.row.original as DB.Transaction)
            ? mapAdjustmentTransactionSubType(cell.value)
            : generateSubTypeCell(
                cell.value,
                get(cell.row.original, 'subtype', null)
              )}
        </span>
      ),
      loadingWidth: '5rem',
    },
    {
      Header: 'Payment Method',
      accessor: 'last_four',
      disableSortBy: true,
      Cell: cell => {
        return generateCardCell(
          get(cell.row.original, 'card_brand', null),
          cell.value,
          get(cell.row.original, 'method_type', null),
          false,
          false
        );
      },
      loadingWidth: '8rem',
    },
    {
      Header: 'Total',
      accessor: 'amount',
      style: {
        textAlign: 'right',
      },
      Cell: cell => (
        <span>
          {showDecimalValues
            ? '$' + cell.value?.toFixed(5)
            : formatCurrency(cell.value)}
        </span>
      ),
      loadingWidth: '7rem',
      loadingWidthstyle: {
        float: 'right',
        marginLeft: '1rem',
      },
    },
  ];
  const formatTransactionType = (transaction: API.Transaction) => {
    return (): keyof typeof drawerTypes => {
      if (isAdjustmentTransaction(transaction)) {
        return 'Adjustment';
      }
      switch (transaction.type) {
        case 'CHARGE':
          return 'Transaction';
        case 'REFUND':
          return 'Refund';
        case 'FEE':
        case 'ACHRETURN':
          return 'Fee';
        case 'RELEASE':
        case 'HOLD':
          return 'DisputeTransaction';
        default:
          return 'Transaction';
      }
    };
  };

  return (
    <div>
      <DetailsContainer>
        <TopDetailDiv>
          <TopHeaderLeft>
            <TopBackNav onClick={navigateBack}>
              <NavHeaderIcon icon={['fas', 'caret-left']} />
              <SettlementWrapperNavigationText>
                Settlements
              </SettlementWrapperNavigationText>
            </TopBackNav>
            <SettlementWrapperContainer>
              <SettlementWrapperTitle>
                {settlementDetails?.company_name}
              </SettlementWrapperTitle>
              <ProfileRedirectIcon
                icon={['fas', 'external-link']}
                onClick={() => redirectToMerchantDetail()}
              />
            </SettlementWrapperContainer>
          </TopHeaderLeft>
          <CustomGrid>
            <div>
              <ActionFrame
                style={{
                  paddingRight:
                    allowApprovalWizard &&
                    (isPendingApproval || isApproved) &&
                    '58%',
                }}
              >
                <SmallSecondaryButton
                  icon={['fa', 'download']}
                  onClick={getSettlementTransactionsData}
                >
                  <span style={{ padding: '2px' }}>Export</span>
                </SmallSecondaryButton>
                <HederActions>
                  {catanTransactionExportData?.data != null ? (
                    <CSVDownload
                      style={{
                        color: 'white',
                        textDecoration: 'none',
                      }}
                      data={catanTransactionExportData?.data ?? []}
                      filename="settlements-exports.csv"
                      target="_blank"
                    />
                  ) : null}
                  {allowApprovalWizard && (isPendingApproval || isApproved) && (
                    <ApprovalButton
                      disabled={
                        settlementDetails?.state === 'APPROVED' || approving
                      }
                      icon={!approving && ['fas', 'thumbs-up']}
                      onClick={async () =>
                        await onApprove(settlementDetails.settlement_id)
                      }
                    >
                      {approving ? <SpinnerButton /> : <span>Approve</span>}
                    </ApprovalButton>
                  )}
                  <EnableDecimalIcon
                    style={{
                      paddingLeft: '8px',
                    }}
                  >
                    <Dropdown
                      placement="bottom-end"
                      trigger={(setRef, isOpen, setIsOpen) => (
                        <IconButton
                          ref={setRef}
                          style={{ marginLeft: 'auto' }}
                          onClick={e => {
                            dropdownToggler = setIsOpen;
                            // Prevents drawer menu from opening when clicking button
                            e.stopPropagation();
                            setIsOpen(!isOpen);
                          }}
                          data-testid="header-question-mark-trigger"
                        >
                          <Icon
                            style={{ color: '#FFFFFF' }}
                            icon={['fas', 'ellipsis-v']}
                          ></Icon>
                        </IconButton>
                      )}
                    >
                      <DropdownContent
                        style={{ minWidth: '193px', width: '32px' }}
                      >
                        <LightTextLink
                          onClick={() =>
                            showDecimalValues
                              ? closeDropdown(false)
                              : closeDropdown(true)
                          }
                        >
                          <LightText>
                            {showDecimalValues
                              ? 'Hide Fractional Amounts'
                              : 'Show Fractional Amounts'}
                          </LightText>
                        </LightTextLink>
                      </DropdownContent>
                    </Dropdown>
                  </EnableDecimalIcon>
                </HederActions>
              </ActionFrame>
            </div>
            <div></div>
          </CustomGrid>
        </TopDetailDiv>
        {settlementDetails?.risk_hold ? (
          <SettlementBanner>
            <div>
              <Icon icon={['fas', 'exclamation-triangle']} />
            </div>
            <div>This merchant is on a Risk Hold</div>
          </SettlementBanner>
        ) : (
          <TopHeaderCard />
        )}

        <SettlementDetailsContainer>
          <Container>
            <TopSection
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
              className={`${settlementDetailsLoading ? 'loading' : ''}`}
            >
              <CardSection>
                <SectionWrap>
                  <HeaderDivRow>
                    <DivLeft>
                      <BoldText
                        as={'h4'}
                        state={`colors.status.${mapSettlementStateToColor(
                          settlementDetails?.state
                        )}[500].hex`}
                      >
                        {mapSettlementStateToStatus(settlementDetails?.state)
                          ?.label ?? settlementDetails?.state}
                      </BoldText>
                    </DivLeft>
                    <DivRight onClick={onCopySettlementId}>
                      <IconButton>
                        <Tooltip
                          content={
                            <span>
                              Copy <strong>Settlement ID</strong> to Clipboard
                              <br />
                              {`${settlementDetails?.settlement_id}`}
                            </span>
                          }
                        >
                          <StyledIcon icon={['fas', 'copy']} />
                        </Tooltip>
                      </IconButton>
                      <SettlementIdText>Settlement ID</SettlementIdText>
                    </DivRight>
                  </HeaderDivRow>
                </SectionWrap>
                <SectionWrap>
                  <Div50>
                    <DivRow>
                      <DivLeft>
                        <span>Batch Date</span>
                      </DivLeft>
                      <DivRight>
                        <span>
                          {formatDate(
                            settlementDetails?.created_at,
                            'MM/dd/yyyy'
                          )}
                        </span>
                        {/* Update */}
                      </DivRight>
                    </DivRow>
                    <DivRow>
                      <DivLeft>
                        <span>Gross Total</span>
                      </DivLeft>
                      <DivRight>
                        <span>
                          {showDecimalValues
                            ? '$' + settlementDetails?.gross_total?.toFixed(5)
                            : formatCurrency(settlementDetails?.gross_total)}
                        </span>
                      </DivRight>
                    </DivRow>
                    <DivRow>
                      <DivLeft>
                        <span>Net Total</span>
                      </DivLeft>
                      <DivRight>
                        <span>
                          {showDecimalValues
                            ? '$' +
                              Math.abs(settlementDetails?.net_total)?.toFixed(5)
                            : formatCurrency(settlementDetails?.net_total)}
                        </span>
                      </DivRight>
                    </DivRow>
                  </Div50>
                  <DivRightSeparator />
                  <Div50>
                    <DivRow>
                      <DivLeft>
                        <span>Refunds </span>
                      </DivLeft>
                      <DivRight>
                        <span>
                          {showDecimalValues
                            ? '$' + settlementDetails?.refund_amount?.toFixed(5)
                            : formatCurrency(settlementDetails?.refund_amount)}
                        </span>
                      </DivRight>
                    </DivRow>

                    <DivRow>
                      <DivLeft>
                        <span>Fees</span>
                      </DivLeft>
                      <DivRight>
                        <span>
                          {showDecimalValues
                            ? '$' + settlementDetails?.fees?.toFixed(5)
                            : formatCurrency(settlementDetails?.fees)}
                        </span>
                      </DivRight>
                    </DivRow>
                    <DivRow>
                      <DivLeft>
                        <span>
                          Chargebacks
                          <Tooltip
                            content={
                              'Chargebacks include both ACH clawbacks and credit card disputes.'
                            }
                          >
                            <SectionHeaderIcon icon={['far', 'info-circle']} />
                          </Tooltip>
                        </span>
                      </DivLeft>
                      <DivRight>
                        {showDecimalValues ? (
                          <span>$0.00000</span>
                        ) : (
                          <span>$0.00</span>
                        )}
                        {/* Update */}
                      </DivRight>
                    </DivRow>
                  </Div50>
                </SectionWrap>
                <SettlementHoldDivider />
                <SwitchDiv>
                  <DivLeft>
                    <DrawerText>Settlement Hold</DrawerText>
                    <SwitchButton
                      id="risk-hold-switch"
                      className="react-switch"
                      data-testid="risk-hold-switch"
                      checked={evaluateHoldState()}
                      onChange={() => handleEnableRiskHold()}
                      offColor={canHoldSettlement() ? '#435E70' : '#627684'}
                      onColor={canHoldSettlement() ? '#75D697' : '#C4EDD3'}
                      offHandleColor={
                        canHoldSettlement() ? '#8D9799' : '#BDC9CC'
                      }
                      onHandleColor="#FFFFFF"
                      boxShadow="inset 0px 1px 2px rgba(64, 71, 80, 0.3)"
                      height={25}
                      width={52}
                      handleDiameter={17}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      disabled={!canHoldSettlement()}
                    />
                  </DivLeft>
                </SwitchDiv>
              </CardSection>
            </TopSection>
            <SettlementBottomWrapperContainer>
              <div>
                <Tabs
                  options={[
                    { label: 'Transactions', value: 'transactions' },
                    { label: 'Funds', value: 'funds' },
                  ]}
                  defaultSelected={viewTab}
                  onChange={(e, option: { label; value }) => {
                    setViewTab(option.value);
                  }}
                />
              </div>
              <div>
                {viewTab === 'transactions' ? (
                  <SettlementFilteredButton
                    onClick={showTransactionsFiltersModal}
                  >
                    <Icon icon={['fas', 'filter']} />
                    <span>Filter</span>
                  </SettlementFilteredButton>
                ) : (
                  <div></div>
                )}
              </div>
            </SettlementBottomWrapperContainer>
            <div>
              {viewTab === 'transactions' && (
                <>
                  <SettlementsDetailsDataTable
                    data={{
                      columns,
                      rows: transactionsLoading
                        ? generateLoadingRows()
                        : catanQueryData?.data ?? [],
                    }}
                    modalDispatch={modalDispatch}
                    settlement={settlementDetails}
                    authToken={authToken}
                    count={catanQueryData?.last_page ?? 0}
                    total={catanQueryData?.total ?? 0}
                    currentPage={catanQueryData?.current_page ?? 0}
                    defaultPageSize={catanQueryData?.per_page ?? 20}
                    lastPage={catanQueryData?.last_page ?? 0}
                    loading={transactionsLoading}
                    pageSizeOptions={pageSizeOptions}
                    handleQueryChange={handleQueryChange}
                    onItemClick={i => {
                      setTransactionDetail(i);
                      setTransactionDetailType(
                        formatTransactionType(i as API.Transaction)
                      );
                    }}
                    onRemove={async transactionIds => {
                      const res = await catanapi.post<{
                        updated_settlement_id: string;
                      }>(
                        authToken,
                        `/settlements/${settlementId}/remove-transactions`,
                        { transaction_ids: transactionIds }
                      );
                      history.push(
                        `/settlements/${res.updated_settlement_id}/details`
                      );
                    }}
                    openSettlement={
                      settlementDetails?.state === 'OPEN' || false
                    }
                    onReloadTransactions={fetchTransactions}
                    onReloadSettlementDetails={fetchSettlementDetails}
                    selectedMerchant={selectedMerchantState}
                  />
                  {hasAdvancedDetailDrawer ? (
                    <TransactionDetailDrawer
                      open={transactionDetail !== null}
                      onClose={() => {
                        setDetailId(null);
                        setTransactionDetail(null);
                      }}
                      configType={transactionDetailType || 'Transaction'}
                      getData={null}
                      data={
                        transactionDetail !== null ? transactionDetail : null
                      }
                      isSettlement={true}
                    />
                  ) : (
                    <TransactionDrawer
                      open={transactionDetail !== null}
                      onClose={() => setTransactionDetail(null)}
                      getData={
                        transactionDetail !== null ? transactionDetail : null
                      }
                    />
                  )}
                </>
              )}

              {viewTab === 'funds' && (
                <>
                  <PayoutsContext.Provider
                    value={catanQueryDataPayouts?.data ?? []}
                  >
                    <DetailsDataTablePayouts
                      data={{
                        columnsPayout,
                        rows: payoutsLoading
                          ? generateLoadingRows()
                          : catanQueryDataPayouts?.data ?? [],
                      }}
                      count={catanQueryDataPayouts?.last_page ?? 0}
                      total={catanQueryDataPayouts?.total ?? 0}
                      currentPage={catanQueryData?.current_page ?? 0}
                      defaultPageSize={catanQueryData?.per_page ?? 20}
                      lastPage={catanQueryData?.last_page ?? 0}
                      loading={payoutsLoading}
                      pageSizeOptions={pageSizeOptions}
                      handleQueryChange={handleQueryChange}
                      handleDataRefresh={fetchPayouts}
                      onItemClick={i => {
                        setPayoutDetail(i);
                      }}
                    />
                    <PayoutDrawer
                      open={payoutDetail !== null}
                      onClose={() => setPayoutDetail(null)}
                      payoutDetail={payoutDetail !== null ? payoutDetail : null}
                      settlementDetail={settlementDetails}
                    />
                  </PayoutsContext.Provider>
                </>
              )}
            </div>
          </Container>
        </SettlementDetailsContainer>
      </DetailsContainer>
    </div>
  );

  async function onApprove(settlement_id: API.Settlement['settlement_id']) {
    const settlements: Pick<API.Settlement, 'settlement_id' | 'state'>[] = [
      {
        settlement_id: settlement_id,
        state: 'APPROVED',
      },
    ];

    setApproving(true);
    try {
      const response = await catanapi.patch<SettlementApprovalResponse>(
        authToken,
        '/settlements',
        {
          settlements,
        }
      );

      if (response.successCount > 0) {
        setSettlementDetails({ ...settlementDetails, state: 'APPROVED' });

        toaster(
          toast.success(
            `Settlement (${settlement_id}) has been approved successfully`,
            'Settlement Approved'
          )
        );
      } else {
        toaster(
          toast.error(
            `Settlement (${settlement_id}) could not be approved`,
            'Settlement Not Approved'
          )
        );
      }
    } catch (e) {
      toaster(
        toast.error(
          'Settlement approval failed. Please try again',
          'OOps, Something went wrong'
        )
      );
    } finally {
      setApproving(false);
    }
  }

  async function onCopySettlementId() {
    copy(settlementDetails.settlement_id);
    toaster(
      toast.success(`${settlementDetails.settlement_id} copied to clipboard`)
    );
  }

  async function showTransactionsFiltersModal() {
    modalDispatch(
      openModal(SettlementTransactionsFilterModal, {
        isOpen: true,
        query,
        handleQueryChange,
        getData,
        authToken,
        catanQueryData,
      })
    );
  }

  async function handleEnableRiskHold() {
    const holdState = evaluateHoldState();
    const holdStateVal = holdState ? 'PENDING' : 'HOLD';

    const settlements: Pick<API.Settlement, 'settlement_id' | 'state'>[] = [
      {
        settlement_id: settlementDetails?.settlement_id,
        state: holdStateVal,
      },
    ];
    try {
      const response = await catanapi.patch<SettlementApprovalResponse>(
        authToken,
        '/settlements',
        {
          settlements,
        }
      );

      const actionableSettlementState = holdState ? 'released' : 'held';

      if (response.successCount > 0) {
        setSettlementDetails({ ...settlementDetails, state: holdStateVal });
        toaster(
          toast.success(
            `Settlement (${settlementDetails.settlement_id}) has been ${actionableSettlementState} successfully`,
            `Settlement ${actionableSettlementState}`
          )
        );
      } else {
        toaster(
          toast.error(
            `Settlement (${settlementDetails.settlement_id}) could not be ${actionableSettlementState}.`,
            `Unable to ${actionableSettlementState} Settlement`
          )
        );
      }
    } catch (e) {
      toaster(
        toast.error(
          `There was an error ${
            holdState ? 'releasing' : 'holding'
          } the settlement. Please try again`,
          'Oops, Something went wrong'
        )
      );
    }
  }

  function evaluateHoldState() {
    return settlementDetails?.state === 'HOLD';
  }

  function redirectToMerchantDetail() {
    const merchantId = get(settlementDetails, 'merchant_id');
    if (merchantId) {
      const url = `/#/merchant/${merchantId}/businessinfo`;
      window.open(url, '_blank');
    }
  }

  async function fetchSettlementDetails() {
    setSettlementDetailsLoading(true);
    try {
      const settlement = await catanapi.get(
        authToken,
        `/settlements/${settlementId}`
      );
      setSettlementDetails(settlement);
      setSettlementDetailsLoading(false);
    } catch (error) {
      if (error.status === 404) {
        setSettlementDetailsLoading(false);
        navigateBack();
      }
    }
  }

  function navigateBack() {
    let url = '';
    // if the merchant Id exists, send back to the merchant/:merchantId/settlement route
    if (merchantId) {
      url = `/merchant/${merchantId}/settlements`;
    } else {
      let settlementUrl = localStorage.getItem('settlement.parenturl');
      if (settlementUrl) {
        url = `/settlements${'?' + settlementUrl}`;
        localStorage.setItem('settlement.parenturl', '');
      } else {
        url = `/settlements${
          '?' +
          stringify({
            startDate: formatDate(startOfYesterday(), 'yyyy-MM-dd'),
            endDate: formatDate(endOfDay(new Date()), 'yyyy-MM-dd'),
            approvalState: ['ALL'],
            page: 1,
          })
        }`;
      }
    }
    history.push(url);
  }
};
