import React, { FunctionComponent, useContext, useState } from 'react';
import Switch from 'react-switch';
import styled from 'styled-components';
import {
  Import,
  ImportSummaryWarning,
  ImportSummaryError,
  ImportRecordWarningType,
} from '../../../../@types';
import { formatTitleCase } from '../../../../util/format.util';
import { Group } from '../../../shared';
import { ImporterCard } from './';
import { ImportStore, updateImport } from '../../../../context';
import { useAuthToken } from '../../../../hooks';
import { importerapi } from '../../../../api';

type ImporterExceptionCardProps = {
  record: Import;
  type: 'warnings' | 'errors';
};

const Label = styled.label`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  text-align: left;
  margin-top: 0.5rem;

  > span {
    margin-right: 0.5rem;
  }
`;

const ExceptionCard = styled(ImporterCard)`
  padding: 0;

  > div {
    padding: 1rem;
    min-height: 110px;

    &:not(:last-child) {
      border-bottom: 1px solid #f2f3f6;
    }

    i {
      padding-right: 1rem;
      font-size: 36px;
    }
  }
`;

const mapWarningTypeToDescription = (type: ImportRecordWarningType): string => {
  switch (type) {
    case 'EXPIRED_CARD':
    case 'INVALID_EXPIRATION_DATE':
      return 'The account updater will attempt to update cards automatically after importing.';
    case 'MISSING_ACCOUNT_TYPE':
      return 'We will automatically populate "Checking" as the account type for all records missing this value.';
    case 'MISSING_NAME':
      return 'We will automatically fill in missing names with "Firstname" or "Lastname" depending on what is missing.';
    case 'INVALID_EMAIL':
    case 'INVALID_PHONE':
    case 'MISSING_ZIP':
    case 'MISSING_ACCOUNT_HOLDER':
    default:
      return 'These will be imported but may need to be manually fixed later. This will not affect the ability to process transactions.';
  }
};

const ExceptionItem: FunctionComponent<
  { record: Import } & (
    | {
        type: 'warning';
        exception: ImportSummaryWarning;
      }
    | {
        type: 'error';
        exception: ImportSummaryError;
      }
  )
> = props => {
  const {
    dispatch,
    state: { record },
  } = useContext(ImportStore);
  const { type, exception } = props;
  const title = exception?.type ?? '';
  const count = exception?.count ?? 0;
  const authToken = useAuthToken();
  const [includeExpired, setIncludeExpired] = useState(
    record.summary.include_expired_cards || false
  );

  // Don't show the card if there are no instances of it
  if (count === 0) {
    return null;
  }

  return (
    <Group>
      {type === 'error' ||
      (title === 'EXPIRED_CARD' && includeExpired === false) ? (
        <i className="fas fa-times-circle" style={{ color: '#e21937' }} />
      ) : (
        <i
          className="fas fa-exclamation-triangle"
          style={{ color: '#efc201' }}
        />
      )}
      <Group direction="column">
        <strong>
          {formatTitleCase(title.replace(/_/g, ' '))} - {count}
        </strong>
        {title === 'EXPIRED_CARD' && record.state !== 'COMPLETE' ? (
          <Label>
            <span>{includeExpired ? 'INCLUDED' : 'EXCLUDED'}</span>
            <Switch
              checked={includeExpired}
              onChange={async (v: boolean) => {
                const r = await importerapi.put(
                  authToken,
                  `/import/${record.id}`,
                  { ...record, summary: { include_expired_cards: v } }
                );
                dispatch(updateImport(r));
                setIncludeExpired(v);
              }}
              offColor="#062333"
              onColor={'#75D697'}
              offHandleColor="#8D9799"
              onHandleColor="#fff"
              height={25}
              width={52}
              handleDiameter={17}
              uncheckedIcon={false}
              checkedIcon={false}
            />
          </Label>
        ) : (
          <p>
            {type === 'error'
              ? record.state === 'COMPLETE'
                ? 'These records were omitted from the import'
                : 'These records will be omitted from the import'
              : mapWarningTypeToDescription(
                  exception.type as ImportRecordWarningType
                )}
          </p>
        )}
      </Group>
    </Group>
  );
};

export const ImporterExceptionCard: FunctionComponent<
  ImporterExceptionCardProps
> = props => {
  const { record, type } = props;
  const exceptions = record?.summary[type];

  if (!exceptions?.length) {
    return null;
  }

  return (
    <ExceptionCard>
      {(exceptions as any[]).map((e, i) => (
        <ExceptionItem
          key={`exception-${i}`}
          exception={e}
          type={type.replace('s', '') as any}
          record={record}
        />
      ))}
    </ExceptionCard>
  );
};
