import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useClickaway } from '../../../hooks/useClickaway';

const FloatingStatusMenu = styled.div`
  border: 1px solid #435e70;
  background: #213745;
  padding: 6px 16px 0 8px;
  font-family: 'Roboto';
  font-size: 12px;
  border-radius: 2px;
  position: absolute;
  top: 14px;
  left: 8px;
  z-index: 1000;

  max-height: 40vh;
  overflow-y: scroll;

  ul {
    display: absolute;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      label {
        text-transform: capitalize;
        color: #fff;
      }
    }
  }
`;

const StatusFilterWrapper = styled.span`
  position: relative;
`;

const UnstyledButton = styled.button`
  background: 0;
  border: 0;
  padding: 0 8px;
  color: #8898aa;

  &[data-has-filters='true'] {
    color: ${({ theme }) => theme.colors.status.blue[500].hex};
  }
`;

export const Filter: FC<{
  onClose?: () => void;
  hasFilters?: boolean;
}> = props => {
  const { onClose, hasFilters } = props;
  const [isOpen, setIsOpen] = useState(false);

  const clickAwayArea = useClickaway(() => {
    setIsOpen(false);
    onClose();
  }, 'ignoreMeOnClickaway');

  return (
    <StatusFilterWrapper data-testid="shared-filter-component">
      <UnstyledButton
        data-testid="shared-filter-button"
        data-has-filters={hasFilters}
        className="ignoreMeOnClickaway"
        onClick={() => setIsOpen(v => !v)}
      >
        <i className="fas fa-filter ignoreMeOnClickaway" />
      </UnstyledButton>
      {isOpen && (
        <FloatingStatusMenu ref={clickAwayArea}>
          {props.children ? <ul>{props.children}</ul> : null}
        </FloatingStatusMenu>
      )}
    </StatusFilterWrapper>
  );
};

export default Filter;
