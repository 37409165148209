import {
  Dropdown,
  DropdownContent,
  Icon,
  Text,
} from '@fattmerchantorg/truffle-components';
import { startOfYesterday } from 'date-fns/esm';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Navbar as RSNavBar } from 'reactstrap';
import styled, { withTheme } from 'styled-components';
import { stringify } from '../../../util/api.util';
import { endOfDay, formatDate } from '../../../util/date.util';
import { Themed } from '../../shared/Themed.types';

interface AnchorProps
  extends Themed,
    React.DetailedHTMLProps<
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    > {}

const StyledNavbar = withTheme(
  styled(RSNavBar)`
    height: 4rem;
    display: none;
    background: ${({ theme }) => theme.black};

    .container-fluid {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    @media (min-width: 768px) {
      display: flex;
    }

    box-shadow: 0 4px 10px rgb(0, 0, 0, 0.5);
  `
);

const StyledBackIcon = styled(Icon)`
  color: ${({ theme }) => theme.linkColor};
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  width: 16px;

  > h5 {
    margin: 0 0 0 8px;
  }

  > span > svg {
    margin-right: 0;
  }
`;

const ContextMenu = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li a,
  li button {
    display: block;
    background: none;
    border: 0;
    padding: 4px 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    text-align: left;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.core.white[0].hex};

    &:hover {
      background: ${({ theme }) => theme.component.table.borderColor};
    }
  }
`;

const IconButton = styled.button`
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.core.white[0].hex};
`;

const LightTextLink: React.FunctionComponent<AnchorProps> = withTheme(props => {
  const { theme, children, style, href, target, ...rest } = props;
  return (
    <a
      {...rest}
      href={href}
      target={target}
      style={{ color: '#fff', ...style }}
    >
      {children}
    </a>
  );
});

export const Navbar = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  let dropdownToggler: React.Dispatch<React.SetStateAction<boolean>>;

  return (
    <StyledNavbar className="navbar-dark" expand="md" id="navbar-main">
      {/* 
      // TODO: implement this
      <Container fluid>
        <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
          <FormGroup className="mb-0">
            <OmniSearch className="input-group-alternative" />
          </FormGroup>
        </Form>
      </Container> */}

      <Container
        fluid
        id="demo-brand-switcher"
        data-testid="demo-brand-switcher"
      >
        {pathname.match(/\/settlements\/.+\/details/) && (
          //Redirects to the settlement page from detail page once clicked
          <BackButton
            onClick={() => {
              let settlementUrl = localStorage.getItem('settlement.parenturl');
              let url = '';
              if (settlementUrl) {
                url = `/settlements${'?' + settlementUrl}`;
                localStorage.setItem('settlement.parenturl', '');
              } else {
                url = `/settlements${
                  '?' +
                  stringify({
                    startDate: formatDate(startOfYesterday(), 'yyyy-MM-dd'),
                    endDate: formatDate(endOfDay(new Date()), 'yyyy-MM-dd'),
                    approvalState: ['ALL'],
                    page: 1,
                  })
                }`;
              }
              history.push(url);
            }}
          >
            <StyledBackIcon icon={['fas', 'chevron-left']} />
            <Text as={'h5'}>Settlements</Text>
          </BackButton>
        )}
      </Container>

      {/** Header navigation bar question mark menu with documentation links */}
      <Container>
        <Dropdown
          placement="bottom-end"
          trigger={(setRef, isOpen, setIsOpen) => (
            <IconButton
              ref={setRef}
              style={{ marginLeft: 'auto' }}
              onClick={e => {
                dropdownToggler = setIsOpen;
                e.stopPropagation();
                setIsOpen(!isOpen);
              }}
              data-testid="header-question-mark-trigger"
            >
              <Icon icon={['fas', 'question-circle']} />
            </IconButton>
          )}
        >
          <DropdownContent style={{ minWidth: '190px', width: 'auto' }}>
            <ContextMenu>
              <ul>
                <li>
                  <LightTextLink
                    target="_blank"
                    href="https://support.fattmerchant.com/stax-connect"
                    onClick={e => {
                      dropdownToggler(false);
                      e.stopPropagation();
                    }}
                  >
                    Knowledge Base
                  </LightTextLink>
                </li>
                <li>
                  <LightTextLink
                    target="_blank"
                    href="https://staxapi.docs.apiary.io/#introduction/docs"
                    onClick={e => {
                      dropdownToggler(false);
                      e.stopPropagation();
                    }}
                  >
                    Stax API Reference
                  </LightTextLink>
                </li>
                <li>
                  <LightTextLink
                    target="_blank"
                    href="https://docs.staxpayments.com/"
                    onClick={e => {
                      dropdownToggler(false);
                      e.stopPropagation();
                    }}
                  >
                    Stax API Documentation
                  </LightTextLink>
                </li>
              </ul>
            </ContextMenu>
          </DropdownContent>
        </Dropdown>
      </Container>
    </StyledNavbar>
  );
};
