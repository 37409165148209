import React, { FC, FunctionComponent } from 'react';
import styled from 'styled-components';
import {
  Drawer,
  Icon,
  StatusPill,
  Text,
} from '@fattmerchantorg/truffle-components';
import { DrawerProps } from '@fattmerchantorg/truffle-components/dist/Drawer/Drawer';
import { CopyButton, Id, Separator as USeparator } from '../../shared';
import { formatCurrency } from '../../../util/format.util';
import { formatDate } from '../../../util/date.util';
import {
  PayoutWithMethodDetail,
  Settlement,
} from '@fattmerchantorg/types-engine/API';
import { mapFundsType } from '../../../util/payout.util';

const Section = styled.div`
  padding: 16px 16px 0;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:first-child) {
    margin-top: 16px;
  }

  small {
    font-size: 12px;
  }

  > *:not(:first-child) {
    margin-left: 8px;
  }
`;

const LineItemRow = styled(Row)`
  > *:last-child {
    margin-left: auto;
  }
`;

const DateRowContainer = styled(LineItemRow)`
  align-items: baseline;

  > small {
    margin-left: 2px !important;
  }
`;

const NoMarginSpan = styled.span`
  margin-left: -17px !important;
`;

const Separator = styled(USeparator)`
  padding-top: 20px;
`;

const DrawerBgColor = styled(Drawer)`
  background-color: #213745;
  border-radius: 0;
`;

const BankAccountDetailsRow = styled(Row)`
  font-size: 14px;
`;

const BankAccountDetailsRowValue = styled.span`
  padding-left: 10px;
  font-weight: bold;
`;

const DateRow: FunctionComponent<{
  payout?: PayoutWithMethodDetail;
}> = props => {
  return (
    <DateRowContainer>
      <NoMarginSpan>
        {formatDate(props?.payout?.created_at, 'MM/dd/yyyy | h:mm b')}
      </NoMarginSpan>
      {props.children}
    </DateRowContainer>
  );
};

const AchReturnReasonNotification = styled.div`
  margin: 10px -16px;
  padding: 10px 16px;
  font-weight: 700;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.status.red[500].hex};
  > span {
    display: inline-block;
    margin-right: 10px;
  }
`;

const BankAccountDetails: FunctionComponent<{
  payout?: PayoutWithMethodDetail;
}> = ({ payout }) => {
  if (!payout) {
    return null;
  }
  return (
    <>
      <Text as="h5">Bank Account Details</Text>
      <BankAccountDetailsItem
        label="Account Holder"
        value={payout.person_name ?? '--'}
      />
      <BankAccountDetailsItem
        label="Routing #"
        value={payout.routing ?? '--'}
      />
      <BankAccountDetailsItem
        label="Acct #"
        value={
          payout.bank_account_number_last_four
            ? '**' + payout.bank_account_number_last_four
            : '--'
        }
      />
    </>
  );
};

const NoMarginSpanContainer = styled.div`
  display: flex;
  margin-left: -17px;
  margin-top: 10px;
`;

const TextPadding = styled.span`
  padding-right: 1em;
`;

const PayoutData: FunctionComponent<{ payout: PayoutWithMethodDetail }> = ({
  payout,
}) => (
  <div>
    <NoMarginSpanContainer>
      <TextPadding>
        <Text as="h6">Reference Number:</Text>
      </TextPadding>
      <TextPadding>
        <Text as="p">
          <Id id={payout.reference_number} />
        </Text>
      </TextPadding>
    </NoMarginSpanContainer>
    {payout.trace_id && (
      <NoMarginSpanContainer>
        <TextPadding>
          <Text as="h6">Trace ID:</Text>
        </TextPadding>
        <TextPadding>
          <Text as="p">
            <Id id={payout.trace_id} />
          </Text>
        </TextPadding>
      </NoMarginSpanContainer>
    )}
    <div>
      {payout.response?.message && (
        <NoMarginSpanContainer>
          <TextPadding>
            <Text as="h6">Payout Error:</Text>
          </TextPadding>
          <TextPadding>
            <Text as="p">{payout.response.message}</Text>
          </TextPadding>
        </NoMarginSpanContainer>
      )}

      {payout.description && (
        <NoMarginSpanContainer>
          <TextPadding>
            <Text as="h6">Description:</Text>
          </TextPadding>
          <TextPadding>
            <Text as="p">{payout.description}</Text>
          </TextPadding>
        </NoMarginSpanContainer>
      )}
    </div>
  </div>
);

const BankAccountDetailsItem: FunctionComponent<{
  label: string;
  value: string;
}> = ({ label, value }) => {
  return (
    <BankAccountDetailsRow>
      {label} <BankAccountDetailsRowValue>{value}</BankAccountDetailsRowValue>
    </BankAccountDetailsRow>
  );
};

const getAchReturnReason = (payout: PayoutWithMethodDetail): string => {
  const output: string[] = [];
  if (payout.status_code) output.push(payout.status_code);
  output.push(payout.status_reason);
  return output.join(': ');
};

/** Props accepted by the PayoutDrawer component */
type PayoutDrawerProps = Partial<DrawerProps> &
  Pick<DrawerProps, 'open'> & {
    payoutDetail: PayoutWithMethodDetail;
    settlementDetail: Settlement;
  };

export const PayoutDrawer: FC<PayoutDrawerProps> = props => {
  const { payoutDetail, settlementDetail, ...drawerProps } = props;
  if (!payoutDetail || !settlementDetail) {
    return null;
  }
  const achReturnReason = getAchReturnReason(payoutDetail);
  return (
    <DrawerBgColor
      {...drawerProps}
      anchor="right"
      title="Fund Details"
      data-testid="details-modal"
      customStyles={{
        borderRadius: 0,
        padding: 0,
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {payoutDetail && (
        <>
          <Section>
            <>
              <LineItemRow>
                <Text as="h2">{formatCurrency(payoutDetail.amount)}</Text>
                <StatusPill {...mapFundsType(payoutDetail.state)} />
                <CopyButton
                  content={payoutDetail.payout_id}
                  tooltip={
                    <span>
                      Copy <strong>Payout ID</strong> to Clipboard
                      <br />
                      {`${payoutDetail?.payout_id}`}
                    </span>
                  }
                >
                  <Icon icon={['fas', 'copy']} size="1x" />
                </CopyButton>
              </LineItemRow>
              {(settlementDetail.state === 'HOLD' || achReturnReason) && (
                <AchReturnReasonNotification>
                  <Icon icon={['far', 'times-circle']} size="1x" />
                  {settlementDetail.state === 'HOLD'
                    ? 'ACH Reject'
                    : `ACH Return Code: ${achReturnReason}`}
                </AchReturnReasonNotification>
              )}
              <Section>
                <DateRow payout={payoutDetail} />
              </Section>
              <Section>
                <PayoutData payout={payoutDetail} />
              </Section>
            </>
          </Section>
          <Separator />
          <Section>
            <BankAccountDetails payout={payoutDetail} />
          </Section>
          <Separator />
        </>
      )}
    </DrawerBgColor>
  );
};
