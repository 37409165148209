import React, { FunctionComponent, useContext, useMemo } from 'react';
import {
  PermissionsStore,
  updatePermissionsGodviewPermissionValue,
} from '../../context';

import { Form, Field } from 'react-final-form';
import { ToggleSelector as TToggleSelector } from '@fattmerchantorg/truffle-components';
import styled from 'styled-components';
import { Button } from '../shared';
import { PermissionReadWrite, Permissions } from '@fattmerchantorg/types-omni';
import { usePermissions } from '../../hooks';

const featureOptions = [
  {
    label: 'Write',
    value: 'write',
  },
  {
    label: 'Read',
    value: 'read',
  },
  {
    label: 'none',
    value: '',
  },
];

const AddNewFeaturePermissionTd = styled.td`
  display: flex;
  align-items: center;

  > button {
    margin-left: 8px;
  }
`;

const ToggleSelector = styled(TToggleSelector)`
  > div[aria-selected='false'] {
    color: white !important;
  }
`;

export const GodviewPermissionsTester: FunctionComponent = () => {
  const { state, dispatch } = useContext(PermissionsStore);
  const godviewPermissions = state.permissions?.godview;
  const godviewPermissionNames = useMemo(
    () =>
      (godviewPermissions
        ? Object.keys(godviewPermissions)
        : []) as (keyof typeof godviewPermissions)[],
    [godviewPermissions]
  );
  const { permit } = usePermissions();

  return permit('godview', 'godviewPermissions') ? (
    <Form<{
      name: keyof Permissions['permissions']['godview'];
      value: PermissionReadWrite;
    }>
      initialValues={{ name: null, value: '' }}
      onSubmit={formValues => {
        dispatch(
          updatePermissionsGodviewPermissionValue(
            formValues.name,
            formValues.value
          )
        );
      }}
    >
      {formProps => {
        return (
          <form onSubmit={formProps.handleSubmit}>
            <h2>Godview Permissions</h2>
            <div>
              <small>
                This tool can be used to test Godview Permissions. All changes
                will be lost upon switching merchants or refreshing your
                browser.
              </small>
            </div>
            <div>
              <small>
                NOTE: This list only populates with godview permissions
                available to you. If the desired permission does not appear in
                the list, you can just type it in!
              </small>
            </div>
            <table className="table table-sm table-dark">
              <thead className="thead-light">
                <tr>
                  <th>Name</th>
                  <th>Permission</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Field name="name" component="input" />
                  </td>
                  <AddNewFeaturePermissionTd className="align-middle">
                    <Field name="value">
                      {fieldProps => (
                        <ToggleSelector
                          options={featureOptions}
                          defaultSelected={fieldProps.input.value}
                          onChange={(_, option) =>
                            fieldProps.input.onChange(option.value)
                          }
                        />
                      )}
                    </Field>
                    <Button size="small" type="submit">
                      Update
                    </Button>
                  </AddNewFeaturePermissionTd>
                </tr>
                {godviewPermissionNames.map(n => {
                  return (
                    <tr key={n}>
                      <td className="align-middle">{n}</td>
                      <td>
                        <ToggleSelector
                          options={featureOptions}
                          defaultSelected={godviewPermissions[n]}
                          onChange={(_, option) => {
                            dispatch(
                              updatePermissionsGodviewPermissionValue(
                                n,
                                option.value
                              )
                            );
                          }}
                        />
                      </td>
                      <td />
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </form>
        );
      }}
    </Form>
  ) : null;
};
