import React, {
  FunctionComponent,
  useState,
  ChangeEvent,
  useCallback,
} from 'react';
import { FieldInputProps } from 'react-final-form';
import { Input } from '..';
import { maskInput } from './MaskedInput.util';
import { TextField, Icon } from '@fattmerchantorg/truffle-components';
import { usePermissions } from '../../../hooks';
import { getMaskedInputMax } from '../../../util';
import { MaskType } from '../../../@types/MaskType';

export interface MaskedInputProps extends FieldInputProps<any, HTMLElement> {
  onChange: (newValue: string) => void;
  maskType: MaskType;
  value: string;
  [key: string]: any;
}

export const MaskedInput: FunctionComponent<MaskedInputProps> = props => {
  const { onChange, value, maskType, ...restOfProps } = props;

  const { permit } = usePermissions();
  const allowViewValue = permit('godview', 'pii', 'write');
  const [val, setVal] = useState(value || '');
  const [viewVal, setviewVal] = useState(false);
  const maskedVal = maskInput(value, viewVal, maskType, allowViewValue) || '';

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const prevValue = maskedVal;
      const newValue = e.target.value;
      let newVal = newValue;

      if (maskType !== 'password') {
        let maxLength: number = getMaskedInputMax(maskType);

        if (newValue.length > maxLength) {
          // max length value @maxLength
          return;
        } else if (newValue.length > prevValue.length) {
          // typing or pasting
          const numberOfNewCharacters = newValue.length - prevValue.length;
          newVal = `${val}${newValue.slice(-numberOfNewCharacters)}`;
        } else {
          // backspacing / deleting
          const numberOfRemovedCharacters = prevValue.length - newValue.length;
          newVal = val.substr(0, val.length - numberOfRemovedCharacters);
        }
      }

      setVal(newVal);
      if (onChange) onChange(newVal);
    },
    [val, maskedVal, onChange, maskType]
  );

  return (
    <>
      {(props.type ?? 'text') === 'text' ? (
        <TextField
          {...restOfProps}
          value={maskedVal}
          autoComplete="off"
          onChange={handleChange}
          type={maskType === 'password' && !viewVal ? 'password' : 'text'}
          slug={
            allowViewValue && (
              <Icon
                icon={['fas', !viewVal ? 'eye' : 'eye-slash']}
                style={{
                  color: '#009bf2',
                  width: '20px',
                }}
                onClick={() => {
                  const view = !viewVal;
                  setviewVal(view);
                }}
              />
            )
          }
        />
      ) : (
        <Input
          {...restOfProps}
          value={maskType === 'password' ? val : maskedVal}
          autoComplete="off"
          onChange={handleChange}
        />
      )}
    </>
  );
};
