import styled from 'styled-components';
import { ThemeProps } from '../../../@types';

export const SummarySection = styled.div`
  display: flex;

  span {
    font-size: 10px;
    margin: 2px auto 2px 0px;

    &.react-loading-skeleton {
      margin: 0;
    }
  }

  > div:first-child {
    flex-basis: 50%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-right: 24px;

    > div {
      display: flex;
      justify-content: space-between;
    }
  }

  > div:not(:first-child) {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 24px;
    border-left: 2px solid ${(props: ThemeProps) => props.theme.black};

    h2 {
      font-size: 22px;
      color: ${(props: ThemeProps) => props.theme.primaryColor};
    }
  }
`;
