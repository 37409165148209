import React from 'react';
import { Text } from '@fattmerchantorg/truffle-components';
import { Card, Row } from 'reactstrap';
import styled from 'styled-components';
import { LoadingSpan } from '../../shared';
import { useSearchState } from '../../../hooks';
import { SupportTicket } from '../../../@types';

const StatusContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StatusSpan = styled.span`
  font-size: 14px;
`;

const StatusCard = styled(Card)`
  width: 24%;
  height: 88px;
  border: 0;
  background-color: ${({ theme }) => theme.colors.core.gray[800].hex};
  border-radius: 2px 0px 0px 2px;
  border: 1px solid #435e70;
  padding: 16px;

  @media (min-width: 1000px) {
    margin-bottom: 1rem;
  }

  &[data-status='NEW'] {
    border-left: 4px solid ${({ theme }) => theme.colors.core.green[500].hex};
  }

  &[data-status='CLOSED'] {
    border-left: 4px solid ${({ theme }) => theme.colors.core.gray[400].hex};
  }

  &[data-status='PENDING'] {
    border-left: 4px solid ${({ theme }) => theme.colors.core.yellow[500].hex};
  }

  &[data-status='AWAITING_RESPONSE'] {
    border-left: 4px solid ${({ theme }) => theme.colors.core.blue[500].hex};
  }
`;

export const FilterButton = styled.div`
  align-items: center;
  float: right;
  background-color: #004166;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 32px;
  justify-content: center;
  min-width: 90px;
  width: auto;
  padding: 6px 8px;
  line-height: 20px;
  margin-top: 14px;
  margin-left: 1rem;

  span {
    color: #009bf2;

    &:not(:first-child) {
      font-weight: bold;
      margin-left: 4px;
    }
  }
`;

export const CollectionCard = styled(Card)`
  width: 100%;
  border: 0;
  background-color: ${({ theme }) => theme.colors.core.gray[800].hex};
  @media (min-width: 1000px) {
    margin-bottom: 1rem;
  }
`;

export interface Counts {
  AWAITING_RESPONSE: number;
  CLOSED: number;
  NEW: number;
  PENDING: number;
}

export const SupportTicketStatusWidget: React.FunctionComponent<{
  counts: Counts;
}> = (props: { counts: Counts }) => {
  const { counts } = props;
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [_, setStatus] = useSearchState<SupportTicket['status']>('status');

  const loading = counts === null;
  return (
    <Row>
      <StatusContainer>
        <StatusCard data-status="NEW" onClick={() => setStatus('NEW')}>
          {loading ? (
            <LoadingSpan />
          ) : (
            <Text as="h4">{counts?.NEW || '0'}</Text>
          )}
          <StatusSpan>Open</StatusSpan>
        </StatusCard>
        <StatusCard data-status="PENDING" onClick={() => setStatus('PENDING')}>
          {loading ? (
            <LoadingSpan />
          ) : (
            <Text as="h4">{counts?.PENDING || '0'}</Text>
          )}
          <StatusSpan>Pending</StatusSpan>
        </StatusCard>
        <StatusCard data-status="CLOSED" onClick={() => setStatus('CLOSED')}>
          {loading ? (
            <LoadingSpan />
          ) : (
            <Text as="h4">{counts?.CLOSED || '0'}</Text>
          )}
          <StatusSpan>Closed</StatusSpan>
        </StatusCard>
        <StatusCard
          data-status="AWAITING_RESPONSE"
          onClick={() => setStatus('AWAITING_RESPONSE')}
        >
          {loading ? (
            <LoadingSpan />
          ) : (
            <Text as="h4">{counts?.AWAITING_RESPONSE || '0'}</Text>
          )}
          <StatusSpan>Awaiting Response</StatusSpan>
        </StatusCard>
      </StatusContainer>
    </Row>
  );
};
