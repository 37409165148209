import React, { Context, createContext, useReducer, useEffect } from 'react';
import {
  PermissionsContextProps,
  PermissionsContext,
  PermissionsAction,
  PermissionsState,
  PermissionsActionType,
  PermissionsEffectType,
} from './Permissions.types';
import produce from 'immer';

const PermissionsStore = createContext(
  {} as PermissionsContext
) as Context<PermissionsContext>;

const getInitialState = (): PermissionsState => {
  try {
    const savedState = JSON.parse(
      localStorage.getItem('omniconnect.permissionsState')
    );
    if (savedState && typeof savedState === 'object') {
      const state = savedState as PermissionsState;
      return {
        ...state,
        effect: null,
      };
    } else {
      throw new Error('unable to hydrate PermissionsStore from localStorage');
    }
  } catch (error) {
    return {
      effect: null,
    };
  }
};

const initialState = getInitialState();

type Reducer = (
  state: PermissionsState,
  action: PermissionsAction
) => PermissionsState;

const storeReducer: Reducer = (state, action) => {
  switch (action.type) {
    case PermissionsActionType.UPDATE_PERMISSIONS:
      const permissions = action.payload;
      return { ...state, ...permissions };
    case PermissionsActionType.REMOVE_PERMISSIONS:
      return {};
    case PermissionsActionType.UPDATE_PERMISSIONS_FEATURE_PERMISSION_VALUE: {
      const { category, feature, value } = action.payload;
      return produce(state, draft => {
        if (!draft.permissions.features[category]) {
          draft.permissions.features[category] = {};
        }
        draft.permissions.features[category][feature] = value || '';
      });
    }
    case PermissionsActionType.UPDATE_PERMISSIONS_GODVIEW_PERMISSION_VALUE: {
      const { permission, value } = action.payload;
      return produce(state, draft => {
        draft.permissions.godview[permission] = value || '';
      });
    }

    default:
      break;
  }

  return state;
};

const effectReducer: Reducer = (state, action) => {
  let newState = state;

  switch (action.type) {
    case PermissionsActionType.UPDATE_PERMISSIONS:
      newState = {
        ...state,
        effect: {
          type: PermissionsEffectType.UPDATE_PERMISSIONS,
          payload: state,
        },
      };
      break;
    default:
      break;
  }

  return newState;
};

const reduceReducers =
  (...reducers: Reducer[]) =>
  (state: PermissionsState, action: PermissionsAction) => {
    return reducers.reduce(
      (nextState, reducer) => reducer(nextState, action),
      state
    );
  };

const PermissionsProvider = (props: PermissionsContextProps) => {
  const [state, dispatch] = useReducer<
    React.Reducer<PermissionsState, PermissionsAction>
  >(reduceReducers(storeReducer, effectReducer), initialState);

  const { effect } = state;

  useEffect(() => {
    const effectType = effect && effect.type;

    switch (effectType) {
      case PermissionsEffectType.UPDATE_PERMISSIONS:
        localStorage.setItem(
          'omniconnect.permissionsState',
          JSON.stringify(effect.payload)
        );
        break;
      case PermissionsEffectType.REMOVE_PERMISSIONS:
        localStorage.removeItem('omniconnect.permissionsState');
        break;
      default:
        break;
    }
  }, [effect]);

  const localContext: PermissionsContext = {
    state: state,
    dispatch: dispatch,
  };

  return (
    <PermissionsStore.Provider value={localContext}>
      {props.children}
    </PermissionsStore.Provider>
  );
};

export { PermissionsStore, PermissionsProvider };
