import styled from 'styled-components';

export const EditToggle = styled.button`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 20px;
  right: 16px;
  z-index: 2;
  background: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  color: #009bf2;
  width: 16px;

  > span > svg {
    margin-right: 0;
  }
`;
