import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { ModalContext } from '../../../../context/modal';
import {
  BaseModal,
  ModalHeader,
  ModalFooter,
  PrimaryButton,
  SecondaryButton,
} from '@fattmerchantorg/truffle-components';
import { ProcessingInfoFields } from './ProcessingInfoFields';
import { Registration } from '@fattmerchantorg/types-omni';
import { Form } from '../../../shared';
import * as processingInfoFormUtil from './ProcessingInfoForm.util';
import {
  ProcessingInfoFieldsValues,
  ProcessingInfoFieldsErrors,
} from './ProcessingInfoForm.types';
import { Dispatch } from 'react';
import { ButtonSpinner } from '../../../shared';
import { useUnderwritingFormSubmission } from '../../context/useUnderwritingFormSubmission';

const ButtonBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  > * {
    margin: 0 0 0 ${({ theme }) => theme.space[4]}px;
  }
`;

const StyledModalContent = styled.div`
  grid-area: content;
  overflow-y: auto;
  padding: ${({ theme }) => theme.space[4]}px;
  color: ${({ theme }) => theme.component.modal.color};
`;

interface ProcessingInfoModalProps {
  isOpen?: boolean;
  registration: Registration;
  merchantId: string;
  merchantDispatch: Dispatch<any>;
}

const validateForm = (
  formValues: ProcessingInfoFieldsValues
): ProcessingInfoFieldsErrors => {
  const errors = {
    ...processingInfoFormUtil.validateFormValues(formValues),
  } as ProcessingInfoFieldsErrors;

  return errors;
};

export const ProcessingInfoFormModal: React.FunctionComponent<
  ProcessingInfoModalProps
> = props => {
  const { modalDispatch } = useContext(ModalContext);
  const [isModalVisible] = useState(!!props.isOpen);
  const { registration, merchantId, merchantDispatch } = props;

  const { onSubmit, isSaving } = useUnderwritingFormSubmission({
    merchantDispatch,
    merchantId,
    payloadFormatter: processingInfoFormUtil.mapFormValuesToPayload,
  });

  const handleCloseModal = () => {
    modalDispatch({
      type: 'CLOSE_MODAL',
    });
  };

  return (
    <Form
      initialValues={{
        ...processingInfoFormUtil.mapRegistrationToInitialFormValues(
          registration
        ),
      }}
      validate={formValues => {
        const validationErrors = {
          ...validateForm(formValues),
        } as ProcessingInfoFieldsErrors;
        return validationErrors;
      }}
      onSubmit={onSubmit}
    >
      {formProps => (
        <BaseModal
          title="Processing Info"
          isOpen={isModalVisible}
          onRequestClose={handleCloseModal}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={false}
          style={{
            overlay: { pointerEvents: 'auto' },
            content: { maxWidth: '533px' },
          }}
        >
          <ModalHeader title="Processing Info" onClose={handleCloseModal} />
          <>
            <StyledModalContent>
              <ProcessingInfoFields
                registration={registration}
                formProps={formProps}
              />
            </StyledModalContent>
            <ModalFooter>
              <ButtonBar>
                <SecondaryButton onClick={handleCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  onClick={() => onSubmit(formProps.values)}
                  disabled={
                    // Disable Save if there are validation errors
                    formProps.hasValidationErrors === true
                  }
                >
                  {isSaving ? <ButtonSpinner /> : `Save`}
                </PrimaryButton>
              </ButtonBar>
            </ModalFooter>
          </>
        </BaseModal>
      )}
    </Form>
  );
};
