import { Registration, Merchant } from '@fattmerchantorg/types-omni';
import { formatCentsOrDollars } from './format.util';

/**
 * Compares specific pairs of CP/CNP pricing values and determines
 * if the "Card Present" rates match "Card Not Present".
 * @param r Registration
 */
export function identicalCardRates(r: Registration): boolean {
  // Don't run comparison if registration is empty.
  if (!r) return false;

  const valuesToCompare = [
    { cp: r.cp_transaction_rate, cnp: r.plan_dcamnt },
    { cp: r.cp_per_item_rate, cnp: r.plan_txamnt },
    { cp: r.cp_amex_rate, cnp: r.amex_qual_disc_rate },
    { cp: r.cp_amex_per_item_rate, cnp: r.amex_qual_trans_fee },
  ];

  const result = valuesToCompare.every(pair => {
    // If values in a pair are both empty then rates are identical
    if (!pair.cp && !pair.cnp) return true;

    // If a pair of values are the same then rates are identical
    return pair.cp === pair.cnp;
  });

  return result;
}

/**
 * Returns a readable combination of "ACH Transaction Amount" and "ACH Discount Fees" per transaction.
 * @param achTransactionAmount Amount in cents.
 * @param achDiscountFees Value in percentage.
 */
export function formatPricingRate(
  achTransactionAmount: string,
  achDiscountFees: string
): string {
  let out: string = '';

  if (achDiscountFees !== '0' && achTransactionAmount !== '0') {
    // If both values are not 0, then format and return both.
    out = `${achDiscountFees}% + ${formatCentsOrDollars(achTransactionAmount)}`;
  } else if (achDiscountFees !== '0') {
    // If the percentage value is not zero, then format and return.
    out = `${achDiscountFees}%`;
  } else if (achTransactionAmount !== '0') {
    // If the fixed amount value is not zero, then format and return.
    out = formatCentsOrDollars(achTransactionAmount);
  }

  return out;
}

/**
 * Returns a readable representation of the ACH cap fee.
 * @param cap The ACH cap fee in dollars.
 */
export function formatACHCap(cap: string): string {
  const capAsInt: number = parseInt(cap);

  // Default scenario. This CAN be empty.
  let out: string = '';

  if (capAsInt > 0 && capAsInt < 9999) {
    out = `(capped at $${cap})`;
  }

  return out;
}

export function formatSurchargeRate(rate): string {
  if (parseFloat(rate) > 0) {
    return `${rate}%`;
  }
  // default
  return '';
}

/**
 * Returns transaction a limit for specified transaction type
 * @param merchant the merchant model
 * @param type the transaction type (credit or ach)
 * @returns the transaction limit (integer)
 */
export function calculateTransactionLimit(
  merchant: Merchant,
  type: 'credit' | 'ach'
): number {
  const creditLimit = merchant.options.transaction_limit
      ? parseInt(merchant.options.transaction_limit.toString())
      : 50000,
    achLimit = merchant.options.ach_transaction_limit
      ? parseInt(merchant.options.ach_transaction_limit.toString())
      : creditLimit;
  return type === 'ach' ? achLimit : creditLimit;
}
