import { Omni } from '@fattmerchantorg/types-omni';
import { ToastContext } from '../../../../../context';
import {
  magService,
  updateRegistrationAuditLog,
} from '../../../../../services/mag-service';
import * as pizzaTracker from '../../../../../util/pizzaTracker.util';

/**
 * Submission is a two-stage process.
 *
 * 1: Submit to the validate route and log the results in the auditlog
 *
 * 2: If validation passes, continue immediately to the submission to FIS and
 * log the results again in the audit log
 */
export const handleSubmitFIS = async (
  authToken: Omni.Auth['token'],
  registration: Omni.Registration,
  merchantId: string,
  toastContext: Pick<ToastContext, 'toaster' | 'toast'>
): Promise<void> => {
  const { toaster, toast } = toastContext;
  const status =
    pizzaTracker.getApplicationStatusLogFromRegistration(registration);
  let magErrors = [];

  try {
    await magService(
      authToken,
      registration,
      registration.files || [],
      true,
      merchantId
    );
  } catch (err) {
    magErrors = err;

    toaster(
      toast.error(
        'Validation errors occurred with this application. Please view the application log for error details.'
      )
    );
  } finally {
    const isSuccess = magErrors.length === 0;
    await updateRegistrationAuditLog(
      authToken,
      registration,
      isSuccess,
      true,
      magErrors
    );
  }

  if (magErrors.length === 0) {
    toaster(
      toast.success(
        `Registration validated for ${registration.business_legal_name}`
      )
    );

    try {
      await magService(
        authToken,
        registration,
        registration.files || [],
        false,
        merchantId
      );
    } catch (err) {
      magErrors = err;
      toaster(
        toast.error(
          'Submission of the application to FIS encountered an error.'
        )
      );
      console.error(err);
    } finally {
      if (!status.externalReview) {
        const internalReview = {
          status: 'success',
          author: pizzaTracker.underwritingAuthor,
        };
        const externalReview = {
          status: 'inprogress',
          author: pizzaTracker.underwritingAuthor,
        };
        const statuslog = { internalReview, externalReview };

        pizzaTracker.mergeApplicationStatusLog(
          authToken,
          statuslog,
          merchantId
        );
      }
      const isSuccess = magErrors.length === 0;
      await updateRegistrationAuditLog(
        authToken,
        registration,
        isSuccess,
        true,
        magErrors
      );
    }
  }
};
