import React, { useState } from 'react';
import { TableSectionV3 } from '../styles/table-section-component-v3';
import { Text } from '@fattmerchantorg/truffle-components';
import { StatementV3Props } from '../util/statement-v3-utils';
import { useAsyncEffect, useAuthToken } from '../../../../hooks';
import { queryapi } from '../../../../api';
import { formatCurrency } from '../../../../util';
import { TableNullState } from '../styles';
import {
  GetFeeStatementSurchargesDatum,
  GetFeeStatementSurchargesSummary,
  GetFeeStatementSurchargesResponse,
} from '@fattmerchantorg/types-omni';
import {
  LoadingState,
  useStatementLoadingState,
} from '../../../../context/statements';
import { TextContainer } from '../../components';

const formatCardBrandText = (cardType: String): String => {
  if (!cardType?.length) return cardType;

  // Card type to line item text mapping.
  switch (cardType) {
    case 'amex':
      return 'American Express';
    default:
      // By default, capitalize the first letter.
      return cardType.charAt(0).toUpperCase() + cardType.slice(1);
  }
};

export const SurchargeSection: React.FC<StatementV3Props> = props => {
  const { startDate, endDate } = props;
  const authToken = useAuthToken();

  const [data, setData] = useState<GetFeeStatementSurchargesDatum[]>();
  const [summary, setSummary] = useState<GetFeeStatementSurchargesSummary>();
  const { surchargesLoading, setSurchargesLoading } =
    useStatementLoadingState();

  useAsyncEffect(async () => {
    if (
      surchargesLoading === LoadingState.Loading &&
      authToken &&
      startDate &&
      endDate
    ) {
      try {
        const query = { startDate, endDate };
        const response = await queryapi.get<GetFeeStatementSurchargesResponse>(
          authToken,
          'statement/v3/surcharges',
          query
        );

        if (response) {
          setData(response.data);
          setSummary(response.summary);
          setSurchargesLoading(LoadingState.Completed);
        }
      } catch (error) {
        setSurchargesLoading(LoadingState.Failed);
      }
    }
  }, [surchargesLoading, authToken, startDate, endDate]);

  if (
    surchargesLoading !== LoadingState.Completed ||
    !data ||
    !summary?.surcharges_count
  )
    return null;

  return (
    <TableSectionV3>
      <TextContainer fontWeight="bold" paddingBottom="8px">
        <Text as="h5">Surcharge</Text>
      </TextContainer>
      <table data-testid="statementV3-surcharge">
        <thead>
          <tr>
            <th>Brand</th>
            <th>Count</th>
            <th>Surcharged</th>
            <th>Sales</th>
          </tr>
        </thead>
        <tbody>
          {data.map((datum, i) => (
            <tr key={i} className="tableRows">
              <td>{formatCardBrandText(datum.card_type)}</td>
              <td>{datum.surcharges_count}</td>
              <td>{formatCurrency(datum.surcharges_amount)}</td>
              <td>{formatCurrency(datum.sales_amount)}</td>
            </tr>
          ))}
        </tbody>
        {!!data.length && (
          <tfoot>
            <tr>
              <td>
                <strong>Total</strong>
              </td>
              <td>
                <strong>{summary.surcharges_count}</strong>
              </td>
              <td>
                <strong>{formatCurrency(summary.surcharges_amount)}</strong>
              </td>
              <td>
                <strong>{formatCurrency(summary.sales_amount)}</strong>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
      {!data.length && <TableNullState>No Surcharged Payments</TableNullState>}
    </TableSectionV3>
  );
};
