import React from 'react';
import { Omni } from '@fattmerchantorg/types-omni';
import get from 'lodash/get';
import { ToastContext } from '../../../../../context';
import {
  CoreFinixConfigurations,
  LitleFinixConfigurations,
} from '../../../../../@types/UnderwritingStatus';
import {
  FinixProcessorEnum,
  provisionFinixMerchant,
} from '../../../../../services/finix-service';
import { ProcessorValue } from './SubmitApplicationForm.types';
import { getTransactionLimitDefaults } from '../../../../../util/merchant-util';

function mapToFinixProcessorEnum(
  processorName: ProcessorValue
): FinixProcessorEnum {
  let proc = null;
  switch (processorName) {
    case 'CORE_FINIX':
      proc = 'VANTIV_V1';
      break;
    case 'LITLE':
    case 'LITLE_FINIX':
      proc = 'LITLE_V1';
      break;
  }
  return proc;
}

export const handleSubmitFinix = async (
  finixApplicationId: string,
  finixCredentialId: string,
  reg: Omni.Registration,
  SubmitApplicationType: string,
  configuration: CoreFinixConfigurations | LitleFinixConfigurations,
  processorName: ProcessorValue,
  authToken: Omni.Auth['token'],
  toastContext: Pick<ToastContext, 'toaster' | 'toast'>,
  merchant: Omni.Merchant
): Promise<void> => {
  const { toaster, toast } = toastContext;
  const finixSuccessMsg = 'Successfully submitted to Finix!';
  const finixErrorPreMsg = 'There was a problem submitting the merchant';
  const finixGeneralErrMsg = 'General Error: Contact support';

  /**
   * The logic below sets largest_ach_transaction and highest_trans_amount to a
   * high of 500k when the brand is not fattmerchant, paymentdepot or sandbox
   * this will prevent fails when transcations from failing at the gateway level
   */
  const { transaction_limit, ach_transaction_limit } = merchant.options
    ? merchant.options
    : { transaction_limit: '', ach_transaction_limit: '' };
  const updatedMerchantOptions = {
    ...(merchant.options ? merchant.options : {}),
    transaction_limit: getTransactionLimitDefaults(
      merchant,
      transaction_limit
    ).toString(),
    ach_transaction_limit: getTransactionLimitDefaults(
      merchant,
      ach_transaction_limit
    ).toString(),
  };

  const updatedRegObj = {
    ...reg,
    merchant: {
      options: updatedMerchantOptions,
    },
    largest_ach_transaction: getTransactionLimitDefaults(
      merchant,
      reg.largest_ach_transaction
    ).toString(),
    highest_trans_amount: getTransactionLimitDefaults(
      merchant,
      reg.highest_trans_amount
    ).toString(),
  };

  try {
    const proc = mapToFinixProcessorEnum(processorName);
    if (proc === null) {
      // If this error occurs, we broke the form somehow
      console.error(
        'Unable to map processor name value to a corresponding Finix processor value',
        processorName
      );
      throw new Error('Could not specify a processor for Finix submission');
    }

    await provisionFinixMerchant(
      updatedRegObj,
      proc,
      processorName,
      finixApplicationId,
      finixCredentialId,
      configuration,
      SubmitApplicationType,
      authToken
    );
    toaster(toast.success(finixSuccessMsg, 'Submitted'));
  } catch (e) {
    const code = get(e, 'response.status', 500);
    const is400Error = code >= 400 && code < 500;

    if (is400Error) {
      const errors = get(e, 'response.data.errors', [finixGeneralErrMsg]);
      const messages = errors.map((err, key) => {
        const message =
          typeof err !== 'string' ? `"${err.field}": ${err.message}` : err;
        return <p key={key}>{message}</p>;
      });
      toaster(
        toast.error(<>{messages}</>, finixErrorPreMsg, {
          lifetime: 'forever',
        })
      );
    } else {
      toaster(toast.error(e, finixErrorPreMsg));
    }
  }
};
