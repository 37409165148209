import React, { FC } from 'react';
import { User } from '@fattmerchantorg/types-omni';
import { debounceAsync, memoize } from '../../util/functional.util';
import { coreapi } from '../../api';
import { FormSpy } from '../shared';

export enum ExistingUserState {
  NotFound = 'NOT_FOUND', // no user found for email
  CannotLink = 'CANNOT_LINK', // found user for email but cannot link
  CanLink = 'CAN_LINK', // found user for email and can link
  WillLink = 'WILL_LINK' // found user for email and has opted to link
}

export type ExistingUserLookupResult = [ExistingUserState, User | null];

const fetchExistingUserStatusAndUser: (
  authToken: string,
  email: string
) => Promise<ExistingUserLookupResult> = memoize(
  debounceAsync<string, ExistingUserLookupResult>((authToken, email) =>
    // look up user by email
    coreapi
      .get(
        authToken,
        `/self/exists?email=${encodeURIComponent(email)}&returnId=1`
      )
      // if the user exists, look them up using their id
      .then(userId => {
        if (userId === 'not found') {
          // api responds with 'not found' if returnId is true
          return [ExistingUserState.NotFound, null] as [
            ExistingUserState,
            User | null
          ];
        }

        return (
          coreapi
            .get(authToken, `/user/${userId}`)
            // if the get /user call succeeds, the user for this email exists and we are allowed to see them
            // so we will resolve with a status of CanLink and the user
            .then(user => [ExistingUserState.CanLink, user as User])
            // else if the get /user call fails, the user for this email exists but we cannot see them (not a part of our brand)
            // so we will resolve with a status of CannotLink and null (no user)
            .catch(e => [
              ExistingUserState.CannotLink,
              null
            ]) as Promise<ExistingUserLookupResult>
        );
      })
      // else if the get /exists call fails, the user for this email does not exist
      // so we will resolve with a status of NotFound and null (no user)
      .catch(e => [ExistingUserState.NotFound, null])
  )
);

export const ExistingEmailChecker: FC<{
  authToken: string | null;
  onCheck: (results: ExistingUserLookupResult) => void;
}> = props => (
  <FormSpy
    subscription={{ values: true, errors: true }}
    onChange={async ({ values, errors }) => {
      const email = values.contact_email;
      const error = errors.contact_email;

      if (email && props.authToken && !error) {
        // if we have an email and the email is valid, look up the user by their email
        const results = await fetchExistingUserStatusAndUser(
          props.authToken,
          email
        );
        props.onCheck(results);
      } else {
        // if there's no email or there's a validation error, set existing user to null
        props.onCheck([ExistingUserState.NotFound, null]);
      }
    }}
  />
);
