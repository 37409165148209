import React, { FunctionComponent, useContext } from 'react';
import styled from 'styled-components';
import { UnderwritingProps } from './Underwriting.types';
import { AdditionalRepresentatives } from './components/additional-representatives/AdditionalRepresentatives';
import { BillingAddress } from './components/addresses/BillingAddress';
import { BusinessInfo } from './components/business-info/BusinessInfo';
import { BankingInfo } from './components/banking-info/BankingInfo';
import { DBALocationAddress } from './components/addresses/DBALocationAddress';
import { Countersign } from './components/countersign/Countersign';
import { ExternalVerification } from './components/external-verification/ExternalVerification';
import { Files } from './components/files/Files';
import { Notes } from './components/Notes';
import { RequiredDocuments } from './components/RequiredDocuments';
import { OwnerSignerInfo } from './components/owner-signer-info/OwnerSignerInfo';
import { PricingInfo } from './components/pricing-info/PricingInfo';
import { ProcessingInfo } from './components/processing-info/ProcessingInfo';
import { Status } from './components/status/Status';
import { ProcessingEnvironment } from './components/processing-environment/ProcessingEnvironment';
import { SignedLocationInfo } from './components/SignedLocationInfo/SignedLocationInfo';
import { AdditionalMetaInformation } from './components/additional-meta-information/AdditionalMetaInformation';
import { UnderwritingProvider } from './context';
import { usePermissions } from '../../hooks';
import { Alert } from '../shared';
import { SelectedMerchantStore } from '../../context';

const Wrapper = styled.div`
  padding: 16px;
`;

const SectionOne = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 10px;
  margin: 0 0 16px;
`;

const SectionTwo = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 10px;
  margin: 0 0 16px;
`;

const SectionThree = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  > section {
    margin: 0 0 16px;
  }
`;

export const Underwriting: FunctionComponent<UnderwritingProps> = props => {
  const { permit } = usePermissions();
  const {
    state: { merchant },
  } = useContext(SelectedMerchantStore);
  const canViewExternalAuditLog = permit(
    'godview',
    'viewExternalAuditLog',
    'read'
  );

  return (
    <UnderwritingProvider>
      <Wrapper>
        {merchant?.currency?.includes('CAD') ? (
          <Alert
            type="warning"
            message="This merchant is Canadian and needs to be processed through WePay or Paypal Canada"
            style={{ marginBottom: '16px' }}
          />
        ) : null}
        <SectionOne>
          <BusinessInfo />
          <Status />
        </SectionOne>
        <SectionOne>
          <BankingInfo />
          <OwnerSignerInfo />
        </SectionOne>
        <SectionTwo>
          <BillingAddress />
          <DBALocationAddress />
        </SectionTwo>
        <SectionTwo>
          <Countersign />
          <SignedLocationInfo />
        </SectionTwo>
        <SectionThree>
          {canViewExternalAuditLog && <ExternalVerification />}

          <RequiredDocuments />
          <AdditionalRepresentatives />
          <ProcessingInfo />
          <ProcessingEnvironment />
          <PricingInfo />
          <AdditionalMetaInformation />
          <Notes />
          <Files />
        </SectionThree>
      </Wrapper>
    </UnderwritingProvider>
  );
};
