import React, { FunctionComponent } from 'react';
import styled, { withTheme } from 'styled-components';
import { ChartTypes } from '../../../../@types';
import {
  NoDataOverlayTitle,
  NoDataOverlayDescription,
} from '../shared/TruffleText';

const SharedStyles = withTheme(
  styled.div`
    background: ${({ theme }) => theme.black};
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.colors.core.gray[600].hex};
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #333;
  `
);

const InlineLayout = styled(SharedStyles)`
  padding: 30px;
`;

const FloatingAndCenteredLayout = styled(SharedStyles)`
  width: 60%;
  height: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  z-index: 10;
  padding: 30px;
  display: flex;
`;

export const NoDataOverlay: FunctionComponent<{
  type: ChartTypes;
  useInlineLayout: boolean;
}> = ({ type, useInlineLayout: isInline }) => {
  const { title, body } = getOverlayTextByChartType(type);

  let noDataOverlay = (
    <FloatingAndCenteredLayout>
      <div>
        {NoDataOverlayTitle(title)}
        {NoDataOverlayDescription(body)}
      </div>
    </FloatingAndCenteredLayout>
  );

  if (isInline) {
    noDataOverlay = (
      <InlineLayout>
        <div>
          {NoDataOverlayTitle(title)}
          {NoDataOverlayDescription(body)}
        </div>
      </InlineLayout>
    );
  }

  return noDataOverlay;
};

function getOverlayTextByChartType(type: ChartTypes): {
  title: string;
  body: string;
} {
  let title = '-';
  let body = '-';

  switch (type) {
    case ChartTypes.RESIDUALS:
      title = 'You Have No Residuals in the Current Filter';
      body =
        'This is typical if you are within your first two months after launching with Stax Connect or if you are viewing a timeframe before there was any activity.';
      break;
    case ChartTypes.TRANSACTIONS:
      title = 'No transactions match the current filter';
      body =
        'This is typical if no transactions have yet been run on the platform or if you are viewing a timeframe before there were any transactions.';
      break;
    case ChartTypes.PERFORMANCE:
      title = 'Not enough merchant data for current filter';
      body =
        'This is typical if there is not enough transactional data to compare merchants.';
      break;
  }

  return { title, body };
}
