import React, { FC, Dispatch, useCallback, useContext } from 'react';
import { Container, Modal, Group } from '../../shared';
import {
  sendCloseSelected,
  Status as ModalStatus,
  Action as ModalAction,
  ModalState
} from '../../../hooks/useModalReducer';
import {
  SelectedMerchantStore,
  deleteSelectedMerchantAPIKey
} from '../../../context';
import { APIKey } from '@fattmerchantorg/types-omni';
import { StaxButton } from '../../shared/Stax/Button';

const closeSelected = sendCloseSelected();

/** Context for `useModalReducer` state. */
export interface DeleteModalContext {
  apiKey: APIKey;
}
/** Initial state for `useModalReducer`. */
export const initialDeleteModalState: ModalState<DeleteModalContext> = {
  status: ModalStatus.CLOSED,
  context: { apiKey: null }
};
export const DeleteAPIKeyModal: FC<{
  apiKey: APIKey;
  status: ModalStatus;
  modalDispatch: Dispatch<ModalAction>;
}> = ({ apiKey, status, modalDispatch }) => {
  const { dispatch: selectedMerchantDispatch } = useContext(
    SelectedMerchantStore
  );

  const apiKeyID = apiKey && apiKey.id;

  const onDeleteClick = useCallback(async () => {
    selectedMerchantDispatch(deleteSelectedMerchantAPIKey(apiKeyID));
    modalDispatch(closeSelected);
  }, [apiKeyID, modalDispatch, selectedMerchantDispatch]);

  return (
    <Modal
      data-testid="delete-api-key-modal"
      isOpen={status === ModalStatus.OPEN}
      onClose={() => modalDispatch(closeSelected)}
      title={<strong>Delete API Key</strong>}
    >
      <Container>
        <p>
          Deletion will revoke Omni API access for any applications that use
          this key.
        </p>
        <p>Delete API key "{apiKey && apiKey.name}?"</p>
        <Group direction="row" space="1rem" justify="flex-end">
          <StaxButton
            rank="secondary"
            onClick={() => modalDispatch(closeSelected)}
          >
            Cancel
          </StaxButton>
          <StaxButton motif="danger" onClick={onDeleteClick}>
            Delete
          </StaxButton>
        </Group>
      </Container>
    </Modal>
  );
};
