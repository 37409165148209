import { Auth } from '@fattmerchantorg/types-omni';
import axios from 'axios';
import { getHeaders } from '../util/api.util';

export interface ImportHubspotTicketsForm {
  companyId: string;
  startDate: Date;
  endDate: Date;
}

export const importHubspotTickets = async (
  auth: Auth,
  params: ImportHubspotTicketsForm
) => {
  try {
    const result = await axios({
      url: `${process.env.REACT_APP_APPSERVICE_URL}/import/hubspot/tickets`,
      method: 'POST',
      headers: getHeaders(auth.token),
      data: params,
    });

    return result.data;
  } catch (exception) {
    throw exception;
  }
};

export interface RespondToTicketForm {
  responseText: string;
  files: FileList;
}

export const replyToTicket = async (
  auth: Auth,
  ticketId: string,
  params: RespondToTicketForm
) => {
  const formData = new FormData();

  const body = {
    subject: `Response to ticket #${ticketId}`,
    text: params.responseText,
  };

  formData.append('body', JSON.stringify(body));
  if (params.files !== null) {
    let files = Array.from(params.files);

    files.forEach(currentFile => {
      formData.append('attachments', currentFile);
    });
  }

  try {
    const result = await axios({
      url: `${process.env.REACT_APP_APPSERVICE_URL}/support/ticket/${ticketId}/engagement`,
      method: 'POST',
      headers: getHeaders(auth.token, 'multipart/form-data'),
      data: formData,
    });
    return result.data;
  } catch (exception) {
    throw exception;
  }
};
