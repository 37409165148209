import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useState,
  useEffect,
  useRef,
} from 'react';
import axios, { CancelTokenSource } from 'axios';
import { useDropzone } from 'react-dropzone';
import styled, { withTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import { useWindowSize } from '../../../hooks';
import { UploadProps, UploadFile } from './Upload.types';

import { Group } from '../Group';
import { withAlpha } from '../../../util/color.util';
import { Progress } from 'reactstrap';
import { formatBytes, getExtension } from '../../../util/file.util';
import { Button } from '../Button';

const ProgressDescription = withTheme(
  styled.div`
    display: flex;
    flex-direction: column;

    > span {
      color: ${({ theme }) => theme.colors.core.blue[500].hex};
    }
  `
);

const StyledProgress = withTheme(
  styled(Progress)`
    margin-bottom: 0;

    > div {
      background-color: ${({ theme }) => theme.colors.core.blue[500].hex};
    }
  `
);

const Container = styled.div`
  height: 180px;
  border: 2px dashed lightgray;

  display: flex;
  justify-content: row;
  background-color: white;
  border-radius: 12px;

  .cloud {
    font-size: 35px !important;
    padding-bottom: 8px;
  }

  .accepted-files {
    font-family: 'Roboto', sans-serif;
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: normal !important;
    letter-spacing: 0em;
    text-align: center;
    overflow: visible;
  }

  .accepted-files-title {
    overflow: visible;
  }

  button {
    min-width: 20px;
    margin: 0px;
  }

  > * {
    flex: 1;
  }
`;

const Dropzone = styled.div`
  color: ${(props: any) => withAlpha(props.theme.primaryColor, 0.2)};

  padding: 4rem;

  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
`;

const Prompt = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  cursor: pointer;

  i {
    font-size: 500%;

    @media (max-width: 1000px) {
      font-size: 200%;
    }
  }
`;

const Content = withTheme(
  styled.div`
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;

    > i {
      font-size: 4rem;
      color: #dddfe4;
    }

    > button > i {
      font-size: 1.2rem;
    }

    > div {
      flex: 1;
      flex-direction: column;
      text-align: left;
      padding: 0 2rem;

      > div[class$='progress'] {
        margin: 0.5rem 0;
      }

      > strong {
        color: ${({ theme }) => theme.white};
      }

      span {
        font-size: 14px;
      }
    }
  `
);

const mapInitialFileToUpload = (file: UploadProps['file']): UploadFile => {
  if (!file) return null;
  return {
    file,
    progress: { done: true, loaded: file?.size, total: file?.size },
  };
};

export const Upload: FunctionComponent<UploadProps> = props => {
  const {
    file,
    uploadConfig,
    onFinish,
    onError,
    onDelete,
    onCancel,
    onInvalid,
    clearAfterUpload,
    accept = [
      'application/pdf',
      'application/zip',
      'image/*',
      'audio/*',
      'video/*',
      'text/*',
    ],
    uploadParams,
    maxSize,
    fileCount,
    fileLimit,
    ...divProps
  } = props;
  const { isMobile } = useWindowSize();
  const cancelTokenSourceRef = useRef<CancelTokenSource>(null);
  const [upload, setUpload] = useState<UploadFile>(
    mapInitialFileToUpload(file)
  );

  useEffect(() => {
    setUpload(mapInitialFileToUpload(file));
  }, [file]);

  // as much as i'd like to use the built-in `accept` props on the dropzone
  // it unfortunately does not work with gpg and pgp files
  const validateFile = useCallback(
    (file: File) => {
      if (fileCount && fileLimit) {
        if (fileCount >= fileLimit) {
          return `File limit is ${fileLimit} files.`;
        }
      }

      // We attempt to validate the file type based on what is available to us
      // Make sure we have a file to check and a valid accept list
      if (file && accept && accept.length > 0) {
        // Assume filetype is not accepted to begin with
        let fileTypeAccepted = false;
        // We have the mime type
        if (file.type) {
          // Make sure we aren't splitting on a bad string
          if (file.type.includes('/')) {
            // image, application, audio, video, etc
            const mimeType = file.type.split('/')[0];

            // file extension
            const fileExt = file.type.split('/')[1];

            for (const a of accept) {
              // Make sure this is a mimetype
              if (a.includes('/')) {
                const acceptedType = a.split('/')[0];
                const acceptedExt = a.split('/')[1];

                // If our filetype matches exactly, we accept the file
                if (acceptedExt === fileExt && mimeType === acceptedType) {
                  fileTypeAccepted = true;
                }

                // If we accept all of this mimetype, accept the file
                if (acceptedExt === '*' && mimeType === acceptedType) {
                  fileTypeAccepted = true;
                }
              }
            }
          }
          // If we have filetype available to us, include it in the error message
          if (!fileTypeAccepted) return `File type ${file.type} not accepted.`;
        }
        // Otherwise, we had valid accept parameters but no mimetype to use, so throw a general message
        if (!fileTypeAccepted) return `File type not accepted.`;
      }
      return null;
    },
    [accept, fileCount, fileLimit]
  );

  const handleDrop = useCallback(
    async (files: File[]) => {
      const [file] = files;
      const validationResult = validateFile(file);
      if (validationResult) {
        return onInvalid(validationResult);
      }
      const upload: UploadFile = {
        file,
        progress: {
          done: false,
          loaded: 0,
          total: 0,
        },
      };
      if (!file) {
        return null;
      }
      setUpload(upload);
      const formData = new FormData();
      formData.append('name', upload.file.name);
      formData.append('file', upload.file as File);

      if (uploadParams) {
        for (const [key, value] of Object.entries(uploadParams)) {
          formData.append(key, value);
        }
      }

      const cancelTokenSource = axios.CancelToken.source();
      cancelTokenSourceRef.current = cancelTokenSource;

      try {
        const result = await axios.request({
          method: 'post',
          cancelToken: cancelTokenSource.token,
          ...uploadConfig,
          data: formData,
          onUploadProgress: progress => {
            if (uploadConfig.onUploadProgress)
              uploadConfig.onUploadProgress(progress);

            setUpload(upload => {
              return {
                ...upload,
                progress,
              };
            });
          },
        });

        setUpload(upload => {
          return {
            ...upload,
            progress: {
              ...upload.progress,
              done: true,
            },
          };
        });

        onFinish(result);
      } catch (error) {
        setUpload(null);

        if (axios.isCancel(error)) {
          if (onCancel) onCancel();
        } else if (onError) {
          onError(error);
        }
      } finally {
        if (clearAfterUpload) {
          setUpload(null);
        }
      }
    },
    [
      uploadConfig,
      onFinish,
      onError,
      onCancel,
      onInvalid,
      validateFile,
      clearAfterUpload,
      uploadParams,
    ]
  );

  const dropzone = useDropzone({
    onDrop: handleDrop,
    maxSize,
    multiple: true,
    onDropRejected(files, event) {
      maxSize
        ? onInvalid(`File size must be less than ${formatBytes(maxSize)}`)
        : onInvalid(`There was a problem uploading the file.`);
    },
  });

  const truncateFileName = (filename): string => {
    if (filename.length > 22) {
      return `${filename.substr(0, 11)}...${filename.substr(-11)}`;
    }
    return filename;
  };

  const renderContent = (): ReactNode => {
    if (dropzone.isDragActive) {
      return (
        <Prompt>
          <i className="fas fa-inbox" />
          <span>Drop to upload</span>
        </Prompt>
      );
    } else if (upload && upload.file && upload.progress) {
      const { file, progress } = upload;
      let percent = progress.done
        ? 100
        : Math.floor((progress.loaded / progress.total) * 100);
      percent = Number.isNaN(percent) ? 0 : percent;

      const filesize = file.size || progress.total;
      const isFinishedWithUploadButBackEndIsStillWorking =
        percent >= 100 && !progress.done;

      return (
        <Content>
          <i className="fas fa-file-alt" />
          <Group>
            <strong>{truncateFileName(file.name)}</strong>
            <StyledProgress
              value={percent}
              animated={isFinishedWithUploadButBackEndIsStillWorking}
            />
            <Group justify="space-between">
              {filesize ? (
                <span className="progress-text">
                  {formatBytes(progress.done ? filesize : progress.loaded)} of{' '}
                  {formatBytes(filesize)}
                </span>
              ) : (
                <div />
              )}
              <ProgressDescription>
                <span className="progress-text">
                  {percent >= 100 ? 'Uploaded' : `Uploading... ${percent}%`}
                </span>
                {isFinishedWithUploadButBackEndIsStillWorking && (
                  <span className="progress-text">
                    Processing...{' '}
                    <FontAwesomeIcon
                      className="button-icon"
                      icon={faSpinnerThird}
                      spin
                    />
                  </span>
                )}
              </ProgressDescription>
            </Group>
          </Group>
          <Button
            variant="plain"
            onClick={e => {
              e.stopPropagation();

              if (progress?.done) {
                onDelete(upload.file);
              } else if (cancelTokenSourceRef.current) {
                cancelTokenSourceRef.current.cancel();
                setUpload(null);
              }
            }}
          >
            <span className="progress-cancel-button">
              <i className="far fa-times-circle" />
            </span>
          </Button>
        </Content>
      );
    } else {
      const types = Array.isArray(accept) ? accept : [accept];

      return (
        <Prompt>
          <i className="fas fa-cloud-upload-alt cloud" />
          {isMobile ? (
            <span>Tap to upload</span>
          ) : (
            <span className="accepted-files-title">
              Drag and drop or <a style={{ color: '#009BF2' }}>browse</a> your
              files
            </span>
          )}
          <div style={{ textAlign: 'center' }}>
            {!!types.length ? (
              <span
                className="accepted-files"
                style={{ display: 'inline-block', textAlign: 'center' }}
              >
                Accepted file types:{' '}
                <strong>
                  {types
                    .map(t => {
                      const ext = getExtension(t) || t;
                      if (t.includes('/')) {
                        const mimeType = t.split('/')[0];
                        // Render the mimetype if our accepted extension is *
                        if (ext === '*') {
                          return (
                            mimeType &&
                            mimeType.charAt(0).toUpperCase() + mimeType.slice(1)
                          );
                        }
                      }
                      return ext && ext.toUpperCase();
                    })
                    .filter(
                      (value, index, array) => array.indexOf(value) === index
                    )
                    .join(', ')}
                </strong>
              </span>
            ) : (
              <p>
                <span
                  className="accepted-files"
                  style={{ display: 'inline-block' }}
                >
                  Accepted file types:{' '}
                  <strong>JPEG, JPG, PNG, PDF, TIFF</strong>
                </span>
              </p>
            )}
            {fileLimit && (
              <span
                className="accepted-files"
                style={{ display: 'inline-block' }}
              >
                Maximum number of files allowed: <strong>{fileLimit}</strong>
              </span>
            )}
            {maxSize && (
              <span
                className="accepted-files"
                style={{ display: 'inline-block' }}
              >
                Maximum file size: <strong>{`${formatBytes(maxSize)}`}</strong>
              </span>
            )}
          </div>
        </Prompt>
      );
    }
  };

  return (
    <Container {...divProps}>
      <Dropzone
        {...dropzone.getRootProps()}
        data-testid="upload-modal-dropzone"
      >
        <input {...dropzone.getInputProps()} />
        {renderContent()}
      </Dropzone>
    </Container>
  );
};
