import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import * as lodash from 'lodash';
import styled from 'styled-components';
import {
  Alert,
  Field,
  FieldError,
  Form,
  GeneralError,
  Group,
  Input,
  Modal,
  ResponsivePadding,
  Select,
  ModalProps,
} from '../shared';
import { coreapi, permissionsapi, BrandDataResponse } from '../../api';
import {
  AuthStore,
  ToastContext,
  deriveAuthMode,
  PermissionsStore,
  SelectedMerchantStore,
} from '../../context';
import { useToaster, usePermissions } from '../../hooks';
import { FormErrors } from '../../@types';
import {
  Merchant,
  User,
  GodviewBrandData,
  Currency,
} from '@fattmerchantorg/types-omni';
import { FormApi, SubmissionErrors } from 'final-form';
import {
  ExistingUserState,
  ExistingEmailChecker,
} from './ExistingEmailChecker';
import { isValidEmail } from '../../util/validator.util';
import { compareDisplayName } from '../../util/brand.util';
import { StaxButton } from '../shared/Stax/Button';

const FormPadding = styled(ResponsivePadding)`
  > * + * {
    margin-top: 0.5rem;
  }
`;

interface AddMerchantForm {
  company_name: string;
  brand: string;
  product_type: string;
  plan: string;
  status: string;
  first_name: string;
  last_name: string;
  contact_email: string;
  password: string;
  pricing_plan: string;
  currency: Currency;
}

const onSubmit = async (
  authToken: string,
  autoCreateUser: boolean,
  existingUser: User | null,
  existingUserState: ExistingUserState,
  toast: ToastContext['toast'],
  toaster: ToastContext['toaster'],
  formValues: AddMerchantForm,
  form: FormApi<AddMerchantForm>,
  onAdd: (merchant: Merchant) => any
): Promise<SubmissionErrors | undefined | void> => {
  try {
    const {
      status,
      company_name,
      first_name,
      last_name,
      contact_email,
      brand,
      plan,
      product_type,
      password,
      pricing_plan,
      currency,
    } = formValues;
    const merchant: Merchant = await coreapi.post(authToken, '/merchant', {
      status,
      company_name,
      contact_name: `${first_name} ${last_name}`,
      contact_email,
      plan,
      product_type,
      brand,
      currency: [currency],
    });

    // Create registration record
    await coreapi.put(authToken, `/merchant/${merchant.id}/registration`, {
      business_legal_name: company_name,
      business_dba: company_name,
      first_name,
      last_name,
      email: contact_email,
      pricing_plan,
      registration_source: 'staxconnect',
    });

    if (!autoCreateUser) {
      // Return early if we do not have to create a user record.
      toaster(toast.success('Merchant successfully created.', 'Created'));
      onAdd(merchant);
      return;
    }

    let user: User;
    const shouldLinkUser: boolean =
      existingUser && existingUserState === ExistingUserState.WillLink;
    if (shouldLinkUser) {
      // Use existing user
      user = existingUser;
    } else {
      // Create new user record
      user = await coreapi.post(authToken, `/user`, {
        name: `${first_name} ${last_name}`,
        email: contact_email,
        brand,
        password,
        password_confirmation: password,
      });
    }

    // Create merchant_user record to link user to merchant
    const merchantWithUser: Merchant = await coreapi.post(
      authToken,
      `merchant/${merchant.id || ''}/user/${user.id || ''}`,
      {
        team_role: 'admin',
      }
    );

    toaster(toast.success('Merchant successfully created.', 'Created'));
    onAdd(merchantWithUser);
  } catch (err) {
    toaster(toast.error(err, 'There was a problem creating a new merchant.'));
    return err;
  }
};

/**
 * If in `Test Mode`, keep only `Test Mode user brands` as options.
 * Else the brands dropdown should reflect the `Brand Switcher` options.
 */
const calculateBrandsForNewMerchant = async (
  userBrands: any,
  setAllBrands: (allBrandsResponse: any) => void,
  toast: ToastContext['toast'],
  toaster: ToastContext['toaster'],
  isTestMode: boolean
) => {
  try {
    // If in TestMode, then only keep brands ending in "-sandbox"
    if (isTestMode) {
      const sandboxTail = '-sandbox';
      userBrands = userBrands.filter(brand => {
        const res =
          brand.name.substring(
            brand.name.length - sandboxTail.length,
            brand.name.length
          ) === '-sandbox'
            ? true
            : false;

        return res;
      });
    }

    setAllBrands(userBrands);
  } catch (err) {
    toaster(toast.error(err, 'There was a problem retrieving brand data.'));
  }
};

const fetchSelectedBrandData = async (
  token: string,
  brandName: string,
  setBrandData: (brandData: any) => void,
  toast: ToastContext['toast'],
  toaster: ToastContext['toaster']
) => {
  try {
    const brandData: BrandDataResponse = await permissionsapi.get(
      token,
      `brand/data/${brandName}`
    );
    // Sort plans alphabetically by their user-facing display names.
    if (brandData?.availablePlans) {
      brandData.availablePlans.sort(compareDisplayName);
    }
    if (brandData?.pricingFields) {
      brandData.pricingFields.sort(compareDisplayName);
    }
    setBrandData(brandData);
  } catch (err) {
    toaster(toast.error(err, 'There was a problem retrieving brand data.'));
  }
};

const productTypes = [
  { value: 'Fattpay Online', label: 'Virtual Terminal' },
  { value: 'Fattpay Mobile', label: 'Mobile' },
  { value: 'Authorize.net', label: 'Authorize.net' },
  { value: 'Terminal', label: 'Terminal' },
  { value: 'Var', label: 'Var' },
  { value: 'Clover', label: 'Clover' },
  { value: 'SwipeSimple', label: 'SwipeSimple' },
  { value: 'Other', label: 'Other' },
];

export interface Props extends ModalProps {
  onAdd: (merchant: Merchant) => any;
}
export const AddMerchantModal: FC<Props> = props => {
  const { onAdd, ...modalProps } = props;
  const { state } = useContext(AuthStore);
  const { permit } = usePermissions();
  const { toast, toaster } = useToaster();
  const authToken = state?.auth?.token || null;
  const userBrand = state?.auth?.user?.brand || null;
  const [allBrands, setAllBrands] = useState<
    {
      name: string;
      displayName: string;
    }[]
  >([]);

  const mode = deriveAuthMode(state);

  // As this form for a new merchant is completed, we will track
  // 1. whether or not the email already exists for a user in our system.
  // 2. the existing user, in case we can and want to link it to the new merchant.
  const [existingUser, setExistingUser] = useState<User | null>(null);
  const [existingUserState, setExistingUserState] = useState<ExistingUserState>(
    ExistingUserState.NotFound
  );

  // Read "user's brands" to populate brands dropdown when creating a new merchant.
  const {
    state: { brands: userBrands },
  } = useContext(PermissionsStore);

  // Read "Brand Switcher" selection to initialize brands dropdown when creating a new merchant.
  const {
    state: { selectedBrandSwitcherOption },
  } = useContext(SelectedMerchantStore);

  const [selectedBrandData, setSelectedBrandData] =
    useState<GodviewBrandData>(undefined);
  const selectedBrandId = selectedBrandData?.brandId || '';
  const availablePlans = selectedBrandData?.availablePlans || [];
  const availablePricingPlans =
    selectedBrandData?.pricingFields?.filter(p =>
      p.brands?.includes(selectedBrandId)
    ) || [];

  const shouldAutoCreateUser = !!selectedBrandData?.flags.shouldAutoCreateUser;

  /**
   * If brand dropdown is changed, return the selected value
   * Else give back the userBrand or brand selected from merchant store context
   */
  const getBrandInitialValue = useCallback(() => {
    if (selectedBrandData) {
      return selectedBrandData.brandName;
    }
    return selectedBrandSwitcherOption?.includes(',')
      ? userBrand
      : selectedBrandSwitcherOption;
  }, [selectedBrandData, userBrand, selectedBrandSwitcherOption]);

  /**
   * Give us back the first plan available or empty string
   */
  const getPlanInitialValue = () => {
    return availablePlans.length ? availablePlans[0].name : '';
  };

  //PHO-1062: refactor to make this form input Stateful to prevent
  // the provided user input from disappearing when brand is changed
  // & plans update
  const defaultFormValues = {
    company_name: '',
    brand: getBrandInitialValue(),
    product_type: '',
    plan: getPlanInitialValue(),
    status: 'PENDING',
    first_name: '',
    last_name: '',
    contact_email: '',
    password: '',
    pricing_plan: '',
    currency: 'USD',
    // TODO: update form validation so that password is required only when creating a new user
    // Then, provide a default value for password, and remove type assertion
  } as AddMerchantForm;
  const [
    {
      company_name,
      product_type,
      plan,
      status,
      first_name,
      last_name,
      contact_email,
      password,
      pricing_plan,
      currency,
    },
    setInputState,
  ] = useState(defaultFormValues);
  //Pull values from state
  const stateFormValues = {
    company_name: company_name || '',
    brand: getBrandInitialValue() || '',
    product_type: product_type || '',
    plan: plan || getPlanInitialValue(),
    status: status || 'PENDING',
    first_name: first_name || '',
    last_name: last_name || '',
    contact_email: contact_email || '',
    password: password,
    pricing_plan,
    currency,
  };
  const initialFormValues = { ...defaultFormValues, ...stateFormValues };
  // store form input in State to prevent brand switching from purging it all
  const onChangeFormInput = e => {
    const { name, value } = e.target;
    setInputState(previousState => ({ ...previousState, [name]: value }));
  };

  const availableCurrencies = lodash.intersection(
    ['USD', 'CAD'],
    selectedBrandData?.allowedCurrencies ?? []
  );

  // Fetch all brands so we can populate the 'brand' dropdown select
  useEffect(() => {
    if (
      // Guarded by permission
      permit('godview', 'brand', 'write') &&
      // Fetch only on mount.
      allBrands.length === 0
    ) {
      calculateBrandsForNewMerchant(
        userBrands,
        setAllBrands,
        toast,
        toaster,
        mode === 'TEST_MODE'
      );
    }
  }, [allBrands.length, authToken, permit, toast, toaster, mode, userBrands]);

  // Initialize brand data. The initial brand can be:
  //     1) The "Brand Switcher" selection (other than "All Brands").
  //     2) The user's brand.
  useEffect(() => {
    if (selectedBrandSwitcherOption) {
      // Default to user's brand.
      let initializeDataForBrand = userBrand;

      // Override default with "Brand Switcher" selection if
      // the user has selected a specific brand other than "All Brands".
      if (!selectedBrandSwitcherOption.includes(',')) {
        initializeDataForBrand = selectedBrandSwitcherOption;
      }

      fetchSelectedBrandData(
        authToken,
        initializeDataForBrand,
        setSelectedBrandData,
        toast,
        toaster
      );
    }
    // Here, fetch only on userBrand change (effectively on mount)
    // Below, the 'brand' dropdown select will handle re-fetching onChange of selected brand.
  }, [authToken, userBrand, toast, toaster, selectedBrandSwitcherOption]);

  return (
    <Modal
      title="New Merchant"
      {...{
        ...modalProps,
        onClose: () => {
          // This component already gets passed an onClose callback
          // for this modal as a prop so we need to call that
          // as well as reset the brand data on close
          setInputState(defaultFormValues);
          modalProps.onClose();
          fetchSelectedBrandData(
            authToken,
            selectedBrandSwitcherOption?.includes(',')
              ? userBrand
              : selectedBrandSwitcherOption,
            setSelectedBrandData,
            toast,
            toaster
          );
        },
      }}
    >
      <Form<AddMerchantForm>
        initialValues={initialFormValues}
        validate={formValues => {
          const keys = Object.keys(formValues).filter(
            key => key !== 'contact_email'
          );

          const formErrors: FormErrors<AddMerchantForm> = keys.reduce(
            (sum, key) => {
              // all fields are required -- if there is no value for `name`, set error.`name` to 'Required'
              if (key && !formValues[key]) sum[key] = 'Required';
              return sum;
            },
            {}
          );

          if (
            formValues.contact_email &&
            !isValidEmail(formValues.contact_email)
          ) {
            formErrors.contact_email = 'Please enter a valid email';
          }

          if (shouldAutoCreateUser && !existingUser && !formValues.password) {
            formErrors.password = 'Required';
          } else if (formErrors.password) {
            delete formErrors.password;
          }
          return formErrors;
        }}
        onSubmit={(formValues, form) =>
          onSubmit(
            authToken,
            shouldAutoCreateUser,
            existingUser,
            existingUserState,
            toast,
            toaster,
            formValues,
            form,
            props.onAdd
          )
        }
      >
        {formProps => (
          <FormPadding desktop="2.5%" mobile="1rem">
            <Group direction="column">
              <Field name="company_name" label="Company Name">
                {fieldProps => (
                  <Input
                    {...fieldProps.input}
                    onChange={onChangeFormInput}
                    value={company_name}
                  />
                )}
              </Field>
              <FieldError name="company_name" />
            </Group>
            {permit('godview', 'brand', 'write') && (
              <Group direction="column">
                <Field name="brand" label="Brand">
                  {fieldProps => (
                    <Select
                      {...fieldProps.input}
                      value={selectedBrandData?.brandName}
                      onChange={event => {
                        // On change of the brand select field,
                        // fetch data for the newly selected brand value
                        fieldProps.input.onChange(event as any);
                        fetchSelectedBrandData(
                          authToken,
                          event.target.value,
                          setSelectedBrandData,
                          toast,
                          toaster
                        );
                      }}
                    >
                      {allBrands &&
                        allBrands.length > 0 &&
                        allBrands.map((brand, index: number) => (
                          <option key={index} value={brand.name}>
                            {brand.displayName}
                          </option>
                        ))}
                    </Select>
                  )}
                </Field>
                <FieldError name="brand" />
              </Group>
            )}

            {permit('godview', 'productType', 'write') && (
              <Group direction="column">
                <Field name="product_type" label="Product Type">
                  {fieldProps => (
                    <Select
                      {...fieldProps.input}
                      onChange={onChangeFormInput}
                      value={product_type}
                    >
                      <option value="" disabled />
                      {productTypes.map(({ value, label }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </Select>
                  )}
                </Field>
                <FieldError name="product_type" />
              </Group>
            )}

            <Group direction="column">
              <Field name="plan" label="Plan">
                {fieldProps => (
                  <Select
                    {...fieldProps.input}
                    onChange={onChangeFormInput}
                    value={plan}
                  >
                    <option value="" disabled />
                    {availablePlans.map(({ name, displayName }) => (
                      <option key={name} value={name}>
                        {displayName}
                      </option>
                    ))}
                  </Select>
                )}
              </Field>
              <FieldError name="plan" />
            </Group>

            <Group direction="column">
              <Field name="pricing_plan" label="Pricing Plan">
                {fieldProps => (
                  <Select
                    {...fieldProps.input}
                    onChange={onChangeFormInput}
                    value={pricing_plan}
                  >
                    <option value="" disabled />
                    {availablePricingPlans.map(plan => (
                      <option key={plan.name} value={plan.name}>
                        {plan.displayName}
                      </option>
                    ))}
                  </Select>
                )}
              </Field>
              <FieldError name="plan" />
            </Group>

            {availableCurrencies.length > 0 && (
              <Group direction="column">
                <Field
                  name="currency"
                  label="Currency"
                  data-testid="currency-field"
                >
                  {fieldProps => (
                    <Select
                      {...fieldProps.input}
                      onChange={onChangeFormInput}
                      value={currency}
                    >
                      {availableCurrencies.map(currency => (
                        <option key={currency} value={currency}>
                          {currency}
                        </option>
                      ))}
                    </Select>
                  )}
                </Field>
                <FieldError name="currency" />
              </Group>
            )}

            {permit('godview', 'status', 'write') && (
              <Group direction="column">
                <Field name="status" label="Status">
                  {fieldProps => (
                    <Select
                      {...fieldProps.input}
                      onChange={onChangeFormInput}
                      value={status}
                    >
                      <option value="" disabled />
                      <option value="PENDING">Pending</option>
                      <option value="INACTIVE">Inactive</option>
                    </Select>
                  )}
                </Field>
                <FieldError name="status" />
              </Group>
            )}

            <Group direction="column">
              <Group direction="row">
                <Field name="first_name" label="Contact First Name">
                  {fieldProps => (
                    <Input
                      {...fieldProps.input}
                      onChange={onChangeFormInput}
                      value={first_name}
                    />
                  )}
                </Field>
                <FieldError name="first_name" />
                <Field name="last_name" label="Contact Last Name">
                  {fieldProps => (
                    <Input
                      {...fieldProps.input}
                      onChange={onChangeFormInput}
                      value={last_name}
                    />
                  )}
                </Field>
                <FieldError name="last_name" />
              </Group>
            </Group>

            <Group direction="column">
              <Field name="contact_email" label="Contact Email">
                {fieldProps => (
                  <Input
                    {...fieldProps.input}
                    onChange={onChangeFormInput}
                    value={contact_email}
                  />
                )}
              </Field>
              <FieldError name="contact_email" />
              <GeneralError
                id="alert-cannot-link-existing-user"
                data-testid="alert-cannot-link-existing-user"
                isVisible={existingUserState === ExistingUserState.CannotLink}
              >
                This email is already in use.
              </GeneralError>
              {(existingUserState === ExistingUserState.CanLink ||
                existingUserState === ExistingUserState.WillLink) &&
                existingUser && (
                  <Alert
                    id="alert-can-link-existing-user"
                    data-testid="alert-can-link-existing-user"
                    type="info-alt"
                    showIcon
                    style={{ width: '100%', margin: '1rem 0' }}
                    message={
                      <>
                        User <strong>{existingUser.email}</strong> already
                        exists
                      </>
                    }
                    description={
                      <div>
                        <input
                          type="checkbox"
                          checked={
                            existingUserState === ExistingUserState.WillLink
                          }
                          style={{ marginRight: '5px' }}
                          onChange={e => {
                            const existingUserState = e.target.checked
                              ? ExistingUserState.WillLink
                              : ExistingUserState.CanLink;
                            setExistingUserState(existingUserState);
                          }}
                        />
                        <span>
                          Link <strong>{existingUser.email}</strong> to{' '}
                          {formProps.values.company_name ? (
                            <strong>{formProps.values.company_name}</strong>
                          ) : (
                            'this new merchant'
                          )}
                        </span>
                        <br />
                        <small>
                          If you do not want to link this user, please use a
                          different email.
                        </small>
                        {formProps.values.brand !== existingUser.brand && (
                          <>
                            <br />
                            <GeneralError isVisible>
                              Warning: this user's brand does not match the
                              brand of the merchant you are creating. Please
                              make sure you want to link this user to this
                              merchant.
                            </GeneralError>
                          </>
                        )}
                      </div>
                    }
                  />
                )}
            </Group>

            {shouldAutoCreateUser &&
              existingUserState !== ExistingUserState.WillLink && (
                <Group direction="column">
                  <Field name="password" label="Password" type="password">
                    {fieldProps => (
                      <Input
                        {...fieldProps.input}
                        onChange={onChangeFormInput}
                        value={password}
                      />
                    )}
                  </Field>
                  <FieldError name="password" />
                </Group>
              )}

            <Group justify="flex-end" space="0px">
              <StaxButton
                type="submit"
                icon="fas fa-check"
                id="add-new-merchant-submit-button"
                data-testid="add-new-merchant-submit-button"
                loading={formProps.submitting}
                disabled={formProps.submitting}
              >
                Add
              </StaxButton>
            </Group>

            {shouldAutoCreateUser &&
              existingUserState !== ExistingUserState.WillLink && (
                <ExistingEmailChecker
                  authToken={authToken}
                  onCheck={results => {
                    const [existingUserState, existingUserOrNull] = results;
                    setExistingUser(existingUserOrNull);
                    setExistingUserState(existingUserState);
                  }}
                />
              )}
          </FormPadding>
        )}
      </Form>
    </Modal>
  );
};
