import React, { useContext, useState } from 'react';
import { ModalContext, SelectedMerchantProvider } from '../../../../context';
import { useAsyncEffect, useToaster } from '../../../../hooks';
import {
  BaseModal,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  StatusPill,
} from '@fattmerchantorg/truffle-components';
import { ButtonSpinner, ExpandablePanel } from '../../../shared';
import styled from 'styled-components';
import { Auth } from '@fattmerchantorg/types-omni';
import {
  getOnboardingLogs,
  getStateLabel,
  mapStateToStatus,
  OnboardingAuditLogs,
} from '../../../../util/onboardingAutditLog.util';
import { ProcessorNames } from '../status/submit/SubmitApplicationForm.types';
import { format } from 'date-fns';
import { OnboardingLog } from '@fattmerchantorg/types-engine/DB';
import { onboardingapi } from '../../../../api';

const StyledModalContent = styled.div`
  grid-area: content;
  overflow-y: auto;
  padding: ${({ theme }) => theme.space[4]}px;
  color: ${({ theme }) => theme.component.modal.color};

  > * {
    margin: 0 0 10px;
  }
`;

const LogContent = styled.div`
  font-size: 14px;

  ul {
    padding: 0 0 0 8px;
    margin: 0 0 16px 0;
  }

  ul:empty {
    display: none;
  }

  li {
    list-style: none;
  }

  // Styles the "Potential Matches" next sections within the log content
  div > div > ul {
    background: ${({ theme }) => theme.black};
    padding: 8px 16px;
    border-radius: 2px;
  }
`;

const ButtonBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  > * {
    margin: 0 0 0 ${({ theme }) => theme.space[4]}px;
  }
`;

interface OnboardingAuditLogDetailModalProps {
  isOpen?: boolean;
  onboarding: OnboardingAuditLogs;
  authToken: Auth['token'];
  registration: JSON;
}

export const OnboardingAuditLogDetailModal: React.FC<
  OnboardingAuditLogDetailModalProps
> = props => {
  const { modalDispatch } = useContext(ModalContext);
  const [isModalVisible] = useState(!!props.isOpen);
  const [inFlight, setInFlight] = useState(false);
  const { authToken, onboarding } = props;
  const [onboardingLogs, setOnboardingLogs] = useState<OnboardingLog[]>([]);
  const { toast, toaster } = useToaster();

  useAsyncEffect(async () => {
    if (!onboardingLogs.length) {
      const { logs } = (await getOnboardingLogs(
        authToken,
        onboarding.onboarding_id,
        onboarding.processor_name
      ).catch(error => toaster(toast.error(error)))) as {
        logs: OnboardingLog[];
      };

      setOnboardingLogs(logs);
    }
  }, [onboarding]);

  const handleCloseModal = () => {
    modalDispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const handleUpdateStatus = async () => {
    setInFlight(true);

    try {
      await onboardingapi.put(
        authToken,
        `/onboarding/${onboarding.onboarding_id}/status`
      );
      const { logs } = await getOnboardingLogs(
        authToken,
        onboarding.onboarding_id,
        onboarding.processor_name
      );
      setOnboardingLogs(logs);

      toaster(toast.success('Successfully fetched new merchant status'));
    } catch (error) {
      toaster(toast.error('There was an error updating the merchant status'));
    } finally {
      setInFlight(false);
    }
  };

  const renderPanels = () => {
    return onboardingLogs.map(
      (
        onboarding: OnboardingLog & { entry: { mid?: string | null } },
        index
      ) => {
        const status = onboarding.onboarding_state;
        return (
          <ExpandablePanel
            expanded={false}
            triggerContent={format(
              new Date(onboarding.created_at),
              'MM/dd/yyyy hh:mm a'
            )}
            triggerSuffix={
              <StatusPill
                label={getStateLabel(status)}
                status={mapStateToStatus(status)}
              />
            }
            key={index}
          >
            <LogContent>
              <div>
                {!!onboarding.entry
                  ? `MID: ${onboarding.entry?.mid ?? ''}`
                  : ''}
              </div>
              <div>Request: {onboarding.type}</div>
              <div>
                Updated At:{' '}
                {format(new Date(onboarding.updated_at), 'MM/dd/yyyy hh:mm a')}
              </div>
            </LogContent>
          </ExpandablePanel>
        );
      }
    );
  };

  const modalTitle = `${ProcessorNames[onboarding.processor_name]} Details`;

  return (
    <SelectedMerchantProvider>
      <BaseModal
        title={modalTitle}
        isOpen={isModalVisible}
        onRequestClose={handleCloseModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={false}
        style={{
          overlay: { pointerEvents: 'auto' },
          content: {
            maxWidth: '533px',
          },
        }}
      >
        <ModalHeader title={modalTitle} onClose={handleCloseModal} />
        <>
          <StyledModalContent data-testid="audit-log-detail-modal">
            {renderPanels()}
          </StyledModalContent>
          <ModalFooter>
            <ButtonBar>
              <PrimaryButton type="button" onClick={handleUpdateStatus}>
                {inFlight ? <ButtonSpinner /> : 'Request Status'}
              </PrimaryButton>
            </ButtonBar>
          </ModalFooter>
        </>
      </BaseModal>
    </SelectedMerchantProvider>
  );
};
