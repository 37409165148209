import React, {
  FunctionComponent,
  useEffect,
  useContext,
  useState,
} from 'react';
import { Section } from './BankAccountDrawerStyles';
import { Text, TextLink } from '@fattmerchantorg/truffle-components';
import { FundingAccountAuditLog } from '@fattmerchantorg/types-omni';
import styled, { withTheme } from 'styled-components';
import { renderTimestamp } from '../../util/BankAccountsPage.util';
import { StatusPill } from '@fattmerchantorg/truffle-components';
import { VerificationLogsModal } from '../modals/VerificationLogsModal';
import { ModalContext } from '../../../../context';
import {
  BusinessVerificationLabel,
  PersonVerificationLabel,
  ReportEntity,
  BusinessVerificationToOperations,
  PersonVerificationToOperations,
} from '../../../underwriting/components/external-verification/ExternalVerification.types';
import { useAuthToken } from '../../../../hooks';
import { LoadingSpan } from '../../../shared';

const PillsContainer = withTheme(
  styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px 16px 16px;
    gap: 8px;
    background: ${({ theme }) => theme.colors.core.gray[800].hex};
  `
);

const Container = withTheme(
  styled.div`
    padding-top: 8px;
  `
);

const DateContainer = withTheme(
  styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px 16px 4px;
    gap: 8px;
    background: ${({ theme }) => theme.colors.core.gray[800].hex};
  `
);

const DateText = styled(TextLink)`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 0px;
`;

const VerificationStatusPill = styled(StatusPill)`
  span {
    font-size: 12px;
  }
`;

const SectionTitle = styled(Text)`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`;

const getReportByOperationLabel = (
  verificationAuditLogs: FundingAccountAuditLog[],
  label: BusinessVerificationLabel | PersonVerificationLabel,
  entityType: ReportEntity
) => {
  return verificationAuditLogs?.filter(report => {
    const operations = report.operations_ran.split(',');

    return operations.includes(
      entityType === 'business'
        ? BusinessVerificationToOperations[label]
        : PersonVerificationToOperations[label]
    );
  });
};

export const BankAccountDrawerVerification: FunctionComponent<{
  verificationAuditLogs: FundingAccountAuditLog[];
  loading: boolean;
}> = props => {
  const { verificationAuditLogs, loading } = props;
  const [verifiedDate, setVerifiedDate] = useState<string | null>(null);
  const { modalDispatch } = useContext(ModalContext);
  const entityType: ReportEntity = 'business';
  const authToken = useAuthToken();

  useEffect(() => {
    verificationAuditLogs?.map(log => {
      setVerifiedDate(log.created_at);
    });
  }, [verificationAuditLogs]);

  let auditLogOperationData =
    entityType === 'business'
      ? {
          Entity: getReportByOperationLabel(
            verificationAuditLogs,
            'Entity',
            entityType
          ),
          'Bank Open': getReportByOperationLabel(
            verificationAuditLogs,
            'Bank Open',
            entityType
          ),
          'Bank Match': getReportByOperationLabel(
            verificationAuditLogs,
            'Bank Match',
            entityType
          ),
          TIN: getReportByOperationLabel(
            verificationAuditLogs,
            'TIN',
            entityType
          ),
          Location: getReportByOperationLabel(
            verificationAuditLogs,
            'Location',
            entityType
          ),
        }
      : {
          Identity: getReportByOperationLabel(
            verificationAuditLogs,
            'Identity',
            entityType
          ),
          Email: getReportByOperationLabel(
            verificationAuditLogs,
            'Email',
            entityType
          ),
          IP: getReportByOperationLabel(
            verificationAuditLogs,
            'IP',
            entityType
          ),
          OFAC: getReportByOperationLabel(
            verificationAuditLogs,
            'OFAC',
            entityType
          ),
          SSN: getReportByOperationLabel(
            verificationAuditLogs,
            'SSN',
            entityType
          ),
        };

  const handleViewVerificationLogsClick = () => {
    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        component: VerificationLogsModal,
        props: {
          isOpen: true,
          auditLogOperationData: auditLogOperationData,
          authToken: authToken,
        },
      },
    });
  };

  const renderPills = () => {
    return Object.keys(auditLogOperationData).map((reportKey, index) => {
      const reports = auditLogOperationData[reportKey];
      const reportsLength = reports.length;

      return (
        <VerificationStatusPill
          label={reportKey}
          status={reportsLength === 0 ? 'error' : 'success'}
          icon={reportsLength === 0 ? [['fas', 'times']] : [['fas', 'check']]}
          key={index}
        />
      );
    });
  };

  return (
    <Section>
      <SectionTitle as="h6">Verification</SectionTitle>
      {loading && <LoadingSpan height="56px" width="80%" />}
      {!loading &&
        Array.isArray(verificationAuditLogs) &&
        0 < verificationAuditLogs.length && (
          <Container>
            <DateContainer>
              {null !== verifiedDate && (
                <DateText onClick={() => handleViewVerificationLogsClick()}>
                  {renderTimestamp(verifiedDate)}
                </DateText>
              )}
            </DateContainer>
            <PillsContainer>{renderPills()}</PillsContainer>
          </Container>
        )}
    </Section>
  );
};
