import { useContext } from 'react';
import { SelectedMerchantStore } from '../context';

export function useUnderwritingStatus() {
  const {
    state: { registration },
  } = useContext(SelectedMerchantStore);

  const underwritingStatus = registration?.underwriting_status;
  const underwritingSubstatuses = registration?.underwriting_substatus;

  const isConditionallyApproved =
    underwritingStatus === 'APPROVED' && !!underwritingSubstatuses?.length;

  const isFullyApproved =
    underwritingStatus === 'APPROVED' && !underwritingSubstatuses?.length;

  return {
    isFullyApproved,
    isConditionallyApproved,
    underwritingStatus,
    underwritingSubstatuses,
  };
}
