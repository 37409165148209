import React, { FunctionComponent, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { originUrl } from '../../util/api.util';
import { FullPageLoader } from '../shared';
import { AuthStore, removeAuth } from '../../context';

export const LogoutPage: FunctionComponent = () => {
  const { dispatch } = useContext(AuthStore);
  const { search } = useLocation();

  useEffect(() => {
    dispatch(removeAuth());

    const redirect = encodeURIComponent(originUrl(`sso${search}`));
    const url = `${process.env.REACT_APP_AUTH_API_URL}/auth0/logout?redirect=${redirect}`;
    window.location.href = url;
  }, [search, dispatch]);

  return <FullPageLoader />;
};
