import React, { FC, Fragment } from 'react';
import styled from 'styled-components';
import { Transaction, Merchant } from '@fattmerchantorg/types-omni';
import { faShieldCheck } from '@fortawesome/pro-regular-svg-icons';
import {
  Drawer,
  Text,
  Tooltip,
  Icon,
  SecondaryButton,
} from '@fattmerchantorg/truffle-components';
import { DrawerProps } from '@fattmerchantorg/truffle-components/dist/Drawer/Drawer';
import { CustomerInfoWidget } from './CustomerInfoWidget';
import TransactionStatusPill from './TransactionStatusPill';
import TransactionHistoryStatusPill from './TransactionHistoryStatusPill';
import { StatusPanel } from '../../shared';
import TransactionRefundModal from './TransactionRefundModal';
import TransactionVoidModal from './TransactionVoidModal';
import useDetailsModalHook, {
  TransactionAsyncDataStatus,
} from './useDetailsModalHook';
import { CopyButton, LoadingSpan, Separator as USeparator } from '../../shared';
import {
  formatCurrency,
  formatPaymentMethodType,
  formatTruncatedId,
  formatReadableDate,
} from '../../../util/format.util';

import { PaymentMethodIcon as UPaymentMethodIcon } from './PaymentMethodIcon';
import { usePermissions, useSearchState } from '../../../hooks';
import { useEffect } from 'react';
import { FunctionComponent } from 'react';
import { TransactionData } from '../../shared/AdvancedDetailDrawer/AdvancedDetailDrawer.types';
import {
  getAchRejectionErrorMessage,
  getAvsErrorMessage,
  getErrorMessage,
} from '../../../util/transaction.util';

const Section = styled.div`
  padding: 16px 16px 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
`;

const BottomBar = styled.div`
  background: ${({ theme }) => theme.component.drawer.backgroundColor};
  padding: 16px;
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;

  display: flex;
  flex-direction: row-reverse;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:first-child) {
    margin-top: 16px;
  }

  small {
    font-size: 12px;
  }

  > *:not(:first-child) {
    margin-left: 8px;
  }
`;

const LineItemRow = styled(Row)`
  > *:last-child {
    margin-left: auto;
  }
`;

const TransactionHistoryTable = styled.table`
  width: 100%;
  margin-top: 4px;
  margin-bottom: 66px;

  > tbody {
    > tr {
      > td {
        padding-top: 12px;
        padding-bottom: 12px;
        border-bottom: 1px solid
          ${({ theme }) => theme.colors.core.gray[600].hex};
      }

      > td:last-child {
        text-align: right;

        > div {
          margin-left: auto;
        }
      }

      > td:nth-child(2) {
        text-align: center;
      }
    }
  }
`;

const DateRowContainer = styled(LineItemRow)`
  align-items: baseline;

  > small {
    margin-left: 2px !important;
  }
`;

const DateRow: FunctionComponent<{
  transaction?: Transaction;
  loading?: boolean;
}> = props =>
  props.loading ? (
    <Row>
      <LoadingSpan height="24px" width="160px" />
    </Row>
  ) : (
    <DateRowContainer>
      <span>{formatReadableDate(String(props.transaction?.created_at))}</span>
      {props.children}
    </DateRowContainer>
  );

const CopyAuthCodeButton = styled(CopyButton)`
  color: ${({ theme }) => theme.colors.core.gray[200].hex};
  font-size: 16px;

  > span:last-child {
    margin-left: 4px;
  }
`;

const AuthCode: FunctionComponent<{
  transaction: Transaction;
}> = props => {
  const authCode =
    props.transaction?.auth_id || props.transaction?.issuer_auth_code;
  return authCode ? (
    <CopyAuthCodeButton
      variant="plain"
      content={authCode}
      tooltip="Copy Transaction Auth Code to clipboard"
      icon={<Icon icon={faShieldCheck} />}
    >
      <span>{formatTruncatedId(authCode, 6)}</span>
    </CopyAuthCodeButton>
  ) : null;
};

const Separator = styled(USeparator)`
  padding-top: 16px;
`;

const PaymentMethodIcon = styled(UPaymentMethodIcon)`
  font-size: 32px;
  height: auto;
  line-height: auto;
`;

const SurchargeTooltipIconWrapper = styled.div`
  margin: 0px 8px auto 4px !important;
  margin-bottom: auto;
  font-size: 14px;
  height: 100%;
  color: ${({ theme }) => theme.colors.core.gray[200].hex};
`;

/** Props accepted by the DetailsModal component */
type TransactionDrawerProps = Partial<DrawerProps> &
  Pick<DrawerProps, 'open'> & {
    getData: ((id: string) => Promise<Transaction>) | null;
    merchant: Merchant;
  };

// Translates status to verbiage for "Won" and "Lost".
// Other statuses say "In Dispute".
const getDisputeDescription = (
  status: string
): { label?: string; description?: string } => {
  if (status === 'LOST') {
    return { label: 'Dispute Lost', description: 'Dispute was lost.' };
  } else if (status === 'PREARBITRATION') {
    return {
      description:
        'Dispute re-opened in Pre-Arbitration and funds are being held.',
    };
  } else if (status === 'WON') {
    return { label: 'Dispute Won', description: 'Dispute was won.' };
  } else if (status === 'PREARBITRATION') {
    return {
      description:
        'Dispute re-opened in Pre-Arbitration and funds are being held.',
    };
  } else if (status === 'INQUIRY') {
    return {
      description: 'There is an open dispute inquiry on this payment.',
    };
  } else if (
    ['OPEN', 'PENDING', 'EVIDENCE_UPLOADED', 'UPLOAD_FAILED'].includes(status)
  ) {
    return {
      description:
        'A dispute has been opened and funds are being held until a determination has been made.',
    };
  }
  return { label: '', description: '' };
};

const getDisputePillStatus = {
  LOST: 'error',
  WON: 'success',
  INQUIRY: 'warning',
  OPEN: 'warning',
  PENDING: 'warning',
  EVIDENCE_UPLOADED: 'warning',
  UPLOAD_FAILED: 'warning',
};

export const TransactionDrawer: FC<TransactionDrawerProps> = props => {
  const { getData, merchant, ...drawerProps } = props;

  const { permit } = usePermissions();
  const [state, dispatch] = useDetailsModalHook(getData);
  const loading =
    state.status === TransactionAsyncDataStatus.INITIAL ||
    state.status === TransactionAsyncDataStatus.LOADING;
  const transaction = state.data as TransactionData;

  const [r, setIsRefundModalOpen] = useSearchState('r');
  const [v, setIsVoidModalOpen] = useSearchState('v');

  const isRefundModalOpen = !!+r;
  const isVoidModalOpen = !!+v;

  const paymentMethodType = formatPaymentMethodType(transaction);
  const paymentMethod = transaction?.payment_method;

  const childTransactions = transaction?.child_transactions ?? [];
  const achRejectionErrorMessage = getAchRejectionErrorMessage(transaction);

  let lineItems = transaction?.meta?.lineItems;
  lineItems = Array.isArray(lineItems) ? lineItems : [];

  useEffect(() => {
    if (!transaction) return;

    // prevent user from trying to force the modal open
    // if they're real sneaky
    if (transaction.is_voidable && isRefundModalOpen)
      setIsRefundModalOpen(null);
    if (transaction.is_refundable && isVoidModalOpen) setIsVoidModalOpen(null);
  }, [
    transaction,
    isRefundModalOpen,
    isVoidModalOpen,
    setIsRefundModalOpen,
    setIsVoidModalOpen,
  ]);

  return (
    <Drawer
      {...drawerProps}
      anchor="right"
      title="Transaction Details"
      data-testid="transaction-details-modal"
      customStyles={{
        borderRadius: 0,
        padding: 0,
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <TransactionRefundModal
        state={state}
        dispatch={dispatch}
        isOpen={isRefundModalOpen}
        onClose={() => setIsRefundModalOpen(null)}
        merchant={merchant}
      />
      <TransactionVoidModal
        state={state}
        dispatch={dispatch}
        isOpen={isVoidModalOpen}
        onClose={() => setIsVoidModalOpen(null)}
        merchant={merchant}
      />
      <Section>
        {loading ? (
          <Row>
            <LoadingSpan height="42px" width="130px" />
            <LoadingSpan height="24px" width="68px" />
          </Row>
        ) : (
          <LineItemRow>
            <Text as="h2">{formatCurrency(transaction.total)}</Text>
            {merchant?.is_surcharge_enabled && transaction?.meta?.surcharge ? (
              <SurchargeTooltipIconWrapper>
                <Tooltip content="This total includes the surcharge amount">
                  <Icon icon={['far', 'info-circle']} />
                </Tooltip>
              </SurchargeTooltipIconWrapper>
            ) : null}
            <TransactionStatusPill
              transaction={transaction}
              location="drawer"
              renderDisputeLink={true}
            />
            <CopyButton
              content={transaction.id}
              tooltip="Copy Transaction ID to clipboard"
            >
              <Icon icon={['fas', 'copy']} size="1x" />
            </CopyButton>
          </LineItemRow>
        )}
      </Section>
      {achRejectionErrorMessage ? (
        <StatusPanel status="warning" style={{ marginTop: '16px' }}>
          Failed: {achRejectionErrorMessage}
        </StatusPanel>
      ) : null}
      {transaction?.dispute && (
        <StatusPanel
          data-testid="dispute-status-panel"
          status={
            getDisputePillStatus[transaction.dispute?.status]
              ? getDisputePillStatus[transaction.dispute.status]
              : 'warning'
          }
          animated
          style={{ marginTop: '16px' }}
        >
          <div style={{ display: 'inline', paddingLeft: '8px' }}>
            {getDisputeDescription(transaction.dispute.status)?.description}
            {permit('godview', 'accessSubmerchants', 'read') && (
              <a
                data-testid="payment-dispute-link"
                href={`/#/merchant/${transaction.dispute?.merchant_id}/disputes?detailId=${transaction.dispute?.id}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: 'inherit',
                  textDecoration: 'underline',
                  paddingLeft: '5px',
                }}
              >
                View Dispute
              </a>
            )}
          </div>
        </StatusPanel>
      )}
      {loading || transaction.success ? null : (
        <StatusPanel status="error" animated style={{ marginTop: '16px' }}>
          {getErrorMessage(transaction)}
          {getAvsErrorMessage(transaction)}
        </StatusPanel>
      )}
      <Section>
        <DateRow transaction={transaction} loading={loading}>
          <AuthCode transaction={transaction} />
        </DateRow>
      </Section>
      <Separator />
      <Section>
        <Text as="h5">Customer Details</Text>
        <CustomerInfoWidget
          customer={transaction?.customer}
          loading={loading}
        />
      </Section>
      <Separator />
      <Section>
        <Text as="h5">Payment Method</Text>
        {loading ? (
          <Row>
            <LoadingSpan height="24px" width="100%" />
          </Row>
        ) : (
          <LineItemRow>
            <PaymentMethodIcon method={paymentMethodType} />
            {['cash', 'giftcard', 'check'].includes(paymentMethod?.id) ? (
              <span style={{ marginLeft: '24px' }}>
                {paymentMethod?.nickname}
              </span>
            ) : (
              <span style={{ marginLeft: '24px' }}>
                {paymentMethodType?.toUpperCase()} ending in{' '}
                {paymentMethod?.card_last_four}
                {paymentMethod?.is_default ? ' (Default)' : ''}
              </span>
            )}
            {paymentMethod?.card_exp ? (
              <span>
                Expires {paymentMethod.card_exp.slice(0, 2)}/
                {paymentMethod.card_exp.slice(-2)}
              </span>
            ) : null}
          </LineItemRow>
        )}
      </Section>
      <Separator />
      <Section>
        <Text as="h5">Line Items</Text>
        {loading ? (
          <Fragment>
            <LineItemRow>
              <LoadingSpan height="24px" width="80px" />
              <LoadingSpan height="24px" width="80px" />
            </LineItemRow>
          </Fragment>
        ) : lineItems.length ? (
          lineItems.map((l, i) => (
            <Fragment key={i}>
              <LineItemRow>
                <span>{l.item || '-'}</span>
                <div>
                  <small>{l.quantity} × </small>
                  <span>{formatCurrency(l.price)}</span>
                </div>
              </LineItemRow>
              {i !== lineItems.length - 1 ? <Separator /> : null}
            </Fragment>
          ))
        ) : (
          <Fragment>
            <LineItemRow>
              <span>-</span>
              <div>-</div>
            </LineItemRow>
          </Fragment>
        )}
        <Separator />
        {merchant?.is_surcharge_enabled ? (
          <>
            <LineItemRow>
              <span>Total</span>
              <div>
                {formatCurrency(
                  +transaction?.total - (+transaction?.meta?.surcharge || 0)
                )}
              </div>
            </LineItemRow>
            <LineItemRow>
              <span>Surcharge</span>
              <div>{formatCurrency(+transaction?.meta?.surcharge || 0)}</div>
            </LineItemRow>
            <LineItemRow>
              <span>Total Paid</span>
              <div>
                {formatCurrency(transaction?.success ? transaction?.total : 0)}
              </div>
            </LineItemRow>
          </>
        ) : (
          <LineItemRow>
            <span>Total</span>
            <div>{formatCurrency(transaction?.total)}</div>
          </LineItemRow>
        )}
      </Section>
      <Separator />
      <Section>
        <Text as="h5">Transaction History</Text>
        <TransactionHistoryTable>
          <tbody>
            {loading ? (
              <tr>
                <td>
                  <DateRow loading />
                </td>
                <td>
                  <LoadingSpan height="24px" width="68px" />
                </td>
                <td>
                  <LoadingSpan height="24px" width="68px" />
                </td>
                <td>
                  <LoadingSpan height="24px" width="68px" />
                </td>
              </tr>
            ) : (
              [...childTransactions, transaction].map((t, i) => (
                <tr key={i}>
                  <td>
                    <DateRow transaction={t} />
                  </td>
                  <td>
                    <TransactionHistoryStatusPill transaction={t} />
                  </td>
                  <td>
                    <AuthCode transaction={t} />
                  </td>
                  <td>
                    {formatCurrency(
                      t.type === 'void' || t.type === 'refund'
                        ? t.total * -1
                        : t.total
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </TransactionHistoryTable>
      </Section>

      {(transaction?.is_refundable || transaction?.is_voidable) &&
      permit('godview', 'voidOrRefundSubmerchantTransactions') ? (
        <BottomBar>
          {transaction.is_refundable ? (
            <SecondaryButton onClick={() => setIsRefundModalOpen('1')}>
              Refund
            </SecondaryButton>
          ) : (
            <SecondaryButton onClick={() => setIsVoidModalOpen('1')}>
              Void
            </SecondaryButton>
          )}
        </BottomBar>
      ) : null}
    </Drawer>
  );
};
