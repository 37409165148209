import { coreapi } from '../api';
import get from 'lodash/get';
import { Omni } from '@fattmerchantorg/types-omni';
import { formatDate } from '../util/date.util';

const getRegistrationRecordForMerchantId = async (
  merchantId: string,
  authToken: string
): Promise<any> => {
  return coreapi.get(authToken, `merchant/${merchantId}/registration`, {});
};

const getRegistrationRecordForSelf = async (
  authToken: string
): Promise<any> => {
  return coreapi.get(authToken, 'team/registration', {});
};

const setRegistrationRecordForMerchantId = async (
  merchantId: string,
  registrationRecord: Partial<Omni.Registration>,
  authToken: string
): Promise<any> => {
  return await coreapi.put(authToken, `merchant/${merchantId}/registration`, {
    body: registrationRecord,
  });
};

const setRegistrationRecordForSelf = async (
  registrationRecord: Partial<Omni.Registration>,
  authToken: string
): Promise<any> => {
  return await coreapi.put(authToken, `team/registration`, {
    body: registrationRecord,
  });
};

export const getApplicationStatusLogFromRegistration = (
  registrationRecord: Omni.Registration
): any => {
  const applicationStatusLog = get(
    registrationRecord,
    'meta.applicationStatusLog',
    {}
  );

  Object.keys(applicationStatusLog).forEach(key => {
    applicationStatusLog[key].date = new Date(applicationStatusLog[key].date);
  });

  return applicationStatusLog as any;
};

export const getApplicationStatusForSelf = async (
  authToken: string
): Promise<any> => {
  const registrationRecord = await getRegistrationRecordForSelf(authToken);
  return getApplicationStatusLogFromRegistration(registrationRecord);
};

export const getApplicationStatusForMerchantId = async (
  merchantId: string,
  authToken: string
): Promise<any> => {
  const registrationRecord = await getRegistrationRecordForMerchantId(
    merchantId,
    authToken
  );

  return getApplicationStatusLogFromRegistration(registrationRecord);
};

export const mergeApplicationStatusLog = async (
  authToken: string,
  applicationStatusLog: any,
  merchantId?: string
) => {
  let registrationRecord: Omni.Registration;

  if (merchantId) {
    registrationRecord = await getRegistrationRecordForMerchantId(
      merchantId,
      authToken
    );
  } else {
    registrationRecord = await getRegistrationRecordForSelf(authToken);
  }

  const log = get(registrationRecord, 'meta.applicationStatusLog', {});

  Object.keys(applicationStatusLog).forEach((step: any) => {
    const stepInfo = applicationStatusLog[step];
    const logEntry: any = {
      ...stepInfo,
      date: stepInfo.date || formatDate(new Date(), 'yyyy-mm-dd'),
    };

    if (stepInfo.author && stepInfo.author.name) {
      // strip any extraneous properties & inject displayName
      logEntry.author = {
        id: stepInfo.author.id,
        name: stepInfo.author.name,
        displayName:
          stepInfo.author.displayName || stepInfo.author.name.split(' ')[0],
      };
    }

    log[step] = logEntry;
  });

  const meta = {
    ...registrationRecord.meta,
    applicationStatusLog: log,
  };

  if (merchantId) {
    return setRegistrationRecordForMerchantId(merchantId, { meta }, authToken);
  } else {
    return setRegistrationRecordForSelf({ meta }, authToken);
  }
};

export const underwritingAuthor = {
  id: 'underwriting',
  name: 'The Stax Underwriting Team',
  displayName: 'The Stax Underwriting Team',
};
