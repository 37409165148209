import { Dispatch } from 'react';
import { EffectReducer, useEffectReducer } from 'use-effect-reducer';

export interface BankAccountsPageModalState {
  open: 'add' | 'edit' | 'deactivate' | 'reject' | 'approve' | 'update' | null;
  body: 'form' | 'saving' | 'approved' | 'pended' | 'rejected' | 'error' | null;
}

export type BankAccountsPageModalAction =
  | {
      type: 'OPEN_MODAL';
      payload: { open: 'add'; body: BankAccountsPageModalState['body'] };
    }
  | {
      type: 'OPEN_MODAL';
      payload: {
        open: 'edit' | 'deactivate' | 'reject' | 'approve' | 'update';
      };
    }
  | { type: 'CLOSE_MODAL' };

const modalReducer: EffectReducer<
  BankAccountsPageModalState,
  BankAccountsPageModalAction
> = (state, action, exec) => {
  exec(action);

  if (action.type === 'OPEN_MODAL') {
    const { payload } = action;

    switch (payload.open) {
      case 'add':
        return { open: 'add', body: payload.body };
      case 'edit':
      case 'update':
      case 'deactivate':
      case 'reject':
      case 'approve':
        return { open: payload.open, body: null };
    }
  } else if (action.type === 'CLOSE_MODAL') {
    /**
     * if the current open modal is the 'add' modal, we need to delay removing the modal body since the modal takes time to close.
     * after the CLOSE_MODAL effect fires when the current open modal is 'add', we dispatch an additional ClOSE_MODAL action to fully close the modal.
     */
    if (state.open === 'add') {
      return { open: null, body: state.body };
    } else {
      return { open: null, body: null };
    }
  }

  return state;
};

export function openModal(
  open: 'add',
  body: BankAccountsPageModalState['body']
): BankAccountsPageModalAction;
export function openModal(
  open: 'edit' | 'deactivate' | 'reject' | 'approve' | 'update'
): BankAccountsPageModalAction;
export function openModal(
  open: BankAccountsPageModalState['open'],
  body: BankAccountsPageModalState['body'] = null
): BankAccountsPageModalAction {
  return open === 'add'
    ? { type: 'OPEN_MODAL', payload: { open, body } }
    : { type: 'OPEN_MODAL', payload: { open } };
}

export const closeModal = (): BankAccountsPageModalAction => ({
  type: 'CLOSE_MODAL',
});

export const useBankAccountsPageModalReducer = (
  initialState: BankAccountsPageModalState = { open: null, body: null }
): [BankAccountsPageModalState, Dispatch<BankAccountsPageModalAction>] => {
  const [BankAccountsPagemodalState, modalDispatch] = useEffectReducer(
    modalReducer,
    initialState,
    {
      CLOSE_MODAL: (state, _, dispatch) => {
        if (state.open === 'add') {
          /**
           * if the current open modal is the 'add' modal, dispatch an additional CLOSE_MODAL action to fully close the modal.
           * we stagger closing the 'add' modal body and actual 'add' modal since the modal takes 300ms to close.
           */
          setTimeout(() => dispatch(closeModal()), 301);
        }
      },
    }
  );

  return [BankAccountsPagemodalState, modalDispatch];
};
