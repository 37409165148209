import {
  BaseModal,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
} from '@fattmerchantorg/truffle-components';
import { Registration } from '@fattmerchantorg/types-omni';
import React, { Dispatch, useCallback, useState } from 'react';
import { useContext } from 'react';
import { Form } from 'react-final-form';
import styled from 'styled-components';
import { coreapi } from '../../../../api';
import {
  AuthStore,
  ModalContext,
  updateSelectedMerchantRegistration,
} from '../../../../context';
import { useToaster } from '../../../../hooks';
import { ButtonSpinner } from '../../../shared';
import { PricingInfoFields } from './PricingInfoFields';
import {
  PricingInfoFieldsErrors,
  PricingInfoFieldsValues,
} from './PricingInfoForm.types';
import * as pricingInfoFormUtil from './PricingInfoForm.util';

const ButtonBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  > * {
    margin: 0 0 0 ${({ theme }) => theme.space[4]}px;
  }
`;

const StyledModalContent = styled.div`
  grid-area: content;
  overflow-y: auto;
  padding: ${({ theme }) => theme.space[4]}px;
  color: ${({ theme }) => theme.component.modal.color};
`;

const validateForm = (
  formValues: PricingInfoFieldsValues
): PricingInfoFieldsErrors => {
  const errors = {
    ...pricingInfoFormUtil.validateFormValues(formValues),
  } as PricingInfoFieldsErrors;

  return errors;
};

interface ProcessingInfoModalProps {
  isOpen?: boolean;
  registration: Registration;
  merchantId: string;
  merchantDispatch: Dispatch<any>;
}

export const PricingInfoFormModal: React.FC<ProcessingInfoModalProps> =
  props => {
    const { modalDispatch } = useContext(ModalContext);
    const [isModalVisible] = useState(!!props.isOpen);
    const { registration, merchantId, merchantDispatch } = props;

    const { state: authState } = useContext(AuthStore);
    const { authToken } = authState;
    const { toaster, toast } = useToaster();
    const [isSaving, setIsSaving] = useState(false);

    const handleCloseModal = useCallback(() => {
      modalDispatch({
        type: 'CLOSE_MODAL',
      });
    }, [modalDispatch]);

    const onSubmit = useCallback(
      async (formValues: PricingInfoFieldsValues) => {
        if (!formValues) return;
        setIsSaving(true);
        const payload: Partial<Registration> = {
          ...pricingInfoFormUtil.mapFormValuesToPayload(formValues),
        };

        try {
          const r = await coreapi.put(
            authToken,
            `/merchant/${merchantId}/registration`,
            payload
          );
          merchantDispatch(updateSelectedMerchantRegistration(r));
          toaster(toast.success('Changes successfully saved', 'Saved'));
          handleCloseModal();
        } catch (error) {
          toaster(
            toast.error(error, 'There was a problem saving your changes.')
          );
        }
        setIsSaving(false);
      },
      [authToken, merchantId, merchantDispatch, toast, toaster, handleCloseModal]
    );

    return (
      <Form
        initialValues={{
          ...pricingInfoFormUtil.mapRegistrationToInitialFormValues(
            registration
          ),
        }}
        validate={formValues => {
          const validationErrors = {
            ...validateForm(formValues),
          } as PricingInfoFieldsErrors;
          return validationErrors;
        }}
        onSubmit={onSubmit}
      >
        {formProps => (
          <BaseModal
            title="Pricing Info"
            isOpen={isModalVisible}
            onRequestClose={handleCloseModal}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={false}
            style={{
              overlay: { pointerEvents: 'auto' },
              content: { maxWidth: '533px' },
            }}
          >
            <ModalHeader title="Pricing Info" onClose={handleCloseModal} />
            <>
              <StyledModalContent>
                <PricingInfoFields formProps={formProps} />
              </StyledModalContent>
              <ModalFooter>
                <ButtonBar>
                  <SecondaryButton onClick={handleCloseModal}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    onClick={() => onSubmit(formProps.values)}
                    disabled={
                      // Disable Save if there are validation errors
                      formProps.hasValidationErrors === true
                    }
                  >
                    {isSaving ? <ButtonSpinner /> : `Save`}
                  </PrimaryButton>
                </ButtonBar>
              </ModalFooter>
            </>
          </BaseModal>
        )}
      </Form>
    );
  };
