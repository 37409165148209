import React from 'react';
import styled from 'styled-components';
import { RegistrationRepresentative } from '@fattmerchantorg/types-omni';
import { Icon, Tooltip } from '@fattmerchantorg/truffle-components';
import {
  valueOrNA,
  valueOrHyphen,
  valueOrZero,
} from '../../../../util/string.util';
import { formatPhoneNumber } from '../../../../util/format.util';
import { countries } from '../../../../constants/countries';

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 0;

  p {
    font-weight: 400;
    line-height: 1.5;
  }
  a {
    font-size: 14px;
  }
`;

const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 18px 1fr;
  grid-auto-rows: auto;
  grid-column-gap: 8px;
  grid-row-gap: 4px;
  align-items: flex-start;
`;

const OwnerInfo = styled.p`
  > span {
    display: block;
  }
`;

const Address = styled.div`
  p {
    margin: 0;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

interface RepresentativeProps {
  repData: RegistrationRepresentative;
  repIndex: number;
}
export const Representative: React.FunctionComponent<
  RepresentativeProps
> = props => {
  const { repIndex } = props;
  const {
    title,
    date_of_birth,
    first_name,
    last_name,
    type,
    ssn,
    ownership_percentage,
    citizenship,
    email,
    phone,
    address_1,
    address_2,
    address_city,
    address_state,
    address_zip,
  } = props.repData;

  // Returns true if any of the address data is truthy
  const hasAddress = [
    address_1,
    address_2,
    address_city,
    address_state,
    address_zip,
  ].some(field => field !== null);

  return (
    <CardBody>
      <OwnerInfo>
        <span data-testid={`uw-rep${repIndex}-name`}>
          {valueOrNA(first_name)} {valueOrNA(last_name)}
        </span>
        {title ? (
          <span data-testid={`uw-rep${repIndex}-title`}>{title}</span>
        ) : (
          'No Title'
        )}
        {type ? (
          <span
            data-testid={`uw-rep${repIndex}-type`}
            style={{ margin: '8px 0 0' }}
          >
            {type}{' '}
            <span data-testid={`uw-rep${repIndex}-ownership-percentage`}>
              {`${valueOrZero(ownership_percentage?.toString())}%`}
            </span>
          </span>
        ) : null}
      </OwnerInfo>
      <DetailsGrid>
        <IconWrapper>
          <Tooltip content="Birthdate">
            <Icon icon={['fas', 'birthday-cake']} />
          </Tooltip>
        </IconWrapper>
        <span data-testid={`uw-rep${repIndex}-dob`}>
          {valueOrHyphen(date_of_birth)}
        </span>
        <IconWrapper>
          <Tooltip content="Citizenship">
            <Icon icon={['fas', 'passport']} />
          </Tooltip>
        </IconWrapper>
        <span data-testid={`uw-rep${repIndex}-citizenship`}>
          {countries.find(i => i.iso === citizenship)?.name}
        </span>
        <IconWrapper>
          <Tooltip content="Social Security">
            <Icon icon={['fas', 'id-card']} />
          </Tooltip>
        </IconWrapper>
        <span data-testid={`uw-rep${repIndex}-ssn`}>
          {ssn ? '***-**-****' : '-'}
        </span>
        <IconWrapper>
          <Tooltip content="Phone">
            <Icon icon={['fas', 'phone']} />
          </Tooltip>
        </IconWrapper>
        <a
          href={phone ? `tel://${phone}` : null}
          data-testid={`uw-rep${repIndex}-phone-number`}
        >
          {valueOrHyphen(formatPhoneNumber(phone))}
        </a>
        <IconWrapper>
          <Tooltip content="Email">
            <Icon icon={['fas', 'envelope']} />
          </Tooltip>
        </IconWrapper>
        <a
          href={email ? `mailto:${email}` : null}
          data-testid={`uw-rep${repIndex}-email`}
        >
          {valueOrHyphen(email)}
        </a>
        <IconWrapper>
          <Tooltip content="Address">
            <Icon icon={['fas', 'map-marker-alt']} />
          </Tooltip>
        </IconWrapper>
        <Address>
          {hasAddress ? (
            <>
              {address_1 ? (
                <p data-testid={`uw-rep${repIndex}-address-1`}>{address_1}</p>
              ) : null}
              {address_2 ? (
                <p data-testid={`uw-rep${repIndex}-address-2`}>{address_2}</p>
              ) : null}
              {address_city || address_state || address_zip ? (
                <p>
                  <span
                    data-testid={`uw-rep${repIndex}-address-city`}
                  >{`${address_city} `}</span>
                  <span
                    data-testid={`uw-rep${repIndex}-address-state`}
                  >{`${address_state} `}</span>
                  <span
                    data-testid={`uw-rep${repIndex}-address-zip`}
                  >{`${address_zip}`}</span>
                </p>
              ) : null}
            </>
          ) : (
            '-'
          )}
        </Address>
      </DetailsGrid>
    </CardBody>
  );
};
