import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import {
  Icon,
  Tooltip,
  Dropdown,
  DropdownContent,
} from '@fattmerchantorg/truffle-components';
import copy from 'copy-to-clipboard';

const TooltipLink = styled.div``;

const IconButton = styled.button`
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.core.gray[200].hex};
`;

const ContextMenu = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    &:last-child {
      border-top: 1px solid ${({ theme }) => theme.lightNeutralOutline};
    }
  }

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    a,
    button {
      display: block;
      background: none;
      border: 0;
      margin: 0;
      padding: 8px 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      text-align: left;
      cursor: pointer;
      color: ${({ theme }) => theme.white};

      span {
        margin-right: 8px;
        width: 16px;
      }
    }
  }
`;

export const CopyIdsDropdown: FunctionComponent<{
  depositId: string;
  transactionId: string;
}> = props => {
  const { depositId, transactionId } = props;
  const [clicked, setClicked] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [tooltipContent, setTooltipContent] = useState(null);
  const [id, setId] = useState(null);
  const content = clicked ? (
    'Copied'
  ) : hovered ? (
    <TooltipLink>
      {tooltipContent}
      <br />
      {id}
    </TooltipLink>
  ) : (
    'Copy ID'
  );

  return (
    <Tooltip
      content={content}
      hideOnClick={false}
      duration={[null, 250]}
      onHide={() => {
        setTimeout(
          () => {
            setClicked(false);
            setHovered(false);
          },
          // 250ms is the duration of the hide animation, as we specified above
          251
        );
      }}
    >
      <Dropdown
        placement="left-start"
        trigger={(setRef, isOpen, setIsOpen) => (
          <IconButton
            ref={setRef}
            onClick={e => {
              // Prevents drawer menu from opening when clicking button
              e.stopPropagation();
              setIsOpen(!isOpen);
            }}
            style={{
              color: '#009bf2',
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
            data-testid="deposit-transaction-context-menu-trigger"
          >
            <Icon icon={['fas', 'copy']} />
          </IconButton>
        )}
      >
        <DropdownContent style={{ minWidth: '200px', width: 'auto' }}>
          <ContextMenu>
            <ul>
              <li>
                <button
                  onClick={e => {
                    e.stopPropagation();
                    copy(depositId, { format: 'text/plain' });
                    setClicked(true);
                  }}
                  onMouseEnter={() => {
                    setTooltipContent('Copy Deposit ID to Clipboard');
                    setId(depositId);
                    setHovered(true);
                  }}
                  onMouseLeave={() => {
                    setTooltipContent(null);
                    setId(null);
                    setHovered(false);
                  }}
                >
                  Copy Deposit ID
                </button>
              </li>
              <li>
                <button
                  onClick={e => {
                    e.stopPropagation();
                    copy(transactionId, { format: 'text/plain' });
                    setClicked(true);
                  }}
                  onMouseEnter={() => {
                    setTooltipContent('Copy Transaction ID to Clipboard');
                    setId(transactionId);
                    setHovered(true);
                  }}
                  onMouseLeave={() => {
                    setTooltipContent(null);
                    setId(null);
                    setHovered(false);
                  }}
                >
                  Copy Transaction ID
                </button>
              </li>
            </ul>
          </ContextMenu>
        </DropdownContent>
      </Dropdown>
    </Tooltip>
  );
};

export default CopyIdsDropdown;
