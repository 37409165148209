import { FundingAccount } from '@fattmerchantorg/types-omni';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { StatusPanel as UnstyledStatusPanel } from '../../../shared';
import { Separator } from './BankAccountDrawerStyles';

const StatusPanel: typeof UnstyledStatusPanel = styled(UnstyledStatusPanel)`
  margin-top: 16px;
  padding: 8px 16px;
  font-size: 14px;
`;

export const BankAccountDrawerStatusPanel: FunctionComponent<{
  account: FundingAccount;
}> = props => {
  const { account } = props;

  if (!account) {
    return null;
  } else if (account.deleted_at) {
    return (
      <StatusPanel status="error">This account has been Removed</StatusPanel>
    );
  } else if (account.status === 'REJECTED') {
    return (
      <StatusPanel status="error">This account has been Rejected</StatusPanel>
    );
  } else if (account.status === 'PENDED') {
    if (account.ticket_id && account.files.length) {
      return (
        <StatusPanel status="warning" icon={['far', 'exclamation-triangle']}>
          Awaiting Approval
        </StatusPanel>
      );
    } else {
      return (
        <StatusPanel status="warning" icon={['far', 'exclamation-triangle']}>
          Please upload a Voided Check or Signed Bank Letter
        </StatusPanel>
      );
    }
  } else if (account.deleted_at) {
    return (
      <StatusPanel status="error">This account has been Removed</StatusPanel>
    );
  } else if (!account.active) {
    return (
      <StatusPanel status="error">
        This account has been Deactivated
      </StatusPanel>
    );
  } else {
    return <Separator />;
  }
};
