import React, { FunctionComponent } from 'react';

import { parse } from 'query-string';
import { useLocation } from 'react-router-dom'
import { doExport, getExportType, createFileName } from '../../util/export.util';
import { useAuthToken, useToaster } from '../../hooks';
import { Button } from '../shared/Button';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  background-color: #ffffff;
  color: #575757;
  font-size: 14px;
  height: 37px;
  margin-top: 0.5rem;
  margin-left: 16px;
  float: right;
  @media (max-width: 1200px) {
    margin: 0.5rem;
  }
  :hover {
    background-color: #ffffff;
    color: #575757;
  }
`;

type Props = {
  disabled?: boolean
};

export const ExportButton: FunctionComponent<Props> = (props) => {
  const { disabled } = props;
  let location = useLocation();
  const authToken = useAuthToken();
  const { toaster, toast } = useToaster();

  const handleExport = async () => {
    try {
      const type = getExportType(location.pathname);
      const params = parse(location.search);

      const csv = await doExport(authToken, type, params);
  
      const encodedCsv = encodeURIComponent(csv);

      let link = document.createElement('a');
      link.href = `data:attachment/csv;charset=utf-8,%EF%BB%BF${encodedCsv}`;
      link.download = createFileName(type, 'csv');
      link.click();
      link.remove();
    } catch (error) {
      toaster(
        toast.error(error, 'There was a problem downloading the export.')
      );
    }
  };

  return (
    <StyledButton
      size="small"
      id="export-button" 
      data-testid="export-button"
      disabled={disabled}
      onClick={() => handleExport()}
    >
      <i className="fas fa-download" style={{ paddingRight: 10 }} />
      Export
    </StyledButton>
  );
};

ExportButton.defaultProps = {
  disabled: false
};
