import React, { FunctionComponent, useMemo } from 'react';
import { TextField, Select } from '@fattmerchantorg/truffle-components';
import { Field } from '../../../shared/Field';
import {
  FormRow,
  PartialForm,
  TwoColumn,
} from '../shared/UnderwritingPartialForm';
import { accountTypes } from '../../../../util/registration.util';
import { FieldGroupTitle } from '../shared/UnderwritingSection';
import { FormRenderProps } from 'react-final-form';
import { formatRoutingNumber } from '../../../../util/format.util';
import { MaskedInput } from '../../../shared/MaskedInput';
interface BankingInfoFieldsProps {
  formProps?: FormRenderProps;
}

export const BankingInfoFields: FunctionComponent<
  BankingInfoFieldsProps
> = props => {
  const accountTypeOptions = useMemo(() => {
    return accountTypes.map(type => {
      return { label: type.value, value: type.key };
    });
  }, []);
  const secondaryBankAccountPurpose = [
    { value: 'discount', label: 'Discount' },
    { value: 'fees', label: 'Fees' },
    { value: 'credits', label: 'Credits' },
    { value: 'chargebacks', label: 'Chargebacks' },
  ];
  const secondaryAccountTypeOptions = [
    { value: 'checking', label: 'Checking' },
    { value: 'savings', label: 'Savings' },
  ];
  return (
    <PartialForm>
      <FormRow>
        <FieldGroupTitle>Primary Bank Account</FieldGroupTitle>
        <FormRow>
          <Field name="bank_account_owner_name">
            {props => (
              <TextField {...props.input} type="text" label="Account Holder" />
            )}
          </Field>
        </FormRow>
        <TwoColumn>
          <div>
            <Field name="bank_routing_number" format={formatRoutingNumber}>
              {props => (
                <TextField
                  {...props.input}
                  type="text"
                  label="Routing Number"
                />
              )}
            </Field>
          </div>
          <div>
            <Field name="bank_account_number">
              {props => (
                <MaskedInput
                  {...props.input}
                  label="Account Number"
                  maskType="accountNumber"
                />
              )}
            </Field>
          </div>
          <div>
            <label>Purpose</label>
            <Field
              name="bank_account_type"
              format={value => {
                return accountTypeOptions.find(
                  option => option.value === value
                );
              }}
              clearable={false}
            >
              {props => {
                return (
                  <div data-testid="account_type">
                    <Select {...props.input} options={accountTypeOptions} />
                  </div>
                );
              }}
            </Field>
          </div>
        </TwoColumn>
      </FormRow>
      <FormRow>
        <FieldGroupTitle>Secondary Bank Account</FieldGroupTitle>
        <FormRow>
          <div>
            <Field name="secondary_bank_account_owner_name">
              {props => (
                <TextField
                  {...props.input}
                  type="text"
                  label="Account Holder"
                />
              )}
            </Field>
          </div>
        </FormRow>
        <TwoColumn>
          <div>
            <Field
              name="secondary_bank_routing_number"
              format={formatRoutingNumber}
            >
              {props => (
                <TextField
                  {...props.input}
                  type="text"
                  label="Routing Number"
                />
              )}
            </Field>
          </div>
          <div>
            <Field name="secondary_bank_account_number">
              {props => (
                <MaskedInput
                  {...props.input}
                  label="Account Number"
                  maskType="accountNumber"
                />
              )}
            </Field>
          </div>
          <div>
            <label>Account Type</label>
            <Field
              name="secondary_bank_account_type"
              format={value => {
                return secondaryAccountTypeOptions.find(
                  option => option.value === value
                );
              }}
              clearable={false}
            >
              {props => {
                return (
                  <div data-testid="secondary_account_type">
                    <Select
                      {...props.input}
                      options={secondaryAccountTypeOptions}
                      styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                        input: base => ({
                          ...base,
                          color: 'white',
                          padding: 0,
                        }),
                      }}
                      menuPortalTarget={document.body}
                      menuShouldScrollIntoView={false}
                      isSearchable={true}
                      menuPosition="fixed"
                    />
                  </div>
                );
              }}
            </Field>
          </div>
          <div>
            <label>Account Purpose</label>
            <Field
              name="secondary_bank_account_purpose"
              format={value => {
                return secondaryBankAccountPurpose.find(
                  option => option.value === value
                );
              }}
              clearable={false}
            >
              {props => {
                return (
                  <div data-testid="secondary_account_purpose">
                    <Select
                      {...props.input}
                      options={secondaryBankAccountPurpose}
                      styles={{
                        menuPortal: base => ({ ...base, zIndex: 9999 }),
                        input: base => ({
                          ...base,
                          color: 'white',
                          padding: 0,
                        }),
                      }}
                      menuPortalTarget={document.body}
                      menuShouldScrollIntoView={false}
                      isSearchable={true}
                      menuPosition="fixed"
                    />
                  </div>
                );
              }}
            </Field>
          </div>
        </TwoColumn>
      </FormRow>
    </PartialForm>
  );
};
