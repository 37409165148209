import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { from, forkJoin, Observable } from 'rxjs';
import {
  googleVerifyBusiness,
  GoogleVerifyBusinessReport,
} from '../../../../services/google-places-service';
import {
  postToGiact,
  GiactReport,
  GiactResult,
} from '../../../../services/giact-service';
import {
  postToTinCheck,
  TincheckReport,
} from '../../../../services/tincheck-service';
import { SelectedMerchantStore } from '../../../../context';
import { UnderwritingContext } from '../../context';
import { useAuthToken, useToaster } from '../../../../hooks';
import {
  getAllAuditLogs,
  getAutoVerifyAuditLog,
} from '../../../../util/auditLog.util';
import { UnderwritingSection } from '../shared/UnderwritingSection';
import { AuditLog } from './AuditLog';
import { ReportEntity } from './ExternalVerification.types';
import { ModalContext } from '../../../../context';
import { SignerSelectionModal } from './SignerSelectionModal';
import { Signer } from './ExternalVerification.types';
import { sortReportsByEntityType } from '../../../../util/auditLog.util';
import { useAsyncEffect } from '../../../../hooks';
import { OnboardingLog } from './OnboardingAuditLog';
import { AutoVerificationStatus } from './AutoVerificationStatus';
import { get } from '../../../../util/object.util';
import { VerifyError, RequiredFieldErrors } from '../../../../@types';
import { getAllCompanyOnboardings } from '../../../../util/catan.util';

export interface ExternalVerificationProps {
  activeTab?: string;
}

type AnyReport =
  | { report: GoogleVerifyBusinessReport; servicesRan: string[] }
  | { report: GiactReport; result: GiactResult; servicesRan: string[] }
  | { report: TincheckReport; servicesRan: string[] };

type AnyReportOrError = AnyReport | RequiredFieldErrors | VerifyError[] | Error;

const ExternalVerificationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TabHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 24px;
`;
const TabHeader = styled.div`
  padding: 4px 16px;
  cursor: pointer;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  border-bottom: 2px solid;
  &.active {
    color: #59a97e;
    font-weight: bold;
  }
`;

export function ExternalVerification(_: ExternalVerificationProps) {
  const {
    state: { merchantId, registration },
  } = useContext(SelectedMerchantStore);
  const { underwritingState, underwritingDispatch } =
    useContext(UnderwritingContext);
  const { modalDispatch } = useContext(ModalContext);
  const authToken = useAuthToken();
  const [newReports, setNewReports] = useState<AnyReportOrError[]>([]);
  const { toast, toaster } = useToaster();
  const [runningVerification, setRunningVerification] =
    useState<ReportEntity | null>(null);
  const hasAdditionalReps = registration?.meta?.representatives?.length > 0;
  const [tab, setTab] = React.useState(_.activeTab || 'business');
  const engineCompanyId = registration?.external_company_id;

  // Fetch existing reports from audit logs endpoint
  useAsyncEffect(async () => {
    // refetch audit logs any time the selected merchant changes
    // so we don't show the audit logs for the wrong merchant
    if (underwritingState.merchantId !== merchantId) {
      const auditLogs = await getAllAuditLogs(authToken, merchantId).catch(
        error => toaster(toast.error(error))
      );

      underwritingDispatch({
        type: 'SET_AUDIT_LOGS',
        payload: {
          merchantId,
          auditLogs: auditLogs ? sortReportsByEntityType(auditLogs) : null,
        },
      });
    }
  }, [underwritingState.auditLogs, authToken, merchantId, toaster, toast]);

  // Fetch auto verification result from audit logs endpoint
  useAsyncEffect(async () => {
    if (!underwritingState.autoverificationResult) {
      const autoResult = await getAutoVerifyAuditLog(
        authToken,
        merchantId
      ).catch(error => toaster(toast.error(error)));

      underwritingDispatch({
        type: 'SET_AUTOVERIFICATION_RESULT',
        payload: {
          autoverificationResult: get(
            autoResult,
            'result.autoverificationResult',
            null
          ),
        },
      });
    }
  }, [
    underwritingState.autoverificationResult,
    authToken,
    merchantId,
    toaster,
    toast,
  ]);

  useAsyncEffect(async () => {
    // refetch onboarding logs any time the selected merchant changes
    // so we don't show the onboarding logs for the wrong merchant
    if (engineCompanyId) {
      const onboardings = await getAllCompanyOnboardings(
        authToken,
        engineCompanyId
      ).catch(error => toaster(toast.error(error)));

      if (!!onboardings)
        underwritingDispatch({
          type: 'SET_ONBOARDING_LOGS',
          payload: {
            merchantId,
            onboardingLogs: onboardings.data ? onboardings.data : null,
          },
        });
    }
  }, [authToken, toaster, toast]);

  const handleVerificationSubmission = (
    entityType: ReportEntity,
    signer?: Signer
  ) => {
    if (!registration) return;

    let googlebusiness: boolean;
    let gverify: boolean;
    let gauthenticate: boolean;
    let gidentify: boolean;
    let gofac: boolean;
    let gesi: boolean;
    let tincheck: boolean;

    if (entityType === 'business') {
      googlebusiness = true;
      gauthenticate = true;
      gidentify = true;
      gverify = true;
      gofac = false;
      gesi = false;
      tincheck = true;
    } else if (entityType === 'person') {
      googlebusiness = false;
      gauthenticate = false;
      gidentify = true;
      gverify = false;
      gofac = true;
      gesi = true;
      tincheck = true;
    }

    setRunningVerification(entityType);

    // collection of report streams to run
    let reports$: Observable<AnyReportOrError>[] = [];
    let googleReport$: Observable<
      | { report: GoogleVerifyBusinessReport; servicesRan: string[] }
      | RequiredFieldErrors
    >;
    let giactReport1$: Observable<
      | { report: GiactReport; result: GiactResult; servicesRan: string[] }
      | RequiredFieldErrors
    >;
    let giactReport2$: Observable<
      | { report: GiactReport; result: GiactResult; servicesRan: string[] }
      | RequiredFieldErrors
    >;
    let tincheckReport$: Observable<
      { report: TincheckReport; servicesRan: string[] } | VerifyError[] | Error
    >;

    // build reports
    // depending upon what's checked in External Verification
    // i.e. servicesToRun
    const servicesToRun = {
      gauthenticate,
      gidentify,
      gverify,
      gofac,
      gesi,
    };

    // if both gidentify and gauthenticate are enabled, they need to run separately
    if (gidentify && gauthenticate) {
      // make two seperate calls
      // first call should run gidentify
      giactReport1$ = from(
        postToGiact(
          authToken,
          registration,
          { gidentify },
          entityType,
          merchantId,
          signer
        )
      );

      // second call can run gauthenticate and anything else that's enabled
      // but we need to strip out gidentify from servicesToRun
      const restOfServicesToRun = {
        ...servicesToRun,
        gidentify: undefined,
      };

      giactReport2$ = from(
        postToGiact(
          authToken,
          registration,
          restOfServicesToRun,
          entityType,
          merchantId,
          signer
        )
      );

      reports$ = [...reports$, giactReport1$, giactReport2$];
    } else if (gverify || gidentify || gauthenticate || gofac || gesi) {
      giactReport1$ = from(
        postToGiact(
          authToken,
          registration,
          servicesToRun,
          entityType,
          merchantId,
          signer
        )
      );
      reports$ = [...reports$, giactReport1$];
    }

    if (googlebusiness) {
      googleReport$ = from(
        googleVerifyBusiness(authToken, registration, merchantId)
      );
      reports$ = [...reports$, googleReport$];
    }

    if (tincheck) {
      tincheckReport$ = from(
        postToTinCheck(registration, merchantId, entityType, authToken, signer)
      );
      reports$ = [...reports$, tincheckReport$];
    }

    forkJoin(reports$).subscribe(
      results => {
        const allResults = [...newReports, ...results];
        setNewReports(allResults);
        dispatchAuditLogs(authToken, merchantId, entityType);

        // If the result set contains a list of required fields show them in a
        // toast
        let requiredFields: VerifyError[] = [];
        results.forEach(result => {
          if ((result as RequiredFieldErrors).requiredFields) {
            requiredFields.push(
              ...(result as RequiredFieldErrors).requiredFields
            );
          }
        });

        if (requiredFields.length > 0) {
          const messages = requiredFields.map((error, index) => {
            return (
              <p key={index}>
                {error.code}: {error.text}
              </p>
            );
          });
          toaster(
            toast.error(<>{messages}</>, 'Verification Error', {
              lifetime: 'forever',
            })
          );
        } else {
          toaster(
            toast.success(
              'Verification results have been added to the logs.',
              'Verifications Complete'
            )
          );
        }
        setRunningVerification(null);
      },
      error => {
        dispatchAuditLogs(authToken, merchantId, entityType);
        toaster(toast.error(error, 'Verification Error'));
        setRunningVerification(null);
      }
    );
  };

  const dispatchAuditLogs = (authToken, merchantId, entityType) => {
    getAllAuditLogs(authToken, merchantId)
      .then(externalVerificationReports => {
        underwritingDispatch({
          type: 'SET_AUDIT_LOGS',
          payload: {
            auditLogs: sortReportsByEntityType(externalVerificationReports),
          },
        });
        scrollLogToTop(entityType);
      })
      .catch(error => {
        toast.error(error, 'Error while retrieving audit logs');
      });
  };

  const handleSelectSigner = () => {
    if (!hasAdditionalReps) return null;
    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        component: SignerSelectionModal,
        props: {
          isOpen: true,
          registration: registration,
          authToken: authToken,
          onRunVerification: (selectedSigner: Signer) =>
            handleVerificationSubmission('person', selectedSigner),
        },
      },
    });
  };

  const scrollLogToTop = (logType: ReportEntity) => {
    let logElement: HTMLElement;

    if (logType === 'business') {
      logElement = document.getElementById('underwriting-business-audit-log');
    }

    if (logType === 'person') {
      logElement = document.getElementById('underwriting-person-audit-log');
    }

    if (logElement) {
      logElement.scrollTop = 0;
    }
  };

  const autoverificationResult = get(
    underwritingState,
    'autoverificationResult',
    null
  );

  return (
    <UnderwritingSection
      title="External Verification"
      id="underwriting-external-verification"
      rightContent={() =>
        autoverificationResult && (
          <AutoVerificationStatus
            runningVerification={runningVerification}
            autoverificationResult={autoverificationResult}
          />
        )
      }
    >
      {({ setToolTipContent }) => {
        setToolTipContent(
          'Results from outside tools such as GIACT and Experian.'
        );

        return (
          <ExternalVerificationContainer>
            <TabHeaderContainer>
              <TabHeader
                onClick={() => setTab('business')}
                className={tab === 'business' ? 'active' : ''}
              >
                Business
              </TabHeader>
              <TabHeader
                onClick={() => setTab('signer')}
                className={tab === 'signer' ? 'active' : ''}
              >
                Signer
              </TabHeader>
              <TabHeader
                onClick={() => setTab('onboarding')}
                className={tab === 'onboarding' ? 'active' : ''}
              >
                Onboarding
              </TabHeader>
              <TabHeader
                onClick={() => setTab('applications')}
                className={tab === 'applications' ? 'active' : ''}
              >
                Applications
              </TabHeader>
              <TabHeader
                onClick={() => setTab('experian')}
                className={tab === 'experian' ? 'active' : ''}
              >
                Experian
              </TabHeader>
              <TabHeader
                onClick={() => setTab('coris')}
                className={tab === 'coris' ? 'active' : ''}
              >
                Coris
              </TabHeader>
            </TabHeaderContainer>

            <OnboardingLog
              logType={tab}
              reports={underwritingState.onboardingLogs}
              auditLogReports={underwritingState.auditLogs}
              onRunBusinessVerification={() =>
                handleVerificationSubmission('business')
              }
              onRunPersonVerification={() =>
                hasAdditionalReps
                  ? handleSelectSigner()
                  : handleVerificationSubmission('person')
              }
              runningVerification={runningVerification}
            />
            <AuditLog
              logType={tab}
              reports={underwritingState.auditLogs}
              onRunBusinessVerification={() =>
                handleVerificationSubmission('business')
              }
              onRunPersonVerification={() =>
                hasAdditionalReps
                  ? handleSelectSigner()
                  : handleVerificationSubmission('person')
              }
              runningVerification={runningVerification}
            />
          </ExternalVerificationContainer>
        );
      }}
    </UnderwritingSection>
  );
}
