import {
  OmniDispute as Dispute,
  DisputeFile,
} from '@fattmerchantorg/types-omni';
import React, {
  FunctionComponent,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import { AsyncDataStatus } from '../../@types';
import { onboardingapi } from '../../api';
import {
  useAuthToken,
  useSearchState,
  useSupportingDocuments,
  useUpdateNumber,
} from '../../hooks';
import { defaultTableOptions, PageHeader, SearchCollection } from '../shared';
import { CollectionData } from '../shared/Collection/Collection.types';
import { sendDisputeIDChanged } from './actions';
import { AcceptDisputeModal } from './components/AcceptDisputeModal';
import { DisputeDrawer } from './components/DisputeDrawer';
import { SupportingDocumentsModal } from './components/SupportingDocumentsModal';
import { getColumns } from './Disputes.util';
import useDetailsModalHook from './useDetailsModalHook';
export interface MerchantDisputeCombo {
  merchantId: string;
  disputeId: string;
}

type DisputesProps = {
  merchantId?: string;
};

export const Disputes: FunctionComponent<DisputesProps> = props => {
  const authToken = useAuthToken();
  const { params } = useRouteMatch<{ merchantId?: string }>();
  const merchantId = props.merchantId || params.merchantId;
  const [isModalOpen, setIsModalOpen] = useState<{
    supportingDocumentModal: boolean;
    acceptDIsputeModal: boolean;
  }>({
    supportingDocumentModal: false,
    acceptDIsputeModal: false,
  });
  const [detailId, setDetailId] = useSearchState<string>('detailId');
  const [merchantDisputeCombo, setMerchantDisputeCombo] =
    useState<MerchantDisputeCombo>(null);

  const [numberOfLoadingItems, setNumberOfLoadingItems] = useState(20);

  const [hasDeadlinePassed, setHasDeadlinePassed] = useState(false);

  const handleReceiveData = useCallback((data: CollectionData<Dispute>) => {
    if (data.per_page) setNumberOfLoadingItems(data.per_page);
  }, []);

  const handleOpenSupportingDocumentsModal = (dispute: Dispute) => {
    const disputeId = dispute.id;
    const merchantId = dispute.merchant_id;

    const now = new Date().toString();
    setHasDeadlinePassed(Date.parse(dispute.respond_by) - Date.parse(now) < 0);

    setMerchantDisputeCombo({ merchantId, disputeId });
    setIsModalOpen(() => ({
      supportingDocumentModal: true,
      acceptDIsputeModal: false,
    }));
  };

  const handleOpenAcceptDisputesModal = (dispute: Dispute) => {
    const disputeId = dispute.id;
    const merchantId = dispute.merchant_id;

    const now = new Date().toString();
    setHasDeadlinePassed(Date.parse(dispute.respond_by) - Date.parse(now) < 0);

    setMerchantDisputeCombo({ merchantId, disputeId });
    setIsModalOpen(() => ({
      supportingDocumentModal: false,
      acceptDIsputeModal: true,
    }));
  };

  const getColumnConfig = useCallback(
    () => getColumns(merchantId),
    [merchantId]
  );

  const getData = useCallback(
    query => {
      return onboardingapi.get(authToken, `/disputes/${merchantId}`, {
        ...query,
        order: query.order || 'ASC',
        sort: query.sort || 'deadline',
      });
    },
    [authToken, merchantId]
  );

  const getDispute = useCallback(
    (disputeId: string): Promise<Dispute> => {
      return onboardingapi.get(
        authToken,
        `/disputes/${merchantId}/${disputeId}`
      );
    },
    [authToken, merchantId]
  );

  const postDispute = useCallback(
    (disputeId: string): Promise<Dispute> => {
      return onboardingapi.post(authToken, `/dispute/${disputeId}/accept`);
    },
    [authToken]
  );

  const [drawerState, drawerDispatch] = useDetailsModalHook(getDispute);
  const [supportingDocuments, setSupportingDocuments] = useState<DisputeFile[]>(
    []
  );

  const files = drawerState.data?.files;
  useEffect(() => {
    setSupportingDocuments(files ?? []);
  }, [files]);

  const incrementUpdateNumber = useUpdateNumber()[1];

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { submitDispute } = useSupportingDocuments(
    merchantDisputeCombo,
    setIsLoading,
    true
  );

  const onModalClose = () => {
    setMerchantDisputeCombo(null);
    setIsModalOpen(() => ({
      supportingDocumentModal: false,
      acceptDIsputeModal: false,
    }));
  };

  const onDrawerClose = () => {
    setDetailId(null);
    setSupportingDocuments([]);
  };

  const handleSubmit = async () => {
    await submitDispute();
    incrementUpdateNumber();
    drawerDispatch(sendDisputeIDChanged());
  };

  return (
    <>
      <PageHeader title="Disputes" />
      <SupportingDocumentsModal
        onClose={onModalClose}
        isOpen={isModalOpen.supportingDocumentModal}
        merchantDisputeCombo={merchantDisputeCombo}
        hasDeadlinePassed={hasDeadlinePassed}
        supportingDocuments={supportingDocuments}
        isLoading={isLoading}
        onUpload={file => {
          setSupportingDocuments([...supportingDocuments, file]);
        }}
        onDelete={file => {
          setSupportingDocuments(
            supportingDocuments.filter(f => {
              return f.id !== file.id;
            })
          );
        }}
        handleSubmit={handleSubmit}
      />
      <AcceptDisputeModal
        isOpen={isModalOpen.acceptDIsputeModal}
        hasDeadlinePassed={hasDeadlinePassed}
        merchantDisputeCombo={merchantDisputeCombo}
        postDispute={postDispute}
        submitDisputeAccept={() => {
          incrementUpdateNumber();
          drawerDispatch(sendDisputeIDChanged());
        }}
        onClose={() => {
          setMerchantDisputeCombo(null);
          setIsModalOpen(() => ({
            supportingDocumentModal: false,
            acceptDIsputeModal: false,
          }));
        }}
      />
      <SearchCollection<Dispute>
        data-testid={`dispute-collection-${merchantId}`}
        getData={getData}
        onReceiveData={handleReceiveData}
        numberOfLoadingItems={numberOfLoadingItems}
        onRowClick={d => setDetailId(d.id)}
        blacklist={['detailId']}
        columns={getColumnConfig}
        useTableOptions={{
          options: {
            ...defaultTableOptions.options,
            ...{
              initialState: {
                sortBy: [{ id: 'deadline', desc: false }],
                pageSize: 10,
                pageIndex: 0,
              },
            },
          },
          plugins: defaultTableOptions.plugins,
        }}
        noResultsMessage={
          window.location.hash.includes('id=')
            ? `No data.` // Match generic message displayed in `Fattpay - Disputes`.
            : `This merchant has no dispute data.` // Default message.
        }
        showSearchBar={false}
        // Hide pagination when filtering by `id`
        paginated={window.location.hash.includes('id=') ? false : true}
      />
      <DisputeDrawer
        open={!!detailId}
        onClose={onDrawerClose}
        dispute={drawerState.data}
        loading={
          drawerState.status === AsyncDataStatus.INITIAL ||
          drawerState.status === AsyncDataStatus.LOADING
        }
        onUploadDocs={handleOpenSupportingDocumentsModal}
        onAcceptDispute={handleOpenAcceptDisputesModal}
        merchantDisputeCombo={merchantDisputeCombo}
        setMerchantDisputeCombo={setMerchantDisputeCombo}
        supportingDocuments={supportingDocuments}
        isLoading={isLoading}
      />
    </>
  );
};
