import React from 'react';
import { Merchant, Transaction } from '@fattmerchantorg/types-omni';
import { ColumnProps } from '../shared';
import PaymentMethod from './components/PaymentMethodIcon';
import TransactionStatus from './components/TransactionStatus';
import TransactionSourceIcon from './components/TransactionSourceIcon';
import TransactionStatusPill from './components/TransactionStatusPill';
import { Id } from '../shared';
import {
  formatPaymentMethodType,
  formatReadableDate,
  formatCurrency,
} from '../../util';
import { TransactionData } from '../shared/AdvancedDetailDrawer/AdvancedDetailDrawer.types';

export const getColumns = (
  merchant: Merchant | null
): ColumnProps<Transaction>[] => {
  const columns: ColumnProps<Transaction>[] = [
    {
      id: 'status',
      accessor: 'success',
      disableSortBy: true,
      Header: 'Status',
      Cell: cell => {
        return <TransactionStatus isSuccessful={cell.value} />;
      },
    },
    {
      id: 'user',
      accessor: 'user',
      disableSortBy: true,
      Header: 'User',
      Cell: cell => {
        const transaction = cell.row.original;
        return transaction.user?.name ?? '';
      },
    },
    {
      id: 'id',
      accessor: 'id',
      disableSortBy: true,
      Header: 'ID',
      Cell: cell => {
        const transaction = cell.row.original;
        return <Id record={transaction} />;
      },
    },
    {
      id: 'customer',
      accessor: 'customer',
      disableSortBy: true,
      Header: 'Customer',
      Cell: cell => {
        const transaction = cell.row.original;
        return (
          transaction.customer &&
          `${transaction.customer.firstname} ${transaction.customer.lastname}`
        );
      },
    },
    {
      id: 'method',
      accessor: 'payment_method',
      disableSortBy: true,
      Header: 'Payment Method',
      Cell: cell => {
        const transaction = cell.row.original;
        return <PaymentMethod method={formatPaymentMethodType(transaction)} />;
      },
    },
    {
      id: 'source',
      accessor: 'source',
      disableSortBy: true,
      Header: 'Source',
      Cell: cell => {
        const transaction = cell.row.original;
        return (
          <TransactionSourceIcon
            source={transaction.source}
            name={transaction.user?.name}
          />
        );
      },
    },
    {
      id: 'type',
      accessor: 'type',
      disableSortBy: true,
      Header: 'Type',
      Cell: cell => {
        const transaction = cell.row.original as TransactionData;
        return <TransactionStatusPill transaction={transaction} />;
      },
    },
    {
      id: 'created_at',
      accessor: 'created_at',
      Header: 'Date',
      Cell: cell => {
        const transaction = cell.row.original;
        return formatReadableDate(String(transaction.created_at));
      },
    },
  ];

  if (merchant?.is_surcharge_enabled) {
    columns.push(
      {
        id: 'total paid',
        accessor: 'total',
        disableSortBy: true,
        Header: 'Total Paid',
        style: { textAlign: 'right' },
        Cell: cell => {
          const transaction = cell.row.original;
          return (
            <div>
              {formatCurrency(transaction.success ? transaction.total : 0)}
            </div>
          );
        },
      },
      {
        id: 'total',
        accessor: 'total',
        Header: 'Total',
        style: { textAlign: 'right' },
        Cell: cell => {
          const transaction = cell.row.original;
          return (
            <div>
              {formatCurrency(
                transaction.total - (transaction?.meta?.surcharge ?? 0)
              )}
            </div>
          );
        },
      }
    );
  } else {
    columns.push({
      id: 'amount',
      accessor: 'total',
      Header: 'Amount',
      style: { textAlign: 'right' },
      Cell: cell => {
        const transaction = cell.row.original;
        return <div>{formatCurrency(transaction.total)}</div>;
      },
    });
  }

  return columns;
};
