import React from 'react';
import { AdjustmentProcessingDetailsSectionData } from '../AdvancedDetailDrawer.types';
import { Accordion } from '@fattmerchantorg/truffle-components';
import styled from 'styled-components';
import { DrawerSection } from '../sharedComponents';
import { formatCurrency } from '../../../../util';
import { AccordionWrapper } from '../sharedComponents';

type AdjustmentProcessingDetailsSectionProps = {
  id: string;
  name: string;
  data: AdjustmentProcessingDetailsSectionData;
};

const DataContainer = styled.div`
  border-right: 1px solid ${({ theme }) => theme.lightNeutralOutline};
  flex-basis: 100%;
  padding: 0 8px;
  &:first-child {
    padding: 0 8px 0 0;
  }
  &:last-child {
    border-right: 0;
  }
`;

const ProcessingDetailRow = styled.div`
  display: flex;
  padding: 4px 0;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  scroll-snap-align: start;
`;

const Title = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  float: left;
  margin-right: 8px;
`;

const Value = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`;

const BreakLine = styled.hr`
  margin: 0;
  width: 100%;
  border-bottom: 1px solid #435e70;
`;

const AdjustmentProcessingDetailsSection: React.FunctionComponent<
  AdjustmentProcessingDetailsSectionProps
> = props => {
  const { data, id = 'adjustment-processing-details-drawer-section' } = props;

  return (
    <AccordionWrapper id={id}>
      <Accordion title="Processing Details">
        <DrawerSection id="adjustment-processing-details-section">
          <DataContainer key={`cols${data?.transactionId}`}>
            <ProcessingDetailRow key={`divadjustment${data?.transactionId}`}>
              <Title>Adjustment</Title>
              <Value>{`${formatCurrency(data?.adjustment)}`}</Value>
            </ProcessingDetailRow>
            <BreakLine />
            <ProcessingDetailRow key={`divtotal${data?.transactionId}`}>
              <Title>Total</Title>
              <Value>{formatCurrency(data?.total)}</Value>
            </ProcessingDetailRow>
          </DataContainer>
          <DataContainer key={`processordetails${data?.transactionId}`}>
            <ProcessingDetailRow key={`divprocessor${data?.transactionId}`}>
              <Title>Processor</Title>
              <Value>{data?.processor?.toUpperCase()}</Value>
            </ProcessingDetailRow>
          </DataContainer>
        </DrawerSection>
      </Accordion>
    </AccordionWrapper>
  );
};

export default AdjustmentProcessingDetailsSection;
