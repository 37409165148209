import React from 'react';
import { PrimaryButton } from '@fattmerchantorg/truffle-components';
import styled from 'styled-components';
import { getNickname } from '../../util/BankAccountsPage.util';
import { FundingAccount } from '@fattmerchantorg/types-omni';
import {
  ModalBody,
  ModalIcon,
  ModalText,
  ModalButtonWrapper,
} from './ModalBody.styles';

const ErrorIcon: typeof ModalIcon = styled(ModalIcon)`
  color: ${({ theme }) => theme.colors.core.gray[400].hex};
`;

const StyledModalBody = styled(ModalBody)`
  margin-bottom: -56px;
  margin-top: 16px;
`;

type ErrorAccountModalProps = {
  account: FundingAccount;
  onResubmit: () => void;
};

export const ErrorSavingAccountModalBody: React.FC<
  ErrorAccountModalProps
> = props => {
  const { account, onResubmit } = props;
  const nickname = getNickname(account);

  return (
    <StyledModalBody>
      <ErrorIcon icon={['far', 'exclamation-triangle']} />
      <ModalText as="p">
        There was an issue adding{' '}
        {nickname ? <strong>{nickname}</strong> : 'the account'}.
        <br />
        Please try to resubmit.
      </ModalText>
      <ModalButtonWrapper>
        <PrimaryButton type="button" onClick={onResubmit}>
          Resubmit
        </PrimaryButton>
      </ModalButtonWrapper>
    </StyledModalBody>
  );
};
