import React from 'react';
import { ToggleableFieldProps, ToggleableField } from '../../../shared';
import { useUnderwritingStatus } from '../../../../hooks/useUnderwritingStatus';

export interface EnrollmentFieldProps<FieldValue, T extends HTMLElement>
  extends ToggleableFieldProps<FieldValue, T> {
  toggleable?: never;
  defaultMode?: never;
}

export function EnrollmentField<
  FieldValue = any,
  T extends HTMLElement = HTMLElement
>(props: EnrollmentFieldProps<FieldValue, T>): JSX.Element {
  const { isFullyApproved } = useUnderwritingStatus();

  return (
    <ToggleableField
      {...props}
      toggleable={false}
      defaultMode={isFullyApproved ? 'display' : 'edit'}
    />
  );
}
