import React from 'react';
import { BoldText } from './BoldText';
import { RegularText } from './RegularText';

//
// Facades for Truffle Typography elements.
// We could use these components directly but there's
// lot of repetitions so they've been extracted to this file.
//

// These styles are repeated but haven't refactored this file yet.
// Need to decide on naming convention that works across the project.
export const TransactionStatisticsTitle = (content: string) => (
  <BoldText fontSize={14} color="#868A91" lineHeight={21}>
    {content}
  </BoldText>
);

export const RegistrationFlowTileTitle = (content: string) => (
  <BoldText fontSize={14} color="#fff" lineHeight={21} inline={true}>
    {content}
  </BoldText>
);

export const RegistrationFlowTileNumber = (count: number) => (
  <BoldText
    fontSize={24}
    color="#fff"
    marginBottom={0}
    marginRight={8}
    lineHeight={36}
  >
    {count}
  </BoldText>
);

export const ModalContentTitle = (content: string) => (
  <BoldText marginBottom={0} color="#404750" fontSize={14} lineHeight={21}>
    {content}
  </BoldText>
);

export const ModalContentDescription = (content: string) => (
  <RegularText color="#404750">{content}</RegularText>
);

export const NoDataOverlayTitle = (content: string) => (
  <BoldText marginBottom={16} color="#ffffff" fontSize={16}>
    {content}
  </BoldText>
);

export const NoDataOverlayDescription = (content: string) => (
  <RegularText marginBottom={0} color="#ffffff" fontSize={12}>
    {content}
  </RegularText>
);

export const ResidualsLastUpdatedMessage = (content: string) => (
  <RegularText color="#fff" fontSize={12}>
    {content}
  </RegularText>
);
