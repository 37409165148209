import React from 'react';
import { Icon, Text } from '@fattmerchantorg/truffle-components';
import styled from 'styled-components';
import { ThemeProps } from '../../../../@types/ThemeProps';

type ErrorBoundaryProps = {};

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background-color: ${(props: ThemeProps) => props.theme.destructiveColorTint};

  p {
    color: ${({ theme }) => theme.colors.status.red['600'].hex};
    font-size: 12px;
    font-weight: bold;
    margin: 0 0 0 6px;
  }

  svg {
    color: ${({ theme }) => theme.colors.status.red['600'].hex};
    margin-right: 4px;
  }
`;

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { error: Error | string | unknown | null }
> {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    const { children } = this.props;

    if (this.state.error) {
      return (
        <ErrorContainer>
          <Icon icon={['far', 'times-circle']} />
          <Text as="p">
            Something went wrong while loading this section. Please reach out to
            our support team for assistance.
          </Text>
        </ErrorContainer>
      );
    }

    return children;
  }
}
