import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Spinner } from '../Spinner';

const Container = styled.div`
  background-color: #f5f5f5;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const FullPageLoader: FunctionComponent<{
  id?: string;
}> = props => {
  return (
    <Container id={props.id} data-testid={props.id}>
      <Spinner />
    </Container>
  );
};

FullPageLoader.defaultProps = {
  id: 'full-page-loader'
};
