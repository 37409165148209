import React, { FunctionComponent } from 'react';

import { PieTooltipProps, ResponsivePie } from '@nivo/pie';
import { Import } from '../../../../@types';
import { formatWithComma } from '../../../../util/format.util';

const getPercent = (num: number, total: number) => {
  const t = total ? total : 1;
  return Math.floor((num / t) * 100).toFixed(0) + '%';
};

// come get sum
const getSum = (arr: { count: number }[] | null | undefined) => {
  return (arr || []).reduce((sum, e) => sum + (e?.count || 0), 0);
};

const PLACEHOLDER_VALUE = 9999999999999999999999999999;

export const ImporterPieChart: FunctionComponent<{
  record: Import;
}> = props => {
  if (!props.record) return null;

  const { summary, state } = props.record;

  const recordCount =
    (state === 'COMPLETE' ? summary.imported_count : summary.record_count) || 0;
  const warningCount = getSum(summary.warnings);
  const errorCount = getSum(summary.errors);

  const total = +recordCount + warningCount + errorCount;

  const data = [
    {
      id: 'warnings',
      label: `Warnings (${getPercent(warningCount, total)})`,
      value: warningCount,
      color: '#8E3B64',
    },
    {
      id: 'errors',
      label: `Can't Import (${getPercent(errorCount, total)})`,
      value: errorCount,
      color: '#E34F62',
    },
    {
      id: 'staged',
      label: `No Issues (${getPercent(recordCount, total)})`,
      // if recordCount is 0, we'll instead use the PLACEHOLDER_VALUE
      // since the pie chart doesn't show up unless it has a valid value
      value: recordCount || PLACEHOLDER_VALUE,
      color: '#283663',
    },
  ];

  return (
    <ResponsivePie
      theme={{
        tooltip: {
          container: {
            background: '#062333',
            borderRadius: '2px',
          },
        },
      }}
      data={data}
      colors={['#8E3B64', '#E34F62', '#283663']}
      margin={{ right: 120 }}
      innerRadius={0.5}
      enableArcLabels={false}
      animate={true}
      motionConfig="gentle"
      tooltip={({
        datum,
      }: PieTooltipProps<{
        value: number;
      }>) => (
        <span>
          {datum?.value === PLACEHOLDER_VALUE
            ? 0
            : formatWithComma(datum.value)}
        </span>
      )}
      legends={[
        {
          anchor: 'right',
          direction: 'column',
          translateY: 0,
          translateX: 130,
          itemWidth: 120,
          itemHeight: 22,
          itemTextColor: '#fff',
          symbolSize: 8,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#ddd',
              },
            },
          ],
        },
      ]}
    />
  );
};
