import React, { FunctionComponent, useCallback, useState } from 'react';
import { Icon } from '@fattmerchantorg/truffle-components';
import { FileRecord } from '@fattmerchantorg/types-omni';
import styled from 'styled-components';
import { AttachmentsListProps } from './Attachments.types';
import { isImageFile, formatBytes } from '../../../util/file.util';
import { onboardingapi } from '../../../api';
import { useAuthToken } from '../../../hooks';
import { ConfirmDeleteModal } from '../../modals/ConfirmDeleteModal';

/**
 * This function will dynamically create an invisible anchor tag
 * - creates a blob based the dataURI `data:${file.meta.mime};base64,${result.data}`
 * - then download or preview the file
 * @param previewType dictates whether the file gets open in a new tab or downloaded
 * @param file is the file object
 */
async function handleOnboardingFileAction(
  action: 'preview' | 'download',
  file: FileRecord,
  authToken: string
) {
  try {
    if (action === 'preview') {
      await onboardingapi.previewFile(authToken, file);
    } else if (action === 'download') {
      await onboardingapi.downloadFile(authToken, file);
    }
  } catch (error) {
    console.error('error caught when retrieving file', error);
  }
}

const ButtonsTd = styled.td`
  display: flex;
  text-align: right;
  float: right;
  > *:first-child {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.status.blue[500].hex};
    margin-right: 16px;
  }

  > *:last-child {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.status.red[500].hex};
  }
`;

const FileName = styled.span`
  font-size: 14px;

  &[data-clickable='true'] {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.status.blue[500].hex};
    text-decoration: underline;
  }
`;

const Table = styled.table`
  a.image-preview {
    color: #009bf2;
    font-size: 14px !important;
    font-weight: normal;
    line-height: 21px;
    text-decoration: underline;

    > img {
      animation: dropdown-enter 0.3s ease-in-out both;
      position: absolute;
      display: none;
      max-height: 16rem;
      z-index: 99;
      border-radius: 3px;
    }
  }

  a.image-preview:hover > img {
    display: block;
    position: relative;
  }

  a.image-preview-absolute:hover > img {
    display: block;
    position: absolute;
    margin-top: -3px;
  }

  th {
    text-align: left;
  }
`;

const ColOne = styled.col`
  width: 30%;
`;

const ColTwo = styled.col`
  width: 20%;
`;

const ColThree = styled.col`
  width: 15%;
`;

const FileItemTr = styled.tr`
  vertical-align: top;
`;

const TableHeaderTr = styled.tr`
  word-wrap: normal;
`;

const DeleteIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.status.red[500].hex};
`;

export const AttachmentsList: FunctionComponent<
  AttachmentsListProps
> = props => {
  const {
    downloadable,
    onDelete,
    includeDeleteModal = true,
    includeFileType = true,
    ...restOfProps
  } = props;
  const files = props.files ?? [];
  const authToken = useAuthToken();
  const [fileToDelete, setFileToDelete] = useState<FileRecord>(null);

  const truncateFileName = useCallback((filename: string): string => {
    if (filename.length > 24) {
      return `${filename.substr(0, 12)}...${filename.substr(-12)}`;
    }
    return filename;
  }, []);
  const renderFileItem = useCallback(
    (file: FileRecord & { type: string; size: string }, index: number) => {
      const isOnboardingFile =
        file.tag === 'onboarding' || file.tag === 'dispute';

      return (
        <FileItemTr key={`attachments-list-item-${index}`}>
          <td>
            {downloadable && isOnboardingFile && (
              <span
                className="ui-anchor"
                id={`onboarding-file-anchor-${file.id}`}
                data-testid={`onboarding-file-anchor-${file.id}`}
                onClick={() =>
                  handleOnboardingFileAction('preview', file, authToken)
                }
              >
                <FileName data-clickable>
                  <strong>{truncateFileName(file.name)}</strong>
                </FileName>
              </span>
            )}
            {downloadable && !isOnboardingFile ? (
              <a
                id={`non-onboarding-file-anchor-${file.id}`}
                data-testid={`non-onboarding-file-anchor-${file.id}`}
                href={file.public_url}
                target="_blank"
                className="ui-anchor image-preview image-preview-absolute"
                rel="noopener noreferrer"
              >
                <FileName data-clickable>
                  <strong>{truncateFileName(file.name)}</strong>
                </FileName>
                {isImageFile(file) && (
                  <img
                    src={file.public_url}
                    alt={truncateFileName(file.name)}
                  />
                )}
              </a>
            ) : null}
            {!downloadable && !isOnboardingFile && (
              <FileName>
                <strong>{truncateFileName(file.name)}</strong>
              </FileName>
            )}
          </td>
          {includeFileType && (
            <td>
              {file.meta?.extension?.toUpperCase() ||
                (file.type?.slice(file.type.indexOf('/') + 1) ?? 'N/A')}
            </td>
          )}
          <td>{file.meta?.filesize || formatBytes(file.size)}</td>
          {downloadable ? (
            <ButtonsTd>
              {isOnboardingFile ? (
                <Icon
                  icon={['fas', 'download']}
                  onClick={() => {
                    if (isOnboardingFile) {
                      handleOnboardingFileAction('download', file, authToken);
                    }
                  }}
                />
              ) : (
                <a href={file.public_url} download={file.name}>
                  <Icon icon={['fas', 'download']} />
                </a>
              )}
              <DeleteIcon
                icon={['fas', 'times']}
                onClick={() => {
                  if (includeDeleteModal) {
                    setFileToDelete(file);
                  } else {
                    onDelete(file);
                  }
                }}
              />
            </ButtonsTd>
          ) : null}
        </FileItemTr>
      );
    },
    [
      downloadable,
      truncateFileName,
      includeFileType,
      authToken,
      includeDeleteModal,
      onDelete,
    ]
  );

  return (
    <>
      <Table {...restOfProps}>
        <colgroup>
          {
            <>
              <ColOne span={1} />
              <ColTwo span={1} />
              {includeFileType && <ColThree span={1} />}
              <ColThree span={1} />
            </>
          }
        </colgroup>
        <thead>
          {files.length ? (
            <TableHeaderTr>
              {
                <>
                  <th>File Name</th>
                  {includeFileType && <th>File Type</th>}
                  <th>File Size</th>
                </>
              }
            </TableHeaderTr>
          ) : null}
        </thead>
        <tbody>{files.map(renderFileItem)}</tbody>
      </Table>
      {includeDeleteModal && (
        <ConfirmDeleteModal
          title="Delete File"
          isOpen={fileToDelete !== null}
          onClose={() => setFileToDelete(null)}
          onConfirm={() => {
            onDelete(fileToDelete);
            setFileToDelete(null);
          }}
        >
          <p>
            Are you sure you want to delete{' '}
            <strong>{fileToDelete?.name}</strong>?
          </p>
        </ConfirmDeleteModal>
      )}
    </>
  );
};

export default AttachmentsList;
