import React, { FC } from 'react';
import styled, { withTheme } from 'styled-components';
import { Tooltip } from '@fattmerchantorg/truffle-components';

// Available icons for payment types.
const paymentMethodsIcons = {
  VISA: 'fab fa-cc-visa',
  MASTERCARD: 'fab fa-cc-mastercard',
  'AMERICAN EXPRESS': 'fab fa-cc-amex',
  JCB: 'fab fa-cc-jcb',
  DISCOVER: 'fab fa-cc-discover',
  DINERSCLUB: 'fab fa-cc-diners-club',
  DINERS: 'fab fa-cc-diners-club',
  'APPLE PAY': 'fab fa-apple-pay',
  'GOOGLE PAY': 'fab fa-google-pay',
  SAVINGS: 'fas fa-money-check',
  CHECKING: 'fas fa-money-check',
  CHECK: 'fas fa-money-check',
  CASH: 'fas fa-money-bill',
  GIFTCARD: 'far fa-credit-card',
  BANK: 'far fa-credit-card',
};

const Icon = withTheme(
  styled.i`
    color: white;
    font-size: 24px;
    height: 22px;
    line-height: 22px;
    float: left;
    margin: 0;
  `
);

interface IPaymentMethodProps extends React.HTMLAttributes<HTMLElement> {
  method: string;
}

const formatTooltip = (method: string) => {
  if (['APPLE PAY', 'GOOGLE PAY'].includes(method.toUpperCase())) {
    return 'Payments made via Apple Pay or Google Pay are not saved on file. To save payment methods for future use, disable this option.';
  }
  return method;
};

export const PaymentMethodIcon: FC<IPaymentMethodProps> = props => {
  const { method, ...iconProps } = props;

  // Exit if the payment method is empty.
  if (!method) return null;

  // Get icon for payment type, or use default icon.
  const getIconForPayment = (pm: string) => paymentMethodsIcons[pm];
  const icon = getIconForPayment(method.toUpperCase()) ?? 'fas fa-credit-card';

  return (
    <Tooltip content={formatTooltip(method)}>
      <Icon
        {...iconProps}
        data-testid="ui-payment-method-icon"
        className={`${icon} fa-fw ${iconProps.className ?? ''}`}
      />
    </Tooltip>
  );
};

export default PaymentMethodIcon;
