import React from 'react';

export default report => {
  const details = Object.entries(report).map(([key, value]) => {
    return formatJSON(value, key, 0);
  });
  if (details.length === 0) {
    return null;
  }
  return (
    <div>
      <ul>{details}</ul>
    </div>
  );
};

const formatJSON = (value, header, depth = 0) => {
  if (!isValueAnObject(value)) {
    return (
      <li key={`${header}-${value}-${depth}`}>
        {header}:{` ${value}`}
      </li>
    );
  }
  const string = [];
  for (var key in value) {
    // loop through key
    if (isValueAnObject(value[key])) {
      string.push(formatJSON(value[key], key, depth + 1));
    } else {
      // not an object, no more children
      string.push(
        <li key={`${header}-${key}-${depth}`}>
          {key}:{` ${value[key]}`}
        </li>
      );
    }
  }
  // wrap the top-level header list element over its recursed tree
  return (
    <li key={`${header}-${depth}`}>
      {isNaN(parseInt(header)) ? header : parseInt(header) + 1}
      <ul>{string}</ul>
    </li>
  );
};

const isValueAnObject = value => typeof value == 'object' && value !== null;
