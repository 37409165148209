import { unitOfTime } from 'moment';
import { buildApi } from '../util/api.util';

export const queryapi = buildApi(process.env.REACT_APP_QUERY_API_URL);

export type GroupBy =
  // time-unit group by values
  | 'year'
  | 'year_month'
  | 'week_of_year'
  | 'date'
  | 'date_hour'
  // other group by values
  | 'transaction_id'
  | 'customer_id'
  | 'customer_created_at'
  | 'transaction_id'
  | 'type';

/** A unit of time accepted by fattquery service's statistics route. */
export type Timespan = unitOfTime.Base | unitOfTime._quarter;

/** A single datum for a particular grouping, calculated by the fattquery service. */
export type StatisticsResponseDatum = Partial<Record<GroupBy, string>> & {
  sum: number;
  sum_formatted: string;
  average: number;
  average_formatted: string;
  count: number;
  min: number;
  min_formatted: string;
  max: number;
  max_formatted: string;
};

/** The response structure for the fattquery service's `/statistics` sub-routes.  */
export interface StatisticsRouteResponse {
  cached_at?: Date;
  data: Array<StatisticsResponseDatum>;
  summary?: StatisticsResponseDatum & {
    row_type: GroupBy;
  };
  group_summary?: StatisticsResponseDatum & {
    max_count: number;
    min_count: number;
    row_type: GroupBy;
  };
}
