import React, { useMemo, useEffect } from 'react';
import {
  Column,
  Row,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import {
  Icon,
  Pagination,
  SortIndicator,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  TableTools,
  TableWrapper,
  Tooltip,
} from '@fattmerchantorg/truffle-components';
import { CollectionQuery, LoadingSpan } from '../../shared';
import { SettlementOptionsDrawerContextMenu } from './SettlementOptionsDrawerContextMenu';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { API } from '@fattmerchantorg/types-engine';
import { useAuthToken, useToaster } from '../../../hooks';
import copy from 'copy-to-clipboard';
import { catanapi } from '../../../api/catan';
import { SettlementApprovalResponse } from '../../../@types';

interface DataTableProps {
  data: {
    columns: Column[];
    rows: Record<string, unknown>[];
  };
  pageSizeOptions: {
    value: number;
    label: string;
  }[];
  count: number;
  total: number;
  currentPage: number;
  lastPage: number;
  defaultPageSize: number;
  loading: boolean;
  handleQueryChange: (query: CollectionQuery) => void;
  onRowsChanged?: (rows: Record<string, unknown>[]) => void;
}

export const MenuCell = styled(TableCell)`
  display: flex;
`;

const StyledTableRow = styled(TableRow)`
  cursor: pointer;

  ${MenuCell} {
    > button {
      margin: auto 15px;
      visibility: hidden;
    }
  }

  &:hover {
    ${MenuCell} {
      > button {
        visibility: visible;
      }
    }
  }
`;

const CopyButton = styled.button`
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  width: 30px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.core.white[0].hex};
`;

export const SettlementsDataTable: React.FC<DataTableProps> = props => {
  const history = useHistory();
  const { pathname } = useLocation();
  const {
    data,
    pageSizeOptions,
    count,
    total,
    handleQueryChange,
    loading,
    currentPage,
    lastPage,
    defaultPageSize,
    onRowsChanged,
  } = props;

  // Row and column data for table
  const rowData = useMemo(() => data.rows, [data.rows]);
  const columns = useMemo(() => data.columns, [data.columns]);

  const authToken = useAuthToken();

  const { toaster, toast } = useToaster();

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageSize },
    setSortBy,
  } = useTable(
    {
      columns,
      data: rowData,
      defaultColumn: {
        width: 0,
      },
      manualPagination: true,
      manualSortBy: true,
      manualFilters: true,
      manualGlobalFilter: true,
      pageCount: count,
      initialState: {
        sortBy: [
          {
            id: 'created_at',
            desc: true,
          },
        ],
        pageSize: defaultPageSize,
        pageIndex: currentPage,
      },
    },
    useSortBy,
    usePagination,
    useRowSelect
  );
  useEffect(() => {
    setPageSize(defaultPageSize);
  }, [defaultPageSize, setPageSize]);

  return (
    <>
      <TableWrapper>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, index) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column: any, index) => (
                  <TableHeaderCell
                    style={column.style}
                    key={index}
                    /** This will sort the table based on
                     * the enabled sorted field
                     */
                    onClick={() => {
                      if (column.disableSortBy !== true) {
                        //set sort desc, asc or undefined?
                        const desc =
                          column.isSortedDesc === false ? true : false;
                        setSortBy([{ id: column.id, desc }]);
                        if (desc !== undefined) {
                          // this will pass the order value based on the sort icon clicked
                          let order = desc === false ? 'ASC' : 'DESC';
                          handleQueryChange({ sort: column.id, order });
                        }
                      }
                    }}
                  >
                    {column.render('Header')}

                    {column.isSorted ? (
                      <SortIndicator
                        sort={column.isSortedDesc ? 'desc' : 'asc'}
                      />
                    ) : column.disableSortBy ? null : (
                      <SortIndicator sort="desc" />
                    )}
                  </TableHeaderCell>
                ))}
                <TableHeaderCell style={{ width: 80 }}>&nbsp;</TableHeaderCell>
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <StyledTableRow {...row.getRowProps()} key={index}>
                  {row.cells.map((cell: any, index) => {
                    return (
                      <TableCell
                        onClick={() => {
                          if (!loading) {
                            localStorage.setItem(
                              'settlement.parenturl',
                              window.location.href.split('?')[1]
                            );
                            const settlement = row.original as API.Settlement;
                            history.push(
                              `${pathname}/${settlement.settlement_id}/details`
                            );
                          }
                        }}
                        {...cell.getCellProps()}
                        key={index}
                        style={cell.column.style}
                      >
                        {loading ? (
                          <LoadingSpan width="75%" />
                        ) : (
                          cell.render('Cell')
                        )}
                      </TableCell>
                    );
                  })}
                  <MenuCell style={{ padding: 0, textAlign: 'center' }}>
                    <CopyButton
                      onClick={() =>
                        onCopySettlementId(row, row.original as API.Settlement)
                      }
                    >
                      <Tooltip
                        content={
                          <span>
                            Copy <strong>Settlement ID</strong> to Clipboard
                            <br />
                            {`${
                              (row.original as API.Settlement).settlement_id
                            }`}
                          </span>
                        }
                      >
                        <Icon icon={['fas', 'copy']} />
                      </Tooltip>
                    </CopyButton>
                    <SettlementOptionsDrawerContextMenu
                      row={row}
                      onCopyId={onCopySettlementId}
                      onApprove={onApprove}
                      onHold={onHold}
                      onRelease={onRelease}
                    />
                  </MenuCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
        <TableTools>
          <Pagination
            onNextPage={() => {
              nextPage();
              handleQueryChange({ page: currentPage + 1 });
            }}
            onPreviousPage={() => {
              previousPage();
              handleQueryChange({ page: currentPage - 1 });
            }}
            onSkipToEnd={() => {
              gotoPage(count - 1);
              handleQueryChange({ page: count });
            }}
            onSkipToBeginning={() => {
              gotoPage(0);
              handleQueryChange({ page: 1 });
            }}
            onGoToPage={(page: number) => {
              gotoPage(page);
              handleQueryChange({ page: page + 1 });
            }}
            totalPages={count}
            totalRecords={total}
            recordsPerPage={pageSize}
            onSetPageSize={size => {
              setPageSize(size);
              handleQueryChange({ perPage: size, page: 1 });
            }}
            currentPage={currentPage - 1}
            pageSizeOptions={pageSizeOptions}
            canNextPage={currentPage === lastPage ? false : true}
            canPreviousPage={currentPage === 1 ? false : true}
          />
        </TableTools>
      </TableWrapper>
    </>
  );

  async function onCopySettlementId(row: Row<object>, rowData: API.Settlement) {
    copy(rowData.settlement_id);
    toaster(toast.success(`${rowData.settlement_id} copied to clipboard`));
  }

  async function onHold(row: Row<object>, rowData: API.Settlement) {
    const settlements: Pick<API.Settlement, 'settlement_id' | 'state'>[] = [
      {
        settlement_id: rowData.settlement_id,
        state: 'HOLD',
      },
    ];

    try {
      const response = await catanapi.patch<SettlementApprovalResponse>(
        authToken,
        '/settlements',
        {
          settlements,
        }
      );

      if (response.successCount > 0) {
        rowData.state = 'HOLD';
        row.values.state = 'HOLD';
        row.values.settlement_hold = 'HOLD';
        triggerRowsChanged();

        toaster(
          toast.success(
            `Settlement (${rowData.settlement_id}) has been held successfully`,
            'Settlement Held'
          )
        );
      } else {
        toaster(
          toast.error(
            `Settlement (${rowData}) could not be held.`,
            'Unable to Hold Settlement'
          )
        );
      }
    } catch (e) {
      toaster(
        toast.error(
          'Holding the settlement failed. Please try again',
          'OOps, Something went wrong'
        )
      );
    }
  }

  async function onRelease(row: Row<object>, rowData: API.Settlement) {
    const settlements: Pick<API.Settlement, 'settlement_id' | 'state'>[] = [
      {
        settlement_id: rowData.settlement_id,
        state: 'PENDING',
      },
    ];

    try {
      const response = await catanapi.patch<SettlementApprovalResponse>(
        authToken,
        '/settlements',
        {
          settlements,
        }
      );

      if (response.successCount > 0) {
        rowData.state = 'PENDING';
        row.values.settlement_hold = rowData.risk_hold;
        row.values.state = 'PENDING';
        triggerRowsChanged();

        toaster(
          toast.success(
            `Settlement (${rowData.settlement_id}) has been released successfully`,
            'Settlement Released'
          )
        );
      } else {
        toaster(
          toast.error(
            `Settlement (${rowData.settlement_id}) could not be released.`,
            'Unable to Release Settlement'
          )
        );
      }
    } catch (e) {
      toaster(
        toast.error(
          'There was an error releasing the settlement. Please try again',
          'OOps, Something went wrong'
        )
      );
    }
  }

  async function onApprove(row: Row<object>, rowData: API.Settlement) {
    const settlements: Pick<API.Settlement, 'settlement_id' | 'state'>[] = [
      {
        settlement_id: rowData.settlement_id,
        state: 'APPROVED',
      },
    ];

    try {
      const response = await catanapi.patch<SettlementApprovalResponse>(
        authToken,
        '/settlements',
        {
          overrideRiskHold: true,
          settlements,
        }
      );

      if (response.successCount > 0) {
        rowData.state = 'APPROVED';
        row.values.state = 'APPROVED';
        triggerRowsChanged();

        toaster(
          toast.success(
            `Settlement (${rowData.settlement_id}) has been approved successfully`,
            'Settlement Approved'
          )
        );
      } else {
        toaster(
          toast.error(
            `Settlement (${rowData}) could not be approved`,
            'Settlement Not Approved'
          )
        );
      }
    } catch (e) {
      toaster(
        toast.error(
          'Settlement approval failed. Please try again',
          'OOps, Something went wrong'
        )
      );
    }
  }
  function triggerRowsChanged() {
    if (typeof onRowsChanged === 'function') {
      onRowsChanged(data.rows);
    }
  }
};
