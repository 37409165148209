import React, { FunctionComponent } from 'react';
import {
  SecondaryButton,
  PrimaryButton,
} from '@fattmerchantorg/truffle-components';
import { Form } from 'react-final-form';

import {
  TransactionReturnModal,
  TransactionReturnModalProps,
  TransactionReturnModalContent,
  TransactionReturnModalButtonGroup,
} from './TransactionReturnModal';
import { formatCurrency } from '../../../util/format.util';
import { sendRefundRequested } from './actions';
import { Field, FieldError, Input, ButtonSpinner } from '../../shared';
import { FormErrors } from '../../../@types';
import { useCallback } from 'react';
import { isWithinRange } from '../../../util/validator.util';
import { TransactionAsyncDataStatus } from './useDetailsModalHook';
import { usePrevious } from '../../../hooks';
import { useEffect } from 'react';
import { get } from 'lodash';

type RefundFormValues = { refundTotal: number };

export const TransactionRefundModal: FunctionComponent<
  TransactionReturnModalProps
> = props => {
  const { state, dispatch, ...modalProps } = props;
  const transaction = state.data;
  const maxRefundTotal = transaction?.total - transaction?.total_refunded;
  const status = state.status;
  const prevStatus = usePrevious(status);
  const { onClose, merchant } = modalProps;
  const hasSurcharge = get(transaction, 'meta.surcharge', 0) > 0;

  let infoPanelLabel =
    'Payments that have been settled are eligible for a full or partial refund.';
  if (merchant?.is_surcharge_enabled && merchant?.is_trust) {
    infoPanelLabel =
      'Partial refunds can not be performed on surcharge transactions within a trust account. Your client will receive the full amount that was paid and your account will be debited the full amount minus the surcharge.';
  }

  const validateFormValues = useCallback(
    (formValues: RefundFormValues) => {
      const formErrors = {} as FormErrors<RefundFormValues>;
      if (!transaction) return formErrors;

      if (!isWithinRange(formValues.refundTotal, 0.01, maxRefundTotal)) {
        formErrors.refundTotal = `Must be between $0.01 and ${maxRefundTotal}`;
      }

      return formErrors;
    },
    [transaction, maxRefundTotal]
  );

  useEffect(() => {
    if (
      // if user did a refund, auto dismiss the modal
      prevStatus === TransactionAsyncDataStatus.REFUNDING &&
      status === TransactionAsyncDataStatus.IDLE
    ) {
      onClose();
    }
  }, [status, prevStatus, onClose, maxRefundTotal]);

  return (
    <TransactionReturnModal {...modalProps}>
      <TransactionReturnModalContent
        title="Issue Refund"
        infoPanelLabel={infoPanelLabel}
        {...props}
      >
        <Form<RefundFormValues>
          initialValues={{ refundTotal: maxRefundTotal }}
          validate={validateFormValues}
          onSubmit={formValues =>
            dispatch(sendRefundRequested(formValues.refundTotal))
          }
        >
          {formProps => (
            <form
              onSubmit={e => {
                e.preventDefault();
                formProps.handleSubmit(e);
              }}
            >
              {(!merchant?.is_trust || !merchant?.is_surcharge_enabled) &&
                !hasSurcharge && (
                  <>
                    <label>Refund Amount</label>
                    <Field
                      name="refundTotal"
                      placeholder="$0.00"
                      format={v => String(v).replace(/[^0-9.,$%]/g, '')}
                    >
                      {props => (
                        <Input
                          {...props.input}
                          style={{ width: 'auto', textAlign: 'right' }}
                        />
                      )}
                    </Field>
                  </>
                )}

              <FieldError name="refundTotal" style={{ textAlign: 'right' }} />
              <TransactionReturnModalButtonGroup>
                <SecondaryButton type="button" onClick={modalProps.onClose}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  type="submit"
                  disabled={
                    state.status !== TransactionAsyncDataStatus.IDLE ||
                    !formProps.valid
                  }
                >
                  {state.status === TransactionAsyncDataStatus.REFUNDING ? (
                    <ButtonSpinner />
                  ) : null}
                  Refund {formatCurrency(formProps.values.refundTotal)}
                </PrimaryButton>
              </TransactionReturnModalButtonGroup>
            </form>
          )}
        </Form>
      </TransactionReturnModalContent>
    </TransactionReturnModal>
  );
};

export default TransactionRefundModal;
