export type PasswordTestResult = {
  /** if the password has at least 8 characters */
  length: boolean;
  /** if the password has at least 1 lowercase character */
  lower: boolean;
  /** if the password has at least 1 uppercase character */
  upper: boolean;
  /** if the password has at least 1 numeric character */
  numeric: boolean;
  /** if the password has at least 1 special character */
  special: boolean;
  /** if the password fully passes the test */
  pass: boolean;
};

export function testPassword(password: string): PasswordTestResult {
  const p = password || '';
  const r: PasswordTestResult = {
    length: /(?=.{8,})/.test(p),
    lower: /(?=.*[a-z])/.test(p),
    upper: /(?=.*[A-Z])/.test(p),
    numeric: /(?=.*[0-9])/.test(p),
    special: /(?=.*[!@#$%^&*()_])/.test(p),
    pass: false,
  };

  // at least 3/4 character conditions are met
  r.pass = r.length && r.lower && r.upper && r.numeric && r.special;
  return r;
}
