import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { BaseModal, ModalHeader } from '@fattmerchantorg/truffle-components';
import { ModalContext, SelectedMerchantProvider } from '../../../../context';
import { RegistrationAuditLog } from '@fattmerchantorg/types-omni';
import { get } from '../../../../util/object.util';
import { AuditLogExperianRow } from './AuditLogExperianRow';
import { formatCamelToTitleCase } from '../../../../util';

const StyledModalContent = styled.div`
  grid-area: content;
  overflow-y: auto;
  padding: ${({ theme }) => theme.space[4]}px;
  color: ${({ theme }) => theme.component.modal.color};

  > * {
    margin: 0 0 10px;
  }
`;

const Container = styled.div`
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  border-top: 1px solid #435e70;
  padding-top: 8px;

  > div:nth-child(odd) {
    background: #1c2f3b;
  }
`;

const ReportRow = styled.div`
  display: flex;
  padding: 8px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  scroll-snap-align: start;

  span {
    font-size: 14px;
  }

  a {
    font-size: 14px;
  }

  > * {
    margin: 0 16px;

    &:first-child {
      margin: 0 16px 0 0;
    }
  }
`;

interface AuditLogExperianModalProps {
  isOpen?: boolean;
  verificationData: RegistrationAuditLog[];
  timestamp: string;
}

export const AuditLogExperianModal: React.FC<
  AuditLogExperianModalProps
> = props => {
  const { modalDispatch } = useContext(ModalContext);
  const [isModalVisible] = useState(!!props.isOpen);
  const { verificationData, timestamp } = props;
  const data = get(verificationData, '0.result', {});

  const details = get(data, 'summaries.0.attributes', []);

  const handleCloseModal = () => {
    modalDispatch({
      type: 'CLOSE_MODAL',
    });
  };

  return (
    <SelectedMerchantProvider>
      <BaseModal
        title={'Experian Details'}
        isOpen={isModalVisible}
        onRequestClose={handleCloseModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={false}
        style={{
          overlay: { pointerEvents: 'auto' },
          content: {
            maxWidth: '533px',
            gridTemplateRows: '48px 1fr',
            gridTemplateAreas: `
                "header"
                "content"
              `,
          },
        }}
      >
        <ModalHeader title={'Experian Details'} onClose={handleCloseModal} />
        <>
          <StyledModalContent data-testid="experian-log-detail-modal">
            <AuditLogExperianRow
              groupTimestamp={new Date(timestamp)}
              reports={verificationData}
            />
            <Container>
              {details.map(({ id, value }) => {
                return (
                  <ReportRow key={id}>
                    <span>{formatCamelToTitleCase(id)}</span>
                    <span>{value}</span>
                  </ReportRow>
                );
              })}
            </Container>
          </StyledModalContent>
        </>
      </BaseModal>
    </SelectedMerchantProvider>
  );
};
