import { getMonthName } from './date.util';
import { OnboardingStages } from '../@types/OnboardingStages';
import { ResidualsStatement, DataKeys } from '@fattmerchantorg/types-omni';
import { Serie, Datum } from '@nivo/line';
import moment from 'moment';

export function mapPartnerDataToLineData(
  data: ResidualsStatement[],
  data_key: DataKeys
): Serie[] {
  const newArray: Datum[] = [];
  data.forEach(dataset => {
    // create new obect with x & y coordinates for the graph
    let object: Datum = {};
    // transform string of YYYY-MM to Date of YYYY-MM-DD hh:mm:ss
    const newDate = new Date(dataset.activity_period + '-01 00:00:00');
    object.x = getMonthName(newDate, 'short');
    object.y = dataset[data_key];
    newArray.push(object);
    return newArray;
  });

  let lineDataArray: Serie[] = [];
  let lineDataObject: Serie = {
    id: data_key,
    data: newArray
  };
  lineDataArray.push(lineDataObject);

  return lineDataArray;
}

export function mapOnboardingStages(data: OnboardingStages | null) {
  return [
    {
      step: 'Application Started',
      count: data?.started,
      key: 'started'
    },

    {
      step: 'In Review',
      count: data?.inReview,
      key: 'inReview'
    },
    {
      step: 'Pended',
      count: data?.pended,
      key: 'pended'
    },
    {
      step: 'Configuration',
      count: data?.configuration,
      key: 'configuration'
    }
  ];
}

export function countOnboardingMerchants(data: OnboardingStages) {
  const { started, inReview, pended, configuration } = data;
  return started + inReview + pended + configuration;
}

export function generateEmptyResidualForActivityPeriod(
  activity_period: string
): ResidualsStatement {
  const emptyResidual: ResidualsStatement = {
    activity_period,
    residuals: 0,
    transaction_count: 0,
    transaction_volume: 0,
    merchants: 0
  };

  return emptyResidual;
}

export function adjustDataForDashboard(data) {
  // If the number of available months is between 1 and 5, then pad the data
  // else it'll be returned unmodified.
  if (data.length >= 1 && data.length <= 5) {
    // Should always be the first element in the array.
    const startDate = data[0].activity_period;

    // Calculate how many months are needed to complete a total of 6.
    const numberOfMonthsToFill = 6 - data.length;

    // Loop for months to fill in.
    for (
      let previousMonthIndex = 1;
      previousMonthIndex <= numberOfMonthsToFill;
      previousMonthIndex++
    ) {
      // Figure out date for the previous month.
      const lastMonth = moment(startDate)
        .subtract(previousMonthIndex, 'month')
        .format('YYYY-MM');

      // Generate and insert empty dataset for that month.
      const emptyResidual = generateEmptyResidualForActivityPeriod(lastMonth);
      data.unshift(emptyResidual);
    }
  }

  return data;
}

/**
 * Formats a possible big number (greater or equal to 1 thousand) into its shorthand equivalent.
 * Ex:
 *  100 -> 100 // No changes here.
 *  2000 -> 2k
 *  3000000 -> 3M
 *  4000000000 -> 4B
 * @param possibleBigNumber
 */
export function formatBigNumber(possibleBigNumber: number): string {
  let out: string;

  // If value is divisible billion/million/thousand, then format.
  const billion: number = 1000000000;
  const million: number = 1000000;
  const thousand: number = 1000;

  if (possibleBigNumber >= billion) {
    out = `${possibleBigNumber / billion}B`;
  } else if (possibleBigNumber >= million) {
    out = `${possibleBigNumber / million}M`;
  } else if (possibleBigNumber >= thousand) {
    out = `${possibleBigNumber / thousand}k`;
  } else {
    out = possibleBigNumber.toString();
  }

  return out;
}

/**
 * This function fills in any undefined keys from the dataset.
 * @param data Dataset for chart.
 */
export function verifyTransactionDataKeys(data: Array<any>): Array<any> {
  const newDataset =
    data &&
    data.map(dataItem => {
      let extendedDataItem = Object.assign({}, dataItem);

      if (!dataItem.hasOwnProperty('sum_ACH')) {
        extendedDataItem.sum_ACH = 0;
      }

      if (!dataItem.hasOwnProperty('sum_CP')) {
        extendedDataItem.sum_CP = 0;
      }

      if (!dataItem.hasOwnProperty('sum_CNP_OTHER')) {
        extendedDataItem.sum_CNP_OTHER = 0;
      }

      return extendedDataItem;
    });

  return newDataset ?? [];
}
