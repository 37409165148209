import React, { FC } from 'react';
import styled, { withTheme } from 'styled-components';
import { Widget } from '../../shared/Widget';
import {
  EditMerchantNotesModal,
  EditModalContext,
  initialEditModalState,
} from './EditMerchantNotesModal';
import { Button, LoadingSpan } from '../../shared';
import {
  useModalReducer,
  sendOpenSelected,
} from '../../../hooks/useModalReducer';
import { AsyncDataStatus } from '../../../@types';
import { MerchantNotesModal } from './MerchantNotesModal';

const Container = styled(Widget)`
  background: white;
  min-height: 119px;
  max-height: 203px;
`;

const WidgetHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  i {
    margin: 0 0 8px;
  }
`;

const Heading = styled.h2`
  color: ${({ theme }) => theme.colors.core.gray[800].hex};
`;

const Content = withTheme(
  styled.div`
    color: #565656;
    display: flex;
    flex-direction: row;
    margin: 1rem;

    span {
      line-height: 21px;
      font-size: 14px;
      color: ${({ theme }) => theme.black};
      white-space: normal;
    }

    h2 {
      margin: 0px;
    }

    div {
      font-size: 14px;
      color: #32325d;
    }

    > div {
      flex: 50%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    button {
      color: #32325d;
      padding: 0;
    }
  `
);

const LoadingRow = withTheme(
  styled.div`
    display: flex;
    margin-bottom: 8px;

    /* loading spans */
    > div + div {
      margin-left: 3rem;
    }
  `
);

const truncateNotes = (notes?: string): string => {
  if (!notes) return '- - -';

  return notes.slice(0, 250) + (notes.length > 250 ? '... ' : '');
};

export const NotesWidget: FC<{
  status: AsyncDataStatus;
  notes?: string;
  canEditNotes: boolean;
}> = ({ status, notes, canEditNotes }) => {
  const [editModalState, editModalDispatch] = useModalReducer<EditModalContext>(
    initialEditModalState
  );
  const seeMore = notes?.length > 250;

  switch (status) {
    case AsyncDataStatus.INITIAL:
    case AsyncDataStatus.LOADING: {
      return (
        <Container>
          <Content>
            <div>
              <WidgetHeader>
                <Heading>Notes</Heading>
              </WidgetHeader>
              <LoadingRow>
                <LoadingSpan width="20rem" />
              </LoadingRow>
              <LoadingRow>
                <LoadingSpan width="20rem" />
              </LoadingRow>
              <LoadingRow>
                <LoadingSpan width="20rem" />
              </LoadingRow>
            </div>
          </Content>
        </Container>
      );
    }
    case AsyncDataStatus.IDLE: {
      return (
        <Container data-testid="notes-widget-notes">
          <EditMerchantNotesModal
            notes={editModalState.context?.notes}
            status={editModalState.status}
            modalDispatch={editModalDispatch}
          />
          <Content>
            <div>
              <WidgetHeader>
                <Heading>Notes</Heading>
                {canEditNotes && (
                  <Button
                    variant="link"
                    id="edit-merchant-notes-button"
                    data-testid="edit-merchant-notes-button"
                    onClick={() =>
                      editModalDispatch(sendOpenSelected({ notes }))
                    }
                  >
                    <i className="fas fa-edit" />
                  </Button>
                )}
              </WidgetHeader>
              <span>
                {truncateNotes(notes)}
                {seeMore && (
                  <MerchantNotesModal data-testid="merchant-notes-modal">
                    {notes}
                  </MerchantNotesModal>
                )}
              </span>
            </div>
          </Content>
        </Container>
      );
    }
    case AsyncDataStatus.ERROR:
      return (
        <Container>
          <p>
            An error occurred while retrieving notes data. Please reach out to
            your Partner Account Manager.
          </p>
        </Container>
      );
  }
};
