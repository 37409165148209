import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

export const Header: FunctionComponent<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.lightNeutralOutline};

  h4 {
    margin: 0;
  }
`;
