import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { ModalProps } from './Modal.types';
import styled, { withTheme } from 'styled-components';
import { Button } from '../Button';
import { ResponsivePadding } from '../ResponsivePadding';
import { usePrevious } from '../../../hooks';

const Content = withTheme(
  styled.div`
    position: relative;
    margin: 4rem auto;
    border-radius: 2px;
    max-width: calc(100vw - 2rem);
    min-height: 10rem;
    max-height: 100%;
    overflow-y: scroll;
    z-index: 1000;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.core.gray[800].hex};
    box-shadow: rgba(0, 0, 0, 0.176) 0px 4px 8px;
    width: ${(props: Partial<ModalProps>) => props.width};
    color: ${({ theme }) => theme.white};
    overflow: ${(props: Partial<ModalProps>) => props.overflow};

    @media (max-width: 1000px) {
      margin: 1rem auto;
    }

    &.-entering {
      animation: fade-in 0.15s ease-in both, grow 0.1s ease-in both;
    }

    &.-exiting {
      animation: fade-out 0.15s ease-in both, shrink 0.1s ease-in both;
    }
  `
);

const Header = styled.div`
  padding: 1rem;
  height: 48px;
  box-sizing: border-box;

  border-bottom: 1px solid #435e70;
`;

export const ModalCloseButton = withTheme(
  styled(Button)`
    position: absolute;
    top: 0;
    right: 0;
    height: 16px;
    width: 16px;
    border-radius: 2px;
    font-size: 1.5rem;
    margin-top: 16px;
    margin-right: 16px;
    padding: 10px 10px 10px 11px;
    color: ${({ theme }) => theme.white};
    &:hover {
      color: ${({ theme }) => theme.black};
      background: ${({ theme }) => theme.colors.core.green[50].hex};
    }
  `
);

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  overflow-y: hidden;
  padding: 1rem 0;
  background-color: rgba(0, 0, 0, 0.5);

  &.-entering {
    animation: fade-in 0.1s ease-in both;
  }

  &.-exiting {
    animation: fade-out 0.15s ease-in both;
  }

  height: 100vh;
  display: flex;
  align-items: center;
`;

export const Modal: FunctionComponent<ModalProps> = props => {
  const { title, width, isClosable, onClose, isOpen: _, ...rest } = props;
  const className = props.isOpen ? '-entering' : '-exiting';

  const [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const wasOpen = usePrevious(props.isOpen || false);

  const onKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 27 && isClosable) {
        // Esc key was pressed
        onClose();
      }
    },
    [onClose, isClosable]
  );

  const onIsOpenChanged = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        // prevent scrolling of main body when modal is open
        document.body.style.overflow = 'hidden';
        window.addEventListener('keyup', onKeyUp);
        setIsOpen(true);
      } else {
        // restore scrolling behavior
        document.body.style.overflow = 'unset';
        window.removeEventListener('keyup', onKeyUp);
        setTimeout(() => {
          // wait for close animation to finish before actually closing
          setIsOpen(false);
        }, 150);
      }
    },
    [setIsOpen, onKeyUp]
  );

  const onOutsideClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const shouldClose =
        isClosable &&
        modalRef.current &&
        !modalRef.current.contains(e.target as Node);

      if (shouldClose) onClose(e);
    },
    [modalRef, isClosable, onClose]
  );

  useEffect(() => {
    // using ! as to not compare `false` to `undefined`
    if (!props.isOpen !== !wasOpen) {
      return onIsOpenChanged(props.isOpen);
    }

    return () => {
      // restore scrolling behavior
      document.body.style.overflow = 'unset';
      window.removeEventListener('keyup', onKeyUp);
    };
  }, [props.isOpen, wasOpen, onKeyUp, onIsOpenChanged]);

  return (
    isOpen &&
    createPortal(
      <Overlay
        aria-modal="true"
        tabIndex={-1}
        className={className}
        onClick={onOutsideClick}
      >
        <Content width={width} ref={modalRef} className={className} {...rest}>
          {title && (
            <Header>
              {typeof title === 'string' ? <strong>{title}</strong> : title}
            </Header>
          )}
          {isClosable && (
            <ModalCloseButton
              variant="plain"
              size="small"
              data-testid="modal-close-button"
              onClick={onClose}
            >
              ×
            </ModalCloseButton>
          )}
          {typeof props.children === 'string' ? (
            <ResponsivePadding desktop="1rem" mobile="1rem">
              {props.children}
            </ResponsivePadding>
          ) : (
            props.children
          )}
        </Content>
      </Overlay>,
      document.body
    )
  );
};

Modal.defaultProps = {
  width: '36rem',
  isClosable: true,
};
