import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

import { Link, NavLink as NavLinkRRD } from 'react-router-dom';
import {
  Col,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Row,
} from 'reactstrap';
import { BrandSwitcher } from './BrandSwitcher';
import { CurrentBrandIcon } from './CurrentBrandIcon';
import styled from 'styled-components';
import {
  Permit,
  useAsyncEffect,
  usePermissions,
  useSupportAuthToken,
} from '../../../hooks';
import { RouteItem } from '../../routes/models/RouteItem.model';
import { TestModeMenu } from './TestModeMenu';
import {
  AuthStore,
  PermissionsStore,
  SelectedMerchantStore,
  SupportAuthData,
  TicketsStore,
  updateSelectedBrandSwitcherOption,
  updateSupportAuth,
  updateTicketsCount,
} from '../../../context';
import { appserviceapi, permissionsapi } from '../../../api';
import { Tooltip } from '@fattmerchantorg/truffle-components';
export const COLLAPSED_SIDE_BAR_WIDTH = 72;
export const EXPANDED_SIDE_BAR_WIDTH = 224;

const StyledNav = styled(Nav)`
  .nav-item:last-child:not(:only-child) {
    margin-bottom: 1rem;
  }
`;

const StyledBottomNav = styled(Nav)`
  margin-top: auto;
`;

const StyledNavbar = styled(Navbar)`
  position: fixed;
  top: 0;
  bottom: 0;
  transition: width 0.2s ease-in-out;
  padding: 0;

  background: ${({ theme }) => theme.black};
  box-shadow: 4px 0 4px rgba(0, 0, 0, 0.25) !important;

  z-index: 9;
  overflow-x: hidden;
  width: ${COLLAPSED_SIDE_BAR_WIDTH}px;

  .container-fluid {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .navbar-nav {
    flex-direction: column;
  }

  .nav-item {
    width: 100%;
    min-height: 52px;
    display: flex;
    align-items: stretch;

    & > * {
      display: flex;
      align-items: center;
    }
  }

  .nav-link {
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    font-size: 0.9rem;
    color: #fff;
    border-left: ${({ theme }) => `4px solid ${theme.black}`};
  }

  .nav-link.active {
    color: ${({ theme }) => theme.colors.core.green[300].hex} !important;
    background: ${({ theme }) => theme.colors.core.gray[700].hex};
    border-left: 4px solid #9de2b5;
  }

  .nav-link:hover,
  .nav-link:focus {
    color: rgba(255, 255, 255, 0.7);
  }

  .expanded-only {
    display: none;
  }

  .collapsed-only {
    display: flex;
  }

  .logo-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    margin-right: 0;
    height: 25px;

    .small-stax-logo {
      opacity: 1;
    }

    .big-stax-logo {
      opacity: 0;
      width: 0;
      max-height: 25px;
    }
  }

  &:hover {
    width: ${EXPANDED_SIDE_BAR_WIDTH}px;

    .nav-link {
      padding-right: 1rem !important;
    }

    .expanded-only {
      display: block;
    }

    .collapsed-only {
      display: none;
    }

    .big-stax-logo {
      opacity: 1;
      width: auto;
    }

    .small-stax-logo {
      opacity: 0;
      width: 0;
    }
  }
`;

export const StyledLabel = styled.span`
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  opacity: 0;
  white-space: nowrap;

  transition: all 0.2s ease-in-out;

  ${StyledNavbar}:hover & {
    opacity: 1;
  }
`;

export const StyledStaxBillLabel = styled.span`
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  opacity: 0;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.core.green[300].hex};

  transition: all 0.2s ease-in-out;

  ${StyledNavbar}:hover & {
    opacity: 1;
  }
`;

export const StyledIcon = styled.i`
  text-align: center;
  min-width: 18px !important;
`;
export const StyledImg = styled.img`
  text-align: center;
  min-width: 18px !important;
  height: 16px;
`;

export const IconWrapper = styled.div`
  width: 33px;
  min-width: 33px;
  margin-right: 0px;
  margin-left: 8px;
  text-align: right;
  transition: all 0.2s ease-in-out;

  ${StyledNavbar}:hover & {
    margin-left: 0px;
    margin-right: 8px;
  }
`;

export const ImageWrapper = styled.div`
  width: 33px;
  min-width: 33px;
  margin-right: 0px;
  margin-left: 8px;
  text-align: right;
  transition: all 0.2s ease-in-out;

  ${StyledNavbar}:hover & {
    margin-left: 0px;
    margin-right: 8px;
  }
`;

const Separator = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
  border-top: 1px solid ${({ theme }) => theme.colors.core.gray[800].hex};
  height: 1px;
`;

const StyledStaxLogo = styled.img`
  margin: 0;
  width: 100%;
`;

const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  width: 22px;
  font-size: 10px;
  margin: auto;
  background-color: #ff4646;
  color: white;
  font-weight: bold;
  text-align: center;
  transition: all 0.25s;
  border-radius: 100%;
  margin-left: 12px;
`;

const PendingTicketsBadge = ({ count }: { count: number }) => {
  return <Badge>{count < 100 ? count : '99+'}</Badge>;
};

const createLinks = (
  routes: RouteItem[],
  permit: Permit,
  hasSelectedBrand: boolean,
  count: number
): ReactNode[] => {
  return routes.map((route, key) => {
    let isVisible = !route.hidden;

    if (Array.isArray(route.permitParams)) {
      // If page is guarded by permission, and user doesn't have permission
      // hide the corresponding sidebar navlink.
      isVisible = isVisible && permit(...route.permitParams);
    }

    if (route.requiresSelectedBrand && !hasSelectedBrand) isVisible = false;

    return (
      isVisible && (
        <NavItem key={key}>
          {route.showSeparator && <Separator />}
          <NavLink
            to={
              route.to ?? {
                pathname: route.path,
                search: route.defaultSearch,
              }
            }
            tag={NavLinkRRD}
            data-testid={route['data-testid']}
            activeClassName="active"
            target={route.target || null}
          >
            <IconWrapper>
              <StyledIcon className={route.icon} />
            </IconWrapper>
            <StyledLabel>{route.name}</StyledLabel>
            {count > 0 && route.name === 'Support' && (
              <PendingTicketsBadge count={count} />
            )}
          </NavLink>
        </NavItem>
      )
    );
  });
};
export const StaxBillLink: FunctionComponent<{
  hasSelectedBrand: boolean;
  ssoValidationProps: SSOValidationProps;
}> = props => {
  const { permit } = usePermissions();
  const isLinkVisible = props.ssoValidationProps?.staxBillLinkIsVisible;
  const isDisabled = props.ssoValidationProps?.staxBillLinkIsDisabled;
  const staxBillUrl = `${process.env.REACT_APP_STAX_PAY_URL}/#/dashboard?assume=${props.ssoValidationProps?.staxPayMerchantIdValue}`;

  // Check the isLinkVisible value and that the user has required permissions
  // set isVisible to true / false accordingly
  const isVisible =
    isLinkVisible &&
    permit('godview', 'assume', 'write') &&
    permit('godview', 'assumeSubmerchants', 'write');
  // if isVisible = true
  if (isVisible) {
    // if pendo guide, update class (StaxBill) else, staxBillSSOLink
    return (
      // if !isDisabled then show tooltip
      <Tooltip
        disabled={!isDisabled}
        content='Please Deselect "All Brands" to Enable This link'
      >
        <NavItem>
          <NavLink
            href={
              props.ssoValidationProps.shouldShowPendoGuide ? '#' : staxBillUrl
            }
            data-testid="staxBillLink"
            activeClassName="active"
            target="_blank"
            key="staxBillLink"
            disabled={isDisabled}
            className={
              props.ssoValidationProps.shouldShowPendoGuide
                ? 'StaxBill'
                : 'staxBillSSOLink'
            }
          >
            <ImageWrapper>
              <StyledImg
                alt="staxBillImg"
                src={require('../../../svg/staxbilllogo.svg')}
              />
            </ImageWrapper>
            <StyledStaxBillLabel>Stax Bill</StyledStaxBillLabel>
          </NavLink>
        </NavItem>
      </Tooltip>
    );
  } else return null;
};

type SSOValidationProps = {
  staxBillLinkIsDisabled: boolean;
  staxBillLinkIsVisible: boolean;
  shouldShowPendoGuide: boolean;
  staxPayMerchantIdValue: string;
};

const checkSSO = async (
  selectedBrandSwitcherOption: string,
  authToken: string,
  userId: string
): Promise<SSOValidationProps> => {
  let staxBillLinkIsDisabled: boolean;
  let staxPayMerchantIdValue: string;
  let staxBillLinkIsVisible: boolean;
  let shouldShowPendoGuide: boolean;
  // if all brands are selected in the brand switcher
  if (selectedBrandSwitcherOption?.includes(',')) {
    // ensure link is visible
    staxBillLinkIsVisible = true;
    // but disable it
    staxBillLinkIsDisabled = true;
  } else {
    // use permissions api to check for the existence of a selected brand's staxPayMerchantId value
    const merchantBrandData = await permissionsapi.get(
      authToken,
      `/brand/data/${selectedBrandSwitcherOption}`
    );
    // if the brand has an associated staxPayMerchantId
    if (merchantBrandData.staxPayMerchantId) {
      // set the associated state item with the staxPayMerchantId value
      staxPayMerchantIdValue = merchantBrandData.staxPayMerchantId;
      // validate whether or not the user and merchant have a record associating the two
      const isUserLinkedToMerchant = permissionsapi.get(
        authToken,
        `/user/${userId}/merchant/${merchantBrandData.staxPayMerchantId}`
      );
      // resolve the value from the route above
      await isUserLinkedToMerchant.then(value => {
        // if data is not returned
        if (value.merchantId !== merchantBrandData.staxPayMerchantId) {
          // set a variable to ensure the stax bill link is hidden
          staxBillLinkIsVisible = false;
        } else {
          // set a variable to ensure the stax bill link is not hidden
          staxBillLinkIsVisible = true;
        }
      });
    } else {
      staxBillLinkIsDisabled = false;
      // if the brand has no associated staxPayMerchantId, ensure the link is visible
      staxBillLinkIsVisible = true;
      // set a variable to ensure the pendo guide is rendered
      shouldShowPendoGuide = true;
    }
  }
  return {
    staxBillLinkIsDisabled,
    staxPayMerchantIdValue,
    staxBillLinkIsVisible,
    shouldShowPendoGuide,
  };
};

export const Sidebar: FunctionComponent<{
  routes: RouteItem[];
}> = props => {
  const {
    state: { authToken, auth },
    dispatch: authDispatch,
  } = useContext(AuthStore);

  const { dispatch: ticketsDispatch } = useContext(TicketsStore);
  let supportToken = useSupportAuthToken();
  const { routes } = props;
  const { permit } = usePermissions();
  const {
    dispatch,
    state: { selectedBrandSwitcherOption },
  } = useContext(SelectedMerchantStore);
  const {
    state: { brands: userBrands },
  } = useContext(PermissionsStore);
  const [ssoValidationProps, setSSOValidationProps] =
    useState<SSOValidationProps>(null);

  const userId = auth?.user?.id;
  const canSeeSupportTickets = permit('godview', 'supportTickets', 'read');

  const refreshSupportAuth = async () => {
    try {
      const supportAuth: SupportAuthData = await appserviceapi.post(
        authToken,
        '/support/authenticate',
        null,
        null,
        null,
        null,
        // don't force log out user if they don't have a hubspot contact
        { override401Redirect: true }
      );
      authDispatch(updateSupportAuth(supportAuth));
      return supportAuth[0]?.token;
    } catch (error) {
      /**
       * We used to trigger a toast notification here but it got to the point where
       * they were appearing way too much since we have this and other support ticket
       * routes on a polling lifecycle.
       */
      console.error(`Error fetching support authToken: ${error?.message}`);
    }
  };

  const refreshSupportTickets = async query => {
    try {
      const statusCounts = await getStatusCounts(query);
      ticketsDispatch(updateTicketsCount(statusCounts));
      setPendingTicketsCount(
        statusCounts ? statusCounts['AWAITING_RESPONSE'] : 0
      );
    } catch (error) {
      /**
       * We used to trigger a toast notification here but it got to the point where
       * they were appearing way too much since we have this and other support ticket
       * routes on a polling lifecycle.
       */
      console.error(`Error fetching support tickets: ${error?.message}`);
    }
  };

  useAsyncEffect(
    // ST count polling after every 5 minutes
    async query => {
      // use brand and auth token to compile staxBill navlink settings
      const ssoChecks = await checkSSO(
        selectedBrandSwitcherOption,
        authToken,
        userId
      );

      //updated ssoValidationProps state
      setSSOValidationProps(ssoChecks);

      if (authToken && canSeeSupportTickets) {
        supportToken = await refreshSupportAuth();
        const FIVE_MINUTES_MS = 5 * 60 * 1000;
        const TEN_MINUTES_MS = 10 * 60 * 1000;

        if (supportToken) {
          await refreshSupportTickets(query);
        }

        let ticketsTimer = setTimeout(async function ticketsTimerCallback() {
          await refreshSupportTickets(query);
          ticketsTimer = setTimeout(ticketsTimerCallback, FIVE_MINUTES_MS);
        }, FIVE_MINUTES_MS);

        let authTokenTimer = setTimeout(
          async function authTokenTimerCallback() {
            supportToken = await refreshSupportAuth();
            authTokenTimer = setTimeout(authTokenTimerCallback, TEN_MINUTES_MS);
          },
          TEN_MINUTES_MS
        );

        return () => {
          clearTimeout(ticketsTimer);
          clearTimeout(authTokenTimer);
        };
      }
    },
    [authToken, selectedBrandSwitcherOption, userId]
  );

  const getStatusCounts = useCallback(
    async query => {
      // Execute this only if user can see support tickets.
      if (supportToken && canSeeSupportTickets) {
        try {
          if (selectedBrandSwitcherOption?.includes(',')) {
            delete query.brand;
            query.brands = selectedBrandSwitcherOption.split(',');
          } else {
            delete query.brands;
            query.brand = selectedBrandSwitcherOption;
          }
          const countsResponse = await appserviceapi.get(
            supportToken,
            'support/tickets/status/count',
            query
          );
          return countsResponse;
        } catch (exception) {
          throw exception;
        }
      }
    },
    [supportToken, canSeeSupportTickets, selectedBrandSwitcherOption]
  );

  const hasSelectedBrand =
    !!selectedBrandSwitcherOption &&
    !selectedBrandSwitcherOption?.includes(',');

  // The brand switcher is not shown if the user only has one brand,
  // so we set the user to that brand.
  if (
    userBrands.length === 1 &&
    selectedBrandSwitcherOption !== userBrands[0].name
  )
    dispatch(updateSelectedBrandSwitcherOption(userBrands[0].name));

  const [pendingTicketsCount, setPendingTicketsCount] = useState(0);

  return (
    <StyledNavbar expand id="sidenav-main" className="d-print-none">
      <Container fluid>
        <NavbarBrand className="pt-0 pb-0 logo-wrapper" tag={Link} to="/">
          <StyledStaxLogo
            alt="Stax Connect logo"
            className="navbar-brand-img d-none d-md-block small-stax-logo"
            src={require('../../../assets/img/brand/small-stax-connect-logo.svg')}
          />
          <StyledStaxLogo
            alt="Stax Connect logo"
            className="navbar-brand-img d-none d-md-block big-stax-logo"
            src={require('../../../assets/img/brand/stax-connect-logo.svg')}
          />
        </NavbarBrand>

        {/* User info */}
        {/* TODO: implement this */}
        {/* <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
              <DropdownToggle nav className="nav-link-icon">
                <i className="ni ni-bell-55" />
              </DropdownToggle>
              <DropdownMenu
                aria-labelledby="navbar-default_dropdown_1"
                className="dropdown-menu-arrow"
                right
              >
                <DropdownItem>Action</DropdownItem>
                <DropdownItem>Another action</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Something else here</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
        </Nav> */}

        <Separator />

        {userBrands.length > 1 && (
          <>
            <div className="expanded-only">
              <BrandSwitcher />
            </div>

            <div
              className="collapsed-only"
              style={{ width: '100%', justifyContent: 'center' }}
            >
              <CurrentBrandIcon />
            </div>

            <Separator />
          </>
        )}

        <div className="navbar-collapse-header d-md-none">
          <Row>
            <Col className="collapse-brand" xs="6">
              <Link to="/">
                <img
                  alt="..."
                  src={require('../../../assets/img/brand/stax-connect-logo.svg')}
                />
              </Link>
            </Col>
          </Row>
        </div>
        {/* OmniSearch */}
        {/* TODO: implement this */}
        {/* <Form className="mt-4 mb-3 d-md-none">
          <OmniSearch
            className="input-group-rounded input-group-merge"
            inputProps={{
              className: 'form-control-rounded form-control-prepended',
              placeholder: 'Search'
            }}
          />
        </Form> */}

        <StyledNav navbar>
          {createLinks(routes, permit, hasSelectedBrand, pendingTicketsCount)}
          <Separator />
          <StaxBillLink
            hasSelectedBrand={hasSelectedBrand}
            ssoValidationProps={ssoValidationProps}
          />
        </StyledNav>

        <StyledBottomNav navbar>
          <NavItem id="feedback-button">
            <NavLink
              data-testid="Feedback"
              to=" "
              tag={NavLinkRRD}
              onClick={e => e.preventDefault()}
            >
              <IconWrapper>
                <StyledIcon className="fas fa-bullhorn" />
              </IconWrapper>
              <StyledLabel>Feedback</StyledLabel>
            </NavLink>
          </NavItem>

          {
            /* Show "Test Mode" menu only if the user has that permission */
            permit('godview', 'testModeToggle', 'write') && <TestModeMenu />
          }

          <NavItem>
            <NavLink data-testid="Logout" to="/logout" tag={NavLinkRRD}>
              <IconWrapper>
                <StyledIcon className="fas fa-sign-out-alt" />
              </IconWrapper>
              <StyledLabel>Logout</StyledLabel>
            </NavLink>
          </NavItem>
        </StyledBottomNav>
      </Container>
    </StyledNavbar>
  );
};
