import { CollectionResponse } from '@fattmerchantorg/types-omni';
import React, {
  FunctionComponent,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { CSVLink } from 'react-csv';
import { catanapi } from '../../api/catan';
import styled from 'styled-components';
import { API } from '@fattmerchantorg/types-engine';
import { Container, Row } from './datatable/table-styles';
import { generateLoadingRows } from '../../util/datatable.util';
import { Billing } from '@fattmerchantorg/types-engine/DB';
import {
  StatusPill,
  ToggleSelector,
} from '@fattmerchantorg/truffle-components';
import { useHistory, useLocation } from 'react-router-dom';
import { cleanCollectionQuery, CollectionQuery } from '../shared';
import { parse, stringify } from '../../util/api.util';
import { formatCurrency } from '../../util';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { StatusPillStatus } from '@fattmerchantorg/truffle-components/dist/StatusPill/StatusPill';
import { FormRenderProps } from 'react-final-form';
import { AsyncData } from '../../@types';
import { useAsyncEffect, useAuthToken, useToaster } from '../../hooks';
import {
  TextField,
  SmallPrimaryButton,
  SmallSecondaryButton,
  Text,
} from '@fattmerchantorg/truffle-components';
import { ReserveBrandDataTable } from './ReserveBrandDataTable';
import { formatDate } from '../../util/date.util';

interface getReserves {
  (query: CollectionQuery): Promise<CollectionResponse<API.Reserves>>;
}

const ReserveInfoContainer = styled.div`
  width: 1099px;
  height: 88px;
  left: 28px;
  top: 190px;
  padding-left: 16px;
`;
const JournalTableContainer = styled.div`
  position: relative;
  width: 98%;
  top: 26px;
  left: 18px;
`;
const ActiveContainer = styled.div`
  /* Dark/Cards */

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  position: absolute;
  width: 32%;
  height: 88px;
  top: 190px;

  /* Gray/800 */

  background: #213745;
  /* Gray/600 */

  border: 1px solid #435e70;
  border-radius: 2px;
`;

const InActiveContainer = styled.div`
  /* Dark/Cards */

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  position: absolute;
  width: 32.5%;
  height: 88px;
  left: 33.3%;
  top: 190px;

  /* Gray/800 */

  background: #213745;
  /* Gray/600 */

  border: 1px solid #435e70;
  border-radius: 2px;
`;

const TargetBalanceContainer = styled.div`
  /* Dark/Cards */

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  position: absolute;
  width: 32%;
  height: 88px;
  left: 66.5%;
  top: 190px;

  /* Gray/800 */

  background: #213745;
  /* Gray/600 */

  border: 1px solid #435e70;
  border-radius: 2px;
`;
const ActiveRectangle = styled.div`
  /* Rectangle 2980 */

  width: 4px;
  height: 88px;

  /* Status Colors/Blue-500 */

  background: #009bf2;
  border-radius: 2px 0px 0px 2px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const InActiveRectangle = styled.div`
  /* Rectangle 2980 */

  width: 4px;
  height: 88px;

  /* Status Colors/Yellow-500 */

  background: #f8dc3d;
  border-radius: 2px 0px 0px 2px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;

const BalanceTargetRectangle = styled.div`
  /* Rectangle 2980 */

  width: 4px;
  height: 88px;

  /* Status Colors/Green-500 */

  background: #28cb35;
  border-radius: 2px 0px 0px 2px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
`;
const ActiveText = styled.div`
  /* 10 */

  width: 28px;
  height: 36px;

  /* Heading/H4-Bold */

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  padding-left: 28px;
  padding-top: 16px;
  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;
const Active = styled.div`
  /* Active */

  height: 20px;

  /* Body/Body 2-Regular */

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 32px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;
  align-self: flex-end;
  color: #ffffff;
`;

const BalanceTargetText = styled.div`
  /* Heading/H4-Bold */

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
`;

const StyledToggleSelector = styled(ToggleSelector)`
  height: 33px;
  > div {
    border-color: #435e70;
  }
`;

const StyledTextField = styled(TextField)`
  + div {
    color: red;
  }

  min-width: 418px;

  + button {
    top: 42%;
  }
`;

let reserveType: Array<any>;

reserveType = [
  {
    type: 'Capped Reserve',
    default: true,
  },
  {
    type: 'Rolling Reserve',
    default: false,
  },
];
const BoldText = styled(Text)`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.1667;
  color: #ffffff;
`;
const AddReserveButtonContainer = styled(SmallPrimaryButton)`
  background-color: ${({ theme }) => theme.primaryColor};
  margin-left: 1rem;
  padding: 10px 16px;
  color: ${({ theme }) => theme.black};
  &:hover {
    color: ${({ theme }) => theme.black};
  }
`;
interface CurrencyInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  helperText?: string;
  error?: boolean;
  reserveTypes?: (keyof typeof reserveType)[];
  reserves: Billing;
  formProps?: FormRenderProps;
  reserveProfiles: AsyncData<Billing[]>;
}

export const BrandReservesInfo: FunctionComponent<
  CurrencyInputProps
> = props => {
  const history = useHistory();
  const authToken = useAuthToken();
  const [catanQueryData, setCatanQueryData] = useState<
    CollectionResponse<API.Reserves> | undefined
  >();

  const { toaster, toast } = useToaster();
  const { search, pathname } = useLocation();
  const [loading, setLoading] = useState(false);

  //set our active and inactive reserve totals
  let active = 0;
  let inActive = 0;
  let totalBalance = 0;
  let totalTarget = 0;
  let keyword = useRef('').current;

  const query = useMemo((): CollectionQuery => {
    return {
      ...parse(search),
    };
  }, [search]);

  const [reserveDetails, setReserveDetails] = useState<
    API.Reserves | undefined
  >();

  useAsyncEffect(async () => {
    const reserve = await catanapi.get(
      authToken,
      `/brand/billing?brandName=[STAX]`
    );
    setReserveDetails(reserve);
  }, [authToken]);
  let combinedBilling = [];
  if (
    catanQueryData === null ||
    catanQueryData === undefined ||
    reserveDetails === undefined
  ) {
    combinedBilling = [];
  } else {
    combinedBilling = catanQueryData['billing'].map(b => ({
      ...b,
      ...catanQueryData['reserves'].find(r => r.billing_id === b.billing_id),
    }));
  }

  combinedBilling.forEach(rb => {
    if (
      rb.reserve_percent_volume > 0 ||
      rb.reserve_percent_days > 0 ||
      rb.reserve_flat_amount > 0
    ) {
      active++;
      totalBalance = totalBalance + rb.balance;
      totalTarget = totalTarget + rb.target;
    } else {
      inActive++;
    }
  });
  const reserveStateOptions = [
    {
      label: 'ALL',
      value: 'ALL',
    },
    {
      label: 'ACTIVE',
      value: 'ACTIVE',
    },
    {
      label: 'IN-ACTIVE',
      value: 'INACTIVE',
    },
  ];

  function mapReserveToStatus(state): {
    label: string;
    status: StatusPillStatus;
    icon: FontAwesomeIconProps['icon'];
  } {
    switch (state) {
      case 'ACTIVE':
        return {
          label: 'Active',
          status: 'success',
          icon: ['fas', 'check'],
        };
      case 'INACTIVE':
        return {
          label: 'In-Active',
          status: 'error',
          icon: ['fas', 'times'],
        };
    }
  }

  const columns = [
    {
      Header: 'Date Created',
      accessor: 'created_at',
      style: { width: '124px' },
      Cell: cell => <span>{formatDate(cell.value, 'MM/dd/yyyy')}</span>,
    },
    {
      Header: 'Merchant',
      accessor: 'company_name',
      style: { width: '205px' },
    },
    {
      Header: 'Status',
      accessor: 'reserve_id',
      disableSortBy: true,
      style: {
        textAlign: 'left',
        width: '92px',
        paddingLeft: 0,
        paddingRight: 0,
      },
      Cell: props =>
        props.row.original.reserve_percent_volume > 0 ||
        props.row.original.reserve_percent_days > 0 ||
        props.row.original.reserve_flat_amount > 0 ? (
          <StatusPill {...mapReserveToStatus('ACTIVE')} />
        ) : (
          <StatusPill {...mapReserveToStatus('INACTIVE')} />
        ),
    },
    {
      Header: 'Reserve Rule',
      accessor: 'target',
      style: { width: '193px' },
      Cell: props => (
        <>
          {props.row.original.reserve_percent_days > 0 ? (
            <span>
              {props.row.original.reserve_percent_volume * 100}% &nbsp; - &nbsp;
              {props.row.original.reserve_percent_days}&nbsp; Days
            </span>
          ) : (
            <span>{formatCurrency(props.row.original.target)}</span>
          )}
        </>
      ),
    },
    {
      Header: 'Targeted Withdraw',
      accessor: `reserve_percent_volume`,
      disableSortBy: true,
      style: { width: '27px' },
      Cell: props => (
        <>
          <span>{props.row.original.reserve_percent_volume * 100}%</span>
        </>
      ),
    },
    {
      Header: 'Balance / Target',
      accessor: 'meta.balance / meta.balance',
      style: { width: '257px' },
      Cell: props => (
        <>
          <b>{formatCurrency(props.row.original.balance)}</b>/
          {formatCurrency(props.row.original.target)}
        </>
      ),
    },
  ];
  const pageSizeOptions = [
    { value: 10, label: 'Show 10' },
    { value: 20, label: 'Show 20' },
    { value: 50, label: 'Show 50' },
    { value: 100, label: 'Show 100' },
  ];

  const handleQueryChange = useCallback(
    (newQuery: Partial<CollectionQuery> = {}) => {
      const newSearch = stringify(
        cleanCollectionQuery({ ...query, ...newQuery })
      );
      history.push(`${pathname}?${newSearch}`);
    },
    [query, history, pathname]
  );

  const getData: getReserves = useCallback(
    collectionQuery =>
      catanapi.get(authToken, `/brand/billing?brandName=[STAX]&`, {
        ...collectionQuery,
        // timezone,
      }),
    [authToken]
  );

  useAsyncEffect(async () => {
    setLoading(true);
    try {
      const data = await getData(query);
      setCatanQueryData(data);
      setLoading(false);
    } catch (e) {
      setCatanQueryData(undefined);
      setLoading(false);
      toaster(
        toast.error(
          'Could not get the Reserve Info. Please retry later!',
          'OOps, Something went wrong'
        )
      );
    }
  }, [authToken, search, pathname]);
  return (
    <div>
      <Container>
        <Row>
          <BoldText as={'h4'}>Reserves</BoldText>
          <div>
            <CSVLink
              style={{ color: 'white', textDecoration: 'none' }}
              data={[combinedBilling]}
            >
              <SmallSecondaryButton icon={['fa', 'download']}>
                <span style={{ padding: '2px' }}>Export</span>
              </SmallSecondaryButton>
            </CSVLink>
            <AddReserveButtonContainer
              onClick={() => {
                history.push(`/merchant/null/reserves`);
              }}
              icon={['fas', 'plus']}
            >
              <span>Add Reserve</span>
            </AddReserveButtonContainer>
          </div>
        </Row>
        <Row>
          <StyledToggleSelector
            options={reserveStateOptions}
            defaultSelected={query.reserveStatus?.[0]}
            onChange={(e, value) => {
              handleQueryChange({ reserveStatus: value.value });
            }}
          />
          <div style={{ display: 'flex' }}>
            <StyledTextField
              placeholder={'Search by merchant, MID, PMID, or amount'}
              type="search"
              onChange={(e, value) => {
                keyword = e?.target?.value;
                if (e?.target?.value === '') {
                  handleQueryChange({ keyword: '', page: 1 });
                }
              }}
              onKeyPress={event =>
                event.key === 'Enter' &&
                handleQueryChange({ keyword: keyword, page: 1 })
              }
              onSearch={() => handleQueryChange({ keyword: keyword, page: 1 })}
            />
          </div>
        </Row>
      </Container>
      <ReserveInfoContainer>
        <ActiveContainer>
          <ActiveRectangle></ActiveRectangle>
          <ActiveText>{active}</ActiveText>
          <Active>Active</Active>
        </ActiveContainer>
        <InActiveContainer>
          <InActiveRectangle></InActiveRectangle>
          <ActiveText>{inActive}</ActiveText>
          <Active>In-Active</Active>
        </InActiveContainer>
        <TargetBalanceContainer>
          <BalanceTargetRectangle></BalanceTargetRectangle>
          <ActiveText>
            <BalanceTargetText>
              {formatCurrency(totalBalance)} / {formatCurrency(totalTarget)}
            </BalanceTargetText>
          </ActiveText>
          <Active>Balance / Target</Active>
        </TargetBalanceContainer>
      </ReserveInfoContainer>
      <JournalTableContainer>
        <ReserveBrandDataTable
          data={{
            columns,
            rows: loading ? generateLoadingRows() : combinedBilling ?? [],
          }}
          count={catanQueryData?.last_page ?? 0}
          total={catanQueryData?.total ?? 0}
          currentPage={catanQueryData?.current_page ?? 0}
          defaultPageSize={catanQueryData?.per_page ?? 20}
          lastPage={catanQueryData?.last_page ?? 0}
          loading={loading}
          pageSizeOptions={pageSizeOptions}
          handleQueryChange={handleQueryChange}
          onRowsChanged={onRowsChanged}
        />
      </JournalTableContainer>
    </div>
  );
  function onRowsChanged(rows) {
    const tmp = { ...catanQueryData };
    tmp.data = rows;
    setCatanQueryData(tmp);
  }
};
