import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';

import {
  Collection,
  CollectionQuery,
  CollectionWrapper,
  Id,
  CollectionData,
} from '../../../shared';
import { useAuthToken, useRouteMatchMerchantId } from '../../../../hooks';
import { importerapi } from '../../../../api';
import { Import } from '../../../../@types';
import { formatReadableDate } from '../../../../util/format.util';
import { ImportStatePill } from './ImportStatePill';

import { ImportMenu } from './ImportMenu';
import { parse } from '../../../../util/api.util';

export const ImportsListing: FunctionComponent = () => {
  const authToken = useAuthToken();
  const merchantId = useRouteMatchMerchantId();
  const { search, pathname } = useLocation();
  const history = useHistory();

  const query = useMemo((): CollectionQuery => parse(search), [search]);

  const [shouldAutoRefresh, setShouldAutoRefresh] = useState(true);

  const getData = useCallback(
    () => importerapi.get(authToken, `/merchant/${merchantId}/import`),
    [authToken, merchantId]
  );

  const onReceiveData = useCallback(
    (data: CollectionData<Import>) => {
      if (!data.data?.length) {
        history.push(`/merchant/${merchantId}/imports`);
      } else if (Array.isArray(data.data)) {
        // only auto-refresh if there's at least one pending record
        setShouldAutoRefresh(
          data.data.some(i => i.state === 'IMPORTING' || i.state === 'UNDOING')
        );
      }
    },
    [history, merchantId]
  );

  return (
    <Container fluid>
      <Row>
        <CollectionWrapper>
          <Collection<Import>
            data-testid={`import-collection-${merchantId}`}
            query={query}
            getData={getData}
            onReceiveData={onReceiveData}
            // refresh every 5 seconds
            autoRefreshAfter={shouldAutoRefresh ? 5 * 1000 : undefined}
            // turn off autorefresh after 10 minutes
            autoRefreshOffAfter={10 * 60 * 1000}
            onRowClick={record =>
              history.push({
                pathname: `${pathname}/${record.id}`,
                search,
              })
            }
            columns={[
              {
                Header: 'ID',
                disableSortBy: true,
                accessor: 'id',
                Cell: cell => <Id id={cell.value} />,
              },
              {
                Header: 'Last Action',
                disableSortBy: true,
                accessor: 'updated_at',
                Cell: cell => formatReadableDate(cell.value),
              },
              {
                Header: 'Status',
                disableSortBy: true,
                accessor: 'state',
                Cell: cell => <ImportStatePill state={cell.value} />,
              },
              {
                Header: 'File Name',
                disableSortBy: true,
                accessor: 'filename',
              },
              {
                Header: 'User ID',
                disableSortBy: true,
                accessor: 'user_id',
                Cell: cell => <Id id={cell.value} />,
              },
              {
                id: 'record_count',
                Header: 'Records Uploaded',
                disableSortBy: true,
                accessor: 'summary',
                style: { textAlign: 'right' },
                Cell: cell => cell.value.record_count || 'N/A',
              },
              {
                id: 'imported_count',
                Header: 'Records Imported',
                disableSortBy: true,
                accessor: 'summary',
                style: { textAlign: 'right' },
                Cell: cell => {
                  const record = cell.row.original;
                  return (
                    <span>
                      {record.state === 'IMPORTING' && (
                        <FontAwesomeIcon
                          className="button-icon"
                          icon={faSpinnerThird}
                          spin
                        />
                      )}{' '}
                      {record.summary.imported_count || 'N/A'}
                    </span>
                  );
                },
              },
              {
                Header: '',
                disableSortBy: true,
                id: 'menu',
                style: { textAlign: 'right' },
                Cell: cell => {
                  const record = cell.row.original;
                  return <ImportMenu record={record} />;
                },
              },
            ]}
            noResultsMessage="This merchant has no imports."
          />
        </CollectionWrapper>
      </Row>
    </Container>
  );
};
