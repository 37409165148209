import react, {
  useReducer,
  Reducer,
  useEffect,
  useContext,
  useRef,
  MutableRefObject,
} from 'react';
import axios, { CancelTokenSource } from 'axios';
import { produce } from 'immer';
import { AsyncDataNoNull, AsyncDataStatus } from '../../../@types';
import { queryapi, StatisticsRouteResponse } from '../../../api';
import { useAuthToken, usePrevious, useToaster } from '../../../hooks';
import { ActionTypes, TimeRange } from '../@types/TimeRange';
import { Action, sendFetchFailed, sendFetchSucceeded } from './actions';
import { TimespanQueryParams } from '../@types/TimespanQueryParams';
import { getTimezoneName } from '../../../util/date.util';
import { generateCancelSource } from '../../../util/api.util';
import { DataSet } from '../@types/DataSet';
import {
  setIntervalAndIsPartialParams,
  setTimespanAndGroupByParams,
} from '../util/timerange.util';
import { SelectedMerchantStore } from '../../../context';

export type MerchantPerformanceData = AsyncDataNoNull<{
  /** The gross sales data for the currently-selected timerange */
  topPerforming: StatisticsRouteResponse;
  underPerforming: StatisticsRouteResponse;
}>;

type ReducerState = {
  selectedTimeRange: TimeRange | null;
  selectedDataSet: DataSet;
  merchantPerformance: MerchantPerformanceData;
};

type Dispatch<Action> = react.Dispatch<Action>;

const initialState: ReducerState = {
  selectedTimeRange: null,
  selectedDataSet: 'API',
  merchantPerformance: {
    status: AsyncDataStatus.INITIAL,
    data: {
      topPerforming: { data: [] },
      underPerforming: { data: [] },
    },
    error: null,
  },
};
/** PrevStatus will be `undefined` during the first render. */
type PrevStatus = AsyncDataStatus | undefined;

async function fetchMerchantPerformanceData(
  authToken: string,
  timerange: TimeRange,
  dataSet: DataSet,
  dispatch: Dispatch<Action>,
  brands: string,
  brandsRef: MutableRefObject<String | null>,
  cancelSourceRef: MutableRefObject<CancelTokenSource>
) {
  try {
    const { selectedTimeRangeParams } = calculateQueryParams(timerange);
    const newCancelSource = generateCancelSource();
    const urlTop =
      dataSet === 'ALL'
        ? 'statistics/topGrossSalesByBrandAggregated'
        : 'statistics/topGrossSalesByBrand';
    const urlBottom =
      dataSet === 'ALL'
        ? 'statistics/bottomGrossSalesByBrandAggregated'
        : 'statistics/bottomGrossSalesByBrand';

    brandsRef.current = brands;
    cancelSourceRef.current = newCancelSource;

    const topPerforming: StatisticsRouteResponse = await queryapi.get(
      authToken,
      urlTop,
      { ...selectedTimeRangeParams, brands },
      undefined,
      undefined,
      newCancelSource
    );
    const underPerforming: StatisticsRouteResponse = await queryapi.get(
      authToken,
      urlBottom,
      { ...selectedTimeRangeParams, brands },
      undefined,
      undefined,
      newCancelSource
    );

    dispatch(sendFetchSucceeded({ topPerforming, underPerforming }));
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch(sendFetchFailed(error));
    }
  }
}

type MerchantPerformanceReducer = Reducer<ReducerState, Action>;
/**
 * Reducer for the custom useMerchantPerformanceHook.
 * This reducer is written in finite-state-machine style:
 * If the current status accepts the current action, transition to a new status.
 * https://twitter.com/DavidKPiano/status/1171062893984526336
 */
const reducer: Reducer<ReducerState, Action> = (state, action) => {
  // Use immer's produce function to simplify state updates
  return produce<ReducerState>(state, draftState => {
    switch (state.merchantPerformance.status) {
      case AsyncDataStatus.IDLE:
        switch (action.type) {
          case ActionTypes.USER_SELECTED_TIMESPAN: {
            draftState.merchantPerformance.status = AsyncDataStatus.LOADING;
            draftState.selectedTimeRange = action.payload;
            return;
          }
          case ActionTypes.USER_SELECTED_DATA_SET: {
            draftState.merchantPerformance.status = AsyncDataStatus.LOADING;
            draftState.selectedDataSet = action.payload;
            return;
          }
          default:
            return;
        }

      case AsyncDataStatus.INITIAL:
        switch (action.type) {
          case ActionTypes.USER_SELECTED_TIMESPAN: {
            draftState.merchantPerformance.status = AsyncDataStatus.LOADING;
            draftState.selectedTimeRange = action.payload;
            return;
          }
          default:
            return;
        }

      case AsyncDataStatus.ERROR:
        switch (action.type) {
          case ActionTypes.USER_SELECTED_TIMESPAN:
            draftState.merchantPerformance.error = null;
            draftState.merchantPerformance.status = AsyncDataStatus.LOADING;
            draftState.selectedTimeRange = action.payload;
            return;
          default:
            return state;
        }

      case AsyncDataStatus.LOADING:
        switch (action.type) {
          case ActionTypes.FETCH_SUCCEEDED:
            draftState.merchantPerformance.status = AsyncDataStatus.IDLE;
            draftState.merchantPerformance.data = action.payload;
            draftState.merchantPerformance.error = null;
            return;

          case ActionTypes.FETCH_FAILED:
            draftState.merchantPerformance.status = AsyncDataStatus.ERROR;
            draftState.merchantPerformance.error = action.payload;
            return;
          case ActionTypes.USER_SELECTED_TIMESPAN:
            // intentionally ignore additional fetches while
            // the existing request is in-flight.
            return state;
          default:
            return state;
        }

      default:
        return state;
    }
  });
};

export function useMerchantPerformanceHook(): [ReducerState, Dispatch<Action>] {
  const [state, dispatch] = useReducer<MerchantPerformanceReducer>(
    reducer,
    initialState
  );
  const prevMerchantPerformanceStatus: PrevStatus =
    usePrevious<AsyncDataStatus>(state.merchantPerformance.status);
  const brandsRef = useRef<String>(null); // Track last selected brand(s)
  const cancelSourceRef = useRef<CancelTokenSource>(generateCancelSource()); // Track axios cancel source of each request
  const authToken = useAuthToken();
  const { toast, toaster } = useToaster();

  // Get "global brand" in context.
  const {
    state: { selectedBrandSwitcherOption },
  } = useContext(SelectedMerchantStore);

  /*
   * Perform side-effects according to status transitions.
   */
  useEffect(() => {
    // Compare current status with previous status to ensure side-effects are
    // only performed when status transition occurs.
    if (
      prevMerchantPerformanceStatus !== AsyncDataStatus.LOADING &&
      state.merchantPerformance.status === AsyncDataStatus.LOADING &&
      // Extra check in case `Brand Switcher` (powered by Permissions API) runs after this on first render.
      selectedBrandSwitcherOption !== null
    ) {
      if (!state.selectedTimeRange) {
        // This shouldn't ever happen, but if we end up in LOADING status and
        // there's no timerange, go to ERROR status
        dispatch(sendFetchFailed(new Error('No timerange selected.')));
        return;
      }
      fetchMerchantPerformanceData(
        authToken,
        state.selectedTimeRange,
        state.selectedDataSet,
        dispatch,
        selectedBrandSwitcherOption,
        brandsRef,
        cancelSourceRef
      );
    } else if (
      state.merchantPerformance.status === AsyncDataStatus.LOADING &&
      selectedBrandSwitcherOption !== null &&
      brandsRef.current !== selectedBrandSwitcherOption
    ) {
      // Cancel the request and re-fetch, the user switched the brand dropdown
      // before the previous data could load.
      cancelSourceRef.current.cancel();
      fetchMerchantPerformanceData(
        authToken,
        state.selectedTimeRange,
        state.selectedDataSet,
        dispatch,
        selectedBrandSwitcherOption,
        brandsRef,
        cancelSourceRef
      );
    }
  }, [
    prevMerchantPerformanceStatus,
    state.merchantPerformance.status,
    dispatch,
    authToken,
    state.selectedTimeRange,
    selectedBrandSwitcherOption,
    state.selectedDataSet,
  ]);

  useEffect(() => {
    if (
      prevMerchantPerformanceStatus !== AsyncDataStatus.ERROR &&
      state.merchantPerformance.status === AsyncDataStatus.ERROR
    ) {
      toaster(
        toast.error(
          state.merchantPerformance.error,
          'There was a problem retrieving the transaction.'
        )
      );
    }
  }, [
    prevMerchantPerformanceStatus,
    state.merchantPerformance.status,
    state.merchantPerformance.error,
    toast,
    toaster,
  ]);

  return [state, dispatch];
}

/**
 * Given a timerange, calculates the URL query parameters needed for a request
 * for fattquery service.
 * @returns The URL query parameters for the selected timerange and also the
 * comparative timerange.
 */
function calculateQueryParams(timerange: TimeRange): {
  selectedTimeRangeParams: TimespanQueryParams;
} {
  const timezone = getTimezoneName();

  const selectedTimeRangeParams: TimespanQueryParams = {
    ...setTimespanAndGroupByParams(timerange),
    ...setIntervalAndIsPartialParams(timerange),
    timezone,
  };

  return { selectedTimeRangeParams };
}
