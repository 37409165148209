// giact code look up for ofac match: https://sandbox.api.giact.com/verificationservices/web_api/help/ResourceModel?modelName=MatchLevel
export const getPotentialMatchCode = type => {
  switch (type) {
    case 1:
      return 'The input data and the record data were an exact match';
    case 2:
      return 'The input data and the record data were not an exact match, but were very similar';
    case 3:
      return 'The input data and the record data were not an exact match, but were somewhat similar';
    case 4:
      return 'The input data and the record data did not match';
    case 5:
      return 'The input data and/or the record data were not available to make a comparison';
    default:
      return 'N/A';
  }
};

// giact code look up for email advice: https://sandbox.api.giact.com/verificationservices/web_api/help/ResourceModel?modelName=Advice
export const getEmailAdviceCode = type => {
  switch (type) {
    case 0:
      return 'Fraud Review';
    case 1:
      return 'Unclear Risk';
    case 2:
      return 'Lower Fraud Risk';
    case 3:
      return 'Moderate Fraud Risk';
    case 4:
      return 'Data Entry Review';
    default:
      return 'N/A';
  }
};

// giact code look up for domain risk level: https://sandbox.api.giact.com/verificationservices/web_api/help/ResourceModel?modelName=DomainRiskLevel
export const getDomainRiskCode = type => {
  switch (type) {
    case 0:
      return 'Very Low';
    case 1:
      return 'Low';
    case 2:
      return 'Moderate';
    case 3:
      return 'High';
    case 4:
      return 'Very High';
    default:
      return 'N/A';
  }
};

// giact code look up for fraud type: https://sandbox.api.giact.com/verificationservices/web_api/help/ResourceModel?modelName=FraudType
export const getFraudTypeCode = type => {
  switch (type) {
    case 0:
      return 'Card Not Present Fraud';
    case 1:
      return 'Chargeback';
    case 2:
      return 'First Party Fraud';
    case 3:
      return 'First Payment Default';
    case 4:
      return 'Identity Theft';
    case 5:
      return 'Synthetic Id';
    case 6:
      return 'Other';
    default:
      return 'N/A';
  }
};
