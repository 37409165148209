import { Avatar, Field, FieldError, Form } from '../../shared';
import { AuthStore } from '../../../context';
import { FormErrors } from '../../../@types';
import styled, { withTheme } from 'styled-components';
import { FormApi } from 'final-form';
import { FormText } from 'reactstrap';
import FileField from '../../shared/FileField/FileField';
import { useToaster } from '../../../hooks';

import React, {
  useCallback,
  useContext,
  useRef,
  useState,
  useEffect,
} from 'react';

import {
  RespondToTicketForm,
  replyToTicket,
} from '../../../services/apps-service';

import {
  SmallPrimaryButton,
  TextArea,
  TextField,
} from '@fattmerchantorg/truffle-components';

const CollapsableFormDiv = styled.div`
  width: 100%;
  padding-top: 16px;
  margin-bottom: 28px;

  &[data-focused='true'] {
    #respondToTicketForm {
      display: flex;
    }

    #respondToTicketPlaceHolder {
      display: none;
    }
  }

  #respondToTicketForm {
    display: none;
  }

  #respondToTicketPlaceHolder {
    display: flex;
  }

  form {
    width: 100%;
  }
`;

const StyledTextArea = withTheme(styled(TextArea)`
  padding: 6px 34px 6px 8px;
  gap: 10px;
  width: 100%;
  height: 109px;
  top: 0px;
`);

const ButtonBar = withTheme(styled.div`
  display: flex;
  bottom: 0;
  margin-top: 16px;
  margin-left: 46px;

  .linkDiv {
    width: 40%;
  }

  .buttonDiv {
    display: inline-flex;
    justify-content: flex-end;
    width: 60%;

    button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 63px;
      height: 33px;
      padding: 6px 16px;
      flex-grow: 0;
      margin: 0 0;
      font-size: 14px;
    }
  }

  > * {
    margin: 0 0 0 ${({ theme }) => theme.space[3]}px;
  }
`);

const initialFormValues: RespondToTicketForm = {
  responseText: '',
  files: null,
};

const SupportTicketReplyForm = (props: {
  ticketId: string;
  refreshFn: Function;
}) => {
  const { ticketId, refreshFn } = props;
  const { state: authState } = useContext(AuthStore);
  const { auth } = authState;
  const userName = auth.user.name;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormFocused, setIsFormFocused] = useState(false);
  const { toast, toaster } = useToaster();

  const submitReply = useCallback(
    async (formValues: RespondToTicketForm, form: FormApi) => {
      setIsSubmitting(true);
      await replyToTicket(auth, ticketId, formValues).then(async () => {
        //There's a delay between creating the engagement and it being searchable by hubspot. I'm guessing it's indexing.
        setTimeout(async () => {
          await refreshFn(auth.token, ticketId);
          setIsSubmitting(false);
          form.reset();
          setIsFormFocused(false);
          toaster(toast.success('Comment successfully submitted', 'Submitted'));
        }, 2000);
      });
    },
    [auth, ticketId, refreshFn, toaster, toast, setIsSubmitting]
  );

  const validateForm = useCallback((formValues: RespondToTicketForm) => {
    const formErrors = {} as FormErrors<RespondToTicketForm>;
    const responseText = formValues.responseText;

    if (
      responseText === null ||
      responseText.match(/#^ *$/) !== null ||
      responseText === ''
    ) {
      formErrors.responseText = 'Required';
    }

    return formErrors;
  }, []);

  useEffect(() => {
    if (isFormFocused && responseTextFieldRef.current) {
      responseTextFieldRef.current.focus();
    }
  }, [isFormFocused]);

  const responseTextFieldRef = useRef(null);

  return (
    <CollapsableFormDiv data-focused={isFormFocused}>
      <div id="respondToTicketPlaceHolder">
        <Avatar name={userName} />
        <TextField onFocus={() => setIsFormFocused(true)} />
      </div>

      <div id="respondToTicketForm">
        <Form<RespondToTicketForm>
          initialValues={initialFormValues}
          validate={validateForm}
          onSubmit={submitReply}
        >
          {formProps => (
            <>
              <div
                style={{
                  display: 'inline-flex',
                  width: '100%',
                  marginBottom: '0',
                }}
              >
                <Avatar name={userName} />

                <Field name="responseText">
                  {fieldProps => (
                    <StyledTextArea
                      id="body"
                      placeholder="Add a comment"
                      {...fieldProps.input}
                      disabled={isSubmitting}
                      ref={responseTextFieldRef}
                    />
                  )}
                </Field>
              </div>

              <FieldError style={{ marginLeft: '52px' }} name="responseText" />

              <ButtonBar>
                <div className="linkDiv">
                  <FileField name="files" linkText="Attach File" />
                  <FormText color="muted">Max file size 25 MB</FormText>
                </div>

                <div className="buttonDiv">
                  <SmallPrimaryButton type="submit" loading={isSubmitting}>
                    Save
                  </SmallPrimaryButton>
                </div>
              </ButtonBar>
            </>
          )}
        </Form>
      </div>
    </CollapsableFormDiv>
  );
};

export default SupportTicketReplyForm;
