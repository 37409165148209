import { CollectionPagination } from '@fattmerchantorg/types-omni';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { calculateNumberOfLoadingRows } from '../statement-v3/util/statement-v3-utils';
import { linkStyles } from '../statement-v3/styles';

type LoadMoreRowProps = {
  colSpan: number;
  pagination: CollectionPagination;
  disabled?: boolean;
  isLoading: boolean;
  onClick: () => void;
};

const Td = styled.td`
  text-align: center !important;
`;

const Link = styled.a`
  ${linkStyles}
`;

export const LoadMoreRow: FunctionComponent<LoadMoreRowProps> = props => {
  const { colSpan, pagination, disabled, isLoading, onClick } = props;

  // if there is no pagination, return null
  if (!pagination) return null;
  // if there is no next page, return null
  if (!pagination.next_page_url) return null;
  // if the current page is the last page, return null
  if (pagination.current_page === pagination.last_page) return null;
  // if the current page is the second to last page and the next page (i.e. the last page) is loading, return null
  if (pagination.current_page === pagination.last_page - 1 && isLoading)
    return null;

  return (
    <tr>
      <Td colSpan={colSpan}>
        <Link data-disabled={disabled} onClick={disabled ? null : onClick}>
          Load {calculateNumberOfLoadingRows(pagination)} More
        </Link>
      </Td>
    </tr>
  );
};
