import React, { FunctionComponent } from 'react';
import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components';

import { PageHeader } from '../shared';

import { ImportsProps } from './Imports.types';
import { ImportAddNewButton, ImportsListing, Importer } from './components';
import { ImportProvider } from '../../context/import';

const Background = styled.div`
  width: 100%;
  /* 64px is the height of the nav bar */
  min-height: calc(100% - 64px);
`;

export const Imports: FunctionComponent<ImportsProps> = props => {
  return (
    <Background>
      <Switch>
        <Route path="/merchant/:merchantId/imports/:importId">
          <ImportProvider>
            <Importer />
          </ImportProvider>
        </Route>

        <Route path="/merchant/:merchantId/imports">
          <PageHeader title="Vault Importer Tool">
            <ImportAddNewButton />
          </PageHeader>
          <ImportsListing />
        </Route>
      </Switch>
    </Background>
  );
};
