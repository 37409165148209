import React, {
  Context,
  createContext,
  useReducer,
  useContext,
  useEffect,
} from 'react';
import {
  State,
  ActionType,
  Action,
  EffectType,
  SelectedMerchantContextProps,
  SelectedMerchantContext,
} from './SelectedMerchant.types';
import { AuthStore, updateMerchantToken } from '../auth';
import { AsyncDataStatus } from '../../@types';
import {
  Merchant,
  APIKey,
  Gateway,
  CollectionResponse,
  PricingPlan,
  Registration,
  GodviewBrandData,
} from '@fattmerchantorg/types-omni';
import { coreapi, permissionsapi, catanapi } from '../../api';
import { useAsyncEffect, useToaster } from '../../hooks';
import { history } from '../../history';
import {
  updateSelectedMerchant,
  updateSelectedMerchantGateways,
  updateSelectedMerchantAPIKeys,
  updateSelectedMerchantRegistration,
  removeSelectedMerchantAPIKey,
  rehydrateSelectedMerchant,
  updateSelectedMerchantBrandData,
  fetchSelectedMerchantBrandData,
  fetchSelectedMerchantBillingProfiles,
  updateSelectedMerchantBillingProfiles,
  fetchSelectedMerchantPaymentMethods,
  updateSelectedMerchantPaymentMethods,
  updateMerchantFundingAccounts,
  fetchSelectedMerchantReserves,
  updateSelectedMerchantReserves,
} from './SelectedMerchants.actions';
import { PaymentMethod } from '@fattmerchantorg/types-engine/DB';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { getCompanyBilling, getCompanyReserves } from '../../util/catan.util';

const SelectedMerchantStore = createContext(
  {} as SelectedMerchantContext
) as Context<SelectedMerchantContext>;

const getInitialState = (): State => {
  const selectedbrandName = localStorage.getItem(
    'omniconnect.selectedBrandName'
  );
  try {
    const selectedMerchantId = localStorage.getItem(
      'omniconnect.selectedMerchantId'
    );

    if (selectedMerchantId && typeof selectedMerchantId === 'string') {
      return {
        status: AsyncDataStatus.LOADING,
        merchantId: selectedMerchantId,
        merchant: null,
        registration: null,
        gateways: { status: AsyncDataStatus.LOADING, data: null },
        apiKeys: { status: AsyncDataStatus.LOADING, data: null },
        brand: { status: AsyncDataStatus.INITIAL, data: null },
        effect: {
          type: ActionType.FETCH_SELECTED_MERCHANT,
          payload: selectedMerchantId,
        },
        selectedBrandSwitcherOption: selectedbrandName,
        billingProfiles: { status: AsyncDataStatus.INITIAL, data: null },
        paymentMethods: { status: AsyncDataStatus.INITIAL, data: null },
        merchantFundingAccounts: {
          status: AsyncDataStatus.INITIAL,
          data: null,
        },
        reserves: { status: AsyncDataStatus.INITIAL, data: null },
      };
    } else {
      throw new Error(
        'unable to hydrate SelectedMerchantStore with localStorage'
      );
    }
  } catch (error) {
    return {
      status: AsyncDataStatus.INITIAL,
      merchantId: null,
      merchant: null,
      registration: null,
      gateways: { status: AsyncDataStatus.INITIAL, data: null },
      apiKeys: { status: AsyncDataStatus.INITIAL, data: null },
      brand: { status: AsyncDataStatus.INITIAL, data: null },
      effect: null,
      selectedBrandSwitcherOption: selectedbrandName,
      billingProfiles: { status: AsyncDataStatus.INITIAL, data: null },
      paymentMethods: { status: AsyncDataStatus.INITIAL, data: null },
      merchantFundingAccounts: { status: AsyncDataStatus.INITIAL, data: null },
      reserves: { status: AsyncDataStatus.INITIAL, data: null },
    };
  }
};

type Reducer = React.Reducer<State, Action>;

export const storeReducer: Reducer = (state, action) => {
  switch (action.type) {
    case ActionType.ADD_SELECTED_MERCHANT:
    case ActionType.REHYDRATE_SELECTED_MERCHANT:
    case ActionType.UPDATE_SELECTED_MERCHANT:
      const merchant = action.payload as Merchant;
      return {
        ...state,
        status: AsyncDataStatus.IDLE,
        merchant: merchant,
        merchantId: merchant.id,
        effect: action,
        gateways: {
          status: AsyncDataStatus.LOADING,
          data: null,
        },
        apiKeys: {
          status: AsyncDataStatus.LOADING,
          data: null,
        },
        registration: null,
        brand: null,
      };
    case ActionType.ADD_SELECTED_MERCHANT_GATEWAY:
      const gateway = action.payload as Gateway;
      return {
        ...state,
        gateways: {
          status: AsyncDataStatus.IDLE,
          data: [...state.gateways.data, gateway],
        },
        effect: action,
      };
    case ActionType.UPDATE_SELECTED_MERCHANT_GATEWAYS:
      const gateways = action.payload as Gateway[];
      return {
        ...state,
        gateways: {
          status: AsyncDataStatus.IDLE,
          data: gateways,
        },
        effect: action,
      };
    case ActionType.UPDATE_SELECTED_MERCHANT_APIKEYS:
      const apiKeys = action.payload as APIKey[];
      return {
        ...state,
        apiKeys: { status: AsyncDataStatus.IDLE, data: apiKeys },
        effect: action,
      };
    case ActionType.APPEND_SELECTED_MERCHANT_APIKEY:
      const apiKey = action.payload as APIKey;
      return {
        ...state,
        apiKeys: {
          status: AsyncDataStatus.IDLE,
          data: [...state.apiKeys.data, apiKey],
        },
        effect: action,
      };
    case ActionType.UPDATE_SELECTED_MERCHANT_REGISTRATION:
      const registration = action.payload as Registration;
      return { ...state, registration, effect: action };

    case ActionType.REMOVE_SELECTED_MERCHANT:
      return {
        ...state,
        status: AsyncDataStatus.INITIAL,
        merchant: null,
        merchantId: null,
        registration: null,
        gateways: { status: AsyncDataStatus.INITIAL, data: null },
        apiKeys: { status: AsyncDataStatus.INITIAL, data: null },
        brand: { status: AsyncDataStatus.INITIAL, data: null },
        effect: action,
      };
    case ActionType.CLEAR_SELECTED_MERCHANT_GATEWAYS:
      return {
        ...state,
        gateways: { status: AsyncDataStatus.INITIAL, data: null },
        effect: action,
      };
    case ActionType.CLEAR_SELECTED_MERCHANT_APIKEYS:
      return {
        ...state,
        apiKeys: { status: AsyncDataStatus.INITIAL, data: null },
        effect: action,
      };

    case ActionType.DELETE_SELECTED_MERCHANT_APIKEY:
      return {
        ...state,
        effect: action,
      };

    case ActionType.REMOVE_SELECTED_MERCHANT_APIKEY:
      const deletedAPIKey = action.payload as APIKey;
      return {
        ...state,
        apiKeys: {
          status: AsyncDataStatus.IDLE,
          data: state.apiKeys.data.filter(
            apiKey => apiKey.id !== deletedAPIKey.id
          ),
        },
        effect: action,
      };

    case ActionType.FETCH_SELECTED_MERCHANT_BRAND_DATA:
      return {
        ...state,
        brand: { status: AsyncDataStatus.LOADING, data: null },
        effect: action,
      };

    case ActionType.UPDATE_SELECTED_MERCHANT_STATUS:
      return {
        ...state,
        merchant: {
          ...state.merchant,
          status: action.payload,
        },
      };

    case ActionType.ALTER_SELECTED_MERCHANT: {
      const merchant = action.payload as Partial<Merchant>;
      return {
        ...state,
        merchant: {
          // update the selected merchant without triggering sideeffects
          // to re-fetch registration, gateway, & apikey data.
          ...state.merchant,
          ...merchant,
        },
        effect: action,
      };
    }

    case ActionType.UPDATE_SELECTED_MERCHANT_BRAND_DATA:
      const brandData = action.payload as GodviewBrandData;

      // Given a set of pricing plans,
      // find the pricingplans explicitly associated with the selected merchant's brand.
      const filteredPricingFields: PricingPlan[] = [];
      brandData.pricingFields.forEach(pricingPlan => {
        if (
          pricingPlan.brands &&
          pricingPlan.brands.length &&
          pricingPlan.brands.indexOf(brandData.brandId) > -1
        ) {
          filteredPricingFields.push(pricingPlan);
        }
      });
      return {
        ...state,
        brand: {
          status: AsyncDataStatus.IDLE,
          data: {
            ...brandData,
            filteredPricingFields,
          },
        },
        effect: action,
      };
    case ActionType.REMOVE_SELECTED_MERCHANT_BRAND_DATA:
      return {
        ...state,
        brand: { status: AsyncDataStatus.INITIAL, data: null },
        effect: action,
      };

    case ActionType.REMOVE_SELECTED_MERCHANT_REGISTRATION:
      return {
        ...state,
        registration: null,
        effect: action,
      };
    case ActionType.FETCH_SELECTED_MERCHANT:
      return {
        ...state,
        status: AsyncDataStatus.LOADING,
        merchant: null,
        merchantId: action.payload as string,
        effect: action,
      };

    case ActionType.UPDATE_SELECTED_BRAND_SWITCHER_OPTION: {
      localStorage.setItem(
        'omniconnect.selectedBrandName',
        action.payload as string
      );
      return {
        ...state,
        selectedBrandSwitcherOption: action.payload as string,
      };
    }

    case ActionType.FETCH_SELECTED_MERCHANT_RESERVES:
      return {
        ...state,
        reserves: {
          status: AsyncDataStatus.LOADING,
          data: null,
        },
        effect: action,
      };

    case ActionType.UPDATE_SELECTED_MERCHANT_RESERVES:
      return {
        ...state,
        reserves: {
          status: AsyncDataStatus.IDLE,
          data: action.payload ?? null,
        },
        effect: action,
      };

    case ActionType.FETCH_SELECTED_MERCHANT_BILLING_PROFILES:
      return {
        ...state,
        billingProfiles: {
          status: AsyncDataStatus.LOADING,
          data: null,
        },
        effect: action,
      };

    case ActionType.UPDATE_SELECTED_MERCHANT_BILLING_PROFILES:
      return {
        ...state,
        billingProfiles: {
          status: AsyncDataStatus.IDLE,
          data: action.payload ?? null,
        },
        effect: action,
      };

    case ActionType.FETCH_SELECTED_MERCHANT_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: {
          status: AsyncDataStatus.LOADING,
          data: null,
        },
        effect: action,
      };

    case ActionType.UPDATE_SELECTED_MERCHANT_PAYMENT_METHODS:
      const response = action.payload as {
        traceId: string;
        data: PaymentMethod[];
      };
      return {
        ...state,
        paymentMethods: {
          status: AsyncDataStatus.IDLE,
          data: response?.data ?? null,
        },
        effect: action,
      };

    case ActionType.FETCH_MERCHANT_FUNDING_ACCOUNTS:
      return {
        ...state,
        merchantFundingAccounts: {
          status: AsyncDataStatus.LOADING,
          data: [],
        },
        effect: action,
      };
    case ActionType.UPDATE_MERCHANT_FUNDING_ACCOUNTS:
      const merchantFundingAccount = action.payload;
      return {
        ...state,
        merchantFundingAccounts: {
          status: AsyncDataStatus.LOADING,
          data: merchantFundingAccount,
        },
        effect: action,
      };

    default:
      return state;
  }
};

const effectReducer: Reducer = (state, action) => {
  return state;
};

const reduceReducers =
  (...reducers: Reducer[]) =>
  (state: State, action: Action): State => {
    return reducers.reduce(
      (nextState, reducer) => reducer(nextState, action),
      state
    );
  };

const getAssumeToken = async (authToken: string, merchantId: string) => {
  try {
    const merchantTokenRes = await coreapi.post(
      authToken,
      `/merchant/${merchantId}/assume`
    );
    return merchantTokenRes.token;
  } catch (error) {
    throw error;
  }
};

const SelectedMerchantProvider = (
  props: SelectedMerchantContextProps,
  defaultInitialState
) => {
  // Note that changes in the AuthContext may trigger changes here in the SelectedMerchantContext
  const { state: authState, dispatch: authDispatch } = useContext(AuthStore);
  const authToken = authState.authToken ?? null;
  const authEffect = authState.effect;

  // Handle if defaultInitialState comes in as {} since that won't be trapped in a default assignment
  if (!defaultInitialState || isEmpty(defaultInitialState)) {
    defaultInitialState = getInitialState();
  }
  useEffect(() => {
    const hash = window.location.hash;
    if (hash.startsWith('#/merchant/') || hash === '#/merchant') {
      const hashParts = hash.split('/');
      // get merchantId from local storage
      const localMID = localStorage.getItem('omniconnect.selectedMerchantId');
      // get merchantId from url
      const MIDFromParams =
        hashParts && hashParts.length >= 2 && hashParts[2]
          ? hashParts[2]
          : null;
      // if localMID is not null and is not equal to MIDFromParams, then update local storage
      if (MIDFromParams && (!localMID || localMID !== MIDFromParams)) {
        localStorage.setItem('omniconnect.selectedMerchantId', MIDFromParams);
        window.location.reload();
      }
    }
  }, []);

  useEffect(() => {
    if (authEffect && authEffect.type === 'UPDATE_AUTH_WITH_REDIRECT') {
      dispatch(updateSelectedMerchant(null));
      history.replace('/');
    }
  }, [authEffect]);

  const { toaster, toast } = useToaster();
  const [state, dispatch] = useReducer<React.Reducer<State, Action>>(
    reduceReducers(storeReducer, effectReducer),
    props.initialState ?? defaultInitialState
  );

  const { effect } = state;

  useAsyncEffect(async () => {
    const effectType: EffectType = effect?.type;
    switch (effectType) {
      case 'ADD_SELECTED_MERCHANT':
      case 'REHYDRATE_SELECTED_MERCHANT':
      case 'UPDATE_SELECTED_MERCHANT': {
        const merchant = effect.payload as Merchant;
        const merchantId = merchant && merchant.id;

        localStorage.setItem('omniconnect.selectedMerchantId', merchantId);

        try {
          // Get merchant token and save.
          const merchantToken = await getAssumeToken(authToken, merchantId);
          // Save merchant token to local storage.
          localStorage.setItem(
            'omniconnect.selectedMerchantToken',
            merchantToken
          );
          authDispatch(updateMerchantToken(merchantToken));

          const registration: Registration = await coreapi.get(
            merchantToken,
            `/merchant/${merchantId}/registration`
          );

          dispatch(updateSelectedMerchantRegistration(registration));

          const gateways: Gateway[] = await coreapi.get(
            merchantToken,
            `/merchant/${merchantId}/gateway`
          );

          dispatch(updateSelectedMerchantGateways(gateways));

          const response: CollectionResponse<APIKey> = await coreapi.get(
            merchantToken,
            `/merchant/${merchantId}/apikey`
          );

          dispatch(updateSelectedMerchantAPIKeys(response.data));

          // TEMP - remove once we no longer need the brand.flags.feeStatementWindows check in private.tsx dynamicallyHiddenPaths
          dispatch(fetchSelectedMerchantBrandData(merchant.brand));

          // fetch merchant's billing profile only when they have been onboarded
          const engineCompanyId = get(
            registration,
            'external_company_id',
            null
          );
          if (engineCompanyId) {
            dispatch(fetchSelectedMerchantBillingProfiles(engineCompanyId));
            dispatch(fetchSelectedMerchantPaymentMethods(engineCompanyId));
            dispatch(fetchSelectedMerchantReserves(engineCompanyId));
          } else {
            dispatch(updateSelectedMerchantBillingProfiles(null));
            dispatch(updateSelectedMerchantPaymentMethods(null));
            dispatch(updateSelectedMerchantReserves(null));
          }
        } catch (error) {
          dispatch(updateSelectedMerchant(null));
          history.push('/merchants');
          toaster(
            toast.error(error, 'Unable to fetch associated data for merchant.')
          );
          console.error(error);
        }

        break;
      }

      case 'FETCH_SELECTED_MERCHANT': {
        const merchantId = effect.payload as string;
        try {
          // Get assume token for selected merchant and dispatch into auth store
          const merchantToken = await getAssumeToken(authToken, merchantId);
          // Update the merchant token in local storage as well
          localStorage.setItem(
            'omniconnect.selectedMerchantToken',
            merchantToken
          );
          authDispatch(updateMerchantToken(merchantToken));

          const merchant: Merchant = await coreapi.get(
            merchantToken,
            `/merchant/${merchantId}`,
            null,
            response => response.data,
            // Return the full axios error so we can access the response HTTP status code
            err => err
          );
          // Dispatch the REHYDRATE_SELECTED_MERCHANT action, **not** the UPDATE_SELECTED_MERCHANT action.
          // These different actions allow us to distinguish between the case when the user actually selects a different merchant,
          // and the case where a page reload causes the selected merchant to change from empty to defined.
          dispatch(rehydrateSelectedMerchant(merchant));
        } catch (error) {
          dispatch(updateSelectedMerchant(null));
          toaster(toast.error(error, 'Unable to fetch selected merchant.'));
          history.push(`/error/${error?.response?.status || '500'}`);
        }

        break;
      }

      case 'DELETE_SELECTED_MERCHANT_APIKEY': {
        const apiKeyID = effect.payload as string;
        try {
          // Use the selected merchant assume token.
          const { merchantToken } = authState;
          if (!merchantToken) throw new Error('Missing authentication token');

          const deletedKey: APIKey = await coreapi.delete(
            merchantToken,
            `/user/${apiKeyID}`
          );
          // API Key was successfully deleted, so now remove it from the store.
          dispatch(removeSelectedMerchantAPIKey(deletedKey));
          // Notify user of successful deletion
          toaster(
            toast.success(
              `API Key ${deletedKey.name} successfully deleted.`,
              'Deleted'
            )
          );
        } catch (error) {
          console.error(error);
          toaster(
            toast.error(error, 'There was a problem deleting the API Key.')
          );
        }

        break;
      }

      case 'FETCH_SELECTED_MERCHANT_BRAND_DATA': {
        const merchantBrand = effect.payload as string;
        try {
          // Use the selected merchant assume token.
          const { merchantToken } = authState;
          if (!merchantToken) throw new Error('Missing authentication token');

          const merchantBrandData: GodviewBrandData = await permissionsapi.get(
            merchantToken,
            `/brand/data/${merchantBrand}`
          );
          dispatch(updateSelectedMerchantBrandData(merchantBrandData));
        } catch (error) {
          console.error(error);
          toaster(
            toast.error(
              error,
              'There was a problem fetching merchant pricing plans.'
            )
          );
          dispatch(updateSelectedMerchantBrandData(null));
        }

        break;
      }

      case 'REMOVE_SELECTED_MERCHANT': {
        localStorage.removeItem('omniconnect.selectedMerchantId');
        localStorage.removeItem('omniconnect.selectedMerchantToken');
        break;
      }

      case 'FETCH_SELECTED_MERCHANT_BILLING_PROFILES': {
        const companyId = effect.payload;
        try {
          const { merchantToken } = authState;
          if (!merchantToken) throw new Error('Missing authentication token');

          const billing = await getCompanyBilling(merchantToken, companyId, {
            // type: [
            //   'TRANSACTION',
            //   'DISPUTE',
            //   'RECURRING',
            //   'ACHREJECT',
            //   'INQUIRY',
            // ],
            paginate: false,
          });
          dispatch(updateSelectedMerchantBillingProfiles(billing.data));
        } catch (error) {
          toaster(
            toast.error(
              error,
              'There was a problem fetching merchant billing profiles.'
            )
          );
          dispatch(updateSelectedMerchantBillingProfiles(null));
        }

        break;
      }

      case 'FETCH_SELECTED_MERCHANT_PAYMENT_METHODS': {
        const companyId = effect.payload;
        try {
          const { merchantToken } = authState;
          if (!merchantToken) throw new Error('Missing authentication token');

          const methods: PaymentMethod[] = await catanapi.get(
            merchantToken,
            `/companies/${[companyId]}/payment-methods`,
            { paginate: false }
          );
          dispatch(updateSelectedMerchantPaymentMethods(methods));
        } catch (error) {
          toaster(
            toast.error(
              error,
              'There was a problem fetching merchant payment methods.'
            )
          );
          dispatch(updateSelectedMerchantPaymentMethods(null));
        }

        break;
      }

      case 'FETCH_MERCHANT_FUNDING_ACCOUNTS': {
        try {
          const merchantFundingAccounts = await coreapi.get(
            authToken,
            `/team/funding-account/`
          );

          dispatch(updateMerchantFundingAccounts(merchantFundingAccounts));
        } catch (error) {
          toaster(
            toast.error(
              error,
              'There was a problem fetching merchant funding accounts'
            )
          );
        }

        break;
      }

      case 'FETCH_SELECTED_MERCHANT_RESERVES': {
        const companyId = effect.payload;
        try {
          const { merchantToken } = authState;
          if (!merchantToken) throw new Error('Missing authentication token');

          const reserves = await getCompanyReserves(merchantToken, companyId);
          dispatch(updateSelectedMerchantReserves(reserves.data));
        } catch (error) {
          toaster(
            toast.error(
              error,
              'There was a problem fetching merchant reserves.'
            )
          );
          dispatch(updateSelectedMerchantReserves(null));
        }
        break;
      }

      default:
        break;
    }
  }, [effect]);

  const localContext: SelectedMerchantContext = {
    state,
    dispatch,
  };

  return (
    <SelectedMerchantStore.Provider value={localContext}>
      {props.children}
    </SelectedMerchantStore.Provider>
  );
};

export { SelectedMerchantStore, SelectedMerchantProvider };
