import { catanapi } from '../api';
import { Auth, CollectionResponse } from '@fattmerchantorg/types-omni';
import { API, DB } from '@fattmerchantorg/types-engine';

export function formatCatanCompanyName(type: string | null) {
  switch (type) {
    case 'CORPORATION':
      return 'Public/Private Corporation';
    case 'GOVERNMENT':
      return 'Government';
    case 'SOLEPROPRIETORSHIP':
      return 'Individual or Sole Proprietor';
    case 'LLC':
      return 'LLC';
    case 'NONPROFIT':
      return 'Tax-Exempt Organization';
    case 'PARTNERSHIP':
      return 'Partnership';
    case 'SECREGULATED':
      return 'SEC-Registered Entity';
    case 'FINANCIALINSTITUTION':
      return 'Financial Institution';
    case 'TRUST':
      return 'Association/Estate/Trust';
    default:
      return null;
  }
}

export type BillingProfileChannelShort =
  | 'All'
  | 'Bank'
  | 'CP'
  | 'CNP'
  | 'CC'
  | 'DC'
  | 'PP';
export const billingProfileChannelName: Record<
  DB.Billing['channel'],
  BillingProfileChannelShort
> = {
  ALL: 'All',
  BANK: 'Bank',
  CARDNOTPRESENT: 'CNP',
  CARDPRESENT: 'CP',
  CREDITCARD: 'CC',
  DEBITCARD: 'DC',
  PAYPAL: 'PP',
};

export type BillingProfileSubChannelShort =
  | 'All'
  | 'VISA'
  | 'AMEX'
  | 'DISCOVER'
  | 'MC'
  | 'ACH'
  | 'DEBIT'
  | 'CREDIT'
  | 'PAYPAL'
  | 'VENMO';
export const billingProfileSubChannelName: Record<
  DB.Billing['subchannel'],
  BillingProfileSubChannelShort
> = {
  ALL: 'All',
  VISA: 'VISA',
  AMERICANEXPRESS: 'AMEX',
  DISCOVER: 'DISCOVER',
  MASTERCARD: 'MC',
  ACH: 'ACH',
  DEBIT: 'DEBIT',
  CREDIT: 'CREDIT',
  PAYPAL: 'PAYPAL',
  VENMO: 'VENMO',
};

type CatanErrorResponse = {
  errors?: {
    message: string;
    context?: {
      [key: string]: any;
      key?: string;
      label?: string;
      value?: any;
    };
    path: (string | number)[];
    type: string;
  }[];
  message: string;
  name: string;
  status: number;
  traceId: string;
  validation: string;
};

export interface Portfolio {
  payfac_id: string;
  payfac_name: string;
  state: string | null;
  created_at: string;
  updated_at: string | null;
  deleted_at: string | null;
  type: string;
  settlement_management: boolean;
  finix_credential_id?: string;
  auto_settlement: boolean;
  brands: Array<DB.Brand & { default: boolean }>;
  staxpay_enabled?: boolean;
  processors: Array<
    Pick<
      DB.Credential,
      'processor_name' | 'environment' | 'processor_external_account_id'
    >
  >;
}

export function getErrorsFromCatanErrorResponse(
  response: CatanErrorResponse
): string[] {
  let errors = [];

  if (response.name === 'CatanValidationError') {
    errors = response?.errors.map(error => error.message);
  }

  return errors;
}

export const getAllCompanyOnboardings = async (
  jwt: Auth['token'],
  companyId: string
): Promise<CollectionResponse<API.Onboarding>> => {
  return catanapi.get<CollectionResponse<API.Onboarding>>(
    jwt,
    `/companies/${companyId}/onboarding`
  );
};

type GetCompanyOnboardingsQueryParams = {
  processorName?: DB.ProcessorName[];
  state?: DB.OnboardingState[];
  payfacId?: string;
};

export const getCompanyOnboardings = async (
  authToken: string,
  companyId: string,
  params?: GetCompanyOnboardingsQueryParams
): Promise<CollectionResponse<API.Onboarding>> => {
  return catanapi.get<CollectionResponse<API.Onboarding>>(
    authToken,
    `/companies/${companyId}/onboarding`,
    params
  );
};

export const getOnboardingPortfolios = async (
  authToken: string
): Promise<CollectionResponse<Portfolio>> => {
  return catanapi.get<CollectionResponse<Portfolio>>(authToken, '/payfacs', {
    onboarding: true,
  });
};

type GetCompanyBillingQueryParameters = {
  paginate?: boolean;
  type?: DB.BillingType[];
};

export const getCompanyBilling = async (
  authToken: string,
  companyId: string,
  params?: GetCompanyBillingQueryParameters
): Promise<CollectionResponse<DB.Billing>> => {
  return catanapi.get<CollectionResponse<DB.Billing>>(
    authToken,
    `/companies/${companyId}/billing`,
    params
  );
};

export const getCompanyReserves = async (
  authToken: string,
  companyId: string
): Promise<CollectionResponse<API.Reserves>> => {
  return catanapi.get<CollectionResponse<API.Reserves>>(
    authToken,
    `/companies/${companyId}/reserves`
  );
};

export const getCompanyReservesAndBilling = async (
  authToken: string,
  companyId: string
): Promise<
  [CollectionResponse<API.Reserves>, CollectionResponse<DB.Billing>]
> => {
  return Promise.all([
    getCompanyReserves(authToken, companyId),
    getCompanyBilling(authToken, companyId, { type: ['RESERVES'] }),
  ]);
};

export const hasApprovedAppsOnboarding = (
  onboardings: DB.Onboarding[]
): boolean => {
  return onboardings.filter(o => isApprovedAppsOnboarding(o)).length > 0;
};

export const isApprovedAppsOnboarding = (
  onboarding: DB.Onboarding
): boolean => {
  return (
    (onboarding.state === 'APPROVED' ||
      onboarding.state === 'APPROVED.INACTIVE') &&
    onboarding.processor_name === 'APPS' &&
    !!onboarding.processor_mid
  );
};

export const isEnginePayfacId = (id: string) =>
  typeof id === 'string' && id.length === 34 && id.substring(0, 2) === 'PF';
