import { Registration, Merchant } from '@fattmerchantorg/types-omni';
import { formatFederalTaxId } from '../../../../util';
import { pick } from '../../../../util/object.util';
import {
  isValidEmail,
  isValidPhoneNumber,
  isValidUrl,
  isValidOpenDate,
} from '../../../../util/validator.util';
import {
  BusinessInfoFieldsValues,
  BusinessInfoFieldsErrors,
} from './BusinessInfoForm.types';

export function mapFormValuesToPayload(
  formValues: BusinessInfoFieldsValues
): [Partial<Registration>, Partial<Merchant>] {
  return [
    {
      business_legal_name: formValues.business_legal_name,
      business_dba: formValues.business_dba,
      company_type: formValues.company_type,
      entity_ownership_type: formValues.entity_ownership_type,
      business_tax_id: formatFederalTaxId(formValues.business_tax_id),
      business_phone_number: formValues.business_phone_number?.replace(
        /\D/g,
        ''
      ),
      business_fax: formValues.business_fax?.replace(/\D/g, ''),
      business_email: formValues.business_email,
      business_website: formValues.business_website,
      business_open_date: formValues.business_open_date,
      service_you_provide: formValues.service_you_provide,
    },
    { currency: [formValues.merchant.currency] },
  ];
}

const mapCurrencyToInitialValue = (registration: any): string =>
  !!registration?.merchant?.currency &&
  registration.merchant.currency.length > 0
    ? registration.merchant.currency[0]
    : 'USD';

export function mapRegistrationToInitialFormValues(
  registration: Registration
): BusinessInfoFieldsValues {
  return {
    merchant: { currency: mapCurrencyToInitialValue(registration) },
    ...pick(
      registration,
      'business_legal_name',
      'business_dba',
      'company_type',
      'entity_ownership_type',
      'business_tax_id',
      'business_phone_number',
      'business_fax',
      'business_email',
      'business_website',
      'business_open_date',
      'service_you_provide'
    ),
  } as BusinessInfoFieldsValues;
}

export function validateRequiredFormValues(
  values: BusinessInfoFieldsValues
): BusinessInfoFieldsErrors {
  const errors = {} as BusinessInfoFieldsErrors;

  const requiredFields = [
    'business_legal_name',
    'business_dba',
    'business_tax_id',
    'business_open_date',
  ];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
}

export function validateFormValues(
  values: BusinessInfoFieldsValues
): BusinessInfoFieldsErrors {
  const errors = {} as BusinessInfoFieldsErrors;

  if (
    values.business_phone_number &&
    !isValidPhoneNumber(values.business_phone_number)
  ) {
    errors.business_phone_number = 'Please enter a valid phone number';
  }

  if (values.business_fax && !isValidPhoneNumber(values.business_fax)) {
    errors.business_fax = 'Please enter a valid fax number';
  }

  if (values.business_website && !isValidUrl(values.business_website)) {
    errors.business_website = 'Please enter a valid website';
  }

  if (values.business_email && !isValidEmail(values.business_email)) {
    errors.business_email = 'Please enter a valid email';
  }
  if (
    values.business_open_date &&
    !isValidOpenDate(values.business_open_date)
  ) {
    errors.business_open_date = 'Please enter a valid incorporation date';
  }
  return errors;
}
