import {
  SecondaryButton,
  SmallPrimaryButton,
  Tabs,
} from '@fattmerchantorg/truffle-components';
import { User } from '@fattmerchantorg/types-omni';
import styled from 'styled-components';
import { omit } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Form as FinalForm } from 'react-final-form';
import {
  useAsyncEffect,
  useAuthToken,
  usePermissions,
  useToaster,
} from '../../../hooks';
import { BottomBar, DrawerCss, StyledDrawer } from '../SettingsPagesStylings';
import { RoleDetails, RoleDetailsResponse } from './RoleDetails';
import { Spinner } from '../../shared/Spinner';
import { AllBrandsResponse, permissionsapi } from '../../../api';
import { PermissionsStore } from '../../../context';
import {
  buildPermissionsFormFromRole,
  buildRolePermissionsFromForm,
  legacyPermissions,
  permissionsSections,
  RoleFormFields,
  RoleFormOption,
  RoleFormState,
} from './RoleFormFields';

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 6px;
  height: 100%;

  & > div[role='tablist'] {
    flex-grow: 0;

    & > * {
      flex: 1 0 0;
      padding: 6px 8px;
    }
  }

  & > div[role='tablist'] + * {
    flex-grow: 1;
  }
`;

const TabContent = styled.div`
  padding: 0 16px 16px 16px;
  margin-bottom: ${props => (props.withBottomBar ? '74px' : 0)};
`;

const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;
`;

const UserList = styled.ul`
  margin: 16px 0 0 0;
  padding: 0;
`;
const UserItem = styled.li`
  list-style: none;
  padding: 0 0 16px 0;
`;
const UserName = styled.div`
  font-size: 14px;
  font-weight: bold;
`;
const UserEmail = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.core.gray[400].hex};
`;

type RoleUser = Pick<User, 'id' | 'name' | 'email'>;

type RolesDrawerProps = {
  close: () => void;
  roleId: string;
};

export const RolesDrawer = ({ close, roleId }: RolesDrawerProps) => {
  const { toast, toaster } = useToaster();
  const authToken = useAuthToken();
  const { permit } = usePermissions();
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [role, setRole] = useState<RoleDetails>(null);
  const [users, setUsers] = useState<RoleUser[]>([]);
  const [view, setView] = useState('permissions');
  const {
    state: { brands: userBrands },
  } = useContext(PermissionsStore);

  const [brandOptions, setBrandOptions] = useState<RoleFormOption[]>(() =>
    userBrands?.map(brandItem => ({
      label: brandItem.displayName,
      value: brandItem.name,
    }))
  );
  const canWrite = permit('godview', 'permissions', 'write');

  const getRole = useCallback(async () => {
    if (roleId) {
      setLoading(true);
      try {
        const result = await permissionsapi.get<RoleDetailsResponse>(
          authToken,
          `/role/${roleId}`
        );

        setRole({ ...result.role, user_count: result.user_count });

        const users = await permissionsapi.get<RoleUser[]>(
          authToken,
          `/role/${roleId}/users`
        );

        setUsers(users);
      } catch (error) {
        toaster(toast.error(error, `'There was a problem retreiving the role`));
      } finally {
        setLoading(false);
      }
    }
  }, [authToken, roleId, toast, toaster]);

  useAsyncEffect(() => getRole(), [getRole, roleId]);

  const canSeeAllBrands = permit('godview', 'canSeeAllBrands', 'write');

  const fetchAllBrands = useCallback(async () => {
    try {
      const allBrandsOptions: AllBrandsResponse = await permissionsapi.get(
        authToken,
        `/allBrands`
      );
      setBrandOptions(
        allBrandsOptions.map(br => ({
          label: br.displayName,
          value: br.name,
        }))
      );
    } catch (error) {
      toaster(
        toast.error(
          error,
          'There was a problem fetching all brands information.'
        )
      );
    }
  }, [authToken, toast, toaster]);

  useEffect(() => {
    if (canSeeAllBrands) {
      fetchAllBrands();
    }
  }, [canSeeAllBrands, fetchAllBrands]);

  const initialFormValues = useMemo(
    () => buildPermissionsFormFromRole(role, brandOptions),
    [role, brandOptions]
  );

  let editPermissions = Object.values(permissionsSections)
    .filter(ps => {
      if (!ps.editPermissionNeeded) return ps.forEdit;
      return (
        ps.forEdit &&
        permit(
          'godview',
          ps.editPermissionNeeded.action,
          ps.editPermissionNeeded.permission
        )
      );
    })
    .map(permissionsSection => permissionsSection.permissions)
    .flat();

  editPermissions = editPermissions.concat(legacyPermissions);
  const editPermissionsKeys = editPermissions.map(p => p.name);

  const handleSubmit = useCallback(
    async (formValues: RoleFormState) => {
      if (!formValues) return;
      setIsSaving(true);

      try {
        const { permissions } = formValues;

        // We keep existing permissions that aren't managed by this form,
        // and overwrite those that are.
        const newRolePermissions = {
          ...omit(role.permissions ?? {}, editPermissionsKeys),
          ...buildRolePermissionsFromForm(permissions),
        };

        await permissionsapi.put(authToken, `/roles/${role.id}`, {
          permissions: newRolePermissions,
        });

        toaster(toast.success('Role successfully updated', 'Saved'));
        close();
        getRole();
      } catch (error) {
        toaster(toast.error(error, `There was a problem creating the user.`));
      } finally {
        setIsSaving(false);
      }
    },
    [authToken, getRole, toast, toaster, role, close, editPermissionsKeys]
  );

  const withBottomBar = view === 'permissions' && canWrite;

  return (
    <>
      <StyledDrawer
        title={'Role Details'}
        open={!!roleId}
        onClose={close}
        anchor="right"
        customStyles={DrawerCss}
      >
        <TabsWrapper>
          <Tabs
            options={[
              { label: 'Permissions', value: 'permissions' },
              {
                label: !role ? 'Users' : `Users (${role.user_count})`,
                value: 'users',
              },
            ]}
            defaultSelected={view}
            onChange={(e, option: { label; value }) => setView(option.value)}
          />

          <TabContent withBottomBar={withBottomBar}>
            {loading && (
              <SpinnerWrapper>
                <Spinner />
              </SpinnerWrapper>
            )}

            {!loading && view === 'users' && (
              <UserList>
                {users.map(user => (
                  <UserItem key={user.id}>
                    <UserName>{user.name}</UserName>
                    <UserEmail>{user.email}</UserEmail>
                  </UserItem>
                ))}
              </UserList>
            )}

            {!loading && view === 'permissions' && (
              <FinalForm<RoleFormState>
                onSubmit={handleSubmit}
                initialValues={initialFormValues}
              >
                {({ handleSubmit, hasValidationErrors, values }) => {
                  return (
                    <form onSubmit={handleSubmit} noValidate>
                      <RoleFormFields
                        brandOptions={brandOptions}
                        formValues={values}
                        forEdit
                        readOnly={!canWrite}
                      />

                      {withBottomBar && (
                        <BottomBar>
                          <SecondaryButton
                            onClick={e => {
                              e.preventDefault();
                              close();
                            }}
                          >
                            Cancel
                          </SecondaryButton>
                          <SmallPrimaryButton
                            type="submit"
                            disabled={hasValidationErrors}
                            loading={isSaving}
                          >
                            Save
                          </SmallPrimaryButton>
                        </BottomBar>
                      )}
                    </form>
                  );
                }}
              </FinalForm>
            )}
          </TabContent>
        </TabsWrapper>
      </StyledDrawer>
    </>
  );
};
