function urlBase64Decode(str: string): string {
  let output = str.replace(/-/g, '+').replace(/_/g, '/');
  switch (output.length % 4) {
    case 0: {
      break;
    }
    case 2: {
      output += '==';
      break;
    }
    case 3: {
      output += '=';
      break;
    }
    default: {
      throw new Error('Illegal base64url string!');
    }
  }

  return decodeURIComponent(escape(atob(output)));
}

export function decodeToken(token: string): any {
  const parts = token.split('.');
  let decoded = null;

  if (parts.length !== 3) {
    throw new Error('JWT must have 3 parts');
  }

  decoded = urlBase64Decode(parts[1]);
  if (!decoded) {
    throw new Error('Cannot decode the token');
  }

  return JSON.parse(decoded);
}

function getTokenExpirationDate(token: string): Date | null {
  const decoded = decodeToken(token);
  const d = new Date(0);

  if (typeof decoded.exp === 'undefined') {
    return null;
  }

  // The 0 here is the key, which sets the date to the epoch
  d.setUTCSeconds(decoded.exp);

  return d;
}

/** Determines if a JWT is expired. */
export function isTokenExpired(token: string, offsetSeconds = 0): boolean {
  const d = getTokenExpirationDate(token);
  if (d === null) {
    return false;
  }

  // Token expired?
  return !(d.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
}
