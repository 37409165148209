import React, { FunctionComponent } from 'react';
import { User } from '@fattmerchantorg/types-omni';
import { Select } from '@fattmerchantorg/truffle-components';
// We are using a .css file here to keep truffle styles and modify
// certain styles we need for this component
import './MagicLinkUserSelect.css';

interface IMagicLinkButtonProps {
  users: Array<User>;
  onChange: Function;
}

export const MagicLinkUserSelect: FunctionComponent<
  IMagicLinkButtonProps
> = props => {
  const { users, onChange } = props;

  const options = users.reduce((options, user) => {
    if (!user.system_admin && !(user as any).is_api_key) {
      return [...options, { label: user.name, value: user.email }];
    }
    return options;
  }, []);

  const isDisabled = options.length === 0;

  return (
    <Select
      className="truffle-select-container magic-link-user-select"
      onChange={onChange}
      options={options}
      isDisabled={isDisabled}
      placeholder={isDisabled ? 'No Available Users' : 'Select a user'}
    />
  );
};
