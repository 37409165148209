import { useState, useEffect } from 'react';
import { useSearchState } from './useSearchState';

const isEqual = (a: string | string[], b: string | string[]) => {
  if (Array.isArray(a) && Array.isArray(b)) {
    return a.toString() === b.toString();
  } else {
    return a === b;
  }
};

/** This hook is used to read and write query params in the 'search' portion of the url while, at the same time, keeping a local copy of the state value from search. this hook is useful when you need to read/write the value in a faster way than reading directly from the url, or when you want to only sometimes update the search */
export const useLocalSearchState = <T extends string | string[]>(
  key: string
): [T, (value: T, shouldUpdateSearch?: boolean) => void] => {
  const [searchValue, setSearchValue] = useSearchState<T>(key);
  const [stateValue, _setStateValue] = useState<T>(searchValue);

  const setValue = (value: T, shouldUpdateSearch = true) => {
    // set state value
    _setStateValue(value);
    // if `shouldUpdateSearch`, update the value in the url search as well]
    if (shouldUpdateSearch) setSearchValue(value);
  };

  useEffect(() => {
    // if the value in the url search changes, update the state value
    if (!isEqual(searchValue, stateValue)) _setStateValue(searchValue);
    // eslint-disable-next-line
  }, [searchValue]);

  return [stateValue, setValue];
};
