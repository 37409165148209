import { FormErrors } from '../../../../@types';
import { Registration, Currency } from '@fattmerchantorg/types-omni';

export const businessInfoFields = [
  'business_legal_name',
  'business_dba',
  'company_type',
  'entity_ownership_type',
  'business_tax_id',
  'business_phone_number',
  'business_fax',
  'business_email',
  'business_website',
  'business_open_date',
  'service_you_provide',
] as const;

export type BusinessInfoFields = typeof businessInfoFields[number];

export type BusinessInfoFieldsValues = Pick<
  Registration,
  BusinessInfoFields
> & { merchant: { currency: Currency } };

export type BusinessInfoFieldsErrors = FormErrors<BusinessInfoFieldsValues>;
