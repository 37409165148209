import React, { FC, useContext, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { Widget } from '../../shared/Widget';
import { Icon, TextLink, Tooltip } from '@fattmerchantorg/truffle-components';
import { useAsyncEffect, useAuthToken, useToaster } from '../../../hooks';
import { SelectedMerchantStore } from '../../../context';
import copy from 'copy-to-clipboard';
import { LoadingSpan } from '../../shared';
import { useHistory } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { Terminal } from '@fattmerchantorg/types-engine/DB';
import { CollectionResponse } from '@fattmerchantorg/types-omni';
import { catanapi } from '../../../api';

const Container = styled(Widget)`
  background: white;
  min-height: 119px;
`;
const Span = styled.span`
  font-weight: normal;
`;
const StyledTooltip = styled(Tooltip)`
  margin-top: 10px;
`;

const WidgetHeaderIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;
const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.linkColor};
  cursor: pointer;
`;

const WidgetHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding-bottom: 8px;
  font-size: 18px;
  border-bottom: 1px solid #bdc9cc;
  color: ${({ theme }) => theme.black};
  i {
    margin: 0 0 8px;
  }
`;
const IconTab = styled.div`
  margin-top: 10px !important;
  padding-top: 10px !important;
`;

const MerchantActionsRow = styled.div`
  display: block;
  justify-content: middle;
  text-align: center;
  margin: 0 auto;
  width: 40%;
`;
const ActionText = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;
const Content = withTheme(
  styled.div`
    color: ${({ theme }) => theme.colors.core.gray[600].hex};
    display: flex;
    flex-direction: row;
    margin: 1rem;

    span {
      line-height: 21px;
      font-size: 14px;
      color: ${({ theme }) => theme.black};
      white-space: normal;
    }

    h2 {
      margin: 0px;
      color: ${({ theme }) => theme.black};
    }

    > div {
      flex: 50%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    button {
      color: #32325d;
      padding: 0;
    }

    .processor-content {
      p {
        font-size: 12px;
      }
      table {
        width: 100%;
        color: ${({ theme }) => theme.black};
        thead {
          tr {
            th {
              font-weight: bold;
              font-size: 12px;
              padding: 5px 10px 5px 10px;
              text-align: right;
              &:first-child {
                text-align: left;
              }
              &:last-child {
                padding-left: 0;
              }
            }
          }
        }
        tbody {
          tr {
            &:nth-child(odd) {
              background-color: #ebf9f0;
            }
            td {
              font-weight: bold;
              font-size: 13px;
              padding: 5px 10px 5px 10px;
              text-align: right;
              &:first-child {
                text-align: left;
              }
              &:last-child {
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  `
);

const LoadingRow = styled.div`
  display: flex;
  margin-bottom: 8px;

  /* loading spans */
  > div + div {
    margin-left: 3rem;
  }
`;

const TerminalLoadingState: FC = () => {
  return (
    <>
      <Container>
        <Content>
          <div>
            <WidgetHeader>
              <h2>Terminals</h2>
            </WidgetHeader>
            <LoadingRow>
              <LoadingSpan width="100%" />
            </LoadingRow>
            <LoadingRow>
              <LoadingSpan width="100%" />
            </LoadingRow>
            <LoadingRow>
              <LoadingSpan width="100%" />
            </LoadingRow>
          </div>
        </Content>
      </Container>
    </>
  );
};

export const TerminalsWidget: FC = () => {
  const authToken = useAuthToken();
  const history = useHistory();
  const { toaster, toast } = useToaster();
  const { state } = useContext(SelectedMerchantStore);
  const [terminals, setTerminals] = useState<CollectionResponse<Terminal>>();
  const [isLoading, setIsLoading] = useState(false);
  const { merchantId } = state;
  const engineCompanyId = state?.registration?.external_company_id;
  useAsyncEffect(async () => {
    setIsLoading(true);
    const response = await catanapi.get<CollectionResponse<Terminal>>(
      authToken,
      `/companies/${engineCompanyId}/terminals`
    );

    setTerminals(response);
    setIsLoading(false);
  }, [merchantId, engineCompanyId]);
  const content = () => {
    const nullState = (
      <div>
        <MerchantActionsRow>
          <TextLink
            onClick={() => {
              history.push({
                pathname: `/merchant/${merchantId}/terminals`,
                state: { showBusInfo: true },
              });
            }}
            style={{ fontWeight: 700 }}
            data-testid="open-brand-link"
          >
            <Icon style={{ color: '#009BF2' }} icon={faPlus} />
            Add Terminal
          </TextLink>
          <ActionText style={{ paddingTop: '8px' }}>
            No Terminals Found
          </ActionText>
        </MerchantActionsRow>
      </div>
    );
    const table = (
      <table>
        <thead>
          <tr>
            <th>Terminal Type</th>
            <th>V Number</th>
          </tr>
        </thead>
        <tbody>
          {!isLoading &&
            terminals?.data?.length > 0 &&
            terminals.data?.map((item, index) => (
              <tr key={index}>
                <td>
                  <Span>{item.label}</Span>
                </td>
                <td>
                  <Span>{item.v_number}</Span>
                  <StyledTooltip content={<span>Copy</span>}>
                    &nbsp;
                    <StyledIcon
                      onClick={() => onCopyVNumber(item?.v_number)}
                      icon={['fas', 'copy']}
                    />
                  </StyledTooltip>
                </td>
                <td>
                  <IconTab></IconTab>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    );
    return terminals?.data?.length === 0 && !isLoading ? nullState : table;
  };

  return (
    <>
      {isLoading && <TerminalLoadingState />}
      {!isLoading && (
        <Container data-testid="processor-widget-container">
          <Content>
            <div>
              <WidgetHeader>
                <h2>Terminals</h2>
                {terminals?.data?.length > 0 && (
                  <WidgetHeaderIcon>
                    <Icon
                      style={{ color: '#009BF2' }}
                      icon={faChevronRight}
                      onClick={() => {
                        history.push({
                          pathname: `/merchant/${merchantId}/terminals`,
                          state: { showBusInfo: true },
                        });
                      }}
                    />
                  </WidgetHeaderIcon>
                )}
              </WidgetHeader>
              <div className="processor-content">{content()}</div>
            </div>
          </Content>
        </Container>
      )}
    </>
  );

  function onCopyVNumber(vnumber: string) {
    copy(vnumber);
    toaster(toast.success(`${vnumber} copied to clipboard`));
  }
};
