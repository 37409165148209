import React from 'react';
import styled, { withTheme } from 'styled-components';

const TestBannerWrapper = withTheme(
  styled.div`
    border-top: 2px solid #c11e9b;
    background: #f9e8f5;

    div {
      text-align: center;
      background: #f9e8f5;
    }

    div > span {
      background: #c11e9b;
      padding: 4px 16px;
      border-radius: 4px;
      color: white;
      text-transform: uppercase;
    }

    p {
      padding: 16px 16px 8px 16px;
      margin: 0;
      color: ${({ theme }) => theme.black};
    }
  `
);

export const TestBannerSection = () => (
  <TestBannerWrapper>
    <div>
      <span>Test Mode</span>
    </div>
    <p>Simulate Approvals, Rejections and Pended applications.</p>
  </TestBannerWrapper>
);
