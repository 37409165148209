import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import { usePrevious, useSearchState } from '../../hooks';
import { isNumberLike } from '../../util';

export enum LoadingState {
  Initial,
  Loading,
  Completed,
  Failed,
}
interface StatementLoadingState {
  deposits: LoadingState;
  feesOverview: LoadingState;
  dailySales: LoadingState;
  cardProcessingVolumes: LoadingState;
  cardTypeVolumes: LoadingState;
  surcharges: LoadingState;
  refundAdjustments: LoadingState;
  disputes: LoadingState;
  achRejects: LoadingState;
  messages: LoadingState;

  lastAction: Action;

  loadingCount: number;
  completedCount: number;
  failedCount: number;

  /**
   * Whether or not this is a "first" load of the statement
   * i.e. if all sections have been set to loading at once
   * this can happen on a page load, when the user changes the activity period, or when the user clicks "retry"
   */
  isFirstLoad: boolean;
}

type StatementLoadingStateIndividualStateKey =
  | 'deposits'
  | 'feesOverview'
  | 'dailySales'
  | 'cardProcessingVolumes'
  | 'cardTypeVolumes'
  | 'surcharges'
  | 'refundAdjustments'
  | 'disputes'
  | 'achRejects'
  | 'messages';

enum ActionTypes {
  SET_DEPOSITS_LOADING = 'SET_DEPOSITS_LOADING',
  SET_FEES_OVERVIEW_LOADING = 'SET_FEES_OVERVIEW_LOADING',
  SET_DAILY_SALES_LOADING = 'SET_DAILY_SALES_LOADING',
  SET_CARD_PROCESSING_VOLUME_LOADING = 'SET_CARD_PROCESSING_VOLUME_LOADING',
  SET_CARD_TYPE_VOLUMES_LOADING = 'SET_CARD_TYPE_VOLUMES_LOADING',
  SET_SURCHARGES_LOADING = 'SET_SURCHARGES_LOADING',
  SET_REFUND_ADJUSTMENTS_LOADING = 'SET_REFUND_ADJUSTMENTS_LOADING',
  SET_DISPUTES_LOADING = 'SET_DISPUTES_LOADING',
  SET_ACH_REJECTS_LOADING = 'SET_ACH_REJECTS_LOADING',
  SET_MESSAGES_LOADING = 'SET_MESSAGES_LOADING',
  RESET_STATE = 'RESET_STATE',
  SET_ALL_FAILED = 'SET_ALL_FAILED',
  SET_ALL_LOADING = 'SET_ALL_LOADING',
}

type Action = {
  type: ActionTypes;
  payload: LoadingState;
};

interface Context {
  statementLoadingState: StatementLoadingState;
  statementLoadingTimeoutDuration: number;

  depositsLoading: LoadingState;
  feesOverviewLoading: LoadingState;
  dailySalesLoading: LoadingState;
  cardProcessingVolumesLoading: LoadingState;
  cardTypeVolumesLoading: LoadingState;
  surchargesLoading: LoadingState;
  refundAdjustmentsLoading: LoadingState;
  disputesLoading: LoadingState;
  achRejectsLoading: LoadingState;
  messagesLoading: LoadingState;

  setDepositsLoading: (loading: LoadingState) => void;
  setFeesOverviewLoading: (loading: LoadingState) => void;
  setDailySalesLoading: (loading: LoadingState) => void;
  setCardProcessingVolumesLoading: (loading: LoadingState) => void;
  setCardTypeVolumesLoading: (loading: LoadingState) => void;
  setSurchargesLoading: (loading: LoadingState) => void;
  setRefundAdjustmentsLoading: (loading: LoadingState) => void;
  setDisputesLoading: (loading: LoadingState) => void;
  setAchRejectsLoading: (loading: LoadingState) => void;
  setMessagesLoading: (loading: LoadingState) => void;

  setAllLoading: () => void;
  resetState: () => void;
}

export const statementLoadingIndividualStateKeys: StatementLoadingStateIndividualStateKey[] =
  [
    'deposits',
    'feesOverview',
    'dailySales',
    'cardProcessingVolumes',
    'cardTypeVolumes',
    'surcharges',
    'refundAdjustments',
    'disputes',
    'achRejects',
    'messages',
  ];

const statementLoadingInitialState: StatementLoadingState = {
  deposits: LoadingState.Initial,
  feesOverview: LoadingState.Initial,
  dailySales: LoadingState.Initial,
  cardProcessingVolumes: LoadingState.Initial,
  cardTypeVolumes: LoadingState.Initial,
  surcharges: LoadingState.Initial,
  refundAdjustments: LoadingState.Initial,
  disputes: LoadingState.Initial,
  achRejects: LoadingState.Initial,
  messages: LoadingState.Initial,

  lastAction: null,

  loadingCount: 0,
  completedCount: 0,
  failedCount: 0,

  isFirstLoad: true,
};

type StatementLoadingReducer = (
  state: StatementLoadingState,
  action: Action
) => StatementLoadingState;

const mapActionToStateKey = (
  action: Action
): StatementLoadingStateIndividualStateKey => {
  switch (action.type) {
    case ActionTypes.SET_DEPOSITS_LOADING:
      return 'deposits';
    case ActionTypes.SET_FEES_OVERVIEW_LOADING:
      return 'feesOverview';
    case ActionTypes.SET_DAILY_SALES_LOADING:
      return 'dailySales';
    case ActionTypes.SET_CARD_PROCESSING_VOLUME_LOADING:
      return 'cardProcessingVolumes';
    case ActionTypes.SET_CARD_TYPE_VOLUMES_LOADING:
      return 'cardTypeVolumes';
    case ActionTypes.SET_SURCHARGES_LOADING:
      return 'surcharges';
    case ActionTypes.SET_REFUND_ADJUSTMENTS_LOADING:
      return 'refundAdjustments';
    case ActionTypes.SET_DISPUTES_LOADING:
      return 'disputes';
    case ActionTypes.SET_ACH_REJECTS_LOADING:
      return 'achRejects';
    case ActionTypes.SET_MESSAGES_LOADING:
      return 'messages';
    default:
      return null;
  }
};

const statementLoadingReducer = (
  state: StatementLoadingState,
  action: Action
): StatementLoadingState => {
  const lastAction = action;

  switch (action.type) {
    case ActionTypes.RESET_STATE:
      return statementLoadingInitialState;
    case ActionTypes.SET_ALL_LOADING:
      return {
        ...state,
        lastAction,
        deposits: LoadingState.Loading,
        feesOverview: LoadingState.Loading,
        dailySales: LoadingState.Loading,
        cardProcessingVolumes: LoadingState.Loading,
        cardTypeVolumes: LoadingState.Loading,
        surcharges: LoadingState.Loading,
        refundAdjustments: LoadingState.Loading,
        disputes: LoadingState.Loading,
        achRejects: LoadingState.Loading,
        messages: LoadingState.Loading,
        isFirstLoad: true,
      };
    case ActionTypes.SET_ALL_FAILED:
      return {
        ...state,
        lastAction,
        deposits: LoadingState.Failed,
        feesOverview: LoadingState.Failed,
        dailySales: LoadingState.Failed,
        cardProcessingVolumes: LoadingState.Failed,
        cardTypeVolumes: LoadingState.Failed,
        surcharges: LoadingState.Failed,
        refundAdjustments: LoadingState.Failed,
        disputes: LoadingState.Failed,
        achRejects: LoadingState.Failed,
        messages: LoadingState.Failed,
      };
    default: {
      const stateKey = mapActionToStateKey(action);
      // don't allow setting a state to anything other than failed if it's already failed
      // i.e. don't allow the statement to "recover" after X minutes if it has already timed out
      const newStateValue =
        state[stateKey] === LoadingState.Failed
          ? state[stateKey]
          : action.payload;

      return {
        ...state,
        lastAction,
        // if a single section is set to loading, this is no longer the first load
        // and the user is simply loading more data on a single section
        isFirstLoad:
          newStateValue === LoadingState.Loading ? false : state.isFirstLoad,
        [stateKey]: newStateValue,
      };
    }
  }
};

const statementLoadingCountReducer = (
  state: StatementLoadingState
): StatementLoadingState => {
  let loadingCount = 0;
  let completedCount = 0;
  let failedCount = 0;

  statementLoadingIndividualStateKeys.forEach(slisk => {
    const loadingState = state[slisk];
    switch (loadingState) {
      case LoadingState.Loading:
        loadingCount++;
        break;
      case LoadingState.Completed:
        completedCount++;
        break;
      case LoadingState.Failed:
        failedCount++;
        break;
    }
  });

  return {
    ...state,
    loadingCount,
    completedCount,
    failedCount,
  };
};

const reduceReducers =
  (...reducers: StatementLoadingReducer[]) =>
  (state, action) => {
    return reducers.reduce(
      (nextState, reducer) => reducer(nextState, action),
      state
    );
  };

const defaultLoadingTimeoutDuration = 120000;

export const StatementLoadingContext = createContext<Context>({
  statementLoadingState: statementLoadingInitialState,
} as Context);

interface StatementLoadingContextProviderProps {
  children?: React.ReactNode;
}

export const StatementLoadingContextProvider = (
  props: StatementLoadingContextProviderProps
) => {
  const { children } = props;
  const {
    params: { activityPeriod },
  } = useRouteMatch<{ activityPeriod: string }>();
  const previousActivityPeriod = usePrevious(activityPeriod);

  const [overriddenLoadingTimeoutDuration] = useSearchState('timeout');
  const loadingTimeoutDuration = isNumberLike(overriddenLoadingTimeoutDuration)
    ? +overriddenLoadingTimeoutDuration
    : defaultLoadingTimeoutDuration;

  const [statementLoadingState, statementLoadingDispatch] =
    useReducer<StatementLoadingReducer>(
      reduceReducers(statementLoadingReducer, statementLoadingCountReducer),
      statementLoadingInitialState
    );

  const [statementLoadingTimeout, setStatementLoadingTimeout] =
    useState<ReturnType<typeof setTimeout>>(null);

  const setDepositsLoading = useCallback(
    (loading: LoadingState) =>
      statementLoadingDispatch({
        type: ActionTypes.SET_DEPOSITS_LOADING,
        payload: loading,
      }),
    []
  );
  const setFeesOverviewLoading = useCallback(
    (loading: LoadingState) =>
      statementLoadingDispatch({
        type: ActionTypes.SET_FEES_OVERVIEW_LOADING,
        payload: loading,
      }),
    []
  );
  const setDailySalesLoading = useCallback(
    (loading: LoadingState) =>
      statementLoadingDispatch({
        type: ActionTypes.SET_DAILY_SALES_LOADING,
        payload: loading,
      }),
    []
  );
  const setCardProcessingVolumesLoading = useCallback(
    (loading: LoadingState) =>
      statementLoadingDispatch({
        type: ActionTypes.SET_CARD_PROCESSING_VOLUME_LOADING,
        payload: loading,
      }),
    []
  );
  const setCardTypeVolumesLoading = useCallback(
    (loading: LoadingState) =>
      statementLoadingDispatch({
        type: ActionTypes.SET_CARD_TYPE_VOLUMES_LOADING,
        payload: loading,
      }),
    []
  );
  const setSurchargesLoading = useCallback(
    (loading: LoadingState) =>
      statementLoadingDispatch({
        type: ActionTypes.SET_SURCHARGES_LOADING,
        payload: loading,
      }),
    []
  );
  const setRefundAdjustmentsLoading = useCallback(
    (loading: LoadingState) =>
      statementLoadingDispatch({
        type: ActionTypes.SET_REFUND_ADJUSTMENTS_LOADING,
        payload: loading,
      }),
    []
  );
  const setDisputesLoading = useCallback(
    (loading: LoadingState) =>
      statementLoadingDispatch({
        type: ActionTypes.SET_DISPUTES_LOADING,
        payload: loading,
      }),
    []
  );
  const setAchRejectsLoading = useCallback(
    (loading: LoadingState) =>
      statementLoadingDispatch({
        type: ActionTypes.SET_ACH_REJECTS_LOADING,
        payload: loading,
      }),
    []
  );
  const setMessagesLoading = useCallback(
    (loading: LoadingState) =>
      statementLoadingDispatch({
        type: ActionTypes.SET_MESSAGES_LOADING,
        payload: loading,
      }),
    []
  );
  const resetState = useCallback(
    () =>
      statementLoadingDispatch({
        type: ActionTypes.RESET_STATE,
        payload: null,
      }),
    []
  );
  const setAllLoading = useCallback(
    () =>
      statementLoadingDispatch({
        type: ActionTypes.SET_ALL_LOADING,
        payload: null,
      }),
    []
  );

  useEffect(() => {
    const lastAction = statementLoadingState.lastAction;

    // on mount or reset, start a timeout to fail all loading states if not completed after X seconds
    if (lastAction === null && statementLoadingTimeout === null) {
      const timeout = setTimeout(() => {
        statementLoadingDispatch({
          type: ActionTypes.SET_ALL_FAILED,
          payload: null,
        });

        clearTimeout(statementLoadingTimeout);
        setStatementLoadingTimeout(null);
      }, loadingTimeoutDuration);

      console.log('timeout', timeout);

      setStatementLoadingTimeout(timeout);
    }
  }, [
    statementLoadingState.lastAction,
    loadingTimeoutDuration,
    statementLoadingTimeout,
  ]);

  useEffect(() => {
    const areAllCompleted =
      statementLoadingState.completedCount ===
      statementLoadingIndividualStateKeys.length;
    if (areAllCompleted && statementLoadingTimeout) {
      clearTimeout(statementLoadingTimeout);
    }
  }, [statementLoadingState.completedCount, statementLoadingTimeout]);

  useEffect(() => {
    if (activityPeriod !== previousActivityPeriod) {
      if (statementLoadingTimeout) clearTimeout(statementLoadingTimeout);

      statementLoadingDispatch({
        type: ActionTypes.SET_ALL_LOADING,
        payload: null,
      });
    }
  }, [activityPeriod, previousActivityPeriod, statementLoadingTimeout]);

  useEffect(() => {
    return () => {
      if (statementLoadingTimeout) clearTimeout(statementLoadingTimeout);
    };
  }, [statementLoadingTimeout]);

  return (
    <StatementLoadingContext.Provider
      value={{
        statementLoadingState,
        statementLoadingTimeoutDuration: loadingTimeoutDuration,

        setDepositsLoading,
        setFeesOverviewLoading,
        setDailySalesLoading,
        setCardProcessingVolumesLoading,
        setCardTypeVolumesLoading,
        setSurchargesLoading,
        setRefundAdjustmentsLoading,
        setDisputesLoading,
        setAchRejectsLoading,
        setMessagesLoading,
        setAllLoading,

        resetState,

        depositsLoading: statementLoadingState.deposits,
        feesOverviewLoading: statementLoadingState.feesOverview,
        dailySalesLoading: statementLoadingState.dailySales,
        cardProcessingVolumesLoading:
          statementLoadingState.cardProcessingVolumes,
        cardTypeVolumesLoading: statementLoadingState.cardTypeVolumes,
        surchargesLoading: statementLoadingState.surcharges,
        refundAdjustmentsLoading: statementLoadingState.refundAdjustments,
        disputesLoading: statementLoadingState.disputes,
        achRejectsLoading: statementLoadingState.achRejects,
        messagesLoading: statementLoadingState.messages,
      }}
    >
      {children}
    </StatementLoadingContext.Provider>
  );
};

export const useStatementLoadingState = () => {
  const context = useContext(StatementLoadingContext);
  if (!context) {
    throw new Error('Statement Loading Context Error');
  }
  return context;
};
