import { Dispatch, ReactNode } from 'react';
import { Merchant, User } from '@fattmerchantorg/types-omni';
import { AsyncDataStatus } from '../../../@types';

export interface AuthContextProps {
  children: ReactNode;
}

export type AuthDatum = {
  merchant: Merchant | null;
  token: string;
  user: User;
};

export type AuthData = AuthDatum[];

export type SupportAuthDatum = {
  token: string;
};

export type SupportAuthData = SupportAuthDatum[];

export enum OptimisticUIStatuses {
  Ok,
  Loading,
  Undo,
}

export interface Auth {
  data: AuthData;
  index: number;
  merchant: Merchant | null;
  token: string;
  user: User;
  brand: string;
}

export interface AuthState {
  index: number;
  auth: Auth | null;
  authToken: string | null;
  supportToken: string | null;
  merchantToken: string | null;
  effect: { type: AuthEffectType; payload?: any } | null;
  // `notauthed` if there is no auth or auth token
  // `stale` if there is auth but it was hydrated from localStorage
  // `fresh` if there is auth and it was fetched during this run of the app
  status: 'notauthed' | 'stale' | 'fresh';
  /**
   * When a side-effect triggers an asynchronous request to a backend API,
   * this value will track the status of the request.
   */
  asyncRequestStatus: AsyncDataStatus;
  optimisticUIStatus: OptimisticUIStatuses;
}

export enum AuthActionType {
  USER_CLICKED_MODE_TOGGLE_SWITCH = 'USER_CLICKED_MODE_TOGGLE_SWITCH',
  USER_SELECTED_MERCHANT_OUTSIDE_MODE = 'USER_SELECTED_MERCHANT_OUTSIDE_MODE',
  REMOVE_AUTH = 'REMOVE_AUTH', // log out
  UPDATE_AUTH = 'UPDATE_AUTH', // log in or update
  UPDATE_AUTH_WITH_REDIRECT = 'UPDATE_AUTH_WITH_REDIRECT',
  UPDATE_AUTH_WITH_DIALOG = 'UPDATE_AUTH_WITH_DIALOG',
  UPDATE_AUTH_INDEX = 'UPDATE_AUTH_INDEX', // switch merchant
  UPDATE_AUTH_USER = 'UPDATE_AUTH_USER',
  UPDATE_AUTH_MERCHANT = 'UPDATE_AUTH_MERCHANT',
  UPDATE_OPTIMISTIC_UI_STATUS = 'UPDATE_OPTIMISTIC_UI_STATUS',
  UPDATE_MERCHANT_TOKEN = 'UPDATE_MERCHANT_TOKEN',
  UPDATE_SUPPORT_AUTH_TOKEN = 'UPDATE_SUPPORT_AUTH_TOKEN', // log in or update
}

export enum AuthEffectType {
  USER_CLICKED_MODE_TOGGLE_SWITCH = 'USER_CLICKED_MODE_TOGGLE_SWITCH',
  USER_SELECTED_MERCHANT_OUTSIDE_MODE = 'USER_SELECTED_MERCHANT_OUTSIDE_MODE',
  REMOVE_AUTH = 'REMOVE_AUTH', // log out
  UPDATE_AUTH = 'UPDATE_AUTH', // log in or update
  UPDATE_AUTH_WITH_REDIRECT = 'UPDATE_AUTH_WITH_REDIRECT',
  UPDATE_AUTH_WITH_DIALOG = 'UPDATE_AUTH_WITH_DIALOG',
  UPDATE_AUTH_INDEX = 'UPDATE_AUTH_INDEX', // switch merchant
  UPDATE_AUTH_USER = 'UPDATE_AUTH_USER',
  UPDATE_AUTH_MERCHANT = 'UPDATE_AUTH_MERCHANT',
  UPDATE_OPTIMISTIC_UI_STATUS = 'UPDATE_OPTIMISTIC_UI_STATUS',
  UPDATE_SUPPORT_AUTH_TOKEN = 'UPDATE_SUPPORT_AUTH_TOKEN', // log in or update
}

export type AuthAction = { type: AuthActionType; payload?: any };

export type AuthMode = 'NOT_AUTHED' | 'TEST_MODE' | 'LIVE_MODE';

export interface AuthContext {
  state: AuthState;
  dispatch: Dispatch<AuthAction>;
}
