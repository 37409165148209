import React, { FunctionComponent } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { BulkActionMenuCloseButton } from './BulkActionsCloseMenuButton';

const Container = styled.div`
  animation: slide-up 0.05s ease-in-out both;

  height: 69px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.core.gray[800].hex};
  box-shadow: 0px 0px 40px rgba(6, 35, 51, 0.32);

  display: flex;
  flex-direction: row;
`;

const Wrapper = styled.div`
  z-index: 9;
  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 100%;
  position: fixed;
  bottom: 16px;
`;

type BulkActionMenuProps = React.HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  onClose: () => void;
};

export const BulkActionMenu: FunctionComponent<BulkActionMenuProps> = props => {
  const { isOpen, onClose, children, ...divProps } = props;

  return isOpen
    ? createPortal(
        <Wrapper>
          <Container {...divProps}>
            {children}
            {onClose ? <BulkActionMenuCloseButton onClick={onClose} /> : null}
          </Container>
        </Wrapper>,
        document.body
      )
    : null;
};
