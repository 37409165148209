import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { TextField, Icon, Select } from '@fattmerchantorg/truffle-components';
import { Field } from '../../../shared/Field';
import {
  FormRow,
  PartialForm,
  TwoColumn,
  StaticValue,
} from '../shared/UnderwritingPartialForm';
import { FormRenderProps } from 'react-final-form';
import { refundPolicies } from '../../../../util/registration.util';
import { FieldGroupTitle } from '../shared/UnderwritingSection';
import { formatCurrency, toDecimalString } from '../../../../util';
import { Registration } from '@fattmerchantorg/types-omni';
import { get } from '../../../../util/object.util';
import { IconName } from '@fortawesome/fontawesome-svg-core';

// @TODO: Add built-in labels to Truffle Select component
const SelectLabelAlignmentFix = styled.div`
  display: flex;
`;

const PercentageFields = styled(TwoColumn)`
  grid-row-gap: 10px;
`;

const ShoppingCartFields = styled.div`
  margin: 20px 0;
`;

const SplitTwoColumn = styled(TwoColumn)`
  position: relative;
  grid-column-gap: 17px;

  &:after {
    content: '';
    width: 2px;
    height: 182px;
    border-left: 1px solid ${({ theme }) => theme.lightNeutralOutline};
    position: absolute;
    bottom: 10px;
    left: 50%;
  }
`;

interface ProcessingInfoFieldsProps {
  formProps?: FormRenderProps;
  registration?: Registration;
}

export const ProcessingInfoFields: FunctionComponent<
  ProcessingInfoFieldsProps
> = props => {
  const { formProps, registration } = props;

  const refundOptions = refundPolicies.map(policy => {
    return {
      label: policy.description,
      value: policy.key,
    };
  });

  const percentageFields = {
    card_present_percent: 'Card Present',
    card_not_present_percent: 'Card Not Present',
    card_swiped_percent: 'Card Swipe',
    moto_percent: 'Moto',
    internet: 'Internet',
    international: 'International',
    b2b_percent: 'B2B',
  };

  const shoppingCartFields = {
    annual_gross_shopping_cart_revenue: 'Annual Gross Revenue',
    shopping_cart_card_present_percent: 'Card Present',
    avg_shopping_cart_transaction: 'Average Transaction',
    shopping_cart_international: 'International',
    largest_shopping_cart_transaction: 'Largest Transaction',
    shopping_cart_b2b_percent: 'B2B',
  };

  const renderNumericForms = (formFields, defaultIcon?: IconName) =>
    Object.keys(formFields).map(fieldKey => {
      const error = formProps.errors ? formProps.errors[fieldKey] : null;
      const isPercentageFields =
        defaultIcon ||
        fieldKey.includes('percent') ||
        fieldKey.includes('international');
      const numberProps = isPercentageFields
        ? {
            type: 'number',
            min: '0',
            max: '100',
            slug: (
              <Icon
                icon={['fas', defaultIcon ?? 'percent']}
                style={{
                  color: !!error ? '#ff4646' : '#009bf2',
                }}
              />
            ),
          }
        : {};

      const format = (value: string) =>
        isPercentageFields ? value : formatCurrency(value);
      return (
        <div key={fieldKey}>
          <Field
            name={fieldKey}
            defaultValue={format(get(registration, fieldKey, ''))}
            formatOnBlur
            format={format}
          >
            {props => (
              <TextField
                {...props.input}
                type={'text'}
                {...numberProps}
                label={formFields[fieldKey]}
                error={!!error}
                helperText={error}
              />
            )}
          </Field>
        </div>
      );
    });

  return (
    <PartialForm>
      <FormRow>
        <SelectLabelAlignmentFix>
          <label>Refund Policy</label>
        </SelectLabelAlignmentFix>
        <Field
          name="refund_policy"
          format={value => {
            return refundOptions.find(option => option.value === value);
          }}
          clearable={false}
        >
          {props => (
            <div data-testid="refund_policy">
              <Select
                {...props.input}
                options={refundOptions}
                styles={{
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                  // @TODO - Need to fix this in Truffle's Stax Connect theme
                  input: base => ({ ...base, color: 'white', padding: 0 }),
                }}
                menuPortalTarget={document.body}
                menuShouldScrollIntoView={false}
                isSearchable={true}
                menuPosition="fixed"
              />
            </div>
          )}
        </Field>
      </FormRow>
      <FormRow>
        <SplitTwoColumn>
          <div>
            <FieldGroupTitle>Credit Card</FieldGroupTitle>
            <FormRow>
              <Field
                name="annual_volume"
                format={val => formatCurrency(val)}
                formatOnBlur
              >
                {props => (
                  <TextField
                    {...props.input}
                    type="text"
                    label="Annual Gross Revenue"
                  />
                )}
              </Field>
            </FormRow>
            <FormRow>
              <Field
                name="avg_trans_size"
                format={val => formatCurrency(val)}
                formatOnBlur
              >
                {props => (
                  <TextField
                    {...props.input}
                    type="text"
                    label="Average Transaction Size"
                  />
                )}
              </Field>
            </FormRow>
            <FormRow>
              <Field
                name="highest_trans_amount"
                format={val => formatCurrency(val)}
                formatOnBlur
              >
                {props => (
                  <TextField
                    {...props.input}
                    type="text"
                    label="Largest Transaction Size"
                  />
                )}
              </Field>
            </FormRow>
          </div>
          <div>
            <FieldGroupTitle>ACH</FieldGroupTitle>
            <FormRow>
              <Field
                name="annual_gross_ach_revenue"
                format={val => formatCurrency(val)}
                formatOnBlur
              >
                {props => (
                  <TextField
                    {...props.input}
                    type="text"
                    label="Annual Gross Revenue"
                  />
                )}
              </Field>
            </FormRow>
            <FormRow>
              <Field
                name="avg_ach_transaction"
                format={val => formatCurrency(val)}
                formatOnBlur
              >
                {props => (
                  <TextField
                    {...props.input}
                    type="text"
                    label="Average Transaction Size"
                  />
                )}
              </Field>
            </FormRow>
            <FormRow>
              <Field
                name="largest_ach_transaction"
                format={val => formatCurrency(val)}
                formatOnBlur
              >
                {props => (
                  <TextField
                    {...props.input}
                    type="text"
                    label="Largest Transaction Size"
                  />
                )}
              </Field>
            </FormRow>
          </div>
        </SplitTwoColumn>
        <TwoColumn>
          <div>
            <label>Total Annual Gross Revenue</label>
            <StaticValue>
              {formatCurrency(
                (+toDecimalString(formProps.values?.annual_volume) || 0) +
                  (+toDecimalString(
                    formProps.values?.annual_gross_ach_revenue
                  ) || 0)
              )}
            </StaticValue>
          </div>
          <div>
            <label>Overall Max Transaction Size</label>
            <StaticValue>
              {formatCurrency(
                Math.max(
                  +toDecimalString(formProps.values?.largest_ach_transaction) ||
                    0,
                  +toDecimalString(formProps.values?.highest_trans_amount) || 0
                )
              )}
            </StaticValue>
          </div>
        </TwoColumn>

        {get(registration, 'chosen_processing_method', null) ===
        'shopping-cart' ? (
          <ShoppingCartFields>
            <FieldGroupTitle>Shopping Cart</FieldGroupTitle>
            <TwoColumn>{renderNumericForms(shoppingCartFields)}</TwoColumn>
          </ShoppingCartFields>
        ) : null}

        <FieldGroupTitle>Processing Percentage</FieldGroupTitle>
        <PercentageFields>
          {renderNumericForms(percentageFields, 'percent')}
        </PercentageFields>
      </FormRow>
    </PartialForm>
  );
};
