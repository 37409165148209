import { Gateway } from '@fattmerchantorg/types-omni';
import { accountingapi } from '../../api';
import { getCompanyBilling } from '../../util/catan.util';

type PayoutConfig = Gateway['meta']['payout_config'] & {
  processor_mids: string[];
};

const getCatanPayoutConfig = async (
  authToken: string,
  externalCompanyId: string
) => {
  try {
    const billing = await getCompanyBilling(authToken, externalCompanyId, {
      type: ['TRANSACTION'],
    });
    const catchAllBilling = billing.data.find(
      ({ state, channel, subchannel }) =>
        state === 'ACTIVE' && channel === 'ALL' && subchannel === 'ALL'
    );

    if (catchAllBilling) {
      const { is_gross_settlement, billing_cycle, funding_schedule_days } =
        catchAllBilling;
      return {
        payout_type: is_gross_settlement ? 'GROSS' : 'NET',
        fee_schedule_type: billing_cycle,
        is_ndf_enabled: funding_schedule_days <= 1,
      };
    }
    return null;
  } catch (e) {
    console.log('Error fetching engine payout configuration:', e);
  }
};

const getFinixPayoutConfig = async (authToken: string, id: string) => {
  try {
    const response = await accountingapi.get(
      authToken,
      `/finix/${id}/payout_configuration`
    );
    return response;
  } catch (e) {
    console.log('Error fetching finix payout configuration:', e);
  }
};
const fetchPayoutConfigs = async (gateway: Gateway, authToken: string) => {
  let response = {};

  if (
    gateway.id?.split('_').includes('finix') ||
    gateway.name?.split('_').includes('finix')
  ) {
    response = await getFinixPayoutConfig(authToken, gateway?.merchant_id);
  }

  if (gateway.keys?.credentials?.companyId) {
    response = await getCatanPayoutConfig(
      authToken,
      gateway.keys.credentials.companyId
    );
  }

  if (!response || !Object.keys(response).length) {
    return {
      is_ndf_enabled: null,
      payout_type: null,
      fee_schedule_type: null,
    };
  }
  return response;
};

export const getGroupedGatewayPayoutConfigs = async (
  gateways: Gateway[],
  authToken: string
) => {
  let gatewayPayoutConfigs: PayoutConfig[] = [];

  if (gateways?.length) {
    await Promise.all(
      gateways?.map(async gateway => {
        if (gateway.type !== 'SETTLEMENTS') return;
        const processorMid =
          gateway.processor_mid || gateway.keys?.credentials?.finixMerchantId;
        let response = {};
        if (!!gateway?.meta?.payout_config) {
          response = gateway?.meta?.payout_config;
        } else {
          response = await fetchPayoutConfigs(gateway, authToken);
        }
        gatewayPayoutConfigs.push({
          processor_mids: processorMid ? [processorMid] : [],
          ...response,
        });
      })
    );
  }

  if (gatewayPayoutConfigs.length > 1) {
    // group identical payout configs so we have 1 card for each unique payout config
    // (usually there's only 1 payout config)
    gatewayPayoutConfigs = Object.values(
      gatewayPayoutConfigs.reduce((acc, config) => {
        const removeConfigKey = { ...config };
        delete removeConfigKey.processor_mids;

        const key = JSON.stringify(removeConfigKey);

        if (!!acc[key]) {
          acc[key].processor_mids = Array.from(
            new Set([...acc[key].processor_mids, ...config.processor_mids])
          );
        } else {
          acc[key] = config;
        }

        return acc;
      }, {})
    );
  }

  if (!gatewayPayoutConfigs?.length) {
    return [
      {
        processor_mids: [],
        is_ndf_enabled: null,
        payout_type: null,
        fee_schedule_type: null,
      },
    ];
  }
  return gatewayPayoutConfigs;
};

export const getTooltipForPayoutType = (
  payoutType: PayoutConfig['payout_type']
) => {
  switch (payoutType) {
    case 'GROSS':
      return 'Merchant will receive two deposits: a credit for the total funds received and a debit for the total fees.';
    case 'NET':
      return 'Merchant will receive one deposit that will include funds collected minus fees.';
    default:
      return null;
  }
};

export const getTooltipForScheduleType = (
  scheduleType: PayoutConfig['fee_schedule_type']
) => {
  switch (scheduleType) {
    case 'DAILY':
      return 'Fees will be charged on a rolling, daily basis.';
    case 'MONTHLY':
      return 'Fees will accrue and be charged on a rolling, monthly basis.';
    default:
      return null;
  }
};
