import { useReducer, Dispatch, Reducer, useEffect } from 'react';
import { AsyncData, AsyncDataStatus } from '../../../../@types';
import { usePrevious, useSearchState, useToaster } from '../../../../hooks';
import {
  Action,
  sendFetchSucceeded,
  sendFetchFailed,
  sendFundingAccountIDChanged,
} from './actions';
import { ActionTypes } from './types';
import { FundingAccount } from '@fattmerchantorg/types-omni';

export const FundingAccountAsyncDataStatus = AsyncDataStatus;
export type FundingAccountAsyncData = AsyncData<FundingAccount>;
export type FundingAccountDetailReducerState = FundingAccountAsyncData;
const initialState: FundingAccountDetailReducerState = {
  status: FundingAccountAsyncDataStatus.INITIAL,
  data: null,
};
/** PrevStatus will be `undefined` after first render. */
type PrevStatus = FundingAccountAsyncData['status'] | undefined;

async function fetchFundingAccount(
  getData: (id: string) => Promise<FundingAccount>,
  detailId: string | string[] | null | undefined,
  dispatch: Dispatch<Action>
) {
  try {
    if (typeof detailId !== 'string' || !detailId) {
      // Abort search if no dispute ID is set in URL
      return;
    }
    if (!getData) {
      // Abort search if data fetch function is not provided.
      return;
    }
    const response = await getData(detailId);
    dispatch(sendFetchSucceeded(response));
  } catch (error) {
    console.error(error);
    dispatch(sendFetchFailed(error));
  }
}

type FundingAccountDetailReducer = Reducer<
  FundingAccountDetailReducerState,
  Action
>;
/**
 * Reducer for the custom useDetailsModalHook.
 * This reducer is written in finite-state-machine style:
 * If the current status accepts the current action, transition to a new status.
 * https://twitter.com/DavidKPiano/status/1171062893984526336
 */
const reducer: FundingAccountDetailReducer = (state, action) => {
  switch (state.status) {
    case FundingAccountAsyncDataStatus.INITIAL:
      switch (action.type) {
        case ActionTypes.FETCH_REQUESTED: {
          return {
            status: FundingAccountAsyncDataStatus.LOADING,
            data: null,
            updatePayload: null,
          };
        }
        default:
          return state;
      }
    case FundingAccountAsyncDataStatus.IDLE:
      switch (action.type) {
        case ActionTypes.FETCH_REQUESTED:
          return {
            status: FundingAccountAsyncDataStatus.LOADING,
            data: null,
            updatePayload: null,
          };

        default:
          return state;
      }

    case FundingAccountAsyncDataStatus.ERROR:
      switch (action.type) {
        case ActionTypes.FETCH_REQUESTED:
          return {
            status: FundingAccountAsyncDataStatus.LOADING,
            data: null,
            updatePayload: null,
          };

        default:
          return state;
      }

    case FundingAccountAsyncDataStatus.LOADING:
      switch (action.type) {
        case ActionTypes.FETCH_SUCCEEDED:
          return {
            status: FundingAccountAsyncDataStatus.IDLE,
            data: action.payload,
            updatePayload: null,
          };

        case ActionTypes.FETCH_FAILED:
          return {
            status: FundingAccountAsyncDataStatus.ERROR,
            data: null,
            error: action.payload,
            updatePayload: null,
          };
        case ActionTypes.FETCH_REQUESTED:
          // intentionally ignore additional fetches while
          // the existing request is in-flight.
          return state;
        default:
          return state;
      }
  }
};

export default function useDetailsModal(
  getData: (id: string) => Promise<FundingAccount>,
  updateData: (
    id: string,
    payload: Partial<FundingAccount>
  ) => Promise<FundingAccount>
): [FundingAccountAsyncData, Dispatch<Action>] {
  const { toaster, toast } = useToaster();
  const [{ detailId, u: updateNumber }] = useSearchState();

  const [state, dispatch] = useReducer<FundingAccountDetailReducer>(
    reducer,
    initialState
  );
  const prevStatus = usePrevious<PrevStatus>(state.status);

  /*
   * Perform side-effects according to status transitions.
   */
  useEffect(() => {
    // Use value of `prevStatus` to ensure side-effects are only performed when status transition occurs.
    switch (true) {
      case prevStatus !== FundingAccountAsyncDataStatus.LOADING &&
        state.status === FundingAccountAsyncDataStatus.LOADING:
        fetchFundingAccount(getData, detailId, dispatch);
        break;
    }
  }, [prevStatus, state.status, getData, detailId, dispatch]);

  useEffect(() => {
    switch (true) {
      case prevStatus === FundingAccountAsyncDataStatus.LOADING &&
        state.status === FundingAccountAsyncDataStatus.ERROR:
        toaster(
          toast.error(
            state.error,
            `There was a problem retrieving the account.`
          )
        );
        break;
    }
  }, [prevStatus, state.status, state.error, toast, toaster]);

  /* Watch URL, and dispatch Actions */
  useEffect(() => {
    if (detailId && getData) {
      // Only if detailId is defined, dispatch the `FETCH_REQUESTED` Action
      dispatch(sendFundingAccountIDChanged());
    }
  }, [detailId, getData, updateNumber]);

  return [state, dispatch];
}
