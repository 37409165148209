import React from 'react';
import styled from 'styled-components';

import {
  BaseModal,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  Icon,
  ModalFooter,
} from '@fattmerchantorg/truffle-components';

import {
  faExclamationTriangle,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons';

const StyledModalContent = styled.div`
  overflow-y: auto;
  padding: ${({ theme }) => theme.space[4]}px;

  display: flex;
  justify-content: center;
  flex-direction: column;

  > p {
    text-align: center;
    margin: 0;
    font-size: 1em;
  }

  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const StyledModalFooter = styled(ModalFooter)`
  justify-content: center;
  padding-top: 0;

  > *:first-child {
    margin-right: 16px;
  }
`;

const WarningIcon = styled(Icon)`
  font-size: 70px;
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.core.gray[400].hex};
` as typeof Icon;

type ConfirmModalProps = {
  onConfirm: () => void;
  onClose: () => void;
  confirmButtonLabel: string;
  cancelButtonLabel?: string;
  title: string;
  icon?: IconDefinition;
  isOpen: boolean;
  // TODO: remove this -- we don't want to hardcode colors
  submitButtonColor?: string;
  zIndex?: number;
};

export const ConfirmModal: React.FunctionComponent<
  ConfirmModalProps
> = props => {
  const {
    title,
    onConfirm,
    onClose,
    confirmButtonLabel,
    cancelButtonLabel = 'Cancel',
    children,
    isOpen,
    icon = faExclamationTriangle,
    submitButtonColor,
    zIndex = 10,
  } = props;

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={false}
      style={{
        overlay: { pointerEvents: 'auto', zIndex: zIndex },
        content: { maxWidth: '533px' },
      }}
    >
      <ModalHeader title={title} onClose={onClose} />
      <StyledModalContent>
        <WarningIcon icon={icon} />
        {children}
      </StyledModalContent>
      <StyledModalFooter>
        <SecondaryButton onClick={onClose}>{cancelButtonLabel}</SecondaryButton>
        <PrimaryButton
          onClick={onConfirm}
          style={
            submitButtonColor ? { backgroundColor: submitButtonColor } : {}
          }
        >
          {confirmButtonLabel}
        </PrimaryButton>
      </StyledModalFooter>
    </BaseModal>
  );
};
