import { ProcessorNames } from '../../../underwriting/components/status/submit/SubmitApplicationForm.types';

export const processorNameOptions = [{ label: 'All', value: 'ALL' }].concat(
  Object.keys(ProcessorNames).map(key => {
    let label = ProcessorNames[key];
    if (key.includes('FINIX')) {
      label += ' (FINIX)';
    }
    return {
      label: label,
      value: key,
    };
  })
);

const transactionTypes = [
  {
    label: 'ACH Return',
    value: 'ACHRETURN',
  },
  {
    label: 'Charge',
    value: 'CHARGE',
  },
  {
    label: 'Credit',
    value: 'CREDIT',
  },
  {
    label: 'Dispute',
    value: 'DISPUTE',
  },
  {
    label: 'Fee',
    value: 'FEE',
  },
  {
    label: 'Payout',
    value: 'PAYOUT',
  },
  {
    label: 'Refund',
    value: 'REFUND',
  },
  {
    label: 'Debit',
    value: 'DEBIT',
  },
] as const;

export const transactionTypeOptions = [{ label: 'All', value: 'ALL' }].concat(
  transactionTypes
);

const transactionPaymentMethodTypes = [
  {
    label: 'Visa',
    value: 'VISA',
  },
  {
    label: 'Mastercard',
    value: 'MASTERCARD',
  },
  {
    label: 'American Express',
    value: 'AMERICANEXPRESS',
  },
  {
    label: 'Discover',
    value: 'DISCOVER',
  },
  {
    label: 'Debit',
    value: 'DEBIT',
  },
  {
    label: 'EBT',
    value: 'EBT',
  },
  {
    label: 'ACH',
    value: 'ACH',
  },
] as const;
export const transactionPaymentMethodTypeOptions = [
  { label: 'All', value: 'ALL' },
].concat(transactionPaymentMethodTypes);
