import { Tooltip, Icon } from '@fattmerchantorg/truffle-components';
import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';

export const UnderwritingSectionBase = styled.section`
  position: relative;
  background-color: ${({ theme }) => theme.colors.core.gray[800].hex};
  padding: 16px;
  border-radius: 2px;
  line-height: 1.5;
  p,
  span {
    font-size: 14px;
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
  }
  a {
    text-decoration: underline;
    color: #009bf2;
  }
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

export const FieldGroupTitle = styled.h4`
  font-weight: bold;
  line-height: 1.5;
  font-size: 14px;
  margin: 0 0 8px;
  color: white;
`;

const SectionHeader = styled.div`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.lightNeutralOutline};
  padding-block-end: 8px;
  background-color: ${({ theme }) => theme.colors.core.gray[800].hex};
  h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    color: ${({ theme }) => theme.white};
    margin: 0;

    > span:first-of-type {
      margin-right: 8px;
    }
  }
`;

const SectionContent = styled.div`
  padding: 8px 8px;
`;

const SectionFooter = styled.div``;

const SectionHeaderIcon = styled(Icon)`
  position: relative;
  top: 2px;
`;

const EditToggle = styled.button`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 20px;
  right: 16px;
  z-index: 2;
  background: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  color: #009bf2;
  width: 16px;

  > span > svg {
    margin-right: 0;
  }
`;

interface UnderwritingSectionProps
  extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  isEditable?: boolean;
  rightContent?: React.FunctionComponent;
  onEdit?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  id: string;
  footer?: React.ReactNode;
}

export const UnderwritingSection: FunctionComponent<
  UnderwritingSectionProps
> = props => {
  const { title, children, isEditable, onEdit, id, rightContent, ...rest } =
    props;
  const [toolTipContent, setToolTipContent] = useState<string | undefined>();

  const renderProps = {
    setToolTipContent: (content: string) => {
      if (content === toolTipContent) return;
      setToolTipContent(content);
    },
  };

  return (
    <UnderwritingSectionBase id={id} data-testid={id} {...rest}>
      <SectionHeader>
        <h3>
          <span>{title}</span>
          {toolTipContent && (
            <Tooltip content={toolTipContent}>
              <SectionHeaderIcon icon={['far', 'info-circle']} />
            </Tooltip>
          )}
        </h3>
        {isEditable ? (
          <EditToggle onClick={onEdit ? onEdit : null}>
            <Icon icon={['fas', 'pencil']} />
          </EditToggle>
        ) : rightContent ? (
          rightContent({})
        ) : null}
      </SectionHeader>
      <SectionContent>
        {typeof children === 'function' ? children(renderProps) : children}
      </SectionContent>
      <SectionFooter>{props.footer}</SectionFooter>
    </UnderwritingSectionBase>
  );
};
