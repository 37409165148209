import React, { useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { appserviceapi } from '../../../api';

import { usePermissions, useAuthToken, useToaster } from '../../../hooks';
import { PricingPlansForm, PricingPlanFromForm } from './PricingPlansForm';

const pricingPlansRoute = '/settings/pricingplans';

export const PricingPlansNew = () => {
  const { permit } = usePermissions();
  const { toast, toaster } = useToaster();
  const authToken = useAuthToken();
  const history = useHistory();

  const onSubmit = useCallback(
    async ({
      pricingPlan,
      duplicateForTrustAccount,
    }: {
      pricingPlan: PricingPlanFromForm;
      duplicateForTrustAccount?: boolean;
    }) => {
      try {
        await appserviceapi.post(authToken, `/pricing-plans/data`, {
          pricingPlan,
          duplicateForTrustAccount,
        });

        toaster(toast.success('Pricing plan successfully created', 'Saved'));
        history.push(pricingPlansRoute);
      } catch (error) {
        toaster(
          toast.error(error, 'There was a problem creating your pricing plan.')
        );
      }
    },
    [authToken, history, toast, toaster]
  );

  const canWrite = permit('godview', 'pricingPlans', 'write');
  if (!canWrite) return <Redirect to={pricingPlansRoute} />;

  return <PricingPlansForm onSubmit={onSubmit} />;
};
