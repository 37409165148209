import React from 'react';
import styled from 'styled-components';

const StyledInputGroup = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;

  @media (max-width: 1000px) {
    border-radius: 0px;
  }

  @media (min-width: 1000px) {
    > :not(:first-child):not(:last-child) {
      border-radius: 0px;
      margin-right: -1px;
      margin-left: -1px;
    }

    > :first-child {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    > :last-child {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
`;

export const InputGroup = props => <StyledInputGroup {...props} />;
