import React, { FunctionComponent } from 'react';
import { Routes } from '../routes/routes';
import {
  AuthProvider,
  ToastProvider,
  PermissionsProvider,
  PageHeaderProvider,
  TestModeProvider,
  ModalProvider,
  ModalRoot,
  TicketsProvider,
} from '../../context';
import { ThemeProvider } from 'styled-components';
import { ErrorBoundary } from '../error/ErrorBoundary';

import { staxConnect, GlobalStyles } from '@fattmerchantorg/truffle-tokens';
import { TestModeGlobalMessage } from './components/TestModeGlobalMessage';
import { setupIcons } from './setupIcons';
import StatusPageIO from '../statuspage-io/StatusPageIO';

setupIcons();

const Content: FunctionComponent = () => {
  return <Routes />;
};

export const Main: FunctionComponent = () => {
  // We will temporarily have 2 themes as we slowly migrate towards Truffle.
  // The OC theme will also include individual properties that are required by Truffle,
  // but are overwritten by the OC theme.
  // Ex: "colors.primary.skyBlue"

  const domain = (window.location.origin || '').toLowerCase();
  const isQaOrPreProd =
    domain.includes('qa-') ||
    domain.includes('qabuilds') ||
    domain.includes('preprod') ||
    domain.includes('sandbox');

  return (
    <ThemeProvider theme={staxConnect}>
      <GlobalStyles />
      <ToastProvider>
        <PageHeaderProvider>
          <AuthProvider>
            <PermissionsProvider>
              <TestModeProvider>
                <ModalProvider>
                  <TicketsProvider>
                    <ErrorBoundary>
                      {isQaOrPreProd === false && <StatusPageIO />}
                      <TestModeGlobalMessage />
                      <Content />
                      <ModalRoot />
                    </ErrorBoundary>
                  </TicketsProvider>
                </ModalProvider>
              </TestModeProvider>
            </PermissionsProvider>
          </AuthProvider>
        </PageHeaderProvider>
      </ToastProvider>
    </ThemeProvider>
  );
};
