import styled from 'styled-components';
import { LoadingSpanProps } from './LoadingSpan.types';

export const LoadingSpan = styled.div`
  border-radius: 3px;
  animation: pulsate 0.5s infinite alternate;
  background-color: ${({ theme }) => theme.colors.core.gray[600].hex};
  color: ${({ theme }) => theme.colors.core.gray[600].hex} !important;
  height: ${(props: LoadingSpanProps) => props.height};
  width: ${(props: LoadingSpanProps) => props.width};
`;

LoadingSpan.defaultProps = {
  height: '1.25rem',
  width: '5rem',
};
