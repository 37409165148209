import { useEffect, useContext, useState } from 'react';
import { Merchant } from '@fattmerchantorg/types-omni';
import {
  TestModeStore,
  AuthStore,
  deriveAuthMode,
  AuthMode,
  userSelectedMerchantOutsideMode
} from '../context';

/*
 * Determines if a merchant is a test/sandbox merchant.
 */
export function isTestMerchant(merchant: Merchant): boolean {
  const brand = merchant?.brand;
  const brandStart = brand.length - 8;

  return brand.substr(brandStart) === '-sandbox' ? true : false;
}

export function shouldSwitchMode(
  route: string,
  merchant: Merchant,
  mode: AuthMode
): boolean {
  // Route is one of the merchant specific routes.
  const isRouteOfInterest = route.substr(0, 10) === '/merchant/' ? true : false;
  let shouldToggle = false;

  if (
    isRouteOfInterest &&
    // If it's a test merchant and test mode is not enabled, then toggle.
    // Reverse applies for production mode.
    ((isTestMerchant(merchant) && mode === 'LIVE_MODE') ||
      (!isTestMerchant(merchant) && mode === 'TEST_MODE'))
  ) {
    shouldToggle = true;
  }

  return shouldToggle;
}

export function useAutomaticModeSwitch(
  path: string,
  merchant: Merchant | null
) {
  const { dispatch: dispatchTestModeAction } = useContext(TestModeStore);
  const { state, dispatch: authDispatch } = useContext(AuthStore);
  const mode = deriveAuthMode(state);

  const [lastMerchantId, setLastMerchantId] = useState('');
  const [lastMode, setLastMode] = useState<AuthMode>(null);

  useEffect(() => {
    if (merchant && merchant.id) {
      // Track these use effect dependencies to not run
      // the calculations if they haven't changed.
      setLastMerchantId(merchant.id);
      setLastMode(mode);

      // Toggle mode.
      if (
        lastMerchantId !== merchant.id && // Run only if merchant changes.
        lastMode !== mode && // Run only if current mode can go to a different mode.
        shouldSwitchMode(path, merchant, mode) // Decides if mode should be switched.
      ) {
        authDispatch(userSelectedMerchantOutsideMode());
      }
    }
  }, [
    merchant,
    dispatchTestModeAction,
    path,
    mode,
    lastMode,
    lastMerchantId,
    authDispatch
  ]);
}
