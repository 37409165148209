import React, {
  useContext,
  useState,
  FunctionComponent,
  Fragment,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import { queryapi, permissionsapi } from '../../api';
import { useAuthToken, useAsyncEffect, useToaster } from '../../hooks';
import { SelectedMerchantStore } from '../../context';
import { startOfMonth, endOfMonth, format } from 'date-fns';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import {
  Icon,
  SmallPrimaryButton,
  Text,
  Tooltip,
} from '@fattmerchantorg/truffle-components';
import { Helmet } from 'react-helmet';
import { getTimezoneName } from '../../util/date.util';
import { formatCurrency, round } from '../../util/format.util';
import { ConditionalWrapper } from '../shared/ConditionalWrapper';
import FeatureFlag from '../shared/FeatureFlag/FeatureFlag';
import { getFeeTypeNiceName } from './util/fee-statement-util';
import { DetailedFeeStatement as Statement } from '@fattmerchantorg/types-omni';

// TODO: use styled components from './components/*' when we have more time

const Header: FunctionComponent<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.lightNeutralOutline};

  h4 {
    margin: 0;
  }
`;

/**
 * To add styles to <Text>
 * Truffle has a bug where adding any props
 * besides "as" throws a TS error
 */
const TextContainer = styled.span`
  * {
    ${({ align }) => align && `text-align: ${align};`}
    ${({ paddingBottom }) =>
      paddingBottom && `padding-bottom: ${paddingBottom};`}
  }
`;

const StatementContainer: FunctionComponent<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = styled.div`
  margin: 16px 16px 76px 16px;
  color: ${props => props.theme.black};

  > * {
    margin-bottom: 24px;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0px;
    font-size: 12px;
  }

  h4 {
    font-size: 14px;
  }
`;

const SummarySection = styled.div`
  display: flex;

  span {
    font-size: 10px;
    margin: 2px 0;

    &.react-loading-skeleton {
      margin: 0;
    }
  }

  > div:first-child {
    flex-basis: 50%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-right: 24px;

    > div {
      display: flex;
      justify-content: space-between;
    }
  }

  > div:not(:first-child) {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px 24px;
    border-left: 2px solid ${props => props.theme.colors.core.gray[600].hex};

    h2 {
      font-size: 22px;
      color: ${props => props.theme.primaryColor};
    }
  }
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: column;
  }

  span {
    font-size: 10px;
  }

  h5 {
    margin-bottom: 4px;
  }

  img {
    margin: 4px 0;
    height: 32px;
  }
`;

const StatementDisclaimer = styled.div`
  color: ${props => props.theme.colors.core.white[0].hex};
  background: ${props => props.theme.colors.status.blue[500].hex};
  padding: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
`;

const StatementMessageSection = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 24px;
  color: ${props => props.theme.colors.core.white[0].hex};
  div:not(:first-child) {
    margin-top: 10px;
  }
`;

const TableSection = styled.div`
  h5 {
    margin-bottom: 8px;
  }

  table {
    width: 100%;
    table-layout: fixed;
    color: ${props => props.theme.colors.core.white[0].hex};
    tfoot,
    thead {
      display: table-row-group;
    }

    tbody {
      padding: 2px 0;
      border-top: 2px solid ${props => props.theme.colors.core.gray[600].hex};
      border-bottom: 2px solid ${props => props.theme.colors.core.gray[600].hex};
    }

    tfoot > tr > td {
      font-weight: 700;
    }

    tr {
      td,
      th {
        font-size: 10px;
        padding: 6px 0;
        font-weight: 400;
        width: auto;
        &:not(:first-child) {
          text-align: right;
        }
      }

      &:first-child > td {
        padding-top: 12px;
      }

      &:last-child > td {
        padding-bottom: 12px;
      }
    }
  }
`;

const createFillerRows = (numberOfColumns, numberOfRows = 5) => {
  return Array(numberOfRows)
    .fill(null)
    .map((_, i) => (
      <tr key={i}>
        {Array(numberOfColumns)
          .fill(null)
          .map((_, i) => (
            <td key={i}>
              <Skeleton height={10} width={50} />
            </td>
          ))}
      </tr>
    ));
};

/** The fee statements page for a selected merchant. */
export const DetailedFeeStatement = props => {
  const [brandLogoUrl, setBrandLogoUrl] = useState<string | null>(null);
  const [statement, setStatement] = useState<Statement & { ready: boolean }>({
    ready: false,
    summary: {
      flatRateFees: 0,
      interchangeFees: 0,
      cardBrandAssessments: 0,
      cardProcessingFees: 0,

      achApplicationFees: 0,
      cardApplicationFees: 0,
      otherApplicationFees: 0,

      otherFees: 0,

      totalNetDeposits: 0,
      totalFees: 0,
      effectiveRate: 0,
    },
    allFees: { data: [], summary: {} as any },
    additionalFees: { data: [], summary: {} as any },
    dateTransactions: { data: [], summary: {} as any },
    cardTransactions: { data: [], summary: {} as any },
    cardPresentTransactions: { data: [], summary: {} as any },
    cardNotPresentTransactions: { data: [], summary: {} as any },
    statementMessages: [],
    payoutConfiguration: {},
  });
  const { toaster, toast } = useToaster();
  const authToken = useAuthToken();
  const {
    state: { merchant },
  } = useContext(SelectedMerchantStore);

  const {
    params: { date: statementDate },
  } = useRouteMatch<{ date?: string }>();

  useAsyncEffect(async () => {
    setStatement(s => ({ ...s, ready: false }));
    const params = {
      timeZone: merchant?.options?.timezone || getTimezoneName(),
      startDate: format(startOfMonth(new Date(statementDate)), 'yyyy-MM-dd'),
      endDate: format(endOfMonth(new Date(statementDate)), 'yyyy-MM-dd'),
    };
    if (merchant) {
      try {
        // release / theodo nav
        const brandData = await permissionsapi.get(
          authToken,
          `brand/data/${merchant?.brand}`
        );
        setBrandLogoUrl(brandData?.darkLogo[0]?.url || null);
      } catch (e) {}
      try {
        const statementResponse = await queryapi.get(
          authToken,
          'statement/summary',
          params
        );
        setStatement({
          ready: true,
          ...statementResponse,
        });
      } catch (e) {
        toaster(
          toast.error(
            'There was a problem fetching the detailed statement.',
            'Error'
          )
        );
      }
    }
  }, [merchant, statementDate]);

  const {
    ready,
    dateTransactions,
    cardTransactions,
    cardPresentTransactions,
    cardNotPresentTransactions,
    summary,
    additionalFees,
    payoutConfiguration,
  } = statement;

  const isSurcharge =
    !!merchant?.is_surcharge_enabled ||
    !!cardTransactions?.data.find(t => t.surcharge_total > 0);

  const isGrossOrNet =
    isSurcharge || payoutConfiguration?.payout_type === 'GROSS'
      ? 'GROSS'
      : 'NET';

  let totalTooltipContent = 'Total is based on gross sales, less any fees.';
  if (isSurcharge) {
    totalTooltipContent =
      'Total is based on gross sales, less any surcharge fees charged to the customer. Fees are deducted from non-surcharged transactions separately';
  } else if (isGrossOrNet === 'GROSS') {
    totalTooltipContent =
      'Total is based on gross sales, fees are taken out separately';
  }

  return (
    <div className="-print-black">
      <Header className="d-print-none">
        <Text as="h4">Fee Statement</Text>
        <div>
          <SmallPrimaryButton
            icon={['fas', 'print']}
            onClick={window.print}
            disabled={!ready}
            trackingId="detailed-statement-print"
            data-testid="detailed-statement-print"
          >
            Print
          </SmallPrimaryButton>
        </div>
      </Header>
      <StatementContainer id="statement">
        <Helmet>
          <title>
            Statement-Report-
            {format(new Date(statementDate), 'MMM yyyy')}
          </title>
        </Helmet>
        <TopSection>
          {ready && merchant ? (
            <>
              <div>
                <Text as="span">{merchant.company_name}</Text>
                <Text as="span">
                  {`${merchant.address_1} ${merchant.address_2}`.trim()}
                </Text>
                <Text as="span">
                  {merchant.address_city} {merchant.address_state}{' '}
                  {merchant.address_zip}
                </Text>
              </div>
              <div>
                {brandLogoUrl && (
                  <img src={brandLogoUrl} alt={merchant.company_name} />
                )}
              </div>
            </>
          ) : (
            <Skeleton width={100} height={40} />
          )}
        </TopSection>

        <TopSection style={{ justifyContent: 'center' }}>
          <TextContainer align="center">
            <Text as="h4">Merchant Statement</Text>
          </TextContainer>
        </TopSection>

        <TopSection>
          <div>
            <Text as="h5">Merchant Number</Text>
            <Text as="h5">{merchant?.mid}</Text>
          </div>
          <div>
            <Text as="h5">Statement Period</Text>
            <TextContainer align="right">
              <Text as="h5">{format(new Date(statementDate), 'MMM yyyy')}</Text>
            </TextContainer>
          </div>
        </TopSection>

        {statement.statementMessages && (
          <StatementMessageSection>
            {statement.statementMessages.map(message => (
              <div>{message}</div>
            ))}
          </StatementMessageSection>
        )}
        <StatementDisclaimer>
          Fee statements are generated based on deposits that are created in a
          given fee statement month. If a deposit was created in the month, any
          transactions that are a part of that deposit will be included.
        </StatementDisclaimer>
        <div>
          <TextContainer paddingBottom="8px">
            <Text as="h5">SUMMARY</Text>
          </TextContainer>

          <SummarySection>
            <div>
              {!merchant?.is_flat_rate && (
                <div>
                  <Text as="span">INTERCHANGE FEES:</Text>
                  {ready ? (
                    <Text as="span" data-testid="summary-interchange-fees">
                      {formatCurrency(summary.interchangeFees)}
                    </Text>
                  ) : (
                    <Skeleton width={50} />
                  )}
                </div>
              )}
              <div>
                <Text as="span">CARD PROCESSING FEES:</Text>
                {ready ? (
                  <Text as="span">
                    {formatCurrency(summary.cardProcessingFees)}
                  </Text>
                ) : (
                  <Skeleton width={50} />
                )}
              </div>
              <div>
                <Text as="span">
                  {merchant?.is_flat_rate
                    ? 'FLAT RATE FEES:'
                    : 'CARD BRAND ASSESSMENTS:'}
                </Text>
                {ready ? (
                  <Text as="span">{formatCurrency(summary.flatRateFees)}</Text>
                ) : (
                  <Skeleton width={50} />
                )}
              </div>
              {merchant?.gateways.some(g =>
                ['ACH', 'TEST'].includes(g.type)
              ) && (
                <div>
                  <Text as="span">ACH FEES:</Text>

                  {ready ? (
                    <Text as="span" data-testid="summary-ach-fees">
                      {formatCurrency(summary.achApplicationFees)}
                    </Text>
                  ) : (
                    <Skeleton width={50} />
                  )}
                </div>
              )}
              <div>
                <Text as="span">ADDITIONAL FEES:</Text>
                {ready ? (
                  <Text as="span">{formatCurrency(summary.otherFees)}</Text>
                ) : (
                  <Skeleton width={50} />
                )}
              </div>
            </div>

            <div>
              <Tooltip content={totalTooltipContent}>
                <Text as="h5">
                  TOTAL{' '}
                  <Icon
                    className="d-print-none"
                    icon={['fas', 'info-circle']}
                  />
                </Text>
              </Tooltip>
              {ready ? (
                <Text as="h2" data-testid="summary-total">
                  {formatCurrency(summary.totalNetDeposits)}
                </Text>
              ) : (
                <Skeleton width={100} height={36} />
              )}
            </div>
            <div>
              <ConditionalWrapper
                condition={isSurcharge}
                wrapper={children => (
                  <Tooltip
                    content={`Total fees are inclusive of both processing and other fees (e.g. dispute fees). Processing fees are deducted separately from other fees.`}
                  >
                    {children}
                  </Tooltip>
                )}
              >
                <Text as="h5">
                  TOTAL FEES{' '}
                  {isSurcharge ? (
                    <Icon
                      className="d-print-none"
                      icon={['fas', 'info-circle']}
                    />
                  ) : null}
                </Text>
              </ConditionalWrapper>

              {ready ? (
                <Text as="h2" data-testid="summary-total-fees">
                  {formatCurrency(summary.totalFees)}
                </Text>
              ) : (
                <Skeleton width={100} height={36} />
              )}
            </div>
          </SummarySection>
        </div>

        <FeatureFlag
          category="Reports"
          feature="DetailedStatementTransactionsbyDay"
        >
          <TableSection>
            <Text as="h5">Transactions</Text>
            <table data-testid="transactions-by-day-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th># of Sales</th>
                  <th>Gross Sales</th>
                  <th>Adjustments</th>
                  <th>Chargebacks</th>
                  {isSurcharge && (
                    <th>
                      <span>Surcharge</span>
                    </th>
                  )}

                  <th>Fees</th>
                  <th>
                    <Tooltip content={totalTooltipContent}>
                      <span>
                        Total{' '}
                        <Icon
                          icon={['fas', 'info-circle']}
                          className="d-print-none"
                        />
                      </span>
                    </Tooltip>
                  </th>
                </tr>
              </thead>
              <tbody>
                {ready
                  ? dateTransactions.data.map((datum, i) => (
                      <tr key={i}>
                        <td>{format(new Date(datum.date), 'MMM dd')}</td>
                        <td>{datum.net_sales_count}</td>
                        <td>{formatCurrency(datum.gross_sales_total)}</td>
                        <td>{formatCurrency(datum.adjustments_total)}</td>
                        <td>{formatCurrency(datum.chargebacks_total)}</td>
                        {isSurcharge && (
                          <td>{formatCurrency(datum.surcharge_total)}</td>
                        )}
                        <td>{formatCurrency(datum.fees_total)}</td>
                        <td>{formatCurrency(datum.displayed_total)}</td>
                      </tr>
                    ))
                  : createFillerRows(6)}
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <strong>TOTAL</strong>
                  </td>
                  <td>{dateTransactions.summary.net_sales_count}</td>
                  <td>
                    {formatCurrency(dateTransactions.summary.gross_sales_total)}
                  </td>

                  <td>
                    {formatCurrency(dateTransactions.summary.adjustments_total)}
                  </td>
                  <td>
                    {formatCurrency(dateTransactions.summary.chargebacks_total)}
                  </td>
                  {isSurcharge && (
                    <td>
                      {formatCurrency(dateTransactions.summary.surcharge_total)}
                    </td>
                  )}
                  <td>{formatCurrency(dateTransactions.summary.fees_total)}</td>
                  <td>
                    {formatCurrency(dateTransactions.summary.displayed_total)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </TableSection>
        </FeatureFlag>

        <div>
          <TableSection>
            <Text as="h5">Volumes</Text>
            <table>
              <thead>
                <tr>
                  <th>Type</th>
                  <th># of Sales</th>
                  <th>Gross Sales</th>
                  <th># of Adjustments</th>
                  <th>Adjustments</th>
                  <th># of Chargebacks</th>
                  <th>Chargebacks</th>
                  <th>Average Ticket</th>
                  {merchant?.is_flat_rate && <th>Rate</th>}
                </tr>
              </thead>
              <tbody>
                {ready
                  ? cardTransactions.data.map((datum, i) => (
                      <tr key={i}>
                        <td>{(datum.card_type || '').toUpperCase()}</td>
                        <td>{datum.gross_sales_count}</td>
                        <td>{formatCurrency(datum.gross_sales_total)}</td>
                        <td>{datum.adjustments_count}</td>
                        <td>{formatCurrency(datum.adjustments_total)}</td>
                        <td>{datum.chargebacks_count}</td>
                        <td>{formatCurrency(datum.chargebacks_total)}</td>
                        <td>{formatCurrency(datum.gross_sale_average)}</td>
                        {merchant?.is_flat_rate && (
                          <td>{round(datum.rate).toFixed(2)}</td>
                        )}
                      </tr>
                    ))
                  : createFillerRows(merchant?.is_flat_rate ? 9 : 8)}
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <strong>TOTAL</strong>
                  </td>
                  <td>{cardTransactions.summary.gross_sales_count}</td>
                  <td>
                    {formatCurrency(cardTransactions.summary.gross_sales_total)}
                  </td>
                  <td>{cardTransactions.summary.adjustments_count}</td>
                  <td>
                    {formatCurrency(cardTransactions.summary.adjustments_total)}
                  </td>
                  <td>{cardTransactions.summary.chargebacks_count}</td>
                  <td>
                    {formatCurrency(cardTransactions.summary.chargebacks_total)}
                  </td>
                  <td>
                    {formatCurrency(
                      cardTransactions.summary.gross_sale_average
                    )}
                  </td>
                  {merchant?.is_flat_rate && <td>--</td>}
                </tr>
              </tfoot>
            </table>
          </TableSection>

          {cardPresentTransactions?.data?.length ? (
            <TableSection style={{ paddingTop: '12px' }}>
              <Text as="h5">Processing Fees - Card Present</Text>
              <table>
                <thead>
                  <tr>
                    <th>Type</th>
                    {!merchant?.is_flat_rate && <th>Interchange Fees</th>}
                    <th>Card Processing Fees</th>
                    <th>
                      {merchant?.is_flat_rate
                        ? 'Flat Rate Fees'
                        : 'Card Brand Assessments'}
                    </th>
                    <th># Trans</th>
                    <th>Rate</th>
                    <th>Total Fees</th>
                  </tr>
                </thead>
                <tbody>
                  {ready
                    ? cardPresentTransactions.data.map((datum, i) => (
                        <tr key={i}>
                          <td>{(datum.card_type || '').toUpperCase()}</td>
                          {!merchant?.is_flat_rate && (
                            <td>
                              {formatCurrency(datum.interchange_fees_total)}
                            </td>
                          )}
                          <td>{formatCurrency(datum.fixed_fees_total)}</td>
                          <td>
                            {formatCurrency(datum.basis_points_fees_total)}
                          </td>
                          <td>{datum.transactions_count}</td>
                          <td>{datum.rate}</td>
                          <td>{formatCurrency(datum.fees_total)}</td>
                        </tr>
                      ))
                    : createFillerRows(4)}
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <strong>TOTAL</strong>
                    </td>
                    {!merchant?.is_flat_rate && (
                      <td data-testid="processing-fees-interchange-fees-total">
                        {formatCurrency(
                          cardPresentTransactions.summary.interchange_fees_total
                        )}
                      </td>
                    )}
                    <td data-testid="processing-fees-fixed-fees-total">
                      {formatCurrency(
                        cardPresentTransactions.summary.fixed_fees_total
                      )}
                    </td>
                    <td data-testid="processing-fees-basis-points-fees-total">
                      {formatCurrency(
                        cardPresentTransactions.summary.basis_points_fees_total
                      )}
                    </td>
                    <td></td>
                    <td></td>
                    <td data-testid="processing-fees-fees-total">
                      {formatCurrency(
                        cardPresentTransactions.summary.fees_total
                      )}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </TableSection>
          ) : null}

          {cardNotPresentTransactions?.data?.length ? (
            <TableSection style={{ paddingTop: '12px' }}>
              <Text as="h5">Processing Fees - Card Not Present</Text>
              <table>
                <thead>
                  <tr>
                    <th>Type</th>
                    {!merchant?.is_flat_rate && <th>Interchange Fees</th>}
                    <th>Card Processing Fees</th>
                    <th>
                      {merchant?.is_flat_rate
                        ? 'Flat Rate Fees'
                        : 'Card Brand Assessments'}
                    </th>
                    <th># Trans</th>
                    <th>Rate</th>
                    <th>Total Fees</th>
                  </tr>
                </thead>
                <tbody>
                  {ready
                    ? cardNotPresentTransactions.data.map((datum, i) => (
                        <tr key={i}>
                          <td>{(datum.card_type || '').toUpperCase()}</td>
                          {!merchant?.is_flat_rate && (
                            <td>
                              {formatCurrency(datum.interchange_fees_total)}
                            </td>
                          )}
                          <td>{formatCurrency(datum.fixed_fees_total)}</td>
                          <td>
                            {formatCurrency(datum.basis_points_fees_total)}
                          </td>
                          <td>{datum.transactions_count}</td>
                          <td>{datum.rate}</td>
                          <td>{formatCurrency(datum.fees_total)}</td>
                        </tr>
                      ))
                    : createFillerRows(4)}
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <strong>TOTAL</strong>
                    </td>
                    {!merchant?.is_flat_rate && (
                      <td data-testid="processing-fees-interchange-fees-total">
                        {formatCurrency(
                          cardNotPresentTransactions.summary
                            .interchange_fees_total
                        )}
                      </td>
                    )}
                    <td data-testid="processing-fees-fixed-fees-total">
                      {formatCurrency(
                        cardNotPresentTransactions.summary.fixed_fees_total
                      )}
                    </td>
                    <td data-testid="processing-fees-basis-points-fees-total">
                      {formatCurrency(
                        cardNotPresentTransactions.summary
                          .basis_points_fees_total
                      )}
                    </td>
                    <td></td>
                    <td></td>
                    <td data-testid="processing-fees-fees-total">
                      {formatCurrency(
                        cardNotPresentTransactions.summary.fees_total
                      )}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </TableSection>
          ) : null}

          {isSurcharge && cardTransactions?.summary?.surcharge_count ? (
            <TableSection style={{ paddingTop: '12px' }}>
              <Text as="h5">Surcharge</Text>
              <table data-testid="surcharge-table">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th># of Sales</th>
                    <th>Surcharge Total</th>
                  </tr>
                </thead>
                <tbody>
                  {ready
                    ? cardTransactions.data
                        // we aren't interested in the row for this table if there are no surcharging fees
                        .filter(datum => datum.surcharge_count > 0)
                        .map((datum, i) => (
                          <tr key={i}>
                            <td>{(datum.card_type || '').toUpperCase()}</td>
                            <td>{datum.surcharge_count}</td>
                            <td>{formatCurrency(datum.surcharge_total)}</td>
                          </tr>
                        ))
                    : createFillerRows(4)}
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <strong>TOTAL</strong>
                    </td>
                    <td>{cardTransactions.summary.surcharge_count}</td>
                    <td>
                      {formatCurrency(cardTransactions.summary.surcharge_total)}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </TableSection>
          ) : null}
        </div>

        <TableSection>
          <Text as="h5">Additional Fees</Text>
          <table data-testid="additional-fees-table">
            <thead>
              <tr>
                <th>Type</th>
                <th>Fee</th>
                <th>Count</th>
                <th>Amount</th>
              </tr>
            </thead>
            {ready && additionalFees.data.length ? (
              <Fragment>
                <tbody>
                  {additionalFees.data.map((datum, i) => (
                    <tr key={i}>
                      <td>{datum.name || getFeeTypeNiceName(datum.type)}</td>
                      <td>{formatCurrency(datum.average)}</td>
                      <td>{datum.count}</td>
                      <td>{formatCurrency(datum.sum)}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <strong>TOTAL</strong>
                    </td>
                    <td>-</td>
                    <td>{additionalFees.summary.count}</td>
                    <td>{formatCurrency(summary.otherFees)}</td>
                  </tr>
                </tfoot>
              </Fragment>
            ) : (
              <Fragment>
                <tbody>
                  {ready ? (
                    <tr>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                  ) : (
                    createFillerRows(4)
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <strong>TOTAL</strong>
                    </td>
                    <td>-</td>
                    <td>0</td>
                    <td>{formatCurrency(0)}</td>
                  </tr>
                </tfoot>
              </Fragment>
            )}
          </table>
        </TableSection>
      </StatementContainer>
    </div>
  );
};
