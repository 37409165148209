import styled from 'styled-components';

export const FormDivider = styled.hr`
  border: none;
  border-bottom: 2px solid #d0d9e0;

  margin: 2rem auto 1rem auto;

  @media (max-width: 1000px) {
    margin: 1rem auto 1rem auto;
  }
`;
