import { RegistrationAuditLog } from '@fattmerchantorg/types-omni';
import groupBy from 'lodash/groupBy';
import { ReportStatus } from './ExternalVerification.types';

/**
 *
 * Verifications are run individually with their own timestamps, but we need to
 * "aggregate" the reports for display in the AuditLog table. So, until we can
 * safely change the way this is structured on the backend, this function groups
 * the results by reports that happened within the same minute.
 */
export const groupReportsByCreatedAtDate = (
  reports: RegistrationAuditLog[]
): { [date: string]: RegistrationAuditLog[] } => {
  return groupBy(reports, report => {
    const dateWithoutSeconds = new Date(report.createdAt);
    dateWithoutSeconds.setSeconds(0);
    return dateWithoutSeconds;
  });
};

export const groupReportsById = (
  reports: RegistrationAuditLog[]
): { [date: string]: RegistrationAuditLog[] } => {
  return groupBy(reports, report => {
    return report.id;
  });
};

export const mapTinNameCodeResult = (code: string): ReportStatus => {
  switch (code) {
    case '0':
    case '5':
    case '13':
      return 'failure';
    case '1':
    case '6':
    case '7':
    case '8':
      return 'success';
    default:
      return 'failure';
  }
};

export const mapDmfCodeColor = (code: string): ReportStatus => {
  switch (code) {
    case '1':
      return 'failure';
    case '0':
      return 'success';
    default:
      return 'failure';
  }
};
