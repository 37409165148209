import React from 'react';
import {
  FunctionComponent,
  useContext,
  useState,
  useEffect,
  MouseEvent,
} from 'react';
import styled from 'styled-components';
import { valueOrHyphen, showOnlyLastFour } from '../../../../util/string.util';
import { SelectedMerchantStore } from '../../../../context';
import { BankingInfoFormModal } from './BankingInfoFormModal';
import { ModalContext } from '../../../../context';
import { UnderwritingSection } from '../shared/UnderwritingSection';
import { Tabs, StatusPill } from '@fattmerchantorg/truffle-components';
import {
  useAsyncEffect,
  useAuthToken,
  usePermissions,
  useToaster,
} from '../../../../hooks';
import { onboardingapi } from '../../../../api';
import { BankAccounts } from './BankingInfoForm.types';

const BankingInfoContent = styled.div`
  width: 100%;
  > div {
    display: flex;
    flex-direction: column;
    > div {
      // Primary / Secondary Bank Info
      display: flex;
      flex-direction: column;
      width: 100%;
      text-align: left;
      padding: 0 0 0 8px;

      > p {
        line-height: 20px;
        display: flex;
        justify-content: space-between;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;
const BankingHolderInfo = styled.div`
  line-height: 20px;
  border-bottom: 1px solid #435e70;
  > p {
    padding: 8px 0;
  }
`;
const BankingContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const BankingInfo2colsContent = styled.div`
  padding: 8px !important;
`;
const BankingInfo2cols = styled.div`
  display: flex;
  flex-direction: row !important;
  width: 100%;
`;
const BankingInfo50 = styled.div`
  width: 50%;
  justify-content: space-between;
  padding-right: 8px;
  > span {
    display: flex;
    justify-content: space-between;
  }
  &:first-child {
    border-right: 1px solid #435e70;
  }
  &:last-child {
    padding-left: 8px;
  }
`;
const BorderBottomWhite = styled.div`
  border-bottom: 2px solid;
  width: 100%;
`;
const TabContent = styled.div`
  display: flex;
`;
const StatusWrapper = styled(BorderBottomWhite)`
  display: flex;
  justify-content: flex-end;
  align-content: end;
  align-items: center;
  span {
    font-size: 12px;
  }
`;

export interface BankingInfoProps {}

export const BankingInfo: FunctionComponent = props => {
  const { modalDispatch } = useContext(ModalContext);
  const { permit } = usePermissions();
  const allowViewValue = permit('godview', 'pii', 'write');
  const {
    dispatch,
    state: { merchantId, registration },
  } = useContext(SelectedMerchantStore);
  const authToken = useAuthToken();
  const { toaster, toast } = useToaster();

  const [regState, setRegState] = useState(registration);
  const [viewAccount, setViewAccount] = useState('primary');
  const [mId, setmId] = useState(merchantId);
  const [plaidAccounts, setPlaidAccounts] = useState<null | {
    primary: boolean;
    secondary: boolean;
  }>(null);

  useEffect(() => {
    setRegState(registration);
    setmId(merchantId);
  }, [registration, merchantId]);

  useAsyncEffect(async () => {
    try {
      const response = await onboardingapi.get<{ accounts: BankAccounts[] }>(
        authToken,
        `/banking/accounts`
      );
      let primary = false;
      let secondary = false;
      for (const { account_type } of response?.accounts ?? []) {
        if (account_type === 'primary') {
          primary = true;
        }
        if (account_type === 'secondary') {
          secondary = true;
        }
      }
      setPlaidAccounts({ primary, secondary });
    } catch (error) {
      toaster(toast.error(error, 'Could not fetch plaid accounts'));
    }
  }, [authToken]);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        component: BankingInfoFormModal,
        props: {
          isOpen: true,
          registration: regState,
          merchantId: mId,
          merchantDispatch: dispatch,
        },
      },
    });
  };

  return (
    <UnderwritingSection
      title="Banking Info"
      id="underwriting-banking-info"
      isEditable={true}
      onEdit={handleClick}
    >
      <BankingContent>
        <TabContent>
          <Tabs
            options={[
              {
                label: 'PRIMARY',
                value: 'primary',
              },
              {
                label: 'SECONDARY',
                value: 'secondary',
              },
            ]}
            defaultSelected={viewAccount}
            onChange={(e, option: { label; value }) =>
              setViewAccount(option.value)
            }
          />
          <BorderBottomWhite />
          {plaidAccounts?.[viewAccount] && (
            <StatusWrapper data-testid={'plaid-account-status'}>
              <StatusPill
                status={'success'}
                label={'Plaid'}
                icon={['fas', 'check']}
              />
            </StatusWrapper>
          )}
        </TabContent>
        <BankingInfoContent id="bic">
          <div>
            {(viewAccount === 'primary' && (
              <>
                <BankingHolderInfo>
                  <p>
                    <span>Account Holder</span>
                    <span data-testid="uw-bank-owner-name">
                      {valueOrHyphen(registration?.bank_account_owner_name)}
                    </span>
                  </p>
                </BankingHolderInfo>
                <BankingInfo2colsContent id="2colscontent">
                  <BankingInfo2cols id="2rows">
                    <BankingInfo50>
                      <span>
                        <span>Routing Number</span>
                        <span data-testid="uw-bank-routing-number">
                          {valueOrHyphen(registration?.bank_routing_number)}
                        </span>
                      </span>
                      <span>
                        <span>Account Number</span>
                        <span data-testid="uw-bank-account-number">
                          {valueOrHyphen(
                            showOnlyLastFour(
                              registration?.bank_account_number,
                              allowViewValue
                            )
                          )}
                        </span>
                      </span>
                    </BankingInfo50>
                    <BankingInfo50>
                      <span>
                        <span>Account Purpose</span>
                        <span data-testid="uw-bank-account-purpose">
                          {valueOrHyphen(registration?.bank_account_type)}
                        </span>
                      </span>
                    </BankingInfo50>
                  </BankingInfo2cols>
                </BankingInfo2colsContent>
              </>
            )) || (
              <>
                <BankingHolderInfo>
                  <p>
                    <span>Account Holder</span>
                    <span data-testid="uw-secondary-bank-owner-name">
                      {valueOrHyphen(
                        registration?.secondary_bank_account_owner_name
                      )}
                    </span>
                  </p>
                </BankingHolderInfo>
                <BankingInfo2colsContent>
                  <BankingInfo2cols id="2rows">
                    <BankingInfo50>
                      <span>
                        <span>Routing Number</span>
                        <span data-testid="uw-secondary-bank-routing-number">
                          {valueOrHyphen(
                            registration?.secondary_bank_routing_number
                          )}
                        </span>
                      </span>
                      <span>
                        <span>Account Number</span>
                        <span data-testid="uw-secondary-bank-account-number">
                          {valueOrHyphen(
                            showOnlyLastFour(
                              registration?.secondary_bank_account_number,
                              allowViewValue
                            )
                          )}
                        </span>
                      </span>
                    </BankingInfo50>
                    <BankingInfo50>
                      <span>
                        <span>Account Type</span>
                        <span data-testid="uw-secondary-bank-account-type">
                          {valueOrHyphen(
                            registration?.secondary_bank_account_type
                          )}
                        </span>
                      </span>
                      <span>
                        <span>Account Purpose</span>
                        <span data-testid="uw-secondary-bank-account-purpose">
                          {valueOrHyphen(
                            registration?.secondary_bank_account_purpose
                          )}
                        </span>
                      </span>
                    </BankingInfo50>
                  </BankingInfo2cols>
                </BankingInfo2colsContent>
              </>
            )}
          </div>
        </BankingInfoContent>
      </BankingContent>
    </UnderwritingSection>
  );
};
