export function downloadData(data: any, filename: string, mime: string) {
  const dataUri = `${mime};base64,${data}`;
  // Use a blob instead of using the URI in the href property of the anchor tag
  // Chrome has a 2MB URI size limit
  const blob = convertDataUriToBlob(dataUri, mime);
  const url = URL.createObjectURL(blob);
  const element = document.createElement('a');
  element.href = url;

  element.setAttribute('download', filename);

  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function convertDataUriToBlob(dataUri: string, mime: string) {
  const binStr = atob(dataUri.split(',')[1]);
  const len = binStr.length;
  const arr = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i);
  }

  return new Blob([arr], { type: mime });
}
