import react, {
  useReducer,
  Reducer,
  useEffect,
  useContext,
  useRef,
  MutableRefObject,
} from 'react';
import axios, { CancelTokenSource } from 'axios';
import { produce } from 'immer';
import { AsyncDataNoNull, AsyncDataStatus } from '../../../@types';
import { queryapi, StatisticsRouteResponse } from '../../../api';
import { useAuthToken, usePrevious, useToaster } from '../../../hooks';
import { ActionTypes, TimeRange } from '../@types/TimeRange';
import { Action, sendFetchFailed, sendFetchSucceeded } from './actions';
import { TimespanQueryParams } from '../@types/TimespanQueryParams';
import { DataSet } from '../@types/DataSet';
import { getTimezoneName } from '../../../util/date.util';
import { generateCancelSource } from '../../../util/api.util';
import {
  setIntervalAndIsPartialParams,
  setTimespanAndGroupByParams,
} from '../util/timerange.util';
import { SelectedMerchantStore } from '../../../context';

export type GrossSalesData = AsyncDataNoNull<{
  /** The gross sales data for the currently-selected timerange */
  selectedTimeRange: StatisticsRouteResponse;
  /**
   * The gross sales data for the comparative timerange. For example, if the
   * selected timerange is data for "This Year", then the comparative timerange
   * is the data for the previous year.
   */
  comparativeTimeRange: StatisticsRouteResponse;
}>;
type ReducerState = {
  selectedTimeRange: TimeRange | null;
  shouldSkipCacheOnce: boolean;
  selectedDataSet: DataSet;
  grossSales: GrossSalesData;
};
type Dispatch<Action> = react.Dispatch<Action>;

const initialState: ReducerState = {
  selectedTimeRange: null,
  shouldSkipCacheOnce: false,
  selectedDataSet: 'API',
  grossSales: {
    status: AsyncDataStatus.INITIAL,
    data: {
      selectedTimeRange: { data: [] },
      comparativeTimeRange: { data: [] },
    },
    error: null,
  },
};
/** PrevStatus will be `undefined` during the first render. */
type PrevStatus = AsyncDataStatus | undefined;

async function fetchGrossSalesData(
  authToken: string,
  timerange: TimeRange,
  noCache: boolean,
  dataSet: DataSet,
  dispatch: Dispatch<Action>,
  brands: string,
  brandsRef: MutableRefObject<String | null>,
  cancelSourceRef: MutableRefObject<CancelTokenSource>
) {
  try {
    const { selectedTimeRangeParams, comparativeTimeRangeParams } =
      calculateQueryParams(timerange);
    const newCancelSource = generateCancelSource();
    const url =
      dataSet === 'ALL'
        ? 'statistics/grossSalesByBrandAggregated'
        : 'statistics/grossSalesByBrand';

    brandsRef.current = brands;
    cancelSourceRef.current = newCancelSource;

    const selectedTimeRange: StatisticsRouteResponse = await queryapi.get(
      authToken,
      url,
      // fattquery will skip the cache if noCache is anything but null
      { ...selectedTimeRangeParams, brands, noCache: noCache || null },
      undefined,
      undefined,
      newCancelSource
    );

    const comparativeTimeRange: StatisticsRouteResponse = await queryapi.get(
      authToken,
      url,
      // fattquery will skip the cache if noCache is anything but null
      { ...comparativeTimeRangeParams, brands, noCache: noCache || null },
      undefined,
      undefined,
      newCancelSource
    );

    dispatch(sendFetchSucceeded({ selectedTimeRange, comparativeTimeRange }));
  } catch (error) {
    if (!axios.isCancel(error)) {
      dispatch(sendFetchFailed(error));
    }
  }
}

type TransactionChartReducer = Reducer<ReducerState, Action>;
/**
 * Reducer for the custom useTransactionChartHook.
 * This reducer is written in finite-state-machine style:
 * If the current status accepts the current action, transition to a new status.
 * https://twitter.com/DavidKPiano/status/1171062893984526336
 */
const reducer: Reducer<ReducerState, Action> = (state, action) => {
  // Use immer's produce function to simplify state updates
  return produce<ReducerState>(state, draftState => {
    switch (state.grossSales.status) {
      case AsyncDataStatus.IDLE:
        switch (action.type) {
          case ActionTypes.USER_SELECTED_TIMESPAN: {
            draftState.grossSales.status = AsyncDataStatus.LOADING;
            draftState.selectedTimeRange = action.payload;
            return;
          }
          case ActionTypes.USER_REQUESTED_NO_CACHE_REFRESH: {
            draftState.grossSales.status = AsyncDataStatus.LOADING;
            draftState.shouldSkipCacheOnce = true;
            return;
          }
          case ActionTypes.USER_SELECTED_DATA_SET: {
            draftState.grossSales.status = AsyncDataStatus.LOADING;
            draftState.selectedDataSet = action.payload;
            return;
          }
          default:
            return;
        }

      case AsyncDataStatus.INITIAL:
        switch (action.type) {
          case ActionTypes.USER_SELECTED_TIMESPAN: {
            draftState.grossSales.status = AsyncDataStatus.LOADING;
            draftState.selectedTimeRange = action.payload;
            return;
          }
          default:
            return;
        }

      case AsyncDataStatus.ERROR:
        switch (action.type) {
          case ActionTypes.USER_SELECTED_TIMESPAN:
            draftState.grossSales.error = null;
            draftState.grossSales.status = AsyncDataStatus.LOADING;
            draftState.selectedTimeRange = action.payload;
            return;
          default:
            return state;
        }

      case AsyncDataStatus.LOADING:
        switch (action.type) {
          case ActionTypes.FETCH_SUCCEEDED:
            draftState.grossSales.status = AsyncDataStatus.IDLE;
            draftState.grossSales.data = action.payload;
            draftState.shouldSkipCacheOnce = false;
            draftState.grossSales.error = null;
            return;

          case ActionTypes.FETCH_FAILED:
            draftState.grossSales.status = AsyncDataStatus.ERROR;
            draftState.shouldSkipCacheOnce = false;
            draftState.grossSales.error = action.payload;
            return;
          default:
            return state;
        }

      default:
        return state;
    }
  });
};

export function useTransactionChartHook(): [ReducerState, Dispatch<Action>] {
  const [state, dispatch] = useReducer<TransactionChartReducer>(
    reducer,
    initialState
  );
  const prevGrossSalesStatus: PrevStatus = usePrevious<AsyncDataStatus>(
    state.grossSales.status
  );
  const brandsRef = useRef<String>(null); // Track last selected brand(s)
  const cancelSourceRef = useRef<CancelTokenSource>(generateCancelSource()); // Track axios cancel source of each request
  const authToken = useAuthToken();
  const { toast, toaster } = useToaster();

  // Get "global brand" in context.
  const {
    state: { selectedBrandSwitcherOption },
  } = useContext(SelectedMerchantStore);

  /*
   * Perform side-effects according to status transitions.
   */
  useEffect(() => {
    // Compare current status with previous status to ensure side-effects are
    // only performed when status transition occurs.
    if (
      prevGrossSalesStatus !== AsyncDataStatus.LOADING &&
      state.grossSales.status === AsyncDataStatus.LOADING &&
      // Extra check in case `Brand Switcher` (powered by Permissions API) runs after this on first render.
      selectedBrandSwitcherOption !== null
    ) {
      if (!state.selectedTimeRange) {
        // This shouldn't ever happen, but if we end up in LOADING status and
        // there's no timerange, go to ERROR status
        dispatch(sendFetchFailed(new Error('No timerange selected.')));
        return;
      }
      fetchGrossSalesData(
        authToken,
        state.selectedTimeRange,
        state.shouldSkipCacheOnce,
        state.selectedDataSet,
        dispatch,
        selectedBrandSwitcherOption,
        brandsRef,
        cancelSourceRef
      );
    } else if (
      state.grossSales.status === AsyncDataStatus.LOADING &&
      selectedBrandSwitcherOption !== null &&
      brandsRef.current !== selectedBrandSwitcherOption
    ) {
      // Cancel the request and re-fetch, the user switched the brand dropdown
      // before the previous data could load.
      cancelSourceRef.current.cancel();
      fetchGrossSalesData(
        authToken,
        state.selectedTimeRange,
        state.shouldSkipCacheOnce,
        state.selectedDataSet,
        dispatch,
        selectedBrandSwitcherOption,
        brandsRef,
        cancelSourceRef
      );
    }
  }, [
    prevGrossSalesStatus,
    state.grossSales.status,
    state.shouldSkipCacheOnce,
    state.selectedDataSet,
    dispatch,
    authToken,
    state.selectedTimeRange,
    selectedBrandSwitcherOption,
  ]);

  useEffect(() => {
    if (
      prevGrossSalesStatus !== AsyncDataStatus.ERROR &&
      state.grossSales.status === AsyncDataStatus.ERROR
    ) {
      toaster(
        toast.error(
          state.grossSales.error,
          'There was a problem retrieving the transaction.'
        )
      );
    }
  }, [
    prevGrossSalesStatus,
    state.grossSales.status,
    state.grossSales.error,
    toast,
    toaster,
  ]);

  return [state, dispatch];
}

/**
 * Given a timerange, calculates the URL query parameters needed for a request
 * for fattquery service.
 * @returns The URL query parameters for the selected timerange and also the
 * comparative timerange.
 */
function calculateQueryParams(timerange: TimeRange): {
  selectedTimeRangeParams: TimespanQueryParams;
  comparativeTimeRangeParams: TimespanQueryParams;
} {
  const timezone = getTimezoneName();
  const selectedTimeRangeParams: TimespanQueryParams = {
    ...setTimespanAndGroupByParams(timerange),
    ...setIntervalAndIsPartialParams(timerange),
    timezone,
  };

  const comparativeTimeRangeParams: TimespanQueryParams = {
    /* The comparative timerange should have the same timespan as the
     * selected timerange. We need to compare year to year, week to week, etc.
     */
    timespan: selectedTimeRangeParams.timespan,
    /* The comparative timerange should always be one interval greater than the
     * selected timerange. E.g. 'LAST_YEAR' has interval === 1, so the
     * comparative year would be interval === 2.
     */
    interval: selectedTimeRangeParams.interval + 1,

    /* The comparative timerange should have the same "partial" value as the
     * selected timerange. E.g. 'THIS_MONTH' has isPartial === 1, so the
     * comparative previous month should also be partial.
     */
    isPartial: selectedTimeRangeParams.isPartial,

    /* The comparative timerange should have the same "groupBy" value as the
     * selected timerange. E.g. 'TODAY' is grouped by `date_hour`, so the
     * comparative previous day should also be grouped by hours.
     */
    groupBy: selectedTimeRangeParams.groupBy,

    timezone,
  };

  return { selectedTimeRangeParams, comparativeTimeRangeParams };
}
