import React, { FunctionComponent, useMemo } from 'react';
import { Form } from 'react-final-form';
import styled, { withTheme } from 'styled-components';
import {
  PrimaryButton,
  SecondaryButton,
  SmallSecondaryButton,
  ModalContent,
  ModalFooter,
} from '@fattmerchantorg/truffle-components';
import { FundingAccount, Registration } from '@fattmerchantorg/types-omni';
import { BankAccountFormFields } from './BankAccountFormFields';
import { BankAccountFormValues } from './BankAccountForm.types';
import {
  mapBankAccountToInitialFormValues,
  mapFormValuesToPayload,
  validateForm,
} from './BankAccountForm.util';
import { BankAccountFiles } from '../../BankAccountFiles';
import { WarningAlert } from './WarningAlert';

interface BankFormProps {
  account?: FundingAccount;
  registration: Registration | null;
  numberOfActiveFeeAccounts?: number;

  onCancel?: () => void;
  onSubmit?: (formValues: BankAccountFormValues) => void;
  onDeactivate?: (formValues: BankAccountFormValues) => void;
  /** Function to fire after file(s) are uploaded. Must be memoized */
  onUploadFile?: () => void;
}

const Container = withTheme(styled.div`
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`);

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;

  > *:not(:first-child) {
    margin-left: 16px;
  }
`;

const StyledModalFooter = styled(ModalFooter)`
  flex-direction: column;
  align-items: flex-end;
`;

/** TODO: replace with truffle component once it exists */
const SmallSecondaryDestructiveButton: typeof SmallSecondaryButton = styled(
  SmallSecondaryButton
)`
  color: ${({ theme }) => theme.colors.status.red[500].hex};
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.status.red[500].hex};
  background: none;

  &:hover,
  &:active {
    color: ${({ theme }) => theme.black};
    box-shadow: inset 0 0 0 1px
      ${({ theme }) => theme.colors.status.red[500].hex};
    background: ${({ theme }) => theme.colors.status.red[100].hex};
  }
`;

const AlertText = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.black};
`;

export const BankAccountForm: FunctionComponent<BankFormProps> = props => {
  const {
    account,
    registration,
    onCancel,
    onSubmit,
    onDeactivate,
    onUploadFile,
    numberOfActiveFeeAccounts,
  } = props;

  const accountId = account?.id;

  const initialValues = useMemo(
    () => mapBankAccountToInitialFormValues(account),
    [account]
  );

  return (
    <Form<BankAccountFormValues>
      onSubmit={formValues => onSubmit(mapFormValuesToPayload(formValues))}
      initialValues={initialValues}
      validate={validateForm}
      render={formProps => {
        return (
          <>
            <ModalContent>
              <form
                id={`${accountId || 'New'}BankAccountForm`}
                onSubmit={formProps.handleSubmit}
              >
                <Container>
                  <BankAccountFormFields
                    registration={registration}
                    account={account}
                    numberOfActiveFeeAccounts={numberOfActiveFeeAccounts}
                    {...formProps}
                  />

                  <div>
                    {accountId && onDeactivate && formProps.values.active ? (
                      <SmallSecondaryDestructiveButton
                        id={`${accountId}-bank-form-deactivate-button`}
                        data-test={`${accountId}-bank-form-deactivate-button`}
                        type="button"
                        onClick={() => onDeactivate(formProps.values)}
                        disabled={
                          account?.flags?.includes('PRIMARY') ||
                          (account?.flags?.includes('FEE') &&
                            numberOfActiveFeeAccounts === 1)
                        }
                      >
                        Deactivate Account
                      </SmallSecondaryDestructiveButton>
                    ) : null}
                  </div>

                  {/*Only show warnings on active accounts*/}
                  {accountId && formProps.values.active ? (
                    <>
                      {account?.flags?.includes('PRIMARY') ? (
                        account?.flags?.includes('FEE') &&
                        numberOfActiveFeeAccounts === 1 ? (
                          <WarningAlert>
                            <AlertText>
                              To <strong>deactivate this account</strong> please
                              choose another bank account to act as your{' '}
                              <strong>Primary Account</strong> and another bank
                              account to act as your{' '}
                              <strong>Deposit & Fees Account</strong>. This can
                              be the same account
                            </AlertText>
                          </WarningAlert>
                        ) : (
                          <WarningAlert>
                            <AlertText>
                              To <strong>deactivate this account</strong> please
                              choose another bank account to act as your{' '}
                              <strong>Primary Account</strong>.
                            </AlertText>
                          </WarningAlert>
                        )
                      ) : null}

                      {account?.flags?.includes('FEE') &&
                      !account?.flags?.includes('PRIMARY') &&
                      numberOfActiveFeeAccounts === 1 ? (
                        <>
                          <WarningAlert>
                            <AlertText>
                              To <strong>deactivate this account </strong>please
                              choose another bank account to act as your{' '}
                              <strong>Deposit & Fees Account.</strong>
                            </AlertText>
                          </WarningAlert>
                        </>
                      ) : null}

                      {account?.flags?.includes('IS_TRUST') ? (
                        <WarningAlert>
                          <AlertText>
                            This account {`can\'t`} be set as your{' '}
                            <strong>Primary Account</strong> because{` it\'s `}a{' '}
                            <strong>Trust Account</strong>.
                          </AlertText>
                        </WarningAlert>
                      ) : null}
                    </>
                  ) : null}

                  {accountId && formProps.values.status === 'PENDED' ? (
                    <>
                      <WarningAlert>
                        {account?.files?.length >= 2 ? (
                          <AlertText>
                            Awaiting Approval{' '}
                            {
                              // TODO: Add Learn More link once created.
                              // eslint-disable-next-line
                            }
                            <a style={{ textDecoration: 'underline' }}>
                              Learn More
                            </a>
                            .
                          </AlertText>
                        ) : (
                          <AlertText>
                            Please upload a Voided Check or Signed Bank Letter.
                          </AlertText>
                        )}
                      </WarningAlert>
                      <BankAccountFiles
                        accountId={account?.id}
                        accountFiles={account?.files}
                        onUpload={onUploadFile}
                      />
                    </>
                  ) : null}
                </Container>
              </form>
            </ModalContent>

            <StyledModalFooter>
              <ButtonGroup>
                <SecondaryButton
                  id={`${accountId || 'new'}-bank-form-cancel-button`}
                  data-test={`${accountId || 'new'}-bank-form-cancel-button`}
                  type="button"
                  onClick={onCancel}
                >
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  id={`${accountId || 'new'}-bank-form-submit-button`}
                  data-test={`${accountId || 'new'}-bank-form-submit-button`}
                  type="submit"
                  disabled={formProps.invalid}
                  form={`${accountId || 'New'}BankAccountForm`}
                >
                  {accountId ? 'Save' : 'Add Account'}
                </PrimaryButton>
              </ButtonGroup>
            </StyledModalFooter>
          </>
        );
      }}
    />
  );
};

export default BankAccountForm;
