import React from 'react';

export default reportObj => {
  return (
    <div>
      <strong>Consumer Alert Messages</strong>
      {reportObj.map((message, index) => (
        <ul key={`${message.Code}-${index}`}>
          <li>
            <strong>Code:</strong> {message.Code}
          </li>
          <li>
            <strong>Description:</strong> {message.Description}
          </li>
        </ul>
      ))}
    </div>
  );
};
