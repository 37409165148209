import { AxiosRequestConfig, AxiosResponse } from 'axios';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import { FileRecord } from '@fattmerchantorg/types-omni';
import { Import } from '../../../../@types';
import styled, { withTheme } from 'styled-components';
import { importerapi } from '../../../../api';
import { ImportStore, updateImport } from '../../../../context';
import {
  useAuthToken,
  useRouteMatchMerchantId,
  useToaster,
} from '../../../../hooks';
import { getHeaders } from '../../../../util/api.util';
import { Upload } from '../../../shared';
import { ImporterButtonBar } from './ImporterButtonBar';

const StyledUpload = withTheme(
  styled(Upload)`
    background: ${({ theme }) => theme.colors.core.gray[800].hex};
    border-radius: 2px;
  `
);

export const ImporterUpload: FunctionComponent = () => {
  const {
    dispatch,
    state: { record },
  } = useContext(ImportStore);
  const authToken = useAuthToken();
  const merchantId = useRouteMatchMerchantId();
  const { toast, toaster } = useToaster();
  const history = useHistory();

  // map import record to file schema
  // so we can use the Files component
  const file = record
    ? {
        id: record.id,
        name: record.filename,
        size: record.meta?.filesize_bytes,
      }
    : null;

  const handleDelete = useCallback(
    // the 'file' here is actually the Import record
    async (file: FileRecord) => {
      try {
        const record = file as any as Import;
        await importerapi.delete(authToken, `/import/${record.id}`);

        dispatch(updateImport(null));

        history.push(`/merchant/${merchantId}/imports/new/upload`);
      } catch (error) {
        toaster(toast.error(error, 'There was a problem deleting the file.'));
      }
    },
    [authToken, merchantId, dispatch, toast, toaster, history]
  );

  const handleUploaded = useCallback(
    (res: AxiosResponse<Import>) => {
      const record = res.data;
      dispatch(updateImport(record));
      history.push(`/merchant/${merchantId}/imports/${record.id}/upload`);
    },
    [merchantId, dispatch, history]
  );

  const handleAlertAboutInvalidFiles = useCallback(
    (file: File) => {
      toaster(
        toast.error(
          `File has unsupported filetype: ${file.name}`,
          'There was a problem uploading the file.'
        )
      );
    },
    [toast, toaster]
  );

  const uploadConfig = useMemo((): AxiosRequestConfig => {
    return {
      method: 'post',
      url: `${importerapi.baseUrl}/merchant/${merchantId}/import`,
      headers: getHeaders(authToken),
    };
  }, [authToken, merchantId]);

  return (
    <>
      <StyledUpload
        accept={['pgp', 'gpg', 'text/csv', 'application/json']}
        file={file}
        uploadConfig={uploadConfig}
        onDelete={handleDelete}
        onInvalid={handleAlertAboutInvalidFiles}
        onFinish={handleUploaded}
        onError={error => {
          toaster(
            toast.error(error, 'There was a problem uploading the file.')
          );
        }}
      />
      <ImporterButtonBar
        backButtonProps={{ hidden: true }}
        onContinue={() =>
          history.push(`/merchant/${merchantId}/imports/${record?.id}/match`)
        }
      />
    </>
  );
};
