import { Icon, SmallPrimaryButton } from '@fattmerchantorg/truffle-components';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { permissionsapi } from '../../../api';
import { useAuthToken, usePermissions, useToaster } from '../../../hooks';
import {
  CenterContainer,
  MainContent,
  PricingHeader,
  SettingsHeaderBreadcrumbs,
  SettingsHeaderContent,
  SettingsHeaderContentActions,
  SettingsHeaderContentTitle,
  SettingsHeaderContentTitleText,
  SettingsHeaderRow,
  SettingsSubheader,
} from '../SettingsPagesStylings';
import { RolesTable } from './RolesTable';
import { SelectedMerchantStore } from '../../../context';
import { AddRoleModal } from './AddRoleModal';
import { RoleDetails, RoleDetailsResponse } from './RoleDetails';

const rolesRoute = '/settings/roles';
const newRoleRoute = '/settings/roles/new';

export const RolesList = () => {
  const { permit } = usePermissions();
  const history = useHistory();
  const { pathname } = useLocation();

  const authToken = useAuthToken();
  const [roles, setRoles] = useState<RoleDetails[]>([]);
  const {
    state: { selectedBrandSwitcherOption },
  } = useContext(SelectedMerchantStore);

  const [isLoading, setIsLoading] = useState(true);
  const { toast, toaster } = useToaster();
  const canWrite = permit('godview', 'permissions', 'write');

  const generateLoadingRows = () => {
    return Array(5).fill({});
  };

  const fetchRoles = useCallback(async () => {
    setIsLoading(true);

    try {
      const result = await permissionsapi.get<RoleDetailsResponse[]>(
        authToken,
        `/roles/${selectedBrandSwitcherOption}`
      );
      setRoles(
        (result ?? []).map(details => ({
          ...details.role,
          user_count: details.user_count,
        }))
      );
    } catch (error) {
      toaster(
        toast.error(error, 'There was a problem fetching the roles plans.')
      );
    } finally {
      setIsLoading(false);
    }
  }, [authToken, toast, toaster, selectedBrandSwitcherOption]);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  const handleCreateNew = () => {
    history.push(newRoleRoute);
  };

  const closeNewRoleModal = () => {
    history.push(rolesRoute);
  };

  return (
    <>
      <AddRoleModal
        isOpen={pathname === newRoleRoute}
        onClose={closeNewRoleModal}
        fetchRoles={fetchRoles}
      />

      <SettingsHeaderContent>
        <SettingsHeaderRow>
          <SettingsHeaderContentTitle>
            <SettingsHeaderContentTitleText>
              Roles
            </SettingsHeaderContentTitleText>
          </SettingsHeaderContentTitle>
          {canWrite && (
            <SettingsHeaderContentActions>
              <SmallPrimaryButton type="button" onClick={handleCreateNew}>
                <Icon icon={['fas', 'plus']} /> Add Role
              </SmallPrimaryButton>
            </SettingsHeaderContentActions>
          )}
        </SettingsHeaderRow>
        <SettingsHeaderRow>
          <SettingsHeaderContentTitle>
            <SettingsSubheader>
              Create, view, search or edit user roles.
            </SettingsSubheader>
          </SettingsHeaderContentTitle>
        </SettingsHeaderRow>
      </SettingsHeaderContent>

      <MainContent>
        {isLoading ? (
          <RolesTable roles={generateLoadingRows()} isLoading />
        ) : roles && roles?.length ? (
          <RolesTable roles={roles} />
        ) : (
          NoDataFound(canWrite)
        )}
      </MainContent>
    </>
  );
};

const NoDataFound = canWrite => {
  return (
    <CenterContainer>
      <PricingHeader>No Roles Found</PricingHeader>
      {canWrite ? (
        <SettingsHeaderBreadcrumbs>
          <Link to={newRoleRoute}>Create Your First Role</Link>
        </SettingsHeaderBreadcrumbs>
      ) : null}
    </CenterContainer>
  );
};
