import { Dispatch, Reducer, useContext, useEffect, useReducer } from 'react';
import { Merchant } from '@fattmerchantorg/types-omni';
import { AsyncDataNoNull, AsyncDataStatus } from '../../../../../@types';
import { coreapi } from '../../../../../api';
import {
  alterSelectedMerchant,
  SelectedMerchantStore,
} from '../../../../../context';
import { usePrevious, useToaster } from '../../../../../hooks';
import {
  sendUpdateRiskHoldFailed,
  Action,
  sendUpdateRiskHoldSucceeded,
} from './actions';
import { ActionTypes } from './types';

type ReducerState = AsyncDataNoNull<{
  optimisticRiskHold: boolean | null;
}>;
const initialState: ReducerState = {
  status: AsyncDataStatus.INITIAL,
  data: {
    optimisticRiskHold: null,
  },
  error: null,
};
/** PrevStatus will be `undefined` after first render. */
type PrevStatus = AsyncDataStatus | undefined;

async function updateRiskHold(
  authToken: string,
  merchantId: string,
  riskHold: boolean,
  dispatch: Dispatch<Action>
) {
  try {
    const response: Merchant = await coreapi.put(
      authToken,
      `/merchant/${merchantId}`,
      {
        risk_hold: !riskHold,
      }
    );
    dispatch(sendUpdateRiskHoldSucceeded(response));
  } catch (error) {
    console.error(error);
    dispatch(sendUpdateRiskHoldFailed(error));
  }
}

type RiskHoldToggleReducer = Reducer<ReducerState, Action>;
/**
 * Reducer for the custom useDetailsModalHook.
 * This reducer is written in finite-state-machine style:
 * If the current status accepts the current action, transition to a new status.
 * https://twitter.com/DavidKPiano/status/1171062893984526336
 */
const reducer: RiskHoldToggleReducer = (state, action) => {
  switch (state.status) {
    case AsyncDataStatus.INITIAL:
      switch (action.type) {
        case ActionTypes.USER_TOGGLED_SWITCH: {
          return {
            ...state,
            data: {
              ...state.data,
              optimisticRiskHold: !state.data.optimisticRiskHold,
            },
            status: AsyncDataStatus.LOADING,
          };
        }
        case ActionTypes.UPDATE_RISK_HOLD: {
          return {
            ...state,
            data: {
              ...state.data,
              optimisticRiskHold: action.payload,
            },
            status: AsyncDataStatus.IDLE,
          };
        }
        default:
          return state;
      }
    case AsyncDataStatus.IDLE:
      switch (action.type) {
        case ActionTypes.USER_TOGGLED_SWITCH: {
          return {
            ...state,
            data: {
              ...state.data,
              optimisticRiskHold: !state.data.optimisticRiskHold,
            },
            status: AsyncDataStatus.LOADING,
          };
        }
        case ActionTypes.UPDATE_RISK_HOLD: {
          return {
            ...state,
            data: {
              ...state.data,
              optimisticRiskHold: action.payload,
            },
            status: AsyncDataStatus.IDLE,
          };
        }
        default:
          return state;
      }

    case AsyncDataStatus.ERROR:
      switch (action.type) {
        case ActionTypes.USER_TOGGLED_SWITCH:
          return {
            error: null, // clear error
            data: {
              ...state.data,
              optimisticRiskHold: !state.data.optimisticRiskHold,
            },
            status: AsyncDataStatus.LOADING,
          };
        case ActionTypes.UPDATE_RISK_HOLD: {
          return {
            error: null, // clear error
            data: {
              ...state.data,
              optimisticRiskHold: action.payload,
            },
            status: AsyncDataStatus.IDLE,
          };
        }
        default:
          return state;
      }

    case AsyncDataStatus.LOADING:
      switch (action.type) {
        case ActionTypes.UPDATE_RISK_HOLD_SUCCEEDED:
          return {
            ...state,
            status: AsyncDataStatus.IDLE,
          };

        case ActionTypes.UPDATE_RISK_HOLD_FAILED:
          return {
            status: AsyncDataStatus.ERROR,
            data: {
              ...state.data,
              // Revert optimistic UI
              optimisticRiskHold: !state.data.optimisticRiskHold,
            },
            error: action.payload,
          };

        case ActionTypes.USER_TOGGLED_SWITCH:
          // intentionally ignore additional updates while
          // the existing request is in-flight.
          return state;
        default:
          return state;
      }
  }
};

export default function useRiskHoldToggleHook(
  riskHold: boolean,
  authToken: string
): [ReducerState, Dispatch<Action>] {
  const {
    state: { merchantId },
    dispatch: selectedMerchantDispatch,
  } = useContext(SelectedMerchantStore);

  const { toaster, toast } = useToaster();
  const [state, dispatch] = useReducer<RiskHoldToggleReducer>(reducer, {
    ...initialState,
    data: {
      optimisticRiskHold: riskHold,
    },
  });
  const prevStatus: PrevStatus = usePrevious<AsyncDataStatus>(state.status);

  /*
   * Perform side-effects according to status transitions.
   */
  useEffect(() => {
    // Use value of `prevStatus` to ensure side-effects are only performed when status transition occurs.
    if (
      prevStatus !== AsyncDataStatus.LOADING &&
      state.status === AsyncDataStatus.LOADING
    ) {
      // Optimistically update the selected merchant context
      selectedMerchantDispatch(
        alterSelectedMerchant({
          risk_hold: !riskHold,
        })
      );
      updateRiskHold(authToken, merchantId, riskHold, dispatch);
    }
  }, [
    prevStatus,
    state.status,
    dispatch,
    authToken,
    merchantId,
    selectedMerchantDispatch,
    riskHold,
  ]);
  useEffect(() => {
    if (
      prevStatus !== AsyncDataStatus.ERROR &&
      state.status === AsyncDataStatus.ERROR
    ) {
      // Revert the optimistic update
      selectedMerchantDispatch(
        alterSelectedMerchant({
          risk_hold: !riskHold,
        })
      );
      toaster(
        toast.error(
          state.error,
          "There was a problem updating the merchant's deposit hold status."
        )
      );
    }
  }, [
    prevStatus,
    state.status,
    state.error,
    toast,
    toaster,
    selectedMerchantDispatch,
    riskHold,
  ]);

  return [state, dispatch];
}
