import { FileRecord } from '@fattmerchantorg/types-omni';
import { buildApi } from '../util/api.util';
import { dataURIToBlob } from '../util/file.util';

const _onboardingapi = buildApi(process.env.REACT_APP_ONBOARDING_URL);

/**
 * Downloads a protected file from the underwriting service and opens it in the browser
 */
const previewFile = async (authToken: string, file: FileRecord) => {
  let url: string;
  if (file.public_url) {
    url = file.public_url;
  } else {
    const fileData = await onboardingapi.get(authToken, `file/${file.id}`);
    const dataURI = `${file.meta.mime};base64,${fileData}`;
    // Using a blob instead of using the URI in the href property of the anchor tag
    // Chrome has a 2MB URI size limit
    const blob = dataURIToBlob(dataURI, file.meta.mime);
    url = URL.createObjectURL(blob);
  }
  const element = document.createElement('a');
  element.href = url;
  element.setAttribute('target', '_blank');
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

/**
 * Downloads a protected file from the underwriting service
 */
const downloadFile = async (authToken: string, file: FileRecord) => {
  const fileData = await onboardingapi.get(authToken, `file/${file.id}`);
  const dataURI = `${file.meta.mime};base64,${fileData}`;
  // Using a blob instead of using the URI in the href property of the anchor tag
  // Chrome has a 2MB URI size limit
  const blob = dataURIToBlob(dataURI, file.meta.mime);
  const url = URL.createObjectURL(blob);
  const element = document.createElement('a');
  element.href = url;
  element.setAttribute('download', file.name);
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const onboardingapi = {
  ..._onboardingapi,
  previewFile,
  downloadFile,
};
