import { Registration } from '@fattmerchantorg/types-omni';
import { pick } from '../../../../util/object.util';
import {
  isValidPhoneNumber,
  isValidEmail,
  isAddressPoBox,
} from '../../../../util/validator.util';
import { AddressFieldsValues, AddressFieldsErrors } from './AddressForm.types';
import { Mutator } from 'final-form';

export function mapFormValuesToPayload(
  formValues: AddressFieldsValues
): Partial<Registration> {
  return {
    business_location_address_1: formValues.business_location_address_1,
    business_location_address_2: formValues.business_location_address_2,
    business_location_address_city: formValues.business_location_address_city,
    business_location_address_state: ['USA', 'CAN'].includes(
      formValues.business_location_address_country
    )
      ? formValues.business_location_address_state
      : '',
    business_location_address_zip: formValues.business_location_address_zip,
    business_location_address_country:
      formValues.business_location_address_country,
    business_address_1: formValues.business_address_1,
    business_address_2: formValues.business_address_2,
    business_address_city: formValues.business_address_city,
    business_address_state: ['USA', 'CAN'].includes(
      formValues.business_address_country
    )
      ? formValues.business_address_state
      : '',
    business_address_zip: formValues.business_address_zip,
    business_address_country: formValues.business_address_country,
    business_location_phone_number: formValues.business_location_phone_number,
    business_location_fax: formValues.business_location_fax,
    business_location_email: formValues.business_location_email,
  };
}

export function mapRegistrationToInitialFormValues(
  registration: Registration
): AddressFieldsValues {
  return pick(
    registration,
    'business_location_address_1',
    'business_location_address_2',
    'business_location_address_city',
    'business_location_address_state',
    'business_location_address_zip',
    'business_location_address_country',
    'business_address_1',
    'business_address_2',
    'business_address_city',
    'business_address_state',
    'business_address_zip',
    'business_address_country',
    'business_location_phone_number',
    'business_location_fax',
    'business_location_email'
  ) as AddressFieldsValues;
}

export const formMutators: {
  [key: string]: Mutator<AddressFieldsValues>;
} = {
  setFieldValue: ([name, value], state, utils) => {
    utils.changeValue(state, name, _ => value);
  },
  setSameAsBilling: ([registration], state, utils) => {
    const fieldMapping = {
      business_location_address_1: registration?.business_address_1,
      business_location_address_2: registration?.business_address_2,
      business_location_address_city: registration?.business_address_city,
      business_location_address_state: registration?.business_address_state,
      business_location_address_zip: registration?.business_address_zip,
      business_location_address_country: registration?.business_address_country,
      business_location_phone_number:
        registration?.business_phone_number || registration?.phone_number,
      business_location_fax: registration?.business_fax,
      business_location_email:
        registration?.business_email || registration?.email,
    };

    Object.keys(fieldMapping).forEach(key => {
      utils.changeValue(state, key, () => fieldMapping[key]);
    });
  },
  cleanSameAsBilling: (_, state, utils) => {
    const fieldMapping = {
      business_location_address_1: null,
      business_location_address_2: null,
      business_location_address_city: null,
      business_location_address_state: null,
      business_location_address_zip: null,
      business_location_address_country: null,
      business_location_email: null,
      business_location_phone_number: null,
      business_location_fax: null,
    };

    Object.keys(fieldMapping).forEach(key => {
      utils.changeValue(state, key, () => fieldMapping[key]);
    });
  },
};

export function validateRequiredFormValues(
  values: AddressFieldsValues
): AddressFieldsErrors {
  const errors = {} as AddressFieldsErrors;

  return errors;
}

export function validateBillingFormValues(
  values: AddressFieldsValues
): AddressFieldsErrors {
  const errors = {} as AddressFieldsErrors;

  if (
    ['USA', 'CAN'].includes(values.business_address_country) &&
    (values.business_address_state === '' ||
      values.business_address_state == null)
  ) {
    errors.business_address_state = 'Please enter a valid state or province';
  }

  return errors;
}

export function validateDbaFormValues(
  values: AddressFieldsValues
): AddressFieldsErrors {
  const errors = {} as AddressFieldsErrors;

  if (
    values.business_location_phone_number &&
    !isValidPhoneNumber(values.business_location_phone_number)
  ) {
    errors.business_location_phone_number = 'Please enter a valid phone number';
  }

  if (
    values.business_location_fax &&
    !isValidPhoneNumber(values.business_location_fax)
  ) {
    errors.business_location_fax = 'Please enter a valid fax number';
  }

  if (
    values.business_location_email &&
    !isValidEmail(values.business_location_email)
  ) {
    errors.business_location_email = 'Please enter a valid email address';
  }

  if (
    ['USA', 'CAN'].includes(values.business_location_address_country) &&
    (values.business_location_address_state === '' ||
      values.business_location_address_state == null)
  ) {
    errors.business_location_address_state =
      'Please enter a valid state or province';
  }

  if (
    values.business_location_address_1 &&
    isAddressPoBox(values.business_location_address_1)
  ) {
    errors.business_location_address_1 = 'Address cannot be a PO box';
  }

  if (
    values.business_location_address_2 &&
    isAddressPoBox(values.business_location_address_2)
  ) {
    errors.business_location_address_2 = 'Address cannot be a PO box';
  }

  return errors;
}
