import { Transaction } from '@fattmerchantorg/types-omni';
import { StatusPillStatus } from '@fattmerchantorg/truffle-components/dist/StatusPill/StatusPill';
import {
  AdjustmentTransactionCategory,
  AdjustmentTransactionSubtype,
  DB,
  API,
} from '@fattmerchantorg/types-engine';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { AdjustmentTransaction } from '../components/transactions/AdjustmentTransactions/AdjustmentTransactions.types';

export function mapTransactionType(type: DB.Transaction['type']): {
  label: string;
  status: StatusPillStatus;
  icon?: FontAwesomeIconProps['icon'];
} {
  switch (type) {
    case 'CHARGE':
      return {
        label: 'Charge',
        status: 'success',
      };
    case 'REFUND':
      return {
        label: 'Refund',
        status: 'warning',
      };
    case 'FEE':
      return {
        status: 'warning',
        label: 'Fee',
      };
    case 'ACHRETURN':
      return {
        status: 'error',
        label: 'ACH Return',
      };
    case 'CREDIT':
      return {
        status: 'success',
        label: 'Credit',
      };
    case 'HOLD':
      return {
        status: 'error',
        label: 'Held',
      };
    case 'RELEASE':
      return {
        status: 'success',
        label: 'Released',
      };
    case 'DEBIT':
      return {
        status: 'warning',
        label: 'Debit',
      };
    default:
      return null;
  }
}

export function mapAdjustmentTransactionSubType(
  type: AdjustmentTransactionSubtype
): string {
  switch (type) {
    case 'ADJUSTMENT_ACH':
      return 'ACH Adjustment';
    case 'ADJUSTMENT_DISPUTE':
      return 'Dispute Adjustment';
    case 'ADJUSTMENT_EQUIPMENT':
      return 'Equipment Adjustment';
    case 'ADJUSTMENT_FEE':
      return 'Fee Adjustment';
    case 'ADJUSTMENT_OTHER':
      return 'Other Adjustment';
    default:
      return type;
  }
}

export const AdjustmentTransactionCategoryEnum: Record<
  AdjustmentTransactionCategory,
  AdjustmentTransactionCategory
> = {
  ACH: 'ACH',
  DISPUTE: 'DISPUTE',
  EQUIPMENT: 'EQUIPMENT',
  FEE: 'FEE',
  OTHER: 'OTHER',
} as const;

export const AdjustmentTransactionCategoryList = Object.values(
  AdjustmentTransactionCategoryEnum
);

export const AdjustmentTransactionSubtypeEnum: Record<
  AdjustmentTransactionSubtype,
  AdjustmentTransactionSubtype
> = {
  ADJUSTMENT_ACH: 'ADJUSTMENT_ACH',
  ADJUSTMENT_DISPUTE: 'ADJUSTMENT_DISPUTE',
  ADJUSTMENT_EQUIPMENT: 'ADJUSTMENT_EQUIPMENT',
  ADJUSTMENT_FEE: 'ADJUSTMENT_FEE',
  ADJUSTMENT_OTHER: 'ADJUSTMENT_OTHER',
};

export const AdjustmentTransactionSubtypeList = Object.values(
  AdjustmentTransactionSubtypeEnum
);

export function isAdjustmentTransaction(
  transaction: API.Transaction | AdjustmentTransaction
) {
  return (
    transaction &&
    ['CREDIT', 'DEBIT'].includes(transaction.type) &&
    AdjustmentTransactionSubtypeList.includes(
      transaction.subtype as AdjustmentTransactionSubtype
    )
  );
}

/**
 * Extract the formatted ACH rejection error status and description from a transaction's meta
 *
 * Note: this function will only return a value if the following conditions are met:
 *
 * 1. This transaction must have a method of "bank"
 * 2. This transaction must have a type of "void" or have a child transaction with a type of "void"
 * 3. This transaction meta must have a forteStatus, achStatus, forteDescription, or achDescription property
 *
 * @param transaction The transaction from which to extract the ACH rejection error
 * @returns The formatted ACH rejection error status and description, or null if no ACH rejection error was found
 */
export const getAchRejectionError = (
  transaction: Pick<Transaction, 'method' | 'type' | 'meta'> & {
    child_transactions?: Pick<Transaction, 'method' | 'type' | 'meta'>[];
  }
): { status: string; description: string } | null => {
  // If a transaction was not provided, exit early
  if (!transaction) return null;

  // If the transaction method was not bank, this cannot be an ACH rejection.
  if (transaction.method !== 'bank') return null;

  const voidedTransaction =
    transaction.type === 'void'
      ? // If the given transaction is a void, use it
        // (In this case, the given transaction is the voided child transaction)
        transaction
      : // Otherwise, check the child transactions for a void
        // (In this case, the given transaction is the parent transaction of the voided child transaction)
        transaction.child_transactions?.find(ct => ct.type === 'void');

  // If the transaction was not voided or there are no voided child transactions, this cannot be an ACH rejection.
  if (!voidedTransaction) return null;

  const meta = voidedTransaction?.meta || {};
  const achStatus = meta.forteStatus || meta.achStatus;
  let achDescription = meta.forteDescription || meta.achDescription;

  if (achDescription) {
    const match = achDescription.match(
      /ReasonCode: (.*).ReasonDescription: (.*)/s
    );
    // If this ach description is in the format of "ReasonCode: R234.ReasonDescription: Some description", extract only the description.
    // e.g. "ReasonCode: R234.ReasonDescription: Some description" -> "Some description"
    if (match && match.length > 2) achDescription = match[2];
  }

  // If we have no ach description or status, this cannot be an ACH rejection.
  if (!achStatus && !achDescription) return null;

  // Use a default status or description if one of the two values were missing
  return {
    status: achStatus || 'DECLINED',
    description: achDescription || 'Initial transaction did not settle.',
  };
};

/**
 * Extract the formatted ACH rejection error description from a transaction's meta
 * Note: this is simply a wrapper function for getAchRejectionError that returns only the description
 *
 * @param transaction The transaction from which to extract the ACH rejection error description
 * @returns The formatted ACH rejection error description, or null if no ACH rejection error was found
 */
export const getAchRejectionErrorMessage = (
  transaction: Pick<
    Transaction,
    'method' | 'type' | 'meta' | 'child_transactions'
  >
) => {
  return getAchRejectionError(transaction)?.description;
};

/**
 * Extract the error message from a transaction
 * If the transaction message is "Gateway Unreachable", return a formatted error message suitable for display to the user
 *
 * @param transaction The transaction from which to extract the error message
 * @returns The error message, or null if no error message was found
 */
export const getErrorMessage = (transaction: Pick<Transaction, 'message'>) => {
  const message = transaction?.message;

  return message === 'Gateway Unreachable'
    ? 'No response or the response from the gateway was disrupted. It is unknown if the transaction was successful. Retrying the transaction may result in a duplicate charge.'
    : message;
};

/**
 * Extracts the AVS error message from a transaction
 * Note: this function will only return a value if the transaction message is "AVS REJECTED" (case insensitive)
 *
 * @param transaction The transaction from which to extract the AVS error message
 * @returns The AVS error message, or null if this transaction message was not AVS rejected or no AVS error message was found
 */
export const getAvsErrorMessage = (
  transaction: Pick<Transaction, 'message' | 'avs_message'>
) => {
  if (!transaction) return null;
  if (transaction.message?.toUpperCase() !== 'AVS REJECTED') return null;
  return transaction.avs_message || null;
};
