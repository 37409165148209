import React, { FunctionComponent } from 'react';
import { Select } from '@fattmerchantorg/truffle-components';
import { countries } from '../../../../constants/countries';
import { FieldProps } from '../../../shared/Field/Field.types';

export interface CountrySelectProps<FieldValue, T extends HTMLElement>
  extends FieldProps<FieldValue, T> {}

export const countryOptions = countries.map(state => {
  return {
    label: state.name,
    value: state.iso,
  };
});

export const CountrySelect: FunctionComponent<any> = props => {
  return (
    <Select
      options={countryOptions}
      styles={{
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        // @TODO - Need to fix this in Truffle's Stax Connect theme
        input: base => ({ ...base, color: 'white', padding: 0 }),
      }}
      menuPortalTarget={document.body}
      menuShouldScrollIntoView={false}
      isSearchable={true}
      menuPosition="fixed"
      {...props}
    />
  );
};
