import { FundingAccount } from '@fattmerchantorg/types-omni';
/**
 *
 * @param fullName full name of user or customer, e.g. 'Susanna Miller'
 * @returns [string, string] first and last name of user or customer, e.g. ['Susanna', 'Miller']
 * @summary taken from fattpay-react
 */
export const getFirstAndLastName = (
  fullName: string = ''
): [string, string] => {
  let name = fullName.trim().split(' ');
  return [name.shift(), name.join(' ')];
};

export const valueOrNA = (value: string) => {
  return !value ? 'N/A' : value;
};

export const valueOrHyphen = (value: string) => {
  return !value ? '-' : value;
};

export const valueOrZero = (value: string) => {
  return !value ? '0' : value;
};

export const showOnlyLastFour = (value: string, showLast4: boolean = false) => {
  const maskedValue = (value || '').replace(/[\d]/g, '*');
  if (showLast4) {
    return maskedValue && maskedValue.slice(0, -4) + value.slice(-4);
  } else {
    return maskedValue && maskedValue;
  }
};

export const getNameWithLastFour = (account: FundingAccount) => {
  if (!account) {
    return '';
  } else if (account.name) {
    return `${account.name || ''} **${account.last_four}`.trim();
  } else {
    return `${account.bank_name || ''} **${account.last_four}`.trim();
  }
};
