import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { GroupProps } from './Group.types';

const getMargin = (props: any): string => {
  switch (props.direction) {
    case 'row':
      return `0 0 0 ${props.margin}`;
    case 'row-reverse':
      return `0 ${props.margin} 0 0`;
    case 'column':
      return `${props.margin} 0 0 0`;
    case 'column-reverse':
      return `0 0 ${props.margin} 0`;
  }
};

export const StyledGroup = styled.div`
  width: auto;
  display: flex;
  flex-direction: ${(props: GroupProps) => props.direction};
  justify-content: ${(props: GroupProps) => props.justify};
  align-items: ${(props: GroupProps) => props.items};

  > * {
    width: auto;

    &:not(:first-child) {
      margin: ${(props: GroupProps) => {
        return getMargin(props);
      }};
    }
  }

  @media (max-width: 1000px) {
    > *:not(:first-child) {
      margin: ${(props: any) => {
        return getMargin({
          ...props,
          margin: props.mobileMargin,
        });
      }};
    }
  }
`;

export const Group: FunctionComponent<GroupProps> = props => {
  let [margin, mobileMargin] = (props.space && props.space.split('/')) || [];

  if (typeof mobileMargin === 'undefined') {
    mobileMargin = margin;
  }

  return (
    <StyledGroup
      {...props}
      margin={margin || '0'}
      mobileMargin={mobileMargin || '0'}
    />
  );
};

Group.defaultProps = {
  direction: 'row',
  justify: 'normal',
  space: 'inherit',
};

export const ButtonGroup: FunctionComponent<GroupProps> = props => (
  <Group space="1rem/0px" {...props} />
);
