import { pick } from '../../../util/object.util';
import { DB } from '@fattmerchantorg/types-engine';
import {
  AchRejectionFeeFieldsValues,
  AchRejectionFeeFieldsErrors,
} from './AchRejectionFeeForm.types';

export function mapFormValuesToPayload(
  formValues: AchRejectionFeeFieldsValues
): Partial<DB.Billing> {
  return {
    state: 'ACTIVE',
    type: 'ACHREJECT',
    brand_id: null,
    billing_cycle_day: formValues.billing_cycle_day ?? 1,
    billing_cycle: formValues.billing_cycle,
    fees_account_id: formValues.fees_account_id,
    is_gross_settlement: formValues.is_gross_settlement === 'true',
    per_transaction_amount: formValues.per_transaction_amount,
    deposit_account_id: formValues.deposit_account_id,
  };
}

export function mapBillingProfileToInitialFormValues(
  billingProfile: Partial<AchRejectionFeeFieldsValues>
) {
  return pick(
    billingProfile,
    'type',
    'billing_cycle_day',
    'billing_cycle',
    'fees_account_id',
    'is_gross_settlement',
    'per_transaction_amount',
    'deposit_account_id',
    'funding_schedule_days'
  ) as AchRejectionFeeFieldsValues;
}

export function validateFormValues(
  values: AchRejectionFeeFieldsValues
): AchRejectionFeeFieldsErrors {
  const errors = {} as AchRejectionFeeFieldsErrors;

  if (!values.fees_account_id) {
    errors.fees_account_id = 'A fees account must be selected.';
  }
  if (values.per_transaction_amount < 0) {
    errors.per_transaction_amount = 'A positive fee amount must be provided.';
  }
  return errors;
}
