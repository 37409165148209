import React, { FunctionComponent, useCallback, useState } from 'react';
import { FundingAccount } from '@fattmerchantorg/types-omni';
import {
  PrimaryButton,
  SecondaryButton,
  ModalContent,
  ModalFooter,
} from '@fattmerchantorg/truffle-components';
import styled from 'styled-components';
import { BankAccountFiles } from '../BankAccountFiles';
import { ModalIcon, ModalText } from './ModalBody.styles';

const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;

  > *:not(:first-child) {
    margin-left: 16px;
  }
`;

const PendedIcon: typeof ModalIcon = styled(ModalIcon)`
  color: ${({ theme }) => theme.colors.core.gray[200].hex};
`;

const BolderText = styled.span`
  font-weight: bold;
`;

const StyledModalFooter = styled(ModalFooter)`
  flex-direction: column;
  align-items: flex-end;
`;

const StyledModalContent = styled(ModalContent)`
  display: flex;
  flex-direction: column;
`;

interface PendedAccountModalBodyProps {
  fundingAccountId: string;
  onFinish: () => void;
  onCancel: () => void;
}

export const PendedAccountModalBody: FunctionComponent<
  PendedAccountModalBodyProps
> = props => {
  const { fundingAccountId, onFinish, onCancel } = props;
  const [hasTheRequiredNumberOfFiles, setHasTheRequiredNumberOfFiles] =
    useState(false);

  const onFetchFiles = useCallback((files: FundingAccount['files']) => {
    setHasTheRequiredNumberOfFiles(files.length >= 1);
  }, []);

  return (
    <>
      <StyledModalContent>
        <PendedIcon icon={['far', 'exclamation-circle']} />
        <ModalText as="p">Let's verify your account.</ModalText>
        <ModalText as="p">
          <span>
            Please upload a{' '}
            <BolderText>Voided Check or Signed Bank Letter</BolderText>. Your
            bank account won't be functional before we can verify some
            information.
          </span>
        </ModalText>
        <BankAccountFiles
          accountId={fundingAccountId}
          onFetchFiles={onFetchFiles}
        />
      </StyledModalContent>
      <StyledModalFooter>
        <ButtonGroup>
          <SecondaryButton
            id="verify-account-modal-body-cancel-button"
            data-testid="verify-account-modal-body-cancel-button"
            type="button"
            onClick={onCancel}
          >
            Do it Later
          </SecondaryButton>
          <PrimaryButton
            id="verify-account-modal-body-confirm-button"
            data-testid="verify-account-modal-body-confirm-button"
            type="button"
            disabled={!hasTheRequiredNumberOfFiles}
            onClick={onFinish}
          >
            Finish
          </PrimaryButton>
        </ButtonGroup>
      </StyledModalFooter>
    </>
  );
};
