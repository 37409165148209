import { formatPhoneNumber } from './format.util';
import { getMaskedInputMax } from './underwriting.util';

export function notRequired(
  predicate: (value: string) => boolean,
  value: string
) {
  return value ? predicate(value) : true;
}

export function isValidEmail(value: string) {
  return /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)$/i.test(
    value
  );
}

export function isAddressPoBoxBase(value: string) {
  return /\b((?:(?:p(?:\.|ost|ostal)?\s*o(?:\.|ffice)?\s*b(?:ox)?))|(?:(?:post|postal)\s+office\s+(?:#|box(?:#|\s+#|\.)?\s*\d))|(?:\b(box(?:#|\s+#|\.)?\s*\d|(?:post|postal|office)\s+box|box(?=\s*\d)|box\.\s*(?=\s*\d)|(?:p\.?|post|postal)\s*o\.?\s*b|p\s*box|PO\s*B\.?|P\.?\s*O\.?\s*B|PO)\b))\b/gim.test(
    value
  );
}

// helper for checking PO boxes in format #xxxxxx
export function isAddressNumeric(value: string) {
  return /#[0-9]/.test(value);
}

export function isAddressApo(value: string) {
  return /[AFD]PO\sA[AEP]\s\d\d\d\d\d/gim.test(value);
}

export function isAddressPoBox(value: string) {
  return (
    value !== '' &&
    (isAddressPoBoxBase(value) ||
      isAddressNumeric(value) ||
      isAddressApo(value))
  );
}

export function isValidPhoneNumber(value: string) {
  return /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
    formatPhoneNumber(value)
  );
}

export function isValidUrl(value: string) {
  // eslint-disable-next-line
  return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(
    value
  );
}

export function isWithinRange(value: number, min: number, max: number) {
  return value >= min && value <= max;
}

export function isValidCreditCard(value: string = '') {
  let valid = false;
  const number = String(value).replace(/[\D]/g, '');

  if (!number) {
    // nothing
    valid = false;
  } else if (/^4[0-9]{6,}$/.test(number)) {
    // VISA
    // try to gather one extra digit at end of chunk
    // if it only gets 4 numbers, it's valid
    valid = number.substring(12, 16 + 1).length === 4;
  } else if (/^5[1-5][0-9]{5,}$/.test(number)) {
    // MasterCard
    valid = number.substring(12, 16 + 1).length === 4;
  } else if (/^2[2-7][2][0-9]{3,}$/.test(number)) {
    // MasterCard new bins
    valid = number.substring(12, 16 + 1).length === 4;
  } else if (/^6(?:011|5[0-9]{2})[0-9]{3,}$/.test(number)) {
    // Discover
    valid = number.substring(12, 16 + 1).length === 4;
  } else if (/^3[47][0-9]{5,}$/.test(number)) {
    // AMEX
    valid = number.substring(10, 15 + 1).length === 5;
  } else if (/^3(?:0[0-5]|[68][0-9])[0-9]{11}$/.test(number)) {
    // Diner's Club
    valid = number.substring(10, 14 + 1).length === 4;
  } else if (/^(?:2131|1800\d{3})\d{11}$/.test(number)) {
    // JCB beginning with 2131 or 1800 (15 digits)
    valid = number.substring(10, 15 + 1).length === 5;
  } else if (/^(?:35\d{3})\d{11}$/.test(number)) {
    // JCB beginning with 35 (16 digits)
    valid = number.substring(12, 16 + 1).length === 4;
  }

  return valid;
}

export function isValidOpenDate(value: string = '', yearsAgo?: number) {
  const openDate = new Date(value);
  const today = new Date();

  if (yearsAgo) {
    const xYearsAgo = new Date(
      new Date().setFullYear(new Date().getFullYear() - yearsAgo)
    );
    if (openDate < xYearsAgo) return false;
  }

  return value.length === 10 && openDate < today;
}

export function isValidSSN(value: string = '') {
  if (!value) {
    return false;
  }
  if (value.includes('-')) {
    const re = /\d\d\d-\d\d-\d\d\d\d/;
    return re.test(value);
  }
  return /\d{9}/.test(value);
}

export function isValidAccountNumber(value: string = '') {
  if (!value) {
    return false;
  }
  const maxlength = getMaskedInputMax('accountNumber');
  return value.length >= 6 && value.length <= maxlength && /^\d+$/.test(value);
}

export function isValidHexCodeColor(value: string) {
  return /^#?([a-f0-9]{6})$/i.test(value);
}
