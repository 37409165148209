import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { PayfacFields } from './processor-configs/PayfacFields';
import { Registration } from '@fattmerchantorg/types-omni';
import { ProcessorValue, ProcessorNames } from './SubmitApplicationForm.types';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { Icon } from '@fattmerchantorg/truffle-components';

const SubPageHeader = styled.span`
  display: block;
  font-size: 14px;
`;

const ProcessorName = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 8px;
`;

const BypassNoVoidWarning = () => (
  <StyledBypassVoidWarning>
    <ExclamationCircleIcon icon={faExclamationCircle} />
    <StyledBypassVoidWarningText>
      You've already submitted an application to this processor. Pressing the
      submit button will submit a new application.
    </StyledBypassVoidWarningText>
  </StyledBypassVoidWarning>
);

const StyledBypassVoidWarning = styled.div`
  background-color: #f8dc3d;
  padding: 5px 16px;
  margin-left: -15px;
  margin-right: -15px;
  font-weight: bold;
`;

const ExclamationCircleIcon = styled(Icon)`
  color: ${({ theme }) => theme.black};
  margin-right: 8px;
  height: 13px;
  font-weight: bold;
`;

const StyledBypassVoidWarningText = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.black};
`;

interface ProcessorConfigProps {
  registration: Registration;
  setIsSubmitEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  processor: ProcessorValue;
  novoidViewConfig?: boolean;
  finixCredentialId: string | null;
}

/**
 *
 * The intent of this component is to accept a processor name as a prop and
 * return/render the appropriate configuration fields for the provided
 * processor, so basically a router for processor configuration inside the
 * underwriting "status" modal experience.
 * @returns
 */
export const ProcessorConfig: FunctionComponent<
  ProcessorConfigProps
> = props => {
  const {
    registration,
    setIsSubmitEnabled,
    processor,
    novoidViewConfig,
    finixCredentialId,
  } = props;

  if (!processor) return null;

  const renderFields = () => {
    switch (processor) {
      case 'CORE_FINIX':
      case 'LITLE':
      case 'LITLE_FINIX':
        return (
          <PayfacFields
            finixCredentialId={finixCredentialId}
            registration={registration}
            setIsSubmitEnabled={setIsSubmitEnabled}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <SubPageHeader>Processor</SubPageHeader>
      <ProcessorName>{ProcessorNames[processor]}</ProcessorName>
      {renderFields()}
      {novoidViewConfig && (
        <div>
          <BypassNoVoidWarning />
        </div>
      )}
    </div>
  );
};
