import {
  Registration,
  RegistrationRepresentative as Representative,
} from '@fattmerchantorg/types-omni';
import { ProcessingMethodEntry } from '../@types';

type RefundPolicyCode = Registration['refund_policy'];
export type CompanyTypeCode = Registration['company_type'];

// prettier-ignore
export const refundPolicies: { key: RefundPolicyCode; description: string }[] = [
  { key: 'N', description: 'No Refunds' },
  { key: 'E', description: 'Merchandise Exchange Only' },
  { key: 'T', description: '30 Days or Fewer' },
  { key: 'O', description: 'Other' }
];

// prettier-ignore
export const procChanges = [
  { key: 'NOT_NOW_PROC', value: 'NOT_NOW_PROC', name: 'Never accepted cards before' },
  { key: 'PROC_CHANGE', value: 'PROC_CHANGE', name: 'Processor change' }
];

// prettier-ignore
export const companyTypes: { key: CompanyTypeCode; description: string }[] = [
  { key: 'A', description: 'Association/Estate/Trust' },
  { key: 'G', description: 'Government (Federal/State/Local)' },
  { key: 'S', description: 'Individual/Sole Proprietor' },
  { key: 'F', description: 'Financial Institution' },
  { key: 'L', description: 'LLC (Limited-Liability Corporation)' },
  { key: 'E', description: 'Tax-Exempt Organization (501C)' },
  { key: 'P', description: 'Partnership' },
  { key: 'V', description: 'Private Corporation' },
  { key: 'B', description: 'Public Corporation' },
  { key: 'R', description: 'SEC-Registered Entity' }
];

export const accountTypes = [
  { key: 'SALES', value: 'Sales' },
  { key: 'DISCOUNT_A', value: 'Discount' },
  { key: 'FEES', value: 'Fees' },
  { key: 'CREDITS', value: 'Credits' },
  { key: 'CHARGEBACKS', value: 'Chargebacks' },
];

export const networks = [
  { key: '6', value: '6 - Vantiv' },
  { key: '3', value: '3 - TSYS-MMS (Vital)' },
  { key: '1', value: '1 - Global East (NDC)' },
  { key: '7', value: '7 - Heartland' },
];

// prettier-ignore
export const processingMethods: ProcessingMethodEntry[] = [
  { key: 'in-person',  shortName: 'In-Person', description: 'In-Person (Terminal or POS integration)' },
  { key: 'mobile-reader',shortName: 'Mobile Reader',  description: 'Mobile Reader' },
  { key: 'online',  shortName: 'Online', description: 'Online (Shopping Cart, Recurring Billing, Invoices)' },
  { key: 'api', shortName: 'API', description: 'API' },
];

// prettier-ignore
export const businessTypes = [
  { key: '1', value: '1 - Retail' },
  { key: '11', value: '11 - Restaurant' },
  { key: '12', value: '12 - Moto' },
  { key: '18', value: '18 - Internet' },
];

// prettier-ignore
export const documentTypes = [
  { key: 1,  label: 'Document 1 Month Previous Processing Statements' },
  { key: 4,  label: 'Document 1 Year Business Financials' },
  { key: 2,  label: 'Document 3 Months Previous Processing Statements' },
  { key: 5,  label: 'Document 3 Years Business Financials' },
  { key: 3,  label: 'Document 6 Months Previous Processing Statements' },
  { key: 35, label: 'Document ACH Agreement' },
  { key: 46, label: 'Document Adding Additional Card Services' },
  { key: 47, label: 'Document Additional Memos' },
  { key: 67, label: 'Document Airborne Shipping Documents' },
  { key: 59, label: 'Document Amendment to Merchant Application' },
  { key: 68, label: 'Document Bank ACH Verification' },
  { key: 60, label: 'Document Business Card' },
  { key: 71, label: 'Document Business License' },
  { key: 38, label: 'Document Check Service Application' },
  { key: 90, label: 'Document Check Service Approval Documents' },
  { key: 44, label: 'Document Customer Service Log' },
  { key: 84, label: 'Document Disclosure of Fees Form' },
  { key: 85, label: 'Document Document in Lieu of Site Photo' },
  { key: 58, label: 'Document Drivers License' },
  { key: 72, label: 'Document Dunn & Bradstreet Report' },
  { key: 13, label: 'Document EBT Application' },
  { key: 20, label: 'Document Equipment Set-up Form' },
  { key: 73, label: 'Document FedEx Shipping Documents' },
  { key: 45, label: 'Document ISP Checklist or Contract' },
  { key: 43, label: 'Document Install Test Tickets' },
  { key: 75, label: 'Document Installation Paperwork' },
  { key: 34, label: 'Document LCG Funding Confirmation' },
  { key: 30, label: 'Document LCG Lease' },
  { key: 32, label: 'Document LCG Lease Confirmation' },
  { key: 31, label: 'Document LCG Verbal Confirmation' },
  { key: 54, label: 'Document Lease Assumption Documents' },
  { key: 37, label: 'Document Lynk Application' },
  { key: 6,  label: 'Document Marketing Materials' },
  { key: 22, label: 'Document Memo to Risk' },
  { key: 21, label: 'Document Merchant Application' },
  { key: 78, label: 'Document Merchant Disclosure' },
  { key: 79, label: 'Document Other Shipping Documents' },
  { key: 63, label: 'Document Personal Guarantee Amendment' },
  { key: 7,  label: 'Document Product Warrantee / Guarantee' },
  { key: 50, label: 'Document Proposal from Sales' },
  { key: 9,  label: 'Document Refund Policy' },
  { key: 12, label: 'Document Social Security Card' },
  { key: 26, label: 'Document Site Inspection Form' },
  { key: 27, label: 'Document Site Inspection Report' },
  { key: 82, label: 'Document Special Merchant Guarantee' },
  { key: 91, label: 'Document Special Quote Request' },
  { key: 18, label: 'Document Tele-Lead Form' },
  { key: 8,  label: 'Document Trade License' },
  { key: 81, label: 'Document Trade Reference Verification' },
  { key: 83, label: 'Document UPS Shipping Documents' },
  { key: 92, label: 'Document URL Information' },
  { key: 57, label: 'Document Utility Bill' },
  { key: 25, label: 'Document Voided Check' },
  { key: 64, label: 'Document Voyager Application' },
  { key: 93, label: 'Document Yellow Page advertisement' },
  { key: 16, label: 'Photo ID Card' },
  { key: 17, label: 'Photo Others' },
  { key: 11, label: 'Photo Photo of Inventory' },
  { key: 15, label: 'Photo Photo of Location' },
  { key: 10, label: 'Photo Photo of Signage' },
  { key: 14, label: 'Photo Photo of Storefront' }
];

// MAPPING

export function mapRepresentativeToSigner(
  representative: Representative
): Partial<Registration> {
  return {
    title: representative.title,
    user_dob: representative.date_of_birth,
    user_ssn: representative.ssn,
    first_name: representative.first_name,
    last_name: representative.last_name,
    email: representative.email,
    phone_number: representative.phone,
    owner_address_1: representative.address_1,
    owner_address_2: representative.address_2,
    owner_address_city: representative.address_city,
    owner_address_state: representative.address_state,
    owner_address_country: representative.address_country,
    owner_address_zip: representative.address_zip,
    meta: {
      ownership_percentage: representative.ownership_percentage,
      owner_citizenship: representative.citizenship,
    },
    drivers_license_id: representative.drivers_license_id,
    drivers_license_state: representative.drivers_license_state,
  };
}

export function mapSignerToRepresentative(
  signer: Partial<Registration>
): Representative {
  return {
    title: signer.title,
    date_of_birth: signer.user_dob,
    ssn: signer.user_ssn,
    first_name: signer.first_name,
    last_name: signer.last_name,
    type: null,
    ownership_percentage: signer.meta && signer.meta.ownership_percentage,
    citizenship: signer.meta && signer.meta.owner_citizenship,
    email: signer.email,
    phone: signer.phone_number,
    address_1: signer.owner_address_1,
    address_2: signer.owner_address_2,
    address_city: signer.owner_address_city,
    address_state: signer.owner_address_state,
    address_country: signer.owner_address_country,
    address_zip: signer.owner_address_zip,
    drivers_license_id: signer.drivers_license_id,
    drivers_license_state: signer.drivers_license_state,
  };
}

export function getSurchargeRate(registration: Registration): number | null {
  return !Number.isNaN(+registration?.cnp_credit_surcharge_rate)
    ? +registration.cnp_credit_surcharge_rate
    : null;
}
