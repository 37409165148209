import { GetFeeStatementAdjustmentDatum } from '@fattmerchantorg/types-omni';
import currency from 'currency.js';
import { formatSnakeToTitleCase } from '../../../../util';

export const getAdjustmentTitle = (
  adjustment: Pick<GetFeeStatementAdjustmentDatum, 'type' | 'subtype'>
) => {
  const { type, subtype } = adjustment;
  let title = formatSnakeToTitleCase(type);

  const isCreditOrDebitAdjustment = type?.toUpperCase()?.includes('ADJUSTMENT');
  if (isCreditOrDebitAdjustment) {
    let friendlySubtype = formatSnakeToTitleCase(
      subtype?.replace('ADJUSTMENT_', '')
    );

    if (friendlySubtype.includes('Ach')) {
      friendlySubtype = friendlySubtype.replace(/Ach/g, 'ACH');
    }

    title += ` - ${friendlySubtype}`;
  }

  return title;
};

export const getAdjustmentDepositLink = (
  adjustment: Pick<GetFeeStatementAdjustmentDatum, 'id' | 'batch_id'>,
  merchantId: string
) => {
  const { id, batch_id } = adjustment;
  const depositLink = `${process.env.REACT_APP_STAX_PAY_URL}/#/report/deposit-report/${batch_id}?highlightId=${id}&assume=${merchantId}`;
  return depositLink;
};

export const getAdjustmentTransactionLink = (
  adjustment: Pick<GetFeeStatementAdjustmentDatum, 'id' | 'transaction_id'>,
  merchantId: string
) => {
  const { id, transaction_id } = adjustment;
  const detailId = transaction_id || id;
  const transactionLink = `/merchant/${merchantId}/transactions?detailId=${detailId}`;

  return transactionLink;
};

export const getAdjustmentFormattedAmount = (
  adjustment: Pick<GetFeeStatementAdjustmentDatum, 'amount' | 'type'>
) => {
  if (adjustment.type === 'credit_adjustment') {
    return currency(adjustment.amount).format();
  } else {
    return currency(-adjustment.amount).format();
  }
};
