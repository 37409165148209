import React, { CSSProperties, FunctionComponent } from 'react';
import styled from 'styled-components';
import {
  Dropdown,
  DropdownContent,
  Icon,
} from '@fattmerchantorg/truffle-components';
import { Row } from 'react-table';
import { usePermissions } from '../../../hooks';
import { API, DB } from '@fattmerchantorg/types-engine';
import { faSync } from '@fortawesome/pro-solid-svg-icons';

const IconButton = styled.button`
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.core.white[0].hex};
`;

const ContextMenu = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    a,
    button {
      display: block;
      background: none;
      border: 0;
      border-radius: 2px;
      padding: 8px 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      text-align: left;
      cursor: pointer;
      color: ${({ theme }) => theme.colors.core.white[0].hex};

      span {
        margin-right: 8px;
        width: 16px;
      }

      &:hover {
        background: ${({ theme }) => theme.component.table.borderColor};
      }
    }
  }
`;

interface PayoutRowContextMenuProps {
  row: Row<object>;
  iconStyles?: CSSProperties;
  onReAttempt?: (row: Row<object>, rowData: API.Payout) => void;
}

export const PayoutRowContextMenu: FunctionComponent<
  PayoutRowContextMenuProps
> = props => {
  const { row, onReAttempt } = props;
  const data = row.original as API.Payout;

  const reAttemptAcceptedStates: DB.PayoutState[] = [
    'SENT',
    'REJECTED',
    'SWEPT',
    'PENDING.PAYOUT_ERROR',
  ];

  const { permit } = usePermissions();
  const canReAttempt =
    permit('godview', 'engineReAttemptPayouts', 'write') &&
    reAttemptAcceptedStates.includes(data.state) &&
    !data.is_reattempted;

  let dropdownToggler: React.Dispatch<React.SetStateAction<boolean>>;

  return (
    <React.Fragment>
      {checkRenderDropdown() === null ? null : (
        <Dropdown
          placement="left-start"
          trigger={(setRef, isOpen, setIsOpen) => (
            <IconButton
              ref={setRef}
              onClick={e => {
                dropdownToggler = setIsOpen;
                // Prevents drawer menu from opening when clicking button
                e.stopPropagation();
                setIsOpen(!isOpen);
              }}
              data-testid="payout-table-row-context-menu-trigger"
            >
              <Icon icon={['fas', 'ellipsis-h']} />
            </IconButton>
          )}
        >
          {renderDropdownContent()}
        </Dropdown>
      )}
    </React.Fragment>
  );

  function checkRenderDropdown() {
    if (!canReAttempt) {
      return null;
    }
  }

  function renderDropdownContent() {
    return (
      <DropdownContent style={{ minWidth: '122px', width: 'auto' }}>
        <ContextMenu>
          <ul>
            {canReAttempt && (
              <li>
                <button onClick={handleReAttempt}>
                  <Icon icon={faSync} /> Re-attempt
                </button>
              </li>
            )}
          </ul>
        </ContextMenu>
      </DropdownContent>
    );
  }

  function closeDropdown() {
    if (typeof dropdownToggler === 'function') {
      dropdownToggler(false);
    }
  }

  function handleReAttempt() {
    closeDropdown();
    if (typeof onReAttempt === 'function') {
      onReAttempt(row, data);
    }
  }
};
