import React, { FunctionComponent, useContext, useMemo, useRef } from 'react';
import moment from 'moment';

import { FeeStatementV3RouterProps } from './util/statement-v3-utils';

import {
  MerchantInfoSection,
  SurchargeSection,
  RefundsAdjustmentsSection,
  SummarySection,
  CardTypeVolumesSection,
  DailySalesSection,
  CardProcessingVolumeSection,
  FeesOverviewSection,
  DepositsSection,
  AchRejectsSection,
  DisputesSection,
  MessagesSection,
} from './components';
import { FeeStatementV3Wrapper } from './FeeStatementV3Wrapper';
import ErrorBoundary from './components/error-boundary';
import TableErrorBoundary from './components/table-error-boundary';
import { SelectedMerchantStore } from '../../../context';
import { useRouteMatch } from 'react-router-dom';

export const FeeStatementV3: FunctionComponent<
  FeeStatementV3RouterProps
> = props => {
  const { location } = props;

  const {
    params: { activityPeriod },
  } = useRouteMatch<{ activityPeriod?: string }>();

  // used for scrolling to specific sections
  const totalFeesRef = useRef(null);
  const refundsAdjustmentsRef = useRef(null);
  const dailySalesRef = useRef(null);

  const {
    state: { merchant, brand },
  } = useContext(SelectedMerchantStore);
  const merchantId = merchant?.id;

  const logoUrl = brand?.data?.['darkLogo']?.[0]?.url;

  const reportName = merchant?.company_name.replace(/ /g, '_');

  const [startDate, endDate] = useMemo(() => {
    let startDate, endDate: string;

    if (/\d{4}-\d{2}-\d{2}-to-\d{4}-\d{2}-\d{2}/.test(activityPeriod)) {
      const [start, end] = activityPeriod.split('-to-');
      startDate = start;
      endDate = end;
    } else {
      startDate = moment(activityPeriod).startOf('month').format('YYYY-MM-DD');
      endDate = moment(activityPeriod).endOf('month').format('YYYY-MM-DD');
    }
    return [startDate, endDate];
  }, [activityPeriod]);

  return (
    <FeeStatementV3Wrapper {...props} startDate={startDate}>
      <ErrorBoundary>
        {/* Merchant Info Section */}
        <MerchantInfoSection
          merchant={merchant}
          logoUrl={logoUrl}
          reportName={reportName}
          startDate={startDate}
          endDate={endDate}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        {/* Summary Section */}
        <SummarySection
          startDate={startDate}
          endDate={endDate}
          totalFeesRef={totalFeesRef}
          refundsAdjustmentsRef={refundsAdjustmentsRef}
          dailySalesRef={dailySalesRef}
          merchantId={merchantId}
        />
      </ErrorBoundary>

      <TableErrorBoundary title="Deposits">
        {/* Deposits Section */}
        <DepositsSection
          merchantId={merchantId}
          startDate={startDate}
          endDate={endDate}
        />
      </TableErrorBoundary>

      <TableErrorBoundary title="Fees Overview">
        {/* Fees Overview Section */}
        <FeesOverviewSection
          merchantId={merchantId}
          startDate={startDate}
          endDate={endDate}
          ref={totalFeesRef}
        />
      </TableErrorBoundary>

      <TableErrorBoundary title="Daily Sales">
        {/* Daily Sales Section */}
        <DailySalesSection
          merchantId={merchantId}
          startDate={startDate}
          endDate={endDate}
          ref={dailySalesRef}
        />
      </TableErrorBoundary>

      <ErrorBoundary>
        {/* Card Processing Volume Summary Section */}
        <CardProcessingVolumeSection
          merchantId={merchantId}
          startDate={startDate}
          endDate={endDate}
        />
      </ErrorBoundary>

      <TableErrorBoundary title="Volumes">
        {/* Card Type Volumes Section */}
        <CardTypeVolumesSection
          merchantId={merchantId}
          startDate={startDate}
          endDate={endDate}
        />
      </TableErrorBoundary>

      <TableErrorBoundary title="Surcharge">
        {/* Surcharge Section */}
        <SurchargeSection
          merchantId={merchantId}
          startDate={startDate}
          endDate={endDate}
        />
      </TableErrorBoundary>

      <TableErrorBoundary title="Refunds & Adjustments">
        {/* Refunds & Adjustments Section */}
        <RefundsAdjustmentsSection
          merchantId={merchantId}
          startDate={startDate}
          endDate={endDate}
          location={location}
          ref={refundsAdjustmentsRef}
        />
      </TableErrorBoundary>

      <TableErrorBoundary title="Disputes">
        {/* Disputes Section */}
        <DisputesSection
          merchantId={merchantId}
          startDate={startDate}
          endDate={endDate}
        />
      </TableErrorBoundary>

      <TableErrorBoundary title="ACH Rejects">
        {/* ACH Rejects Section */}
        <AchRejectsSection
          merchantId={merchantId}
          startDate={startDate}
          endDate={endDate}
          location={location}
        />
      </TableErrorBoundary>

      <ErrorBoundary>
        {/* Messages Section */}
        <MessagesSection startDate={startDate} />
      </ErrorBoundary>
    </FeeStatementV3Wrapper>
  );
};
