import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import { UnderwritingSection } from '../shared/UnderwritingSection';
import { SelectedMerchantStore } from '../../../../context';
import { valueOrHyphen } from '../../../../util/string.util';
import { startCase, isObject } from 'lodash';

const SectionWrap = styled.div`
  padding: 0;
  width: 100%;
`;

const DataField = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

// Fields that we display elsewhere that we want to ignore here
const ignoreFields = [
  'representatives',
  'ownership_percentage',
  'finix',
  'applicationStatusLog',
  'auditLog',
  'signature_request_history',
  'signature_history',
];
export const deepRenderViewContent = (data: any, key: string) => {
  return (
    data &&
    Object.keys(data).map((item, i) => {
      if (isObject(data[item])) {
        return deepRenderViewContent(data[item], item);
      }
      return (
        <DataField key={i}>
          <div>{startCase(item)}</div>
          <p>{valueOrHyphen(String(data[item]))}</p>
        </DataField>
      );
    })
  );
};

export const AdditionalMetaInformation: FunctionComponent = props => {
  const { state } = useContext(SelectedMerchantStore);
  const [metaData, setMetaData] = useState<{} | null>(null);
  const registrationMeta = state?.registration?.meta;

  useEffect(() => {
    let meta = { ...registrationMeta };
    ignoreFields.forEach(item => {
      delete meta[item];
    });
    setMetaData(Object.keys(meta).length ? meta : null);
  }, [registrationMeta, setMetaData]);

  return (
    <UnderwritingSection
      title="Additional Meta Information"
      id="underwriting-additional-meta-information"
      data-testid="underwriting-additional-meta-information"
      isEditable={false}
    >
      <SectionWrap>
        {metaData ? (
          Object.keys(metaData).map((item, i) => {
            return (
              <DataField key={i}>
                <div>{item}</div>
                <p>
                  {valueOrHyphen(
                    typeof metaData[item] === 'boolean'
                      ? metaData[item].toString()
                      : typeof metaData[item] === 'object'
                      ? JSON.stringify(metaData[item], null, 2)
                      : metaData[item]
                  )}
                </p>
              </DataField>
            );
          })
        ) : (
          <DataField>
            <p>No Additional Meta</p>
          </DataField>
        )}
      </SectionWrap>
    </UnderwritingSection>
  );
};
