import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const BoldStyles = styled.span`
  font-family: 'Roboto';
  font-weight: 700;
  line-height: 27px;
`;

export const BoldText: FunctionComponent<{
  color?: string;
  fontSize?: number;
  marginBottom?: number;
  marginRight?: number;
  lineHeight?: number;
  inline?: boolean;
}> = ({
  color = '#fff',
  fontSize = 18,
  marginBottom = 16,
  marginRight = 0,
  lineHeight = 27,
  inline = false,
  children
}) => {
  return (
    <BoldStyles
      style={{
        color,
        fontSize,
        marginBottom,
        marginRight,
        lineHeight: `${lineHeight}px`,
        display: inline ? 'inline' : 'block'
      }}
    >
      {children}
    </BoldStyles>
  );
};
