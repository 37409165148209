import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react';
import { SelectedMerchantStore } from '../../../context';
import { FundingAccount } from '@fattmerchantorg/types-omni';
import styled from 'styled-components';
import { Attachments } from '../../shared/Attachments';
import { coreapi } from '../../../api';
import { useToaster, useAuthToken } from '../../../hooks';

const Container = styled.div`
  > div {
    margin: 8px 0 0 0;
  }
`;

export const BankAccountFiles: FunctionComponent<{
  accountId: FundingAccount['id'];
  accountFiles?: FundingAccount['files'];
  /** Function to fire every time files are refetched. Must be memoized. */
  onFetchFiles?: (files: FundingAccount['files']) => void;
  /** Function to fire after file(s) are uploaded. Must be memoized */
  onUpload?: () => void;
}> = props => {
  const { state } = useContext(SelectedMerchantStore);
  const { merchantId } = state;
  const { accountId, accountFiles, onFetchFiles, onUpload } = props;
  const shouldFetchAccountFiles = accountId && !Array.isArray(accountFiles);

  const [files, setFiles] = useState(accountFiles || []);
  const authToken = useAuthToken();
  const { toast, toaster } = useToaster();

  const fetchBankAccountFiles = useCallback(async () => {
    try {
      const account = await coreapi.get<FundingAccount>(
        authToken,
        `/merchant/${merchantId}/funding-account/${accountId}`
      );
      setFiles(account.files);
    } catch (error) {
      toaster(toast.error(error, 'Error loading bank account files'));
    }
  }, [accountId, authToken, toaster, toast, merchantId]);

  const handleOnUpload = useCallback(() => {
    fetchBankAccountFiles();
    if (onUpload) onUpload();
  }, [onUpload, fetchBankAccountFiles]);

  useEffect(() => {
    if (shouldFetchAccountFiles) fetchBankAccountFiles();
  }, [fetchBankAccountFiles, shouldFetchAccountFiles]);

  useEffect(() => {
    if (onFetchFiles) onFetchFiles(files);
  }, [files, onFetchFiles]);

  if (!accountId) return null;

  return (
    <Container>
      <Attachments
        downloadable
        files={files}
        uploadUrl={`/merchant/${merchantId}/funding-account/${accountId}/file`}
        onUpload={handleOnUpload}
        getDeleteUrl={file =>
          `/merchant/${merchantId}/funding-account/${accountId}/file/${file.id}`
        }
        onDelete={fetchBankAccountFiles}
      />
    </Container>
  );
};
