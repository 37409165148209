import React, { FunctionComponent, useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { useAsyncEffect, useAuthToken, useToaster } from '../../../../hooks';
import { Import } from '../../../../@types';
import { importerapi } from '../../../../api';
import { RouteItem } from '../../../routes/models/RouteItem.model';
import { Spinner } from '../../../shared';
import {
  ImportStore,
  SelectedMerchantStore,
  updateImport,
  updateTargetColumns,
} from '../../../../context';
import { useRouteMatchImportId } from '../../../../hooks';
import {
  ImporterSteps,
  ImporterUpload,
  ImporterMatch,
  ImporterSummary,
} from './';
import { FullPage } from '../../../shared';

const Container = withTheme(
  styled.div`
    * {
      color: ${({ theme }) => theme.white};
    }
    background: ${({ theme }) => theme.black};
    width: 100%;
    min-height: 100%;
    padding: 80px 5% calc(65px + 1rem);
    display: flex;
    align-items: center;
    flex-direction: column;

    > h1 {
      padding-bottom: 136px;
      font-size: 48px;
    }
  `
);

const SelectedMerchantCompanyName = withTheme(
  styled.div`
    h2 {
      color: ${({ theme }) => theme.white};
    }
    padding: 24px;
    width: 100%;
    text-align: left;
    font-size: 32px;
    position: absolute;

    i {
      padding-right: 0.5rem;
    }
  `
);

const Content = styled.div`
  padding-top: 5%;
  width: auto;
  min-width: min(60rem, 100%);
  max-width: 140rem;
  padding-top: 2rem;
`;

const routes: RouteItem[] = [
  {
    name: 'Upload',
    path: '/merchant/:merchantId/imports/:importId/upload',
    component: ImporterUpload,
  },
  {
    name: 'Match Columns',
    path: '/merchant/:merchantId/imports/:importId/match',
    component: ImporterMatch,
  },
  {
    name: 'Review',
    path: '/merchant/:merchantId/imports/:importId/summary',
    component: ImporterSummary,
  },
];

const renderRedirect = (importId: string, record: Import) => {
  let to: string, from: string;

  if (record && record.state !== 'UPLOAD_COMPLETE') {
    from = '/merchant/:merchantId/imports/:importId';
    to = '/merchant/:merchantId/imports/:importId/summary';
  } else if (record && record.state === 'UPLOAD_COMPLETE') {
    from = '/merchant/:merchantId/imports/:importId';
    to = '/merchant/:merchantId/imports/:importId/match';
  } else if (record || importId === 'new') {
    from = '/merchant/:merchantId/imports/:importId';
    to = '/merchant/:merchantId/imports/:importId/upload';
  } else {
    from = '/merchant/:merchantId/imports/:importId/*';
    to = '/merchant/:merchantId/imports/:importId';
  }

  return to && from && <Redirect exact to={to} from={from} />;
};

export const Importer: FunctionComponent = () => {
  const authToken = useAuthToken();
  const importId = useRouteMatchImportId();
  const {
    state: { merchant },
  } = useContext(SelectedMerchantStore);
  const { toast, toaster } = useToaster();

  const {
    dispatch,
    state: { record },
  } = useContext(ImportStore);

  const recordId = record?.id;
  const isActionable =
    importId === 'new' || record?.state === 'UPLOAD_COMPLETE';

  useAsyncEffect(
    async isMounted => {
      if (!authToken) return;
      try {
        const targetColumns = await importerapi.get(
          authToken,
          '/target-columns'
        );
        if (isMounted()) dispatch(updateTargetColumns(targetColumns));
      } catch (error) {
        toaster(toast.error(error, 'There was a problem loading the import.'));
      }
    },
    [authToken]
  );

  useAsyncEffect(
    async isMounted => {
      const shouldFetch =
        authToken && importId && importId !== 'new' && importId !== recordId;

      if (!shouldFetch) return;

      try {
        const i = await importerapi.get(authToken, `/import/${importId}`);
        if (i && isMounted()) dispatch(updateImport(i));
      } catch (error) {
        toaster(toast.error(error, 'There was a problem loading the import.'));
      }
    },
    [authToken, importId, recordId]
  );

  return (
    <FullPage>
      <SelectedMerchantCompanyName>
        {!!merchant && (
          <h2>
            <i className="fas fa-store" />
            {merchant.company_name}
          </h2>
        )}
      </SelectedMerchantCompanyName>
      <Container>
        <h1>Vault Importer Tool</h1>
        {isActionable && <ImporterSteps routes={routes} />}
        <Content>
          <Switch>
            {renderRedirect(importId, record)}
            <Route exact path="/merchant/:merchantId/imports/:importId/">
              <div style={{ textAlign: 'center' }}>
                <Spinner />
              </div>
            </Route>
            {isActionable ? (
              routes.map((route, index) => (
                <Route key={index} exact {...route} />
              ))
            ) : (
              // if this import is done or pending, only ever show the summary
              <ImporterSummary />
            )}
          </Switch>
        </Content>
      </Container>
    </FullPage>
  );
};
