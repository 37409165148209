import { buildApi } from '../util/api.util';

export const authapi = buildApi(process.env.REACT_APP_AUTH_API_URL);

/**
 * The response from the auth service route POST /test-mode-toggle
 * when the `returnType` query parameter is specified as `tokens`.
 */
export interface TestModeToggleResponse {
  tokens: [string];
}
