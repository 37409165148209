import { Registration } from '@fattmerchantorg/types-omni';
import {
  PricingInfoFieldsErrors,
  PricingInfoFieldsValues,
  PricingInfoFields,
} from './PricingInfoForm.types';
import { pick } from '../../../../util/object.util';
import {
  formatCurrency,
  formatPercent,
  toDecimalString,
  leaveAsNull,
} from '../../../../util';
import {
  businessTypes,
  networks,
  processingMethods,
  procChanges,
} from '../../../../util/registration.util';
import { BrandWithPricing } from '../processing-environment/ProcessingEnvironment';

export const toMoney = val => {
  return formatCurrency(toDecimalString(val));
};

export function mapFormValuesToPayload(
  formValues: PricingInfoFieldsValues
): Partial<Registration> {
  return {
    credit_qual_trans_fee: toDecimalString(formValues.credit_qual_trans_fee),
    credit_mid_trans_fee: toDecimalString(formValues.credit_mid_trans_fee),
    credit_non_trans_fee: toDecimalString(formValues.credit_non_trans_fee),
    amex_qual_trans_fee: toDecimalString(formValues.amex_qual_trans_fee),
    amex_mid_trans_fee: toDecimalString(formValues.amex_mid_trans_fee),
    amex_non_trans_fee: toDecimalString(formValues.amex_non_trans_fee),
    credit_qual_disc_rate: toDecimalString(formValues.credit_qual_disc_rate),
    credit_mid_disc_rate: toDecimalString(formValues.credit_mid_disc_rate),
    credit_non_disc_rate: toDecimalString(formValues.credit_non_disc_rate),
    amex_qual_disc_rate: toDecimalString(formValues.amex_qual_disc_rate),
    amex_mid_disc_rate: toDecimalString(formValues.amex_mid_disc_rate),
    amex_non_disc_rate: toDecimalString(formValues.amex_non_disc_rate),
  };
}

export function mapRegistrationToInitialFormValues(
  registration: Registration
): PricingInfoFieldsValues {
  const data = pick(
    registration,
    'credit_qual_trans_fee',
    'credit_mid_trans_fee',
    'credit_non_trans_fee',
    'amex_qual_trans_fee',
    'amex_mid_trans_fee',
    'amex_non_trans_fee',
    'credit_qual_disc_rate',
    'credit_mid_disc_rate',
    'credit_non_disc_rate',
    'amex_qual_disc_rate',
    'amex_mid_disc_rate',
    'amex_non_disc_rate'
  ) as PricingInfoFieldsValues;

  return {
    ...data,
    credit_qual_trans_fee: formatCurrency(data.credit_qual_trans_fee),
    credit_mid_trans_fee: formatCurrency(data.credit_mid_trans_fee),
    credit_non_trans_fee: formatCurrency(data.credit_non_trans_fee),
    amex_qual_trans_fee: formatCurrency(data.amex_qual_trans_fee),
    amex_mid_trans_fee: formatCurrency(data.amex_mid_trans_fee),
    amex_non_trans_fee: formatCurrency(data.amex_non_trans_fee),
    credit_qual_disc_rate: formatPercent(+data.credit_qual_disc_rate),
    credit_mid_disc_rate: formatPercent(+data.credit_mid_disc_rate),
    credit_non_disc_rate: formatPercent(+data.credit_non_disc_rate),
    amex_qual_disc_rate: formatPercent(+data.amex_qual_disc_rate),
    amex_mid_disc_rate: formatPercent(+data.amex_mid_disc_rate),
    amex_non_disc_rate: formatPercent(+data.amex_non_disc_rate),
  };
}

export function mapPricingFieldsToFormValues<
  T extends Pick<Registration, PricingInfoFields>
>(fields: T, brand: BrandWithPricing): T {
  const formatters = {
    chosen_processing_method: value => {
      return processingMethods
        .map(type => {
          return { label: type.description, value: type.key };
        })
        .find(option => option.value === value);
    },
    pricing_plan: value => {
      return (brand?.filteredPricingFields ?? [])
        .map(type => {
          return { label: type.displayName, value: type.name };
        })
        .find(option => option.value === value);
    },
    plan_txamnt: leaveAsNull(formatCurrency),
    credit_mid_trans_fee: leaveAsNull(formatCurrency),
    credit_non_trans_fee: leaveAsNull(formatCurrency),
    credit_qual_trans_fee: leaveAsNull(formatCurrency),
    cp_per_item_rate: leaveAsNull(formatCurrency),
    amex_mid_trans_fee: leaveAsNull(formatCurrency),
    amex_non_trans_fee: leaveAsNull(formatCurrency),
    amex_qual_trans_fee: leaveAsNull(formatCurrency),
    cp_amex_per_item_rate: leaveAsNull(formatCurrency),
    cnp_debit_trans_fee: leaveAsNull(formatCurrency),
    cp_debit_trans_fee: leaveAsNull(formatCurrency),
    plan_ach_txamnt: leaveAsNull(formatCurrency),
    plan_dcamnt: leaveAsNull(formatPercent),
    credit_mid_disc_rate: leaveAsNull(formatPercent),
    credit_non_disc_rate: leaveAsNull(formatPercent),
    credit_qual_disc_rate: leaveAsNull(formatPercent),
    cp_transaction_rate: leaveAsNull(formatPercent),
    amex_mid_disc_rate: leaveAsNull(formatPercent),
    amex_non_disc_rate: leaveAsNull(formatPercent),
    amex_qual_disc_rate: leaveAsNull(formatPercent),
    cp_amex_rate: leaveAsNull(formatPercent),
    cnp_debit_discount_rate: leaveAsNull(formatPercent),
    cp_debit_discount_rate: leaveAsNull(formatPercent),
    plan_ach_dcamnt: leaveAsNull(formatPercent),
    plan_ach_cap: leaveAsNull(formatCurrency),
    monthly_minimum_fee: leaveAsNull(formatCurrency),
    monthly_service_fee: leaveAsNull(formatCurrency),
    cp_credit_surcharge_rate: leaveAsNull(formatPercent),
    cnp_credit_surcharge_rate: leaveAsNull(formatPercent),
    is_flat_rate: value => value,
    proc_change: value => {
      return procChanges
        ?.map(type => {
          return { label: type.name, value: type.key };
        })
        .find(option => option.value === value);
    },
    network: value => {
      return networks
        ?.map(type => {
          return { label: type.value, value: type.key };
        })
        .find(option => option.value === value);
    },
    mcc: value => {
      return (brand?.merchantCategoryCodes ?? [])
        .map(code => {
          return { label: code.displayName, value: code.name };
        })
        .find(option => option.value === value);
    },
    bus_type: value => {
      return businessTypes
        .map(type => {
          return { label: type.value, value: type.key };
        })
        .find(option => option.value === value);
    },
  };

  const result = { ...fields };

  for (const key in result) {
    const formatter = formatters[key as string];
    if (formatter) {
      result[key] = formatter(result[key]);
    }
  }

  return result;
}

export function validateRequiredFormValues(
  values: PricingInfoFieldsValues
): PricingInfoFieldsErrors {
  const errors = {} as PricingInfoFieldsErrors;

  const requiredFields = [];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
}

export function validateFormValues(
  values: PricingInfoFieldsValues
): PricingInfoFieldsErrors {
  const errors = {} as PricingInfoFieldsErrors;

  return errors;
}
