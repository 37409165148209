import { pick } from '../../../util/object.util';

import { Mutator } from 'final-form';
import { ChannelSelectOption } from '../util/billingProfile.util';
import {
  DisputeFeeFieldsErrors,
  DisputeFeeFieldsValues,
  DisputeFeePayload,
} from './DisputeFeeForm.types';

export function mapFormValuesToPayload(
  formValues: DisputeFeeFieldsValues
): DisputeFeePayload {
  return {
    /** Not in UI */
    type: 'DISPUTE',
    state: 'ACTIVE',
    brand_id: null,
    /** -- */
    billing_cycle_day: formValues.billing_cycle_day,
    billing_cycle: formValues.billing_cycle,
    channel: formValues.channel?.value,
    fee_cap_amount: formValues.fee_cap_amount,
    deposit_account_id: formValues.deposit_account_id,
    fees_account_id: formValues.fees_account_id,
    is_gross_settlement: formValues.is_gross_settlement === 'true',
    per_transaction_amount: formValues.per_transaction_amount ?? 0,
    subchannel: formValues.subchannel?.value,
    funding_schedule_days: formValues.funding_schedule_days,
  };
}

export function mapInquiryFormValuesToPayload(
  formValues: DisputeFeeFieldsValues
): DisputeFeePayload {
  return {
    /** Not in UI */
    type: 'INQUIRY',
    state: 'ACTIVE',
    brand_id: null,
    /** -- */
    billing_cycle_day: formValues.billing_cycle_day,
    billing_cycle: formValues.billing_cycle,
    channel: formValues.channel?.value,
    fee_cap_amount: formValues.fee_cap_amount,
    fees_account_id: formValues.fees_account_id,
    is_gross_settlement: formValues.is_gross_settlement === 'true',
    per_transaction_amount: formValues.inquiry_fee ?? 15,
    subchannel: formValues.subchannel?.value,
    funding_schedule_days: formValues.funding_schedule_days,
    deposit_account_id: formValues.deposit_account_id,
  };
}

export function mapBillingProfileToInitialFormValues(
  billingProfile: Partial<DisputeFeeFieldsValues>
) {
  return pick(
    billingProfile,
    'billing_cycle_day',
    'billing_cycle',
    'channel',
    'fee_cap_amount',
    'fees_account_id',
    'is_gross_settlement',
    'per_transaction_amount',
    'subchannel',
    'inquiry_fee',
    'inquiry_fee_id',
    'funding_schedule_days',
    'deposit_account_id'
  );
}

export function validateFormValues(
  values: DisputeFeeFieldsValues
): DisputeFeeFieldsErrors {
  const errors = {} as DisputeFeeFieldsErrors;

  if (!values.channel) {
    errors.channel = 'A channel must be selected.';
  }

  if (!['BANK', 'ALL'].includes(values.channel?.value) && !values.subchannel) {
    errors.subchannel = 'A subchannel must be selected.';
  }

  if (!values.fees_account_id) {
    errors.fees_account_id = 'A fees account must be selected.';
  }

  if (
    values.billing_cycle_day &&
    (values.billing_cycle_day < 1 || values.billing_cycle_day > 28)
  ) {
    errors.billing_cycle_day =
      'Billing Cycle Day of Month must be set to a value between 1-28.';
  }
  if (values.per_transaction_amount < 0) {
    errors.per_transaction_amount = 'A positive fee amount must be provided.';
  }
  return errors;
}

export const formMutators: {
  [key: string]: Mutator<DisputeFeeFieldsValues>;
} = {
  setDefaultSubchannel: (
    args: ChannelSelectOption['value'][],
    state,
    utils
  ) => {
    const channel = args[0];

    if (['BANK', 'ALL'].includes(channel)) {
      utils.changeValue(state, 'subchannel', () => null);
    }
  },

  unsetFeeCap: (_args, state, utils) => {
    utils.changeValue(state, 'fee_cap_amount', () => {
      return null;
    });
  },
};
