import React, { useContext, FunctionComponent } from 'react';
import styled from 'styled-components';
import { RegistrationRepresentative } from '@fattmerchantorg/types-omni';
import { SecondaryButton, Icon } from '@fattmerchantorg/truffle-components';
import { UnderwritingSection } from '../shared/UnderwritingSection';
import { ModalContext } from '../../../../context';
import { SelectedMerchantStore } from '../../../../context';
import { AdditionalRepresentativesFormModal } from './AdditionalRepresentativesFormModal';
import { Representative } from './Representative';

export interface AdditionalRepresentativesProps {}

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const RepsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-columns: minmax(25%, 100%);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  width: 100%;

  > div {
    border-right: 1px solid ${({ theme }) => theme.lightNeutralOutline};

    &:last-child,
    &:nth-child(4n) {
      border-right: 0;
    }
  }
`;

const AddRep = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 240px;
  min-width: 25%;
`;

const NoReps = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  justify-content: center;
  align-items: center;

  ${AddRep} {
    min-height: 0;
    min-width: 0;
  }

  > p {
    font-size: 16px;
  }
`;

const Disclaimer = styled.p`
  width: 100%;
  font-size: 12px;
  margin: 8px 0 0;
`;

interface AddRepButtonProps {
  handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const AddRepButton: FunctionComponent<AddRepButtonProps> = props => {
  const { handleClick } = props;
  return (
    <AddRep>
      <SecondaryButton onClick={handleClick}>
        <Icon icon={['fas', 'plus']} />
        Add Rep
      </SecondaryButton>
    </AddRep>
  );
};

export function AdditionalRepresentatives(
  props: AdditionalRepresentativesProps
) {
  const { modalDispatch } = useContext(ModalContext);

  const {
    dispatch,
    state: { merchantId, registration },
  } = useContext(SelectedMerchantStore);

  const representatives = Array.isArray(registration?.meta?.representatives)
    ? registration?.meta?.representatives
    : [];

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    newRep?: boolean
  ) => {
    e.stopPropagation();

    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        component: AdditionalRepresentativesFormModal,
        props: {
          isOpen: true,
          registration: registration,
          merchantId: merchantId,
          merchantDispatch: dispatch,
          isNewRep: newRep,
          newRepIndex: newRep ? representatives.length : null,
        },
      },
    });
  };

  return (
    <UnderwritingSection
      title="Additional Representatives"
      id="underwriting-additional-representatives"
      isEditable={representatives.length > 0}
      onEdit={handleClick}
    >
      {({ setToolTipContent }) => {
        setToolTipContent(`Additional Representatives`);
        return (
          <CardContent>
            {representatives.length > 0 ? (
              <RepsContainer>
                {representatives.map(
                  (rep: RegistrationRepresentative, index: number) => {
                    return (
                      <Representative
                        repData={rep}
                        repIndex={index}
                        key={index}
                      />
                    );
                  }
                )}
                <AddRepButton handleClick={e => handleClick(e, true)} />
              </RepsContainer>
            ) : (
              <NoReps>
                <p>No Additional Representatives</p>
                <AddRepButton handleClick={e => handleClick(e, true)} />
              </NoReps>
            )}
            <Disclaimer>
              Effective May 11, 2018, the Federal FinCen (Financial Crimes
              Enforcement Network) requires all financial institutions to
              collect the Control Owner and Beneficial Owners on entities that
              are not otherwise exempt. Please provide information on any
              additional representatives if any representatives (Beneficial
              Owner) directly or indirectly own 25% or more of the entity.
            </Disclaimer>
          </CardContent>
        );
      }}
    </UnderwritingSection>
  );
}
