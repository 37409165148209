import React, { FunctionComponent } from 'react';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { Icon } from '@fattmerchantorg/truffle-components';

export const AsyncIcon: FunctionComponent<{
  className?: string;
  loading: boolean;
  icon: FontAwesomeIconProps['icon'];
}> = props => {
  return props.loading ? (
    <i className="fas fa-circle-notch fa-spin"></i>
  ) : (
    <Icon icon={props.icon} className={props.className} />
  );
};
