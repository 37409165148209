import React from 'react';
import {
  TransactionData,
  TransactionHistorySectionData,
} from '../AdvancedDetailDrawer.types';
import {
  Accordion,
  TableBody,
  TableRow,
  StatusPill,
} from '@fattmerchantorg/truffle-components';
import TransactionHistoryStatusPill from '../../../transactions/components/TransactionHistoryStatusPill';
import styled from 'styled-components';
import {
  formatCurrency,
  formatReadableDate,
} from '../../../../util/format.util';
import { AccordionWrapper } from '../sharedComponents';
import { mapTransactionType } from '../../../../util/transaction.util';

type TransactionHistorySectionProps = {
  id: string;
  name: string;
  data: TransactionHistorySectionData;
};

const TableAccordion = styled.table`
  width: 100%;

  tbody {
    tr {
      &:hover {
        background: none !important;
      }
    }
  }
`;

const TableSectionLeft = styled.td`
  padding: 4px 8px;
  width: 40%;
  max-width: 175px;
`;

const TableSectionCenter = styled.td`
  text-align: left;
  width: 33.3%;
`;

const TableSectionRight = styled.td`
  text-align: right;
  padding: 8px;
`;

const TableItem = styled.span`
  border: none;
  color: #f8f9fe;
  padding: 8px;
  font-size: 14px;
  font-weight: 400px;
  line-height: 20px;
`;

const TransactionHistorySection: React.FunctionComponent<
  TransactionHistorySectionProps
> = props => {
  const {
    data,
    name = 'Transaction History',
    id = 'transaction-history-drawer-section',
  } = props;

  if (!data) return null;

  const { history } = data;

  const transactionTotal = (total: number) => {
    if (total >= 0) {
      return formatCurrency(total);
    } else {
      return formatCurrency(Math.abs(total));
    }
  };
  const transactionSuccess = (transaction: TransactionData) => {
    if (transaction?.success || transaction?.state !== 'REJECTED') {
      return true;
    }
  };
  return (
    <AccordionWrapper id={id}>
      <Accordion
        contentStyle={{ padding: '0' }}
        title={name}
        asyncContent={true}
      >
        <TableAccordion>
          <TableBody>
            {history?.map(transaction => (
              <TableRow
                style={{ border: 'none', margin: 0 }}
                key={transaction?.id}
              >
                <TableSectionLeft>
                  <TableItem>
                    {transaction?.success !== undefined ? (
                      <TransactionHistoryStatusPill
                        transaction={
                          {
                            success: transactionSuccess(transaction),
                            type: transaction?.type,
                            state: transaction?.state,
                          } as TransactionData
                        }
                      />
                    ) : (
                      <StatusPill {...mapTransactionType(transaction?.type)} />
                    )}
                  </TableItem>
                </TableSectionLeft>
                <TableSectionCenter>
                  <TableItem>
                    {formatReadableDate(transaction?.created_at?.toString())}
                  </TableItem>
                </TableSectionCenter>
                <TableSectionRight>
                  <TableItem>
                    {transaction?.total
                      ? transactionTotal(transaction?.total)
                      : transactionTotal(transaction?.amount)}
                  </TableItem>
                </TableSectionRight>
              </TableRow>
            ))}
          </TableBody>
        </TableAccordion>
      </Accordion>
    </AccordionWrapper>
  );
};

export default TransactionHistorySection;
