import React, { FunctionComponent } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { ContentSeparator, PageContent } from '../SettingsPagesStylings';
import { RolesList } from './RolesList';
import { RolesDrawer } from './RolesDrawer';

export const RolesPage: FunctionComponent = () => {
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>('/settings/roles/id/:id');
  const selectedRole = match?.params?.id;

  const handleClose = () => {
    history.push('/settings/roles');
  };

  return (
    <ContentSeparator>
      <PageContent>
        <RolesList />
        <RolesDrawer roleId={selectedRole} close={handleClose} />
      </PageContent>
    </ContentSeparator>
  );
};
