import { TestModeAction, TestModeActionType } from './TestMode.types';

export function updateAutomaticSwitchModalStatus(
  isAutomaticRedirectModalOpen: boolean
): TestModeAction {
  return {
    type: TestModeActionType.UPDATE_AUTOMATIC_REDIRECT_MODAL_STATUS,
    payload: isAutomaticRedirectModalOpen
  };
}
