import React, { useState } from 'react';
import styled from 'styled-components';
import { Text } from '@fattmerchantorg/truffle-components';
import {
  GetFeeStatementDailySalesDatum,
  GetFeeStatementDailySalesResponse,
  GetFeeStatementDailySalesSummary,
} from '@fattmerchantorg/types-omni';
import { TableSectionV3 } from '../styles/table-section-component-v3';
import { StatementV3Props } from '../util/statement-v3-utils';
import { useAsyncEffect, useAuthToken } from '../../../../hooks';
import { queryapi } from '../../../../api';
import { TableNullState } from '../styles';
import { formatDate } from '../../../../util/date.util';
import {
  LoadingState,
  useStatementLoadingState,
} from '../../../../context/statements';
import currency from 'currency.js';
import { TextContainer } from '../../components';

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Table = styled(TableSectionV3)`
  > table > tbody > tr > td.negativeAmount,
  > table > tfoot > tr > td.negativeAmount {
    color: ${props => props.theme.colors.status.red['500'].hex};
  }
`;

export const DailySalesSection: React.FC<StatementV3Props> = React.forwardRef(
  (props, ref) => {
    const { startDate, endDate } = props;
    const authToken = useAuthToken();

    const [data, setData] = useState<GetFeeStatementDailySalesDatum[]>();
    const [summary, setSummary] = useState<GetFeeStatementDailySalesSummary>();
    const { dailySalesLoading, setDailySalesLoading } =
      useStatementLoadingState();

    useAsyncEffect(async () => {
      if (
        dailySalesLoading === LoadingState.Loading &&
        authToken &&
        startDate &&
        endDate
      ) {
        try {
          const query = { startDate, endDate };
          const response =
            await queryapi.get<GetFeeStatementDailySalesResponse>(
              authToken,
              'statement/v3/daily-sales',
              query
            );

          if (!response) return;

          setData(response.data);
          setSummary(response.summary);
          setDailySalesLoading(LoadingState.Completed);
        } catch (error) {
          setDailySalesLoading(LoadingState.Failed);
        }
      }
    }, [dailySalesLoading, authToken, startDate, endDate]);

    if (dailySalesLoading !== LoadingState.Completed || !data || !summary)
      return null;

    return (
      <Table ref={ref}>
        <SectionHeader>
          <TextContainer fontWeight="bold" paddingBottom="8px"></TextContainer>
          <Text as="h5">Daily Sales</Text>
        </SectionHeader>
        <table data-testid="statementV3-sales">
          <colgroup>
            <col style={{ width: '70%' }} />
            <col
              style={{ width: summary.surcharge_total > 0 ? '10%' : '15%' }}
            />
            <col
              style={{ width: summary.surcharge_total > 0 ? '10%' : '15%' }}
            />
            {summary.surcharge_total > 0 && <col style={{ width: '10%' }} />}
          </colgroup>
          <thead>
            <tr>
              <th>Date</th>
              <th>Sales</th>
              {summary.surcharge_total > 0 && <th>Surcharged</th>}
              <th>Total Fees</th>
            </tr>
          </thead>
          <tbody>
            {data.map((datum, i) => (
              <tr key={i} className="tableRows">
                <td>{formatDate(datum.date, 'MMM dd')}</td>
                <td className={datum.sales_total < 0 ? 'negativeAmount' : ''}>
                  {currency(datum.sales_total).format()}
                </td>
                {summary.surcharge_total > 0 && (
                  <td>{currency(datum.surcharge_total).format()}</td>
                )}
                <td>{currency(datum.fees_total).format()}</td>
              </tr>
            ))}
          </tbody>
          {!!data.length && (
            <tfoot>
              <tr>
                <td>
                  <strong>Total</strong>
                </td>
                <td className={summary.sales_total < 0 ? 'negativeAmount' : ''}>
                  <strong>{currency(summary.sales_total).format()}</strong>
                </td>
                {summary.surcharge_total > 0 && (
                  <td>
                    <strong>
                      {currency(summary.surcharge_total).format()}
                    </strong>
                  </td>
                )}
                <td>
                  <strong>{currency(summary.fees_total).format()}</strong>
                </td>
              </tr>
            </tfoot>
          )}
        </table>
        {!data.length && <TableNullState>No Daily Sales</TableNullState>}
      </Table>
    );
  }
);
