import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Props as SelectProps, ValueType } from 'react-select';
import styled, { withTheme } from 'styled-components';
import { BarChart } from './components/TransactionsWidget/TransactionsChart';
import { LineChart } from './components/line-chart';
import {
  Select as TruffleSelect,
  Icon,
  Dropdown,
  DropdownContent,
  Modal,
  RadioButton,
} from '@fattmerchantorg/truffle-components';
import { ChartTypes } from '../../@types';
import { TransactionsInfoIcon } from './components/TransactionsWidget/TransactionsInfoIcon';
import { MerchantPerformanceInfoIcon } from './components/MerchantPerformanceWidget/MerchantPerformanceInfoIcon';
import { TimeRange } from './@types/TimeRange';
import {
  useTransactionChartHook,
  sendUserSelectedTimespan,
  sendUserRequestedNoCacheRefresh,
  sendUserSelectedDataSet,
} from './useTransactionChartHook';
import {
  useMerchantPerformanceHook,
  sendMerchantPerformanceTimespan,
  sendUserSelectedPerformanceDataSet,
} from './useMerchantPerformanceHook';
import { VolumeCountAverageColumn } from './VolumeCountAvgColumn';
import { MerchantPerformanceTable } from './components/MerchantPerformanceTable';
import { RegistrationFlow } from './components/RegistrationFlowWidget/RegistrationFlow';
import { BoldText } from './components/shared/BoldText';
import {
  useResidualsData,
  sendResidualsUserSelectedTimespan,
} from './components/ResidualsWidget/useResidualsData';
import {
  AuthStore,
  deriveAuthMode,
  SelectedMerchantStore,
} from '../../context';
import { usePrevious } from '../../hooks';
import { withAlpha } from '../../util/color.util';
import { Tooltip } from '@fattmerchantorg/truffle-components';
import moment from 'moment';
import { DataSet } from './@types/DataSet';
import { queryapi } from '../../api';
import { getTimezoneName } from '../../util/date.util';
import { ErrorBoundary } from '../error/ErrorBoundary';

type DashboardData = { dashboard: string; url: string };
interface DashboardsResponse {
  data: Array<DashboardData>;
}

const Select: FunctionComponent<SelectProps> = TruffleSelect;

export type OptionType = {
  label: string;
  value: TimeRange;
};

export const options: OptionType[] = [
  { label: 'Today', value: 'TODAY' },
  { label: 'Yesterday', value: 'YESTERDAY' },
  { label: 'This Week', value: 'THIS_WEEK' },
  { label: 'Last Week', value: 'LAST_WEEK' },
  { label: 'This Month', value: 'THIS_MONTH' },
  { label: 'Last Month', value: 'LAST_MONTH' },
  { label: 'This Year', value: 'THIS_YEAR' },
  { label: 'Last Year', value: 'LAST_YEAR' },
];

const WidgetRow = withTheme(
  styled(Row)`
    background: ${({ theme }) =>
      withAlpha(theme.colors.core.gray[50].hex, 0.1)};
    margin-bottom: 24px;
    padding: 16px;
    border-radius: 2px;
  `
);

const TruffleSelectOverride = withTheme(
  styled.div`
    @media (min-width: 1200px) {
      margin-left: 16px;
    }

    margin-right: 8px;
    flex: 1;
    // This is needed to remove Truffle's fixed min-width;
    > div {
      min-width: unset;
    }

    // The styles below will get simplified when Truffle allows
    // more styles to be overridable (in progress).
    div div {
      background: ${({ theme }) => theme.colors.core.gray[800].hex};
      color: ${({ theme }) => theme.white};
    }

    .truffle-select__dropdown-indicator,
    .truffle-select__dropdown-indicator:hover {
      color: ${({ theme }) => theme.colors.core.green[400].hex};
    }

    .truffle-select__control,
    .truffle-select__menu {
      border: 1px solid ${({ theme }) => theme.colors.core.gray[600].hex};
      border-radius: 2px;

      .truffle-select__option--is-selected,
      .truffle-select__option:hover {
        background: ${({ theme }) => theme.colors.core.gray[600].hex};
        border-radius: 2px;
      }
    }
  `
);

const VerticallyResponsiveCol = styled(Col)`
  padding: 0;
  @media (max-width: 1199px) {
    margin-top: 40px;
    order: 2; /* Edge case. There is a gap between XL and LG breakpoints where "order:2" should apply sooner. */
  }
`;

const VerticalLineWrapper = withTheme(
  styled.div`
    @media (min-width: 1200px) {
      margin-top: 16px;
      border-left: 1px solid ${({ theme }) => theme.white};
      margin-left: 16px;
      padding-left: 16px;
    }
  `
);
const ContextMenu = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li a,
  li button {
    display: block;
    background: none;
    border: 0;
    padding: 4px 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    text-align: left;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.core.white[0].hex};

    &:hover {
      background: ${({ theme }) => theme.component.table.borderColor};
    }
  }
`;

const IconButton = styled.button`
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.core.white[0].hex};
`;

// Fixes alignment issue with truffle radio label
const RadioButtonWrapper = styled.div`
  .check-mark {
    top: 4px;
  }
`;

const LightTextLink: React.FunctionComponent<any> = withTheme(props => {
  const { theme, children, style, href, target, ...rest } = props;
  return (
    <a
      {...rest}
      href={href}
      target={target}
      style={{ color: '#fff', ...style }}
    >
      {children}
    </a>
  );
});

export const TruffleDashboardPage: FunctionComponent = () => {
  const { state: authState } = useContext(AuthStore);
  const mode = deriveAuthMode(authState);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [widgetSetting, setWidgetSetting] = useState<DataSet>('API');
  const [activatedTimezone, setActivatedTimezone] = useState('');
  const [transactionTimezone, setTransactionTimezone] = useState('');
  const [createdTimezone, setCreatedTimezone] = useState('');
  const [settledTimezone, setSettledTimezone] = useState('');

  // Get "global brand" in context.
  const {
    state: { selectedBrandSwitcherOption },
  } = useContext(SelectedMerchantStore);

  // Keep track of it's previous value to only re-fetch data when neccessary.
  const previousSelectedBrandSwitcherOption: string = usePrevious<string>(
    selectedBrandSwitcherOption
  );

  // Use `Brand Switcher` to get `Residuals` data.
  const [residualsState, residualsDispatch] = useResidualsData(
    mode,
    selectedBrandSwitcherOption
  );

  const [transactionChartState, transactionChartDispatch] =
    useTransactionChartHook();

  const [merchantPerformanceState, merchantPerformanceDispatch] =
    useMerchantPerformanceHook();

  const [
    selectedTimespanForTransactionsChart,
    setSelectedTimespanForTransactionsChart,
  ] = useState<string | null>(null);

  useEffect(() => {
    // Used for Transactions and Top/Bottom Merchants.
    const defaultTimespanForSelects = 'THIS_MONTH';

    // Set it for Transactions.
    // Track selection in state so Statistics Pills tooltips can display dynamic copy.
    setSelectedTimespanForTransactionsChart(defaultTimespanForSelects);
    transactionChartDispatch(
      sendUserSelectedTimespan(defaultTimespanForSelects)
    );

    // Set it for Top/Bottom Merchants.
    merchantPerformanceDispatch(
      sendMerchantPerformanceTimespan(defaultTimespanForSelects)
    );
  }, [transactionChartDispatch, merchantPerformanceDispatch]);

  useEffect(() => {
    // If global brand (Brand Switcher) changes, re-fetch data.
    if (selectedBrandSwitcherOption !== previousSelectedBrandSwitcherOption) {
      residualsDispatch(sendResidualsUserSelectedTimespan());
      transactionChartDispatch(
        sendUserSelectedTimespan(transactionChartState.selectedTimeRange)
      );
      merchantPerformanceDispatch(
        sendMerchantPerformanceTimespan(
          merchantPerformanceState.selectedTimeRange
        )
      );
    }
  }, [
    selectedBrandSwitcherOption,
    previousSelectedBrandSwitcherOption,
    merchantPerformanceState,
    merchantPerformanceDispatch,
    transactionChartState,
    transactionChartDispatch,
    residualsDispatch,
  ]);

  const [cacheDetails, setCacheDetails] = useState(null);

  useEffect(() => {
    setCacheDetails({
      grossSales: moment
        .utc(
          transactionChartState.grossSales?.data?.selectedTimeRange?.cached_at
        )
        .local()
        .format('MMM DD, YYYY h:mm a'),
    });
  }, [transactionChartState]);

  useEffect(() => {
    transactionChartDispatch(sendUserSelectedDataSet(widgetSetting));
    merchantPerformanceDispatch(
      sendUserSelectedPerformanceDataSet(widgetSetting)
    );
  }, [merchantPerformanceDispatch, transactionChartDispatch, widgetSetting]);

  const goodDataRef = useRef(null);

  const { authToken } = authState;
  const [dashboards, setDashboard] = useState<DashboardData[]>([
    { dashboard: 'Legacy', url: '' },
  ]);
  const [currentDashboard, setCurrentDashboard] = useState(
    dashboards[0].dashboard
  );

  useEffect(() => {
    queryapi
      .get(authToken, `/dashboard`, { brand: selectedBrandSwitcherOption })
      .then((response: DashboardsResponse) => {
        if (response.data && response.data.length > 0) {
          setDashboard(response.data);
        }
      })
      .catch(error => console.error(error));
  }, [authToken, selectedBrandSwitcherOption]);

  const getGoodDataUrl = dashboard => {
    const url = dashboards.find(d => dashboard === d.dashboard)?.url;
    if (url) {
      return `${url}?hideControl=[topBar]&showNavigation=false&setHeight=${
        goodDataRef.current?.clientHeight || 0
      }&hidefilters=label.createdtimezone.timezone,label.activatedtimezone.timezone,label.transactiontimezone.timezone,label.settledtimezone.timezone`;
    } else {
      setCurrentDashboard('Legacy');
      setDashboard([{ dashboard: 'Legacy', url: '' }]);
    }
  };

  useEffect(() => {
    const url = dashboards.find(d => currentDashboard === d.dashboard)?.url;
    const workspaceUrl = url?.split('/', 20);
    const workspaceId = String(workspaceUrl[7]);
    const timezone: string = getTimezoneName();
    switch (timezone) {
      case 'CST':
        setActivatedTimezone(
          '/gdc/md/' + workspaceId + '/obj/2003/elements?id=6'
        );
        setTransactionTimezone(
          '/gdc/md/' + workspaceId + '/obj/2017/elements?id=6'
        );
        setCreatedTimezone(
          '/gdc/md/' + workspaceId + '/obj/2006/elements?id=6'
        );
        setSettledTimezone(
          '/gdc/md/' + workspaceId + '/obj/2014/elements?id=6'
        );
        break;
      case 'EST':
        setActivatedTimezone(
          '/gdc/md/' + workspaceId + '/obj/2003/elements?id=30388'
        );
        setTransactionTimezone(
          '/gdc/md/' + workspaceId + '/obj/2017/elements?id=30388'
        );
        setCreatedTimezone(
          '/gdc/md/' + workspaceId + '/obj/2006/elements?id=30388'
        );
        setSettledTimezone(
          '/gdc/md/' + workspaceId + '/obj/2014/elements?id=30388'
        );
        break;
      case 'MST':
        setActivatedTimezone(
          '/gdc/md/' + workspaceId + '/obj/2003/elements?id=30389'
        );
        setTransactionTimezone(
          '/gdc/md/' + workspaceId + '/obj/2017/elements?id=30389'
        );
        setCreatedTimezone(
          '/gdc/md/' + workspaceId + '/obj/2006/elements?id=30389'
        );
        setSettledTimezone(
          '/gdc/md/' + workspaceId + '/obj/2014/elements?id=30389'
        );
        break;
      case 'PST':
        setActivatedTimezone(
          '/gdc/md/' + workspaceId + '/obj/2003/elements?id=2'
        );
        setTransactionTimezone(
          '/gdc/md/' + workspaceId + '/obj/2017/elements?id=2'
        );
        setCreatedTimezone(
          '/gdc/md/' + workspaceId + '/obj/2006/elements?id=2'
        );
        setSettledTimezone(
          '/gdc/md/' + workspaceId + '/obj/2014/elements?id=2'
        );
        break;
      default:
        setActivatedTimezone(
          '/gdc/md/' + workspaceId + '/obj/2003/elements?id=30388'
        );
        setTransactionTimezone(
          '/gdc/md/' + workspaceId + '/obj/2017/elements?id=30388'
        );
        setCreatedTimezone(
          '/gdc/md/' + workspaceId + '/obj/2006/elements?id=30388'
        );
        setSettledTimezone(
          '/gdc/md/' + workspaceId + '/obj/2014/elements?id=30388'
        );
    }
  }, [currentDashboard, dashboards]);

  const postFilterContext = async () => {
    const postMessageBody = {
      gdc: {
        product: 'dashboard',
        event: {
          name: 'setFilterContext',
          data: {
            filters: [
              {
                positiveAttributeFilter: {
                  displayForm: {
                    identifier: 'label.activatedtimezone.timezone',
                  },
                  in: [activatedTimezone],
                },
              },
              {
                positiveAttributeFilter: {
                  displayForm: {
                    identifier: 'label.transactiontimezone.timezone',
                  },
                  in: [transactionTimezone],
                },
              },
              {
                positiveAttributeFilter: {
                  displayForm: {
                    identifier: 'label.createdtimezone.timezone',
                  },
                  in: [createdTimezone],
                },
              },
              {
                positiveAttributeFilter: {
                  displayForm: {
                    identifier: 'label.settledtimezone.timezone',
                  },
                  in: [settledTimezone],
                },
              },
            ],
          },
        },
      },
    };

    let frame = document.getElementById('goodDataFrame') as HTMLIFrameElement;
    const eventReceived = e => {
      if (
        e.data?.gdc?.event?.name === 'loadingStarted' &&
        frame.contentWindow
      ) {
        frame.contentWindow.postMessage(postMessageBody, '*');
      }
    };
    // @ts-ignore
    window.addEventListener('message', eventReceived);
  };
  return (
    <>
      <Container
        fluid={true}
        data-testid="truffle-dashboard-container"
        className="pb-1 pt-4"
      >
        {dashboards.length > 1 && (
          <div
            className="py-3"
            style={{
              margin: '0 -15px',
              maxWidth: '300px',
            }}
          >
            <ErrorBoundary>
              <Select
                isClearable={false}
                value={{ label: currentDashboard, value: currentDashboard }}
                options={dashboards.map(({ dashboard }) => ({
                  label: dashboard,
                  value: dashboard,
                }))}
                onChange={({ value }) => setCurrentDashboard(value)}
                label="Dashboard"
              />
            </ErrorBoundary>
          </div>
        )}
        {currentDashboard !== 'Legacy' && (
          <iframe
            ref={goodDataRef}
            onLoad={() => postFilterContext()}
            id="goodDataFrame"
            title="Dashboard"
            src={getGoodDataUrl(currentDashboard)}
            style={{
              width: '100%',
              height: 'calc(100vh - 4.5rem)',
              minHeight: '700px',
              margin: '0px -15px',
            }}
            width="100%"
            frameBorder="0"
          />
        )}
        {currentDashboard === 'Legacy' && (
          <>
            <WidgetRow>
              <Col className="p-0">
                <ErrorBoundary>
                  <RegistrationFlow />
                </ErrorBoundary>
              </Col>
            </WidgetRow>
            <WidgetRow>
              <Col xl={3} lg={{ order: 2 }} className="p-0 py-2">
                <div className="d-flex pl-xl-3">
                  <ErrorBoundary>
                    <TruffleSelectOverride>
                      <Select
                        data-testid="transactions-chart-filters"
                        options={options}
                        onChange={(
                          selectedOption: ValueType<OptionType, false>
                        ) => {
                          if (!selectedOption || 'length' in selectedOption) {
                            // This is not a multi-select, so this conditional should never be true
                            return;
                          } else {
                            setSelectedTimespanForTransactionsChart(
                              selectedOption.value
                            );
                            transactionChartDispatch(
                              sendUserSelectedTimespan(selectedOption.value)
                            );
                          }
                        }}
                        value={options.find(
                          option =>
                            option.value ===
                            transactionChartState.selectedTimeRange
                        )}
                      />
                    </TruffleSelectOverride>
                  </ErrorBoundary>
                  <TransactionsInfoIcon data-testid="transactions-chart-info-icon" />
                  <Dropdown
                    placement="bottom-end"
                    trigger={(setRef, isOpen, setIsOpen) => (
                      <IconButton
                        ref={setRef}
                        style={{ marginLeft: 'auto' }}
                        onClick={e => {
                          e.stopPropagation();
                          setIsOpen(!isOpen);
                        }}
                        data-testid="header-question-mark-trigger"
                      >
                        <Icon icon={['far', 'ellipsis-v']}></Icon>
                      </IconButton>
                    )}
                  >
                    <DropdownContent
                      style={{ minWidth: '190px', width: 'auto' }}
                    >
                      <ContextMenu>
                        <ul>
                          {cacheDetails?.grossSales ? (
                            <li>
                              <Tooltip
                                content={
                                  transactionChartState.shouldSkipCacheOnce
                                    ? 'Updating...'
                                    : `Updated at ${cacheDetails.grossSales}`
                                }
                              >
                                <LightTextLink
                                  onClick={() =>
                                    transactionChartDispatch(
                                      sendUserRequestedNoCacheRefresh()
                                    )
                                  }
                                >
                                  <i
                                    className="fa fa-refresh"
                                    style={{ marginRight: '10px' }}
                                  />
                                  Refresh Data
                                </LightTextLink>
                              </Tooltip>
                            </li>
                          ) : null}
                          <li>
                            <LightTextLink
                              onClick={() => setIsSettingsModalOpen(true)}
                            >
                              <i
                                className="fa fa-cog"
                                style={{ marginRight: '10px' }}
                              />
                              Widget Settings
                            </LightTextLink>
                          </li>
                        </ul>
                      </ContextMenu>
                    </DropdownContent>
                  </Dropdown>
                </div>
                <VerticalLineWrapper>
                  <div className="pr-3">
                    <ErrorBoundary>
                      <VolumeCountAverageColumn
                        data={transactionChartState.grossSales}
                        selectedTimespan={selectedTimespanForTransactionsChart}
                      />
                    </ErrorBoundary>
                  </div>
                </VerticalLineWrapper>
              </Col>
              <VerticallyResponsiveCol xl={9} lg={{ size: 12, order: 1 }}>
                <ErrorBoundary>
                  <BarChart
                    data={
                      transactionChartState.grossSales.status === 'INITIAL'
                        ? null
                        : transactionChartState.grossSales.data
                            .selectedTimeRange.data
                    }
                    type={ChartTypes.TRANSACTIONS}
                    indexBy={
                      transactionChartState.grossSales.data.selectedTimeRange
                        .group_summary?.row_type
                    }
                    keys={['sum_CP', 'sum_ACH', 'sum_CNP_OTHER']}
                    selectedTimespan={selectedTimespanForTransactionsChart}
                    dataStatus={transactionChartState.grossSales.status}
                  />
                </ErrorBoundary>
              </VerticallyResponsiveCol>
            </WidgetRow>

            {mode !== 'TEST_MODE' && (
              <WidgetRow>
                <Col className="p-0">
                  <ErrorBoundary>
                    <LineChart
                      dataStatus={residualsState.residuals.status}
                      data={residualsState.residuals.data}
                      type={ChartTypes.RESIDUALS}
                      dataKey={'residuals'}
                      yFormat={'dollars'}
                    />
                  </ErrorBoundary>
                </Col>
              </WidgetRow>
            )}

            {/* Merchant Performance */}
            <WidgetRow>
              <Col lg={9} className="p-0">
                <BoldText>Merchant Performance</BoldText>
              </Col>

              <Col lg={3} className="p-0">
                <div className="d-flex">
                  <ErrorBoundary>
                    <TruffleSelectOverride>
                      <Select
                        data-testid="merchant-performance-filters"
                        options={options}
                        onChange={(
                          selectedOption: ValueType<OptionType, false>
                        ) => {
                          if (!selectedOption || 'length' in selectedOption) {
                            // This is not a multi-select, so this conditional should never be true
                            return;
                          } else {
                            merchantPerformanceDispatch(
                              sendMerchantPerformanceTimespan(
                                selectedOption.value
                              )
                            );
                          }
                        }}
                        value={options.find(
                          option =>
                            option.value ===
                            merchantPerformanceState.selectedTimeRange
                        )}
                      />
                    </TruffleSelectOverride>
                  </ErrorBoundary>
                  <MerchantPerformanceInfoIcon data-testid="merchant-performance-info-icon" />
                  <Dropdown
                    placement="bottom-end"
                    trigger={(setRef, isOpen, setIsOpen) => (
                      <IconButton
                        ref={setRef}
                        style={{ marginLeft: 'auto' }}
                        onClick={e => {
                          e.stopPropagation();
                          setIsOpen(!isOpen);
                        }}
                        data-testid="header-question-mark-trigger"
                      >
                        <Icon icon={['far', 'ellipsis-v']}></Icon>
                      </IconButton>
                    )}
                  >
                    <DropdownContent
                      style={{ minWidth: '190px', width: 'auto' }}
                    >
                      <ContextMenu>
                        <ul>
                          <li>
                            <LightTextLink
                              onClick={() => setIsSettingsModalOpen(true)}
                            >
                              <i
                                className="fa fa-cog"
                                style={{ marginRight: '10px' }}
                              />
                              Widget Settings
                            </LightTextLink>
                          </li>
                        </ul>
                      </ContextMenu>
                    </DropdownContent>
                  </Dropdown>
                </div>
              </Col>

              <Col
                md={12}
                lg={4}
                className="mt-md-4 mt-lg-0 pr-lg-3 px-0 pt-3 pt-lg-0"
              >
                <ErrorBoundary>
                  <MerchantPerformanceTable
                    title={'Highest Gross Sales'}
                    data={
                      merchantPerformanceState.merchantPerformance.data
                        .topPerforming.data
                    }
                    dataStatus={
                      merchantPerformanceState.merchantPerformance.status
                    }
                    performanceType="Highest Gross Sales"
                  />
                </ErrorBoundary>
              </Col>
              <Col md={12} lg={8} className="pl-lg-3 px-0 pt-0">
                <ErrorBoundary>
                  <MerchantPerformanceTable
                    title={'Under Performing by Volume'}
                    data={
                      merchantPerformanceState.merchantPerformance.data
                        .underPerforming.data
                    }
                    dataStatus={
                      merchantPerformanceState.merchantPerformance.status
                    }
                    performanceType="Under Performing by Volume"
                  />
                </ErrorBoundary>
              </Col>
            </WidgetRow>
          </>
        )}
      </Container>
      <Modal
        title="Widget Settings"
        isOpen={isSettingsModalOpen}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setIsSettingsModalOpen(false)}
        onCancel={() => setIsSettingsModalOpen(false)}
        onConfirm={() => {
          setWidgetSetting(
            (
              document.querySelector(
                'input[name=widgetSetting]:checked'
              ) as HTMLInputElement
            ).value as DataSet
          );
          setIsSettingsModalOpen(false);
        }}
        onClose={() => setIsSettingsModalOpen(false)}
        style={{ content: { width: '30%' } }}
      >
        <p
          style={{ fontSize: '1.25rem', fontWeight: 400, marginBottom: '4px' }}
        >
          Transaction Data
        </p>
        <p style={{ color: '#8D9799', marginBottom: '8px' }}>
          Select the type of data you would like to see in your widget.
        </p>
        <RadioButtonWrapper>
          <RadioButton
            name="widgetSetting"
            label="API Only"
            value="API"
            checked={widgetSetting === 'API'}
          />
        </RadioButtonWrapper>
        <RadioButtonWrapper>
          <RadioButton
            name="widgetSetting"
            label="All Data"
            value="ALL"
            checked={widgetSetting === 'ALL'}
          />
        </RadioButtonWrapper>
      </Modal>
    </>
  );
};
