import { ProcessorName } from '@fattmerchantorg/types-engine/DB';
import {
  CoreFinixConfigurations,
  LitleFinixConfigurations,
} from '../../../../../@types/UnderwritingStatus';

type FinixProcessorName = 'CORE_FINIX' | 'LITLE_FINIX';
type MagProcessorName = 'FIS';

export const ProcessorNames: {
  [key in ProcessorName | FinixProcessorName | MagProcessorName]?: string;
} = {
  TSYS: 'TSYS',
  FAKE: 'Fake Processor',
  CORE: 'FIS Core',
  FIS: 'FIS ISO (Mag)',
  CORE_FINIX: 'FIS Core',
  LITLE_FINIX: 'FIS Litle',
  FORTE: 'Forte ACH',
  PAYPAL: 'Paypal',
  APPS: 'APPS',
  APPS_ACH: 'APPS ACH',
} as const;

export type ProcessorValue = keyof typeof ProcessorNames;

// Note, these are isolated field names that are unrelated to the registration record
export interface SubmitApplicationFieldsValues {
  processor: ProcessorValue;
  payfacConfiguration?: CoreFinixConfigurations | LitleFinixConfigurations;
  payfacApplication?: {
    label: string;
    value: string;
  } | null;
  processingInfo?: 'shopping_cart' | 'keyed_transactions';
  portfolioId: string;
  forceNewApplication?: string;
  achOnly?: boolean;
}
