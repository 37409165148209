import get from 'lodash/get';
import axios from 'axios';
import { Registration } from '@fattmerchantorg/types-omni';
import { isEmpty } from '../../../../util/object.util';

export interface GeoLocationResponse {
  response: any | null;
  alerts: string[];
  status: 'TOBESIGNED' | 'READY' | 'MATCH' | 'NOMATCH' | 'ERROR';
  alertText: string;
}

const getLocation = async ip => {
  try {
    const ipInfo = await axios({
      url: `https://ipapi.co/${ip}/json`,
      method: 'get',
    });
    return await ipInfo.data;
  } catch (error) {
    return null;
  }
};

const getSignedStatus = status => {
  const signatureStatus = { status: '', text: '' };
  switch (status) {
    case 'VIEWED':
      signatureStatus.status = 'READY';
      signatureStatus.text = 'Application is ready to be signed';
      break;
    case 'SIGNED':
    case 'COUNTER-SIGNED':
    case 'CANCELLED':
      signatureStatus.status = 'NOMATCH';
      signatureStatus.text = 'Location does not match billing or DBA';
      break;
    case null:
      signatureStatus.status = 'TOBESIGNED';
      signatureStatus.text = 'Merchant has not yet filled the application';
      break;
    default:
      break;
  }
  return signatureStatus as Pick<GeoLocationResponse, 'status'> & {
    text: string;
  };
};

export const geoLocationByIp = async (
  registration: Registration
): Promise<GeoLocationResponse> => {
  const data = {
    response: null,
    alerts: null,
    status: '',
    alertText: '',
  } as unknown as GeoLocationResponse;
  const ip = get(registration, 'user_ip');
  const alerts: string[] = [];
  const signedStatus = getSignedStatus(registration?.signature_status);
  let status = signedStatus.status;
  let alertText = signedStatus.text;
  const shouldCheckAddress = ['SIGNED', 'COUNTER-SIGNED', 'CANCELLED'].includes(
    registration?.signature_status
  );
  if (ip && shouldCheckAddress) {
    const response = await getLocation(ip);

    if (!response) {
      data.status = 'ERROR';
      data.alertText = 'Error getting location information';
      return data;
    }
    data.response = response;

    const billingAddress = {
      country:
        registration.business_address_country === 'USA'
          ? 'US'
          : registration.business_address_country,
      state: registration.business_address_state,
    };

    const locationAddress = {
      country:
        registration.business_location_address_country === 'USA'
          ? 'US'
          : registration.business_address_country,
      state: registration.business_location_address_state,
      city: registration.business_location_address_city,
      zip: registration.business_location_address_zip,
    };

    if (
      response.country_code !== billingAddress.country &&
      response.country_code !== locationAddress.country
    ) {
      alerts.push('country');
    }

    if (
      response.region_code !== billingAddress.state &&
      response.region_code !== locationAddress.state
    ) {
      alerts.push('state');
    }

    if (isEmpty(alerts)) {
      status = 'MATCH';
      alertText = 'Location match billing or DBA';
    }
  }
  data.status = status;
  data.alerts = alerts;
  data.alertText = alertText;

  return data;
};
