import styled from 'styled-components';

export const PartialForm = styled.div`
  label {
    margin-bottom: 0;
    font-size: 14px;
  }

  button {
    margin: 0;
  }

  .truffle-select__input {
    input {
      margin: 0;
    }
  }
`;

export const TwoColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
`;

export const ThreeColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
`;

export const FormRow = styled.div`
  margin: 0 0 8px;
`;

export const RadioFormRow = styled.div`
  display: flex;
  gap: 16px;
  margin: 0 0 16px;
`;

export const FormDivider = styled.hr`
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.core.gray[500].hex};

  margin: 8px auto 16px auto;
`;

export const StaticValue = styled.div`
  padding: 8px 0;
  color: ${({ theme }) => theme.white};
  font-size: 14px;
`;

export const RadioLabel = styled.label`
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent; // prevents layout shift when selecting
  border-bottom: 1px solid ${({ theme }) => theme.lightNeutralOutline};
  margin: 0;

  &[data-selected='true'] {
    background: ${({ theme }) => theme.colors.core.blue[800].hex};
    border: 1px solid ${({ theme }) => theme.colors.core.blue[400].hex};
    box-sizing: border-box;
    box-shadow: 0px 0px 4px rgba(102, 204, 204, 0.5);
  }

  input[type='radio'] {
    margin: 0 8px 0 0;
    position: inherit;
  }
`;

export const RadioContainer = styled.span`
  label {
    box-sizing: content-box;
    margin-top: 3px;
  }
`;
