import React, { ReactElement } from 'react';
import {
  FeatureCategory,
  FeatureFlagProps,
  FeatureFlagRenderPropsChildren
} from './FeatureFlag.types';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';

export function FeatureFlag<C extends FeatureCategory>(
  props: FeatureFlagProps<C>
): ReactElement | null {
  const { category, children } = props;

  let features = props.feature ? [props.feature] : props.features;
  features = features || [];

  const {
    available,
    unavailable,
    availableWithAddon,
    addon,
    addons,
    atLeastOneAvailable,
    atLeastOneAvailableWithAddon,
    atLeastOneUnavailable
  } = useFeatureFlag(category, ...features);

  if (typeof children === 'function') {
    return (children as FeatureFlagRenderPropsChildren)({
      available,
      unavailable,
      availableWithAddon,
      addon,
      addons,
      atLeastOneAvailable,
      atLeastOneAvailableWithAddon,
      atLeastOneUnavailable
    });
  } else {
    return <>{available ? children : null}</>;
  }
}

export default FeatureFlag;
