import React from 'react';
import styled from 'styled-components';
import { LoadingSpan } from '../../shared';
import {
  BPSettingsRow,
  BPSettingsRowDescription,
  BPSettingsRowFields,
  BPSettingsRowTitle,
  FieldWrap,
  SubCol,
  ThreeCol,
} from '../components/BPSettingsRow';
import { CTAPageHeader } from '../components/CTAPageHeader';

const LoadingAnimation: React.FC = () => (
  <LoadingSpan width="100%" height="24px" />
);

const TitleWrapper = styled.div`
  width: 206px;
  margin-bottom: 10px;
`;

const StyledFieldWrap = styled(FieldWrap)`
  margin: 8px 0 10px 0;
`;
const StyledEdgeFieldWrap = styled(FieldWrap)`
  margin: 0 0 16px 0;
`;

const Title: React.FC = () => (
  <TitleWrapper>
    <LoadingAnimation />
  </TitleWrapper>
);

export const TransactionsLoadingState: React.FC = () => {
  return (
    <>
      <CTAPageHeader
        headingText={<Title />}
        subheadingText={<Title />}
        ctaArea={null}
        breadcrumb={null}
      />

      <BPSettingsRow>
        <BPSettingsRowTitle>
          <StyledFieldWrap>
            <LoadingAnimation />
          </StyledFieldWrap>
          <StyledFieldWrap>
            <LoadingAnimation />
          </StyledFieldWrap>
          <StyledFieldWrap>
            <LoadingAnimation />
          </StyledFieldWrap>
          <StyledFieldWrap>
            <LoadingAnimation />
          </StyledFieldWrap>
        </BPSettingsRowTitle>
        <BPSettingsRowFields>
          <ThreeCol>
            <SubCol>
              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>
              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>
              <StyledEdgeFieldWrap>
                <LoadingAnimation />
              </StyledEdgeFieldWrap>
              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>
            </SubCol>
            <SubCol>
              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>
              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>
              <StyledEdgeFieldWrap>
                <LoadingAnimation />
              </StyledEdgeFieldWrap>
              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>
            </SubCol>
            <SubCol>
              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>

              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>
              <StyledEdgeFieldWrap>
                <LoadingAnimation />
              </StyledEdgeFieldWrap>
              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>
            </SubCol>
          </ThreeCol>
        </BPSettingsRowFields>
        <BPSettingsRowDescription>
          <StyledFieldWrap>
            <LoadingAnimation />
          </StyledFieldWrap>
          <StyledFieldWrap>
            <LoadingAnimation />
          </StyledFieldWrap>
          <StyledFieldWrap>
            <LoadingAnimation />
          </StyledFieldWrap>
        </BPSettingsRowDescription>
      </BPSettingsRow>
      <BPSettingsRow>
        <BPSettingsRowTitle>
          <StyledFieldWrap>
            <LoadingAnimation />
          </StyledFieldWrap>
          <StyledFieldWrap>
            <LoadingAnimation />
          </StyledFieldWrap>
          <StyledFieldWrap>
            <LoadingAnimation />
          </StyledFieldWrap>
          <StyledFieldWrap>
            <LoadingAnimation />
          </StyledFieldWrap>
        </BPSettingsRowTitle>
        <BPSettingsRowFields>
          <ThreeCol>
            <SubCol>
              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>
              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>
              <StyledEdgeFieldWrap>
                <LoadingAnimation />
              </StyledEdgeFieldWrap>
              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>
            </SubCol>
            <SubCol>
              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>
              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>
              <StyledEdgeFieldWrap>
                <LoadingAnimation />
              </StyledEdgeFieldWrap>
              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>
            </SubCol>
            <SubCol>
              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>
              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>
              <StyledEdgeFieldWrap>
                <LoadingAnimation />
              </StyledEdgeFieldWrap>
              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>
            </SubCol>
          </ThreeCol>
        </BPSettingsRowFields>
        <BPSettingsRowDescription>
          <StyledFieldWrap>
            <LoadingAnimation />
          </StyledFieldWrap>
          <StyledFieldWrap>
            <LoadingAnimation />
          </StyledFieldWrap>
          <StyledFieldWrap>
            <LoadingAnimation />
          </StyledFieldWrap>
        </BPSettingsRowDescription>
      </BPSettingsRow>
      <BPSettingsRow>
        <BPSettingsRowTitle>
          <StyledFieldWrap>
            <LoadingAnimation />
          </StyledFieldWrap>
          <StyledFieldWrap>
            <LoadingAnimation />
          </StyledFieldWrap>
          <StyledFieldWrap>
            <LoadingAnimation />
          </StyledFieldWrap>
          <StyledFieldWrap>
            <LoadingAnimation />
          </StyledFieldWrap>
        </BPSettingsRowTitle>
        <BPSettingsRowFields>
          <ThreeCol>
            <SubCol>
              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>
              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>
              <StyledEdgeFieldWrap>
                <LoadingAnimation />
              </StyledEdgeFieldWrap>
              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>
            </SubCol>
            <SubCol>
              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>
              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>
              <StyledEdgeFieldWrap>
                <LoadingAnimation />
              </StyledEdgeFieldWrap>
              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>
            </SubCol>
            <SubCol>
              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>
              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>
              <StyledEdgeFieldWrap>
                <LoadingAnimation />
              </StyledEdgeFieldWrap>
              <StyledFieldWrap>
                <LoadingAnimation />
              </StyledFieldWrap>
            </SubCol>
          </ThreeCol>
        </BPSettingsRowFields>
        <BPSettingsRowDescription>
          <StyledFieldWrap>
            <LoadingAnimation />
          </StyledFieldWrap>
          <StyledFieldWrap>
            <LoadingAnimation />
          </StyledFieldWrap>
          <StyledFieldWrap>
            <LoadingAnimation />
          </StyledFieldWrap>
        </BPSettingsRowDescription>
      </BPSettingsRow>
    </>
  );
};
