import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { memoize } from '../../../util/functional.util';
import { Themed } from '../Themed.types';
import { PillProps } from './Pill.types';

const getBackgroundColorFromMotif = memoize((props: PillProps & Themed) => {
  switch (props.motif) {
    case 'success':
      return '#9BEEA6';
    case 'warning':
      return '#FFE88F';
    case 'error':
      return '#F9C0CA';
    default:
    case 'neutral':
      return '#C5E9FD';
  }
});

const getTextColorFromMotif = memoize((props: PillProps & Themed) => {
  switch (props.motif) {
    case 'success':
      return '#033F0A';
    case 'warning':
      return '#88341A';
    case 'error':
      return '#A8001B';
    default:
    case 'neutral':
      return '#0E1945';
  }
});

const Outer = styled.div`
  background: white;
  font-size: 12px;
  padding: 0.125rem 0.5rem;
  border-radius: 20px;
  display: inline-block;
  line-height: unset;

  background-color: ${getBackgroundColorFromMotif};
  color: ${getTextColorFromMotif};

  > div {
    display: flex;
    align-items: center;

    > i {
      margin-right: 0.3rem;
      font-size: 6px;
    }
  }
`;

export const Pill: FunctionComponent<PillProps> = props => {
  const { children, ...divProps } = props;
  return (
    <Outer {...divProps}>
      <div>{children}</div>
    </Outer>
  );
};
