import React, {
  ChangeEvent,
  FunctionComponent,
  InputHTMLAttributes,
  useCallback,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { FileRecord, FundingAccount } from '@fattmerchantorg/types-omni';
import { Icon, Text, TextLink } from '@fattmerchantorg/truffle-components';
import { useAuthToken, useToaster } from '../../../../hooks';
import { coreapi } from '../../../../api';
import { AttachmentsList as UAttachmentsList } from '../../../shared/Attachments';
import { LineItemRow, Section } from './BankAccountDrawerStyles';

const AttachmentsList: typeof UAttachmentsList = styled(UAttachmentsList)`
  margin-top: 16px;
  font-size: 14px;
  width: 100%;
`;

const HiddenInput: FunctionComponent<
  React.DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
> = styled.input`
  display: none;
`;

export const BankAccountDrawerFiles: FunctionComponent<{
  account: FundingAccount;
  onNeedsRefetch: () => void;
}> = props => {
  const { account, onNeedsRefetch } = props;
  const accountId = account?.id;
  const merchantId = account?.merchant_id;

  const authToken = useAuthToken();
  const { toast, toaster } = useToaster();
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);

  const hiddenFileInput = useRef(null);

  const handleUploadFile = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      setIsUploadingFiles(true);

      const files = Array.from(event.target.files);

      await Promise.all(
        files.map(async file => {
          try {
            await coreapi.post(
              authToken,
              `/merchant/${merchantId}/funding-account/${accountId}/file`,
              file
            );
          } catch (error) {
            const title =
              files.length === 1
                ? 'There was a problem uploading the file.'
                : `There was a problem uploading file "${file.name}"`;

            toaster(toast.error(error, title));
          }
        })
      );

      onNeedsRefetch();
      setIsUploadingFiles(false);
    },
    [authToken, merchantId, accountId, onNeedsRefetch, toast, toaster]
  );

  const handleDeleteFile = useCallback(
    async (file: FileRecord) => {
      try {
        await coreapi.delete(
          authToken,
          `/merchant/${merchantId}/funding-account/${accountId}/file/${file.id}`
        );
      } catch (error) {
        toaster(toast.error(error, 'Error deleting file'));
      } finally {
        onNeedsRefetch();
      }
    },
    [authToken, merchantId, accountId, onNeedsRefetch, toast, toaster]
  );

  return (
    <Section>
      <LineItemRow>
        <Text as="h6">Uploaded Files</Text>
        <TextLink onClick={() => hiddenFileInput.current.click()}>
          {isUploadingFiles ? (
            <Icon icon={['fas', 'spinner-third']} spin />
          ) : (
            <Icon icon={['fas', 'upload']} />
          )}
          Upload Files
        </TextLink>
      </LineItemRow>
      <HiddenInput
        type="file"
        ref={hiddenFileInput}
        disabled={!account}
        onChange={handleUploadFile}
      />
      {account?.files?.length ? (
        <AttachmentsList
          files={account.files}
          downloadable
          onDelete={handleDeleteFile}
        />
      ) : null}
    </Section>
  );
};
