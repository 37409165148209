import React from 'react';
import { Checkbox } from './FilterCheckbox';

export const FilterCheckboxRow = ({ checked, label, ...props }) => (
  <li style={{ marginLeft: '20px' }}>
    <label>
      <Checkbox checked={checked} {...props} />
      <span>{label}</span>
    </label>
  </li>
);
