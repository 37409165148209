import React from 'react';
import { Container, Group, Modal } from '../shared';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { StaxButton } from '../shared/Stax/Button';

const ModalIcon = styled(FontAwesomeIcon)`
  width: 448px;
  height: 70px;
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 70px;
  line-height: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.core.gray[200].hex};
`;

const ModalMessage = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
`;

const ModalDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 16px;
  height: 212px;
  background: #213745;
`;

const ModalButtonDiv = styled.div`
  margin-top: 10px;
`;

const StyledContainer = styled(Container)`
  padding: 16px;
`;

const ModalLeaveButtonPrimary = styled(StaxButton)`
  background-color: ${({ theme }) => theme.colors.status.red[500].hex};
  color: ${({ theme }) => theme.colors.core.white[0].hex};
`;

type ConfirmCancelModalProps = {
  isOpen: boolean;
  title: React.ReactNode;
  message: React.ReactNode;
  cancelButtonText?: React.ReactNode;
  confirmButtonText?: React.ReactNode;
  onCancel?: () => void;
  onConfirm?: () => void;
};

export const ConfirmCancelModal: React.FunctionComponent<
  ConfirmCancelModalProps
> = ({
  isOpen,
  title,
  message,
  cancelButtonText,
  confirmButtonText,
  onCancel,
  onConfirm,
}: ConfirmCancelModalProps) => {
  const handleClose = () => {
    if (typeof onCancel === 'function') {
      onCancel();
    }
  };

  const handleConfirm = () => {
    if (typeof onConfirm === 'function') {
      onConfirm();
    }
  };

  return (
    <Modal
      data-testid="confirm-cancel-modal"
      width="480px"
      overflow="hidden"
      isOpen={isOpen}
      onClose={handleClose}
      title={title}
      isClosable={false}
    >
      <StyledContainer>
        <ModalDiv>
          <ModalIcon
            id={`risk-hold-merchant-icon`}
            icon={faExclamationTriangle as IconProp}
          />
          <ModalMessage>{message}</ModalMessage>
        </ModalDiv>
        <ModalButtonDiv>
          <Group direction="row" space="1rem" justify="center">
            <React.Fragment>
              <StaxButton rank="secondary" onClick={handleClose}>
                {cancelButtonText ?? 'Cancel'}
              </StaxButton>
              <ModalLeaveButtonPrimary motif="danger" onClick={handleConfirm}>
                {confirmButtonText ?? 'Confirm'}
              </ModalLeaveButtonPrimary>
            </React.Fragment>
          </Group>
        </ModalButtonDiv>
      </StyledContainer>
    </Modal>
  );
};
