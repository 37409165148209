import * as React from 'react';
import { Text } from '@fattmerchantorg/truffle-components';
import { StatementV3Props } from '../util/statement-v3-utils';
import { Helmet } from 'react-helmet';
import { TopSection } from '../../shared/top-section-component';
import moment from 'moment';
import { TextContainer } from '../../components';

export const MerchantInfoSection: React.FC<StatementV3Props> = props => {
  const { merchant, startDate, endDate, logoUrl, reportName } = props;

  if (!merchant) return null;

  return (
    <span>
      <Helmet>
        <title>
          {reportName}-Statement-Report-
          {moment(startDate).startOf('month').format('YYYY-MM')}
        </title>
      </Helmet>
      <TopSection style={{ marginTop: '0px', marginBottom: '8px' }}>
        <div>
          <Text as="span">{merchant.company_name}</Text>
          <Text as="span">
            {`${merchant.address_1} ${merchant.address_2}`.trim()}
          </Text>
          <Text as="span">
            {merchant.address_city} {merchant.address_state}{' '}
            {merchant.address_zip}
          </Text>
        </div>
        <div>{<img src={logoUrl} alt="logo" />}</div>
      </TopSection>

      <TopSection style={{ marginBottom: '14px' }}>
        <TextContainer fontWeight="bold">
          <Text as="h4">Merchant Statement</Text>
        </TextContainer>
      </TopSection>

      <TopSection style={{ marginBottom: '14px' }}>
        <div>
          <TextContainer fontWeight="bold">
            <Text as="h5">Merchant Number</Text>
          </TextContainer>
          <Text as="h5">{merchant.mid}</Text>
        </div>
        <div>
          <TextContainer textAlign="right" fontWeight="bold">
            <Text as="h5">Statement Period</Text>
          </TextContainer>
          <TextContainer fontWeight="normal" textAlign="right">
            <Text as="h5">
              {moment(startDate).format('MMM DD, YYYY') +
                ' - ' +
                moment(endDate).format('MMM DD, YYYY')}
            </Text>
          </TextContainer>
        </div>
      </TopSection>
    </span>
  );
};
