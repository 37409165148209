import { Merchant } from '@fattmerchantorg/types-omni';
import { ActionTypes } from './types';

/* Actions */
interface UserToggledSwitch {
  type: typeof ActionTypes.USER_TOGGLED_SWITCH;
}
interface UpdateRiskHoldSucceededAction {
  type: typeof ActionTypes.UPDATE_RISK_HOLD_SUCCEEDED;
  payload: Merchant;
}
interface UpdateRiskHoldFailedAction {
  type: typeof ActionTypes.UPDATE_RISK_HOLD_FAILED;
  payload: Error;
}
interface UpdateRiskHold {
  type: typeof ActionTypes.UPDATE_RISK_HOLD;
  payload: boolean;
}
export type Action =
  | UserToggledSwitch
  | UpdateRiskHoldSucceededAction
  | UpdateRiskHoldFailedAction
  | UpdateRiskHold;

/** Action Creators */
export function sendUserToggledSwitch(): Action {
  return { type: ActionTypes.USER_TOGGLED_SWITCH };
}
export function sendUpdateRiskHoldSucceeded(payload: Merchant): Action {
  return { type: ActionTypes.UPDATE_RISK_HOLD_SUCCEEDED, payload };
}
export function sendUpdateRiskHoldFailed(payload: Error): Action {
  return { type: ActionTypes.UPDATE_RISK_HOLD_FAILED, payload };
}
export function sendUpdateRiskHold(payload: boolean): Action {
  return { type: ActionTypes.UPDATE_RISK_HOLD, payload };
}