import React, { Dispatch, useState, useContext } from 'react';
import styled from 'styled-components';
import {
  BaseModal,
  ModalHeader,
  ModalFooter,
  PrimaryButton,
  SecondaryButton,
} from '@fattmerchantorg/truffle-components';
import { Registration } from '@fattmerchantorg/types-omni';
import { ModalContext } from '../../../../context/modal';
import { ButtonSpinner, Form } from '../../../shared';
import { BillingAddressFields } from './AddressFields';
import * as BillingAddressFormUtil from './AddressForm.util';
import { useUnderwritingFormSubmission } from '../../context/useUnderwritingFormSubmission';

const ButtonBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  > * {
    margin: 0 0 0 ${({ theme }) => theme.space[3]}px;
  }
`;

const StyledModalContent = styled.div`
  grid-area: content;
  overflow-y: auto;
  padding: ${({ theme }) => theme.space[2]}px ${({ theme }) => theme.space[4]}px;
  color: ${({ theme }) => theme.component.modal.color};
`;

interface BillingAddressModalProps {
  isOpen?: boolean;
  registration: Registration;
  merchantId: string;
  merchantDispatch: Dispatch<any>;
}

export const BillingAddressFormModal: React.FunctionComponent<
  BillingAddressModalProps
> = props => {
  const { modalDispatch } = useContext(ModalContext);
  const [isModalVisible] = useState(!!props.isOpen);
  const { registration, merchantId, merchantDispatch } = props;

  const { onSubmit, isSaving } = useUnderwritingFormSubmission({
    merchantDispatch,
    merchantId,
    payloadFormatter: BillingAddressFormUtil.mapFormValuesToPayload,
  });

  const handleCloseModal = () => {
    modalDispatch({
      type: 'CLOSE_MODAL',
    });
  };

  return (
    <Form
      initialValues={{
        ...BillingAddressFormUtil.mapRegistrationToInitialFormValues(
          registration
        ),
      }}
      validate={formValues =>
        BillingAddressFormUtil.validateBillingFormValues(formValues)
      }
      onSubmit={onSubmit}
      mutators={BillingAddressFormUtil.formMutators}
    >
      {formProps => (
        <BaseModal
          title="Billing Address"
          isOpen={isModalVisible}
          onRequestClose={handleCloseModal}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={false}
          style={{
            overlay: { pointerEvents: 'auto' },
            content: { maxWidth: '533px' },
          }}
        >
          <ModalHeader title="Billing Address" onClose={handleCloseModal} />
          <>
            <StyledModalContent>
              <BillingAddressFields
                formProps={formProps}
                registration={registration}
              />
            </StyledModalContent>
            <ModalFooter>
              <ButtonBar>
                <SecondaryButton onClick={handleCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  onClick={() => onSubmit(formProps.values)}
                  disabled={
                    // Disable Save if there are validation errors
                    formProps.hasValidationErrors === true
                  }
                >
                  {isSaving ? <ButtonSpinner /> : `Save`}
                </PrimaryButton>
              </ButtonBar>
            </ModalFooter>
          </>
        </BaseModal>
      )}
    </Form>
  );
};
