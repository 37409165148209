import React, { useRef } from 'react';
import { Field as FinalField } from 'react-final-form';
import { FieldProps } from './Field.types';
import isObject from 'lodash';

const parseValue = (value: any) => {
  if (value === null) {
    return null;
  } else if (isObject(value) && value.hasOwnProperty('value')) {
    return value.value;
  }
  return value;
};

export function Field<FieldValue = any, T extends HTMLElement = HTMLElement>(
  props: FieldProps<FieldValue, T> & {
    debouncedValidate?: boolean;
    milliseconds?: number;
  }
): JSX.Element {
  const timeout = useRef(null);
  const lastValue = useRef(null);
  const lastResult = useRef(null);

  const { validate, milliseconds, debouncedValidate, ...rest } = props;

  const debouncedValidateField = (value, values, meta) =>
    new Promise(resolve => {
      if (timeout.current) {
        timeout.current();
      }

      if (value !== lastValue.current) {
        const timerId = setTimeout(() => {
          lastValue.current = value;
          lastResult.current = validate(value, values, meta);
          resolve(lastResult.current);
        }, milliseconds ?? 400);

        timeout.current = () => {
          clearTimeout(timerId);
          resolve(true);
        };
      } else {
        resolve(lastResult.current);
      }
    });

  if (typeof props.children === 'function') {
    const { children, label, name } = props;

    return (
      <FinalField
        {...rest}
        validate={debouncedValidate ? debouncedValidateField : validate}
        parse={parseValue}
      >
        {fieldProps => {
          // inject label and id props
          const inputProps = {
            ...fieldProps.input,
            id: name,
            label,
          };

          inputProps['data-testid'] = name;
          return (children as any)({ ...fieldProps, input: inputProps });
        }}
      </FinalField>
    );
  } else {
    return (
      <FinalField
        {...rest}
        validate={debouncedValidate ? debouncedValidateField : validate}
        parse={parseValue}
      />
    );
  }
}
