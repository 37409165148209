import {
  Registration,
  RegistrationUnderwritingSubStatusApprovedReasonItem,
  RegistrationUnderwritingSubStatusItem,
  RegistrationUnderwritingSubStatusPendedReasonItem,
  RegistrationUnderwritingSubStatusRejectedReasonItem,
} from '@fattmerchantorg/types-omni';
import { formatCapitalCase } from './format.util';
import { MaskType } from '../@types/MaskType';

export const underwritingStatuses: {
  [K in Registration['underwriting_status']]: Registration['underwriting_status'];
} = {
  APPROVED: 'APPROVED',
  PENDED: 'PENDED',
  REJECTED: 'REJECTED',
};

type Reasons<T extends RegistrationUnderwritingSubStatusItem> = {
  [K in T['key']]?: T['message'];
};

// prettier-ignore
export const underwritingStatusApprovedReasons: Reasons<RegistrationUnderwritingSubStatusApprovedReasonItem> = {
  APPROVED_BANKING_VERIFICATION: 'Banking Verification',
  APPROVED_WEBSITE_COMPLIANCE: 'Website Compliance',
  APPROVED_OTHER: 'Other',
};

// prettier-ignore
export const underwritingStatusPendedReasons: Reasons<RegistrationUnderwritingSubStatusPendedReasonItem> = {
  PENDED_SIGNER_VERIFICATION: 'Signer Verification',
  PENDED_BUSINESS_LOCATION: 'Business Location',
  PENDED_VOLUME_CLARITY: 'Volume Clarity',
  PENDED_TIN_MISMATCH: 'TIN Mismatch',
  PENDED_PREVIOUS_PROCESSING_HISTORY_CREDIT: 'Previous Processing History / Credit',
  PENDED_MARKETING_MATERIAL: 'Marketing Material',
  PENDED_DEPOSIT_POLICY: 'Deposit Policy',
  PENDED_FINANCIALS_BANKING: 'Financials/Banking',
  PENDED_BANKING_VERIFICATION: 'Banking Verification',
};

// prettier-ignore
export const underwritingStatusRejectedReasons: Reasons<RegistrationUnderwritingSubStatusRejectedReasonItem> = {
  REJECTED_PROHIBITED_BUSINESS: 'Prohibited Business',
  REJECTED_PROHIBITED_PRODUCTS: 'Prohibited Products',
  REJECTED_BUSINESS_VERIFICATION: 'Business Verification',
  REJECTED_SIGNER_VERIFICATION: 'Signer Verification',
  REJECTED_FRAUD: 'Fraud',
  REJECTED_INSUFFICIENT_CREDIT: 'Insufficient Credit',
  REJECTED_INSUFFICIENT_FINANCIAL_BACKING: 'Insufficient Financial Backing',
  REJECTED_MATCH: 'Match',
  REJECTED_EXPIRED: 'Expired',
};

export const underwritingStatusAllReasons = {
  ...underwritingStatusApprovedReasons,
  ...underwritingStatusPendedReasons,
  ...underwritingStatusRejectedReasons,
};

export const getUnderwritingStatusReasonsTitle = (
  underwritingStatus: Registration['underwriting_status'],
  underwritingSubstatuses: Registration['underwriting_substatuses'] | any[]
) => {
  const title =
    underwritingStatus === 'APPROVED'
      ? 'Conditional Approval Reason'
      : `${
          underwritingStatus ? formatCapitalCase(underwritingStatus) : ''
        } Reason`;

  return Array.isArray(underwritingSubstatuses) &&
    underwritingSubstatuses?.length
    ? `${title}s`
    : title;
};

export const getMaskedInputMax = (maskType: MaskType): number => {
  const maskTypeSizes: Record<MaskType, number> = {
    accountNumber: 17,
    ssn: 11,
    ssnWithoutDashes: 9,
    password: Infinity,
  };

  return maskTypeSizes[maskType] ?? 0;
};
