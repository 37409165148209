import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';

export const StyledStaxButton = styled.button`
  // Shared among all button configurations.
  border-radius: 2px;
  border: 0;
  font-size: 14px;
  line-height: 20px;

  &:disabled {
    color: ${({ theme }) => theme.colors.core.gray[200].hex};
    background: ${({ theme }) => theme.colors.core.gray[500].hex};
    &:hover {
      background: ${({ theme }) => theme.colors.core.gray[500].hex};
    }
  }

  &:focus {
    box-shadow: 0 0 2px 2px #3fa2f7;
  }

  i,
  .button-icon {
    margin-right: 8px;
  }

  ${({ size, rank, motif }) =>
    size === 'large' &&
    rank === 'primary' &&
    motif === 'danger' &&
    css`
      color: ${({ theme }) => theme.black};
      background: ${({ theme }) => theme.colors.status.red[500].hex};
      padding: 10px 32px;

      &:hover {
        background: ${({ theme }) => theme.colors.status.red[600].hex};
      }

      &:active {
        background: ${({ theme }) => theme.colors.status.red[100]};
      }
    `}

  ${({ size, rank, motif }) =>
    size === 'large' &&
    rank === 'primary' &&
    motif === 'default' &&
    css`
      color: ${({ theme }) => theme.black};
      background: ${({ theme }) => theme.white};
      padding: 10px 32px;
      border: 1px solid ${({ theme }) => theme.white};
      &:hover {
        background: ${({ theme }) => theme.colors.core.green[600].hex};
        border-color: ${({ theme }) => theme.colors.core.green[600].hex};
      }

      &:active {
        background: ${({ theme }) => theme.colors.core.green[500].hex};
        border-color: ${({ theme }) => theme.colors.core.green[500].hex};
      }
    `}

  ${({ size, rank, motif }) =>
    size === 'large' &&
    rank === 'secondary' &&
    motif === 'default' &&
    css`
      color: ${({ theme }) => theme.white};
      background: ${({ theme }) => theme.black};
      border: 1px solid ${({ theme }) => theme.white};
      padding: 10px 32px;

      &:hover {
        background: ${({ theme }) => theme.colors.core.green[50].hex};
        color: ${({ theme }) => theme.black};
      }

      &:active {
        background: ${({ theme }) => theme.colors.core.green[300].hex};
        color: ${({ theme }) => theme.black};
      }
    `}



  ${({ size, rank, motif }) =>
    size === 'small' &&
    rank === 'primary' &&
    motif === 'default' &&
    css`
      color: ${({ theme }) => theme.black};
      background: ${({ theme }) => theme.colors.core.green[400].hex};
      padding: 6px 16px;

      &:hover {
        background: ${({ theme }) => theme.colors.core.green[300].hex};
      }

      &:active {
        background: ${({ theme }) => theme.colors.core.green[500].hex};
      }
    `}
`;

export const StaxButton: FunctionComponent<StaxButtonProps> = props => {
  const {
    size,
    rank,
    loading,
    disabled,
    type,
    icon,
    children,
    ...rest
  } = props;

  const renderIcon = useCallback(() => {
    if (loading) {
      return (
        <FontAwesomeIcon className="button-icon" icon={faSpinnerThird} spin />
      );
    } else if (icon) {
      return <i className={`button-icon ${icon}`} />;
    } else {
      return icon;
    }
  }, [loading, icon]);

  return (
    <StyledStaxButton
      disabled={disabled}
      type={type}
      rank={rank}
      size={size}
      {...rest}
    >
      {renderIcon()}
      <span>{children}</span>
    </StyledStaxButton>
  );
};

StaxButton.defaultProps = {
  size: 'large',
  rank: 'primary',
  motif: 'default',
  loading: false,
  disabled: false,
  type: 'button',
  icon: undefined
};
interface StaxButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  size?: 'large' | 'small'; // Stax variant.
  rank?: 'primary' | 'secondary'; // Stax variant.
  motif?: 'default' | 'danger'; // Stax variant.
  loading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  icon?: string;
}
