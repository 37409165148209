import React, { FunctionComponent, HTMLAttributes } from 'react';
import { Icon } from '@fattmerchantorg/truffle-components';
import styled from 'styled-components';
import { Themed } from '../../shared';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  line-height: 20px;
  font-size: 14px;
  animation: ${(props: StatusPanelProps) =>
    props.animated
      ? 'fade-in 0.2s ease-in-out both, shift-up 0.3s ease-in-out both'
      : 'none'};
  padding: 12px 16px;
  color: ${props => {
    const { status, theme } = props;
    switch (status) {
      case 'warning':
        return theme.black;
      default:
        return 'inherit';
    }
  }};
  background-color: ${(props: StatusPanelProps) => {
    const { status } = props;
    const theme: any = props.theme;
    switch (status) {
      case 'error':
        return theme.colors.status.red['500'].hex;
      case 'success':
        return theme.colors.status.green['800'].hex;
      case 'warning':
        return theme.colors.status.yellow['500'].hex;
      case 'info':
        return theme.colors.status.blue['500'].hex;
    }
  }};
  // icon
  span:first-child {
    margin-right: 8px;
  }
`;

const renderIcon = (
  status: StatusPanelProps['status'],
  overrideIcon?: StatusPanelProps['icon']
) => {
  if (overrideIcon) {
    return <Icon icon={overrideIcon} />;
  }

  switch (status) {
    case 'success':
      return <Icon icon={['far', 'check-circle']} />;
    case 'warning':
      return <Icon icon={['far', 'exclamation-triangle']} />;
    case 'error':
      return <Icon icon={['far', 'times-circle']} />;
    case 'info':
      return <Icon icon={['far', 'info-circle']} />;
  }
};

type StatusPanelProps = HTMLAttributes<HTMLDivElement> &
  Themed & {
    animated?: boolean;
    icon?: FontAwesomeIconProps['icon'];
    status: 'success' | 'warning' | 'error' | 'info';
  };

export const StatusPanel: FunctionComponent<StatusPanelProps> = props => {
  const { children, icon, ...containerProps } = props;
  return (
    <Container {...containerProps}>
      {renderIcon(props.status, icon)}
      {typeof children === 'string' ? <span>{children}</span> : children}
    </Container>
  );
};

StatusPanel.defaultProps = {
  animated: false,
};
