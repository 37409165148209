import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import { formatCurrency } from '../../../../util/format.util';

const Wrapper = styled.div`
  font-size: 14;
`;

// Needed to make font bold.
const Label = styled.span`
  font-weight: 700;
`;

// Single element (CP, CNP or ACH) that goes in the tooltip.
export function TooltipElement(
  color: string,
  label: string,
  value: number,
  key: string
): JSX.Element {
  return (
    <Wrapper>
      <FontAwesomeIcon
        id={`tooltip-element-${key}`}
        data-testid={`tooltip-element-${key}`}
        icon={faCircle as IconProp}
        style={{ color }}
      />{' '}
      <Label>{label}: </Label>
      {formatCurrency(value)}
    </Wrapper>
  );
}
