import {
  BusinessFieldsValues,
  BusinessFieldsErrors,
} from './BusinessFields.types';
import { Registration, Merchant } from '@fattmerchantorg/types-omni';
import { pick } from '../../../../util/object.util';
import {
  isValidPhoneNumber,
  isValidUrl,
} from '../../../../util/validator.util';

export function mapFormValuesToPayload(
  formValues: BusinessFieldsValues
): Partial<Registration> {
  return {
    ...formValues,
  };
}

export function mapRegistrationToInitialFormValues(
  registration: Registration
): BusinessFieldsValues {
  return pick(
    registration,
    'business_legal_name',
    'business_dba',
    'business_website',
    'business_fax',
    'business_address_1',
    'business_address_2',
    'business_address_city',
    'business_address_state',
    'business_address_zip',
    'business_tax_id',
    'business_open_date'
  ) as BusinessFieldsValues;
}

export function mapFormValuesToTeamPayload(
  formValues: BusinessFieldsValues
): Partial<Merchant> {
  return {
    // prettier-ignore
    address_1: formValues.business_address_1,
    address_2: formValues.business_address_2 || '', // need || '' since field is optional. If taken out, it will not allow submission if field is empty.
    address_city: formValues.business_address_city,
    address_state: formValues.business_address_state,
    address_zip: formValues.business_address_zip,
  };
}

export function validateRequiredFormValues(
  values: BusinessFieldsValues
): BusinessFieldsErrors {
  const errors = {} as BusinessFieldsErrors;

  const requiredFields = [
    'business_legal_name',
    'business_dba',
    'business_address_1',
    'business_address_city',
    'business_address_state',
    'business_address_zip',
    'business_tax_id',
    'business_open_date',
  ];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
}

export function validateFormValues(
  values: BusinessFieldsValues
): BusinessFieldsErrors {
  const errors = {} as BusinessFieldsErrors;

  if (values.business_fax && !isValidPhoneNumber(values.business_fax)) {
    errors.business_fax = 'Please enter a valid fax number';
  }

  if (values.business_website && !isValidUrl(values.business_website)) {
    errors.business_website = 'Please enter a valid website';
  }

  return errors;
}
