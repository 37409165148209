import React, { Context, createContext, useReducer } from 'react';
import {
  PageHeaderContextProps,
  PageHeaderContext,
  PageHeaderAction,
  PageHeaderState,
  PageHeaderActionType
} from './PageHeader.types';

export const PageHeaderStore = createContext(
  {} as PageHeaderContext
) as Context<PageHeaderContext>;

const initialState: PageHeaderState = {
  currentTitle: null,
  previousTitle: null
};

type Reducer = (
  state: PageHeaderState,
  action: PageHeaderAction
) => PageHeaderState;

const storeReducer: Reducer = (state, action) => {
  if (action.type === PageHeaderActionType.UPDATE_TITLE) {
    return {
      previousTitle: state.currentTitle,
      currentTitle: action.payload
    };
  }

  return state;
};

export const PageHeaderProvider = (props: PageHeaderContextProps) => {
  const [state, dispatch] = useReducer(storeReducer, initialState);

  const localContext: PageHeaderContext = {
    state,
    dispatch
  };

  return (
    <PageHeaderStore.Provider value={localContext}>
      {props.children}
    </PageHeaderStore.Provider>
  );
};
