import { renderCorisReport } from './renderCorisReport';

export const RenderCorisLogDrilldownRecord = (
  auditData: any[] | { [key: string]: any },
  reportKey: string,
  index: number
) => {
  let drilldown = [];
  if (
    typeof auditData === 'object' &&
    !Array.isArray(auditData) &&
    auditData !== null
  ) {
    drilldown.push(renderCorisReport(auditData, reportKey, index));
  } else if (
    typeof auditData === 'object' &&
    Array.isArray(auditData) &&
    auditData !== null
  ) {
    if (auditData.length === 0) {
      drilldown.push(renderCorisReport([], reportKey, index));
    } else {
      if (
        reportKey === 'Reviews Summary' ||
        reportKey === 'Personnel/Officers'
      ) {
        drilldown.push(renderCorisReport(auditData, reportKey, index));
      } else {
        auditData.forEach((element, key) => {
          drilldown.push(renderCorisReport(element, reportKey, key));
        });
      }
    }
  }
  return drilldown;
};
