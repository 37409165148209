import React, { useState } from 'react';
import styled from 'styled-components';
import { Text } from '@fattmerchantorg/truffle-components';
import currency from 'currency.js';
import { GetFeeStatementCardTypeVolumesResponse } from '@fattmerchantorg/types-omni';
import { TableSectionV3 } from '../styles/table-section-component-v3';
import { StatementV3Props } from '../util/statement-v3-utils';
import { useAsyncEffect, useAuthToken } from '../../../../hooks';
import { queryapi } from '../../../../api';
import { TableNullState } from '../styles';
import { formatCurrency } from '../../../../util';
import {
  LoadingState,
  useStatementLoadingState,
  useStatementSummaryState,
} from '../../../../context/statements';
import { TextContainer } from '../../components';

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Table = styled(TableSectionV3)`
  /** Override the global styles from _anchor.scss for the first section links */
  > table > tbody > tr > td,
  > table > tfoot > tr > td {
    &[data-is-negative='true'] {
      color: ${props => props.theme.colors.status.red['500'].hex};
    }
  }
`;

const formatCardBrand = datum => {
  switch (datum.card_brand) {
    case 'ACH':
      return 'ACH';
    case 'amex':
      if (datum.bin_type) {
        return (
          'American Express ' +
          datum.bin_type.charAt(0) +
          datum.bin_type.substring(1).toLowerCase() +
          ' ' +
          (datum.is_card_present ? 'CP' : 'CNP')
        );
      } else {
        return 'American Express ' + (datum.is_card_present ? 'CP' : 'CNP');
      }
    default:
      if (datum.bin_type) {
        return (
          datum.card_brand.charAt(0).toUpperCase() +
          datum.card_brand.substring(1).toLowerCase() +
          ' ' +
          datum.bin_type.charAt(0) +
          datum.bin_type.substring(1).toLowerCase() +
          ' ' +
          (datum.is_card_present ? 'CP' : 'CNP')
        );
      } else {
        return (
          datum.card_brand.charAt(0).toUpperCase() +
          datum.card_brand.substring(1).toLowerCase() +
          ' ' +
          (datum.is_card_present ? 'CP' : 'CNP')
        );
      }
  }
};

export const CardTypeVolumesSection: React.FC<StatementV3Props> = props => {
  const { startDate, endDate } = props;
  const authToken = useAuthToken();
  const { updateTotalSales, updateTotalCharges } = useStatementSummaryState();
  const [data, setData] =
    useState<GetFeeStatementCardTypeVolumesResponse['data']>(null);
  const [summary, setSummary] =
    useState<GetFeeStatementCardTypeVolumesResponse['summary']>(null);
  const { cardTypeVolumesLoading, setCardTypeVolumesLoading } =
    useStatementLoadingState();

  useAsyncEffect(async () => {
    if (
      cardTypeVolumesLoading === LoadingState.Loading &&
      authToken &&
      startDate &&
      endDate
    ) {
      try {
        const query = { startDate, endDate };
        const data = await queryapi.get<GetFeeStatementCardTypeVolumesResponse>(
          authToken,
          'statement/v3/volumes/card-type',
          query
        );

        setData(data.data);
        setSummary(data.summary);
        updateTotalSales(data.summary?.total_charges);
        updateTotalCharges(data.summary?.use_count);
        setCardTypeVolumesLoading(LoadingState.Completed);
      } catch (error) {
        setCardTypeVolumesLoading(LoadingState.Failed);
      }
    }
  }, [cardTypeVolumesLoading, useAuthToken, startDate, endDate]);

  if (cardTypeVolumesLoading !== LoadingState.Completed) return null;

  const defaultColWidth = +summary?.interchange_fees_amount > 0 ? '12%' : '16%';

  if (data && summary) {
    return (
      <Table>
        <SectionHeader>
          <TextContainer fontWeight="bold" paddingBottom="8px">
            <Text as="h5">Volumes</Text>
          </TextContainer>
        </SectionHeader>
        <table data-testid="statementV3-sales">
          <thead>
            <tr>
              <th scope="col" style={{ width: '20%' }}>
                Brand
              </th>
              <th scope="col" style={{ width: '8%' }}>
                Count
              </th>
              <th scope="col" style={{ width: defaultColWidth }}>
                Sales
              </th>
              <th scope="col" style={{ width: defaultColWidth }}>
                Per Transaction Fee
              </th>
              <th scope="col" style={{ width: defaultColWidth }}>
                Rate Fees
              </th>
              {!!summary.interchange_fees_amount && (
                <th scope="col" style={{ width: defaultColWidth }}>
                  Interchange Fees
                </th>
              )}
              <th scope="col" style={{ width: '5%' }}></th>
              <th scope="col" style={{ width: '14%' }}>
                Total Processing Fees
              </th>
              <th scope="col" style={{ width: '5%' }}>
                Rate
              </th>
            </tr>
          </thead>
          <tbody style={{ textAlign: 'left' }}>
            {data.map((datum, i) => (
              <tr key={i} className="tableRows">
                <td>{formatCardBrand(datum)}</td>
                <td>{datum.use_count || 0}</td>
                <td data-is-negative={datum.total_charges < 0}>
                  {currency(datum.total_charges).format()}
                </td>
                <td>{formatCurrency(datum.per_transaction_fees)}</td>
                <td>{formatCurrency(datum.rate_fees)}</td>
                {!!summary.interchange_fees_amount && (
                  <td>{formatCurrency(datum.interchange_fees_amount)}</td>
                )}
                <td></td>
                <td>{formatCurrency(datum.total_fees)}</td>
                <td>{datum.rate ? datum.rate + '%' : 0 + '%'} </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td>
                <strong>Total</strong>
              </td>
              <td>
                <strong>{summary.use_count || 0}</strong>
              </td>
              <td data-is-negative={summary.total_charges < 0}>
                <strong>{currency(summary.total_charges).format()}</strong>
              </td>
              <td>
                <strong>{formatCurrency(summary.per_transaction_fees)}</strong>
              </td>
              <td>
                <strong>{formatCurrency(summary.rate_fees)}</strong>
              </td>
              {!!summary.interchange_fees_amount && (
                <td>
                  <strong>
                    {formatCurrency(summary.interchange_fees_amount)}
                  </strong>
                </td>
              )}
              <td></td>
              <td>
                <strong>{formatCurrency(summary.total_fees)}</strong>
              </td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </Table>
    );
  } else {
    return (
      <Table>
        <SectionHeader>
          <TextContainer fontWeight="bold" paddingBottom="8px">
            <Text as="h5">Volumes</Text>
          </TextContainer>
        </SectionHeader>
        <table data-testid="statementV3-sales">
          <thead>
            <tr>
              <th scope="col" style={{ width: '12%' }}>
                Brand
              </th>
              <th scope="col" style={{ width: '16%' }}>
                Count
              </th>
              <th scope="col" style={{ width: '16%' }}>
                Sales
              </th>
              <th scope="col" style={{ width: '16%' }}>
                Per Transaction Fee
              </th>
              <th scope="col" style={{ width: '16%' }}>
                Rate Fees
              </th>
              <th scope="col" style={{ width: '14%' }}></th>
              <th scope="col" style={{ width: '5%' }}>
                Total Processing Fees
              </th>
              <th scope="col" style={{ width: '5%' }}>
                Rate
              </th>
            </tr>
          </thead>
        </table>
        <TableNullState>No Payments Processed</TableNullState>
      </Table>
    );
  }
};
