import { AxiosRequestConfig, AxiosResponse } from 'axios';
import React, {
  FunctionComponent,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { Text } from '@fattmerchantorg/truffle-components';
import { FileRecord } from '@fattmerchantorg/types-omni';
import styled, { withTheme } from 'styled-components';
import { Upload } from '../Upload';
import { coreapi } from '../../../api';
import { AttachmentsProps } from './Attachments.types';
import { useAuthToken, useToaster } from '../../../hooks';
import { ConfirmDeleteModal } from '../../modals/ConfirmDeleteModal';
import { getHeaders } from '../../../util/api.util';
import AttachmentsList from './AttachmentsList';
import { StatusPanel } from '../StatusPanel';

const StyledUpload = withTheme(
  styled(Upload)`
    background: ${({ theme }) => theme.colors.core.gray[800].hex};
    border-radius: 2px;
    margin-bottom: 16px;
    color: ${({ theme }) => theme.white};

    .accepted-files {
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal !important;
      letter-spacing: 0em;
      text-align: left;
      overflow: visible;
      color: ${({ theme }) => theme.white};
    }

    .accepted-files-title {
      font-size: 14px;
      overflow: visible;
      color: ${({ theme }) => theme.white};
    }

    .cloud {
      color: ${({ theme }) => theme.white};
    }

    .progress-text {
      color: ${({ theme }) => theme.white};
    }

    .progress-cancel-button {
      color: ${({ theme }) => theme.colors.status.red[500].hex};
    }
  `
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  width: 100%;
`;

export const Attachments: FunctionComponent<AttachmentsProps> = props => {
  const {
    files,
    children,
    downloadable,

    uploadUrl,
    onUpload,
    postUploadToastMessages = true,
    includeDeleteModal = true,
    includeFileType = true,
    getDeleteUrl,
    handleDelete,
    onDelete,
    uploadParams,
    accept,
    maxSize,
    fileLimit,
    useInlineErrorHandling = false,
    ...restOfProps
  } = props;

  const authToken = useAuthToken();
  const { toast, toaster } = useToaster();

  const existingFiles = files && files.length ? files : [];
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<FileRecord>(null);
  const [uploadError, setUploadError] = useState(null);

  const [fileUpload, setFileUpload] = useState<FileRecord>(null);

  const deleteFile = useCallback(
    async (file: FileRecord, index?: number) => {
      let promise: Promise<unknown>;
      if (getDeleteUrl) {
        const deleteUrl = getDeleteUrl(file);
        promise = coreapi.delete(authToken, deleteUrl, null);
      } else {
        promise = handleDelete(file, index);
      }

      try {
        await promise;
        if (onDelete) onDelete(file);
      } catch (error) {
        toaster(
          toast.error('There was a problem deleting the file.', 'Delete Failed')
        );
      }
    },
    [getDeleteUrl, authToken, handleDelete, onDelete, toaster, toast]
  );

  const uploadConfig = useMemo((): AxiosRequestConfig => {
    return {
      method: 'post',
      url: `${coreapi.baseUrl}${uploadUrl}`,
      headers: getHeaders(authToken),
    };
  }, [authToken, uploadUrl]);

  const handleAlertAboutInvalidFiles = useCallback(
    (errorMessage: string) => {
      if (useInlineErrorHandling) {
        setUploadError(errorMessage);
      } else {
        if (errorMessage) {
          toaster(
            toast.error(
              `${errorMessage}`,
              'There was a problem uploading the file.'
            )
          );
        }
      }
    },
    [toast, toaster, useInlineErrorHandling]
  );

  const handleUploaded = useCallback(
    (res: AxiosResponse<FileRecord>) => {
      if (postUploadToastMessages) {
        toaster(toast.success('The file was uploaded successfully.'));
      }

      setFileUpload(res?.data);
      setUploadError(null);
      if (onUpload) {
        onUpload(res?.data);
      }
    },
    [postUploadToastMessages, onUpload, toaster, toast]
  );

  const handleDeleteProcess = useCallback(() => {
    if (includeDeleteModal) {
      setFileToDelete(fileUpload);
      setDeleteModalOpen(true);
    } else {
      deleteFile(fileUpload);
    }
  }, [
    setFileToDelete,
    setDeleteModalOpen,
    deleteFile,
    includeDeleteModal,
    fileUpload,
  ]);

  return (
    <Container {...restOfProps}>
      <StyledUpload
        accept={accept}
        file={null}
        uploadConfig={uploadConfig}
        onInvalid={handleAlertAboutInvalidFiles}
        onFinish={handleUploaded}
        onDelete={handleDeleteProcess}
        clearAfterUpload={true}
        onError={() => {
          handleAlertAboutInvalidFiles(
            'There was a problem uploading the file.'
          );
        }}
        uploadParams={uploadParams}
        maxSize={maxSize}
        fileLimit={fileLimit}
        fileCount={files.length}
      />
      <AttachmentsList
        files={existingFiles}
        downloadable={downloadable}
        includeDeleteModal={includeDeleteModal}
        includeFileType={includeFileType}
        onDelete={file => {
          if (includeDeleteModal) {
            setFileToDelete(file);
            setDeleteModalOpen(true);
          } else {
            deleteFile(file);
          }
        }}
      />
      {uploadError && (
        <StatusPanel
          data-testid="upload-status-panel"
          status={'error'}
          animated
          style={{ marginTop: '16px', display: 'inline' }}
        >
          <span>Upload Error: {uploadError}</span>
        </StatusPanel>
      )}
      {children}
      {includeDeleteModal && (
        <ConfirmDeleteModal
          title="Delete File"
          isOpen={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={() => {
            deleteFile(fileToDelete);
            setDeleteModalOpen(false);
          }}
          confirmButtonLabel={'Delete'}
          style={{
            icon: { color: '#BDC9CC' },
            confirmDelete: { padding: '40px 0px 40px 0px' },
          }}
        >
          {fileToDelete ? (
            <Text as="p">
              Are you sure that you would like to delete{' '}
              <strong>{fileToDelete.name} </strong>?
            </Text>
          ) : null}
          <Text as="p">This action cannot be undone.</Text>
        </ConfirmDeleteModal>
      )}
    </Container>
  );
};

export default Attachments;
