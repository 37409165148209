import React, { FC, useContext } from 'react';
import styled, { withTheme } from 'styled-components';
import { Widget } from '../../shared/Widget';
import { AsyncDataStatus } from '../../../@types';
import { LoadingSpan } from '../../shared';
import { Billing, Reserves } from '@fattmerchantorg/types-engine/DB';
import { TextLink, Icon } from '@fattmerchantorg/truffle-components';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { SelectedMerchantStore } from '../../../context';
let dollarUSLocale = Intl.NumberFormat('en-US');

const Container = styled(Widget)`
  background: white;
  min-height: 119px;
  max-height: 203px;
`;

const MerchantActionsRow = styled.div`
  display: block;
  justify-content: middle;
  text-align: center;
  margin: 0 auto;
  width: 40%;
`;

const WidgetHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 18px;
  color: ${({ theme }) => theme.black};
  i {
    margin: 0 0 8px;
  }
`;

const InfoText = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #bdc9cc;
`;
const BalanceText = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const Content = withTheme(
  styled.div`
    color: #565656;
    display: flex;
    flex-direction: row;
    margin: 1rem;

    span {
      line-height: 21px;
      font-size: 14px;
      color: ${({ theme }) => theme.black};
      white-space: normal;
    }

    h2 {
      margin: 0px;
      color: ${({ theme }) => theme.black};
    }

    div {
      font-size: 14px;
      color: #32325d;
    }

    > div {
      flex: 50%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    button {
      color: #32325d;
      padding: 0;
    }

    &:last-child {
      padding-right: 0;
    }
    .special-text {
      font-weight: bold;
      font-size: 14px;
      color: ${({ theme }) => theme.colors.core.green[500].hex};
    }
    .info-div {
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -pre-wrap;
      white-space: -o-pre-wrap;
      word-wrap: break-word;
    }
    .billing-profile-content {
      p {
        font-size: 12px;
      }
      table {
        width: 100%;
        thead {
          tr {
            th {
              font-weight: 400;
              font-size: 12px;
              padding-right: 10px;
              &:last-child {
                padding-right: 0;
              }
            }
          }
        }
        tbody {
          tr {
            td {
              font-weight: bold;
              font-size: 12px;
              color: ${({ theme }) => theme.colors.core.green[500].hex};
              padding-right: 10px;
              &:last-child {
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  `
);

const LoadingRow = withTheme(
  styled.div`
    display: flex;
    margin-bottom: 8px;

    /* loading spans */
    > div + div {
      margin-left: 3rem;
    }
  `
);

export const ReservesWidget: FC<{}> = () => {
  const {
    state: { billingProfiles, reserves, status, merchant },
  } = useContext(SelectedMerchantStore);
  const history = useHistory();

  const reserveBillingProfile = billingProfiles.data?.find(
    billing => billing.type === 'RESERVES'
  );
  const activeReserve = reserves.data?.find(
    reserve => reserve.status === 'ACTIVE'
  );
  const dataStatus = [billingProfiles.status, reserves.status, status];

  if (dataStatus.includes(AsyncDataStatus.LOADING)) {
    return (
      <Container>
        <Content>
          <div>
            <WidgetHeader>
              <h2>Reserves</h2>
            </WidgetHeader>
            <LoadingRow>
              <LoadingSpan width="100%" />
            </LoadingRow>
            <LoadingRow>
              <LoadingSpan width="100%" />
            </LoadingRow>
            <LoadingRow>
              <LoadingSpan width="100%" />
            </LoadingRow>
          </div>
        </Content>
      </Container>
    );
  } else if (dataStatus.every(i => i === AsyncDataStatus.IDLE)) {
    return (
      <Container data-testid="reserves-widget-container">
        <Content>
          <div>
            <WidgetHeader>
              <h2>Reserves</h2>
              <TextLink
                onClick={() => {
                  history.push(`/merchant/${merchant?.id}/reserves`);
                }}
                style={{ fontWeight: 700 }}
                data-testid="back-link"
              >
                {activeReserve && (
                  <Icon style={{ color: '#009BF2' }} icon={faChevronRight} />
                )}
              </TextLink>
            </WidgetHeader>
            <div className="billing-profile-content">
              <ReserveProfileContent
                reserve={activeReserve}
                billing={reserveBillingProfile}
              />
            </div>
          </div>
        </Content>
      </Container>
    );
  }
  return null;
};

const ReserveProfileContent: FC<{ reserve: Reserves; billing: Billing }> = ({
  reserve,
  billing,
}) => {
  const history = useHistory();
  const { state } = useContext(SelectedMerchantStore);
  const { merchant } = state;
  if (!billing) {
    return (
      <div>
        <MerchantActionsRow data-testid="add-reserve-button">
          <TextLink
            onClick={() => {
              history.push(`/merchant/${merchant?.id}/reserves`);
            }}
            style={{ fontWeight: 700 }}
            data-testid="open-brand-link"
          >
            <Icon style={{ color: '#009BF2' }} icon={faPlus} />
            Add Reserve
          </TextLink>
          <BalanceText style={{ paddingTop: '8px' }}>
            You have no reserves
          </BalanceText>
        </MerchantActionsRow>
      </div>
    );
  }
  return (
    <div style={{ display: 'inline' }} data-testid="reserve-content">
      {billing['reserve_percent_days'] === 0 &&
      billing['reserve_flat_amount'] > 0 ? (
        <div>
          <InfoText>
            <div className="special-text" style={{ display: 'inline' }}>
              {Math.round(billing['reserve_percent_volume'] * 100)}%
            </div>
            &nbsp;of the settlement is withdrawn and put into reserves until the
            balance reaches
            <div className="special-text" style={{ display: 'inline' }}>
              &nbsp;${dollarUSLocale.format(billing['reserve_flat_amount'])}
            </div>
          </InfoText>
          <div>
            <BalanceText>
              Balance
              <br></br>
              <div className="special-text" style={{ display: 'inline' }}>
                ${dollarUSLocale.format(reserve['balance'])}
              </div>
            </BalanceText>
          </div>
        </div>
      ) : (
        <div>
          {billing['reserve_percent_days'] === null &&
          billing['reserve_flat_amount'] === 0 ? (
            <div>
              <InfoText>
                Reserve amount is set to
                <div className="special-text" style={{ display: 'inline' }}>
                  &nbsp;${dollarUSLocale.format(billing['reserve_flat_amount'])}
                </div>
              </InfoText>
              <BalanceText>
                Balance
                <br></br>
                <div className="special-text" style={{ display: 'inline' }}>
                  ${dollarUSLocale.format(reserve['balance'])}
                </div>
              </BalanceText>
            </div>
          ) : (
            <div>
              {' '}
              <InfoText>
                <div className="special-text" style={{ display: 'inline' }}>
                  100%
                </div>
                &nbsp;of the settlement is withdrawn and put into reserves until
                the balance reaches
                <div className="special-text" style={{ display: 'inline' }}>
                  &nbsp;${dollarUSLocale.format(billing['reserve_flat_amount'])}
                </div>
                &nbsp;which is
                <div className="special-text" style={{ display: 'inline' }}>
                  &nbsp;{Math.round(billing['reserve_percent_volume'] * 100)}%
                </div>
                &nbsp;of their
                <div className="special-text" style={{ display: 'inline' }}>
                  &nbsp;{billing['reserve_percent_days']}
                </div>
                &nbsp;Day rolling average.
              </InfoText>
              <BalanceText>
                Balance
                <br></br>
                <div className="special-text" style={{ display: 'inline' }}>
                  ${dollarUSLocale.format(reserve['balance'])}
                </div>
              </BalanceText>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
