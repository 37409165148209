import React, { FunctionComponent } from 'react';
import { PageHeader } from '../shared';
import { Transactions } from './Transactions';

export const TransactionsPage: FunctionComponent = () => {
  return (
    <>
      <PageHeader title="Payments" />
      <Transactions />
    </>
  );
};
