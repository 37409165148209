import React, {
  useEffect,
  useContext,
  FunctionComponent,
  useState,
} from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

import {
  AuthStore,
  PermissionsStore,
  removeAuth,
  removePermissions,
  updateAuth,
} from '../../context';

import { parse } from '../../util/api.util';
import { coreapi } from '../../api';
import { history } from '../../history';
import { useToaster, useAsyncEffect } from '../../hooks';
import { FullPageLoader } from '../shared';
import { isEmpty } from '../../util/object.util';
import { stringify } from 'querystring';

export const SsoPage: FunctionComponent = () => {
  const {
    state: { status: authStatus },
    dispatch,
  } = useContext(AuthStore);
  const { dispatch: permissionDispatch } = useContext(PermissionsStore);
  const { toaster, toast } = useToaster();
  const { search, pathname } = useLocation();
  const matchToken = useRouteMatch<{ token?: string }>()?.params?.token || null;
  /**
   * Previous user data is potentially still in the context which will
   * immediately redirect the user thinking that its the previous user.
   * Using this piece of state will track wether we have attempted an
   * authentication on the new token yet. After we attempt to auth, we
   * are clear to redirect.
   */
  const [authAttempted, setAuthAttempted] = useState<boolean>(false);

  useAsyncEffect(async () => {
    /**
     * Clear the current auth/permissions and selected merchant:
     * It is possible there is still auth/permission/merchant state from the previous user
     * (Something went wrong with logout / User wasnt properly logged out, Logged in user clicked a magiclink or sso link for a different user)
     * causing authorization/permission errors and unexpected front end display. Clearing this data
     * before finishing authenticating the new user will ensure all the data is fresh and belongs to user from the `token`
     *
     * Note on the localStorage.removeItem('omniconnect.selectedMerchantId')
     * There was a point in time in which Omniconnect had the selected merchant
     * pinned to the sidebar, so when a user logged out and logged back in that
     * merchant still would be pinned. However, the nav is no longer like that
     * so removing the selectedMerchantId from local storage before they log in
     * has no real negative impact anymore.
     *
     * We are removing the merchantToken from local storage here as well.
     */
    localStorage.removeItem('omniconnect.selectedMerchantId');
    localStorage.removeItem('omniconnect.selectedMerchantToken');
    dispatch(removeAuth());
    permissionDispatch(removePermissions());
    const tokens: string | string[] = matchToken || parse(search)['jwt'];
    const token = Array.isArray(tokens) ? tokens[0] : tokens;
    try {
      if (pathname.includes('magiclink')) {
        const res = await coreapi.post(token, `/magiclink/${token}`);
        dispatch(updateAuth(res));
        setAuthAttempted(true);
      } else {
        const res = await coreapi.get(token, '/self/list');
        dispatch(updateAuth(res));
        setAuthAttempted(true);
      }
    } catch (error) {
      toaster(
        toast.error(error, 'There was a problem authenticating.', {
          lifetime: 'forever',
        })
      );
    }
  }, [search, matchToken]);

  useEffect(() => {
    if (authAttempted && authStatus !== 'notauthed') {
      let { redirect, ...query } = parse(search);
      delete query['jwt[]'];
      delete query['jwt'];
      if (Array.isArray(redirect)) {
        redirect = redirect[0];
      }
      redirect = redirect || '/';
      if (!isEmpty(query)) redirect += `?${stringify(query)}`;
      history.replace(redirect);
    }
  }, [search, authStatus, authAttempted]);

  return <FullPageLoader />;
};
