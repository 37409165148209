import React, { FunctionComponent } from 'react';
import { Tooltip, Icon } from '@fattmerchantorg/truffle-components';
import { Merchant } from '@fattmerchantorg/types-omni';
import { faPeopleArrows } from '@fortawesome/pro-solid-svg-icons';
import { usePermissions } from '../../hooks';
import { TextLink } from '@fattmerchantorg/truffle-components';

export const AssumeLink: FunctionComponent<{ merchant: Merchant }> = props => {
  const { permit } = usePermissions();
  const { merchant } = props;
  // if merchant is pending, disable assuming
  const disabled = merchant.status === 'PENDING';
  const href = disabled
    ? '#'
    : `${process.env.REACT_APP_STAX_PAY_URL}/#/dashboard?assume=${merchant.id}`;
  const tooltipContent = disabled
    ? 'Pending merchants cannot be assumed'
    : null;

  return permit('godview', 'assumeSubmerchants', 'read') ? (
    <Tooltip disabled={!tooltipContent} content={tooltipContent}>
      <TextLink
        href={href}
        onClick={e => {
          if (disabled) e.preventDefault();
        }}
        target="_blank"
        style={{ fontWeight: 700 }}
        data-testid="assume-merchant-link"
      >
        <Icon icon={faPeopleArrows} />
        Assume
      </TextLink>
    </Tooltip>
  ) : null;
};
