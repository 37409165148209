import React, {
  useContext,
  useState,
  useCallback,
  FunctionComponent,
} from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import styled, { withTheme } from 'styled-components';
import { Widget } from '../../shared/Widget';
import { Button, Pill, Alert } from '../../shared';
import { MockModal } from './UnderwritingStatusWidget/MockModal';
import SubTitle from './UnderwritingStatusWidget/SubTitle';
import { getUnderwritingStatusName } from '../../../util/mockFeature.util';
import {
  AuthStore,
  deriveAuthMode,
  SelectedMerchantStore,
} from '../../../context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHandHoldingUsd,
  faClipboardCheck,
  faSpinner,
  faPaperPlane,
} from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { RiskHoldToggle } from './UnderwritingStatusWidget/RiskHoldToggle';
import { AccountStatus } from './UnderwritingStatusWidget/AccountStatus';
import {
  useAsyncEffect,
  usePermissions,
  useToaster,
  useAsyncOnClick,
} from '../../../hooks';
import { onboardingapi, captainhookapi } from '../../../api';
import { downloadData } from '../../../util/documents.util';
import { RISK_HOLD_TOOLTIP_TEXT } from '../../../util';
import { Registration } from '@fattmerchantorg/types-omni';
import {
  underwritingStatusApprovedReasons,
  getUnderwritingStatusReasonsTitle,
} from '../../../util/underwriting.util';

const Container = styled(Widget)`
  background: white;
`;

const Status = withTheme(
  styled.div`
    color: #565656;
    display: flex;
    flex-direction: column;
    margin: 1rem;

    span {
      font-size: 16px;
      color: ${({ theme }) => theme.colors.core.green[500].hex};
    }
  `
);

const IconWrapper = styled.div`
  background: #c11e9b;
  border-radius: 5px;
  padding: 4px 0 0 3px;
  margin: 14px 8px;
  width: 24px;
  height: 24px;
  float: right;

  i {
    height: 20px;
    width: 20px;
    color: white;
  }
`;

const ClickableAction = styled.span`
  color: #009bf2;
  text-decoration: underline;
  cursor: pointer;
`;

const Details = withTheme(
  styled.div`
    background: ${({ theme }) => theme.colors.core.green[800].hex};
    border-radius: 0 0 3px 3px;
    color: white;

    h3 {
      color: white;
      font-weight: 700;
    }

    hr {
      background: white;
      margin: 0 1rem 0 1rem;
    }
  `
);

const Reasons = styled.div`
  padding: 1rem;

  i {
    padding-right: 4px;
  }
`;

const ScrollContainer = styled.div<{ maxHeight?: number }>`
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : 'unset')};
  overflow-y: auto;
`;

const StyledFileLabel = styled.div`
  color: #009bf2;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  span {
    color: #868a91;
  }
  flex: 1;
`;

const StatusNameAndDownloadWrapper = styled.div`
  display: flex;
  flex-direction: row nowrap;
  justify-content: space-between;
`;

export const UnderwritingStatusWidget: FunctionComponent<{
  accountStatus: string;
  underwritingStatus: Registration['underwriting_status'];
  subStatuses?: any[];
  note?: string;
  electronicSignatureTimestamp: string;
  canSeeRiskHold: boolean;
  canWriteRiskHold: boolean;
  riskHold: boolean;
  merchantId: string;
}> = ({
  accountStatus,
  underwritingStatus,
  subStatuses,
  note,
  electronicSignatureTimestamp,
  canSeeRiskHold,
  canWriteRiskHold,
  riskHold,
  merchantId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const hasSubStatuses = subStatuses && subStatuses.length > 0;
  const { state: authState } = useContext(AuthStore);
  const { state: selectedMerchantState } = useContext(SelectedMerchantStore);
  const authToken = authState.auth?.token ?? null;
  const mode = deriveAuthMode(authState);
  const { permit } = usePermissions();
  const [reReviewStatus, setReReviewStatus] = useState<
    'idle' | 'processing' | 'processed'
  >('idle');
  const [state, setState] = useState<'idle' | 'downloading'>('idle');

  const { toaster, toast } = useToaster();

  /*
   * Download file.
   */
  const downloadFile = async (merchantId: string) => {
    try {
      const res = await onboardingapi.get(
        authToken,
        `/pdf/${merchantId}/all?getAs=base64`
      );
      downloadData(res, merchantId, 'application/pdf');
    } catch (error) {
      toaster(
        toast.error(error, 'There was a problem downloading the application.')
      );
    }
  };

  const requestWebsiteReReview = useAsyncOnClick(
    useCallback(async () => {
      try {
        await captainhookapi.post(authToken, '/action', {
          event_name: 'update_merchant_registration_files',
          merchant_id: selectedMerchantState.merchantId,
          payload: selectedMerchantState.registration,
        });
        setReReviewStatus('processed');
        toaster(toast.success('Re-review has been requested.'));
      } catch (error) {
        setReReviewStatus('idle');
        toaster(
          toast.error(error, 'There was a problem requesting a re-review.')
        );
      }
    }, [
      selectedMerchantState.merchantId,
      selectedMerchantState.registration,
      authToken,
      toast,
      toaster,
    ])
  );

  // Only visible for users with BOTH read/write permissions of onboarding and write permission for PII
  const canDownloadMerchantApplication =
    permit('godview', 'onboarding', 'read') &&
    permit('godview', 'pii', 'write');

  useAsyncEffect(
    async isMounted => {
      switch (state) {
        case 'downloading': {
          const p = downloadFile(merchantId);
          if (p instanceof Promise) await p;
          break;
        }
        case 'idle':
          break;
      }

      if (isMounted() && state !== 'idle') {
        setState('idle');
      }
    },
    [state]
  );

  const hasApprovalReason = (reasons: { key: string; message: string }[]) => {
    const approvedKeys = Object.keys(underwritingStatusApprovedReasons);
    return reasons?.some(reason => approvedKeys.includes(reason.key));
  };

  const renderWebsiteReReview = () => {
    return (
      <>
        <ClickableAction
          style={{ marginLeft: '10px' }}
          onClick={requestWebsiteReReview}
        >
          {reReviewStatus === 'processing' ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <FontAwesomeIcon icon={faClipboardCheck} />
          )}
          &nbsp;Request Re-Review
        </ClickableAction>
        {reReviewStatus === 'processed' && (
          <>
            {' '}
            <FontAwesomeIcon
              style={{ color: 'rgb(117, 214, 151)' }}
              href="#"
              id="reReviewTooltip"
              icon={faPaperPlane}
            />{' '}
            <UncontrolledTooltip target="reReviewTooltip">
              Submitted, will be reviewed.
            </UncontrolledTooltip>
          </>
        )}
      </>
    );
  };

  return (
    <Container data-testid="underwriting_status_test_widget">
      <Status>
        <div>
          {mode === 'TEST_MODE' && (
            <IconWrapper>
              <Button
                variant="link"
                onClick={() => setIsModalOpen(true)}
                data-testid="edit_underwriting_status_button"
              >
                <i className="fa fa-edit"></i>
              </Button>
            </IconWrapper>
          )}

          <MockModal
            isModalOpen={isModalOpen}
            closeModal={() => setIsModalOpen(false)}
            originalUnderwritingStatus={underwritingStatus}
            originalAccountStatus={accountStatus}
          ></MockModal>

          {canSeeRiskHold && riskHold && (
            <Alert type="warning" message={RISK_HOLD_TOOLTIP_TEXT} />
          )}

          <SubTitle>Underwriting/Application Status</SubTitle>
          <StatusNameAndDownloadWrapper>
            <span>
              {getUnderwritingStatusName(
                underwritingStatus,
                electronicSignatureTimestamp
              )}
              {underwritingStatus === 'APPROVED' &&
                hasApprovalReason(subStatuses) && (
                  <Pill
                    data-testid="underwriting-conditional-approval-pill"
                    motif="neutral"
                    style={{ marginLeft: '5px' }}
                  >
                    <FontAwesomeIcon icon={faHandHoldingUsd as IconProp} />
                    <div style={{ marginLeft: '1px' }}>
                      Conditional Approval
                    </div>
                  </Pill>
                )}
            </span>
            {canDownloadMerchantApplication && (
              <Button
                variant="link"
                style={{
                  cursor: downloadFile ? 'pointer' : 'not-allowed',
                }}
                onClick={async e => {
                  if (!downloadFile) return;
                  setState('downloading');
                  e.stopPropagation();
                }}
              >
                <StyledFileLabel>
                  {state === 'downloading' && (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  )}
                  {state !== 'downloading' && (
                    <i className="fas fa-download"></i>
                  )}
                </StyledFileLabel>
              </Button>
            )}
          </StatusNameAndDownloadWrapper>

          {permit('godview', 'status', 'read') ? (
            <AccountStatus
              accountStatus={accountStatus}
              merchantId={merchantId}
            />
          ) : null}

          {canSeeRiskHold && (
            <RiskHoldToggle
              authToken={authToken}
              riskHold={riskHold}
              canWriteRiskHold={canWriteRiskHold}
            />
          )}
        </div>
      </Status>
      <Details>
        {hasSubStatuses && (
          <Reasons>
            <div>
              <h3>
                {getUnderwritingStatusReasonsTitle(
                  underwritingStatus,
                  subStatuses
                )}
              </h3>
            </div>
            {subStatuses.map(subStatus => (
              <div key={subStatus.key}>
                - {subStatus.message}{' '}
                {underwritingStatus === 'APPROVED' &&
                  subStatus.message ===
                    underwritingStatusApprovedReasons.APPROVED_WEBSITE_COMPLIANCE &&
                  renderWebsiteReReview()}
              </div>
            ))}
          </Reasons>
        )}
        {hasSubStatuses && note && <hr />}
        {note && (
          <ScrollContainer maxHeight={550}>
            <Reasons>
              <h3>Notes</h3>
              {note.split('\n').map(p => (
                <p>{p}</p>
              ))}
            </Reasons>
          </ScrollContainer>
        )}
      </Details>
    </Container>
  );
};
