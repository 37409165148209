import { TextLink } from '@fattmerchantorg/truffle-components';
import React from 'react';
import { InputHTMLAttributes } from 'react';
import { Field } from '../Field';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  linkText?: string;
}

const FileField = ({ name, linkText, ...props }: Props) => {
  const isComponentHidden = linkText !== null;

  const handleUploadLinkClick = e => {
    e.preventDefault();
    document.getElementById(name).click();
  };

  return (
    <>
      <Field<FileList> name={name}>
        {({ input: { value, onChange, ...input } }) => (
          <input
            {...input}
            type="file"
            onChange={({ target }) => onChange(target.files)}
            style={isComponentHidden ? { display: 'none' } : null}
            {...props}
          />
        )}
      </Field>

      {/* Display link if hidden == true */}
      {isComponentHidden && (
        <TextLink
          href=""
          icon={['fas', 'paperclip']}
          id="attachFileLink"
          onClick={handleUploadLinkClick}
        >
          {linkText}
        </TextLink>
      )}
    </>
  );
};

export default FileField;
