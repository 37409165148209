import React, { Dispatch, FunctionComponent, useContext } from 'react';
import { SelectedMerchantStore } from '../../../context';
import {
  Text,
  BaseModal,
  ModalHeader,
} from '@fattmerchantorg/truffle-components';
import { useUpdateNumber } from '../../../hooks';

import {
  openModal,
  closeModal,
  BankAccountForm as BankAccountFormModalBody,
  SavingAccountModalBody,
  PendedAccountModalBody,
  ApprovedAccountModalBody,
  ErrorSavingAccountModalBody,
  BankAccountsPageModalState,
  BankAccountsPageModalAction,
} from './modals';

import { FundingAccount } from '@fattmerchantorg/types-omni';
import { ConfirmDeleteModal } from '../../modals/ConfirmDeleteModal';

import { ConfirmModal } from '../../settings/ConfirmModal';
import {
  checkAccountTypeUpdate,
  getNickname,
} from '../util/BankAccountsPage.util';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';

interface BankAccountsPageModalsProps {
  selectedAccount: FundingAccount;
  editBankAccountPayload?: Partial<FundingAccount>;
  numberOfActiveFeeAccounts?: number;

  modalState: BankAccountsPageModalState;
  modalDispatch: Dispatch<BankAccountsPageModalAction>;

  onAdd: (payload: Partial<FundingAccount>) => void;

  onEdit: (
    accountId: string,
    payload: Partial<FundingAccount>,
    operation: 'rejected' | 'deactivated' | 'updated' | 'approved'
  ) => void;

  onConfirmEdit: (
    accountId: string,
    payload: Partial<FundingAccount>,
    operation: 'rejected' | 'deactivated' | 'updated' | 'approved'
  ) => void;
}

export const BankAccountsPageModals: FunctionComponent<
  BankAccountsPageModalsProps
> = props => {
  const {
    selectedAccount,
    editBankAccountPayload,
    modalState,
    modalDispatch,
    numberOfActiveFeeAccounts,
  } = props;
  const {
    state: { registration },
  } = useContext(SelectedMerchantStore);
  const incrementUpdateNumber = useUpdateNumber()[1];
  const selectedAccountName = `${selectedAccount?.bank_name} **${selectedAccount?.last_four}`;

  return (
    <>
      <BaseModal
        title={'Add Bank Account'}
        isOpen={modalState.open === 'add'}
        style={{
          overlay: { pointerEvents: 'auto', zIndex: 100 },
          content: {
            maxWidth: '480px',
            innerHeight: 'fit-content',
          },
        }}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
      >
        <ModalHeader
          title={'Add Bank Account'}
          onClose={
            // do not allow user to close if the modal is in the 'saving' state
            modalState.body === 'saving'
              ? null
              : () => modalDispatch(closeModal())
          }
        />

        {modalState.body === 'form' && (
          <BankAccountFormModalBody
            numberOfActiveFeeAccounts={numberOfActiveFeeAccounts}
            registration={registration}
            onSubmit={props.onAdd}
            onCancel={() => modalDispatch(closeModal())}
          />
        )}
        {modalState.body === 'saving' && <SavingAccountModalBody />}
        {modalState.body === 'approved' && (
          <ApprovedAccountModalBody
            account={selectedAccount}
            onCancel={() => modalDispatch(closeModal())}
          />
        )}
        {modalState.body === 'pended' && (
          <PendedAccountModalBody
            fundingAccountId={selectedAccount?.id}
            onCancel={() => modalDispatch(closeModal())}
            onFinish={() => modalDispatch(closeModal())}
          />
        )}
        {modalState.body === 'error' && (
          <ErrorSavingAccountModalBody
            account={selectedAccount}
            onResubmit={() => modalDispatch(openModal('add', 'form'))}
          />
        )}
      </BaseModal>

      <BaseModal
        title={'Edit Bank Account'}
        isOpen={modalState.open === 'edit'}
        style={{
          overlay: { pointerEvents: 'auto', zIndex: 100 },
          content: {
            maxWidth: '480px',
            innerHeight: 'fit-content',
          },
        }}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
      >
        <ModalHeader
          title={'Edit Bank Account'}
          onClose={() => modalDispatch(closeModal())}
        />
        <BankAccountFormModalBody
          numberOfActiveFeeAccounts={numberOfActiveFeeAccounts}
          registration={registration}
          account={selectedAccount}
          onSubmit={payload =>
            props.onConfirmEdit(payload.id, payload, 'updated')
          }
          onCancel={() => modalDispatch(closeModal())}
          onDeactivate={_ => modalDispatch(openModal('deactivate'))}
          onUploadFile={incrementUpdateNumber}
        />
      </BaseModal>

      <ConfirmDeleteModal
        title="Deactivate Account"
        isOpen={modalState.open === 'deactivate'}
        onClose={() => modalDispatch(closeModal())}
        confirmButtonLabel="Deactivate Account"
        onConfirm={() =>
          props.onEdit(selectedAccount.id, { active: false }, 'deactivated')
        }
        zIndex={100}
      >
        <Text as="p">
          Are you sure you want to deactivate the bank account called{' '}
          <strong>{selectedAccountName}</strong>?
        </Text>
      </ConfirmDeleteModal>

      <ConfirmDeleteModal
        title="Reject Account"
        isOpen={modalState.open === 'reject'}
        onClose={() => modalDispatch(closeModal())}
        confirmButtonLabel="Reject Account"
        onConfirm={() =>
          props.onEdit(
            selectedAccount.id,
            { status: 'REJECTED', active: false },
            'rejected'
          )
        }
        zIndex={100}
      >
        <Text as="p">
          Are you sure you want to reject the bank account called{' '}
          <strong>{selectedAccountName}</strong>?
        </Text>
      </ConfirmDeleteModal>

      <ConfirmModal
        title="Approve Account"
        isOpen={modalState.open === 'approve'}
        onClose={() => modalDispatch(closeModal())}
        confirmButtonLabel="Approve Account"
        onConfirm={() =>
          props.onEdit(
            selectedAccount.id,
            { status: 'APPROVED', active: true },
            'approved'
          )
        }
        zIndex={100}
      >
        <Text as="p">
          Are you sure you want to approve the bank account called{' '}
          <strong>{selectedAccountName}</strong>?
        </Text>
      </ConfirmModal>

      <ConfirmModal
        title="Update Account"
        isOpen={modalState.open === 'update'}
        onClose={() => modalDispatch(closeModal())}
        confirmButtonLabel="Yes, Update"
        cancelButtonLabel="No, Cancel"
        icon={faExclamationCircle}
        onConfirm={() => {
          modalDispatch(closeModal());
          props.onEdit(selectedAccount.id, editBankAccountPayload, 'updated');
        }}
        zIndex={100}
      >
        {selectedAccount &&
        'primary' ===
          checkAccountTypeUpdate(
            selectedAccount?.flags,
            editBankAccountPayload?.flags
          ) ? (
          <Text as="p">
            Are you sure you want to update the bank account called{' '}
            <strong>{getNickname(selectedAccount)}</strong> to act as your{' '}
            <strong>Primary Account</strong>?
          </Text>
        ) : null}
        {selectedAccount &&
        !selectedAccount?.flags?.includes('FEE') &&
        'fee' ===
          checkAccountTypeUpdate(
            selectedAccount?.flags,
            editBankAccountPayload?.flags
          ) ? (
          <Text as="p">
            Are you sure you want to update the bank account called{' '}
            <strong>{getNickname(selectedAccount)}</strong> to act as your{' '}
            <strong>Deposit & Fees Account</strong>?
          </Text>
        ) : null}
        {selectedAccount &&
        'primaryAndFee' ===
          checkAccountTypeUpdate(
            selectedAccount?.flags,
            editBankAccountPayload?.flags
          ) ? (
          <Text as="p">
            Are you sure you want to update the bank account called{' '}
            <strong>{getNickname(selectedAccount)}</strong> to act as your{' '}
            <strong>Primary Account</strong> and{' '}
            <strong>Deposit & Fees Account</strong>?
          </Text>
        ) : null}
      </ConfirmModal>
    </>
  );
};
