import React, { useState, Fragment, useEffect } from 'react';
import { TableSectionV3 } from '../styles/table-section-component-v3';
import { Text } from '@fattmerchantorg/truffle-components';
import { StatementV3Props } from '../util/statement-v3-utils';
import { useStatementSummaryState } from '../../../../context/statements';
import {
  useAuthToken,
  useAsyncEffect,
  useSearchState,
  usePermissions,
} from '../../../../hooks';
import { queryapi } from '../../../../api';
import {
  CollectionPagination,
  GetFeeStatementAdjustmentDatum,
  GetFeeStatementAdjustmentsResponse,
} from '@fattmerchantorg/types-omni';
import currency from 'currency.js';
import { format } from '../../../../util/date.util';
import { Link as RRDLink, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { TableNullState, linkStyles } from '../styles';
import {
  LoadingState,
  useStatementLoadingState,
} from '../../../../context/statements';
import { LoadingRows } from '../../shared/loading-rows';
import { LoadMoreRow } from '../../shared/load-more-row';
import { TextContainer } from '../../components';
import {
  getAdjustmentFormattedAmount,
  getAdjustmentTransactionLink,
  getAdjustmentDepositLink,
  getAdjustmentTitle,
} from '../util';

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InternalLink = styled(RRDLink)`
  ${linkStyles}
` as typeof RRDLink;

const ExternalLink = styled.a`
  ${linkStyles}
`;

const AdjustmentsTable = styled(TableSectionV3)`
  table {
    tr {
      td,
      th {
        &:last-child {
          text-align: right;
        }
        &:not(:last-child) {
          text-align: left;
        }
      }
    }

    tbody > tr > td,
    tfoot > tr > td {
      &:last-child {
        &[data-is-red='true'] {
          color: ${props => props.theme.colors.status.red['500'].hex};
        }
      }
    }
  }
`;

export type RefundsAdjustmentsSectionProps = StatementV3Props & {
  location: RouteComponentProps['location'];
};

const RefundOrAdjustmentLink: React.FunctionComponent<{
  merchantId: string;
  adjustment: GetFeeStatementAdjustmentDatum;
}> = props => {
  const { adjustment, merchantId } = props;
  const { permit } = usePermissions();

  const isCreditOrDebitAdjustment = adjustment.type?.includes('adjustment');
  const canAssumeMerchants = permit('godview', 'assumeSubmerchants', 'read');

  if (isCreditOrDebitAdjustment && canAssumeMerchants) {
    return (
      <ExternalLink
        rel="noopener noreferrer"
        href={getAdjustmentDepositLink(adjustment, merchantId)}
        target="_blank"
      >
        {adjustment.id}
      </ExternalLink>
    );
  } else if (isCreditOrDebitAdjustment && !canAssumeMerchants) {
    return <Text as="span">{adjustment.id}</Text>;
  } else {
    return (
      <InternalLink
        rel="noopener noreferrer"
        to={getAdjustmentTransactionLink(adjustment, merchantId)}
        target="_blank"
      >
        {adjustment.id}
      </InternalLink>
    );
  }
};

export const RefundsAdjustmentsSection: React.FC<RefundsAdjustmentsSectionProps> =
  React.forwardRef((props, ref) => {
    const { startDate, endDate, merchantId } = props;
    const authToken = useAuthToken();
    const { updateRefundsAdjustments } = useStatementSummaryState();
    const [perPage = 1000] = useSearchState('adjustmentsPerPage');

    const [page, setPage] = useState(1);
    const [data, setData] =
      useState<GetFeeStatementAdjustmentsResponse['data']>(null);
    const [summary, setSummary] =
      useState<GetFeeStatementAdjustmentsResponse['summary']>(null);
    const [pagination, setPagination] = useState<CollectionPagination>(null);

    const {
      refundAdjustmentsLoading,
      setRefundAdjustmentsLoading,
      statementLoadingState: { isFirstLoad },
    } = useStatementLoadingState();
    const isLoading = refundAdjustmentsLoading === LoadingState.Loading;

    useAsyncEffect(async () => {
      if (isLoading && authToken && startDate && endDate) {
        try {
          const query = { startDate, endDate, page, perPage };
          const response =
            await queryapi.get<GetFeeStatementAdjustmentsResponse>(
              authToken,
              'statement/v3/adjustments',
              query
            );
          const { data, summary, ...pagination } = response;

          if (page === 1) {
            // if the user is loading the first page, simply set the data
            setData(data);
          } else {
            // if the user is loading any page other than the first, append the data
            // in order to show a continuous list of records
            setData(previousData => [...previousData, ...data]);
          }

          setSummary(summary);
          setPagination(pagination);
          updateRefundsAdjustments(-summary?.adjustments_amount);
          setRefundAdjustmentsLoading(LoadingState.Completed);
        } catch (error) {
          setRefundAdjustmentsLoading(LoadingState.Failed);
        }
      }
    }, [isLoading, authToken, startDate, endDate, perPage, page]);

    useEffect(() => {
      // trigger another fetch if the user clicks the "Load X More" button
      setRefundAdjustmentsLoading(LoadingState.Loading);
    }, [page, setRefundAdjustmentsLoading]);

    useEffect(() => {
      /**
       * Reset the data if the user changes the adjustmentsPerPage query param
       * This is necessary to prevent duplicate rows
       */
      setData(null);
      /**
       * Reset the page if the user changes the adjustmentsPerPage query param
       */
      setPage(1);
      /**
       * Refetch the data if the user changes the adjustmentsPerPage query param
       */
      setRefundAdjustmentsLoading(LoadingState.Loading);
    }, [perPage, setRefundAdjustmentsLoading]);

    if (!data || !summary) return null;

    return (
      <AdjustmentsTable ref={ref}>
        <SectionHeader>
          <TextContainer fontWeight="bold" paddingBottom="8px">
            <Text as="h5">Refunds & Adjustments</Text>
          </TextContainer>
        </SectionHeader>
        <table data-testid="statementV3-refunds">
          <colgroup>
            <col style={{ width: '25%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '45%' }} />
          </colgroup>
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Description</th>
              <th>Amount</th>
            </tr>
          </thead>
          {data.length ? (
            <Fragment>
              <tbody>
                {data.map((datum, i) => (
                  <tr key={i} className="tableRows">
                    <td>
                      <RefundOrAdjustmentLink
                        merchantId={merchantId}
                        adjustment={datum}
                      />
                    </td>
                    <td>{format(new Date(datum.created_at), 'MM/dd/yyyy')}</td>
                    <td>{getAdjustmentTitle(datum)}</td>
                    {/* if this was a credit adjustment, that means the merchant was given money. in that case, we want to display it as a positive number (not red) */}
                    <td data-is-red={datum.type !== 'credit_adjustment'}>
                      {getAdjustmentFormattedAmount(datum)}
                    </td>
                  </tr>
                ))}
                {isLoading && !isFirstLoad ? (
                  <LoadingRows
                    pagination={pagination}
                    tdWidths={[185, 53, 31, 36]}
                  />
                ) : null}
              </tbody>
              <tfoot>
                <LoadMoreRow
                  colSpan={4}
                  disabled={isLoading}
                  pagination={pagination}
                  isLoading={isLoading}
                  onClick={() => setPage(page => page + 1)}
                />
                <tr data-is-first-load={isFirstLoad}>
                  <td>
                    <strong>Total</strong>
                  </td>
                  <td />
                  <td />
                  {/* if the adjustments amount is less than 0, that means the merchant received more money than they lost. in that case, we want to display this as a positive number (not red) */}
                  <td data-is-red={summary.adjustments_amount > 0}>
                    {summary?.adjustments_amount
                      ? currency(-summary.adjustments_amount).format()
                      : currency(0).format()}
                  </td>
                </tr>
              </tfoot>
            </Fragment>
          ) : null}
        </table>
        {!data.length && (
          <TableNullState>No Refunds or Adjustments</TableNullState>
        )}
      </AdjustmentsTable>
    );
  });
