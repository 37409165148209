import { MaskType } from '../../../@types/MaskType';
import { showOnlyLastFour } from '../../../util/string.util';

export function maskInput(
  value: string,
  viewValue: boolean,
  maskType: MaskType,
  allowViewValue?: boolean
) {
  // Remove non-digits characters
  const cleanValue = value || '';
  const cleanNumericValue = cleanValue.replace(/[\D]/g, '');

  switch (maskType) {
    case 'ssn':
      return maskSsn(cleanNumericValue, viewValue);

    case 'accountNumber':
      return maskAccountNumber(cleanNumericValue, viewValue, allowViewValue);

    default:
      return cleanValue;
  }
}

function maskSsn(cleanValue: string, viewValue: boolean) {
  const maskedValue = viewValue
    ? cleanValue
    : (cleanValue || '').replace(/[\d]/g, '*');

  if (maskedValue.length <= 3) {
    return maskedValue;
  } else if (maskedValue.length <= 5) {
    return maskedValue.slice(0, 3) + '-' + maskedValue.slice(3, 5);
  }
  return (
    maskedValue.slice(0, 3) +
    '-' +
    maskedValue.slice(3, 5) +
    '-' +
    maskedValue.slice(5, 9)
  );
}

function maskAccountNumber(
  cleanValue: string,
  viewValue: boolean,
  allowViewValue?: boolean
) {
  const maskedValue = viewValue
    ? cleanValue
    : showOnlyLastFour(cleanValue, allowViewValue);

  return maskedValue;
}
