import Omni from '@fattmerchantorg/types-omni';

export const getExtension = (name: string) => {
  if (!name) return null;
  const delimiter = name.includes('/') ? '/' : name.includes('.') ? '.' : null;
  if (!delimiter) return null;
  return name.split(delimiter).pop();
};

// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/** Takes a blob and pushes a text/csv file to the browser. */
export function pushCSVToBrowser(data: Blob, filename: string) {
  // Set up contents.
  const csvData = new Blob([data], { type: 'text/csv' });
  const csvUrl = URL.createObjectURL(csvData);
  const element = document.createElement('a');
  element.setAttribute('href', csvUrl);
  element.setAttribute('download', filename);
  element.style.display = 'none';

  // Append and call.
  document.body.appendChild(element);
  element.click();

  // Clean up.
  document.body.removeChild(element);
}

export const isUnderFileSize = (
  file: File,
  maxFilesizeInBytes: number = 31457280 // 30mbs
) => {
  return file && file.size <= maxFilesizeInBytes;
};

export const checkMagicNumber = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();

    reader.onerror = err => reject(err);

    reader.onload = function () {
      if (reader.readyState === FileReader.DONE) {
        //@ts-ignore
        const uint = new Uint8Array(reader.result);
        let bytes = [];
        uint.forEach(byte => {
          bytes.push(byte.toString(16));
        });
        const hex = bytes.join('').toUpperCase();

        resolve(hex);
      }
    };

    reader.readAsArrayBuffer(file.slice(0, 4));
  });
};

export const inPossibleFileBits = (hex: string) => {
  const fileTypes = [
    '25504446', //pdf
    '89504E47', // gif,
    'FFD8FFE1', // jpeg
    'FFD8FFE0', // jpeg
    'FFD8FFE1', //  jpeg
    '504B0304', // zip
  ];

  return fileTypes.includes(hex);
};

export const dataURIToBlob = (dataURI: string, mimeString: string) => {
  const binStr = atob(dataURI.split(',')[1]);
  const len = binStr.length;
  const arr = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i);
  }

  return new Blob([arr], {
    type: mimeString,
  });
};

export const createFileDisplayName = (
  fileName: string,
  createdAtString: string
) => {
  if (fileName?.length > 0) {
    return fileName;
  }
  const date = new Date(createdAtString);
  if (isNaN(date.valueOf())) {
    return '';
  }
  return date.toString();
};

export const getFileExtension = (file: Omni.FileRecord): string => {
  if (file.meta && file.meta.extension) {
    return file.meta.extension;
  }

  const last = array => (Array.isArray(array) ? array[array.length - 1] : '');

  const urlExtension = last(
    last((file.public_url || '').split('/')).split('.')
  );

  if (urlExtension) {
    return urlExtension;
  }

  const nameExtension = last((file.name || '').split('.'));

  return nameExtension;
};

export const isImageFile = (file: Omni.FileRecord): boolean => {
  const fileExtension = getFileExtension(file);
  return ['gif', 'png', 'jpg', 'jpeg'].indexOf(fileExtension) > -1;
};
