import React, { FC } from 'react';
import styled, { withTheme } from 'styled-components';
import { Widget } from '../../shared/Widget';
import dollarSignBackground from '../../../assets/img/brand/dollar-sign-background.png';

const Container = styled(Widget)`
  background: white;
  background-image: url(${dollarSignBackground});
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 300px;
`;

const Heading = styled.h2`
  color: ${({ theme }) => theme.colors.core.gray[800].hex};
`;

const Content = withTheme(
  styled.div`
    color: #565656;
    display: flex;
    flex-direction: row;
    margin: 1rem;

    span {
      padding: 2px;
      font-size: 30px;
      color: ${({ theme }) => theme.colors.core.green[500].hex};
      white-space: nowrap;
      margin-bottom: 8px;
      letter-spacing: 0px;
      opacity: 1;
    }

    .tiny {
      font-size: 12px;
    }

    h3 {
      text-align: left;
      font-weight: 700;
      letter-spacing: 0px;
      color: #111e52;
      opacity: 1;
      margin: 8px 0 0 0;
    }

    div {
      font-weight: 700;
      font-size: 12px;
      color: #32325d;
    }

    > div {
      flex: 50%;
      display: flex;
      flex-direction: column;
    }
  `
);

export const PricingCardWidget: FC<{
  identicalCardRates: boolean;
  cnpRate: string;
  cnpAmexRate: string;
  cpRate: string;
  cpAmexRate: string;
  ach: string;
  title: string;
  cap?: string;
  cpCreditSurchargeRate: string;
  cnpCreditSurchargeRate: string;
  cpDebitRate: string;
  cnpDebitRate: string;
  isFlatRate: boolean;
}> = ({
  identicalCardRates,
  cnpRate,
  cnpAmexRate,
  cpRate,
  cpAmexRate,
  ach,
  title,
  cap,
  cpCreditSurchargeRate,
  cnpCreditSurchargeRate,
  cpDebitRate,
  cnpDebitRate,
  isFlatRate,
}) => {
  let capLabel = cap ? `${cap}` : '(no ACH fee cap)';
  const nonSurchargeLabel =
    cnpCreditSurchargeRate || cpCreditSurchargeRate ? 'Non-surcharge ' : '';

  return (
    <Container data-testid={`pricing_widget_${title.replace(/\s/g, '_')}`}>
      <Content>
        <div>
          <Heading>
            <i className="fa fa-tags" aria-hidden="true"></i> Pricing:{' '}
            {isFlatRate ? `Flat Rate` : `Interchange +`}
          </Heading>

          {cnpCreditSurchargeRate && (
            <>
              <h3>Surcharge CNP Rate</h3>
              <span>{cnpCreditSurchargeRate}</span>
            </>
          )}
          {cpCreditSurchargeRate && (
            <>
              <h3>Surcharge CP Rate</h3>
              <span>{cpCreditSurchargeRate}</span>
            </>
          )}

          {identicalCardRates ? (
            <>
              {cnpRate && (
                <>
                  <h3>{nonSurchargeLabel}Credit Card (non-AMEX)</h3>
                  <span>{cnpRate}</span>
                </>
              )}
              {cnpAmexRate && (
                <>
                  <h3>{nonSurchargeLabel}Credit Card (AMEX)</h3>
                  <span>{cnpAmexRate}</span>
                </>
              )}
            </>
          ) : (
            <>
              {cpRate && (
                <>
                  <h3>{nonSurchargeLabel}CP Credit Card (non-AMEX)</h3>
                  <span>{cpRate}</span>
                </>
              )}
              {cpAmexRate && (
                <>
                  <h3>{nonSurchargeLabel}CP Credit Card (AMEX)</h3>
                  <span>{cpAmexRate}</span>
                </>
              )}
              <h3>{nonSurchargeLabel}Credit Card (non-AMEX)</h3>
              <span>{cnpRate}</span>
              <h3>{nonSurchargeLabel}Credit Card (AMEX)</h3>
              <span>{cnpAmexRate}</span>
            </>
          )}

          {/* debit rates */}
          {cpDebitRate && (
            <>
              <h3>CP Debit Rate</h3>
              <span>{cpDebitRate}</span>
            </>
          )}
          {cnpDebitRate && (
            <>
              <h3>CNP Debit Rate</h3>
              <span>{cnpDebitRate}</span>
            </>
          )}

          <h3>ACH</h3>
          <span>
            {!ach ? (
              'N/A'
            ) : (
              <>
                {ach} <span className="tiny">{capLabel}</span>
              </>
            )}
          </span>
        </div>
      </Content>
    </Container>
  );
};
