import { Mutator } from 'final-form';
import { Registration } from '@fattmerchantorg/types-omni';
import { pick } from '../../../../util/object.util';
import {
  isValidPhoneNumber,
  isValidEmail,
  isValidOpenDate,
  isAddressPoBox,
} from '../../../../util/validator.util';
import {
  OwnerSignerInfoFieldsValues,
  OwnerSignerInfoFieldsErrors,
} from './OwnerSignerInfoForm.types';
import { formatDate } from '../../../../util';

export const formMutators: {
  [key: string]: Mutator<OwnerSignerInfoFieldsValues>;
} = {
  setFieldValue: ([name, value], state, utils) => {
    utils.changeValue(state, name, _ => value);
  },
};

export function mapFormValuesToPayload(
  formValues: OwnerSignerInfoFieldsValues
): Partial<Registration> {
  const existingMetaInfo = formValues.meta || {};
  return {
    ...formValues,
    principal_owners_name: formValues.principal_owners_name,
    title: formValues.title,
    job_title: formValues.title,
    first_name: formValues.first_name,
    last_name: formValues.last_name,
    user_dob: formatDate(formValues.user_dob),
    user_ssn: formValues.user_ssn?.replace(/\D/g, ''),
    phone_number: formValues.phone_number?.replace(/\D/g, ''),
    email: formValues.email,
    owner_address_1: formValues.owner_address_1,
    owner_address_2: formValues.owner_address_2,
    owner_address_city: formValues.owner_address_city,
    owner_address_state: ['USA', 'CAN'].includes(
      formValues.owner_address_country
    )
      ? formValues.owner_address_state
      : '',
    owner_address_zip: formValues.owner_address_zip,
    owner_address_country: formValues.owner_address_country,
    meta: {
      ...existingMetaInfo,
      ownership_percentage: formValues.ownership_percentage
        ?.toString()
        .replace(/\D/g, ''),
    },
  };
}

export function mapRegistrationToInitialFormValues(
  registration: Registration
): OwnerSignerInfoFieldsValues {
  return {
    ...pick(
      registration,
      'principal_owners_name',
      'title',
      'first_name',
      'last_name',
      'user_dob',
      'user_ssn',
      'phone_number',
      'email',
      'owner_address_1',
      'owner_address_2',
      'owner_address_city',
      'owner_address_state',
      'owner_address_zip',
      'owner_address_country',
      'meta'
    ),
    ...pick(registration?.meta, 'ownership_percentage'),
  } as OwnerSignerInfoFieldsValues;
}

export function validateRequiredFormValues(
  values: OwnerSignerInfoFieldsValues
): OwnerSignerInfoFieldsErrors {
  const errors = {} as OwnerSignerInfoFieldsErrors;

  return errors;
}

export function validateFormValues(
  values: OwnerSignerInfoFieldsValues
): OwnerSignerInfoFieldsErrors {
  const errors = {} as OwnerSignerInfoFieldsErrors;

  if (values.phone_number && !isValidPhoneNumber(values.phone_number)) {
    errors.phone_number = 'Please enter a valid phone number';
  }

  if (values.email && !isValidEmail(values.email)) {
    errors.email = 'Please enter a valid email';
  }

  if (isNaN(+values.ownership_percentage)) {
    errors.ownership_percentage = 'Must be a number';
  } else if (+values.ownership_percentage > 100) {
    errors.ownership_percentage = 'Must be 100% or less';
  }

  const ssnDigits = (values.user_ssn || '').replace(/[\D]/g, '');
  if (ssnDigits && ssnDigits.length !== 9) {
    errors.user_ssn = 'Must be 9 characters';
  }

  if (values.user_dob && !isValidOpenDate(formatDate(values.user_dob), 200)) {
    errors.user_dob = 'Must be a valid birthdate';
  }

  if (
    ['USA', 'CAN'].includes(values.owner_address_country) &&
    (values.owner_address_state === '' || values.owner_address_state == null)
  ) {
    errors.owner_address_state = 'Please enter a valid state or province';
  }

  if (values.owner_address_1 && isAddressPoBox(values.owner_address_1)) {
    errors.owner_address_1 = 'Address cannot be a PO box';
  }

  if (values.owner_address_2 && isAddressPoBox(values.owner_address_2)) {
    errors.owner_address_2 = 'Address cannot be a PO box';
  }

  return errors;
}
