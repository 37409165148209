import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const LongLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
`;

export const LongLoadingState = () => {
  return (
    <LongLoadingContainer>
      <FontAwesomeIcon
        icon={faSpinnerThird as IconProp}
        size="6x"
        color="#75D697"
        spin
      />
      <div>
        <span>Processing...</span>
      </div>
    </LongLoadingContainer>
  );
};
