import React, { FC } from 'react';
import styled, { withTheme } from 'styled-components';
import { Tooltip } from '@fattmerchantorg/truffle-components';
import { ReactComponent as QBOIcon } from '../../../svg/qbo-source.svg';

const Icon = withTheme(
  styled.i`
    color: white;
    font-size: 24px;
    height: 18px;
    line-height: 18px;
    //float: left;
    margin: 0;
  `
);

interface SourceProps extends React.HTMLAttributes<HTMLElement> {
  source: string | null;
  name: string | null;
}

const TransactionSourceIcon: FC<SourceProps> = props => {
  const { source, name, ...iconProps } = props;
  if (source === null && name === 'Hosted Payments') {
    return (
      <Tooltip content="Website Payments">
        <Icon
          {...iconProps}
          data-testid="ui-payment-method-icon"
          className={`fas fa-globe fa-fw ${iconProps.className ?? ''}`}
        />
      </Tooltip>
    );
  }

  if (source === null) {
    return (
      <Tooltip content={'Virtual Terminal'}>
        <Icon
          {...iconProps}
          data-testid="ui-payment-method-icon"
          className={`fas fa-desktop fa-fw ${iconProps.className ?? ''}`}
        />
      </Tooltip>
    );
  }

  switch (true) {
    case source.includes('terminal'):
      return (
        <Tooltip content={'Terminal'}>
          <Icon
            {...iconProps}
            data-testid="ui-payment-method-icon"
            className={`fas fa-cash-register fa-fw ${
              iconProps.className ?? ''
            }`}
          />
        </Tooltip>
      );
    case source.includes('fpm|Android'):
    case source.includes('fpm|i'):
    case source.includes('mobile'):
    case source.includes('CPSDK'):
    case source.includes('AWC'):
      return (
        <Tooltip content={'Mobile'}>
          <Icon
            {...iconProps}
            data-testid="ui-payment-method-icon"
            className={`fas fa-mobile-alt fa-fw ${iconProps.className ?? ''}`}
          />
        </Tooltip>
      );
    case source.includes('intuit'):
      return (
        <Tooltip content={'Intuit'}>
          <QBOIcon style={{ height: '22px', width: '22px' }} />
        </Tooltip>
      );

    case (source.length === 0 ||
      (typeof source === 'string' &&
        ['apple_pay', 'google_pay'].includes('' + source))) &&
      name === 'Hosted Payments':
      return (
        <Tooltip content="Website Payments">
          <Icon
            {...iconProps}
            data-testid="ui-payment-method-icon"
            className={`fas fa-globe fa-fw ${iconProps.className ?? ''}`}
          />
        </Tooltip>
      );

    default:
      return (
        <Tooltip content={'Virtual Terminal'}>
          <Icon
            {...iconProps}
            data-testid="ui-payment-method-icon"
            className={`fas fa-desktop fa-fw ${iconProps.className ?? ''}`}
          />
        </Tooltip>
      );
  }
};

export default TransactionSourceIcon;
