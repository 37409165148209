import React, { FunctionComponent, ReactNode } from 'react';
import styled, { withTheme } from 'styled-components';
import { Widget } from '../../shared/Widget';
import { Tooltip } from '@fattmerchantorg/truffle-components';

const Container = styled(Widget)`
  background: white;
`;

const Content = withTheme(
  styled.div`
    color: #565656;
    display: flex;
    flex-direction: column;
    margin: 1rem;

    h2,
    h3 {
      margin: 0px;
      color: #000000;
    }

    div {
      font-weight: 700;
      font-size: 12px;
      color: #32325d;
    }

    > div {
      flex: 50%;
      display: flex;
      flex-direction: column;

      &:first-child {
        margin-top: 0.5rem;
      }
    }
  `
);

const Heading = styled.h3`
  color: ${({ theme }) => theme.colors.core.gray[800].hex};
`;

const Value = styled.span`
  padding: 2px;
  font-size: 30px;
  color: ${({ theme }) => theme.colors.core.green[500].hex};
  white-space: nowrap;
`;

const Subtitle = styled.h4`
  color: ${({ theme }) => theme.colors.core.gray[400].hex};
`;

type DataWidgetProps = React.HTMLAttributes<HTMLDivElement> & {
  title?: string;
  subtitle?: string;
  titleIcon?: string;
  data?:
    | { title?: ReactNode; tooltip?: ReactNode; value: any }
    | { title?: ReactNode; tooltip?: ReactNode; value: any }[];
};

export const DataWidget: FunctionComponent<DataWidgetProps> = props => {
  const { title = '', subtitle = '', titleIcon, data: d, ...divProps } = props;

  if (!d) return null;

  const data = Array.isArray(d) ? d : [d];

  return (
    <Container
      data-testid={`data_widget_${title.replace(/\s/g, '_')}`}
      {...divProps}
    >
      <Content>
        {titleIcon || title ? (
          <h2>
            {titleIcon ? (
              <i className={titleIcon} aria-hidden="true"></i>
            ) : null}{' '}
            {title || ''}
          </h2>
        ) : null}

        {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}

        {data.map((datum, i) => (
          <div key={i}>
            {datum.title ? (
              typeof datum.title === 'string' ? (
                <Heading>
                  {datum.title}{' '}
                  {datum.tooltip ? (
                    <Tooltip content={datum.tooltip}>
                      <i className="fas fa-info-circle" />
                    </Tooltip>
                  ) : null}
                </Heading>
              ) : (
                datum.title
              )
            ) : null}
            <Value>{datum.value}</Value>
          </div>
        ))}

        {divProps.children}
      </Content>
    </Container>
  );
};
