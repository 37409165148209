import {
  Icon,
  SecondaryButton,
  SmallPrimaryButton,
  StatusPill,
} from '@fattmerchantorg/truffle-components';
import { User } from '@fattmerchantorg/types-omni';
import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { coreapi } from '../../../api';
import { AuthStore, PermissionsStore } from '../../../context';
import {
  useAsyncEffect,
  useAuthToken,
  usePermissions,
  useToaster,
} from '../../../hooks';
import { ButtonSpinner } from '../../shared';
import {
  BottomBar,
  BrandChip,
  DividingLine,
  DrawerCss,
  ContentSeparator,
  GreenIcon,
  RedIcon,
  StyledBrandSection,
  StyledDangerPrimaryButton,
  StyledDrawer,
  StyledEmail,
  StyledEmailContainer,
  StyledHeaderContainer,
  StyledInfoSection,
  StyledSecurityInfo,
  StyledStatus,
  StyledText,
} from '../SettingsPagesStylings';
import { TeamMemberModal } from './TeamMemberModal';
import { calculateStatus, mapStatusToIcon } from './TeamSettingsPage';

interface Props {
  isOpen: boolean;
  setUserId: (value: string) => void;
  userId: string;
}

const TextInfo = styled.div`
  margin-bottom: 8px;
`;

const BrandContainer = styled.div`
  margin-bottom: 4px;
`;

export const ViewMemberDrawer: FC<Props> = ({ isOpen, setUserId, userId }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [user, setUser] = useState<User>(null);
  const [userStatus, setUserStatus] = useState<string>();

  const { toast, toaster } = useToaster();
  const authToken = useAuthToken();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { permit } = usePermissions();

  const {
    state: { auth },
  } = useContext(AuthStore);

  const userBrand = auth.data[0]?.user.brand;

  const isInternalUser =
    userBrand.startsWith('stax') || userBrand.startsWith('fattmerchant');

  const canWrite = permit('godview', 'teamSettings', 'write');

  const {
    state: { brands },
  } = useContext(PermissionsStore);

  const getUser = useCallback(async () => {
    if (userId) {
      try {
        const user: User = isInternalUser
          ? await coreapi.get(authToken, `/user/${userId}`)
          : await coreapi.get(
              authToken,
              `/user/${userId}?exclude_internal_users=1`
            );

        setUserStatus(calculateStatus(user));

        setUser(user);
      } catch (error) {
        toaster(toast.error(error, `'There was a problem retreiving the user`));
      }
    }
  }, [authToken, userId, isInternalUser, toast, toaster]);

  useAsyncEffect(() => getUser(), [getUser]);

  const deleteUser = async userId => {
    setIsDeleting(true);
    try {
      await coreapi.delete(authToken, `/user/${userId}`);
      setIsDeleting(false);
      setUserId(null);
    } catch (error) {
      toaster(toast.error(error, `There was a problem deleting the user.`));
      setIsDeleting(false);
    }
  };

  const restoreUser = async userId => {
    setIsDeleting(true);
    try {
      await coreapi.put(authToken, `/user/${userId}/restore`);
      setIsDeleting(false);
      setUserId(null);
    } catch (error) {
      toaster(toast.error(error, `There was a problem restoring the user.`));
      setIsDeleting(false);
    }
  };

  const brandsMap = useMemo(() => {
    const map = new Map();
    brands.forEach(br => {
      map.set(br.name, br.displayName);
    });
    return map;
  }, [brands]);

  return (
    <>
      <TeamMemberModal
        isEditMode
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        initialUser={user}
        getUser={() => getUser()}
      />
      {user ? (
        <StyledDrawer
          title={'Team Member Details'}
          open={isOpen}
          onClose={() => setUserId(null)}
          anchor="right"
          customStyles={DrawerCss}
        >
          <div>
            <StyledHeaderContainer>
              <StyledInfoSection>
                <ContentSeparator>
                  <StyledText fontSize={'28px'} fontHeight={'42px'}>
                    {user?.name}
                  </StyledText>
                  {!!userStatus && (
                    <StyledStatus>
                      <StatusPill {...mapStatusToIcon(userStatus)} />
                    </StyledStatus>
                  )}
                </ContentSeparator>
                <StyledText fontSize={'14px'} fontHeight={'20px'}>
                  {user?.role_name}
                </StyledText>
                <StyledEmailContainer>
                  <Icon icon={['fas', 'envelope']} />
                  <StyledEmail> {user?.email}</StyledEmail>
                </StyledEmailContainer>
              </StyledInfoSection>
            </StyledHeaderContainer>
            <DividingLine />
            <StyledBrandSection>
              <StyledText
                fontSize={'16px'}
                fontHeight={'24px'}
                fontWeight={'700'}
                marginBottom={'5px'}
              >
                Brands
              </StyledText>
              <TextInfo>Primary</TextInfo>
              <BrandContainer>
                <BrandChip label={brandsMap.get(user?.brand) || user?.brand} />
              </BrandContainer>
              <TextInfo>Additional</TextInfo>
              {user?.brands?.slice(1)?.map((br, index) => {
                return (
                  <BrandChip key={index} label={brandsMap.get(br) || br} />
                );
              })}
            </StyledBrandSection>
            <DividingLine />
            <StyledSecurityInfo>
              {user?.mfa_enabled ? (
                <GreenIcon icon={['fas', 'check']} />
              ) : (
                <RedIcon icon={['fas', 'times']} />
              )}
              <StyledText fontSize={'14px'} fontHeight={'20px'}>
                Multi-Factor Authentication
              </StyledText>
            </StyledSecurityInfo>
          </div>
          {canWrite && (
            <BottomBar>
              {user?.deleted_at ? (
                <SmallPrimaryButton onClick={() => restoreUser(user?.id)}>
                  {isDeleting ? <ButtonSpinner /> : 'Reactivate'}
                </SmallPrimaryButton>
              ) : (
                <StyledDangerPrimaryButton onClick={() => deleteUser(user?.id)}>
                  {isDeleting ? <ButtonSpinner /> : 'Deactivate'}
                </StyledDangerPrimaryButton>
              )}
              <SecondaryButton onClick={() => setIsModalOpen(prev => !prev)}>
                Edit
              </SecondaryButton>
            </BottomBar>
          )}
        </StyledDrawer>
      ) : null}
    </>
  );
};
