// compose higher order functions from left to right
export const pipe = <T>(...fns: ((arg: T) => any)[]) => (x: T) =>
  fns.reduce((v, f) => f(v), x);
// compose higher order functions from right to left. i.e. enhancing dumb components
export const compose = <T>(...fns: ((arg: T) => any)[]) => (x: T) =>
  fns.reduceRight((v, f) => f(v), x);

/**
 * memoizes a function -- won't invoke function unless argument has changed. useful for onChange validators
 * @param func: function to memoize
 */
export const memoize = <A, R>(
  func: (...args: A[]) => R
): ((...args: A[]) => R) => {
  let prevArgs: A[] = [];
  let prevResult: R;
  return (...args: A[]) => {
    const argsDidChange =
      prevArgs.length !== args.length ||
      prevArgs.some((arg, index) => arg !== args[index]);

    if (argsDidChange) {
      prevArgs = args;
      prevResult = func(...args);
    }

    return prevResult;
  };
};

export const sleep = amount =>
  new Promise(resolve => setTimeout(resolve, amount));

export function times(num: number): number[] {
  let indexes = [];

  for (let i = 0; i < num; i++) {
    indexes.push(i);
  }

  return indexes;
}

/**
 * debounces a function -- won't invoke the function until `ms` milliseconds have passed since the debounced function was last called
 * @param promiseMaker: promise maker to debounce
 */
export const debounce = <A>(fn: (...args: A[]) => any, ms: number = 500) => {
  let timeout: number;

  return (...args: A[]) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => fn(...args), ms);
  };
};

/**
 * debounces a function that returns a promise -- won't invoke the promise until `ms` milliseconds have passed since the debounced function was last called. useful for onChange async validators
 * @param promiseMaker: promise maker to debounce
 */
export const debounceAsync = <A, R>(
  promiseMaker: (...args: A[]) => Promise<R>,
  ms: number = 500
): ((...args: A[]) => Promise<R>) => {
  let timeout: number;

  return (...args: A[]) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    return new Promise(resolve => {
      timeout = setTimeout(() => resolve(promiseMaker(...args)), ms);
    });
  };
};
