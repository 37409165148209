import styled from 'styled-components';

export const TableSection = styled.div`
  h5 {
    margin-bottom: 8px;
  }

  table {
    width: 100%;
    table-layout: fixed;
    color: ${props => props.theme.colors.core.white[0].hex};
    tfoot,
    thead {
      display: table-row-group;
    }

    tbody {
      padding: 2px 0;
      border-top: 2px solid ${props => props.theme.colors.core.gray[600].hex};
      border-bottom: 2px solid ${props => props.theme.colors.core.gray[600].hex};
    }

    tfoot > tr > td {
      font-weight: 700;
    }

    tr {
      td,
      th {
        font-size: 10px;
        padding: 6px 0;
        font-weight: 400;
        width: auto;
        &:not(:first-child) {
          text-align: right;
        }
      }

      &:first-child > td {
        padding-top: 12px;
      }

      &:last-child > td {
        padding-bottom: 12px;
      }
    }
  }
`;
