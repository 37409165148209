import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { coreapi } from '../../api';
import { usePermissions, useSearchState } from '../../hooks';
import { Merchant } from '@fattmerchantorg/types-omni';
import { isEmpty } from '../../util/object.util';
import { history } from '../../history';
import { SearchCollection, PageHeader } from '../shared';
import {
  SelectedMerchantStore,
  updateSelectedMerchant,
  addSelectedMerchant,
  AuthStore,
  deriveAuthMode,
} from '../../context';
import { AddMerchantModal } from './AddMerchantModal';
import { StaxButton } from '../shared/Stax/Button';
import { MerchantsFilterAccordion } from './filteraccordion/MerchantsFilterAccordion';
import { useMerchantsTableColumns } from './hooks';

const MERCHANTS_SEARCH_STORAGE_KEY = 'omniconnect.merchants.search';

export const Merchants = () => {
  const {
    state: { effect },
    dispatch,
  } = useContext(SelectedMerchantStore);
  const { state: authState } = useContext(AuthStore);
  const authToken = authState.authToken;
  const { permit, associatedBrands } = usePermissions();
  const columns = useMerchantsTableColumns();
  const location = useLocation();
  const mode = deriveAuthMode(authState);
  const canAddMerchants = permit('godview', 'canAddMerchants', 'write');
  const canSeeAllBrands = permit('godview', 'canSeeAllBrands', 'read');
  const isSingleBrandUser = associatedBrands.length === 1;

  // Get "selected brand" in context.
  const {
    state: { selectedBrandSwitcherOption },
  } = useContext(SelectedMerchantStore);
  const isAllBrandsSelected = selectedBrandSwitcherOption?.includes(',');

  const [searchState, setSearchState] = useSearchState();
  const [isCollectionReady, setIsCollectionReady] = useState(false);

  useEffect(() => {
    /**
     * CACHED SEARCH VS URL PARAM SEARCH:
     * When user firsts lands on the page, we check if there are URL params
     * and use those as our search. This is because the user came from a link
     * in the app that was intended to filter a specific result. If there are no URL
     * params, we will go ahead and use the cached search state (from local storage).
     * We then tag the collection as ready and continue to track the searches so
     * users can resume searching when landing back on the page.
     * If the collection is already ready, we always want to continue to track and
     * cache the search state in local storage so when the user lands back on this page,
     * they can resume their search.
     */
    if (!isCollectionReady) {
      // If there are URL params, ignore the search cache (PHO-1296)
      if (isEmpty(searchState)) {
        try {
          const value = localStorage.getItem(MERCHANTS_SEARCH_STORAGE_KEY);
          const cachedSearchState = JSON.parse(value);
          if (cachedSearchState && !isEmpty(cachedSearchState))
            setSearchState(cachedSearchState);
        } catch (error) {}
      }
      setIsCollectionReady(true);
    } else {
      if (!isEmpty(searchState)) {
        const cachedSearchState = JSON.stringify(searchState);
        localStorage.setItem(MERCHANTS_SEARCH_STORAGE_KEY, cachedSearchState);
      } else {
        localStorage.removeItem(MERCHANTS_SEARCH_STORAGE_KEY);
      }
    }
  }, [searchState, setSearchState, isCollectionReady]);

  const getData = useCallback(
    query => {
      // Adjust Core API request by adding `Brand Switcher` selection as `brand` or `brands`.
      if (selectedBrandSwitcherOption.includes(',')) {
        delete query.brand;
        query.brands = selectedBrandSwitcherOption.split(',');
      } else {
        delete query.brands;
        query.brand = selectedBrandSwitcherOption;
      }

      // List all merchants scenario - the user must have `canSeeAllBrands` godview permission and is either
      // 1. Assigned a single brand
      // 2. Assigned multiple brands but has selected All Merchants from the brand switcher
      // core api logic determines what a user should see when passing in no brand parameters
      if (canSeeAllBrands && (isSingleBrandUser || isAllBrandsSelected)) {
        delete query.brand;
        delete query.brands;
        // if not querying by brand, make sure to only return live merchants for live mode and sandbox merchants for sandbox mode
        query.is_sandbox = mode === 'TEST_MODE' ? 1 : 0;
      }

      if ('perPage' in query) {
        query.per_page = query.perPage;
        delete query.perPage;
      }
      return coreapi.get(authToken, '/merchant', query);
    },
    [
      authToken,
      selectedBrandSwitcherOption,
      canSeeAllBrands,
      isSingleBrandUser,
      isAllBrandsSelected,
      mode,
    ]
  );

  /** Click handler for individual rows of merchant collection */
  const onRowClick = useCallback(
    (merchant: Merchant) => {
      // When user selects a new merchant, simply dispatch an update to the context.
      // (page navigation should instead occur as a side-effect)
      dispatch(updateSelectedMerchant(merchant));
    },
    [dispatch]
  );

  // Side-effects that are triggered by changes to context
  useEffect(() => {
    switch (effect?.type) {
      case 'UPDATE_SELECTED_MERCHANT': {
        // Once context has updated after user selects a merchant,
        // now we may navigate to the selected merchant page

        if (!effect?.payload?.id) {
          // highly unusual, but technically possible for effect.payload.id to be undefined/null
          console.error(
            'Unable to navigate to merchant business info page without ID of selected merchant.'
          );
          return;
        }
        const merchantId = effect.payload.id;

        history.push(`/merchant/${merchantId}/businessinfo`);
        break;
      }
      case 'ADD_SELECTED_MERCHANT': {
        // Once context has updated after user adds a new merchant,
        // now we may navigate to the enrollment page

        if (!effect?.payload?.id) {
          // highly unusual, but technically possible for effect.payload.id to be undefined/null
          console.error(
            'Unable to navigate to merchant enrollment page without ID of selected merchant.'
          );
          return;
        }
        const merchantId = effect.payload.id;

        history.push(`/merchant/${merchantId}/enrollment`);
        break;
      }

      case 'REHYDRATE_SELECTED_MERCHANT': {
        // Intentionally do not perform any side effects in this case.
        // The selected merchant has "changed" simply due to page reload,
        // not because the user has selected a different merchant from the list, or added a new merchant via the form.
        break;
      }
    }
  }, [effect]);

  return (
    <>
      <PageHeader title="Merchants">
        {canAddMerchants && (
          <StaxButton
            type="submit"
            icon="fas fa-plus"
            id="create-new-merchant-button"
            data-testid="create-new-merchant-button"
            onClick={() => history.push('/merchants/addnew')}
          >
            Add New
          </StaxButton>
        )}
      </PageHeader>
      {!canAddMerchants ? (
        // Redirect users without write-access.
        <Redirect to="/merchants" />
      ) : (
        <AddMerchantModal
          isOpen={location.pathname.includes('addnew')}
          onClose={() => history.push('/merchants')}
          onAdd={merchant => {
            history.push('/merchants');
            dispatch(addSelectedMerchant(merchant));
          }}
        />
      )}
      <SearchCollection<Merchant>
        hideClearButton={true}
        filterAccordion={<MerchantsFilterAccordion />}
        data-testid="merchant-search-collection"
        getData={
          isCollectionReady && selectedBrandSwitcherOption ? getData : null
        }
        onRowClick={onRowClick}
        columns={columns}
      />
    </>
  );
};
