import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 50rem;
  padding: 0 5%;

  @media (max-width: 1000px) {
    padding: 0;
  }
`;
