import React, { FunctionComponent, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';

import {
  useAsyncEffect,
  useAuthToken,
  useRouteMatchImportId,
  useRouteMatchMerchantId,
  useToaster
} from '../../../../hooks';
import {
  autoSpecifyUnspecifiedMappingColumns,
  ImportEffectType,
  ImportStore,
  updateImport
} from '../../../../context';
import { ImporterMatchCard, ImporterMatchColumn } from './ImporterMatchCard';
import { ImporterButtonBar } from './ImporterButtonBar';
import { useHistory } from 'react-router-dom';
import { importerapi } from '../../../../api';
import { Button, Group, ResponsivePadding, Modal } from '../../../shared';
import { ImporterCard } from './ImporterCard';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type ImportMappingError = {
  error: string;
  statusCode: number;
  detail: {
    badKeyColumns: string[];
    badTargetColumns: string[];
    misformedMappings: string[];
  };
};

const isMappingError = (error: any) => {
  return typeof error === 'object' && error.error && error.detail;
};

const LongLoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const LongLoaderCard = styled(ImporterCard)`
  display: flex;
  flex-direction: row;
  width: auto;
  padding: 3rem;

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 3rem;

    > span {
      color: #73767c;
    }
  }
`;

export const ImporterMatch: FunctionComponent = () => {
  const {
    dispatch,
    state: { mapping, hasMappingChanged, unspecifiedColumns, effect }
  } = useContext(ImportStore);

  const history = useHistory();
  const authToken = useAuthToken();
  const { toast, toaster } = useToaster();
  const importId = useRouteMatchImportId();
  const merchantId = useRouteMatchMerchantId();
  const [state, setState] = useState<'idle' | 'loading' | 'loading-long'>(
    'idle'
  );
  const [isUnspecifiedModalOpen, setIsUnspecifiedModalOpen] = useState(false);

  useAsyncEffect(() => {
    if (
      effect.type === ImportEffectType.AUTO_SPECIFY_UNSPECIFIED_MAPPING_COLUMNS
    ) {
      handleSaveMapping();
    }
  }, [effect.type]);

  const columns: ImporterMatchColumn[] = useMemo(() => {
    const columns = mapping?.columns || {};
    return Object.keys(columns).reduce((sum, name) => {
      sum.push({ name, ...columns[name] });
      return sum;
    }, []);
  }, [mapping]);

  const handleSaveMapping = async () => {
    if (state !== 'idle') return;

    if (!hasMappingChanged) {
      // if mapping hasn't changed, don't bother saving the record
      // just push to the next page
      return history.push(
        `/merchant/${merchantId}/imports/${importId}/summary`
      );
    }

    setState('loading');

    // if, after 700 milliseconds, the state is still 'loading',
    // then set the state to 'loading-long'
    // so we can show the long-running loader
    setTimeout(() => {
      setState(s => (s === 'loading' ? 'loading-long' : s));
    }, 700);

    try {
      const record = await importerapi.put(
        authToken,
        `/import/${importId}/mapping`,
        mapping
      );

      dispatch(updateImport(record));

      history.push(`/merchant/${merchantId}/imports/${importId}/summary`);
    } catch (error) {
      setState('idle');

      if (isMappingError(error)) {
        const mappingError = error as ImportMappingError;
        let formattedErrors = [];

        if (mappingError?.detail?.badKeyColumns?.length) {
          formattedErrors.push(
            <div>
              <strong>Bad Key Columns: </strong>
              {mappingError.detail.badKeyColumns.join(', ')}
            </div>
          );
        }

        if (mappingError?.detail?.badTargetColumns?.length) {
          formattedErrors.push(
            <div>
              <strong>Bad Target Columns: </strong>
              {mappingError.detail.badTargetColumns.join(', ')}
            </div>
          );
        }

        if (mappingError?.detail?.misformedMappings?.length) {
          formattedErrors.push(
            <div>
              <strong>Misformed Mappings: </strong>
              {mappingError.detail.misformedMappings.join(', ')}
            </div>
          );
        }

        toaster(
          toast.error(
            <div>
              {formattedErrors.map((formattedError, i) => (
                <div key={i}>{formattedError}</div>
              ))}
            </div>,
            'There was a problem saving your column mappings.'
          )
        );
      } else {
        toaster(
          toast.error(error, 'There was a problem saving your column mappings.')
        );
      }
    }
  };

  return (
    <>
      <Modal
        title="Column Matching(s) Unspecified"
        isOpen={isUnspecifiedModalOpen}
        onClose={() => setIsUnspecifiedModalOpen(false)}
      >
        <ResponsivePadding desktop="1rem" mobile="1rem">
          <p>
            There are <strong>{unspecifiedColumns.length}</strong> column(s)
            that have not been <strong>confirmed</strong> or{' '}
            <strong>ignored</strong>.
          </p>
          <p>Continue with recommended mapping on unspecified columns?</p>
          <Group justify="space-between">
            <Button
              variant="outline"
              onClick={() => setIsUnspecifiedModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={() => {
                setIsUnspecifiedModalOpen(false);
                dispatch(autoSpecifyUnspecifiedMappingColumns());
              }}
            >
              Continue
            </Button>
          </Group>
        </ResponsivePadding>
      </Modal>

      {state === 'loading-long' ? (
        <LongLoaderContainer>
          <LongLoaderCard>
            <FontAwesomeIcon
              icon={faSpinnerThird as IconProp}
              size="6x"
              color="#009bf2"
              spin
            />
            <div>
              <span>Processing Records...</span>
              <span>Applying Column Mapping Preferences...</span>
              <span>Checking for errors...</span>
            </div>
          </LongLoaderCard>
        </LongLoaderContainer>
      ) : (
        <Group direction="column" style={{ paddingBottom: '128px' }}>
          {columns.map((column, i) => (
            <ImporterMatchCard
              key={i}
              index={i}
              disabled={state !== 'idle'}
              column={column}
            />
          ))}
        </Group>
      )}
      <ImporterButtonBar
        onBack={() =>
          history.push(`/merchant/${merchantId}/imports/${importId}/upload`)
        }
        onContinue={() => {
          if (unspecifiedColumns.length > 0) {
            setIsUnspecifiedModalOpen(true);
          } else {
            handleSaveMapping();
          }
        }}
        continueButtonProps={{
          disabled: state !== 'idle',
          loading: state !== 'idle',
          children: (
            <span>{hasMappingChanged ? 'Save & Continue' : 'Continue'}</span>
          )
        }}
      />
    </>
  );
};
