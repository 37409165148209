import React, { useMemo } from 'react';
import { Column, useTable, useSortBy } from 'react-table';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableWrapper,
} from '@fattmerchantorg/truffle-components';
import { TableHeaderCell } from '@fattmerchantorg/truffle-components/dist-esm';
import { LoadingSpan } from '../../shared';
import { ClickedArea, HeaderRow, StyledTable } from '../SettingsPagesStylings';
import { BoldText } from '../../truffle-dashboard/components/shared/BoldText';
interface DataTableProps {
  data: {
    columns: Column[];
    rows: Record<string, unknown>[];
  };
  isLoading: boolean;
  displayName?: string;
  onItemClick?: () => void;
}

export const PricingPlansTable: React.FC<DataTableProps> = props => {
  const { rows: rowsData, columns: columnsData } = props.data;
  const { isLoading, displayName, onItemClick } = props;

  // Row and column data for table
  const rowData = useMemo(() => rowsData, [rowsData]);
  const columns = useMemo(() => columnsData, [columnsData]);

  const { getTableProps, getTableBodyProps, prepareRow, headerGroups, rows } =
    useTable(
      {
        columns,
        data: rowData,
      },
      useSortBy
    );

  return (
    <ClickedArea onClick={onItemClick}>
      <HeaderRow>
        {isLoading ? (
          <LoadingSpan width={'300px'} height={'25px'} />
        ) : (
          <>
            <BoldText fontSize={18} lineHeight={27} marginBottom={0}>
              {displayName}
            </BoldText>
          </>
        )}
      </HeaderRow>

      <TableWrapper>
        <StyledTable {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, index) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column: any, index) => (
                  <TableHeaderCell
                    {...column.getHeaderProps()}
                    style={column.style}
                    key={index}
                  >
                    {column.render('Header')}
                  </TableHeaderCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, index) => (
              <React.Fragment key={index}>
                {isLoading
                  ? (prepareRow(row),
                    (
                      <TableRow {...row.getRowProps()} key={index}>
                        {row.cells.map((cell: any, index) => {
                          return (
                            <TableCell
                              {...cell.getCellProps()}
                              key={index}
                              style={cell.column.style}
                            >
                              <LoadingSpan
                                width={
                                  cell.column.style.width
                                    ? cell.column.style.width
                                    : cell.column.width
                                }
                              />
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))
                  : (prepareRow(row),
                    (
                      <TableRow {...row.getRowProps()} key={index}>
                        {row.cells.map((cell: any, index) => {
                          return (
                            <TableCell
                              {...cell.getCellProps()}
                              key={index}
                              style={cell.column.style}
                            >
                              {cell.render('Cell')}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
              </React.Fragment>
            ))}
          </TableBody>
        </StyledTable>
      </TableWrapper>
    </ClickedArea>
  );
};
