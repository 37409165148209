import { useLocation } from 'react-router-dom';
/**
 * Q: Why not use `useRouteMatch`, like the name implies?
 * A: Because of the :merchantId replace logic within the child routes for /merchants,
 *    (added after this function was made) the merchant id is not available in the params.
 *    So we have to use the location pathname instead.
 */
export function useRouteMatchMerchantId() {
  const { pathname } = useLocation();
  // match any string after /merchant/ that does not contain a /
  // e.g. /merchant/MERCHANT_ID_1234/businessinfo will match MERCHANT_ID_1234
  const regex = /merchant\/([^\/]+)/;
  const merchantId = pathname.match(regex)?.[1];
  return merchantId;
}
