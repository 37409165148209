import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import styled, { withTheme } from 'styled-components';
import { Tooltip } from '@fattmerchantorg/truffle-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DepositDetailModalProps } from './DepositDetailModal.types';
import { Modal, Collection, CollectionQuery } from '../../shared';
import { useAuthToken, useAsyncEffect, useFeatureFlag } from '../../../hooks';
import { SelectedMerchantStore } from '../../../context';
import { queryapi, coreapi } from '../../../api';
import {
  DepositDetail,
  CollectionResponse,
  FundingAccount,
} from '@fattmerchantorg/types-omni';
import { formatCurrency, formatReadableDate } from '../../../util/format.util';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { getColumns } from './DepositDetailModal.util';
import { getFeeTypeNiceName } from '../../feestatements/util/fee-statement-util';
import { dayStart, dayEnd } from '../../../../src/util/date.util';
import { DepositDetailsDrawer } from './DepositDetailDrawer';
import FeatureFlag from '../../shared/FeatureFlag/FeatureFlag';

const Container = styled.div``;

const InfoIcon = withTheme(
  styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.core.gray[200].hex};
    margin-left: 8px;
  `
);

const StyledFees = withTheme(
  styled.div`
    background: ${({ theme }) => theme.colors.core.gray[800].hex};
    padding: 16px;
    font-size: 14px;
    font-weight: bold;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  `
);

const TooltipTable = styled.table`
  text-align: left;
  > tbody > tr > td {
    &:last-child {
      text-align: right;
    }
    &:not(:last-child) {
      padding-right: 8px;
    }
  }
  > thead > th:last-child {
    text-align: right;
  }
`;

type ExtendedDepositDetail = DepositDetail & { settlement_id?: string | null };

export interface TransactionDetails {
  id: string;
  transaction_id: string;
  invoice_id: string | null;
  engine_settlement_id: string | null;
  engine_transaction_id: string | null;
  account_id: string | null;
  amount: number | null;
  memo: string | null;
  meta: string | null;
  created_at: string;
  updated_at: string | null;
  deleted_at: string | null;
}

export const DepositDetailModal: FunctionComponent<
  DepositDetailModalProps
> = props => {
  const { deposit, ...modalProps } = props;
  const batchId = deposit?.batch_id;
  const batchedAt = deposit?.batched_at?.split(' ')?.[0];

  const authToken = useAuthToken();
  const {
    state: { merchantId, merchant },
  } = useContext(SelectedMerchantStore);

  const [depositOtherFees, setDepositOtherFees] = useState<any>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [selectedDeposit, setSelectedDeposit] = useState<
    ExtendedDepositDetail | null | any
  >(null);
  const [transactionDetails, setTransactionDetails] = useState<
    TransactionDetails[]
  >([]);
  const { available: hasSplitFunding } = useFeatureFlag(
    'Funding',
    'SplitFunding'
  );
  const [fundingAccounts, setFundingAccounts] = useState<FundingAccount[]>([]);
  const drawerRef = useRef<HTMLDivElement>(null);

  const numberOfSales: number =
    +deposit?.count || Collection.defaultProps.numberOfLoadingItems;

  const shouldShowFees =
    deposit?.fees !== null &&
    !merchant?.is_surcharge_enabled &&
    !merchant?.is_trust;

  const query = useMemo((): CollectionQuery => {
    const query = {} as CollectionQuery;

    if (batchId) {
      query.batchId = batchId;
    }

    if (batchedAt && typeof batchedAt === 'string') {
      // TODO: use date functions once we fix the javascript date timezone issue
      // OR just test batched_at with regex and see if it looks like a date string
      query.startDate = `${batchedAt} 00:00:00`;
      query.endDate = `${batchedAt} 23:59:59`;
    }

    if (Object.keys(query).length > 0) {
      // only fetch deposit details if we have at least batchId or batchedAt
      query.merchantId = merchantId;
      return query;
    } else {
      // return `null` to prevent Collection from requesting new data
      return null;
    }
  }, [batchId, batchedAt, merchantId]);

  const getData = useCallback(
    async (query: CollectionQuery) => {
      const depositDetails = await queryapi.get<
        CollectionResponse<DepositDetail>
      >(authToken, `/depositDetail`, query);

      return depositDetails;
    },
    [authToken]
  );

  useEffect(() => {
    if (batchId) {
      const getDepositOtherFees = async () => {
        const startDate = dayStart(new Date(batchedAt as string));
        const endDate = dayEnd(new Date(batchedAt as string));
        const query = { batchId, startDate, endDate, orphaned: 1 };

        try {
          const fees = await queryapi.get(authToken, 'statement/fees', query);
          setDepositOtherFees(fees);
        } catch (error) {
          console.log(error);
        }
      };

      getDepositOtherFees();
    }
  }, [authToken, query, batchId, batchedAt, getData]);

  useAsyncEffect(async () => {
    if (selectedDeposit && hasSplitFunding) {
      let transactionDetails = await coreapi.get<TransactionDetails[]>(
        authToken,
        `transaction/${selectedDeposit?.transaction_id}/funding`,
        {}
      );
      setTransactionDetails(transactionDetails);
    }
  }, [authToken, selectedDeposit, hasSplitFunding]);

  useEffect(() => {
    if (authToken && hasSplitFunding) {
      const getBankAccounts = async () => {
        try {
          const response = await coreapi.get<
            CollectionResponse<FundingAccount>
          >(authToken, 'team/funding-account', {});
          setFundingAccounts(response?.data);
        } catch (error) {
          console.log(error);
        }
      };

      getBankAccounts();
    }
  }, [authToken, hasSplitFunding]);

  const onOutsideClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const shouldClose =
        isDrawerOpen &&
        drawerRef.current &&
        !drawerRef.current.contains(e.target as Node);

      if (shouldClose) setIsDrawerOpen(false);
    },
    [drawerRef, isDrawerOpen, setIsDrawerOpen]
  );

  return (
    <div onClick={onOutsideClick} id="DepositsContainer">
      <Modal
        width="80%"
        data-testid="deposit-detail-modal"
        title={
          <strong>
            Deposit {batchId}{' '}
            {typeof batchedAt === 'string' ? formatReadableDate(batchedAt) : ''}
          </strong>
        }
        {...modalProps}
      >
        <Container>
          <Collection<DepositDetail>
            query={query}
            getData={getData}
            numberOfLoadingItems={numberOfSales}
            columns={getColumns(merchant, deposit?.fees !== null)}
            paginated={false}
            onRowClick={(record: ExtendedDepositDetail | any) => {
              setIsDrawerOpen(false);
              if (selectedDeposit !== record) {
                setTransactionDetails([]);
              }
              setSelectedDeposit(record);
              setIsDrawerOpen(true);
            }}
          />

          {shouldShowFees && (
            <>
              <StyledFees data-testid="deposit-detail-modal-fees">
                <div>
                  Processing Fees:{' '}
                  {formatCurrency(
                    Math.abs(deposit?.transaction_fees ?? deposit?.fees)
                  )}
                  <Tooltip content="Fees such as those associated with a disputed payment may be included in this total.">
                    <InfoIcon icon={faInfoCircle} />
                  </Tooltip>
                </div>

                {depositOtherFees?.data?.length ? (
                  <div>
                    Other Fees:{' '}
                    {formatCurrency(Math.abs(depositOtherFees.summary.sum))}
                    <Tooltip
                      content={
                        <TooltipTable>
                          <thead>
                            <th>Fee Name</th>
                            <th>Total</th>
                          </thead>
                          <tbody>
                            {depositOtherFees?.data?.map((depositFee, i) => (
                              <tr key={i}>
                                <td>
                                  {depositFee.name ??
                                    getFeeTypeNiceName(depositFee.type)}
                                </td>
                                <td>{formatCurrency(depositFee.sum)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </TooltipTable>
                      }
                    >
                      <InfoIcon icon={faInfoCircle} />
                    </Tooltip>
                  </div>
                ) : null}

                <div>Total Fees: {formatCurrency(deposit?.fees)}</div>
              </StyledFees>
            </>
          )}
        </Container>
      </Modal>

      <FeatureFlag category="Funding" feature="SplitFunding">
        <DepositDetailsDrawer
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          selectedDeposit={selectedDeposit}
          transactionDetails={transactionDetails}
          fundingAccounts={fundingAccounts}
          drawerRef={drawerRef}
        />
      </FeatureFlag>
    </div>
  );
};
