import styled, { withTheme } from 'styled-components';

export const InputContainer = withTheme(
  styled.div`
    position: relative;
    transition: all 0.2s ease-in-out;
    flex-grow: 1;
    height: ${props => (props.label ? '48px' : '40px')};

    > input,
    > select,
    > textarea {
      line-height: 1.2;
      box-sizing: border-box;
      border: solid 1px ${({ theme }) => theme.colors.core.gray[600].hex};
      color: ${({ theme }) => theme.colors.core.gray[200].hex};
      height: 100%;
      width: 100%;
      font-size: 15px;
      outline: 0;

      @media (min-width: 1000px) {
        border-radius: 2px;
      }

      :disabled {
        background: ${({ theme }) => theme.colors.core.gray[500].hex};
        cursor: not-allowed;
      }

      :not(:disabled) {
        background: ${({ theme }) => theme.colors.core.gray[800].hex};

        &:focus,
        &:hover {
          border-color: ${props => props.theme.colors.primaryAccent};

          @media (min-width: 1000px) {
            box-shadow: 0 0 5px ${props => props.theme.colors.primaryAccent};
          }
        }
      }

      &.-error {
        border-color: #be1e2d;
        box-shadow: 0 0 5px #be1e2d;
      }
    }

    > input {
      padding: ${props => (props.label ? '8px' : '0px')} 10px 0 10px;
    }

    > textarea {
      transition: 0.3s;
      border-bottom-right-radius: 0px;
      padding: 16px 10px 8px 12px;
      min-height: 47px;
      resize: vertical;
    }

    > input[type='number']::-webkit-outer-spin-button,
    > input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    > input[type='number'] {
      -moz-appearance: textfield;
    }

    > input[type='date'] {
      padding: 8px 10px 0 7px;
    }

    > select {
      cursor: pointer;
      padding: 8px 20px 8px 7px;

      /* hide default select arrow */
      appearance: none;
      ::-ms-expand {
        display: none;
      }
    }

    > div {
      padding-left: 10px;

      > label {
        z-index: 2;
        cursor: text;
        color: lightgray;
        position: absolute;
        transform-origin: top left;
        transform: translate(0, 14px) scale(1);
        transition: all 0.1s ease-in-out;
      }
    }

    > i.tooltip {
      font-size: 1rem;
      position: absolute;
      top: 40%;
      right: 11px;
      cursor: help;
      color: ${props => props.theme.colors.primaryAccent};
    }

    > i.select-indicator {
      font-size: 60%;
      pointer-events: none;
      position: absolute;
      top: 45%;
      right: 14px;
    }

    &.-required {
      > div > label:after {
        content: '*';
        color: red;
        margin-left: 0.5ex;
      }
    }

    &.-active {
      > div > label {
        color: gray;
        transform: translate(0, 4px) scale(0.7);
      }
    }
  `
);
