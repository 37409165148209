import React, { useState } from 'react';
import styled from 'styled-components';
import { EscalatedSupportForm } from './EscalatedSupportForm';

import {
  BaseModal,
  ModalHeader,
  ModalFooter,
  PrimaryButton,
} from '@fattmerchantorg/truffle-components';

const StyledPrimaryButton = styled(PrimaryButton)`
  background-color: ${({ theme }) => theme.colors.core.green[400].hex};
`;

const FormContainer = styled.div`
  max-width: 690px;
  @media (max-width: 1000px) {
    max-width: 100%;
  }
`;

const StyledModalContent = styled.div`
  grid-area: content;
  overflow-y: auto;
  padding: ${({ theme }) => theme.space[2]}px ${({ theme }) => theme.space[4]}px;
  color: ${({ theme }) => theme.component.modal.color};
`;

const NewSupportTicketModal = () => {
  const [isNewSupportTicketModalOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <StyledPrimaryButton
        onClick={openModal}
        className="bg-green"
        id="new-ticket"
        trackingId="new-ticket"
        icon={['fas', 'plus']}
      >
        New Ticket
      </StyledPrimaryButton>

      <BaseModal
        title="Submit New Ticket"
        isOpen={isNewSupportTicketModalOpen}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeModal}
        onCancel={closeModal}
        onConfirm={closeModal}
        style={{
          content: {
            width: '480px',
            gridTemplate: `"header" 48px "content" 1fr`,
          },
        }}
      >
        <ModalHeader title="Submit New Ticket" onClose={closeModal} />

        <StyledModalContent>
          <FormContainer>
            <EscalatedSupportForm />
          </FormContainer>
        </StyledModalContent>
        <ModalFooter></ModalFooter>
      </BaseModal>
    </>
  );
};

export default NewSupportTicketModal;
