import React, { FC, useState } from 'react';
import { Group } from '../shared';
import styled, { withTheme } from 'styled-components';
import {
  BaseModal,
  DateRangePicker,
  Icon,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  Tooltip,
} from '@fattmerchantorg/truffle-components';
import {
  endOfDay,
  endOfYesterday,
  formatISO,
  isAfter,
  subDays,
} from 'date-fns';
import { formatDate, getTimezoneName } from '../../util/date.util';
import {
  useAsyncEffect,
  useAuthToken,
  usePermissions,
  useSearchState,
  useToaster,
} from '../../hooks';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { catanapi } from '../../api/catan';
import { AxiosError } from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { formatCurrency } from '../../util';
import {
  SettlementApprovalResponse,
  SettlementsSummaryResponse,
} from '../../@types';

const timezone = getTimezoneName();

const ButtonBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  > * {
    margin: 0 0 0 ${({ theme }) => theme.space[3]}px;
  }
`;

const StyledLink = withTheme(styled.a`
  color: ${({ theme }) => theme.linkHoverColor};
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  font-size: 12px;
  margin: 0.25rem 0;

  &:hover {
    color: #fff;
    i {
      color: #fff;
    }
  }

  span {
    margin-right: 0.25rem;
  }

  i {
    margin-top: 4px;
    color: ${({ theme }) => theme.linkHoverColor};
  }
`);

const StyledTable = styled.table`
  background-color: ${({ theme }) => theme.colors.core.gray[700].hex};

  width: 100%;
  border-radius: 2px;
  tr {
    display: grid;
    grid-template-columns: ${({ colCount }) => `repeat(${colCount ?? 3}, 1fr)`};
    align-items: center;
  }
  th,
  td,
  tr {
    padding: ${({ theme }) => theme.space[1]}px;
  }

  .green {
    color: ${({ theme }) => theme.colors.core.green[400].hex};
  }
  .red {
    color: ${({ theme }) => theme.colors.status.red[500].hex};
  }
  span {
    margin-right: 0.5rem;
  }

  th:first-child,
  td:first-child {
    text-align: left;
  }
  th:last-child,
  td:last-child {
    text-align: right;
  }
  th,
  td {
    text-align: center;
    font-size: 14px;
  }

  th {
    font-weight: bold;
    font-size: 12px;
  }
`;

const StyledInfoCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.core.gray[700].hex};
  width: 100%;
  padding: ${({ theme }) => theme.space[4]}px;
`;

const StyledResultsCard = styled.div`
  display: grid;
  background-color: ${({ theme }) => theme.colors.core.gray[700].hex};
  padding: ${({ theme }) => theme.space[2]}px;
  font-size: 14px;
  grid-row-gap: ${({ theme }) => theme.space[2]}px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto-fit, auto);

  hr {
    margin: 0;
    grid-column: 1 / 3;
    background-color: ${({ theme }) => theme.colors.core.gray[600].hex};
  }

  span {
    justify-self: end;
    grid-column: 2/3;
  }

  strong {
    grid-column: 1 / 2;
    justify-self: start;
  }

  div {
    display: flex;
    align-items: center;
    justify-self: end;
    cursor: pointer;
    &:hover {
      border-bottom: 1px ${({ theme }) => theme.linkHoverColor} solid;
    }
    i {
      color: ${({ theme }) => theme.linkHoverColor};
      margin-left: ${({ theme }) => theme.space[2]}px;
    }
  }

  .green {
    color: ${({ theme }) => theme.colors.core.green[400].hex};
  }
  .red {
    color: ${({ theme }) => theme.colors.status.red[500].hex};
  }
`;

const StyledModalContent = styled.div`
  grid-area: content;
  position: relative;
`;

const StyledScrollableArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;

const StyledScrollableAreaInner = styled.div`
  height: 100%;
  overflow-y: auto;
  padding: ${({ theme }) => `${theme.space[2]}px ${theme.space[4]}px`} 0;
  color: ${({ theme }) => theme.component.modal.color};
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 1px solid #435e70;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  top: 0;
`;

const CheckmarkIcon = styled.svg`
  fill: none;
  stroke: #000000;
  stroke-width: 3px;
  position: absolute;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  background: ${props =>
    props.checked || props.allStatus === 0 || props.allStatus === -1
      ? '#75D697'
      : '#213745'};
  border: 1px solid #435e70;
  border-radius: 2px;
  transition: all 150ms;
  margin-right: 8px;
  ${CheckmarkIcon} {
    visibility: ${props =>
      props.checked || props.allStatus === 0 || props.allStatus === -1
        ? 'visible'
        : 'hidden'};
  }
`;

const Checkbox = ({ checked, ...props }) => (
  <CheckboxContainer>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <CheckmarkIcon viewBox="0 0 24 32">
        <polyline points="20 6 9 17 4 12" />
      </CheckmarkIcon>
    </StyledCheckbox>
  </CheckboxContainer>
);

const BypassRiskHoldWarning = () => (
  <StyledBypassRiskHoldWarning>
    <ExclamationTriangleIcon icon={faExclamationTriangle} />
    <StyledBypassRiskHoldWarningText>
      Approves settlements regardless of merchant risk hold status.
    </StyledBypassRiskHoldWarningText>
  </StyledBypassRiskHoldWarning>
);

const StyledBypassRiskHoldWarning = styled.div`
  background-color: #f8dc3d;
  padding: 5px 16px;
`;

const ExclamationTriangleIcon = styled(Icon)`
  color: ${({ theme }) => theme.black};
  margin-right: 8px;
  height: 13px;
`;

const StyledBypassRiskHoldWarningText = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.black};
`;

const StyledBypassRiskHoldCheckboxText = styled.span`
  font-size: 14px;
  line-height: 20px;
`;

const StyledBypassRiskHoldCheckboxWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
`;

interface Props {
  isOpen: boolean;
}

export const SettlementApprovalWizardModal: FC<Props> = props => {
  const [query] = useSearchState<string>();
  const history = useHistory();
  const location = useLocation();
  const authToken = useAuthToken();
  const { permit } = usePermissions();
  const { toaster, toast } = useToaster();

  let startDate = new Date(query.startDate);
  let endDate = new Date(query.endDate);
  const maxDate = endOfYesterday();

  startDate = isAfter(startDate, maxDate) ? subDays(maxDate, 2) : startDate;
  endDate = isAfter(endDate, maxDate) ? maxDate : endDate;

  const canBypassRiskHold = permit('godview', 'overrideRiskHold', 'write');

  const [inFlight, setInFlight] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [approvedSettlements, setApprovedSettlements] = useState<null | Omit<
    SettlementApprovalResponse,
    'traceId'
  >>(null);
  const [settlements, setSettlements] = useState<
    SettlementsSummaryResponse['data'] | null
  >(null);
  const [error, setError] = useState<AxiosError | null>(null);
  const [dateFilter, setDateFilter] = useState<[Date, Date]>([
    startDate,
    endDate,
  ]);
  const [bypassRiskHold, setBypassRiskHold] = useState(false);

  const renderBody = () => {
    if (error !== null) {
      return (
        <StyledInfoCard>
          <strong>Something Went Wrong</strong>
          <span>No action was taken. Try approving settlements again.</span>
        </StyledInfoCard>
      );
    }
    if (settlements === null || settlements.settlements_summary?.length === 0) {
      return (
        <StyledInfoCard>
          <strong>No Pending Settlements Found</strong>
          <span>Select a different date range and try again</span>
        </StyledInfoCard>
      );
    }
    if (confirmed) {
      return (
        <>
          <span>Result</span>
          <StyledResultsCard>
            <strong>Date Range</strong>
            <span>
              {formatDate(dateFilter[0], 'M/dd/yyyy')} -{' '}
              {formatDate(dateFilter[1], 'M/dd/yyyy')}
            </span>
            <hr />

            <strong className="green">Approved</strong>
            <div onClick={() => alert('Hello')}>
              <strong>{approvedSettlements.successCount}</strong>
              <i className="fas fa-chevron-right"></i>
            </div>

            <strong className="red">Exceptions</strong>
            <div>
              <strong>{approvedSettlements.failedCount}</strong>
              <i className="fas fa-chevron-right"></i>
            </div>

            <hr />

            <strong>Total Settlement Count</strong>
            <span>
              <strong>
                {approvedSettlements.successCount +
                  approvedSettlements.failedCount}
              </strong>
            </span>
          </StyledResultsCard>
        </>
      );
    }

    return (
      <>
        <StyledTable colCount={2}>
          <thead>
            <tr>
              <th>Date Processed</th>
              <th>Gross Settled</th>
            </tr>
          </thead>
          <tbody>
            {settlements &&
              settlements.settlements_summary?.map(v => (
                <tr key={v.date}>
                  <td>{formatDate(v.date, 'MM/dd/yyyy')}</td>
                  <td>{formatCurrency(v.gross_settled)}</td>
                </tr>
              ))}
          </tbody>
        </StyledTable>
        <Group
          justify="space-between"
          space="0.25rem"
          style={{ marginTop: 10 }}
        >
          <span style={{ fontSize: 14 }}>Record Summary</span>
          {process.env.REACT_APP_RECONCILIATION_REPORT_URL && (
            <StyledLink
              href={`${
                process.env.REACT_APP_RECONCILIATION_REPORT_URL
              }? param_report_start_date=${formatDate(
                dateFilter[0],
                'yyyy-MM-dd'
              )}&param_report_end_date=${formatDate(
                dateFilter[1],
                'yyyy-MM-dd'
              )}&param_portfolio`}
              target="_blank"
            >
              <span>View Full Report</span>
              <i className="fas fa-chevron-right"></i>
            </StyledLink>
          )}
        </Group>

        <StyledTable>
          <thead>
            <tr>
              <td>
                <strong>
                  <span className="green">Approve</span>
                  {settlements.active}
                </strong>
              </td>
              <td>
                <Tooltip
                  content={'Settlements omitted due to risk hold flag present.'}
                >
                  <span>
                    <strong>
                      <span className="red">Omit</span>
                      {settlements.omit}
                    </strong>
                  </span>
                </Tooltip>
              </td>
              <td>
                <strong>
                  <span>Total</span>
                  {settlements.total}
                </strong>
              </td>
            </tr>
          </thead>
        </StyledTable>

        {canBypassRiskHold && (
          <StyledBypassRiskHoldCheckboxWrapper>
            <label>
              <Checkbox
                checked={bypassRiskHold}
                onChange={() => setBypassRiskHold(!bypassRiskHold)}
              />
              <StyledBypassRiskHoldCheckboxText>
                Bypass Risk Hold
              </StyledBypassRiskHoldCheckboxText>
            </label>
          </StyledBypassRiskHoldCheckboxWrapper>
        )}
      </>
    );
  };

  const handleCloseModal = () => {
    [setApprovedSettlements, setError].map(fn => fn(null));
    setConfirmed(false);
    history.push(`/settlements${location.search}`);
  };

  useAsyncEffect(async () => {
    if (props.isOpen) {
      try {
        setInFlight(true);
        const { data } = await catanapi.get<SettlementsSummaryResponse>(
          authToken,
          '/settlements-summary',
          {
            startDate: formatISO(dateFilter[0]),
            endDate: formatISO(endOfDay(dateFilter[1])),
            timezone,
          }
        );
        setSettlements(data);
      } catch (error) {
        setError(error);
      } finally {
        setInFlight(false);
      }
    }
  }, [authToken, dateFilter, props.isOpen]);

  const handleConfirm = async () => {
    setInFlight(true);
    let approval_settlements = [];
    settlements.settlements_summary?.forEach(settlement => {
      approval_settlements = approval_settlements.concat(
        settlement.settlement_id_list.map(v => ({
          settlement_id: v,
          state: 'APPROVED',
        }))
      );
    });

    try {
      const data = await catanapi.patch<SettlementApprovalResponse>(
        authToken,
        '/settlements',
        {
          overrideRiskHold: bypassRiskHold,
          settlements: approval_settlements,
        }
      );

      toaster(
        toast.success(
          'Approval Wizard approved settlements successfully',
          'Settlements Approved'
        )
      );
      delete data.traceId;
      setBypassRiskHold(false);
      setApprovedSettlements(data);
    } catch (e) {
      toaster(
        toast.error(
          'No action was taken. Try approving settlements again.',
          'OOps, Something went wrong'
        )
      );
      setError(e);
    } finally {
      setConfirmed(true);
      setInFlight(false);
    }
  };

  return (
    <BaseModal
      title="Approval Wizard"
      isOpen={props.isOpen}
      onRequestClose={handleCloseModal}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={false}
      style={{
        overlay: { pointerEvents: 'auto' },
        content: { maxWidth: '533px', height: '429px' },
      }}
    >
      <ModalHeader onClose={handleCloseModal} title="Approval Wizard" />
      <StyledModalContent>
        <StyledScrollableArea>
          <StyledScrollableAreaInner>
            {!confirmed && (
              <Group direction="column" items="baseline">
                <span>Select Date Range</span>
                <DateRangePicker
                  startDateFieldName="start_date"
                  endDateFieldName="end_date"
                  {...{
                    onChange: (date: [Date, Date], e) => {
                      if (date[0] && date[1]) setDateFilter(date);
                    },
                    maxDate: new Date(),
                    initialStart: dateFilter[0],
                    initialEnd: dateFilter[1],
                    hiddenInputs: false,
                  }}
                />
              </Group>
            )}
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              {renderBody()}
            </div>
          </StyledScrollableAreaInner>
          {canBypassRiskHold && bypassRiskHold && <BypassRiskHoldWarning />}
        </StyledScrollableArea>
      </StyledModalContent>
      <ModalFooter>
        <ButtonBar>
          {!confirmed && (
            <SecondaryButton onClick={handleCloseModal}>Cancel</SecondaryButton>
          )}

          <PrimaryButton
            disabled={
              settlements === null ||
              !settlements.settlements_summary?.length ||
              inFlight
            }
            onClick={confirmed || error ? handleCloseModal : handleConfirm}
          >
            {confirmed || error ? 'Close' : 'Confirm'}
          </PrimaryButton>
        </ButtonBar>
      </ModalFooter>
    </BaseModal>
  );
};
