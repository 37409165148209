import React, { FunctionComponent } from 'react';
import { Icon } from '@fattmerchantorg/truffle-components';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;

  background-color: ${({ theme }) => theme.colors.status.yellow[500].hex};
  color: ${({ theme }) => theme.black};
`;

const Message = styled.div`
  margin-left: 6px;
`;

export const WarningAlert: FunctionComponent = props => {
  return (
    <Container>
      <Icon icon={['far', 'info-circle']} />
      <Message>{props.children}</Message>
    </Container>
  );
};
