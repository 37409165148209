/**
 * This simply maps FIS field names to Stax's internal registration record field
 * names.
 *
 * In the future we probably should handle this mapping on the backend UW
 * service so that we can keep one clean interface for the front end error
 * mapping to various processors.
 */
export const resolveFISField = (fieldData: { field: string; text: string }) => {
  switch (fieldData.field) {
    case 'bankAccounts.account_num':
      return 'bank_account_number';
    case 'bankAccounts.routing_num':
      return 'bank_routing_number';
    case 'bankAccounts.type':
      return 'bank_account_type';
    case 'merchantInfo.legal_name':
      return 'business_legal_name';
    case 'merchantInfo.dba':
      return 'business_dba';
    case 'merchantInfo.own_type':
      return 'company_type';
    case 'merchantInfo.card_present':
      return 'card_present_percent';
    case 'merchantInfo.card_not_present':
      return 'card_not_present_percent';
    case 'merchantInfo.mcc':
      return 'mcc';
    case 'merchantInfo.bus_open_date':
      return 'business_open_date';
    case 'merchantInfo.fed_tax_id':
      return 'business_tax_id';
    case 'merchantInfo.annual_sales':
      return 'annual_volume';
    case 'merchantInfo.average_ticket':
      return 'avg_trans_size';
    case 'merchantInfo.lrgst_avg_ticket':
      return 'highest_trans_amount';
    case 'merchantInfo.addl_loc':
      return 'addl_loc';
    case 'merchantInfo.addl_loc_main_mid':
      return 'addl_loc_main_mid';
    case 'merchantInfo.proc_change':
      return 'proc_change';
    case 'merchantInfo.card_swipe':
      return 'card_swiped_percent';
    case 'merchantInfo.refund_policy':
      return 'refund_policy';
    case 'addresses.line1':
      if (fieldData.text.indexOf('Address: 1,')) {
        return 'business_address_1';
      }
      if (fieldData.text.indexOf('Address: 2,')) {
        return 'business_location_address_1';
      }
      if (fieldData.text.indexOf('Address: 3,')) {
        return 'owner_address_1';
      }
      break;
    case 'addresses.city':
      if (fieldData.text.indexOf('Address: 1,')) {
        return 'business_address_city';
      }
      if (fieldData.text.indexOf('Address: 2,')) {
        return 'business_location_address_city';
      }
      if (fieldData.text.indexOf('Address: 3,')) {
        return 'owner_address_city';
      }
      break;
    case 'addresses.state':
      if (fieldData.text.indexOf('Address: 1,')) {
        return 'business_address_state';
      }
      if (fieldData.text.indexOf('Address: 2,')) {
        return 'business_location_address_state';
      }
      if (fieldData.text.indexOf('Address: 3,')) {
        return 'owner_address_state';
      }
      break;
    case 'addresses.zip':
      if (fieldData.text.indexOf('Address: 1,')) {
        return 'business_address_zip';
      }
      if (fieldData.text.indexOf('Address: 2,')) {
        return 'business_location_address_zip';
      }
      if (fieldData.text.indexOf('Address: 3,')) {
        return 'owner_address_zip';
      }
      break;
    case 'addresses.phone':
      if (fieldData.text.indexOf('Address: 1,')) {
        return 'business_phone_number';
      }
      if (fieldData.text.indexOf('Address: 2,')) {
        return 'business_location_phone_number';
      }
      if (fieldData.text.indexOf('Address: 3,')) {
        return 'phone_number';
      }
      break;
    case 'representatives.first_name':
      return 'first_name';
    case 'representatives.last_name':
      return 'last_name';
    case 'representatives.title':
      return 'title';
    case 'representatives.date_of_birth':
      return 'user_dob';
    case 'representatives.ssn':
      return 'user_ssn';
    default:
      console.warn(`No field map found for field: ${fieldData.field}`);
      break;
  }
};
