import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Field, Form } from '../../shared';
import { SubmissionErrors, FormApi } from 'final-form';
import {
  Select,
  DateRangePicker,
  Label,
} from '@fattmerchantorg/truffle-components';
import { format } from 'date-fns';
import { Button } from '../../shared/Button';
import { history } from '../../../history';
import { parse } from '../../../util/api.util';
import { useLocation } from 'react-router-dom';
import { useSearchState, usePermissions } from '../../../hooks';
import { Row, Col } from 'reactstrap';
import { FieldProps } from 'react-final-form';

interface MerchantFilterForm {
  accountStatus: Array<{ value: string; label: string }>;
  underwritingStatus: Array<{ value: string; label: string }>;
  surcharging: string;
  createdDate: (string | string[])[];
}

export const FilterButton = styled.div`
  align-items: center;
  float: right;
  background-color: #004166;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 32px;
  justify-content: center;
  min-width: 90px;
  width: auto;
  padding: 6px 8px;
  line-height: 20px;
  margin-top: 14px;
  margin-left: 1rem;

  span {
    color: #009bf2;

    &:not(:first-child) {
      font-weight: bold;
      margin-left: 4px;
    }
  }
`;

const ClearFiltersButton = styled(Button)`
  background-color: transparent;
  color: #fff;
  font-size: 14px;
  height: 37px;
  margin-top: 0.5rem;
  margin-left: 16px;
  float: right;
  border: 2px solid #fff;
  @media (max-width: 1200px) {
    margin: 0.5rem;
  }
  :hover,
  :active,
  :focus {
    background-color: #ffffff;
    color: #575757;
  }
`;

const StyledSelect = styled(Select)`
  margin-bottom: 10px;
`;

// this is custom style to align date-range picker with other inputs
export const FormRow = styled.div`
  button {
    margin-bottom: 0;
  }

  .react-datepicker__input-container > div > button {
    top: 0;
    right: 0;
    height: 30px;
    width: 100%;
  }
  .react-datepicker-popper {
    min-width: 380px;
  }
`;

const riskStatusOption = { label: 'Deposit Hold', value: 'risk_hold' };

const statusOptions = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'INACTIVE' },
  { label: 'Pending', value: 'PENDING' },
  { label: 'Ceased Processing', value: 'ceased_processing' },
];

const underwritingStatusOptions = [
  { label: 'Fully Approved', value: 'FULLY_APPROVED' },
  { label: 'Conditionally Approved', value: 'CONDITIONALLY_APPROVED' },
  { label: 'Pended', value: 'PENDED' },
  { label: 'Rejected', value: 'REJECTED' },
];

const surchargeOptions = [
  {
    label: 'No',
    value: '0',
  },
  {
    label: 'Yes',
    value: '1',
  },
];

const AccordionContainer = styled.div`
  width: 100%;
  border-top: 1px solid #435e70;
  padding-top: 1rem;
`;

// removes URL parameters from the URL
const splitUrl = hash => {
  let urlArray = hash.split('?');
  let hashUrlArray = urlArray[0].split('#');
  return hashUrlArray[1];
};

export const MerchantsFilterAccordion = props => {
  const [searchState] = useSearchState();
  const { permit } = usePermissions();

  const [dateFilter, setDateFilter] = useState(null);

  const canSeeRiskHold = permit('godview', 'riskHold', 'read');

  const accountOptions = useMemo(() => {
    const options = statusOptions;
    if (canSeeRiskHold && !options.some(o => o.value === 'risk_hold')) {
      options.push(riskStatusOption);
    }
    return options;
  }, [canSeeRiskHold]);

  const { pathname, search } = useLocation();

  const onSubmit = useCallback(
    async (
      formValues: MerchantFilterForm
    ): Promise<SubmissionErrors | undefined | void> => {
      let filters: string[] = [];

      const prevFilters = parse(search);
      if ('perPage' in prevFilters) {
        filters.push('perPage=' + prevFilters['perPage']);
        filters.push('page=1');
      }

      if (formValues.accountStatus) {
        const status = formValues.accountStatus.filter(
          i => !['ceased_processing', 'risk_hold'].includes(i.value)
        );

        const riskHold =
          formValues.accountStatus.filter(i => i.value === 'risk_hold').length >
          0;

        const cp =
          formValues.accountStatus.filter(i => i.value === 'ceased_processing')
            .length > 0;

        if (cp) {
          filters.push('ceased_processing=1');
        }
        if (riskHold) {
          filters.push('risk_hold=1');
        }
        status.map(i => {
          return filters.push('statuses[]=' + i.value);
        });
      }

      if (formValues.underwritingStatus) {
        formValues.underwritingStatus.map(us =>
          filters.push('underwriting_status[]=' + us.value)
        );
      }

      if (formValues.surcharging) {
        filters.push('is_surcharge_enabled=' + formValues.surcharging);
      }

      if (formValues.createdDate) {
        filters.push('created_at_start=' + formValues.createdDate[0]);
        filters.push('created_at_end=' + formValues.createdDate[1]);
      }

      history.push(
        `${pathname}?${filters.filter(f => !f.endsWith('undefined')).join('&')}`
      );
    },
    [pathname, search]
  );

  const initialFormValues: MerchantFilterForm = useMemo(() => {
    let accountStatuses = statusOptions.filter(o =>
      searchState.statuses?.includes(o.value)
    );

    // need to push in these two separately because they are not part of the 'statuses'
    if (searchState.risk_hold) {
      accountStatuses.push({ label: 'Deposit Hold', value: 'risk_hold' });
    }

    if (searchState.ceased_processing) {
      accountStatuses.push({
        label: 'Ceased Processing',
        value: 'ceased_processing',
      });
    }

    return {
      accountStatus: accountStatuses,
      underwritingStatus: underwritingStatusOptions.filter(o =>
        searchState.underwriting_status?.includes(o.value)
      ),
      surcharging: String(searchState.is_surcharge_enabled),
      createdDate: [
        searchState?.created_at_start,
        searchState?.created_at_start,
      ],
    };
  }, [searchState]);

  const updateDates = (
    date: [Date | null, Date | null],
    input?: FieldProps<any, HTMLElement>,
    form?: FormApi<MerchantFilterForm>
  ) => {
    if (date[0] && date[1]) {
      const start = format(date[0], 'yyyy-MM-dd');
      const end = format(date[1], 'yyyy-MM-dd');

      input.onChange([start, end]);
      form.submit();
    }
  };

  return (
    <AccordionContainer>
      <Form<MerchantFilterForm>
        initialValues={initialFormValues}
        onSubmit={onSubmit}
      >
        {formProps => (
          <Row noGutters>
            <Col xs="12" md="4" lg="2" className="pr-md-3">
              <Label text="Created Date" />
              <Field name="createdDate" label="Created Date" key={dateFilter}>
                {fieldProps => (
                  <FormRow>
                    <DateRangePicker
                      {...fieldProps.input}
                      startDateFieldName="created_at_start"
                      endDateFieldName="created_at_end"
                      {...{
                        onChange: (date: [Date | null, Date | null]) => {
                          updateDates(date, fieldProps.input, formProps.form);
                          if (date && date[0] && date[1]) {
                            setDateFilter(date);
                          }
                        },
                        maxDate: new Date(),
                        initialStart: dateFilter ? dateFilter[0] : null,
                        initialEnd: dateFilter ? dateFilter[1] : null,
                        hiddenInputs: true,
                      }}
                    />
                  </FormRow>
                )}
              </Field>
            </Col>
            <Col xs="12" md="4" lg="2" className="pr-md-3">
              <Field name="accountStatus" label="Account Status">
                {fieldProps => (
                  <StyledSelect
                    {...fieldProps.input}
                    label="Account Status"
                    defaultValue={statusOptions.filter(o =>
                      searchState.statuses?.includes(o.value)
                    )}
                    onChange={value => {
                      fieldProps.input.onChange(value);
                      formProps.form.submit();
                    }}
                    options={accountOptions}
                    isMulti={true}
                  />
                )}
              </Field>
            </Col>
            <Col xs="12" md="4" lg="2" className="pr-md-3">
              <Field name="underwritingStatus" label="Underwriting Status">
                {fieldProps => (
                  <StyledSelect
                    {...fieldProps.input}
                    label="Underwriting Status"
                    defaultValue={underwritingStatusOptions.filter(o =>
                      searchState.underwriting_status?.includes(o.value)
                    )}
                    onChange={value => {
                      fieldProps.input.onChange(value);
                      formProps.form.submit();
                    }}
                    options={underwritingStatusOptions}
                    isMulti={true}
                  />
                )}
              </Field>
            </Col>
            <Col xs="12" md="4" lg="2" className="pr-md-3">
              <Field name="surcharging" label="Surcharging Enabled">
                {fieldProps => (
                  <StyledSelect
                    {...fieldProps.input}
                    label="Surcharging Enabled"
                    value={surchargeOptions.filter(o =>
                      searchState.is_surcharge_enabled?.includes(o.value)
                    )}
                    onChange={value => {
                      fieldProps.input.onChange(value);
                      formProps.form.submit();
                    }}
                    isClearable
                    options={surchargeOptions}
                  />
                )}
              </Field>
            </Col>
            <Col xs="12" md="4" lg="2">
              <ClearFiltersButton
                type="button"
                onClick={() => {
                  setDateFilter(null);
                  history.push(`${splitUrl(window.location.hash)}`);
                }}
              >
                Clear Filters
              </ClearFiltersButton>
            </Col>
          </Row>
        )}
      </Form>
    </AccordionContainer>
  );
};
