import React, { FC, useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Modal, TextArea } from '../../../shared';
import { UnderwritingStatusSelection } from './UnderwritingStatusSelection';
import { AccountStatusSelection } from './MockAccountStatusSelection';
import { TestBannerSection } from './TestBannerSection';
import { ReasonsSelectionSection } from './ReasonsSelectionSection';
import { useAuthToken, useToaster } from '../../../../hooks';
import { coreapi } from '../../../../api';
import {
  SelectedMerchantStore,
  addSelectedMerchantGateway,
  updateSelectedMerchantRegistration,
  updateSelectedMerchantStatus,
  alterSelectedMerchant,
} from '../../../../context';
import { Gateway, Merchant } from '@fattmerchantorg/types-omni';
import { generateMockPayload } from '../../../../util/mockFeature.util';
import { StaxButton } from '../../../shared/Stax/Button';
import MockModalSubTitle from './MockModalSubtitle';

const ModalTop = styled.div`
  padding: 16px 32px 16px 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BoldText = styled.span`
  font-family: Open Sans, sans-serif;
  font-weight: bold;
`;

export const MockModal: FC<{
  closeModal: Function;
  isModalOpen: boolean;
  originalUnderwritingStatus: string;
  originalAccountStatus: string;
}> = ({
  closeModal,
  isModalOpen,
  originalUnderwritingStatus,
  originalAccountStatus,
}) => {
  const {
    dispatch,
    state: { merchantId, merchant, registration },
  } = useContext(SelectedMerchantStore);

  // Form fields.
  const [mockUnderwritingStatus, setMockUnderwritingStatus] =
    useState<string>(null);
  const [mockAccountStatus, setMockAccountStatus] = useState<string>(null);
  const [mockNotes, setMockNotes] = useState<string>(
    registration.underwriting_note
  );
  const [mockReasons, setMockReasons] = useState<any[]>([]);

  const { toaster, toast } = useToaster();
  const authToken = useAuthToken();
  const merchantName = merchant?.company_name;
  const allowedNoteStatus = ['APPROVED', 'PENDED', 'REJECTED']; // mockUnderwritingStatus values that triggers the Underwriting Note to show
  const allowedAccountStatus = ['APPROVED']; // mockUnderwritingStatus values that triggers the Account Status to show

  // Save defaults in case nothing changes and the user still saves.
  useEffect(() => {
    if (registration.underwriting_substatuses) {
      // TODO: remove "as any" cast once/if we remove "| string | null" from type
      const originalReasons = (
        registration.underwriting_substatuses as any
      ).map((ss: any) => {
        return ss.key;
      });
      setMockReasons([...originalReasons]);
    }
  }, [registration]);

  // Reset selected reasons on status change.
  useEffect(() => {
    if (originalUnderwritingStatus !== mockUnderwritingStatus) {
      setMockReasons([]);
    }
  }, [originalUnderwritingStatus, mockUnderwritingStatus]);

  // Reset selected status on modal change.
  useEffect(() => {
    setMockUnderwritingStatus(null);
  }, [isModalOpen]);

  const handleSubmit = async () => {
    const payload = generateMockPayload(
      mockUnderwritingStatus,
      mockReasons,
      mockNotes
    );

    try {
      const registration = await coreapi.put(
        authToken,
        `/merchant/${merchantId}/registration`,
        payload
      );

      dispatch(updateSelectedMerchantRegistration(registration));

      dispatch(
        alterSelectedMerchant({ risk_hold: registration.merchant.risk_hold })
      );

      // This block should only run when the underwriting status is APPROVED.
      if (mockUnderwritingStatus === 'APPROVED') {
        const merchant: Merchant = await coreapi.put(
          authToken,
          `/merchant/${merchantId}`,
          {
            status: mockAccountStatus,
          }
        );

        dispatch(updateSelectedMerchantStatus(merchant.status));
      }

      if (
        mockUnderwritingStatus === 'APPROVED' &&
        mockUnderwritingStatus !== originalUnderwritingStatus
      ) {
        const body = {
          name: 'test',
          type: 'TEST',
          vendor: 'spreedly',
        };

        const gateway: Gateway = await coreapi.post(
          authToken,
          `/merchant/${merchantId}/gateway/`,
          body
        );

        dispatch(addSelectedMerchantGateway(gateway));
      }

      toaster(toast.success('Changes successfully saved'));
      closeModal();
    } catch (error) {
      toaster(toast.error(error, 'There was a problem saving your changes.'));
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={() => closeModal()}
      data-testid="edit_underwriting_status_modal"
    >
      <ModalTop>
        Mock Underwriting Approval -{' '}
        <BoldText>{merchantName || 'N/A'}</BoldText>
      </ModalTop>

      {/* Must be outside main container as it spans to the edge */}
      <TestBannerSection />

      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <UnderwritingStatusSelection
              handleSelection={setMockUnderwritingStatus}
              defaultStatus={registration.underwriting_status}
            />
          </div>

          {/* Underwriting Status radio buttons */}
          <div className="col-md-6">
            <ReasonsSelectionSection
              status={
                mockUnderwritingStatus || registration.underwriting_status
              }
              handleSelection={setMockReasons}
              // TODO: remove "as any" cast once/if we remove "| string | null" from type
              defaultReasons={registration.underwriting_substatuses as any}
              defaultStatus={registration.underwriting_status}
            />
          </div>

          {/* Account Status radio buttons */}
          {allowedAccountStatus.indexOf(mockUnderwritingStatus) >= 0 && (
            <div className="col-12">
              <AccountStatusSelection
                handleSelection={setMockAccountStatus}
                defaultStatus={originalAccountStatus}
              />
            </div>
          )}

          {/* Underwriting Note */}
          {allowedNoteStatus.indexOf(mockUnderwritingStatus) >= 0 && (
            <div className="col-12">
              <MockModalSubTitle>Underwriting Note</MockModalSubTitle>
              <TextArea
                onBlur={e => setMockNotes(e.target.value)}
                defaultValue={registration.underwriting_note}
              />
            </div>
          )}

          <div className="col-12 text-right pt-2 pb-3">
            <StaxButton
              rank="secondary"
              onClick={() => closeModal()}
              className="mr-2"
            >
              Cancel
            </StaxButton>
            <StaxButton onClick={handleSubmit}>Submit</StaxButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};
