import { useState, useEffect } from 'react';
import { useToaster } from './useToaster';
import {
  FileRecord,
  OmniDispute,
  DisputeFile,
} from '@fattmerchantorg/types-omni';
import { coreapi, onboardingapi } from '../api';
import { downloadData } from '../util/documents.util';
import { useAuthToken } from './useAuthToken';
import { MerchantDisputeCombo } from '../components/disputes/Disputes';

export function useSupportingDocuments(
  merchantAndDispute: MerchantDisputeCombo,
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
  bypassFetch = false
) {
  const { toaster, toast } = useToaster();
  const authToken = useAuthToken();
  const [supportingDocuments, setSupportingDocuments] = useState<DisputeFile[]>(
    []
  );

  // Files array must be cleared when the dispute Id changes.
  useEffect(() => {
    if (bypassFetch) {
      return;
    }
    if (merchantAndDispute?.disputeId) {
      (async () => {
        const dispute = await onboardingapi.get(
          authToken,
          `/disputes/${merchantAndDispute.merchantId}/${merchantAndDispute.disputeId}`
        );
        if (dispute.files) {
          setSupportingDocuments(dispute.files);
        } else {
          setSupportingDocuments([]);
        }
      })();
    }
  }, [authToken, merchantAndDispute, bypassFetch]);

  /*
   * Download file.
   */
  const downloadFile = async (file: FileRecord) => {
    setIsLoading(true);
    try {
      const res = await onboardingapi.get(authToken, `/file/${file.id}`);
      downloadData(res, file.name, file.meta.mime);
    } catch (error) {
      toaster(toast.error(error, 'There was a problem downloading the file.'));
    }
    setIsLoading(false);
  };

  /*
   * Delete file.
   */
  const deleteFile = async (file: FileRecord) => {
    setIsLoading(true);
    try {
      // throw error for missing file id
      if (!file.id) {
        throw new Error();
      }
      // Delete file.
      const deletedFile = await coreapi.delete(
        authToken,
        'file/dispute/' + file.id
      );

      // Remove deleted file from our UI.
      // It is better to pass a function that calculates the new state of supportingDocuments based on the previous state
      // This method uses ques that can guarantee accuracy when it comes to the state of supportingDocuments
      setSupportingDocuments(documents =>
        documents.filter(file => file.id !== deletedFile.id)
      );
    } catch (error) {
      toaster(toast.error(error, 'There was a problem deleting the file.'));
    }
    setIsLoading(false);
  };

  /*
   * Submit dispute.
   */
  const submitDispute = async () => {
    try {
      const dispute = await onboardingapi.post<OmniDispute>(
        authToken,
        `dispute/${merchantAndDispute.disputeId}/submit`
      );
      toaster(toast.success('Dispute Evidence has been submitted.'));
      return dispute;
    } catch (error) {
      toaster(toast.error(error, 'There was an error submitting the dispute.'));
    }
  };

  /*
   * Upload files.
   */
  const uploadFiles = async (files: File[]) => {
    setIsLoading(true);
    files.map(async file => {
      try {
        const formData = new FormData();
        formData.append('name', file.name);
        formData.append('dispute_id', merchantAndDispute.disputeId);
        formData.append('file', file);

        const res = await coreapi.post(authToken, 'file/dispute', formData);

        // Update array of files for UI.
        setSupportingDocuments(supportingDocuments => [
          ...supportingDocuments,
          res,
        ]);
      } catch (error) {
        toaster(toast.error(error, 'There was a problem uploading the file.'));
      }
    });
    setIsLoading(false);
  };

  return {
    downloadFile,
    deleteFile,
    submitDispute,
    uploadFiles,
    supportingDocuments,
    setSupportingDocuments,
  };
}
