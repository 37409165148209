import React from 'react';
import {
  Accordion,
  Icon,
  TableBody,
  TableCell,
  TableRow,
} from '@fattmerchantorg/truffle-components';
import { AccordionWrapper, DrawerSection } from '../sharedComponents';
import { AdjustmentSectionData } from '../AdvancedDetailDrawer.types';
import styled from 'styled-components';

type AdjustmentSectionProps = {
  id: string;
  name: string;
  data: AdjustmentSectionData;
};

const StyledTable = styled.table`
  margin-bottom: 20px;
  width: 100%;
  word-break: break-word;
  tbody {
    tr {
      background-color: transparent;
      border: none;
      &:hover {
        background-color: transparent !important;
      }
      td {
        padding-left: 0;
        padding-right: 0;
        vertical-align: top;
        &:first-child {
          padding-left: 0;
          padding-right: 5px;
          font-weight: bold;
          width: 40%;
        }
      }
    }
  }
`;

const AssociatedTransaction = styled.div`
  display: flex;
`;

const AssociatedTransactionIconWrapper = styled.div`
  display: flow-root;
`;

const AssociatedTransactionIcon = styled(Icon)<{ disabled: boolean }>`
  position: relative;
  float: left;
  margin-left: 10px;
  cursor: ${({ disabled }) => (disabled ? '' : 'pointer')};
  color: ${({ disabled }) =>
    disabled ? 'gray' : ({ theme }) => theme.colors.status.blue[500].hex};
`;

const AdjustmentSection: React.FunctionComponent<
  AdjustmentSectionProps
> = props => {
  const { id, data } = props;

  return (
    <AccordionWrapper id={id}>
      <Accordion title="Adjustment">
        <DrawerSection id="adjustment-section">
          <StyledTable>
            <TableBody>
              <TableRow>
                <TableCell>Category</TableCell>
                <TableCell>{data.category}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>{data.description}</TableCell>
              </TableRow>
              {data.parentTransaction && (
                <TableRow>
                  <TableCell>Associated Transaction</TableCell>
                  <TableCell>
                    <AssociatedTransaction>
                      {data.parentTransaction}
                      <AssociatedTransactionIconWrapper>
                        <AssociatedTransactionIcon
                          icon={['fas', 'external-link']}
                          onClick={() => {
                            data.settlementId &&
                              window.open(
                                `#/settlements/${data.settlementId}/details?detailId=${data.parentTransaction}`,
                                '_blank'
                              );
                          }}
                          disabled={!data.settlementId}
                        />
                      </AssociatedTransactionIconWrapper>
                    </AssociatedTransaction>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>Created by</TableCell>
                <TableCell>{data.createdBy}</TableCell>
              </TableRow>
            </TableBody>
          </StyledTable>
        </DrawerSection>
      </Accordion>
    </AccordionWrapper>
  );
};

export default AdjustmentSection;
