import React, { Dispatch, FC, useCallback } from 'react';
import { Container, Group, Modal } from '../../shared';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  Action as ModalAction,
  ModalState,
  sendCloseSelected,
  Status as ModalStatus,
} from '../../../hooks/useModalReducer';
import { StaxButton } from '../../shared/Stax/Button';

const closeSelected = sendCloseSelected();

const RiskHoldIcon = styled(FontAwesomeIcon)`
  width: 448px;
  height: 70px;
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 70px;
  line-height: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.core.gray[200].hex};
`;

const ModalMessage = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
`;

const ModalDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 16px;
  height: 212px;
  background: #213745;
`;

const ModalButtonDiv = styled.div`
  margin-top: 10px;
`;
const StyledContainer = styled(Container)`
  padding: 16px;
`;
const ModalLeaveButton = styled(StaxButton)`
  background: none;
  border: 1px solid ${({ theme }) => theme.colors.status.red[500].hex};
  color: ${({ theme }) => theme.colors.status.red[500].hex};
`;

const ModalLeaveButtonPrimary = styled(StaxButton)`
  background-color: ${({ theme }) => theme.colors.status.red[500].hex};
  color: ${({ theme }) => theme.colors.core.white[0].hex};
`;

/** Context for `useModalReducer` state. */
export interface DeleteModalContext {
  message: React.ReactNode;
}
/** Initial state for `useModalReducer`. */
export const initialDeleteModalState: ModalState<DeleteModalContext> = {
  status: ModalStatus.CLOSED,
  context: { message: null },
};
export const DeleteFormDataModal: FC<{
  message: React.ReactNode;
  status: ModalStatus;
  title?: string;
  primaryActionPreferred?: boolean;
  actionFunc?: Function;
  modalDispatch: Dispatch<ModalAction>;
  cancelText?: string;
  actionText?: string;
}> = ({
  message,
  status,
  title,
  primaryActionPreferred,
  modalDispatch,
  actionFunc,
  cancelText,
  actionText,
}) => {
  const onDeleteClick = useCallback(async () => {
    if (actionFunc) {
      actionFunc();
    }
    modalDispatch(closeSelected);
  }, [modalDispatch, actionFunc]);

  return (
    <Modal
      data-testid="delete-form-value-modal"
      width="480px"
      overflow="hidden"
      isOpen={status === ModalStatus.OPEN}
      onClose={() => modalDispatch(closeSelected)}
      title={<strong>{title ?? ''}</strong>}
    >
      <StyledContainer>
        <ModalDiv>
          <RiskHoldIcon
            id={`risk-hold-merchant-icon`}
            icon={faExclamationTriangle as IconProp}
          />

          <ModalMessage>{message}</ModalMessage>
        </ModalDiv>
        <ModalButtonDiv>
          <Group direction="row" space="1rem" justify="center">
            {primaryActionPreferred ? (
              <React.Fragment>
                <StaxButton
                  rank="secondary"
                  onClick={() => modalDispatch(closeSelected)}
                >
                  <strong>{cancelText ?? 'Cancel'}</strong>
                </StaxButton>
                <ModalLeaveButtonPrimary motif="danger" onClick={onDeleteClick}>
                  <strong>{actionText ?? 'Proceed'}</strong>
                </ModalLeaveButtonPrimary>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <StaxButton onClick={() => modalDispatch(closeSelected)}>
                  <strong>{cancelText ?? 'Cancel'}</strong>
                </StaxButton>
                <ModalLeaveButton motif="danger" onClick={onDeleteClick}>
                  <strong>{actionText ?? 'Proceed'}</strong>
                </ModalLeaveButton>
              </React.Fragment>
            )}
          </Group>
        </ModalButtonDiv>
      </StyledContainer>
    </Modal>
  );
};
