import React, { FunctionComponent } from 'react';
import {
  Group,
  Input,
  FieldError,
  DateInput,
  Select,
} from '../../../../shared';
import { formatPhoneNumber } from '../../../../../util/format.util';
import { states } from '../../../../../constants/usaStates';

import { MaskedInput } from '../../../../shared/MaskedInput';
import { EnrollmentField } from '../../enrollment-field';

export const RepresentativeFields: FunctionComponent<{
  index?: number;
  mode: 'edit' | 'review';
  isMobile: boolean;
  setCalculatedSignerOwnershipPercentage?: () => any;
}> = props => {
  const { index, isMobile, setCalculatedSignerOwnershipPercentage } = props;

  const isSigner = isNaN(index);
  const model: string = isSigner ? '' : `representatives[${index}].`;

  return (
    <>
      <Group direction="row" space="0.5rem/0px">
        <Group direction="column" style={{ flex: '70%' }}>
          <EnrollmentField name={model + 'title'} label="Title">
            {props => <Input {...props.input} />}
          </EnrollmentField>
          <FieldError name={model + 'title'} />
        </Group>

        <Group direction="column" style={{ flex: '30%' }}>
          <EnrollmentField
            name={model + 'ownership_percentage'}
            label={isMobile ? 'Own. %' : 'Ownership %'}
          >
            {props => (
              <Input
                {...props.input}
                onChange={e => {
                  props.input.onChange(e);
                  if (!isSigner) setCalculatedSignerOwnershipPercentage();
                }}
              />
            )}
          </EnrollmentField>
          <FieldError name={model + 'ownership_percentage'} />
        </Group>
      </Group>

      {!isSigner && (
        <Group direction="column">
          <EnrollmentField name={model + 'type'} label="Representative Type">
            {props => (
              <Select {...props.input}>
                <option value="" disabled />
                <option value="Beneficial Owner">Beneficial Owner</option>
                <option value="Guarantor">Guarantor</option>
              </Select>
            )}
          </EnrollmentField>
          <FieldError name={model + 'type'} />
        </Group>
      )}

      <Group direction="row" space="0.5rem/0px">
        <Group direction="column" style={{ flex: '50%' }}>
          <EnrollmentField name={model + 'first_name'} label="First Name">
            {props => <Input {...props.input} />}
          </EnrollmentField>
          <FieldError name={model + 'first_name'} />
        </Group>
        <Group direction="column" style={{ flex: '50%' }}>
          <EnrollmentField name={model + 'last_name'} label="Last Name">
            {props => <Input {...props.input} />}
          </EnrollmentField>
          <FieldError name={model + 'last_name'} />
        </Group>
      </Group>

      <Group direction="column">
        <EnrollmentField
          name={model + 'phone'}
          label="Phone Number"
          parse={formatPhoneNumber}
        >
          {props => <Input {...props.input} type="tel" />}
        </EnrollmentField>
        <FieldError name={model + 'phone'} />
      </Group>

      <Group direction="column">
        <EnrollmentField name={model + 'date_of_birth'} label="Date of Birth">
          {props => <DateInput {...props.input} />}
        </EnrollmentField>
        <FieldError name={model + 'date_of_birth'} />
      </Group>

      <Group direction="column">
        <EnrollmentField
          name={model + 'ssn'}
          label="Social Security Number"
          secure={true}
        >
          {props => <MaskedInput {...props.input} maskType="ssn" />}
        </EnrollmentField>
        <FieldError name={model + 'ssn'} />
      </Group>

      <Group direction="column">
        <EnrollmentField name={model + 'address_1'} label="Home Address Line 1">
          {props => <Input {...props.input} />}
        </EnrollmentField>
        <FieldError name={model + 'address_1'} />
      </Group>

      <Group direction="column">
        <EnrollmentField
          name={model + 'address_2'}
          label="Home Address Line 2 (optional)"
        >
          {props => <Input {...props.input} />}
        </EnrollmentField>
        <FieldError name={model + 'address_2'} />
      </Group>

      <Group direction="row" space="0.5rem/0px">
        <Group direction="column" style={{ flex: '40%' }}>
          <EnrollmentField name={model + 'address_city'} label="City">
            {props => <Input {...props.input} />}
          </EnrollmentField>
          <FieldError name={model + 'address_city'} />
        </Group>

        <Group direction="column" style={{ flex: '30%' }}>
          <EnrollmentField name={model + 'address_state'} label="State">
            {props => (
              <Select {...props.input}>
                <option value="" disabled />
                {states.map((state, index) => (
                  <option key={index} value={state.abbr}>
                    {state.name}
                  </option>
                ))}
              </Select>
            )}
          </EnrollmentField>
          <FieldError name={model + 'address_state'} />
        </Group>

        <Group direction="column" style={{ flex: '30%' }}>
          <EnrollmentField name={model + 'address_zip'} label="Zip">
            {props => <Input {...props.input} />}
          </EnrollmentField>
          <FieldError name={model + 'address_zip'} />
        </Group>
      </Group>

      <Group direction="row" space="0.5rem/0px">
        <Group direction="column" style={{ flex: '50%' }}>
          <EnrollmentField
            data-testid={model + 'drivers_license_id'}
            name={model + 'drivers_license_id'}
            label="Drivers License ID"
          >
            {props => <Input {...props.input} />}
          </EnrollmentField>
          <FieldError name={model + 'drivers_license_id'} />
        </Group>
        <Group direction="column" style={{ flex: '50%' }}>
          <EnrollmentField
            name={model + 'drivers_license_state'}
            label="Issuer State"
          >
            {props => (
              <Select {...props.input}>
                <option value="" disabled />
                {states.map((state, index) => (
                  <option key={index} value={state.abbr}>
                    {state.name}
                  </option>
                ))}
              </Select>
            )}
          </EnrollmentField>
          <FieldError name={model + 'drivers_license_state'} />
        </Group>
      </Group>
    </>
  );
};
