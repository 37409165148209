import * as lodash from 'lodash';
import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { ModalContext } from '../../../../context/modal';
import {
  BaseModal,
  ModalHeader,
  ModalFooter,
  PrimaryButton,
  SecondaryButton,
} from '@fattmerchantorg/truffle-components';
import { BusinessInfoFields } from './BusinessInfoFields';

import { Registration } from '@fattmerchantorg/types-omni';
import { Form } from '../../../shared';
import * as businessInfoFormUtil from './BusinessInfoForm.util';
import {
  BusinessInfoFieldsValues,
  BusinessInfoFieldsErrors,
} from './BusinessInfoForm.types';
import { Dispatch } from 'react';
import { ButtonSpinner } from '../../../shared';
import { useUnderwritingFormSubmission } from '../../context/useUnderwritingFormSubmission';

const ButtonBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  > * {
    margin: 0 0 0 ${({ theme }) => theme.space[4]}px;
  }
`;

const StyledModalContent = styled.div`
  grid-area: content;
  overflow-y: auto;
  padding: ${({ theme }) => theme.space[4]}px;
  color: ${({ theme }) => theme.component.modal.color};
`;

interface BusinessInfoModalProps {
  isOpen?: boolean;
  registration: Registration;
  merchantId: string;
  merchantDispatch: Dispatch<any>;
}

const validateForm = (
  formValues: BusinessInfoFieldsValues
): BusinessInfoFieldsErrors => {
  const errors = {
    ...businessInfoFormUtil.validateFormValues(formValues),
  } as BusinessInfoFieldsErrors;

  return errors;
};

export const BusinessInfoFormModal: React.FunctionComponent<
  BusinessInfoModalProps
> = props => {
  const { modalDispatch } = useContext(ModalContext);
  const [isModalVisible] = useState(!!props.isOpen);
  const { registration, merchantId, merchantDispatch } = props;
  const { isSaving, onSubmit } = useUnderwritingFormSubmission({
    merchantDispatch,
    merchantId,
    payloadFormatter: businessInfoFormUtil.mapFormValuesToPayload,
  });

  const handleCloseModal = () => {
    modalDispatch({
      type: 'CLOSE_MODAL',
    });
  };

  return (
    <Form
      initialValues={{
        ...businessInfoFormUtil.mapRegistrationToInitialFormValues(
          registration
        ),
      }}
      initialValuesEqual={lodash.isEqual}
      validate={formValues => {
        const validationErrors = {
          ...validateForm(formValues),
        } as BusinessInfoFieldsErrors;
        return validationErrors;
      }}
      mutators={{
        setFormValues: ([values], state, utils) => {
          Object.keys(values).forEach(key => {
            const value = values[key];
            utils.changeValue(state, key, () => value);
          });
        },
      }}
      onSubmit={onSubmit}
    >
      {formProps => (
        <BaseModal
          title="Business Info"
          isOpen={isModalVisible}
          onRequestClose={handleCloseModal}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={false}
          style={{
            overlay: { pointerEvents: 'auto' },
            content: { maxWidth: '533px' },
          }}
        >
          <ModalHeader title="Business Info" onClose={handleCloseModal} />
          <>
            <StyledModalContent>
              <BusinessInfoFields formProps={formProps} />
            </StyledModalContent>
            <ModalFooter>
              <ButtonBar>
                <SecondaryButton onClick={handleCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  onClick={() => onSubmit(formProps.values)}
                  disabled={
                    // Disable Save if there are validation errors
                    formProps.hasValidationErrors === true
                  }
                >
                  {isSaving ? <ButtonSpinner /> : `Save`}
                </PrimaryButton>
              </ButtonBar>
            </ModalFooter>
          </>
        </BaseModal>
      )}
    </Form>
  );
};
