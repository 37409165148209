import React, { FunctionComponent } from 'react';
import {
  BulkActionMenuButton,
  BulkActionMenuButtonProps,
} from './BulkActionsMenuButton';
import styled from 'styled-components';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

const Button = styled(BulkActionMenuButton)`
  width: 47px;
  padding: 8px;
  justify-content: center;
  background-color: ${props => props.theme.colors.core.gray['600'].hex};
  color: ${props => props.theme.colors.status.blue['500'].hex};
`;

export type BulkActionMenuCloseButtonProps = Omit<
  BulkActionMenuButtonProps,
  'icon'
>;

export const BulkActionMenuCloseButton: FunctionComponent<
  BulkActionMenuCloseButtonProps
> = props => {
  return <Button {...props} icon={faTimes} />;
};
