import { FormErrors } from '../../../../@types';
import { Registration } from '@fattmerchantorg/types-omni';

export const processingInfoFields = [
  'refund_policy',
  'avg_trans_size',
  'highest_trans_amount',
  'annual_gross_ach_revenue',
  'avg_ach_transaction',
  'largest_ach_transaction',
  'annual_volume',
  'card_present_percent',
  'card_not_present_percent',
  'card_swiped_percent',
  'moto_percent',
  'internet',
  'international',
  'b2b_percent',
  'annual_gross_shopping_cart_revenue',
  'avg_shopping_cart_transaction',
  'largest_shopping_cart_transaction',
  'shopping_cart_card_present_percent',
  'shopping_cart_international',
  'shopping_cart_b2b_percent',
] as const;

export type ProcessingInfoFields = typeof processingInfoFields[number];

export type ProcessingInfoFieldsValues = Pick<
  Registration,
  ProcessingInfoFields
>;

export type ProcessingInfoFieldsErrors = FormErrors<ProcessingInfoFieldsValues>;
