import React, {
  FunctionComponent,
  useContext,
  useCallback,
  useState,
} from 'react';
import styled from 'styled-components';
import { UnderwritingSection } from '../shared/UnderwritingSection';
import { Uploader } from './Uploader';
import {
  RegistrationFileRecord,
  GroupedRegistrationDocumentResults,
  Registration,
} from '@fattmerchantorg/types-omni';
import { coreapi, onboardingapi } from '../../../../api';
import { useToaster, useAuthToken, useAsyncEffect } from '../../../../hooks';
import {
  SelectedMerchantStore,
  updateSelectedMerchantRegistration,
} from '../../../../context';
import { downloadData } from '../../../../util/documents.util';
import { dataURIToBlob } from '../../../../util/file.util';
import { FileRow } from './FileRow';
import { getFileById } from '../../../../services/s3-file-service';

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FileList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;
  margin: 16px 0 0 0;
`;

export const Files: FunctionComponent = props => {
  const fileNumberLimit = 20;
  const {
    state: { merchantId },
    dispatch,
  } = useContext(SelectedMerchantStore);
  const authToken = useAuthToken();
  const { toaster, toast } = useToaster();
  const [existingFiles, setExistingFiles] = useState<RegistrationFileRecord[]>(
    []
  );
  const numberOfExistingFiles = existingFiles.length;

  useAsyncEffect(async () => {
    getRegistrationDocuments();
  }, [authToken]);

  const getRegistrationDocuments = useCallback(async () => {
    try {
      const registrationDocuments: GroupedRegistrationDocumentResults =
        await onboardingapi.get(
          authToken,
          `/merchant/${merchantId}/registration/documents`
        );

      const allFiles = [];

      // Registration documents are more than just the list of files, but that's
      // the only part we need here, so loop through and surface the files.
      Object.keys(registrationDocuments).forEach(subject => {
        if (registrationDocuments[subject]) {
          registrationDocuments[subject].forEach(doc => {
            if (doc.files.length) {
              allFiles.push(...doc.files);
            }
          });
        }
      });

      setExistingFiles(allFiles);

      const registration: Registration = await coreapi.get(
        authToken,
        `/merchant/${merchantId}/registration`
      );

      dispatch(updateSelectedMerchantRegistration(registration));
    } catch (error) {
      console.error(error);
    }
  }, [authToken, dispatch, merchantId]);

  // Changes the file.meta.onboardingMagType property
  const changeFileOnboardingMagType = async (file, type, label) => {
    const filenameArray = file.name.split('__');
    const currentFileName =
      filenameArray.length > 1 ? filenameArray.slice(1).join('') : file.name;
    const packagedFile = {
      id: file.id,
      merchant_id: merchantId,
      name: `${label}${currentFileName}`,
      meta: { ...file.meta, onboardingMagType: type },
    };

    try {
      await coreapi.put(
        authToken,
        `/merchant/${merchantId}/registration/file/${file.id}`,
        packagedFile
      );
      getRegistrationDocuments();
      toaster(toast.success('File type updated.'));
    } catch (error) {
      toaster(
        toast.error(error, 'There was a problem updating the file type.')
      );
    }
  };

  const deleteFile = useCallback(
    async (file: RegistrationFileRecord) => {
      try {
        await coreapi.delete(
          authToken,
          `/merchant/${merchantId}/registration/file/${file.id}`
        );
        getRegistrationDocuments();
        toaster(toast.success('File deleted.'));
      } catch (error) {
        toaster(toast.error(error, 'There was a problem deleting the file.'));
      }
    },
    [authToken, merchantId, toaster, toast, getRegistrationDocuments]
  );

  const downloadFile = useCallback(
    async (file: RegistrationFileRecord) => {
      try {
        const res = await onboardingapi.get(authToken, `/file/${file.id}`);
        downloadData(res, file.name, file.meta.mime);
      } catch (error) {
        toaster(
          toast.error(error, 'There was a problem downloading the file.')
        );
      } finally {
        getRegistrationDocuments();
      }
    },
    [authToken, toaster, toast, getRegistrationDocuments]
  );

  const previewFile = useCallback(
    async (file: RegistrationFileRecord) => {
      try {
        const result = await getFileById(file.id, authToken);
        const dataURI = `${file.meta.mime};base64,${result.data}`;
        // Using a blob instead of using the URI in the href property of the anchor tag
        // Chrome has a 2MB URI size limit
        const blob = dataURIToBlob(dataURI, file.meta.mime);
        const url = URL.createObjectURL(blob);
        const element = document.createElement('a');
        element.href = url;

        element.setAttribute('target', '_blank');

        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      } catch (error) {
        toaster(
          toast.error(
            error,
            'Unable to generate a preview for this file. Try downloading the file instead.'
          )
        );
      }
    },
    [authToken, toast, toaster]
  );

  const uploadFiles = useCallback(
    async (files: File[], registrationDocumentId: string | null = null) => {
      const uploads = files.map(async file => {
        try {
          const body: { [key: string]: any } = { file };

          if (registrationDocumentId) {
            body.registration_document_id = registrationDocumentId;
          }

          await coreapi.post(
            authToken,
            `/merchant/${merchantId}/registration/file?return=file`,
            body
          );
        } catch (error) {
          toaster(
            toast.error(error, 'There was a problem uploading the file.')
          );
        }
      });

      await Promise.all(uploads);

      getRegistrationDocuments();
    },
    [authToken, merchantId, toast, toaster, getRegistrationDocuments]
  );

  return (
    <UnderwritingSection
      title="Files"
      id="underwriting-files"
      isEditable={false}
    >
      <CardContent>
        {numberOfExistingFiles < fileNumberLimit ? (
          <Uploader
            onDrop={files => uploadFiles(files, null)}
            onDownload={downloadFile}
            onDelete={deleteFile}
          />
        ) : null}
        <FileList>
          {existingFiles.map(file => {
            return (
              <FileRow
                key={file.id}
                file={file}
                onDownload={downloadFile}
                onDelete={deleteFile}
                onPreview={previewFile}
                onChangeFileOnboardingMagType={changeFileOnboardingMagType}
              />
            );
          })}
        </FileList>
      </CardContent>
    </UnderwritingSection>
  );
};
