import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import {
  BaseModal,
  ModalHeader,
  Icon,
} from '@fattmerchantorg/truffle-components';
import { ModalContext } from '../../../../context';
import { ExpandablePanel } from '../../../shared';
import { FundingAccountAuditLog, Auth } from '@fattmerchantorg/types-omni';
import renderAuditLogDrilldownRecord from '../../../underwriting/components/external-verification/audit-log-drilldown';

const StyledModalContent = styled.div`
  grid-area: content;
  overflow-y: auto;
  padding: ${({ theme }) => theme.space[4]}px;
  color: ${({ theme }) => theme.component.modal.color};

  > * {
    margin: 0 0 10px;
  }
`;

const LogContent = styled.div`
  font-size: 14px;

  ul {
    padding: 0 0 0 8px;
    margin: 0 0 16px 0;
  }

  ul:empty {
    display: none;
  }

  li {
    list-style: none;
    word-wrap: break-word;
  }

  // Styles the "Potential Matches" next sections within the log content
  div > div > ul {
    padding: 8px 16px;
    border-radius: 2px;
  }

  .log-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .pass-log-item-value {
    color: ${({ theme }) => theme.colors.status.green[500].hex};
  }
`;

export interface BusinessOperationLogsData {
  Entity: FundingAccountAuditLog;
  'Bank Match': FundingAccountAuditLog;
  'Bank Open': FundingAccountAuditLog;
  TIN: FundingAccountAuditLog;
  Location: FundingAccountAuditLog;
}

export interface IndividualOperationLogsData {
  Identity: FundingAccountAuditLog;
  Email: FundingAccountAuditLog;
  IP: FundingAccountAuditLog;
  OFAC: FundingAccountAuditLog;
  SSN: FundingAccountAuditLog;
}

interface VerificationLogProps {
  isOpen?: boolean;
  auditLogOperationData:
    | BusinessOperationLogsData
    | IndividualOperationLogsData;
  authToken: Auth['token'];
}

const PassIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.core.green[500].hex};
`;

const FailIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.status.red[600].hex};
`;

export const VerificationLogsModal: React.FC<VerificationLogProps> = props => {
  const { modalDispatch } = useContext(ModalContext);
  const [isModalVisible] = useState(!!props.isOpen);
  const { auditLogOperationData } = props;

  const handleCloseModal = () => {
    modalDispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const renderStatusIcon = (status: FundingAccountAuditLog | null) => {
    if (null !== status) {
      return <PassIcon icon={['fas', 'check']} />;
    }
    if (null === status) {
      return <FailIcon icon={['fas', 'times']} />;
    }
  };

  const renderViewMoreDetailsBtn = () => {};

  const renderPanels = (
    auditLogOperationData:
      | BusinessOperationLogsData
      | IndividualOperationLogsData
  ) => {
    return Object.keys(auditLogOperationData).map((reportKey, index) => {
      const reports = auditLogOperationData[reportKey];
      if (reports.length === 0) return null;
      return (
        <ExpandablePanel
          expanded={false}
          triggerContent={reportKey}
          triggerSuffix={renderStatusIcon(reports.map(r => r.status ?? null))}
          key={index}
        >
          <LogContent>
            {reports.length > 0
              ? renderAuditLogDrilldownRecord(
                  reports.map(r => r.result),
                  renderViewMoreDetailsBtn,
                  'fundingAccounts'
                )
              : null}
          </LogContent>
        </ExpandablePanel>
      );
    });
  };

  const modalTitle = 'Verification Details';

  return (
    <BaseModal
      title={modalTitle}
      isOpen={isModalVisible}
      onRequestClose={handleCloseModal}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={false}
      style={{
        overlay: { pointerEvents: 'auto' },
        content: {
          maxWidth: '533px',
          gridTemplateRows: '48px 1fr',
          gridTemplateAreas: `
          "header"
          "content"
          `,
        },
      }}
    >
      <ModalHeader title={modalTitle} onClose={handleCloseModal} />
      <StyledModalContent data-testid="bank-account-verification-logs-modal">
        {renderPanels(auditLogOperationData)}
      </StyledModalContent>
    </BaseModal>
  );
};
