import React, { FunctionComponent, useState, useContext } from 'react';
import styled from 'styled-components';
import { Modal, ModalProps, LoadingSpan } from '../shared';

import { formatCurrency, formatWithComma } from '../../util/format.util';
import { getTimezoneName } from '../../util/date.util';
import { useAsyncEffect, useAuthToken } from '../../hooks';
import { queryapi } from '../../api';
import { SelectedMerchantStore } from '../../context';
import {
  DetailedFeeStatement,
  DetailedSettlementStatement,
  DetailedFeeStatement as Statement,
} from '@fattmerchantorg/types-omni';
import { useFeatureFlag } from '../../hooks';
import { startOfMonth, endOfMonth, format, isValid } from 'date-fns';
import { TextLink } from '@fattmerchantorg/truffle-components';
import { useHistory } from 'react-router-dom';
import FeatureFlag from '../shared/FeatureFlag/FeatureFlag';

const Container = styled.div`
  tr th:last-child {
    text-align: right;
  }
`;

/** Context for `useModalReducer` state. */
export interface ModalContext {
  feeSummary: Statement;
}
interface DetailModalProps extends ModalProps {
  activityPeriod: string | null;
  shouldUseSettlementStatement: boolean;
}

// Figma design for this table doesn't match
// truffle, create a new table design
const DetailModalTable = styled.table`
  width: 100%;
  font-size: 16px;
  border-bottom: 2px solid ${props => props.theme.colors.core.gray[600].hex};
  td {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 50%;
    text-align: left;
    padding-left: 16px;
    &:nth-child(2) {
      text-align: right;
      padding-left: 0;
      padding-right: 16px;
    }
  }
`;

type DetailModalStatementSummary = {
  netDepositsTotal: number;
  salesCount: number;
  interchangeFees: number;
  cardProcessingFees: number;
  flatRateFees: number;
  achApplicationFees: number;
  otherFees: number;
  surchargeTotal: number;
  totalFees: number;
};

export const DetailModal: FunctionComponent<DetailModalProps> = props => {
  const { shouldUseSettlementStatement } = props;

  const history = useHistory();
  const authToken = useAuthToken();
  const {
    state: { merchant: selectedMerchant },
  } = useContext(SelectedMerchantStore);
  const detailedFeeStatementsFeature = useFeatureFlag(
    'Reports',
    'DetailedStatement'
  );
  const hasDetailedFeeStatements =
    !!selectedMerchant?.is_payfac && detailedFeeStatementsFeature.available;

  const [statementSummary, setStatementSummary] =
    useState<DetailModalStatementSummary>(null);

  useAsyncEffect(async () => {
    /**
     * Most of this code is a temp fix to satisfy PHO-2190
     * We plan on making detailed fee statements in Omniconnect but
     * until then we have this summary modal. We'll just use the logic
     * for Detailed Fee Statements (from fattpay-react) here to display
     * the numbers they see in fattpay-react until we have a dedicated
     * page for the detailed statement
     */
    if (authToken && props.activityPeriod && selectedMerchant) {
      setStatementSummary(null);
      const startDate = format(
        startOfMonth(new Date(props.activityPeriod)),
        'yyyy-MM-dd'
      );
      const endDate = format(
        endOfMonth(new Date(props.activityPeriod)),
        'yyyy-MM-dd'
      );

      // ELSE IF its a payfac merchant with engine transactions, use the data from the detailed settlement statement route
      // ELSE IF its a payfac merchant use the data from the detailed statement route
      // ELSE just use the basic summary route and map response to Statement shape
      if (shouldUseSettlementStatement) {
        const response = await queryapi.get<DetailedSettlementStatement>(
          authToken,
          'statement/settlement/summary',
          { startDate, endDate }
        );
        setStatementSummary({
          netDepositsTotal: response.summary.totalNetDeposits,
          salesCount: response.dateSettlements.summary.gross_sales_count,
          interchangeFees: response.summary.interchangeFees,
          cardProcessingFees: response.summary.cardProcessingFees,
          flatRateFees: response.summary.flatRateFees,
          achApplicationFees: response.summary.achApplicationFees,
          otherFees: response.summary.otherFees,
          surchargeTotal: response.cardTransactions.summary.surcharge_total,
          totalFees: response.summary.totalFees,
        });
      } else if (hasDetailedFeeStatements) {
        const response = await queryapi.get<DetailedFeeStatement>(
          authToken,
          'statement/summary',
          {
            timeZone: selectedMerchant?.options?.timezone || getTimezoneName(),
            startDate,
            endDate,
          }
        );
        setStatementSummary({
          netDepositsTotal: response.summary.totalNetDeposits,
          salesCount: response.dateTransactions.summary.gross_sales_count,
          interchangeFees: response.summary.interchangeFees,
          cardProcessingFees: response.summary.cardProcessingFees,
          flatRateFees: response.summary.flatRateFees,
          achApplicationFees: response.summary.achApplicationFees,
          otherFees: response.summary.otherFees,
          surchargeTotal: response.cardTransactions.summary.surcharge_total,
          totalFees: response.summary.totalFees,
        });
      } else {
        const feeSummaryData = await queryapi.get(
          authToken,
          `/fee/summary/${props.activityPeriod}`
        );
        setStatementSummary({
          netDepositsTotal: feeSummaryData.volume,
          salesCount: feeSummaryData.transactions,
          interchangeFees: feeSummaryData.has_detailed_fees
            ? feeSummaryData.interchange_fees_total
            : feeSummaryData.interchange_and_fees,
          cardProcessingFees: feeSummaryData.fixed_fees_total,
          flatRateFees: feeSummaryData.card_brand_assessments,
          achApplicationFees: 0,
          otherFees: feeSummaryData.other_fees_total,
          surchargeTotal: 0,
          totalFees: feeSummaryData.has_detailed_fees
            ? +feeSummaryData.card_brand_assessments +
              +feeSummaryData.interchange_fees_total +
              +feeSummaryData.fixed_fees_total +
              +feeSummaryData.other_fees_total
            : feeSummaryData.transaction_fees,
        });
      }
    }
  }, [authToken, props.activityPeriod, selectedMerchant]);

  const activityPeriod: Date = new Date(props.activityPeriod);
  const isLoading = !statementSummary;
  const isSurcharge =
    !!selectedMerchant?.is_surcharge_enabled ||
    statementSummary?.surchargeTotal > 0;
  const isAch = selectedMerchant?.gateways?.some(g =>
    ['ACH', 'TEST'].includes(g.type)
  );

  return (
    <Modal
      {...props}
      data-testid="fee-statement-detail-modal"
      title={<strong>Summary</strong>}
    >
      <Container>
        <DetailModalTable>
          <tbody>
            <tr>
              <td style={{ fontWeight: 'bold' }}>
                {isValid(activityPeriod)
                  ? format(activityPeriod, 'MMM yyyy')
                  : ''}
              </td>
              <td>
                {hasDetailedFeeStatements && (
                  <FeatureFlag
                    category="OmniConnect"
                    feature="AllowViewStatements"
                  >
                    <TextLink
                      style={{ fontWeight: 'bold' }}
                      data-testid="view-statement-link"
                      onClick={() =>
                        history.push(
                          shouldUseSettlementStatement
                            ? `/merchant/${selectedMerchant?.id}/detailedsettlementstatements/${props.activityPeriod}`
                            : `/merchant/${selectedMerchant?.id}/detailedfeestatements/${props.activityPeriod}`
                        )
                      }
                    >
                      View Statement
                    </TextLink>
                  </FeatureFlag>
                )}
              </td>
            </tr>
          </tbody>
        </DetailModalTable>
        <DetailModalTable>
          <tbody>
            <tr>
              <td>Volume</td>
              <td>
                {isLoading ? (
                  <LoadingSpan style={{ marginLeft: 'auto' }} />
                ) : (
                  formatCurrency(statementSummary?.netDepositsTotal || 0)
                )}
              </td>
            </tr>
            <tr>
              <td>Monthly Transactions</td>
              <td>
                {isLoading ? (
                  <LoadingSpan style={{ marginLeft: 'auto' }} />
                ) : (
                  formatWithComma(statementSummary?.salesCount || 0)
                )}
              </td>
            </tr>
          </tbody>
        </DetailModalTable>

        {isLoading ? null : hasDetailedFeeStatements ? (
          <DetailModalTable data-testid="fee-statement-modal-detailed">
            <tbody>
              <tr>
                <td style={{ fontWeight: 'bold' }}>Fees</td>
                <td></td>
              </tr>
              <tr>
                <td>Interchange</td>
                <td>
                  {formatCurrency(statementSummary?.interchangeFees || 0)}
                </td>
              </tr>
              <tr>
                <td>Card Processing</td>
                <td>
                  {formatCurrency(statementSummary?.cardProcessingFees || 0)}
                </td>
              </tr>
              <tr>
                <td>Flat Rate</td>
                <td>{formatCurrency(statementSummary?.flatRateFees || 0)}</td>
              </tr>
              {isAch && (
                <tr>
                  <td>ACH</td>
                  <td>
                    {formatCurrency(statementSummary?.achApplicationFees || 0)}
                  </td>
                </tr>
              )}
              <tr>
                <td>Other</td>
                <td>{formatCurrency(statementSummary?.otherFees || 0)}</td>
              </tr>
              {isSurcharge && (
                <tr>
                  <td>Surcharge Total</td>
                  <td>
                    {formatCurrency(statementSummary?.surchargeTotal || 0)}
                  </td>
                </tr>
              )}
              <tr>
                <td style={{ fontWeight: 'bold' }}>Total Fees</td>
                <td style={{ fontWeight: 'bold' }}>
                  {formatCurrency(statementSummary?.totalFees || 0)}
                </td>
              </tr>
            </tbody>
          </DetailModalTable>
        ) : (
          <DetailModalTable data-testid="fee-statement-modal-basic">
            <tbody>
              <tr>
                <td>Interchange Fees and Assessments</td>
                <td>
                  {formatCurrency(statementSummary?.interchangeFees || 0)}
                </td>
              </tr>
              <tr>
                <td>Transaction Fees</td>
                <td>{formatCurrency(statementSummary?.totalFees || 0)}</td>
              </tr>
            </tbody>
          </DetailModalTable>
        )}
      </Container>
    </Modal>
  );
};
