function s4(): string {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

export function generatePassword(): string {
  const getRandomElement = <T>(arr: T[]) =>
    arr[Math.floor(Math.random() * arr.length)];
  const symbols = '!@#$%^&*()_'.split('');
  const letters = 'abcdefghijklmnopqrstuvwxyz'.split('');
  const randomSymbol = getRandomElement(symbols);
  const randomLowercase = getRandomElement(letters);
  const randomUppercase = getRandomElement(letters).toUpperCase();
  const password = `${s4()}${s4()}${s4()}${s4()}${randomLowercase}${randomUppercase}${randomSymbol}`;
  return password;
}
