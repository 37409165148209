import {
  FinancialFieldsValues,
  FinancialFieldsErrors,
} from './FinancialFields.types';
import {
  Registration,
  RegistrationRepresentative as Representative,
  Merchant,
} from '@fattmerchantorg/types-omni';
import { isEmpty } from '../../../../util/object.util';
import {
  mapSignerToRepresentative,
  mapRepresentativeToSigner,
} from '../../../../util/registration.util';
import { Mutator } from 'final-form';
import { isAddressPoBox } from '../../../../util/validator.util';

export function toNumber(value: string): number {
  return isNaN(+value) ? 0 : +value;
}

export function mapFormValuesToPayload(
  formValues: FinancialFieldsValues
): Partial<Registration> {
  const signer = mapRepresentativeToSigner(formValues);

  return {
    ...signer,

    job_title: signer.title,
    principal_owners_name: `${formValues.first_name} ${formValues.last_name}`,
    bank_account_owner_name: formValues.bank_account_owner_name,
    bank_routing_number: formValues.bank_routing_number,
    bank_account_number: formValues.bank_account_number,
    secondary_bank_account_owner_name:
      formValues.secondary_bank_account_owner_name,
    secondary_bank_routing_number: formValues.secondary_bank_routing_number,
    secondary_bank_account_number: formValues.secondary_bank_account_number,

    secondary_bank_account_purpose: formValues.secondary_bank_account_purpose,

    meta: {
      // TODO: remove "as any" cast once/if we remove "| string | null" from type
      ...(signer.meta as any),
      representatives: formValues.representatives,
    },
  };
}

export function mapRegistrationToInitialFormValues(
  registration: Registration
): FinancialFieldsValues {
  let initialValues: Partial<FinancialFieldsValues> = {};

  if (!isEmpty(registration)) {
    const representative = mapSignerToRepresentative(registration);
    initialValues = {
      ...representative,
      address_country: representative.address_country || 'USA',
      bank_account_owner_name:
        registration.bank_account_owner_name ||
        `${representative.first_name || ''} ${
          representative.last_name || ''
        }`.trim(),
      bank_routing_number: registration.bank_routing_number,
      bank_account_number: registration.bank_account_number,
      secondary_bank_account_owner_name:
        registration.secondary_bank_account_owner_name,
      secondary_bank_routing_number: registration.secondary_bank_routing_number,
      secondary_bank_account_number: registration.secondary_bank_account_number,
      secondary_bank_account_purpose:
        registration.secondary_bank_account_purpose,
      ownership_percentage: representative.ownership_percentage || '100',
      representatives:
        // TODO: remove "as any" cast once/if we remove "| string | null" from type
        (registration.meta && (registration.meta as any).representatives) || [],
    };
  }

  return initialValues as FinancialFieldsValues;
}

export function mapFormValuesToTeamPayload(
  formValues: FinancialFieldsValues
): Partial<Merchant> {
  return {
    // prettier-ignore
    contact_name: `${formValues.first_name || ''} ${formValues.last_name ||''}`.trim(),
  };
}

export function validateRequiredFormValues(
  values: FinancialFieldsValues
): FinancialFieldsErrors {
  const errors: FinancialFieldsErrors = {};

  const requiredSignerFields: (keyof Representative)[] = [
    'type',
    'title',
    'first_name',
    'last_name',
    'ownership_percentage',
    // 'email',
    'date_of_birth',
    'ssn',
    'phone',
    'address_1',
    'address_city',
    'address_state',
    'address_zip',
  ];

  const representatives = values.representatives || [];

  representatives.forEach((representative, i) => {
    requiredSignerFields.forEach(requiredField => {
      if (!representative[requiredField]) {
        if (!errors.representatives) errors.representatives = [];
        if (!errors.representatives[i]) errors.representatives[i] = {} as any;
        errors.representatives[i][requiredField] = 'Required';
      }
    });
  });

  const requiredOtherFields: string[] = [
    // remove 'type' since it's not required for the principal signer, only the addl reps
    ...requiredSignerFields.slice(1, requiredSignerFields.length),
    'bank_account_owner_name',
    'bank_routing_number',
    'bank_account_number',
  ];

  requiredOtherFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
}

export function validateFormValues(
  values: FinancialFieldsValues
): FinancialFieldsErrors {
  const errors: FinancialFieldsErrors = {};

  const requiredSignerFields: (keyof Representative)[] = [
    'type',
    'title',
    'first_name',
    'last_name',
    'ownership_percentage',
    // 'email',
    'date_of_birth',
    'ssn',
    'phone',
    'address_1',
    'address_city',
    'address_state',
    'address_zip',
  ];

  const validateTheseFields: string[] = [
    ...requiredSignerFields,
    'bank_routing_number',
    'secondary_bank_routing_number',
  ];

  const representatives = values.representatives || [];

  const totalOwnershipPercentage = representatives.reduce(
    (sum, next) => sum + toNumber(String(next.ownership_percentage)),
    toNumber(String(values.ownership_percentage))
  );

  representatives.forEach((representative, i) => {
    validateTheseFields.forEach(requiredField => {
      let error;
      const value = representative[requiredField];

      if (requiredField === 'ownership_percentage') {
        if (isNaN(+value)) {
          error = 'Must be a number';
        } else if (+value > 100) {
          error = 'Must not be over 100%';
        } else if (totalOwnershipPercentage > 100) {
          /**
           * @see https://fattmerchant.atlassian.net/browse/KRK-1435
           * removing this validation for now
           * see this thread for more information: https://fattmerchant.slack.com/archives/CH0PJRQFN/p1616714706128900
           */
          // error = 'Total % must not be over 100%';
        }
      }

      // if (requiredField === 'bank_routing_number' && value) {
      //   if (isNaN(+value)) {
      //     error = 'Must be a number';
      //   } else if (value.legth !== 9) {
      //     error = 'Must be 9 digits';
      //   }
      // }

      // if (requiredField === 'secondary_bank_routing_number' && value) {
      //   if (isNaN(+value)) {
      //     error = 'Must be a number';
      //   } else if (value.legth !== 9) {
      //     error = 'Must be 9 digits';
      //   }
      // }

      if (error) {
        if (!errors.representatives) errors.representatives = [];
        if (!errors.representatives[i]) errors.representatives[i] = {} as any;

        errors.representatives[i][requiredField] = error;
      }
    });

    if (representative.address_1) {
      if (isAddressPoBox(representative.address_1)) {
        errors.representatives[i].address_1 = 'Address cannot be a PO box';
      }
    }

    if (representative.address_2) {
      if (isAddressPoBox(representative.address_2)) {
        errors.representatives[i].address_2 = 'Address cannot be a PO box';
      }
    }
  });

  if (isNaN(+values.ownership_percentage)) {
    errors.ownership_percentage = 'Must be a number';
  } else if (+values.ownership_percentage > 100) {
    errors.ownership_percentage = 'Must be 100% or less';
  }

  if (values.bank_routing_number) {
    if (isNaN(+values.bank_routing_number)) {
      errors.bank_routing_number = 'Must be a number';
    } else if (+values.bank_routing_number.length !== 9) {
      errors.bank_routing_number = 'Must be 9 digits';
    }
  }

  if (values.secondary_bank_routing_number) {
    if (isNaN(+values.secondary_bank_routing_number)) {
      errors.secondary_bank_routing_number = 'Must be a number';
    } else if (+values.secondary_bank_routing_number.length !== 9) {
      errors.secondary_bank_routing_number = 'Must be 9 digits';
    }
  }

  if (values.address_1) {
    if (isAddressPoBox(values.address_1)) {
      errors.address_1 = 'Address cannot be a PO box';
    }
  }

  if (values.address_2) {
    if (isAddressPoBox(values.address_2)) {
      errors.address_2 = 'Address cannot be a PO box';
    }
  }

  return errors;
}

export const formMutators: { [key: string]: Mutator<FinancialFieldsValues> } = {
  setCalculatedSignerOwnershipPercentage: (_, state, utils) => {
    /**
     * @see https://fattmerchant.atlassian.net/browse/KRK-1435
     * removing this fn for now
     * see this thread for more information: https://fattmerchant.slack.com/archives/CH0PJRQFN/p1616714706128900
     */
    // utils.changeValue(state, 'ownership_percentage', _ => {
    //   const values = state.formState.values as FinancialFieldsValues;
    //   const representatives = values.representatives || [];
    //   const signerOwnershipPercentage = representatives.reduce(
    //     (sum, next) => sum - toNumber(String(next.ownership_percentage)),
    //     100
    //   );
    //   return signerOwnershipPercentage;
    // });
  },
  addAdditionalRepresentative: (_, state, utils) => {
    utils.changeValue(state, 'representatives', representatives => [
      ...representatives,
      mapSignerToRepresentative({}), // quick way to get a new empty rep
    ]);
  },
  removeAdditionalRepresentative: ([index], state, utils) => {
    utils.changeValue(state, 'representatives', representatives => {
      const newRepresentatives = [...representatives];
      newRepresentatives.splice(index, 1);
      return newRepresentatives;
    });
  },
};
