import React, { FunctionComponent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { originUrl } from '../../util/api.util';
import { FullPageLoader } from '../shared';

export const LoginPage: FunctionComponent = () => {
  const { search } = useLocation();

  useEffect(() => {
    const redirect = encodeURIComponent(originUrl(`sso${search}`));
    const url = `${process.env.REACT_APP_AUTH_API_URL}/auth0/login?redirect=${redirect}`;
    window.location.href = url;
  }, [search]);

  return <FullPageLoader />;
};
