import React, { FunctionComponent, useContext } from 'react';
import {
  useHistory,
  useLocation,
  NavLink as NavLinkRRD,
} from 'react-router-dom';
import { Nav, Navbar, NavItem, NavLink } from 'reactstrap';
import styled from 'styled-components';
import { SelectedMerchantStore } from '../../../context';
import { LeafRouteItem } from '../../routes/models/RouteItem.model';
import { usePermissions } from '../../../hooks';
import { COLLAPSED_SIDE_BAR_WIDTH } from './Sidebar';
import { stringify } from '../../../util/api.util';
import {
  formatDate,
  startOfDay,
  daysAgo,
  endOfDay,
} from '../../../util/date.util';

export const CHILD_NAVBAR_WIDTH = '130px';

const StyledChildNavbar = styled(Navbar)`
  position: fixed;
  margin-left: ${COLLAPSED_SIDE_BAR_WIDTH}px;

  height: 100vh;
  min-height: 100%;
  background: ${({ theme }) => theme.colors.core.gray[700].hex};
  left: 0;
  z-index: 8;
  flex-direction: column;
  transition: left 0.2s ease-in-out;
  width: ${CHILD_NAVBAR_WIDTH};

  display: flex;
  justify-content: start;

  &.navbar {
    padding: 0;
  }
`;

export const StyledChildNav = styled(Nav)`
  overflow-y: scroll;
  width: 100%;
  flex: 1;

  .nav-item:last-child:not(:only-child) {
    padding-bottom: 1rem;
  }
  .nav-link.active {
    background: ${({ theme }) => theme.colors.core.gray[600].hex} !important;
  }
`;

const StyledChildTitle = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.core.gray[600].hex};
  padding-top: 1rem;
  padding-bottom: 8px;
  margin-bottom: 8px;
  font-weight: bold;
  text-align: center;
`;

export const StyledChildNavLink = styled(NavLink)`
  padding: 15px 0px;
  border-left: 4px solid ${({ theme }) => theme.colors.core.gray[700].hex};
  :hover {
    background: ${({ theme }) => theme.colors.core.gray[800].hex};
  }
`;

export const StyledChildLabel = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`;

export const ChildNavbar: FunctionComponent<{
  title?: string;
  routes: LeafRouteItem[];
}> = ({ title, routes }) => {
  const {
    state: { selectedBrandSwitcherOption, merchant },
  } = useContext(SelectedMerchantStore);
  const { permit } = usePermissions();
  const history = useHistory();
  const location = useLocation();

  const hasSelectedBrand =
    !!selectedBrandSwitcherOption &&
    !selectedBrandSwitcherOption?.includes(',');

  function locationHasRedirectPath() {
    return (
      location.pathname.includes('/merchant') &&
      (location.pathname.includes('/settlements') ||
        location.pathname.includes('/businessinfo'))
    );
  }

  async function redirectIfMerchantSettlements(route: string, history: any) {
    if (!route) {
      return;
    }
    if (route.includes('settlements') && route.includes('keyword')) {
      const settlementsRoute =
        '/settlements?' +
        stringify({
          startDate: formatDate(startOfDay(daysAgo(180)), 'yyyy-MM-dd'),
          endDate: formatDate(endOfDay(new Date()), 'yyyy-MM-dd'),
          approvalState: ['ALL'],
          page: 1,
          keyword: merchant.id,
        });
      new Promise(resolve => {
        const loop = () =>
          locationHasRedirectPath() ? resolve(true) : setTimeout(loop);
        loop();
      }).then(() => {
        history.push(settlementsRoute);
      });
    }
  }

  return (
    <StyledChildNavbar
      className="navbar-vertical fixed-left navbar-light -print-hide"
      id="child-nav-bar"
    >
      {title ? <StyledChildTitle>{title}</StyledChildTitle> : null}
      <StyledChildNav navbar>
        {routes
          .filter(route => {
            let isVisible = !route.hidden;

            if (Array.isArray(route.permitParams)) {
              // If page is guarded by permission, and user doesn't have permission
              // hide the corresponding sidebar navlink.
              isVisible = isVisible && permit(...route.permitParams);
            }

            if (route.requiresSelectedBrand && !hasSelectedBrand)
              isVisible = false;
            return isVisible;
          })
          .map((route, i) => {
            if (route.render)
              return <React.Fragment key={i}>{route.render()}</React.Fragment>;
            return (
              <NavItem key={i}>
                <StyledChildNavLink
                  onClick={e => {
                    redirectIfMerchantSettlements(
                      route.path as string,
                      history
                    );
                  }}
                  to={route.to ?? route.path}
                  tag={NavLinkRRD}
                  data-testid={route.name}
                >
                  <StyledChildLabel>{route.name}</StyledChildLabel>
                </StyledChildNavLink>
              </NavItem>
            );
          })}
      </StyledChildNav>
    </StyledChildNavbar>
  );
};
