import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Customer } from '@fattmerchantorg/types-omni';
import { LoadingSpan } from '../../shared';
import { formatPhoneNumber } from '../../../util/format.util';
import {
  Icon as TruffleIcon,
  Text,
  TextLink as UTextLink,
} from '@fattmerchantorg/truffle-components';

const Container = styled.div`
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;

  a {
    font-weight: normal;
  }
`;

const Row = styled.div`
  padding-top: 8px;
  display: flex;
  align-items: baseline;

  > *:last-child {
    margin-left: 12px;
  }
`;

const MultilineTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PhoneTextLink: typeof UTextLink = styled(UTextLink)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const EmailTextLink: typeof UTextLink = styled(UTextLink)`
  overflow: hidden;
  text-overflow: ellipsis;

  div {
    word-break: break-all;
  }
`;

const Icon = styled(TruffleIcon)`
  align-items: top;
`;

const AddressLine: FunctionComponent = props => {
  let children = props.children;

  if (Array.isArray(props.children)) {
    // remove any empty values
    // then join with a ", "
    // e.g.
    // ["618 E South St", "Suite 510"] -> 618 E South St, Suite 510
    // ["100 S Orange Ave"] -> 100 S Orange Ave
    children = (props.children as string[])
      .filter(c => !!c && c !== ' ')
      .join(', ');
  }

  return <Text as="span">{children}</Text>;
};

export const CustomerInfoWidget: FunctionComponent<{
  customer: Customer;
  loading?: boolean;
  rows?: ('name' | 'company' | 'address' | 'contact')[];
}> = props => {
  const { customer, loading, rows } = props;

  if (!loading && !customer) return null;

  return (
    <Container>
      {rows.includes('name') ? (
        <Row>
          <Icon icon={['fas', 'user']} fixedWidth />
          {loading ? (
            <LoadingSpan height="20px" width="120px" />
          ) : (
            <Text as="span">
              {`${customer?.firstname} ${customer?.lastname}`.trim() || '--'}
            </Text>
          )}
        </Row>
      ) : null}
      {rows.includes('company') ? (
        <Row>
          <Icon icon={['fas', 'store']} fixedWidth />
          {loading ? (
            <LoadingSpan height="20px" width="120px" />
          ) : (
            <Text as="span">{customer?.company || '--'}</Text>
          )}
        </Row>
      ) : null}
      {rows.includes('address') ? (
        <Row>
          <Icon icon={['fas', 'map-marker-alt']} fixedWidth />
          {loading ? (
            <MultilineTextContainer>
              <LoadingSpan height="56px" width="120px" />
            </MultilineTextContainer>
          ) : (
            <MultilineTextContainer>
              {!customer?.address_1 &&
              !customer?.address_2 &&
              !customer?.address_city &&
              !customer?.address_state &&
              !customer?.address_zip ? (
                <AddressLine>{`--`}</AddressLine>
              ) : (
                <>
                  {(customer?.address_1 || customer?.address_2) && (
                    <AddressLine>
                      {customer?.address_1} {customer?.address_2}
                    </AddressLine>
                  )}
                  {(customer?.address_city || customer?.address_state) && (
                    <AddressLine>
                      {customer?.address_city} {customer?.address_state}
                    </AddressLine>
                  )}
                  {customer?.address_zip && (
                    <AddressLine>{customer?.address_zip || `--`}</AddressLine>
                  )}
                </>
              )}
            </MultilineTextContainer>
          )}
        </Row>
      ) : null}
      {rows.includes('contact') ? (
        <Row>
          <Row style={{ flex: '30%', paddingTop: '0' }}>
            <Icon icon={['fas', 'phone']} fixedWidth />
            {loading ? (
              <LoadingSpan height="24px" width="110px" />
            ) : customer?.phone ? (
              <PhoneTextLink href={`tel:${formatPhoneNumber(customer?.phone)}`}>
                {formatPhoneNumber(customer?.phone)}
              </PhoneTextLink>
            ) : (
              <span>--</span>
            )}
          </Row>
          <Row style={{ flex: '1 1 auto', paddingTop: '0' }}>
            <Icon icon={['fas', 'envelope']} fixedWidth />
            {loading ? (
              <LoadingSpan height="24px" width="200px" />
            ) : customer?.email ? (
              <EmailTextLink href={`mailto:${customer?.email}`}>
                <div>{customer?.email}</div>
              </EmailTextLink>
            ) : (
              <span>--</span>
            )}
          </Row>
        </Row>
      ) : null}
    </Container>
  );
};

CustomerInfoWidget.defaultProps = {
  rows: ['name', 'company', 'address', 'contact'],
};
