import React, { FunctionComponent, useContext } from 'react';
import styled, { withTheme } from 'styled-components';
import { Widget } from '../../shared/Widget';
import { Tooltip } from '@fattmerchantorg/truffle-components';
import { useHistory } from 'react-router-dom';
import { SelectedMerchantStore } from '../../../context';
import { FundingAccount } from '@fattmerchantorg/types-omni';

const Container = styled(Widget)`
  background: white;
`;

const Heading = styled.h2`
  flex: 1;
  color: ${({ theme }) => theme.colors.core.gray[800].hex};
`;

const Content = withTheme(
  styled.div`
    color: #062333;
    display: flex;
    flex-direction: column;
    margin: 1rem;

    h2,
    h3 {
      margin: 0;
    }

    div {
      font-weight: 700;
      font-size: 12px;
      color: #32325d;
    }

    > div {
      flex: 50%;
      display: flex;
      flex-direction: row;

      &:first-child {
        margin-top: 0.5rem;
      }
    }
  `
);

const AccountNum = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: #062333;
`;

type BankWidgetProps = React.HTMLAttributes<HTMLDivElement> & {
  title?: string;
  subtitle?: string;
  titleIcon?: string;
  tooltip?: string;
  primaryFundingAccount: FundingAccount | null;
};

export const BankAccountWidget: FunctionComponent<BankWidgetProps> = props => {
  const { title = '', tooltip = '', titleIcon, primaryFundingAccount } = props;
  const { state } = useContext(SelectedMerchantStore);
  const history = useHistory();
  const { merchantId } = state;

  return (
    <Container data-testid={`data_widget_${title.replace(/\s/g, '_')}`}>
      <Content>
        <div
          onClick={() =>
            history.push(`/merchant/${merchantId}/funding-accounts`)
          }
        >
          {title ? (
            typeof title === 'string' ? (
              <Heading>
                {title}{' '}
                {tooltip ? (
                  <Tooltip content={tooltip}>
                    <i className="fas fa-info-circle" />
                  </Tooltip>
                ) : null}
              </Heading>
            ) : (
              title
            )
          ) : null}

          {titleIcon || title ? (
            <h2>
              {tooltip ? (
                <Tooltip content={tooltip}>
                  {titleIcon ? (
                    <i className={titleIcon} aria-hidden="true"></i>
                  ) : null}
                </Tooltip>
              ) : (
                <i
                  className={titleIcon}
                  aria-hidden="true"
                  style={{ color: '#009BF2' }}
                />
              )}
            </h2>
          ) : null}
        </div>
        <AccountNum>
          Primary Account - {primaryFundingAccount?.bank_name} **
          {primaryFundingAccount?.last_four}
        </AccountNum>
      </Content>
    </Container>
  );
};
