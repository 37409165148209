import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  color: white;

  padding: 0px 16px;
  font-size: 18px;
  line-height: 69px;

  border-right: 1px solid ${props => props.theme.colors.core.gray[600].hex};
`;

export const BulkActionMenuTitle: FunctionComponent = props => {
  return <Container {...props} />;
};
