import React, {
  FunctionComponent,
  useEffect,
  useState,
  useCallback,
} from 'react';
import styled from 'styled-components';
import { HelloSignModalProps } from './HellosignModal.types';
import {
  useToaster,
  usePrevious,
  useRouteMatchMerchantId,
} from '../../../hooks';
import { Modal, FullPageLoader } from '..';
import { createNewHelloSignClient } from './HellosignClient';

const Container = styled.div`
  iframe {
    height: 84vh;
  }
`;

export const HelloSignModal: FunctionComponent<HelloSignModalProps> = props => {
  const {
    containerId,
    getSignatureUrl,
    onSign,
    onClose,
    isOpen,
    ...modalProps
  } = props;
  const wasOpen = usePrevious(isOpen);
  const { toast, toaster } = useToaster();
  const [isLoading, setIsLoading] = useState(true);
  const [isCloseButtonVisible, setIsCloseButtonVisible] = useState(true);
  const merchantId = useRouteMatchMerchantId();

  const setupHelloSignClient = useCallback(async () => {
    setIsLoading(true);
    setIsCloseButtonVisible(true);
    const signatureUrl = await getSignatureUrl();
    if (!signatureUrl) {
      return;
    }
    try {
      createNewHelloSignClient(merchantId, signatureUrl, onSign, onClose);
      setIsCloseButtonVisible(false);
    } catch (error) {
      toaster(toast.error(error, 'There was a problem loading the form'));
      // if there some problem loading the form, show the close button just in case
      setIsCloseButtonVisible(true);
    } finally {
      setIsLoading(false);
    }
  }, [onSign, onClose, merchantId, toast, toaster, getSignatureUrl]);

  useEffect(() => {
    if (isOpen && !wasOpen) setupHelloSignClient();
  }, [isOpen, wasOpen, setupHelloSignClient]);

  return (
    <Modal
      {...modalProps}
      isOpen={isOpen}
      width="900px"
      isClosable={isCloseButtonVisible}
      onClose={() => {
        const container = document.getElementById(containerId);
        if (container) {
          while (container.firstChild) {
            container.firstChild.remove();
          }
        }
        onClose();
      }}
      data-testid="hellosign-modal"
      id="hellosign-modal"
    >
      {props.isOpen && (
        <>
          {isLoading && <FullPageLoader />}
          <Container data-testid="hellosign-form" id={containerId} />
        </>
      )}
    </Modal>
  );
};
