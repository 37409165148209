import React, { useMemo } from 'react';
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableTools,
  TableWrapper,
  Pagination,
} from '@fattmerchantorg/truffle-components';
import { CollectionQuery, LoadingSpan } from '../shared';
import styled from 'styled-components';

interface DataTableProps {
  data: {
    columns: {
      Header: string;
      accessor: string;
    }[];
    rows: Record<string, unknown>[];
  };
  pageSizeOptions: {
    value: number;
    label: string;
  }[];
  count: number;
  total: number;
  currentPage: number;
  lastPage: number;
  defaultPageSize: number;
  loading: boolean;
  handleQueryChange: (query: CollectionQuery) => void;
  onRowsChanged?: (rows: Record<string, unknown>[]) => void;
}

export const MenuCell = styled(TableCell)`
  display: flex;
`;

export const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  ${MenuCell} {
    > button {
      margin: auto 15px;
      visibility: hidden;
    }
  }

  &:hover {
    ${MenuCell} {
      > button {
        visibility: visible;
      }
    }
  }
`;

export const JournalDataTable: React.FC<DataTableProps> = props => {
  const {
    data,
    pageSizeOptions,
    count,
    total,
    handleQueryChange,
    loading,
    currentPage,
    lastPage,
    defaultPageSize,
  } = props;

  // Row and column data for table
  const rowData = useMemo(() => data.rows, [data.rows]);
  const columns = useMemo(() => data.columns, [data.columns]);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data: rowData,
      defaultColumn: {
        width: 0,
      },
      manualPagination: true,
      manualSortBy: true,
      manualFilters: true,
      manualGlobalFilter: true,
      pageCount: count,
      initialState: {
        sortBy: [
          {
            id: 'created_at',
            desc: true,
          },
        ],
        pageSize: defaultPageSize,
        pageIndex: currentPage,
      },
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  return (
    <>
      <TableWrapper>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, index) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column: any, index) => (
                  <TableHeaderCell style={column.style} key={index}>
                    {column.render('Header')}
                  </TableHeaderCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <StyledTableRow {...row.getRowProps()} key={index}>
                  {row.cells.map((cell: any, index) => {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        key={index}
                        style={cell.column.style}
                      >
                        {loading ? (
                          <LoadingSpan width="75%" />
                        ) : (
                          cell.render('Cell')
                        )}
                      </TableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
        <TableTools>
          <Pagination
            onNextPage={() => {
              nextPage();
              handleQueryChange({ page: currentPage + 1 });
            }}
            onPreviousPage={() => {
              previousPage();
              handleQueryChange({ page: currentPage - 1 });
            }}
            onSkipToEnd={() => {
              gotoPage(count - 1);
              handleQueryChange({ page: count });
            }}
            onSkipToBeginning={() => {
              gotoPage(0);
              handleQueryChange({ page: 1 });
            }}
            onGoToPage={(page: number) => {
              gotoPage(page);
              handleQueryChange({ page: page + 1 });
            }}
            totalPages={count}
            totalRecords={total}
            recordsPerPage={pageSize}
            onSetPageSize={size => {
              setPageSize(size);
              handleQueryChange({ perPage: size });
            }}
            currentPage={currentPage - 1}
            pageSizeOptions={pageSizeOptions}
            canNextPage={currentPage === lastPage ? false : true}
            canPreviousPage={currentPage === 1 ? false : true}
          />
        </TableTools>
      </TableWrapper>
    </>
  );
};
