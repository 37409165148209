import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ResponsiveMarginProps } from './ResponsiveMargin.types';

export const StyledMargin = styled.div`
  @media (min-width: 1000px) {
    margin: ${(props: ResponsiveMarginProps) => props.desktop};
  }

  @media (max-width: 1000px) {
    margin: ${(props: ResponsiveMarginProps) => props.mobile};
  }
`;

export const ResponsiveMargin: FunctionComponent<
  ResponsiveMarginProps
> = props => {
  return <StyledMargin {...props} />;
};
