import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { ModalContext, SelectedMerchantStore } from '../../../../context';
import { formatCurrency, formatPercent } from '../../../../util';
import { valueOrHyphen } from '../../../../util/string.util';
import { UnderwritingSection } from '../shared/UnderwritingSection';
import { FieldGroupTitle } from '../shared/UnderwritingSection';
import { PricingInfoFormModal } from './PricingInfoFormModal';

const DivRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 0 16px;
  border-bottom: 0px solid ${({ theme }) => theme.lightNeutralOutline};
`;

const SectionWrap = styled.div`
  padding: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Div50 = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${DivRow}:last-of-type {
    padding: 0;
  }
`;

const DivContent = styled.div`
  width: 100%;
  display: block;
  font-size: 14px;
  p {
    font-weight: 400;
  }

  ${FieldGroupTitle} {
    margin: 0 0 8px;
  }

  ${SectionWrap}:last-of-type {
  }
`;

const DivRightSeparator = styled.div`
  border-right: 1px solid ${({ theme }) => theme.lightNeutralOutline};
  margin: 0 8px;
`;

const DataField = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export interface PricingInfoProps {}

const toMoney = val => formatCurrency(val);
const percentageOrHyphen = val =>
  valueOrHyphen(val ? formatPercent(val) : null);

export function PricingInfo(props: PricingInfoProps) {
  const {
    dispatch,
    state: { merchantId, registration },
  } = React.useContext(SelectedMerchantStore);
  const { modalDispatch } = useContext(ModalContext);
  const [regState, setRegState] = useState(registration);
  const [mId, setmId] = useState(merchantId);

  useEffect(() => {
    setRegState(registration);
    setmId(merchantId);
  }, [registration, merchantId]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        component: PricingInfoFormModal,
        props: {
          isOpen: true,
          registration: regState,
          merchantId: mId,
          merchantDispatch: dispatch,
        },
      },
    });
  };

  return (
    <UnderwritingSection
      title="Pricing Info"
      id="underwriting-pricing-info"
      isEditable={true}
      onEdit={handleClick}
    >
      {({ setToolTipContent }) => {
        setToolTipContent('Pricing info');
        return (
          <DivContent>
            <SectionWrap>
              <Div50>
                <FieldGroupTitle>Transaction Amount</FieldGroupTitle>
                <DataField>
                  <p>Credit Qual</p>
                  <div>
                    {valueOrHyphen(
                      toMoney(registration?.credit_qual_trans_fee)
                    )}
                  </div>
                </DataField>
                <DataField>
                  <p>Credit Mid</p>
                  <div>
                    {valueOrHyphen(toMoney(registration?.credit_mid_trans_fee))}
                  </div>
                </DataField>
                <DataField>
                  <p>Credit Non</p>
                  <div>
                    {valueOrHyphen(toMoney(registration?.credit_non_trans_fee))}
                  </div>
                </DataField>
                <DataField>
                  <p>Amex Qual</p>
                  <div>
                    {valueOrHyphen(toMoney(registration?.amex_qual_trans_fee))}
                  </div>
                </DataField>
                <DataField>
                  <p>Amex Mid</p>
                  <div>
                    {valueOrHyphen(toMoney(registration?.amex_mid_trans_fee))}
                  </div>
                </DataField>
                <DataField>
                  <p>Amex Non</p>
                  <div>
                    {valueOrHyphen(toMoney(registration?.amex_non_trans_fee))}
                  </div>
                </DataField>
              </Div50>
              <DivRightSeparator />
              <Div50>
                <FieldGroupTitle>Discount Amount</FieldGroupTitle>
                <DataField>
                  <p>Credit Qual</p>
                  <div>
                    {percentageOrHyphen(registration?.credit_qual_disc_rate)}
                  </div>
                </DataField>
                <DataField>
                  <p>Credit Mid</p>
                  <div>
                    {percentageOrHyphen(registration?.credit_mid_disc_rate)}
                  </div>
                </DataField>
                <DataField>
                  <p>Credit Non</p>
                  <div>
                    {percentageOrHyphen(registration?.credit_non_disc_rate)}
                  </div>
                </DataField>
                <DataField>
                  <p>Amex Qual</p>
                  <div>
                    {percentageOrHyphen(registration?.amex_qual_disc_rate)}
                  </div>
                </DataField>
                <DataField>
                  <p>Amex Mid</p>
                  <div>
                    {percentageOrHyphen(registration?.amex_mid_disc_rate)}
                  </div>
                </DataField>
                <DataField>
                  <p>Amex Non</p>
                  <div>
                    {percentageOrHyphen(registration?.amex_non_disc_rate)}
                  </div>
                </DataField>
              </Div50>
            </SectionWrap>
          </DivContent>
        );
      }}
    </UnderwritingSection>
  );
}
