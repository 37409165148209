import { transactionsapi, queryapi } from '../api';
import { formatDate, subDays } from '../util/date.util';

export function getExportType(path: string): string {
  const segments = path.split('/');
  return segments[segments.length - 1];
}

export function createFileName(type: string, extension: string): string {
  const date = formatDate(new Date(), 'yyyy-MM-dd h:mmtt');
  return `${type}-${date}.${extension}`;
}

// Down the line this might have to contain a switch statement
// depending on if other export routes for other pages follow
// the `/${type}/export/` convention or not.
export const doExport = (
  token: string,
  type: string,
  params: { startDate?: string; endDate?: string }
) => {
  switch (type) {
    case 'transactions':
      return transactionsapi.get(token, `/${type}/export/`, {
        ...params,
        startDate:
          params?.startDate ?? formatDate(subDays(new Date(), 7), 'yyyy-MM-dd'), // Api expects startDate (we have no filter for it right now in OC)
        endDate: params?.endDate ?? formatDate(new Date(), 'yyyy-MM-dd'),
      });
    case 'deposits':
      return queryapi.get(token, `/depositDetail`, {
        ...params,
        resourceType: 'depositDetail',
        export: 'csv',
      });
    default:
      return Promise.reject();
  }
};
