import React from 'react';
import { RegistrationAuditLog } from '@fattmerchantorg/types-omni';
import renderConsumerAlertReport from './consumer-alert';
import renderDefaultReport from './default';
import renderErrorReport from './error';
import renderGenericReport from './generic';
import renderGoogleReport from './google';
import renderTinCheckReport from './tin-check';
import { renderTimestamp } from '../../../../funding-accounts/util/BankAccountsPage.util';

const RESPONSE_TYPES_WITH_DETAILS = [
  'MagValidationResponse',
  'VerificationResponse',
  'AccountResponseCode',
  'CustomerResponseCode',
  'ConsumerAlertMessages',
  'BusinessDBAResponse',
  'BusinessLegalNameResponse',
];

const RESPONSE_TYPES_WITHOUT_DETAILS = [
  'TinCheckResponse',
  'payfacSubmissionResponse',
  'errors',
];

const REPORT_TYPES = {
  MagValidationResponse: renderDefaultReport,
  VerificationResponse: renderDefaultReport,
  AccountResponseCode: renderDefaultReport,
  CustomerResponseCode: renderDefaultReport,
  ConsumerAlertMessages: renderConsumerAlertReport,
  BusinessDBAResponse: renderGoogleReport,
  BusinessLegalNameResponse: renderGoogleReport,
  TinCheckResponse: renderTinCheckReport,
  payfacSubmissionResponse: renderDefaultReport,
  errors: renderErrorReport,
};

const hasDecoratedProperties = (auditData: RegistrationAuditLog['result']) => {
  for (const type of RESPONSE_TYPES_WITH_DETAILS.concat(
    RESPONSE_TYPES_WITHOUT_DETAILS
  )) {
    if (auditData.hasOwnProperty(type)) {
      return true;
    }
  }
  return false;
};

export default (
  auditData: RegistrationAuditLog['result'][],
  renderViewMoreDetailsButton,
  source?: 'underwriting' | 'fundingAccounts'
) => {
  let drilldown = [];
  auditData.forEach(datum => {
    if (!hasDecoratedProperties(datum)) {
      drilldown.push(renderGenericReport(datum));
      return;
    }
    for (const type of RESPONSE_TYPES_WITH_DETAILS) {
      if (datum.hasOwnProperty(type) && datum[type]) {
        drilldown.push(
          <div key={`${datum.id}-${type}`}>
            {REPORT_TYPES[type](datum[type], type)}
          </div>
        );
      }
    }
    if (datum.hasOwnProperty('ItemReferenceId') && datum.ItemReferenceId) {
      drilldown.push(
        <div key={`${datum.id}-ItemReferenceId`}>
          {renderViewMoreDetailsButton(datum.ItemReferenceId)}
        </div>
      );
    }
    if (
      'fundingAccounts' === source &&
      datum.hasOwnProperty('BankName') &&
      datum.BankName
    ) {
      drilldown.push(
        <div key={`${datum.ItemReferenceId}-BankName`} className="log-item">
          <div>
            <strong>Bank Name</strong>
          </div>
          <div>{datum.BankName}</div>
        </div>
      );
    }
    if (
      'fundingAccounts' === source &&
      datum.hasOwnProperty('ItemReferenceId') &&
      datum.ItemReferenceId
    ) {
      drilldown.push(
        <div
          key={`${datum.ItemReferenceId}-ItemReferenceId`}
          className="log-item"
        >
          <div>
            <strong>Item Reference ID</strong>
          </div>
          <div>{datum.ItemReferenceId}</div>
        </div>
      );
    }
    if (
      'fundingAccounts' === source &&
      datum.hasOwnProperty('CreatedDate') &&
      datum.CreatedDate
    ) {
      drilldown.push(
        <div key={`${datum.ItemReferenceId}-CreatedDate`} className="log-item">
          <div>
            <strong>Created date</strong>
          </div>
          <div>{renderTimestamp(datum.CreatedDate, 'MM/dd/yy h:mma')}</div>
        </div>
      );
    }
    for (const type of RESPONSE_TYPES_WITHOUT_DETAILS) {
      if (datum.hasOwnProperty(type) && datum[type]) {
        drilldown.push(
          <div key={`${datum.id}-${type}`}>
            {REPORT_TYPES[type](datum[type], type)}
          </div>
        );
      }
    }
  });
  return drilldown;
};
