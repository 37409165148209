import React, { FunctionComponent, CSSProperties } from 'react';
import styled from 'styled-components';
import {
  Icon,
  BaseModal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  SecondaryButton,
  DestructiveButton,
} from '@fattmerchantorg/truffle-components';
import { Spinner } from '../shared/Spinner';

type ConfirmDeleteModalProps = {
  isOpen: boolean;
  title: string;
  confirmButtonTitle?: string;
  confirmButtonLabel?: string;
  isDeleting?: boolean;
  style?: Record<string, CSSProperties>;
  onClose: () => void;
  onConfirm: () => void;
  zIndex?: number;
};

const ConfirmContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  text-align: center;

  svg {
    height: 70px;
    width: 70px !important;
  }
`;

const ConfirmFooter = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: center;
  width: 100%;
`;

const WarningIcon = styled(Icon)`
  font-size: 70px;
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.core.gray[400].hex};
` as typeof Icon;

const BoldHeader = styled(ModalHeader)`
  h1 {
    font-weight: bold;
  }
` as typeof ModalHeader;

export const ConfirmDeleteModal: FunctionComponent<
  ConfirmDeleteModalProps
> = props => {
  const {
    isOpen,
    title,
    confirmButtonLabel = 'Delete',
    style = {},
    onClose,
    onConfirm,
    children,
    isDeleting,
    zIndex = 9999,
  } = props;

  return (
    <BaseModal
      isOpen={isOpen}
      style={{
        overlay: { pointerEvents: 'auto', zIndex: zIndex },
        content: { maxWidth: '480px' },
        ...style,
      }}
    >
      <BoldHeader title={title} onClose={onClose} />
      <ModalContent>
        <ConfirmContent style={{ ...style.confirmDelete }}>
          <WarningIcon
            className="icon"
            icon={['far', 'exclamation-triangle']}
            style={{ ...style.icon }}
          />
          {children}
        </ConfirmContent>
      </ModalContent>
      <ModalFooter>
        <ConfirmFooter>
          <SecondaryButton
            onClick={onClose}
            trackingId="confirm-delete-modal-cancel"
          >
            Cancel
          </SecondaryButton>
          <DestructiveButton
            onClick={onConfirm}
            trackingId="confirm-delete-modal-delete"
          >
            {isDeleting ? <Spinner /> : confirmButtonLabel}
          </DestructiveButton>
        </ConfirmFooter>
      </ModalFooter>
    </BaseModal>
  );
};
