import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { InputProps } from './Input.types';
import { InputContainer } from '../InputContainer/InputContainer';
import { useToaster } from '../../../hooks';

export const Input: FunctionComponent<InputProps> = props => {
  const {
    id,
    value,
    label,
    tooltip,
    className,
    active: parentActive,
    ...rest
  } = props;
  const [active, setActive] = useState(props.active || !!value);
  const { toast, toaster } = useToaster();

  const makeActive = useCallback(
    (value: boolean) => {
      if (typeof parentActive === 'boolean') {
        setActive(parentActive);
      } else {
        setActive(value);
      }
    },
    [parentActive]
  );

  useEffect(() => {
    const isActive = value && !active;
    if (isActive && active !== isActive) {
      makeActive(isActive);
    }
  }, [value, active, makeActive]);

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    makeActive(true);
    if (props.onFocus) props.onFocus(e);
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    makeActive(!!e.target.value);
    if (props.onBlur) props.onBlur(e);
  };

  return (
    <InputContainer
      label={label}
      className={`ui-input ${active ? '-active' : ''} ${className || ''} `}
    >
      {label && (
        <div>
          <label htmlFor={id}>{label}</label>
        </div>
      )}
      {tooltip && (
        <i
          className="tooltip fas fa-question-circle"
          data-tip={tooltip}
          onClick={() => toaster(toast.info(tooltip))}
        />
      )}
      <input
        {...rest}
        id={id}
        data-testid={id}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </InputContainer>
  );
};

Input.defaultProps = {
  type: 'text',
};
