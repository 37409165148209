import { PermissionSection } from './RoleFormFields';

export const validateName = (name: string): string | undefined => {
  return name && name.length > 3
    ? undefined
    : 'Name should be more than 3 characters';
};

export const readOption = [{ label: 'View', value: 'read' }];

export const writeOption = [{ label: 'Edit', value: 'write' }];

export const readWriteOptions = readOption.concat(writeOption);

export const legacyPermissionsSections: PermissionSection[] = [
  {
    label: 'Admin',
    permissions: [
      {
        name: 'omniSearch',
        label: 'Search by ID',
        options: writeOption,
      },
      {
        name: 'assume',
        label: 'Assume Merchant',
        options: readWriteOptions,
      },
      {
        name: 'haltRecurring',
        label: 'Halt Recurring Schedules',
        options: writeOption,
      },
      {
        name: 'canAddMerchants',
        label: 'Add A Merchant',
        options: writeOption,
      },
      {
        name: 'pii',
        label: 'PII Access',
        options: writeOption,
      },
      {
        name: 'connectLite',
        label: 'Connect Lite',
        options: writeOption,
      },
      {
        name: 'surchargeStateCheck',
        label: 'Surcharging State Check',
        options: readWriteOptions,
      },
      {
        name: 'brand',
        label: 'Brand',
        options: readWriteOptions,
      },
    ],
  },
  {
    label: 'Merchant Modal (Popup) Tabs',
    permissions: [
      {
        name: 'details',
        label: 'Details',
        options: writeOption,
      },
      {
        name: 'enrollment',
        label: 'Enrollment',
        options: writeOption,
      },
      {
        name: 'onboarding',
        label: 'Onboarding',
        options: readWriteOptions,
      },
      {
        name: 'registrationProgress',
        label: 'Registration Progress',
        options: writeOption,
      },
      {
        name: 'webPayments',
        label: 'API Keys',
        options: writeOption,
      },
      {
        name: 'gateway',
        label: 'Gateways',
        options: writeOption,
      },
      {
        name: 'emvSettings',
        label: 'EMV Settings',
        options: writeOption,
      },
      {
        name: 'terminals',
        label: 'Terminals',
        options: writeOption,
      },
      {
        name: 'qbo',
        label: 'QBO Integration',
        options: writeOption,
      },
    ],
  },
  {
    label: 'Merchant Modal Details Tab - Company Info',
    permissions: [
      {
        name: 'companyName',
        label: 'Company Name',
        options: readWriteOptions,
      },
      {
        name: 'productType',
        label: 'Product Type',
        options: readWriteOptions,
      },
      {
        name: 'vertical',
        label: 'Vertical Picker',
        options: readWriteOptions,
      },
      {
        name: 'plan',
        label: 'Plan',
        options: readWriteOptions,
      },
      {
        name: 'status',
        label: 'Status',
        options: readWriteOptions,
      },
      {
        name: 'mid',
        label: 'MID',
        options: readWriteOptions,
      },
      {
        name: 'fetchMid',
        label: 'Fetch MID',
        options: writeOption,
      },
      {
        name: 'reprocessMid',
        label: 'Reprocess MID',
        options: writeOption,
      },
      {
        name: 'partnerName',
        label: 'Partner Name',
        options: readWriteOptions,
      },
      {
        name: 'notes',
        label: 'Notes',
        options: readWriteOptions,
      },
      {
        name: 'riskHold',
        label: 'Risk Hold',
        options: readWriteOptions,
      },
      {
        name: 'isEnterprise',
        label: 'Enterprise Checkbox',
        options: readWriteOptions,
      },
      {
        name: 'surcharge',
        label: 'Surcharge',
        options: readWriteOptions,
      },
      {
        name: 'partnershipType',
        label: 'ISO/Payfac Checkboxes',
        options: readWriteOptions,
      },
      {
        name: 'pricingType',
        label: 'Interchange/Flat Rate Checkboxs',
        options: readWriteOptions,
      },
      {
        name: 'manageSubscriptions',
        label: 'Manage Subscriptions',
        options: readWriteOptions,
      },
    ],
  },

  {
    label: 'Merchant Modal Details Tab - Contact Info',
    permissions: [
      {
        name: 'contactName',
        label: 'Contact Name',
        options: readWriteOptions,
      },
      {
        name: 'contactPhone',
        label: 'Contact Phone',
        options: readWriteOptions,
      },
      {
        name: 'contactEmail',
        label: 'Contact Email',
        options: readWriteOptions,
      },
      {
        name: 'address',
        label: 'Address',
        options: readWriteOptions,
      },
      {
        name: 'fmBillingScheduleId',
        label: 'FM Billing Schedule ID',
        options: readWriteOptions,
      },
    ],
  },
  {
    label: 'Stax Connect Permissions',
    permissions: [
      {
        name: 'assumeSubmerchants',
        label: 'Assume Submerchant',
        options: writeOption,
      },
      {
        name: 'underwriting',
        label: 'Underwriting',
        options: writeOption,
      },
    ],
  },
  {
    label: 'Settlements',
    permissions: [
      {
        name: 'manageStaxSettlements',
        label: 'Manage Stax Settlements',
        options: readWriteOptions,
      },
      {
        name: 'approvalWizard',
        label: 'Approval Wizard',
        options: writeOption,
      },
      {
        name: 'overrideRiskHold',
        label: 'Modify Risk Hold Status',
        options: writeOption,
      },
    ],
  },
  {
    label: 'Engine',
    permissions: [
      {
        name: 'engineBillingProfiles',
        label: 'Billing Profiles',
        options: readWriteOptions,
      },
      {
        name: 'engineReserves',
        label: 'Reserves',
        options: readWriteOptions,
      },
      {
        name: 'engineReservesRelease',
        label: 'Reserves Release',
        options: writeOption,
      },
      {
        name: 'enginePaymentMethods',
        label: 'Payment Methods',
        options: readWriteOptions,
      },
      {
        name: 'engineReAttemptPayouts',
        label: 'Re-Attempt Payouts',
        options: writeOption,
      },
      {
        name: 'engineAdjustmentTransactions',
        label: 'Credit & Debit Adjustments',
        options: writeOption,
      },
      {
        name: 'engineTerminals',
        label: 'Terminals',
        options: readWriteOptions,
      },
    ],
  },
  {
    label: 'Misc',
    permissions: [
      {
        name: 'magSubmit',
        label: 'Submit Mag (Onboarding)',
        options: writeOption,
      },
      {
        name: 'internalOnboardingFields',
        label: 'Internal Onboarding Fields',
        options: writeOption,
      },
      {
        name: 'overridePricing',
        label: 'Override Pricing',
        options: writeOption,
      },
      {
        name: 'payfacVerification',
        label: 'Payfac Verification (Onboarding)',
        options: readWriteOptions,
      },
      {
        name: 'transactionLimit',
        label: 'Merchant Transaction Limit (Gateway)',
        options: readWriteOptions,
      },
      {
        name: 'processor',
        label: 'Processor (Gateway)',
        options: readWriteOptions,
      },
      {
        name: 'runVerification',
        label: 'Run External Verifications',
        options: writeOption,
      },
      {
        name: 'overridePricingManager',
        label: 'Override Pricing - Manager',
        options: writeOption,
      },
      {
        name: 'overridePricingUser',
        label: 'Override Pricing - User',
        options: writeOption,
      },
      {
        name: 'viewExternalAuditLog',
        label: 'View External Audit Log (Onboarding)',
        options: writeOption,
      },
      {
        name: 'escalatedSupportTool',
        label: 'Escalated Support Tool',
        options: readWriteOptions,
      },
    ],
  },
];
