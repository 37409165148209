import React, {
  useState,
  useEffect,
  FunctionComponent,
  useContext,
} from 'react';
import styled, { withTheme } from 'styled-components';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import { PageHeaderStore } from '../../../context';
import { updateTitle } from '../../../context/page-header/PageHeader.actions';

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem 0.5rem;
  margin-left: auto;
  margin-right: auto;

  > *:not(:first-child) {
    margin-left: 2rem;
  }
`;

const Children = styled.div`
  &.-visible {
    visibility: visible;
    animation: fade-in 0.25s ease-in both;
  }

  &.-hidden {
    visibility: hidden;
    animation: fade-out 0.25s ease-out both;
  }
`;

const StyledHeader = withTheme(
  styled.h1`
    transition: 0.2s;
    color: ${({ theme }) => theme.white};
    font-size: 4em;
    font-weight: lighter;
    display: none;
    margin: 0;

    @media (min-width: 768px) {
      display: block;
    }

    opacity: ${(props: { state: TransitionStatus }) =>
      props.state === 'exited' ? 1 : 0};

    transform: ${(props: { state: TransitionStatus }) =>
      `translateX(${props.state === 'exited' ? '0' : '-1rem'})`};
  `
);

export const PageHeader: FunctionComponent<
  React.HTMLAttributes<HTMLDivElement> & { title: string }
> = props => {
  const { title, children, ...restOfProps } = props;

  const {
    state: { currentTitle },
    dispatch,
  } = useContext(PageHeaderStore);
  const [isHeaderAnimating, setIsHeaderAnimating] = useState(false);
  const [areChildrenVisible, setAreChildrenVisible] = useState(false);

  useEffect(() => {
    let headerTimeout: number;
    let childrenTimeout: number;

    setIsHeaderAnimating(true);
    setAreChildrenVisible(false);

    headerTimeout = setTimeout(() => {
      dispatch(updateTitle(title));
      setIsHeaderAnimating(false);
    }, 200);

    childrenTimeout = setTimeout(() => {
      setAreChildrenVisible(true);
    }, 400);

    return () => {
      clearTimeout(headerTimeout);
      clearTimeout(childrenTimeout);
    };
  }, [title, dispatch]);

  return (
    <Container {...restOfProps}>
      <Transition in={isHeaderAnimating} timeout={200}>
        {state => <StyledHeader state={state}>{currentTitle}</StyledHeader>}
      </Transition>
      <Children className={areChildrenVisible ? '-visible' : '-hidden'}>
        {children}
      </Children>
    </Container>
  );
};
