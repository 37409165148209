import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Icon } from '@fattmerchantorg/truffle-components';
import { Registration } from '@fattmerchantorg/types-omni';
import { UnderwritingSection } from '../shared/UnderwritingSection';
import { SelectedMerchantStore } from '../../../../context';
import { geoLocationByIp, GeoLocationResponse } from './ip-geolocation-util';
import { AlertProps, Alert } from '../../../shared';

export interface SignedLocationInfoProps {}

const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const YellowIcon = styled.span`
  color: #f8dc3d;
`;
const AddressRow = styled.div`
  display: flex;
  padding: 4px 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  scroll-snap-align: start;

  span {
    font-size: 14px;
    line-height: 20px;
  }

  a {
    font-size: 14px;
  }
  :last-child {
    padding-bottom: 0px;
  }
`;

const mapStatusToAlertType = (
  status: GeoLocationResponse['status']
): AlertProps['type'] => {
  switch (status) {
    case 'TOBESIGNED':
      return 'neutral';
    case 'READY':
      return 'info';
    case 'MATCH':
      return 'success';
    case 'NOMATCH':
      return 'warning';
    case 'ERROR':
    default:
      return 'error';
  }
};

export function SignedLocationInfo(props: SignedLocationInfoProps) {
  const {
    state: { registration },
  } = useContext(SelectedMerchantStore);
  const [locationByIp, setlocationByIp] = useState<GeoLocationResponse>(null);

  const getLocationByIp = async (registration: Registration) => {
    const data = await geoLocationByIp(registration);
    setlocationByIp(data);
  };

  const location = locationByIp?.response;
  const alerts = locationByIp?.alerts;
  const status = locationByIp?.status;
  const alertText = locationByIp?.alertText || '';
  useEffect(() => {
    getLocationByIp(registration);
  }, [registration]);

  return (
    <UnderwritingSection
      title="Signed Location Info"
      data-testid="signed-location-info"
      id="SignedLocationInfoCard"
    >
      {({ setToolTipContent }) => {
        setToolTipContent('Approximate place where the document was signed');
        return (
          <div style={{ width: '100%' }}>
            {status ? (
              <Alert
                type={mapStatusToAlertType(status)}
                message={alertText}
                // inherent 4px top padding for address rows + 12px = desired 16px of space between elements
                style={{ marginBottom: '12px' }}
              />
            ) : null}
            <AddressContainer>
              <AddressRow>
                <span>Country</span>
                <span>
                  {alerts?.includes('country') && (
                    <YellowIcon>
                      <Icon icon={['fas', 'exclamation-triangle']} />
                    </YellowIcon>
                  )}
                  {location?.country || '-'}
                </span>
              </AddressRow>
              <AddressRow>
                <span>Location </span>
                <span>
                  {alerts?.includes('state') && (
                    <YellowIcon>
                      <Icon icon={['fas', 'exclamation-triangle']} />
                    </YellowIcon>
                  )}
                  {location?.city || location?.region
                    ? location?.city + ' ' + location?.region
                    : '-'}
                </span>
              </AddressRow>
              <AddressRow>
                <span> Zip </span> <span>{location?.postal || '-'}</span>
              </AddressRow>
              <AddressRow>
                <span> Timezone</span> <span> {location?.timezone || '-'}</span>
              </AddressRow>
            </AddressContainer>
          </div>
        );
      }}
    </UnderwritingSection>
  );
}
