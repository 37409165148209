import React, { useContext } from 'react';
import { DeleteAdjustmentTransaction } from './components/DeleteAdjustmentTransaction';
import {
  AdjustmentTransactionFromGlobal,
  AdjustmentTransactionFromTransactions,
  AdjustmentTransactionRootModalProps,
} from './AdjustmentTransactions.types';
import { ModalContext, closeModal } from '../../../context';
import { CreateOrEditModal } from './components/CreateOrEditModal';

type Props = AdjustmentTransactionRootModalProps &
  (AdjustmentTransactionFromGlobal | AdjustmentTransactionFromTransactions);

export const AdjustmentTransactionsModal: React.FC<Props> = (props: Props) => {
  const { modalDispatch } = useContext(ModalContext);

  if (props.action === 'DELETE' && props.source === 'transaction') {
    return (
      <DeleteAdjustmentTransaction
        source="transaction"
        onCancel={onCancel}
        onDone={onDone}
        action={props.action}
        transaction={props.transaction}
        merchant={props.merchant}
        authToken={props.authToken}
      />
    );
  } else if (props.source === 'global') {
    return (
      <CreateOrEditModal
        source={props.source}
        onCancel={onCancel}
        onDone={onDone}
        action={props.action}
        merchant={props.merchant}
        authToken={props.authToken}
        type={props.type}
      />
    );
  } else if (props.source === 'transaction') {
    return (
      <CreateOrEditModal
        source={props.source}
        onCancel={onCancel}
        onDone={onDone}
        action={props.action}
        merchant={props.merchant}
        authToken={props.authToken}
        transaction={props.transaction}
        type={props.type}
      />
    );
  }
  return null;

  function onCancel() {
    modalDispatch(closeModal());
    if (typeof props.onCancel === 'function') {
      props.onCancel();
    }
  }

  function onDone() {
    modalDispatch(closeModal());
    if (typeof props.onDone === 'function') {
      props.onDone();
    }
  }
};
