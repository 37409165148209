import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FormImageProps } from './FormImage.types';

const StyledFormImage = styled.div`
  display: flex;
  overflow: hidden;

  justify-content: ${(props: FormImageProps) => props.justify};
  max-height: ${(props: FormImageProps) => props.height};
  width: ${(props: FormImageProps) => props.width};

  img {
    padding: 3rem;

    @media (max-width: 1000px) {
      padding: 1rem;
    }
  }
`;

export const FormImage: FunctionComponent<FormImageProps> = props => {
  return (
    <StyledFormImage {...props}>
      <img src={props.src} alt="" />
    </StyledFormImage>
  );
};

FormImage.defaultProps = {
  height: '30%',
  width: '100%',
  justify: 'center'
};
