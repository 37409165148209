import React, { useState } from 'react';
import {
  GetFeeStatementDisputesDatum,
  GetFeeStatementDisputesResponse,
  GetFeeStatementDisputesSummary,
} from '@fattmerchantorg/types-omni';
import { Text } from '@fattmerchantorg/truffle-components';
import currency from 'currency.js';
import styled from 'styled-components';
import { queryapi } from '../../../../api';
import { TableSectionV3 } from '../styles/table-section-component-v3';
import { StatementV3Props } from '../util/statement-v3-utils';
import { useAsyncEffect, useAuthToken } from '../../../../hooks';
import { format } from 'date-fns';
import { TableNullState } from '../styles';
import { Link } from 'react-router-dom';
import {
  LoadingState,
  useStatementLoadingState,
} from '../../../../context/statements';
import { TextContainer } from '../../components';

const Table = styled(TableSectionV3)`
  /** Override the global styles from _anchor.scss for the first section links */
  > table > tbody > tr > td:first-child {
    > a {
      color: ${props => props.theme.colors.status.blue['500'].hex};
      text-decoration: underline;
      cursor: pointer;
    }
  }
  /** The second row of the table should be aligned left */
  > table .negativeAmount {
    color: ${props => props.theme.colors.status.red['500'].hex};
  }
`;

export const DisputesSection: React.FC<StatementV3Props> = props => {
  const { startDate, endDate, merchantId } = props;
  const authToken = useAuthToken();

  const [data, setData] = useState<GetFeeStatementDisputesDatum[]>();
  const [summary, setSummary] = useState<GetFeeStatementDisputesSummary>();
  const { disputesLoading, setDisputesLoading } = useStatementLoadingState();

  useAsyncEffect(async () => {
    if (
      disputesLoading === LoadingState.Loading &&
      authToken &&
      startDate &&
      endDate
    ) {
      try {
        const query = { startDate, endDate };
        const response = await queryapi.get<GetFeeStatementDisputesResponse>(
          authToken,
          'statement/v3/disputes',
          query
        );

        if (!response) return;

        setData(response.data);
        setSummary(response.summary);
        setDisputesLoading(LoadingState.Completed);
      } catch (error) {
        setDisputesLoading(LoadingState.Failed);
      }
    }
  }, [disputesLoading, authToken, startDate, endDate]);

  if (disputesLoading !== LoadingState.Completed || !data || !summary)
    return null;

  return (
    <Table>
      <TextContainer fontWeight="bold" paddingBottom="8px">
        <Text as="h5">Disputes</Text>
      </TextContainer>
      <table data-testid="statementV3-disputes">
        <thead>
          <tr>
            <th>ID</th>
            <th style={{ textAlign: 'left' }}>Date</th>
            <th>Disputed Amount</th>
            <th>Dispute Fee</th>
          </tr>
        </thead>
        <tbody>
          {data.map(({ type, ...datum }, i) => (
            <tr key={i} className="tableRows">
              <td>
                <Link
                  to={`/merchant/${merchantId}/disputes?detailId=${datum.id}`}
                  target="_blank"
                >
                  {datum.id}
                </Link>
              </td>
              <td style={{ textAlign: 'left' }}>
                {format(new Date(datum.date), 'MM/dd/yyyy')}
              </td>
              <td className={type === 'HOLD' ? 'negativeAmount' : ''}>{`${
                type === 'HOLD' ? '-' : ''
              }${currency(datum.dispute_amount).format()}`}</td>
              <td className={datum.fee_amount > 0 ? 'negativeAmount' : ''}>
                {datum.fee_amount > 0 ? '-' : ''}
                {currency(datum.fee_amount).format()}
              </td>
            </tr>
          ))}
        </tbody>
        {!!data.length && (
          <tfoot>
            <tr>
              <td>
                <strong>Total</strong>
              </td>
              <td></td>
              <td
                className={
                  summary.dispute_amount_total < 0 ? 'negativeAmount' : ''
                }
              >
                <strong>
                  {currency(summary.dispute_amount_total).format()}
                </strong>
              </td>
              <td className="negativeAmount">
                <strong>-{currency(summary.fee_amount_total).format()}</strong>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
      {!data.length && <TableNullState>No Disputes</TableNullState>}
    </Table>
  );
};
