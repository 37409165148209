import React from 'react';
import styled, { withTheme } from 'styled-components';
import { Merchant, DepositDetail } from '@fattmerchantorg/types-omni';
import { formatCurrency, formatReadableDate } from '../../../util/format.util';
import { ColumnProps } from '../../shared';

const getMethodType = (record: DepositDetail) =>
  (record.method === 'bank' && 'BANK') ||
  (record.method === 'card' &&
    record.card_type &&
    record.card_type.toUpperCase()) ||
  '';

const StyledIcon = withTheme(
  styled.i`
    color: ${({ theme }) => theme.colors.core.blue[500].hex};
  `
);

export const getColumns = (
  merchant: Merchant | null,
  hasFees: boolean
): ColumnProps<DepositDetail>[] => {
  const isTrustAccount = merchant?.is_trust;
  const isSurchargingEnabled = merchant?.is_surcharge_enabled;
  const shouldShowFees = hasFees && !isSurchargingEnabled && !isTrustAccount;

  const columns: ColumnProps<DepositDetail>[] = [
    {
      Header: 'Date',
      disableSortBy: true,
      accessor: 'created_at',
      Cell: cell => formatReadableDate(cell.value),
    },
    {
      Header: 'Type',
      disableSortBy: true,
      accessor: 'method',
      Cell: cell => getMethodType(cell.row.original),
    },
    {
      Header: 'Last Four',
      disableSortBy: true,
      accessor: 'last_four',
    },
    {
      Header: 'Authorization Code',
      disableSortBy: true,
      accessor: 'auth_id',
    },
  ];

  if (isSurchargingEnabled) {
    columns.push(
      {
        id: 'subtotal',
        Header: 'Subtotal',
        disableSortBy: true,
        accessor: 'total',
        Cell: cell => formatCurrency(cell.value),
      },
      {
        id: 'surcharge',
        Header: 'Surcharge',
        disableSortBy: true,
        accessor: 'surcharge',
        Cell: cell => formatCurrency(cell.value || 0),
      },
      {
        id: 'total',
        Header: 'Total',
        disableSortBy: true,
        accessor: 'total',
        Cell: cell => {
          const total = cell.value;
          const surcharge = cell.row.original.surcharge || 0;
          return formatCurrency(total - surcharge);
        },
      }
    );
  } else {
    columns.push({
      id: 'total',
      Header: 'Total',
      disableSortBy: true,
      accessor: 'total',
      Cell: cell => formatCurrency(cell.value),
    });
  }

  // only show fees if fee information is available
  // and surcharge is not enabled
  // and this is not a trust account merchant
  if (shouldShowFees) {
    columns.push({
      id: 'fees',
      Header: 'Fees',
      disableSortBy: true,
      accessor: 'fees',
      Cell: cell => formatCurrency(cell.value),
    });
  }

  columns.push({
    Header: 'Transaction Details',
    disableSortBy: true,
    accessor: 'transaction_id',
    Cell: cell => {
      return cell.value ? (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={
            '#/merchant/' +
            merchant?.id +
            '/transactions?detailId=' +
            cell.value
          }
          style={{ display: 'block', textAlign: 'center' }}
        >
          <StyledIcon className="fa fa-external-link"></StyledIcon>
        </a>
      ) : (
        '-'
      );
    },
  });

  return columns;
};
