import React, { FunctionComponent } from 'react';
import { FormRenderProps, FieldRenderProps } from 'react-final-form';
import styled from 'styled-components';
import { TextField, Icon } from '@fattmerchantorg/truffle-components';
import { Registration } from '@fattmerchantorg/types-omni';
import { Field } from '../../../shared/Field';
import {
  FormRow,
  PartialForm,
  TwoColumn,
} from '../shared/UnderwritingPartialForm';
import { StateSelect, SelectOption } from '../shared/StateSelect';
import { CountrySelect, countryOptions } from '../shared/CountrySelect';
import {
  getStateLabel,
  getStateValues,
  mapStateToSelectOption,
} from '../../../../util/countryState.util';
import { formatDate, formatPhoneNumber } from '../../../../util/format.util';
import { MaskedInput } from '../../../shared/MaskedInput';

// @TODO: Add built-in labels to Truffle Select component
const SelectLabelAlignmentFix = styled.div`
  display: flex;
`;

interface OwnerSignerInfoFieldsProps {
  formProps?: FormRenderProps;
  registration?: Registration;
}

export const OwnerSignerInfoFields: FunctionComponent<
  OwnerSignerInfoFieldsProps
> = props => {
  const { formProps, registration } = props;
  const mutators = props.formProps.form.mutators;
  const addressCountry = formProps.values?.owner_address_country || 'USA';
  const stateLabel = getStateLabel(addressCountry);
  const stateOptions = getStateValues(addressCountry).map(
    mapStateToSelectOption
  );

  const findState = (value: string) =>
    stateOptions.find(option => option.value === value);

  const findCountry = (value: string) =>
    countryOptions.find(option => option.value === value);

  return (
    <PartialForm>
      <FormRow>
        <TwoColumn>
          <div>
            <Field name="principal_owners_name">
              {props => (
                <TextField
                  {...props.input}
                  type="text"
                  label="Principal Owner's Name"
                />
              )}
            </Field>
          </div>
          <div>
            <Field name="title">
              {props => (
                <TextField {...props.input} type="text" label="Owner's Title" />
              )}
            </Field>
          </div>
        </TwoColumn>
      </FormRow>
      <FormRow>
        <TwoColumn>
          <div>
            <Field name="first_name">
              {props => (
                <TextField {...props.input} type="text" label="First Name" />
              )}
            </Field>
          </div>
          <div>
            <Field name="last_name">
              {props => (
                <TextField {...props.input} type="text" label="Last Name" />
              )}
            </Field>
          </div>
        </TwoColumn>
      </FormRow>
      <FormRow>
        <TwoColumn>
          <div>
            <Field name="ownership_percentage">
              {props => (
                <TextField
                  {...props.input}
                  type="number"
                  label="Ownership"
                  min="0"
                  max="100"
                  slug={
                    <Icon
                      icon={['fas', 'percent']}
                      style={{
                        color: !!formProps.errors?.ownership_percentage
                          ? '#ff4646'
                          : '#009bf2',
                      }}
                    />
                  }
                  error={!!formProps.errors?.ownership_percentage}
                  helperText={formProps.errors?.ownership_percentage}
                />
              )}
            </Field>
          </div>
          <div>
            <Field name="user_dob" format={formatDate}>
              {props => (
                // @TODO: Will convert to single date picker
                <TextField
                  {...props.input}
                  type="text"
                  label="Birthdate"
                  error={!!formProps.errors?.user_dob}
                  helperText={formProps.errors?.user_dob}
                />
              )}
            </Field>
          </div>
        </TwoColumn>
      </FormRow>
      <FormRow>
        <TwoColumn>
          <div>
            <SelectLabelAlignmentFix>
              <label>Citizenship</label>
            </SelectLabelAlignmentFix>
            <Field
              name="meta.owner_citizenship"
              format={value =>
                countryOptions.find(option => option.value === value)
              }
              clearable={false}
              defaultValue={
                countryOptions.find(
                  option =>
                    option.value === registration?.meta?.owner_citizenship
                )?.value
              }
            >
              {props => (
                <div data-testid="meta.owner_citizenship">
                  <CountrySelect {...props.input} />
                </div>
              )}
            </Field>
          </div>
          <div>
            <Field name="user_ssn" type="text">
              {props => (
                <MaskedInput
                  {...props.input}
                  label="Social Security"
                  maskType="ssn"
                />
              )}
            </Field>
          </div>
        </TwoColumn>
      </FormRow>
      <FormRow>
        <TwoColumn>
          <div>
            <Field name="phone_number" format={formatPhoneNumber}>
              {props => (
                <TextField
                  {...props.input}
                  type="text"
                  label="Phone Number"
                  error={!!formProps.errors?.phone_number}
                  helperText={formProps.errors?.phone_number}
                />
              )}
            </Field>
          </div>
          <div>
            <Field name="email">
              {props => (
                <TextField
                  {...props.input}
                  type="text"
                  label="Email"
                  error={!!formProps.errors?.email}
                  helperText={formProps.errors?.email}
                />
              )}
            </Field>
          </div>
        </TwoColumn>
      </FormRow>
      <FormRow>
        <Field name="owner_address_1">
          {props => (
            <TextField
              {...props.input}
              type="text"
              label="Home Address 1"
              error={!!formProps.errors?.owner_address_1}
              helperText={formProps.errors?.owner_address_1}
            />
          )}
        </Field>
      </FormRow>
      <FormRow>
        <Field name="owner_address_2">
          {props => (
            <TextField
              {...props.input}
              type="text"
              label="Home Address 2"
              placeholder="Optional"
              error={!!formProps.errors?.owner_address_2}
              helperText={formProps.errors?.owner_address_2}
            />
          )}
        </Field>
      </FormRow>
      <FormRow>
        <TwoColumn>
          <div>
            <Field name="owner_address_city">
              {props => <TextField {...props.input} type="text" label="City" />}
            </Field>
          </div>
          <div>
            <SelectLabelAlignmentFix>
              <label>{stateLabel}</label>
            </SelectLabelAlignmentFix>
            <Field
              name="owner_address_state"
              format={findState}
              clearable={false}
            >
              {(props: FieldRenderProps<SelectOption, HTMLElement>) => (
                <div data-testid="owner_address_state">
                  <StateSelect
                    {...props.input}
                    value={
                      findState(props.input?.value?.value) || {
                        label: '',
                        value: '',
                      }
                    }
                    options={stateOptions}
                  />
                </div>
              )}
            </Field>
          </div>
        </TwoColumn>
      </FormRow>
      <FormRow>
        <TwoColumn>
          <div>
            <Field name="owner_address_zip">
              {props => <TextField {...props.input} type="text" label="Zip" />}
            </Field>
          </div>
          <div>
            <SelectLabelAlignmentFix>
              <label>Country</label>
            </SelectLabelAlignmentFix>
            <Field
              name="owner_address_country"
              format={findCountry}
              clearable={false}
              defaultValue={
                findCountry(registration?.owner_address_country || 'USA')
                  ?.value || 'USA'
              }
            >
              {props => (
                <div data-testid="owner_address_country">
                  <CountrySelect
                    {...props.input}
                    onChange={evt => {
                      // if the parent passed an 'onChange' handler via props call it
                      if (props.input.onChange) {
                        props.input.onChange(evt);
                      }
                      mutators.setFieldValue('owner_address_state', '');
                    }}
                  />
                </div>
              )}
            </Field>
          </div>
        </TwoColumn>
      </FormRow>
    </PartialForm>
  );
};
