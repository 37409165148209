import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Themed } from '../Themed.types';
import { StepProps } from './Step.types';

const Badge = styled.div`
  display: block;
  height: 32px;
  width: 32px;

  color: white;

  text-align: center;

  margin-bottom: 8px;
  transition: all 0.25s;

  > label {
    position: relative;
    line-height: 32px;
    background-color: #b7bac1;
    border-radius: 16px;
    height: 100%;
    width: 100%;
    z-index: 2;
    transition: all 0.15s;
  }

  &[data-active='true'] > label {
    background-color: ${({ theme }) => theme.colors.core.green[400].hex};
  }

  ::after {
    content: '';
    width: 100%;
    height: 2px;
    background-color: #dddfe4;
    position: absolute;
    right: 50%;
    top: 16px;
    z-index: 0;
    transition: all 0.15s;
  }

  &[data-active='true']::after {
    background-color: ${(props: Themed) => props.theme.colors.secondaryColor};
  }
`;

const Container = styled.div`
  position: relative;
  flex-grow: 1;

  display: flex;
  align-items: center;
  flex-direction: column;
  text-transform: uppercase;

  color: #404750;
  border-color: lightgray;

  transition: all 0.25s;

  &[data-active='true'] {
    color: ${(props: Themed) => props.theme.colors.secondaryColor};
  }

  &[data-clickable='true'] {
    cursor: pointer;
  }

  &:first-child > ${Badge}::after {
    content: none;
    display: none;
  }
`;

export const Step: FunctionComponent<StepProps> = props => {
  const { active, index, current, children, ...divProps } = props;
  const isActive = active || current >= index;

  return (
    <Container
      data-active={isActive}
      data-clickable={!!props.onClick}
      {...divProps}
    >
      <Badge data-active={isActive} data-index={index}>
        <label>{index + 1}</label>
      </Badge>
      <div>{children}</div>
    </Container>
  );
};
