import { Tooltip } from '@fattmerchantorg/truffle-components';
import { format } from 'date-fns';
import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import styled, { withTheme } from 'styled-components';
import { useWindowSize } from '../../../hooks';
import { Button } from '../Button';
import { FileRecord } from '@fattmerchantorg/types-omni';

const Container = withTheme(
  styled.div`
    height: 180px;

    &[data-disabled-null-state='true'] {
      height: 64px;
    }

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background: ${({ theme }) => theme.colors.core.gray[700].hex};
    border-radius: 6px;

    .cloud {
      font-size: 35px !important;
      padding-bottom: 8px;
    }

    .accepted-files {
      font-family: 'Roboto', sans-serif;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal !important;
      letter-spacing: 0em;
      text-align: left;
      overflow: visible;
    }

    .accepted-files-title {
      overflow: visible;
    }

    button {
      min-width: 20px;
      margin: 0px !important; // Important to override more specific Form button style
    }

    * {
      overflow: hidden;
    }

    > *:last-child {
      flex: 70%;
      border: 2px dashed ${({ theme }) => theme.colors.core.gray[600].hex};
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  `
);

const Dropzone = withTheme(
  styled.div`
    flex: 30%;

    /* Skip right border */
    border-top: 1px dashed ${({ theme }) => theme.colors.core.gray[600].hex};
    border-left: 1px dashed ${({ theme }) => theme.colors.core.gray[600].hex};
    border-bottom: 1px dashed ${({ theme }) => theme.colors.core.gray[600].hex};

    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;

    color: ${({ theme }) => theme.white};
    background: ${({ theme }) => theme.colors.core.gray[700].hex};

    > div {
      height: 100%;
      padding: 0.5rem;

      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      cursor: pointer;

      i {
        font-size: 500%;

        @media (max-width: 1000px) {
          font-size: 200%;
        }
      }
    }
  `
);

const DisabledNullState = styled.div`
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.colors.core.gray[500].hex};
  color: ${({ theme }) => theme.colors.core.gray[200].hex};
`;

const StyledFileLabel = styled.div`
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FileTable = styled.table`
  display: grid;
  grid-template-columns: auto minmax(auto, max-content) auto;
  grid-auto-rows: min-content;
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;

  thead th,
  thead td {
    position: sticky;
    top: 0;
    background: ${({ theme }) => theme.colors.core.gray[700].hex};
  }

  thead,
  tbody,
  tr {
    display: contents;
  }

  td,
  th {
    padding-block: 4px;
    padding-inline: 8px;
  }

  tbody > tr:nth-child(2n - 1) > td {
    background: ${(props: any) => props.theme.component.table.backgroundColor};
  }

  .action-buttons {
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
  }
`;

type FileItemProps = {
  file: FileRecord;
  onDelete?: (file: FileRecord) => Promise<void> | any;
  onDownload?: (file: FileRecord) => Promise<void> | any;
  onPreview?: (file: FileRecord) => Promise<void> | void;
};

const FileListItem: FunctionComponent<FileItemProps> = ({
  file,
  onDownload,
  onDelete,
}) => {
  const [state, setState] = useState<'idle' | 'deleting' | 'downloading'>(
    'idle'
  );

  return (
    <tr key={file.id}>
      <td>
        {file.name} {file?.meta?.filesize && <span>{file.meta.filesize}</span>}
      </td>
      <td>{format(new Date(file.created_at), 'MM/dd/yyyy | h:mm aa')}</td>
      <td>
        <div className="action-buttons">
          <Tooltip content="Download">
            <Button
              variant="link"
              style={{
                cursor: onDownload ? 'pointer' : 'not-allowed',
              }}
              onClick={async e => {
                if (!onDownload) return;
                setState('downloading');
                onDownload(file).finally(() => setState('idle'));
                e.stopPropagation();
              }}
            >
              <StyledFileLabel>
                {state === 'downloading' ? (
                  <i className="fas fa-circle-notch fa-spin"></i>
                ) : (
                  <i className="fas fa-download"></i>
                )}
              </StyledFileLabel>
            </Button>
          </Tooltip>

          {onDelete && (
            <Tooltip content="Delete">
              <Button
                data-testid="delete-files-button"
                variant="link"
                motif="destructive"
                disabled={state !== 'idle'}
                onClick={async e => {
                  if (!onDelete) return;
                  setState('deleting');
                  onDelete(file).finally(() => setState('idle'));
                  e.stopPropagation();
                }}
              >
                <StyledFileLabel>
                  {state === 'deleting' ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    <i className="fas fa-trash"></i>
                  )}
                </StyledFileLabel>
              </Button>
            </Tooltip>
          )}
        </div>
      </td>
    </tr>
  );
};

type FilesProps = {
  files?: FileRecord[];
  disabled?: boolean;
  onDrop: (files: File[]) => Promise<any> | any;
  onDelete?: (file: FileRecord) => Promise<any> | any;
  onDownload?: (file: FileRecord) => Promise<any> | any;
  isDropzoneHidden?: boolean;
};

export const Files: FunctionComponent<
  FilesProps & React.HTMLProps<HTMLInputElement>
> = props => {
  const { isMobile } = useWindowSize();
  const [isUploading, setIsUploading] = useState(false);
  const { onDrop, disabled, accept } = props;

  const handleDrop = useCallback(
    (files: File[]) => {
      const p = onDrop(files);

      if (p instanceof Promise) {
        (async function () {
          setIsUploading(true);
          await p;
          setIsUploading(false);
        })();
      }
    },
    [onDrop]
  );

  const dropzone = useDropzone({
    onDrop: handleDrop,
    multiple: true,
  });

  const renderContent = (): ReactNode => {
    if (dropzone.isDragActive) {
      return (
        <div>
          <i className="fas fa-inbox" />
          <span>Drop to upload</span>
        </div>
      );
    } else if (isUploading) {
      return (
        <div>
          <i className="fas fa-circle-notch fa-spin" />
          <span>Uploading...</span>
        </div>
      );
    } else {
      return (
        <div>
          <i className="fas fa-cloud-upload-alt cloud" />
          {isMobile ? (
            <span>Tap to upload</span>
          ) : (
            <span className="accepted-files-title">
              Click or drop here to upload
            </span>
          )}
          <p>
            <span className="accepted-files">
              Accepted file types: JPEG, JPG, PNG, PDF, TIFF
            </span>
          </p>
        </div>
      );
    }
  };

  return (
    <Container data-disabled-null-state={disabled && !props.files?.length}>
      {/* Dropzone */}
      {props.isDropzoneHidden || disabled ? null : (
        <Dropzone {...dropzone.getRootProps()}>
          <input
            {...dropzone.getInputProps()}
            data-test-id="document-upload-file-selector"
            accept={accept ? accept : '*'}
          />
          {renderContent()}
        </Dropzone>
      )}

      {/* File List */}
      {props.files && props.files.length ? (
        <div>
          <FileTable>
            <thead>
              <tr>
                <th>Name</th>
                <th>Date</th>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {props.files.map(file => (
                <FileListItem
                  file={file}
                  onDownload={props.onDownload}
                  onDelete={disabled ? null : props.onDelete}
                />
              ))}
            </tbody>
          </FileTable>
        </div>
      ) : null}

      {/* Null state */}
      {disabled && !props.files?.length && (
        <DisabledNullState>
          <span>No Uploaded Documents</span>
        </DisabledNullState>
      )}
    </Container>
  );
};
