import { AxiosError } from 'axios';

export function isObjectLike(
  candidate: unknown
): candidate is Record<string, unknown> {
  return typeof candidate === 'object' && candidate !== null;
}

export function isNumberLike(candidate: unknown): candidate is number {
  // Check if the value is not null or undefined
  if (candidate !== null && candidate !== undefined && candidate !== '') {
    const numericValue = +candidate;
    return !isNaN(numericValue);
  }

  return false;
}

/**
 * Determines if a given error is an error generated by axios.
 */
export function isAxiosError<T = any>(error: unknown): error is AxiosError<T> {
  // TODO: Once `mock-axios-adapter` Error matches `axios` Error, rely on `.isAxiosError` property.
  // return isObjectLike(error) && error.isAxiosError === true;
  return isObjectLike(error) && error.config !== undefined;
}
