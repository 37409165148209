import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { CheckboxProps } from './Checkbox.types';

// https://appitventures.com/blog/styling-checkbox-css-tips/
const Container = styled.div`
  display: flex;
  flex-direction: row;

  label {
    cursor: pointer;
  }

  > :first-child {
    padding-right: 0.5em;
  }

  .checkbox-label {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    line-height: 20px;
    height: 20px;
    width: 20px;
    clear: both;
  }

  .checkbox-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkbox-label .checkbox {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 20px;
    width: 20px;
    background-color: white;
    border-radius: 5px;
    transition: all 0.2s ease-out;
    border: 1px solid lightgray;
  }

  .checkbox-label input:checked ~ .checkbox {
    background-color: ${props => props.theme.colors.primaryColor};
    border-radius: 5px;
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }

  .checkbox-label .checkbox::after {
    position: absolute;
    content: '';
    left: 10px;
    top: 10px;
    height: 0px;
    width: 0px;
    border-radius: 5px;
    border: solid ${props => props.theme.colors.primaryColor};
    border-width: 0 3px 3px 0;
    transform: rotate(0deg) scale(0);
    opacity: 1;
    transition: all 0.1s ease-out;
  }

  .checkbox-label input:checked ~ .checkbox::after {
    transform: rotate(45deg) scale(1);
    opacity: 1;
    left: 6px;
    top: 3px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
  }
`;

export const Checkbox: FunctionComponent<CheckboxProps> = props => {
  const { id, label, style, ...rest } = props;

  return (
    <Container style={style}>
      <label className="checkbox-label">
        <input id={id} data-testid={id} type="checkbox" {...rest} />
        <span className="checkbox" />
      </label>
      {label && <label htmlFor={id}>Show password</label>}
    </Container>
  );
};
