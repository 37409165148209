import { StatusPillStatus } from '@fattmerchantorg/truffle-components/dist/StatusPill/StatusPill';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { DB } from '@fattmerchantorg/types-engine';

export function mapSettlementStateToStatus(
  state: DB.SettlementState | DB.PayoutState
): {
  label: string;
  status: StatusPillStatus;
  icon: FontAwesomeIconProps['icon'];
} {
  switch (state) {
    case 'APPROVED':
      return {
        label: 'Approved',
        status: 'success',
        icon: ['fas', 'check'],
      };
    case 'APPROVED.PAYOUT_ERROR':
      return {
        label: 'Payout Error',
        status: 'error',
        icon: ['fas', 'times'],
      };
    case 'PENDING':
      return {
        label: 'Pending',
        status: 'warning',
        icon: ['fas', 'ellipsis-h'],
      };
    case 'OPEN':
      return {
        label: 'Open',
        status: 'neutral',
        icon: ['fas', 'ellipsis-h'],
      };
    case 'FUNDED':
      return {
        label: 'Funded',
        status: 'success',
        icon: ['fas', 'check'],
      };
    case 'REJECTED':
      return {
        label: 'Rejected',
        status: 'error',
        icon: ['fas', 'times'],
      };
    case 'HOLD':
      return {
        label: 'Hold',
        status: 'warning',
        icon: ['fas', 'hand-paper'],
      };
    case 'PENDING.PAYOUT_ERROR':
      return {
        label: 'Payout Error',
        status: 'error',
        icon: ['fas', 'exclamation-triangle'],
      };
    case 'PAYOUTS_CALCULATED':
      return {
        label: 'Payouts Pending',
        status: 'neutral',
        icon: ['fas', 'check'],
      };
  }
}

export function mapSettlementStateToColor(state: DB.SettlementState) {
  let color: string | undefined;
  switch (state) {
    case 'PENDING':
    case 'HOLD':
      color = 'yellow';
      break;
    case 'APPROVED':
    case 'FUNDED':
      color = 'green';
      break;
    case 'REJECTED':
    case 'APPROVED.PAYOUT_ERROR':
      color = 'red';
      break;
    case 'OPEN':
    case 'PAYOUTS_CALCULATED':
      color = 'blue';
      break;
  }
  if (!color) {
    return undefined;
  }
  return color;
}
