import React, {
  FunctionComponent,
  useEffect,
  useContext,
  useState,
} from 'react';
import styled, { withTheme } from 'styled-components';

import { ToastProps } from '../Toast.types';
import { Toaster } from '../Toast.context';
import { removeToast } from '../Toast.actions';
import { memoize } from '../../../util/functional.util';
import { withAlpha } from '../../../util/color.util';

const getTextColorFromMotif = memoize((motif: ToastProps['motif']) => {
  switch (motif) {
    case 'success':
      return '#00660D';
    case 'warning':
      return '#A27500';
    case 'error':
      return '#3F0000';
    case 'info':
      return '#000F38';
  }
});

const getColorFromMotif = memoize((motif: ToastProps['motif']) => {
  switch (motif) {
    case 'success':
      return '#079940';
    case 'warning':
      return '#EEC11A';
    case 'error':
      return '#be1e2d';
    case 'info':
      return '#00426B';
  }
});

const Container = styled.div`
  box-shadow: rgba(0, 0, 0, 0.176) 0px 4px 8px;
  background: white;
  overflow: hidden;
  display: flex;
  border-radius: 2px;
  min-height: 50px;
  min-width: 250px;
  max-width: 459px;
  font-size: 14px;

  &.-entering {
    animation: it-is-toast-time 0.2s ease-in both;
  }

  &.-exiting {
    animation: it-is-no-longer-toast-time 0.2s ease-in both;
  }

  @media (max-width: 1000px) {
    max-width: 100%;
  }

  background: white;
  border: 1px solid ${({ theme }) => theme.colors.core.gray[600].hex};
`;

const IconPanel = withTheme(
  styled.div`
    position: relative;
    height: inherit;
    text-align: center;
    color: ${({ theme }) => theme.black};
    width: auto;
    padding: 8px;
    overflow: hidden;
    display: flex;
    align-items: center;
    background: ${(props: ToastProps) => getColorFromMotif(props.motif)};

    i {
      font-size: 24px;
      line-height: 18px;
    }
  `
);

const StalenessMeter = styled.div`
  opacity: ${(props: ToastProps) => (props.lifetime === 'forever' ? 0 : 1)};
  animation: ${(props: ToastProps) =>
    props.lifetime === 'forever'
      ? 'none'
      : `stale-toast-countdown ${props.lifetime}ms linear`};
  bottom: 0;
  height: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);

  position: absolute;
`;

const ContentPanel = withTheme(
  styled.div`
    padding: 16px 24px;
    flex: 1;
    background: ${({ theme }) => theme.colors.core.gray[800].hex};
    color: ${({ theme }) => theme.white};
  `
);

const ClosePanel = withTheme(
  styled.div`
    cursor: pointer;
    padding: 8px;
    transition: all 0.3s ease-in-out;
    background: ${({ theme }) => theme.colors.core.gray[800].hex};
    color: ${({ theme }) => theme.white};

    i {
      background: ${({ theme }) => theme.colors.core.gray[600].hex};
      padding: 3px 7px;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 14px;
      line-height: 18px;
      border-bottom-left-radius: 2px;
    }

    &:hover {
      color: ${(props: ToastProps) =>
        withAlpha(getTextColorFromMotif(props.motif), 0.6)};
    }
  `
);

const renderIcon = (motif: ToastProps['motif']) => {
  switch (motif) {
    case 'success':
      return <i className="fas fa-check" />;
    case 'warning': // falls through
    case 'error':
      return <i className="far fa-times-circle" />;
    case 'info':
      return <i className="fas fa-info-circle" />;
  }
};

const StyledTitle = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 14px;
  text-transform: uppercase;
`;

const StyledDescription = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`;

export const Toast: FunctionComponent<ToastProps> = props => {
  const { id, title, content, motif, lifetime } = props;

  const { dispatch } = useContext(Toaster);
  const [stale, setStale] = useState(false);

  useEffect(() => {
    if (lifetime !== 'forever') {
      const timeout = setTimeout(() => setStale(true), lifetime);
      return () => clearTimeout(timeout);
    }
  }, [lifetime, setStale]);

  useEffect(() => {
    if (stale) {
      const timeout = setTimeout(() => dispatch(removeToast(id)), 200);
      return () => clearTimeout(timeout);
    }
  }, [stale, dispatch, id]);

  return (
    <Container className={stale ? '-exiting' : '-entering'}>
      <IconPanel motif={motif}>
        <StalenessMeter lifetime={lifetime} />
        {renderIcon(props.motif)}
      </IconPanel>
      <ContentPanel motif={motif}>
        {title && <StyledTitle>{title}</StyledTitle>}
        <StyledDescription>{content}</StyledDescription>
      </ContentPanel>
      <ClosePanel motif={motif} role="button" onClick={() => setStale(true)}>
        <i className="fas fa-times" />
      </ClosePanel>
    </Container>
  );
};

Toast.defaultProps = {
  motif: 'info',
  lifetime: 5000,
};
