import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { useAuthToken } from '../../hooks';
import { decodeToken } from '../../util/jwt.util';
import { Copy } from './DevTools';
import { ShowJSON } from './ShowJSON';

export const JWT: FunctionComponent = () => {
  const jwt = useAuthToken();
  const jwtDecoded = decodeToken(jwt);

  const { sub: userId, merchant: merchantId, brand, iat, exp } = jwtDecoded;

  const issuedAt = moment(new Date(iat * 1000)).format(
    'MMMM Do YYYY, h:mm:ss a'
  );
  const expiresAt = moment(new Date(exp * 1000)).format(
    'MMMM Do YYYY, h:mm:ss a'
  );

  return (
    <>
      <h2>JWT</h2>
      <p>
        <b>Issued on:</b> {issuedAt}. <br />
        <b>Expires on:</b> {expiresAt}.
      </p>
      <Copy val={jwt} label="JWT" />
      <p>{jwt}</p>

      <h2>Decoded</h2>
      <Copy val={userId} label={`User Id: ${userId}`} />
      <Copy val={brand} label={`Brand: ${brand}`} />
      <Copy val={merchantId} label={`Merchant Id: ${merchantId}`} />
      <ShowJSON data={jwtDecoded} hideCollapseButton={true} />
    </>
  );
};
