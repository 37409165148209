import { AxiosError } from 'axios';
import { coreapi } from '../../../api';
import { isValidEmail } from '../../../util/validator.util';

export const checkEmail = async (
  email: string,
  initialValue: string,
  authToken: string
) => {
  if (!email) {
    return 'Required';
  }

  if (email === initialValue) return;

  if (!isValidEmail(email)) return 'Enter a Valid Email Address';

  try {
    await coreapi.get(
      authToken,
      `/self/exists?email=${encodeURIComponent(email)}`,
      undefined,
      undefined,
      (error: AxiosError) => error
    );
    return 'This email is already in use';
  } catch (error) {
    // If we get a 404 then the user's not found and the email is available
    // For other errors we log the error, but we don't display it.  If there's
    // a serious problem the error will be reported when the user actually
    // submits
    if (error?.response?.status !== 404) {
      console.log(error);
    }
  }
};

export const validateBrands = (brands: string[]): string | undefined => {
  return brands && brands.length > 0 ? undefined : 'brands should not be empty';
};

export const validateName = (name: string): string | undefined => {
  return name && name.length > 3
    ? undefined
    : 'Name should be more than 3 characters';
};
