import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import {
  Icon,
  SmallPrimaryButton,
  Text,
  Tooltip,
} from '@fattmerchantorg/truffle-components';

import { round } from '../../util/currency.util';
import {
  checkForDifferentRates,
  createEmptySettlementStatement,
  createFillerRows,
  getFeeTypeNiceName,
} from './util';

import { queryapi } from '../../api';
import { Header } from './components/Header';
import { StatementContainer } from './components/StatementContainer';
import { TopSection } from './components/TopSection';
import { StatementDisclaimer } from './components/StatementDisclaimer';
import { SummarySection } from './components/SummarySection';
import { TableSection } from './components/TableSection';
import { StatementMessageSection } from './components/StatementMessageSection';
import { SummaryRow } from './components/SummaryRow';
import { formatCurrency } from '../../util';
import { useRouteMatch } from 'react-router-dom';
import { PermissionsStore, SelectedMerchantStore } from '../../context';
import { useAsyncEffect, useAuthToken } from '../../hooks';
import { TextContainer } from './components';

export const DetailedSettlementStatement: FunctionComponent = () => {
  const match = useRouteMatch<{ activityPeriodOrBatchId?: string }>();
  const activityPeriodOrBatchId = match?.params?.activityPeriodOrBatchId;
  let activityPeriod: string | null = null;
  let batchId: string | null = null;

  if (/\d{4}-\d{2}-\d{2}/.test(activityPeriodOrBatchId)) {
    activityPeriod = activityPeriodOrBatchId;
  } else {
    batchId = activityPeriodOrBatchId;
  }

  const [statement, setStatement] = useState(createEmptySettlementStatement());

  const {
    state: { merchant },
  } = useContext(SelectedMerchantStore);
  const authToken = useAuthToken();
  const {
    state: { brand },
  } = useContext(PermissionsStore);
  const logoUrl = brand?.darkLogo?.[0]?.url;

  const merchantId = merchant?.id;

  useAsyncEffect(async () => {
    setStatement(s => ({ ...s, ready: false }));

    let startDate, endDate: string;

    if (
      /\d{4}-\d{2}-\d{2}-to-\d{4}-\d{2}-\d{2}/.test(activityPeriodOrBatchId)
    ) {
      const [start, end] = activityPeriodOrBatchId.split('-to-');
      startDate = start;
      endDate = end;
    } else {
      startDate = moment(activityPeriod).startOf('month').format('YYYY-MM-DD');
      endDate = moment(activityPeriod).endOf('month').format('YYYY-MM-DD');
    }

    if (merchantId && authToken) {
      const statement = await queryapi.get(
        authToken,
        'statement/settlement/summary',
        {
          startDate,
          endDate,
          batchId,
        }
      );

      setStatement({ ready: true, ...statement });
    }
  }, [authToken, merchantId, activityPeriod, batchId]);

  const {
    ready,
    dateSettlements,
    cardTransactions,
    cardPresentTransactions,
    cardNotPresentTransactions,
    summary,
    additionalFees,
    statementMessages,
  } = statement;

  const isSurcharge =
    !!merchant?.is_surcharge_enabled ||
    !!cardTransactions?.data.find(t => t.surcharge_total > 0);

  // TODO: add back once the numbers are correct
  //   const totalTooltipContent =
  //     'Total is based on the summed net settlement totals for the statement period.';

  const reportName = merchant?.company_name.replace(/ /g, '_');

  if (!merchant) return null;

  return (
    <div>
      <Header className="-print-hide">
        <Text as="h4">Fee Statement</Text>
        <div>
          <SmallPrimaryButton
            icon={['fas', 'print']}
            onClick={window.print}
            disabled={!ready}
            trackingId="detailed-statement-print"
            data-testid="detailed-statement-print"
          >
            Print
          </SmallPrimaryButton>
        </div>
      </Header>
      <StatementContainer id="statement">
        <Helmet>
          <title>
            {reportName}-Statement-Report-
            {batchId ? batchId : moment(activityPeriod).format('MMM-YYYY')}
          </title>
        </Helmet>
        <TopSection>
          {ready && merchant ? (
            <>
              <div>
                <Text as="span">{merchant.company_name}</Text>
                <Text as="span">
                  {`${merchant.address_1} ${merchant.address_2}`.trim()}
                </Text>
                <Text as="span">
                  {merchant.address_city} {merchant.address_state}{' '}
                  {merchant.address_zip}
                </Text>
              </div>
              <div>
                {logoUrl && <img src={logoUrl} alt={merchant.company_name} />}
              </div>
            </>
          ) : (
            <Skeleton width={100} height={40} />
          )}
        </TopSection>

        <TopSection style={{ justifyContent: 'center' }}>
          <TextContainer align="center" fontWeight="bold">
            <Text as="h4">Merchant Statement</Text>
          </TextContainer>
        </TopSection>

        <TopSection>
          <div>
            <TextContainer fontWeight="bold">
              <Text as="h5">Merchant Number</Text>
            </TextContainer>
            <Text as="h5">{merchant.mid}</Text>
          </div>
          <div>
            <TextContainer fontWeight="bold">
              <Text as="h5">
                {batchId ? 'Statement Batch ID' : 'Statement Period'}
              </Text>
            </TextContainer>
            <Text as="h5">
              {batchId ? batchId : moment(activityPeriod).format('MMM YYYY')}
            </Text>
          </div>
        </TopSection>

        <StatementDisclaimer>
          Fee statements are generated based on deposits that are created in a
          given fee statement month. If a deposit was created in the month, any
          transactions that are a part of that deposit will be included.
        </StatementDisclaimer>

        <div>
          <TextContainer fontWeight="bold" paddingBottom="8px">
            <Text as="h5">SUMMARY</Text>
          </TextContainer>

          <SummarySection>
            <div>
              {!merchant.is_flat_rate && (
                <SummaryRow>
                  <Text as="span">INTERCHANGE FEES:</Text>
                  {ready ? (
                    <Text as="span">
                      {formatCurrency(summary.interchangeFees)}
                    </Text>
                  ) : (
                    <Skeleton width={50} />
                  )}
                </SummaryRow>
              )}
              <SummaryRow>
                <Text as="span">CARD PROCESSING FEES:</Text>
                {ready ? (
                  <Text as="span">
                    {formatCurrency(summary.cardProcessingFees)}
                  </Text>
                ) : (
                  <Skeleton width={50} />
                )}
              </SummaryRow>
              <SummaryRow>
                <Text as="span">
                  {merchant.is_flat_rate
                    ? 'FLAT RATE FEES:'
                    : 'CARD BRAND ASSESSMENTS:'}
                </Text>
                {ready ? (
                  <Text as="span">{formatCurrency(summary.flatRateFees)}</Text>
                ) : (
                  <Skeleton width={50} />
                )}
              </SummaryRow>
              {merchant.gateways.some(g =>
                ['ACH', 'TEST'].includes(g.type)
              ) && (
                <SummaryRow>
                  <Text as="span">ACH FEES:</Text>

                  {ready ? (
                    <Text as="span">
                      {formatCurrency(summary.achApplicationFees)}
                    </Text>
                  ) : (
                    <Skeleton width={50} />
                  )}
                </SummaryRow>
              )}
              <SummaryRow>
                <Text as="span">ADDITIONAL FEES:</Text>
                {ready ? (
                  <Text as="span">{formatCurrency(summary.otherFees)}</Text>
                ) : (
                  <Skeleton width={50} />
                )}
              </SummaryRow>
            </div>
            {/* TODO: add back once totals are correct */}
            {/* <div>
              <Text as="h5" fontWeight="bold">
                TOTAL{' '}
                <Tooltip content={totalTooltipContent}>
                  <Icon className="-print-hide" icon={['fal', 'info-circle']} />
                </Tooltip>
              </Text>
              {ready ? (
                <Text as="h2" fontWeight="bold">
                  {formatCurrency(summary.totalNetDeposits)}
                </Text>
              ) : (
                <Skeleton width={100} height={36} />
              )}
            </div> */}
            {/* <div>
              <Text as="h5" fontWeight="bold">
                TOTAL FEES{' '}
                {isSurcharge ? (
                  <Tooltip
                    content={
                      'Total fees are inclusive of debit card, ACH, and/or other processing fees (e.g. dispute fees).'
                    }
                  >
                    <Icon
                      className="-print-hide"
                      icon={['fal', 'info-circle']}
                    />
                  </Tooltip>
                ) : null}
              </Text>

              {ready ? (
                <Text
                  as="h2"
                  fontWeight="bold"
                  data-testid="summary-total-fees"
                >
                  {formatCurrency(summary.totalFees)}
                </Text>
              ) : (
                <Skeleton width={100} height={36} />
              )}
            </div> */}
          </SummarySection>
        </div>

        <StatementDisclaimer>
          For reconciliation, please use the data provided in the deposit
          section as all other sections are subject to rounding.
        </StatementDisclaimer>

        <TableSection>
          <TextContainer fontWeight="bold">
            <Text as="h5">Deposits</Text>
          </TextContainer>
          <table data-testid="settlements-by-day-table">
            <thead>
              <tr>
                <th>Date</th>
                <th># of Sales</th>

                <th>Adjustments</th>
                <th>Chargebacks</th>

                <th>Gross Settlement Total</th>
                <th>Fees</th>
                <th>
                  <span>Net Settlement Total</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {ready
                ? dateSettlements.data.map((datum, i) => (
                    <tr key={i}>
                      <td>
                        {moment(datum.date).format('DD-MMM').toUpperCase()}
                      </td>
                      <td>{datum.gross_sales_count}</td>
                      <td>{formatCurrency(datum.adjustments_total)}</td>
                      <td>{formatCurrency(datum.chargebacks_total)}</td>
                      <td>{formatCurrency(datum.gross_settlement_total)}</td>
                      <td>{formatCurrency(datum.fees_total)}</td>
                      <td>{formatCurrency(datum.net_settlement_total)}</td>
                    </tr>
                  ))
                : createFillerRows(7)}
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <strong>TOTAL</strong>
                </td>
                <td>{dateSettlements.summary.gross_sales_count}</td>
                <td>
                  {formatCurrency(dateSettlements.summary.adjustments_total)}
                </td>
                <td>
                  {formatCurrency(dateSettlements.summary.chargebacks_total)}
                </td>
                <td>
                  {formatCurrency(
                    dateSettlements.summary.gross_settlement_total
                  )}
                </td>
                <td>{formatCurrency(dateSettlements.summary.fees_total)}</td>
                <td>
                  {formatCurrency(dateSettlements.summary.net_settlement_total)}
                </td>
              </tr>
            </tfoot>
          </table>
        </TableSection>

        <div>
          <TableSection>
            <TextContainer fontWeight="bold">
              <Text as="h5">Volumes</Text>
            </TextContainer>
            <table>
              <thead>
                <tr>
                  <th>Type</th>
                  <th># of Sales</th>
                  <th>Gross Sales</th>
                  <th># of Adjustments</th>
                  <th>Adjustments</th>
                  <th># of Chargebacks</th>
                  <th>Chargebacks</th>
                  <th>Average Ticket</th>
                  {merchant.is_flat_rate && <th>Rate</th>}
                </tr>
              </thead>
              <tbody>
                {ready
                  ? cardTransactions.data.map((datum, i) => (
                      <tr key={i}>
                        <td>{(datum.card_type || '').toUpperCase()}</td>
                        <td>{datum.gross_sales_count}</td>
                        <td>{formatCurrency(datum.gross_sales_total)}</td>
                        <td>{datum.adjustments_count}</td>
                        <td>{formatCurrency(datum.adjustments_total)}</td>
                        <td>{datum.chargebacks_count}</td>
                        <td>{formatCurrency(datum.chargebacks_total)}</td>
                        <td>{formatCurrency(datum.gross_sale_average)}</td>
                        {merchant.is_flat_rate && (
                          <td>{round(+datum.rate).toFixed(2)}</td>
                        )}
                      </tr>
                    ))
                  : createFillerRows(merchant.is_flat_rate ? 9 : 8)}
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <strong>TOTAL</strong>
                  </td>
                  <td>{cardTransactions.summary.gross_sales_count}</td>
                  <td>
                    {formatCurrency(cardTransactions.summary.gross_sales_total)}
                  </td>
                  <td>{cardTransactions.summary.adjustments_count}</td>
                  <td>
                    {formatCurrency(cardTransactions.summary.adjustments_total)}
                  </td>
                  <td>{cardTransactions.summary.chargebacks_count}</td>
                  <td>
                    {formatCurrency(cardTransactions.summary.chargebacks_total)}
                  </td>
                  <td>
                    {formatCurrency(
                      cardTransactions.summary.gross_sale_average
                    )}
                  </td>
                  {merchant.is_flat_rate && <td>--</td>}
                </tr>
              </tfoot>
            </table>
          </TableSection>

          {cardPresentTransactions?.data?.length ? (
            <TableSection style={{ paddingTop: '12px' }}>
              <TextContainer fontWeight="bold">
                <Text as="h5">Processing Fees - Card Present</Text>
              </TextContainer>
              <table>
                <thead>
                  <tr>
                    <th>Type</th>
                    {!merchant.is_flat_rate && <th>Interchange Fees</th>}
                    <th>Card Processing Fees</th>
                    <th>
                      {merchant.is_flat_rate
                        ? 'Flat Rate Fees'
                        : 'Card Brand Assessments'}
                    </th>
                    <th># Trans</th>
                    <th>
                      Rate{' '}
                      {checkForDifferentRates(
                        cardPresentTransactions.data.map(
                          ({ card_type }) => card_type
                        )
                      ) ? (
                        <Tooltip content="Rates differ due to a pricing adjustment made during the statement period.">
                          <Icon
                            icon={['fal', 'info-circle']}
                            className="-print-hide"
                          />
                        </Tooltip>
                      ) : null}
                    </th>
                    <th>Total Fees</th>
                  </tr>
                </thead>
                <tbody>
                  {ready
                    ? cardPresentTransactions.data.map((datum, i) => (
                        <tr key={i}>
                          <td>{(datum.card_type || '').toUpperCase()}</td>
                          {!merchant.is_flat_rate && (
                            <td>
                              {formatCurrency(datum.interchange_fees_total)}
                            </td>
                          )}
                          <td>{formatCurrency(datum.fixed_fees_total)}</td>
                          <td>
                            {formatCurrency(datum.basis_points_fees_total)}
                          </td>
                          <td>{datum.transactions_count}</td>
                          <td>{datum.rate}</td>
                          <td>{formatCurrency(datum.fees_total)}</td>
                        </tr>
                      ))
                    : createFillerRows(4)}
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <strong>TOTAL</strong>
                    </td>
                    {!merchant.is_flat_rate && (
                      <td data-testid="processing-fees-interchange-fees-total">
                        {formatCurrency(
                          cardPresentTransactions.summary.interchange_fees_total
                        )}
                      </td>
                    )}
                    <td data-testid="processing-fees-fixed-fees-total">
                      {formatCurrency(
                        cardPresentTransactions.summary.fixed_fees_total
                      )}
                    </td>
                    <td data-testid="processing-fees-basis-points-fees-total">
                      {formatCurrency(
                        cardPresentTransactions.summary.basis_points_fees_total
                      )}
                    </td>
                    <td></td>
                    <td></td>
                    <td data-testid="processing-fees-fees-total">
                      {formatCurrency(
                        cardPresentTransactions.summary.fees_total
                      )}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </TableSection>
          ) : null}

          {cardNotPresentTransactions?.data?.length ? (
            <TableSection style={{ paddingTop: '12px' }}>
              <TextContainer fontWeight="bold">
                <Text as="h5">Processing Fees - Card Not Present</Text>
              </TextContainer>
              <table>
                <thead>
                  <tr>
                    <th>Type</th>
                    {!merchant.is_flat_rate && <th>Interchange Fees</th>}
                    <th>Card Processing Fees</th>
                    <th>
                      {merchant.is_flat_rate
                        ? 'Flat Rate Fees'
                        : 'Card Brand Assessments'}
                    </th>
                    <th># Trans</th>
                    <th>
                      Rate{' '}
                      {checkForDifferentRates(
                        cardNotPresentTransactions.data.map(
                          ({ card_type }) => card_type
                        )
                      ) ? (
                        <Tooltip content="Rates differ due to a pricing adjustment made during the statement period.">
                          <Icon
                            icon={['fal', 'info-circle']}
                            className="-print-hide"
                          />
                        </Tooltip>
                      ) : null}
                    </th>
                    <th>Total Fees</th>
                  </tr>
                </thead>
                <tbody>
                  {ready
                    ? cardNotPresentTransactions.data.map((datum, i) => (
                        <tr key={i}>
                          <td>{(datum.card_type || '').toUpperCase()}</td>
                          {!merchant.is_flat_rate && (
                            <td>
                              {formatCurrency(datum.interchange_fees_total)}
                            </td>
                          )}
                          <td>{formatCurrency(datum.fixed_fees_total)}</td>
                          <td>
                            {formatCurrency(datum.basis_points_fees_total)}
                          </td>
                          <td>{datum.transactions_count}</td>
                          <td>{datum.rate}</td>
                          <td>{formatCurrency(datum.fees_total)}</td>
                        </tr>
                      ))
                    : createFillerRows(4)}
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <strong>TOTAL</strong>
                    </td>
                    {!merchant.is_flat_rate && (
                      <td data-testid="processing-fees-interchange-cnp-fees-total">
                        {formatCurrency(
                          cardNotPresentTransactions.summary
                            .interchange_fees_total
                        )}
                      </td>
                    )}
                    <td data-testid="processing-fees-fixed-cnp-fees-total">
                      {formatCurrency(
                        cardNotPresentTransactions.summary.fixed_fees_total
                      )}
                    </td>
                    <td data-testid="processing-fees-basis-points-cnp-fees-total">
                      {formatCurrency(
                        cardNotPresentTransactions.summary
                          .basis_points_fees_total
                      )}
                    </td>
                    <td></td>
                    <td></td>
                    <td data-testid="processing-fees-cnp-fees-total">
                      {formatCurrency(
                        cardNotPresentTransactions.summary.fees_total
                      )}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </TableSection>
          ) : null}

          {isSurcharge && cardTransactions?.summary?.surcharge_count ? (
            <TableSection style={{ paddingTop: '12px' }}>
              <TextContainer fontWeight="bold">
                <Text as="h5">Surcharge</Text>
              </TextContainer>
              <table data-testid="surcharge-table">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th># of Sales</th>
                    <th>Surcharge Total</th>
                  </tr>
                </thead>
                <tbody>
                  {ready
                    ? cardTransactions.data
                        // we aren't interested in the row for this table if there are no surcharging fees
                        .filter(datum => datum.surcharge_count > 0)
                        .map((datum, i) => (
                          <tr key={i}>
                            <td>{(datum.card_type || '').toUpperCase()}</td>
                            <td>{datum.surcharge_count}</td>
                            <td>{formatCurrency(datum.surcharge_total)}</td>
                          </tr>
                        ))
                    : createFillerRows(4)}
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <strong>TOTAL</strong>
                    </td>
                    <td>{cardTransactions.summary.surcharge_count}</td>
                    <td>
                      {formatCurrency(cardTransactions.summary.surcharge_total)}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </TableSection>
          ) : null}
        </div>

        <TableSection>
          <TextContainer fontWeight="bold">
            <Text as="h5">Additional Fees</Text>
          </TextContainer>
          <table>
            <thead>
              <tr>
                <th>Type</th>
                <th>Fee</th>
                <th>Count</th>
                <th>Amount</th>
              </tr>
            </thead>
            {ready && additionalFees.data.length ? (
              <Fragment>
                <tbody>
                  {additionalFees.data.map((datum, i) => (
                    <tr key={i}>
                      <td>{datum.name || getFeeTypeNiceName(datum.type)}</td>
                      <td>{formatCurrency(datum.average)}</td>
                      <td>{datum.count}</td>
                      <td>{formatCurrency(datum.sum)}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <strong>TOTAL</strong>
                    </td>
                    <td>-</td>
                    <td>{additionalFees.summary.count}</td>
                    <td>{formatCurrency(summary.otherFees)}</td>
                  </tr>
                </tfoot>
              </Fragment>
            ) : (
              <Fragment>
                <tbody>
                  {ready ? (
                    <tr>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                  ) : (
                    createFillerRows(4)
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td>
                      <strong>TOTAL</strong>
                    </td>
                    <td>-</td>
                    <td>0</td>
                    <td>{formatCurrency(0)}</td>
                  </tr>
                </tfoot>
              </Fragment>
            )}
          </table>
        </TableSection>

        {statementMessages ? (
          <StatementMessageSection data-testid="custom-messages">
            {statement.statementMessages.map((message, index) => (
              <div key={index}>{message}</div>
            ))}
          </StatementMessageSection>
        ) : null}
      </StatementContainer>
    </div>
  );
};
