import { useState, useEffect } from 'react';
import { AuthMode, OptimisticUIStatuses } from '../context';

export default (
  mode: AuthMode,
  backgroundProcessBegin: Function,
  backgroundProcessFinished: Function,
  optimisticUIStatus: OptimisticUIStatuses
) => {
  const [optimisticState, setOptimisticState] = useState<boolean>(false);

  // Handle automatic redirects. If "mode" happens to be different than the optimistic UI value, then "mode" takes over.
  // Ex: being in "Live Mode" and copy/paste a "Test Mode" link in the browser.
  useEffect(() => {
    setOptimisticState(mode === 'TEST_MODE');
  }, [mode]);

  // If user clicks toggle, update UI state and start background process.
  const handleOptimisticSwitchChange = () => {
    setOptimisticState(o => !o);
    backgroundProcessBegin();
  };

  // Undo optimistic status if background process fails.
  useEffect(() => {
    if (optimisticUIStatus === OptimisticUIStatuses.Undo) {
      setOptimisticState(o => !o);
      backgroundProcessFinished();
    }
  }, [optimisticUIStatus, backgroundProcessFinished]);

  return { optimisticState, setOptimisticState, handleOptimisticSwitchChange };
};
