import React, { FunctionComponent, useState } from 'react';
import copy from 'copy-to-clipboard';
import styled from 'styled-components';
import { formatTruncatedId } from '../../../util/format.util';
import { Tooltip, Text } from '@fattmerchantorg/truffle-components';
import { ConditionalWrapper } from '../ConditionalWrapper';
import { IdPropsToolTip } from './IdCustomToolTips';

const StyledId = styled.span`
  border: 1px dashed lightgray;
  border-radius: 3px;
  padding: 0.2rem;
  cursor: pointer;
`;

export const IdCustomToolTip: FunctionComponent<IdPropsToolTip> = props => {
  const { record, ...restOfProps } = props;
  const id = props.id || (record && record.id);
  const toolTip = props.toolTip || (record && record.toolTip);
  const truncatedId = formatTruncatedId(id) || 'None';
  const [copied, setCopied] = useState<boolean>(false);

  return (
    <StyledId
      {...restOfProps}
      id={`uuid-${id}`}
      data-testid={`uuid-${id}`}
      onClick={e => {
        e.stopPropagation();
        copy(id);
        setCopied(true);
      }}
    >
      <ConditionalWrapper
        condition={!!id}
        wrapper={children => (
          <Tooltip
            content={!copied ? `${toolTip}` : 'Copied'}
            hideOnClick={false}
            allowHTML={true}
            onHidden={() => setCopied(false)}
          >
            {children}
          </Tooltip>
        )}
      >
        <Text as="span">{truncatedId}</Text>
      </ConditionalWrapper>
    </StyledId>
  );
};
