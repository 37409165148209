import React, { useState, useContext, useMemo } from 'react';
import { Merchant, User, Registration } from '@fattmerchantorg/types-omni';
import { Form, Field, Alert, FieldError } from '../../shared';
import { Select, TextField } from '@fattmerchantorg/truffle-components';
import {
  ModalHeader,
  ModalContent,
  PrimaryButton,
  SecondaryButton,
} from '@fattmerchantorg/truffle-components';
import {
  SelectedMerchantStore,
  updateSelectedMerchant,
} from '../../../context';
import { coreapi } from '../../../api';
import { useAuthToken, useToaster, usePermissions } from '../../../hooks';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import { history } from '../../../history';

type FormValues = {
  linkUserIds: Array<{ value: string; label: string }>;
  shouldCloneOnboardingInformation: boolean;
  shouldCloneTrust: boolean;
  original: {
    merchant: Pick<Merchant, 'company_name'>;
    registration: Pick<
      Registration,
      | 'pricing_plan'
      | 'bank_routing_number'
      | 'bank_account_number'
      | 'bank_account_owner_name'
      | 'bank_account_type'
    >;
  };
  trust: FormValues['original'];
};

interface CloneMerchantFormProps {
  merchant: Merchant;
  onModalClose: () => void;
}
const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
  button {
    margin: 0 0 0 10px;
  }
`;

const FieldWrap = styled.div`
  margin-bottom: 0.75rem;
`;

const TrustHeaders = styled.h3`
  color: #fff;
  font-weight: 700;
`;

const SubTitle = styled.p`
  font-size: 18px;
  text-align: center;
  span {
    font-weight: 700;
  }
`;

const SelectLabel = styled.label`
  display: inline-block;
  font-size: 14px;
  line-height: 1.5;
  color: #ffffff;
  margin-bottom: 0.5rem;
  &[data-required*='true']:after {
    content: '*';
    color: #be1e2d;
    margin-left: 0.5ex;
  }
`;

const getNewCompanyName = (merchant: Merchant, type: 'trust' | 'operating') => {
  const suffix = type === 'trust' ? '- Trust' : '- Operating';
  const wrongSuffix = type === 'trust' ? '- Operating' : '- Trust';
  return `${merchant.company_name
    .replace(wrongSuffix, '')
    .replace(suffix, '')} ${suffix}`;
};

const accountTypeOptions: Array<{ label: string; value: string }> = [
  { value: 'checking', label: 'Checking' },
  { value: 'savings', label: 'Savings' },
  { value: 'trust', label: 'Trust' },
];

export const CloneMerchantForm = ({
  merchant,
  onModalClose,
}: CloneMerchantFormProps) => {
  const authToken = useAuthToken();
  const { toaster, toast } = useToaster();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [formStep, setFormStep] = useState<1 | 2>(1);
  const { permit } = usePermissions();
  const {
    dispatch: selectedMerchantDispatch,
    state: { brand: brandData, registration },
  } = useContext(SelectedMerchantStore);

  const userOptions = useMemo(() => {
    return merchant.users.reduce((users, user) => {
      if (!user.is_api_key) {
        users.push({
          value: user.id,
          label: `${user.name} (${user.email})`,
        });
      }

      return users;
    }, []);
  }, [merchant.users]);

  // non-override pricing plans
  const standardPricingPlanOptions = useMemo(() => {
    const pricingPlans = brandData?.data?.filteredPricingFields ?? [];

    return pricingPlans
      .filter(plan => {
        return (
          plan.productType &&
          plan.productType.includes(registration?.chosen_processing_method)
        );
      })
      .map(plan => {
        return { label: plan.displayName, value: plan.name };
      });
  }, [brandData, registration]);

  // override pricing plans
  const overridePricingPlanOptions = useMemo(() => {
    const pricingPlans = brandData?.data?.filteredPricingFields ?? [];

    return pricingPlans
      .filter(plan => {
        const types = plan.productType ? plan.productType : [];
        return !types.includes(registration?.chosen_processing_method);
      })
      .map(plan => {
        return {
          label: plan.displayName,
          value: plan.name,
          isDisabled: !permit('godview', 'overridePricing', 'write'),
        };
      });
  }, [brandData, permit, registration]);

  const pricingPlanOptions = useMemo(() => {
    return [
      {
        label: `Available Plans for ${registration?.chosen_processing_method}`,
        options: standardPricingPlanOptions,
      },
      {
        label: `Unavailable Plans ${
          permit('godview', 'overridePricing', 'write')
            ? '(Available Through Override Pricing)'
            : ''
        }`,
        options: overridePricingPlanOptions,
      },
    ];
  }, [
    overridePricingPlanOptions,
    standardPricingPlanOptions,
    registration,
    permit,
  ]);

  const initialValues = useMemo(() => {
    return {
      shouldCloneOnboardingInformation: true,
      linkUserIds: userOptions,
      shouldCloneTrust: false,
      original: {
        merchant: {
          company_name: getNewCompanyName(merchant, 'operating'),
        },
        registration: {
          pricing_plan: registration?.pricing_plan,
          bank_routing_number: registration?.bank_routing_number,
          bank_account_number: registration?.bank_account_number,
          bank_account_owner_name: registration?.bank_account_owner_name,
          bank_account_type: 'checking', // default to checking
        },
      },
      trust: {
        merchant: {
          company_name: getNewCompanyName(merchant, 'trust'),
        },
        registration: {
          pricing_plan: null,
          bank_routing_number: registration?.secondary_bank_routing_number,
          bank_account_number: registration?.secondary_bank_account_number,
          bank_account_owner_name:
            registration?.secondary_bank_account_owner_name,
          bank_account_type: 'trust', // default to trust
        },
      },
    };
  }, [userOptions, registration, merchant]);

  // Clone Merchant Submit Action
  const handleCloneSubmit = async (formValues: FormValues) => {
    setIsProcessing(true);

    const {
      linkUserIds,
      shouldCloneOnboardingInformation,
      shouldCloneTrust,
      trust,
      original,
    } = formValues;

    const payload: {
      merchant?: Partial<Merchant>;
      registration?: Partial<Registration>;
    } = {};

    const { registration: originalRegistration } = original;

    // If cloning onboarding information bring over new pricing plan for new merchant
    if (shouldCloneOnboardingInformation) {
      payload.registration = {};
      payload.registration.pricing_plan = trust.registration.pricing_plan;
      payload.registration.registration_source = 'staxconnect';
    }

    if (shouldCloneTrust && shouldCloneOnboardingInformation) {
      payload.merchant = trust.merchant;
      payload.registration = trust.registration;

      // clone's secondary bank account should have the primary data of the operating/original bank account (from the operating MID)
      payload.registration.secondary_bank_routing_number =
        originalRegistration.bank_routing_number;
      payload.registration.secondary_bank_account_number =
        originalRegistration.bank_account_number;
      payload.registration.secondary_bank_account_owner_name =
        originalRegistration.bank_account_owner_name;
      payload.registration.secondary_bank_account_type = 'checking';
      payload.registration.secondary_bank_account_purpose = 'fees';
      payload.registration.bank_account_type = 'trust';
    }

    try {
      // Clone the merchant
      const newMerchant: Merchant = await coreapi.post(
        authToken,
        `merchant/${merchant.id}/clone?withRegistration=${shouldCloneOnboardingInformation}&forceGodMode=true`,
        payload
      );
      if (shouldCloneTrust && shouldCloneOnboardingInformation) {
        // update original merchant with new company name and any other changed values
        await coreapi.put(
          authToken,
          `merchant/${merchant.id}`,
          original.merchant
        );

        // Clear the secondary bank fields on original account, now that we've copied them
        // over to the clone account as the primary bank fields.
        await coreapi.put(authToken, `merchant/${merchant.id}/registration`, {
          ...originalRegistration,
          secondary_bank_routing_number: '',
          secondary_bank_account_number: '',
          secondary_bank_account_owner_name: '',
          secondary_bank_account_type: '',
        });
      }
      // Link users (if users were selected)
      await Promise.all(
        linkUserIds.map(userObj => {
          const user: User = merchant.users.find(
            mUser => mUser.id === userObj.value
          );
          return user.id
            ? coreapi.post(
                authToken,
                `merchant/${newMerchant.id}/user/${user.id}`,
                {
                  team_role: user.team_role,
                }
              )
            : null;
        })
      );

      // update the selected merchant right away to avoid loading state
      selectedMerchantDispatch(updateSelectedMerchant(newMerchant));
      // switch to new cloned merchant (a change to the merchant id param in the url will refetch the merchant, but we need to do this to get the new company name and anything else that may have changed on the back end)
      history.push(`/merchant/${newMerchant.id}/businessinfo`);

      // Close clone modal
      onModalClose();

      toaster(
        toast.success(
          `${merchant.company_name} has been cloned; you are now seeing the new clone.`,
          'Cloned'
        )
      );
      setIsProcessing(false);
    } catch (e) {
      setIsProcessing(false);
      toaster(toast.error(e, 'Error Cloning Merchant'));
    }
  };

  const shouldShowTrustSetup = (formValues: FormValues) => {
    return (
      permit('godview', 'pii', 'write') &&
      brandData?.data?.flags?.shouldShowTrustAndOperating &&
      formValues.shouldCloneOnboardingInformation
    );
  };

  return (
    <>
      <ModalHeader title="Clone Merchant" onClose={onModalClose} />
      <ModalContent data-testid="clone-merchant-modal-content">
        {formStep === 1 && (
          <>
            <SubTitle>
              Are you sure you want to clone <br />
              <span>{merchant.company_name}</span>?
            </SubTitle>
            <p style={{ textAlign: 'center' }}>
              All settings will be transferred to copy and will need to be
              modified accordingly.
            </p>
            <ButtonRow>
              <SecondaryButton type="button" onClick={onModalClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton
                type="submit"
                onClick={() => setFormStep(2)}
                data-testid="clone-merchant-confirm"
              >
                Continue
              </PrimaryButton>
            </ButtonRow>
          </>
        )}
        {formStep === 2 && (
          <>
            <Form<FormValues>
              onSubmit={handleCloneSubmit}
              showToastOnFailedSubmit={false}
              validate={({ trust, shouldCloneOnboardingInformation }) => {
                const errors: any = {
                  trust: {
                    registration: {},
                  },
                };
                if (
                  !trust.registration.pricing_plan &&
                  shouldCloneOnboardingInformation
                ) {
                  errors.trust.registration.pricing_plan = 'Required';
                }
                return errors;
              }}
              initialValues={initialValues}
            >
              {formProps => {
                return (
                  <>
                    <p>
                      Please select the users that you would like to link to the
                      cloned{' '}
                      <b style={{ fontWeight: 700 }}>{merchant.company_name}</b>{' '}
                      merchant.
                    </p>
                    <SelectLabel>Users</SelectLabel>
                    <Field name="linkUserIds" clearable={false}>
                      {fieldProps => (
                        <Select
                          {...fieldProps.input}
                          options={userOptions}
                          styles={{
                            menuPortal: base => ({ ...base, zIndex: 9999 }),
                            input: base => ({
                              ...base,
                              color: 'white',
                              padding: 0,
                            }),
                          }}
                          onChange={el => {
                            fieldProps.input.onChange(el);
                          }}
                          menuPortalTarget={document.body}
                          menuShouldScrollIntoView={false}
                          isSearchable={true}
                          menuPosition="fixed"
                          isMulti
                        />
                      )}
                    </Field>
                    <FieldWrap>
                      <label>
                        <Field
                          name="shouldCloneOnboardingInformation"
                          type="checkbox"
                          component="input"
                        />{' '}
                        Clone Onboarding Info
                      </label>
                    </FieldWrap>
                    {formProps.values.shouldCloneOnboardingInformation && (
                      <Row>
                        <Col>
                          <FieldWrap>
                            <SelectLabel>Original Pricing Plan</SelectLabel>
                            <Field
                              name="original.registration.pricing_plan"
                              clearable={false}
                              format={value => {
                                return [
                                  ...standardPricingPlanOptions,
                                  ...overridePricingPlanOptions,
                                ].find(option => option.value === value);
                              }}
                            >
                              {fieldProps => (
                                <Select
                                  {...fieldProps.input}
                                  options={pricingPlanOptions}
                                  isDisabled
                                  styles={{
                                    menuPortal: base => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                    input: base => ({
                                      ...base,
                                      color: 'white',
                                      padding: 0,
                                    }),
                                  }}
                                  onChange={({ value }) => {
                                    fieldProps.input.onChange(value);
                                  }}
                                  menuPortalTarget={document.body}
                                  menuShouldScrollIntoView={false}
                                  isSearchable={true}
                                  menuPosition="fixed"
                                />
                              )}
                            </Field>
                          </FieldWrap>
                        </Col>
                        <Col>
                          <FieldWrap data-testid="trust-registration-pricing-plan">
                            <SelectLabel data-required>
                              Clone Pricing Plan
                            </SelectLabel>
                            <Field
                              name="trust.registration.pricing_plan"
                              clearable={false}
                              format={value => {
                                return [
                                  ...standardPricingPlanOptions,
                                  ...overridePricingPlanOptions,
                                ].find(option => option.value === value);
                              }}
                            >
                              {fieldProps => (
                                <Select
                                  {...fieldProps.input}
                                  options={pricingPlanOptions}
                                  styles={{
                                    menuPortal: base => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                    input: base => ({
                                      ...base,
                                      color: 'white',
                                      padding: 0,
                                    }),
                                  }}
                                  onChange={({ value }) => {
                                    fieldProps.input.onChange(value);
                                  }}
                                  menuPortalTarget={document.body}
                                  menuShouldScrollIntoView={false}
                                  isSearchable={true}
                                  menuPosition="fixed"
                                />
                              )}
                            </Field>
                            {formProps.submitFailed && (
                              <FieldError
                                name="trust.registration.pricing_plan"
                                validateOn="error"
                              />
                            )}
                          </FieldWrap>
                        </Col>
                      </Row>
                    )}
                    {shouldShowTrustSetup(formProps.values) && (
                      <FieldWrap>
                        <label>
                          <Field
                            name="shouldCloneTrust"
                            type="checkbox"
                            component="input"
                            data-testid="clone-as-trust-account"
                          />{' '}
                          Set up Clone as Trust Account
                        </label>
                      </FieldWrap>
                    )}
                    {formProps.values.shouldCloneTrust &&
                      formProps.values.shouldCloneOnboardingInformation && (
                        <>
                          <Alert
                            type="info"
                            message="Secondary bank fields in the original account will be cleared and moved to the newly created trust account. Please review the values below for correctness before submitting."
                            data-testid="clone-trust-alert"
                          />
                          <Row>
                            <Col>
                              <TrustHeaders>Original</TrustHeaders>
                              <FieldWrap>
                                <Field name="original.merchant.company_name">
                                  {fieldProps => (
                                    <TextField
                                      {...fieldProps.input}
                                      type="text"
                                      label="Company Name"
                                    />
                                  )}
                                </Field>
                              </FieldWrap>
                              <FieldWrap>
                                <Field name="original.registration.bank_account_owner_name">
                                  {fieldProps => (
                                    <TextField
                                      {...fieldProps.input}
                                      type="text"
                                      label="Name On Bank Account"
                                    />
                                  )}
                                </Field>
                              </FieldWrap>
                              <FieldWrap>
                                <Field name="original.registration.bank_routing_number">
                                  {fieldProps => (
                                    <TextField
                                      {...fieldProps.input}
                                      type="text"
                                      label="Bank Routing Number"
                                    />
                                  )}
                                </Field>
                              </FieldWrap>
                              <FieldWrap>
                                <Field name="original.registration.bank_account_number">
                                  {fieldProps => (
                                    <TextField
                                      {...fieldProps.input}
                                      type="text"
                                      label="Bank Account Number"
                                    />
                                  )}
                                </Field>
                              </FieldWrap>
                              <FieldWrap>
                                <SelectLabel>Bank Account Type</SelectLabel>
                                <Field
                                  name="original.registration.bank_account_type"
                                  clearable={false}
                                  format={value => {
                                    return accountTypeOptions.find(
                                      option => option.value === value
                                    );
                                  }}
                                >
                                  {fieldProps => (
                                    <Select
                                      {...fieldProps.input}
                                      options={accountTypeOptions}
                                      styles={{
                                        menuPortal: base => ({
                                          ...base,
                                          zIndex: 9999,
                                        }),
                                        input: base => ({
                                          ...base,
                                          color: 'white',
                                          padding: 0,
                                        }),
                                      }}
                                      onChange={({ value }) => {
                                        fieldProps.input.onChange(value);
                                      }}
                                      menuPortalTarget={document.body}
                                      menuShouldScrollIntoView={false}
                                      isSearchable={true}
                                      menuPosition="fixed"
                                    />
                                  )}
                                </Field>
                              </FieldWrap>
                            </Col>
                            <Col>
                              <TrustHeaders>Trust Account (Clone)</TrustHeaders>
                              <FieldWrap>
                                <Field name="trust.merchant.company_name">
                                  {fieldProps => (
                                    <TextField
                                      {...fieldProps.input}
                                      type="text"
                                      label="Company Name"
                                    />
                                  )}
                                </Field>
                              </FieldWrap>
                              <FieldWrap>
                                <Field name="trust.registration.bank_account_owner_name">
                                  {fieldProps => (
                                    <TextField
                                      {...fieldProps.input}
                                      type="text"
                                      label="Name On Bank Account"
                                    />
                                  )}
                                </Field>
                              </FieldWrap>
                              <FieldWrap>
                                <Field name="trust.registration.bank_routing_number">
                                  {fieldProps => (
                                    <TextField
                                      {...fieldProps.input}
                                      type="text"
                                      label="Bank Routing Number"
                                    />
                                  )}
                                </Field>
                              </FieldWrap>
                              <FieldWrap>
                                <Field name="trust.registration.bank_account_number">
                                  {fieldProps => (
                                    <TextField
                                      {...fieldProps.input}
                                      type="text"
                                      label="Bank Account Number"
                                    />
                                  )}
                                </Field>
                              </FieldWrap>
                              <FieldWrap>
                                <SelectLabel>Bank Account Type</SelectLabel>
                                <Field
                                  name="trust.registration.bank_account_type"
                                  clearable={false}
                                  format={value => {
                                    return accountTypeOptions.find(
                                      option => option.value === value
                                    );
                                  }}
                                >
                                  {fieldProps => (
                                    <Select
                                      {...fieldProps.input}
                                      options={accountTypeOptions}
                                      styles={{
                                        menuPortal: base => ({
                                          ...base,
                                          zIndex: 9999,
                                        }),
                                        input: base => ({
                                          ...base,
                                          color: 'white',
                                          padding: 0,
                                        }),
                                      }}
                                      onChange={({ value }) => {
                                        fieldProps.input.onChange(value);
                                      }}
                                      menuPortalTarget={document.body}
                                      menuShouldScrollIntoView={false}
                                      isSearchable={true}
                                      menuPosition="fixed"
                                    />
                                  )}
                                </Field>
                              </FieldWrap>
                            </Col>
                          </Row>
                        </>
                      )}
                    <ButtonRow>
                      <SecondaryButton type="button" onClick={onModalClose}>
                        Cancel
                      </SecondaryButton>
                      <PrimaryButton
                        type="submit"
                        disabled={isProcessing}
                        data-testid="clone-merchant-submit"
                      >
                        Clone
                      </PrimaryButton>
                    </ButtonRow>
                  </>
                );
              }}
            </Form>
          </>
        )}
      </ModalContent>
    </>
  );
};
