import React, { FunctionComponent, useMemo } from 'react';
import { FormRenderProps } from 'react-final-form';
import { Label } from 'reactstrap';
import {
  TextField,
  Select,
  RadioButton,
} from '@fattmerchantorg/truffle-components';
import { Field } from '../../../shared/Field';
import {
  FormRow,
  RadioFormRow,
  FormDivider,
  PartialForm,
  TwoColumn,
} from '../shared/UnderwritingPartialForm';
import {
  processingMethods,
  businessTypes,
  procChanges,
  networks,
} from '../../../../util/registration.util';
import { formatCurrency, formatPercent, leaveAsNull } from '../../../../util';
import { FieldGroupTitle } from '../shared/UnderwritingSection';
import { BrandWithPricing } from './ProcessingEnvironment';
import { mapPricingPlanToRegistration } from './ProcessingEnvironmentForm.util';
import * as PricingInfoFormUtil from '../pricing-info/PricingInfoForm.util';

interface BusinessInfoFieldsProps {
  formProps?: FormRenderProps;
  brand: BrandWithPricing;
  setFormValues: (...args: any[]) => any;
}

export const ProcessingEnvironmentFields: FunctionComponent<
  BusinessInfoFieldsProps
> = props => {
  const { formProps, brand, setFormValues } = props;

  const processingMethodsOptions = useMemo(() => {
    return processingMethods?.map(type => {
      return { label: type.description, value: type.key };
    });
  }, []);

  const pricingPlanOptions = useMemo(() => {
    const pricingPlans = brand?.filteredPricingFields ?? [];

    return pricingPlans.map(type => {
      return { label: type.displayName, value: type.name };
    });
  }, [brand]);

  const mccOptions = useMemo(() => {
    return brand?.merchantCategoryCodes?.map(code => {
      return { label: code.displayName, value: code.name };
    });
  }, [brand]);

  const businessTypeOptions = useMemo(() => {
    return businessTypes?.map(type => {
      return { label: type.value, value: type.key };
    });
  }, []);

  const procChangeOptions = useMemo(() => {
    return procChanges?.map(type => {
      return { label: type.name, value: type.key };
    });
  }, []);

  const NetworkOptions = useMemo(() => {
    return networks?.map(type => {
      return { label: type.value, value: type.key };
    });
  }, []);

  return (
    <PartialForm>
      <FormRow>
        <label>Processing Method</label>
        <Field
          name="chosen_processing_method"
          format={value => {
            return processingMethodsOptions.find(
              option => option.value === value
            );
          }}
          clearable={false}
        >
          {props => {
            return (
              <div data-testid="processing_method_option">
                <Select {...props.input} options={processingMethodsOptions} />
              </div>
            );
          }}
        </Field>
      </FormRow>

      <FormRow>
        <label>Pricing Plan</label>
        <Field
          name="pricing_plan"
          format={value => {
            return pricingPlanOptions.find(option => option.value === value);
          }}
          clearable={false}
        >
          {props => {
            return (
              <div data-testid="pricing_plan">
                <Select
                  {...props.input}
                  options={pricingPlanOptions}
                  onChange={evt => {
                    const newPlanName = evt.value;
                    const newPricingPlan = brand.filteredPricingFields.find(
                      plan => plan.name === newPlanName
                    );
                    if (newPricingPlan) {
                      const pricingFields =
                        mapPricingPlanToRegistration(newPricingPlan);
                      setFormValues({
                        ...formProps.values,
                        ...PricingInfoFormUtil.mapPricingFieldsToFormValues(
                          pricingFields,
                          brand
                        ),
                      });
                      props.input.onChange(evt);
                    }
                  }}
                />
              </div>
            );
          }}
        </Field>
      </FormRow>
      <FormDivider />

      <FormRow>
        <TwoColumn>
          <div>
            <FieldGroupTitle>Transaction Amount</FieldGroupTitle>
            <FormRow>
              <Field
                name="plan_txamnt"
                format={leaveAsNull(formatCurrency)}
                formatOnBlur={true}
              >
                {props => (
                  <TextField
                    {...props.input}
                    type="text"
                    label="Card Not Present"
                    error={!!formProps.errors?.plan_txamnt}
                    helperText={formProps.errors?.plan_txamnt}
                    onChange={e => {
                      setFormValues({
                        ...formProps.values,
                        credit_mid_trans_fee: e.target.value,
                        credit_non_trans_fee: e.target.value,
                        credit_qual_trans_fee: e.target.value,
                      });
                      props.input.onChange(e);
                    }}
                  />
                )}
              </Field>
            </FormRow>
            <FormRow>
              <Field
                name="cp_per_item_rate"
                format={leaveAsNull(formatCurrency)}
                formatOnBlur={true}
              >
                {props => (
                  <TextField
                    {...props.input}
                    type="text"
                    label="Card Present"
                    error={!!formProps.errors?.cp_per_item_rate}
                    helperText={formProps.errors?.cp_per_item_rate}
                  />
                )}
              </Field>
            </FormRow>
            <FormRow>
              <Field
                name="amex_mid_trans_fee"
                format={leaveAsNull(formatCurrency)}
                formatOnBlur={true}
              >
                {props => (
                  <TextField
                    {...props.input}
                    type="text"
                    label="Card Not Present - AMEX"
                    error={!!formProps.errors?.amex_mid_trans_fee}
                    helperText={formProps.errors?.amex_mid_trans_fee}
                    onChange={e => {
                      setFormValues({
                        ...formProps.values,
                        amex_mid_trans_fee: e.target.value,
                        amex_non_trans_fee: e.target.value,
                        amex_qual_trans_fee: e.target.value,
                      });
                      props.input.onChange(e);
                    }}
                  />
                )}
              </Field>
            </FormRow>
            <FormRow>
              <Field
                name="cp_amex_per_item_rate"
                format={leaveAsNull(formatCurrency)}
                formatOnBlur={true}
              >
                {props => (
                  <TextField
                    {...props.input}
                    type="text"
                    label="Card Present - AMEX"
                    error={!!formProps.errors?.cp_amex_per_item_rate}
                    helperText={formProps.errors?.cp_amex_per_item_rate}
                  />
                )}
              </Field>
            </FormRow>
            <FormRow>
              <Field
                name="cnp_debit_trans_fee"
                format={leaveAsNull(formatCurrency)}
                formatOnBlur={true}
              >
                {props => (
                  <TextField
                    {...props.input}
                    type="text"
                    label="Card Not Present - Debit"
                    error={!!formProps.errors?.cnp_debit_trans_fee}
                    helperText={formProps.errors?.cnp_debit_trans_fee}
                  />
                )}
              </Field>
            </FormRow>
            <FormRow>
              <Field
                name="cp_debit_trans_fee"
                format={leaveAsNull(formatCurrency)}
                formatOnBlur={true}
              >
                {props => (
                  <TextField
                    {...props.input}
                    type="text"
                    label="Card Present - Debit"
                    error={!!formProps.errors?.cp_debit_trans_fee}
                    helperText={formProps.errors?.cp_debit_trans_fee}
                  />
                )}
              </Field>
            </FormRow>
            <FormRow>
              <Field
                name="plan_ach_txamnt"
                format={leaveAsNull(formatCurrency)}
                formatOnBlur={true}
              >
                {props => (
                  <TextField
                    {...props.input}
                    type="text"
                    label="Plan ACH"
                    error={!!formProps.errors?.plan_ach_txamnt}
                    helperText={formProps.errors?.plan_ach_txamnt}
                  />
                )}
              </Field>
            </FormRow>
          </div>
          <div>
            <FieldGroupTitle>Discount Amount</FieldGroupTitle>
            <FormRow>
              <Field
                name="plan_dcamnt"
                format={leaveAsNull(formatPercent)}
                formatOnBlur={true}
              >
                {props => (
                  <TextField
                    {...props.input}
                    type="text"
                    label="Card Not Present"
                    error={!!formProps.errors?.plan_dcamnt}
                    helperText={formProps.errors?.plan_dcamnt}
                    onChange={e => {
                      setFormValues({
                        ...formProps.values,
                        credit_mid_disc_rate: e.target.value,
                        credit_non_disc_rate: e.target.value,
                        credit_qual_disc_rate: e.target.value,
                      });
                      props.input.onChange(e);
                    }}
                  />
                )}
              </Field>
            </FormRow>
            <FormRow>
              <Field
                name="cp_transaction_rate"
                format={leaveAsNull(formatPercent)}
                formatOnBlur={true}
              >
                {props => (
                  <TextField
                    {...props.input}
                    type="text"
                    label="Card Present"
                    error={!!formProps.errors?.cp_transaction_rate}
                    helperText={formProps.errors?.cp_transaction_rate}
                  />
                )}
              </Field>
            </FormRow>
            <FormRow>
              <Field
                name="amex_mid_disc_rate"
                format={leaveAsNull(formatPercent)}
                formatOnBlur={true}
              >
                {props => (
                  <TextField
                    {...props.input}
                    type="text"
                    label="Card Not Present - AMEX"
                    error={!!formProps.errors?.amex_mid_disc_rate}
                    helperText={formProps.errors?.amex_mid_disc_rate}
                    onChange={e => {
                      setFormValues({
                        ...formProps.values,
                        amex_mid_disc_rate: e.target.value,
                        amex_non_disc_rate: e.target.value,
                        amex_qual_disc_rate: e.target.value,
                      });
                      props.input.onChange(e);
                    }}
                  />
                )}
              </Field>
            </FormRow>
            <FormRow>
              <Field
                name="cp_amex_rate"
                format={leaveAsNull(formatPercent)}
                formatOnBlur={true}
              >
                {props => (
                  <TextField
                    {...props.input}
                    type="text"
                    label="Card Present - AMEX"
                    error={!!formProps.errors?.cp_amex_rate}
                    helperText={formProps.errors?.cp_amex_rate}
                  />
                )}
              </Field>
            </FormRow>
            <FormRow>
              <Field
                name="cnp_debit_discount_rate"
                format={leaveAsNull(formatPercent)}
                formatOnBlur={true}
              >
                {props => (
                  <TextField
                    {...props.input}
                    type="text"
                    label="Card Not Present - Debit"
                    error={!!formProps.errors?.cnp_debit_discount_rate}
                    helperText={formProps.errors?.cnp_debit_discount_rate}
                  />
                )}
              </Field>
            </FormRow>
            <FormRow>
              <Field
                name="cp_debit_discount_rate"
                format={leaveAsNull(formatPercent)}
                formatOnBlur={true}
              >
                {props => (
                  <TextField
                    {...props.input}
                    type="text"
                    label="Card Present - Debit"
                    error={!!formProps.errors?.cp_debit_discount_rate}
                    helperText={formProps.errors?.cp_debit_discount_rate}
                  />
                )}
              </Field>
            </FormRow>
            <FormRow>
              <Field
                name="plan_ach_dcamnt"
                format={leaveAsNull(formatPercent)}
                formatOnBlur={true}
              >
                {props => (
                  <TextField
                    {...props.input}
                    type="text"
                    label="Plan ACH"
                    error={!!formProps.errors?.plan_ach_dcamnt}
                    helperText={formProps.errors?.plan_ach_dcamnt}
                  />
                )}
              </Field>
            </FormRow>
          </div>
        </TwoColumn>
      </FormRow>
      <FormDivider />
      <FormRow>
        <TwoColumn>
          <FormRow>
            <Field
              name="plan_ach_cap"
              format={leaveAsNull(formatCurrency)}
              formatOnBlur={true}
            >
              {props => (
                <TextField
                  {...props.input}
                  type="text"
                  label="Plan ACH Capped Fee"
                  error={!!formProps.errors?.plan_ach_cap}
                  helperText={formProps.errors?.plan_ach_cap}
                />
              )}
            </Field>
          </FormRow>
        </TwoColumn>
      </FormRow>
      <FormDivider />
      <FormRow>
        <TwoColumn>
          <div>
            <FormRow>
              <Field
                name="monthly_minimum_fee"
                format={leaveAsNull(formatCurrency)}
                formatOnBlur={true}
              >
                {props => (
                  <TextField
                    {...props.input}
                    type="text"
                    label="Monthly Minimum Fee"
                    error={!!formProps.errors?.monthly_minimum_fee}
                    helperText={formProps.errors?.monthly_minimum_fee}
                  />
                )}
              </Field>
            </FormRow>
          </div>
          <div>
            <FormRow>
              <Field
                name="monthly_service_fee"
                format={leaveAsNull(formatCurrency)}
                formatOnBlur={true}
              >
                {props => (
                  <TextField
                    {...props.input}
                    type="text"
                    label="Monthly Service Fee"
                    error={!!formProps.errors?.monthly_service_fee}
                    helperText={formProps.errors?.monthly_service_fee}
                  />
                )}
              </Field>
            </FormRow>
          </div>
        </TwoColumn>
      </FormRow>
      <FormDivider />
      <FormRow>
        <FieldGroupTitle>Surcharge</FieldGroupTitle>
        <TwoColumn>
          <FormRow>
            <Field
              name="cp_credit_surcharge_rate"
              format={leaveAsNull(formatPercent)}
              formatOnBlur={true}
            >
              {props => (
                <TextField
                  {...props.input}
                  type="text"
                  label="Card Present"
                  error={!!formProps.errors?.cp_credit_surcharge_rate}
                  helperText={formProps.errors?.cp_credit_surcharge_rate}
                />
              )}
            </Field>
          </FormRow>
          <FormRow>
            <Field
              name="cnp_credit_surcharge_rate"
              format={leaveAsNull(formatPercent)}
              formatOnBlur={true}
            >
              {props => (
                <TextField
                  {...props.input}
                  type="text"
                  label="Card Not Present"
                  error={!!formProps.errors?.cnp_credit_surcharge_rate}
                  helperText={formProps.errors?.cnp_credit_surcharge_rate}
                />
              )}
            </Field>
          </FormRow>
        </TwoColumn>
      </FormRow>
      <FormDivider />
      <FormRow>
        <TwoColumn>
          <div>
            <Label>Flat Rate</Label>
            <div>
              <RadioFormRow>
                <Field name="is_flat_rate">
                  {props => (
                    <>
                      <RadioButton
                        {...props.input}
                        label="Yes"
                        value="true"
                        onChange={e => {
                          props.input.onChange(true);
                        }}
                        checked={props.input.value}
                      />
                    </>
                  )}
                </Field>
                <Field name="is_flat_rate">
                  {props => (
                    <>
                      <RadioButton
                        {...props.input}
                        label="No"
                        value="false"
                        onChange={e => {
                          props.input.onChange(false);
                        }}
                        checked={!props.input.value}
                      />
                    </>
                  )}
                </Field>
              </RadioFormRow>
            </div>
            <label>Proc Change</label>
            <FormRow>
              <Field
                name="proc_change"
                format={value => {
                  return procChangeOptions.find(
                    option => option.value === value
                  );
                }}
                clearable={false}
              >
                {props => {
                  return (
                    <div data-testid="proc_change_option">
                      <Select
                        {...props.input}
                        options={procChangeOptions}
                        styles={{
                          menuPortal: base => ({ ...base, zIndex: 9999 }),
                          input: base => ({
                            ...base,
                            color: 'white',
                            padding: 0,
                          }),
                        }}
                        menuPortalTarget={document.body}
                        menuShouldScrollIntoView={false}
                        isSearchable={true}
                        menuPosition="fixed"
                      />
                    </div>
                  );
                }}
              </Field>
            </FormRow>
            <FormRow>
              <label>Network</label>
              <Field
                name="network"
                format={value => {
                  return NetworkOptions.find(option => option.value === value);
                }}
                clearable={false}
              >
                {props => {
                  return (
                    <div data-testid="network">
                      <Select
                        {...props.input}
                        options={NetworkOptions}
                        styles={{
                          menuPortal: base => ({ ...base, zIndex: 9999 }),
                          input: base => ({
                            ...base,
                            color: 'white',
                            padding: 0,
                          }),
                        }}
                        menuPortalTarget={document.body}
                        menuShouldScrollIntoView={false}
                        isSearchable={true}
                        menuPosition="fixed"
                      />
                    </div>
                  );
                }}
              </Field>
            </FormRow>
          </div>
          <div>
            <label>MCC</label>

            <FormRow>
              <Field
                name="mcc"
                format={value => {
                  return mccOptions?.find(option => option.value === value);
                }}
                clearable={false}
              >
                {props => {
                  return (
                    <div data-testid="mcc_option">
                      <Select
                        {...props.input}
                        options={mccOptions}
                        styles={{
                          menuPortal: base => ({ ...base, zIndex: 9999 }),
                          input: base => ({
                            ...base,
                            color: 'white',
                            padding: 0,
                          }),
                        }}
                        menuPortalTarget={document.body}
                        menuShouldScrollIntoView={false}
                        isSearchable={true}
                        menuPosition="fixed"
                      />
                    </div>
                  );
                }}
              </Field>
            </FormRow>
            <label>Business Type</label>
            <FormRow>
              <Field
                name="bus_type"
                format={value => {
                  return businessTypeOptions.find(
                    option => option.value === value
                  );
                }}
                clearable={false}
              >
                {props => {
                  return (
                    <div data-testid="bus_type_option">
                      <Select
                        {...props.input}
                        options={businessTypeOptions}
                        styles={{
                          menuPortal: base => ({ ...base, zIndex: 9999 }),
                          input: base => ({
                            ...base,
                            color: 'white',
                            padding: 0,
                          }),
                        }}
                        menuPortalTarget={document.body}
                        menuShouldScrollIntoView={false}
                        isSearchable={true}
                        menuPosition="fixed"
                      />
                    </div>
                  );
                }}
              </Field>
            </FormRow>
          </div>
        </TwoColumn>
      </FormRow>
    </PartialForm>
  );
};
