import React, { FunctionComponent, useMemo, useContext } from 'react';
import styled from 'styled-components';
import { RegistrationAuditLog } from '@fattmerchantorg/types-omni';
import { TextLink, Icon } from '@fattmerchantorg/truffle-components';
import { SelectedMerchantStore } from '../../../../context';
import { format } from 'date-fns';
import {
  groupReportsByCreatedAtDate,
  groupReportsById,
} from './external-verification.util';
import {
  getStatus,
  parseMagFieldErrorsFromAuditLog,
  parsePayfacFieldErrorsFromAuditLog,
  RegistrationAuditLogFieldError,
} from '../../../../util/auditLog.util';
import { FieldErrorModal } from '../status/submit/field-error-modal/FieldErrorModal';
import { ModalContext } from '../../../../context';
import { ReportEntity } from './ExternalVerification.types';
import { ReportsByEntityType } from '../../../../util/auditLog.util';
import { get } from 'lodash';
import { AuditLogExperianModal } from './AuditLogExperianModal';
import { AuditLogExperianRow } from './AuditLogExperianRow';
import { AuditCorisRow } from './AuditCorisRow';
import { AuditLogCorisModal } from './AuditLogCorisModal';
import { ProcessorNames } from '../status/submit/SubmitApplicationForm.types';

const AuditLogContainer = styled.div`
  max-height: 370px; // ~10 rows visible
  overflow-y: auto;
  scroll-snap-type: y mandatory;

  > div:nth-child(odd) {
    background: #1c2f3b;
  }
`;

const LogSection = styled.div`
  margin: 0 0 16px;
`;

const InternalLogRow = styled.span`
  font-size: 14px !important;
  font-weight: 400;
`;

const Success = styled.span`
  font-weight: 700;
  font-size: 14px !important;
  color: #28cb35;
`;

const ReportRow = styled.div`
  display: flex;
  padding: 8px;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  scroll-snap-align: start;

  span {
    font-size: 12px;
  }

  a {
    font-size: 14px;
  }

  > * {
    margin: 0 16px;

    &:first-child {
      margin: 0 16px 0 0;
    }
  }
  .audit-log-processor-name {
    font-size: 14px;
    font-weight: bold;
    line-height: 21px;
    text-decoration-line: underline;
    color: #009bf2;
    display: block;
    margin-bottom: 5px;
    cursor: pointer;
  }

  .audit-log-time {
    font-size: 12px;
    font-weight: normal;
    line-height: 18px;
  }
`;

const ExperianRowContainer = styled.div`
  padding: 8px;
`;

const LogStatusWrapper = styled.span`
  display: flex;
  align-self: start;
  flex-direction: column;
  text-align: right;
`;

const LogStatusPill = styled.span`
  display: block;
`;

interface ReportGroupProps {
  group: ReportEntity;
  reports: RegistrationAuditLog[];
  groupTimestamp: Date;
  onViewReport: React.MouseEventHandler<HTMLAnchorElement>;
}

// A date-indexed (by minute at a minimum) group of reports
const ReportInternalGroup: React.FunctionComponent<
  ReportGroupProps
> = props => {
  const { groupTimestamp, reports, onViewReport } = props;
  let status = 'failure';
  let errors = null;

  const reportErrors = reports.find(r => get(r.result, 'errors', []).length);
  errors = reportErrors && get(reportErrors.result, 'errors', []).length;
  const report = errors ? reportErrors : reports[reports.length - 1];
  status = getStatus(report);
  if (!errors && status !== 'success') {
    errors = 1;
  }
  const description = get(
    report,
    'result.payfacSubmissionResponse.Description'
  );
  const processorNameShort = get(report, 'processorName');
  const processorNameLong = get(
    ProcessorNames,
    processorNameShort,
    description
  );

  return (
    <ReportRow>
      <InternalLogRow>
        <span className="audit-log-processor-name">{processorNameLong}</span>
        <span className="audit-log-time">
          {format(groupTimestamp, 'MM/dd/yyyy hh:mm a')}
        </span>
      </InternalLogRow>
      <LogStatusWrapper data-testid="audit-log-status">
        {status === 'success' ? (
          <Success>
            <Icon icon={['fas', 'check']} /> Submitted
          </Success>
        ) : (
          <TextLink onClick={onViewReport}>{errors} Errors</TextLink>
        )}
        <LogStatusPill />
      </LogStatusWrapper>
    </ReportRow>
  );
};

interface AuditLogProps {
  reports: ReportsByEntityType;
  onRunBusinessVerification: () => void;
  onRunPersonVerification: () => void;
  runningVerification: ReportEntity | null;
  logType?: string;
}

export const AuditLog: FunctionComponent<AuditLogProps> = props => {
  let { reports, logType } = props;
  reports = reports || {
    business: [],
    person: [],
    internal: [],
    experian: [],
    coris: [],
    autoverificationResult: [],
  };
  const {
    dispatch,
    state: { merchantId, registration, brand },
  } = useContext(SelectedMerchantStore);

  const { modalDispatch } = useContext(ModalContext);
  const groupedInternalReports = useMemo(
    () => groupReportsByCreatedAtDate(reports.internal),
    [reports.internal]
  );

  const groupedExperianReports = useMemo(
    () => groupReportsByCreatedAtDate(reports.experian),
    [reports.experian]
  );

  const groupedCorisReports = useMemo(
    () => groupReportsById(reports.coris),
    [reports.coris]
  );

  const handleViewReportInternalClick = (auditLog: RegistrationAuditLog) => {
    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        component: FieldErrorModal,
        props: {
          isOpen: true,
          auditLog: auditLog,
          registration: registration,
          merchantId: merchantId,
          merchantDispatch: dispatch,
          brand: brand,
        },
      },
    });
  };

  const handleViewReportExperianClick = (timestampKey: string) => {
    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        component: AuditLogExperianModal,
        props: {
          isOpen: true,
          verificationData: groupedExperianReports[timestampKey],
          timestamp: timestampKey,
        },
      },
    });
  };

  const handleViewReportCorisClick = (timestampKey: string) => {
    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        component: AuditLogCorisModal,
        props: {
          isOpen: true,
          verificationData: groupedCorisReports[timestampKey],
          timestamp: timestampKey,
        },
      },
    });
  };

  return (
    <>
      {logType === 'experian' && (
        <LogSection data-testid="experian-verification-logs">
          <AuditLogContainer id="underwriting-experian-audit-log">
            {Object.keys(groupedExperianReports).map(timestampKey => {
              return (
                <ExperianRowContainer key={timestampKey}>
                  <AuditLogExperianRow
                    groupTimestamp={new Date(timestampKey)}
                    reports={groupedExperianReports[timestampKey]}
                    onViewReport={() =>
                      handleViewReportExperianClick(timestampKey)
                    }
                  />
                </ExperianRowContainer>
              );
            })}
          </AuditLogContainer>
        </LogSection>
      )}
      {logType === 'applications' && (
        <LogSection data-testid="applications-verification-logs">
          <AuditLogContainer data-testid="underwriting-application-audit-log">
            {Object.keys(groupedInternalReports).map(timestampKey => {
              return (
                <ReportInternalGroup
                  group="internal"
                  groupTimestamp={new Date(timestampKey)}
                  reports={groupedInternalReports[timestampKey]}
                  key={timestampKey}
                  onViewReport={() => {
                    const errorReport = groupedInternalReports[
                      timestampKey
                    ].find(auditLog => {
                      // const serviceName = get(auditLog, 'serviceName', null);
                      const serviceName = auditLog.serviceName;
                      let errors: RegistrationAuditLogFieldError[];
                      switch (serviceName) {
                        case 'mag':
                          errors = parseMagFieldErrorsFromAuditLog(auditLog);
                          break;
                        case 'payfac':
                          errors = parsePayfacFieldErrorsFromAuditLog(auditLog);
                          break;
                        default:
                          errors = [];
                      }
                      if (
                        (errors && Array.isArray(errors) && errors.length) ||
                        get(
                          auditLog,
                          'result.payfacSubmissionResponse.Code',
                          ''
                        ) === 'Failed'
                      ) {
                        return auditLog;
                      }
                      return false;
                    });
                    return errorReport
                      ? handleViewReportInternalClick(errorReport)
                      : null;
                  }}
                />
              );
            })}
          </AuditLogContainer>
        </LogSection>
      )}
      {logType === 'coris' && (
        <LogSection data-testid="applications-coris-logs">
          <AuditLogContainer id="underwriting-coris-audit-log">
            {Object.keys(get(reports, 'coris', {})).length !== 0 ? (
              Object.keys(groupedCorisReports).map(timestampKey => {
                return (
                  <AuditCorisRow
                    data-testid="applications-coris-logs"
                    key={timestampKey}
                    groupTimestamp={new Date(timestampKey)}
                    reports={groupedCorisReports[timestampKey]}
                    onViewReport={() =>
                      handleViewReportCorisClick(timestampKey)
                    }
                  />
                );
              })
            ) : (
              <AuditCorisRow reports={{}}></AuditCorisRow>
            )}
          </AuditLogContainer>
        </LogSection>
      )}
    </>
  );
};
