import React, { FunctionComponent } from 'react';
import { InfoIcon } from '../shared/InfoIcon';
import {
  ModalContentDescription,
  ModalContentTitle
} from '../shared/TruffleText';

export const TransactionsInfoIcon: FunctionComponent = () => {
  return (
    <InfoIcon title="Transaction Details">
      {ModalContentTitle('Volume')}
      {ModalContentDescription('The total dollar amount of transactions')}
      {ModalContentTitle('Count')}
      {ModalContentDescription('The total number of transactions')}
      {ModalContentTitle('Average')}
      {ModalContentDescription('The average transaction dollar amount')}
      {ModalContentTitle('Percentage')}
      {ModalContentDescription(
        'Compares the current date range and previous date range'
      )}
    </InfoIcon>
  );
};
