import React from 'react';
import { Tooltip, StatusPill } from '@fattmerchantorg/truffle-components';
import { Id } from '../shared';
import { formatReadableDate, formatTruncatedId } from '../../util/format.util';
import { SupportTicket } from '../../@types';
import { SupportTicketStatusFilter } from './components';
import { StatusPillStatus } from '@fattmerchantorg/truffle-components/dist/StatusPill/StatusPill';
import { ColumnProps } from '../shared/Table/Table.types';

export const formatTicketOwner = (ticket: SupportTicket) => {
  if (!ticket) return '';

  const { owner, owner_id } = ticket;

  if (!owner_id) return 'Unassigned';
  if (!owner) return '';

  return `${owner.firstName ?? ''} ${owner.lastName ?? ''} ${
    owner.email ? `(${owner.email})` : ''
  }`.trim();
};

export const mapTicketStatusToStatusPillStatus = (
  ticket: Pick<SupportTicket, 'status'>
): StatusPillStatus => {
  if (!ticket) return null;

  switch (ticket.status) {
    case 'PENDING':
      return 'warning';
    case 'NEW':
      return 'success';
    case 'CLOSED':
      return 'error';
    case 'AWAITING_RESPONSE':
      return 'neutral';
  }
};

export const getColumns = () => {
  const columns: ColumnProps<SupportTicket>[] = [
    {
      id: 'id',
      accessor: 'id',
      Header: 'ID',
      Cell: cell => <Id id={cell.value} maxLength={9} />,
    },
    {
      id: 'ticket name',
      accessor: 'name',
      Header: 'Ticket Name',
      Cell: cell => (
        <Tooltip content={cell.value}>
          <span>{formatTruncatedId(cell.value, 50)}</span>
        </Tooltip>
      ),
    },
    {
      id: 'content',
      accessor: 'content',
      disableSortBy: true,
      Header: 'Ticket Description',
      Cell: cell => {
        return (
          <Tooltip content="Click to view details">
            <span>{formatTruncatedId(cell.value, 50)}</span>
          </Tooltip>
        );
      },
    },
    {
      id: 'status',
      accessor: 'status',
      disableSortBy: true,
      Header: (
        <>
          Status <SupportTicketStatusFilter />
        </>
      ),
      Cell: cell => {
        const ticket = cell.row.original;
        return (
          <StatusPill
            status={mapTicketStatusToStatusPillStatus(ticket)}
            label={
              ticket?.status === 'NEW'
                ? 'OPEN'
                : ticket?.status?.replace(/_/g, ' ')
            }
            disabled={ticket?.status === 'CLOSED'}
          />
        );
      },
    },
    {
      id: 'owner',
      accessor: 'owner',
      Header: 'Assigned Agent',
      Cell: cell => {
        const ticket = cell.row.original;
        return formatTicketOwner(ticket);
      },
    },
    {
      id: 'external_created_at',
      accessor: 'external_created_at',
      // PEG-559 TODO: make sure this sorts
      Header: 'Date',
      Cell: cell => formatReadableDate(String(cell.value)),
    },
  ];

  return columns;
};
