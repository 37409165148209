import { StatusPill, Tooltip } from '@fattmerchantorg/truffle-components';
import { OmniDispute as Dispute } from '@fattmerchantorg/types-omni';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faExternalLink } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { differenceInDays, differenceInHours, isValid } from 'date-fns';
import React from 'react';
import styled from 'styled-components';
import { originUrl } from '../../util/api.util';
import { formatCurrency, formatReadableDate } from '../../util/format.util';
import { ColumnProps } from '../shared';
import { formatReasonAndDescription } from './Disputes.format';

const StyledExternalLinkIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.core.blue[500].hex};
`;

export function formatRespondByDate(
  respondByStr: string,
  referenceDate: Date = new Date()
) {
  const respondBy = new Date(respondByStr);
  if (!respondByStr || !(isValid(respondBy) && isValid(referenceDate))) {
    return '';
  }
  const start = referenceDate ?? new Date();

  if (start > respondBy) {
    return 'Closed';
  }

  const durationInDays = differenceInDays(respondBy, start);
  const durationInHours = differenceInHours(respondBy, start);
  if (durationInDays >= 45) {
    return '45+ Days';
  }
  if (durationInDays === 0) {
    return durationInHours === 0
      ? '<1 Hour'
      : durationInHours === 1
      ? '1 Hour'
      : `${durationInHours} Hours`;
  }
  return durationInDays === 1 ? '1 Day' : `${durationInDays} Days`;
}

export const mapDisputeStatusToPill = (disputeStatus: String | undefined) => {
  let type = null;
  let status = null;
  switch (disputeStatus) {
    case 'OPEN':
      status = 'warning';
      type = 'Open';
      break;
    case 'PENDING':
      status = 'warning';
      type = 'Pending';
      break;
    case 'UPLOAD_FAILED':
      status = 'error';
      type = 'Upload Failed';
      break;
    case 'INQUIRY':
      status = 'warning';
      type = 'Inquiry';
      break;
    case 'EVIDENCE_UPLOADED':
      status = 'neutral';
      type = 'Evidence Uploaded';
      break;
    case 'WON':
      status = 'success';
      type = 'Won';
      break;
    case 'ACCEPTED':
      status = 'error';
      type = 'Accepted';
      break;
    case 'LOST':
      status = 'error';
      type = 'Lost';
      break;
    case 'CLOSED_LOST.RESOLVED':
      status = 'error';
      type = 'Resolved';
      break;
    case 'PREARBITRATION':
      status = 'warning';
      type = 'Pre-Arbitration';
      break;
  }

  const pill = (
    <StatusPill
      data-testid="ui-transaction-type"
      status={status}
      label={type}
    />
  );

  return pill;
};

export const getColumns = (merchantId: string) => {
  const columns: ColumnProps<Dispute>[] = [
    {
      id: 'status',
      accessor: 'status',
      disableSortBy: true,
      Header: 'Status',
      Cell: cell => mapDisputeStatusToPill(cell.value),
    },
    {
      id: 'reason',
      accessor: 'description',
      disableSortBy: true,
      Header: 'Reason',
      Cell: cell => {
        const reason = cell.row.original.reason;
        const desc = cell.value;
        return (
          <Tooltip
            content={formatReasonAndDescription(reason, desc)}
            hideOnClick={false}
          >
            <span>{formatReasonAndDescription(reason, desc, true)}</span>
          </Tooltip>
        );
      },
    },
    {
      id: 'customer',
      accessor: 'customer',
      disableSortBy: true,
      Header: 'Customer',
      Cell: cell => {
        let fullName = '';
        const customer = cell.value;
        if (customer) {
          const name = `${customer.firstname} ${customer.lastname}`;
          if (customer.company && name.replace(/\s/g, '')) {
            fullName = `${name} (${customer.company})`;
          } else if (customer.company) {
            fullName = customer.company;
          } else if (name) {
            fullName = name.trim();
          }
        }

        if (!fullName) {
          return '--';
        }
        return fullName;
      },
    },
    {
      id: 'respond_by',
      accessor: 'respond_by',
      Header: 'Deadline',
      Cell: cell =>
        ['WON', 'LOST'].includes(cell.row?.original?.status) ? (
          <b>Closed</b>
        ) : (
          <b>{formatRespondByDate(cell.value)}</b>
        ),
    },
    {
      id: 'created_at',
      accessor: 'created_at',
      Header: 'Created',
      Cell: cell => formatReadableDate(cell.value, true),
    },
    {
      id: 'amount',
      accessor: 'amount',
      disableSortBy: true,
      Header: <div style={{ textAlign: 'right' }}>Amount</div>,
      Cell: cell => (
        <div style={{ textAlign: 'right' }}>{formatCurrency(cell.value)}</div>
      ),
    },
    {
      id: 'payment',
      accessor: 'transaction_id',
      disableSortBy: true,
      Header: <div style={{ textAlign: 'center' }}>Payment</div>,
      Cell: cell => {
        const transactionId = cell.value;
        return (
          <div style={{ textAlign: 'center' }}>
            {transactionId ? (
              <a
                id={`dispute-transaction-id-${transactionId}`}
                style={{ textAlign: 'center' }}
                href={originUrl(
                  `merchant/${merchantId}/transactions?detailId=${transactionId}`
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                <StyledExternalLinkIcon icon={faExternalLink as IconProp} />
              </a>
            ) : (
              <span>-</span>
            )}
          </div>
        );
      },
    },
  ];

  return columns;
};

export function formatDisputeEvidenceFileName(filename: string): string {
  if (filename.length > 26) {
    const firstPart = filename.substring(0, 8);
    const lastPart = filename.substring(filename.length - 16, filename.length);
    return firstPart + '...' + lastPart;
  } else {
    return filename;
  }
}
