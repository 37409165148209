import { NavItem } from 'reactstrap';
import { NavLink as NavLinkRRD } from 'react-router-dom';
import { RouteItem, LeafRouteItem } from './models/RouteItem.model';
import { RedirectItem } from './models/RedirectItem.model';
import { Merchants } from '../merchants/Merchants';
import { BusinessInfo } from '../businessinfo/BusinessInfo';
import { ReservesInfo } from '../businessinfo/ReservesInfo';
import { BrandReservesInfo } from '../businessinfo/BrandReservesInfo';
import { Deposits } from '../deposits/Deposits';
import { Disputes } from '../disputes/Disputes';
import { FeeStatements } from '../feestatements/FeeStatements';
import { FeeStatementV3 } from '../feestatements/statement-v3';
import { DetailedFeeStatement } from '../feestatements/DetailedFeeStatement';
import { DetailedSettlementStatement } from '../feestatements/DetailedSettlementStatement';
import { RegistrationPage } from '../registration/RegistrationPage';
import { Settlements } from '../settlements/Settlements';
import { UnderwritingPage } from '../underwriting/UnderwritingPage';
import { TransactionsPage } from '../transactions/TransactionsPage';
import { TruffleDashboardPage } from '../truffle-dashboard/truffle-dashboard-page';
import { Imports } from '../imports/Imports';

import {
  daysAgo,
  formatDate,
  startOfDay,
  endOfDay,
} from '../../util/date.util';
import { stringify } from '../../util/api.util';
import { AuthState } from '../../context';
import { State as MerchantState } from '../../context/selected-merchant/SelectedMerchant.types';
import { startOfYesterday } from 'date-fns/esm';
import { SupportTicketsPage } from '../supporttickets/SupportTicketsPage';
import { SettlementDetails } from '../settlements/details/SettlementDetails';
import {
  AccountSettingsPage,
  BrandSettingsPage,
  DevToolsSettingsPage,
  TeamSettingsPage,
  PricingPlansPage,
  RolesPage,
} from '../settings';
import { FeatureFlag } from '../shared/FeatureFlag/FeatureFlag';
import {
  StyledChildNavLink,
  StyledChildLabel,
} from '../main/components/ChildNavbar';
import { BillingProfiles } from '../billing-profiles/BillingProfiles';
import { AddBillingProfile } from '../billing-profiles/AddBillingProfile';
import { BankAccountsPage } from '../funding-accounts/BankAccountsPage';
import { Terminals } from '../terminals/Terminals';
import React from 'react';

export const ExternalUrl = () => {
  window.location.replace(
    process.env.REACT_APP_ALLBOUND_SSO_URL ||
      'https://omni-auth-dev.auth0.com/samlp/XvhSX9PGbtFRPEN6ZY1ATb1l7sMaackk?connection=google-oauth2'
  );

  return null;
};

export const getNavRoutes = ({
  merchantState,
  authState,
  appsEnabled,
}: {
  merchantState?: MerchantState;
  authState: AuthState;
  appsEnabled: boolean;
}): RouteItem[] => [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'fas fa-tachometer-alt',
    component: TruffleDashboardPage,
    permitParams: ['godview', 'omniConnDashboard', 'read'],
    // Redirect users without dashboard access to merchant list view.
    redirectPath: '/merchants',
  },
  {
    path: '/merchants',
    name: 'Merchants',
    icon: 'fas fa-store',
    component: Merchants,
    permitParams: ['godview', 'accessSubmerchants', 'read'],
    childMenuTitle: merchantState?.merchant?.company_name,
    childRoutes: merchantRoutes.map<LeafRouteItem>(route => {
      return {
        ...route,
        to: {
          // TODO: determine if we *need* to use this hack to get the merchant id into the path.
          //       (replacing an :id param with the param value is an anti-pattern and should be avoided)
          pathname: merchantState.merchantId
            ? (route.path as string)
                .split(':merchantId')
                .join(merchantState.merchantId)
                .split('%3AmerchantId')
                .join(merchantState.merchantId)
            : undefined,
        },
        hidden: shouldHidePaths(
          route.path,
          authState,
          appsEnabled,
          merchantState.brand?.data as any
        )
          ? true
          : route.hidden,
      };
    }),
  },
  {
    path: '/settlements',
    name: 'Settlements',
    icon: 'fas fa-money-bill-wave',
    component: Settlements,
    permitParams: ['godview', 'manageStaxSettlements', 'read'],
    'data-testid': 'settlements-nav',
    defaultSearch:
      '?' +
      stringify({
        startDate: formatDate(startOfYesterday(), 'yyyy-MM-dd'),
        endDate: formatDate(endOfDay(new Date()), 'yyyy-MM-dd'),
        approvalState: ['ALL'],
        page: 1,
      }),
  },
  {
    // TODO: implement this item
    path: '/agents',
    name: 'Agent Management',
    icon: 'fas fa-headset',
    component: null,
    hidden: true,
  },
  {
    path: '/tickets',
    name: 'Support',
    icon: 'fas fa-headset',
    component: SupportTicketsPage,
    permitParams: ['godview', 'supportTickets', 'read'],
  },
  {
    path: '/allbound',
    name: 'Marketing',
    icon: 'fas fa-mail-bulk',
    component: ExternalUrl,
    permitParams: null,
    target: '__blank',
  },
  {
    path: '/settings',
    name: 'Settings',
    icon: 'fas fa-cog',
    childRoutes: settingsRoutes,
  },
  {
    // TODO: implement this item
    path: '/resources',
    name: 'Resources',
    icon: 'fas fa-graduation-cap',
    component: null,
    hidden: true,
  },
];

export const settlementRoutes: RouteItem[] = [
  {
    path: '/settlements/:settlementId/details',
    name: 'Settlement Details',
    component: SettlementDetails,
    permitParams: ['godview', 'manageStaxSettlements', 'read'],
  },
];

export const businessInfoRoutes: RouteItem[] = [
  {
    path: `/merchant/:merchantId/reserves`,
    name: 'Single Reserve Detail',
    component: ReservesInfo,
    permitParams: ['godview', 'engineReserves', 'write'],
  },
  {
    path: `/reserves/`,
    name: 'Reserve Details',
    component: BrandReservesInfo,
    permitParams: ['godview', 'engineReserves', 'write'],
  },
];

/**
 * Dynamically determines if a particular path that should be displayed or hidden.
 * @returns `true` if the path should be displayed and `false` if the path should be hidden.
 */
function shouldHidePaths(
  path: RouteItem['path'],
  authState: AuthState,
  appsEnabled: boolean,
  brand: { flags?: { feeStatementWindows?: string } } // TEMP Hack - not sure if this brand value will be permanent
): boolean {
  const paths = Array.isArray(path) ? path : [path];

  // Paths of interest.
  const vaultImporterPath = '/merchant/:merchantId/imports';
  const terminalView = '/merchant/:merchantId/terminals/';
  // Fee Statements
  const feeStatementsPath = '/merchant/:merchantId/feestatements';

  // Hide "Vault Importer Tool" menu item if user brand is not "fattmerchant"
  if (
    paths.some(p => p === vaultImporterPath) &&
    authState?.auth.user.brand !== 'fattmerchant'
  ) {
    return true;
  }

  if (paths.some(p => p === terminalView) && appsEnabled !== true) {
    return true;
  }

  if (
    paths.some(p => p === feeStatementsPath) &&
    // KRK-2152 - If a value exists in this column they are prone to the fee discrepency, so hide the fee statements.
    brand?.flags?.feeStatementWindows
  ) {
    return true;
  }

  return false;
}

const settingsRoutes: LeafRouteItem[] = [
  {
    path: '/settings/account',
    name: 'Account',
    component: AccountSettingsPage,
  },
  {
    path: '/settings/brand',
    name: 'Brand',
    permitParams: ['godview', 'branding', 'read'],
    component: BrandSettingsPage,
    requiresSelectedBrand: true,
  },
  {
    path: '/settings/roles',
    name: 'Roles',
    permitParams: ['godview', 'permissions', 'read'],
    component: RolesPage,
    requiresSelectedBrand: false,
  },
  {
    path: '/settings/team',
    to: {
      pathname: '/settings/team',
      search:
        '?' +
        stringify({
          page: 1,
          sort: '',
          order: 'DESC',
          include_deleted: 0,
          is_deleted: 0,
          per_page: 20,
        }),
    },
    name: 'Team',
    component: TeamSettingsPage,
    requiresSelectedBrand: true,
    permitParams: ['godview', 'teamSettings', 'read'],
  },
  {
    path: '/settings/pricingplans',
    name: 'Pricing Plans',
    component: PricingPlansPage,
    requiresSelectedBrand: true,
    permitParams: ['godview', 'pricingPlans', 'read'],
  },
  {
    path: '/settings/devtools',
    name: 'Dev Tools',
    component: DevToolsSettingsPage,
    render: () => (
      <FeatureFlag category="Developer" feature="DevTools">
        <NavItem>
          <StyledChildNavLink to="/settings/devtools" tag={NavLinkRRD}>
            <StyledChildLabel>Dev Tools</StyledChildLabel>
          </StyledChildNavLink>
        </NavItem>
      </FeatureFlag>
    ),
  } as LeafRouteItem,
];

const merchantRoutes: LeafRouteItem[] = [
  {
    path: `/merchant/:merchantId/businessinfo`,
    permitParams: ['godview', 'accessSubmerchants', 'read'],
    name: 'Business Info',
    icon: 'fas fa-chart-line',
    'data-testid': `selected-merchant-nav-businessinfo`,
    component: BusinessInfo,
  },

  {
    path: `/merchant/:merchantId/enrollment`,
    permitParams: ['godview', 'accessSubmerchants', 'read'],
    name: 'Enrollment',
    icon: 'fas fa-door-open',
    'data-testid': `selected-merchant-nav-enrollment`,
    component: RegistrationPage,
  },
  {
    path: `/merchant/:merchantId/transactions`,
    permitParams: ['godview', 'accessSubmerchants', 'read'],
    name: 'Payments',
    icon: 'fas fa-money-bill-wave',
    'data-testid': `selected-merchant-nav-transactions`,
    component: TransactionsPage,
  },
  {
    path: `/merchant/:merchantId/underwriting`,
    permitParams: ['godview', 'underwriting', 'read'],
    name: 'Underwriting',
    icon: 'fas fa-file-contract',
    'data-testid': `selected-merchant-nav-underwriting`,
    component: UnderwritingPage,
  },
  {
    path: `/merchant/:merchantId/settlements/:settlementId/details`,
    permitParams: ['godview', 'manageStaxSettlements', 'read'],
    name: 'Settlement Details',
    'data-testid': 'selected-merchant-nav-settlements-details',
    component: SettlementDetails,
    icon: null,
    hidden: true,
  },
  {
    path: `/merchant/:merchantId/settlements`,
    permitParams: ['godview', 'manageStaxSettlements', 'read'],
    name: 'Settlements',
    icon: 'fas fa-money-bill-wave',
    'data-testid': `selected-merchant-nav-settlements`,
    component: Settlements,
  },
  {
    path: `/merchant/:merchantId/deposits`,
    permitParams: ['godview', 'accessSubmerchants', 'read'],
    name: 'Deposits',
    icon: 'fas fa-university',
    'data-testid': `selected-merchant-nav-deposits`,
    component: Deposits,
    defaultSearch:
      '?' +
      stringify({
        startDate: formatDate(startOfDay(daysAgo(30)), 'yyyy-MM-dd'),
        endDate: formatDate(endOfDay(new Date()), 'yyyy-MM-dd'),
      }),
  },
  {
    path: `/merchant/:merchantId/feestatements`,
    permitParams: ['godview', 'accessSubmerchants', 'read'],
    name: 'Fee Statements',
    icon: 'fas fa-file-invoice-dollar',
    'data-testid': 'selected-merchant-nav-fee-statements',
    component: FeeStatements,
  },
  {
    path: `/merchant/:merchantId/detailedfeestatements/:date`,
    permitParams: ['godview', 'accessSubmerchants', 'read'],
    name: 'Detailed Fee Statements',
    hidden: true,
    component: DetailedFeeStatement,
  },
  {
    path: `/merchant/:merchantId/fee-statement/:activityPeriod`,
    permitParams: ['godview', 'accessSubmerchants', 'read'],
    name: 'Fee Statement',
    hidden: true,
    component: FeeStatementV3,
  },
  {
    path: `/merchant/:merchantId/detailedsettlementstatements/:activityPeriodOrBatchId`,
    permitParams: ['godview', 'accessSubmerchants', 'read'],
    name: 'Detailed Settlement Statements',
    hidden: true,
    component: DetailedSettlementStatement,
  },
  {
    // TODO: implement this item
    path: `/merchant/:merchantId/users`,
    permitParams: ['godview', 'accessSubmerchants', 'read'],
    name: 'Users',
    icon: 'fas fa-users',
    'data-testid': `selected-merchant-nav-users`,
    component: null,
    hidden: true,
  },
  {
    // TODO: implement this item
    path: `/merchant/:merchantId/qbo`,
    permitParams: ['godview', 'accessSubmerchants', 'read'],
    name: 'Quickbooks Online',
    icon: null,
    'data-testid': `selected-merchant-nav-qbo`,
    component: null,
    hidden: true,
  },
  {
    path: `/merchant/:merchantId/disputes`,
    permitParams: ['godview', 'accessSubmerchants', 'read'],
    name: 'Disputes',
    icon: 'fas fa-exclamation-circle',
    'data-testid': `selected-merchant-nav-disputes`,
    component: Disputes,
  },
  {
    path: `/merchant/:merchantId/billing-profiles/add`,
    permitParams: ['godview', 'engineBillingProfiles', 'write'],
    name: 'Add a Billing Profile',
    icon: null,
    hidden: true,
    component: AddBillingProfile,
  },
  {
    path: `/merchant/:merchantId/billing-profiles`,
    permitParams: ['godview', 'engineBillingProfiles', 'read'],
    name: 'Billing Profiles',
    icon: 'fas fa-tools',
    'data-testid': 'selected-merchant-nav-billing-profiles',
    component: BillingProfiles,
  },
  {
    path: `/merchant/:merchantId/terminals/`,
    permitParams: ['godview', 'engineTerminals', 'read'],
    name: 'Terminals',
    icon: 'fas fa-tools',
    'data-testid': 'selected-merchant-nav-terminals',
    component: Terminals,
  },
  {
    path: `/merchant/:merchantId/imports`,
    permitParams: ['godview', 'accessSubmerchants', 'read'],
    name: 'Importer',
    icon: 'fas fa-file-import',
    'data-testid': `selected-merchant-nav-import`,
    component: Imports,
  },
];

/** Routes related to the selected merchant that should not appear in the sidebar navigation */
export const merchantNonSidebarRoutes: RouteItem[] = [
  {
    path: `/merchant/:merchantId/funding-accounts`,
    permitParams: ['godview', 'accessSubmerchants', 'read'],
    icon: 'fas fa-chart-line',
    'data-testid': `selected-merchant-nav-businessinfo`,
    component: BankAccountsPage,
  },
];

export const redirects: RedirectItem[] = [
  {
    from: '/settings',
    to: '/settings/account',
  },
  {
    from: '/',
    to: '/dashboard',
  },
];
