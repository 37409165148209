import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Column,
  Row,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import {
  Dropdown,
  DropdownContent,
  BaseModal,
  Icon,
  ModalFooter,
  ModalHeader,
  Pagination,
  PrimaryButton,
  SecondaryButton,
  SortIndicator,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  TableTools,
  TableWrapper,
  Tooltip,
} from '@fattmerchantorg/truffle-components';
import { CollectionQuery, LoadingSpan } from '../../shared';
import styled from 'styled-components';
import { faBan } from '@fortawesome/pro-solid-svg-icons';
import { BulkActionMenuButton } from '../../shared/BulkActions/BulkActionsMenuButton';
import { BulkActionMenu } from '../../shared/BulkActions/BulkActionMenu';
import { BulkActionMenuCount } from '../../shared/BulkActions/BulkActionMenuCount';
import { BulkActionMenuContent } from '../../shared/BulkActions/BulkActionMenuContent';
import { BulkActionMenuTitle } from '../../shared/BulkActions/BulkActionMenuTitle';
import get from 'lodash/get';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import copy from 'copy-to-clipboard';
import { useAsyncEffect, useToaster, usePermissions } from '../../../hooks';
import { API, Engine } from '@fattmerchantorg/types-engine';
import { isAdjustmentTransaction } from '../../../util/transaction.util';
import {
  AdjustmentTransaction,
  AdjustmentTransactionAction,
  AdjustmentTransactionType,
} from '../../transactions/AdjustmentTransactions/AdjustmentTransactions.types';
import { State, openModal } from '../../../context';
import { AdjustmentTransactionsModal } from '../../transactions/AdjustmentTransactions/AdjustmentTransactionsModal';
import { getMerchantMids } from '../../transactions/AdjustmentTransactions/AdjustmentTransactions.util';

interface DataTableProps {
  data: {
    columns: Column[];
    rows: Record<string, unknown>[];
  };
  pageSizeOptions: {
    value: number;
    label: string;
  }[];
  count: number;
  total: number;
  loading: boolean;
  currentPage: number;
  lastPage: number;
  defaultPageSize: number;
  handleQueryChange: (query: CollectionQuery) => void;
  onRemove?: (transactionIds: string[]) => Promise<void>;
  onItemClick: (index: CollectionQuery) => void;
  hideCopyButton?: boolean;
  openSettlement?: boolean;
  modalDispatch: Function;
  authToken: string;
  settlement: Engine.API.Settlement;
  onReloadTransactions?: () => void;
  onReloadSettlementDetails?: () => void;
  selectedMerchant?: State;
}

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 2rem;

  > h2 {
    color: white;
    margin-top: 2rem;
    text-align: center;
  }

  > span > svg {
    font-size: 70px;
  }
`;

const ButtonBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  > * {
    margin: 0 0 0 ${({ theme }) => theme.space[3]}px;
  }
`;

const CopyButton = styled.button`
  background: none;
  border: 0;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.core.white[0].hex};
`;

const RemoveButton = styled(CopyButton)`
  color: ${({ theme }) => theme.colors.status.red[500].hex};
`;

const StyledTooltip = styled(Tooltip)`
  white-space: normal;
`;

const ContextMenu = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    &:last-child {
      border-top: 1px solid ${({ theme }) => theme.lightNeutralOutline};
    }
  }

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    * {
      display: block;
      background: none;
      border: 0;
      border-radius: 2px;
      margin: 4px;
      padding: 4px;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      text-align: left;
      cursor: pointer;
      color: ${({ theme }) => theme.colors.core.white[0].hex};

      &:hover {
        background: ${({ theme }) => theme.component.table.borderColor};
      }
    }
  }
  span {
    margin: 0px;
    padding: 0px;
    white-space: nowrap;
    background: none;

    &:hover {
      background: none;
    }
  }
`;

const MenuCell = styled(TableCell)`
  display: flex;
`;

const StyledTableRow = styled(TableRow)`
  cursor: pointer;

  ${MenuCell} {
    > button {
      margin: 15px auto;
      visibility: hidden;
    }
  }

  &:hover {
    ${MenuCell} {
      > button {
        visibility: visible;
      }
    }
  }
`;

export const SettlementsDetailsDataTable: React.FC<DataTableProps> = props => {
  const transactionForDeletion = useRef(undefined);
  const [isDeleting, setIsDeleting] = useState(false);
  const {
    data,
    pageSizeOptions,
    count,
    total,
    handleQueryChange,
    loading,
    onRemove,
    onItemClick,
    currentPage,
    lastPage,
    defaultPageSize,
    openSettlement,
    modalDispatch,
    authToken,
    settlement,
    selectedMerchant,
  } = props;

  // Row and column data for table
  const rowData = useMemo(() => data.rows, [data.rows]);
  const columns = useMemo(() => data.columns, [data.columns]);

  const { toaster, toast } = useToaster();
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { pageSize },
    setSortBy,
  } = useTable(
    {
      columns,
      data: rowData,
      defaultColumn: {
        width: 0,
      },
      manualPagination: true,
      manualSortBy: true,
      manualFilters: true,
      manualGlobalFilter: true,
      pageCount: count,
      initialState: {
        sortBy: [
          {
            id: 'created_at',
            desc: true,
          },
        ],
        pageSize: defaultPageSize,
        pageIndex: currentPage,
      },
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  const { permit } = usePermissions();

  const canManageAdjustmentTransactions = permit(
    'godview',
    'engineAdjustmentTransactions',
    'write'
  );

  useEffect(() => {
    setPageSize(defaultPageSize);
  }, [defaultPageSize, setPageSize]);

  useAsyncEffect(async () => {
    if (!page) {
      return;
    }
  }, [page]);

  const selectedItems = useMemo(() => {
    return selectedFlatRows.map(
      selectedFlatRow => selectedFlatRow.original as API.SettlementTransaction
    );
  }, [selectedFlatRows]);

  const closeModal = () => {
    if (transactionForDeletion.current !== undefined) {
      transactionForDeletion.current = undefined;
    }
    setIsDeleting(false);
  };

  return (
    <>
      <TableWrapper>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, index) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column: any, index) => (
                  <TableHeaderCell
                    style={column.style}
                    key={index}
                    /** This will sort the table based on
                     * the enabled sorted field
                     */
                    onClick={() => {
                      if (column.disableSortBy !== true) {
                        //set sort desc, asc or undefined?
                        const desc =
                          column.isSortedDesc === false ? true : false;
                        setSortBy([{ id: column.id, desc }]);
                        if (desc !== undefined) {
                          // this will pass the order value based on the sort icon clicked
                          let order = desc === false ? 'ASC' : 'DESC';
                          handleQueryChange({ sort: column.id, order });
                        }
                      }
                    }}
                  >
                    {column.render('Header')}

                    {column.isSorted ? (
                      <SortIndicator
                        sort={column.isSortedDesc ? 'desc' : 'asc'}
                      />
                    ) : column.disableSortBy ? null : (
                      <SortIndicator sort="desc" />
                    )}
                  </TableHeaderCell>
                ))}
                <TableHeaderCell style={{ width: 40 }}>&nbsp;</TableHeaderCell>
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, ind) => (
              <React.Fragment key={ind}>
                {loading
                  ? ind < pageSize
                    ? (prepareRow(row),
                      (
                        <StyledTableRow {...row.getRowProps()} key={ind}>
                          {row.cells.map((cell: any, index) => {
                            return (
                              <TableCell
                                {...cell.getCellProps()}
                                key={index}
                                style={cell.column.style}
                              >
                                <LoadingSpan
                                  style={cell.column.loadingWidthstyle}
                                  width={cell.column.loadingWidth}
                                />
                              </TableCell>
                            );
                          })}
                        </StyledTableRow>
                      ))
                    : null
                  : (prepareRow(row),
                    (
                      <StyledTableRow {...row.getRowProps()} key={ind}>
                        {row.cells.map((cell: any, index) => {
                          return (
                            <TableCell
                              onClick={() => {
                                if (cell.column.id !== 'selection') {
                                  onItemClick(data?.rows[ind]);
                                }
                              }}
                              {...cell.getCellProps()}
                              key={index}
                              style={cell.column.style}
                            >
                              {cell.render('Cell')}
                            </TableCell>
                          );
                        })}
                        <MenuCell style={{ padding: 0, textAlign: 'center' }}>
                          <Dropdown
                            placement="left-start"
                            trigger={(setRef, isOpen, setIsOpen) => (
                              <CopyButton
                                ref={setRef}
                                style={{
                                  marginLeft: '16px',
                                  marginRight: '16px',
                                }}
                                onClick={e => {
                                  e.stopPropagation();
                                  setIsOpen(!isOpen);
                                }}
                              >
                                <Icon icon={['fas', 'ellipsis-h']} />
                              </CopyButton>
                            )}
                          >
                            <DropdownContent
                              style={{ minWidth: '122px', width: 'auto' }}
                            >
                              <ContextMenu>
                                <ul>
                                  {canManageAdjustmentTransactions &&
                                    openSettlement &&
                                    isAdjustmentTransaction(
                                      row.original as AdjustmentTransaction
                                    ) && (
                                      <li
                                        onClick={() =>
                                          openAdjustmentsModal(
                                            row.original as AdjustmentTransaction,
                                            'EDIT'
                                          )
                                        }
                                      >
                                        <button>Edit Adjustment</button>
                                      </li>
                                    )}
                                  {canManageAdjustmentTransactions && (
                                    <React.Fragment>
                                      <li
                                        onClick={() =>
                                          openAdjustmentsModal(
                                            row.original as AdjustmentTransaction,
                                            'CREATE',
                                            'CREDIT'
                                          )
                                        }
                                      >
                                        <button>Credit Adjustment</button>
                                      </li>
                                      <li
                                        onClick={() =>
                                          openAdjustmentsModal(
                                            row.original as AdjustmentTransaction,
                                            'CREATE',
                                            'DEBIT'
                                          )
                                        }
                                      >
                                        <button>Debit Adjustment</button>
                                      </li>
                                    </React.Fragment>
                                  )}
                                  <li>
                                    <StyledTooltip
                                      content={
                                        <span>
                                          Copy <strong>Transaction ID</strong>{' '}
                                          to Clipboard
                                          <br />
                                          {
                                            (
                                              row.original as Engine.DB.Transaction
                                            ).transaction_id
                                          }
                                        </span>
                                      }
                                    >
                                      <button
                                        onClick={() =>
                                          onCopyTransactionId(
                                            row,
                                            row.original as Engine.DB.Transaction
                                          )
                                        }
                                      >
                                        Copy Transaction ID
                                      </button>
                                    </StyledTooltip>
                                  </li>
                                  {(row.original as API.SettlementTransaction)
                                    .processor_mid ? (
                                    <li>
                                      <StyledTooltip
                                        content={
                                          <span>
                                            Copy <strong>MID</strong> to
                                            Clipboard
                                            <br />
                                            {
                                              (
                                                row.original as API.SettlementTransaction
                                              ).processor_mid
                                            }
                                          </span>
                                        }
                                      >
                                        <button
                                          onClick={() =>
                                            onCopyMerchantId(
                                              (
                                                row.original as API.SettlementTransaction
                                              ).processor_mid
                                            )
                                          }
                                        >
                                          Copy MID
                                        </button>
                                      </StyledTooltip>
                                    </li>
                                  ) : (
                                    <></>
                                  )}
                                  {canManageAdjustmentTransactions &&
                                    openSettlement &&
                                    isAdjustmentTransaction(
                                      row.original as AdjustmentTransaction
                                    ) && (
                                      <li
                                        onClick={() =>
                                          openAdjustmentsModal(
                                            row.original as AdjustmentTransaction,
                                            'DELETE'
                                          )
                                        }
                                      >
                                        <button>Delete Adjustment</button>
                                      </li>
                                    )}
                                </ul>
                              </ContextMenu>
                            </DropdownContent>
                          </Dropdown>
                          <RemoveButton
                            style={{
                              marginLeft: '16px',
                              marginRight: '16px',
                            }}
                            onClick={() => {
                              transactionForDeletion.current = get(
                                row.original,
                                'transaction_id'
                              );
                              setIsDeleting(true);
                            }}
                          >
                            <StyledTooltip content="Remove">
                              <Icon icon={faBan} />
                            </StyledTooltip>
                          </RemoveButton>
                        </MenuCell>
                      </StyledTableRow>
                    ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        <TableTools>
          <Pagination
            onNextPage={() => {
              nextPage();
              handleQueryChange({ page: currentPage + 1 });
            }}
            onPreviousPage={() => {
              previousPage();
              handleQueryChange({ page: currentPage - 1 });
            }}
            onSkipToEnd={() => {
              gotoPage(count - 1);
              handleQueryChange({ page: count });
            }}
            onSkipToBeginning={() => {
              gotoPage(0);
              handleQueryChange({ page: 1 });
            }}
            onGoToPage={(page: number) => {
              gotoPage(page);
              handleQueryChange({ page: page + 1 });
            }}
            totalPages={count}
            totalRecords={total}
            recordsPerPage={pageSize}
            onSetPageSize={size => {
              setPageSize(size);
              handleQueryChange({ perPage: size });
            }}
            currentPage={currentPage - 1}
            pageSizeOptions={pageSizeOptions}
            canNextPage={currentPage === lastPage ? false : true}
            canPreviousPage={currentPage === 1 ? false : true}
          />
        </TableTools>
      </TableWrapper>
      <BulkActionMenu
        isOpen={selectedItems.length > 0}
        onClose={() => toggleAllRowsSelected(false)}
      >
        <BulkActionMenuCount>{selectedItems.length}</BulkActionMenuCount>
        <BulkActionMenuContent>
          <BulkActionMenuTitle>Selected</BulkActionMenuTitle>
          <BulkActionMenuButton
            color={`colors.status.red[500].hex`}
            icon={faBan}
            label={`Remove`}
            onClick={() => {
              setIsDeleting(true);
            }}
          />
        </BulkActionMenuContent>
      </BulkActionMenu>
      <BaseModal
        title="Bulk Remove"
        isOpen={isDeleting}
        onRequestClose={closeModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={false}
        style={{
          overlay: { pointerEvents: 'auto' },
          content: { maxWidth: '533px', height: '429px' },
        }}
      >
        <ModalHeader title={'Bulk Remove'} onClose={closeModal} />
        <StyledModalContent>
          <Icon icon={faExclamationTriangle} style={{ flex: 2 }} />
          <h2>
            Are you sure that you would like to remove{' '}
            {transactionForDeletion.current === undefined
              ? selectedItems.length
              : 1}{' '}
            transaction{selectedItems?.length > 1 ? 's' : ''} from the
            settlement?{' '}
          </h2>
        </StyledModalContent>
        <ModalFooter>
          <ButtonBar>
            <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
            <PrimaryButton
              onClick={async () => {
                const itemsToRemove =
                  transactionForDeletion.current !== undefined
                    ? [transactionForDeletion.current]
                    : selectedItems.map(id => id.transaction_id);
                await onRemove(itemsToRemove);
                closeModal();
              }}
            >
              Continue
            </PrimaryButton>
          </ButtonBar>
        </ModalFooter>
      </BaseModal>
    </>
  );

  async function onCopyTransactionId(
    row: Row<object>,
    rowData: Engine.DB.Transaction
  ) {
    copy(rowData.transaction_id);
    toaster(toast.success(`${rowData.transaction_id} copied to clipboard`));
  }

  async function onCopyMerchantId(merchantId: string) {
    copy(merchantId);
    toaster(toast.success(`${merchantId} copied to clipboard`));
  }

  function openAdjustmentsModal(
    transaction: AdjustmentTransaction,
    action: AdjustmentTransactionAction,
    type?: AdjustmentTransactionType
  ): void {
    modalDispatch(
      openModal(AdjustmentTransactionsModal, {
        source: 'transaction',
        action,
        type,
        transaction,
        authToken,
        merchant: {
          id: transaction?.merchant_id,
          name: settlement?.company_name,
          companyId: settlement?.company_id,
          mids: getMerchantMids(selectedMerchant.gateways.data),
        },
        onDone: () => {
          if (typeof props.onReloadSettlementDetails === 'function') {
            props.onReloadSettlementDetails();
          }
          if (typeof props.onReloadTransactions === 'function') {
            props.onReloadTransactions();
          }
        },
      })
    );
  }
};
