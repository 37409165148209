import React from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import styled from 'styled-components';

const Error = styled.span`
  font-size: 11px;
  margin: 0;
  width: 100%;

  text-align: left;
  color: ${({ theme }) => theme.colors.status.red[500].hex};
  display: none;

  animation: fade-in 0.3s ease-in-out both, slide-down 0.3s ease-in-out both;

  &.-visible {
    display: block;
  }
`;

export function FieldError<FieldValue, T extends HTMLElement>(
  props: FieldProps<FieldValue, T> & {
    validateOn?:
      | keyof FieldRenderProps<FieldValue, T>['meta']
      | (keyof FieldRenderProps<FieldValue, T>['meta'])[]
      | 'any';
  }
): JSX.Element {
  const { name, validateOn = 'touched', ...spanProps } = props;
  const allValidateOn = Array.isArray(validateOn) ? validateOn : [validateOn];

  return (
    <Field
      name={name}
      subscription={{ touched: true, error: true, submitError: true }}
    >
      {fieldProps => {
        const { meta } = fieldProps;
        const isVisible =
          (meta.error || meta.submitError) &&
          (validateOn === 'any' || allValidateOn.some(vo => meta[vo]));

        return (
          <Error className={isVisible ? '-visible' : ''} {...spanProps}>
            {meta.error}
          </Error>
        );
      }}
    </Field>
  );
}
