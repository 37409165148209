import {
  underwritingStatuses,
  underwritingStatusAllReasons,
} from './underwriting.util';

// If the status is not a valid underwriting status then
// it should return "AWAITING REVIEW" or "AWAITING APPLICATION SIGNATURE"
// depending on the electronic signature status.
export function getUnderwritingStatusName(
  status: string | null,
  electronicSignatureTimestamp: string | null
): string {
  const alternativeStatus =
    electronicSignatureTimestamp !== null
      ? 'AWAITING REVIEW'
      : 'AWAITING APPLICATION SIGNATURE';

  return underwritingStatuses[status]
    ? underwritingStatuses[status]
    : alternativeStatus;
}

export function generateMockPayload(
  mockUnderwritingStatus: string,
  mockReasons: any[],
  mockNotes: string
) {
  // Base payload. This payload gets pushed if the status is "AWAITING_REVIEW".
  let payload = {
    underwriting_status: null,
    underwriting_substatuses: [],
    underwriting_note: null,
  };

  if (mockUnderwritingStatus !== 'AWAITING_REVIEW') {
    payload = {
      underwriting_status: mockUnderwritingStatus,
      underwriting_substatuses: mockReasons.map(reason => {
        return {
          key: reason,
          message: underwritingStatusAllReasons[reason],
        };
      }),
      underwriting_note: mockNotes,
    };
  }

  return payload;
}
