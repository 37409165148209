import { Dispatch, ReactNode } from 'react';

export interface PageHeaderContextProps {
  children: ReactNode;
}

export interface PageHeaderState {
  previousTitle: string;
  currentTitle: string;
}

export enum PageHeaderActionType {
  UPDATE_TITLE = 'UPDATE_TITLE'
}

export type PageHeaderAction = { type: PageHeaderActionType; payload: string };

export interface PageHeaderContext {
  state: PageHeaderState;
  dispatch: Dispatch<PageHeaderAction>;
}
