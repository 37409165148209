import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { StatementContainer } from '../shared/statement-container-component';
import { HeaderSection } from './components';
import {
  StatementLoadingContextProvider,
  useStatementLoadingState,
} from '../../../context/statements/statement-loading-context';
import { StatementSummaryContextProvider } from '../../../context/statements/statement-summary-context';
import { Loading } from './components/loading';
import { ErrorRetry } from './components/error-retry';
import { RouteComponentProps } from 'react-router';

type FeeStatementV3WrapperProps = Pick<
  RouteComponentProps<{ activityPeriod: string }>,
  'location' | 'match'
> & { startDate: string };

const Container = styled.div`
  height: 100%;
`;

const NotCompletedContainer = styled.div`
  height: calc(100vh - 97px);

  > *:first-child {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 16px;
  }
`;

const FeeStatementV3InnerWrapper: FunctionComponent<
  FeeStatementV3WrapperProps
> = props => {
  const { startDate, children } = props;
  const {
    statementLoadingState: { failedCount, loadingCount, isFirstLoad },
  } = useStatementLoadingState();

  return (
    <Container>
      {/* Header Links Section */}
      <HeaderSection startDate={startDate} />

      {failedCount > 0 ? (
        <NotCompletedContainer>
          <ErrorRetry />
        </NotCompletedContainer>
      ) : null}
      {isFirstLoad && failedCount === 0 && loadingCount > 0 ? (
        <NotCompletedContainer>
          <Loading />
        </NotCompletedContainer>
      ) : null}
      <StatementContainer
        id="statement"
        data-is-loading={isFirstLoad && (loadingCount > 0 || failedCount > 0)}
      >
        {children}
      </StatementContainer>
    </Container>
  );
};

export const FeeStatementV3Wrapper: FunctionComponent<
  FeeStatementV3WrapperProps
> = props => {
  return (
    <StatementLoadingContextProvider {...props}>
      <StatementSummaryContextProvider>
        <FeeStatementV3InnerWrapper {...props} />
      </StatementSummaryContextProvider>
    </StatementLoadingContextProvider>
  );
};
