import { Brand } from '@fattmerchantorg/types-omni';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { permissionsapi } from '../../api';
import { SelectedMerchantStore } from '../../context';
import { useAuthToken, usePermissions, useToaster } from '../../hooks';
import { LoadingAnimation } from '../truffle-dashboard/components/shared/LoadingAnimation';
import { BrandData } from './BrandData';
import {
  ContentSeparator,
  MainContent,
  PageContent,
  SettingsHeaderContent,
  SettingsHeaderContentTitle,
  SettingsHeaderContentTitleText,
  SettingsHeaderRow,
  SettingsSubheader,
} from './SettingsPagesStylings';

export const BrandSettingsPage: FunctionComponent = () => {
  const {
    state: { selectedBrandSwitcherOption },
  } = useContext(SelectedMerchantStore);

  const authToken = useAuthToken();
  const [currentBrand, setCurrentBrand] = useState<Brand>({});
  const [isLoading, setIsLoading] = useState(true);
  const { permit } = usePermissions();
  const { toast, toaster } = useToaster();

  const canWrite = permit('godview', 'branding', 'write');

  const fetchBrandDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      if (
        !selectedBrandSwitcherOption ||
        selectedBrandSwitcherOption.includes(',')
      ) {
        return;
      }
      const merchantBrandData: Brand = await permissionsapi.get(
        authToken,
        `/brand/data/${selectedBrandSwitcherOption}`
      );
      setCurrentBrand(merchantBrandData);
    } catch (error) {
      toaster(
        toast.error(
          error,
          'There was a problem fetching the brand information.'
        )
      );
    } finally {
      setIsLoading(false);
    }
  }, [authToken, selectedBrandSwitcherOption, toast, toaster]);

  useEffect(() => {
    fetchBrandDetails();
  }, [fetchBrandDetails]);

  return (
    <ContentSeparator>
      <PageContent>
        {/* @TODO add loading */}
        {isLoading ? (
          <>
            <SettingsHeaderContent>
              <SettingsHeaderRow>
                <SettingsHeaderContentTitle>
                  <SettingsHeaderContentTitleText>
                    Brand
                  </SettingsHeaderContentTitleText>
                  {canWrite ? (
                    <SettingsSubheader>
                      Change your display names, colors, logos etc.
                    </SettingsSubheader>
                  ) : null}
                </SettingsHeaderContentTitle>
              </SettingsHeaderRow>
            </SettingsHeaderContent>

            <MainContent>
              <LoadingAnimation />
            </MainContent>
          </>
        ) : (
          <BrandData
            key={`brand-data-${currentBrand?.id}`}
            currentBrand={currentBrand}
            getBrand={fetchBrandDetails}
          />
        )}
      </PageContent>
    </ContentSeparator>
  );
};
