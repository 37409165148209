import {
  SmallPrimaryButton,
  SmallSecondaryButton,
  TextField,
  Tooltip,
  Label,
} from '@fattmerchantorg/truffle-components';
import styled from 'styled-components';
import { User } from '@fattmerchantorg/types-omni';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import pick from 'lodash/pick';
import { Form as FinalForm } from 'react-final-form';
import { AxiosError } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Field } from '../shared';
import { coreapi } from '../../api';
import { AuthStore, updateAuthUser, updateAuthMerchant } from '../../context';
import { history } from '../../history';
import { useToaster, useSearchState } from '../../hooks';
import { ButtonSpinner, Select } from '../shared';
import { BoldText } from '../truffle-dashboard/components/shared/BoldText';
import { getAllTimezones } from '../../util/date.util';
import { isRequired } from '../../util/form.util';
import { isValidEmail } from '../../util/validator.util';
import { ConfirmModal } from './ConfirmModal';
import { PasswordModal } from './PasswordModal';

import {
  ContentSeparator,
  MainContent,
  PageContent,
  SettingsHeaderContent,
  SettingsHeaderRow,
  SettingsHeaderContentTitle,
  SettingsHeaderContentTitleText,
  SettingsHeaderContentActions,
  SettingsSubheader,
  SettingsSection,
  SettingsSectionHeader,
  SettingsSectionContent,
  SettingsSectionInfoText,
  TextFieldWrapper,
} from './SettingsPagesStylings';

type FormValues = {
  name: string;
  email: string;
  timeZone: string;
};

const InfoText = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.core.gray[400].hex};
`;

export const AccountSettingsPage: FunctionComponent = () => {
  const { dispatch: authDispatch, state } = useContext(AuthStore);
  const [isDisableMfaModalOpen, setIsDisableMfaModalOpen] = useState(false);
  const [isEnableMfaModalOpen, setIsEnableMfaModalOpen] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [mfaEnabled, setMfaEnabled] = useState(
    state?.auth?.user?.mfa_enabled || false
  );
  const authToken = state?.auth?.token || null;
  const { toast, toaster } = useToaster();

  useEffect(() => {
    setMfaEnabled(state?.auth?.user?.mfa_enabled);
  }, [state]);

  const user = state?.auth?.user;
  const merchant = state?.auth?.merchant;
  const [{ update_password: autoOpenPassword }] = useSearchState();

  useEffect(() => {
    if (autoOpenPassword) {
      setIsPasswordModalOpen(true);
    }
  }, [autoOpenPassword]);

  const initialValues = useMemo(() => {
    return {
      name: user?.name,
      email: user?.email,
      timeZone: merchant?.options?.timezone ?? 'auto',
    };
  }, [user, merchant]);

  const checkEmail = async (email: string) => {
    if (!email) {
      return 'Required';
    }

    if (email === user?.email) return;

    if (!isValidEmail(email)) return 'Invalid email';

    try {
      await coreapi.get(
        authToken,
        `/self/exists?email=${encodeURIComponent(email)}`,
        undefined,
        undefined,
        (error: AxiosError) => error
      );
      return 'Email taken!';
    } catch (error) {
      // If we get a 404 then the user's not found and the email is available
      // For other errors we log the error, but we don't display it.  If there's
      // a serious problem the error will be reported when the user actually
      // submits
      if (error?.response?.status !== 404) {
        console.log(error);
      }
    }
  };

  const handleSubmit = useCallback(
    async (formValues: FormValues) => {
      if (!formValues) return;

      setIsSaving(true);

      try {
        await coreapi.put(
          authToken,
          `/self`,
          pick(formValues, ['name', 'email'])
        );
        await coreapi.put(authToken, `/team/option/timezone`, {
          value: formValues.timeZone,
        });

        const { user, merchant } = await coreapi.get(authToken, '/self');

        authDispatch(updateAuthUser(user));
        authDispatch(updateAuthMerchant(merchant));

        toaster(toast.success('Changes successfully saved', 'Saved'));
      } catch (error) {
        toaster(toast.error(error, 'There was a problem saving your changes.'));
      }
      setIsSaving(false);
    },
    [authDispatch, authToken, toast, toaster]
  );

  const updateUserMFA = async mfaEnabled => {
    await coreapi
      .put(authToken, `/self`, {
        mfa_enabled: mfaEnabled,
      })
      .then((user: User) => {
        setMfaEnabled(user.mfa_enabled);
        toaster(
          toast.success(
            `MFA Status successfully ${mfaEnabled ? 'enabled' : 'disabled'}.`,
            'Updated'
          )
        );

        if (mfaEnabled) {
          history.push('/logout');
        }
      })
      .catch(() => {
        toaster(toast.error(`MFA Status update failed`, 'Updated'));
      });
  };

  const timeZoneOptions = [
    {
      value: 'auto',
      label: 'Automatic by Location',
    },
    ...getAllTimezones().map(tz => ({
      value: tz.value,
      label: `${tz.name} (${tz.offset})`,
    })),
  ];

  return (
    <ContentSeparator>
      <PageContent>
        <FinalForm<FormValues>
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          {({
            handleSubmit,
            dirty,
            form,
            hasValidationErrors,
            submitFailed,
          }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <SettingsHeaderContent>
                  <SettingsHeaderRow>
                    <SettingsHeaderContentTitle>
                      <SettingsHeaderContentTitleText>
                        Account
                      </SettingsHeaderContentTitleText>
                      <SettingsSubheader>
                        Change your password, email and set up MFA
                      </SettingsSubheader>
                    </SettingsHeaderContentTitle>

                    <SettingsHeaderContentActions>
                      <SmallSecondaryButton
                        type="button"
                        onClick={form.reset}
                        disabled={isSaving || !dirty}
                      >
                        Cancel
                      </SmallSecondaryButton>
                      <SmallPrimaryButton
                        type="submit"
                        disabled={hasValidationErrors || !dirty}
                      >
                        {isSaving ? <ButtonSpinner /> : 'Save'}
                      </SmallPrimaryButton>
                    </SettingsHeaderContentActions>
                  </SettingsHeaderRow>
                </SettingsHeaderContent>

                <MainContent>
                  <SettingsSection>
                    <SettingsSectionHeader>
                      User Information
                    </SettingsSectionHeader>

                    <SettingsSectionContent>
                      <Field name="name" validate={isRequired}>
                        {props => (
                          <TextFieldWrapper>
                            <TextField
                              {...props.input}
                              className="user-name"
                              placeholder="Name"
                              label="Name"
                              error={!!props.meta.error}
                              helperText={props.meta.error}
                            />
                          </TextFieldWrapper>
                        )}
                      </Field>

                      <Field
                        name="email"
                        validate={checkEmail}
                        debouncedValidate
                      >
                        {props => (
                          <TextFieldWrapper>
                            <TextField
                              {...props.input}
                              className="user-email"
                              placeholder="Email Address"
                              label="Email Address"
                              error={!!props.meta.error}
                              helperText={props.meta.error}
                            />
                          </TextFieldWrapper>
                        )}
                      </Field>

                      <Label text="Role" />
                      <TextFieldWrapper>
                        <Select value="" className="user-role" disabled>
                          <option value="">{user?.team_role}</option>
                        </Select>
                      </TextFieldWrapper>
                      <InfoText>
                        Roles can be edited under Permissions.
                      </InfoText>

                      <Field name="timeZone">
                        {props => (
                          <>
                            <Label text="Timezone" />
                            <TextFieldWrapper>
                              <Select
                                {...props.input}
                                className="user-timezone"
                              >
                                {timeZoneOptions.map(({ value, label }) => (
                                  <option key={value} value={value}>
                                    {label}
                                  </option>
                                ))}
                              </Select>
                            </TextFieldWrapper>
                          </>
                        )}
                      </Field>
                    </SettingsSectionContent>

                    <SettingsSectionHeader>
                      <span className="mr-2">Security</span>
                      <Tooltip content="Manage and your password and security settings">
                        <FontAwesomeIcon
                          size="sm"
                          icon={faInfoCircle as IconProp}
                        />
                      </Tooltip>
                    </SettingsSectionHeader>

                    <SettingsSectionContent>
                      <BoldText fontSize={14} marginBottom={8}>
                        Password
                      </BoldText>
                      <SettingsSectionInfoText>
                        A strong password is composed of at least 8 characters
                        with upper, lower, digits and special characters. It
                        should be hard to guess and unique to this account.
                      </SettingsSectionInfoText>
                      <SmallPrimaryButton
                        onClick={e => {
                          e.preventDefault();
                          setIsPasswordModalOpen(true);
                        }}
                      >
                        Update Password
                      </SmallPrimaryButton>
                    </SettingsSectionContent>

                    <SettingsSectionContent>
                      <BoldText fontSize={14} marginBottom={8}>
                        Multi-factor Authentication
                      </BoldText>
                      <SettingsSectionInfoText>
                        Multi-factor Authentication adds an additional layer of
                        security to your account by requiring more than just a
                        password to log in.
                      </SettingsSectionInfoText>

                      {mfaEnabled ? (
                        <SmallSecondaryButton
                          onClick={e => {
                            e.preventDefault();
                            setIsDisableMfaModalOpen(true);
                          }}
                        >
                          Disable Multi-Factor Authentication
                        </SmallSecondaryButton>
                      ) : (
                        <SmallPrimaryButton
                          onClick={e => {
                            e.preventDefault();
                            setIsEnableMfaModalOpen(true);
                          }}
                        >
                          Enable Multi-Factor Authentication
                        </SmallPrimaryButton>
                      )}
                    </SettingsSectionContent>
                  </SettingsSection>
                </MainContent>
              </form>
            );
          }}
        </FinalForm>

        <ConfirmModal
          title="Disable Multi-Factor Authentication"
          onConfirm={() => {
            updateUserMFA(false);
            setIsDisableMfaModalOpen(false);
          }}
          onClose={() => setIsDisableMfaModalOpen(false)}
          confirmButtonLabel={'Disable'}
          isOpen={isDisableMfaModalOpen}
          submitButtonColor="#FF4646"
        >
          <p>Are you sure you want to disable Multi-Factor Authentication?</p>
        </ConfirmModal>
        <ConfirmModal
          title="Enable Multi-Factor Authentication"
          onConfirm={() => {
            updateUserMFA(true);
            setIsEnableMfaModalOpen(false);
          }}
          onClose={() => setIsEnableMfaModalOpen(false)}
          confirmButtonLabel={'Enable'}
          isOpen={isEnableMfaModalOpen}
          submitButtonColor="#59A97E"
        >
          <p>
            By enabling MFA you will be logged out so that you can complete and
            confirm your enrollment.
          </p>
        </ConfirmModal>

        <PasswordModal
          onSubmitted={() => {
            setIsPasswordModalOpen(false);
          }}
          onCancel={() => setIsPasswordModalOpen(false)}
          isOpen={isPasswordModalOpen}
        />
      </PageContent>
    </ContentSeparator>
  );
};
