import React, { FunctionComponent, useState, useContext } from 'react';
import { Dispatch } from 'react';
import get from 'lodash/get';
import styled from 'styled-components';
import {
  BaseModal,
  ModalHeader,
  ModalFooter,
  SecondaryButton,
  Icon,
} from '@fattmerchantorg/truffle-components';
import { ModalContext } from '../../../../../../context/modal';
import {
  Registration,
  RegistrationAuditLog,
} from '@fattmerchantorg/types-omni';
import {
  parseMagFieldErrorsFromAuditLog,
  parsePayfacFieldErrorsFromAuditLog,
  RegistrationAuditLogFieldError,
} from '../../../../../../util/auditLog.util';
import { getModalInfoByRegistrationField } from './FieldErrorModal.util';
import { resolveFISField } from './fieldMapRegToFIS';
import { resolveValidationField } from './fieldMapRegToPayfac';
import { BrandWithPricing } from '../../../processing-environment/ProcessingEnvironment';

const ButtonBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  > * {
    margin: 0 0 0 ${({ theme }) => theme.space[4]}px;
  }
`;

const StyledModalContent = styled.div`
  grid-area: content;
  overflow-y: auto;
  padding: ${({ theme }) => theme.space[4]}px;
  color: ${({ theme }) => theme.component.modal.color};
`;

const AlertBanner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  margin: -8px -8px 18px -8px;
  font-size: 12px;
  color: ${({ theme }) => theme.black};
  background: ${({ theme }) => theme.colors.status.yellow[500].hex};
`;

const ErrorGroups = styled.section`
  > div {
    border-bottom: 1px solid #435e70;
    padding: 0 0 10px;
    margin: 0 0 10px;

    &:last-of-type {
      border-bottom: none;
    }

    p {
      margin: 0 0 8px;
      font-size: 14px;
    }
  }
`;

const FollowArrow = styled(Icon)`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.status.blue[500].hex};
`;

const ModalFollowButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  font-weight: 700;
  padding: 0;
  margin: 0 0 8px;
  color: ${({ theme }) => theme.white};
  width: 100%;
  transition: color 0.2s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.status.blue[500].hex};
  }
`;

interface FieldErrorModal {
  isOpen?: boolean;
  auditLog: RegistrationAuditLog;
  registration: Registration;
  merchantId: string;
  merchantDispatch: Dispatch<any>;
  brand: BrandWithPricing;
}

export const FieldErrorModal: FunctionComponent<FieldErrorModal> = props => {
  const {
    isOpen,
    auditLog,
    registration,
    merchantId,
    merchantDispatch,
    brand,
  } = props;
  const { modalDispatch } = useContext(ModalContext);
  const [isModalVisible] = useState(!!isOpen);

  if (Array.isArray(auditLog)) {
    console.error('Invalid data sent to FieldErrorModal');
    return null;
  }

  const serviceName = get(auditLog, 'serviceName', null);

  if (serviceName === null) {
    throw new Error('No service name included in auditLog for FieldErrorModal');
  }

  let errors: RegistrationAuditLogFieldError[];

  // This may eventually support the validation for each processor so we switch on the service name
  switch (serviceName) {
    case 'mag':
      errors = parseMagFieldErrorsFromAuditLog(auditLog);
      break;
    case 'payfac':
      errors = parsePayfacFieldErrorsFromAuditLog(auditLog);
      break;
    default:
      errors = [];
  }

  // Return early if there is no error data to use
  let hasErrors = errors && Array.isArray(errors) && errors.length;

  if (!hasErrors && auditLog.result?.payfacSubmissionResponse) {
    errors = [
      {
        code: get(auditLog, 'result.payfacSubmissionResponse.Code'),
        text: get(auditLog, 'result.payfacSubmissionResponse.Description'),
        field: '',
      },
    ];

    hasErrors = 1;
  }

  const handleCloseModal = () => {
    modalDispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const handleFollowErrorLinkClick = (modalComponent: React.ReactNode) => {
    if (!modalComponent) return false;
    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        component: modalComponent,
        props: {
          isOpen: true,
          registration: registration,
          merchantId: merchantId,
          merchantDispatch: merchantDispatch,
          brand: brand,
        },
      },
    });
  };

  const renderErrorDetails = (
    errors: RegistrationAuditLogFieldError[],
    fieldResolver?: Function
  ) => {
    const errorGroups = {};

    // Set FIS/Mag as default field resolver
    if (typeof fieldResolver !== 'function') fieldResolver = resolveFISField;

    errors.forEach((error, index) => {
      if (
        error.text.startsWith('- Missing') &&
        typeof error.text === 'string'
      ) {
        const missingProfileErrors = error.text.split(',');
        const formattedErrors = missingProfileErrors.map(msg => {
          let trimmedMsg = msg.trim();
          if (trimmedMsg.startsWith('- Missing')) {
            return {
              text: trimmedMsg
                .replace('- Missing ', 'Missing ')
                .replace(' Profile', ' Fee'),
            };
          }
          return { text: trimmedMsg }; // for any non-formatted error within
        });

        errorGroups['Billing Profiles'] = {
          errors: formattedErrors,
        };
      } else {
        const modalInfo = getModalInfoByRegistrationField(fieldResolver(error));

        if (!modalInfo) return null;

        if (modalInfo.title in errorGroups === false) {
          errorGroups[modalInfo.title] = {
            modalInfo: modalInfo,
            errors: [error],
          };
        } else {
          errorGroups[modalInfo.title].errors.push(error);
        }
      }
    });

    return Object.keys(errorGroups).map((errorGroup, index) => {
      const currentErrorGroup = errorGroups[errorGroup];
      if (errorGroup === 'Billing Profiles') {
        return (
          <div key={`field-error-info-${index}`}>
            <p>
              <strong>{errorGroup}</strong>
            </p>

            {currentErrorGroup.errors.map((error, eIndex) => (
              <p key={eIndex}>{error.text}</p>
            ))}
          </div>
        );
      } else {
        return (
          <div key={`field-error-info-${index}`}>
            <ModalFollowButton
              onClick={() =>
                handleFollowErrorLinkClick(
                  currentErrorGroup.modalInfo.component
                )
              }
            >
              <strong>{currentErrorGroup.modalInfo.title}</strong>
              {currentErrorGroup.modalInfo.component && (
                <FollowArrow icon={['fas', 'chevron-right']} />
              )}
            </ModalFollowButton>
            {currentErrorGroup.errors.map((error, eIndex) => (
              <p key={eIndex}>{error.text}</p>
            ))}
          </div>
        );
      }
    });
  };

  const renderErrors = (errors: RegistrationAuditLogFieldError[]) => {
    switch (serviceName) {
      case 'mag':
        return renderErrorDetails(errors, resolveFISField);
      case 'payfac':
        // Handle special missingProfiles error
        const missingProfileError = errors.find(error =>
          error.text.startsWith('- Missing')
        );
        if (missingProfileError) {
          return renderErrorDetails([missingProfileError]);
        } else {
          return renderErrorDetails(errors, resolveValidationField);
        }
      default:
        return null;
    }
  };

  return (
    <div>
      <BaseModal
        title="Application Errors"
        isOpen={isModalVisible}
        onRequestClose={handleCloseModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={false}
        style={{
          overlay: { pointerEvents: 'auto' },
          content: { maxWidth: '533px' },
        }}
      >
        <ModalHeader title="Application Errors" onClose={handleCloseModal} />
        <>
          <StyledModalContent>
            <AlertBanner>
              <Icon
                icon={['fas', 'exclamation-triangle']}
                style={{ margin: '0 8px 0 0' }}
              />{' '}
              The following sections need your attention before you can submit
            </AlertBanner>
            <ErrorGroups>
              {hasErrors ? (
                renderErrors(errors)
              ) : (
                <p>No errors were found in the provided log entry.</p>
              )}
            </ErrorGroups>
          </StyledModalContent>
          <ModalFooter>
            <ButtonBar>
              <SecondaryButton onClick={handleCloseModal}>
                Close
              </SecondaryButton>
            </ButtonBar>
          </ModalFooter>
        </>
      </BaseModal>
    </div>
  );
};
