import { useEffect, useRef } from 'react';

/**
 * Hook that executes a handler function when user clicks outside a specified area.
 * @param handler function to be executed on clickaway
 * @param ignoreClass elements to ignore on clickaway (ex: button that opens/closes a modal)
 */
export function useClickaway(handler: Function, ignoreClass: string) {
  const domNode = useRef<HTMLElement>(null);

  useEffect(() => {
    // If the click happens outside the ref area, then execute the handler function.
    const clickEventHandler = event => {
      if (
        !event.target.classList.contains(ignoreClass) &&
        domNode.current &&
        !domNode.current.contains(event.target)
      ) {
        handler();
      }
    };

    document.addEventListener('mousedown', clickEventHandler);

    // Remove event listener on unmounting component.
    return () => {
      document.removeEventListener('mousedown', clickEventHandler);
    };
  });

  // Return ref so it can be attached to an area.
  return domNode;
}
