import React, { FunctionComponent, useMemo } from 'react';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import { StaxButton } from '../shared/Stax/Button';
import { JWT } from './JWT';
import { StaxTheme } from './StaxTheme';
import { Environment, getRelevantEnvs } from './Environment';
import { Contexts } from './Contexts';
import { useAuthToken, usePermissions } from '../../hooks';
import { decodeToken } from '../../util/jwt.util';
import { UserBrands } from './UserBrands';
import { FeaturePermissionsTester } from './FeaturePermissionsTester';
import { GodviewPermissionsTester } from './GodviewPermissionsTester';
import FeatureFlag from '../shared/FeatureFlag/FeatureFlag';
import { History } from 'history';
import { PermissionGatedRoute } from '../routes/components/PrivateRoutes';
import { Permissions } from '@fattmerchantorg/types-omni';
import {
  MemoryRouter,
  Switch,
  useHistory,
  Route,
  useLocation,
  Redirect,
} from 'react-router-dom';
import { RouteItem } from '../routes/models/RouteItem.model';

const StyledWrapper = styled.div`
  background: #eee;
  min-height: 100vh;
  * {
    color: black;
  }
  h1 {
    text-align: center;
  }
  p {
    word-wrap: break-word;
  }
`;

export const StyledButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

interface ICopyProps {
  val: string | null;
  label?: string;
}

export const Copy: FunctionComponent<ICopyProps> = ({ val, label }) => {
  return val ? (
    <StaxButton
      size="small"
      onClick={() => copy(val)}
      className="mr-2 text-white"
    >
      <i className="fas fa-copy mr-2 text-white"></i> {label ?? 'Copy'}
    </StaxButton>
  ) : null;
};

type DevRouteItem = RouteItem & {
  feature?: keyof Permissions['permissions']['features']['Developer'];
};

const relevantEnvs = getRelevantEnvs();
const hasLocalhost = Object.keys(relevantEnvs).some(k => {
  return relevantEnvs[k].toString().includes('localhost');
});

const routes: DevRouteItem[] = [
  { path: '/jwt', name: 'JWT', component: JWT },
  { path: '/user-brands', name: 'User Brands', component: UserBrands },
  { path: '/environment', name: 'Environment', component: Environment },
  { path: '/theme', name: 'Theme', component: StaxTheme },
  { path: '/context', name: 'Contexts', component: Contexts },
  {
    path: '/feature-permissions',
    name: 'Feature Perm.',
    component: FeaturePermissionsTester,
    feature: 'TestFeaturePermissions',
    redirectPath: '/jwt',
  },
  {
    path: '/godview-permissions',
    name: 'Godview Perm.',
    component: GodviewPermissionsTester,
    feature: 'TestGodviewPermissions',
    permitParams: ['godview', 'godviewPermissions', 'write'],
    redirectPath: '/jwt',
  },
];

const renderButton = (route: RouteItem, pathname: string, history: History) => {
  const isSelected = pathname.includes(route.path.toString());

  return (
    <StaxButton
      onClick={() => history.push(route.path.toString())}
      className="mb-2 text-left"
      style={{
        background: isSelected ? 'silver' : null,
        borderLeft: isSelected ? '4px solid gray' : null,
      }}
      key={route.path.toString()}
    >
      <i className="fas fa-chevron-right" /> {route.name}
    </StaxButton>
  );
};

export const DevToolsBase: FunctionComponent = () => {
  const { pathname } = useLocation();
  const { permit } = usePermissions();
  const history = useHistory();
  const jwt = useAuthToken();
  const {
    godUser: isGodUser,
    assuming: isAssuming,
    brand,
  } = useMemo(() => decodeToken(jwt), [jwt]);

  const hasSandboxBrand: boolean = brand.toString().endsWith('-sandbox');

  return (
    <Switch>
      <Route path="*">
        <StyledWrapper className="pb-1 pt-4 container-fluid">
          <div className="row">
            <div className="col">
              <h1 className="text-center">Dev Tools</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-2">
              <h2>General Info</h2>
              <FlexDetail isTrue={isGodUser} label="God User" />
              <FlexDetail isTrue={isAssuming} label="Assuming" />
              <FlexDetail isTrue={hasSandboxBrand} label="Sandbox Brand" />
              <FlexDetail isTrue={hasLocalhost} label="Running Local Service" />
              <h2>Modules</h2>
              <StyledButtonGroup>
                {routes.map(route => {
                  if (route.permitParams && !permit(...route.permitParams)) {
                    return null;
                  } else if (route.feature) {
                    return (
                      <FeatureFlag category="Developer" feature={route.feature}>
                        {renderButton(route, pathname, history)}
                      </FeatureFlag>
                    );
                  } else {
                    return renderButton(route, pathname, history);
                  }
                })}
              </StyledButtonGroup>
            </div>
            <div className="col-10">
              {routes.map(route => {
                const { feature, ...routeProps } = route;
                return route.feature ? (
                  <FeatureFlag category="Developer" feature={route.feature}>
                    <PermissionGatedRoute {...routeProps} />
                  </FeatureFlag>
                ) : (
                  <PermissionGatedRoute {...routeProps} />
                );
              })}
              <Redirect to={routes[0].path.toString()} />
            </div>
          </div>
        </StyledWrapper>
      </Route>
    </Switch>
  );
};

export const DevTools = () => (
  <MemoryRouter>
    <DevToolsBase />
  </MemoryRouter>
);

interface IFlexDetailProps {
  isTrue: boolean;
  label: string;
}

export const FlexDetail: FunctionComponent<IFlexDetailProps> = ({
  isTrue,
  label,
}) => {
  let icon: string = 'fa-times';
  let backgroundClass: string = 'bg-light';

  if (isTrue) {
    icon = 'fa-check';
    backgroundClass = 'bg-warning';
  }

  return (
    <div
      className={`${backgroundClass} text-white text-center p-1 my-2 flex-fill rounded`}
    >
      <i className={`fas ${icon} text-white mr-2`}></i>
      {label}
    </div>
  );
};
