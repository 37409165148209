import React, { FunctionComponent, useCallback, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle as RSDropdownToggle,
  DropdownMenu as RSDropdownMenu,
  DropdownItem as RSDropdownItem,
  DropdownItemProps,
} from 'reactstrap';
import { Button, Group, Modal, ResponsivePadding } from '../../../shared';

import { useAuthToken, useToaster } from '../../../../hooks';
import { importerapi } from '../../../../api';
import { parse } from '../../../../util/api.util';
import { Import, ImportState } from '../../../../@types';
import { stringify } from 'querystring';

const formatState = (state: Import['state']) => {
  switch (state) {
    case 'COMPLETE':
      return 'Complete';
    case 'UPLOAD_COMPLETE':
      return 'File Uploaded';
    case 'IMPORTING':
      return 'Importing';
    case 'UNDOING':
      return 'Undoing';
    default:
      return '';
  }
};

const DropdownToggle = styled(RSDropdownToggle)`
  color: ${({ theme }) => theme.white};

  &:hover {
    color: ${({ theme }) => theme.white};
  }
`;

const DropdownMenu = styled(RSDropdownMenu)`
  &[data-disabled='true'] {
    cursor: not-allowed !important;
  }
`;

const DropdownItem = styled(RSDropdownItem)`
  &[data-disabled='true'] {
    cursor: not-allowed !important;
    color: #525f7f !important;
    opacity: 0.5 !important;
  }
`;

const StateEnabledDropdownItem: FunctionComponent<
  DropdownItemProps & {
    id: DropdownItemProps['id'];
    enabledForState: ImportState;
    state: ImportState;
  }
> = props => {
  const { enabledForState, state, ...dropdownItemProps } = props;
  const isEnabled = enabledForState === state;

  return (
    <DropdownItem
      {...dropdownItemProps}
      // intentionally not using the 'disabled' prop here
      // because if 'disabled={true}', the onClick will not be called
      // and the click event will bubble up to the row and open up the import detail
      // which is not what we want
      data-disabled={!isEnabled}
      onClick={e => {
        e.stopPropagation();
        if (isEnabled) dropdownItemProps.onClick(e);
      }}
    >
      {dropdownItemProps.children}
      {!isEnabled && (
        <UncontrolledTooltip delay={0} target={dropdownItemProps.id}>
          Import must be in "{formatState(enabledForState)}" status
        </UncontrolledTooltip>
      )}
    </DropdownItem>
  );
};

export const ImportMenu: FunctionComponent<{ record: Import }> = props => {
  const { record } = props;
  const importId = record?.id;

  const authToken = useAuthToken();
  const { search, pathname } = useLocation();
  const [csv, setCsv] = useState(null);
  const aRef = useRef<HTMLAnchorElement>(null);
  const { toast, toaster } = useToaster();
  const history = useHistory();

  const [state, setState] = useState<
    'idle' | 'confirm-undo' | 'confirm-delete' | 'busy'
  >('idle');

  const handleDownloadCsvReport = useCallback(async () => {
    if (state !== 'idle') return;

    // if we already have the csv
    // just click the hidden link to download it
    if (csv) return aRef.current.click();

    setState('busy');

    try {
      const data = await importerapi.get(
        authToken,
        `/import/${importId}/report?export=csv`
      );

      setCsv(encodeURIComponent(data));

      // now that we have the csv
      // click the hidden link to download it
      aRef.current.click();
    } catch (error) {
      toaster(toast.error(error, 'Error downloading CSV: '));
    } finally {
      setState('idle');
    }
  }, [csv, authToken, importId, state, toast, toaster]);

  const handleUndo = useCallback(async () => {
    if (state !== 'confirm-undo') return;

    setState('busy');

    try {
      await importerapi.post(authToken, `/import/${importId}/undo`);

      toaster(toast.success(`Import ${importId} UNDO has been started.`));

      const query = parse(search);

      history.push({
        pathname,
        search: stringify({
          ...query,
          u: +((query.u as string) ?? 0) + 1,
        }),
      });
    } catch (error) {
      toaster(toast.error(error, 'Error undoing import: '));
    }
  }, [authToken, importId, state, toast, toaster, history, pathname, search]);

  const handleDelete = useCallback(async () => {
    if (state !== 'confirm-delete') return;

    setState('busy');

    try {
      await importerapi.delete(authToken, `/import/${importId}`);

      toaster(toast.success(`Import ${importId} has been deleted.`));

      const query = parse(search);

      history.push({
        pathname,
        search: stringify({
          ...query,
          u: +((query.u as string) ?? 0) + 1,
        }),
      });
    } catch (error) {
      toaster(toast.error(error, 'Error undoing import: '));
    }
  }, [authToken, importId, state, toast, toaster, history, pathname, search]);

  return (
    <div>
      <Modal
        title="Confirm Undo"
        isOpen={state === 'confirm-undo'}
        onClose={e => {
          e?.stopPropagation();
          setState('idle');
        }}
      >
        <ResponsivePadding desktop="1rem" mobile="1rem">
          <p>Are you sure you want to undo this import?</p>
          <ul>
            <li>
              <span>
                All{' '}
                {!!record?.summary?.imported_count &&
                  `(${record?.summary?.imported_count}) `}
                imported records will be deleted
              </span>
            </li>
            <li>
              <span>Uploaded file and matched columns will not be deleted</span>
            </li>
          </ul>
          <Group justify="space-between">
            <Button
              motif="tertiary"
              variant="outline"
              size="small"
              onClick={e => {
                setState('idle');
                e.stopPropagation();
              }}
            >
              Cancel
            </Button>
            <Button
              motif="tertiary"
              size="small"
              onClick={e => {
                handleUndo();
                e.stopPropagation();
              }}
            >
              Confirm Undo
            </Button>
          </Group>
        </ResponsivePadding>
      </Modal>
      <Modal
        title="Confirm Remove"
        isOpen={state === 'confirm-delete'}
        onClose={e => {
          e?.stopPropagation();
          setState('idle');
        }}
      >
        <ResponsivePadding desktop="1rem" mobile="1rem">
          <p>Are you sure you want to remove this incomplete import?</p>
          <ul>
            <li>
              <span>Uploaded file and matched columns will be deleted</span>
            </li>
          </ul>
          <Group justify="space-between">
            <Button
              motif="tertiary"
              variant="outline"
              size="small"
              onClick={e => {
                setState('idle');
                e.stopPropagation();
              }}
            >
              Cancel
            </Button>
            <Button
              motif="tertiary"
              size="small"
              onClick={e => {
                handleDelete();
                e.stopPropagation();
              }}
            >
              Confirm Remove
            </Button>
          </Group>
        </ResponsivePadding>
      </Modal>
      <UncontrolledDropdown>
        <DropdownToggle
          className="btn-icon-only"
          role="button"
          size="sm"
          color=""
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <i className="fas fa-ellipsis-v" />
        </DropdownToggle>
        <DropdownMenu
          positionFixed
          className="dropdown-menu-arrow"
          data-disabled={record.state !== 'COMPLETE'}
          right
        >
          <StateEnabledDropdownItem
            id={`import-${record.id}-menu-item-export`}
            enabledForState="COMPLETE"
            state={record.state}
            onClick={handleDownloadCsvReport}
          >
            <a
              ref={aRef}
              style={{ display: 'none' }}
              rel="noopener noreferrer"
              href={`data:attachment/csv;charset=utf-8,%EF%BB%BF${csv}`}
              onClick={e => e.stopPropagation()}
              download={`import-${importId}.csv`}
              aria-hidden={true}
            >
              {/* whitespace is needed to make the linter happy lol */}{' '}
            </a>
            <i className="fas fa-download" />
            <span>Download CSV</span>
          </StateEnabledDropdownItem>
          <StateEnabledDropdownItem
            id={`import-${record.id}-menu-item-undo`}
            enabledForState="COMPLETE"
            state={record.state}
            onClick={() => setState('confirm-undo')}
          >
            <i className="fas fa-undo" />
            <span>Undo Import</span>
          </StateEnabledDropdownItem>
          <StateEnabledDropdownItem
            id={`import-${record.id}-menu-item-remove`}
            enabledForState="UPLOAD_COMPLETE"
            state={record.state}
            onClick={() => setState('confirm-delete')}
          >
            <i className="fas fa-times-circle" />
            <span>Remove</span>
          </StateEnabledDropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};
