import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/argon-dashboard-react.css';
import './assets/scss/index.scss';
import './assets/scss/argon-dashboard-react.scss';

import { Main } from './components/main/Main';

ReactDOM.render(<Main />, document.getElementById('root'));
