import React, { FunctionComponent, useContext } from 'react';
import { PermissionsStore } from '../../context';
import { Copy } from './DevTools';

export const UserBrands: FunctionComponent = () => {
  const {
    state: { brands: userBrands }
  } = useContext(PermissionsStore);

  return (
    <>
      <h2>User Brands</h2>
      <table className="table table-sm table-dark">
        <thead className="thead-light">
          <tr>
            <th style={{ width: 300 }}>Id</th>
            <th style={{ width: 200 }}>Name/Id2</th>
            <th>Display Name</th>
          </tr>
        </thead>
        <tbody>
          {userBrands.map(brand => (
            <tr key={brand.id}>
              <td>
                <Copy val={brand.id} label={brand.id} />
              </td>
              <td>
                <Copy val={brand.name} label={brand.name} />
              </td>
              <td className="align-middle">{brand.displayName}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
