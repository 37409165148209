import React from 'react';
import { SmallSecondaryButton } from '@fattmerchantorg/truffle-components';

interface AddCorisButtonProps {
  handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  ButtonStyle: React.FunctionComponent;
}

export const OpenCorisPortalButton: React.FunctionComponent<
  AddCorisButtonProps
> = props => {
  const { handleClick, ButtonStyle } = props;
  return (
    <ButtonStyle>
      <SmallSecondaryButton type="button" onClick={handleClick}>
        Open Coris Portal
      </SmallSecondaryButton>
    </ButtonStyle>
  );
};
