import styled, { withTheme } from 'styled-components';
import { Button } from '..';

export const PageHeaderButton = withTheme(
  styled(Button)`
    background-color: ${({ theme }) => theme.white};
    color: ${({ theme }) => theme.black};
    border-radius: 2px;
    height: 46px;
    width: 150px;
  `
);
