import { CombinedData } from './drawer-configs';
import {
  AdjustmentProcessingDetailsSectionData,
  AdjustmentSectionData,
  ProcessingDetailsSectionData,
  RequiredKeys,
} from './AdvancedDetailDrawer.types';
import { transactionsapi, catanapi, coreapi } from '../../../api';
import { Fees, TransactionData } from './AdvancedDetailDrawer.types';
import { mapAdjustmentTransactionSubType } from '../../../util/transaction.util';
import { AdjustmentTransactionSubtype } from '@fattmerchantorg/types-engine';
import { AdjustmentTransaction } from '../../transactions/AdjustmentTransactions/AdjustmentTransactions.types';

const processingFees = [
  'INTERCHANGE',
  'BASIS_POINTS',
  'FLAT_FEE_AMOUNT',
  'SURCHARGE',
  'DUES_ASSESSMENTS',
  'CUSTOM',
  'INQUIRY',
];

export function mapFeeName(type: string) {
  const feeNameMap: Record<string, string> = {
    BASIS_POINTS: 'Rate Fee',
    ACHRETURN: 'ACH Return',
    INTERCHANGE: 'Interchange',
    FLAT_FEE_AMOUNT: 'Per Transaction Fee',
    SURCHARGE: 'Surcharge',
    INQUIRY: 'Inquiry',
    DUES_ASSESSMENTS: 'Dues & Assessments',
    CARD_FIXED: 'Card Processing Fees',
    CUSTOM: 'Custom',
    DISPUTE: 'Dispute',
    INSIGHTS: 'Stax Insights Fee',
    PAF: 'Performance Analytics Fee',
    PCI: 'PCI Non Compliance Fee',
    SAAS: 'Subscription Fee',
    PAYPAL: 'PayPal',
    VENMO: 'Venmo',
    MISC: 'Miscellaneous Fee',
    CNP: 'CNP',
    BLIND_CREDIT: 'Blind Credit',
    ACH_ADJUSTMENT: 'ACH Adjustment',
    DISPUTE_ADJUSTMENT: 'Dispute Adjustment',
    FEE_ADJUSTMENT: 'Fee Adjustment',
    OTHER_ADJUSTMENT: 'Other Adjustment',
    EQUIPMENT_ADJUSTMENT: 'Equipment Adjustment',
    OTHER: 'Other',
  };
  return feeNameMap[type.toUpperCase()];
}

export const getFeesTotal = transactionFees => {
  try {
    return {
      totalProcessingFees: transactionFees
        .reduce((acc, fee) => {
          if (processingFees.includes(fee.subtype)) {
            return acc + +fee.amount;
          }
          return acc;
        }, 0)
        .toFixed(2),
      totalAdditionalFees: transactionFees
        .reduce((acc, fee) => {
          if (!processingFees.includes(fee.subtype)) {
            return acc + +fee.amount;
          }
          return acc;
        }, 0)
        .toFixed(2),
    };
  } catch (error) {
    return null;
  }
};

function getFees(transactionFees) {
  const processing_fees: Fees[] = [];
  const additional_fees: Fees[] = [];
  transactionFees.forEach(fee => {
    const transactionFees = {
      id: fee.transaction_id,
      type: fee.type,
      subtype: fee.subtype,
      name: fee?.fee_description || mapFeeName(fee.subtype),
      total: fee.amount,
      settled_at: fee.created_at,
      transaction_id: fee.parent_transaction_id,
      settlement_id: fee?.settlement_id?.substring(2),
    };
    if (
      processingFees.includes(fee.subtype) &&
      fee.subtype !== 'DISPUTE' // DISPUTE fees should display in 'additional fees'
    ) {
      processing_fees.push(transactionFees);
    } else {
      additional_fees.push(transactionFees);
    }
  });
  return {
    processingFees: processing_fees,
    additionalFees: additional_fees,
  };
}

const getFeeData = async (token: string, transactionId: string) => {
  if (!transactionId) return null;
  try {
    const data = await transactionsapi.get(
      token,
      `/transactions/${transactionId}/fees`
    );
    return data;
  } catch (error) {
    return null;
  }
};

const getFeeDataFromSettlement = async (
  token: string,
  transactionId: string
) => {
  if (!transactionId) return {};
  try {
    const transactionFees = await catanapi.get(
      token,
      `/settlements/associated-transactions/${transactionId}`
    ); // Fetch fees from engine.transactions instead of processor.settlement_fees
    const filteredFees = transactionFees.data.length
      ? transactionFees.data.filter(row => row.type === 'FEE')
      : [];
    const feesTotal = getFeesTotal(filteredFees);
    const fees = getFees(filteredFees);
    return {
      total_processing_fees: feesTotal.totalProcessingFees,
      total_additional_fees: feesTotal.totalAdditionalFees,
      processing_fees: fees.processingFees,
      additional_fees: fees.additionalFees,
    };
  } catch (error) {
    return {};
  }
};

export function setProcessingDetails(
  transaction: Partial<TransactionData>,
  processingFees: number,
  isSettlement?: boolean,
  transactionToSettlement?: Partial<TransactionData>,
  surcharge?: number
): ProcessingDetailsSectionData {
  const transactionId =
    transaction?.id_to_link || //transaction from fatt db
    transaction?.id || //transaction from processor db
    transaction?.transaction_id; // transaction from engine db
  const charge = transaction?.total || transaction?.amount || 0;
  const total = parseFloat((charge - (processingFees || 0)).toFixed(2));
  const data = {
    charge: charge,
    processingFees: processingFees,
    total: total,
    processor: transaction?.gateway?.name || transaction?.processor_name,
    transactionProfile: setTransactionProfile(transaction?.meta?.billing),
    transactionId:
      isSettlement && transactionToSettlement
        ? transactionToSettlement?.id
        : transactionId,
    source: transaction?.source,
    surcharge: transaction?.meta?.surcharge || surcharge || 0,
    surchargeDate: transaction?.created_at,
    merchant_id:
      isSettlement && transactionToSettlement
        ? transactionToSettlement?.merchant_id
        : transaction?.merchant_id,
    settled_at: transaction?.settled_at,
    is_settlement: isSettlement,
    batch_id: transaction?.batch_id,
    type: transaction?.type,
    parent_transaction_id:
      isSettlement && transactionToSettlement
        ? transactionToSettlement?.parent_transaction?.id
        : transaction?.reference_id,
  };
  return data;
}

export function setTransactionProfile(billing): string {
  // This function should be setting the transaction profile based on the
  // transaction data passed in.
  if (!billing) return '';
  const transactionAmount =
    billing?.per_transaction_amount > 0
      ? ` $${billing.per_transaction_amount}`
      : '';
  const transactionPercent = billing?.fee_percent
    ? (billing?.fee_percent * 100).toFixed(2)
    : '';
  const concatTransactionProfile =
    transactionPercent && transactionAmount ? ' + ' : '';
  const showPercent = transactionPercent !== '' ? '%' : '';
  return `${transactionPercent}${showPercent}${concatTransactionProfile}${transactionAmount}`;
}

const setInvoiceDetailsSectionData = async (
  transaction: Partial<TransactionData>
) => {
  return {
    lineItems: transaction?.meta?.lineItems,
    subtotal: transaction?.meta?.subtotal,
    total: transaction?.total,
    surcharge: transaction?.meta?.surcharge || 0,
    tax: transaction?.meta?.tax,
    shippingAmount: transaction?.meta?.shippingAmount,
    tip: transaction?.meta?.tip || 0,
    isTipEnabled: transaction?.meta?.isTipEnabled,
  };
};

export async function getDataForTransactionDrawer(
  transaction: Partial<TransactionData>,
  token: string,
  isSettlement?: boolean,
  configType?: string
): Promise<
  RequiredKeys<
    Pick<
      CombinedData,
      // These are the sections we need to return data for.
      | 'ProcessingDetailsSectionData'
      | 'CustomerDetailsSectionData'
      | 'InvoiceDetailsSectionData'
      | 'ProcessingFeesSectionData'
      | 'AdditionalFeesSectionData'
      | 'TransactionHistorySectionData'
      | 'DisputesSectionData'
      | 'AdjustmentSectionData'
      | 'AdjustmentProcessingDetailsSectionData'
    >
  >
> {
  const feesData = isSettlement
    ? await getFeeDataFromSettlement(
        token,
        configType === 'Fee' || configType === 'DisputeTransaction'
          ? transaction?.parent_transaction_id
          : transaction?.transaction_id
      )
    : (await getFeeData(token, transaction?.id)) || {};
  feesData?.processing_fees?.map(fee => {
    if (fee.name === 'Basis Points') {
      fee.name = 'Rate Fee';
    }
    if (fee.name === 'Flat Rate Fees') {
      fee.name = 'Per Transaction Fee';
    }
  });
  if (transaction?.meta?.surcharge) {
    const miscellaneousFee = feesData.processing_fees.find(
      fee => fee.name === 'Application Fee' || fee.name === 'Surcharge Fee' || fee.name === 'Surcharge'
    );
    if (miscellaneousFee?.total === transaction?.meta?.surcharge) {
      miscellaneousFee.name =
        miscellaneousFee.name === 'Application Fee'
          ? 'Surcharge'
          : 'Surcharge Fee';
    } else {
      feesData.processing_fees.push({
        id: 'surcharge',
        name: 'Surcharge',
        settled_at: transaction?.created_at,
        total: transaction?.meta?.surcharge,
        settlement_id: transaction?.batch_id,
        transaction_id: transaction?.id,
      });
      feesData.total_processing_fees =
        Number(feesData.total_processing_fees) + transaction?.meta?.surcharge;
    }
  }
  if (transaction?.total_refunded > 0) {
    feesData.transaction_type = 'refund';
  } else {
    feesData.transaction_type = transaction?.type;
  }

  const feesSectionData = {
    processingFees: {
      total_fees: feesData?.total_processing_fees,
      fees: feesData?.processing_fees,
      transaction_type: feesData?.transaction_type,
      isSettlement: isSettlement,
    },
    additionalFees: {
      total_fees: feesData?.total_additional_fees,
      fees: feesData?.additional_fees,
      transaction_type: feesData?.transaction_type,
      isSettlement: isSettlement,
    },
  };

  const getTransactionToSettlement = async (
    token: string,
    transaction: Partial<TransactionData>,
    isSettlement: boolean
  ): Promise<Partial<TransactionData>> => {
    let transactionToSettlement;
    if (!transaction) return null;
    if (isSettlement) {
      try {
        let transactionId =
          transaction?.type === 'FEE' ||
          transaction?.type === 'DISPUTE' ||
          transaction?.type === 'HOLD'
            ? transaction?.parent_transaction_id
            : transaction?.transaction_id;
        transactionToSettlement =
          (await transactionsapi.get(
            token,
            `/transaction/${transactionId?.substring(2)}/settled-transaction`
          )) ||
          (await transactionsapi.get(
            token,
            `/transactions/${transactionId?.substring(2)}`
          )) ||
          {};
      } catch (error) {
        console.error(error);
      }
    }
    return transactionToSettlement;
  };

  const transactionToSettlement = await getTransactionToSettlement(
    token,
    transaction,
    isSettlement
  );

  const setCustomerDetailsSectionData = async (
    transaction: Partial<TransactionData>,
    isSettlement: boolean
  ) => {
    let fattTransaction;
    if (isSettlement && transaction?.dispute_id) {
      const parentTransactionFromSettlement = await transactionsapi.get(
        token,
        `/transaction/${transaction?.parent_transaction_id?.substring(
          2
        )}/settled-transaction`
      );
      fattTransaction = parentTransactionFromSettlement;
    } else if (isSettlement) {
      fattTransaction = transactionToSettlement;
    } else {
      fattTransaction = transaction;
    }
    return {
      ...fattTransaction?.customer,
      firstName:
        fattTransaction?.customer?.firstname ||
        fattTransaction?.first_name ||
        '',
      lastName:
        fattTransaction?.customer?.lastname || fattTransaction?.last_name || '',
      company: fattTransaction?.customer?.company || '',
      email: fattTransaction?.customer?.email || fattTransaction?.email || '',
      phone: fattTransaction?.customer?.phone || fattTransaction?.phone || '',
      address1:
        fattTransaction?.customer?.address_1 ||
        fattTransaction?.address_1 ||
        '',
      address2: fattTransaction?.customer?.address_2 || '',
      addressCity:
        fattTransaction?.customer?.address_city ||
        fattTransaction?.address_city ||
        '',
      addressState:
        fattTransaction?.customer?.address_state ||
        fattTransaction?.address_state ||
        '',
      addressZip: fattTransaction?.customer?.address_zip,
      source: fattTransaction?.source || fattTransaction?.processor_name || '',
      payment_method: {
        method:
          fattTransaction?.payment_method?.method || fattTransaction?.method,
        bin_type: fattTransaction?.payment_method?.bin_type,
        lastFour:
          fattTransaction?.payment_method?.card_last_four ||
          fattTransaction?.last_four ||
          '',
        expiration:
          fattTransaction?.payment_method?.card_exp ||
          fattTransaction?.expiration ||
          '',
        card_type:
          fattTransaction?.payment_method?.card_type ||
          fattTransaction?.card_brand ||
          '',
      },
    };
  };

  const setProcessingDetailsSectionData = async (
    transaction: Partial<TransactionData>,
    processingFees: number,
    isSettlement: boolean
  ) => {
    if (!transaction) return null;
    if (isSettlement) {
      if (configType === 'Refund' && !transaction?.parent_transaction_id) {
        const chargeTransaction =
          transactionToSettlement?.parent_transaction as Partial<TransactionData>;
        return setProcessingDetails(
          chargeTransaction,
          processingFees,
          isSettlement
        );
      } else {
        const transactionId =
          configType === 'Fee' ||
          configType === 'Refund' ||
          configType === 'DisputeTransaction'
            ? transaction?.parent_transaction_id
            : transaction?.transaction_id;
        try {
          const settlementFeeChargeTransaction = await catanapi.get(
            token,
            `/settlement-transaction/${transactionId}`
          );

          return setProcessingDetails(
            settlementFeeChargeTransaction,
            processingFees,
            isSettlement,
            transactionToSettlement
          );
        } catch (error) {
          return null;
        }
      }
    } else {
      if (transaction?.database === 'fatt') {
        try {
          const transactionData = await transactionsapi.get(
            token,
            `/settled-transaction/${transaction?.id}/transaction`
          );
          const surcharge = transaction?.meta?.surcharge || 0;
          if (transactionData) {
            transaction.meta.billing = transactionData.meta?.billing;
            transaction.id_to_link = transactionData.id;
            return setProcessingDetails(
              transactionData,
              processingFees,
              isSettlement,
              {},
              surcharge
            );
          } else {
            return setProcessingDetails(
              transaction,
              processingFees,
              isSettlement
            );
          }
        } catch (error) {
          return null;
        }
      } else {
        return setProcessingDetails(transaction, processingFees, isSettlement);
      }
    }
  };

  const setDisputesSectionData = async (
    transaction: Partial<TransactionData>,
    isSettlement?: boolean
  ) => {
    if (!transaction) return null;
    let fattTransaction;
    if (isSettlement) {
      const parentTransactionId = transaction?.parent_transaction_id;
      try {
        // Grab the original fatt transaction which contains the dispute data
        const fattChargeData = await transactionsapi.get(
          token,
          `/transaction/${parentTransactionId.substring(2)}/settled-transaction`
        );
        if (fattChargeData) fattTransaction = fattChargeData;
      } catch (error) {}
    } else {
      fattTransaction = transaction;
    }
    const now = new Date().toString();
    const hasDeadlinePassed =
      Date.parse(fattTransaction?.dispute?.respond_by) - Date.parse(now) < 0;
    return {
      ...fattTransaction?.dispute,
      disputeId: fattTransaction?.dispute?.id,
      merchantId: fattTransaction?.merchant_id,
      createdAt: fattTransaction?.dispute?.created_at,
      respondBy: fattTransaction?.dispute?.respond_by,
      hasDeadlinePassed: hasDeadlinePassed,
    };
  };

  const setAdjustmentSectionData = async (
    transaction: AdjustmentTransaction
  ): Promise<AdjustmentSectionData> => {
    const meta = transaction?.meta ?? {};
    let createdBy = meta.userId?.toString();
    if (meta.userId) {
      try {
        const user = await coreapi.get(token, `/user/${meta.userId}`);
        if (user?.name) {
          createdBy = user.name;
        }
      } catch (error) {}
    }

    return {
      transactionId: transaction?.transaction_id,
      category: mapAdjustmentTransactionSubType(
        transaction?.subtype as AdjustmentTransactionSubtype
      )?.replace('Adjustment', ''),
      description: meta.description?.toString(),
      parentTransaction: transaction?.parent_transaction_id,
      settlementId: transaction?.settlement_id,
      createdBy: createdBy,
    };
  };

  const setAdjustmentProcessingDetailsSectionData = async (
    transaction: AdjustmentTransaction
  ): Promise<AdjustmentProcessingDetailsSectionData> => {
    return {
      transactionId: transaction?.transaction_id,
      adjustment: transaction?.amount,
      total: transaction?.amount,
      processor: transaction?.processor_name,
    };
  };

  let transactionHistory;
  if (transaction?.type !== 'FEE') {
    if (isSettlement && transaction) {
      if (!transaction?.parent_transaction_id) {
        try {
          if (transactionToSettlement) {
            const fattTransaction = transactionToSettlement;
            transactionHistory = [fattTransaction];
            if (fattTransaction.child_transactions?.length > 0) {
              transactionHistory.push(...fattTransaction?.child_transactions);
            }
            if (fattTransaction.type === 'refund') {
              const fattCharge = fattTransaction.parent_transaction;
              if (fattCharge) transactionHistory.push(fattCharge);
            }
          } else {
            const engineTransaction = await catanapi.get(
              token,
              `/settlement-transaction/${transaction?.transaction_id}`
            );
            transactionHistory = engineTransaction ? [engineTransaction] : [];
          }
        } catch (error) {}
      } else {
        try {
          const parentTransaction = await catanapi.get(
            token,
            `/settlement-transaction/${transaction?.parent_transaction_id}`
          );
          const associatedTransactions = await catanapi.get(
            token,
            `/settlements/associated-transactions/${transaction?.parent_transaction_id}`
          );
          associatedTransactions.data.push(parentTransaction);
          transactionHistory = associatedTransactions.data;
        } catch (error) {}
      }
    } else {
      transactionHistory = transaction ? [transaction] : [];
      if (transaction?.child_transactions?.length > 0) {
        transactionHistory.push(...transaction?.child_transactions);
      }
      if (transaction?.parent_transaction?.id) {
        transactionHistory.push(transaction?.parent_transaction);
      }
    }
  }
  // TODO: add a block for querying for dispute details from settlements - query using the same 'id' used to link to the payments drawer.
  return {
    ProcessingDetailsSectionData: await setProcessingDetailsSectionData(
      transaction,
      feesSectionData.processingFees.total_fees || 0,
      isSettlement
    ),
    ProcessingFeesSectionData: feesSectionData.processingFees,
    AdditionalFeesSectionData: feesSectionData.additionalFees,
    CustomerDetailsSectionData: await setCustomerDetailsSectionData(
      transaction,
      isSettlement
    ),
    InvoiceDetailsSectionData: await setInvoiceDetailsSectionData(
      isSettlement ? transactionToSettlement : transaction
    ),
    TransactionHistorySectionData: {
      history: transactionHistory?.length ? transactionHistory : [transaction],
    },
    DisputesSectionData: await setDisputesSectionData(
      transaction,
      isSettlement
    ),
    AdjustmentSectionData: await setAdjustmentSectionData(
      transaction as unknown as AdjustmentTransaction
    ),
    AdjustmentProcessingDetailsSectionData:
      await setAdjustmentProcessingDetailsSectionData(
        transaction as unknown as AdjustmentTransaction
      ),
  };
}
