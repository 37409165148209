import React from 'react';
import { ChartTypes } from '../../../../@types';
import { NoDataOverlay } from './NoDataOverlay';
import { LoadingAnimation } from './LoadingAnimation';

/**
 * This helper renders any elements that could be displayed before
 * displaying the chart like a loading animation and a "no data" message.
 * @param dataStatus Status of the data request.
 * @param hasData Does the data have data or not.
 * @param type Chart type.
 */
export function ChartPreRender(
  dataStatus: string,
  hasData: boolean,
  type: ChartTypes,
  useInlineLayout: boolean = false
): JSX.Element {
  if (dataStatus === 'LOADING' || dataStatus === 'INITIAL') {
    // If the request is loading, render the loader.
    return <LoadingAnimation />;
  } else if (!hasData) {
    // If the dataset is empty, display the no data message.
    return (
      <NoDataOverlay
        type={type}
        useInlineLayout={useInlineLayout}
      ></NoDataOverlay>
    );
  }

  // Good to go. Dataset is not empty and no longer loading.
  return null;
}
