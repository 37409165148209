import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { Icon } from '@fattmerchantorg/truffle-components';

const Trigger = styled.button`
  display: flex;
  width: 100%;
  padding: 8px 16px;
  border-radius: 2px;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.lightNeutralOutline};
  outline: none;
  background: ${({ theme }) => theme.colors.core.gray[800].hex};
  color: ${({ theme }) => theme.white};
`;

const TriggerPrefix = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  color: ${({ theme }) => theme.colors.status.blue[500].hex};
`;

const TriggerContent = styled.div`
  margin-right: 16px;
`;

const TriggerSuffix = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const Content = styled.div`
  padding: 16px;
  display: ${props => (props.expanded ? 'block' : 'none')};
  border: 1px solid ${({ theme }) => theme.lightNeutralOutline};
  border-top: 0;
`;

interface ExpandablePanelProps {
  expanded?: boolean;
  triggerContent: ReactNode;
  triggerSuffix?: ReactNode;
  className?: string;
}

export const ExpandablePanel: React.FunctionComponent<
  ExpandablePanelProps
> = props => {
  const { children, expanded, triggerContent, triggerSuffix, className } =
    props;
  const [isExpanded, setIsExpanded] = useState(expanded || false);

  return (
    <div className={className || null}>
      <Trigger onClick={() => setIsExpanded(!isExpanded)}>
        <TriggerPrefix>
          {isExpanded ? (
            <Icon icon={['fas', 'chevron-up']} />
          ) : (
            <Icon icon={['fas', 'chevron-down']} />
          )}
        </TriggerPrefix>
        <TriggerContent>{triggerContent}</TriggerContent>
        {triggerSuffix ? <TriggerSuffix>{triggerSuffix}</TriggerSuffix> : null}
      </Trigger>
      <Content expanded={isExpanded}>{children}</Content>
    </div>
  );
};
