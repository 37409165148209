import React, { FC } from 'react';
import styled, { withTheme } from 'styled-components';
import { UncontrolledTooltip } from 'reactstrap';
import copy from 'copy-to-clipboard';

import { CreateNewAPIKeyModal } from './CreateNewAPIKeyModal';
import {
  DeleteAPIKeyModal,
  DeleteModalContext,
  initialDeleteModalState,
} from './DeleteAPIKeyModal';
import { Button, CopyButton, LoadingSpan } from '../../shared';
// Avoiding compilation error by importing Widget directly instead of from the /shared folder
// See this GH issue thread for more info (https://github.com/styled-components/styled-components/issues/1449#issuecomment-420087359)
import { Widget } from '../../shared/Widget/Widget';
import { APIKey } from '@fattmerchantorg/types-omni';
import { AsyncData, AsyncDataStatus } from '../../../@types';
import {
  useModalReducer,
  sendOpenSelected,
} from '../../../hooks/useModalReducer';
import { usePermissions } from '../../../hooks';

const Container = withTheme(
  styled(Widget)`
    background: ${({ theme }) => theme.colors.core.green[800].hex};
    color: white;
    padding: 1rem;

    h2 {
      color: white;
    }

    > div + div {
      margin-top: 1rem;
    }

    button {
      color: white;
      padding: 0;
    }
  `
);

const WidgetHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const KVPairsDiv = withTheme(
  styled.div`
    i {
      padding-right: 0.25rem;
    }

    > div.kv-row {
      display: flex;
      border-bottom: 1px solid ${({ theme }) => theme.black};
      justify-content: space-between;

      > span {
        white-space: nowrap;
        margin-right: 1.5rem;
      }

      > span.key {
        width: 5rem;
      }

      > span.value {
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 12.5rem;
        flex-grow: 1;
      }

      > button,
      a {
        padding: 0;
        color: white;
      }
    }
  `
);

const LoadingRow = withTheme(
  styled.div`
    display: flex;
    border-bottom: 1px solid ${({ theme }) => theme.black};

    /* loading spans */
    > div + div {
      margin-left: 3rem;
    }
  `
);

const APIKeyKVPairs: FC<{
  apiKey: APIKey;
  onDeleteButtonClick: (apiKey: APIKey) => void;
  hasAPIKeyWritePerm: boolean;
}> = ({ apiKey, onDeleteButtonClick, hasAPIKeyWritePerm }) => {
  const { name, id, api_key: jwt } = apiKey;
  const copyToken = `copy-api-key-token-${id}`;
  const deleteId = `delete-api-key-${id}`;

  return (
    <KVPairsDiv data-testid={`api-key-${id}`}>
      <div className="kv-row">
        <span className="value">{name}</span>
      </div>
      <div className="kv-row">
        <span className="value">{jwt}</span>
        <CopyButton id={copyToken} data-testid={copyToken} content={jwt} />
        {hasAPIKeyWritePerm && (
          <Button
            id={deleteId}
            data-testid={deleteId}
            variant="link"
            onClick={() => onDeleteButtonClick(apiKey)}
          >
            <i className="fas fa-trash" />
            <UncontrolledTooltip
              target={deleteId}
              delay={{ show: 750, hide: 0 }}
            >
              This will revoke Omni access for any applications that use this
              key
            </UncontrolledTooltip>
          </Button>
        )}
      </div>
    </KVPairsDiv>
  );
};

export const APIKeysWidget: FC<{
  apiKeys: AsyncData<APIKey[]>;
  websitePaymentsToken: string;
}> = ({ apiKeys, websitePaymentsToken }) => {
  const { data, status } = apiKeys;

  const { permit } = usePermissions();
  const hasAPIKeyWritePerm = permit('godview', 'webPayments', 'write');

  const [createModalState, createModalDispatch] = useModalReducer();

  const [deleteModalState, deleteModalDispatch] =
    useModalReducer<DeleteModalContext>(initialDeleteModalState);

  // Render different UI according to current status of async data
  switch (status) {
    case AsyncDataStatus.INITIAL:
    case AsyncDataStatus.LOADING: {
      return (
        <Container>
          <LoadingRow>
            <LoadingSpan width="7rem" />
            <LoadingSpan width="7rem" />
          </LoadingRow>
          <LoadingRow>
            <LoadingSpan width="7rem" />
            <LoadingSpan width="7rem" />
          </LoadingRow>
          <LoadingRow>
            <LoadingSpan width="7rem" />
            <LoadingSpan width="7rem" />
          </LoadingRow>
          <LoadingRow>
            <LoadingSpan width="7rem" />
            <LoadingSpan width="7rem" />
          </LoadingRow>
          <LoadingRow>
            <LoadingSpan width="7rem" />
            <LoadingSpan width="7rem" />
          </LoadingRow>
          <LoadingRow>
            <LoadingSpan width="7rem" />
            <LoadingSpan width="7rem" />
          </LoadingRow>
        </Container>
      );
    }
    case AsyncDataStatus.IDLE: {
      // Use plural when user has web payments token and at least 1 api key
      const headerText = data?.length > 0 ? 'Keys' : 'Key';

      return (
        <Container>
          <CreateNewAPIKeyModal
            status={createModalState.status}
            modalDispatch={createModalDispatch}
          />
          <DeleteAPIKeyModal
            status={deleteModalState.status}
            apiKey={deleteModalState.context?.apiKey}
            modalDispatch={deleteModalDispatch}
          />
          <WidgetHeader>
            <h2>
              <i className="fas fa-key" /> {headerText}
            </h2>
            {hasAPIKeyWritePerm && (
              <Button
                variant="link"
                id="api-key-widget-plus-sign-button"
                data-testid="api-key-widget-plus-sign-button"
                onClick={() => createModalDispatch(sendOpenSelected())}
              >
                <i className="fas fa-plus" />
              </Button>
            )}
          </WidgetHeader>

          <KVPairsDiv data-testid="website-payments-token">
            <div className="kv-row">
              <span>Public Key (Website Payments Token)</span>
              <a
                id="website-payments-token-info"
                target="_blank"
                href="https://fattmerchant.docs.apiary.io/#introduction/fattmerchant.js"
                rel="noopener noreferrer"
              >
                <i className="fas fa-info-circle"></i>
                <UncontrolledTooltip
                  delay={0}
                  target="website-payments-token-info"
                >
                  View documentation for fattmerchant.js and the website
                  payments token
                </UncontrolledTooltip>
              </a>
            </div>
            <div className="kv-row">
              <span className="value">{websitePaymentsToken}</span>
              <Button
                id="copy-website-payments-token"
                data-testid="copy-website-payments-token"
                variant="link"
                onClick={e => {
                  copy(websitePaymentsToken);
                }}
              >
                <i className="far fa-copy" />
                <UncontrolledTooltip
                  delay={0}
                  target="copy-website-payments-token"
                >
                  Copy to clipboard
                </UncontrolledTooltip>
              </Button>
            </div>
          </KVPairsDiv>

          {data.map(apiKey => (
            <APIKeyKVPairs
              key={apiKey.id}
              apiKey={apiKey}
              onDeleteButtonClick={apiKey => {
                deleteModalDispatch(sendOpenSelected({ apiKey }));
              }}
              hasAPIKeyWritePerm={hasAPIKeyWritePerm}
            />
          ))}
        </Container>
      );
    }
    case AsyncDataStatus.ERROR:
      return (
        <Container>
          <p>
            An error occurred while retrieving api key data. Please reach out to
            your Partner Account Manager.
          </p>
        </Container>
      );
  }
};
