import { Gateway } from '@fattmerchantorg/types-omni';

export function getMerchantMids(gateways: Gateway[]): string[] {
  return gateways
    ?.filter(
      g =>
        g.type === 'SETTLEMENTS' &&
        g.name === 'engine' &&
        g.processor_mid !== null &&
        g.processor_mid !== ''
    )
    ?.map(g => g.processor_mid);
}
