import { Registration, PricingPlan } from '@fattmerchantorg/types-omni';
import { pick } from '../../../../util/object.util';
import {
  ProcessingEnvironmentFieldsErrors,
  ProcessingEnvironmentFieldsValues,
} from './ProcessingEnvironment.types';
import {
  formatCurrency,
  formatPercent,
  leaveAsNull,
  toDecimalString,
} from '../../../../util';

export function mapFormValuesToPayload(
  formValues: ProcessingEnvironmentFieldsValues
): Partial<Registration> {
  return {
    chosen_processing_method: formValues.chosen_processing_method,
    pricing_plan: formValues.pricing_plan,
    plan_txamnt: leaveAsNull(toDecimalString)(formValues.plan_txamnt),
    cp_per_item_rate: leaveAsNull(toDecimalString)(formValues.cp_per_item_rate),
    amex_mid_trans_fee: leaveAsNull(toDecimalString)(
      formValues.amex_mid_trans_fee
    ),
    cp_amex_per_item_rate: leaveAsNull(toDecimalString)(
      formValues.cp_amex_per_item_rate
    ),
    cnp_debit_trans_fee: leaveAsNull(toDecimalString)(
      formValues.cnp_debit_trans_fee
    ),
    cp_debit_trans_fee: leaveAsNull(toDecimalString)(
      formValues.cp_debit_trans_fee
    ),
    plan_ach_txamnt: leaveAsNull(toDecimalString)(formValues.plan_ach_txamnt),
    plan_dcamnt: leaveAsNull(toDecimalString)(formValues.plan_dcamnt),
    cp_transaction_rate: leaveAsNull(toDecimalString)(
      formValues.cp_transaction_rate
    ),
    amex_mid_disc_rate: leaveAsNull(toDecimalString)(
      formValues.amex_mid_disc_rate
    ),
    cp_amex_rate: leaveAsNull(toDecimalString)(formValues.cp_amex_rate),
    cp_debit_discount_rate: leaveAsNull(toDecimalString)(
      formValues.cp_debit_discount_rate
    ),
    cnp_debit_discount_rate: leaveAsNull(toDecimalString)(
      formValues.cnp_debit_discount_rate
    ),
    plan_ach_dcamnt: leaveAsNull(toDecimalString)(formValues.plan_ach_dcamnt),
    plan_ach_cap: leaveAsNull(toDecimalString)(formValues.plan_ach_cap),
    cp_credit_surcharge_rate: leaveAsNull(toDecimalString)(
      formValues.cp_credit_surcharge_rate
    ),
    cnp_credit_surcharge_rate: leaveAsNull(toDecimalString)(
      formValues.cnp_credit_surcharge_rate
    ),
    is_flat_rate: formValues.is_flat_rate,
    mcc: leaveAsNull(toDecimalString)(formValues.mcc),
    proc_change: formValues.proc_change,
    bus_type: leaveAsNull(toDecimalString)(formValues.bus_type),
    network: formValues.network,
    monthly_minimum_fee: leaveAsNull(toDecimalString)(
      formValues.monthly_minimum_fee
    ),
    monthly_service_fee: leaveAsNull(toDecimalString)(
      formValues.monthly_service_fee
    ),
  };
}

export function mapRegistrationToInitialFormValues(
  registration: Registration
): ProcessingEnvironmentFieldsValues {
  const data = pick(
    registration,
    'chosen_processing_method',
    'pricing_plan',
    'plan_txamnt',
    'cp_per_item_rate',
    'amex_mid_trans_fee',
    'cp_amex_per_item_rate',
    'cnp_debit_trans_fee',
    'cp_debit_trans_fee',
    'plan_ach_txamnt',
    'plan_dcamnt',
    'cp_transaction_rate',
    'amex_mid_disc_rate',
    'cp_amex_rate',
    'cp_debit_discount_rate',
    'cnp_debit_discount_rate',
    'plan_ach_dcamnt',
    'plan_ach_cap',
    'cp_credit_surcharge_rate',
    'cnp_credit_surcharge_rate',
    'is_flat_rate',
    'mcc',
    'proc_change',
    'bus_type',
    'network',
    'monthly_minimum_fee',
    'monthly_service_fee'
  ) as ProcessingEnvironmentFieldsValues;

  return {
    ...data,
    plan_txamnt: leaveAsNull(formatCurrency)(data.plan_txamnt),
    cp_per_item_rate: leaveAsNull(formatCurrency)(data.cp_per_item_rate),
    amex_mid_trans_fee: leaveAsNull(formatCurrency)(data.amex_mid_trans_fee),
    cp_amex_per_item_rate: leaveAsNull(formatCurrency)(
      data.cp_amex_per_item_rate
    ),
    cnp_debit_trans_fee: leaveAsNull(formatCurrency)(data.cnp_debit_trans_fee),
    cp_debit_trans_fee: leaveAsNull(formatCurrency)(data.cp_debit_trans_fee),
    plan_ach_txamnt: leaveAsNull(formatCurrency)(data.plan_ach_txamnt),
    plan_dcamnt: leaveAsNull(formatPercent)(data.plan_dcamnt),
    cp_transaction_rate: leaveAsNull(formatPercent)(data.cp_transaction_rate),
    amex_mid_disc_rate: leaveAsNull(formatPercent)(data.amex_mid_disc_rate),
    cp_amex_rate: leaveAsNull(formatPercent)(data.cp_amex_rate),
    cp_debit_discount_rate: leaveAsNull(formatPercent)(
      data.cp_debit_discount_rate
    ),
    cnp_debit_discount_rate: leaveAsNull(formatPercent)(
      data.cnp_debit_discount_rate
    ),
    plan_ach_dcamnt: leaveAsNull(formatPercent)(data.plan_ach_dcamnt),
    plan_ach_cap: leaveAsNull(formatCurrency)(data.plan_ach_cap),
    cp_credit_surcharge_rate: leaveAsNull(formatPercent)(
      data.cp_credit_surcharge_rate
    ),
    cnp_credit_surcharge_rate: leaveAsNull(formatPercent)(
      data.cnp_credit_surcharge_rate
    ),
    monthly_minimum_fee: leaveAsNull(formatCurrency)(data.monthly_minimum_fee),
    monthly_service_fee: leaveAsNull(formatCurrency)(data.monthly_service_fee),
  };
}

export function mapPricingPlanToRegistration(pricingPlan: PricingPlan) {
  const {
    perTransactionFee,
    discountFee,
    amexPerTransactionFee,
    amexDiscountFee,
    achPerTransactionFee,
    achDiscountFee,
    achCappedFee,
    type,
    cpPerTransactionFee,
    cpDiscountFee,
    cpAmexPerDiscountFee,
    cpAmexPerTransactionFee,
    cpCcSurchargeRate,
    cnpCcSurchargeRate,
    cpDebitPerTransactionFee,
    cnpDebitPerTransactionFee,
    cpDebitDiscountRate,
    cnpDebitDiscountRate,
  } = pricingPlan;

  return {
    // name of the pricing plan
    pricing_plan: pricingPlan.name,
    // $ per trans
    plan_txamnt: perTransactionFee,
    credit_mid_trans_fee: perTransactionFee,
    credit_non_trans_fee: perTransactionFee,
    credit_qual_trans_fee: perTransactionFee,
    cp_per_item_rate: cpPerTransactionFee,
    // amex $ per trans
    amex_qual_trans_fee: isNaN(+amexPerTransactionFee)
      ? perTransactionFee
      : amexPerTransactionFee,
    amex_mid_trans_fee: isNaN(+amexPerTransactionFee)
      ? perTransactionFee
      : amexPerTransactionFee,
    amex_non_trans_fee: isNaN(+amexPerTransactionFee)
      ? perTransactionFee
      : amexPerTransactionFee,
    cp_amex_per_item_rate: cpAmexPerTransactionFee,
    // ach $ per trans
    plan_ach_txamnt: achPerTransactionFee,
    // % per trans
    plan_dcamnt: discountFee,
    credit_qual_disc_rate: discountFee,
    credit_mid_disc_rate: discountFee,
    credit_non_disc_rate: discountFee,
    cp_transaction_rate: cpDiscountFee,
    // amex % per trans
    amex_qual_disc_rate: isNaN(+amexDiscountFee)
      ? discountFee
      : amexDiscountFee,
    amex_mid_disc_rate: isNaN(+amexDiscountFee) ? discountFee : amexDiscountFee,
    amex_non_disc_rate: isNaN(+amexDiscountFee) ? discountFee : amexDiscountFee,
    cp_amex_rate: cpAmexPerDiscountFee,
    // ach % per trans
    plan_ach_dcamnt: achDiscountFee,
    // ach capped fee
    plan_ach_cap: achCappedFee,
    // flat rate
    is_flat_rate: type === 'Flat Rate',
    // surcharge
    cp_credit_surcharge_rate: cpCcSurchargeRate,
    cnp_credit_surcharge_rate: cnpCcSurchargeRate,
    cp_debit_trans_fee: cpDebitPerTransactionFee,
    cnp_debit_trans_fee: cnpDebitPerTransactionFee,
    cp_debit_discount_rate: cpDebitDiscountRate,
    cnp_debit_discount_rate: cnpDebitDiscountRate,
    // interchange plus pricing plan
    monthly_minimum_fee: pricingPlan.name === 'ic-plus-bps' ? '25' : null,
    monthly_service_fee: pricingPlan.name === 'ic-plus-bps' ? '25' : null,
  };
}

export function validateRequiredFormValues(
  values: ProcessingEnvironmentFieldsValues
): ProcessingEnvironmentFieldsErrors {
  const errors = {} as ProcessingEnvironmentFieldsErrors;

  const requiredFields = ['bus_type'];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
}

export function validateFormValues(
  values: ProcessingEnvironmentFieldsValues
): ProcessingEnvironmentFieldsErrors {
  const errors = {} as ProcessingEnvironmentFieldsErrors;
  const requiredErrors = validateRequiredFormValues(values);
  return { ...errors, ...requiredErrors };
}
