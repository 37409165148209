import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ModalBody, ModalIcon, ModalText } from './ModalBody.styles';

const SaveIcon: typeof ModalIcon = styled(ModalIcon)`
  color: ${({ theme }) => theme.colors.core.green[400].hex};
`;

const StyledModalBody = styled(ModalBody)`
  margin-bottom: -64px;
  margin-top: 16px;
`;

export const SavingAccountModalBody: React.FC = () => {
  const [message, setMessage] = useState('Processing...');

  useEffect(() => {
    const timeout = setTimeout(
      // change message after 30 seconds
      () => setMessage('We are still processing your information'),
      30 * 1000
    );

    return () => clearTimeout(timeout);
  }, []);

  return (
    <StyledModalBody>
      <SaveIcon icon={['fas', 'spinner-third']} spin />
      <ModalText as="span">{message}</ModalText>
    </StyledModalBody>
  );
};
