import { CollectionResponse } from '@fattmerchantorg/types-omni';
import { API } from '@fattmerchantorg/types-engine';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Container } from './datatable/table-styles';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { catanapi } from '../../api/catan';
import {
  sendOpenSelected,
  useAsyncEffect,
  useAuthToken,
  useModalReducer,
  usePermissions,
  useToaster,
} from '../../hooks';
import { cleanCollectionQuery, CollectionQuery, PageHeader } from '../shared';
import { history } from '../../history';
import { parse, stringify } from '../../util/api.util';

import {
  SmallPrimaryButton,
  Text,
  TextLink,
} from '@fattmerchantorg/truffle-components';
import { Icon } from '@fattmerchantorg/truffle-components/dist-esm';
import { generateLoadingRows } from '../../util/datatable.util';
import { faCaretLeft, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { TerminalLoadingState } from './LoadingState';
import {
  AddTerminalModal,
  EditModalContext,
  initialEditModalState,
} from './AddTerminalModal';
import { TerminalsDataTable } from './datatable/TerminalDataTable';
import { TerminalDrawer } from './TerminalDrawer';
import { SelectedMerchantStore } from '../../context';
import { IdCustomToolTip } from '../shared/Id/IdCustomToolTip';

interface GetTerminals {
  (query: CollectionQuery): Promise<CollectionResponse<API.Terminal>>;
}

const TitleBar = styled.div`
  /* title bar */
  grid-area: tt;

  /* Auto layout */
  float: left;
  justify-content: space-between;
  flex-direction: row;
  padding: 16px 24px;
  position: absolute;
  width: 93%;
  left: 120px;
  /* Stax Black */

  background: #062333;
  box-shadow: 0px 5px 8px 8px rgba(0, 0, 0, 0.12);
`;
const RightDiv = styled.div`
  float: left;
  padding-left: 8px;
`;

const ButtonContainer = styled.div`
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85px;
`;
const AddTerminalTextLink = styled.div`
  /* Add Terminal */

  position: absolute;
  width: 130px;
  height: 21px;
  left: 46%;
  top: 130px;
`;
const NoTerminalText = styled.div`
  /* No Terminals Found */

  position: absolute;
  width: 1098px;
  height: 54px;
  left: 40%;
  top: 64px;

  /* Heading/H2-Regular */

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 54px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Gray/200 */

  color: #bdc9cc;
`;
const TableContainer = styled.div`
  position: absolute;
  width: 90%;
  top: 200px;
  left: 150px;
`;

const columns = [
  {
    Header: 'Terminal Type',
    accessor: 'label',
    style: { width: '272px' },
    disableSortBy: true,
  },
  {
    Header: 'Terminal Number',
    accessor: 'terminal_number',
    style: { width: '272px' },
    disableSortBy: true,
    Cell: cell => (
      <span>
        <IdCustomToolTip
          toolTip={`Copy Terminal Number to clipboard ${cell.value}`}
          id={cell.value}
        />
      </span>
    ),
  },
  {
    Header: 'V Number',
    accessor: 'v_number',
    disableSortBy: true,
    style: { width: '272px' },
    Cell: cell => (
      <span>
        <IdCustomToolTip
          toolTip={`Copy V Number to clipboard ${cell.value}`}
          id={cell.value}
        />
      </span>
    ),
  },
];

const pageSizeOptions = [
  { value: 10, label: 'Show 10' },
  { value: 20, label: 'Show 20' },
  { value: 50, label: 'Show 50' },
  { value: 100, label: 'Show 100' },
];

export const Terminals: FunctionComponent = () => {
  const authToken = useAuthToken();
  const { state } = useContext(SelectedMerchantStore);
  const [isLoading, setIsLoading] = useState(false);
  const { search, pathname } = useLocation();
  const [terminalDetail, setTerminalDetail] = useState(null);
  const [terminals, setTerminals] = useState<
    CollectionResponse<API.Terminal> | undefined
  >();
  const { toast, toaster } = useToaster();
  const engineCompanyId = state?.registration?.external_company_id;
  const [editModalState, editModalDispatch] = useModalReducer<EditModalContext>(
    initialEditModalState
  );
  const busInfo = history.location.state?.showBusInfo;
  const query = useMemo((): CollectionQuery => {
    return {
      ...parse(search),
    };
  }, [search]);
  const handleQueryChange = useCallback(
    (newQuery: Partial<CollectionQuery> = {}) => {
      const newSearch = stringify(
        cleanCollectionQuery({ ...query, ...newQuery })
      );
      history.push(`${pathname}?${newSearch}`);
    },
    [query, pathname]
  );
  const getData: GetTerminals = useCallback(
    collectionQuery =>
      catanapi.get(authToken, `/companies/${engineCompanyId}/terminals`, {
        ...collectionQuery,
        // timezone,
      }),
    [authToken, engineCompanyId]
  );
  const { permit } = usePermissions();
  const canEditTerminals = permit('godview', 'engineTerminals', 'write');
  useAsyncEffect(async () => {
    fetchTerminals();
  }, [engineCompanyId]);

  return (
    <Container>
      <TitleBar>
        <PageHeader title="" />
        <RightDiv>
          {busInfo === true && (
            <TextLink
              onClick={() => {
                history.push({
                  pathname: `/merchant/${state?.registration?.merchant_id}/businessinfo`,
                });
              }}
              style={{ fontWeight: 700 }}
              data-testid="back-link"
            >
              <Icon
                style={{
                  color: '#009BF2',
                  paddingTop: '50%',
                  width: '8px',
                  height: '20px',
                }}
                icon={faCaretLeft}
              />
              Business Info
            </TextLink>
          )}
          <Text as="h3">
            <b>Terminals</b>
          </Text>
          <Text as="p">View the merchant’s terminals below</Text>
        </RightDiv>
        {canEditTerminals && (
          <ButtonContainer>
            <SmallPrimaryButton
              onClick={() => editModalDispatch(sendOpenSelected({}))}
            >
              <Icon icon={['fas', 'plus']} />
              Add Terminal
            </SmallPrimaryButton>
          </ButtonContainer>
        )}
      </TitleBar>
      <TableContainer>
        {isLoading && <TerminalLoadingState></TerminalLoadingState>}
        {!isLoading && terminals?.total > 0 && (
          <TerminalsDataTable
            data={{
              columns,
              rows: isLoading ? generateLoadingRows() : terminals?.data ?? [],
            }}
            count={terminals?.last_page ?? 0}
            total={terminals?.total ?? 0}
            currentPage={terminals?.current_page ?? 0}
            defaultPageSize={terminals?.per_page ?? 20}
            lastPage={terminals?.last_page ?? 0}
            loading={isLoading}
            pageSizeOptions={pageSizeOptions}
            handleQueryChange={handleQueryChange}
            onRowsChanged={onRowsChanged}
            onItemClick={i => {
              setTerminalDetail(i);
            }}
          />
        )}
        {!isLoading && terminals?.total === 0 && (
          <div>
            <NoTerminalText>No Terminals Found</NoTerminalText>{' '}
            <AddTerminalTextLink>
              <TextLink
                onClick={() => editModalDispatch(sendOpenSelected({}))}
                style={{ fontWeight: 700 }}
                data-testid="open-terminal-link"
              >
                <Icon style={{ color: '#009BF2' }} icon={faPlus} />
                Add Terminal
              </TextLink>
            </AddTerminalTextLink>
          </div>
        )}
      </TableContainer>
      <AddTerminalModal
        status={editModalState.status}
        modalDispatch={editModalDispatch}
        onTerminalCreated={onTerminalCreated}
      />
      <TerminalDrawer
        open={terminalDetail !== null}
        onClose={() => setTerminalDetail(null)}
        getData={terminalDetail !== null ? terminalDetail : null}
      />
    </Container>
  );
  function onRowsChanged(rows) {
    const tmp = { ...terminals };
    tmp.data = rows;
    setTerminals(tmp);
  }

  async function fetchTerminals() {
    if (engineCompanyId) {
      setIsLoading(true);
      try {
        const terminals = await getData(query);

        setTerminals(terminals);

        setIsLoading(false);
      } catch (error) {
        toaster(toast.error(error));
        setIsLoading(false);
      }
    }
  }

  function onTerminalCreated() {
    fetchTerminals();
  }
};
