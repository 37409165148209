import HelloSign, { HelloSign as HS } from 'hellosign-embedded';

export const getContainerId = (identifier: string) => {
  return `hellosign-iframe-container-${identifier}`;
};

export const instances = {};

export const createNewHelloSignClient = (
  merchantId: string,
  signatureUrl: string,
  onSign: (data: HS.SignEventPayload) => void,
  onClose: () => void
): HelloSign => {
  const newClient = new HelloSign({
    clientId: process.env.REACT_APP_HELLOSIGN_CLIENT_ID,
  });

  const clientSettings: HS.Options = {
    testMode: process.env.NODE_ENV !== 'production',
    skipDomainVerification: process.env.NODE_ENV !== 'production',
    container: document.getElementById(getContainerId(merchantId)),
    allowCancel: true,
    debug: false,
  };

  newClient.on('sign', onSign);
  newClient.on('cancel', onClose);
  newClient.on('close', onClose);
  newClient.open(signatureUrl, clientSettings);

  instances[merchantId] = newClient;
  return newClient;
};
