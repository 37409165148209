import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

export const DrawerSectionBase = styled.section`
  position: relative;
  border-radius: 2px;
  line-height: 1.5;
  p,
  span {
    font-size: 14px;
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
  }
  a {
    text-decoration: underline;
    color: #009bf2;
  }
`;

const SectionContent = styled.div`
  display: flex;
  padding: 8;
  width: 100%;
`;

export const AccordionWrapper = styled.div`
  padding: 0 16px;
`;

interface DrawerSectionProps extends React.HTMLAttributes<HTMLDivElement> {
  rightContent?: React.FunctionComponent;
  id: string;
}

export const DrawerSection: FunctionComponent<DrawerSectionProps> = props => {
  const { children, id, rightContent, ...rest } = props;
  return (
    <DrawerSectionBase id={id} data-testid={id} {...rest}>
      <SectionContent>
        {typeof children === 'function' ? children() : children}
      </SectionContent>
    </DrawerSectionBase>
  );
};
