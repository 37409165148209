import React, {
  FunctionComponent,
  useState,
  useCallback,
  useMemo,
  useEffect,
  useContext,
  useRef,
} from 'react';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { TeamSettingsDataTable } from './TeamSettingsTable';
import StatusPill, {
  StatusPillStatus,
} from '@fattmerchantorg/truffle-components/dist/StatusPill/StatusPill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserLock, faUser } from '@fortawesome/pro-solid-svg-icons';
import { coreapi } from '../../../api';
import { useAuthToken, usePermissions, useToaster } from '../../../hooks';
import { formatDate } from '../../../util/date.util';
import {
  ContentSeparator,
  MainContent,
  PageContent,
  SettingsHeaderContent,
  SettingsHeaderRow,
  SettingsHeaderContentTitle,
  SettingsHeaderContentTitleText,
  SettingsHeaderContentActions,
  SettingsSubheader,
  StyledPrimaryButton,
  StyledTextField,
  StyledToggleSelector,
} from '../SettingsPagesStylings';
import { CollectionResponse, User } from '@fattmerchantorg/types-omni';
import { TeamMemberModal } from './TeamMemberModal';
import { generateLoadingRows } from '../../../util/datatable.util';
import { cleanCollectionQuery, CollectionQuery } from '../../shared';
import { generateCancelSource, parse, stringify } from '../../../util/api.util';
import { useLocation } from 'react-router-dom';
import { history } from '../../../history';
import { pick } from '../../../util/object.util';
import { SelectedMerchantStore, AuthStore } from '../../../context';
import axios, { CancelTokenSource } from 'axios';

interface SelectionOption {
  label: string;
  value: string;
}

export function mapStatusToIcon(status: string): {
  label: string;
  status: StatusPillStatus;
} {
  if (status === 'active') {
    return {
      label: 'Active',
      status: 'success',
    };
  } else if (status === 'invite sent') {
    return {
      label: 'Invite Sent',
      status: 'neutral',
    };
  }
  return {
    label: 'Deactivated',
    status: 'error',
  };
}

export function calculateStatus(user: User): string {
  if (
    user.email_verification_sent_at &&
    !user.deleted_at &&
    !user.email_verified_at
  ) {
    return 'invite sent';
  } else if (user.deleted_at) {
    return 'inactive';
  } else {
    return 'active';
  }
}

const statusOptions: SelectionOption[] = [
  {
    label: 'All',
    value: '{"include_deleted":"1","is_deleted":"0"}',
  },
  {
    label: 'Active',
    value: '{"include_deleted":"0","is_deleted":"0"}',
  },
  {
    label: 'Deactivated',
    value: '{"include_deleted":"0","is_deleted":"1"}',
  },
];

const COLUMN_DATA = [
  {
    Header: '',
    accessor: 'mfa_enabled',
    style: {
      width: '42px',
      height: '50px',
    },
    Cell: cell =>
      cell.value ? (
        <FontAwesomeIcon icon={faUserLock} />
      ) : (
        <FontAwesomeIcon icon={faUser} />
      ),
    disableSortBy: true,
  },
  {
    Header: 'Full Name',
    accessor: 'name',
    style: {
      height: '50px',
    },
  },
  {
    Header: 'Role',
    accessor: 'role_name',
    style: {
      height: '50px',
    },
    Cell: cell => cell.value || '--',
  },
  {
    Header: 'Email Address',
    accessor: 'email',
    style: {
      height: '50px',
    },
  },

  {
    Header: 'Date Added',
    accessor: 'created_at',
    style: {
      height: '50px',
    },
    Cell: cell => <span>{formatDate(cell.value, 'MM/dd/yy')}</span>,
  },
  {
    Header: 'Status',
    accessor: 'status',
    style: {
      height: '50px',
    },
    Cell: cell =>
      cell.value !== null ? (
        <StatusPill {...mapStatusToIcon(cell.value)} />
      ) : null,
  },
];

const pageSizeOptions = [
  { value: 10, label: 'Show 10' },
  { value: 20, label: 'Show 20' },
  { value: 50, label: 'Show 50' },
  { value: 100, label: 'Show 100' },
];

export const TeamSettingsPage: FunctionComponent = () => {
  const {
    state: { selectedBrandSwitcherOption },
  } = useContext(SelectedMerchantStore);
  const authToken = useAuthToken();
  const {
    state: { auth },
  } = useContext(AuthStore);

  const userBrand = auth.data[0]?.user.brand;

  const isInternalUser =
    userBrand.startsWith('stax') || userBrand.startsWith('fattmerchant');

  const { toast, toaster } = useToaster();
  const [isAddTeamMemberModalOpen, setIsAddTeamMemberModalOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [keywords, setKeywords] = useState('');

  const [usersData, setUsersData] = useState<CollectionResponse<User>>();

  const { search, pathname } = useLocation();
  const { permit } = usePermissions();
  const cancelTokenSourceRef = useRef<CancelTokenSource>(null);

  const canWrite = permit('godview', 'teamSettings', 'write');

  const query = useMemo(() => {
    const parsedQuery = parse(search);
    parsedQuery.brand = selectedBrandSwitcherOption;
    // query ONLY god users
    parsedQuery.system_admin = '1';
    // exclude api_key users
    parsedQuery.is_api_key = '0';
    if (!isInternalUser) {
      parsedQuery.exclude_internal_users = '1';
    }
    return parsedQuery;
  }, [search, selectedBrandSwitcherOption, isInternalUser]);

  const handleQueryChange = useCallback(
    (newQuery: Partial<CollectionQuery> = {}) => {
      const newSearch = stringify(
        cleanCollectionQuery({ ...query, ...newQuery })
      );
      history.push(`${pathname}?${newSearch}`);
    },
    [query, pathname]
  );

  const selectedStatusOption: string = useMemo(() => {
    const queryStatus = pick(query, 'include_deleted', 'is_deleted');
    return JSON.stringify(queryStatus);
  }, [query]);

  const fetchUsers = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await coreapi.get(
        authToken,
        '/user',
        query,
        null,
        null,
        cancelTokenSourceRef.current
      );

      const dataWithStatus = data.data?.map(user => {
        const status = calculateStatus(user);
        return { ...user, status };
      });

      setUsersData({ ...data, data: dataWithStatus });
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }
      setIsLoading(false);
      toaster(toast.error(error, `There was a problem fetching users.`));
    }
  }, [authToken, query, toaster, toast]);

  useEffect(() => {
    cancelTokenSourceRef.current = generateCancelSource();
    fetchUsers();
    return () => {
      cancelTokenSourceRef.current.cancel();
    };
  }, [fetchUsers]);

  return (
    <ContentSeparator>
      <PageContent>
        <TeamMemberModal
          isOpen={isAddTeamMemberModalOpen}
          setIsOpen={setIsAddTeamMemberModalOpen}
          fetchUsers={fetchUsers}
        />
        <SettingsHeaderContent>
          <SettingsHeaderRow>
            <SettingsHeaderContentTitle>
              <SettingsHeaderContentTitleText>
                Team Settings
              </SettingsHeaderContentTitleText>
              <SettingsSubheader>
                Add, remove, search, or edit team members.
              </SettingsSubheader>
            </SettingsHeaderContentTitle>

            <SettingsHeaderContentActions>
              {canWrite && (
                <StyledPrimaryButton
                  onClick={() => {
                    setIsAddTeamMemberModalOpen(
                      currentIsOpen => !currentIsOpen
                    );
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add Team Member
                </StyledPrimaryButton>
              )}
            </SettingsHeaderContentActions>
          </SettingsHeaderRow>

          <SettingsHeaderRow>
            <StyledToggleSelector
              options={statusOptions}
              defaultSelected={
                statusOptions.find(o => o.value === selectedStatusOption).value
              }
              onChange={(e, value) => {
                handleQueryChange(JSON.parse(value.value));
              }}
            />
            <div className="d-flex">
              <StyledTextField
                placeholder={'Search by user name or email'}
                type="search"
                onChange={e => {
                  setKeywords(e?.target?.value ?? '');
                  if (e?.target?.value === '') {
                    handleQueryChange({ keywords: [], page: 1 });
                  }
                }}
                onKeyPress={event =>
                  event.key === 'Enter' &&
                  handleQueryChange({
                    keywords: keywords.split(' '),
                    page: 1,
                  })
                }
                onSearch={() =>
                  handleQueryChange({ keywords: keywords.split(' '), page: 1 })
                }
              />
            </div>
          </SettingsHeaderRow>
        </SettingsHeaderContent>
        <MainContent>
          <TeamSettingsDataTable
            data={{
              columns: COLUMN_DATA,
              rows: isLoading ? generateLoadingRows() : usersData?.data || [],
            }}
            isLoading={isLoading}
            pageSizeOptions={pageSizeOptions}
            count={usersData?.last_page ?? 0}
            total={usersData?.total ?? 0}
            currentPage={usersData?.current_page ?? 0}
            defaultPageSize={usersData?.per_page ?? 20}
            lastPage={usersData?.last_page ?? 0}
            handlePagination={handleQueryChange}
          />
        </MainContent>
      </PageContent>
    </ContentSeparator>
  );
};
