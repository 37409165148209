import React, { FC, Dispatch, useCallback, useContext } from 'react';
import {
  Modal,
  FieldError,
  Form,
  Group,
  Input,
  Field,
  ResponsivePadding
} from '../../shared';
import { coreapi } from '../../../api';
import { useAuthToken, useToaster } from '../../../hooks';
import {
  sendCloseSelected,
  Action as ModalAction,
  Status as ModalStatus
} from '../../../hooks/useModalReducer';
import {
  appendSelectedMerchantAPIKey,
  SelectedMerchantStore
} from '../../../context';
import { APIKey, User } from '@fattmerchantorg/types-omni';
import { StaxButton } from '../../shared/Stax/Button';

type TeamRole = User['team_role'];

interface NewAPIKeyForm {
  apiKeyName: string;
}

const initialValues: NewAPIKeyForm = {
  apiKeyName: ''
};

const closeSelected = sendCloseSelected();
export const CreateNewAPIKeyModal: FC<{
  status: ModalStatus;
  modalDispatch: Dispatch<ModalAction>;
}> = ({ status, modalDispatch }) => {
  const authToken = useAuthToken();
  const { toaster, toast } = useToaster();
  const {
    dispatch: selectedMerchantDispatch,
    state: { merchantId }
  } = useContext(SelectedMerchantStore);

  const onClose = useCallback(() => modalDispatch(closeSelected), [
    modalDispatch
  ]);

  const onSubmit = useCallback(
    async formValues => {
      try {
        const response: APIKey = await coreapi.post(authToken, '/user', {
          is_api_key: true,
          merchant_id: merchantId,
          team_role: 'admin', // API Keys will always have role of team admin
          name: formValues.apiKeyName
        });

        selectedMerchantDispatch(appendSelectedMerchantAPIKey(response));
        toaster(toast.success('API Key successfully created.', 'Created'));
        modalDispatch(sendCloseSelected());
      } catch (error) {
        toaster(
          toast.error(error, 'There was a problem creating a new API Key.')
        );
        modalDispatch(sendCloseSelected());
      }
    },
    [
      merchantId,
      authToken,
      selectedMerchantDispatch,
      toaster,
      toast,
      modalDispatch
    ]
  );
  return (
    <Modal
      data-testid="create-new-api-key-modal"
      isOpen={status === ModalStatus.OPEN}
      onClose={onClose}
      title={<strong>Create New API Key</strong>}
    >
      <Form<NewAPIKeyForm> onSubmit={onSubmit} initialValues={initialValues}>
        {formProps => (
          <ResponsivePadding desktop="2.5%" mobile="1rem">
            <Group direction="column">
              <Field name="apiKeyName" label="API Key Name">
                {props => <Input {...props.input} />}
              </Field>
              <FieldError name="apiKeyName" />
              <small>
                Give this API Key a descriptive name to help you identify it.
              </small>
            </Group>
            <Group justify="flex-end" space="0px">
              <StaxButton
                type="submit"
                icon="fas fa-check"
                id="create-new-api-key-submit-button"
                data-testid="create-new-api-key-submit-button"
                loading={formProps.submitting}
                disabled={formProps.submitting}
              >
                Create
              </StaxButton>
            </Group>
          </ResponsivePadding>
        )}
      </Form>
    </Modal>
  );
};
