import React, {
  FunctionComponent,
  useContext,
  useState,
  useEffect,
} from 'react';
import styled from 'styled-components';
import { valueOrNA, valueOrHyphen } from '../../../../util/string.util';
import { UnderwritingSectionBase } from '../shared/UnderwritingSection';
import { SelectedMerchantStore } from '../../../../context';
import { Icon, Tooltip } from '@fattmerchantorg/truffle-components';
import { BusinessInfoFormModal } from './BusinessInfoFormModal';
import { ModalContext } from '../../../../context';
import {
  companyTypes,
  CompanyTypeCode,
} from '../../../../util/registration.util';
import {
  formatPhoneNumber,
  formatFederalTaxId,
} from '../../../../util/format.util';
import { CurrenciesIndicator } from '../../../shared';

const BusinessInfoSection = styled(UnderwritingSectionBase)`
  p {
    display: inline-flex;
    align-items: left;
    margin: 0 0 8px;
  }

  p svg,
  span svg {
    // Inline icons need right margin
    margin: 0 8px 0 0;
  }
`;

const BusinessInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  h2 {
    font-weight: bold;
    font-size: 24px;
  }
`;

const EditToggle = styled.button`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 20px;
  right: 16px;
  z-index: 2;
  background: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  color: #009bf2;
  width: 16px;

  > span > svg {
    margin-right: 0;
  }
`;

const CurrencyAndIpAddressSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export interface BusinessInfoProps {}

export const BusinessInfo: FunctionComponent = props => {
  const { modalDispatch } = useContext(ModalContext);
  const {
    dispatch,
    state: { merchantId, registration, merchant },
  } = useContext(SelectedMerchantStore);

  const [regState, setRegState] = useState(registration);
  const [mId, setmId] = useState(merchantId);

  useEffect(() => {
    setRegState(registration);
    setmId(merchantId);
  }, [registration, merchantId]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        component: BusinessInfoFormModal,
        props: {
          isOpen: true,
          registration: regState,
          merchantId: mId,
          merchantDispatch: dispatch,
        },
      },
    });
  };

  const renderCompanyType = (key: CompanyTypeCode): string => {
    if (!key) return undefined;
    return companyTypes.find(type => type.key === key)?.description ?? key;
  };

  return (
    <BusinessInfoSection data-testid="underwriting-business-info">
      <EditToggle onClick={handleClick}>
        <Icon icon={['fas', 'pencil']} />
      </EditToggle>
      <BusinessInfoContent>
        <h2 data-testid="uw-business-legal-name">
          {valueOrNA(registration?.business_legal_name)}
        </h2>
        <p data-testid="uw-business-dba">
          {valueOrNA(registration?.business_dba)}
        </p>
        <p data-testid="uw-company-type">
          {valueOrHyphen(renderCompanyType(registration?.company_type))}{' '}
          {registration?.entity_ownership_type
            ? `- ${registration.entity_ownership_type}`
            : ''}
        </p>
        <p data-testid="uw-business-tax-id">
          {valueOrNA(formatFederalTaxId(registration?.business_tax_id))}
        </p>

        <p>
          <Tooltip content="Business Phone Number">
            <Icon icon={['fas', 'phone']} />
          </Tooltip>
          <a
            href={
              registration?.business_phone_number
                ? `tel://${registration.business_phone_number}`
                : null
            }
            data-testid="uw-business-phone-number"
          >
            {valueOrNA(formatPhoneNumber(registration?.business_phone_number))}
          </a>
        </p>
        <p>
          <Tooltip content="Business Fax">
            <Icon icon={['fas', 'fax']} />
          </Tooltip>
          <span data-testid="uw-business-fax">
            {valueOrNA(formatPhoneNumber(registration?.business_fax))}
          </span>
        </p>

        <p>
          <Tooltip content="Business Email">
            <Icon icon={['fas', 'envelope']} />
          </Tooltip>
          <a
            href={
              registration?.business_email
                ? `mailto:${registration.business_email}`
                : null
            }
            data-testid="uw-business-email"
          >
            {valueOrNA(registration?.business_email)}
          </a>
        </p>
        <p>
          <Tooltip content="Business Website">
            <Icon icon={['fas', 'globe']} />
          </Tooltip>
          <a
            href={
              registration?.business_website
                ? registration.business_website
                : null
            }
            data-testid="uw-business-website"
          >
            {valueOrNA(registration?.business_website)}
          </a>
        </p>
        <p data-testid="uw-business-open-date">
          Incorporated: {valueOrNA(registration?.business_open_date)}
        </p>
        <p data-testid="uw-service-you-provide">
          {valueOrNA(registration?.service_you_provide)}
        </p>
        <CurrencyAndIpAddressSection>
          <p data-testid="uw-merchant-currency">
            <CurrenciesIndicator currencies={merchant?.currency ?? ['USD']} />
          </p>
          <p data-testid="uw-user-ip">
            IP Address: {valueOrNA(registration?.user_ip)}
          </p>
        </CurrencyAndIpAddressSection>
      </BusinessInfoContent>
    </BusinessInfoSection>
  );
};
