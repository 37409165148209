import styled, { withTheme } from 'styled-components';

const MockModalSubTitle = withTheme(
  styled.div`
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme }) => theme.white};
    padding: 16px 0 8px 0;
  `
);

export default MockModalSubTitle;
