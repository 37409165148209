import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Tooltip, Icon } from '@fattmerchantorg/truffle-components';
import { Merchant } from '@fattmerchantorg/types-omni';
import { faPeopleArrows } from '@fortawesome/pro-solid-svg-icons';

const AssumeIcon = styled(Icon)`
  margin-left: auto;
  color: ${({ theme }) => theme.colors.status.blue[500].hex};

  &[data-disabled='true'] {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 50%;
    color: ${({ theme }) => theme.colors.core.gray[200].hex};
  }
`;

export const AssumeLink: FunctionComponent<{ merchant: Merchant }> = props => {
  const { merchant } = props;
  // if merchant is pending, disable assuming
  const disabled = merchant.status === 'PENDING';
  const href = disabled
    ? '#'
    : `${process.env.REACT_APP_STAX_PAY_URL}/#/dashboard?assume=${merchant.id}`;
  const tooltipContent = disabled
    ? 'Pending merchants cannot be assumed'
    : 'Assume this merchant';

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={href}
      onClick={e => {
        e.stopPropagation();
        if (disabled) e.preventDefault();
      }}
    >
      <Tooltip content={tooltipContent}>
        <AssumeIcon data-disabled={disabled} icon={faPeopleArrows} />
      </Tooltip>
    </a>
  );
};
