import React, { FunctionComponent, useContext } from 'react';
import styled, { withTheme } from 'styled-components';
import Select from 'react-select';

import { ImportMappingRecord, OmniColumn } from '../../../../@types';
import { Button, Group } from '../../../shared';
import { ImportStore, updateMappingColumn } from '../../../../context';
import { withAlpha } from '../../../../util/color.util';
import { ImporterCard } from './';

export type ImporterMatchColumn = ImportMappingRecord & { name: string };

const LeftSection = styled.div`
  flex: 55%;
  border-radius: 6px;
  overflow: visible;
  border: 1px solid #435e70;
`;

const RightSection = styled.div`
  flex: 45%;

  margin: 0.5rem 0.5rem 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div:nth-child(2) {
    margin-top: 16px;
  }
`;

const DescriptionLine = styled.div`
  text-align: left;

  > span {
    font-size: 14px;
  }

  > i {
    font-size: 14px;
    padding-right: 0.5rem;
  }
`;

const SampleListHeader = styled.div`
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  justify-content: space-between;
  height: 42px;
  background-color: ${({ theme }) => theme.colors.core.gray[800]};

  &[data-ignored='true'] {
    overflow: hidden;
    opacity: 0.5;
    border-radius: 2px;
  }

  &[data-confirmed='true'] {
    overflow: hidden;
    border-radius: 2px;
  }
`;

const LeftSampleListHeader = withTheme(
  styled(SampleListHeader)`
    line-height: 42px;
    overflow: hidden;

    > span {
      width: 42px;
      text-align: center;
      font-weight: bold;
      background-color: ${({ theme }) => theme.black};
    }

    > label {
      padding-left: 0.5rem;
    }
  `
);

const RightSampleListHeader = styled(SampleListHeader)`
  align-items: center;
  margin: auto 0.5rem;

  > i {
    color: #868a91;
    padding-right: 1rem;
  }

  > div {
    text-align: left;
    font-size: 14px;
    min-width: 12rem;
  }
`;

const selectStyles = {
  option: (styles, state) => {
    if (state.isFocused || state.isSelected) {
      return {
        ...styles,
        backgroundColor: withAlpha('#0099CC', 0.2),
      };
    }

    return styles;
  },
  control: (styles, state) => {
    if (state.isFocused || state.isSelected || state.menuIsOpen) {
      return {
        ...styles,
        borderColor: '#0099CC',
        boxShadow: '1px solid #0099CC',
      };
    }

    return styles;
  },
};

const SampleList = withTheme(
  styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;

    > div {
      background: ${({ theme }) => theme.colors.core.gray[800].hex};
      font-size: 15px;
      line-height: 32px;
      text-align: left;
      padding-left: calc(42px + 0.5rem);
      border-top: 1px solid ${({ theme }) => theme.colors.core.gray[600].hex};
      &:nth-child(5) > span {
        mask-image: linear-gradient(to top, transparent 30%, black 100%);
      }
    }
  `
);

const StyledCard = withTheme(
  styled(ImporterCard)`
    margin-bottom: 2rem;
    background: ${({ theme }) => theme.colors.core.gray[800].hex};
    border-radius: 2px;
  `
);

const StyledSelect = withTheme(
  styled(Select)`
    > div {
      border: 1px solid ${({ theme }) => theme.colors.core.gray[600].hex};
      background: ${({ theme }) => theme.colors.core.gray[800].hex};
    }
  `
);

export const ImporterMatchCard: FunctionComponent<{
  index: number;
  disabled: boolean;
  column: ImporterMatchColumn;
}> = props => {
  const {
    dispatch,
    state: { availableTargetColumns },
  } = useContext(ImportStore);
  const { disabled, index, column } = props;
  const isAutomatched = !!column.target;

  const value = { value: column.target, label: column.target };
  const isDone = column.ignored || column.confirmed;

  const options = availableTargetColumns.map(atc => ({
    value: atc,
    label: atc,
  }));

  return (
    <StyledCard>
      <form
        onSubmit={() =>
          !disabled &&
          dispatch(updateMappingColumn(column.name, { confirmed: true }))
        }
      >
        <Group>
          <LeftSection>
            <SampleListHeader
              data-ignored={column.ignored}
              data-confirmed={column.confirmed}
            >
              <LeftSampleListHeader>
                <span>{index + 1}</span>
                <label>{column.name}</label>
              </LeftSampleListHeader>
              <RightSampleListHeader>
                <i className="fas fa-arrow-right" />

                <StyledSelect
                  isClearable
                  disabled={isDone || disabled}
                  isDisabled={isDone || disabled}
                  placeholder="Select a column"
                  value={column.target ? value : null}
                  options={options}
                  styles={selectStyles}
                  onChange={option => {
                    const target: OmniColumn =
                      option && option.value
                        ? (option.value as OmniColumn)
                        : null;
                    if (!disabled) {
                      dispatch(updateMappingColumn(column.name, { target }));
                    }
                  }}
                />
              </RightSampleListHeader>
            </SampleListHeader>
            {!isDone && (
              <SampleList>
                {column.samples.map((sample, i) => (
                  <div key={`sample-${index}-${i}`}>
                    <span>
                      {typeof sample === 'number' || typeof sample === 'string'
                        ? sample
                        : JSON.stringify(sample)}
                    </span>
                  </div>
                ))}
              </SampleList>
            )}
          </LeftSection>
          {isDone ? (
            <RightSection>
              <Group justify="space-between">
                {column.ignored ? (
                  <DescriptionLine>
                    <i
                      className="fas fa-eye-slash"
                      style={{ color: '#B7BAC1' }}
                    />
                    <span>
                      <strong>Column Ignored</strong>
                    </span>
                  </DescriptionLine>
                ) : (
                  <DescriptionLine>
                    <i className="fas fa-check" style={{ color: '#28CB35' }} />
                    <span>
                      <strong>Mapping Confirmed</strong>
                    </span>
                  </DescriptionLine>
                )}
                <Button
                  disabled={disabled}
                  tabIndex={-1}
                  variant="outline"
                  size="small"
                  onClick={() =>
                    !disabled &&
                    dispatch(
                      updateMappingColumn(column.name, {
                        ignored: false,
                        confirmed: false,
                      })
                    )
                  }
                >
                  Edit
                </Button>
              </Group>
            </RightSection>
          ) : (
            <RightSection>
              <div>
                <DescriptionLine>
                  {isAutomatched ? (
                    <i className="fas fa-check" style={{ color: '#28CB35' }} />
                  ) : (
                    <i
                      className="fas fa-exclamation-triangle"
                      style={{ color: '#EFC201' }}
                    />
                  )}
                  {isAutomatched ? (
                    <span>
                      Matched to <strong>{column.target}</strong> field
                    </span>
                  ) : (
                    <span>Unable to match</span>
                  )}
                </DescriptionLine>
                <DescriptionLine>
                  <i
                    className="fas fa-chart-pie"
                    style={{ color: '#B7BAC1' }}
                  />
                  <span>
                    {Math.floor((column.percent_filled ?? 0) * 100)}% of your
                    rows have a value for this column
                  </span>
                </DescriptionLine>
              </div>

              <Group justify="flex-end" space="0.5rem">
                <Button
                  disabled={disabled}
                  variant="outline"
                  size="small"
                  onClick={() =>
                    !disabled &&
                    dispatch(
                      updateMappingColumn(column.name, {
                        target: '',
                        ignored: true,
                      })
                    )
                  }
                >
                  Ignore Column
                </Button>
                <Button
                  type="submit"
                  size="small"
                  disabled={!column.target || disabled}
                  onClick={() =>
                    !disabled &&
                    dispatch(
                      updateMappingColumn(column.name, { confirmed: true })
                    )
                  }
                >
                  Confirm
                </Button>
              </Group>
            </RightSection>
          )}
        </Group>
      </form>
    </StyledCard>
  );
};
