import React, { FC, useState, useEffect } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import MockModalSubTitle from './MockModalSubtitle';
import { underwritingStatuses } from '../../../../util/underwriting.util';

const statuses = Object.keys(underwritingStatuses);

export const UnderwritingStatusSelection: FC<{
  handleSelection: Function;
  defaultStatus: string;
}> = ({ handleSelection, defaultStatus }) => {
  const [status, setStatus] = useState<string>();

  // Set default status.
  useEffect(() => {
    setStatus(defaultStatus);
    handleSelection(defaultStatus);
  }, [defaultStatus, handleSelection]);

  const handleStatusChange = (x: { target: { value: string } }) => {
    const status = x.target.value;
    handleSelection(status);
    setStatus(status);
  };

  const tooltip_id = 'underwriting-status-info';
  const tooltip_copy = [
    'This status is used for underwriting application statuses.  This mimics what will happen when our underwriting system and team go through an application.  When set to approved, an account status can be set as well',
    <br key="tt_br_1" />,
    <br key="tt_br_2" />,
    ' NOTE: In test mode, when you set the status as approved, pended, or rejected, requirement to have an electronic signature will be bypassed.',
  ];

  const awaiting_review_tooltip_id = 'awaiting-review-status-info';
  const awaiting_review_tooltip_copy =
    'Awaiting review is the default status after an electronic signature has been sent with a completed merchant application.  In the front-end display on OmniConnect, if no electronic signature has been sent, this underwriting status will be overridden and still display as "Awaiting Application Signature"';

  return (
    <>
      <MockModalSubTitle>
        Merchant Application Status{' '}
        <i
          id={tooltip_id}
          data-testid={tooltip_id}
          className="fas fa-info-circle"
        />
        <UncontrolledTooltip delay={0} target={tooltip_id}>
          {tooltip_copy}
        </UncontrolledTooltip>
      </MockModalSubTitle>

      <label key="null-underwriting-status" className="d-block mb-0">
        <input
          type="radio"
          name="application-status"
          value="AWAITING_REVIEW"
          onChange={handleStatusChange}
          className="mr-2"
          // This status' value (only used on this mock feature) is a string
          // before saving but a null will be inserted in the database.
          checked={status === 'AWAITING_REVIEW' || !status}
        />
        AWAITING REVIEW{' '}
        <i
          id={awaiting_review_tooltip_id}
          data-testid={awaiting_review_tooltip_id}
          className="fas fa-info-circle"
        />
        <UncontrolledTooltip delay={0} target={awaiting_review_tooltip_id}>
          {awaiting_review_tooltip_copy}
        </UncontrolledTooltip>
      </label>

      {statuses.map(key => (
        <label key={key} className="d-block mb-0">
          <input
            type="radio"
            name="application-status"
            value={key}
            onChange={handleStatusChange}
            className="mr-2"
            checked={status === key}
          />
          {underwritingStatuses[key]}
        </label>
      ))}
    </>
  );
};
