import React, { FC, useCallback, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { CollectionResponse, FeeStatement } from '@fattmerchantorg/types-omni';
import { queryapi } from '../../api';
import { useAuthToken, useFeatureFlag, useSearchState } from '../../hooks';

import { daysAgo, formatDate, getMonthName } from '../../util/date.util';
import { formatCurrency, sumAndFormat } from '../../util/format.util';
import {
  Collection,
  CollectionQuery,
  CollectionWrapper,
  CollectionProps,
  PageHeader,
  ColumnProps,
  FeatureFlag,
} from '../shared';
import { DetailModal } from './DetailModal';

interface FeeStatementsProps {
  merchantId?: string;
}
interface QueryParams {
  merchantId?: string;
  startDate?: string;
  endDate?: string;
  order?: string;
  timezone?: string;
  page?: string;
}
interface getFeeStatements {
  (query: CollectionQuery): Promise<CollectionResponse<FeeStatement>>;
}

const getFeeStatementColumns: CollectionProps<FeeStatement>['columns'] =
  items => {
    const feeStatementColumns: ColumnProps<FeeStatement>[] = [
      {
        Header: 'Date',
        accessor: 'activity_period',
        disableSortBy: true,
        Cell: cell => {
          const activityPeriod: Date = new Date(cell.value);
          return (
            <>
              <b>{getMonthName(activityPeriod, 'short')}</b>{' '}
              {activityPeriod.getFullYear()}
            </>
          );
        },
      },
    ];

    // if there is no summary data, don't render this column
    if (typeof items?.[0]?.transactions !== 'undefined') {
      feeStatementColumns.push({
        Header: 'Total',
        accessor: 'transaction_fees',
        disableSortBy: true,
        Cell: cell => {
          const statement = cell.row.original;
          return (
            <>
              {sumAndFormat(
                statement.interchange_and_fees,
                statement.transaction_fees,
                formatCurrency
              )}
            </>
          );
        },
      });
    }

    return feeStatementColumns;
  };

/** The fee statements page for a selected merchant. */
export const FeeStatements: FC<FeeStatementsProps> = props => {
  const { params } = useRouteMatch<QueryParams>();

  const [searchState, setSearchState] = useSearchState();
  const { sv: statementVersion, detail: detailActivityPeriod } = searchState;

  const shouldUseSettlementStatement = statementVersion === '2';
  const authToken = useAuthToken();
  const history = useHistory();

  const { available: eligibleForV3 } = useFeatureFlag(
    'Reports',
    'StaxConnectFeeStatementV3'
  );

  const merchantId = props.merchantId || params.merchantId;
  const query: CollectionQuery = useMemo(
    () => ({
      startDate: formatDate(daysAgo(365)), // TODO: limit to 182 days (.5 year) after PHX-242
      endDate: formatDate(new Date()),
      merchantId,
    }),
    [merchantId]
  );

  const getData: getFeeStatements = useCallback(
    async collectionQuery =>
      await queryapi.get(authToken, `fee/months`, collectionQuery),
    [authToken]
  );

  const rowClickHandler = useCallback(
    feeStatement => {
      if (eligibleForV3 && feeStatement.is_v3) {
        return history.push(
          `/merchant/${merchantId}/fee-statement/${feeStatement.activity_period}`
        );
      }
      return setSearchState({
        sv: feeStatement.is_v2 ? '2' : null,
        detail: feeStatement.activity_period,
      });
    },
    [eligibleForV3, history, merchantId, setSearchState]
  );

  return (
    <>
      <PageHeader title="Fee Statements" />
      <FeatureFlag category="Reports" feature="DetailedSettlementStatement">
        {({ available }) => (
          <DetailModal
            activityPeriod={detailActivityPeriod as string}
            shouldUseSettlementStatement={
              available || shouldUseSettlementStatement
            }
            isOpen={!!detailActivityPeriod}
            onClose={() => {
              setSearchState({ sv: null, detail: null });
            }}
          />
        )}
      </FeatureFlag>
      <Container fluid>
        <Row>
          <CollectionWrapper>
            <Collection<FeeStatement>
              data-testid={`fee-statement-collection-${merchantId}`}
              query={query}
              blacklist={['detail']}
              getData={
                // if we don't have a merchant id yet we're not ready to get data
                merchantId ? getData : null
              }
              columns={getFeeStatementColumns}
              onRowClick={rowClickHandler}
              noResultsMessage="This merchant has no fee statement data."
              paginated={false}
            />
          </CollectionWrapper>
        </Row>
      </Container>
    </>
  );
};
