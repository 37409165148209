import React from 'react';
import styled, { withTheme } from 'styled-components';

const StyledSpan = withTheme(styled.span`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 2px;
  gap: 10px;
  width: 12px;
  height: 11.6px;
  background: ${({ theme }) => theme.colors.core.purple[500].hex};
  border-radius: 2px;
  flex: none;
  order: 1;
  flex-grow: 0;
`);

const StaxFlag = () => {
  return (
    <StyledSpan>
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 6.95261V7.79872L3.99927 5.80348L8 7.79872V6.95261L3.99927 4.95737L0 6.95261ZM0 2.42489L8 6.41391V5.56779L0 1.57877V2.42489ZM0 2.96359V3.8097L1.76981 4.69241L0 5.57511V6.42123L3.46642 4.69241L0 2.96359ZM3.99927 2.19652L0 0.201282V1.04739L4.00073 3.04263L8 1.04739V0.201282L3.99927 2.19652ZM8 5.03641V4.1903L6.23019 3.30759L8 2.42489V1.57877L4.53358 3.30759L8 5.03641Z"
          fill="white"
        />
      </svg>
    </StyledSpan>
  );
};

export default StaxFlag;
