import styled from 'styled-components';

export const StatementMessageSection = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 24px;
  div:not(:first-child) {
    margin-top: 10px;
  }
`;
