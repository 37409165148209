import { Card as RSCard } from 'reactstrap';
import styled, { withTheme } from 'styled-components';

export const ImporterCard = withTheme(
  styled(RSCard)`
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
    box-shadow: rgba(0, 0, 0, 0.176) 0px 4px 8px !important;
    background: ${({ theme }) => theme.colors.core.gray[800].hex};
    border-radius: 2px;
    color: white;
  `
);
