import React, {
  useState,
  useCallback,
  FunctionComponent,
  useEffect,
} from 'react';
import { useLocation } from 'react-router';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import styled from 'styled-components';

import { parse } from '../../../util/api.util';
import { debounce } from '../../../util/functional.util';
import { KeywordSearchProps } from './KeywordSearch.types';

const StyledInput = styled(Input)`
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
`;

const StyledInputGroupText = styled(InputGroupText)`
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
`;

export const KeywordSearch: FunctionComponent<KeywordSearchProps> = props => {
  const { searchKey, isSearching, onSearch, ...restOfProps } = props;
  const { search } = useLocation();
  const keywords = parse(search)[searchKey];
  const initialValue =
    keywords && Array.isArray(keywords) ? keywords.join(' ') : '';
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChange = useCallback(
    debounce((value: string) => {
      onSearch(value && value.split(' '));
    }),
    [onSearch]
  );

  return (
    <InputGroup>
      <StyledInput
        id="keyword-search-input"
        data-testid="keyword-search-input"
        placeholder="Keyword Search"
        {...restOfProps}
        value={value}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
      <InputGroupAddon addonType="prepend">
        <StyledInputGroupText>
          {isSearching ? (
            <i className="button-icon fas fa-circle-notch fa-spin" />
          ) : (
            <i className="fas fa-search" />
          )}
        </StyledInputGroupText>
      </InputGroupAddon>
    </InputGroup>
  );
};

KeywordSearch.defaultProps = {
  isSearching: false,
  searchKey: 'keywords',
};
