import React, { FC, Dispatch, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { Modal, Group, Form } from '../shared';
import { catanapi } from '../../api';
import { useAuthToken, useToaster } from '../../hooks';
import {
  sendCloseSelected,
  Action as ModalAction,
  Status as ModalStatus,
  ModalState,
} from '../../hooks/useModalReducer';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import {
  Icon,
  PrimaryButton,
  SecondaryButton,
  Text,
} from '@fattmerchantorg/truffle-components';
import { formatCurrency } from '../../util';
import { Billing } from '@fattmerchantorg/types-engine/DB';
import { AuthStore } from '../../context';

interface ReserveFormValues {
  balance: string | null;
  company_name: string | null;
  billing_id: string | null;
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 16px;
  /* identical to box height, or 70px */

  text-align: center;
  .space {
    height: 16px;
    width: auto;
    display: inline-block;
  }
`;

const ButtonContainer = styled.div`
  position: relative;

  text-align: center;
  justify-content: bottom;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 16px;
  /* Gray/800 */

  background: #213745;
  border-radius: 0px 0px 2px 2px;
  .space {
    width: 16px;
    height: auto;
    display: inline-block;
  }
`;

export type EditModalContext = ReserveFormValues;

/** Initial state for `useModalReducer`. */
export const initialEditModalState: ModalState<EditModalContext> = {
  status: ModalStatus.CLOSED,
  context: { balance: null, company_name: null, billing_id: null },
};

const closeSelected = sendCloseSelected();

export const ReleaseReserveModal: FC<{
  balance?: string;
  company_name?: string;
  billing_id?: string;
  status: ModalStatus;
  modalDispatch: Dispatch<ModalAction>;
}> = ({ company_name, balance, status, billing_id, modalDispatch }) => {
  const authToken = useAuthToken();
  const { toaster, toast } = useToaster();
  const {
    state: { auth },
  } = useContext(AuthStore);
  const onClose = useCallback(
    () => modalDispatch(closeSelected),
    [modalDispatch]
  );
  const userId = auth?.user?.id;
  const onSubmit = useCallback(
    async formValues => {
      const billings: Partial<Billing> = {
        billing_id: formValues['billing_id'],
        reserve_flat_amount: 0,
        reserve_percent_volume: null,
        reserve_percent_days: null,
        updated_by: userId,
      };
      try {
        await catanapi.patch(authToken, `/billing`, { billings: [billings] });

        window.location.reload();
        toaster(
          toast.success(
            <Text as="p">
              You’ve released <b>{formatCurrency(balance)}</b> reserves from{' '}
              <b>{company_name}</b>{' '}
            </Text>,
            'RESERVE Released'
          )
        );
        modalDispatch(closeSelected);
      } catch (error) {
        toaster(toast.error(error, 'There was a problem saving your changes.'));
        modalDispatch(closeSelected);
      }
    },
    [authToken, toaster, toast, balance, company_name, modalDispatch, userId]
  );

  return (
    <Modal
      data-testid="release-resver-modal"
      isOpen={status === ModalStatus.OPEN}
      onClose={onClose}
      style={{ overflow: 'hidden' }}
      title={<strong>Release Reserve</strong>}
    >
      <Form<ReserveFormValues>
        onSubmit={onSubmit}
        initialValues={{ balance, company_name, billing_id }}
      >
        <Content>
          <Group direction="column">
            <Icon
              icon={faExclamationTriangle}
              style={{
                color: '#BDC9CC',
                width: '448px',
                height: '70px',
              }}
            />
            <div className={'space'}></div>
            <Text as="p">
              {' '}
              Are you sure you want to release reserves?
              <br /> A reserve of
              <strong> {formatCurrency(balance)}</strong> will be released to{' '}
              <strong>{company_name}</strong> during the next payout. Reserves
              will zero out and stop accruing.
            </Text>
          </Group>
        </Content>
        <ButtonContainer>
          <SecondaryButton type="button" onClick={onClose}>
            Cancel
          </SecondaryButton>
          <div className={'space'}></div>
          <PrimaryButton type="submit">
            Release {formatCurrency(balance)}
          </PrimaryButton>
        </ButtonContainer>
      </Form>
    </Modal>
  );
};
