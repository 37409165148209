import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const CurrencyIndicatorContainer = styled.div`
  margin-right: 16px;
  display: flex;
  align-items: center;

  > img {
    height: 16px;
  }

  > span {
    margin-left: 8px;
    line-height: 16px;
  }
`;

const CurrenciesIndicatorContainer = styled.div`
  display: flex;
`;

export const CurrencyIndicator: FunctionComponent<
  {
    currency: string;
  } & React.HTMLAttributes<HTMLDivElement>
> = props => {
  const { currency, ...divProps } = props;

  // don't display unknown currencies
  // TODO: support additional currencies as we add them
  if (!['USD', 'CAD'].includes(currency)) return null;

  return (
    <CurrencyIndicatorContainer {...divProps}>
      <img
        src={require(`../../../assets/img/icons/currencies/${currency}.png`)}
        alt={`${currency} icon`}
      />
      <span>{currency}</span>
    </CurrencyIndicatorContainer>
  );
};

export const CurrenciesIndicator: FunctionComponent<
  {
    currencies: string[];
    indicatorsProps?: React.HTMLAttributes<HTMLDivElement>;
  } & React.HTMLAttributes<HTMLDivElement>
> = props => {
  const { currencies, indicatorsProps = {}, ...divProps } = props;

  return (
    <CurrenciesIndicatorContainer {...divProps}>
      {currencies.map((currency, i) => (
        <CurrencyIndicator currency={currency} key={i} {...indicatorsProps} />
      ))}
    </CurrenciesIndicatorContainer>
  );
};
