import { useState, useEffect, useCallback } from 'react';

function getSize() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile: window.innerWidth < 1000
  };
}

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getSize);

  const handleResize = useCallback(() => {
    setWindowSize(getSize());
  }, [setWindowSize]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return windowSize;
}
