import React, { FunctionComponent } from 'react';
import { FundingAccount, DepositDetail } from '@fattmerchantorg/types-omni';
import { Text } from '@fattmerchantorg/truffle-components';
import {
  StyledDrawer,
  DrawerContainer,
  ActionsContainer,
  FundingAccountsContainer,
  DateText,
  Separator,
  PaymentMethodContainer,
  DetailsHeader,
  PaymentDetails,
  TransactionsText,
} from './DepositDetailModalStyles';
import { formatCurrency } from '../../../util/format.util';
import CopyIdsDropdown from './DepositDetailCopyIdsDropdown';
import { renderTimestamp } from '../../funding-accounts/util/BankAccountsPage.util';
import { getNameWithLastFour } from '../../../util/string.util';

type ExtendedDepositDetail = DepositDetail & { settlement_id?: string | null };

export interface TransactionDetails {
  id: string;
  transaction_id: string;
  invoice_id: string | null;
  engine_settlement_id: string | null;
  engine_transaction_id: string | null;
  account_id: string | null;
  amount: number | null;
  memo: string | null;
  meta: string | null;
  created_at: string;
  updated_at: string | null;
  deleted_at: string | null;
}

/** Props accepted by the DetailsModal component */
type DepositDetailsDrawerProps = {
  isDrawerOpen: boolean;
  setIsDrawerOpen: (boolean) => void;
  selectedDeposit: ExtendedDepositDetail | null | any;
  transactionDetails: TransactionDetails[];
  fundingAccounts: FundingAccount[];
  drawerRef?: React.LegacyRef<HTMLDivElement> | null;
};

export const DepositDetailsDrawer: FunctionComponent<
  DepositDetailsDrawerProps
> = props => {
  const {
    isDrawerOpen,
    setIsDrawerOpen,
    selectedDeposit,
    transactionDetails,
    fundingAccounts,
    drawerRef,
  } = props;

  const generateDrawerContent = (
    deposit: ExtendedDepositDetail | any,
    transactionDetails: TransactionDetails[]
  ) => {
    return (
      <>
        <DrawerContainer>
          <Text as="h4">
            <strong>{formatCurrency(deposit.total)}</strong>
          </Text>
          <ActionsContainer>
            <CopyIdsDropdown
              depositId={deposit.settlement_id}
              transactionId={deposit.transaction_id}
            />
          </ActionsContainer>
        </DrawerContainer>

        <FundingAccountsContainer>
          <DateText as="p">
            <strong>Date:</strong>
            {` ${renderTimestamp(deposit.created_at, 'MM/dd/yy | h:mm:ss a')}`}
          </DateText>
        </FundingAccountsContainer>

        <Separator />

        {transactionDetails.length > 0 && (
          <>
            <PaymentMethodContainer>
              <DetailsHeader as="h6">
                <strong>Deposit Account</strong>
              </DetailsHeader>
              <PaymentDetails>
                <TransactionsText as="p">
                  <strong>Bank Account Name</strong>
                </TransactionsText>
                <TransactionsText as="p">
                  <strong>Amount</strong>
                </TransactionsText>
              </PaymentDetails>
              {transactionDetails.map((transaction, index) => (
                <PaymentDetails key={index}>
                  <TransactionsText as="p">
                    {getNameWithLastFour(
                      fundingAccounts?.find(
                        a => a.id === transaction?.account_id
                      )
                    )}
                  </TransactionsText>
                  <TransactionsText as="p">
                    {formatCurrency(transaction.amount)}
                  </TransactionsText>
                </PaymentDetails>
              ))}
            </PaymentMethodContainer>
          </>
        )}

        <Separator />
      </>
    );
  };

  return (
    <StyledDrawer
      anchor="right"
      title="Deposits - Transaction Details"
      data-testid="deposit-transaction-details-drawer"
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
      customStyles={{
        borderRadius: 0,
        padding: 0,
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
      zIndex={999}
    >
      <div ref={drawerRef}>
        {isDrawerOpen &&
          generateDrawerContent(selectedDeposit, transactionDetails)}
      </div>
    </StyledDrawer>
  );
};
