import React, { useMemo } from 'react';
import { useTable, useSortBy, Column } from 'react-table';
import { useHistory } from 'react-router-dom';
import {
  TableBody,
  TableCell,
  TableRow,
  TableWrapper,
} from '@fattmerchantorg/truffle-components';
import { LoadingSpan } from '../../shared';
import { StyledTable } from '../SettingsPagesStylings';
import { RoleDetails } from './RoleDetails';

const COLUMN_DATA: Column<RoleDetails>[] = [
  {
    accessor: 'role_name',
    Cell: cell => (
      <>
        <div>
          <strong>{cell.value}</strong>
        </div>
        <div style={{ marginTop: 8 }}>{cell.row.original.description}</div>
      </>
    ),
  },
  {
    accessor: 'user_count',
    Cell: cell =>
      cell.value === 1
        ? '1 user associated'
        : `${cell.value ?? 0} users associated`,
  },
];

const COLUMN_STYLES = {
  role_name: {
    padding: '16px',
  },
  user_count: {
    padding: '16px',
    textAlign: 'right',
    verticalAlign: 'baseline',
  },
};

type RolesTableProps = {
  roles: RoleDetails[];
  isLoading?: boolean;
};

export const RolesTable = ({ roles, isLoading }: RolesTableProps) => {
  const history = useHistory();
  const rowData = useMemo(() => roles, [roles]);

  const { getTableProps, getTableBodyProps, prepareRow, rows } =
    useTable<RoleDetails>(
      {
        columns: COLUMN_DATA,
        data: rowData,
      },
      useSortBy
    );

  return (
    <TableWrapper>
      <StyledTable {...getTableProps()}>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row, index) => (
            <React.Fragment key={index}>
              {isLoading
                ? (prepareRow(row),
                  (
                    <TableRow {...row.getRowProps()} key={index}>
                      {row.cells.map((cell: any, index) => {
                        const style = COLUMN_STYLES[cell.column.id] ?? {};
                        return (
                          <TableCell
                            {...cell.getCellProps({ style })}
                            key={index}
                          >
                            <LoadingSpan
                              width={
                                style.width ? style.width : cell.column.width
                              }
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))
                : (prepareRow(row),
                  (
                    <TableRow {...row.getRowProps()} key={index}>
                      {row.cells.map((cell: any, index) => {
                        const style = COLUMN_STYLES[cell.column.id] ?? {};
                        return (
                          <TableCell
                            {...cell.getCellProps({ style })}
                            onClick={() => {
                              history.push(
                                `/settings/roles/id/${row.original.id}`
                              );
                            }}
                            key={index}
                          >
                            {cell.render('Cell')}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
            </React.Fragment>
          ))}
        </TableBody>
      </StyledTable>
    </TableWrapper>
  );
};
