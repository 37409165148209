import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const RegularStyles = styled.div`
  font-family: 'Roboto';
`;

export const RegularText: FunctionComponent<{
  color?: string;
  fontSize?: number;
  marginBottom?: number;
}> = ({ color = '#111E52', fontSize = 14, marginBottom = 16, children }) => {
  return (
    <RegularStyles style={{ color, fontSize, marginBottom }}>
      {children}
    </RegularStyles>
  );
};
