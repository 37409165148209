import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router';
import {
  Card,
  Container,
  Row,
  Col,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { Button } from './../Button';
import {
  cleanCollectionQuery,
  Collection,
  CollectionQuery,
  CollectionData,
  CollectionProps,
} from '../Collection';
import { history } from '../../../history';
import { parse, stringify } from '../../../util/api.util';
import { omit } from '../../../util/object.util';
import styled from 'styled-components';
import { KeywordSearch } from '../KeywordSearch/KeywordSearch';
import { SearchCollectionProps } from './SearchCollection.types';
import { ExportButton } from '../../exports/ExportButton';
import { Icon } from '@fattmerchantorg/truffle-components';
import { useSearchState } from '../../../hooks';
import { DateInput } from '../DateInput';
import { formatDateForDateInput } from '../../../util/date.util';
import { format, subDays } from 'date-fns';

const SearchContainer = styled.div`
  padding-bottom: 1rem;
  width: 24rem;
  @media (max-width: 1000px) {
    padding: 0.5rem;
    width: 100%;
  }
`;

export const FilterButton = styled.div`
  align-items: center;
  float: right;
  background-color: #004166;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 32px;
  justify-content: center;
  min-width: 90px;
  width: auto;
  padding: 6px 8px;
  line-height: 20px;
  margin-top: 14px;
  margin-left: 1rem;

  span {
    color: #009bf2;

    &:not(:first-child) {
      font-weight: bold;
      margin-left: 4px;
    }
  }
`;

export const CollectionWrapper = styled(Card)`
  width: 100%;
  border: 0;
  border-radius: 0px;
  background-color: ${({ theme }) => theme.colors.core.gray[800].hex};

  @media (min-width: 1000px) {
    margin-bottom: 1rem;
  }
`;

const StyledCol = styled(Col)`
  padding-left: 0px;
  padding-right: 0px;
  min-width: 385px;
`;

const StyledColDate = styled(Col)`
  padding-left: 10px;
  padding-right: 0px;
`;

const StyledClearButtonCol = styled(StyledCol)`
  float: right;
  margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
  background-color: #ffffff;
  color: #575757;
  font-size: 14px;
  height: 37px;
  margin-top: 0.5rem;
  margin-left: 16px;
  float: right;
  @media (max-width: 1200px) {
    margin: 0.5rem;
  }
  :hover {
    background-color: #ffffff;
    color: #575757;
  }
`;

const StyledInputGroupText = styled(InputGroupText)`
  background-color: ${({ theme }) => theme.colors.core.green[400].hex};
  i {
    color: ${({ theme }) => theme.black};
  }
`;

// removes URL parameters from the URL
const splitUrl = hash => {
  let urlArray = hash.split('?');
  let hashUrlArray = urlArray[0].split('#');
  return hashUrlArray[1];
};

export function SearchCollection<T extends object>(
  props: SearchCollectionProps<T>
): JSX.Element {
  const {
    containerProps = {},
    showSearchBar,
    showExportButton,
    hideClearButton = false,
    filterModal,
    filterAccordion,
    tableHeader,
    ...collectionProps
  } = props;

  const { search, pathname } = useLocation();

  const shouldShowDateFilter = pathname.endsWith('/transactions');

  const [status, setStatus] = useState<'loading' | 'idle' | 'error'>('loading');
  const [numberOfLoadingItems, setNumberOfLoadingItems] = useState(
    collectionProps?.defaultPageSize || 20
  );
  const [exportDisabled, setExportDisabled] = useState<boolean>(true);
  const [isSearchAccordionOpen, setIsSearchAccordionOpen] =
    useState<boolean>(false);
  const [searchState] = useSearchState();

  const query = useMemo((): CollectionQuery => {
    const parsedSearch = parse(search);
    // append dates to the query ONLY we're on transactions route. This is a hack to get around the fact that we're using the same collection component for multiple routes. Most of those routes don't expect dates filters, resulting to failing tests.
    if (shouldShowDateFilter) {
      let startDate = parsedSearch.startDate;
      let endDate = parsedSearch.endDate;
      if (!startDate && !endDate) {
        const sevenDaysAgo = subDays(new Date(), 7);
        startDate = format(sevenDaysAgo, 'yyyy-MM-dd');
        endDate = format(new Date(), 'yyyy-MM-dd');
      }

      return {
        ...parsedSearch,
        startDate,
        endDate,
      };
    }
    return parsedSearch;
  }, [search, shouldShowDateFilter]);

  const activeFiltersCount = useMemo(() => {
    return Object.keys(
      omit(
        Object.assign({}, searchState),
        'page',
        'per_page',
        'perPage',
        'sortBy',
        'sort',
        'order',
        'NoCache',
        'is_sandbox'
      )
    ).length;
  }, [searchState]);

  useEffect(() => {
    if (activeFiltersCount > 0) setIsSearchAccordionOpen(true);
  }, [activeFiltersCount]);

  const shouldShowAccordionToggle = filterAccordion ? true : false;

  const handleQueryChange = useCallback(
    (newQuery: Partial<CollectionQuery> = {}) => {
      const newSearch = stringify(
        cleanCollectionQuery({ ...query, ...newQuery })
      );
      history.push(`${pathname}?${newSearch}`);
    },
    [pathname, query]
  );

  const handleReceiveData = useCallback(
    (data: CollectionData<T>) => {
      if (data.per_page) setNumberOfLoadingItems(data.per_page);
      if (showExportButton) {
        setExportDisabled(data.data?.length < 1);
      } else {
        setExportDisabled(true);
      }
    },
    [showExportButton]
  );

  const handleSearch = useCallback(
    (keywords: CollectionQuery['keywords']) => {
      handleQueryChange({ keywords, page: 0 });
    },
    [handleQueryChange]
  );

  /**
   * IF query params ONLY includes 'order' or blacklisted items, don't show Clear Filters
   * ELSE if there are other query params, show Clear Filter
   */
  const shouldShowClear = (hideClearButton: boolean = false) => {
    if (hideClearButton) {
      return false;
    }
    try {
      const params = new URLSearchParams(search);

      if (collectionProps.blacklist?.length) {
        collectionProps.blacklist.forEach(key => {
          params.delete(String(key));
        });
      }

      if (params.has('order') && Array.from(params).length === 1) {
        return false;
      }
      return Array.from(params).length > 0;
    } catch (e) {
      console.error(e);
      return window.location.hash.includes('?');
    }
  };

  return (
    <Container fluid {...containerProps}>
      <Row>
        <StyledCol>
          {showSearchBar && (
            <SearchContainer data-testid="search-container">
              <KeywordSearch
                isSearching={status === 'loading'}
                onSearch={handleSearch}
              />
            </SearchContainer>
          )}
        </StyledCol>

        {shouldShowDateFilter ? (
          <>
            <StyledColDate>
              <DateInput
                style={{ borderRadius: '0.375rem' }}
                label="Start Date"
                value={formatDateForDateInput(query.startDate) || ''}
                onChange={e => {
                  const startDate = e.currentTarget.value;
                  handleQueryChange({ startDate });
                }}
              />
            </StyledColDate>

            <StyledColDate>
              <DateInput
                style={{ borderRadius: '0.375rem' }}
                label="End Date"
                value={formatDateForDateInput(query.endDate) || ''}
                onChange={e => {
                  const endDate = e.currentTarget.value;
                  handleQueryChange({ endDate });
                }}
              />
            </StyledColDate>

            <StyledColDate xs={6} sm={1} md={1} lg={1}>
              <InputGroupAddon addonType="prepend" style={{ height: '75%' }}>
                <StyledInputGroupText>
                  <i className="fas fa-search" />
                </StyledInputGroupText>
              </InputGroupAddon>
            </StyledColDate>
          </>
        ) : null}

        <StyledClearButtonCol>
          {shouldShowClear(hideClearButton) && (
            <StyledButton
              size="small"
              onClick={() => history.push(`${splitUrl(window.location.hash)}`)}
              data-testid="clear-filters-button"
            >
              <i className="fas fa-filter" style={{ paddingRight: 10 }} />
              <span>CLEAR FILTERS</span>
            </StyledButton>
          )}
          {filterModal}
          {shouldShowAccordionToggle && (
            <FilterButton
              onClick={() => setIsSearchAccordionOpen(!isSearchAccordionOpen)}
            >
              <Icon icon={['fas', 'filter']} />
              <span>
                Filters {activeFiltersCount > 0 && `(${activeFiltersCount})`}
              </span>
            </FilterButton>
          )}
          {showExportButton && <ExportButton disabled={exportDisabled} />}
        </StyledClearButtonCol>
      </Row>
      {tableHeader}

      <Row>{isSearchAccordionOpen && filterAccordion}</Row>

      <Row>
        <CollectionWrapper>
          <Collection<T>
            {...(collectionProps as CollectionProps<T>)}
            query={query}
            onReceiveData={handleReceiveData}
            onStatusChange={setStatus}
            numberOfLoadingItems={numberOfLoadingItems}
          />
        </CollectionWrapper>
      </Row>
    </Container>
  );
}

SearchCollection.defaultProps = {
  paginated: true,
  showSearchBar: true,
  showExportButton: false,
};
