import React, { FunctionComponent } from 'react';
import { Merchant } from '@fattmerchantorg/types-omni';
import { BaseModal, ModalHeader } from '@fattmerchantorg/truffle-components';
import styled from 'styled-components';

import { CustomerInfoWidget } from './CustomerInfoWidget';
import { formatCurrency } from '../../../util/format.util';
import { LoadingSpan, StatusPanel } from '../../shared';
import {
  TransactionDetailReducerState,
  TransactionDetailReducerDispatch,
} from './useDetailsModalHook';

export type TransactionReturnModalProps = {
  onClose: () => void;
  isOpen: boolean;
  state: TransactionDetailReducerState;
  dispatch: TransactionDetailReducerDispatch;
  merchant: Merchant;
};

type TransactionReturnModalTopSectionProps = Omit<
  TransactionReturnModalProps,
  'isOpen' | 'dispatch'
> & {
  title: string;
  infoPanelLabel: string;
};

export const TransactionReturnModalButtonGroup = styled.div`
  padding-top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  > *:not(:last-child) {
    margin-right: 8px;
  }
`;

const ContentSection = styled.div`
  padding: 16px;
  text-align: right;

  > *:not(:first-child) {
    padding-top: 8px;

    &:nth-child(2) {
      padding-top: 40px;
    }
  }

  label {
    margin-bottom: 0px;
  }
`;

const TotalSection = styled.div`
  text-align: right;
  display: flex;
  flex-direction: row-reverse;

  > div > span {
    font-weight: bold;
  }
`;

export const TransactionReturnModalContent: FunctionComponent<
  TransactionReturnModalTopSectionProps
> = props => {
  const { children, onClose, state, title, infoPanelLabel } = props;
  const transaction = state.data;
  const loading = !transaction;

  return (
    <>
      <ModalHeader title={title} onClose={onClose} />
      <div>
        <StatusPanel status="info" animated={false}>
          {infoPanelLabel}
        </StatusPanel>
        <ContentSection>
          <CustomerInfoWidget
            customer={transaction?.customer}
            loading={loading}
            rows={['name', 'company']}
          />
          <TotalSection>
            <div>
              <label htmlFor="total">Total</label>
              {loading ? (
                <LoadingSpan height="20px" width="60px" />
              ) : (
                <div>
                  <span id="total">{formatCurrency(transaction?.total)}</span>
                </div>
              )}
            </div>
          </TotalSection>
          {transaction?.total_refunded > 0 ? (
            <TotalSection>
              <div>
                <label htmlFor="total">Previously Refunded</label>
                {loading ? (
                  <LoadingSpan height="20px" width="60px" />
                ) : (
                  <div>
                    <span id="total">
                      {formatCurrency(transaction?.total_refunded)}
                    </span>
                  </div>
                )}
              </div>
            </TotalSection>
          ) : null}
          {children}
        </ContentSection>
      </div>
    </>
  );
};

export const TransactionReturnModal: FunctionComponent<
  Omit<TransactionReturnModalProps, 'state' | 'dispatch'>
> = props => {
  return (
    <BaseModal
      {...props}
      onRequestClose={props.onClose}
      style={{
        content: {
          width: 'auto',
          maxWidth: '95%',
          minWidth: '40%',
          gridTemplateRows: '48px 1fr',
        },
      }}
    />
  );
};
