import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  //margin: 1rem;
  grid-gap: 0.75rem;
`;

export const TitleContainer = styled.div`
  display: grid;
  grid-gap: 0.75rem;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const FilterButton = styled.div`
  align-items: center;
  background-color: #004166;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 32px;
  justify-content: center;
  min-width: 110px;
  padding: 7px 12px;
  margin-left: 1rem;

  span {
    color: #009bf2;

    &:not(:first-child) {
      font-weight: bold;
      margin-left: 4px;
    }
  }
`;
