import React, { FunctionComponent } from 'react';
import { Merchant } from '@fattmerchantorg/types-omni';
import { formatCapitalCase } from '../../../../util/format.util';
import { Pill, PillProps } from '../../../shared';

function motifForStatus(status: Merchant['status']): PillProps['motif'] {
  switch (status) {
    case 'ACTIVE':
      return 'success';
    case 'INACTIVE':
      return 'error';
    case 'PENDING':
      return 'warning';
    default:
      return null;
  }
}

export const ImporterMerchantStatusPill: FunctionComponent<
  Omit<PillProps, 'motif'> & { status: Merchant['status'] }
> = props => {
  const { status, ...pillProps } = props;

  return (
    <Pill motif={motifForStatus(status)} {...pillProps}>
      <i className="fas fa-circle" />
      {formatCapitalCase(status)}
    </Pill>
  );
};
