import React, { Context, createContext, useReducer, Reducer } from 'react';
import { User, Merchant } from '@fattmerchantorg/types-omni';
import {
  AuthContextProps,
  AuthContext,
  AuthAction,
  AuthState,
  AuthActionType,
  AuthData,
  AuthEffectType,
  Auth,
  AuthMode,
  OptimisticUIStatuses,
  SupportAuthData,
} from '../types/Auth.types';
import {
  updateAuthWithRedirect,
  updateAuthWithDialog,
  updateOptimisticUIStatus,
  updateAuth,
} from '../actions/Auth.actions';
import { authapi, TestModeToggleResponse, coreapi } from '../../../api';
import { useAsyncEffect } from '../../../hooks';

const AuthStore = createContext({} as AuthContext) as Context<AuthContext>;

const getInitialState = (): AuthState => {
  try {
    let savedAuth = JSON.parse(localStorage.getItem('omniconnect.authdata'));
    let savedIndex = +localStorage.getItem('omniconnect.authindex');
    let savedMerchantToken = localStorage.getItem(
      'omniconnect.selectedMerchantToken'
    );

    if (savedAuth && typeof savedAuth === 'object') {
      const auth = savedAuth as Auth & { supportToken: string | null };
      return {
        auth: auth,
        index: savedIndex || 0,
        authToken: auth.token,
        supportToken: auth.supportToken,
        merchantToken: savedMerchantToken ?? null,
        status: 'stale',
        asyncRequestStatus: 'INITIAL',
        effect: null,
        optimisticUIStatus: OptimisticUIStatuses.Ok,
      };
    } else {
      throw new Error('unable to hydrate AuthStore from localStorage');
    }
  } catch (error) {
    return {
      index: 0,
      auth: null,
      authToken: null,
      supportToken: null,
      merchantToken: null,
      status: 'notauthed',
      asyncRequestStatus: 'INITIAL',
      effect: null,
      optimisticUIStatus: OptimisticUIStatuses.Ok,
    };
  }
};

const initialState = getInitialState();

type AuthReducer = Reducer<AuthState, AuthAction>;

const authReducer: AuthReducer = (state, action) => {
  switch (action.type) {
    case AuthActionType.UPDATE_MERCHANT_TOKEN: {
      return { ...state, merchantToken: action.payload };
    }
    case AuthActionType.UPDATE_AUTH:
    case AuthActionType.UPDATE_AUTH_WITH_DIALOG:
    case AuthActionType.UPDATE_AUTH_WITH_REDIRECT: {
      const data = action.payload as AuthData;
      const index = 0;
      const { merchant, user, token } = data[index];
      const brand = (merchant && merchant.brand) || (user && user.brand);

      return {
        ...state,
        status: 'fresh',
        authToken: token,
        auth: { data, index, merchant, user, token, brand },
        asyncRequestStatus: 'IDLE',
      };
    }
    case AuthActionType.UPDATE_AUTH_USER: {
      const user = action.payload as User;
      return {
        ...state,
        auth: { ...state.auth, user },
      };
    }
    case AuthActionType.UPDATE_AUTH_MERCHANT: {
      const merchant = action.payload as Merchant;
      return {
        ...state,
        auth: { ...state.auth, merchant },
      };
    }
    case AuthActionType.REMOVE_AUTH: {
      return { ...state, auth: null, status: 'notauthed' };
    }
    case AuthActionType.UPDATE_AUTH_INDEX: {
      const data = state.auth.data;
      const index = action.payload as number;
      const { merchant, user, token } = data[index];
      const brand = (merchant && merchant.brand) || (user && user.brand);

      return {
        ...state,
        auth: { data, index, merchant, user, token, brand },
      };
    }
    case AuthActionType.UPDATE_OPTIMISTIC_UI_STATUS: {
      return {
        ...state,
        optimisticUIStatus: action.payload,
      };
    }
    case AuthActionType.UPDATE_SUPPORT_AUTH_TOKEN:
      const supportAuth: SupportAuthData = action.payload;
      const supportToken = supportAuth?.[0]?.token;
      return {
        ...state,
        supportToken,
        effect: {
          type: AuthEffectType.UPDATE_SUPPORT_AUTH_TOKEN,
          payload: supportToken,
        },
      };
    default:
      return state;
  }
};

const effectReducer: AuthReducer = (state, action) => {
  switch (action.type) {
    case AuthActionType.UPDATE_AUTH_WITH_REDIRECT:
      // When we receive a new authset,
      // We want to do a side-effect of a page redirect
      // because there may be stale data on the page the user is currently on
      return {
        ...state,
        effect: {
          type: AuthEffectType.UPDATE_AUTH_WITH_REDIRECT,
          payload: state.auth,
        },
      };
    case AuthActionType.UPDATE_AUTH_WITH_DIALOG:
      // When we receive a new authset because we changed auth mode
      // show a dialog prompt to the user
      return {
        ...state,
        effect: {
          type: AuthEffectType.UPDATE_AUTH_WITH_DIALOG,
          payload: state.auth,
        },
      };
    case AuthActionType.UPDATE_AUTH:
    case AuthActionType.UPDATE_AUTH_USER:
    case AuthActionType.UPDATE_AUTH_MERCHANT:
      // Don't do a page redirect, since we assume the user just logged in or refreshed the page.
      return {
        ...state,
        effect: {
          type: AuthEffectType.UPDATE_AUTH,
          payload: state.auth,
        },
      };
    case AuthActionType.UPDATE_AUTH_INDEX:
      return {
        ...state,
        effect: {
          type: AuthEffectType.UPDATE_AUTH_INDEX,
          payload: state.auth,
        },
      };
    case AuthActionType.REMOVE_AUTH:
      return {
        ...state,
        effect: {
          type: AuthEffectType.REMOVE_AUTH,
        },
      };

    case AuthActionType.USER_CLICKED_MODE_TOGGLE_SWITCH: {
      // If a request is already in-flight, don't issue another one.
      if (state.asyncRequestStatus === 'LOADING') return state;

      return {
        ...state,
        asyncRequestStatus: 'LOADING',
        effect: {
          type: AuthEffectType.USER_CLICKED_MODE_TOGGLE_SWITCH,
        },
      };
    }

    case AuthActionType.USER_SELECTED_MERCHANT_OUTSIDE_MODE: {
      // If a request is already in-flight, don't issue another one.
      if (state.asyncRequestStatus === 'LOADING') return state;

      return {
        ...state,
        asyncRequestStatus: 'LOADING',
        effect: {
          type: AuthEffectType.USER_SELECTED_MERCHANT_OUTSIDE_MODE,
        },
      };
    }

    case AuthActionType.UPDATE_SUPPORT_AUTH_TOKEN:
      return {
        ...state,
        effect: {
          type: AuthEffectType.UPDATE_SUPPORT_AUTH_TOKEN,
          payload: state.supportToken,
        },
      };

    default: {
      return { ...state };
    }
  }
};

const reduceReducers =
  (...reducers) =>
  (state, action) => {
    return reducers.reduce(
      (nextState, reducer) => reducer(nextState, action),
      state
    );
  };

const AuthProvider = (props: AuthContextProps) => {
  const [state, dispatch] = useReducer<React.Reducer<AuthState, AuthAction>>(
    reduceReducers(authReducer, effectReducer),
    initialState
  );

  const { effect } = state;

  useAsyncEffect(async () => {
    const effectType = effect && effect.type;

    switch (effectType) {
      case AuthEffectType.UPDATE_AUTH:
      case AuthEffectType.UPDATE_AUTH_WITH_REDIRECT:
      case AuthEffectType.UPDATE_AUTH_WITH_DIALOG:
        localStorage.setItem(
          'omniconnect.authdata',
          JSON.stringify(effect.payload)
        );
        // The redirect will actually be completed by the SelectedMerchantContext
        // The dialog will be handled by TestModeContext

        break;
      case AuthEffectType.REMOVE_AUTH:
        localStorage.removeItem('omniconnect.authindex');
        localStorage.removeItem('omniconnect.authdata');
        localStorage.removeItem('omniconnect.selectedBrandName');

        break;
      case AuthEffectType.UPDATE_AUTH_INDEX:
        localStorage.setItem('omniconnect.authindex', effect.payload.index);
        localStorage.setItem(
          'omniconnect.authdata',
          JSON.stringify(effect.payload)
        );
        break;

      case AuthEffectType.USER_CLICKED_MODE_TOGGLE_SWITCH:
      case AuthEffectType.USER_SELECTED_MERCHANT_OUTSIDE_MODE:
        try {
          dispatch(updateOptimisticUIStatus(OptimisticUIStatuses.Loading));

          const {
            tokens: [newToken],
          }: TestModeToggleResponse = await authapi.post(
            state.authToken,
            '/test-mode-toggle?returnType=token'
          );

          const authset: AuthData = await coreapi.get(
            newToken, // Use the new token to fetch full authset
            '/self/list'
          );

          dispatch(updateOptimisticUIStatus(OptimisticUIStatuses.Ok));

          if (effectType === AuthEffectType.USER_CLICKED_MODE_TOGGLE_SWITCH) {
            // When a user clicks the mode toggle switch, we need to change their mode and re-fetch auth.
            // At that point, all app data is stale, so we also need to clear selected merchant, and redirect home.
            dispatch(updateAuthWithRedirect(authset));
          } else if (
            effectType === AuthEffectType.USER_SELECTED_MERCHANT_OUTSIDE_MODE
          ) {
            // When a user is viewing a merchant outside their current mode,
            // we only need to update their mode (don't clear selected merchant and redirect)
            dispatch(updateAuthWithDialog(authset));
          }
        } catch (err) {
          /*
           * If generating new JWT throws an error, we need to bring in the last successful authdata back into context.
           * Without this step, the authdata's "asyncRequestStatus" remains forever as "loading".
           */
          const savedAuth = JSON.parse(
            localStorage.getItem('omniconnect.authdata')
          );
          dispatch(updateAuth([savedAuth]));

          // Handle optimistic UI.
          dispatch(updateOptimisticUIStatus(OptimisticUIStatuses.Undo));
        }
        break;
      default:
        break;
    }
  }, [effect]);

  const localContext: AuthContext = {
    state,
    dispatch,
  };

  return (
    <AuthStore.Provider value={localContext}>
      {props.children}
    </AuthStore.Provider>
  );
};

/**
 * Using the auth response, determines whether an authenticated user is in test mode or live mode.
 */
export function deriveAuthMode(authState: AuthState): AuthMode {
  if (authState.status === 'notauthed' || !authState.auth)
    // We don't know the user's mode until after authentication.
    return 'NOT_AUTHED';

  if (!authState.auth?.user || !authState.auth?.user.brand)
    // We need the user's brand to determine the mode.
    // If brand is missing, default to test mode as a precaution.
    return 'TEST_MODE';

  // A user is in test mode if the user's brand contains the suffix -sandbox
  return authState.auth?.user.brand.includes('-sandbox')
    ? 'TEST_MODE'
    : 'LIVE_MODE';
}

export { AuthStore, AuthProvider };
