import styled, { withTheme } from 'styled-components';

export const BPSettingsRow = withTheme(
  styled.div`
    display: grid;
    grid-template-columns: 0.56fr 1fr 0.71fr; // Based upon relative px values in the designs
    grid-template-rows: 1fr;
    padding: 16px 0;
    margin: 0 24px;
    border-bottom: 1px solid ${({ theme }) => theme.lightNeutralOutline};

    &[data-highlight] {
      background-color: ${({ theme }) => theme.colors.core.gray[800].hex};
      border-bottom: none;
      margin: 0;
      padding: 16px 24px;
    }

    &:last-child {
      border-bottom: none;
    }

    > div {
      font-size: 14px;
      line-height: 20px;
    }

    // @TODO: this overrides an intrusive global style which conflict with
    // Truffle labels
    label {
      margin-bottom: 0;
    }
  `
);

export const BPSettingsRowTitle = withTheme(
  styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    min-width: 255px;
    padding: 0 24px 0 0;

    > h3 {
      width: 100%;
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
      color: ${({ theme }) => theme.white};
      margin: 0;
    }
  `
);

export const FieldWrap = withTheme(
  styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    input::placeholder {
      color: ${({ theme }) => theme.disabledTextColor};
    }
    label {
      margin-bottom: 8px;
    }
  `
);

export const SubCol = styled.div`
  width: 100%;
`;

export const TwoCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  > ${SubCol} {
    padding: 0 24px;
    border-right: 1px solid ${({ theme }) => theme.lightNeutralOutline};

    &:first-child {
      padding: 0 24px 0 0;
      border-left: none;
    }
    &:last-child {
      padding: 0 0 0 24px;
      border-right: none;
    }
  }
`;

export const ThreeCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  > ${SubCol} {
    padding: 0 24px;
    border-right: 1px solid ${({ theme }) => theme.lightNeutralOutline};

    &:first-child {
      padding: 0 24px 0 0;
      border-left: none;
    }
    &:last-child {
      padding: 0 0 0 24px;
      border-right: none;
    }
  }
`;

export const BPSettingsRowFields = withTheme(
  styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 400px;
    padding: 16px 24px;
    border-left: 1px solid ${({ theme }) => theme.lightNeutralOutline};

    ${TwoCol} + ${ThreeCol},
    ${ThreeCol} + ${TwoCol} {
      margin: 24px 0 0 0;
      padding: 24px 0 0 0;
      border-top: 1px solid ${({ theme }) => theme.lightNeutralOutline};
    }
  `
);

export const BPSettingsRowDescription = withTheme(
  styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    min-width: 320px;
    padding: 16px 24px;
    border-left: 1px solid ${({ theme }) => theme.lightNeutralOutline};

    span,
    p {
      font-size: 16px;
      line-height: 24px;
    }

    strong {
      font-weight: 700;
    }
  `
);
