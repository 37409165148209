import React from 'react';
import {
  Icon,
  SmallPrimaryButton,
  Text,
} from '@fattmerchantorg/truffle-components';
import styled from 'styled-components';
import { useStatementLoadingState } from '../../../../context/statements';

const ErrorIcon = styled(Icon)`
  font-size: 64px;
  color: ${({ theme }) => theme.colors.core.gray[400].hex};
` as typeof Icon;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
`;

export const ErrorRetry = () => {
  const { setAllLoading } = useStatementLoadingState();

  return (
    <Container>
      <ErrorIcon icon={['far', 'exclamation-circle']} />
      <Text as="span">
        Looks like we ran into an issue loading your fee statement. Please try
        again.
      </Text>
      <SmallPrimaryButton
        id="fee-statement-retry-button"
        data-testid="fee-statement-retry-button"
        icon={['fas', 'sync']}
        // set all the loading states back to LoadingState.Loading
        // which will trigger the useEffects in the various sections to fetch the data again
        onClick={setAllLoading}
      >
        Retry
      </SmallPrimaryButton>
    </Container>
  );
};
