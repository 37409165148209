import { useEffect, useContext } from 'react';
import {
  AuthStore,
  deriveAuthMode,
  PermissionsStore,
  SelectedMerchantStore,
} from '../context';
import {
  isAllBrandsSelected,
  calculateSelectedBrand,
} from '../util/brandSwitcher.util';
import {
  loadPendoLibrary,
  initializePendo,
  PendoVisitor,
  PendoAccount,
} from '../util/tracking.util';

export function usePendo() {
  const domain = (window.location.origin || '').toLowerCase();
  const isQaOrOrPreProd =
    domain.includes('qa-') ||
    domain.includes('qabuilds') ||
    domain.includes('preprod') ||
    domain.includes('sandbox');

  useEffect(() => {
    if (isQaOrOrPreProd) return; // Don't enable Pendo in lower envs
    loadPendoLibrary();
  }, [isQaOrOrPreProd]);

  const { state: authState } = useContext(AuthStore);
  const { state: permissions } = useContext(PermissionsStore);
  const { state: selectedMerchantState } = useContext(SelectedMerchantStore);
  useEffect(() => {
    if (isQaOrOrPreProd) return; // Don't enable Pendo in lower envs
    if (!(authState && permissions)) {
      return;
    }
    const {
      auth: { user, merchant },
    } = authState;
    const { id: userId, email, name } = user;
    const findSelectedBrand = () => {
      const brandName = calculateSelectedBrand(
        permissions?.brands,
        user?.brand,
        selectedMerchantState?.merchant?.brand,
        selectedMerchantState?.selectedBrandSwitcherOption
      );
      return permissions?.brands?.find(brand => brand.name === brandName);
    };
    const visitor: PendoVisitor = {
      userId,
      name,
      email,
      timezone: merchant.options?.timezone,
      dashboardPermission: permissions.permissions?.godview?.omniConnDashboard,
      submerchantPermission:
        permissions.permissions?.godview?.accessSubmerchants,
      devModePermission: permissions.permissions?.godview?.testModeToggle,
      isTestMode: deriveAuthMode(authState) === 'TEST_MODE',
      connectPersona: user.options?.connect_persona,
    };
    const brand = findSelectedBrand();
    const account: PendoAccount = {
      brandId: brand?.id,
      name: brand?.name?.replace('-sandbox', ''),
      displayName: brand?.displayName,
      externalName: brand?.externalName,
      relationship: brand?.relationship,
      reportType: brand?.reportType,
      isAllBrandsMode: isAllBrandsSelected(
        selectedMerchantState?.selectedBrandSwitcherOption,
        permissions?.brands
      ),
    };
    initializePendo(visitor, account);
  }, [authState, isQaOrOrPreProd, permissions, selectedMerchantState]);
}
