import React, { FunctionComponent } from 'react';
// TODO: update truffle Select to also have AsyncSelect
// import { Select } from '@fattmerchantorg/truffle-components';
import AsyncSelect from 'react-select/async';
import { Props as ReactSelectProps } from 'react-select';
import styled, { withTheme } from 'styled-components';

const StyledSelect = withTheme(
  styled(AsyncSelect)`
    flex: 1;

    > div {
      min-width: 200px;
    }

    // The styles below will get simplified when Truffle allows
    // more styles to be overridable (in progress).
    div div {
      background: ${({ theme }) => theme.colors.core.gray[800].hex};
      color: ${({ theme }) => theme.white};
    }

    .truffle-select__dropdown-indicator,
    .truffle-select__dropdown-indicator:hover {
      color: ${({ theme }) => theme.colors.core.green[400].hex};
    }

    .truffle-select__control,
    .truffle-select__menu {
      border: 1px solid ${({ theme }) => theme.colors.core.gray[600].hex};
      border-radius: 2px;

      .truffle-select__option--is-selected,
      .truffle-select__option:hover {
        background: ${({ theme }) => theme.white};
        border-radius: 2px;
      }
    }
  `
);

export const StaxAsyncSelect: FunctionComponent<ISelectProps> = props => {
  return <StyledSelect {...props}></StyledSelect>;
};

type ISelectProps = ReactSelectProps;
