import {
  GetFeeStatementOverviewDatum,
  GetNewFeeStatementOverviewDatum,
} from '@fattmerchantorg/types-omni';

export const convertFeesOverviewDataToList = (
  data: GetFeeStatementOverviewDatum
): GetNewFeeStatementOverviewDatum[] => {
  const getFeeObject = (
    key: keyof GetFeeStatementOverviewDatum,
    data: GetFeeStatementOverviewDatum
  ): GetNewFeeStatementOverviewDatum => {
    switch (key) {
      case 'account_updater_fees_amount':
        if (!data.account_updater_fees_amount) return null;
        return {
          name: 'Account Updater',
          count: data.account_updater_fees_count || 0,
          amount: data.account_updater_fees_amount || 0,
        };
      case 'ach_reject_fees_amount':
        if (!data.ach_reject_fees_amount) return null;
        return {
          name: 'ACH Reject Fees',
          count: data.ach_reject_fees_count || 0,
          amount: data.ach_reject_fees_amount || 0,
        };
      case 'dispute_fees_amount':
        if (!data.dispute_fees_amount) return null;
        return {
          name: 'Dispute',
          count: data.dispute_fees_count || 0,
          amount: data.dispute_fees_amount || 0,
        };
      case 'interchange_fees_amount':
        if (!data.interchange_fees_amount) return null;
        return {
          name: 'Interchange',
          count: data.interchange_fees_count || 0,
          amount: data.interchange_fees_amount || 0,
        };
      case 'miscellaneous_fees_amount':
        if (!data.miscellaneous_fees_amount) return null;
        return {
          name: 'Miscellaneous',
          count: data.miscellaneous_fees_count || 0,
          amount: data.miscellaneous_fees_amount || 0,
        };
      case 'pci_non_compliance_fees_amount':
        if (!data.pci_non_compliance_fees_amount) return null;
        return {
          name: 'PCI Non Compliance Fee',
          count: data.pci_non_compliance_fees_count || 0,
          amount: data.miscellaneous_fees_amount || 0,
        };
      case 'rate_fees_amount':
        if (!data.rate_fees_amount) return null;
        return {
          name: 'Rate Fee',
          count: data.rate_fees_count || 0,
          amount: data.rate_fees_amount || 0,
        };
      case 'transaction_fees_amount':
        if (!data.transaction_fees_amount) return null;
        return {
          name: 'Per Transaction Fee',
          count: data.transaction_fees_count || 0,
          amount: data.transaction_fees_amount || 0,
        };
      default:
        return null;
    }
  };

  const keys = Object.keys(data) as (keyof GetFeeStatementOverviewDatum)[];
  return keys.map(value => getFeeObject(value, data)).filter(value => !!value);
};

export const getPieChartColor = (index: number = 0) => {
  const colors = [
    '#66CCCC',
    '#A1D879',
    '#F8DC3D',
    '#70D3A9',
    '#9D6BDC',
    '#829CD4',
    '#75D697',
    '#CCDA5B',
    '#6BCFBA',
    '#B93BE4',
    '#F07587',
  ];
  try {
    const colorIdx = index === 0 ? 0 : index % colors.length;
    return colors[colorIdx];
  } catch (error) {
    return colors[0];
  }
};
