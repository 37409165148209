import { getHeaders } from '../util/api.util';
import axios from 'axios';
import get from 'lodash/get';
import { RequiredFieldErrors } from '../@types';
import {
  ReportEntity,
  Signer,
} from '../components/underwriting/components/external-verification/ExternalVerification.types';
import { Registration, Auth } from '@fattmerchantorg/types-omni';
import { getAxiosErrorMessage } from '../util/errors.util';

const stripSpecialCharacters = str => str.replace(/[\W_]/gi, '');

/**
GIACT Services Overview:

gVerify
GIACT’s bank account verification service verifies the current
reported status of bank accounts in real- time and can report certain
negative history associated with the account, if required and available.

Inquiries on currently assigned routing numbers will return the bank name
that the routing number is assigned to. For accounts at participating banks,
gVerify will also return the date the bank account was added to the
real-time network and the last updated date; or, the date that the account
was closed.

gAuthenticate
GIACT’s bank account authentication service starts with
gVerify to get the current status of the account, then takes it one step
further by authenticating your customer data against signature data from the
participating banks. This service works with both personal and business bank
accounts.

gIdentify
GIACT’s customer identification suite of services searches
consumer or business data records then compares your customer data to find
the closest matched records.

gOFAC
The gOFAC service scans all the US Office of Foreign Assets Control 
(OFAC) lists along with other national and international sanctioned entities 
lists and watch lists.

gESI
GIACT’s gIdentify ESI service processes an email address and IP address to
return the email address owner’s name, company, title, and social media 
links along with other information associated with the email address.
 */

export interface GiactReport {
  UniqueId: string;
  AccountResponseCode: { Code: string; Description: string } | undefined;
  VerificationResponse:
    | { Code: string; Status: string; Description: string }
    | undefined;
  CustomerResponseCode: { Code: string; Description: string } | undefined;
  [key: string]: any; // giactResponse.data
}

export interface GiactResult {
  ItemReferenceId: any;
  EntityType: string;
  VerificationResponse:
    | { Code: string; Status: string; Description: string }
    | undefined;
  CustomerResponseCode: { Code: string; Description: string } | undefined;
  ConsumerAlertMessages: any;
  AccountResponseCode: { Code: string; Description: string } | undefined;
  [key: string]: any; // mergeResult
}

/**
 *
 * @param {Object} jwt auth token
 * @param {Object} registration registration form containing values of input fields
 * @param {Object} servicesToRun object containing which services to run
 * @param {String} entityType 'person' or 'business' for giact call when running gIdentify
 * @param {String} merchantId id of the merchant for giact report, used for logging to registration_audit_logs
 */
export const postToGiact = async (
  jwt: Auth['token'],
  registration: Registration,
  servicesToRun: {
    gverify?: boolean;
    gidentify?: boolean;
    gauthenticate?: boolean;
    gofac?: boolean;
    gesi?: boolean;
  },
  entityType: ReportEntity,
  merchantId: string,
  signer?: Signer
): Promise<
  | { report: GiactReport; result: GiactResult; servicesRan: string[] }
  | RequiredFieldErrors
> => {
  const {
    bank_account_number: BankAccountNumber,
    bank_routing_number: BankRoutingNumber,
    first_name: OwnerFirstName,
    last_name: OwnerLastName,
    user_dob: OwnerDOB,
    email: OwnerEmail,
    owner_address_1: OwnerAddressLine1,
    owner_address_2: OwnerAddressLine2,
    owner_address_city: OwnerCity,
    owner_address_state: OwnerState,
    owner_address_zip: OwnerZip,
    owner_address_country: OwnerCountry,
    phone_number: OwnerPhoneNumber,
    user_ssn: OwnerTaxID,
    business_legal_name: BusinessLegalName,
    business_dba: BusinessDBA,
    business_location_address_1: BusinessAddressLine1,
    business_location_address_2: BusinessAddressLine2,
    business_location_address_city: BusinessCity,
    business_location_address_state: BusinessState,
    business_location_address_zip: BusinessZip,
    business_address_country: BusinessCountry,
    business_location_phone_number: BusinessPhoneNumber,
    business_tax_id: BusinessTaxId,
  } = registration;

  const { gverify, gidentify, gauthenticate, gofac, gesi } = servicesToRun;

  let Check;
  if (gverify || gauthenticate)
    Check = {
      AccountNumber: !!BankAccountNumber ? BankAccountNumber : undefined,
      RoutingNumber: !!BankRoutingNumber ? BankRoutingNumber : undefined,
    };

  let giactPayload = {
    merchantId,
    Services: {
      GIdentifyEnabled: gidentify ? gidentify : undefined,
      GIdentifyEsiEnabled: gesi ? gesi : undefined,
      GAuthenticateEnabled: gauthenticate ? gauthenticate : undefined,
      GVerifyEnabled: gverify ? gverify : undefined,
      OfacScanEnabled: gofac ? gofac : undefined,
    },
    Check: !!Check ? Check : undefined,
    Customer: {
      EntityType: entityType,
      OwnerFirstName: !!OwnerFirstName ? OwnerFirstName : undefined,
      OwnerLastName: !!OwnerLastName ? OwnerLastName : undefined,
      OwnerDOB: !!OwnerDOB ? OwnerDOB : undefined,
      OwnerEmail: !!OwnerEmail ? OwnerEmail : undefined,
      OwnerAddressLine1: !!OwnerAddressLine1 ? OwnerAddressLine1 : undefined,
      OwnerAddressLine2: !!OwnerAddressLine2 ? OwnerAddressLine2 : undefined,
      OwnerCity: !!OwnerCity ? OwnerCity : undefined,
      OwnerState: !!OwnerState ? OwnerState : undefined,
      OwnerZip: !!OwnerZip ? OwnerZip : undefined,
      OwnerCountry: !!OwnerCountry ? OwnerCountry : undefined,
      OwnerPhoneNumber: !!OwnerPhoneNumber
        ? stripSpecialCharacters(OwnerPhoneNumber)
        : undefined,
      OwnerTaxID: !!OwnerTaxID ? stripSpecialCharacters(OwnerTaxID) : undefined,
      BusinessLegalName: !!BusinessLegalName ? BusinessLegalName : undefined,
      BusinessDBA: !!BusinessDBA ? BusinessDBA : undefined,
      BusinessAddressLine1: !!BusinessAddressLine1
        ? BusinessAddressLine1
        : undefined,
      BusinessAddressLine2: !!BusinessAddressLine2
        ? BusinessAddressLine2
        : undefined,
      BusinessCity: !!BusinessCity ? BusinessCity : undefined,
      BusinessState: !!BusinessState ? BusinessState : undefined,
      BusinessZip: !!BusinessZip ? BusinessZip : undefined,
      BusinessCountry: !!BusinessCountry ? BusinessCountry : undefined,
      BusinessPhoneNumber: !!BusinessPhoneNumber
        ? stripSpecialCharacters(BusinessPhoneNumber)
        : undefined,
      BusinessTaxId: !!BusinessTaxId
        ? stripSpecialCharacters(BusinessTaxId)
        : undefined,
    },
  };

  // If a signer is passed in, that means we're running the checks on a
  // beneficial owner instead of the principal owner. So here we overwrite the
  // associated owner fields before sending it off to Giact.
  if (signer) {
    giactPayload = {
      ...giactPayload,
      Customer: {
        ...giactPayload.Customer,
        OwnerFirstName: !!signer.first_name ? signer.first_name : undefined,
        OwnerLastName: !!signer.last_name ? signer.last_name : undefined,
        OwnerDOB: !!signer.user_dob ? signer.user_dob : undefined,
        OwnerEmail: !!signer.email ? signer.email : undefined,
        OwnerAddressLine1: !!signer.address_1 ? signer.address_1 : undefined,
        OwnerAddressLine2: !!signer.address_2 ? signer.address_2 : undefined,
        OwnerCity: !!signer.address_city ? signer.address_city : undefined,
        OwnerState: !!signer.address_state ? signer.address_state : undefined,
        OwnerZip: !!signer.address_zip ? signer.address_zip : undefined,
        OwnerCountry: !!signer.address_country
          ? signer.address_country
          : undefined,
        OwnerPhoneNumber: !!signer.phone_number
          ? stripSpecialCharacters(signer.phone_number)
          : undefined,
        OwnerTaxID: !!signer.user_ssn
          ? stripSpecialCharacters(signer.user_ssn)
          : undefined,
      },
    };
  }

  try {
    const giactResponse = await axios({
      url: `${process.env.REACT_APP_ONBOARDING_URL}/giact/verify`,
      data: giactPayload,
      method: 'post',
      headers: getHeaders(jwt),
    });
    return giactResponse.data;
  } catch (error) {
    // Underwriting backend will potentially respond with a list of required
    // fields alongside a 422 so we need to catch and return those so we can
    // notify the user. We don't throw in this case since the reports are
    // streamed in via rxjs and it's preferable to not break the stream if we're
    // just returning validation errors.
    const requiredFields = get(error, 'response.data.report.errors', false);

    if (!!requiredFields) {
      return {
        requiredFields: requiredFields,
      };
    }
    // If something else went wrong, throw a real error.
    // If the error is an axios response with a `message` field, create the error with it,
    // otherwise just use the axios error object to create the error.
    throw new Error(getAxiosErrorMessage(error) ?? error);
  }
};

export const getGiactReport = async (jwt, itemReferenceId) => {
  try {
    const giactResponse = await axios({
      url: `${process.env.REACT_APP_ONBOARDING_URL}/giact/verify/${itemReferenceId}`,
      method: 'get',
      headers: getHeaders(jwt),
    });
    return giactResponse.data;
  } catch (error) {
    throw error;
  }
};
