import { FunctionComponent } from 'react';
import { ModalAction } from './Modal.types';

export function openModal<Props>(
  component: FunctionComponent<Props>,
  props: Props
): ModalAction {
  return {
    type: 'OPEN_MODAL',
    payload: { component, props },
  };
}

export function closeModal(): ModalAction {
  return {
    type: 'CLOSE_MODAL',
  };
}
