import React, { useContext, useRef, useState } from 'react';
import {
  Icon,
  PrimaryButton,
  SecondaryButton,
} from '@fattmerchantorg/truffle-components';
import {
  AdjustmentTransactionChildModalProps,
  AdjustmentTransactionFromGlobal,
  AdjustmentTransactionFromTransactions,
  AdjustmentTransactionType,
  CreateOrEditFormFields,
} from '../AdjustmentTransactions.types';
import { formatCapitalCase, formatCurrency } from '../../../../util';
import { ConfirmationModal } from './ConfirmationModal';
import { catanapi, coreapi } from '../../../../api';
import { AuthStore } from '../../../../context';
import { useAsyncEffect, useToaster } from '../../../../hooks';
import { CreateOrEditForm } from './CreateOrEditForm';
import { GenericModal } from './GenericModal';
import { getErrorsFromCatanErrorResponse } from '../../../../util/catan.util';
import {
  CollectionResponse,
  FundingAccount,
} from '@fattmerchantorg/types-omni';
import { AdjustmentTransactionCategory } from '@fattmerchantorg/types-engine';
import styled from 'styled-components';

type Props = AdjustmentTransactionChildModalProps &
  (AdjustmentTransactionFromGlobal | AdjustmentTransactionFromTransactions);

const LoadingStateWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.component.modal.backgroundColor};
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CreateOrEditModal: React.FC<Props> = (props: Props) => {
  const { onCancel, onDone, authToken, merchant, source, action } = props;
  const {
    state: { auth },
  } = useContext(AuthStore);

  const { toaster, toast } = useToaster();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeView, setActiveView] = useState<'form' | 'confirm'>('form');

  const [amountValue, setAmountValue] = useState(
    props.source === 'transaction' && props.action === 'EDIT'
      ? props.transaction.amount.toString()
      : null
  );
  const [formState, setFormState] = useState<CreateOrEditFormFields>();
  const [fundingAccounts, setFundingAccounts] = useState<FundingAccount[]>(
    merchant.fundingAccounts
  );
  const [isFetchingFundingAccounts, setIsFetchingFundingAccounts] =
    useState<boolean>(false);

  const [isFormValid, setIsFormValid] = useState<boolean>(
    props.action === 'EDIT' ? true : false
  );

  const submitRef = useRef<HTMLButtonElement>();

  const transactionTypeLabel = formatCapitalCase(getTransactionType());

  const textActionButton = `${transactionTypeLabel}${
    parseFloat(amountValue) > 0 ? ' ' + formatCurrency(amountValue) : ''
  }`;

  useAsyncEffect(async () => {
    await fetchFundingAccounts();
  }, [authToken]);

  return (
    <React.Fragment>
      <ConfirmationModal
        isOpen={activeView === 'confirm'}
        title={`${
          props.action === 'CREATE' ? 'Confirm' : 'Edit'
        } ${transactionTypeLabel} Adjustment`}
        confirmButtonLabel={
          props.action === 'CREATE' ? textActionButton : 'Save Changes'
        }
        confirmButtonType="primary"
        onClose={onConfirmCancel}
        onConfirm={createOrUpdateTransaction}
        inProgress={isSubmitting}
      >
        <p>
          {props.action === 'CREATE' && (
            <React.Fragment>
              Are you sure you want to{' '}
              <strong>
                {transactionTypeLabel} {formatCurrency(amountValue)}
              </strong>{' '}
              to <strong>{merchant.name}</strong>?
            </React.Fragment>
          )}
          {props.action === 'EDIT' && (
            <React.Fragment>
              Are you sure you want to save the following changes?
              <br />
              <strong>
                {transactionTypeLabel} {formatCurrency(amountValue)}
              </strong>{' '}
              from <strong>{merchant.name}</strong>
            </React.Fragment>
          )}
        </p>
      </ConfirmationModal>
      <GenericModal
        isOpen={activeView === 'form'}
        title={`${
          props.action === 'EDIT' ? 'Edit' : ''
        } ${transactionTypeLabel} Adjustment`}
        onClose={onCancelLocal}
        buttons={
          <React.Fragment>
            <SecondaryButton onClick={onCancelLocal}>Cancel</SecondaryButton>
            <PrimaryButton
              disabled={!isFormValid || isFetchingFundingAccounts}
              onClick={() => submitRef.current?.click()}
            >
              {props.action === 'CREATE' ? textActionButton : 'Save'}
            </PrimaryButton>
          </React.Fragment>
        }
        buttonsPosition="right"
      >
        {isFetchingFundingAccounts && (
          <LoadingStateWrapper>
            <Icon icon={['fas', 'spinner-third']} spin={true} size="2x" />
          </LoadingStateWrapper>
        )}

        <CreateOrEditForm
          transactionType={getTransactionType()}
          action={action}
          initialValues={populateFormInitialValues()}
          fundingAccounts={fundingAccounts}
          mids={merchant.mids}
          merchantName={merchant.name}
          source={source}
          submitRef={submitRef}
          onSubmit={onSubmit}
          onInputChange={onInputChange}
          onValidity={valid => {
            if (isFormValid !== valid) {
              setIsFormValid(valid);
            }
          }}
        />
      </GenericModal>
    </React.Fragment>
  );

  function onConfirmCancel() {
    setActiveView('form');
  }

  function onCancelLocal() {
    setActiveView(null);
    if (typeof onCancel === 'function') {
      onCancel();
    }
  }

  function onDoneLocal() {
    setActiveView(null);
    if (typeof onDone === 'function') {
      onDone();
    }
  }

  function onSubmit(values: CreateOrEditFormFields, isValid: boolean) {
    if (isValid) {
      setFormState(values);
      setActiveView('confirm');
    }
  }

  function onInputChange(field: string, value: any) {
    if (field === 'amount') {
      setAmountValue(value);
    }
  }

  async function createOrUpdateTransaction() {
    setIsSubmitting(true);
    try {
      // remove empty properties
      const formData = Object.entries(formState).reduce((acc, [k, v]) => {
        if (v !== undefined && v !== null) {
          acc[k] = v;
        }
        return acc;
      }, {});

      let url = 'adjustments';
      if (props.source === 'transaction' && props.action === 'EDIT') {
        url += `/${props.transaction.transaction_id}`;
      }
      const request = props.action === 'EDIT' ? catanapi.put : catanapi.post;
      await request(authToken, url, formData);
      toaster(
        toast.success(
          <span>
            <strong>{props.action === 'EDIT' ? 'Edited' : 'Created'}</strong>{' '}
            {merchant.name} <strong>{transactionTypeLabel} Adjustment</strong>
          </span>,
          `Adjustment ${props.action === 'EDIT' ? 'Edited' : 'Created'}`
        )
      );
      setIsSubmitting(false);
      onDoneLocal();
    } catch (error) {
      const catanValidation = getErrorsFromCatanErrorResponse(error);
      const errors = catanValidation.length
        ? catanValidation
        : error
        ? [error.message]
        : [];
      toaster(
        toast.error(
          <div>
            <div>
              Could not{' '}
              <strong>{props.action === 'EDIT' ? 'edit' : 'create'}</strong>{' '}
              {merchant.name} <strong>{transactionTypeLabel} Adjustment</strong>
            </div>
            {errors.map((e, i) => (
              <div key={i}>{e}</div>
            ))}
          </div>,
          'Error'
        )
      );
      setIsSubmitting(false);
      setActiveView('form');
    }
  }

  async function fetchFundingAccounts() {
    setIsFetchingFundingAccounts(true);
    try {
      const res = await coreapi.get<CollectionResponse<FundingAccount>>(
        authToken,
        `/merchant/${merchant.id}/funding-account`,
        { active: 1, status: 'APPROVED' }
      );

      setFundingAccounts(res.data ?? []);
    } catch (error) {
      const errors = getErrorsFromCatanErrorResponse(error);
      toaster(
        toast.error(
          <div>
            {errors.map((e, i) => (
              <div key={i}>{e}</div>
            ))}
          </div>,
          'Error'
        )
      );
    }
    setIsFetchingFundingAccounts(false);
  }

  function getTransactionType(): AdjustmentTransactionType {
    if (props.source === 'transaction') {
      return props.action === 'EDIT'
        ? (props.transaction.type as AdjustmentTransactionType)
        : props.type;
    } else if (props.source === 'global') {
      return props.type;
    }
  }

  function populateFormInitialValues(): CreateOrEditFormFields {
    if (formState) {
      return formState;
    }

    const firstPm = fundingAccounts?.find(f =>
      f.flags?.includes('FEE')
    )?.engine_api_payment_method_id;

    return {
      companyId: merchant.companyId,
      processorMid: merchant.mids?.length === 1 ? merchant.mids[0] : null,
      amount: null,
      type: null,
      description: null,
      category: null,
      userId: auth?.user?.id ?? null,
      paymentMethodId: firstPm ?? null,
      parentTransactionId: null,
      settlementId: null,
      ...(props.source === 'global' && {
        type: props.type,
      }),
      ...(props.source === 'transaction' &&
        props.action === 'EDIT' && {
          companyId: null,
          amount: props.transaction.amount,
          processorMid: props.transaction.processor_mid,
          description: props.transaction.meta?.description?.toString(),
          category: props.transaction.subtype?.replace(
            'ADJUSTMENT_',
            ''
          ) as AdjustmentTransactionCategory,
          type: null,
          paymentMethodId:
            props.transaction.payout_instructions[0].deposit_account_id,
        }),
      ...(props.source === 'transaction' &&
        props.action === 'CREATE' && {
          type: props.type,
          parentTransactionId: props.transaction.transaction_id,
        }),
    };
  }
};
