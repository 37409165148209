import React from 'react';
import styled from 'styled-components';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 1px solid #435e70;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const CheckmarkIcon = styled.svg`
  fill: none;
  stroke: #000000;
  stroke-width: 3px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${props =>
    props.checked || props.allStatus === 0 || props.allStatus === -1
      ? '#75D697'
      : '#213745'};
  border: 1px solid #435e70;
  border-radius: 2px;
  transition: all 150ms;
  margin-right: 8px;
  ${CheckmarkIcon} {
    visibility: ${props =>
      props.checked || props.allStatus === 0 || props.allStatus === -1
        ? 'visible'
        : 'hidden'};
  }
`;

export const Checkbox = ({ checked, ...props }) => (
  <CheckboxContainer>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked}>
      <CheckmarkIcon viewBox="0 0 24 32">
        <polyline points="20 6 9 17 4 12" />
      </CheckmarkIcon>
    </StyledCheckbox>
  </CheckboxContainer>
);
