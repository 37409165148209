import React, {
  useState,
  useCallback,
  useEffect,
  ReactNode,
  useRef,
  useContext,
} from 'react';
import { CardHeader } from 'reactstrap';

import { CollectionProps, CollectionData } from './Collection.types';
import { memoize, times } from '../../../util/functional.util';
import { LoadingSpan } from '../LoadingSpan';
import { usePrevious } from '../../../hooks';
import { isShallowEqual } from '../../../util/object.util';
import { SelectedMerchantStore } from '../../../context';
import { ColumnProps, Table } from '../Table';
import styled from 'styled-components';

const getLoadingItems = memoize(
  <T extends Record<string, any>>(numberOfItems: number) =>
    times(numberOfItems).map(_ => ({} as T))
);

const getLoadingColumns = memoize(
  <T extends Record<string, any>>(columns: ColumnProps<T>[]) =>
    columns.map(column => {
      return { ...column, Cell: () => <LoadingSpan /> };
    }) as ColumnProps<T>[]
);

const ErrorOrNoResultsOuter = styled.div`
  min-height: 50vh;
`;

const ErrorOrNoResultsInner = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export function Collection<T extends object>(
  props: CollectionProps<T>
): JSX.Element {
  const {
    query,
    blacklist,
    header,
    columns,
    getData,
    onReceiveData,
    onStatusChange,
    numberOfLoadingItems,
    noResultsMessage,
    errorMessage,
    autoRefreshAfter,
    autoRefreshOffAfter,
    ...tableProps
  } = props;

  const [data, setData] = useState<CollectionData<T>>(null);
  const [status, setStatus] = useState<'loading' | 'idle' | 'error'>('loading');
  const isMounted = useRef(true);
  const previousQuery = usePrevious(query);
  const previousGetData = usePrevious(getData);

  // Get "selected brand" in context.
  const {
    state: { selectedBrandSwitcherOption },
  } = useContext(SelectedMerchantStore);
  const previousBrandSwitcher = usePrevious(selectedBrandSwitcherOption);

  // transform blacklist into a string so we can easily tell when it changes
  // without having to memoize the blacklist or use usePrevious on it
  const blacklistString = blacklist.join(',');

  useEffect(() => {
    return () => (isMounted.current = false);
  }, []);

  useEffect(() => {
    if (onStatusChange) onStatusChange(status);
  }, [onStatusChange, status]);

  const handleSearch = useCallback(
    async (options: { silently } = { silently: false }) => {
      if (!query || !getData) return;

      if (!options.silently) {
        setData(null);
        setStatus('loading');
      }

      try {
        const data = await getData(query);

        // if this component is no longer mounted, short-circuit here
        // to prevent react errors / memory leaks
        if (!isMounted.current) return;

        setData(data);

        if (onReceiveData) {
          onReceiveData(data);
        }

        // if this component is no longer mounted, short-circuit here
        // to prevent react errors / memory leaks
        if (!isMounted.current) return;

        setStatus('idle');
      } catch (error) {
        setStatus('error');
      }
    },
    [onReceiveData, getData, query]
  );

  useEffect(() => {
    let interval: number;
    let timeout: number;

    if (autoRefreshAfter) {
      interval = setInterval(() => {
        handleSearch({ silently: true });
      }, autoRefreshAfter);

      timeout = setTimeout(() => {
        if (interval) clearInterval(interval);
      }, autoRefreshOffAfter);
    }

    return () => {
      if (interval) clearInterval(interval);
      if (timeout) clearTimeout(timeout);
    };
  }, [autoRefreshAfter, autoRefreshOffAfter, handleSearch]);

  useEffect(() => {
    /**
     * We should run the search
     * IF getData is defined
     * AND
     * (
     *   IF the query is different
     *   OR getData was previously undefined
     * )
     */

    const cleanedQuery = { ...query };
    const cleanedPreviousQuery = { ...previousQuery };
    const blacklist = blacklistString.split(',');

    blacklist.forEach(key => {
      delete cleanedQuery[key];
      delete cleanedPreviousQuery[key];
    });

    const shouldRunSearch =
      getData &&
      (!isShallowEqual(cleanedQuery, cleanedPreviousQuery) ||
        !previousGetData ||
        previousBrandSwitcher !== selectedBrandSwitcherOption);

    if (shouldRunSearch) {
      handleSearch();
    } else if (!getData && status !== 'loading') {
      // if getData is not ready yet, persist a loading state
      setStatus('loading');
    }
  }, [
    query,
    previousQuery,
    getData,
    status,
    previousGetData,
    handleSearch,
    blacklistString,
    previousBrandSwitcher,
    selectedBrandSwitcherOption,
  ]);

  const renderContent = useCallback((): ReactNode => {
    if (status === 'error') {
      return (
        <ErrorOrNoResultsOuter>
          <ErrorOrNoResultsInner>{errorMessage}</ErrorOrNoResultsInner>
        </ErrorOrNoResultsOuter>
      );
    } else if (data?.data?.length === 0) {
      return (
        <ErrorOrNoResultsOuter>
          <ErrorOrNoResultsInner>{noResultsMessage}</ErrorOrNoResultsInner>
        </ErrorOrNoResultsOuter>
      );
    }

    return null;
  }, [status, data, errorMessage, noResultsMessage]);

  const normalizedColumns =
    typeof columns === 'function' ? columns(data?.data ?? null) : columns;

  return (
    <>
      {header ? <CardHeader className="border-0">{header}</CardHeader> : null}
      <Table
        {...tableProps}
        data={{
          columns:
            status === 'loading'
              ? getLoadingColumns<T>(normalizedColumns)
              : normalizedColumns,
          rows:
            status === 'loading'
              ? getLoadingItems<T>(numberOfLoadingItems)
              : data?.data ?? [],
        }}
        count={data?.last_page ?? 0}
        total={data?.total ?? 0}
        currentPage={data?.current_page ?? 0}
        defaultPageSize={tableProps?.defaultPageSize || 20}
        lastPage={data?.last_page}
        loading={status === 'loading'}
      >
        {renderContent()}
      </Table>
    </>
  );
}

Collection.defaultProps = {
  blacklist: ['detailId'],
  numberOfLoadingItems: 20,
  errorMessage: <span>There was an error fetching results.</span>,
  noResultsMessage: <span>No results found with your chosen filters.</span>,
};
