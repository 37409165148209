import { css } from 'styled-components';

export const linkStyles = css`
  color: ${props => props.theme.colors.status.blue['500'].hex} !important;
  text-decoration: underline !important;
  font-size: 10px;
  cursor: pointer;

  &[data-disabled='true'] {
    color: ${({ theme }) => theme.colors.core.gray[200].hex} !important;
    cursor: not-allowed;
  }
`;
