import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback
} from 'react';
import { SelectProps } from './Select.types';
import { InputContainer } from '../InputContainer/InputContainer';

export const Select: FunctionComponent<SelectProps> = props => {
  const { id, value, label, tooltip, onFocus, onBlur, ...rest } = props;

  // If data-testid is provided, use it. Else, data-testid falls back to the id.
  let dataTestId: string = props['data-testid'] ? props['data-testid'] : id;

  const [active, setActive] = useState(!!value);

  const setActiveIfValue = useCallback(
    (value: string) => {
      if (value && !active) {
        setActive(true);
      }
    },
    [active]
  );

  useEffect(() => {
    setActiveIfValue(value);
  }, [value, setActiveIfValue]);

  return (
    <InputContainer className={`ui-input ${active ? '-active' : ''}`}>
      {label && (
        <div>
          <label htmlFor={id}>{label}</label>
        </div>
      )}
      <select
        {...rest}
        placeholder="Select one"
        id={id}
        data-testid={dataTestId}
        value={value}
        onFocus={e => {
          setActive(true);
          if (onFocus) onFocus(e);
        }}
        onBlur={e => {
          setActive(!!e.target.value);
          if (onBlur) onBlur(e);
        }}
      />
      <i className="select-indicator fas fa-chevron-down" />
    </InputContainer>
  );
};

Select.defaultProps = {};
