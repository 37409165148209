import React, { CSSProperties, FunctionComponent } from 'react';
import styled from 'styled-components';
import {
  Dropdown,
  DropdownContent,
  Icon,
  Tooltip,
} from '@fattmerchantorg/truffle-components';
import { Row } from 'react-table';
import { usePermissions } from '../../../hooks';
import { API } from '@fattmerchantorg/types-engine';

const IconButton = styled.button`
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.core.white[0].hex};
`;

const StyledTooltip = styled(Tooltip)`
  max-width: 20vh;
  white-space: normal;
`;

const ContextMenu = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    &:last-child {
      border-top: 1px solid ${({ theme }) => theme.lightNeutralOutline};
    }
  }

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    a,
    button {
      display: block;
      background: none;
      border: 0;
      border-radius: 2px;
      margin: 4px;
      padding: 8px 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      text-align: left;
      cursor: pointer;
      color: ${({ theme }) => theme.colors.core.white[0].hex};

      span {
        margin-right: 8px;
        width: 16px;
      }

      &:hover {
        background: ${({ theme }) => theme.component.table.borderColor};
      }
    }
  }
`;

interface SettlementOptionsDrawerContextProps {
  row: Row<object>;
  iconStyles?: CSSProperties;
  onCopyId?: (row: Row<object>, rowData: API.Settlement) => void;
  onApprove?: (row: Row<object>, rowData: API.Settlement) => void;
  onExport?: (row: Row<object>, rowData: API.Settlement) => void;
  onHold?: (row: Row<object>, rowData: API.Settlement) => void;
  onRelease?: (row: Row<object>, rowData: API.Settlement) => void;
}

export const SettlementOptionsDrawerContextMenu: FunctionComponent<
  SettlementOptionsDrawerContextProps
> = props => {
  const { row, onCopyId, onApprove, onExport, onHold, onRelease } = props;
  const isPendingApproval = row?.values?.state === 'PENDING';

  const { permit } = usePermissions();
  const canApprove =
    row.values.state !== 'APPROVED' &&
    permit('godview', 'approvalWizard', 'write');
  const canExport = false;

  const isOnHold = row.values.state === 'HOLD';

  const isHoldable =
    row.values.state === 'FUNDED' ||
    row.values.state === 'APPROVED' ||
    row.values.state === 'REJECTED';

  const data = row.original as API.Settlement;
  let dropdownToggler: React.Dispatch<React.SetStateAction<boolean>>;

  return (
    <Dropdown
      placement="left-start"
      trigger={(setRef, isOpen, setIsOpen) => (
        <IconButton
          ref={setRef}
          onClick={e => {
            dropdownToggler = setIsOpen;
            // Prevents drawer menu from opening when clicking button
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
          data-testid="payment-table-row-context-menu-trigger"
        >
          <Icon icon={['fas', 'ellipsis-h']} />
        </IconButton>
      )}
    >
      <DropdownContent style={{ minWidth: '122px', width: 'auto' }}>
        <ContextMenu>
          <ul>
            <li>
              <StyledTooltip
                content={`
                  Copy Settlement ID to clipboard
                  ${data.settlement_id}
                `}
              >
                <button onClick={handleCopyId}>
                  <Icon icon={['fas', 'copy']} /> Copy ID
                </button>
              </StyledTooltip>
            </li>
            {canApprove && isPendingApproval && (
              <li>
                <button onClick={handleApprove}>
                  <Icon icon={['fas', 'thumbs-up']} /> Approve
                </button>
              </li>
            )}
            {canApprove && !isHoldable && !isOnHold && (
              <li>
                <button onClick={handleHold}>
                  <Icon icon={['fas', 'hand-paper']} /> Hold
                </button>
              </li>
            )}
            {canApprove && !isHoldable && isOnHold && (
              <li>
                <button onClick={handleRelease}>
                  <Icon icon={['fas', 'hands-usd']} /> Release
                </button>
              </li>
            )}
            {canExport && (
              <li>
                <button onClick={handleExport}>
                  <Icon icon={['fas', 'download']} /> Export
                </button>
              </li>
            )}
          </ul>
        </ContextMenu>
      </DropdownContent>
    </Dropdown>
  );

  function closeDropdown() {
    if (typeof dropdownToggler === 'function') {
      dropdownToggler(false);
    }
  }

  function handleCopyId() {
    closeDropdown();
    if (typeof onCopyId === 'function') {
      onCopyId(row, data);
    }
  }

  function handleApprove() {
    closeDropdown();
    if (typeof onApprove === 'function') {
      onApprove(row, data);
    }
  }

  function handleHold() {
    closeDropdown();
    if (typeof onHold === 'function') {
      onHold(row, data);
    }
  }

  function handleRelease() {
    closeDropdown();
    if (typeof onRelease === 'function') {
      onRelease(row, data);
    }
  }

  function handleExport() {
    closeDropdown();
    if (typeof onExport === 'function') {
      onExport(row, data);
    }
  }
};
