import { Registration } from '@fattmerchantorg/types-omni';
import { pick } from '../../../../util/object.util';
import { isWithinRange } from '../../../../util/validator.util';
import {
  ProcessingInfoFieldsValues,
  ProcessingInfoFieldsErrors,
} from './ProcessingInfoForm.types';
import { formatCurrency, toDecimalString } from '../../../../util';

export function mapFormValuesToPayload(
  formValues: ProcessingInfoFieldsValues
): Partial<Registration> {
  return {
    refund_policy: formValues.refund_policy,
    annual_volume: toDecimalString(formValues.annual_volume),
    avg_trans_size: toDecimalString(formValues.avg_trans_size),
    highest_trans_amount: toDecimalString(formValues.highest_trans_amount),
    annual_gross_ach_revenue: toDecimalString(
      formValues.annual_gross_ach_revenue
    ),
    avg_ach_transaction: toDecimalString(formValues.avg_ach_transaction),
    largest_ach_transaction: toDecimalString(
      formValues.largest_ach_transaction
    ),
    card_present_percent: formValues.card_present_percent,
    card_not_present_percent: formValues.card_not_present_percent,
    card_swiped_percent: formValues.card_swiped_percent,
    moto_percent: formValues.moto_percent,
    internet: formValues.internet,
    international: formValues.international,
    b2b_percent: formValues.b2b_percent,

    annual_gross_shopping_cart_revenue: toDecimalString(
      formValues.annual_gross_shopping_cart_revenue
    ),
    avg_shopping_cart_transaction: toDecimalString(
      formValues.avg_shopping_cart_transaction
    ),
    largest_shopping_cart_transaction: toDecimalString(
      formValues.largest_shopping_cart_transaction
    ),
    shopping_cart_card_present_percent: toDecimalString(
      formValues.shopping_cart_card_present_percent
    ),
    shopping_cart_international: toDecimalString(
      formValues.shopping_cart_international
    ),
    shopping_cart_b2b_percent: toDecimalString(
      formValues.shopping_cart_b2b_percent
    ),
  };
}

export function mapRegistrationToInitialFormValues(
  registration: Registration
): ProcessingInfoFieldsValues {
  const data = pick(
    registration,
    'refund_policy',
    'avg_trans_size',
    'highest_trans_amount',
    'annual_gross_ach_revenue',
    'avg_ach_transaction',
    'largest_ach_transaction',
    'annual_volume',
    'card_present_percent',
    'card_not_present_percent',
    'card_swiped_percent',
    'moto_percent',
    'internet',
    'international',
    'b2b_percent',
    'annual_gross_shopping_cart_revenue',
    'avg_shopping_cart_transaction',
    'largest_shopping_cart_transaction',
    'shopping_cart_card_present_percent',
    'shopping_cart_international',
    'shopping_cart_b2b_percent'
  ) as ProcessingInfoFieldsValues;

  return {
    ...data,
    refund_policy: data.refund_policy,
    avg_trans_size: formatCurrency(data.avg_trans_size),
    highest_trans_amount: formatCurrency(data.highest_trans_amount),
    annual_gross_ach_revenue: formatCurrency(data.annual_gross_ach_revenue),
    avg_ach_transaction: formatCurrency(data.avg_ach_transaction),
    largest_ach_transaction: formatCurrency(data.largest_ach_transaction),
    annual_volume: formatCurrency(data.annual_volume),
    card_present_percent: data.card_present_percent,
    card_not_present_percent: data.card_not_present_percent,
    card_swiped_percent: data.card_swiped_percent,
    moto_percent: data.moto_percent,
    internet: data.internet,
    international: data.international,
    b2b_percent: data.b2b_percent,

    annual_gross_shopping_cart_revenue: formatCurrency(
      data.annual_gross_shopping_cart_revenue
    ),
    avg_shopping_cart_transaction: formatCurrency(
      data.avg_shopping_cart_transaction
    ),
    largest_shopping_cart_transaction: formatCurrency(
      data.largest_shopping_cart_transaction
    ),
    shopping_cart_card_present_percent: data.shopping_cart_card_present_percent,
    shopping_cart_international: data.shopping_cart_international,
    shopping_cart_b2b_percent: data.shopping_cart_b2b_percent,
  };
}

export function validateRequiredFormValues(
  values: ProcessingInfoFieldsValues
): ProcessingInfoFieldsErrors {
  const errors = {} as ProcessingInfoFieldsErrors;

  const requiredFields = [
    'refund_policy',
    'annual_volume',
    'avg_trans_size',
    'highest_trans_amount',
    'card_present_percent',
    'card_not_present_percent',
    'card_swiped_percent',
    'moto_percent',
    'internet',
    'international',
    'b2b_percent',
  ];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
}

export function validateFormValues(
  values: ProcessingInfoFieldsValues
): ProcessingInfoFieldsErrors {
  const errors = {} as ProcessingInfoFieldsErrors;

  const percentFields = [
    'card_present_percent',
    'card_not_present_percent',
    'card_swiped_percent',
    'moto_percent',
    'internet',
    'international',
    'b2b_percent',
  ];

  const shoppingCartPercentFields = [
    'shopping_cart_card_present_percent',
    'shopping_cart_international',
    'shopping_cart_b2b_percent',
  ];

  // Require whole number in 0-100 range for all percent fields
  // validation in underwriting is /^[0-9]{1,3}$/i
  // src/logic/vantiv-logic/validation-merchant-info-patterns.js#L105
  [...percentFields, ...shoppingCartPercentFields].forEach(fieldName => {
    if (
      values[fieldName] &&
      (!isWithinRange(parseInt(values[fieldName].toString(), 10), 0, 100) ||
        values[fieldName] % 1 > 0)
    ) {
      errors[fieldName] = 'Please enter a whole number between 0 and 100';
    }
  });

  return errors;
}
