import React, { FC } from 'react';
import styled from 'styled-components';
import { Terminal } from '@fattmerchantorg/types-engine/DB';
import { Drawer, Text } from '@fattmerchantorg/truffle-components';
import { DrawerProps } from '@fattmerchantorg/truffle-components/dist/Drawer/Drawer';
import { Separator as USeparator } from '../shared';
import { IdCustomToolTip } from '../shared/Id/IdCustomToolTip';

const Section = styled.div`
  padding: 16px 16px 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
`;

const Overflow = styled.div`
  /* Auto layout */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
`;

const FirtColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  height: 48px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

const BothColumns = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;

  height: 528px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
`;
const LeftColumns = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 230px;
  height: 496px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
`;
const RightColumns = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 216px;
  height: 260px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;
`;

const HeaderText = styled.div`
  width: 300px;
  height: 36px;

  /* Heading/H4-Regular */

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */

  /* Gray/0 */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

const Separator = styled(USeparator)`
  padding-top: 16px;
`;

const DrawerBgColour = styled(Drawer)`
  background-color: #213745;
  border-radius: 0;
`;

/** Props accepted by the DetailsModal component */
type TerminalDrawerProps = Partial<DrawerProps> &
  Pick<DrawerProps, 'open'> & {
    getData: Terminal | null;
  };

export const TerminalDrawer: FC<TerminalDrawerProps> = props => {
  const { getData, ...drawerProps } = props;

  return (
    <DrawerBgColour
      {...drawerProps}
      anchor="right"
      title="Terminal Details"
      data-testid="terminal-details-modal"
      customStyles={{
        borderRadius: 0,
        padding: 0,
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <>
        <Section>
          <>
            <HeaderText>
              {getData?.label} - {getData?.terminal_number}
            </HeaderText>
          </>
        </Section>
        <Separator />
      </>
      <BothColumns>
        <LeftColumns>
          <Overflow>
            <Text as="p">Terminal Number</Text>
            <Text as="p">
              <IdCustomToolTip
                toolTip={`Copy Terminal Number to clipboard ${getData?.terminal_number}`}
                id={getData?.terminal_number ? getData?.terminal_number : '--'}
              />
            </Text>
          </Overflow>
          <Overflow>
            <Text as="p">MCC</Text>
            <Text as="p">
              <IdCustomToolTip
                toolTip={`Copy MCC to clipboard ${getData?.mcc}`}
                id={getData?.mcc ? getData?.mcc : '--'}
              />
            </Text>
          </Overflow>
          <Overflow>
            <Text as="p">V Number</Text>
            <Text as="p">
              <IdCustomToolTip
                toolTip={`Copy V Number to clipboard ${getData?.v_number}`}
                id={getData?.v_number ? getData?.v_number : '--'}
              />
            </Text>
          </Overflow>
          <Overflow>
            <Text as="p">Acquirer Bin</Text>
            <Text as="p">
              <IdCustomToolTip
                toolTip={`Copy Acquirer Bin to clipboard ${getData?.acquirer_bin}`}
                id={getData?.acquirer_bin}
              />
            </Text>
          </Overflow>
          <Overflow>
            <Text as="p">Store Number</Text>
            <Text as="p">
              <IdCustomToolTip
                toolTip={`Copy Store Number to clipboard ${getData?.store_number}`}
                id={getData?.store_number ? getData?.store_number : '--'}
              />
            </Text>
          </Overflow>
          <Overflow>
            <Text as="p">Pos Merchant ID</Text>
            <Text as="p">
              <IdCustomToolTip
                toolTip={`Copy Pos Merchant ID to clipboard ${getData?.pos_merchant_id}`}
                id={getData?.pos_merchant_id ? getData?.pos_merchant_id : '--'}
              />
            </Text>
          </Overflow>
          <Overflow>
            <Text as="p">Agent Bank Number</Text>
            <Text as="p">
              <IdCustomToolTip
                toolTip={`Copy Agent Bank Number to clipboard ${getData?.agent_bank_number}`}
                id={
                  getData?.agent_bank_number ? getData?.agent_bank_number : '--'
                }
              />
            </Text>
          </Overflow>
          <Overflow>
            <Text as="p">Agent Chain Number</Text>
            <Text as="p">
              <IdCustomToolTip
                toolTip={`Copy Agent Chain Number to clipboard ${getData?.agent_chain_number}`}
                id={
                  getData?.agent_chain_number
                    ? getData?.agent_chain_number
                    : '--'
                }
              />
            </Text>
          </Overflow>
        </LeftColumns>
        <RightColumns>
          <FirtColumn>
            <Text as="p">Processor</Text>
            <Text as="p">
              <b>{getData?.processor ? getData?.processor : '--'}</b>
            </Text>
          </FirtColumn>
          <Overflow>
            <Text as="p">Software</Text>
            <Text as="p">
              <b>{getData?.software ? getData?.software : '--'}</b>
            </Text>
          </Overflow>
          <Overflow>
            <Text as="p">Payment Types</Text>
            <Text as="p">
              <b>
                {getData?.payment_types
                  ? getData?.payment_types
                      .map(function (val) {
                        return val;
                      })
                      .join(', ')
                  : '--'}
              </b>
            </Text>
          </Overflow>
          <Overflow>
            <Text as="p">Descriptor Name</Text>
            <Text as="p">
              <b>
                {getData?.descriptor_name ? getData?.descriptor_name : '--'}
              </b>
            </Text>
          </Overflow>
        </RightColumns>
      </BothColumns>
    </DrawerBgColour>
  );
};
