import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMinus, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  RegistrationUnderwritingSubStatus,
  RegistrationUnderwritingSubStatusKey,
} from '@fattmerchantorg/types-omni';
import { StaxButton } from '../../../shared/Stax/Button';
import { UnderwritingSection } from '../shared/UnderwritingSection';
import {
  ModalContext,
  openModal,
  SelectedMerchantStore,
} from '../../../../context';
import { useAuthToken, useToaster } from '../../../../hooks';
import { UpdateUnderwritingStatusFormModal } from './update/UpdateUnderwritingStatusFormModal';
import { SubmitApplicationFormModal } from './submit/SubmitApplicationFormModal';
import {
  getUnderwritingStatusReasonsTitle,
  underwritingStatusApprovedReasons,
  underwritingStatusPendedReasons,
  underwritingStatusRejectedReasons,
} from '../../../../util/underwriting.util';
import { UnderwritingContext } from '../../context';
import { ProcessorName } from '@fattmerchantorg/types-engine/DB';
import { getOnboardingPortfolios } from '../../../../util/catan.util';

type Status = 'PENDED' | 'APPROVED' | 'REJECTED';

const statusColors = {
  PENDED: '#F8DC3D',
  APPROVED: '#28CB35',
  REJECTED: '#FF4646',
};

export interface StatusProps {}

const ScrollContainer = styled.div`
  position: relative;
  height: 100%;
  min-height: 250px;
  flex-grow: 1;
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
`;
const CardHeader = styled.h2<{ status: Status }>`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;
  color: ${props => statusColors[props.status]} !important;
`;

const CardButton = styled(StaxButton)`
  width: 50%;
  font-weight: 700;
`;
const ReasonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-transform: capitalize;
  gap: 8px;
  padding-bottom: 8px;
`;
const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  margin-bottom: auto;
  border-top: 1px solid ${({ theme }) => theme.lightNeutralOutline};
`;

const ButtonBar = styled.div`
  display: flex;
  align-items: flex-end;

  > button:last-child {
    margin: 0 0 0 ${({ theme }) => theme.space[4]}px;
  }
`;

const reasonFormatter = (str: string) => str.replace(/_/g, ' ');

interface ReasonProps {
  name: string;
  status: 'pass' | 'fail';
}

const ReasonItem: React.FC<ReasonProps> = props => {
  const { name, status } = props;

  const icon = {
    pass: (
      <FontAwesomeIcon
        style={{ marginRight: '.5rem' }}
        color="#435E70"
        icon={faMinus as IconProp}
        fixedWidth
      />
    ),
    fail: (
      <FontAwesomeIcon
        style={{ marginRight: '.5rem' }}
        color="#FF4646"
        icon={faTimes as IconProp}
        fixedWidth
      />
    ),
  };

  return (
    <span>
      {icon[status]}
      {reasonFormatter(name)}
    </span>
  );
};

const SectionTitle = styled.div`
  margin-bottom: 0.75rem;
`;

export function Status(props: StatusProps) {
  const authToken = useAuthToken();
  const { modalDispatch } = useContext(ModalContext);
  const { underwritingDispatch } = useContext(UnderwritingContext);
  const {
    state: { merchant, merchantId, registration, brand, gateways },
    dispatch,
  } = useContext(SelectedMerchantStore);
  const { toaster, toast } = useToaster();
  const status = registration?.underwriting_status;
  let reasons: {
    [K in RegistrationUnderwritingSubStatusKey]?: RegistrationUnderwritingSubStatus;
  } = {};

  const [isRegistrationLoading, setIsRegistrationLoading] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      registration &&
      typeof registration === 'object' &&
      Object.keys(registration).length
    ) {
      setIsRegistrationLoading(true);
    } else {
      setIsRegistrationLoading(false);
    }
  }, [registration]);

  switch (status) {
    case 'APPROVED':
      reasons = underwritingStatusApprovedReasons;
      break;
    case 'PENDED':
      reasons = underwritingStatusPendedReasons;
      break;
    case 'REJECTED':
      reasons = underwritingStatusRejectedReasons;
      break;
  }

  const registrationUnderwritingSubstatuses =
    registration?.underwriting_substatuses;
  const underwritingSubstatuses = useMemo(() => {
    return Array.isArray(registrationUnderwritingSubstatuses)
      ? registrationUnderwritingSubstatuses?.map(rs => rs.key)
      : [];
  }, [registrationUnderwritingSubstatuses]);

  // a merchant is conditionally approved if their underwriting status is approved
  // but they have an underwriting substatus
  // and if the brand allows a merchant to be conditionally approved
  const isConditionallyApproved =
    status === 'APPROVED' &&
    underwritingSubstatuses.length > 0 &&
    brand?.data?.flags?.allowConditionalApproval;
  // always show reasons if brand allows a merchant to be conditionally approved
  const shouldShowReasons = brand?.data?.flags?.allowConditionalApproval;

  const handleUpdateClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    modalDispatch(
      openModal(UpdateUnderwritingStatusFormModal, {
        isOpen: true,
        registration,
        gateways,
        brandData: brand?.data,
        merchantId,
        merchantDispatch: dispatch,
      })
    );
  };

  const handleSubmitClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    try {
      const portfolios = (await getOnboardingPortfolios(authToken)).data.filter(
        p => p.processors.length
      );
      if (portfolios.length) {
        if (
          portfolios.length > 1 ||
          (portfolios.length === 1 &&
            portfolios[0].payfac_name !== 'TESTPAYFAC')
        ) {
          // add Stax Payfac (Finix)
          portfolios.push(
            {
              payfac_id: 'stax_payfac_finix',
              payfac_name: 'Stax Payfac (Finix)',
              state: 'ACTIVE',
              created_at: '',
              updated_at: '',
              deleted_at: '',
              type: 'PAYFAC',
              settlement_management: true,
              auto_settlement: false,
              brands: [],
              // Add processors supported by Finix
              processors: [
                {
                  processor_name: 'CORE_FINIX' as ProcessorName,
                  environment: 'PROD' as const,
                  processor_external_account_id: '05115M',
                },
                {
                  processor_name: 'LITLE_FINIX' as ProcessorName,
                  environment: 'PROD' as const,
                  processor_external_account_id: '315300',
                },
              ],
            }
            //TODO: Undo comment when Finix Payfac (Finix) is fully developed
            // {
            //   payfac_id: 'finix_payfac_finix',
            //   payfac_name: 'Finix Payfac (Finix)',
            //   state: 'ACTIVE',
            //   created_at: '',
            //   updated_at: '',
            //   deleted_at: '',
            //   type: 'PAYFAC',
            //   finix_credential_id: 'finix-fis-payfac-live',
            //   settlement_management: true,
            //   auto_settlement: false,
            //   brands: [],
            //   // Add processors supported by Finix
            //   processors: [
            //     {
            //       processor_name: 'CORE_FINIX' as ProcessorName,
            //       environment: 'PROD' as const,
            //       // TODO: get new ids from new finix platform
            //       processor_external_account_id: 'TBD',
            //     },
            //     {
            //       processor_name: 'LITLE_FINIX' as ProcessorName,
            //       environment: 'PROD' as const,
            //       // TODO: get new ids from new finix platform
            //       processor_external_account_id: 'TBD',
            //     },
            //   ],
            // }
          );
        }
      }

      if (portfolios.length) {
        modalDispatch(
          openModal(SubmitApplicationFormModal, {
            isOpen: true,
            registration,
            merchantId,
            gateways,
            merchantDispatch: dispatch,
            portfolios: portfolios,
            underwritingDispatch: underwritingDispatch,
            merchant,
          })
        );
      } else {
        toaster(
          toast.error(
            'There are no active portfolios',
            'There was a problem loading processor information.'
          )
        );
      }
    } catch (error) {
      toaster(
        toast.error(error, 'There was a problem loading processor information.')
      );
    }
  };

  return (
    <UnderwritingSection
      title="Status"
      id="underwriting-status"
      footer={
        <Footer
          handleSubmit={handleSubmitClick}
          handleUpdate={handleUpdateClick}
          isRegistrationLoading={isRegistrationLoading}
        />
      }
    >
      {({ setToolTipContent }) => {
        setToolTipContent('Internal underwriting status and notes section.');
        return (
          <ScrollContainer>
            <Content>
              <CardBody>
                <CardHeader status={status} data-testid="status-card-header">
                  {isConditionallyApproved ? 'CONDITIONALLY APPROVED' : status}
                </CardHeader>
                {shouldShowReasons && (
                  <div>
                    <SectionTitle data-testid="status-reasons-title">
                      {getUnderwritingStatusReasonsTitle(
                        status,
                        registration?.underwriting_substatuses
                      )}
                    </SectionTitle>
                    <ReasonsContainer>
                      {Object.keys(reasons).map(
                        (key: RegistrationUnderwritingSubStatusKey) => (
                          <ReasonItem
                            key={key}
                            name={reasons[key]}
                            status={
                              underwritingSubstatuses.includes(key)
                                ? 'fail'
                                : 'pass'
                            }
                          />
                        )
                      )}
                    </ReasonsContainer>
                  </div>
                )}
                <NotesContainer>
                  <SectionTitle>
                    <strong>Notes</strong>
                  </SectionTitle>
                  {/* <p>{registration?.underwriting_note || '-'}</p> */}
                  {registration?.underwriting_note
                    ? registration.underwriting_note
                        .split('\n')
                        .map(p => <p>{p}</p>)
                    : '-'}
                </NotesContainer>
              </CardBody>
            </Content>
          </ScrollContainer>
        );
      }}
    </UnderwritingSection>
  );
}

const Footer = ({ handleSubmit, handleUpdate, isRegistrationLoading }) => (
  <ButtonBar>
    <CardButton
      onClick={handleSubmit}
      size="small"
      disabled={!isRegistrationLoading}
    >
      Submit
    </CardButton>
    <CardButton
      onClick={handleUpdate}
      size="small"
      disabled={!isRegistrationLoading}
    >
      Update
    </CardButton>
  </ButtonBar>
);
