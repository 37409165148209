import { Dispatch, ReactNode } from 'react';
import { Import } from '../../@types';

export interface ImportContextProps {
  children: ReactNode;
}

export interface ImportState {
  record: Import | null;
  mapping: Import['mapping'] | null;
  targetColumns: string[];
  availableTargetColumns: string[];
  unavailableTargetColumns: string[];
  unspecifiedColumns: string[];
  hasMappingChanged: boolean;
  effect: { type: ImportEffectType; payload?: any } | null;
}

export enum ImportActionType {
  UPDATE_IMPORT = 'UPDATE_IMPORT',
  UPDATE_MAPPING_COLUMN = 'UPDATE_MAPPING_COLUMN',
  UPDATE_TARGET_COLUMNS = 'UPDATE_TARGET_COLUMNS',
  AUTO_SPECIFY_UNSPECIFIED_MAPPING_COLUMNS = 'AUTO_SPECIFY_UNSPECIFIED_MAPPING_COLUMNS'
}

export enum ImportEffectType {
  UPDATE_IMPORT = 'UPDATE_IMPORT',
  UPDATE_MAPPING_COLUMN = 'UPDATE_MAPPING_COLUMN',
  UPDATE_TARGET_COLUMNS = 'UPDATE_TARGET_COLUMNS',
  AUTO_SPECIFY_UNSPECIFIED_MAPPING_COLUMNS = 'AUTO_SPECIFY_UNSPECIFIED_MAPPING_COLUMNS'
}

export type ImportAction = { type: ImportActionType; payload?: any };

export interface ImportContext {
  state: ImportState;
  dispatch: Dispatch<ImportAction>;
}
