import axios from 'axios';
import get from 'lodash/get';
import { Registration } from '@fattmerchantorg/types-omni';
import { RequiredFieldErrors } from '../@types';
import { getHeaders } from '../util/api.util';

// https://developers.google.com/maps/documentation/places/web-service/search-find-place
interface GooglePlaceSearchResponse {
  candidates: object[];
  status: { Code: string; Description: string };
  error_message?: string;
  info_messages?: string;
}

export interface GoogleVerifyBusinessReport {
  BusinessLegalNameResponse: GooglePlaceSearchResponse;
  BusinessDBAResponse: GooglePlaceSearchResponse;
}

export const googleVerifyBusiness = async (
  jwt: string,
  registration: Registration,
  merchantId: string
): Promise<
  | { report: GoogleVerifyBusinessReport; servicesRan: string[] }
  | RequiredFieldErrors
> => {
  const {
    business_legal_name: BusinessLegalName,
    business_dba: BusinessDBA,
    business_location_address_1: BusinessAddressLine1,
    business_location_address_2: BusinessAddressLine2,
    business_location_address_city: BusinessCity,
    business_location_address_state: BusinessState,
    business_location_address_zip: BusinessZip,
  } = registration;

  const payload = {
    Services: { GoogleVerifyBusiness: true },
    BusinessLegalName: !!BusinessLegalName ? BusinessLegalName : undefined,
    BusinessDBA: !!BusinessDBA ? BusinessDBA : undefined,
    BusinessAddressLine1: !!BusinessAddressLine1
      ? BusinessAddressLine1
      : undefined,
    BusinessAddressLine2: !!BusinessAddressLine2
      ? BusinessAddressLine2
      : undefined,
    BusinessCity: !!BusinessCity ? BusinessCity : undefined,
    BusinessState: !!BusinessState ? BusinessState : undefined,
    BusinessZip: !!BusinessZip ? BusinessZip : undefined,
    merchantId,
  };

  try {
    const verifyBusiness = await axios({
      url: `${process.env.REACT_APP_ONBOARDING_URL}/verify-business-exist`,
      data: payload,
      method: 'post',
      headers: getHeaders(jwt),
    });
    return verifyBusiness.data;
  } catch (error) {
    const requiredFields = get(error, 'response.data.report.errors', false);

    if (!!requiredFields) {
      return {
        requiredFields: requiredFields,
      };
    }

    throw new Error(error);
  }
};
