import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../../../@types/ThemeProps';

export const Container = styled.div`
  color: ${props => props.theme.colors.core.gray[500].hex};
  border-top: 1px solid
    ${(props: ThemeProps) => props.theme.lightNeutralOutline};
  text-align: center;
  padding-top: 24px;
`;

export const TableNullState: FunctionComponent = props => {
  return (
    <Container>
      <div>{props.children}</div>
    </Container>
  );
};
