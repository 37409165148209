import React from 'react';

import {
  Pagination as RSPagination,
  PaginationItem,
  PaginationLink
} from 'reactstrap';

import { PaginationProps } from './Pagination.types';

const renderPageButton = (
  i: number,
  currentPage: number,
  onPageSelect: (index: number) => any
) => {
  return (
    <PaginationItem
      className={currentPage === i ? 'active' : ''}
      key={`pagination-item-${i}`}
    >
      <PaginationLink
        onClick={e => {
          onPageSelect(i);
          e.preventDefault();
        }}
      >
        {+i}
      </PaginationLink>
    </PaginationItem>
  );
};

function getPageButtonIndices(
  numberOfPages: number,
  currentPage: number
): number[] {
  let indicies = [];
  let startPage, endPage;

  if (currentPage < 5) {
    // if current page is less than 5
    // just render the first 6 pages (1 - 6)
    // total number of pages is 7 (not 6) since the last page gets added by the logic below
    startPage = 1;
    endPage = Math.min(6, numberOfPages);
  } else if (currentPage + 3 >= numberOfPages) {
    // else if the current page + 3 is equal or greater than the total number of pages
    // just render the last 6 pages
    // total number of pages is 7 since the first page gets added by the logic below
    startPage = Math.max(1, numberOfPages - 5);
    endPage = numberOfPages;
  } else {
    // else
    // just render 2 previous, current, and 2 next pages
    // total number of pages is still 7 since the first and last pages get added by the logic below
    startPage = Math.max(1, currentPage - 2);
    endPage = Math.min(numberOfPages, currentPage + 2);
  }

  if (startPage !== 1) {
    // if the start page isn't 1, render a button for the first (1) page
    indicies.push(1);
  }

  for (let i = startPage; i <= endPage; i++) {
    indicies.push(i);
  }

  if (endPage !== numberOfPages) {
    indicies.push(numberOfPages);
  }

  return indicies;
}

export function Pagination(props: PaginationProps): JSX.Element {
  const { currentPage, numberOfPages, onPageSelect } = props;
  const pageButtonIndices = getPageButtonIndices(numberOfPages, currentPage);

  return (
    <nav aria-label="...">
      <RSPagination
        className="pagination justify-content-end mb-0"
        listClassName="justify-content-end mb-0"
      >
        <PaginationItem className={currentPage === 1 ? 'disabled' : ''}>
          <PaginationLink
            tabIndex={-1}
            onClick={e => {
              e.preventDefault();
              onPageSelect(Math.max(currentPage - 1, 1));
            }}
          >
            <i className="fas fa-angle-left" />
            <span className="sr-only">Previous</span>
          </PaginationLink>
        </PaginationItem>
        {pageButtonIndices.map(index =>
          renderPageButton(index, currentPage, onPageSelect)
        )}
        <PaginationItem
          className={currentPage === numberOfPages ? 'disabled' : ''}
        >
          <PaginationLink
            tabIndex={-1}
            onClick={e => {
              e.preventDefault();
              onPageSelect(Math.min(currentPage + 1, numberOfPages));
            }}
          >
            <i className="fas fa-angle-right" />
            <span className="sr-only">Next</span>
          </PaginationLink>
        </PaginationItem>
      </RSPagination>
    </nav>
  );
}
