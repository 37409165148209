import { Omni } from '@fattmerchantorg/types-omni';
import { useEffect, useState } from 'react';
import { usePermissions } from './usePermissions';

export type FeaturePermissions = Omni.Permissions['permissions']['features'];
export type FeatureCategory = keyof FeaturePermissions;
export type FeatureAddon = {
  type: string;
  name: string;
  item_id: string;
};

export function getAvailableAddonForFeature<
  Category extends keyof FeaturePermissions
>(
  categoryName: Category,
  featureName: keyof FeaturePermissions[Category],
  availableAddons: { [key: string]: any }
): { type: string; name: string; item_id: string } | null {
  return availableAddons?.[categoryName]?.[featureName] ?? null;
}

export function getFeatureLevel<Category extends keyof FeaturePermissions>(
  categoryName: Category,
  featureName: keyof FeaturePermissions[Category] = null,
  featurePermissions: FeaturePermissions = {}
): null | 'available-addon' | 'available' {
  const category: Partial<Omni.FeaturePermissions>[Category] =
    featurePermissions?.[categoryName];

  if (!featureName && !category) {
    // if we're using the shorthand usage of this function
    // i.e. getFeatureLevel('CustomersList')
    // and there aren't ANY features available for this category
    // then return `null`, since they don't have any of the applicable features
    return null;
  } else if (!featureName && category) {
    // if we're using the shorthand usage of this function
    // i.e. getFeatureLevel('CustomersList')
    // and there's at least one active feature
    // then return 'active'
    // else if all features are addons
    // then return 'available-addon'
    const featureLevels = Object.values(category);

    const isAtLeastOneActive = featureLevels.some(
      f => f === 'write' || f === true
    );

    if (isAtLeastOneActive) {
      return 'available';
    }

    const areAllAddons = featureLevels.every(f => f === 'available-addon');

    if (areAllAddons) {
      return 'available-addon';
    }

    return null;
  }

  const featureLevel: Omni.FeatureLevel = category?.[featureName] as any;

  if (featureLevel === 'write' || featureLevel === true) {
    return 'available';
  } else if (featureLevel === 'available-addon') {
    return 'available-addon';
  } else {
    return null;
  }
}

/**
 * @typedef {Object} FeatureFlagReturn
 * @property {boolean} available - if all given features are active
 * @property {boolean} unavailable - if all given features are addon
 * @property {boolean} availableWithAddon - if all given features are inactive
 * @property {boolean} atLeastOneAvailable - if at least one given feature is active
 * @property {boolean} atLeastOneAvailableWithAddon - if at least one given feature is addon
 * @property {boolean} atLeastOneUnavailable - if at least one given feature is inactive
 */

/**
 *
 * @param category the feature category. e.g. 'CustomersList'
 * @param features a list of features for that category. e.g. 'Export', 'AddNewCustomer', 'Merge'
 * @returns {FeatureFlagReturn}
 */
export function useFeatureFlag<C extends FeatureCategory>(
  category: C,
  ...features: (keyof FeaturePermissions[C])[]
) {
  const { permissions } = usePermissions();

  const [allAvailable, setAllAvailable] = useState(false);
  const [allAvailableWithAddon, setAllAvailableWithAddon] = useState(false);
  const [allUnavailable, setAllUnavailable] = useState(false);
  const [atLeastOneAvailable, setAtLeastOneAvailable] = useState(false);
  const [
    atLeastOneAvailableWithAddon,
    setAtLeastOneAvailableWithAddon
  ] = useState(false);
  const [atLeastOneUnavailable, setAtLeastOneUnavailable] = useState(false);
  const [addons, setAddons] = useState<FeatureAddon[]>([]);

  // we use joinedFeatures as a dependency for useEffect
  // to prevent the useEffect from firing every render
  // if features is not memoized
  const joinedFeatures = features.join('|');

  useEffect(() => {
    const features = joinedFeatures.split(
      '|'
    ) as (keyof FeaturePermissions[C])[];

    let atLeastOneAvailable = false;
    let atLeastOneAvailableWithAddon = false;
    let atLeastOneUnavailable = false;

    function setFlagsByFeatureLevel(
      featureLevel: 'available-addon' | 'available' | null
    ) {
      switch (featureLevel) {
        case 'available':
          atLeastOneAvailable = true;
          break;
        case 'available-addon':
          atLeastOneAvailableWithAddon = true;
          break;
        case null:
          atLeastOneUnavailable = true;
          break;
      }
    }

    if (!features || !features.length) {
      // if we're using the shorthand usage of component
      // i.e. <FeatureFlag category="CustomersList">
      // then just do a check on the category
      const featureLevel = getFeatureLevel(
        category,
        null,
        permissions?.features
      );
      setFlagsByFeatureLevel(featureLevel);
    } else {
      let addons: FeatureAddon[] = [];
      // otherwise check each feature provided in props
      features.forEach(f => {
        const featureLevel = getFeatureLevel(
          category,
          f,
          permissions?.features
        );

        if (featureLevel === 'available-addon') {
          const addon = getAvailableAddonForFeature(
            category,
            f,
            (permissions as any)?.availableAddons
          );

          addons.push(addon || null);
        }

        setFlagsByFeatureLevel(featureLevel);
      });

      setAddons(addons);
    }

    setAtLeastOneAvailable(atLeastOneAvailable);
    setAtLeastOneAvailableWithAddon(atLeastOneAvailableWithAddon);
    setAtLeastOneUnavailable(atLeastOneUnavailable);

    // if there's at least one active, but no addons and no inactives
    // that means all are active
    setAllAvailable(
      atLeastOneAvailable &&
        !atLeastOneAvailableWithAddon &&
        !atLeastOneUnavailable
    );
    // if there's at least one addon, but no active and no inactives
    // that means all are addons
    setAllAvailableWithAddon(
      !atLeastOneAvailable &&
        atLeastOneAvailableWithAddon &&
        !atLeastOneUnavailable
    );
    // if there's at least one inactive, but no active and no addons
    // that means all are inactive
    setAllUnavailable(
      !atLeastOneAvailable &&
        !atLeastOneAvailableWithAddon &&
        atLeastOneUnavailable
    );
  }, [category, joinedFeatures, permissions]);

  return {
    available: allAvailable,
    unavailable: allUnavailable,
    availableWithAddon: allAvailableWithAddon,
    addon: addons?.[0],
    addons: addons,
    atLeastOneAvailable,
    atLeastOneAvailableWithAddon,
    atLeastOneUnavailable
  };
}
