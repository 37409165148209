import React, { createContext, useContext, useReducer } from 'react';

interface StatementSummaryState {
  totalCharges: number | null;
  totalSales: number | null;
  totalFees: number | null;
  refundsAdjustments: number | null;
  totalDeposited: number | null;
}

const statementSummaryInitialState = {
  totalCharges: null,
  totalSales: null,
  totalFees: null,
  refundsAdjustments: null,
  totalDeposited: null,
};

enum ActionTypes {
  UPDATE_TOTALCHARGES = 'UPDATE_TOTALCHARGES',
  UPDATE_TOTALSALES = 'UPDATE_TOTALSALES',
  UPDATE_TOTALFEES = 'UPDATE_TOTALFEES',
  UPDATE_REFUNDSADJUSTMENTS = 'UPDATE_REFUNDSADJUSTMENTS',
  UPDATE_TOTALDEPOSITED = 'UPDATE_TOTALDEPOSITED',
}

type Action = {
  type: ActionTypes;
  payload: any;
};

const statementSummaryReducer = (
  state: StatementSummaryState,
  action: Action
) => {
  switch (action.type) {
    case ActionTypes.UPDATE_TOTALCHARGES:
      return { ...state, totalCharges: action.payload };
    case ActionTypes.UPDATE_TOTALSALES:
      return { ...state, totalSales: action.payload };
    case ActionTypes.UPDATE_TOTALFEES:
      return { ...state, totalFees: action.payload };
    case ActionTypes.UPDATE_REFUNDSADJUSTMENTS:
      return { ...state, refundsAdjustments: action.payload };
    case ActionTypes.UPDATE_TOTALDEPOSITED:
      return { ...state, totalDeposited: action.payload };
    default:
      return state;
  }
};

interface Context {
  statementSummaryState: StatementSummaryState;

  updateTotalCharges: (value: number) => void;
  updateTotalSales: (value: number) => void;
  updateTotalFees: (value: number) => void;
  updateRefundsAdjustments: (value: number) => void;
  updateTotalDeposited: (value: number) => void;
}

export const StatementSummaryContext = createContext<Context>({
  statementSummaryState: statementSummaryInitialState,
} as Context);

export function StatementSummaryContextProvider({ children }) {
  const [statementSummaryState, statementSummaryDispatch] = useReducer(
    statementSummaryReducer,
    statementSummaryInitialState
  );

  const updateTotalCharges = (value: number) =>
    statementSummaryDispatch({
      type: ActionTypes.UPDATE_TOTALCHARGES,
      payload: value,
    });
  const updateTotalSales = (value: number) =>
    statementSummaryDispatch({
      type: ActionTypes.UPDATE_TOTALSALES,
      payload: value,
    });
  const updateTotalFees = (value: number) =>
    statementSummaryDispatch({
      type: ActionTypes.UPDATE_TOTALFEES,
      payload: value,
    });
  const updateRefundsAdjustments = (value: number) =>
    statementSummaryDispatch({
      type: ActionTypes.UPDATE_REFUNDSADJUSTMENTS,
      payload: value,
    });
  const updateTotalDeposited = (value: number) =>
    statementSummaryDispatch({
      type: ActionTypes.UPDATE_TOTALDEPOSITED,
      payload: value,
    });

  return (
    <StatementSummaryContext.Provider
      value={{
        statementSummaryState,
        updateTotalCharges,
        updateTotalSales,
        updateTotalFees,
        updateRefundsAdjustments,
        updateTotalDeposited,
      }}
    >
      {children}
    </StatementSummaryContext.Provider>
  );
}

// Custom hook for using the context
export const useStatementSummaryState = () => {
  const context = useContext(StatementSummaryContext);
  if (!context) {
    throw new Error('Statement Summary Context Error');
  }
  return context;
};
