export const resolveValidationField = (fieldData: {
  field: string;
  text: string;
}) => {
  switch (fieldData.field) {
    case 'ownership_type':
      return 'company_type';
    case 'registration.business_name':
      return 'business_legal_name';
    case 'registration.business_tax_id':
      return 'business_tax_id';
    case 'registration.business_phone':
      return 'business_phone_number';
    case 'registration.incorporation_date.month':
      return 'business_open_date';
    case 'registration.business_address.country':
      return 'business_address_country';
    case 'registration.business_address.region':
      return 'business_address_state';
    case 'registration.tax_id':
      return '';
    case 'registration.dob.month':
      return 'user_dob';
    case 'registration.personal_address.region':
      return 'owner_address_state';
    case 'registration.personal_address.country':
      return 'owner_address_country';
    case 'registration.default_statement_descriptor':
      return '';
    default:
      break;
  }

  if (fieldData.field && fieldData.field.match(/^identities\[\d+\]\./)) {
    return fieldData.field.replace(/^identities\[\d+\]\./, '');
  } else if (
    fieldData.field &&
    fieldData.field.match(/^registration\.meta\.representatives\.\d+\.\w/)
  ) {
    return fieldData.field.substring(13);
  } else if (fieldData.field && fieldData.field.match(/^registration\./)) {
    return fieldData.field.replace(/^registration\./, '').replace('.', '_');
  } else if (!fieldData.field && fieldData.text) {
    if (fieldData.text.match(/Card Present Discount Fees/i)) {
      return 'cp_transaction_rate';
    } else if (fieldData.text.match(/Card Present Transaction Amount/i)) {
      return 'cp_per_item_rate';
    }
  }
  return fieldData.field;
};
