import {
  AuthData,
  AuthAction,
  AuthActionType,
  OptimisticUIStatuses,
  SupportAuthData,
} from '../types/Auth.types';
import { User, Merchant } from '@fattmerchantorg/types-omni';

/**
 * Action creator for the action `USER_CLICKED_MODE_TOGGLE_SWITCH`,
 * when a user clicks on the switch to toggle their current mode (test mode or live mode).
 */
export function userClickedModeToggleSwitch(): AuthAction {
  return { type: AuthActionType.USER_CLICKED_MODE_TOGGLE_SWITCH };
}

/**
 * Action creator for the action `USER_SELECTED_MERCHANT_OUTSIDE_MODE`,
 * when a user selects a merchant with a status (sandbox or live)
 * that differs outside of their current mode (test mode or live mode).
 */
export function userSelectedMerchantOutsideMode(): AuthAction {
  return { type: AuthActionType.USER_SELECTED_MERCHANT_OUTSIDE_MODE };
}

/**
 * Action creator for the action `UPDATE_AUTH_WITH_REDIRECT`, when we receive set of JWTs from the Core API.
 * Note: this action will eventually result in a page redirect.
 */
export function updateAuthWithRedirect(payload: AuthData): AuthAction {
  return { type: AuthActionType.UPDATE_AUTH_WITH_REDIRECT, payload };
}

/**
 * Action creator for the action `UPDATE_AUTH_WITH_DIALOG`, when we receive set of JWTs from the Core API.
 * Note: this action will show a dialog prompt to the user to indicate that the auth mode has changed.
 */
export function updateAuthWithDialog(payload: AuthData): AuthAction {
  return { type: AuthActionType.UPDATE_AUTH_WITH_DIALOG, payload };
}

/**
 * Action creator for the action `UPDATE_AUTH`, when we receive set of JWTs from the Core API.
 * Note: if a page redirect is needed, use the `UPDATE_AUTH_WITH_REDIRECT` action.
 */
export function updateAuth(payload: AuthData): AuthAction {
  return { type: AuthActionType.UPDATE_AUTH, payload };
}

export function removeAuth(): AuthAction {
  return { type: AuthActionType.REMOVE_AUTH };
}

export function updateAuthUser(payload: User): AuthAction {
  return { type: AuthActionType.UPDATE_AUTH_USER, payload };
}

export function updateAuthMerchant(payload: Merchant): AuthAction {
  return { type: AuthActionType.UPDATE_AUTH_MERCHANT, payload };
}

export function updateAuthIndex(payload: number): AuthAction {
  return { type: AuthActionType.UPDATE_AUTH_INDEX, payload };
}

// Controls status of "Optimistic UI" when changing modes.
export function updateOptimisticUIStatus(
  payload: OptimisticUIStatuses
): AuthAction {
  return { type: AuthActionType.UPDATE_OPTIMISTIC_UI_STATUS, payload };
}

export function updateMerchantToken(payload: string | null): AuthAction {
  return { type: AuthActionType.UPDATE_MERCHANT_TOKEN, payload };
}

export function updateSupportAuth(payload: SupportAuthData): AuthAction {
  return { type: AuthActionType.UPDATE_SUPPORT_AUTH_TOKEN, payload };
}
