import React, { FunctionComponent, useContext, useMemo } from 'react';
import { Section, Separator } from './BankAccountDrawerStyles';
import { Text } from '@fattmerchantorg/truffle-components';
import { FundingAccountAuditLog } from '@fattmerchantorg/types-omni';
import styled from 'styled-components';
import { SelectedMerchantStore } from '../../../../context';
import { renderTimestamp } from '../../util/BankAccountsPage.util';
import { LoadingSpan } from '../../../shared';

const DateText = styled(Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.core.gray[200].hex};
  margin-bottom: 4px;
`;

const ContentText = styled(Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
`;

const ContentTextBold = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
`;

const AddAccountContentText = styled(Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
`;

const Container = styled.div`
  padding-top: 16px;
`;

const HistorySeparator = styled(Separator)`
  padding-top: 8px;
`;

const HistorySection = styled(Section)`
  padding-top: 0px;
  overflow: scroll;
  padding-bottom: 78px;
`;

const checkFieldValue = (fieldValue: string, fieldName: string): boolean => {
  if (fieldName === 'flags') {
    return fieldValue !== null;
  }
  return fieldValue !== null && fieldValue !== '';
};

const processFieldValue = (field: string, fieldValue: string): string => {
  if (field === 'last_four') {
    return `**${fieldValue.toString()}`;
  }

  if (field === 'flags') {
    return mapFlagsType(fieldValue);
  }

  return fieldValue.toString();
};

const formatStatus = (status: string): string => {
  return '' !== status && null !== status
    ? status.charAt(0) + status.slice(1).toLowerCase()
    : '';
};

const mapFlagsType = (type: string): string => {
  if (type.includes('FEE')) {
    return 'Deposit & Fees';
  } else if (type.includes('IS_TRUST')) {
    return 'Trust';
  } else {
    return 'Deposit';
  }
};

const CreateFundingAccountHistoryLog: FunctionComponent<{
  log: FundingAccountAuditLog;
}> = props => {
  const { log } = props;
  const {
    state: { merchant },
  } = useContext(SelectedMerchantStore);
  const merchantUsers = merchant?.users;

  const fieldNames = Object.keys(log.result['new']);
  const allowedFields = {
    name: 'nickname',
    person_name: 'account holder name',
    bank_routing_number: 'routing number',
    last_four: 'account number',
    flags: 'account type',
  };

  const userOptions = useMemo(() => {
    return merchantUsers?.reduce((users, user) => {
      users.push({
        id: user.id,
        name: `${user.name}`,
      });

      return users;
    }, []);
  }, [merchantUsers]);

  const user = userOptions.find(o => log.user_id === o.id);

  return (
    <>
      <AddAccountContentText as="p">
        <ContentTextBold>{user?.name}</ContentTextBold> has added a new bank
        account.
      </AddAccountContentText>

      {fieldNames.map((fieldName, index) => {
        return (
          <React.Fragment key={index}>
            {checkFieldValue(log.result['new']['flags'], fieldName) &&
              allowedFields.hasOwnProperty(fieldName) &&
              fieldName === 'flags' && (
                <>
                  <ContentText as="p">
                    {allowedFields[fieldName] ?? fieldName}
                    {': '}
                    <ContentTextBold>
                      {mapFlagsType(log.result['new']['flags'])}
                    </ContentTextBold>
                  </ContentText>
                  {log.result['new']['flags']?.includes('PRIMARY') &&
                    checkFieldValue(log.result['new']['name'], fieldName) && (
                      <ContentText as="p">
                        {'primary account: '}
                        <ContentTextBold>
                          {processFieldValue('name', log.result['new']['name'])}
                        </ContentTextBold>
                      </ContentText>
                    )}
                </>
              )}

            {checkFieldValue(log.result['new'][fieldName], fieldName) &&
              allowedFields.hasOwnProperty(fieldName) &&
              fieldName !== 'flags' && (
                <ContentText as="p">
                  {allowedFields[fieldName] ?? fieldName}
                  {': '}
                  <ContentTextBold>
                    {processFieldValue(fieldName, log.result['new'][fieldName])}
                  </ContentTextBold>
                </ContentText>
              )}
          </React.Fragment>
        );
      })}
    </>
  );
};

const UpdateFundingAccountHistoryLog: FunctionComponent<{
  log: FundingAccountAuditLog;
}> = props => {
  const { log } = props;
  const fieldNames = Object.keys(log.result['new']);
  const {
    state: { merchant },
  } = useContext(SelectedMerchantStore);
  const merchantUsers = merchant?.users;

  const renameFields = {
    name: 'nickname',
    person_name: 'account holder name',
    bank_routing_number: 'routing number',
    last_four: 'account number',
    flags: 'account type',
    bank_name: 'bank name',
  };

  const customHandledLogs: Array<string> = [
    'active',
    'status',
    'deleted_at',
    'file_created_at',
    'file_deleted_at',
    'file_name',
  ];

  const userOptions = useMemo(() => {
    return merchantUsers?.reduce((users, user) => {
      users.push({
        id: user.id,
        name: `${user.name}`,
      });

      return users;
    }, []);
  }, [merchantUsers]);

  const user = userOptions.find(o => log.user_id === o.id);

  return (
    <>
      {fieldNames.map((fieldName, index) => {
        return (
          <React.Fragment key={index}>
            {checkFieldValue(log.result['new'][fieldName], fieldName) &&
              !customHandledLogs.includes(fieldName) && (
                <ContentText as="p" key={index}>
                  {user?.name}
                  {` changed `}
                  {renameFields[fieldName] ?? fieldName}
                  {checkFieldValue(log.result['old'][fieldName], fieldName)
                    ? ' from '
                    : ''}
                  <ContentTextBold>
                    {checkFieldValue(log.result['old'][fieldName], fieldName)
                      ? processFieldValue(
                          fieldName,
                          log.result['old'][fieldName]
                        )
                      : ''}
                  </ContentTextBold>
                  {' to '}
                  <ContentTextBold>
                    {processFieldValue(fieldName, log.result['new'][fieldName])}
                  </ContentTextBold>
                </ContentText>
              )}

            {!checkFieldValue(log.result['new'][fieldName], fieldName) &&
              !customHandledLogs.includes(fieldName) && (
                <ContentText as="p">
                  {user?.name}
                  {` `}
                  {renameFields[fieldName] ?? fieldName}
                  {' has been '}
                  <ContentTextBold>{' removed.'}</ContentTextBold>
                </ContentText>
              )}

            {fieldName === 'active' && (
              <ContentText as="p">
                {user?.name}
                {` set to `}
                <ContentTextBold>
                  {log.result['new'][fieldName] === true
                    ? 'Active'
                    : 'Inactive'}
                </ContentTextBold>
              </ContentText>
            )}

            {fieldName === 'flags' &&
              log.result['new']['flags']?.includes('PRIMARY') &&
              !log.result['old']['flags']?.includes('PRIMARY') && (
                <ContentText as="p">
                  {user?.name}
                  {' set account as '}
                  <ContentTextBold>{'Primary'}</ContentTextBold>
                </ContentText>
              )}

            {fieldName === 'flags' &&
              log.result['old']['flags']?.includes('PRIMARY') &&
              !log.result['new']['flags']?.includes('PRIMARY') && (
                <ContentText as="p">
                  {user?.name}
                  {' removed account as '}
                  <ContentTextBold>{'Primary'}</ContentTextBold>
                </ContentText>
              )}

            {fieldName === 'status' && (
              <ContentText as="p">
                {user?.name}
                {` set status to `}
                <ContentTextBold>
                  {formatStatus(log.result['new'][fieldName])}
                </ContentTextBold>
              </ContentText>
            )}

            {fieldName === 'deleted_at' && (
              <ContentText as="p">
                {user?.name}
                {` removed account.`}
              </ContentText>
            )}

            {log.source === 'DELETE_FUNDING_ACCOUNT_FILE' &&
              fieldName === 'file_deleted_at' && (
                <ContentText as="p">
                  {user?.name}
                  {` deleted file `}
                  <ContentTextBold>
                    {`${log.result.new.file_name}.`}
                  </ContentTextBold>
                </ContentText>
              )}

            {log.source === 'POST_FUNDING_ACCOUNT_FILE' &&
              fieldName === 'file_created_at' && (
                <ContentText as="p">
                  {user?.name}
                  {` added file `}
                  <ContentTextBold>
                    {`${log.result.new.file_name}.`}
                  </ContentTextBold>
                </ContentText>
              )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export const BankAccountDrawerHistory: FunctionComponent<{
  historyAuditLogs: FundingAccountAuditLog[];
  loading: boolean;
}> = props => {
  const { historyAuditLogs, loading } = props;

  return (
    <>
      <Section>
        <Text as="h6">History</Text>
      </Section>
      {loading ? (
        <HistorySection>
          <LoadingSpan height="56px" width="80%" />
        </HistorySection>
      ) : (
        <HistorySection>
          {historyAuditLogs.map((log, index) => {
            return (
              <Container key={index}>
                <DateText as="p">{renderTimestamp(log.created_at)}</DateText>
                {'POST_FUNDING_ACCOUNT' === log.source && (
                  <CreateFundingAccountHistoryLog log={log} />
                )}
                {('PUT_FUNDING_ACCOUNT' === log.source ||
                  'DELETE_FUNDING_ACCOUNT_FILE' === log.source ||
                  'POST_FUNDING_ACCOUNT_FILE' === log.source) && (
                  <UpdateFundingAccountHistoryLog log={log} />
                )}
                <HistorySeparator />
              </Container>
            );
          })}
        </HistorySection>
      )}
    </>
  );
};
