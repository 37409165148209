import React, { Fragment, useRef, useState } from 'react';
import { TableSectionV3 } from '../styles/table-section-component-v3';
import { Text } from '@fattmerchantorg/truffle-components';
import { StatementV3Props } from '../util/statement-v3-utils';
import styled, { useTheme } from 'styled-components';
import { useAsyncEffect, useAuthToken } from '../../../../hooks';
import { queryapi } from '../../../../api';
import {
  GetFeeStatementOverviewResponse,
  GetFeeStatementOverviewSummary,
  GetNewFeeStatementOverviewDatum,
} from '@fattmerchantorg/types-omni';
import { useStatementSummaryState } from '../../../../context/statements';
import { TableNullState } from '../styles';
import currency from 'currency.js';
import { ResponsivePie } from '@nivo/pie';
import {
  LoadingState,
  useStatementLoadingState,
} from '../../../../context/statements';
import { TextContainer } from '../../components';
import {
  convertFeesOverviewDataToList,
  getPieChartColor,
} from '../util/fees-overview';

const CHART_HEIGHT = 100;

const FeeOverviewSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const TableWrapper = styled.div`
  width: 80%;

  @media print {
    width: 100%;
  }
`;

const ChartWrapper = styled.div`
  height: ${CHART_HEIGHT}px;
  width: 100%;
`;

const PieChart = styled.div`
  height: ${CHART_HEIGHT}px;
  width: 100%;
  z-index: 10;
`;

const ChartSection = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media print {
    display: none;
  }
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PieChartFeesSection = styled.div`
  display: grid;
  top: -50px;
  text-align: center;
  position: relative;

  span {
    font-size: 18px;
    line-height: 27px;
    font-weight: 700;
    color: ${({ theme }) => theme.white};
  }

  p {
    font-size: 12px;
    margin: 0px !important;
    line-height: 14px;
    color: ${({ theme }) => theme.white};
  }
`;

const FeeOverviewTable = styled(TableSectionV3)`
  table {
    tr {
      td,
      th {
        &:last-child {
          text-align: right !important;
        }
        &:first-child {
          text-align: left !important;
        }
      }
    }
  }
`;

const FeesPieChart: React.FC<{
  data: GetNewFeeStatementOverviewDatum[];
  summary: GetFeeStatementOverviewSummary;
}> = props => {
  const { data, summary } = props;
  const feeDetailsRef = useRef(null);
  const theme = useTheme() as any;

  // Prepare data for chart
  let pieChartData = [];
  if (summary.total > 0) {
    pieChartData = data.map((fee, idx) => ({
      id: fee.name.replace(/\s/g, '-').toLowerCase(),
      label: fee.name,
      value: fee.amount,
      color: getPieChartColor(idx),
    }));
  } else {
    pieChartData = [
      {
        id: 'no_fees',
        label: 'Total Fees',
        value: 100,
        color: theme.colors.core.gray[50].hex,
      },
    ];
  }

  return (
    <ChartWrapper>
      <PieChart>
        <ResponsivePie
          data={pieChartData}
          margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
          startAngle={-90}
          endAngle={90}
          innerRadius={0.8}
          activeOuterRadiusOffset={8}
          sortByValue={false}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          colors={{ datum: 'data.color' }}
          onMouseEnter={(node, event) => {
            feeDetailsRef.current.innerHTML = `<span>${
              summary.total > 0
                ? currency(node.value).format()
                : currency(summary.total).format()
            }</span><p>${node.data.label}</p>`;
          }}
          onMouseLeave={(node, event) => {
            feeDetailsRef.current.innerHTML = `<span>${currency(
              summary.total
            ).format()}</span><p>Total Fees</p>`;
          }}
          tooltip={e => {
            return <></>;
          }}
        />
      </PieChart>
      <PieChartFeesSection
        id="pieChartFeeTypeDisplaySection"
        ref={feeDetailsRef}
      >
        <Text as="span">{currency(summary.total).format()}</Text>
        <Text as="p">{'Total Fees'}</Text>
      </PieChartFeesSection>
    </ChartWrapper>
  );
};

export const FeesOverviewSection: React.FC<StatementV3Props> = React.forwardRef(
  (props, ref) => {
    const { startDate, endDate } = props;
    const authToken = useAuthToken();
    const { updateTotalFees } = useStatementSummaryState();
    const [data, setData] = useState<GetNewFeeStatementOverviewDatum[]>(null);
    const [summary, setSummary] =
      useState<GetFeeStatementOverviewResponse['summary']>(null);
    const { feesOverviewLoading, setFeesOverviewLoading } =
      useStatementLoadingState();

    useAsyncEffect(async () => {
      if (
        feesOverviewLoading === LoadingState.Loading &&
        authToken &&
        startDate &&
        endDate
      ) {
        try {
          const query = { startDate, endDate };
          const data = await queryapi.get<GetFeeStatementOverviewResponse>(
            authToken,
            'statement/v3/fees',
            query
          );
          let fees;
          if (!Array.isArray(data.data)) {
            fees = convertFeesOverviewDataToList(data.data);
          } else {
            fees = data.data;
          }
          setData(fees);
          setSummary(data.summary);
          updateTotalFees(data.summary?.total);
          setFeesOverviewLoading(LoadingState.Completed);
        } catch (error) {
          setFeesOverviewLoading(LoadingState.Failed);
        }
      }
    }, [feesOverviewLoading, useAuthToken, startDate, endDate]);

    if (feesOverviewLoading !== LoadingState.Completed || !data || !summary)
      return null;

    const hasFees = summary.total > 0;

    return (
      <FeeOverviewSection ref={ref}>
        <TableWrapper>
          <FeeOverviewTable>
            <SectionHeader>
              <TextContainer fontWeight="bold" paddingBottom="8px">
                <Text as="h5">Fees Overview</Text>
              </TextContainer>
            </SectionHeader>
            <table data-testid="statementV3-fees">
              <thead>
                <tr>
                  <th>Fee Type</th>
                  <th>Amount</th>
                </tr>
              </thead>
              {hasFees && Object.keys(data).length ? (
                <Fragment>
                  <tbody>
                    {data.map(({ name, count, amount }, idx) =>
                      count > 0 ? (
                        <tr key={idx} className="tableRows">
                          <td>
                            {name}
                            {count > 1 ? `(x${count})` : ``}
                          </td>
                          <td>{currency(amount).format()}</td>
                        </tr>
                      ) : (
                        <></>
                      )
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td>{currency(summary?.total).format()}</td>
                    </tr>
                  </tfoot>
                </Fragment>
              ) : null}
            </table>
            {!hasFees && <TableNullState>No Fees</TableNullState>}
          </FeeOverviewTable>
        </TableWrapper>
        <ChartSection>
          <FeesPieChart data={data} summary={summary} />
        </ChartSection>
      </FeeOverviewSection>
    );
  }
);
