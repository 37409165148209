import styled from 'styled-components';
import { ThemeProps } from '../../../../@types/ThemeProps';

export const TableSectionV3 = styled.div`
  margin-top: 50px;

  h5 {
    margin-bottom: 8px;
  }

  .tableRows:nth-child(even) {
    background-color: ${props => props.theme.colors.core.gray[900].hex};
  }

  table {
    width: 100%;
    table-layout: fixed;
    tfoot,
    thead {
      display: table-row-group;
    }

    tbody {
      padding: 2px 0;
      border-top: 1px solid
        ${(props: ThemeProps) => props.theme.lightNeutralOutline};
      border-bottom: 1px solid
        ${(props: ThemeProps) => props.theme.lightNeutralOutline};
    }

    tfoot > tr {
      > td {
        font-weight: 700;
      }

      &[data-is-first-load='false'] {
        animation: fade-in 0.1s ease-in-out both,
          shift-down 0.2s ease-in-out both;
      }
    }

    tr {
      td,
      th {
        color: ${props => props.theme.white};
        font-size: 10px;
        padding: 3px 2px;
        font-weight: 400;
        width: auto;
        &:not(:first-child) {
          text-align: right;
        }
      }

      &:first-child > td {
        padding-top: 4px;
      }

      &:last-child > td {
        padding-bottom: 3px;
      }
    }
  }
`;
