import React from 'react';

const successColor = '#28CB35'; // green 500
const failureColor = '#E30029'; // red 600
const defaultColor = '#435E70'; // gray 600

const mapTinNameCodeColor = code => {
  switch (code) {
    case '0':
    case '5':
    case '13':
      return failureColor; // red 600
    case '1':
    case '6':
    case '7':
    case '8':
      return successColor; // green 500
    default:
      return defaultColor; // gray 600
  }
};

const mapDmfCodeColor = code => {
  switch (code) {
    case '1':
      return failureColor;
    case '0':
      return successColor;
    default:
      return defaultColor; // gray 600
  }
};

export default reportObj => {
  return (
    <div>
      <strong>TinName</strong>
      <ul>
        <li>
          <strong>Code:</strong> {reportObj.TinNameResponse.Code}
        </li>
        <li>
          <strong>Description:</strong>{' '}
          <span
            style={{
              color: `${mapTinNameCodeColor(reportObj.TinNameResponse.Code)}`,
            }}
          >
            {reportObj.TinNameResponse.Description}
          </span>
        </li>
      </ul>
      <strong>Ein</strong>
      <ul>
        <li>
          <strong>Code:</strong> {reportObj.EinResponse.Code}
        </li>
        <li>
          <strong>Description:</strong>{' '}
          <span
            style={{
              color: `${
                reportObj.EinResponse.Code === '1' ? successColor : defaultColor
              }`,
            }}
          >
            {reportObj.EinResponse.Description}
          </span>
        </li>
      </ul>
      <strong>Dmf</strong>
      <ul>
        <li>
          <strong>Code:</strong> {reportObj.DmfResponse.Code}
        </li>
        <li>
          <strong>Description:</strong>{' '}
          <span
            style={{
              color: `${mapDmfCodeColor(reportObj.DmfResponse.Code)}`,
            }}
          >
            {reportObj.DmfResponse.Description}
          </span>
        </li>
      </ul>
    </div>
  );
};
