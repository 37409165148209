import { Brand } from '@fattmerchantorg/types-omni';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faImage } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent } from 'react';
import { FormValues } from './BrandData';

import {
  CenterVertical,
  LogoImg,
  LogoPreviewContainer,
  SpanInfo,
  StyledFontAwesomeImgIcon,
} from './SettingsPagesStylings';
interface Props {
  currentBrand: Brand;
  color: string;
  name: string;
  isSmall?: boolean;
  values: FormValues;
}

export const LogoPreview: FunctionComponent<Props> = ({
  currentBrand,
  color,
  isSmall = false,
  name,
  values,
}) => {
  return (
    <LogoPreviewContainer color={color} small={isSmall}>
      {values[name] ? (
        <LogoImg src={URL.createObjectURL(values[name])} alt="not found" />
      ) : currentBrand?.[name]?.[0]?.url ? (
        <LogoImg src={`${currentBrand?.[name]?.[0]?.url}`} alt="not found" />
      ) : (
        <CenterVertical>
          {color === 'white' ? (
            <StyledFontAwesomeImgIcon size="sm" icon={faImage as IconProp} />
          ) : (
            <FontAwesomeIcon size="sm" icon={faImage as IconProp} />
          )}
          {!isSmall && <SpanInfo>No Preview Available</SpanInfo>}
        </CenterVertical>
      )}
    </LogoPreviewContainer>
  );
};
