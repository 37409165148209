import { pick } from '../../../util/object.util';
import { DB } from '@fattmerchantorg/types-engine';
import {
  RecurringFeeFieldsValues,
  RecurringFeeFieldsErrors,
} from './RecurringFeeForm.types';

export type FeeDescriptionOption = {
  label: string;
  value: DB.Billing['fee_description_id'];
};

export function mapFormValuesToPayload(
  formValues: RecurringFeeFieldsValues
): Partial<DB.Billing> {
  return {
    state: 'ACTIVE',
    type: 'RECURRING',
    brand_id: null,
    billing_cycle_day: formValues.billing_cycle_day ?? 5,
    billing_cycle: formValues.billing_cycle,
    fees_account_id: formValues.fees_account_id,
    deposit_account_id:
      formValues.is_gross_settlement === 'false'
        ? formValues.fees_account_id
        : null,
    is_gross_settlement: formValues.is_gross_settlement === 'true',
    per_transaction_amount: formValues.per_transaction_amount,
    fee_description_id: formValues.fee_description_id.value ?? null,
    billing_cycle_month: formValues.billing_cycle_month ?? null,
  };
}

export function mapBillingProfileToInitialFormValues(
  billingProfile: Partial<RecurringFeeFieldsValues>
) {
  return pick(
    billingProfile,
    'type',
    'billing_cycle_day',
    'billing_cycle',
    'fees_account_id',
    'fee_description_id',
    'is_gross_settlement',
    'per_transaction_amount',
    'billing_cycle_month'
  ) as RecurringFeeFieldsValues;
}

export function validateFormValues(
  values: RecurringFeeFieldsValues
): RecurringFeeFieldsErrors {
  const errors = {} as RecurringFeeFieldsErrors;

  if (!values.fee_description_id) {
    errors.fee_description_id = 'A Fee type must be selected.';
  }

  if (!values.fees_account_id) {
    errors.fees_account_id = 'A fees account must be selected.';
  }
  if (values.per_transaction_amount < 0) {
    errors.per_transaction_amount = 'A positive fee amount must be provided.';
  }
  return errors;
}
