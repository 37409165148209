import axios, { AxiosRequestConfig } from 'axios';
import get from 'lodash/get';
import range from 'lodash/range';
import { getHeaders } from '../util/api.util';
import { formatDate as formatDateUtil } from '../util/date.util';
import { Omni } from '@fattmerchantorg/types-omni';
import { Worldpay } from '@fattmerchantorg/types-worldpay';
import { coreapi } from '../api';
import { uuid } from '../util';
import { formatDate } from '../util/date.util';
import { RegistrationAuditLogFieldError } from '../util/auditLog.util';
import { ProcessorValue } from '../components/underwriting/components/status/submit/SubmitApplicationForm.types';

/**
 * Describes the request body expected by the following API routes:
 * - `POST /vantiv/validate`
 * - `POST /vantiv/submit`
 */
interface VantivLogicValidateSubmitReqBody {
  merchants: Worldpay.MerchantImport[];
  merchantId?: string;
  processorName?: ProcessorValue;
}

export function magService(
  jwt: string,
  registration: Omni.Registration,
  files: Omni.RegistrationFileRecord[] = [],
  validateOnly: boolean = false,
  merchantId: string
): any {
  const stripNonDigits = (str: string) => (str ? str.replace(/\D/g, '') : '');
  const formatDate = (dateString: string) => {
    return formatDateUtil(dateString, 'MM/dd/yyyy');
  };

  /**
   * The following function is used to strip away any non-digit characters except a decimal point.
   * Due to the changes in FAT-5092 https://github.com/fattmerchantorg/fattpay-react/pull/1859
   * we allow $ signs and commas to be included in the onboarding component.
   * However, when trying to run MagValidate, the annual_volume, avg_trans_size and highest_trans_amount
   * fields will error out if they include $ signs and commas. It only respects the inclusion of decimal point.
   *
   */
  const formatVolume = (str: string) =>
    String(str)
      .replace(/[^0-9.]+/g, '')
      .trim();

  let representatives: Worldpay.MerchantImport['representatives'] = [
    {
      title: get(registration, 'job_title', ''),
      date_of_birth: formatDate(get(registration, 'user_dob', '')),
      ssn: stripNonDigits(get(registration, 'user_ssn', '')),
      first_name: get(registration, 'first_name', ''),
      middle_name: get(registration, 'middle_name', ''),
      last_name: get(registration, 'last_name', ''),
      sequence: 0,
      type: get(registration, 'owner_type', 'Control Owner'),
      ownership_percentage: +get(
        registration,
        'meta.ownership_percentage',
        '100'
      ),
    },
    ...get(registration, 'meta.representatives', []).map(
      (rep: Omni.RegistrationRepresentative) => ({
        title: get(rep, 'title', ''),
        date_of_birth: formatDate(get(rep, 'date_of_birth', '')),
        ssn: stripNonDigits(get(rep, 'ssn', '')),
        first_name: get(rep, 'first_name', ''),
        middle_name: get(rep, 'middle_name', ''),
        last_name: get(rep, 'last_name', ''),
        sequence: 0,
        type: get(rep, 'type', ''),
        ownership_percentage: +get(rep, 'ownership_percentage', '0'),
        address: {
          line1: (get(rep, 'address_1', '') || '').replace(/\./g, ''),
          line2: (get(rep, 'address_2', '') || '').replace(/\./g, ''),
          city: (get(rep, 'address_city', '') || '').replace(/\./g, ''),
          state: rep.address_state,
          zip: rep.address_zip,
          zip4: '',
          phone: String(rep.phone).replace(/[\D]/g, ''),
          fax: '',
          contact_name: `${rep.first_name} ${rep.last_name}`,
          e_mail: rep.email,
          alt_phone: '',
          cust_svc_phone: '',
        },
      })
    ),
  ];

  // method to get the correct sequence
  // e.g. Beneficial Owner 1, Beneficial Owner 2, Guarantor 1, Guarantor 2
  // sloppy but we're strapped for time

  let controlOwnerSequence = 0;
  let beneficialOwnerSequence = 0;
  let guarantorSequence = 0;
  let additionalRepresentativesAddresses = [];

  // 31 corresponds to 1st beneficial owner
  // 34 through 42 corresponds to 2nd through 10th beneficial owners
  const beneficialOwnerSequenceAddressIds = [31, ...range(34, 43)];

  representatives = representatives
    .map(rep => {
      if (rep.type === 'Control Owner') {
        controlOwnerSequence += 1;
        return { ...rep, sequence: controlOwnerSequence };
      } else if (rep.type === 'Beneficial Owner') {
        const { address, ...repWithoutAddress } = rep;
        additionalRepresentativesAddresses.push({
          type: beneficialOwnerSequenceAddressIds[beneficialOwnerSequence],
          ...address,
        });
        beneficialOwnerSequence += 1;
        return { ...repWithoutAddress, sequence: beneficialOwnerSequence };
      } else if (rep.type === 'Guarantor') {
        const { address, ...repWithoutAddress } = rep;
        additionalRepresentativesAddresses.push({ type: 43, ...address });
        guarantorSequence += 1;
        return { ...repWithoutAddress, sequence: guarantorSequence };
      }

      return null;
    })
    .filter(rep => rep !== null);

  // If the payload contains a secondary bank account and routing
  // number, assume we want to submit a secondary bank account with the
  // payload.

  const bankAccounts = (): Worldpay.BankAccount[] => {
    const hasSecondaryBankAccount =
      registration?.secondary_bank_routing_number &&
      registration?.secondary_bank_account_number;

    const accounts: Worldpay.BankAccount[] = [
      {
        type: get(
          registration,
          'bank_account_type',
          'SALES'
        ) as Worldpay.BankAccount['type'],
        routing_num: +get(registration, 'bank_routing_number', null),
        account_num: +get(registration, 'bank_account_number', null),
        dda_account_type: 'CHECKING',
      },
    ];

    if (hasSecondaryBankAccount) {
      accounts.push({
        type: mapAccountTypeToFIS(
          registration?.secondary_bank_account_purpose ?? ''
        ) as Worldpay.BankAccount['type'],
        routing_num: +(registration?.secondary_bank_routing_number ?? ''),
        account_num: +(registration?.secondary_bank_account_number ?? ''),
        dda_account_type: get(
          registration,
          'secondary_bank_account_type',
          null
        ),
      });
    }

    return accounts;
  };

  const requestConfig: AxiosRequestConfig<VantivLogicValidateSubmitReqBody> = {
    url: `${process.env.REACT_APP_ONBOARDING_URL}/${
      validateOnly ? 'vantiv/validate' : 'vantiv/submit'
    }`,
    method: 'post',
    data: {
      processorName: 'FIS',
      merchantId,
      merchants: [
        {
          /**
           *  @see Worldpay filespec 2.6a in GDrive
           *  https://drive.google.com/drive/folders/0B5MxTLLkMUK-X29UR3pJY3haTTg?resourcekey=0-SqhFo_WBNja9MIDB-tWp8w
           */
          representatives,
          id: registration.merchant_id,
          rep_code: 'T1160R000',
          agent_bank: '',
          app_version: 'NPC.CMA.0518',
          legal_name: registration.business_legal_name,
          dba: registration.business_dba,
          contact:
            registration.first_name && registration.last_name
              ? `${registration.first_name} ${registration.last_name}`
              : '',
          email: registration.email,
          website: registration.business_website,
          business_location_headquarters: false,
          own_type:
            registration.company_type as Worldpay.MerchantInfo['own_type'],

          fed_tax_id: stripNonDigits(registration.business_tax_id),
          ownership_chg: false,
          own_chg_mid: 0,
          bus_open_date: formatDate(registration.business_open_date),
          annual_sales: +formatVolume(registration.annual_volume),
          average_ticket: +formatVolume(registration.avg_trans_size),
          lrgst_avg_ticket: +formatVolume(registration.highest_trans_amount),
          addl_loc: !!registration.addl_loc,
          addl_loc_main_mid: +registration.addl_loc_main_mid,
          proc_change: registration.proc_change,
          card_present: +registration.card_present_percent,
          card_not_present: +registration.card_not_present_percent,
          card_swipe: +registration.card_swiped_percent,
          imprint:
            100 -
            +registration.card_swiped_percent -
            +registration.moto_percent -
            +registration.internet,
          moto: +registration.moto_percent,
          internet: +registration.internet,
          b2b: +registration.b2b_percent,
          b2c: 100 - +registration.b2b_percent,
          international: +registration.international,
          // MCC must be 4 digits, left-padded with zeros if necessary per specification.
          mcc: registration.mcc,
          mcc_addl_desc: (registration.service_you_provide || '').substring(
            0,
            150
          ),
          refund_policy:
            registration.refund_policy as Worldpay.MerchantInfo['refund_policy'],
          seasonal_flag: false,
          seasonal_months: '',
          deposit_time_frame: 'ALTERNATE',
          deposit_type: 'COMBINED',
          bill_cycle: 'M',
          partner: '',
          paper_statement: true,
          bus_type:
            (registration.bus_type as unknown as Worldpay.MerchantInfo['bus_type']) ||
            1,
          sub_bus_type:
            (registration.sub_bus_type as unknown as Worldpay.MerchantInfo['sub_bus_type']) ||
            36,
          special_proc: 0,
          offline_debit_mapping_method: 'ALL',
          pos_software_publisher: '',
          pos_software_name: '',
          pos_software_version: '',
          site_inspection: '3RD-PARTY_COMPLETED',
          site_verification_1: true,
          site_verification_2: true,
          site_verification_3: true,
          site_verification_4: true,
          site_verification_5: true,
          site_verification_6: 'O',
          location_type: 1,
          misc_equip_comment: '',
          misc_equip_1: '',
          misc_equip_2: '',
          network: (get(registration, 'network', 3) ||
            3) as Worldpay.MerchantInfo['network'],
          dial_type: 'T',
          dial_access: 'N',
          cbr_score: 0,
          note: '',
          long_plate_qty: 1,
          short_plate_qty: 0,
          goods_svcs_delivery_method: 'B',
          merch_sig_on_file: false,
          guar_sig_on_file: false,
          bank_sig_on_file: false,
          pci_compliance_indicator: false,
          pci_security_assessor: '',
          pci_certificate_code: '',
          pci_certificate_date: '',
          pci_compromise_notification: false,
          pci_remediation_complete: false,
          pci_store_cardholder_data: '',
          amex_vol_below_threshold: false,
          amex_mkt_mat_opt_out: false,
          addresses: [
            {
              // BUSINESS BILLING ADDRESS
              type: 1,
              // any of these fields could be null, so if they are null convert to a string
              // and if they were undefined, use a string also
              line1: (
                get(registration, 'business_address_1', '') || ''
              ).replace(/\./g, ''),
              line2: (
                get(registration, 'business_address_2', '') || ''
              ).replace(/\./g, ''),
              city: (
                get(registration, 'business_address_city', '') || ''
              ).replace(/\./g, ''),
              state: registration.business_address_state,
              zip: registration.business_address_zip,
              zip4: '',
              phone: String(registration.business_phone_number).replace(
                /[\D]/g,
                ''
              ),
              fax: String(registration.business_fax).replace(/[\D]/g, ''),
              contact_name: `${registration.first_name} ${registration.last_name}`,
              e_mail: registration.business_email,
              alt_phone: '',
              cust_svc_phone: '',
            },
            {
              // BUSINESS/DBA LOCATION ADDRESS
              type: 2,
              line1: (
                get(registration, 'business_location_address_1', '') || ''
              ).replace(/\./g, ''),
              line2: (
                get(registration, 'business_location_address_2', '') || ''
              ).replace(/\./g, ''),
              city: (
                get(registration, 'business_location_address_city', '') || ''
              ).replace(/\./g, ''),
              state: registration.business_location_address_state,
              zip: registration.business_location_address_zip,
              zip4: '',
              phone: String(
                registration.business_location_phone_number
              ).replace(/[\D]/g, ''),
              fax: String(registration.business_location_fax).replace(
                /[\D]/g,
                ''
              ),
              contact_name: `${registration.first_name} ${registration.last_name}`,
              e_mail: registration.business_location_email,
              alt_phone: '',
              cust_svc_phone: '',
            },
            {
              // SIGNER HOME ADDRESS
              type: 3,
              line1: (get(registration, 'owner_address_1', '') || '').replace(
                /\./g,
                ''
              ),
              line2: (get(registration, 'owner_address_2', '') || '').replace(
                /\./g,
                ''
              ),
              city: (get(registration, 'owner_address_city', '') || '').replace(
                /\./g,
                ''
              ),
              state: registration.owner_address_state,
              zip: registration.owner_address_zip,
              zip4: '',
              // owner phone is called phone_number while business phone is business_phone_number
              phone: String(registration.phone_number).replace(/[\D]/g, ''),
              fax: '',
              contact_name: `${registration.first_name} ${registration.last_name}`,
              e_mail: registration.email,
              alt_phone: '',
              cust_svc_phone: '',
            },
            ...additionalRepresentativesAddresses,
          ],
          bank_accounts: [...bankAccounts()],
          pricing: [
            {
              type: 'CREDIT',
              qual_disc_rate: +get(registration, 'credit_qual_disc_rate', 0.0),
              qual_trans_fee: +get(
                registration,
                'credit_qual_trans_fee',
                registration.plan_txamnt
              ),
              mid_disc_rate: +get(registration, 'credit_mid_disc_rate', 0),
              mid_trans_fee: +get(
                registration,
                'credit_mid_trans_fee',
                registration.plan_txamnt
              ),
              non_disc_rate: +get(registration, 'credit_non_disc_rate', 0),
              non_trans_fee: +get(
                registration,
                'credit_non_trans_fee',
                registration.plan_txamnt
              ),
            },
            {
              type: 'AMEX',
              qual_disc_rate: +get(registration, 'amex_qual_disc_rate', 0.3),
              qual_trans_fee: +get(
                registration,
                'amex_qual_trans_fee',
                registration.plan_txamnt
              ),
              mid_disc_rate: +get(registration, 'amex_mid_disc_rate', 0.3),
              mid_trans_fee: +get(
                registration,
                'amex_mid_trans_fee',
                registration.plan_txamnt
              ),
              non_disc_rate: +get(registration, 'amex_non_disc_rate', 0.3),
              non_trans_fee: +get(
                registration,
                'amex_non_trans_fee',
                registration.plan_txamnt
              ),
            },
          ],
          products: [
            {
              // visa mastercard discover product
              type: 1,
              disc_rate: 0.0,
              trans_fee: +registration.plan_txamnt,
            },
            {
              // offline debit product
              type: 37,
              disc_rate: 0.0,
              trans_fee: +registration.plan_txamnt,
            },
            {
              // amex product
              type: 6,
              disc_rate: 0.3,
              trans_fee: +registration.plan_txamnt,
              arc_num: '',
              iata_num: '',
              existing: false,
            },
          ],
          charges: [
            {
              // ACH charge
              id: 212,
              amount: 25.0,
            },
            {
              // voice authorization
              id: 223,
              amount: 0.95,
            },
            {
              // chargeback fee
              id: 151,
              amount: 25.0,
            },
            {
              // retrieval request
              id: 222,
              amount: 25.0,
            },
            {
              // card brand usage fee
              id: 216,
              amount: +registration.plan_nabu,
            },
            {
              // visa
              id: 215,
              amount: +registration.plan_nabu,
            },
          ],
          equipments: [
            {
              // ???
              id: 97,
              quantity: 1,
              purchasing: 1,
              shipping_flag: 'N',
              provider_code: 'NPC',
              purchase_amt: 0.0,
            },
          ],
          documents: [
            ...files.map(f => ({
              // use the god-user assigned file type or type 21
              type: +get(
                f,
                'meta.onboardingMagType',
                21
              ) as Worldpay.DocumentType,
              s3Filename: f.path,
            })),
          ],
        },
      ],
    },
    headers: getHeaders(jwt),
  };

  return new Promise((resolve, reject) => {
    axios(requestConfig)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        console.error(error);
        // error.response.data.validation[0] looks like:
        // {field: {merchantID: "96bda272-f65f-4471-b108-9cb581cd42f1", block: "merchantInfo", name: "email"}, code: "merchant_info_invalid", text: "Merchant: 96bda272-f65f-4471-b108-9cb581cd42f1, field email has invalid value 'null' "}
        // we want: { field: "email", code: "merchant_info_invalid", text: "field email has invalid value 'null'"}
        // so that's what the map() does
        reject(
          get(error.response, 'data.validation', []).map(r => {
            const name = get(r, 'field.name');
            const block = get(r, 'field.block');
            const blockId = get(r, 'field.blockId');
            return {
              field: `${block}.${blockId ? blockId + '.' : ''}${name}`,
              code: r.code,
              text: r.text.replace(
                `Merchant: ${registration.merchant_id}, `,
                ''
              ),
            } as RegistrationAuditLogFieldError;
          })
        );
      });
  });
}

const mapAccountTypeToFIS = (accountType: string) => {
  let accountT = accountType?.toUpperCase() || accountType;
  if (accountT === 'DISCOUNT') {
    return 'DISCOUNT_A';
  }
  if (accountT === 'TRUST') {
    return '';
  }
  return accountT;
};
/**
 * Save the registration to the db
 * @param {Omni.Registration} body The body of the PUT request
 */
const saveRegistrationRecord = async (
  authToken: Omni.Auth['token'],
  registration: Omni.Registration
): Promise<Omni.Registration> => {
  // Delete files so we don't run into weirdness with adding/removing files
  // This won't remove existing files off a record
  delete registration.files;
  delete registration.signature_status;

  try {
    return await coreapi.put<Omni.Registration>(
      authToken,
      `merchant/${registration.merchant_id}/registration`,
      { registration }
    );
  } catch (err) {
    console.error(err);
  }
};

/**
 * Update the registration record based on mag result.
 */
export const updateRegistrationAuditLog = async (
  authToken: Omni.Auth['token'],
  registration: Omni.Registration,
  isSuccess: boolean,
  isValidation: boolean,
  errors: any[] = []
): Promise<Omni.Registration> => {
  const auditId = uuid();

  const body = {
    // rebuild meta object
    ...registration,
    meta: {
      ...registration.meta,
      auditLog: [
        ...get(registration, 'meta.auditLog', []),
        {
          id: auditId,
          type: isSuccess ? 'success' : 'failure',
          content: `${formatDate(new Date(), 'MM/dd/yy - h:mm:ss tt')} ${
            isValidation ? 'VALIDATE' : 'SUBMIT'
          }: ${isSuccess ? 'SUCCESS' : 'FAILURE'}`,
          errors,
        },
      ],
    },
  };

  try {
    const result = await saveRegistrationRecord(authToken, body);

    return result;
  } catch (err) {
    console.error(err);
  }
};
