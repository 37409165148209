import { MinimalBrand } from '@fattmerchantorg/types-omni';

export const createAllBrandsValue = (brands: MinimalBrand[]) => {
  return brands.map(brand => brand.name).join(',');
};

export const isAllBrandsSelected = (
  selectedMerchantValue: string,
  brands: MinimalBrand[]
) =>
  typeof selectedMerchantValue === 'string' &&
  brands.length > 0 &&
  selectedMerchantValue === createAllBrandsValue(brands);

// determine what brand to record as the account name
// in order of priority: the brand of the merchant, if selected; the brand switcher; the user brand
export const calculateSelectedBrand = (
  brands: MinimalBrand[],
  userBrand: string,
  merchantBrand?: string,
  selectedMerchantValue?: string
) => {
  return (
    merchantBrand ||
    (!isAllBrandsSelected(selectedMerchantValue, brands) &&
      selectedMerchantValue) ||
    userBrand
  );
};
