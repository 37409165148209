import React, { FunctionComponent } from 'react';

import {
  Input,
  Group,
  DateInput,
  FieldError,
  Select,
  FormHeader,
  FormDivider,
} from '../../../shared';

import { BusinessFieldsProps } from './BusinessFields.types';
import { useWindowSize } from '../../../../hooks';

import {
  formatPhoneNumber,
  formatFederalTaxId,
} from '../../../../util/format.util';
import { states } from '../../../../constants/usaStates';
import { EnrollmentField } from '../enrollment-field';

export const BusinessFields: FunctionComponent<BusinessFieldsProps> = props => {
  const { isMobile } = useWindowSize();

  return (
    <>
      <FormHeader tag="h1">Business Details</FormHeader>
      <Group direction="column">
        <EnrollmentField name="business_legal_name" label="Legal Name">
          {props => <Input {...props.input} />}
        </EnrollmentField>
        <FieldError name="business_legal_name" />
      </Group>

      <Group direction="column">
        <EnrollmentField name="business_dba" label="DBA">
          {props => <Input {...props.input} />}
        </EnrollmentField>
        <FieldError name="business_dba" />
      </Group>

      <Group direction="column">
        <EnrollmentField name="business_website" label="Website (optional)">
          {props => <Input {...props.input} />}
        </EnrollmentField>
        <FieldError name="business_website" />
      </Group>

      <Group direction="column">
        <EnrollmentField
          name="business_fax"
          label="Fax (optional)"
          parse={formatPhoneNumber}
        >
          {props => <Input {...props.input} type="tel" />}
        </EnrollmentField>
        <FieldError name="business_fax" />
      </Group>

      <Group direction="column">
        <EnrollmentField name="business_address_1" label="Address Line 1">
          {props => <Input {...props.input} />}
        </EnrollmentField>
        <FieldError name="business_address_1" />
      </Group>

      <Group direction="column">
        <EnrollmentField
          name="business_address_2"
          label="Address Line 2 (optional)"
        >
          {props => <Input {...props.input} />}
        </EnrollmentField>
        <FieldError name="business_address_2" />
      </Group>

      <Group direction="row" space="0.5rem/0px">
        <Group direction="column" style={{ flex: '40%' }}>
          <EnrollmentField name="business_address_city" label="City">
            {props => <Input {...props.input} />}
          </EnrollmentField>
          <FieldError name="business_address_city" />
        </Group>

        <Group direction="column" style={{ flex: '30%' }}>
          <EnrollmentField name="business_address_state" label="State">
            {props => (
              <Select {...props.input}>
                <option value="" disabled />
                {states.map((state, index) => (
                  <option key={index} value={state.abbr}>
                    {state.name}
                  </option>
                ))}
              </Select>
            )}
          </EnrollmentField>
          <FieldError name="business_address_state" />
        </Group>
        <Group direction="column" style={{ flex: '30%' }}>
          <EnrollmentField name="business_address_zip" label="Zip">
            {props => <Input {...props.input} />}
          </EnrollmentField>
          <FieldError name="business_address_zip" />
        </Group>
      </Group>

      <Group direction={isMobile ? 'column-reverse' : 'row'}>
        <Group direction="column" space="0.5rem/0px" style={{ flex: '50%' }}>
          <Group direction="column">
            <EnrollmentField
              name="business_tax_id"
              label="Tax ID/EIN"
              parse={formatFederalTaxId}
            >
              {props => (
                <Input
                  {...props.input}
                  tooltip="Tax ID/EIN is required but under some circumstances an applicant may use their SSN instead."
                />
              )}
            </EnrollmentField>
            <FieldError name="business_tax_id" />
          </Group>

          <Group direction="column">
            <EnrollmentField
              name="business_open_date"
              label="Business Incorporation Date"
            >
              {props => <DateInput {...props.input} />}
            </EnrollmentField>
            <FieldError name="business_open_date" />
          </Group>
        </Group>
      </Group>

      {props.children}

      <div>
        <FormDivider />
      </div>
    </>
  );
};
