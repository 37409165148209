import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  width: 69px;
  color: white;
  background-color: ${props => props.theme.colors.status.blue['500'].hex};

  font-size: 28px;
  line-height: 69px;
  text-align: center;
`;

export const BulkActionMenuCount: FunctionComponent = props => {
  return <Container {...props} />;
};
