import React, { FC, useContext } from 'react';
import styled, { withTheme } from 'styled-components';
import { Tooltip } from '@fattmerchantorg/truffle-components';
import { SelectedMerchantStore } from '../../../context';
import { formatWithComma, getIfNotEmpty } from '../../../util';
const Container = styled.div`
  background: white;
  min-height: 119px;
`;

const StyledReportedAnnualTitle = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
  align-items: baseline;
  vertical-align: text-bottom;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  border-top: 1px solid #ccc;
  color: black;
`;

const StyledTitleAndTooltip = styled.div`
  align-items: baseline;

  i {
    margin-left: 4px; /* Adjust the margin for spacing as needed */
    vertical-align: middle;
  }
`;

const StyledTooltip = styled(Tooltip)`
  background-color: ${({ theme }) => theme.colors.core.gray[700].hex};
  text-align: left;
  align-items: baseline;
  vertical-align: text-bottom;
  .tippy-arrow::before {
    border-top-color: ${({ theme }) =>
      theme.colors.core.gray[700].hex} !important;
  }
`;

const StyledMaxCCTitle = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
  align-items: baseline;
  vertical-align: text-bottom;
  width: 100%;
  color: black;
`;

const StyledMaxCCValue = styled.div`
  color: #062333;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
  text-align: right;
`;

const StyledMaxCCWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

const StyledMaxACHTitle = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
  align-items: baseline;
  vertical-align: text-bottom;
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  color: black;
`;

const StyledMaxACHValue = styled.div`
  color: #062333;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
  text-align: right;
  padding-top: 8px;
  padding-bottom: 8px;
`;

const StyledReportedAnnualValue = styled.div`
  color: #062333;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
  text-align: right;
  padding-top: 8px;
  padding-bottom: 8px;
`;

const WidgetHeader = styled.div`
  color: #062333;
  font-size: 18px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 27px;
  word-wrap: break-word;
  padding-bottom: 16px;
`;

const ConsolidatedContent = withTheme(
  styled.div`
    color: #565656;
    display: flex;
    flex-direction: row;
    padding: 16px;

    span {
      line-height: 21px;
      font-size: 14px;
      color: ${({ theme }) => theme.black};
      white-space: normal;
    }

    h2 {
      margin: 0px;
      color: ${({ theme }) => theme.black};
    }

    > div {
      flex: 50%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    button {
      color: #32325d;
      padding: 0;
    }

    .billing-profile-content {
      p {
        font-size: 12px;
      }
      table {
        width: 100%;
        color: ${({ theme }) => theme.black};
        thead {
          tr {
            th {
              font-weight: bold;
              font-size: 12px;
              padding: 5px 5px 5px 5px;
              text-align: right;
              &:first-child {
                text-align: left;
              }
              &:last-child {
                padding-left: 0;
              }
            }
          }
        }
        tbody {
          tr {
            &:nth-child(odd) {
              background-color: #ebf9f0;
            }
            td {
              font-weight: bold;
              font-size: 14px;
              padding: 5px 5px 5px 5px;
              text-align: right;
              &:first-child {
                text-align: left;
              }
              &:last-child {
                padding-left: 0px;
              }
            }
          }
        }
      }
    }
  `
);

export const MaximumsAndVolumeWidget: FC<{}> = () => {
  return (
    <>
      <MaximumsWidget />
    </>
  );
};

const MaximumsWidget = () => {
  const { state } = useContext(SelectedMerchantStore);
  const { registration } = state;

  let annualCCVolume: number;
  let annualAchVolume: number;
  if (registration) {
    annualCCVolume = parseInt(getIfNotEmpty(registration.annual_volume));
    annualAchVolume = parseInt(
      getIfNotEmpty(registration.annual_gross_ach_revenue)
    );
  }

  const content = () => {
    let table;

    table = (
      <Container>
        <StyledMaxCCTitle>
          <StyledMaxCCWrapper>
            Max Credit Card
            <StyledTooltip
              placement="top"
              content={
                <span style={{ color: '#ffffff' }}>
                  Max Allowed Per Transaction
                </span>
              }
            >
              <i
                style={{ paddingLeft: '4px' }}
                className="fas fa-info-circle"
              ></i>
            </StyledTooltip>
          </StyledMaxCCWrapper>
          <StyledMaxCCValue>
            {registration.highest_trans_amount
              ? `$${formatWithComma(
                  parseInt(registration.highest_trans_amount)
                )}`
              : '--'}
          </StyledMaxCCValue>
        </StyledMaxCCTitle>
        <StyledMaxACHTitle>
          <StyledTitleAndTooltip>
            Max ACH
            <StyledTooltip
              placement="top"
              content={
                <span style={{ color: '#ffffff' }}>
                  Max Allowed Per Transaction
                </span>
              }
            >
              <i
                style={{ marginLeft: '4px' }} /* Use marginLeft for spacing */
                className="fas fa-info-circle"
              ></i>
            </StyledTooltip>
          </StyledTitleAndTooltip>
          <StyledMaxACHValue>
            {registration.largest_ach_transaction
              ? `$${formatWithComma(
                  parseInt(registration.largest_ach_transaction)
                )}`
              : '--'}
          </StyledMaxACHValue>
        </StyledMaxACHTitle>
        <StyledReportedAnnualTitle>
          <StyledTitleAndTooltip>
            Reported Annual Volume
            <StyledTooltip
              placement="top"
              content={
                <span style={{ color: '#ffffff' }}>
                  Total Credit Card & ACH Revenue
                </span>
              }
            >
              <i
                style={{ marginLeft: '4px' }} /* Use marginLeft for spacing */
                className="fas fa-info-circle"
              ></i>
            </StyledTooltip>
          </StyledTitleAndTooltip>
          <StyledReportedAnnualValue>
            {annualCCVolume + annualAchVolume
              ? `$${formatWithComma(annualCCVolume + annualAchVolume)}`
              : '--'}
          </StyledReportedAnnualValue>
        </StyledReportedAnnualTitle>
      </Container>
    );

    return table;
  };

  return (
    <Container data-testid="max-totals-widget-container">
      <ConsolidatedContent>
        <div>
          <WidgetHeader>
            <h2>Maximums and Volume</h2>
          </WidgetHeader>
          <div className="maximums-profile-content">{content()}</div>
        </div>
      </ConsolidatedContent>
    </Container>
  );
};
