import { Dispatch, ReactNode } from 'react';

export interface TestModeContextProps {
  children: ReactNode;
}

export interface TestModeState {
  isAutomaticRedirectModalOpen: boolean;
}

export interface TestModeContext {
  state: TestModeState;
  dispatch: Dispatch<TestModeAction>;
}

export enum TestModeActionType {
  UPDATE_AUTOMATIC_REDIRECT_MODAL_STATUS = 'UPDATE_AUTOMATIC_REDIRECT_MODAL_STATUS'
}

export type TestModeAction = { type: TestModeActionType; payload: boolean };
