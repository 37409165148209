import strEnum from '../../../util/strEnum';

export const TimeRange = strEnum([
  'TODAY',
  'YESTERDAY',
  'THIS_WEEK',
  'LAST_WEEK',
  'THIS_MONTH',
  'LAST_MONTH',
  'THIS_YEAR',
  'LAST_YEAR',
]);
export type TimeRange = keyof typeof TimeRange;

/*
 * ActionTypes
 */
export const ActionTypes = strEnum([
  'USER_SELECTED_TIMESPAN',
  'USER_REQUESTED_NO_CACHE_REFRESH',
  'FETCH_SUCCEEDED',
  'FETCH_FAILED',
  'USER_SELECTED_DATA_SET',
]);
