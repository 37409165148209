import { ActionTypes } from './types';

/* Actions */
interface UserPressedCloseAction {
  type: typeof ActionTypes.USER_SELECTED_CLOSE;
}
interface UserPressedOpenAction<C> {
  type: typeof ActionTypes.USER_SELECTED_OPEN;
  payload?: C;
}

export type Action<C = {}> = UserPressedCloseAction | UserPressedOpenAction<C>;

/* Action Creators */
export function sendOpenSelected<C>(payload?: C): UserPressedOpenAction<C> {
  return {
    type: ActionTypes.USER_SELECTED_OPEN,
    payload
  };
}

export function sendCloseSelected(): UserPressedCloseAction {
  return {
    type: ActionTypes.USER_SELECTED_CLOSE
  };
}
