import React from 'react';
import { format } from 'date-fns';
import {
  getPotentialMatchCode,
  getEmailAdviceCode,
  getDomainRiskCode,
  getFraudTypeCode,
} from '../../../../../../util/giactCodeLookUp.util';

const timestampFormat = 'MMM dd yyyy h:mm:ss';

export const renderEmailReport = report => {
  return (
    <div className="potential-match-info">
      <li>
        <strong>Email Address Found:</strong>{' '}
        {report.EmailAddressFound ? 'true' : 'false'}
      </li>
      <li>
        <strong>Email Owner Name:</strong>{' '}
        {report.EmailAddressOwnerName ? report.EmailAddressOwnerName : ''}
      </li>
      <li>
        <strong>Email Owner Company:</strong>{' '}
        {report.EmailAddressOwnerCompany ? report.EmailAddressOwnerCompany : ''}
      </li>
      <li>
        <strong>Email Owner Title:</strong>{' '}
        {report.EmailAddressOwnerTitle ? report.EmailAddressOwnerTitle : ''}
      </li>
      <li>
        <strong>Email Status:</strong>{' '}
        {report.EmailAddressStatus ? report.EmailAddressStatus : ''}
      </li>
      <li>
        <strong>Email Advice:</strong>{' '}
        {report.EmailAddressAdvice
          ? getEmailAdviceCode(report.EmailAddressAdvice)
          : ''}
      </li>
      <li>
        <strong>Email Created At:</strong>{' '}
        {report.EmailAddressCreatedDate
          ? format(new Date(report.EmailAddressCreatedDate), timestampFormat)
          : ''}
      </li>
      <li>
        <strong>Email First Seen:</strong>{' '}
        {report.FirstSeen
          ? format(new Date(report.FirstSeen), timestampFormat)
          : ''}
      </li>
      <li>
        <strong>Email Last Seen:</strong>{' '}
        {report.LastSeen
          ? format(new Date(report.LastSeen), timestampFormat)
          : ''}
      </li>
      <li>
        <strong>Domain:</strong> {report.Domain ? report.Domain : ''}
      </li>
      <li>
        <strong>Domain Exists:</strong>{' '}
        {report.DomainExists ? `${report.DomainExists}` : ''}
      </li>
      <li>
        <strong>Domain Created At:</strong>{' '}
        {report.DomainCreatedDate
          ? format(new Date(report.DomainCreatedDate), timestampFormat)
          : ''}
      </li>
      <li>
        <strong>Domain Company:</strong>{' '}
        {report.DomainCompany ? report.DomainCompany : ''}
      </li>
      <li>
        <strong>Domain Country:</strong>{' '}
        {report.DomainCountry ? report.DomainCountry : ''}
      </li>
      <li>
        <strong>Domain Category:</strong>{' '}
        {report.DomainCategory ? report.DomainCategory : ''}
      </li>
      <li>
        <strong>Domain Risk:</strong>{' '}
        {report.DomainRisk ? getDomainRiskCode(report.DomainRisk) : ''}
      </li>
      <li>
        <strong>Fraud:</strong>{' '}
        {report.Fraud ? getFraudTypeCode(report.Fraud) : ''}
      </li>
      {report.SocialMediaLinks.length > 0 &&
        report.SocialMediaLinks.map((link, index) => (
          <li key={`${link}-${index}`}>
            <strong>Connected Social Media Link:</strong>{' '}
            <a href={link} target="_blank" rel="noopener noreferrer">
              {link}
            </a>
          </li>
        ))}
    </div>
  );
};

export const renderAddressInfo = (address, index) => {
  return (
    <div className="potential-match-info" key={`address-${index}`}>
      <li>
        <strong>Address Line 1:</strong>{' '}
        {address.AddressLine1 ? address.AddressLine1 : ''}
      </li>
      <li>
        <strong>Address Line 2:</strong>{' '}
        {address.AddressLine2 ? address.AddressLine2 : ''}
      </li>
      <li>
        <strong>City:</strong> {address.City ? address.City : ''}
      </li>
      <li>
        <strong>State:</strong> {address.State ? address.State : ''}
      </li>
      <li>
        <strong>Zip Code:</strong> {address.ZipCode ? address.ZipCode : ''}
      </li>
      {address.Status >= 0 && (
        <li>
          <strong>Status:</strong> {address.Status}
        </li>
      )}
      {address.DateReported && (
        <li>
          <strong>Date Reported:</strong>{' '}
          {format(new Date(address.DateReported), timestampFormat)}
        </li>
      )}
    </div>
  );
};

export const renderMatchedBusinessData = (business, index) => {
  return (
    <ul style={{ listStyle: 'disc' }}>
      <li>
        <strong>Corportation Type:</strong>{' '}
        {business.CorporationType ? business.CorporationType : ''}
      </li>
      <li>
        <strong>Registration Type:</strong>{' '}
        {business.RegistrationType ? business.RegistrationType : ''}
      </li>
      <li>
        <strong>Incorporation State:</strong>{' '}
        {business.IncorporationState ? business.IncorporationState : ''}
      </li>
      <li>
        <strong>Incorporation Date:</strong>{' '}
        {business.IncorporationDate
          ? format(new Date(business.IncorporationDate), timestampFormat)
          : ''}
      </li>
      <li>
        <strong>Filing Number:</strong>{' '}
        {business.FilingNumber ? business.FilingNumber : ''}
      </li>
      <li>
        <strong>FEIN:</strong> {business.FEIN ? business.FEIN : ''}
      </li>
      <li>
        <strong>Duns Number:</strong>{' '}
        {business.DunsNumber ? business.DunsNumber : ''}
      </li>
      <li>
        <strong>Bankruptcy Subject Record Count:</strong>{' '}
        {business.BankruptcySubjectRecordCount >= 0
          ? business.BankruptcySubjectRecordCount
          : ''}
      </li>
      <li>
        <strong>Business Report Key:</strong>{' '}
        {business.BusinessReportKey ? business.BusinessReportKey : ''}
      </li>
      <li>
        <strong>Bankruptcy Creditor Record Count:</strong>{' '}
        {business.BankruptcyCreditorRecordCount >= 0
          ? business.BankruptcyCreditorRecordCount
          : ''}
      </li>
      {business.NameRecords.length > 0 && (
        <React.Fragment>
          <li>
            <strong>Name Records:</strong>
          </li>
          <div className="potential-match-info">
            {business.NameRecords.map((match, index) => (
              <div
                className="potential-match-info"
                key={`name-record-${index}`}
              >
                <li>
                  <strong>Business Name:</strong> {match.BusinessName}
                </li>
                <li>
                  <strong>Business Type:</strong> {match.BusinessType}
                </li>
              </div>
            ))}
          </div>
        </React.Fragment>
      )}
      {business.Industries.length > 0 && (
        <React.Fragment>
          <li>
            <strong>Industries:</strong>
          </li>
          <div className="potential-match-info">
            {business.Industries.map(match => (
              <li key={match}>{match}</li>
            ))}
          </div>
        </React.Fragment>
      )}
      {business.Domains.length > 0 && (
        <React.Fragment>
          <li>
            <strong>Domains:</strong>
          </li>
          <div className="potential-match-info">
            {business.Domains.map(match => (
              <li key={match}>{match}</li>
            ))}
          </div>
        </React.Fragment>
      )}
      {business.AddressRecords.length > 0 && (
        <React.Fragment>
          <li>
            <strong>Address Records:</strong>
          </li>
          {business.AddressRecords.map((address, index) =>
            renderAddressInfo(address, index)
          )}
        </React.Fragment>
      )}
      {business.PhoneNumbers.length > 0 && (
        <React.Fragment>
          <li>
            <strong>Phone Numbers:</strong>
          </li>
          {business.PhoneNumbers.map(match => (
            <div className="potential-match-info" key={match.PhoneNumber}>
              <li>
                <strong>Phone Number:</strong> {match.PhoneNumber}
              </li>
              <li>
                <strong>Classification:</strong> {match.Classification}
              </li>
              <li>
                <strong>Number Type:</strong> {match.NumberType}
              </li>
            </div>
          ))}
        </React.Fragment>
      )}
      {business.BusinessContacts.length > 0 && (
        <React.Fragment>
          <li>
            <strong>Business Contacts:</strong>
          </li>
          {business.BusinessContacts.map((contact, index) => (
            <div className="potential-match-info" key={`contact-${index}`}>
              <li>
                <strong>First Name:</strong>{' '}
                {contact.FirstName ? contact.FirstName : ''}
              </li>
              <li>
                <strong>Middle Name:</strong>{' '}
                {contact.MiddleName ? contact.MiddleName : ''}
              </li>
              <li>
                <strong>Last Name:</strong>{' '}
                {contact.LastName ? contact.LastName : ''}
              </li>
              <li>
                <strong>Title:</strong> {contact.Title ? contact.Title : ''}
              </li>
            </div>
          ))}
        </React.Fragment>
      )}
    </ul>
  );
};

export const renderMatchedPersonData = (person, index) => {
  return (
    <ul style={{ listStyle: 'disc' }}>
      <li>
        <strong>First Name:</strong> {person.FirstName}
      </li>
      <li>
        <strong>Middle Name:</strong>{' '}
        {person.MiddleName ? person.MiddleName : ''}
      </li>
      <li>
        <strong>Last Name:</strong> {person.LastName}
      </li>
      <li>
        <strong>Date of Birth:</strong>{' '}
        {format(new Date(person.DateOfBirth), 'MMM dd yyyy')}
      </li>
      <li>
        <strong>Id Number:</strong> {person.IdNumber ? person.IdNumber : ''}
      </li>
      <li>
        <strong>Ssn Issue State:</strong>{' '}
        {person.SsnIssueState ? person.SsnIssueState : ''}
      </li>
      <li>
        <strong>Ssn Issue Start Year:</strong>{' '}
        {person.SsnIssueStartYear ? person.SsnIssueStartYear : ''}
      </li>
      <li>
        <strong>Ssn Issue End Year:</strong>{' '}
        {person.SsnIssueEndYear ? person.SsnIssueEndYear : ''}
      </li>
      <li>
        <strong>Ssn Status:</strong> {person.SsnStatus ? person.SsnStatus : ''}
      </li>
      {person.AddressRecords.length > 0 && (
        <React.Fragment>
          <li>
            <strong>Address Records:</strong>
          </li>
          {person.AddressRecords.map((address, index) =>
            renderAddressInfo(address, index)
          )}
        </React.Fragment>
      )}
      {person.PhoneNumbers.length > 0 && (
        <React.Fragment>
          <li>
            <strong>Phone Numbers:</strong>
          </li>
          {person.PhoneNumbers.map(match => (
            <div className="potential-match-info" key={match.PhoneNumber}>
              <li>
                <strong>Phone Number:</strong> {match.PhoneNumber}
              </li>
              <li>
                <strong>Classification:</strong> {match.Classification}
              </li>
              <li>
                <strong>Number Type:</strong> {match.NumberType}
              </li>
            </div>
          ))}
        </React.Fragment>
      )}
    </ul>
  );
};

export const renderMatchedOfacData = (person, index) => {
  return (
    <ul style={{ listStyle: 'disc' }}>
      <li>
        <strong>Giact Entity Id:</strong> {person.EntityId}
      </li>
      <li>
        <strong>List Item Name:</strong>{' '}
        {person.Name.ListItemName ? person.Name.ListItemName : ''}
      </li>
      <li>
        <strong>First Name:</strong>{' '}
        {person.Name.FirstName ? person.Name.FirstName : ''}
      </li>
      <li>
        <strong>Middle Name:</strong>{' '}
        {person.Name.MiddleName ? person.Name.MiddleName : ''}
      </li>
      <li>
        <strong>Last Name:</strong>{' '}
        {person.Name.LastName ? person.Name.LastName : ''}
      </li>
      <li>
        <strong>Potential Match Level:</strong>{' '}
        {person.Summary.PotentialMatchLevel
          ? getPotentialMatchCode(person.Summary.PotentialMatchLevel)
          : ''}
      </li>
      {person.Summary.PotentialMatchDetails.length > 0 && (
        <React.Fragment>
          <li>
            <strong>Potential Match Details:</strong>
          </li>
          <div className="potential-match-info">
            {person.Summary.PotentialMatchDetails.map(match => (
              <li key={match}>{match}</li>
            ))}
          </div>
        </React.Fragment>
      )}
      <li>
        <strong>Potential Match To An Aka:</strong>{' '}
        {person.Summary.PotentialMatchAka
          ? person.Summary.PotentialMatchAka
          : ''}
      </li>
      <li>
        <strong>Title:</strong> {person.Title ? person.Title : ''}
      </li>
      <li>
        <strong>Gender:</strong> {person.Gender ? person.Gender : ''}
      </li>
      <li>
        <strong>Data Source:</strong>{' '}
        {person.DataSource ? person.DataSource : ''}
      </li>
      <li>
        <strong>Last Updated Date:</strong>{' '}
        {format(new Date(person.LastUpdatedDate), timestampFormat)}
      </li>
      <li>
        <strong>Website:</strong> {person.Website ? person.Website : ''}
      </li>
      <li>
        <strong>Remarks:</strong> {person.Remarks ? person.Remarks : ''}
      </li>
      {person.AlsoKnownAs.length > 0 && (
        <React.Fragment>
          <li>
            <strong>Also Known As:</strong>
          </li>
          <div className="potential-match-info">
            {person.AlsoKnownAs.map(match => (
              <li key={match}>{match}</li>
            ))}
          </div>
        </React.Fragment>
      )}
      {person.ProgramNames.length > 0 && (
        <React.Fragment>
          <li>
            <strong>Program Names:</strong>
          </li>
          <div className="potential-match-info">
            {person.ProgramNames.map(match => (
              <li key={match}>{match}</li>
            ))}
          </div>
        </React.Fragment>
      )}
      {person.Associations.length > 0 && (
        <React.Fragment>
          <li>
            <strong>Associations:</strong>
          </li>
          <div className="potential-match-info">
            {person.Associations.map(match => (
              <li key={match}>{match}</li>
            ))}
          </div>
        </React.Fragment>
      )}
      {person.Passports.length > 0 && (
        <React.Fragment>
          <li>
            <strong>Passports:</strong>
          </li>
          <div className="potential-match-info">
            {person.Passports.map(match => (
              <li key={match}>{match}</li>
            ))}
          </div>
        </React.Fragment>
      )}
      {person.Citizenships.length > 0 && (
        <React.Fragment>
          <li>
            <strong>Citizenships:</strong>
          </li>
          <div className="potential-match-info">
            {person.Citizenships.map(match => (
              <li key={match}>{match}</li>
            ))}
          </div>
        </React.Fragment>
      )}
      {person.NationalIds.length > 0 && (
        <React.Fragment>
          <li>
            <strong>National Ids:</strong>
          </li>
          <div className="potential-match-info">
            {person.NationalIds.map(match => (
              <li key={match}>{match}</li>
            ))}
          </div>
        </React.Fragment>
      )}
      {person.Nationalities.length > 0 && (
        <React.Fragment>
          <li>
            <strong>Nationalities:</strong>
          </li>
          <div className="potential-match-info">
            {person.Nationalities.map(match => (
              <li key={match}>{match}</li>
            ))}
          </div>
        </React.Fragment>
      )}
      {person.PlaceOfBirthListings.length > 0 && (
        <React.Fragment>
          <li>
            <strong>Place Of Birth Listings:</strong>
          </li>
          <div className="potential-match-info">
            {person.PlaceOfBirthListings.map(match => (
              <li key={match}>{match}</li>
            ))}
          </div>
        </React.Fragment>
      )}
      {person.DateOfBirthListings.length > 0 && (
        <React.Fragment>
          <li>
            <strong>Date Of Birth Listings:</strong>
          </li>
          <div className="potential-match-info">
            {person.DateOfBirthListings.map(match => (
              <div>
                <li key={match}>{`${match}`}</li>
              </div>
            ))}
          </div>
        </React.Fragment>
      )}
      {person.Addresses.length > 0 && (
        <React.Fragment>
          <li>
            <strong>Address Records:</strong>
          </li>
          {person.Addresses.map((match, index) => (
            <div key={`${match}-${index}`} className="potential-match-info">
              <li>Adress: {match.Adress ? match.Adress : ''}</li>
              <li>
                City State Post:{' '}
                {match.CityStatePost ? match.CityStatePost : ''}
              </li>
              <li>Country: {match.Country ? match.Country : ''}</li>
              <li>Remarks: {match.Remarks ? match.Remarks : ''}</li>
            </div>
          ))}
        </React.Fragment>
      )}
    </ul>
  );
};

export const renderMoreDetailsFromGiact = report => {
  return (
    <div style={{ marginTop: '1em' }}>
      <div>
        <strong>Giact Item Reference Id:</strong> {report.ItemReferenceId}
      </div>
      <div>
        <strong>Created At:</strong>{' '}
        {format(new Date(report.CreatedDate), timestampFormat)}
      </div>

      <ul>
        {report.BankName && (
          <li>
            <strong>Bank Name:</strong> {report.BankName}
          </li>
        )}
        {report.BankAccountType && (
          <li>
            <strong>Bank Account Type:</strong> {report.BankAccountType}
          </li>
        )}
        {report.AccountAddedDate && (
          <li>
            <strong>Account Added Date:</strong>{' '}
            {format(new Date(report.AccountAddedDate), timestampFormat)}
          </li>
        )}
        {report.AccountLastUpdatedDate && (
          <li>
            <strong>Account Last Updated Date:</strong>{' '}
            {format(new Date(report.AccountLastUpdatedDate), timestampFormat)}
          </li>
        )}
        {report.AccountClosedDate && (
          <li>
            <strong>Account Closed Date:</strong>{' '}
            {format(new Date(report.AccountClosedDate), timestampFormat)}
          </li>
        )}
        {report.FundsConfirmationResult && (
          <li>
            <strong>Funds Confirmation Result:</strong>{' '}
            {report.FundsConfirmationResult.Description}
          </li>
        )}
      </ul>
      {report.MatchedPersonData.length > 0 && (
        <React.Fragment>
          <strong>Potential Person Matches</strong>
          {report.MatchedPersonData.map((person, index) =>
            renderMatchedPersonData(person, index)
          )}
        </React.Fragment>
      )}
      {report.MatchedBusinessData.length > 0 && (
        <React.Fragment>
          <strong>Potential Business Matches</strong>
          {report.MatchedBusinessData.map((business, index) =>
            renderMatchedBusinessData(business, index)
          )}
        </React.Fragment>
      )}
      {report.OfacListPotentialMatches.length > 0 && (
        <React.Fragment>
          <strong>Potential Ofac Matches</strong>
          {report.OfacListPotentialMatches.map((business, index) =>
            renderMatchedOfacData(business, index)
          )}
        </React.Fragment>
      )}
      {report.EmailAddressInformationResult && (
        <React.Fragment>
          <strong>Email Info</strong>
          {renderEmailReport(report.EmailAddressInformationResult)}
        </React.Fragment>
      )}
    </div>
  );
};
