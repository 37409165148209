import React, { FunctionComponent, useState } from 'react';
import { FormRenderProps, useForm, FieldRenderProps } from 'react-final-form';
import { TextField, Checkbox } from '@fattmerchantorg/truffle-components';
import { Registration } from '@fattmerchantorg/types-omni';
import styled from 'styled-components';
import { Field } from '../../../shared/Field';
import {
  FormRow,
  PartialForm,
  TwoColumn,
} from '../shared/UnderwritingPartialForm';
import { formatPhoneNumber } from '../../../../util/format.util';
import { StateSelect, SelectOption } from '../shared/StateSelect';
import { CountrySelect, countryOptions } from '../shared/CountrySelect';
import {
  getStateLabel,
  getStateValues,
  mapStateToSelectOption,
} from '../../../../util/countryState.util';

const SelectLabelAlignmentFix = styled.div`
  display: flex;
`;

interface AddressFieldsProps {
  formProps?: FormRenderProps;
  registration?: Registration;
}

export const BillingAddressFields: FunctionComponent<
  AddressFieldsProps
> = props => {
  const { formProps, registration } = props;
  const mutators = props.formProps.form.mutators;
  const addressCountry = formProps.values?.business_address_country || 'USA';
  const stateLabel = getStateLabel(addressCountry);
  const stateOptions = getStateValues(addressCountry).map(
    mapStateToSelectOption
  );

  const findState = (value: string) =>
    stateOptions.find(option => option.value === value);

  const findCountry = (value: string) =>
    countryOptions.find(option => option.value === value);

  return (
    <PartialForm>
      <FormRow>
        <Field name="business_address_1">
          {props => (
            <TextField {...props.input} type="text" label="Address 1" />
          )}
        </Field>
      </FormRow>

      <FormRow>
        <Field name="business_address_2">
          {props => (
            <TextField
              {...props.input}
              type="text"
              label="Address 2"
              placeholder="Optional"
            />
          )}
        </Field>
      </FormRow>

      <FormRow>
        <TwoColumn>
          <div>
            <Field name="business_address_city">
              {props => <TextField {...props.input} type="text" label="City" />}
            </Field>
          </div>
          <div>
            <SelectLabelAlignmentFix>
              <label>{stateLabel}</label>
            </SelectLabelAlignmentFix>
            <Field
              name="business_address_state"
              format={findState}
              clearable={false}
            >
              {(props: FieldRenderProps<SelectOption, HTMLElement>) => (
                <div>
                  <StateSelect
                    {...props.input}
                    value={
                      findState(props.input?.value?.value) || {
                        label: '',
                        value: '',
                      }
                    }
                    options={stateOptions}
                    error={!!(props.meta.error && props.meta.touched)}
                    helperText={props.meta.touched ? props.meta.error : null}
                  />
                </div>
              )}
            </Field>
          </div>
        </TwoColumn>
      </FormRow>
      <FormRow>
        <TwoColumn>
          <div>
            <Field name="business_address_zip">
              {props => <TextField {...props.input} type="text" label="Zip" />}
            </Field>
          </div>
          <div>
            <SelectLabelAlignmentFix>
              <label>Country</label>
            </SelectLabelAlignmentFix>
            <Field
              name="business_address_country"
              format={findCountry}
              clearable={false}
              defaultValue={
                findCountry(registration?.business_address_country || '')
                  ?.value || ''
              }
            >
              {props => (
                <div>
                  <CountrySelect
                    {...props.input}
                    onChange={evt => {
                      // if the parent passed an 'onChange' handler via props call it
                      if (props.input.onChange) {
                        props.input.onChange(evt);
                      }
                      mutators.setFieldValue('business_address_state', '');
                    }}
                  />
                </div>
              )}
            </Field>
          </div>
        </TwoColumn>
      </FormRow>
    </PartialForm>
  );
};

export const DBALocationAddressFields: FunctionComponent<
  AddressFieldsProps
> = props => {
  const { formProps, registration } = props;
  const mutators = props.formProps.form.mutators;
  const addressCountry =
    formProps.values?.business_location_address_country || 'USA';
  const stateLabel = getStateLabel(addressCountry);
  const stateOptions = getStateValues(addressCountry).map(
    mapStateToSelectOption
  );

  const findState = (value: string) =>
    stateOptions.find(option => option.value === value);

  const findCountry = (value: string) =>
    countryOptions.find(option => option.value === value);

  const form = useForm();
  const [sameAddress, setSameAddress] = useState(false);
  const handleUseFieldsFromDbaAddress = () => {
    form.mutators.setSameAsBilling(registration);
    setSameAddress(true);
  };
  const cleanUseFieldsFromDbaAddress = () => {
    form.mutators.cleanSameAsBilling(registration);
    setSameAddress(false);
  };

  return (
    <PartialForm>
      <FormRow>
        <Field name="hasSameBillingAndLocationAddress">
          {props => (
            <Checkbox
              onChange={e => {
                if (e.target.checked) {
                  handleUseFieldsFromDbaAddress();
                } else {
                  cleanUseFieldsFromDbaAddress();
                }
              }}
              checked={sameAddress}
              label="Same as Billing Address"
            />
          )}
        </Field>
      </FormRow>
      <FormRow>
        <Field name="business_location_address_1">
          {props => (
            <TextField
              {...props.input}
              type="text"
              label="Address 1"
              disabled={sameAddress}
              error={!!formProps.errors?.business_location_address_1}
              helperText={formProps.errors?.business_location_address_1}
            />
          )}
        </Field>
      </FormRow>

      <FormRow>
        <Field name="business_location_address_2">
          {props => (
            <TextField
              {...props.input}
              type="text"
              label="Address 2"
              placeholder="Optional"
              disabled={sameAddress}
              error={!!formProps.errors?.business_location_address_2}
              helperText={formProps.errors?.business_location_address_2}
            />
          )}
        </Field>
      </FormRow>

      <FormRow>
        <TwoColumn>
          <div>
            <Field name="business_location_address_city">
              {props => (
                <TextField
                  {...props.input}
                  type="text"
                  label="City"
                  disabled={sameAddress}
                />
              )}
            </Field>
          </div>
          <div>
            <SelectLabelAlignmentFix>
              <label>{stateLabel}</label>
            </SelectLabelAlignmentFix>
            <Field
              name="business_location_address_state"
              format={findState}
              clearable={false}
            >
              {(props: FieldRenderProps<SelectOption, HTMLElement>) => (
                <div>
                  <StateSelect
                    {...props.input}
                    value={
                      findState(props.input?.value?.value) || {
                        label: '',
                        value: '',
                      }
                    }
                    options={stateOptions}
                    isDisabled={sameAddress}
                    error={!!(props.meta.error && props.meta.touched)}
                    helperText={props.meta.touched ? props.meta.error : null}
                  />
                </div>
              )}
            </Field>
          </div>
        </TwoColumn>
      </FormRow>
      <FormRow>
        <TwoColumn>
          <div>
            <Field name="business_location_address_zip">
              {props => (
                <TextField
                  {...props.input}
                  type="text"
                  label="zip"
                  disabled={sameAddress}
                />
              )}
            </Field>
          </div>
          <div>
            <SelectLabelAlignmentFix>
              <label>Country</label>
            </SelectLabelAlignmentFix>
            <Field
              name="business_location_address_country"
              format={findCountry}
              clearable={false}
              defaultValue={
                findCountry(
                  registration?.business_location_address_country || 'USA'
                )?.value || 'USA'
              }
            >
              {props => (
                <div>
                  <CountrySelect
                    {...props.input}
                    onChange={evt => {
                      // if the parent passed an 'onChange' handler via props call it
                      if (props.input.onChange) {
                        props.input.onChange(evt);
                      }
                      mutators.setFieldValue(
                        'business_location_address_state',
                        ''
                      );
                    }}
                    isDisabled={sameAddress}
                  />
                </div>
              )}
            </Field>
          </div>
        </TwoColumn>
      </FormRow>
      <FormRow>
        <Field name="business_location_email">
          {props => (
            <TextField
              {...props.input}
              type="email"
              label="Email"
              error={!!formProps.errors?.business_location_email}
              helperText={formProps.errors?.business_location_email}
              disabled={sameAddress}
            />
          )}
        </Field>
      </FormRow>
      <FormRow>
        <TwoColumn>
          <div>
            <Field
              name="business_location_phone_number"
              format={formatPhoneNumber}
            >
              {props => (
                <TextField
                  {...props.input}
                  type="text"
                  label="Phone Number"
                  error={!!formProps.errors?.business_location_phone_number}
                  helperText={formProps.errors?.business_location_phone_number}
                  disabled={sameAddress}
                />
              )}
            </Field>
          </div>
          <div>
            <Field name="business_location_fax" format={formatPhoneNumber}>
              {props => (
                <TextField
                  {...props.input}
                  type="text"
                  label="Fax Number"
                  error={!!formProps.errors?.business_location_fax}
                  helperText={formProps.errors?.business_location_fax}
                  disabled={sameAddress}
                />
              )}
            </Field>
          </div>
        </TwoColumn>
      </FormRow>
    </PartialForm>
  );
};
