import produce from 'immer';
import React, { Context, createContext, useReducer } from 'react';
import { Import } from '../../@types';
import {
  ImportContextProps,
  ImportContext,
  ImportAction,
  ImportState,
  ImportActionType
} from './Import.types';

const ImportStore = createContext(
  {} as ImportContext
) as Context<ImportContext>;

const getInitialState = (): ImportState => {
  return {
    record: null,
    mapping: null,
    targetColumns: [],
    availableTargetColumns: [],
    unavailableTargetColumns: [],
    unspecifiedColumns: [],
    hasMappingChanged: false,
    effect: null
  };
};

const initialState = getInitialState();

type Reducer = (state: ImportState, action: ImportAction) => ImportState;

const storeReducer: Reducer = (state, action) => {
  const nextState = produce(state, (draft: ImportState) => {
    switch (action.type) {
      case ImportActionType.UPDATE_IMPORT: {
        const record = action.payload as Import;
        draft.record = record;
        draft.hasMappingChanged = false;
        if (record) draft.mapping = record.mapping;
        break;
      }
      case ImportActionType.UPDATE_MAPPING_COLUMN:
        const { name, mapping } = action.payload;
        const column = draft.mapping.columns[name];

        if (column) {
          draft.hasMappingChanged = true;
          draft.mapping.columns[name] = { ...column, ...mapping };
          if (mapping.target === null) draft.mapping.columns[name].target = '';
        }

        break;
      case ImportActionType.UPDATE_TARGET_COLUMNS: {
        draft.targetColumns = action.payload;
        break;
      }
      case ImportActionType.AUTO_SPECIFY_UNSPECIFIED_MAPPING_COLUMNS: {
        draft.hasMappingChanged = true;
        Object.keys(draft.mapping.columns).forEach(column => {
          const mapping = draft.mapping.columns[column];
          draft.mapping.columns[column] = {
            ...mapping,
            confirmed: !!mapping.target,
            ignored: !mapping.target
          };
        });

        break;
      }
    }

    if (draft.targetColumns && draft.mapping?.columns) {
      let unspecifiedColumns = [];
      let unavailableTargetColumns = [];
      let availableTargetColumns = [...draft.targetColumns];
      Object.keys(draft.mapping.columns).forEach(column => {
        const mapping = draft.mapping.columns[column];

        if (!mapping.confirmed && !mapping.ignored) {
          unspecifiedColumns.push(column);
        }

        if (!mapping.target) {
          return;
        }

        const index = availableTargetColumns.indexOf(mapping.target);
        if (index > -1) {
          availableTargetColumns.splice(index, 1);
          unavailableTargetColumns.push(mapping.target);
        }
      });
      draft.unspecifiedColumns = unspecifiedColumns;
      draft.availableTargetColumns = availableTargetColumns;
      draft.unavailableTargetColumns = unavailableTargetColumns;
    }
  });

  return nextState;
};

const effectReducer: Reducer = (state, action) => {
  let newState = state;

  newState = {
    ...state,
    effect: {
      type: action.type as any,
      payload: state
    }
  };

  return newState;
};

const reduceReducers = (...reducers) => (state, action) => {
  return reducers.reduce(
    (nextState, reducer) => reducer(nextState, action),
    state
  );
};

const ImportProvider = (props: ImportContextProps) => {
  const [state, dispatch] = useReducer(
    reduceReducers(storeReducer, effectReducer),
    initialState
  );

  const localContext: ImportContext = {
    state: state,
    dispatch: dispatch
  };

  return (
    <ImportStore.Provider value={localContext}>
      {props.children}
    </ImportStore.Provider>
  );
};

export { ImportStore, ImportProvider };
