import {
  PaymentMethod,
  Invoice,
  OmniDispute as Dispute,
  Customer,
  Transaction,
} from '@fattmerchantorg/types-omni';
import { DB } from '@fattmerchantorg/types-engine';
/* Utility Types */
export type RequiredKeys<T> = {
  [K in keyof T]-?: T[K];
};

export interface TransactionData extends Transaction {
  amount: number;
  processor_name: string;
  transaction_id: string;
  parent_transaction_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  address_1: string;
  address_city: string;
  address_state: string;
  method_type: PaymentMethod['method'];
  last_four: string;
  expiration: string;
  card_brand: string;
  type: Transaction['type'] & DB.TransactionType;
  subtype: string;
  fee_description: string;
  company_id: string;
  parent_transaction: Transaction;
  state: DB.TransactionState;
  dispute_id: string;
  authorization_code: string;
  success: boolean;
  status: 'SUCCESS' | 'FAILED' | 'PENDING';
  id_to_link: string;
  database: 'fatt' | 'processor';
}

/*
This should be the list of AdvancedDetailDrawer implementations that exist in in
the Connect codebase. This should be updated as drawers are implemented.
*/
export const drawerTypes = {
  Transaction: 'Transaction',
  Fee: 'Fee',
  Refund: 'Refund',
  DisputeTransaction: 'DisputeTransaction',
  // RecurringFee: 'RecurringFee',
  Adjustment: 'Adjustment',
} as const;

// These would be the base props on the AdvancedDetailDrawer component.
export interface AdvancedDetailDrawerProps<T> {
  initialData: T;
  onClose: () => void;
  onSave?: (data: Partial<T>) => Promise<void>;
}

/**
 * These "[...]SectionData" interfaces are responsible for defining the data
 * shape for each unique section that can be rendered in the body of the
 * AdvancedDetailDrawer component.
 *
 * Keep in mind that modifying an existing section data type will impact every
 * drawer that particular section is used in. So if you need significant changes
 * make sure they're compatible or create a new section definition.
 *
 * @see ./drawer-configs.tsx for the drawer configurations where these are
 * ultimately used
 */
export interface ProcessingDetailsSectionData {
  charge: Transaction['total'];
  processingFees: number;
  total: number;
  processor: string;
  transactionProfile: string;
  transactionId: Transaction['id'];
  source?: Transaction['source'];
  surcharge?: number;
  surchargeDate?: Transaction['created_at'];
  merchant_id?: Transaction['merchant_id'];
  settled_at?: Transaction['settled_at'];
  is_settlement?: boolean;
  batch_id?: Transaction['batch_id'];
  type?: Transaction['type'];
  parent_transaction_id?: Transaction['id'];
}

export interface CustomerDetailsSectionData {
  firstName: Customer['firstname'];
  lastName: Customer['lastname'];
  email: Customer['email'];
  phone: Customer['phone'];
  address1: Customer['address_1'];
  address2: Customer['address_2'];
  addressCity: Customer['address_city'];
  addressState: Customer['address_state'];
  addressZip: Customer['address_zip'];
  company: Customer['company'];
  source:
    | 'Terminal'
    | 'Virtual Terminal'
    | 'Mobile'
    | 'Website Payments'
    | 'Intuit'
    | string;
  payment_method?: {
    method: PaymentMethod['method'];
    bin_type?: PaymentMethod['bin_type'];
    lastFour: PaymentMethod['card_last_four'];
    expiration: PaymentMethod['card_exp'];
    card_type: PaymentMethod['card_type'];
    // @see TransactionSourceCell.tsx in StaxPay
  };
}

export interface InvoiceDetailsSectionData {
  lineItems: Invoice['meta']['lineItems'];
  subtotal: Invoice['meta']['subtotal'];
  total: Invoice['total'];
  surcharge?: Invoice['meta']['surcharge'];
  tax?: Invoice['meta']['tax'];
  shippingAmount?: Invoice['meta']['shippingAmount'];
  tip?: number;
  isTipEnabled?: boolean;
}

export interface DisputesSectionData {
  disputeId: Dispute['id'];
  merchantId: Dispute['merchant_id'];
  status: Dispute['status'];
  reason: Dispute['reason'];
  createdAt: Dispute['created_at'];
  respondBy: Dispute['respond_by'];
  amount: Dispute['amount'];
  hasDeadlinePassed: boolean;
}

export interface Fees {
  id: string;
  type: string;
  subtype: string;
  name: string;
  total: number;
  settled_at: string;
  settlement_id: string;
  transaction_id: string;
}
export interface FeesSectionData {
  total_fees: number;
  fees: Array<Fees>;
  transaction_type?: string;
  isSettlement?: boolean;
}

export interface TransactionHistorySectionData {
  history: TransactionData[];
}

export interface AdjustmentSectionData {
  transactionId: string;
  category: string;
  description: string;
  parentTransaction: string;
  settlementId: string;
  createdBy: string;
}

export interface AdjustmentProcessingDetailsSectionData {
  transactionId: string;
  adjustment: number;
  total: number;
  processor: string;
}

export type SectionData =
  | ProcessingDetailsSectionData
  | CustomerDetailsSectionData
  | InvoiceDetailsSectionData
  | FeesSectionData
  | DisputesSectionData
  | TransactionHistorySectionData
  | AdjustmentSectionData
  | AdjustmentProcessingDetailsSectionData;

export const sectionDataIdentifiers = {
  ProcessingDetailsSectionData: 'ProcessingDetailsSectionData',
  CustomerDetailsSectionData: 'CustomerDetailsSectionData',
  InvoiceDetailsSectionData: 'InvoiceDetailsSectionData',
  ProcessingFeesSectionData: 'ProcessingFeesSectionData',
  AdditionalFeesSectionData: 'AdditionalFeesSectionData',
  DisputesSectionData: 'DisputesSectionData',
  TransactionHistorySectionData: 'TransactionHistorySectionData',
  AdjustmentSectionData: 'AdjustmentSectionData',
  AdjustmentProcessingDetailsSectionData:
    'AdjustmentProcessingDetailsSectionData',
} as const;
