import { Dispatch, ReactNode } from 'react';
import { AsyncData, AsyncDataStatus } from '../../@types';
import {
  APIKey,
  Merchant,
  Gateway,
  PricingPlan,
  Registration,
  GodviewBrandData,
} from '@fattmerchantorg/types-omni';
import {
  Billing,
  PaymentMethod,
  Reserves,
} from '@fattmerchantorg/types-engine/DB';

export interface SelectedMerchantContextProps {
  initialState?: State;
  children: ReactNode;
}

export interface State {
  /**
   * Represents the status of the async data fetch for the selected merchant.
   */
  status: keyof typeof AsyncDataStatus;
  merchantId: string | null;
  merchant: Merchant | null;
  registration: Registration | null;
  gateways: AsyncData<Gateway[]>;
  apiKeys: AsyncData<APIKey[]>;
  brand: AsyncData<
    GodviewBrandData & {
      /** Contains only the pricing plans which specify the selected merchant's brand. */
      filteredPricingFields: PricingPlan[];
    }
  >;
  effect: Effect;
  /**
   * The name `selectedBrandSwitcherOption` is for specificity.
   * The `option` represents 1 brand right, but it'll be multiple in the future. */
  selectedBrandSwitcherOption: string | null;
  billingProfiles: AsyncData<Billing[]>;
  paymentMethods: AsyncData<PaymentMethod[]>;
  merchantFundingAccounts: AsyncData<[]>;
  reserves: AsyncData<Reserves[]>;
}

export type SelectedMerchantState = State;

export enum ActionType {
  FETCH_SELECTED_MERCHANT = 'FETCH_SELECTED_MERCHANT',
  REMOVE_SELECTED_MERCHANT = 'REMOVE_SELECTED_MERCHANT',
  UPDATE_SELECTED_MERCHANT = 'UPDATE_SELECTED_MERCHANT',
  UPDATE_SELECTED_MERCHANT_STATUS = 'UPDATE_SELECTED_MERCHANT_STATUS',
  ALTER_SELECTED_MERCHANT = 'ALTER_SELECTED_MERCHANT',

  ADD_SELECTED_MERCHANT = 'ADD_SELECTED_MERCHANT',
  REHYDRATE_SELECTED_MERCHANT = 'REHYDRATE_SELECTED_MERCHANT',

  CLEAR_SELECTED_MERCHANT_GATEWAYS = 'CLEAR_SELECTED_MERCHANT_GATEWAYS',
  UPDATE_SELECTED_MERCHANT_GATEWAYS = 'UPDATE_SELECTED_MERCHANT_GATEWAYS',
  REMOVE_SELECTED_MERCHANT_GATEWAY = 'REMOVE_SELECTED_MERCHANT_GATEWAY',
  ADD_SELECTED_MERCHANT_GATEWAY = 'ADD_SELECTED_MERCHANT_GATEWAY',

  CLEAR_SELECTED_MERCHANT_APIKEYS = 'CLEAR_SELECTED_MERCHANT_APIKEYS',
  UPDATE_SELECTED_MERCHANT_APIKEYS = 'UPDATE_SELECTED_MERCHANT_APIKEYS',
  DELETE_SELECTED_MERCHANT_APIKEY = 'DELETE_SELECTED_MERCHANT_APIKEY',
  REMOVE_SELECTED_MERCHANT_APIKEY = 'REMOVE_SELECTED_MERCHANT_APIKEY',
  APPEND_SELECTED_MERCHANT_APIKEY = 'APPEND_SELECTED_MERCHANT_APIKEY',

  UPDATE_SELECTED_MERCHANT_REGISTRATION = 'UPDATE_SELECTED_MERCHANT_REGISTRATION',
  REMOVE_SELECTED_MERCHANT_REGISTRATION = 'REMOVE_SELECTED_MERCHANT_REGISTRATION',

  FETCH_SELECTED_MERCHANT_BRAND_DATA = 'FETCH_SELECTED_MERCHANT_BRAND_DATA',
  UPDATE_SELECTED_MERCHANT_BRAND_DATA = 'UPDATE_SELECTED_MERCHANT_BRAND_DATA',
  REMOVE_SELECTED_MERCHANT_BRAND_DATA = 'REMOVE_SELECTED_MERCHANT_BRAND_DATA',

  UPDATE_SELECTED_BRAND_SWITCHER_OPTION = 'UPDATE_SELECTED_BRAND_SWITCHER_OPTION',

  FETCH_SELECTED_MERCHANT_BILLING_PROFILES = 'FETCH_SELECTED_MERCHANT_BILLING_PROFILES',
  UPDATE_SELECTED_MERCHANT_BILLING_PROFILES = 'UPDATE_SELECTED_MERCHANT_BILLING_PROFILES',

  FETCH_SELECTED_MERCHANT_PAYMENT_METHODS = 'FETCH_SELECTED_MERCHANT_PAYMENT_METHODS',
  UPDATE_SELECTED_MERCHANT_PAYMENT_METHODS = 'UPDATE_SELECTED_MERCHANT_PAYMENT_METHODS',

  FETCH_MERCHANT_FUNDING_ACCOUNTS = 'FETCH_MERCHANT_FUNDING_ACCOUNTS',
  UPDATE_MERCHANT_FUNDING_ACCOUNTS = 'UPDATE_MERCHANT_FUNDING_ACCOUNTS',

  FETCH_SELECTED_MERCHANT_RESERVES = 'FETCH_SELECTED_MERCHANT_RESERVES',
  UPDATE_SELECTED_MERCHANT_RESERVES = 'UPDATE_SELECTED_MERCHANT_RESERVES',
}

export type EffectType = ActionType;

export type Action = { type: ActionType; payload?: any };
export type Effect = Action;

export interface SelectedMerchantContext {
  state: State;
  dispatch: Dispatch<Action>;
}
