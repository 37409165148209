import moment from 'moment';
import React from 'react';
import { IAxisRenderer, ITick } from './types';

export function BottomAxis(selectedTimespan: string): object {
  // Default axisBottom renderer.
  let axisBottomRendering: IAxisRenderer = {
    legend: '',
    legendPosition: 'middle',
    legendOffset: 40
  };

  switch (selectedTimespan) {
    case 'TODAY':
    case 'YESTERDAY':
      axisBottomRendering = {
        ...axisBottomRendering,
        renderTick: renderAsHoursInDay,
        legend: 'Hours of the Day'
      };
      break;
    case 'THIS_WEEK':
    case 'LAST_WEEK':
      axisBottomRendering = {
        ...axisBottomRendering,
        renderTick: renderAsDaysOfTheWeek,
        legend: 'Days of the Week'
      };
      break;
    case 'THIS_MONTH':
    case 'LAST_MONTH':
      axisBottomRendering = {
        ...axisBottomRendering,
        renderTick: renderAsOrdinalDays,
        legend: 'Days of the Month'
      };
      break;
    case 'THIS_YEAR':
    case 'LAST_YEAR':
      axisBottomRendering = {
        ...axisBottomRendering,
        renderTick: renderAsMonthsOfTheYear,
        legend: 'Months of the Year'
      };
      break;
    default:
      // If no renderTick() and legend are added, then
      // the default Nevo renderer will run and that's ok.
      break;
  }

  return axisBottomRendering;
}

//#region Helpers that format the x-axis label.
export function renderAsMonthsOfTheYear(tick: ITick): JSX.Element {
  // Pluck the x-axis value, and its position.
  const { value: axisValue, x, y } = tick;
  const date = moment(axisValue);
  const monthOfYear = date.format('MMM'); // Get month.

  return generateGenericSVG(x, y, monthOfYear);
}

export function renderAsOrdinalDays(tick: ITick): JSX.Element {
  // Pluck the x-axis value, and its position.
  const { value: axisValue, x, y, tickIndex } = tick;
  const date = moment(axisValue);
  const dayAsNumber = tickIndex % 2 === 0 ? date.format('Do') : ''; // Get day of the month as a number.

  return generateGenericSVG(x, y, dayAsNumber.toString());
}

export function renderAsDaysOfTheWeek(tick: ITick): JSX.Element {
  // Pluck the x-axis value, and its position.
  const { value: axisValue, x, y } = tick;
  const date = moment(axisValue);
  const dayOfWeek = date.format('ddd'); // Get day of the week.

  return generateGenericSVG(x, y, dayOfWeek);
}

export function renderAsHoursInDay(tick: ITick): JSX.Element {
  // Pluck the x-axis value, and its position.
  const { value: axisValue, x, y, tickIndex } = tick;
  const date = moment(axisValue);
  const hourOfDay = tickIndex % 2 === 0 ? date.format('ha') : ''; // Get hour of the day.

  return generateGenericSVG(x, y, hourOfDay);
}
//#endregion

function generateGenericSVG(x: number, y: number, value: string): JSX.Element {
  // The styles and <line> in the element below are needed to retain
  // default styles and align with the rest of the non-custom elements.
  return (
    <g transform={`translate(${x},${y})`}>
      <line
        x1="0"
        x2="0"
        y1="0"
        y2="5"
        style={{ stroke: 'rgb(119, 119, 119)', strokeWidth: 1 }}
      ></line>
      <text
        textAnchor="middle"
        dominantBaseline="text-before-edge"
        transform="translate(0,10) rotate(0)"
        style={{
          fill: 'rgb(255, 255, 255)',
          fontSize: 11
        }}
      >
        {value}
      </text>
    </g>
  );
}
