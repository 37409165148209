import { FundingAccount } from '@fattmerchantorg/types-omni';
import {
  BankAccountFormErrors,
  BankAccountFormValues,
} from './BankAccountForm.types';

export const validateForm = (
  formValues: BankAccountFormValues
): BankAccountFormErrors => {
  const formErrors = {} as BankAccountFormErrors;

  if (!formValues.id) {
    // if we do not have an id, we are creating a new account

    if (!formValues.bank_routing_number) {
      formErrors.bank_routing_number = 'Required';
    } else if (formValues.bank_routing_number.length !== 9) {
      formErrors.bank_routing_number = 'Must be exactly 9 digits';
    }

    if (!formValues.bank_account_number) {
      formErrors.bank_account_number = 'Required';
    } else if (formValues.bank_account_number.length < 5) {
      formErrors.bank_account_number = 'Must be at least 5 digits';
    } else if (formValues.bank_account_number.length > 17) {
      formErrors.bank_account_number = 'Must be fewer than 17 digits';
    }
  }

  return formErrors;
};

export const mapBankAccountToInitialFormValues = (
  account: FundingAccount
): BankAccountFormValues => {
  if (account) {
    const flags: string[] = account.flags?.split(',') || [];

    // the PRIMARY flag is handled separately by the isPrimaryAccount checkbox, so remove it here
    if (flags.includes('PRIMARY')) {
      flags.splice(flags.indexOf('PRIMARY'), 1);
    }

    return {
      id: account.id,
      name: account.name,
      person_name: account.person_name,
      active: account.active,
      flags: flags.join(','),
      isPrimaryAccount: account.flags?.includes('PRIMARY'),
      files: account.files,

      status: account.status,
    };
  } else {
    return {
      name: '',
      person_name: '',
      flags: '',
      active: false,
      isPrimaryAccount: false,
      files: [],

      bank_account_number: '',
      bank_routing_number: '',
    };
  }
};

export const mapFormValuesToPayload = (formValues: BankAccountFormValues) => {
  const flags: string[] = formValues.flags?.split(',') || [];
  if (formValues.isPrimaryAccount) flags.push('PRIMARY');
  return {
    ...formValues,
    person_name: formValues.person_name ?? '',
    // handle user clearing out nickname
    name: formValues.name ?? '',
    flags: flags.join(','),
  };
};
