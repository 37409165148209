import React, {
  FunctionComponent,
  useContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import { Registration } from '@fattmerchantorg/types-omni';
import { Form } from '../shared';
import { coreapi } from '../../api';
import {
  SelectedMerchantStore,
  updateSelectedMerchantRegistration,
  fetchSelectedMerchantBrandData,
  AuthStore,
} from '../../context';
import { BusinessFields } from './components/business/BusinessFields';
import { PaymentsFields } from './components/payments/PaymentsFields';
import { FinancialFields } from './components/financial/FinancialFields';
import { Documents } from './components/documents/Documents';
import { useToaster, useAuthToken } from '../../hooks';
import {
  RegistrationFormValues,
  RegistrationFormErrors,
} from './RegistrationForm.types';
import * as financialFieldsUtil from './components/financial/FinancialFields.util';
import * as paymentsFieldsUtil from './components/payments/PaymentsFields.util';
import * as businessFieldsUtil from './components/business/BusinessFields.util';
import { AsyncDataStatus } from '../../@types';
import { StaxButton } from '../shared/Stax/Button';
import { useUnderwritingStatus } from '../../hooks/useUnderwritingStatus';

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  align-content: stretch;

  > * {
    padding: 1rem;

    @media (min-width: 1000px) {
      flex-basis: 50%;
    }
  }
`;

const FormSection = styled.div`
  > * {
    margin-bottom: 0.5rem;
  }

  @media (max-width: 1000px) {
    > * {
      margin-bottom: 0px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: 0;
  width: 100%;
  justify-content: end;
  padding: 16px;
`;

const validateForm = (
  formValues: RegistrationFormValues
): RegistrationFormErrors => {
  const errors = {
    ...businessFieldsUtil.validateFormValues(formValues),
    ...financialFieldsUtil.validateFormValues(formValues),
    ...paymentsFieldsUtil.validateFormValues(formValues),
  } as RegistrationFormErrors;

  return errors;
};

export const RegistrationForm: FunctionComponent = () => {
  const authToken = useAuthToken();
  const { toaster, toast } = useToaster();
  const [pricingPlanValidity, setPricingPlanValidity] = useState<
    'INITIAL' | 'VALID' | 'INVALID'
  >('INITIAL');
  const {
    dispatch,
    state: { status, merchantId, merchant, registration },
  } = useContext(SelectedMerchantStore);
  const { state: authState } = useContext(AuthStore);

  const merchantBrand = merchant?.brand;
  const { isFullyApproved } = useUnderwritingStatus();

  const onSubmit = useCallback(
    async (formValues: RegistrationFormValues) => {
      const payload: Partial<Registration> = {
        ...businessFieldsUtil.mapFormValuesToPayload(formValues),
        ...financialFieldsUtil.mapFormValuesToPayload(formValues),
        ...paymentsFieldsUtil.mapFormValuesToPayload(formValues),
      };
      // We dont want to override existing meta values that are not part of the UW form
      payload.meta = Object.assign({}, registration.meta, payload.meta);

      // Remove files off the body so we don't run into weirdness with adding/removing files
      // This will not remove existing files off a record
      delete payload['files'];

      const defaultLimitValue = 500000;

      const { brand } = merchant;
      const brandToLower = brand.toLowerCase();
      const isQualifiedbrand =
        /fattmerchant/.test(brandToLower) ||
        /paymentdepot/.test(brandToLower) ||
        /sandbox/.test(brandToLower);

      const { highest_trans_amount, largest_ach_transaction } = formValues;

      const merchantOptions = {
        ...merchant.options,
        transaction_limit: isQualifiedbrand
          ? highest_trans_amount
          : defaultLimitValue,
        ach_transaction_limit: isQualifiedbrand
          ? largest_ach_transaction
          : defaultLimitValue,
      };

      // update merchant optiuons
      try {
        // update registration
        const r = await coreapi.put(
          authToken,
          `/merchant/${merchantId}/registration`,
          payload
        );

        // update merchnat options
        await coreapi.put(authToken, `/merchant/${merchantId}`, {
          options: merchantOptions,
        });
        dispatch(updateSelectedMerchantRegistration(r));
        toaster(toast.success('Changes successfully saved', 'Saved'));
      } catch (error) {
        toaster(toast.error(error, 'There was a problem saving your changes.'));
      }
    },
    [registration, merchant, authToken, merchantId, dispatch, toaster, toast]
  );

  useEffect(() => {
    if (
      status === AsyncDataStatus.IDLE &&
      merchantBrand &&
      authState.merchantToken
    ) {
      dispatch(fetchSelectedMerchantBrandData(merchantBrand));
    }
  }, [dispatch, merchantBrand, status, authState]);

  return (
    <Form
      initialValues={{
        ...businessFieldsUtil.mapRegistrationToInitialFormValues(registration),
        ...financialFieldsUtil.mapRegistrationToInitialFormValues(registration),
        ...paymentsFieldsUtil.mapRegistrationToInitialFormValues(registration),
      }}
      validate={formValues => {
        const validationErrors = {
          ...validateForm(formValues),
        } as RegistrationFormErrors;
        if (pricingPlanValidity === 'INVALID') {
          validationErrors.pricing_plan =
            'Please select a pricing plan available for the chosen Processing Method';
        }
        return validationErrors;
      }}
      onSubmit={onSubmit}
      mutators={financialFieldsUtil.formMutators}
    >
      {formProps => (
        <>
          <Container>
            <FormSection>
              <BusinessFields mode="edit" {...formProps} />
            </FormSection>
            <FormSection>
              <PaymentsFields
                mode="edit"
                setPricingPlanValidity={setPricingPlanValidity}
                {...formProps}
              />
            </FormSection>
            <FormSection>
              <FinancialFields mode="edit" {...formProps} />
            </FormSection>

            <Documents accept="image/jpeg,image/jpg,image/png,application/pdf,.pdf,.jpeg,.jpg,.png" />
          </Container>

          {isFullyApproved ? null : (
            <ButtonContainer>
              <StaxButton
                type="submit"
                icon="fas fa-check"
                id="review-submit-button"
                data-testid="review-submit-button"
                loading={formProps.submitting}
                disabled={formProps.submitting}
              >
                Save
              </StaxButton>
            </ButtonContainer>
          )}
        </>
      )}
    </Form>
  );
};
