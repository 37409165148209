import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { importerapi } from '../../../../api';
import { ImportStore, SelectedMerchantStore } from '../../../../context';
import {
  useAuthToken,
  usePermissions,
  useRouteMatchImportId,
  useRouteMatchMerchantId,
  useToaster,
} from '../../../../hooks';
import {
  formatCapitalCase,
  formatReadableDate,
  formatWithComma,
} from '../../../../util/format.util';
import {
  Button,
  Group,
  Modal,
  ResponsivePadding,
  Spinner,
} from '../../../shared';
import {
  ImporterButtonBar,
  ImporterCard,
  ImporterPieChart,
  ImporterMerchantStatusPill,
  ImporterExceptionCard,
} from './';

const GroupSpinner = () => (
  <div style={{ paddingTop: '1rem', textAlign: 'center' }}>
    <Spinner />
  </div>
);

const TopCardGroup = styled(Group)`
  > div {
    flex: 1;

    > h1 {
      font-weight: bold;
      padding-bottom: 0.5rem;
    }

    span {
      line-height: 24px;
      font-size: 14px;
    }
  }
`;

const Status = styled.div`
  height: 24px;
  display: flex;
  align-items: center;

  > span {
    padding-right: 4px;
  }
`;

const Count = withTheme(
  styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;

    > h1 {
      font-weight: normal;
      color: ${(props: { name: 'ready' | 'omitted' }) =>
        props.name === 'ready' ? '#F56500' : '#B3142C'};
      font-size: ${(props: { name: 'ready' | 'omitted' }) =>
        props.name === 'ready' ? '40px' : '32px'};
      padding-bottom: 0;
      margin-bottom: 0;
    }

    > span {
      font-size: 12px;
      color: ${({ theme }) =>
        theme.white} !important; // Copy under "Total Records";
      font-weight: 500;
      text-transform: uppercase;
    }
  `
);

const BottomGroup = styled(Group)`
  align-items: flex-start;

  > div:first-child {
    flex-basis: calc(50% - 0.5rem);
    margin-right: 0.5rem;
  }

  > div:nth-child(2) {
    flex-basis: calc(50% - 0.5rem);
    margin-left: 0.5rem;
  }

  p {
    padding: 0;
    margin: 0;
    font-size: 14px;
  }
`;

export const ImporterSummary: FunctionComponent = () => {
  const {
    state: { record },
  } = useContext(ImportStore);
  const {
    state: { merchant },
  } = useContext(SelectedMerchantStore);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const authToken = useAuthToken();
  const merchantId = useRouteMatchMerchantId();
  const importId = useRouteMatchImportId();
  const { toaster, toast } = useToaster();

  const { permit } = usePermissions();
  const history = useHistory();

  const handleStartImport = useCallback(async () => {
    setIsConfirmModalOpen(false);

    try {
      await importerapi.post(authToken, `/import/${importId}/execute`);

      toaster(toast.success(`Import ${importId} has been started`));
      history.push(`/merchant/${merchantId}/imports`);
    } catch (error) {
      toaster(toast.error(error));
    }
  }, [authToken, importId, merchantId, history, toast, toaster]);

  return (
    <>
      <Group direction="column">
        <ImporterCard>
          <ResponsivePadding desktop="1rem" mobile="0">
            <TopCardGroup justify="space-between">
              <Group direction="column">
                <h1>Target Merchant</h1>
                {merchant ? (
                  <>
                    <span>
                      Company Name: <strong>{merchant.company_name}</strong>
                    </span>
                    <span>PMID: {merchant.id}</span>
                    <span>MID: {merchant.mid}</span>
                    <Status>
                      <span>Status:</span>{' '}
                      <ImporterMerchantStatusPill status={merchant.status} />
                    </Status>
                    <span>
                      Created: {formatReadableDate(merchant.created_at)}
                    </span>
                    <span>Plan: {formatCapitalCase(merchant.plan?.name)}</span>
                    {permit('godview', 'canSeeAllBrands', 'read') && (
                      <span>Brand: {formatCapitalCase(merchant.brand)}</span>
                    )}
                    <span>Type: {merchant.is_payfac ? 'Payfac' : 'ISO'}</span>
                  </>
                ) : (
                  <GroupSpinner />
                )}
              </Group>
              <Group direction="column">
                <h1>Total Records</h1>
                {record ? (
                  <>
                    <Count name="ready">
                      <h1>
                        {record.state === 'COMPLETE'
                          ? formatWithComma(record.summary?.imported_count || 0)
                          : formatWithComma(record.summary?.record_count || 0)}
                      </h1>
                      <span>
                        {record.state === 'COMPLETE'
                          ? 'Imported'
                          : record.state === 'IMPORTING'
                          ? 'Importing'
                          : 'Ready for import'}
                      </span>
                    </Count>
                    <Count name="omitted">
                      <h1>
                        {/* the number of omitted records is the number of errors, since those records will be unable to be imported */}
                        {formatWithComma(
                          record.summary?.errors?.reduce(
                            (s, e) => s + e.count,
                            0
                          ) ?? 0
                        )}
                      </h1>
                      <span>Omitted</span>
                    </Count>
                  </>
                ) : (
                  <GroupSpinner />
                )}
              </Group>
              <Group direction="column">
                <h1>Error Handling</h1>
                <ImporterPieChart record={record} />
              </Group>
            </TopCardGroup>
          </ResponsivePadding>
        </ImporterCard>

        <BottomGroup>
          <ImporterExceptionCard record={record} type="warnings" />
          <ImporterExceptionCard record={record} type="errors" />
        </BottomGroup>
      </Group>
      <Modal
        title="Start Import"
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
      >
        <ResponsivePadding desktop="1rem" mobile="1rem">
          <p>
            Carefully review all information on the current page before
            continuing.
          </p>
          <Group justify="space-between">
            <Button
              variant="outline"
              onClick={() => setIsConfirmModalOpen(false)}
            >
              Cancel
            </Button>
            <Button onClick={handleStartImport}>Start Import</Button>
          </Group>
        </ResponsivePadding>
      </Modal>
      <ImporterButtonBar
        onBack={() => {
          if (record?.state === 'COMPLETE') {
            history.push(`/merchant/${merchantId}/imports`);
          } else {
            history.push(`/merchant/${merchantId}/imports/${importId}/match`);
          }
        }}
        onContinue={() => setIsConfirmModalOpen(true)}
        continueButtonProps={{
          hidden: record?.state !== 'UPLOAD_COMPLETE',
          children: 'Confirm',
        }}
      />
    </>
  );
};
