import { Omni } from '@fattmerchantorg/types-omni';
import { ToastContext } from '../../../../../context';
import { ProcessorNames } from './SubmitApplicationForm.types';
import { ProcessorName } from '@fattmerchantorg/types-engine/DB';
import { onboardingapi } from '../../../../../api';
import {
  Portfolio,
  formatCatanCompanyName,
} from '../../../../../util/catan.util';
import React from 'react';

type HandleCatanProvisionParams = {
  type: ProcessorName;
  registration: Omni.Registration;
  portfolio: Portfolio;
  authToken: Omni.Auth['token'];
  toastContext: Pick<ToastContext, 'toaster' | 'toast'>;
  forceNewApplication?: boolean;
  processorConfig?: {
    achOnly?: boolean;
  };
};

export const handleCatanProvision = async ({
  type,
  registration,
  portfolio,
  authToken,
  toastContext,
  forceNewApplication,
  processorConfig,
}: HandleCatanProvisionParams): Promise<void> => {
  const { toaster, toast } = toastContext;
  try {
    const payload: Record<string, any> = {
      processorName: type,
      portfolioId: portfolio.payfac_id,
      portfolioName: portfolio.payfac_name,
      registration,
      forceNewApplication,
    };
    if (Object.keys(processorConfig).length > 0) {
      payload.processorConfig = processorConfig;
    }
    // submit to APPS if we're attempting to submit APPS_ACH with ACH-only
    if (type === 'APPS_ACH' && processorConfig.achOnly) {
      payload.processorName = 'APPS';
      // always force new application (Void Original & Submit a New Application) for ACH-only
      payload.forceNewApplication = true;
    }
    await onboardingapi.post(authToken, '/processor-provision', payload);
    toaster(
      toast.success(
        `Successfully Submitted to ${ProcessorNames[type]}`,
        'Submitted'
      )
    );
  } catch (e) {
    let validationErrors: ProcessorValidationError[] = [];

    if (
      // handle validation errors from catan
      e.status === 422 &&
      e.message === 'Validation Error'
    ) {
      let [errors] = e.errors;
      if (typeof errors === 'string') {
        errors = [errors];
      }

      validationErrors = errors;
    } else if (
      // handle validation errors from underwriting
      e.errors?.[0]?.message
    ) {
      validationErrors = e.errors;
    } else if (e.type === 'CatanValidationError') {
      if (e.group === true) {
        const contentNodes: React.ReactNode[] = [];
        if (e.errors?.length > 2) {
          contentNodes.push(React.createElement('div', null, e.errors[0]));
          contentNodes.push(
            React.createElement(
              'div',
              null,
              `and ${e.errors.length - 1} more errors`
            )
          );
        } else {
          validationErrors = e.errors.map(msg =>
            contentNodes.push(React.createElement('div', null, msg))
          );
        }
        toaster(
          toast.error(
            React.createElement('div', null, contentNodes),
            'Validation Error',
            { lifetime: 10000 }
          )
        );
        return;
      } else {
        validationErrors = e.errors.map(msg => ({ message: msg }));
      }
    }

    if (validationErrors.length) {
      validationErrors.forEach(err => {
        const { title, description } = formatProcessorValidationError(
          err,
          type
        );

        const content = getMessageContent(description);

        toaster(toast.error(content, title, { lifetime: 10000 }));
      });

      return;
    }

    toaster(
      toast.error(
        `An error occurred while trying to provision with processor ${ProcessorNames[type]} : ${e.message}`
      )
    );
  }
};

function formatProcessorValidationError(
  error: ProcessorValidationError,
  processor: ProcessorName
): { title: string; description?: string } {
  if (typeof error === 'string') {
    return {
      title: error,
    };
  } else if (processor === 'PPSFD' || processor === 'PPSTSYS') {
    return {
      description: error.path
        .map(er => {
          const res = er.replace(/([A-Z])/g, ' $1');
          return res.charAt(0).toUpperCase() + res.slice(1);
        })
        .join(' -> '),
      title: error.message,
    };
  } else if (error.context?.value === null) {
    const property = error.path[1]
      .split('_')
      .filter(x => x.length > 0)
      .map(x => x.charAt(0).toUpperCase() + x.slice(1))
      .join(' ');
    return {
      title: `${property} is invalid.`,
      description: 'Value is empty',
    };
  } else if (error.context?.valids) {
    return {
      title: `Invalid Type: ${error.context.value}.`,
      description: `Must be one of : ${error.context.valids
        .map(type => formatCatanCompanyName(type) ?? type)
        .join(', ')}`,
    };
  } else if (typeof error?.message === 'string') {
    if (error.message.startsWith('- Missing')) {
      return {
        description: error.message,
        title: 'THERE WAS A PROBLEM SUBMITTING THE MERCHANT',
      };
    }
    return {
      description: error.message,
      title: 'Validation error',
    };
  }
  return {
    title: 'Unknown validation error encountered',
  };
}

type ProcessorValidationError =
  | {
      message: string;
      path?: string[];
      type?: string;
      context?: {
        valids?: string[];
        label: string;
        value: any;
        key: string;
      };
    }
  | string;
function getMessageContent(description: string): React.ReactNode {
  if (description?.startsWith('- Missing')) {
    const profileErrors = description
      .split(',')
      .map(err => err.trim().replace(/^- /, '')); // Remove the dash

    return (
      <div>
        <div>Missing Billing Profiles</div>
        {profileErrors.map((err, idx) => (
          <div key={idx}>• {err}</div>
        ))}
      </div>
    );
  }
  return description; // Return as-is for other descriptions
}
