import React from 'react';
import styled from 'styled-components';
import {
  BaseModal,
  Icon,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
} from '@fattmerchantorg/truffle-components';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  > * {
    margin: 0 0 0 ${({ theme }) => theme.space[3]}px;
  }
`;

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  > h2 {
    color: white;
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
  }
`;

const StyledModalHeader = styled(ModalHeader)`
  h1 {
    font-size: 16px;
    font-weight: 700;
  }
`;

interface PayoutReAttemptConfirmationModalProps {
  isOpen: boolean;
  isProcessing?: boolean;
  onClose?: () => void;
  onReAttempt?: () => void;
}

export const PayoutReAttemptConfirmationModal: React.FC<
  PayoutReAttemptConfirmationModalProps
> = props => {
  const { isOpen, isProcessing, onClose, onReAttempt } = props;
  return (
    <BaseModal
      title="Re-attempt Payout"
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      style={{
        overlay: { pointerEvents: 'auto' },
        content: { maxWidth: '480px', height: '312px' },
      }}
    >
      <StyledModalHeader title={'Re-attempt Payout'} onClose={onClose} />
      <StyledModalContent>
        <Icon
          icon={faExclamationTriangle}
          style={{ flex: 2 }}
          color="#8D9799"
          size="4x"
        />
        <h2>Are you sure you want to re-attempt this payout?</h2>
      </StyledModalContent>
      <ModalFooter>
        <ButtonsContainer>
          <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
          <PrimaryButton disabled={isProcessing} onClick={onReAttempt}>
            {isProcessing && (
              <Icon icon={faSpinnerThird} spin={true} size="sm" />
            )}
            Re-attempt
          </PrimaryButton>
        </ButtonsContainer>
      </ModalFooter>
    </BaseModal>
  );
};
