import { FunctionComponent, useContext } from 'react';
import {
  useAsyncEffect,
  useAuthToken,
  useRouteMatchMerchantId,
} from '../../../hooks';
import { SelectedMerchantStore, fetchSelectedMerchant } from '../../../context';
import { AsyncDataStatus } from '../../../@types';

export const SelectedMerchantFetcher: FunctionComponent = () => {
  const authToken = useAuthToken();
  const {
    dispatch,
    state: { status, merchantId: previousMerchantId },
  } = useContext(SelectedMerchantStore);
  const merchantId = useRouteMatchMerchantId();

  useAsyncEffect(async () => {
    if (
      // Do not fetch if we're not authenticated
      authToken &&
      // Do not fetch if we don't have a merchant id
      merchantId &&
      // Do not fetch if the merchant id has not changed
      merchantId !== previousMerchantId &&
      // Do not fetch if we're already loading`
      status !== AsyncDataStatus.LOADING
    ) {
      dispatch(fetchSelectedMerchant(merchantId));
    }
  }, [merchantId, previousMerchantId, authToken, status]);

  return null;
};
