import React, { useState } from 'react';
import { Select, TextArea } from '@fattmerchantorg/truffle-components';
import { useEffect } from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { FormRow, PartialForm } from '../../shared/UnderwritingPartialForm';

import styled from 'styled-components';
import {
  ApprovedReasons,
  PendedReasons,
  RejectedReasons,
  UnderwritingStatus,
} from '../../../../../@types/UnderwritingStatus';
import { UpdateUnderwritingStatusFieldsValues } from './UpdateUnderwritingStatusForm.types';
import {
  approvedReasons,
  pendedReasons,
  rejectedReasons,
} from './UpdateUnderwritingStatusForm.util';
import { Alert, FieldError } from '../../../../shared';
import { getUnderwritingStatusReasonsTitle } from '../../../../../util/underwriting.util';

export type UnderwritingSubStatus =
  | PendedReasons
  | RejectedReasons
  | ApprovedReasons;

interface UpdateUnderwritingStatusFieldsProps {
  formProps?: FormRenderProps<UpdateUnderwritingStatusFieldsValues>;
  allowConditionalApproval?: boolean;
}

const ReasonSection = styled.div`
  > label {
    padding-bottom: 8px;
  }

  &[data-disabled='true'] {
    * {
      cursor: not-allowed;
    }

    label {
      color: ${({ theme }) => theme.colors.core.gray[400].hex};
    }
  }

  > div {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
  }
`;

const statusOptions = [
  { label: 'APPROVED', value: 'APPROVED' },
  { label: 'PENDED', value: 'PENDED' },
  { label: 'REJECTED', value: 'REJECTED' },
];

const autoAdjustTextAreaHeight = (textarea: HTMLTextAreaElement) => {
  textarea.style.height = '1px';
  textarea.style.height = Math.max(textarea.scrollHeight, 142) + 'px';
};

export const UpdateUnderwritingStatusFields: React.FC<
  UpdateUnderwritingStatusFieldsProps
> = props => {
  const { formProps, allowConditionalApproval } = props;

  const { underwriting_status, underwriting_substatuses } = formProps.values;
  const [reasons, setReasons] = useState([]);

  const areReasonsDisabled =
    underwriting_status === 'APPROVED' && !allowConditionalApproval;

  useEffect(() => {
    switch (underwriting_status) {
      case UnderwritingStatus.APPROVED:
        setReasons(approvedReasons);
        break;
      case UnderwritingStatus.PENDED:
        setReasons(pendedReasons);
        break;
      case UnderwritingStatus.REJECTED:
        setReasons(rejectedReasons);
        break;
    }
    return () => {
      setReasons([]);
    };
  }, [underwriting_status]);

  useEffect(() => {
    const textarea = document.getElementById('underwriting_note_textarea');
    autoAdjustTextAreaHeight(textarea as HTMLTextAreaElement);
  }, []);

  return (
    <PartialForm>
      <FormRow>
        <label>Status</label>
        <Field
          name="underwriting_status"
          format={value => {
            return statusOptions.find(option => option.value === value);
          }}
          parse={value => {
            return value?.value ?? '';
          }}
          clearable={false}
        >
          {props => (
            <Select
              {...props.input}
              data-testid="underwriting_status"
              options={statusOptions}
              styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                // @TODO - Need to fix this in Truffle's Stax Connect theme
                input: base => ({ ...base, color: 'white', padding: 0 }),
              }}
              menuPortalTarget={document.body}
              menuShouldScrollIntoView={false}
              isSearchable={true}
              menuPosition="fixed"
              placeholder="Select a Status"
              onChange={val => {
                formProps.form.change('underwriting_substatuses', []);
                props.input.onChange(val);
              }}
            />
          )}
        </Field>
      </FormRow>
      <FieldError name="underwriting_status" />
      {areReasonsDisabled ? (
        <FormRow>
          <Alert message="Conditional approval is not allowed for merchants within this brand." />
        </FormRow>
      ) : null}
      <ReasonSection
        id="underwriting-substatuses-reasons"
        data-testid="underwriting-substatuses-reasons"
        data-disabled={areReasonsDisabled}
      >
        <label>
          {getUnderwritingStatusReasonsTitle(
            underwriting_status,
            underwriting_substatuses
          )}
        </label>
        <div>
          {reasons.map(({ message, key }) => {
            return (
              <FormRow key={key}>
                <Field
                  name="underwriting_substatuses"
                  type="checkbox"
                  value={key}
                  format={arr => arr?.map(val => val?.message ?? val)}
                  parse={arr => arr?.map(val => val?.message ?? val)}
                >
                  {props => {
                    const id = `underwriting_substatuses_${key}`;

                    return (
                      <>
                        {/* @TODO: replace with truffle Checkbox once it exposes the "id" property */}
                        <input
                          {...props.input}
                          id={id}
                          data-testid={id}
                          type="checkbox"
                          checked={props.input.checked}
                          disabled={areReasonsDisabled}
                        />
                        <label htmlFor={id}>{message}</label>
                      </>
                    );
                  }}
                </Field>
              </FormRow>
            );
          })}
        </div>
      </ReasonSection>
      <FormRow>
        <label>Notes</label>
        <Field name="underwriting_note">
          {props => (
            <TextArea
              {...props.input}
              id="underwriting_note_textarea"
              onChange={e => {
                autoAdjustTextAreaHeight(e.target);
                props.input.onChange(e);
              }}
            />
          )}
        </Field>
      </FormRow>
    </PartialForm>
  );
};
