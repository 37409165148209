import React, { useMemo, useEffect } from 'react';
import { usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import {
  Pagination as TPagination,
  SortIndicator as TSortIndicator,
  Table as TTable,
  TableBody as TTableBody,
  TableHeaderCell as TTableHeaderCell,
  TableHead as TTableHead,
  TableRow as TTableRow,
  TableTools as TTableTools,
  TableWrapper as TTableWrapper,
} from '@fattmerchantorg/truffle-components';
import {
  LoadingSpan,
  TableCell,
  TableRow,
  TableProps,
  Cell,
  HeaderGroup,
  TableMenuCell,
} from '..';
import { useSearchState } from '../../../hooks';
import styled from 'styled-components';

const TableInnerWrapper = styled.div`
  overflow-x: scroll;
`;

export const defaultTableOptions = {
  options: {
    defaultColumn: {
      width: 0,
    },
    manualPagination: true,
    manualSortBy: true,
    manualFilters: true,
    manualGlobalFilter: true,
    pageCount: 0,
    initialState: {
      sortBy: [
        {
          id: 'created_at',
          desc: true,
        },
      ],
      pageSize: 10,
      pageIndex: 0,
    },
  },
  plugins: [useSortBy, usePagination, useRowSelect],
};

export function Table<T extends object>(props: TableProps<T>): JSX.Element {
  const [searchState, setSearchState] = useSearchState();

  const {
    data,
    pageSizeOptions = [
      { value: 10, label: 'Show 10' },
      { value: 20, label: 'Show 20' },
      { value: 50, label: 'Show 50' },
      { value: 100, label: 'Show 100' },
    ],
    count,
    total,
    loading,
    currentPage,
    lastPage,
    defaultPageSize,
    useTableOptions = {
      options: {
        defaultColumn: {
          width: 0,
        },
        manualPagination: true,
        manualSortBy: true,
        manualFilters: true,
        manualGlobalFilter: true,
        pageCount: count,
        initialState: {
          sortBy: [
            {
              id: 'created_at',
              desc: true,
            },
          ],
          pageSize: defaultPageSize,
          pageIndex: currentPage,
        },
      },
      plugins: [useSortBy, usePagination, useRowSelect],
    },
    components = {
      TableRow,
      TableHeaderCell: TTableHeaderCell,
      TableCell,
      ContextMenu: null,
    },
    paginated = true,
    onRowClick,
  } = props;

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageSize },
    setSortBy,
  } = useTable<T>(
    {
      data: useMemo(() => data.rows, [data.rows]),
      columns: useMemo(() => data.columns, [data.columns]),
      ...useTableOptions.options,
    },
    ...useTableOptions.plugins
  );

  useEffect(() => {
    let ps = props.defaultPageSize;
    if ('perPage' in searchState && typeof searchState.perPage === 'string') {
      const perPage = parseInt(searchState.perPage, 10);
      if (!isNaN(perPage)) ps = perPage;
    }

    if (ps !== pageSize) setPageSize(ps);
  }, [pageSize, props.defaultPageSize, searchState, setPageSize]);

  return (
    <TTableWrapper>
      <TableInnerWrapper>
        <TTable {...getTableProps()}>
          <TTableHead>
            {headerGroups.map((headerGroup, index) => (
              <TTableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((header: HeaderGroup<T>, index) => (
                  <components.TableHeaderCell
                    style={header.style}
                    key={index}
                    // This will sort the table based on the enabled sorted field
                    onClick={() => {
                      if (header.disableSortBy !== true) {
                        // Set sort desc, asc or undefined?
                        const desc =
                          header.isSortedDesc === false ? true : false;
                        setSortBy([{ id: header.id, desc }]);
                        if (desc !== undefined) {
                          // This will pass the order value based on the sort icon clicked
                          let order = desc === false ? 'ASC' : 'DESC';
                          setSearchState({ sort: `${header.id}`, order });
                        }
                      }
                    }}
                  >
                    {header.render('Header')}

                    {header.disableSortBy ? null : header.isSorted ? (
                      <TSortIndicator
                        sort={header.isSortedDesc ? 'desc' : 'asc'}
                      />
                    ) : (
                      <TSortIndicator sort="unsorted" />
                    )}
                  </components.TableHeaderCell>
                ))}
                <TTableHeaderCell style={{ width: 80 }}>
                  {/** the purpose of this is to create space for the table menu cell */}
                  &nbsp;
                </TTableHeaderCell>
              </TTableRow>
            ))}
          </TTableHead>

          <TTableBody {...getTableBodyProps()}>
            {props.children ? (
              <tr>
                <td>{props.children}</td>
              </tr>
            ) : (
              page.map((row, index) => {
                prepareRow(row);
                return (
                  <components.TableRow {...row.getRowProps()} key={index}>
                    {row.cells.map((cell: Cell<T>, index) => {
                      return (
                        <components.TableCell
                          {...cell.getCellProps()}
                          key={index}
                          style={cell.column.style}
                          data-visible={cell.column.visible ?? true}
                          onClick={() => {
                            // don't open drawer if clicking checkbox row or if still loading
                            if (cell.column.id !== 'selection' && !loading) {
                              onRowClick(row.original);
                            }
                          }}
                        >
                          {loading ? (
                            <LoadingSpan width="75%" />
                          ) : (
                            cell.render('Cell')
                          )}
                        </components.TableCell>
                      );
                    })}
                    <TableMenuCell
                      onClick={() => {
                        // TODO: remove this once table menu cell is implemented (so that clicking on the menu doesn't open a drawer)
                        if (!loading) {
                          onRowClick(row.original);
                        }
                      }}
                    ></TableMenuCell>
                  </components.TableRow>
                );
              })
            )}
          </TTableBody>
        </TTable>
      </TableInnerWrapper>
      {paginated ? (
        <TTableTools data-testid="pagination-card-footer">
          <TPagination
            onNextPage={() => {
              nextPage();
              setSearchState({ page: `${currentPage + 1}` });
            }}
            onPreviousPage={() => {
              previousPage();
              setSearchState({ page: `${currentPage - 1}` });
            }}
            onSkipToEnd={() => {
              gotoPage(count - 1);
              setSearchState({ page: `${count}` });
            }}
            onSkipToBeginning={() => {
              gotoPage(0);
              setSearchState({ page: '1' });
            }}
            onGoToPage={(page: number) => {
              gotoPage(page);
              setSearchState({ page: `${page + 1}` });
            }}
            totalPages={count}
            totalRecords={total}
            recordsPerPage={pageSize}
            onSetPageSize={size => {
              setPageSize(size);
              setSearchState({ perPage: `${size}`, page: '1' });
            }}
            currentPage={currentPage ? currentPage - 1 : 0}
            pageSizeOptions={pageSizeOptions}
            canNextPage={currentPage === lastPage ? false : true}
            canPreviousPage={currentPage === 1 ? false : true}
          />
        </TTableTools>
      ) : null}
    </TTableWrapper>
  );
}
