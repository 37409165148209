import React, { FunctionComponent, useContext, useState } from 'react';
import styled from 'styled-components';
import {
  BusinessInfoWidget,
  GatewayWidget,
  APIKeysWidget,
  PricingCardWidget,
  UnderwritingStatusWidget,
  OnboardingProcessorWidget,
} from './components';
import { SelectedMerchantStore } from '../../context';
import {
  useAsyncEffect,
  useAuthToken,
  useFeatureFlag,
  usePermissions,
  useToaster,
} from '../../hooks';
import { PageHeader } from '../shared';
import {
  getIfNotEmpty,
  formatPerItemRate,
  formatCapitalCase,
} from '../../util/format.util';
import {
  formatPricingRate,
  identicalCardRates,
  formatACHCap,
  formatSurchargeRate,
} from '../../util/pricing.util';
import { NotesWidget } from './components/NotesWidget';
import { DataWidget } from './components/DataWidget';
import {
  getGroupedGatewayPayoutConfigs,
  getTooltipForPayoutType,
  getTooltipForScheduleType,
} from './BusinessInfo.util';
import FeatureFlag from '../shared/FeatureFlag/FeatureFlag';
import { BillingProfileWidget } from './components/BillingProfileWidget';
import { ReservesWidget } from './components/ReservesWidget';
import { BankAccountWidget } from './components/BankAccountWidget';
import { FeaturesWidget } from './components/FeaturesWidget';
import { coreapi } from '../../api';
import {
  CollectionResponse,
  FundingAccount,
} from '@fattmerchantorg/types-omni';
import { Onboarding } from '@fattmerchantorg/types-engine/API';
import { TerminalsWidget } from './components/TerminalsWidget';
import { getCompanyOnboardings } from '../../util/catan.util';
import { MaximumsAndVolumeWidget } from './components/MaximumsAndVolumeWidget';

const Container = styled.div`
  align-content: center;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  max-width: 100%;
  overflow: hidden;
  padding: 1rem 0.5rem 2rem;

  > * {
    box-sizing: border-box;
  }

  > * + * {
    /* Add space between cards (exclude first card) */
    margin-top: 2rem;
  }

  @media (min-width: 768px) {
    padding: 1rem 1.5rem 0.5rem;

    > * {
      flex: 1 0 100%;
    }
  }

  @media (min-width: 992px) {
    align-content: flex-start;
    min-height: 80vh;
    flex-flow: row nowrap;
    overflow-x: scroll;
    padding: 0 2rem 2rem;

    > * {
      /* For ultrawide screens, prevent columns from growing indefinitely. */
      max-width: 22rem;
    }
    > * + * {
      /* Add vertical gutter between columns (exclude first column) */
      margin: 0 0 0 2rem;
    }
  }
`;

const Column = styled.div`
  min-width: ${({ minWidth = 'auto' }) => minWidth};
  flex-flow: column nowrap;

  > * + * {
    /* Add horizontal gap between cards within a column (exclude first card) */
    margin-top: 2rem;
  }
`;

const Spacer = styled.div`
  padding-right: 1px;
  margin-right: 1px;
  flex-shrink: 1;
`;

export const BusinessInfo: FunctionComponent = () => {
  const authToken = useAuthToken();
  const { toaster, toast } = useToaster();
  const [appsOnboardings, setAppsOnboardings] = useState<Onboarding[]>([]);
  const { state } = useContext(SelectedMerchantStore);
  const {
    gateways,
    apiKeys,
    merchant,
    merchantId,
    registration,
    status,
    billingProfiles,
  } = state;
  const { permit } = usePermissions();
  const { available: hasSplitFundingFeature } = useFeatureFlag(
    'Funding',
    'AddMultipleBankAccounts'
  );
  const [primaryFundingAccount, setPrimaryFundingAccount] =
    useState<FundingAccount | null>(null);

  const engineCompanyId = registration?.external_company_id;

  useAsyncEffect(async () => {
    // only fetch funding accounts if the auth user/merchant has the split funding feature
    if (hasSplitFundingFeature) {
      try {
        const response = await coreapi.get<CollectionResponse<FundingAccount>>(
          authToken,
          `/merchant/${merchantId}/funding-account?active=1&flags=PRIMARY`
        );

        setPrimaryFundingAccount(response.data[0]);
      } catch (error) {
        toaster(
          toast.error(
            error,
            'There was a problem fetching merchant funding accounts'
          )
        );
      }
    }
    if (engineCompanyId) {
      try {
        const response = await getCompanyOnboardings(
          authToken,
          engineCompanyId,
          {
            state: ['APPROVED'],
            processorName: ['APPS'],
          }
        );

        setAppsOnboardings(response.data);
      } catch (error) {
        toaster(
          toast.error(
            error,
            'There was a problem fetching merchant onboardings'
          )
        );
      }
    }
  }, [authToken, merchantId, hasSplitFundingFeature]);

  // breaks out the logic for the data that is passed into the Pricing Widgets to handle cases where data is not set
  let achPercent: string;
  let achPerTransaction: string;

  // card not present
  let cnpTransactionRate: string;
  let cnpPerItemRate: string;
  let cnpAmexTransactionRate: string;
  let cnpAmexPerItemRate: string;

  // card present
  let cpTransactionRate: string;
  let cpPerItemRate: string;
  let cpAmexTransactionRate: string;
  let cpAmexPerItemRate: string;

  let cnpRate: string;
  let cnpAmexRate: string;
  let cpRate: string;
  let cpAmexRate: string;
  let achRate: string;

  let cpCreditSurchargeRate: string;
  let cnpCreditSurchargeRate: string;
  let cpDebitTransFee: string;
  let cnpDebitTransFee: string;
  let cpDebitDiscountRate: string;
  let cnpDebitDiscountRate: string;
  let cpDebitRate: string;
  let cnpDebitRate: string;

  if (registration) {
    // card not present non-amex
    cnpTransactionRate = getIfNotEmpty(registration.plan_dcamnt);
    cnpPerItemRate = getIfNotEmpty(registration.plan_txamnt);
    // card not present amex
    cnpAmexTransactionRate = getIfNotEmpty(registration.amex_qual_disc_rate);
    cnpAmexPerItemRate = getIfNotEmpty(registration.amex_qual_trans_fee);

    // card present non-amex
    cpTransactionRate = getIfNotEmpty(registration.cp_transaction_rate);
    cpPerItemRate = getIfNotEmpty(registration.cp_per_item_rate);
    // card present amex
    cpAmexTransactionRate = getIfNotEmpty(registration.cp_amex_rate);
    cpAmexPerItemRate = getIfNotEmpty(registration.cp_amex_per_item_rate);

    achPercent = getIfNotEmpty(registration.plan_ach_dcamnt);
    achPerTransaction = getIfNotEmpty(registration.plan_ach_txamnt);

    // set the per transaction amounts to cents instead of dollars. Make sure it is a whole number
    achPerTransaction = formatPerItemRate(achPerTransaction);
    cnpPerItemRate = formatPerItemRate(cnpPerItemRate);
    cnpAmexPerItemRate = formatPerItemRate(cnpAmexPerItemRate);
    cpPerItemRate = formatPerItemRate(cpPerItemRate);
    cpAmexPerItemRate = formatPerItemRate(cpAmexPerItemRate);

    cnpRate = formatPricingRate(cnpPerItemRate, cnpTransactionRate);
    cnpAmexRate = formatPricingRate(cnpAmexPerItemRate, cnpAmexTransactionRate);
    cpRate = formatPricingRate(cpPerItemRate, cpTransactionRate);
    cpAmexRate = formatPricingRate(cpAmexPerItemRate, cpAmexTransactionRate);
    achRate = formatPricingRate(achPerTransaction, achPercent);

    // surcharge rates
    cpCreditSurchargeRate = formatSurchargeRate(
      getIfNotEmpty(registration.cp_credit_surcharge_rate)
    );
    cnpCreditSurchargeRate = formatSurchargeRate(
      getIfNotEmpty(registration.cnp_credit_surcharge_rate)
    );

    // debit rates
    cpDebitTransFee = getIfNotEmpty(registration.cp_debit_trans_fee);
    cnpDebitTransFee = getIfNotEmpty(registration.cnp_debit_trans_fee);
    cpDebitDiscountRate = getIfNotEmpty(registration.cp_debit_discount_rate);
    cnpDebitDiscountRate = getIfNotEmpty(registration.cnp_debit_discount_rate);
    cpDebitRate = formatPricingRate(cpDebitTransFee, cpDebitDiscountRate);
    cnpDebitRate = formatPricingRate(cnpDebitTransFee, cnpDebitDiscountRate);
  }

  const canSeeRiskHold = permit('godview', 'riskHold', 'read');
  const canWriteRiskHold = permit('godview', 'riskHold', 'write');
  const canSeeTerminals = permit('godview', 'engineTerminals', 'read');
  const canReadNotes = permit('godview', 'notes', 'read');
  const canEditNotes = permit('godview', 'notes', 'write');
  const canSeeReserveWidget = permit('godview', 'engineReserves', 'write');
  //const canViewReserves = permit('godview', 'manageStaxReserves', 'write');

  let achCap: string = formatACHCap(registration?.plan_ach_cap);

  const [gatewayPayoutConfigs, setGatewayPayoutConfigs] = useState([]);

  useAsyncEffect(async () => {
    if (authToken && merchant?.gateways) {
      setGatewayPayoutConfigs(
        await getGroupedGatewayPayoutConfigs(gateways.data, authToken)
      );
    }
  }, [authToken, gateways.data]);

  if (status !== 'IDLE') {
    return <></>;
  }

  if (
    (!billingProfiles?.data || billingProfiles?.data.length === 0) &&
    billingProfiles?.status === 'IDLE'
  ) {
    return (
      <>
        <PageHeader title="Business Info " />
        <Container>
          <Column>
            <BusinessInfoWidget />
            {canReadNotes && (
              <NotesWidget
                status={status}
                notes={merchant?.notes}
                canEditNotes={canEditNotes}
              />
            )}
            <APIKeysWidget
              apiKeys={apiKeys}
              websitePaymentsToken={merchant?.hosted_payments_token}
            />
            <OnboardingProcessorWidget />
          </Column>

          <Column>
            {registration && (
              <UnderwritingStatusWidget
                accountStatus={merchant?.status}
                underwritingStatus={registration.underwriting_status}
                // TODO: remove "as any" cast once/if we remove "| string | null" from type
                subStatuses={registration.underwriting_substatuses as any}
                note={registration.underwriting_note}
                electronicSignatureTimestamp={
                  registration.electronic_signature?.timestamp || null
                }
                canSeeRiskHold={canSeeRiskHold}
                canWriteRiskHold={canWriteRiskHold}
                merchantId={merchant?.id}
                riskHold={merchant?.risk_hold}
              />
            )}

            {permit('godview', 'gateway', 'read') && (
              <GatewayWidget
                gateways={gateways}
                plan={(merchant?.plan?.name || null) as any}
              />
            )}

            <FeatureFlag category="Funding" feature="AddMultipleBankAccounts">
              <BankAccountWidget
                title="Bank Accounts"
                titleIcon="fas fa-chevron-right"
                primaryFundingAccount={primaryFundingAccount}
              />
            </FeatureFlag>
            <FeaturesWidget />
          </Column>

          {registration && (
            <Column minWidth="388px">
              <PricingCardWidget
                identicalCardRates={identicalCardRates(registration)}
                cnpRate={cnpRate}
                cnpAmexRate={cnpAmexRate}
                cpRate={cpRate}
                cpAmexRate={cpAmexRate}
                ach={achRate}
                cap={achCap}
                cpCreditSurchargeRate={cpCreditSurchargeRate}
                cnpCreditSurchargeRate={cnpCreditSurchargeRate}
                cpDebitRate={cpDebitRate}
                cnpDebitRate={cnpDebitRate}
                isFlatRate={registration.is_flat_rate}
                title="Credit Card and ACH Pricing"
              />

              <BillingProfileWidget billingProfiles={billingProfiles} />
              <MaximumsAndVolumeWidget />
              {canSeeReserveWidget && engineCompanyId && (
                <ReservesWidget data-testid="reserves_widget" />
              )}
            </Column>
          )}

          {merchant?.is_payfac && (
            <FeatureFlag category="OmniConnect" feature="PayoutConfiguration">
              <Column>
                {gatewayPayoutConfigs.map((gpc, i) => {
                  return (
                    <DataWidget
                      key={i}
                      title="Payout Configuration"
                      subtitle={
                        // only show the processor mid if there's at least one other payout config
                        gatewayPayoutConfigs.length > 1
                          ? gpc.processor_mids.join(', ')
                          : null
                      }
                      titleIcon="fa fa-calendar-check"
                      data={[
                        {
                          title: 'Next Day Funding',
                          tooltip:
                            'When enabled, payouts will be received by the merchant on the day following a transaction.  The cutoff time is approximately 8pm ET.',
                          value:
                            typeof gpc.is_ndf_enabled === 'boolean'
                              ? gpc.is_ndf_enabled
                                ? 'Enabled'
                                : 'Disabled'
                              : '-',
                        },
                        {
                          title: 'Fees',
                          tooltip: getTooltipForPayoutType(gpc.payout_type),
                          value: formatCapitalCase(gpc.payout_type || '-'),
                        },
                        {
                          title: 'Discounting',
                          tooltip: getTooltipForScheduleType(
                            gpc.fee_schedule_type
                          ),
                          value: formatCapitalCase(
                            gpc.fee_schedule_type || '-'
                          ),
                        },
                      ]}
                    />
                  );
                })}
              </Column>
            </FeatureFlag>
          )}
          <Spacer />
        </Container>
      </>
    );
  } else {
    return (
      <>
        <PageHeader title="Business Info " />
        <Container>
          <Column>
            <BusinessInfoWidget />
            {canReadNotes && (
              <NotesWidget
                status={status}
                notes={merchant?.notes}
                canEditNotes={canEditNotes}
              />
            )}
            <APIKeysWidget
              apiKeys={apiKeys}
              websitePaymentsToken={merchant?.hosted_payments_token}
            />
            <OnboardingProcessorWidget />
          </Column>
          <Column>
            {registration && (
              <UnderwritingStatusWidget
                accountStatus={merchant?.status}
                underwritingStatus={registration.underwriting_status}
                // TODO: remove "as any" cast once/if we remove "| string | null" from type
                subStatuses={registration.underwriting_substatuses as any}
                note={registration.underwriting_note}
                electronicSignatureTimestamp={
                  registration.electronic_signature?.timestamp || null
                }
                canSeeRiskHold={canSeeRiskHold}
                canWriteRiskHold={canWriteRiskHold}
                merchantId={merchant?.id}
                riskHold={merchant?.risk_hold}
              />
            )}
            {permit('godview', 'gateway', 'read') && (
              <GatewayWidget
                gateways={gateways}
                plan={(merchant?.plan?.name || null) as any}
              />
            )}
            {canSeeTerminals && appsOnboardings?.length ? (
              <TerminalsWidget data-testid="terminals_widget" />
            ) : (
              <></>
            )}
            <FeatureFlag category="Funding" feature="AddMultipleBankAccounts">
              <BankAccountWidget
                title="Bank Accounts"
                titleIcon="fas fa-chevron-right"
                primaryFundingAccount={primaryFundingAccount}
              />
            </FeatureFlag>
            <FeaturesWidget />
          </Column>

          {registration && (
            <Column minWidth="388px">
              <BillingProfileWidget billingProfiles={billingProfiles} />
              <MaximumsAndVolumeWidget />

              {canSeeReserveWidget && engineCompanyId && (
                <ReservesWidget data-testid="reserves_widget" />
              )}
            </Column>
          )}

          {merchant?.is_payfac && (
            <FeatureFlag category="OmniConnect" feature="PayoutConfiguration">
              <Column>
                {gatewayPayoutConfigs.map((gpc, i) => {
                  return (
                    <DataWidget
                      key={i}
                      title="Payout Configuration"
                      subtitle={
                        // only show the processor mid if there's at least one other payout config
                        gatewayPayoutConfigs.length > 1
                          ? gpc.processor_mids.join(', ')
                          : null
                      }
                      titleIcon="fa fa-calendar-check"
                      data={[
                        {
                          title: 'Next Day Funding',
                          tooltip:
                            'When enabled, payouts will be received by the merchant on the day following a transaction.  The cutoff time is approximately 8pm ET.',
                          value:
                            typeof gpc.is_ndf_enabled === 'boolean'
                              ? gpc.is_ndf_enabled
                                ? 'Enabled'
                                : 'Disabled'
                              : '-',
                        },
                        {
                          title: 'Fees',
                          tooltip: getTooltipForPayoutType(gpc.payout_type),
                          value: formatCapitalCase(gpc.payout_type || '-'),
                        },
                        {
                          title: 'Discounting',
                          tooltip: getTooltipForScheduleType(
                            gpc.fee_schedule_type
                          ),
                          value: formatCapitalCase(
                            gpc.fee_schedule_type || '-'
                          ),
                        },
                      ]}
                    />
                  );
                })}
              </Column>
            </FeatureFlag>
          )}
          <Spacer />
        </Container>
      </>
    );
  }
};
