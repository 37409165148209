import React, { FunctionComponent, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { ImportStore } from '../../../../context';
import {
  useRouteMatchImportId,
  useRouteMatchMerchantId,
} from '../../../../hooks';
import {
  Button,
  ButtonProps,
  Group,
  Modal,
  ResponsivePadding,
} from '../../../shared';

const Container = withTheme(
  styled.div`
    background-color: ${({ theme }) => theme.black};
    border-top: 1px solid ${({ theme }) => theme.colors.core.gray[200].hex};
    padding: 1rem;
    bottom: 0;
    position: fixed;
    z-index: 999;

    left: 0;
    right: 0;

    /* left: 250px;
  width: calc(100% - 250px); */

    /* @media (max-width: 768px) {
    left: 0;
    width: 100%;
  } */
  `
);

type ImporterButtonBarProps = {
  cancelButtonProps?: ButtonProps & { hidden?: boolean };
  onCancel?: ButtonProps['onClick'];

  backButtonProps?: ButtonProps & { hidden?: boolean };
  onBack?: ButtonProps['onClick'];

  continueButtonProps?: ButtonProps & { hidden?: boolean };
  onContinue?: ButtonProps['onClick'];
};

export const ImporterButtonBar: FunctionComponent<ImporterButtonBarProps> =
  props => {
    const {
      cancelButtonProps = {},
      onCancel: propsOnCancel,
      backButtonProps = {},
      onBack,
      continueButtonProps = {},
      onContinue,
    } = props;
    const history = useHistory();
    const merchantId = useRouteMatchMerchantId();
    const importId = useRouteMatchImportId();
    const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
      useState(false);
    const {
      state: { record, hasMappingChanged },
    } = useContext(ImportStore);

    const onCancel = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
      if (propsOnCancel) {
        propsOnCancel(event);
      } else {
        history.push(`/merchant/${merchantId}/imports`);
      }
    };

    return (
      <Container>
        <Modal
          title="Unsaved Changes"
          isOpen={isUnsavedChangesModalOpen}
          onClose={() => setIsUnsavedChangesModalOpen(false)}
        >
          <ResponsivePadding desktop="1rem" mobile="1rem">
            <p>You have unsaved changes that will be lost if you leave now.</p>
            <Group justify="space-between">
              <Button
                motif="tertiary"
                variant="outline"
                size="small"
                onClick={onCancel}
              >
                Discard Changes
              </Button>
              <Button
                motif="tertiary"
                size="small"
                onClick={() => {
                  setIsUnsavedChangesModalOpen(false);
                  history.push(
                    `/merchant/${merchantId}/imports/${importId}/match`
                  );
                }}
              >
                View Changes
              </Button>
            </Group>
          </ResponsivePadding>
        </Modal>
        <Group justify="space-between">
          {!cancelButtonProps.hidden && (
            <Button
              motif="accent"
              variant="outline"
              size="small"
              onClick={event => {
                if (hasMappingChanged) {
                  setIsUnsavedChangesModalOpen(true);
                } else {
                  onCancel(event);
                }
              }}
              children="Cancel"
              {...cancelButtonProps}
            />
          )}
          <Group space="1rem">
            {!backButtonProps.hidden && (
              <Button
                motif="accent"
                variant="outline"
                size="small"
                onClick={onBack}
                children="Back"
                {...backButtonProps}
              />
            )}
            {!continueButtonProps.hidden && (
              <Button
                motif="accent"
                size="small"
                onClick={onContinue}
                disabled={!record}
                children="Continue"
                {...continueButtonProps}
              />
            )}
          </Group>
        </Group>
      </Container>
    );
  };
