import { useMemo } from 'react';
import { DrawerProps } from '@fattmerchantorg/truffle-components/dist/Drawer/Drawer';
import useDetailsModal from './useDetailsModal';
import SupportTicketReplyForm from './SupportTicketReplyForm';
import StaxFlag from './StaxFlag';
import styled from 'styled-components';
import { formatReadableDate } from '../../../util/format.util';
import { makeAvatarColors } from '../../../util/color.util';
import { AsyncDataStatus, SupportTicket } from '../../../@types';
import { appserviceapi } from '../../../api';
import EngagementContent from './EngagementContent';

import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import {
  CopyButton,
  LoadingSpan,
  Separator as USeparator,
  Avatar,
} from '../../shared';

import {
  Drawer,
  Text,
  StatusPill,
  Icon,
} from '@fattmerchantorg/truffle-components';

import { TextLink } from '@fattmerchantorg/truffle-components';

import {
  formatTicketOwner,
  mapTicketStatusToStatusPillStatus,
} from '../SupportTickets.util';

import {
  usePrevious,
  useSearchState,
  useAsyncEffect,
  useSupportAuthToken,
} from '../../../hooks';

const Section = styled.div`
  padding: 16px 16px 0px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:first-child) {
    margin-top: 16px;
  }

  small {
    font-size: 12px;
  }

  > *:not(:first-child) {
    margin-left: 8px;
  }
`;

const SubNames = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const LineItemRow = styled(Row)`
  > *:last-child {
    margin-left: auto;
  }
`;

const LineItemRowDate = styled(Row)`
  > *:last-child {
    margin-left: 0px;
  }
`;

const DateRowContainer = styled(LineItemRow)`
  align-items: baseline;

  > small {
    margin-left: 2px !important;
  }
`;

const DateRowContainerLeft = styled(LineItemRowDate)`
  align-items: baseline;

  > small {
    margin-left: 2px !important;
  }
`;

const BrandSpan = styled.div`
  margin: 5px 0;
`;

const DateRow: FunctionComponent<{
  ticket?: SupportTicket;
  loading?: boolean;
  alignRight?: boolean;
}> = props =>
  props.loading ? (
    <Row>
      <LoadingSpan height="24px" width="160px" style={{ marginLeft: 'auto' }} />
    </Row>
  ) : props.alignRight ? (
    <DateRowContainer>
      <span>{formatReadableDate(String(props.ticket?.created_at))}</span>
      {props.children}
    </DateRowContainer>
  ) : (
    <DateRowContainerLeft>
      <span>{formatReadableDate(String(props.ticket?.created_at))}</span>
      {props.children}
    </DateRowContainerLeft>
  );

const Separator = styled(USeparator)`
  padding-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
`;

const CommentSeparator = styled(USeparator)`
  padding-top: 16px;
  margin-left: 2px;
  margin-right: 2px;
`;

type handleClickOutside = {
  seeMore: boolean;
  setSeeMore: Dispatch<SetStateAction<boolean>>;
};

const AttachmentContainer = styled.div`
  margin-top: 8px;
`;

/** Props accepted by the DetailsModal component */
type SupportTicketDrawerProps = Partial<DrawerProps> &
  Pick<DrawerProps, 'open' | 'onClose'> & {
    getData: ((id: string) => Promise<SupportTicket>) | null;
  } & handleClickOutside;

interface Engagement {
  id?: string;
  email?: string;
  created_at?: Date;
  first_name?: string;
  last_name?: string;
  content?: string;
  attachment_ids: number[];
  is_attachments_loaded: boolean;
  attachments: {
    id: string;
    name: string;
    extension: string;
  }[];
}

export const SupportTicketDrawer: FunctionComponent<
  SupportTicketDrawerProps
> = props => {
  const supportToken = useSupportAuthToken();
  const [detailId] = useSearchState('detailId');
  const [engagements, setEngagements] = useState(null);

  const avatarColors = useMemo(
    () => makeAvatarColors(engagements?.data),
    [engagements]
  );

  const { getData, onClose, ...drawerProps } = props;

  const refreshEngagements = async (supportToken: string, detailId: string) => {
    if (supportToken && detailId) {
      let engagements = await appserviceapi.get(
        supportToken,
        `/support/ticket/${detailId}/engagements`
      );

      // We dynamically load attachments when the user clicks "View Attachments"
      // We need to add properties to each engagement to know if the files are loaded
      // and wether to show them or not
      engagements.data = engagements.data.map(row => {
        row.is_attachments_loaded = false;
        row.attachments = [];
        return row;
      });

      setEngagements(engagements);
    }
  };

  useAsyncEffect(async () => {
    setEngagements(null);
    await refreshEngagements(supportToken, detailId as string);
  }, [detailId, supportToken]);

  const [state] = useDetailsModal(getData);
  const loading =
    state.status === AsyncDataStatus.INITIAL ||
    state.status === AsyncDataStatus.LOADING;

  const prevStatus = usePrevious(state.status);
  const ticket = state.data;

  const fetchAttachments = async engagement => {
    const engagementsState = { ...engagements };
    const engagementIndex = engagements.data.findIndex(
      row => row.id === engagement.id
    );
    const { data } = await appserviceapi.get(
      supportToken,
      `/support/ticket/${detailId}/files`,
      {
        fileIds: engagement.attachment_ids,
      }
    );
    engagement.is_attachments_loaded = true;
    engagement.attachments = data;
    engagementsState[engagementIndex] = engagement;
    setEngagements(engagementsState);
  };

  const viewHubspotFile = async fileId => {
    const { data } = await appserviceapi.get(
      supportToken,
      `/support/ticket/${detailId}/file/${fileId}/signed-url`
    );
    if (data?.url) {
      window.open(data.url);
    }
  };

  useEffect(() => {
    if (
      state.status === AsyncDataStatus.ERROR &&
      prevStatus !== AsyncDataStatus.ERROR
    ) {
      onClose();
    }
  }, [state.status, prevStatus, onClose]);

  return (
    <Drawer
      {...drawerProps}
      onClose={onClose}
      anchor="right"
      title={`Support Ticket ${ticket?.id ?? ''}`}
      data-testid="support-ticket-modal"
      customStyles={{
        borderRadius: 0,
        padding: 0,
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Section>
        {loading ? (
          <Row>
            <LoadingSpan height="36px" width="400px" />
          </Row>
        ) : (
          <LineItemRow>
            <Text as="h5">{ticket?.name}</Text>

            <CopyButton
              content={ticket?.id}
              tooltip="Copy Ticket ID to clipboard"
            >
              <Icon icon={['fas', 'copy']} size="1x" />
            </CopyButton>
          </LineItemRow>
        )}
      </Section>
      <Section>
        <DateRow ticket={ticket} loading={loading} />
        {ticket?.merchant?.brand_name && (
          <BrandSpan>{ticket?.merchant?.brand_name}</BrandSpan>
        )}
        {loading ? null : ticket ? (
          <StatusPill
            status={mapTicketStatusToStatusPillStatus(ticket)}
            label={
              ticket?.status === 'NEW'
                ? 'OPEN'
                : ticket?.status?.replace(/_/g, ' ')
            }
            disabled={ticket?.status === 'CLOSED'}
          />
        ) : null}
      </Section>
      <Separator />
      <Section>
        <Text as="h5">Merchant</Text>
        <LineItemRow>
          <SubNames>{ticket?.merchant?.company_name ?? 'N/A'}</SubNames>
          <CopyButton
            content={ticket?.merchant?.id}
            tooltip={ticket?.merchant ? 'Copy PMID' : 'Invalid Merchant ID'}
            disabled={!ticket?.merchant}
          >
            <Icon icon={['fas', 'copy']} size="1x" />
            <span style={{ textDecoration: 'underline' }}> Copy PMID</span>
          </CopyButton>
        </LineItemRow>
      </Section>
      <Separator />
      <Section>
        <Text as="h5">Description</Text>
        <Row>
          {loading ? (
            <LoadingSpan height="24px" width="400px" />
          ) : (
            <p>{ticket?.content}</p>
          )}
        </Row>
      </Section>
      <Separator />
      <Section>
        <Text as="h5">Stax Agent</Text>
        <Row>
          {loading ? (
            <LoadingSpan height="24px" width="130px" />
          ) : (
            <SubNames>{formatTicketOwner(ticket)}</SubNames>
          )}
        </Row>
      </Section>

      <Separator />

      <Section>
        <Text as="h5">Comments</Text>

        <SupportTicketReplyForm
          ticketId={detailId as string}
          refreshFn={refreshEngagements}
        />

        {engagements ? (
          <>
            {engagements?.data
              .sort((a, b) =>
                a.created_at < b.created_at
                  ? 1
                  : b.created_at < a.created_at
                  ? -1
                  : 0
              )
              .map((r: Engagement, index: number) => {
                const {
                  id,
                  first_name,
                  last_name,
                  email,
                  content,
                  created_at,
                  attachment_ids,
                  is_attachments_loaded,
                  attachments,
                } = r;

                return (
                  <Row key={id}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}
                    >
                      {first_name && last_name && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'baseline',
                            width: '100%',
                          }}
                        >
                          <Avatar
                            name={`${first_name} ${last_name}`}
                            color={avatarColors.get(email)}
                          />

                          <span style={{ width: '50%' }}>
                            {first_name}&nbsp;{last_name}&nbsp;
                            {email.match(
                              /^[A-Za-z0-9._%+-]+@(fattmerchant|staxpayments).com$/
                            ) ? (
                              <StaxFlag />
                            ) : (
                              ''
                            )}
                          </span>

                          <span
                            style={{
                              textAlign: 'right',
                              float: 'right',
                              width: '50%',
                            }}
                          >
                            <DateRowContainer>
                              <span>
                                &nbsp;
                                {formatReadableDate(created_at.toString())}
                              </span>
                            </DateRowContainer>
                          </span>
                        </div>
                      )}

                      <EngagementContent
                        engagementId={id}
                        contentText={content}
                      />
                      {attachment_ids &&
                        attachment_ids.length > 0 &&
                        !is_attachments_loaded && (
                          <AttachmentContainer>
                            <TextLink onClick={() => fetchAttachments(r)}>
                              {r.attachment_ids.length === 1
                                ? `View Attachment`
                                : `View ${r.attachment_ids.length} Attachments`}
                            </TextLink>
                          </AttachmentContainer>
                        )}
                      {is_attachments_loaded &&
                        attachments.length > 0 &&
                        attachments.map(file => {
                          return (
                            <AttachmentContainer>
                              <TextLink
                                onClick={() => viewHubspotFile(file.id)}
                                icon={['fas', 'link']}
                              >
                                {file.name}.{file.extension}
                              </TextLink>
                            </AttachmentContainer>
                          );
                        })}
                      {engagements.data &&
                        engagements.data.length > index + 1 && (
                          <CommentSeparator />
                        )}
                    </div>
                  </Row>
                );
              })}
          </>
        ) : (
          <Row>
            <LoadingSpan height="24px" width="400px" />
          </Row>
        )}
      </Section>

      <Separator />
    </Drawer>
  );
};
