import { DB } from '@fattmerchantorg/types-engine';
import { Auth, CollectionPagination } from '@fattmerchantorg/types-omni';
import { catanapi } from '../api/catan';
import { StatusPillStatus } from '@fattmerchantorg/truffle-components/dist/StatusPill/StatusPill';

/**
 * @param status OnboardingState
 *
 * Util function used to map the Catan onboarding states to their display counterparts
 * - FAILED: Submission failed when attempting to submit
 * - REJECTED: Submission has been rejected by the processor
 * - PENDED: Application has been pended by the processor
 * - RECEIVED: Application is in Catan but has not been submitted to a processor
 * - APPROVED: Application has been approved by the processor
 * - SUBMITTED: Application has been submitted to the processor, and were waiting to hear back
 * - CLOSED: Application has been closed by the processor
 * */
export function getStateLabel(status: DB.OnboardingState) {
  switch (status) {
    case 'RECEIVED.FAILED':
    case 'SUBMITTED.FAILED':
    case 'PENDED.FAILED':
      return 'Failed';
    case 'REJECTED':
      return 'Rejected';
    case 'PENDED':
      return 'Pended';
    case 'RECEIVED':
      return 'Received';
    case 'APPROVED':
      return 'Approved';
    case 'SUBMITTED':
      return 'Pending';
    case 'APPROVED.INACTIVE':
      return 'Inactive';
    case 'CLOSED':
      return 'Closed';
  }
}

export function mapStateToStatus(state: DB.OnboardingState): StatusPillStatus {
  switch (state) {
    case 'RECEIVED.FAILED':
    case 'SUBMITTED.FAILED':
    case 'PENDED.FAILED':
    case 'REJECTED':
    case 'CLOSED':
      return 'error';
    case 'PENDED':
    case 'RECEIVED':
    case 'SUBMITTED':
      return 'warning';
    default:
      return 'success';
  }
}

export type OnboardingAuditLogs = DB.Onboarding;

export const getAllOnboardings = async (
  jwt: Auth['token'],
  merchantId: string
): Promise<
  CollectionPagination & { traceId: string; data: DB.Onboarding[] }
> => {
  try {
    return await catanapi.get(jwt, `/onboarding/?merchantId[]=${merchantId}`);
  } catch (e) {
    throw e;
  }
};

export const getOnboardingLogs = async (
  jwt: Auth['token'],
  onboardingId: string,
  processorName: DB.Onboarding['processor_name']
): Promise<{ traceId: string; logs: DB.OnboardingLog[] }> => {
  try {
    return await catanapi.get(
      jwt,
      `/onboarding/${onboardingId}/logs?processorName=${processorName}`
    );
  } catch (error) {
    throw error;
  }
};
