import React, { FunctionComponent } from 'react';

// Note: each element of this bank names array must have a
// corresponding svg file with a hyphenated file name.
const banks = [
  'chase',
  'bank of america',
  'wells fargo',
  'u.s. bank',
  'citibank',
  'pnc',
  'truist',
  'td bank',
  'capital one',
  'goldman sachs',
];

export const BankAccountImage: FunctionComponent<{
  bankName: string;
}> = props => {
  const bankName = props.bankName?.toLowerCase();
  if (banks.includes(bankName)) {
    return (
      <img
        alt="..."
        src={require(`../../../svg/${bankName.replace(/\s/g, '-')}.svg`)}
      />
    );
  } else {
    return <img alt="..." src={require('../../../svg/generic.svg')} />;
  }
};
