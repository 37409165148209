import React, { FunctionComponent } from 'react';
import { DevTools } from '../devtools/DevTools';
import FeatureFlag from '../shared/FeatureFlag/FeatureFlag';
import { ContentSeparator } from './SettingsPagesStylings';

export const DevToolsSettingsPage: FunctionComponent = () => {
  return (
    <ContentSeparator>
      <div>
        <>
          <FeatureFlag category="Developer" feature="TestFeaturePermissions">
            <DevTools />
          </FeatureFlag>
        </>
      </div>
    </ContentSeparator>
  );
};
