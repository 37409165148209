import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
  Method as HTTPMethod,
} from 'axios';
import { parse as qsParse, ParsedQuery } from 'query-string';
import { history } from '../history';

export function getHeaders(
  jwtToken: string,
  contentType: string = 'application/json'
) {
  return {
    Authorization: `Bearer ${jwtToken}`,
    'Content-Type': contentType,
    Accept: 'application/json',
  };
}

export function stringify(args: object): string {
  const params: string[] = [];

  Object.keys(args).forEach(key => {
    const value = args[key];

    if (typeof value === 'undefined' || value === null) return;

    if (value && typeof value === 'object') {
      Object.keys(value).forEach(subkey => {
        params.push(`${key}[]=${encodeURIComponent(args[key][subkey])}`);
      });
    } else {
      params.push(`${key}=${encodeURIComponent(args[key])}`);
    }
  });

  return params.join('&');
}

export function parse(urlOrSearch: string): ParsedQuery<string> {
  const search = urlOrSearch.split('?')[1];
  return qsParse(search, { arrayFormat: 'bracket' });
}

export function generateCancelSource(): CancelTokenSource {
  const CancelToken = axios.CancelToken;
  return CancelToken.source();
}

export function originUrl(append: string = ''): string {
  let origin =
    process.env.NODE_ENV === 'test'
      ? process.env.E2E_BASE_URL
      : window.location.origin;

  // fix for IE
  if (!origin && process.env.NODE_ENV !== 'test') {
    const portOrEmpty = window.location.port ? `:${window.location.port}` : '';
    origin = `${window.location.protocol}//${window.location.hostname}${portOrEmpty}`;
  }

  return `${origin}/#/${append}`;
}

export function buildRequest(baseUrl: string, method: HTTPMethod) {
  return function <T = any>(
    token: string,
    endpoint: string,
    bodyOrQuery?: object,
    transformResponse?: (res: AxiosResponse<any>) => T,
    transformError?: (res: AxiosError<any>) => any,
    cancelSource?: CancelTokenSource,
    options: { override401Redirect?: boolean } = { override401Redirect: false }
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      let url = baseUrl;
      url += endpoint.slice(0, 1) === '/' ? '' : '/';
      url += endpoint;

      const config: AxiosRequestConfig = {
        url: url,
        method: method,
        headers: getHeaders(token),
        cancelToken: cancelSource?.token,
      };

      if (bodyOrQuery && method !== 'get') {
        const isFile = bodyOrQuery instanceof File;

        if (isFile) {
          const file = bodyOrQuery as File;
          const formData = new FormData();
          formData.append('name', file.name);
          formData.append('file', file);
          config.data = formData;
        } else if (bodyOrQuery['file'] instanceof File) {
          const { file, ...body } = bodyOrQuery as {
            file: File;
            [key: string]: any;
          };
          const formData = new FormData();
          formData.append('name', file.name);
          formData.append('file', file);
          Object.keys(body).forEach(key => {
            formData.append(key, body[key]);
          });
          config.data = formData;
        } else {
          config.data = bodyOrQuery;
        }
      } else if (bodyOrQuery && Object.keys(bodyOrQuery).length) {
        if (!config.url.includes('?')) {
          config.url += '?';
        }

        config.url += stringify(bodyOrQuery);
      }

      axios
        .request(config)
        .then(res => {
          resolve(transformResponse ? transformResponse(res) : res.data);
        })
        .catch(error => {
          if (error?.response?.status === 401 && !options.override401Redirect) {
            history.push('/logout');
            return reject(error);
          } else {
            reject(
              transformError
                ? transformError(error)
                : error?.response?.data ?? error
            );
          }
        });
    });
  };
}

export function buildApi(baseUrl: string) {
  const url = baseUrl?.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl;
  return {
    baseUrl: url,
    get: buildRequest(url, 'get'),
    put: buildRequest(url, 'put'),
    post: buildRequest(url, 'post'),
    delete: buildRequest(url, 'delete'),
    patch: buildRequest(url, 'patch'),
  };
}
