import { Icon as UIcon } from '@fattmerchantorg/truffle-components';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import React, {
  ButtonHTMLAttributes,
  FunctionComponent,
  MouseEvent,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import { Spinner } from '../Spinner';
import { useIsMounted } from '../../../hooks/useIsMounted';
import get from 'lodash/get';

const Icon = styled(UIcon)`
  cursor: inherit;
  font-size: 24px;
`;

const Label = styled.label`
  cursor: inherit;
  padding-top: 8px;
`;

const Button = styled.button`
  border: none;
  appearance: none;
  background: transparent;
  font-size: 14px;
  height: 100%;
  padding: 8px 16px;
  cursor: pointer;
  align-items: center;
  color: ${props => get(props.theme, props.color, 'white')};

  display: flex;
  flex-direction: column;

  &:disabled {
    cursor: not-allowed;

    * {
      opacity: 0.8;
    }
  }
`;

export type BulkActionMenuButtonProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'onClick'
> & {
  color?: string;
  icon: FontAwesomeIconProps['icon'];
  label?: string;
  isLoading?: boolean;
  onClick:
    | MouseEventHandler<HTMLButtonElement>
    | ((event: MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<any>);
};

export const BulkActionMenuButton: FunctionComponent<
  BulkActionMenuButtonProps
> = props => {
  const { icon, label, children, onClick, ...buttonProps } = props;
  const [isLoading, setIsLoading] = useState(props.isLoading);
  const isMounted = useIsMounted();

  useEffect(() => {
    setIsLoading(props.isLoading);
  }, [props.isLoading]);

  return (
    <Button
      {...buttonProps}
      disabled={buttonProps.disabled || isLoading}
      onClick={e => {
        const r = onClick(e);

        if (r instanceof Promise) {
          setIsLoading(true);
          r.then(() => {
            if (isMounted()) setIsLoading(false);
          });
        }
      }}
    >
      {isLoading ? <Spinner /> : <Icon icon={icon} />}
      {label ? <Label>{label}</Label> : null}
      {children}
    </Button>
  );
};
