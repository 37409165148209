import React from 'react';
import { FeesSectionData } from '../AdvancedDetailDrawer.types';
import {
  Accordion,
  TableBody,
  TableRow,
  Tooltip,
} from '@fattmerchantorg/truffle-components';
import { formatCurrency } from '../../../../util';
import { formatDate } from '../../../../util/date.util';
import styled from 'styled-components';
import { AccordionWrapper } from '../sharedComponents';
import { usePermissions } from '../../../../hooks';

type FeesSectionProps = {
  id: string;
  name: string;
  data: FeesSectionData;
};

const StyledTable = styled.table`
  width: 100%;
`;

const TableRowStyled = styled(TableRow)`
  cursor: ${({ hover }) => (hover ? 'pointer' : 'default')};
  ${({ hover }) =>
    !hover &&
    `
      &:hover {
        background: none !important;
      }
    `}
`;

const TableSectionLeft = styled.td`
  width: 40%;
  max-width: 175px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 4px 0 4px 8px;
`;

const TableSectionRight = styled.td`
  padding-right: 8px;
  text-align: right;
`;

const TableItem = styled.span`
  border: none;
  color: #f8f9fe;
  padding: 8px;
  font-size: 14px;
  font-weight: 400px;
  line-height: 20px;
`;

const FeesSection: React.FunctionComponent<FeesSectionProps> = props => {
  const { data, name, id } = props;
  const { permit } = usePermissions();
  const hasSettlementPermissions = permit(
    'godview',
    'manageStaxSettlements',
    'read'
  );
  const tooltipDisabled = content => {
    return content < 28;
  };

  if (!data) return null;
  const surchargeTitle =
    name === 'Processing Fees' &&
    data?.fees?.length > 0 &&
    (data?.fees[0]?.name === 'Surcharge' ||
      data?.fees[0]?.name === 'Surcharge Fee')
      ? 'Surcharge'
      : null;
  return (
    <>
      {data?.fees?.length > 0 && (
        <AccordionWrapper id={id}>
          <Accordion
            title={`${surchargeTitle || name} (${data.fees.length})`}
            titleSuffix={formatCurrency(data.total_fees)}
            disabled={data.fees.length === 0}
            asyncContent={true}
            contentStyle={{ padding: '0' }}
          >
            <StyledTable className="table-accordion-processing-fees">
              <TableBody>
                {data.fees.map(fee => {
                  return (
                    <TableRowStyled
                      style={{
                        border: 'none',
                        margin: 0,
                      }}
                      key={fee.id}
                      hover={
                        hasSettlementPermissions && fee.settlement_id
                          ? true
                          : false
                      }
                      onClick={() =>
                        hasSettlementPermissions && fee.settlement_id
                          ? window.open(
                              `#/settlements/ST${fee.settlement_id.toUpperCase()}/details?detailId=${
                                data.isSettlement ? fee.id : `TR${fee.id}`
                              }`
                            )
                          : null
                      }
                    >
                      <TableSectionLeft>
                        <Tooltip
                          content={fee.name}
                          disabled={tooltipDisabled(fee?.name?.length)}
                        >
                          <TableItem>{fee.name}</TableItem>
                        </Tooltip>
                      </TableSectionLeft>
                      <td style={{ textAlign: 'left', width: '33.3%' }}>
                        <TableItem>
                          {formatDate(fee.settled_at, 'MM/dd/yyyy')}
                        </TableItem>
                      </td>
                      <TableSectionRight>
                        <TableItem>{formatCurrency(fee.total)}</TableItem>
                      </TableSectionRight>
                    </TableRowStyled>
                  );
                })}
              </TableBody>
            </StyledTable>
          </Accordion>
        </AccordionWrapper>
      )}
    </>
  );
};

export default FeesSection;
