import { Registration } from '@fattmerchantorg/types-omni';
import { pick } from '../../../../util/object.util';

import {
  BankingInfoFieldsValues,
  BankingInfoFieldsErrors,
} from './BankingInfoForm.types';

export function mapFormValuesToPayload(
  formValues: BankingInfoFieldsValues
): Partial<Registration> {
  return {
    bank_account_owner_name: formValues.bank_account_owner_name,
    bank_routing_number: formValues.bank_routing_number,
    bank_account_number: formValues.bank_account_number,
    bank_account_type: formValues.bank_account_type,
    secondary_bank_account_owner_name:
      formValues.secondary_bank_account_owner_name,
    secondary_bank_routing_number: formValues.secondary_bank_routing_number,
    secondary_bank_account_number: formValues.secondary_bank_account_number,
    secondary_bank_account_type: formValues.secondary_bank_account_type,
    secondary_bank_account_purpose: formValues.secondary_bank_account_purpose,
  };
}

export function mapRegistrationToInitialFormValues(
  registration: Registration
): BankingInfoFieldsValues {
  return pick(
    registration,
    'bank_account_owner_name',
    'bank_routing_number',
    'bank_account_number',
    'bank_account_type',
    'secondary_bank_account_owner_name',
    'secondary_bank_routing_number',
    'secondary_bank_account_number',
    'secondary_bank_account_type',
    'secondary_bank_account_purpose'
  ) as BankingInfoFieldsValues;
}

export function validateRequiredFormValues(
  values: BankingInfoFieldsValues
): BankingInfoFieldsErrors {
  const errors = {} as BankingInfoFieldsErrors;

  const requiredFields = [
    'bank_account_owner_name',
    'bank_routing_number',
    'bank_account_number',
    'bank_account_type',
  ];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
}

export function validateFormValues(
  values: BankingInfoFieldsValues
): BankingInfoFieldsErrors {
  const errors = {} as BankingInfoFieldsErrors;

  if (values.bank_routing_number && values.bank_routing_number.length !== 9) {
    errors.bank_account_number = 'Bank routing numbers must be 9 digits long';
  }
  if (
    values.secondary_bank_routing_number &&
    values.secondary_bank_routing_number.length !== 9
  ) {
    errors.secondary_bank_account_number =
      'Bank routing numbers must be 9 digits long';
  }
  return errors;
}
