import { OmniDispute as Dispute } from '@fattmerchantorg/types-omni';
import { Dispatch, Reducer, useEffect, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import { AsyncData, AsyncDataStatus } from '../../@types';
import { usePrevious, useToaster } from '../../hooks';
import { parse } from '../../util/api.util';
import {
  Action,
  sendDisputeIDChanged,
  sendFetchFailed,
  sendFetchSucceeded,
} from './actions';
import { ActionTypes } from './types';

const initialState: AsyncData<Dispute> = {
  status: AsyncDataStatus.INITIAL,
  data: null,
};
/** PrevStatus will be `undefined` after first render. */
type PrevStatus = AsyncDataStatus | undefined;

async function fetchDispute(
  getData: (id: string) => Promise<Dispute>,
  detailId: string | string[] | null | undefined,
  dispatch: Dispatch<Action>
) {
  try {
    if (typeof detailId !== 'string' || !detailId) {
      // Abort search if no dispute ID is set in URL
      return;
    }
    if (!getData) {
      // Abort search if data fetch function is not provided.
      return;
    }
    const response = await getData(detailId);
    dispatch(sendFetchSucceeded(response));
  } catch (error) {
    console.error(error);
    dispatch(sendFetchFailed(error));
  }
}

type DisputeDetailReducer = Reducer<AsyncData<Dispute>, Action>;
/**
 * Reducer for the custom useDetailsModalHook.
 * This reducer is written in finite-state-machine style:
 * If the current status accepts the current action, dispute to a new status.
 * https://twitter.com/DavidKPiano/status/1171062893984526336
 */
const reducer: DisputeDetailReducer = (state, action) => {
  switch (state.status) {
    case AsyncDataStatus.INITIAL:
      switch (action.type) {
        case ActionTypes.FETCH_REQUESTED: {
          return {
            status: AsyncDataStatus.LOADING,
            data: null,
          };
        }
        default:
          return state;
      }
    case AsyncDataStatus.IDLE:
      switch (action.type) {
        case ActionTypes.FETCH_REQUESTED: {
          return {
            status: AsyncDataStatus.LOADING,
            data: null,
          };
        }
        default:
          return state;
      }

    case AsyncDataStatus.ERROR:
      switch (action.type) {
        case ActionTypes.FETCH_REQUESTED:
          return {
            status: AsyncDataStatus.LOADING,
            data: null,
          };
        default:
          return state;
      }

    case AsyncDataStatus.LOADING:
      switch (action.type) {
        case ActionTypes.FETCH_SUCCEEDED:
          return {
            status: AsyncDataStatus.IDLE,
            data: action.payload,
          };

        case ActionTypes.FETCH_FAILED:
          return {
            status: AsyncDataStatus.ERROR,
            data: null,
            error: action.payload,
          };
        case ActionTypes.FETCH_REQUESTED:
          // intentionally ignore additional fetches while
          // the existing request is in-flight.
          return state;
        default:
          return state;
      }
  }
};

export default function useDetailsModalHook(
  getData: (id: string) => Promise<Dispute>
): [AsyncData<Dispute>, Dispatch<Action>] {
  const { search } = useLocation();
  const { detailId } = parse(search);
  const { toaster, toast } = useToaster();

  const [state, dispatch] = useReducer<DisputeDetailReducer>(
    reducer,
    initialState
  );
  const prevStatus = usePrevious<PrevStatus>(state.status);

  /*
   * Perform side-effects according to status transitions.
   */
  useEffect(() => {
    // Use value of `prevStatus` to ensure side-effects are only performed when status transition occurs.
    switch (true) {
      case prevStatus !== AsyncDataStatus.LOADING &&
        state.status === AsyncDataStatus.LOADING:
        fetchDispute(getData, detailId, dispatch);
        break;
    }
  }, [prevStatus, state.status, getData, detailId, dispatch]);

  useEffect(() => {
    switch (true) {
      case prevStatus === AsyncDataStatus.LOADING &&
        state.status === AsyncDataStatus.ERROR:
        toaster(
          toast.error(
            state.error,
            `There was a problem retrieving the dispute.`
          )
        );
        break;
    }
  }, [prevStatus, state.status, state.error, toast, toaster]);

  /* Watch URL, and dispatch Actions */
  useEffect(() => {
    if (detailId && getData) {
      // Only if detailId is defined, dispatch the `FETCH_REQUESTED` Action
      dispatch(sendDisputeIDChanged());
    }
  }, [detailId, getData]);

  return [state, dispatch];
}
