import strEnum from '../../../util/strEnum';

/* ActionTypes */
export const ActionTypes = strEnum([
  'FETCH_REQUESTED',
  'FETCH_SUCCEEDED',
  'FETCH_FAILED',

  'REFUND_REQUESTED',
  'REFUND_SUCCEEDED',
  'REFUND_FAILED',

  'VOID_REQUESTED',
  'VOID_SUCCEEDED',
  'VOID_FAILED',
]);
