import React, { FunctionComponent, useContext, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { Widget } from '../../shared';
import { Icon } from '@fattmerchantorg/truffle-components';
import {
  useAsyncEffect,
  useAuthToken,
  useRouteMatchMerchantId,
  getFeatureLevel,
} from '../../../hooks';
import { permissionsapi } from '../../../api';
import { FeaturePermissions } from '@fattmerchantorg/types-omni';
import { SelectedMerchantStore } from '../../../context';
import { getSurchargeRate } from '../../../util/registration.util';

const DEFAULT_INCLUDED_CATEGORIES: (keyof FeaturePermissions)[] = ['Funding'];

const Container = styled(Widget)`
  background: white;
  min-height: 119px;
  border-radius: 0;
`;

const Content = withTheme(
  styled.div`
    color: ${({ theme }) => theme.colors.core.gray[600].hex};
    display: flex;
    flex-direction: row;
    margin: 1rem;

    h2 {
      margin: 0;
      color: ${({ theme }) => theme.black};
    }

    > div {
      flex: 50%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .features-content {
      p {
        font-size: 12px;
      }
      table {
        width: 100%;
        color: ${({ theme }) => theme.black};
        thead {
          tr {
            th {
              font-weight: bold;
              font-size: 12px;
              padding: 5px 10px 5px 10px;
              text-align: right;
              &:first-child {
                text-align: left;
              }
              &:last-child {
                padding-left: 0;
              }
            }
          }
        }
        tbody {
          tr {
            &:nth-child(odd) {
              background-color: #ebf9f0;
            }
            td {
              font-weight: bold;
              font-size: 14px;
              padding: 8px 16px 8px 16px;
              text-align: center;
              &:first-child {
                text-align: left;
              }
            }
          }
        }
      }
    }
  `
);

const WidgetHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding-bottom: 8px;
  font-size: 18px;
  color: ${({ theme }) => theme.black};
  i {
    margin: 0 0 8px;
  }
`;

const StyledIcon = styled(Icon)`
  color: ${({ color }) => color || '#28CB35'};
  margin-right: 9px;
  padding-top: 4px;
  padding-bottom: 0;
  font-weight: 900;
  font-size: 14px;
  line-height: 18px;
`;

type AllFeatures = {
  [K in keyof FeaturePermissions]: (keyof FeaturePermissions[K])[];
};

type FeaturesWidgetProps = {
  categories?: (keyof FeaturePermissions)[];
};

export const FeaturesWidget: FunctionComponent<FeaturesWidgetProps> = props => {
  const { categories = DEFAULT_INCLUDED_CATEGORIES } = props;
  const authToken = useAuthToken();
  const merchantId = useRouteMatchMerchantId();
  const [allFeatures, setAllFeatures] = useState<AllFeatures>(null);
  const [merchantFeatures, setMerchantFeatures] =
    useState<FeaturePermissions>(null);
  const {
    state: { registration },
  } = useContext(SelectedMerchantStore);
  const surchargeRate = getSurchargeRate(registration);

  useAsyncEffect(async () => {
    const [allFeatures, merchantFeatures] = await Promise.all([
      permissionsapi.get<AllFeatures>(authToken, '/features'),
      permissionsapi.get<FeaturePermissions>(
        authToken,
        `/merchant/${merchantId}/features`
      ),
    ]);

    setAllFeatures(allFeatures);
    setMerchantFeatures(merchantFeatures);
  }, [authToken]);

  const renderTable = () => {
    return (
      <table>
        <tbody>
          <tr data-testid={'surcharge_enabled'}>
            <td>
              {surchargeRate > 0 ? (
                <StyledIcon icon={['fas', 'check']} />
              ) : (
                <StyledIcon icon={['fas', 'times']} color="#FF4646" />
              )}
              Surcharge
            </td>
          </tr>
          {categories.map(category => {
            /**
             * casting as string[] to temporarily fix this error, which is fixed in a later version of typescript:
             *   - Each member of the union type '(<U>(callbackfn: (value: "QuickBooksOnlineSync" | "OnlineReputationManagement" | "PaycheckProtectionProgram" | "Referral" | "AdvancedCustomization" | "GiftUp!" | "PCICompliance" | "OmniiPhoneandiPadApp" | ... 13 more ... | "OmniVT+CardPresent", index: number, array: ("QuickBooksOnlineSync" | ... 20 more ... | "OmniV...' has signatures, but none of those signatures are compatible with each other.  TS2349
             */
            const featuresInCategory = allFeatures[category] as string[];

            return featuresInCategory?.map(
              (feature: keyof FeaturePermissions[typeof category]) => {
                const merchantHasFeature =
                  getFeatureLevel(category, feature, merchantFeatures) ===
                  'available';
                const id = `${category}_${feature}_${
                  merchantHasFeature ? 'enabled' : 'disabled'
                }`;

                return (
                  <tr key={id} id={id} data-testid={id}>
                    <td>
                      {merchantHasFeature ? (
                        <StyledIcon icon={['fas', 'check']} />
                      ) : (
                        <StyledIcon icon={['fas', 'times']} color="#FF4646" />
                      )}
                      {feature}
                    </td>
                  </tr>
                );
              }
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <Container data-testid="features-widget-container">
      <Content>
        <div>
          <WidgetHeader>
            <h2>Features</h2>
          </WidgetHeader>
          <div className="features-content">
            {allFeatures && merchantFeatures ? renderTable() : null}
          </div>
        </div>
      </Content>
    </Container>
  );
};
