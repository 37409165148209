import React from 'react';
import { Deposit, Merchant } from '@fattmerchantorg/types-omni';
import { formatCurrency, formatReadableDate } from '../../util/format.util';
import { ColumnProps, Id } from '../shared';

export const getColumns = (
  merchant: Merchant | null,
  hasFees: boolean
): ColumnProps<Deposit>[] => {
  const isTrustAccount = merchant?.is_trust;
  const isSurchargingEnabled = merchant?.is_surcharge_enabled;

  const columns: ColumnProps<Deposit>[] = [
    {
      id: 'display_batch_id',
      Header: 'ID',
      disableSortBy: true,
      accessor: 'display_batch_id',
      Cell: cell => <Id id={cell.value} />,
    },
    {
      id: 'count',
      Header: '# of Sales',
      disableSortBy: true,
      accessor: 'count',
      Cell: cell => `${cell.value} ${cell.value > 1 ? 'Sales' : 'Sale'}`,
    },
  ];

  if (isSurchargingEnabled) {
    columns.push(
      {
        id: 'subtotal',
        Header: 'Subtotal',
        disableSortBy: true,
        accessor: 'sum',
        Cell: cell => formatCurrency(cell.value),
      },
      {
        id: 'surcharge',
        Header: 'Surcharge',
        disableSortBy: true,
        accessor: 'surcharges',
        Cell: cell => formatCurrency(cell.value || 0),
      },
      {
        id: 'sum',
        Header: 'Total',
        disableSortBy: true,
        accessor: 'sum',
        Cell: cell => {
          const total = cell.value;
          const surcharges = cell.row.original.surcharges || 0;
          return formatCurrency(total - surcharges);
        },
      }
    );
  } else {
    columns.push({
      id: 'sum',
      Header: 'Total',
      disableSortBy: true,
      accessor: 'sum',
      Cell: cell => formatCurrency(cell.value),
    });
  }

  // only show fees if fee information is available
  // and surcharge is not enabled
  // and this is not a trust account merchant
  if (hasFees && !isSurchargingEnabled && !isTrustAccount) {
    columns.push({
      id: 'fees',
      Header: 'Fees',
      disableSortBy: true,
      accessor: 'fees',
      Cell: cell => formatCurrency(cell.value),
    });
  }

  columns.push({
    id: 'date',
    Header: 'Date',
    disableSortBy: true,
    accessor: 'batched_at',
    Cell: cell => formatReadableDate(cell.value),
  });

  return columns;
};
