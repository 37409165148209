import React, { FunctionComponent, useContext, useState } from 'react';
import styled from 'styled-components';
import {
  AuthStore,
  ImportStore,
  PermissionsStore,
  SelectedMerchantStore
} from '../../context';
import { StaxButton } from '../shared/Stax/Button';
import { StyledButtonGroup } from './DevTools';
import { ShowJSON } from './ShowJSON';

const ContextWrapper = styled.div`
  background: #fff;
  border: 1px solid #666;
  padding: 8px;
  margin-top: 38px;
  border-radius: 2px;
  overflow-x: scroll;
`;

export const Contexts: FunctionComponent = () => {
  const { state: selectedMerchantState } = useContext(SelectedMerchantStore);
  const { gateways, apiKeys, merchant, registration } = selectedMerchantState;
  const { state: authState } = useContext(AuthStore);
  const { state: importState } = useContext(ImportStore);
  const { state: permissionsState } = useContext(PermissionsStore);

  const [
    selectedMerchantActiveContext,
    setSelectedMerchantActiveContext
  ] = useState('Auth');

  const contexts = [
    { title: 'Global Context', contexts: ['Auth', 'Importer', 'Permissions'] },
    {
      title: 'Selected Merchant Context',
      contexts: ['Merchant', 'Registration', 'Gateways', 'API Keys']
    }
  ];

  let activeContext: object | null = null;

  switch (selectedMerchantActiveContext) {
    case 'Merchant':
      activeContext = merchant;
      break;
    case 'Registration':
      activeContext = registration;
      break;
    case 'Gateways':
      activeContext = gateways;
      break;
    case 'API Keys':
      activeContext = apiKeys;
      break;
    case 'Auth':
      activeContext = authState;
      break;
    case 'Importer':
      activeContext = importState;
      break;
    case 'Permissions':
      activeContext = permissionsState;
      break;
  }

  return (
    <div className="row">
      <div className="col-3">
        <ContextWrapper>
          {contexts.map(contextSet => {
            const buttonGroup = contextSet.contexts.map(subModule => (
              <StaxButton
                key={subModule}
                onClick={() => setSelectedMerchantActiveContext(subModule)}
                className="mb-2 text-left"
                style={{
                  background:
                    selectedMerchantActiveContext === subModule
                      ? 'silver'
                      : null,
                  borderLeft:
                    selectedMerchantActiveContext === subModule
                      ? '4px solid gray'
                      : null
                }}
              >
                <i className="fas fa-database"></i> {subModule}
              </StaxButton>
            ));
            return (
              <React.Fragment key={contextSet.title}>
                <h2>{contextSet.title}</h2>
                <StyledButtonGroup>{buttonGroup}</StyledButtonGroup>
              </React.Fragment>
            );
          })}
        </ContextWrapper>
      </div>
      <div className="col-9">
        <ShowJSON data={activeContext} />
      </div>
    </div>
  );
};
