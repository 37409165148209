import React, { FunctionComponent } from 'react';
import { PrimaryButton } from '@fattmerchantorg/truffle-components';
import { FundingAccount } from '@fattmerchantorg/types-omni';
import styled from 'styled-components';
import { getNickname } from '../../util/BankAccountsPage.util';
import {
  ModalBody,
  ModalButtonWrapper,
  ModalIcon,
  ModalText,
} from './ModalBody.styles';

const ApprovedIcon: typeof ModalIcon = styled(ModalIcon)`
  color: ${({ theme }) => theme.colors.status.green[500].hex};
`;

const StyledModalBody = styled(ModalBody)`
  margin-bottom: -56px;
  margin-top: 16px;
`;

interface ApprovedAccountModalProps {
  account: FundingAccount;
  onCancel: () => void;
}

export const ApprovedAccountModalBody: FunctionComponent<
  ApprovedAccountModalProps
> = props => {
  const { account, onCancel } = props;

  return (
    <StyledModalBody>
      <ApprovedIcon icon={['far', 'check']} />
      <ModalText as="p">
        <strong>{getNickname(account)} </strong>
        has been verified!
      </ModalText>
      <ModalButtonWrapper>
        <PrimaryButton type="button" onClick={onCancel}>
          Continue
        </PrimaryButton>
      </ModalButtonWrapper>
    </StyledModalBody>
  );
};
