import React, { Context, createContext, useReducer } from 'react';
import {
  TicketsContextProps,
  TicketsContext,
  TicketsAction,
  TicketsState,
  TicketsActionType,
  TicketsEffectType,
} from './Tickets.types';

const TicketsStore = createContext(
  {} as TicketsContext
) as Context<TicketsContext>;

const initialState = {
  ticketsCount: { AWAITING_RESPONSE: 0, CLOSED: 0, NEW: 0, PENDING: 0 },
  effect: null,
};

type Reducer = (state: TicketsState, action: TicketsAction) => TicketsState;

const storeReducer: Reducer = (state, action) => {
  switch (action.type) {
    case TicketsActionType.SWITCH_BRAND:
      const ticketsCount = action.payload;
      return { ...state, ticketsCount };
    default:
      break;
  }

  return state;
};

const effectReducer: Reducer = (state, action) => {
  let newState = state;

  switch (action.type) {
    case TicketsActionType.SWITCH_BRAND:
      newState = {
        ...state,
        effect: {
          type: TicketsEffectType.SWITCH_BRAND,
          payload: state,
        },
      };
      break;
    default:
      break;
  }

  return newState;
};

const reduceReducers =
  (...reducers: Reducer[]) =>
  (state: TicketsState, action: TicketsAction) => {
    return reducers.reduce(
      (nextState, reducer) => reducer(nextState, action),
      state
    );
  };

const TicketsProvider = (props: TicketsContextProps) => {
  const [state, dispatch] = useReducer<
    React.Reducer<TicketsState, TicketsAction>
  >(reduceReducers(storeReducer, effectReducer), initialState);

  const localContext: TicketsContext = {
    state: state,
    dispatch: dispatch,
  };

  return (
    <TicketsStore.Provider value={localContext}>
      {props.children}
    </TicketsStore.Provider>
  );
};

export { TicketsStore, TicketsProvider };
