import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import { ErrorPage } from '../error/Error';
import { history } from '../../history';
import { LoginPage } from '../login/LoginPage';
import { LogoutPage } from '../logout/LogoutPage';
import { SsoPage } from '../sso/SsoPage';
import { ExternalUrl } from './private';

import { PrivateRoutes } from './components';

export const Routes = () => (
  <Router history={history}>
    <Switch>
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/logout" component={LogoutPage} />
      <Route exact path="/sso" component={SsoPage} />
      <Route path="/error/:code" component={ErrorPage} />
      <Route path="/allbound" component={ExternalUrl} />
      <PrivateRoutes />
    </Switch>
  </Router>
);
