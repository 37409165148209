import { Dispatch, ReactNode } from 'react';

export interface ToastProps {
  motif?: 'success' | 'warning' | 'error' | 'info';

  title?: ReactNode;
  content?: ReactNode;
  lifetime?: number | 'forever';

  id?: string;
}

export interface ToastContextProps {
  children: React.ReactNode;
}

export interface ToastState {
  toasts: ToastProps[];
}

export enum ToastActionType {
  ADD_TOAST = 'ADD_TOAST',
  REMOVE_TOAST = 'REMOVE_TOAST'
}

export type ToastAction = {
  type: ToastActionType;
  payload?: ToastActionPayload;
};

export type ToastActionPayload = string | ToastProps;
export interface ToastContext {
  state: ToastState;
  dispatch: Dispatch<ToastAction>;
  toaster: Dispatch<ToastAction>;
  toast: {
    [key in ToastProps['motif']]: (
      content: any,
      title?: ReactNode,
      options?: Partial<ToastProps>
    ) => ToastAction;
  };
}
