import { FundingAccount } from '@fattmerchantorg/types-omni';
import {
  formatDate,
  parseDateStringInUnknownFormat,
} from '../../../util/date.util';

export const getNickname = (account: FundingAccount) => {
  if (!account) {
    return '';
  } else if (account.name) {
    return account.name;
  } else {
    return `${account.bank_name || ''} **${account.last_four}`.trim();
  }
};

export const renderTimestamp = (value: string, format?: string): string => {
  const defaultFormat = 'MM/dd/yy | h:mm a';
  return formatDate(
    parseDateStringInUnknownFormat(value),
    format ?? defaultFormat
  );
};

export const checkAccountTypeUpdate = (oldFlags: string, newFlags: string) => {
  // Check if only PRIMARY changed.
  if (
    !oldFlags?.includes('PRIMARY') &&
    newFlags?.includes('PRIMARY') &&
    !(!oldFlags?.includes('FEE') && newFlags?.includes('FEE'))
  ) {
    return 'primary';
  }

  // Check if only FEE changed.
  if (
    !oldFlags?.includes('FEE') &&
    newFlags?.includes('FEE') &&
    !(!oldFlags?.includes('PRIMARY') && newFlags?.includes('PRIMARY'))
  ) {
    return 'fee';
  }

  // Check if both PRIMARY and FEE changed.
  if (
    !oldFlags?.includes('FEE') &&
    !oldFlags?.includes('PRIMARY') &&
    newFlags?.includes('FEE') &&
    newFlags?.includes('PRIMARY')
  ) {
    return 'primaryAndFee';
  }

  return false;
};
