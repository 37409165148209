import React, {
  FunctionComponent,
  useCallback,
  useState,
  ReactNode,
} from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { useWindowSize } from '../../../../hooks';
import { FileRecord } from '@fattmerchantorg/types-omni';
import { Icon } from '@fattmerchantorg/truffle-components';

export interface UploaderProps {
  files?: FileRecord[];
  onDrop: (files: File[]) => Promise<any> | any;
  onDelete?: (file: FileRecord, index: number) => Promise<any> | any;
  onDownload?: (file: FileRecord, index: number) => Promise<any> | any;
}

const Dropzone = styled.div`
  border: 2px dashed #bdc9cc;
  border-radius: 8px;
  padding: 24px;
  width: 100%;
  cursor: pointer;

  &:hover {
    border: 2px dashed ${({ theme }) => theme.colors.secondaryColor};
  }
`;

const DropzoneContent = styled.div`
  display: flex;
  flex-direction: column;
  place-items: center;
  // min-height so the dropzone doesn't change heights while dragging/dropping
  min-height: 114px;

  > span:not(:last-child) {
    margin: 0 0 8px;
  }
`;

interface UploadIcon {
  state: 'dragActive' | 'idle';
}

const UploadIcon = styled(Icon)<UploadIcon>`
  font-size: 48px;
  margin: 0 0 8px;
  color: ${({ state, theme }) =>
    state === 'dragActive' ? theme.primaryColor : theme.white};
`;

const UploadSpinner = styled.i`
  display: block;
  font-size: 48px;
  margin: 0 0 8px;
`;

export const Uploader: FunctionComponent<UploaderProps> = props => {
  const { isMobile } = useWindowSize();
  const [isUploading, setIsUploading] = useState(false);
  const { onDrop } = props;

  const handleDrop = useCallback(
    (files: File[]) => {
      const p = onDrop(files);

      if (p instanceof Promise) {
        (async function () {
          setIsUploading(true);
          await p;
          setIsUploading(false);
        })();
      }
    },
    [onDrop]
  );

  const dropzone = useDropzone({
    onDrop: handleDrop,
    multiple: true,
    maxSize: 30000000, // 30MB
    accept: 'image/jpeg, image/png, .pdf, .csv',
  });

  const renderContent = (): ReactNode => {
    if (dropzone.isDragActive) {
      return (
        <>
          <UploadIcon icon={['fas', 'cloud-upload']} state="dragActive" />
          <span>Drop to upload</span>
        </>
      );
    } else if (isUploading) {
      return (
        <>
          <UploadSpinner className="fas fa-circle-notch fa-spin" />
          <span>Uploading...</span>
        </>
      );
    } else {
      return (
        <>
          {isMobile ? (
            <span>Tap to upload</span>
          ) : (
            <>
              <UploadIcon icon={['fas', 'cloud-upload']} state="idle" />
              <span>Drag and drop or click to browse your files</span>
              <span>Accepted file types: .jpg, .png, .pdf, .csv</span>
            </>
          )}
        </>
      );
    }
  };

  return (
    <Dropzone {...dropzone.getRootProps()}>
      <input {...dropzone.getInputProps()} />
      <DropzoneContent>{renderContent()}</DropzoneContent>
    </Dropzone>
  );
};
