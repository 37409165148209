import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  FundingAccount,
  FundingAccountAuditLog,
} from '@fattmerchantorg/types-omni';
import {
  DestructiveButton,
  Drawer,
  PrimaryButton,
  Text,
  TextLink,
  Tooltip,
} from '@fattmerchantorg/truffle-components';
import { DrawerProps } from '@fattmerchantorg/truffle-components/dist/Drawer/Drawer';
import useDetailsModal from './useDetailsModal';
import { LoadingSpan } from '../../../shared';
import { AsyncDataStatus } from '../../../../@types';
import {
  usePrevious,
  useAuthToken,
  useRouteMatchMerchantId,
  useAsyncEffect,
} from '../../../../hooks';
import { BankAccountStatusPill } from '../BankAccountStatusPill';
import { BankAccountImage } from '../BankAccountImage';
import { BankAccountDrawerStatusPanel } from './BankAccountDrawerStatusPanel';
import { BankAccountDrawerFiles } from './BankAccountDrawerFiles';
import { BankAccountDrawerHistory } from './BankAccountDrawerHistory';
import { BankAccountDrawerVerification } from './BankAccountDrawerVerification';
import {
  Section,
  Separator,
  Row,
  LineItemRow,
  BottomBar,
  SecondaryDestructiveButton,
} from './BankAccountDrawerStyles';
import { sendFetchRequested } from './actions';
import { coreapi } from '../../../../api';

/** Props accepted by the DetailsModal component */
type BankAccountDrawerProps = Pick<DrawerProps, 'open' | 'onClose'> & {
  getData: ((id: string) => Promise<FundingAccount>) | null;
  updateData:
    | ((
        id: string,
        payload: Partial<FundingAccount>
      ) => Promise<FundingAccount>)
    | null;

  onEdit: (account: FundingAccount) => void;
  onApprove: (account: FundingAccount) => void;
  onReject: (account: FundingAccount) => void;
  onDeactivate: (account: FundingAccount) => void;
};

export const BankAccountDrawer: FunctionComponent<
  BankAccountDrawerProps
> = props => {
  const {
    getData,
    updateData,

    onClose,
    onEdit,
    onApprove,
    onReject,
    onDeactivate,

    ...drawerProps
  } = props;
  const merchantId = useRouteMatchMerchantId();
  const authToken = useAuthToken();
  const [state, dispatch] = useDetailsModal(getData, updateData);
  const loading =
    state.status === AsyncDataStatus.INITIAL ||
    state.status === AsyncDataStatus.LOADING;

  const prevStatus = usePrevious(state.status);
  const account = state.data;
  const accountName = `${account?.bank_name ?? ''} **${
    account?.last_four ?? ''
  }`.trim();
  const accountId = account?.id;

  const [historyAuditLogs, setHistoryAuditLogs] = useState<
    FundingAccountAuditLog[]
  >([]);
  const [verificationAuditLogs, setVerificationAuditLogs] = useState<
    FundingAccountAuditLog[]
  >([]);
  const [logsLoading, setLogsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (
      state.status === AsyncDataStatus.ERROR &&
      prevStatus !== AsyncDataStatus.ERROR
    ) {
      onClose();
    }
  }, [state.status, prevStatus, onClose]);

  useAsyncEffect(async () => {
    setHistoryAuditLogs([]);
    setVerificationAuditLogs([]);
    setLogsLoading(true);

    if (accountId) {
      const auditLogs = await coreapi.get<FundingAccountAuditLog[]>(
        authToken,
        `/merchant/${merchantId}/funding-account/${accountId}/audit-logs`
      );

      const historyAuditLogs: FundingAccountAuditLog[] = [];
      const verificationAuditLogs: FundingAccountAuditLog[] = [];

      auditLogs.forEach(auditLog => {
        if (
          auditLog.source === 'POST_FUNDING_ACCOUNT' ||
          auditLog.source === 'PUT_FUNDING_ACCOUNT' ||
          auditLog.source === 'DELETE_FUNDING_ACCOUNT_FILE' ||
          auditLog.source === 'POST_FUNDING_ACCOUNT_FILE'
        ) {
          historyAuditLogs.push(auditLog);
        } else {
          verificationAuditLogs.push(auditLog);
        }
      });

      setHistoryAuditLogs(historyAuditLogs);
      setVerificationAuditLogs(verificationAuditLogs);
    }

    setLogsLoading(false);
  }, [authToken, merchantId, accountId]);

  return (
    <Drawer
      {...drawerProps}
      onClose={onClose}
      anchor="right"
      title="Bank Account Details"
      data-testid="funding-account-modal"
      customStyles={{
        borderRadius: 0,
        padding: 0,
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Section>
        {loading ? (
          <Row>
            <LoadingSpan height="36px" width="200px" />
          </Row>
        ) : (
          <LineItemRow>
            <Row>
              <BankAccountImage bankName={account?.bank_name} />
              <Text as="h4">{accountName}</Text>
              <BankAccountStatusPill account={account} />
            </Row>

            {account.active || account.status === 'PENDED' ? (
              <TextLink
                icon={['fas', 'pencil']}
                onClick={() => onEdit(account)}
              >
                Edit
              </TextLink>
            ) : (
              <div />
            )}
          </LineItemRow>
        )}
      </Section>
      <BankAccountDrawerStatusPanel account={account} />
      <BankAccountDrawerVerification
        verificationAuditLogs={verificationAuditLogs}
        loading={logsLoading}
      />
      <Separator />
      <BankAccountDrawerFiles
        account={account}
        onNeedsRefetch={() => dispatch(sendFetchRequested())}
      />
      <Separator />
      <BankAccountDrawerHistory
        historyAuditLogs={historyAuditLogs}
        loading={logsLoading}
      />

      {account?.status === 'PENDED' ? (
        <BottomBar>
          <SecondaryDestructiveButton onClick={() => onReject(account)}>
            Reject Account
          </SecondaryDestructiveButton>
          <Tooltip
            disabled={account.files.length > 0}
            content="At least one file must be uploaded before approving an account"
            maxWidth="171px"
          >
            <PrimaryButton
              disabled={account.files.length === 0}
              onClick={() => onApprove(account)}
            >
              Approve Account
            </PrimaryButton>
          </Tooltip>
        </BottomBar>
      ) : null}

      {account?.status === 'APPROVED' && account?.active ? (
        <BottomBar>
          <SecondaryDestructiveButton onClick={() => onReject(account)}>
            Reject Account
          </SecondaryDestructiveButton>
          <DestructiveButton onClick={() => onDeactivate(account)}>
            Deactivate Account
          </DestructiveButton>
        </BottomBar>
      ) : null}
    </Drawer>
  );
};
