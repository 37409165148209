import { GodviewBrandData } from '@fattmerchantorg/types-omni';
import { buildApi } from '../util/api.util';

export const permissionsapi = buildApi(
  process.env.REACT_APP_PERMISSIONS_API_URL
);

export interface AllBrandsResponseItem {
  id: string;
  name: string;
  displayName: string;
}

/** Response for the permissions service route, GET /allBrands */
export interface AllBrandsResponse extends Array<AllBrandsResponseItem> {}

/** Response for the permissions service route, GET /brand/data/{brandName} */
export interface BrandDataResponse extends GodviewBrandData {}
