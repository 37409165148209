import currency from 'currency.js';

/**
 * Sum the amounts without experiencing loss of precision that is associated with floating-point representation.
 * @param a
 * @param b
 * @param options
 * @returns sum
 */
export function addAmounts(
  a: number,
  b: number,
  options: currency.Options = { precision: 10 }
): number {
  return currency(a, options).add(b).value;
}
export const add = addAmounts;

/**
 * Subtract the amounts without experiencing loss of precision that is associated with floating-point representation.
 * @param a
 * @param b
 * @param options
 * @returns difference
 */
export function subtractAmounts(
  a: number,
  b: number,
  options: currency.Options = { precision: 10 }
): number {
  return currency(a, options).subtract(b).value;
}
export const subtract = subtractAmounts;

/**
 * Multiply the amounts without experiencing loss of precision that is associated with floating-point representation.
 * @param a
 * @param factor
 * @param options
 * @returns multiplied value
 */
export function multiplyAmounts(
  a: number,
  factor: number,
  options?: currency.Options
): number {
  return currency(a, options).multiply(factor).value;
}
export const multiply = multiplyAmounts;

/**
 * Divide the amounts without experiencing loss of precision that is associated with floating-point representation.
 * @param dividend
 * @param divisor
 * @returns division result
 */
export function divideAmounts(
  dividend: number,
  divisor: number,
  options?: currency.Options
): number {
  return currency(dividend, options).divide(divisor).value;
}
export const divide = divideAmounts;

export function roundAmount(
  value: number,
  options: currency.Options = { precision: 2 }
) {
  return currency(value, options).value;
}
export const round = roundAmount;
