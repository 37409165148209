import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import isNil from 'lodash/isNil';

export function isRequired(
  value: string | number | string[]
): string | undefined {
  if (isArray(value) || isString(value)) {
    return !isEmpty(value) ? undefined : 'Required';
  }

  return !isNil(value) ? undefined : 'Required';
}
