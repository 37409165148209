import React, { useState, useContext, useEffect } from 'react';
import { Field, useFormState } from 'react-final-form';
import styled from 'styled-components';
import { Select } from '@fattmerchantorg/truffle-components';
import { Registration } from '@fattmerchantorg/types-omni';
import { FormRow, PartialForm } from '../../../shared/UnderwritingPartialForm';
import { AuthStore } from '../../../../../../context';
import {
  ApprovedReasons,
  PendedReasons,
  RejectedReasons,
} from '../../../../../../@types/UnderwritingStatus';
import {
  LitleFinixConfigurations,
  CoreFinixConfigurations,
  FinixConfigurationsDictionary,
} from '../../../../../../@types/UnderwritingStatus';
import { getFinixApplications } from '../../../../../../services/finix-service';
import {
  usePermissions,
  useAsyncEffect,
  useToaster,
} from '../../../../../../hooks';
import { SubmitApplicationFieldsValues } from '../SubmitApplicationForm.types';

export type UnderwritingSubStatus =
  | PendedReasons
  | RejectedReasons
  | ApprovedReasons;

const SelectLabelAlignmentFix = styled.div`
  display: flex;
`;

interface PayfacFieldsProps {
  finixCredentialId: string | null;
  registration: Registration;
  setIsSubmitEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const RadioGroup = styled.div`
  label {
    display: block;
  }
`;

export const PayfacFields: React.FC<PayfacFieldsProps> = props => {
  const { registration, setIsSubmitEnabled, finixCredentialId } = props;
  const { values } = useFormState<SubmitApplicationFieldsValues>();
  const { permit } = usePermissions();
  const { state: authState } = useContext(AuthStore);
  const [finixApplications, setFinixApplications] = useState<
    { label: string; value: string }[]
  >([]);
  const { authToken } = authState;
  const { toaster, toast } = useToaster();
  const processor = values.processor;

  // Get all finix apps but only once
  useAsyncEffect(async () => {
    if (authToken) {
      try {
        const apps = await getFinixApplications(authToken, finixCredentialId);

        setFinixApplications(
          apps.map(app => {
            return {
              label: app.name,
              value: app.id,
            };
          })
        );
      } catch (error) {
        toaster(
          toast.error(
            error,
            'There was a problem retrieving application types.'
          )
        );
      }
    }
  }, [authToken]);

  // Since processor config fields have default values, we use the application
  // selection as the trigger to enable submit as it is the last required field
  // for this processor
  useEffect(() => {
    if (values.payfacApplication) {
      setIsSubmitEnabled(true);
    }
  }, [values, setIsSubmitEnabled]);

  if (!processor) return null;

  return (
    <PartialForm data-testid="payfac-configuration-fields">
      <FormRow>
        <SelectLabelAlignmentFix>
          <label>ISV application</label>
        </SelectLabelAlignmentFix>
        <Field
          name="payfacApplication"
          format={value => {
            return finixApplications.find(option => option.value === value);
          }}
          clearable={false}
        >
          {props => (
            <div data-testid="payfacApplication">
              <Select
                aria-label="ISV Application Select"
                style={{ marginBottom: '1em' }}
                disabled={
                  !permit('godview', 'payfacVerification', 'write') ||
                  !finixApplications
                }
                options={finixApplications}
                styles={{
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                  input: base => ({ ...base, color: 'white', padding: 0 }),
                }}
                menuPortalTarget={document.body}
                menuShouldScrollIntoView={false}
                isSearchable={true}
                menuPosition="fixed"
                {...props.input}
                onChange={application => {
                  props.input.onChange(application);
                }}
              />
            </div>
          )}
        </Field>
      </FormRow>
      {registration?.chosen_processing_method === 'shopping-cart' ? (
        <FormRow>
          <SelectLabelAlignmentFix>
            <label>Processing Info</label>
          </SelectLabelAlignmentFix>
          <div data-testid="processing-info-select">
            <Select
              name="processingInfo"
              aria-label="Processing Info Select"
              options={[
                { label: 'Shopping Cart', value: 'shopping-cart' },
                { label: 'Keyed Transactions', value: 'keyed-transactions' },
              ]}
            />
          </div>
        </FormRow>
      ) : null}

      <FormRow>
        <label className="ui-label">Configuration</label>
        {values.processor === 'CORE_FINIX' && (
          <RadioGroup>
            {Object.keys(CoreFinixConfigurations).map((config, index) => (
              <label htmlFor={`core-config-${index}`} key={config}>
                <Field
                  name="payfacConfiguration"
                  component="input"
                  initialValue="Core_CNP"
                  type="radio"
                  value={CoreFinixConfigurations[config]}
                  id={`core-config-${index}`}
                  data-testid={`payfac-form-core-configuration-${config}`}
                />
                {FinixConfigurationsDictionary[config]}
              </label>
            ))}
          </RadioGroup>
        )}
        {(values.processor === 'LITLE' ||
          values.processor === 'LITLE_FINIX') && (
          <RadioGroup>
            {Object.keys(LitleFinixConfigurations).map((config, index) => (
              <label htmlFor={`litle-config-${index}`} key={config}>
                <Field
                  name="payfacConfiguration"
                  component="input"
                  initialValue="Litle_CNP"
                  type="radio"
                  value={LitleFinixConfigurations[config]}
                  id={`litle-config-${index}`}
                  data-testid={`payfac-form-litle-configuration-${config}`}
                />
                {FinixConfigurationsDictionary[config]}
              </label>
            ))}
          </RadioGroup>
        )}
      </FormRow>
    </PartialForm>
  );
};
