import React, { FC } from 'react';
import styled from 'styled-components';

interface ITransactionStatusProps {
  isSuccessful: boolean;
}

const Wrapper = styled.div`
  text-align: center;
`;

const SuccessIcon = styled.i`
  color: #0ca200;
`;

const ErrorIcon = styled.i`
  color: #a20000;
`;

const TransactionStatus: FC<ITransactionStatusProps> = (props) => {
  return (
    <Wrapper>
      {props.isSuccessful ? (
        <SuccessIcon
          className="fas fa-check-circle"
          data-testid="ui-transaction-status-icon"
        />
      ) : (
        <ErrorIcon
          className="fas fa-times-circle"
          data-testid="ui-transaction-status-icon"
        />
      )}
    </Wrapper>
  );
};

export default TransactionStatus;
