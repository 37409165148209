import React, { createContext, useReducer } from 'react';
import get from 'lodash/get';
import {
  ModalState,
  ModalStore,
  ModalAction,
  ModalProviderProps,
} from './Modal.types';

export const ModalContext = createContext({} as ModalStore);

const modalInitialState: ModalState = {
  component: null,
  props: {},
};

const modalReducer = (state: ModalState, action: ModalAction): ModalState => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        component: get(action.payload, 'component', null),
        props: get(action.payload, 'props', {}),
      };
    case 'CLOSE_MODAL':
      return {
        component: null,
        props: {
          isOpen: false,
        },
      };
    default:
      return state;
  }
};

export const ModalProvider = (props: ModalProviderProps) => {
  const { children, initialState = {} } = props;
  const [modalState, modalDispatch] = useReducer(modalReducer, {
    ...modalInitialState,
    ...initialState,
  });

  const localContext: ModalStore = {
    modalState,
    modalDispatch,
  };

  return (
    <ModalContext.Provider value={localContext}>
      {children}
    </ModalContext.Provider>
  );
};

export const ModalConsumer = ModalContext.Consumer;

export const ModalRoot = () => (
  <ModalConsumer>
    {({ modalState: { component: Component, props } }) =>
      Component ? <Component {...props} /> : null
    }
  </ModalConsumer>
);
