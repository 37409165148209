import React, { useContext, useEffect } from 'react';
import {
  fetchSelectedMerchantBrandData,
  ModalContext,
  SelectedMerchantStore,
} from '../../../../context';
import { UnderwritingSection } from '../shared/UnderwritingSection';
import styled from 'styled-components';
import { formatCurrency, formatPercent } from '../../../../util/format.util';
import { ProcessingEnvironmentFormModal } from './ProcessingEnvironmentFormModal';
import { useState } from 'react';
import { EditToggle } from '../shared/EditToggle';
import { Icon } from '@fattmerchantorg/truffle-components';
import { FieldGroupTitle } from '../shared/UnderwritingSection';
import { PricingPlan, GodviewBrandData } from '@fattmerchantorg/types-omni';
import {
  businessTypes,
  networks,
  processingMethods,
} from '../../../../util/registration.util';
import get from 'lodash/get';
export interface ProcessingEnvironmentProps {}

const DivRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 0 16px;
  border-bottom: 0px solid ${({ theme }) => theme.lightNeutralOutline};
`;

const SectionWrap = styled.div`
  padding: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Div50 = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${DivRow}:last-of-type {
    padding: 0;
  }
`;
const Div33 = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${DivRow}:last-of-type {
    padding: 0;
  }
`;
const DivLeft = styled.div`
  width: 50%;
`;
const DivRight = styled.div`
  width: 50%;
  text-align: right;
`;
const DivContent = styled.div`
  width: 100%;
  display: block;
  font-size: 14px;

  ${FieldGroupTitle} {
    margin: 0 0 8px;
  }

  ${SectionWrap}:last-of-type {
  }
`;
const DivBottomSeparator = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.lightNeutralOutline};
  margin: 16px 0;
`;
const DivRightSeparator = styled.div`
  border-right: 1px solid ${({ theme }) => theme.lightNeutralOutline};
  margin: 0 8px;
`;
const Column = styled.div`
  display: flex;
  justify-content: space-between;
  width: 25%;
`;

export type BrandWithPricing = GodviewBrandData & {
  filteredPricingFields: PricingPlan[];
};

export function ProcessingEnvironment(props: ProcessingEnvironmentProps) {
  const {
    dispatch,
    state: { merchantId, registration, brand, merchant },
  } = useContext(SelectedMerchantStore);

  const processingMethod = processingMethods.find(method => {
    return method.key === registration?.chosen_processing_method;
  });
  const pricingPlan = brand?.data?.filteredPricingFields.find(pricingField => {
    return pricingField.name === registration?.pricing_plan;
  });

  const { modalDispatch } = useContext(ModalContext);
  const [regState, setRegState] = useState(registration);
  const [mId, setmId] = useState(merchantId);
  const [regBrand, setRegBrand] = useState<BrandWithPricing>(undefined);

  useEffect(() => {
    const brandName = merchant?.brand;
    if (brandName) {
      dispatch(fetchSelectedMerchantBrandData(merchant.brand));
    }
  }, [merchant, dispatch]);

  useEffect(() => {
    setRegState(registration);
    setmId(merchantId);
    if (brand?.status === 'IDLE') {
      setRegBrand(brand?.data);
    }
  }, [registration, merchantId, brand]);

  const handleClick = () => {
    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        component: ProcessingEnvironmentFormModal,
        props: {
          isOpen: true,
          registration: regState,
          merchantId: mId,
          brand: regBrand,
          merchantDispatch: dispatch,
        },
      },
    });
  };
  const businessTypeName = get(
    businessTypes.find(biz => biz.key === registration?.bus_type),
    'value',
    registration?.bus_type
  );
  const networkName = get(
    networks.find(net => net.key === registration?.network),
    'value',
    registration?.network
  );
  return (
    <UnderwritingSection
      title="Processing Environment"
      id="underwriting-processing-environment"
    >
      {({ setToolTipContent }) => {
        setToolTipContent('Processing Environment.');

        return (
          <DivContent>
            <EditToggle onClick={handleClick}>
              <Icon icon={['fas', 'pencil']} />
            </EditToggle>
            <SectionWrap>
              <Div50>
                <DivRow>
                  <DivLeft>Processing Method</DivLeft>
                  <DivRight>{processingMethod?.description || '-'}</DivRight>
                </DivRow>
              </Div50>
              <DivRightSeparator />
              <Div50>
                <DivRow>
                  <DivLeft>Pricing Plan</DivLeft>
                  <DivRight>{pricingPlan?.displayName || '-'}</DivRight>
                </DivRow>
              </Div50>
            </SectionWrap>
            <DivBottomSeparator />
            <SectionWrap>
              <Div50>
                <FieldGroupTitle>Transaction Amount</FieldGroupTitle>
                <DivRow>
                  <DivLeft> Card Not Present</DivLeft>
                  <DivRight>
                    {registration?.plan_txamnt
                      ? formatCurrency(registration?.plan_txamnt)
                      : '-'}
                  </DivRight>
                </DivRow>
                <DivRow>
                  <DivLeft> Card Present</DivLeft>
                  <DivRight>
                    {registration?.cp_per_item_rate
                      ? formatCurrency(registration?.cp_per_item_rate)
                      : '-'}
                  </DivRight>
                </DivRow>
                <DivRow>
                  <DivLeft> Card Not Present - AMEX</DivLeft>
                  <DivRight>
                    {registration?.amex_mid_trans_fee
                      ? formatCurrency(registration?.amex_mid_trans_fee)
                      : '-'}
                  </DivRight>
                </DivRow>
                <DivRow>
                  <DivLeft> Card Present - AMEX</DivLeft>
                  <DivRight>
                    {registration?.cp_amex_per_item_rate
                      ? formatCurrency(registration?.cp_amex_per_item_rate)
                      : '-'}
                  </DivRight>
                </DivRow>
                <DivRow>
                  <DivLeft> Card Not Present - Debit</DivLeft>
                  <DivRight>
                    {registration?.cnp_debit_trans_fee
                      ? formatCurrency(registration?.cnp_debit_trans_fee)
                      : '-'}
                  </DivRight>
                </DivRow>
                <DivRow>
                  <DivLeft> Card Present - Debit</DivLeft>
                  <DivRight>
                    {registration?.cp_debit_trans_fee
                      ? formatCurrency(registration?.cp_debit_trans_fee)
                      : '-'}
                  </DivRight>
                </DivRow>
                <DivRow>
                  <DivLeft> Plan ACH</DivLeft>
                  <DivRight>
                    {registration?.plan_ach_txamnt
                      ? formatCurrency(registration?.plan_ach_txamnt)
                      : '-'}
                  </DivRight>
                </DivRow>
              </Div50>
              <DivRightSeparator />
              <Div50>
                <FieldGroupTitle>Discount Amount</FieldGroupTitle>
                <DivRow>
                  <DivLeft> Card Not Present</DivLeft>
                  <DivRight>
                    {registration?.plan_dcamnt
                      ? `${registration?.plan_dcamnt}%`
                      : '-'}
                  </DivRight>
                </DivRow>
                <DivRow>
                  <DivLeft> Card Present</DivLeft>
                  <DivRight>
                    {registration?.cp_transaction_rate
                      ? `${registration?.cp_transaction_rate}%`
                      : '-'}
                  </DivRight>
                </DivRow>
                <DivRow>
                  <DivLeft> Card Not Present - AMEX</DivLeft>
                  <DivRight>
                    {registration?.amex_mid_disc_rate
                      ? `${registration?.amex_mid_disc_rate}%`
                      : '-'}
                  </DivRight>
                </DivRow>
                <DivRow>
                  <DivLeft> Card Present - AMEX</DivLeft>
                  <DivRight>
                    {registration?.cp_amex_rate
                      ? `${registration?.cp_amex_rate}%`
                      : '-'}
                  </DivRight>
                </DivRow>
                <DivRow>
                  <DivLeft> Card Not Present - Debit</DivLeft>
                  <DivRight>
                    {registration?.cnp_debit_discount_rate
                      ? `${registration?.cnp_debit_discount_rate}%`
                      : '-'}
                  </DivRight>
                </DivRow>
                <DivRow>
                  <DivLeft> Card Present - Debit</DivLeft>
                  <DivRight>
                    {registration?.cp_debit_discount_rate
                      ? `${registration?.cp_debit_discount_rate}%`
                      : '-'}
                  </DivRight>
                </DivRow>
                <DivRow>
                  <DivLeft> Plan ACH</DivLeft>
                  <DivRight>
                    {registration?.plan_ach_dcamnt
                      ? `${registration?.plan_ach_dcamnt}%`
                      : '-'}
                  </DivRight>
                </DivRow>
              </Div50>
            </SectionWrap>
            <DivBottomSeparator />
            <SectionWrap>
              <Div50>
                <DivRow>
                  <DivLeft>Plan ACH Capped Fee</DivLeft>
                  <DivRight>
                    {registration?.plan_ach_cap
                      ? formatCurrency(registration?.plan_ach_cap)
                      : '-'}
                  </DivRight>
                </DivRow>
              </Div50>
              <DivRightSeparator />
              <Div50></Div50>
            </SectionWrap>
            <DivBottomSeparator />
            <SectionWrap>
              <Div50>
                <DivRow>
                  <DivLeft>Monthly Minimum Fee</DivLeft>
                  <DivRight>
                    {registration?.monthly_minimum_fee
                      ? formatCurrency(registration?.monthly_minimum_fee)
                      : '-'}
                  </DivRight>
                </DivRow>
              </Div50>
              <DivRightSeparator />
              <Div50>
                <DivRow>
                  <DivLeft>Monthly Service Fee</DivLeft>
                  <DivRight>
                    {registration?.monthly_service_fee
                      ? formatCurrency(registration?.monthly_service_fee)
                      : '-'}
                  </DivRight>
                </DivRow>
              </Div50>
            </SectionWrap>
            <DivBottomSeparator />
            <FieldGroupTitle>Surcharge</FieldGroupTitle>
            <SectionWrap>
              <Div50>
                <DivRow>
                  <DivLeft>Card Present</DivLeft>
                  <DivRight>
                    {registration?.cp_credit_surcharge_rate
                      ? formatPercent(+registration?.cp_credit_surcharge_rate)
                      : '-'}
                  </DivRight>
                </DivRow>
              </Div50>
              <DivRightSeparator />
              <Div50>
                <DivRow>
                  <DivLeft>Card Not Present</DivLeft>
                  <DivRight>
                    {registration?.cnp_credit_surcharge_rate
                      ? formatPercent(+registration?.cnp_credit_surcharge_rate)
                      : '-'}
                  </DivRight>
                </DivRow>
              </Div50>
            </SectionWrap>
            <DivBottomSeparator />
            <SectionWrap id="sectionWrap">
              <Div33>
                <DivRow id="divRow">
                  <DivLeft id="divLeft">
                    <span>Flat Rate</span>
                  </DivLeft>
                  <DivRight id="divLeft">
                    <span>{registration?.is_flat_rate ? 'Yes' : 'No'}</span>
                  </DivRight>
                </DivRow>
                <DivRow>
                  <DivLeft>
                    <span>Business Type</span>
                  </DivLeft>
                  <DivRight>
                    <span>{businessTypeName || '-'}</span>
                  </DivRight>
                </DivRow>
              </Div33>
              <DivRightSeparator />
              <Div33>
                <DivRow>
                  <DivLeft>
                    <span>MCC</span>{' '}
                  </DivLeft>
                  <DivRight>
                    <span>{registration?.mcc || '-'}</span>
                  </DivRight>
                </DivRow>
                <DivRow>
                  <DivLeft>
                    <span>Network</span>{' '}
                  </DivLeft>
                  <DivRight>
                    <span>{networkName || '-'}</span>
                  </DivRight>
                </DivRow>
              </Div33>
              <DivRightSeparator />
              <Column>
                <span>Proc Change</span>
                <span>{registration?.proc_change || '-'}</span>
              </Column>
            </SectionWrap>
          </DivContent>
        );
      }}
    </UnderwritingSection>
  );
}
