import React, { FunctionComponent, useState, useContext } from 'react';
import { DB } from '@fattmerchantorg/types-engine';
import { TransactionProfile } from './transaction/TransactionProfile';
import { useLocation } from 'react-router-dom';
import { parse } from '../../util/api.util';
import { SelectedMerchantStore } from '../../context';
import { RecurringFee } from './recurring-fee/RecurringFee';
import { useAsyncEffect, useAuthToken } from '../../hooks';
import * as billingProfileUtil from './util/billingProfile.util';
import get from 'lodash/get';
import { DisputeFee } from './dispute-fee/DisputeFee';
import { AchRejectionFee } from './ach-rejection-fee/AchRejectionFee';

interface AddBillingProfileProps {
  type: DB.BillingType;
  edit_billing_id: string;
}

/**
 * Different billing profile types will have different fields associated with
 * them. This component just serves as a "router" to the appropriate profile UI
 * based upon the provided profile type in the URL. Normally, we could just do
 * that with react router but this is here in case we need to introduce some
 * extra logic before loading the profile edit UI.
 */
export const AddBillingProfile: FunctionComponent<
  AddBillingProfileProps
> = props => {
  const authToken = useAuthToken();
  const { search } = useLocation();
  const urlParams = parse(search);
  const { state } = useContext(SelectedMerchantStore);
  const engineCompanyId = get(state?.registration, 'external_company_id', null);
  const [billingProfileTransaction, setBillingProfileTransaction] =
    useState(null);
  const type = urlParams.type;
  const editBillingId = urlParams.edit_billing_id;

  if (Array.isArray(type) || Array.isArray(editBillingId)) {
    throw new Error('Multiple type arguments not supported');
  }
  useAsyncEffect(async () => {
    if (!authToken) return;
    let billingType = 'TRANSACTION';

    if (type === 'Recurring Fee') {
      billingType = 'RECURRING';
    }
    if (type === 'Dispute Fee') {
      billingType = 'DISPUTE';
    }
    if (type === 'Ach Reject Fee') {
      billingType = 'ACHREJECT';
    }

    const billingProfile =
      await billingProfileUtil.getBillingProfileByCompanyIdAndTypeMethods(
        authToken,
        engineCompanyId,
        billingType
      );
    setBillingProfileTransaction(billingProfile);
  }, [authToken]);

  const getProfileUI = (type: string, editBillingId: string): JSX.Element => {
    switch (type.toUpperCase()) {
      case 'TRANSACTION PROFILE':
        return (
          billingProfileTransaction !== null && (
            <TransactionProfile
              initialValues={{ channel: null, subChannel: null }}
              isFirstProfile={
                billingProfileTransaction?.length < 1 ||
                billingProfileTransaction === false
              }
              editBillingId={editBillingId}
            />
          )
        );

      case 'RECURRING FEE':
        return (
          billingProfileTransaction !== null && (
            <RecurringFee
              isFirstProfile={
                billingProfileTransaction?.length < 1 ||
                billingProfileTransaction === false
              }
              editBillingId={editBillingId}
            />
          )
        );
      case 'DISPUTE FEE':
        return (
          billingProfileTransaction !== null && (
            <DisputeFee
              isFirstProfile={
                billingProfileTransaction?.length < 1 ||
                billingProfileTransaction === false
              }
              editBillingId={editBillingId}
            />
          )
        );
      case 'ACH REJECT FEE':
        return (
          billingProfileTransaction !== null && (
            <AchRejectionFee
              isFirstProfile={
                billingProfileTransaction?.length < 1 ||
                billingProfileTransaction === false
              }
              editBillingId={editBillingId}
            />
          )
        );

      default:
        throw new Error('Invalid billing profile type prop.');
    }
  };
  return getProfileUI(type, editBillingId);
};
