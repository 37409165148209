import React from 'react';
import { CustomerDetailsSectionData } from '../AdvancedDetailDrawer.types';
import { Accordion } from '@fattmerchantorg/truffle-components';
import styled from 'styled-components';
import { DB } from '@fattmerchantorg/types-engine';
import get from 'lodash/get';
import { Text, Tooltip, Icon } from '@fattmerchantorg/truffle-components';
import { formatPhoneNumber, formatTruncatedName } from '../../../../util';
import { AccordionWrapper } from '../sharedComponents';

type CustomerDetailsSectionProps = {
  id: string;
  name: string;
  data: CustomerDetailsSectionData;
};

const CustomerDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-flow: column;
  height: 95px;
  font-size: 14px;
  > div {
    max-width: 195px;
  }
`;

const CustomerID = styled.div`
  flex-grow: 2;
  border-right: 1px solid #435e70;
  padding-left: 3px;
  padding-right: 5px;
  white-space: nowrap;
`;

const CustomerInfo = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 8px;
  span {
    padding-right: 6px;
  }
  display: flex;
`;

const CustomerLocation = styled.div`
  flex-grow: 1;
  border-right: 1px solid #435e70;
  padding-left: 10px;
  white-space: nowrap;
  padding-right: 10px;
`;

const CompanyIconSpan = styled.span`
  font-size: 13px;
  padding-right: 6px;
  margin-left: -3px;
`;

const CompanyTitle = styled.span`
  margin-left: 2px;
`;
const PaymentMethod = styled.div`
  flex-grow: 1;
  padding-left: 10px;
`;

const Method = styled.div`
  padding-bottom: 8px;
`;

const CardInfo = styled.div`
  display: flex;
`;

const StyledCardLogo = styled.img`
  width: 34px;
  margin-right: 10px;
`;

const CenteredCell = styled.div`
  white-space: nowrap;
  padding-right: 3%;

  img {
    width: 34px;
    height: 24px;
  }
`;

const BoldText = styled(Text)`
  color: ${({ theme, state }) => get(theme, state)};
  font-size: ${({ as }) => (as === 'h4' ? '1.5rem' : undefined)};
  font-weight: 700;
  line-height: 14px;
  text-align: right;
`;

const CustomerDetailsSection: React.FunctionComponent<
  CustomerDetailsSectionProps
> = props => {
  const {
    data,
    name = 'Customer Details',
    id = 'customer-details-drawer-section',
  } = props;
  if (!data) return null;

  const customerName =
    (data.firstName && data.lastName) ||
    (data.firstName !== '' && data.lastName !== '')
      ? `${data.firstName} ${data.lastName}`
      : '--';

  const customerPhone =
    data.phone || data.phone !== '' ? formatPhoneNumber(data.phone) : '--';

  const customerAddress = () => {
    return !data?.address1 &&
      !data?.address2 &&
      !data?.addressCity &&
      !data?.addressState &&
      !data?.addressZip ? (
      <Text as="span">{`--`}</Text>
    ) : (
      <div>
        {(data?.address1 || data?.address2) && (
          <Text as="span">
            {data?.address1}
            <br />
            {data?.address2 && (
              <>
                {data?.address2}
                <br />
              </>
            )}
          </Text>
        )}
        {(data?.addressCity || data?.addressState) && (
          <Text as="span">
            {data?.addressCity} {data?.addressState} <br />
          </Text>
        )}
        {data?.addressZip && <Text as="span">{data?.addressZip || `--`}</Text>}
      </div>
    );
  };
  const tooltipDisabled = content => {
    return content < 23;
  };

  const formatExp = exp => {
    return `${exp.slice(0, 2)}/${exp.slice(4, 6)}`;
  };

  const generateCardCell = (
    cardType: DB.PaymentMethod['card_brand'],
    lastFour: DB.PaymentMethod['last_four'],
    methodType: DB.PaymentMethod['method_type'],
    binType: String
  ) => {
    const cardTypesToImagePaths = {
      visa: require('../../../../assets/img/icons/cardbrands/visa.svg'),
      americanexpress: require('../../../../assets/img/icons/cardbrands/amex.svg'),
      discover: require('../../../../assets/img/icons/cardbrands/discover.svg'),
      mastercard: require('../../../../assets/img/icons/cardbrands/mastercard.svg'),
      bank: require('../../../../assets/img/icons/bankimages/bank.svg'),
    };

    const cardTypeHasImage = cardTypesToImagePaths.hasOwnProperty(
      cardType === null ? methodType.toLowerCase() : cardType.toLowerCase()
    );
    if ((!cardType && !methodType) || !cardTypeHasImage) {
      return (
        // fallback if no payment method is found
        <CenteredCell>
          <BoldText style={{ paddingLeft: '10px' }} as={'span'}>
            --
          </BoldText>
          <span>{lastFour}</span>
        </CenteredCell>
      );
    }

    return (
      <CenteredCell>
        <div style={{ float: 'left' }}>
          <Tooltip
            content={
              cardType === null
                ? methodType.toUpperCase()
                : cardType.toUpperCase()
            }
          >
            <StyledCardLogo
              src={
                cardTypesToImagePaths[
                  cardType === null
                    ? methodType.toLowerCase()
                    : cardType.toLowerCase()
                ]
              }
            />
          </Tooltip>
        </div>
        <div>
          <span style={{ fontWeight: 'bold' }}>{binType?.toUpperCase()}</span>
          {` **${lastFour}`}
          <div>
            {data.payment_method.expiration ||
            data.payment_method.expiration !== ''
              ? `Exp: ${formatExp(data.payment_method.expiration)}`
              : ''}
          </div>
        </div>
      </CenteredCell>
    );
  };

  return (
    <AccordionWrapper id={id}>
      {/** Placeholder */}
      <Accordion title={name} asyncContent={true}>
        <CustomerDetailsWrapper>
          <CustomerID>
            <CustomerInfo>
              <Tooltip
                content={customerName}
                disabled={tooltipDisabled(customerName.length)}
              >
                <>
                  <span>
                    <Icon id="nameIcon" icon={['fas', 'user']} />
                  </span>
                  {customerName}
                </>
              </Tooltip>
            </CustomerInfo>

            <CustomerInfo>
              <Tooltip
                content={customerPhone}
                disabled={tooltipDisabled(customerPhone.length)}
              >
                <>
                  <span>
                    <Icon id="phoneIcon" icon={['fas', 'phone']} />
                  </span>
                  {customerPhone}
                </>
              </Tooltip>
            </CustomerInfo>
            <CustomerInfo>
              <Tooltip
                content={data.email}
                disabled={tooltipDisabled(data.email.length)}
              >
                <>
                  <span>
                    <Icon id="emailIcon" icon={['fas', 'envelope']} />
                  </span>
                  {data.email || data.email !== '' ? data.email : '--'}
                </>
              </Tooltip>
            </CustomerInfo>
          </CustomerID>
          <CustomerLocation>
            <CustomerInfo>
              <CompanyIconSpan>
                <Icon id="companyIcon" icon={['fas', 'store']} />
              </CompanyIconSpan>
              <Tooltip
                content={data.company}
                disabled={tooltipDisabled(data.company.length)}
              >
                <CompanyTitle>
                  {data.company ? formatTruncatedName(data.company) : '--'}
                </CompanyTitle>
              </Tooltip>
            </CustomerInfo>
            <CustomerInfo>
              <span>
                <Icon id="addressIcon" icon={['fas', 'map-marker-alt']} />
              </span>
              <Tooltip
                content={customerAddress()}
                disabled={
                  tooltipDisabled(data?.address1?.length) &&
                  tooltipDisabled(data?.address2?.length) &&
                  tooltipDisabled(data?.addressCity?.length)
                }
              >
                {customerAddress()}
              </Tooltip>
            </CustomerInfo>
          </CustomerLocation>
          <PaymentMethod>
            <Method>{data?.source || 'Virtual Terminal'}</Method>
            <CardInfo>
              <div>
                {generateCardCell(
                  data.payment_method.card_type as DB.CardBrand,
                  data.payment_method.lastFour,
                  'CARD',
                  data.payment_method.bin_type
                )}
              </div>
            </CardInfo>
          </PaymentMethod>
        </CustomerDetailsWrapper>
      </Accordion>
    </AccordionWrapper>
  );
};

export default CustomerDetailsSection;
