export enum UnderwritingStatus {
  APPROVED = 'APPROVED',
  PENDED = 'PENDED',
  REJECTED = 'REJECTED',
}

export enum ApprovedReasons {
  APPROVED_FULL_BALANCE_HOLD = 'Full Balance Hold',
  APPROVED_SIGNER_VERIFICATION = 'Signer Verification',
  APPROVED_BANKING_VERIFICATION = 'Banking Verification',
  APPROVED_WEBSITE_COMPLIANCE = 'Website Compliance',
}

export enum PendedReasons {
  PENDED_SIGNER_VERIFICATION = 'Signer Verification',
  PENDED_BUSINESS_LOCATION = 'Business Location',
  PENDED_VOLUME_CLARITY = 'Volume Clarity',
  PENDED_TIN_MISMATCH = 'TIN Mismatch',
  PENDED_PREVIOUS_PROCESSING_HISTORY_CREDIT = 'Previous Processing History / Credit',
  PENDED_MARKETING_MATERIAL = 'Marketing Material',
  PENDED_DEPOSIT_POLICY = 'Deposit Policy',
  PENDED_FINANCIALS_BANKING = 'Financials/Banking',
  PENDED_BANKING_VERIFICATION = 'Banking Verification',
}

export enum RejectedReasons {
  REJECTED_PROHIBITED_BUSINESS = 'Prohibited Business',
  REJECTED_PROHIBITED_PRODUCTS = 'Prohibited Products',
  REJECTED_BUSINESS_VERIFICATION = 'Business Verification',
  REJECTED_SIGNER_VERIFICATION = 'Signer Verification',
  REJECTED_FRAUD = 'Fraud',
  REJECTED_INSUFFICIENT_CREDIT = 'Insufficient Credit',
  REJECTED_INSUFFICIENT_FINANCIAL_BACKING = 'Insufficient Financial Backing',
  REJECTED_MATCH = 'Match',
  REJECTED_EXPIRED = 'Expired',
}

export enum LitleFinixConfigurations {
  LITLE_CNP = 'Litle_CNP',
  LITLE_ACH = 'Litle_ACH',
  LITLE_CNP_ACH = 'Litle_CNP_ACH',
}

export enum CoreFinixConfigurations {
  CORE_CNP = 'Core_CNP',
  CORE_CP = 'Core_CP',
  CORE_CNP_CP = 'Core_CNP_CP',
}

export enum FinixConfigurationsDictionary {
  LITLE_CNP = 'CNP',
  LITLE_ACH = 'ACH',
  LITLE_CNP_ACH = 'CNP + ACH',
  CORE_CP = 'CP',
  CORE_CNP = 'CNP',
  CORE_CNP_CP = 'CNP + CP',
}

export type UnderwritingSubStatus =
  | PendedReasons
  | RejectedReasons
  | ApprovedReasons;
