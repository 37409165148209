import React from 'react';
import { Field, FormRenderProps, FieldRenderProps } from 'react-final-form';
import styled, { withTheme } from 'styled-components';
import {
  Icon,
  TextField,
  Select,
  Tooltip,
  Checkbox,
  RedactedField,
} from '@fattmerchantorg/truffle-components';
import { BankAccountFormValues } from './BankAccountForm.types';
import { FieldError } from '../../../../shared';
import { FundingAccount, Registration } from '@fattmerchantorg/types-omni';
import { useFeatureFlag } from '../../../../../hooks';

type BankAccountFormFieldsProps = FormRenderProps<BankAccountFormValues> & {
  account?: FundingAccount;
  registration: Registration | null;
  numberOfActiveFeeAccounts: number;
};

const FieldDiv = withTheme(styled.div`
  max-width: 240px;
  svg {
    color: ${({ theme }) => theme.colors.core.green[500].hex} !important;
  }
`);

const AccountNumberFieldDiv = withTheme(styled.div`
  max-width: 240px;
`);

const TooltipWrapper = withTheme(styled.div`
  margin-left: 6px;
  margin-bottom: 0.2rem;
`);

const TooltipIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.core.gray[200].hex};
`;

const CheckboxWrapper = withTheme(styled.div`
  display: flex;
  align-items: center;
`);

type AccountTypeOption = {
  label: string;
  value: FundingAccount['flags'];
};

const accountTypeOptions: AccountTypeOption[] = [
  {
    label: 'Deposit & Fees Account',
    value: 'FEE',
  },
  {
    label: 'Trust Account',
    value: 'IS_TRUST',
  },
  {
    label: 'Deposit Account',
    value: '',
  },
];

const getFieldIdProps = (
  fieldProps: FieldRenderProps<any, HTMLElement>,
  prefix: string
) => {
  const id = `${prefix}-${fieldProps.input.name}-field`;
  return { id, 'data-testid': id };
};

export const BankAccountFormFields: React.FunctionComponent<
  BankAccountFormFieldsProps
> = props => {
  const accountId = props.values?.id;
  const nodeIdPrefix = accountId ?? 'new';
  const { registration, account } = props;

  const { available: hasSplitFunding } = useFeatureFlag(
    'Funding',
    'SplitFunding'
  );

  // Remove IS_TRUST option when mcc is NOT equal to '8111'.
  const onFilter = (option: AccountTypeOption) => {
    if (registration?.mcc !== '8111') {
      if (option.value === 'IS_TRUST') {
        return false;
      }
    }

    // When adding a bank account, remove the Deposit & Fee Account option.
    if (!accountId && option.value === 'FEE') {
      return false;
    }

    // Remove Deposit & Fee account type.
    if (
      hasSplitFunding &&
      account?.status !== 'APPROVED' &&
      option.value === 'FEE'
    ) {
      return false;
    }

    return true;
  };

  return (
    <>
      <FieldDiv>
        <Field name="name">
          {fieldProps => (
            <TextField
              {...fieldProps.input}
              {...getFieldIdProps(fieldProps, nodeIdPrefix)}
              type="text"
              label="Nickname"
              placeholder="Account Nickname"
            />
          )}
        </Field>
        <FieldError name="name" />
      </FieldDiv>
      {accountId ? null : (
        // only render person_name if this is a new account.
        <FieldDiv>
          <Field name="person_name" required>
            {fieldProps => (
              <TextField
                {...fieldProps.input}
                {...getFieldIdProps(fieldProps, nodeIdPrefix)}
                type="text"
                label="Account Holder Name"
                placeholder="John Doe"
                required
              />
            )}
          </Field>
          <FieldError name="person_name" />
        </FieldDiv>
      )}

      {accountId ? null : (
        // only render bank_routing_number and bank_account_number fields if this is a new account
        <>
          <FieldDiv>
            <Field
              name="bank_routing_number"
              required
              format={value => value?.toString()?.replace(/\D+/g, '')}
            >
              {fieldProps => (
                <TextField
                  {...fieldProps.input}
                  {...getFieldIdProps(fieldProps, nodeIdPrefix)}
                  label="Routing Number"
                  placeholder="0000000000"
                  maxLength={9}
                  required
                />
              )}
            </Field>
            <FieldError name="bank_routing_number" />
          </FieldDiv>
          <AccountNumberFieldDiv>
            <Field
              name="bank_account_number"
              required
              format={value => value?.toString()?.replace(/\D+/g, '')}
            >
              {fieldProps => (
                <RedactedField
                  {...fieldProps.input}
                  {...getFieldIdProps(fieldProps, nodeIdPrefix)}
                  permitViewValue
                  showLastFour
                  // @ts-ignore TODO: add label to RedactedField prop types as it is actually a valid prop
                  label="Account Number"
                  placeholder="******0000"
                  required
                  number
                />
              )}
            </Field>
            <FieldError name="bank_account_number" />
          </AccountNumberFieldDiv>
        </>
      )}

      {account?.status === 'APPROVED' ||
      (!accountId && registration?.mcc === '8111') ? (
        <FieldDiv>
          <div className="ui-select-arrow">
            <Field name="flags" required>
              {fieldProps => (
                <Select
                  {...getFieldIdProps(fieldProps, nodeIdPrefix)}
                  label="Account Type"
                  required
                  isClearable={false}
                  value={accountTypeOptions.find(o =>
                    fieldProps.input.value.includes(o.value)
                  )}
                  onChange={option => fieldProps.input.onChange(option.value)}
                  options={
                    account?.flags?.includes('IS_TRUST')
                      ? accountTypeOptions?.filter(
                          option => option?.value !== 'FEE'
                        )
                      : accountTypeOptions
                  }
                  // If this is a FEE account, disable this field since there always has to be a FEE account
                  isDisabled={account?.flags?.includes('FEE')}
                  filterOption={onFilter}
                />
              )}
            </Field>
          </div>
          <FieldError name="flags" />
        </FieldDiv>
      ) : null}

      {/* // add message about primary account */}
      {account?.status === 'APPROVED' ? (
        <CheckboxWrapper>
          <Field name="isPrimaryAccount" type="checkbox">
            {fieldProps => (
              <Checkbox
                {...getFieldIdProps(fieldProps, nodeIdPrefix)}
                label="Make this the Primary Account"
                checked={fieldProps.input.checked}
                onChange={fieldProps.input.onChange}
                // If this is a PRIMARY account, disable this field since there always has to be a PRIMARY account.
                // Disable field when this is a TRUST account because a TRUST account cannot be set as the PRIMARY account.
                disabled={
                  props?.account?.flags?.includes('PRIMARY') ||
                  props?.account?.flags?.includes('IS_TRUST')
                }
              />
            )}
          </Field>
          <TooltipWrapper>
            <Tooltip
              content={'Make this account selected by default.'}
              placement="top"
            >
              <TooltipIcon icon={['far', 'info-circle']} />
            </Tooltip>
          </TooltipWrapper>
        </CheckboxWrapper>
      ) : null}
    </>
  );
};
