import * as React from 'react';
import { Helmet } from 'react-helmet';

const StatusPageIO = () => {
  const domain = (window.location.origin || '').toLowerCase();
  const isQa = domain.includes('qa-') || domain.includes('qabuilds'); // return true if 'qa or qabuilds' is in url

  if (isQa) return null;

  return (
    <Helmet>
      <script
        src="https://78h9382y4ksn.statuspage.io/embed/script.js"
        async
      ></script>
    </Helmet>
  );
};

export default StatusPageIO;
