import {
  BaseModal,
  PrimaryButton,
  SecondaryButton,
} from '@fattmerchantorg/truffle-components';
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Form as FinalForm } from 'react-final-form';
import { AllBrandsResponse, permissionsapi } from '../../../api';
import { PermissionsStore, SelectedMerchantStore } from '../../../context';
import { useAuthToken, usePermissions, useToaster } from '../../../hooks';
import {
  modalWidthClass,
  StyledContent,
  StyledHeader,
  StyledModalFooter,
} from '../SettingsPagesStylings';
import {
  buildRolePermissionsFromForm,
  RoleFormFields,
  RoleFormOption,
  RoleFormState,
} from './RoleFormFields';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  fetchRoles?: () => void;
}

export const AddRoleModal: FC<Props> = ({ isOpen, onClose, fetchRoles }) => {
  const {
    state: { selectedBrandSwitcherOption },
  } = useContext(SelectedMerchantStore);
  const {
    state: { brands: userBrands },
  } = useContext(PermissionsStore);
  const { toast, toaster } = useToaster();
  const authToken = useAuthToken();
  const { permit } = usePermissions();

  const [brandOptions, setBrandOptions] = useState<RoleFormOption[]>(() =>
    userBrands?.map(brandItem => ({
      label: brandItem.displayName,
      value: brandItem.name,
    }))
  );

  const canSeeAllBrands = permit('godview', 'canSeeAllBrands', 'write');

  const fetchAllBrands = useCallback(async () => {
    try {
      const allBrandsOptions: AllBrandsResponse = await permissionsapi.get(
        authToken,
        `/allBrands`
      );
      setBrandOptions(
        allBrandsOptions.map(br => ({
          label: br.displayName,
          value: br.name,
        }))
      );
    } catch (error) {
      toaster(
        toast.error(
          error,
          'There was a problem fetching all brands information.'
        )
      );
    }
  }, [authToken, toast, toaster]);

  useEffect(() => {
    if (canSeeAllBrands) {
      fetchAllBrands();
    }
  }, [canSeeAllBrands, fetchAllBrands]);

  const initialFormValues = useMemo(() => {
    return {
      roleBrand: brandOptions.find(
        brandOption => brandOption.value === selectedBrandSwitcherOption
      ),
    };
  }, [brandOptions, selectedBrandSwitcherOption]);

  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = useCallback(
    async (formValues: RoleFormState) => {
      if (!formValues) return;
      setIsSaving(true);

      try {
        const { name, description, roleBrand, permissions } = formValues;

        const newRolePermissions = buildRolePermissionsFromForm(permissions);

        const newRole = {
          role_name: name,
          description,
          permissions: newRolePermissions,
        };

        await permissionsapi.post(
          authToken,
          `/roles/${roleBrand.value}`,
          newRole
        );

        toaster(toast.success('Role successfully created', 'Saved'));
        if (fetchRoles) fetchRoles();

        onClose();
      } catch (error) {
        toaster(toast.error(error, `There was a problem creating the user.`));
      } finally {
        setIsSaving(false);
      }
    },
    [authToken, onClose, fetchRoles, toast, toaster]
  );

  return (
    <BaseModal
      style={modalWidthClass}
      title="Add Team Member"
      onClose={() => {
        onClose();
      }}
      isOpen={isOpen}
    >
      <StyledHeader title={'Add Role'} onClose={() => onClose()} />

      <StyledContent>
        <FinalForm<RoleFormState>
          onSubmit={handleSubmit}
          initialValues={initialFormValues}
          validate={values => {
            const isAtLeastOneValueSelected =
              values?.permissions &&
              Object.entries(values?.permissions).some(
                ([key, value]) => value.checked
              );
            return isAtLeastOneValueSelected
              ? undefined
              : { permissions: 'At least one permission should be selected' };
          }}
        >
          {({ handleSubmit, form, hasValidationErrors, values }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <RoleFormFields
                  brandOptions={brandOptions}
                  formValues={values}
                />

                <StyledModalFooter>
                  <SecondaryButton
                    onClick={() => {
                      onClose();
                      form.reset();
                    }}
                  >
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    type="submit"
                    disabled={hasValidationErrors}
                    loading={isSaving}
                  >
                    Add
                  </PrimaryButton>
                </StyledModalFooter>
              </form>
            );
          }}
        </FinalForm>
      </StyledContent>
    </BaseModal>
  );
};
