import React, {
  useContext,
  useState,
  useCallback,
  FunctionComponent,
} from 'react';
import { staxPay } from '@fattmerchantorg/truffle-tokens';
import { Merchant } from '@fattmerchantorg/types-omni';
import { Select } from '@fattmerchantorg/truffle-components';
import { ThemeProvider } from 'styled-components';

import SubTitle from './SubTitle';
import { Button } from '../../../shared';
import {
  SelectedMerchantStore,
  alterSelectedMerchant,
} from '../../../../context';
import { usePermissions, useToaster, useAuthToken } from '../../../../hooks';
import { coreapi } from '../../../../api';

const accountStatusOptions = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'PENDING', label: 'Pending' },
  { value: 'INACTIVE', label: 'Inactive' },
];

export const AccountStatus: FunctionComponent<{
  accountStatus: string;
  merchantId: string;
}> = props => {
  const authToken = useAuthToken();
  const { dispatch } = useContext(SelectedMerchantStore);
  const { toast, toaster } = useToaster();
  const { permit } = usePermissions();
  const [state, setState] = useState<'idle' | 'editing' | 'saving'>('idle');
  const [selectedStatus, setSelectedStatus] = useState(props.accountStatus);

  const handleSaveStatus = useCallback(async () => {
    try {
      const payload = { status: selectedStatus };
      const merchant = await coreapi.put<Merchant>(
        authToken,
        `merchant/${props.merchantId}`,
        payload
      );
      dispatch(alterSelectedMerchant(merchant));
    } catch (error) {
      toaster(toast.error(error));
    } finally {
      setState('idle');
    }
  }, [authToken, dispatch, props.merchantId, selectedStatus, toast, toaster]);

  return state === 'idle' ? (
    <>
      <SubTitle>
        <span style={{ marginRight: '8px' }}>Account Status</span>
        {permit('godview', 'status', 'write') ? (
          <Button variant="link" onClick={() => setState('editing')}>
            <i className="fas fa-edit" />
          </Button>
        ) : null}
      </SubTitle>

      <span>{props.accountStatus}</span>
    </>
  ) : (
    <>
      <SubTitle style={{ justifyContent: 'space-between' }}>
        <span style={{ marginRight: '8px' }}>Account Status</span>
        <Button
          variant="link"
          disabled={state === 'saving'}
          onClick={() => {
            if (state !== 'saving') {
              handleSaveStatus();
            }
          }}
        >
          <i className="fas fa-save" style={{ marginRight: '4px' }} />
          <u>Save</u>
        </Button>
      </SubTitle>
      {/* according to the Figma, this one select should have a staxPay theme */}
      <ThemeProvider theme={staxPay}>
        <Select
          disabled={state === 'saving'}
          styles={{
            option: base => ({
              ...base,
              // these specific styles from the staxPay theme are being overriden
              // by the default staxConnect theme that the rest of this app has
              // so over-override them
              color: '#062333 !important',
              ':active': { backgroundColor: '#F2F2F2 !important' },
              ':hover': { backgroundColor: '#F2F2F2 !important' },
            }),
          }}
          options={accountStatusOptions}
          defaultValue={accountStatusOptions.find(
            o => o.value === props.accountStatus
          )}
          onChange={option => setSelectedStatus(option.value)}
        />
      </ThemeProvider>
    </>
  );
};
