import { TimeRange } from '../@types/TimeRange';
import { TimespanQueryParams } from '../@types/TimespanQueryParams'

export function setTimespanAndGroupByParams(
  selectedTimerange: TimeRange
): Pick<TimespanQueryParams, 'timespan' | 'groupBy'> {
  switch (selectedTimerange) {
    case 'THIS_YEAR':
    case 'LAST_YEAR':
      return {
        timespan: 'year',
        // Divide data for a single year into month-long slices
        groupBy: 'year_month'
      };
    case 'THIS_MONTH':
    case 'LAST_MONTH':
      return {
        timespan: 'month',
        // Divide data for a single month into day-long slices
        groupBy: 'date'
      };
    case 'THIS_WEEK':
    case 'LAST_WEEK':
      return {
        timespan: 'week',
        // Divide data for a single week into day-long slices
        groupBy: 'date'
      };
    case 'TODAY':
    case 'YESTERDAY':
      return {
        timespan: 'day',
        // Divide data for a single day into hour-long slices
        groupBy: 'date_hour'
      };
  }
}

export function setIntervalAndIsPartialParams(
  selectedTimerange: TimeRange
): Pick<TimespanQueryParams, 'interval' | 'isPartial'> {
  // Set `interval` and `isPartial` params
  switch (selectedTimerange) {
    case 'THIS_YEAR':
    case 'THIS_MONTH':
    case 'THIS_WEEK':
    case 'TODAY':
      return {
        interval: 0,
        isPartial: 1
      };
    case 'LAST_YEAR':
    case 'LAST_MONTH':
    case 'LAST_WEEK':
    case 'YESTERDAY':
      return {
        interval: 1,
        isPartial: 0
      };
  }
}
