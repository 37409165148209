import { useReducer, Dispatch, Reducer, useEffect } from 'react';
import { Transaction } from '@fattmerchantorg/types-omni';
import { AsyncData, AsyncDataStatus } from '../../../@types';
import { useAuthToken, usePrevious, useToaster } from '../../../hooks';
import {
  Action,
  sendFetchSucceeded,
  sendFetchFailed,
  sendTransactionIDChanged,
  sendVoidSucceeded,
  sendVoidFailed,
  sendRefundSucceeded,
  sendRefundFailed,
} from './actions';
import { ActionTypes } from './types';
import { useLocation } from 'react-router-dom';
import { parse } from '../../../util/api.util';
import { coreapi } from '../../../api';

export const TransactionAsyncDataStatus = {
  ...AsyncDataStatus,
  VOIDING: 'VOIDING' as const,
  REFUNDING: 'REFUNDING' as const,
};
export type TransactionAsyncDataStatus =
  keyof typeof TransactionAsyncDataStatus;
export type TransactionAsyncData =
  | AsyncData<Transaction>
  | {
      status: 'VOIDING';
      data: Transaction;
      error?: Error;
    }
  | {
      status: 'REFUNDING';
      data: Transaction;
      error?: Error;
    };

export type TransactionDetailReducerState = TransactionAsyncData & {
  refundTotal?: number;
};
export type TransactionDetailReducerAction = Action;
export type TransactionDetailReducerDispatch =
  Dispatch<TransactionDetailReducerAction>;

const initialState: TransactionDetailReducerState = {
  status: AsyncDataStatus.INITIAL,
  data: null,
  refundTotal: null,
};
/** PrevStatus will be `undefined` after first render. */
type PrevStatus = TransactionAsyncDataStatus | undefined;

async function fetchTransaction(
  getData: (id: string) => Promise<Transaction>,
  detailId: string | string[] | null | undefined,
  dispatch: Dispatch<Action>
) {
  try {
    if (typeof detailId !== 'string' || !detailId) {
      // Abort search if no transaction ID is set in URL
      return;
    }
    if (!getData) {
      // Abort search if data fetch function is not provided.
      return;
    }
    const response = await getData(detailId);
    dispatch(sendFetchSucceeded(response));
  } catch (error) {
    console.error(error);
    dispatch(sendFetchFailed(error));
  }
}

async function voidTransaction(
  authToken: string,
  transactionId: string | string[] | null | undefined,
  dispatch: Dispatch<Action>
) {
  try {
    if (typeof transactionId !== 'string' || !transactionId) {
      // Abort search if no transaction ID is set in URL
      return;
    }
    const response = await coreapi.post<Transaction>(
      authToken,
      `/transaction/${transactionId}/void`
    );
    dispatch(sendVoidSucceeded(response));
  } catch (error) {
    console.error(error);
    dispatch(sendVoidFailed(error));
  }
}

async function refundTransaction(
  authToken: string,
  transactionId: string | string[] | null | undefined,
  refundTotal: number,
  dispatch: Dispatch<Action>
) {
  try {
    if (typeof transactionId !== 'string' || !transactionId) {
      // Abort search if no transaction ID is set in URL
      return;
    }
    const response = await coreapi.post<Transaction>(
      authToken,
      `/transaction/${transactionId}/refund`,
      {
        total: refundTotal,
      }
    );
    dispatch(sendRefundSucceeded(response));
  } catch (error) {
    console.error(error);
    dispatch(sendRefundFailed(error));
  }
}

type TransactionDetailReducer = Reducer<TransactionDetailReducerState, Action>;
/**
 * Reducer for the custom useDetailsModalHook.
 * This reducer is written in finite-state-machine style:
 * If the current status accepts the current action, transition to a new status.
 * https://twitter.com/DavidKPiano/status/1171062893984526336
 */
const reducer: TransactionDetailReducer = (state, action) => {
  switch (state.status) {
    case TransactionAsyncDataStatus.INITIAL:
      switch (action.type) {
        case ActionTypes.FETCH_REQUESTED: {
          return {
            status: TransactionAsyncDataStatus.LOADING,
            data: null,
          };
        }
        default:
          return state;
      }
    case TransactionAsyncDataStatus.IDLE:
      switch (action.type) {
        case ActionTypes.FETCH_REQUESTED: {
          return {
            status: TransactionAsyncDataStatus.LOADING,
            data: null,
          };
        }
        case ActionTypes.VOID_REQUESTED: {
          return {
            status: TransactionAsyncDataStatus.VOIDING,
            data: state.data,
          };
        }
        case ActionTypes.REFUND_REQUESTED: {
          return {
            status: TransactionAsyncDataStatus.REFUNDING,
            data: state.data,
            refundTotal: action.payload,
          };
        }
        default:
          return state;
      }

    case TransactionAsyncDataStatus.ERROR:
      switch (action.type) {
        case ActionTypes.FETCH_REQUESTED:
          return {
            status: TransactionAsyncDataStatus.LOADING,
            data: null,
          };
        default:
          return state;
      }

    case TransactionAsyncDataStatus.LOADING:
      switch (action.type) {
        case ActionTypes.FETCH_SUCCEEDED:
          return {
            status: TransactionAsyncDataStatus.IDLE,
            data: action.payload,
          };

        case ActionTypes.FETCH_FAILED:
          return {
            status: TransactionAsyncDataStatus.ERROR,
            data: null,
            error: action.payload,
          };
        case ActionTypes.FETCH_REQUESTED:
          // intentionally ignore additional fetches while
          // the existing request is in-flight.
          return state;
        default:
          return state;
      }
    case TransactionAsyncDataStatus.REFUNDING:
    case TransactionAsyncDataStatus.VOIDING:
      switch (action.type) {
        case ActionTypes.REFUND_SUCCEEDED:
        case ActionTypes.VOID_SUCCEEDED:
          return {
            status: TransactionAsyncDataStatus.IDLE,
            data: action.payload,
          };
        case ActionTypes.REFUND_FAILED:
        case ActionTypes.VOID_FAILED:
          return {
            status: TransactionAsyncDataStatus.ERROR,
            data: state.data,
            error: action.payload,
          };
        default:
          return state;
      }
  }
};

export default function useDetailsModalHook(
  getData: (id: string) => Promise<Transaction>
): [TransactionDetailReducerState, Dispatch<Action>] {
  const authToken = useAuthToken();
  const { search } = useLocation();
  const { detailId } = parse(search);
  const { toaster, toast } = useToaster();

  const [state, dispatch] = useReducer<TransactionDetailReducer>(
    reducer,
    initialState
  );
  const prevStatus = usePrevious<PrevStatus>(state.status);

  /*
   * Perform side-effects according to status transitions.
   */
  useEffect(() => {
    // Use value of `prevStatus` to ensure side-effects are only performed when status transition occurs.
    switch (true) {
      case prevStatus !== TransactionAsyncDataStatus.LOADING &&
        state.status === TransactionAsyncDataStatus.LOADING:
        fetchTransaction(getData, detailId, dispatch);
        break;
    }
  }, [prevStatus, state.status, getData, detailId, dispatch]);

  useEffect(() => {
    // Use value of `prevStatus` to ensure side-effects are only performed when status transition occurs.
    switch (true) {
      case prevStatus !== TransactionAsyncDataStatus.VOIDING &&
        state.status === TransactionAsyncDataStatus.VOIDING:
        voidTransaction(authToken, detailId, dispatch);
        break;
      case prevStatus !== TransactionAsyncDataStatus.REFUNDING &&
        state.status === TransactionAsyncDataStatus.REFUNDING:
        refundTransaction(authToken, detailId, state.refundTotal, dispatch);
        break;
      default:
        break;
    }
  }, [
    prevStatus,
    state.status,
    state.refundTotal,
    detailId,
    dispatch,
    authToken,
  ]);

  useEffect(() => {
    switch (true) {
      case prevStatus === TransactionAsyncDataStatus.LOADING &&
        state.status === TransactionAsyncDataStatus.ERROR:
        toaster(
          toast.error(
            state.error,
            `There was a problem retrieving the transaction.`
          )
        );
        break;

      case prevStatus === TransactionAsyncDataStatus.VOIDING &&
        state.status === TransactionAsyncDataStatus.IDLE:
        toaster(toast.success(null, `Transaction voided.`));
        break;
      case prevStatus === TransactionAsyncDataStatus.REFUNDING &&
        state.status === TransactionAsyncDataStatus.IDLE:
        toaster(toast.success(null, `Refund issued.`));
        break;
      case prevStatus === TransactionAsyncDataStatus.VOIDING &&
        state.status === TransactionAsyncDataStatus.ERROR:
        toaster(
          toast.error(
            state.error,
            `There was a problem voiding the transaction.`
          )
        );
        break;
      case prevStatus === TransactionAsyncDataStatus.REFUNDING &&
        state.status === TransactionAsyncDataStatus.ERROR:
        const transaction = state.error as any as Transaction;
        const errorMessage =
          // handle transaction error messages from child transactions (such as voids or refunds)
          transaction?.child_transactions?.[0]?.message ??
          // handle transaction error messages
          transaction?.message ??
          // handle all other errors
          state.error;

        toaster(
          toast.error(
            errorMessage,
            `There was a problem refunding the transaction.`
          )
        );
        break;
    }
  }, [prevStatus, state.status, state.error, toast, toaster]);

  /* Watch URL, and dispatch Actions */
  useEffect(() => {
    if (detailId && getData) {
      // Only if detailId is defined, dispatch the `FETCH_REQUESTED` Action
      dispatch(sendTransactionIDChanged());
    }
  }, [detailId, getData]);

  return [state, dispatch];
}
