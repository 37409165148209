import { Registration, Merchant } from '@fattmerchantorg/types-omni';
import { Dispatch, useCallback, useContext, useState } from 'react';
import { coreapi } from '../../../api';
import {
  AuthStore,
  updateSelectedMerchantRegistration,
  updateSelectedMerchant,
  ModalContext,
  closeModal,
} from '../../../context';
import { useToaster } from '../../../hooks';
import { isValidAccountNumber, isValidSSN } from '../../../util/validator.util';

export type PayloadFormatter<T> =
  | ((fields: T) => [Partial<Registration>, Partial<Merchant>])
  | ((fields: T) => Partial<Registration>);

export interface HookProps<T> {
  merchantId: string;
  merchantDispatch: Dispatch<any>;
  payloadFormatter: PayloadFormatter<T>;
  shouldCloseModalOnSubmit?: boolean;
}

export const useUnderwritingFormSubmission = <T,>(props: HookProps<T>) => {
  const {
    merchantId,
    merchantDispatch,
    payloadFormatter,
    shouldCloseModalOnSubmit = true,
  } = props;
  const [isSaving, setIsSaving] = useState(false);
  const { modalDispatch } = useContext(ModalContext);

  const { state: authState } = useContext(AuthStore);
  const { authToken } = authState;
  const { toaster, toast } = useToaster();

  const onSubmit = useCallback(
    async (formValues: T) => {
      if (!formValues) return;
      setIsSaving(true);

      //the payload is a tuple of registration and merchant, where the merchant is optional.
      const formattedPayload = payloadFormatter(formValues);
      const [registrationPayload, merchantPayload]: [
        Partial<Registration>,
        Partial<Merchant> | null
      ] =
        formattedPayload instanceof Array
          ? formattedPayload
          : [formattedPayload, null];

      // validate ssn
      if (
        registrationPayload.hasOwnProperty('user_ssn') &&
        (!registrationPayload.user_ssn ||
          !isValidSSN(registrationPayload.user_ssn))
      ) {
        toaster(
          toast.error(
            'Please provide a valid social security number.',
            'Invalid SSN'
          )
        );
        setIsSaving(false);
        return;
      }

      // validate Account Number
      if (
        registrationPayload.hasOwnProperty('bank_account_number') &&
        (!registrationPayload.bank_account_number ||
          !isValidAccountNumber(registrationPayload.bank_account_number))
      ) {
        toaster(
          toast.error(
            'Please provide valid bank account number.',
            'Invalid Bank Account Number'
          )
        );
        setIsSaving(false);
        return;
      }

      // validate Secondary Account Number
      if (
        registrationPayload.hasOwnProperty('secondary_bank_account_number') &&
        registrationPayload.secondary_bank_account_number &&
        !isValidAccountNumber(registrationPayload.secondary_bank_account_number)
      ) {
        toaster(
          toast.error(
            'Please provide a valid secondary bank account number.',
            'Invalid Bank Account Number'
          )
        );
        setIsSaving(false);
        return;
      }

      const {
        bank_account_number,
        secondary_bank_account_number,
        bank_routing_number,
        secondary_bank_routing_number,
      } = registrationPayload;

      const containBankDetails =
        bank_account_number &&
        secondary_bank_account_number &&
        bank_routing_number &&
        secondary_bank_routing_number;

      // validate secondary bank account information cannot be the same as primary
      if (
        containBankDetails &&
        bank_routing_number.concat(bank_account_number) ===
          secondary_bank_routing_number.concat(secondary_bank_account_number)
      ) {
        toaster(
          toast.error(
            'Secondary Bank Account information cannot be the same as primary',
            'Duplicate Bank Account information'
          )
        );
        setIsSaving(false);
        return;
      }

      try {
        //If there are any changes to the merchant that need to be pushed up to the API
        if (!!merchantPayload) {
          const merchantResponse = await coreapi.put(
            authToken,
            `/merchant/${merchantId}`,
            merchantPayload
          );

          merchantDispatch(updateSelectedMerchant(merchantResponse));
        }

        //Underwriting contains some information from registration, and some information from merchant.
        //We have to update both entities on form submission.
        const registrationResponse = await coreapi.put(
          authToken,
          `/merchant/${merchantId}/registration`,
          registrationPayload
        );

        merchantDispatch(
          updateSelectedMerchantRegistration(registrationResponse)
        );

        toaster(toast.success('Changes successfully saved', 'Saved'));

        if (shouldCloseModalOnSubmit) {
          modalDispatch(closeModal());
        }
      } catch (error) {
        toaster(toast.error(error, 'There was a problem saving your changes.'));
      }
      setIsSaving(false);
    },
    [
      authToken,
      merchantId,
      merchantDispatch,
      toast,
      toaster,
      payloadFormatter,
      shouldCloseModalOnSubmit,
      modalDispatch,
    ]
  );

  return { onSubmit, isSaving };
};
