import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { RegistrationAuditLog } from '@fattmerchantorg/types-omni';
import { ModalContext, SelectedMerchantProvider } from '../../../../context';
import { BaseModal, ModalHeader } from '@fattmerchantorg/truffle-components';
import { CorisVerificationLabel } from './ExternalVerification.types';
import { ExpandablePanel } from '../../../shared';
import { RenderCorisLogDrilldownRecord } from './coris-log-drilldown';
import { OpenCorisPortalButton } from './coris-log-drilldown/coris.util';

const StyledModalContent = styled.div`
  grid-area: content;
  overflow-y: auto;
  padding: ${({ theme }) => theme.space[4]}px;
  color: ${({ theme }) => theme.component.modal.color};

  > * {
    margin: 0 0 10px;
  }
`;

const LogContent = styled.div`
  font-size: 14px;

  ul {
    padding: 0 0 0 8px;
    margin: 0 0 16px 0;
  }

  ul:empty {
    display: none;
  }

  li {
    list-style: none;
    word-wrap: break-word;
  }

  // Styles the "Potential Matches" next sections within the log content
  div > div > ul {
    background: ${({ theme }) => theme.black};
    padding: 8px 16px;
    border-radius: 2px;
  }
`;

const OpenCorisButton = styled.div`
  display: flex;
  align-items: left;
  justify-content: left;
  width: 100%;
  height: 100%;
  min-height: 240px;
  min-width: 25%;
`;

const RecordsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
  justify-content: center;
  align-items: left;

  ${OpenCorisButton} {
    min-height: 0;
    min-width: 0;
  }

  > p {
    font-size: 14px;
    margin-bottom: 8px;
  }
`;

interface AuditLogCorisModalProps {
  isOpen?: boolean;
  verificationData: RegistrationAuditLog[];
  timestamp: string;
}

interface AddRCorisButtonProps {
  handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const getReportByOperationLabel = (
  verificationData: RegistrationAuditLog[],
  objectKeys: CorisVerificationLabel
) => {
  return verificationData[0]?.result?.[objectKeys];
};

export const AuditLogCorisModal: React.FunctionComponent<
  AuditLogCorisModalProps
> = props => {
  const { verificationData } = props;
  const { modalDispatch } = useContext(ModalContext);
  const [isModalVisible] = useState(!!props.isOpen);

  const handleCloseModal = () => {
    modalDispatch({
      type: 'CLOSE_MODAL',
    });
  };
  const operationData = {
    'Business Search': getReportByOperationLabel(
      verificationData,
      'businessSearch'
    ),
    'Business Info': getReportByOperationLabel(
      verificationData,
      'businessInformation'
    ),
    'Website Info': getReportByOperationLabel(
      verificationData,
      'websiteInformation'
    ),
    'Reviews Summary': getReportByOperationLabel(verificationData, 'reviews'),
    'License Details': getReportByOperationLabel(
      verificationData,
      'licenseDetails'
    ),
    'Personnel/Officers': getReportByOperationLabel(
      verificationData,
      'personnel'
    ),
  };
  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    newRep?: boolean
  ) => {
    // Default coris url https://www.coris.ai/
    // portal url https://api.coris.ai/partner/get_business_details/?uuid=:business_id
    const business_id = operationData['Business Search']?.business_id;
    const corisURL = business_id
      ? `https://api.coris.ai/partner/get_business_details/?uuid=${business_id}`
      : `https://www.coris.ai/`;
    window.open(corisURL, '_blank');
  };

  const renderPanels = () => {
    return Object.keys(operationData).map((reportKey, index) => {
      const reports = operationData[reportKey];
      return (
        <ExpandablePanel
          key={index}
          expanded={false}
          triggerContent={reportKey}
          className={`${reportKey}-coris`}
        >
          <LogContent>
            <RecordsContainer>
              {RenderCorisLogDrilldownRecord(reports, reportKey, index)}
              <OpenCorisPortalButton
                handleClick={e => handleClick(e, true)}
                ButtonStyle={OpenCorisButton}
              />
            </RecordsContainer>
          </LogContent>
        </ExpandablePanel>
      );
    });
  };

  return (
    <SelectedMerchantProvider>
      <BaseModal
        title="Verification Details"
        isOpen={isModalVisible}
        onRequestClose={handleCloseModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={false}
        style={{
          overlay: { pointerEvents: 'auto' },
          content: {
            maxWidth: '533px',
            gridTemplateRows: '48px 1fr',
            gridTemplateAreas: `
              "header"
              "content"
            `,
          },
        }}
      >
        <ModalHeader title="Verification Details" onClose={handleCloseModal} />
        <StyledModalContent data-testid="coris-log-detail-modal">
          {renderPanels()}
        </StyledModalContent>
      </BaseModal>
    </SelectedMerchantProvider>
  );
};
