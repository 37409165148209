import React, { FC } from 'react';
import { Transaction } from '@fattmerchantorg/types-omni';
import { StatusPill, Tooltip } from '@fattmerchantorg/truffle-components';
import { formatCapitalCase } from '../../../util/format.util';
import { StatusPillStatus } from '@fattmerchantorg/truffle-components/dist/StatusPill/StatusPill';

export const TransactionHistoryStatusPill: FC<{
  transaction: Transaction;
}> = props => {
  const { type, success } = props.transaction;
  const typeLower = type.toLowerCase();
  let status: StatusPillStatus = 'success';
  let label: string = formatCapitalCase(type);
  const tooltipContent = `${
    success ? 'Successful' : 'Failed'
  } ${formatCapitalCase(type)}`;

  if (!success) {
    status = 'error';
    label = typeLower === 'charge' ? 'Failed Attempt' : label;
  } else if (typeLower === 'void' || typeLower === 'refund') {
    status = 'warning';
  }

  return (
    <Tooltip content={tooltipContent}>
      <StatusPill status={status} label={label} />
    </Tooltip>
  );
};

export default TransactionHistoryStatusPill;
