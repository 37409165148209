import {
  Registration,
  RegistrationUnderwritingSubStatusKey,
  RegistrationUnderwritingSubStatusItem,
} from '@fattmerchantorg/types-omni';
import { pick } from '../../../../../util/object.util';
import {
  UpdateUnderwritingStatusFieldsValues,
  UpdateUnderwritingStatusFieldsErrors,
} from './UpdateUnderwritingStatusForm.types';
import {
  underwritingStatusApprovedReasons,
  underwritingStatusPendedReasons,
  underwritingStatusRejectedReasons,
} from '../../../../../util/underwriting.util';

export const formatReasons = <K extends string, V>(reasons: {
  [key in K]?: V;
}) => {
  return Object.keys(reasons).map(reason => {
    return {
      message: reasons[reason],
      key: reason,
    };
  }) as RegistrationUnderwritingSubStatusItem[];
};

export const approvedReasons = formatReasons(underwritingStatusApprovedReasons);
export const pendedReasons = formatReasons(underwritingStatusPendedReasons);
export const rejectedReasons = formatReasons(underwritingStatusRejectedReasons);

export const allReasons = [
  ...approvedReasons,
  ...pendedReasons,
  ...rejectedReasons,
];
const allReasonMessages = {
  ...underwritingStatusApprovedReasons,
  ...underwritingStatusPendedReasons,
  ...underwritingStatusRejectedReasons,
};

const mapFormUnderwritingSubstatusKeysToPayloadUnderwritingSubstatuses = (
  keys: RegistrationUnderwritingSubStatusKey[]
): RegistrationUnderwritingSubStatusItem[] | null => {
  if (keys === null) return null;

  return keys.map(key => {
    const message = allReasonMessages[key];
    return { key, message } as RegistrationUnderwritingSubStatusItem;
  });
};

export function mapFormValuesToPayload(
  formValues: UpdateUnderwritingStatusFieldsValues
): Partial<Registration> {
  return {
    underwriting_status: formValues.underwriting_status,
    underwriting_substatuses:
      mapFormUnderwritingSubstatusKeysToPayloadUnderwritingSubstatuses(
        formValues.underwriting_substatuses
      ),
    underwriting_note: formValues.underwriting_note ?? null,
  };
}

export function mapRegistrationToInitialFormValues(
  registration: Registration
): UpdateUnderwritingStatusFieldsValues {
  const data = {
    ...pick(registration, 'underwriting_status', 'underwriting_note'),
    underwriting_substatuses: Array.isArray(
      registration.underwriting_substatuses
    )
      ? registration.underwriting_substatuses.map(us => us.key)
      : null,
  };

  return data as UpdateUnderwritingStatusFieldsValues;
}

export function validateRequiredFormValues(
  values: UpdateUnderwritingStatusFieldsValues
): UpdateUnderwritingStatusFieldsErrors {
  const errors = {} as UpdateUnderwritingStatusFieldsErrors;

  const requiredFields = [];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
}

export function validateFormValues(
  values: UpdateUnderwritingStatusFieldsValues,
  registration: Registration
): UpdateUnderwritingStatusFieldsErrors {
  const errors = {} as UpdateUnderwritingStatusFieldsErrors;

  // when the status is changed to "APPROVED"
  // if it's a Trust account, and there's no operating account
  // add an underwriting_status error
  if (
    values.underwriting_status === 'APPROVED' &&
    !!registration.is_trust &&
    (!registration.secondary_bank_account_number ||
      !registration.secondary_bank_routing_number)
  ) {
    errors.underwriting_status =
      'This application is for a trust mid and it cannot be approved until an operating account is added as the secondary bank account.';
  }

  return errors;
}
