import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

export type BusinessVerificationLabel =
  | 'Entity'
  | 'Bank Open'
  | 'Bank Match'
  | 'TIN'
  | 'Location';

export type PersonVerificationLabel =
  | 'Identity'
  | 'Email'
  | 'IP'
  | 'OFAC'
  | 'SSN';

export type CorisVerificationLabel =
  | 'businessSearch'
  | 'businessInformation'
  | 'websiteInformation'
  | 'reviews'
  | 'licenseDetails'
  | 'personnel';

export type Operation =
  | 'gIdentify'
  | 'gAuthenticate'
  | 'gVerify'
  | 'tincheck'
  | 'googleVerifyBusiness'
  | 'ESI'
  | 'gOFAC';

export type ReportEntity = 'business' | 'person' | 'internal' | 'experian';

// This just maps the StatusPill labels in the design to the report operations they represent for the Business entity checks
export const BusinessVerificationToOperations: Record<
  BusinessVerificationLabel,
  Operation
> = {
  Entity: 'gIdentify',
  'Bank Match': 'gAuthenticate',
  'Bank Open': 'gVerify',
  TIN: 'tincheck',
  Location: 'googleVerifyBusiness',
};
// This just maps the StatusPill labels in the design to the report operations they represent for the Person entity checks
export const PersonVerificationToOperations: Record<
  PersonVerificationLabel,
  Operation
> = {
  Identity: 'gIdentify',
  Email: 'ESI',
  IP: 'ESI',
  OFAC: 'gOFAC',
  SSN: 'tincheck',
};

export const tincheckSuccessCodes = ['1', '6', '7', '8'];
export const tincheckFailureCodes = ['0', '5', '13', '-1', '11'];

export type ReportStatus = 'neutral' | 'success' | 'failure' | 'warning';

export type CorisStatus = 'warning' | 'success' | 'error';

export interface ReportPillProperties {
  status: string;
  icon: FontAwesomeIconProps['icon'];
  disabled: boolean;
}
export interface ReportPillConfigurations {
  neutral: ReportPillProperties;
  success: ReportPillProperties;
  failure: ReportPillProperties;
  warning: ReportPillProperties;
}

export interface CorisPillConfigurations {
  error: ReportPillProperties;
  success: ReportPillProperties;
  warning: ReportPillProperties;
}

export type Signer = {
  first_name: string;
  last_name: string;
  user_dob: string;
  email: string;
  address_1: string;
  address_2: string | null;
  address_city: string;
  address_state: string;
  address_zip: string;
  address_country: string;
  phone_number: string;
  user_ssn: string;
};
