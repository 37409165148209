import React, { FunctionComponent } from 'react';
import { Import } from '../../../../@types';
import { Pill, PillProps } from '../../../shared';

interface ImportStatePillProps {
  state: Import['state'];
}

const formatState = (state: Import['state']) => {
  switch (state) {
    case 'COMPLETE':
      return 'Complete';
    case 'UPLOAD_COMPLETE':
      return 'File Uploaded';
    case 'IMPORTING':
      return 'Importing';
    case 'UNDOING':
      return 'Undoing';
    default:
      return '';
  }
};

const mapImportStateToMotif = (state: Import['state']): PillProps['motif'] => {
  switch (state) {
    case 'COMPLETE':
      return 'success';
    case 'UPLOAD_COMPLETE':
      return 'neutral';
    case 'IMPORTING':
    case 'UNDOING':
    default:
      return 'warning';
  }
};

export const ImportStatePill: FunctionComponent<ImportStatePillProps> = props => {
  return (
    <Pill motif={mapImportStateToMotif(props.state)}>
      <span>{formatState(props.state)}</span>
    </Pill>
  );
};
