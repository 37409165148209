import React, { FunctionComponent, cloneElement, useCallback } from 'react';
import styled from 'styled-components';
import { BigRadioProps, BigRadioOptionProps } from './BigRadio.types';
import { Option } from './BigRadioOption';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BigRadio: FunctionComponent<BigRadioProps> & {
  Option: React.FunctionComponent<BigRadioOptionProps>;
} = props => {
  const { onChange } = props;

  const children: any[] = Array.isArray(props.children)
    ? props.children
    : [props.children];

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value),
    [onChange]
  );

  return (
    <Container id={props.id} data-testid={props.id}>
      {children.map((child, i) =>
        cloneElement(child, {
          ...child.props,
          id: child.props.id || `${props.id}-option-${i}`,

          parentId: props.id,
          parentValue: props.value,
          disabled: props.disabled,
          onChange: handleChange
        })
      )}
    </Container>
  );
};

BigRadio.Option = Option;
