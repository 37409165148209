import React, { FC, useContext, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { Widget } from '../../shared/Widget';
import { Icon, Tooltip } from '@fattmerchantorg/truffle-components';
import { useAsyncEffect, useAuthToken, useToaster } from '../../../hooks';
import { SelectedMerchantStore } from '../../../context';
import { OnboardingAuditLogs } from '../../../util/onboardingAutditLog.util';
import copy from 'copy-to-clipboard';
import { formatTruncatedId } from '../../../util/format.util';
import { LoadingSpan } from '../../shared';
import {
  getCompanyOnboardings,
  getOnboardingPortfolios,
} from '../../../util/catan.util';

const Container = styled(Widget)`
  background: white;
  min-height: 119px;
`;

const StyledTooltip = styled(Tooltip)`
  margin-top: 10px;
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.linkColor};
  cursor: pointer;
`;

const WidgetHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding-bottom: 8px;
  font-size: 18px;
  color: ${({ theme }) => theme.black};
  i {
    margin: 0 0 8px;
  }
`;
const IconTab = styled.div`
  margin-top: 10px !important;
  padding-top: 10px !important;
`;

const Span = styled.span`
  font-weight: normal;
  display: flex;
  justify-content: baseline;
  font-size: 14px !important;
  line-height: 20px;
  padding-top: 1px;
`;

const Mid = styled.span`
  font-weight: normal;
  margin-top: 20px !important;
  display: flex;
  justify-content: baseline;
  font-size: 14px !important;
  line-height: 20px;
  width: fit-content;
  margin-top: 5px;
  text-align: right;
  align-content: right;
`;

const Content = withTheme(
  styled.div`
    color: ${({ theme }) => theme.colors.core.gray[600].hex};
    display: flex;
    flex-direction: row;
    margin: 1rem;

    span {
      line-height: 21px;
      font-size: 14px;
      color: ${({ theme }) => theme.black};
      white-space: normal;
    }

    h2 {
      margin: 0px;
      color: ${({ theme }) => theme.black};
    }

    > div {
      flex: 50%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    button {
      color: #32325d;
      padding: 0;
    }

    .processor-content {
      p {
        font-size: 12px;
      }
      table {
        width: 100%;
        color: ${({ theme }) => theme.black};
        thead {
          tr {
            th {
              font-weight: bold;
              font-size: 12px;
              padding: 5px 10px 5px 10px;
              text-align: right;
              &:first-child {
                text-align: left;
              }
              &:last-child {
                padding-left: 0;
              }
            }
          }
        }
        tbody {
          tr {
            &:nth-child(odd) {
              background-color: #ebf9f0;
            }
            td {
              font-weight: bold;
              font-size: 13px;
              padding: 5px 10px 5px 10px;
              text-align: right;
              &:first-child {
                text-align: left;
              }
              &:last-child {
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  `
);

const LoadingRow = styled.div`
  display: flex;
  margin-bottom: 8px;

  /* loading spans */
  > div + div {
    margin-left: 3rem;
  }
`;

export type Processors = OnboardingAuditLogs[];

const ProcessorLoadingState: FC = () => {
  return (
    <>
      <Container>
        <Content>
          <div>
            <WidgetHeader>
              <h2>Processors</h2>
            </WidgetHeader>
            <LoadingRow>
              <LoadingSpan width="100%" />
            </LoadingRow>
            <LoadingRow>
              <LoadingSpan width="100%" />
            </LoadingRow>
            <LoadingRow>
              <LoadingSpan width="100%" />
            </LoadingRow>
          </div>
        </Content>
      </Container>
    </>
  );
};

export const OnboardingProcessorWidget: FC = () => {
  const authToken = useAuthToken();
  const [data, setData] = useState([]);
  const { toaster, toast } = useToaster();
  const { state } = useContext(SelectedMerchantStore);
  const [portfolios, setPortfolios] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const engineCompanyId = state?.registration?.external_company_id;

  useAsyncEffect(async () => {
    if (engineCompanyId) {
      try {
        setIsLoading(true);
        const portfolios = await getOnboardingPortfolios(authToken);
        setPortfolios(portfolios.data);
        const onboardings = await getCompanyOnboardings(
          authToken,
          engineCompanyId,
          { state: ['APPROVED', 'APPROVED.INACTIVE'] }
        );
        setData(onboardings.data);
        setIsLoading(false);
      } catch (e) {}
    }
  }, [engineCompanyId]);

  function getPortfolioName(id) {
    const pfolio = portfolios.find(p => p.payfac_id === id);
    return pfolio?.payfac_name;
  }

  const content = () => {
    const nullState = <p>No processors approved yet.</p>;
    const table = (
      <table>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>
                {item?.processor_name}
                <Span>{getPortfolioName(item?.payfac_id)}</Span>
              </td>
              <td>
                <Mid>{formatTruncatedId(item?.processor_mid, 24)}</Mid>
              </td>
              <td>
                <IconTab>
                  <StyledTooltip content={<span>Copy ID</span>}>
                    <StyledIcon
                      onClick={() => onCopyPMID(item?.processor_mid)}
                      icon={['fas', 'copy']}
                    />
                  </StyledTooltip>
                </IconTab>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
    return Object.keys(data).length === 0 ? nullState : table;
  };

  return (
    <>
      {isLoading && <ProcessorLoadingState />}
      {!isLoading && (
        <Container data-testid="processor-widget-container">
          <Content>
            <div>
              <WidgetHeader>
                <h2>Processors</h2>
              </WidgetHeader>
              <div className="processor-content">{content()}</div>
            </div>
          </Content>
        </Container>
      )}
    </>
  );

  function onCopyPMID(pmid: string) {
    copy(pmid);
    toaster(toast.success(`${pmid} copied to clipboard`));
  }
};
