import React, { FC, useEffect, useContext } from 'react';
import Switch from 'react-switch';
import styled from 'styled-components';

import SubTitle from '../SubTitle';
import { sendUserToggledSwitch, sendUpdateRiskHold } from './actions';
import useRiskHoldToggleHook from './useRiskHoldToggleHook';
import { deriveAuthMode, AuthStore } from '../../../../../context';

const Label = styled.label`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

export const RiskHoldToggle: FC<{
  authToken: string;
  riskHold: boolean;
  canWriteRiskHold: boolean;
}> = ({ authToken, riskHold, canWriteRiskHold }) => {
  const [state, dispatch] = useRiskHoldToggleHook(riskHold, authToken);
  const { state: authState } = useContext(AuthStore);
  const mode = deriveAuthMode(authState);
  useEffect(() => {
    if (state.data.optimisticRiskHold !== riskHold) {
      // The merchant in the SelectedMerchantStore state is the source of truth for deposit hold
      // When the merchant deposit hold gets updated it will carry its prop down to this component but we
      // need to also reflect it in the optimisticRiskHold state so the toggle ui doesn't get off sync with the value
      dispatch(sendUpdateRiskHold(riskHold));
    }
  }, [riskHold, dispatch, state.data.optimisticRiskHold]);

  const isToggleDisabled = () => {
    return state.status === 'LOADING' || !canWriteRiskHold;
  };

  return (
    <>
      <SubTitle>Deposit Hold</SubTitle>
      <Label>
        <span>{riskHold ? 'ACTIVE' : 'INACTIVE'}</span>
        <Switch
          id="risk-hold-switch"
          data-testid="risk-hold-switch"
          checked={state.data.optimisticRiskHold}
          onChange={() => dispatch(sendUserToggledSwitch())}
          offColor="#062333"
          onColor={mode === 'TEST_MODE' ? '#b93be4' : '#75D697'}
          offHandleColor="#8D9799"
          onHandleColor="#fff"
          height={25}
          width={52}
          handleDiameter={17}
          uncheckedIcon={false}
          checkedIcon={false}
          disabled={isToggleDisabled()}
        />
      </Label>
    </>
  );
};
