import React, { FunctionComponent } from 'react';
import { PointTooltipProps, ResponsiveLine, Serie } from '@nivo/line';
import { ResidualsStatement, DataKeys } from '@fattmerchantorg/types-omni';
import { ChartTypes } from '../../../@types';
import {
  formatBigNumber,
  mapPartnerDataToLineData,
} from '../../../util/dashboard.util';
import { emptyResidualSamples } from '../data/sample';
import { BoldText } from './shared/BoldText';
import { ChartPreRender } from './shared/ChartPreRender';
import { formatCurrency } from '../../../util/format.util';
import { ResidualsLastUpdatedMessage } from './shared/TruffleText';
import moment from 'moment';

const tooltipStyle = {
  color: '#000',
  backgroundColor: '#fff',
  borderRadius: '4px',
  padding: '3px 8px',
  fontFamily: 'Roboto',
  fontWeight: 700,
  fontSize: 12,
};

//Line chart can optionally take in a yFormat prop which determines formatting for the y-axis data.
type LineProps = {
  dataStatus: string;
  data: any[] | null;
  type: ChartTypes;
  dataKey: DataKeys;
  yFormat?: 'dollars';
};

// "Add single x-axis to empty chart"
//
// An empty Nivo chart displays a single x-axis right thru the center of the chart area
// and this doesn't look good with the "empty chart overlay message" we display.
// The UI/UX team asked to push this x-axis line to the bottom of the chart so
// an empty chart looks better with our overlay message.
const showSingleXAxisForEmptyChart = {
  axis: {
    ticks: {
      text: {
        fill: '#979797',
        fontSize: 12,
      },
    },
    domain: {
      line: {
        stroke: '#dddddd',
        strokeWidth: 1,
      },
    },
  },
};

function calculateLatestUpdatedDate(data: ResidualsStatement[]): string {
  let lastUpdated: string = '';

  if (data.length) {
    // Grab last date from set.
    const allDates = data.map(o => o.activity_period);
    const mostRecentDate = allDates[allDates.length - 1];

    // Make readable.
    const date = moment(mostRecentDate);
    const month = date.format('MMMM');
    const year = date.format('YYYY');

    lastUpdated = month + ' ' + year;
  }

  return lastUpdated;
}

function hasNegativeValue(data: ResidualsStatement[]): boolean {
  const negativeValues = data.map(r => r.residuals).filter(r => r < 0);
  return negativeValues.length > 0;
}

export const LineChart: FunctionComponent<LineProps> = ({
  dataStatus,
  data,
  type,
  dataKey,
  yFormat,
}) => {
  const hasData: boolean = data === null || data.length > 0;
  /**
   * If there is a negative value in the dataset,
   * adjust the line chart to use 'auto' for min Y axis
   */
  const hasNegative: boolean = hasNegativeValue(data);

  const formatNumber = value => {
    if (yFormat === 'dollars') {
      return (
        '$' +
        Number(value).toLocaleString('en-US', { minimumFractionDigits: 2 })
      );
    } else {
      return value;
    }
  };

  const lineData: Serie[] = mapPartnerDataToLineData(
    data || emptyResidualSamples,
    dataKey
  );

  const CustomSymbol = ({ size, color, borderWidth, borderColor }) => (
    <g>
      <circle
        r={5}
        strokeWidth={2}
        stroke={'#fff'}
        fill="#75D697"
        fillOpacity={1}
      />
      <circle r={10} fill="#fff" fillOpacity={0.2} />
    </g>
  );

  const toolTipElement = (props: PointTooltipProps) => {
    return (
      <div style={tooltipStyle}>{formatCurrency(+props.point.data.y)}</div>
    );
  };

  const lastUpdated: string = calculateLatestUpdatedDate(data);

  return (
    <div style={{ height: 440 }}>
      <div className="d-flex">
        <div className="flex-fill">
          <BoldText>{type.toString()}</BoldText>
        </div>
        <div>{hasData}</div>
      </div>

      {lastUpdated !== ''
        ? ResidualsLastUpdatedMessage(`Last Updated: ${lastUpdated}`)
        : null}

      {ChartPreRender(dataStatus, hasData, type) ?? (
        <ResponsiveLine
          data={lineData}
          margin={{ top: 10, right: 10, bottom: 116, left: 32 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: hasNegative ? 'auto' : 0,
            max: 'auto',
            stacked: true,
            reverse: false,
          }}
          animate={true}
          enableSlices={false}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 0,
            tickPadding: 20,
            tickRotation: 0,
          }}
          axisLeft={
            data
              ? {
                  tickSize: 0,
                  tickPadding: 5,
                  tickRotation: 0,
                  tickValues: 6, // makes sure the ticks are spaced out,
                  format: v => formatBigNumber(parseFloat(v.toString())),
                }
              : null
          }
          colors={['#75D697']}
          theme={
            data
              ? {
                  // gives a specific style to the line graph
                  axis: {
                    ticks: {
                      text: {
                        fill: 'rgb(255, 255, 255)',
                        fontSize: 12,
                      },
                    },
                  },
                  crosshair: {
                    line: {
                      stroke: '#75D697',
                      strokeWidth: 1,
                      strokeOpacity: 1,
                    },
                  },
                }
              : showSingleXAxisForEmptyChart
          } // See "Add single x-axis to empty chart".
          gridYValues={6} // makes sure the grid lines are spaced out
          curve="monotoneX"
          enableArea={true}
          areaOpacity={0.25}
          // gradient area
          defs={[
            {
              id: 'gradientA',
              type: 'linearGradient',
              colors: [
                { offset: 0, color: '#75D697', opacity: '1' },
                { offset: 100, color: '#75D697', opacity: '.25' },
              ],
            },
          ]}
          fill={[{ match: '*', id: 'gradientA' }]}
          enablePoints={true}
          enablePointLabel={false}
          enableGridX={false}
          enableGridY={true}
          pointSymbol={CustomSymbol}
          pointSize={20}
          pointBorderWidth={0}
          pointBorderColor={{
            from: 'color',
            modifiers: [['darker', 0.3]],
          }}
          useMesh={true}
          enableCrosshair={true}
          crosshairType={'bottom'}
          yFormat={formatNumber}
          tooltip={toolTipElement}
        />
      )}
    </div>
  );
};
