import React, { FunctionComponent } from 'react';
import { Icon } from '@fattmerchantorg/truffle-components';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import styled from 'styled-components';

const ButtonIcon: typeof Icon = styled(Icon)`
  margin-right: 8px;
  animation-duration: 0.5s;
`;

export const ButtonSpinner: FunctionComponent = () => (
  <ButtonIcon icon={faSpinnerThird} spin />
);
