import { FormErrors } from '../../../../@types';
import {
  Omni,
  RegistrationRepresentative,
  Registration,
} from '@fattmerchantorg/types-omni';

export const additionalRepresentativesFields = [
  'meta',
  'representatives',
  'meta.representatives.1.*',
  'meta.representatives.2.*',
  'meta.representatives.3.*',
  'meta.representatives.4.*',
  'meta.representatives.5.*',
] as const;

export type AdditionalRepresentativesFields =
  typeof additionalRepresentativesFields[number];

export type AdditionalRepresentativesFieldsValues =
  Omni.RegistrationRepresentative & {
    meta: Registration['meta'];
    representatives: RegistrationRepresentative[];
  };

export type AdditionalRepresentativesFieldsErrors =
  FormErrors<AdditionalRepresentativesFieldsValues>;
