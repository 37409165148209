import React, { useMemo, useState } from 'react';
import {
  Row,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import {
  Pagination,
  SortIndicator,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  TableTools,
  TableWrapper,
} from '@fattmerchantorg/truffle-components';
import { CollectionQuery, LoadingSpan } from '../../shared';
import styled from 'styled-components';
import { PayoutRowContextMenu } from '../details/PayoutRowContextMenu';
import { API } from '@fattmerchantorg/types-engine';
import { PayoutReAttemptConfirmationModal } from '../details/PayoutReAttemptConfirmationModal';
import { catanapi } from '../../../api';
import { useAuthToken, useToaster } from '../../../hooks';

interface DataTablePropsPayouts {
  data: {
    columnsPayout: {
      Header: string;
      accessor: string;
    }[];
    rows: Record<string, unknown>[];
  };
  pageSizeOptions: {
    value: number;
    label: string;
  }[];
  count: number;
  total: number;
  loading: boolean;
  currentPage: number;
  defaultPageSize: number;
  lastPage: number;
  handleQueryChange: (query: CollectionQuery) => void;
  handleDataRefresh?: () => void;
  onItemClick: (index: CollectionQuery) => void;
}

export const MenuCell = styled(TableCell)``;

export const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  ${MenuCell} {
    > button {
      visibility: hidden;
    }
  }

  &:hover {
    ${MenuCell} {
      > button {
        visibility: visible;
      }
    }
  }
`;

export const DetailsDataTablePayouts: React.FC<
  DataTablePropsPayouts
> = props => {
  const {
    data,
    pageSizeOptions,
    count,
    total,
    handleQueryChange,
    loading,
    onItemClick,
    currentPage,
    lastPage,
    defaultPageSize,
  } = props;

  // Row and column data for table
  const rowData = useMemo(() => data.rows, [data.rows]);
  const columns = useMemo(() => data.columnsPayout, [data.columnsPayout]);

  const [reAttemptPayoutId, setReAttemptPayoutId] = useState<string | null>(
    null
  );
  const [isReAttempting, setIsReAttempting] = useState<boolean>(false);
  const authToken = useAuthToken();
  const { toast, toaster } = useToaster();

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setSortBy,
  } = useTable(
    {
      columns,
      data: rowData,
      defaultColumn: {
        width: 0,
      },
      manualPagination: true,
      manualSortBy: true,
      manualFilters: true,
      manualGlobalFilter: true,
      pageCount: count,
      initialState: {
        sortBy: [
          {
            id: 'created_at',
            desc: true,
          },
        ],
        pageSize: defaultPageSize,
        pageIndex: currentPage,
      },
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  return (
    <>
      <TableWrapper>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, index) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column: any, index) => (
                  <TableHeaderCell
                    style={column.style}
                    key={index}
                    /** This will sort the table based on
                     * the enabled sorted field
                     */
                    onClick={() => {
                      if (column.disableSortBy !== true) {
                        //set sort desc, asc or undefined?
                        const desc =
                          column.isSortedDesc === false ? true : false;
                        setSortBy([{ id: column.id, desc }]);
                        if (desc !== undefined) {
                          // this will pass the order value based on the sort icon clicked
                          let order = desc === false ? 'ASC' : 'DESC';
                          handleQueryChange({ sort: column.id, order });
                        }
                      }
                    }}
                  >
                    {column.render('Header')}

                    {column.isSorted ? (
                      <SortIndicator
                        sort={column.isSortedDesc ? 'desc' : 'asc'}
                      />
                    ) : column.disableSortBy ? null : (
                      <SortIndicator sort="desc" />
                    )}
                  </TableHeaderCell>
                ))}
                <TableHeaderCell style={{ width: 40 }}>&nbsp;</TableHeaderCell>
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <StyledTableRow {...row.getRowProps()} key={rowIndex}>
                  {row.cells.map((cell: any, colIndex) => {
                    return (
                      <TableCell
                        onClick={() => {
                          onItemClick(data?.rows[rowIndex]);
                        }}
                        {...cell.getCellProps()}
                        key={colIndex}
                        style={cell.column.style}
                      >
                        {loading ? (
                          <LoadingSpan width="75%" />
                        ) : (
                          cell.render('Cell')
                        )}
                      </TableCell>
                    );
                  })}

                  <MenuCell style={{ padding: 0, textAlign: 'center' }}>
                    <PayoutRowContextMenu row={row} onReAttempt={onReAttempt} />
                  </MenuCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
        <TableTools>
          <Pagination
            onNextPage={() => {
              nextPage();
              handleQueryChange({ page: currentPage + 1 });
            }}
            onPreviousPage={() => {
              previousPage();
              handleQueryChange({ page: currentPage - 1 });
            }}
            onSkipToEnd={() => {
              gotoPage(count - 1);
              handleQueryChange({ page: count });
            }}
            onSkipToBeginning={() => {
              gotoPage(0);
              handleQueryChange({ page: 1 });
            }}
            onGoToPage={(page: number) => {
              gotoPage(page);
              handleQueryChange({ page: page + 1 });
            }}
            totalPages={count}
            totalRecords={total}
            recordsPerPage={defaultPageSize}
            onSetPageSize={size => {
              setPageSize(size);
              handleQueryChange({ perPage: size });
            }}
            currentPage={currentPage - 1}
            pageSizeOptions={pageSizeOptions}
            canNextPage={currentPage === lastPage ? false : true}
            canPreviousPage={currentPage === 1 ? false : true}
          />
        </TableTools>
      </TableWrapper>
      <PayoutReAttemptConfirmationModal
        isOpen={reAttemptPayoutId !== null}
        isProcessing={isReAttempting}
        onReAttempt={reAttempt}
        onClose={onReAttemptConfirmed}
      />
    </>
  );

  function onReAttempt(row: Row<object>, rowData: API.Payout) {
    setReAttemptPayoutId(rowData.payout_id);
  }

  function onReAttemptConfirmed() {
    // close modal only if we're not still processing a request
    if (!isReAttempting) {
      setReAttemptPayoutId(null);
    }
  }

  async function reAttempt() {
    if (!reAttemptPayoutId) return;

    setIsReAttempting(true);
    try {
      await catanapi.post(
        authToken,
        `/payouts/${reAttemptPayoutId}/re-attempt`
      );
      props.handleDataRefresh();
    } catch (e) {
      toaster(
        toast.error(
          e.message ?? `An error occurred while trying to re-attempt payout`
        )
      );
    } finally {
      setIsReAttempting(false);
      setReAttemptPayoutId(null);
    }
  }
};
