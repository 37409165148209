import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBirthdayCake,
  faPassport,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCloudUpload,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faEmptySet,
  faExclamationTriangle,
  faEye,
  faFax,
  faGlobe,
  faHandPaper,
  faHandsUsd,
  faIdCard,
  faMapMarkerAlt,
  faPercent,
  faStars,
  faThumbsUp,
  faTimes,
  faTrash,
  faSync,
  faPaperclip,
  faUpload,
} from '@fortawesome/pro-solid-svg-icons';

import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';

/**
 * For extra icons not included in Truffle
 */
export const setupIcons = () => {
  library.add(
    faFax,
    faGlobe,
    faBirthdayCake,
    faPassport,
    faIdCard,
    faMapMarkerAlt,
    faPercent,
    faEye,
    faDownload,
    faTrash,
    faCloudUpload,
    faChevronDown,
    faChevronUp,
    faChevronLeft,
    faChevronRight,
    faTimes,
    faExclamationTriangle,
    faEmptySet,
    faEllipsisH,
    faEllipsisV,
    faStars,
    faThumbsUp,
    faSync,
    faPaperclip,
    faHandsUsd,
    faHandPaper,
    faExclamationCircle,
    faPaperclip,
    faUpload
  );
};
