import React, { FunctionComponent, useContext, useState } from 'react';
import { Merchant, User } from '@fattmerchantorg/types-omni';
import { RegistrationForm } from './RegistrationForm';
import { Container, PageHeader } from '../shared';
import { SelectedMerchantStore } from '../../context';
import styled, { withTheme } from 'styled-components';
import { MagicLinkButton } from './components/magiclink/MagicLinkButton';
import { MagicLinkUserSelect } from './components/magiclink/MagicLinkUserSelect';
import { Col, Row } from 'reactstrap';
import { coreapi } from '../../api';
import { useToaster, useAuthToken, useUnderwritingStatus } from '../../hooks';
import clipboard from 'copy-to-clipboard';

const Header = withTheme(
  styled.h1`
    font-size: 2rem;
    color: ${({ theme }) => theme.white};
    padding: 0 16px;
  `
);

const MagicLinkContainer = styled.div`
  display: flex;

  > *:not(:first-child) {
    padding-left: 8px;
  }
`;

export const RegistrationPage: FunctionComponent = () => {
  const {
    state: { merchant },
  } = useContext(SelectedMerchantStore);
  const { isFullyApproved } = useUnderwritingStatus();

  const { toaster, toast } = useToaster();
  const authToken = useAuthToken();
  const [magicLinkEmail, setMagicLinkEmail] = useState<string | null>(null);

  async function handleMagicLinkGeneration(
    email: string,
    merchant_id: string,
    company_name: string
  ) {
    const payload = {
      merchant_id,
      email,
      app_url: `${process.env.REACT_APP_SELF_REGISTRATION_APP_URL}#/sso/magiclink/`,
    };
    try {
      // Generate magic link.
      const res = await coreapi.post(authToken, `magiclink/create`, payload);
      // Copy to clipboard.
      clipboard(res.link);
      // Update UI.
      toaster(toast.success(`Magic link has been copied to clipboard!`));
    } catch (error) {
      if (error['email']) {
        toaster(
          toast.error(
            `No email was found matching any users of ${company_name}`
          )
        );
      } else {
        toaster(toast.error('Could not copy magic link'));
      }
    }
  }

  return (
    <>
      <PageHeader title="Enrollment" />
      <Container>
        <Row>
          <Col>
            {merchant && (
              <Header>
                {merchant.company_name} Enrollment{' '}
                {isFullyApproved ? '(Fully Approved)' : ''}
              </Header>
            )}
          </Col>
          <Col xs="12" sm="12" md="auto">
            {merchant && (
              <MagicLinkContainer>
                <MagicLinkUserSelect
                  onChange={(option: { label: string; value: string }) => {
                    setMagicLinkEmail(option.value);
                  }}
                  users={(merchant as Merchant & { users: User[] }).users}
                />
                <MagicLinkButton
                  email={magicLinkEmail}
                  merchant_id={merchant.id}
                  company_name={merchant.company_name}
                  handleMagicLinkGeneration={handleMagicLinkGeneration}
                />
              </MagicLinkContainer>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <RegistrationForm />
          </Col>
        </Row>
      </Container>
    </>
  );
};
