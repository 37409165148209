import styled, { withTheme } from 'styled-components';
import { withAlpha } from '../../../util/color.util';

export const List = withTheme(
  styled.ul`
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: scroll !important;

    > li {
      padding: 4px 8px;
      background: ${({ theme }) => theme.colors.core.gray[800].hex};
    }

    > li:nth-child(2n) {
      background: ${(props: any) => withAlpha(props.theme.primaryColor, 0.05)};
    }
  `
);
