export const SectionTitles = {
  AdditionalMetaInfo: 'Additional Meta Information',
  AdditionalRepresentatives: 'Additional Representatives',
  BillingAddress: 'Billing Address',
  BusinessInfo: 'Business Info',
  Countersign: 'Countersign',
  DBALocationAddress: 'DBA Location Address',
  ExternalVerification: 'External Verification',
  Files: 'Files',
  InternalNotes: 'Internal Notes',
  OwnerSignerInfo: 'Owner/Signer Info',
  PricingInfo: 'Pricing Info',
  ProcessingEnvironment: 'Processing Environment',
  ProcessingInfo: 'Processing Info',
  BankingInfo: 'Banking Info',
  RequiredDocuments: 'Required Documents',
  Status: 'Status',
} as const;
