import { StatisticsRouteResponse } from '../../../api';
import { ActionTypes, TimeRange } from '../@types/TimeRange';
import { DataSet } from '../@types/DataSet';

/*
 * Actions
 */
interface UserSelectedTimespan {
  type: typeof ActionTypes.USER_SELECTED_TIMESPAN;
  payload: TimeRange;
}

interface FetchSucceededAction {
  type: typeof ActionTypes.FETCH_SUCCEEDED;
  payload: {
    topPerforming: StatisticsRouteResponse;
    underPerforming: StatisticsRouteResponse;
  };
}

interface FetchFailedAction {
  type: typeof ActionTypes.FETCH_FAILED;
  payload: Error;
}

interface UserSelectedDataSet {
  type: typeof ActionTypes.USER_SELECTED_DATA_SET;
  payload: DataSet;
}

export type Action =
  | UserSelectedTimespan
  | FetchFailedAction
  | FetchSucceededAction
  | UserSelectedDataSet;

/*
 * Action Creators
 */
export function sendMerchantPerformanceTimespan(payload: TimeRange): Action {
  return { type: ActionTypes.USER_SELECTED_TIMESPAN, payload };
}

export function sendUserSelectedPerformanceDataSet(payload: DataSet): Action {
  return { type: ActionTypes.USER_SELECTED_DATA_SET, payload };
}

export function sendFetchSucceeded(payload: {
  topPerforming: StatisticsRouteResponse;
  underPerforming: StatisticsRouteResponse;
}): Action {
  return { type: ActionTypes.FETCH_SUCCEEDED, payload };
}

export function sendFetchFailed(payload: Error): Action {
  return { type: ActionTypes.FETCH_FAILED, payload };
}
