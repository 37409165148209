// credit for this function 👉 https://stackoverflow.com/a/28056903
export const hexToRGB = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgb(${r}, ${g}, ${b})`;
  }
};

/**
 * credit for this function 👉 https://css-tricks.com/snippets/javascript/lighten-darken-color/
 * @param {*} color hex color
 * @param {*} amt amount to lighten. If amt is a negative number the color will be darkened
 */
export const adjustColor = (color, amt) => {
  let usePound = false;

  if (color[0] === '#') {
    color = color.slice(1);
    usePound = true;
  }

  const num = parseInt(color, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

export const withAlpha = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgb(${r}, ${g}, ${b})`;
  }
};

export const makeAvatarColors = (engagements: { email: string }[] | null) => {
  const getAvatarHex = (index: number): string => {
    const thumbnailColors = [
      '#B93BE4',
      '#9D6BDC',
      '#829CD4',
      '#66CCCC',
      '#A1D879',
      '#CCDA5B',
      '#F8DC3D',
    ];
    const i = index % thumbnailColors.length;
    return thumbnailColors[+i];
  };

  return engagements?.reduce((result, engagement) => {
    if (!result.has(engagement.email)) {
      result.set(engagement.email, getAvatarHex(result.size));
    }
    return result;
  }, new Map<string, string>());
};

// credit for this function 👉 https://stackoverflow.com/a/28056903
export const transparentize = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgb(${r}, ${g}, ${b})`;
  }
};
