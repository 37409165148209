import { CollectionPagination, Merchant } from '@fattmerchantorg/types-omni';
import { Ref } from 'react';
import { RouteComponentProps } from 'react-router';

export type FeeStatementV3RouterProps = RouteComponentProps<{
  activityPeriod: string;
}>;

export type FeeStatementV3Props = {
  startDate: string;
  endDate: string;
} & FeeStatementV3RouterProps;

export interface StatementV3Props {
  merchant?: Merchant;
  merchantId?: string;
  startDate?: string;
  endDate?: string;
  activityPeriod?: string;
  logoUrl?: string;
  reportName?: string;
  totalFeesRef?: any;
  refundsAdjustmentsRef?: any;
  dailySalesRef?: any;
  ref?: Ref<HTMLElement>;
}

export const calculateNumberOfLoadingRows = (
  pagination: CollectionPagination
) => {
  const { per_page, current_page, total } = pagination;
  const numberOfRowsLoaded = per_page * current_page;
  const numberOfRowsLeft = Math.max(total - numberOfRowsLoaded, 0);
  return Math.min(per_page, numberOfRowsLeft);
};
