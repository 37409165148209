import React from 'react';
import { ProcessingDetailsSectionData } from '../AdvancedDetailDrawer.types';
import { Accordion, Icon, Tooltip } from '@fattmerchantorg/truffle-components';
import { usePermissions } from '../../../../hooks';
import styled from 'styled-components';
import { DrawerSection } from '../sharedComponents';
import { formatDate } from '../../../../util/date.util';
import { formatCurrency } from '../../../../util';
import { AccordionWrapper } from '../sharedComponents';
type ProcessingDetailsSectionProps = {
  id: string;
  name: string;
  data: ProcessingDetailsSectionData;
};

const DataContainer = styled.div`
  border-right: 1px solid ${({ theme }) => theme.lightNeutralOutline};
  flex-basis: 100%;
  padding: 0 8px;
  &:first-child {
    padding: 0 8px 0 0;
  }
  &:last-child {
    border-right: 0;
  }
`;
const ProcessingDetailRow = styled.div`
  display: flex;
  padding: 4px 0;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  scroll-snap-align: start;
`;

const Title = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  float: left;
  margin-right: 8px;
`;
const Value = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`;

const TransactionRedirectIcon = styled(Icon)<{ disabled: boolean }>`
  position: relative;
  float: left;
  cursor: ${({ disabled }) => (disabled ? '' : 'pointer')};
  color: ${({ disabled }) =>
    disabled ? 'gray' : ({ theme }) => theme.colors.status.blue[500].hex};
`;

const ProcessingToolTip = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

const BreakLine = styled.hr`
  margin: 0;
  width: 100%;
  border-bottom: 1px solid #435e70;
`;

const ProcessingDetailsSection: React.FunctionComponent<
  ProcessingDetailsSectionProps
> = props => {
  const {
    data,
    name = 'Processing Details',
    id = 'processing-details-drawer-section',
  } = props;

  const { permit } = usePermissions();
  const hasSettlementPermissions = permit(
    'godview',
    'manageStaxSettlements',
    'read'
  );

  const hasSettlementAvailable = !!data?.batch_id || data?.is_settlement;
  return (
    <AccordionWrapper id={id}>
      <Accordion
        title={name}
        titleSuffix={!data?.processingFees ? 'Pending' : ''}
      >
        <DrawerSection id="processing-details-section">
          <DataContainer key={`cols${data?.transactionId}`}>
            <ProcessingDetailRow key={`divcharge${data?.transactionId}`}>
              <div>
                <Title>Charge</Title>
                {data?.merchant_id && data?.is_settlement && (
                  <TransactionRedirectIcon
                    icon={['fas', 'external-link']}
                    onClick={() => {
                      window.open(
                        `#/merchant/${
                          data?.merchant_id
                        }/transactions?startDate=${formatDate(
                          data?.surchargeDate,
                          'yyyy-MM-dd'
                        )}&endDate=${formatDate(
                          data?.surchargeDate,
                          'yyyy-MM-dd'
                        )}&detailId=${
                          data?.type?.toLowerCase() === 'refund'
                            ? data?.parent_transaction_id
                            : data?.transactionId
                        }`
                      );
                    }}
                  />
                )}
                {!data?.is_settlement && hasSettlementPermissions && (
                  <TransactionRedirectIcon
                    icon={['fas', 'external-link']}
                    onClick={() => {
                      data?.batch_id &&
                        window.open(
                          `#/settlements/ST${data?.batch_id}/details?detailId=TR${data?.transactionId}`,
                          '_blank'
                        );
                    }}
                    disabled={!data?.batch_id}
                  />
                )}
              </div>
              <Value>{`$${data?.charge.toFixed(2)}`}</Value>
            </ProcessingDetailRow>
            <ProcessingDetailRow
              key={`divprocessingfees${data?.transactionId}`}
            >
              {data?.surcharge ? (
                <Title>Surcharge</Title>
              ) : (
                <Title>Processing Fees</Title>
              )}
              {hasSettlementAvailable && (
                <Value>{formatCurrency(data?.processingFees)}</Value>
              )}
              {!hasSettlementAvailable && (
                <Tooltip
                  content={
                    <>
                      <ProcessingToolTip>
                        Data for your processing fees is unavailable. Check back
                        at a later time
                      </ProcessingToolTip>
                    </>
                  }
                >
                  <span>N/A</span>
                </Tooltip>
              )}
            </ProcessingDetailRow>
            <BreakLine />
            <ProcessingDetailRow key={`divtotal${data?.transactionId}`}>
              <Title>Total</Title>
              {hasSettlementAvailable && (
                <Value>{formatCurrency(data?.total)}</Value>
              )}
              {!hasSettlementAvailable && (
                <Tooltip
                  content={
                    <>
                      <ProcessingToolTip>
                        Data for your processing fees is unavailable. Check back
                        at a later time
                      </ProcessingToolTip>
                    </>
                  }
                >
                  <span>N/A</span>
                </Tooltip>
              )}
            </ProcessingDetailRow>
          </DataContainer>
          <DataContainer key={`processordetails${data?.transactionId}`}>
            {hasSettlementPermissions && (
              <ProcessingDetailRow key={`divprocessor${data?.transactionId}`}>
                <Title>Processor</Title>
                <Value>{data?.processor?.toUpperCase()}</Value>
              </ProcessingDetailRow>
            )}
            <ProcessingDetailRow key={`divprofile${data?.transactionId}`}>
              <Title>Transaction Profile</Title>
              <Value>{data?.transactionProfile || ' - '}</Value>
            </ProcessingDetailRow>
          </DataContainer>
        </DrawerSection>
      </Accordion>
    </AccordionWrapper>
  );
};

export default ProcessingDetailsSection;
