import React, {
  useCallback,
  FunctionComponent,
  useContext,
  useRef,
  useState,
  useEffect,
} from 'react';
import { SearchCollection } from '../shared';
import { SupportTicket } from '../../@types';
import { useSearchState, useSupportAuthToken } from '../../hooks';
import { appserviceapi } from '../../api';
import { SupportTicketStatusWidget } from './components';
import { SupportTicketDrawer } from './components/SupportTicketDrawer';
import { getColumns } from './SupportTickets.util';
import { SelectedMerchantStore, TicketsStore } from '../../context';

export const SupportTickets: FunctionComponent = props => {
  const {
    state: { selectedBrandSwitcherOption },
  } = useContext(SelectedMerchantStore);

  const {
    state: { ticketsCount },
  } = useContext(TicketsStore);

  const supportToken = useSupportAuthToken();
  const [detailId, setDetailId] = useSearchState<string>('detailId');
  const [seeMore, setSeeMore] = useState(false);
  const drawerRef = useRef(null);

  const handleClickOutside = e => {
    if (drawerRef.current && drawerRef.current.contains(e.target)) {
      setSeeMore(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const getData = useCallback(
    async query => {
      if (selectedBrandSwitcherOption.includes(',')) {
        delete query.brand;
        query.brands = selectedBrandSwitcherOption.split(',');
      } else {
        delete query.brands;
        query.brand = selectedBrandSwitcherOption;
      }
      const ticketsResponse = await appserviceapi.get(
        supportToken,
        '/support/tickets',
        query
      );

      return ticketsResponse;
    },
    [supportToken, selectedBrandSwitcherOption]
  );

  const getSupportTicket = useCallback(
    (supportTicketId: string): Promise<SupportTicket> => {
      return appserviceapi.get(
        supportToken,
        `/support/ticket/${supportTicketId}`
      );
    },
    [supportToken]
  );

  return (
    <div ref={drawerRef}>
      <SearchCollection<SupportTicket>
        getData={supportToken ? getData : null}
        showSearchBar={true}
        blacklist={['detailId', 'r', 'v']}
        onRowClick={t => setDetailId(t.id)}
        tableHeader={<SupportTicketStatusWidget counts={ticketsCount} />}
        columns={getColumns()}
      />
      <SupportTicketDrawer
        open={!!detailId}
        onClose={() => setDetailId(null)}
        getData={supportToken ? getSupportTicket : null}
        seeMore={seeMore}
        setSeeMore={setSeeMore}
      />
    </div>
  );
};
