import React, { Component } from 'react';

/** 
 * Error boundary catches errors anywhere in their child component tree,
 * log those errors, and display a fallback UI instead of the component tree that crashed.
 * 
 * Use ErrorBoundary to prevent White Screen Of Death (WSOD).
 * 
 * Example:
 * 
 * <ErrorBoundary>
 *   <Widget />
 * </ErrorBoundary>
 */
export class ErrorBoundary extends Component {
  // inital state
  state = {
    error: null
  }

  static getDerivedStateFromError(error) {
    // Update state so next render shows fallback UI.
    return { error: error };
  }

  componentDidCatch(error, info) {
    // log error messages
    console.log('ErrorBoundary: ', error.toString(), info?.componentStack);
  }

  render() {
    const { error } = this.state;
    if (error) {
      // Error path
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details>
            <summary>Error Boundary</summary>
            <p>{error.toString()}</p>
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}
