import React from 'react';
import { Omni } from '@fattmerchantorg/types-omni';
import { DB } from '@fattmerchantorg/types-engine';
import { API } from '@fattmerchantorg/types-engine';
import { ToastContext } from '../../../../../context';
import { handleSubmitFinix } from './ProvisionFinix.util';
import { handleCatanProvision } from './ProvisionCatan.util';
import { handleSubmitFIS } from './ProvisionFIS.util';
import { SubmitApplicationFieldsValues } from './SubmitApplicationForm.types';
import { catanapi } from '../../../../../api/catan';
import { Portfolio } from '../../../../../util/catan.util';
import { Mutator } from 'final-form';

export const handleApplicationSubmit = async (
  formValues: SubmitApplicationFieldsValues,
  portfolios: Portfolio[],
  registration: Omni.Registration,
  auth: Omni.Auth,
  toastContext: Pick<ToastContext, 'toaster' | 'toast'>,
  setIsSaving: React.Dispatch<React.SetStateAction<boolean>>,
  merchant: Omni.Merchant,
  finixCredentialId?: string | null,
  handleCloseModal?: () => void
) => {
  const { processor, forceNewApplication, achOnly } = formValues;
  const { token: authToken } = auth;
  setIsSaving(true);
  switch (processor) {
    case 'CORE_FINIX':
    case 'LITLE_FINIX':
      await handleSubmitFinix(
        formValues.payfacApplication?.value,
        finixCredentialId,
        registration,
        formValues.processingInfo,
        formValues.payfacConfiguration,
        formValues.processor,
        authToken,
        toastContext,
        merchant
      )
        .then(v => handleCloseModal())
        .catch(error => console.error(error));
      break;
    case 'TSYS':
    case 'FDOMAHA':
    case 'PPSFD':
    case 'PPSTSYS':
    case 'FAKE':
    case 'CORE':
    case 'LITLE':
    case 'WEPAY_V2':
    case 'FORTE':
    case 'PAYPAL':
    case 'INFINICEPT':
    case 'APPS':
    case 'APPS_ACH':
      await handleCatanProvision({
        type: processor as DB.ProcessorName,
        registration,
        portfolio: portfolios.find(
          portfolio => portfolio.payfac_id === formValues.portfolioId
        ),
        authToken,
        toastContext,
        // If void, sent `true`, else send `false`
        forceNewApplication: forceNewApplication === 'void',
        processorConfig: {
          ...(achOnly ? { achOnly } : {}),
        },
      }).then(v => handleCloseModal());
      break;
    case 'FIS':
      setIsSaving(true);
      await handleSubmitFIS(
        authToken,
        registration,
        registration.merchant_id,
        toastContext
      )
        .then(v => handleCloseModal())
        .catch(error => console.error(error));
      break;
    default:
      throw new Error('Unable to submit merchant application.');
  }
  setIsSaving(false);
};

// Stub function - replace with real logic and typings
export const getPortfolios = async (
  authToken: string
): Promise<Portfolio[]> => {
  const portfolios = await catanapi.get<{
    traceId: string;
    data: Portfolio[];
  }>(authToken, '/payfacs', { onboarding: true });
  return portfolios.data;
};

type GetOnboardingsQueryParams = {
  processorName?: DB.ProcessorName[];
  state?: DB.OnboardingState[];
  merchantId?: string[];
  payfacId?: string;
};

export const getOnboardings = async (
  authToken: string,
  params: GetOnboardingsQueryParams
): Promise<API.Onboarding[]> => {
  const onboarding = await catanapi.get<{
    traceId: string;
    data: API.Onboarding[];
  }>(authToken, '/onboarding', params);
  return onboarding.data;
};

export const formMutators: {
  [key: string]: Mutator<SubmitApplicationFieldsValues>;
} = {
  /**
   * This mutator sets the property achOnly on the form state.
   * When set to true, it will be added to processorConfig in the payload for POST {underwriting-service}/processor-provision
   * @param args
   * @param state
   * @param utils
   */
  setAchOnly: (args, state, utils) => {
    utils.changeValue(state, 'achOnly', () => args[0]);
  },
};

type MonitorLongLoadingProps = {
  dispatcher: React.Dispatch<React.SetStateAction<boolean>>;
};

export function startLongLoadingMonitor(
  props: MonitorLongLoadingProps
): number {
  const startedAt = Date.now();
  const interval = setInterval(() => {
    const elapsed = Date.now() - startedAt;
    if (elapsed > 20000) {
      props.dispatcher(true);
      endLongLoadingMonitor(interval);
    }
  }, 500);
  return interval;
}

export function endLongLoadingMonitor(id: number) {
  clearInterval(id);
}
