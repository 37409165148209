import React, { FunctionComponent } from 'react';
import {
  SecondaryButton,
  PrimaryButton,
} from '@fattmerchantorg/truffle-components';

import {
  TransactionReturnModal,
  TransactionReturnModalProps,
  TransactionReturnModalContent,
  TransactionReturnModalButtonGroup,
} from './TransactionReturnModal';
import { sendVoidRequested } from './actions';
import { TransactionAsyncDataStatus } from './useDetailsModalHook';
import { ButtonSpinner } from '../../shared';
import { usePrevious } from '../../../hooks';
import { useEffect } from 'react';

export const TransactionVoidModal: FunctionComponent<TransactionReturnModalProps> = props => {
  const { state, dispatch, ...modalProps } = props;
  const status = state.status;
  const prevStatus = usePrevious(status);
  const { onClose } = modalProps;

  useEffect(() => {
    if (
      prevStatus === TransactionAsyncDataStatus.VOIDING &&
      status === TransactionAsyncDataStatus.IDLE
    ) {
      onClose();
    }
  }, [status, prevStatus, onClose]);

  return (
    <TransactionReturnModal {...modalProps}>
      <TransactionReturnModalContent
        title="Void Transaction"
        infoPanelLabel="Payments that have not settled can only be voided for the full amount."
        {...props}
      >
        <TransactionReturnModalButtonGroup>
          <SecondaryButton onClick={modalProps.onClose}>Cancel</SecondaryButton>
          <PrimaryButton
            disabled={state.status !== TransactionAsyncDataStatus.IDLE}
            onClick={() => dispatch(sendVoidRequested())}
          >
            {state.status === TransactionAsyncDataStatus.VOIDING ? (
              <ButtonSpinner />
            ) : null}
            Void Transaction
          </PrimaryButton>
        </TransactionReturnModalButtonGroup>
      </TransactionReturnModalContent>
    </TransactionReturnModal>
  );
};

export default TransactionVoidModal;
