import styled from 'styled-components';

const SubTitle = styled.div`
  display: flex;
  text-align: left;
  font-weight: 700;
  letter-spacing: 0px;
  color: #111e52;
  opacity: 1;
  padding: 16px 0 8px 0;
`;

export default SubTitle;
