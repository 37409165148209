import { states } from '../constants/usaStates';
import { provinces } from '../constants/canadaProvinces';
import {
  ProvinceCode,
  ProvinceName,
  StateCode,
  StateName,
} from '@fattmerchantorg/types-omni';

export interface StateFieldValue {
  abbr: StateCode | ProvinceCode;
  name: StateName | ProvinceName;
}

export function mapStateToSelectOption(state: StateFieldValue) {
  return {
    label: state.name,
    value: state.abbr,
  };
}

export const getStateLabel = (countryCode: string): string => {
  switch (countryCode) {
    case 'USA':
      return 'State';
    case 'CAN':
      return 'Province';
  }
  return 'State';
};

export const getStateValues = (countryCode: string): StateFieldValue[] => {
  switch (countryCode) {
    case 'USA':
      return states;
    case 'CAN':
      return provinces;
  }
  return [];
};
