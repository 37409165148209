import React, { FC } from 'react';
import { StatusPill } from '@fattmerchantorg/truffle-components';
import {
  calculateTransactionType,
  formatCapitalCase,
} from '../../../util/format.util';
import { StatusPillStatus } from '@fattmerchantorg/truffle-components/dist/StatusPill/StatusPill';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHandPaper } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { mapTransactionType } from '../../../util/transaction.util';
import { TransactionData } from '../../shared/AdvancedDetailDrawer/AdvancedDetailDrawer.types';

library.add(faHandPaper);
/* This gets rendered on the payments table AND in the payment detail drawer next to the total amount */
export const TransactionStatusPill: FC<{
  transaction: Partial<TransactionData>;
  location?;
  isSettlement?: boolean;
  /**
   * @deprecated Does nothing for compatability purposes. Dispute link is now rendered separately.
   */
  renderDisputeLink?: boolean;
}> = props => {
  const { type, is_voided, total_refunded, dispute } = props.transaction;
  const location = props.location || 'table';
  const isSettlement = props.isSettlement || false;
  let calculatedType = calculateTransactionType(
    type,
    is_voided,
    total_refunded
  );
  let status: StatusPillStatus = 'success';
  let icon: FontAwesomeIconProps['icon'] | null = null;
  if (dispute?.id) {
    //set to the most likely/common types
    if (
      [
        'PENDING',
        'EVIDENCE_UPLOADED',
        'UPLOAD_FAILED',
        'OPEN',
        'PREARBITRATION',
      ].includes(dispute?.status)
    ) {
      status = 'error';
      calculatedType = 'In Dispute';
      icon = ['fas', 'hand-paper'];
    } else if (dispute?.status === 'INQUIRY' && location !== 'drawer') {
      status = 'error';
      calculatedType = 'In Dispute';
      icon = ['fas', 'exclamation-triangle'];
    } else if (dispute.status === 'LOST') {
      status = 'error';
      calculatedType = 'Dispute Lost';
      icon = ['fas', 'times'];
    } else if (dispute.status === 'WON') {
      status = 'success';
      calculatedType = 'Dispute Won';
      icon = ['fas', 'check'];
    } else {
      //No condition met, so don't render as a dispute pill
    }
  }
  if (calculatedType === 'voided' || calculatedType === 'refunded') {
    status = 'warning';
  }
  if (isSettlement) {
    status = mapTransactionType(type).status;
    if (calculatedType === 'HOLD') {
      calculatedType = 'HELD';
    }
    if (calculatedType === 'RELEASE') {
      calculatedType = 'RELEASED';
    }
  }
  return (
    <StatusPill
      data-testid="ui-transaction-type"
      status={status}
      label={formatCapitalCase(calculatedType)}
      icon={icon}
    />
  );
};

export default TransactionStatusPill;
