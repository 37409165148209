import { Billing } from '@fattmerchantorg/types-engine/DB';
import { useCallback, useContext, useState } from 'react';
import { catanapi } from '../../api';
import {
  AuthStore,
  ModalContext,
  closeModal,
  fetchSelectedMerchantBillingProfiles,
  fetchSelectedMerchantReserves,
} from '../../context';
import { useToaster, useRouteMatchMerchantId } from '../../hooks';
import { useHistory } from 'react-router-dom';
import { SelectedMerchantStore } from '../../context';

interface HookProps<T> {
  companyId: string;
  merchantId: string;
  payloadFormatter: (fields: T) => Partial<Billing>;
  shouldCloseModalOnSubmit?: boolean;
}

export const useReserveFormSubmission = <T,>(props: HookProps<T>) => {
  const { companyId, shouldCloseModalOnSubmit = true } = props;
  const {
    state: { auth },
  } = useContext(AuthStore);
  const [isSaving, setIsSaving] = useState(false);
  const { modalDispatch } = useContext(ModalContext);
  const merchantId = useRouteMatchMerchantId();
  const history = useHistory();
  const userId = auth?.user?.id;
  const { state: authState } = useContext(AuthStore);
  const { authToken } = authState;
  const { toaster, toast } = useToaster();
  const { dispatch } = useContext(SelectedMerchantStore);

  const onSubmit = useCallback(
    async (formValues: T) => {
      if (!formValues) return;
      setIsSaving(true);
      const payload: Partial<Billing> = {
        state: 'ACTIVE',
        type: 'RESERVES',
        company_id: companyId,
        reserve_flat_amount: formValues['reserve_flat_amount']
          ? typeof formValues['reserve_flat_amount'] === 'string'
            ? Number(
                formValues['reserve_flat_amount'].replace(/[^0-9.-]+/g, '')
              )
            : typeof formValues['reserve_flat_amount'] === 'number'
            ? Number(formValues['reserve_flat_amount'])
            : 0
          : 0,
        reserve_percent_volume:
          formValues['reserve_percent_volume'] >= 1
            ? formValues['reserve_percent_volume'] / 100
            : formValues['reserve_percent_volume'],
        reserve_percent_days: formValues['reserve_percent_days'] ?? 0,
        updated_by: userId,
      };

      try {
        await catanapi.post(authToken, `/billing`, payload);
        dispatch(fetchSelectedMerchantReserves(companyId));
        dispatch(fetchSelectedMerchantBillingProfiles(companyId));
        if (shouldCloseModalOnSubmit) {
          modalDispatch(closeModal());
        }
        toaster(
          toast.success(
            `A Capped Reserve of $${formValues['reserve_flat_amount']} has been created`,
            'RESERVE CREATED'
          )
        );
        history.push({ pathname: '/' });
        history.replace({ pathname: `/merchant/${merchantId}/businessinfo` });
      } catch (error) {
        toaster(toast.error(error, 'There was a problem saving your changes.'));
      }
      setIsSaving(false);
    },
    [
      companyId,
      userId,
      authToken,
      dispatch,
      shouldCloseModalOnSubmit,
      toaster,
      toast,
      history,
      merchantId,
      modalDispatch,
    ]
  );
  const onUpdate = useCallback(
    async (formValues: T) => {
      if (!formValues) return;
      setIsSaving(true);

      const billings: Partial<Billing> = {
        billing_id: formValues['billing_id'],
        reserve_flat_amount: formValues['reserve_flat_amount']
          ? Number(formValues['reserve_flat_amount'])
          : null,
        reserve_percent_volume:
          formValues['reserve_percent_volume'] < 1
            ? Number(formValues['reserve_percent_volume'])
            : formValues['reserve_percent_volume'] / 100,
        reserve_percent_days: formValues['reserve_percent_days'],
        updated_by: userId,
      };
      try {
        await catanapi.patch(authToken, `/billing`, { billings: [billings] });

        if (shouldCloseModalOnSubmit) {
          modalDispatch(closeModal());
        }
        window.location.reload();
        toaster(
          toast.success(`Your Reserve has been edited`, 'RESERVE EDITED')
        );
      } catch (error) {
        toaster(toast.error(error, 'There was a problem saving your changes.'));
      }
      setIsSaving(false);
    },
    [userId, authToken, shouldCloseModalOnSubmit, toaster, toast, modalDispatch]
  );
  return { onSubmit, onUpdate, isSaving };
};
