import React from 'react';
import styled, { withTheme } from 'styled-components';

export interface AvatarProps {
  color?: string;
}

const AvatarDiv = withTheme(styled.div<AvatarProps>`
  color: ${({ theme }) => theme.black};
  border-radius: 50%;
  background-color: ${({ theme, color }) =>
    color ?? theme.colors.core.yellow[400].hex};
  height: 34px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 5px;
  margin-right: 8px;
`);

export const Avatar = (props: { name: string; color?: string }) => {
  const { name, color } = props;
  const initials = name.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g);

  return <AvatarDiv color={color}>{initials}</AvatarDiv>;
};
