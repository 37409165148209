import React, {
  Context,
  createContext,
  useReducer,
  useContext,
  useEffect
} from 'react';
import {
  TestModeContextProps,
  TestModeContext,
  TestModeAction,
  TestModeState,
  TestModeActionType
} from './TestMode.types';
import { AuthStore } from '../auth';
import { updateAutomaticSwitchModalStatus } from './TestMode.actions';

export const TestModeStore = createContext({} as TestModeContext) as Context<
  TestModeContext
>;

const initialState: TestModeState = {
  isAutomaticRedirectModalOpen: false
};

type Reducer = (state: TestModeState, action: TestModeAction) => TestModeState;

const storeReducer: Reducer = (state, action) => {
  if (
    action.type === TestModeActionType.UPDATE_AUTOMATIC_REDIRECT_MODAL_STATUS
  ) {
    return {
      ...state,
      isAutomaticRedirectModalOpen: action.payload
    };
  }

  return state;
};

export const TestModeProvider = (props: TestModeContextProps) => {
  const [state, dispatch] = useReducer(storeReducer, initialState);

  const { state: authState } = useContext(AuthStore);
  const authEffectType = authState?.effect?.type;

  useEffect(() => {
    if (authEffectType === 'UPDATE_AUTH_WITH_DIALOG') {
      // After auto-toggle of user's mode is successful, trigger acceptance dialog.
      dispatch(updateAutomaticSwitchModalStatus(true));
    }
  }, [authEffectType]);

  const localContext: TestModeContext = {
    state,
    dispatch
  };

  return (
    <TestModeStore.Provider value={localContext}>
      {props.children}
    </TestModeStore.Provider>
  );
};
