import React, { FC, useState, useEffect } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import MockModalSubTitle from './MockModalSubtitle';

export const AccountStatusSelection: FC<{
  handleSelection: Function;
  defaultStatus: string;
}> = ({ handleSelection, defaultStatus }) => {
  const [status, setStatus] = useState<string>();

  // Set default status.
  useEffect(() => {
    setStatus(defaultStatus);
    handleSelection(defaultStatus);
  }, [defaultStatus, handleSelection]);

  const handleStatusChange = (x: { target: { value: string } }) => {
    const status = x.target.value;
    handleSelection(status);
    setStatus(status);
  };

  const tooltip_id = 'account-status-info';
  const tooltip_copy =
    'The account status is used in production for accounts that have already made it through underwriting approval.  Pending is the default value before an account is configured for processing.  Processing can only occur when this status is set as active.  Inactive is used to denote an account that has been shut down and can no longer process.';

  return (
    <>
      <MockModalSubTitle>
        Account Status{' '}
        <i
          id={tooltip_id}
          data-testid={tooltip_id}
          className="fas fa-info-circle"
        />
        <UncontrolledTooltip delay={0} target={tooltip_id}>
          {tooltip_copy}
        </UncontrolledTooltip>
      </MockModalSubTitle>

      {['ACTIVE', 'PENDING', 'INACTIVE'].map(key => (
        <label key={key} className="d-block mb-0">
          <input
            type="radio"
            name="account-status"
            value={key}
            onChange={handleStatusChange}
            className="mr-2"
            checked={status === key}
          />
          {key}
        </label>
      ))}
    </>
  );
};
