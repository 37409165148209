import { ActionTypes } from './types';
import { Transaction } from '@fattmerchantorg/types-omni';

/* Actions */
interface RequestAction<T extends keyof typeof ActionTypes, P = never> {
  type: T;
  payload?: P;
}
interface SuccessAction<T extends keyof typeof ActionTypes> {
  type: T;
  payload: Transaction;
}
interface FailedAction<T extends keyof typeof ActionTypes> {
  type: T;
  payload: Error;
}

type FetchRequestedAction = RequestAction<typeof ActionTypes.FETCH_REQUESTED>;
type FetchSuccessAction = SuccessAction<typeof ActionTypes.FETCH_SUCCEEDED>;
type FetchFailedAction = FailedAction<typeof ActionTypes.FETCH_FAILED>;

type VoidRequestedAction = RequestAction<typeof ActionTypes.VOID_REQUESTED>;
type VoidSuccessAction = SuccessAction<typeof ActionTypes.VOID_SUCCEEDED>;
type VoidFailedAction = FailedAction<typeof ActionTypes.VOID_FAILED>;

type RefundRequestedAction = RequestAction<
  typeof ActionTypes.REFUND_REQUESTED,
  number
>;
type RefundSuccessAction = SuccessAction<typeof ActionTypes.REFUND_SUCCEEDED>;
type RefundFailedAction = FailedAction<typeof ActionTypes.REFUND_FAILED>;

export type Action =
  | FetchRequestedAction
  | FetchSuccessAction
  | FetchFailedAction
  | VoidRequestedAction
  | VoidSuccessAction
  | VoidFailedAction
  | RefundRequestedAction
  | RefundSuccessAction
  | RefundFailedAction;

/** Action Creators */
export function sendTransactionIDChanged(): Action {
  return { type: ActionTypes.FETCH_REQUESTED };
}
export function sendFetchSucceeded(payload: Transaction): Action {
  return { type: ActionTypes.FETCH_SUCCEEDED, payload };
}
export function sendFetchFailed(payload: Error): Action {
  return { type: ActionTypes.FETCH_FAILED, payload };
}
export function sendVoidRequested(): Action {
  return { type: ActionTypes.VOID_REQUESTED };
}
export function sendVoidSucceeded(payload: Transaction): Action {
  return { type: ActionTypes.VOID_SUCCEEDED, payload };
}
export function sendVoidFailed(payload: Error): Action {
  return { type: ActionTypes.VOID_FAILED, payload };
}
export function sendRefundRequested(payload: number): Action {
  return { type: ActionTypes.REFUND_REQUESTED, payload };
}
export function sendRefundSucceeded(payload: Transaction): Action {
  return { type: ActionTypes.REFUND_SUCCEEDED, payload };
}
export function sendRefundFailed(payload: Error): Action {
  return { type: ActionTypes.REFUND_FAILED, payload };
}
