import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link as RRDLink, RouteComponentProps } from 'react-router-dom';
import currency from 'currency.js';
import { Text } from '@fattmerchantorg/truffle-components';
import {
  CollectionPagination,
  GetStatementAchRejectsResponse,
} from '@fattmerchantorg/types-omni';
import { TableSectionV3 } from '../styles/table-section-component-v3';
import { StatementV3Props } from '../util/statement-v3-utils';
import {
  useAsyncEffect,
  useAuthToken,
  useSearchState,
} from '../../../../hooks';
import { queryapi } from '../../../../api';
import { format as formatDate } from '../../../../util/date.util';
import { TableNullState, linkStyles } from '../styles';
import {
  LoadingState,
  useStatementLoadingState,
} from '../../../../context/statements';
import { LoadingRows } from '../../shared/loading-rows';
import { LoadMoreRow } from '../../shared/load-more-row';
import { TextContainer } from '../../components';

const Link = styled(RRDLink)`
  ${linkStyles}
` as typeof RRDLink;

const Table = styled(TableSectionV3)`
  /** Unlike most of the other sections in the fee statement, the second-to-the-leftmost column should be left aligned in this table */
  > table > tbody > tr > td,
  > table > thead > tr > th {
    &:nth-child(2) {
      text-align: left;
    }
  }

  > table > tbody > tr > td,
  > table > tfoot > tr > td {
    &[data-is-negative='true'] {
      color: ${props => props.theme.colors.status.red['500'].hex};
    }
  }

  > table > tfoot > tr > td {
    font-weight: 700;
  }
`;

export type AchRejectsSectionProps = StatementV3Props & {
  location: RouteComponentProps['location'];
};

export const AchRejectsSection: React.FC<AchRejectsSectionProps> = props => {
  const { startDate, endDate } = props;
  const authToken = useAuthToken();
  const [perPage = '1000'] = useSearchState('achRejectsPerPage');

  const [page, setPage] = useState(1);
  const [data, setData] =
    useState<GetStatementAchRejectsResponse['data']>(null);
  const [summary, setSummary] =
    useState<GetStatementAchRejectsResponse['summary']>(null);
  const [pagination, setPagination] = useState<CollectionPagination>(null);

  const {
    achRejectsLoading,
    setAchRejectsLoading,
    statementLoadingState: { isFirstLoad },
  } = useStatementLoadingState();
  const isLoading = achRejectsLoading === LoadingState.Loading;

  useAsyncEffect(async () => {
    if (isLoading && authToken && startDate && endDate) {
      try {
        const query = { startDate, endDate, perPage, page };
        const response = await queryapi.get<GetStatementAchRejectsResponse>(
          authToken,
          'statement/v3/ach-rejects',
          query
        );
        const { data, summary, ...pagination } = response;

        if (page === 1) {
          // if the user is loading the first page, simply set the data
          setData(data);
        } else {
          // if the user is loading any page other than the first, append the data
          // in order to show a continuous list of records
          setData(previousData => [...previousData, ...data]);
        }

        setSummary(summary);
        setPagination(pagination);
        setAchRejectsLoading(LoadingState.Completed);
      } catch (error) {
        setAchRejectsLoading(LoadingState.Failed);
      }
    }
  }, [isLoading, authToken, startDate, endDate, perPage, page]);

  useEffect(() => {
    // trigger another fetch if the user clicks the "Load X More" button
    setAchRejectsLoading(LoadingState.Loading);
  }, [page, setAchRejectsLoading]);

  useEffect(() => {
    /**
     * Reset the data if the user changes the achRejectsPerPage query param
     * This is necessary to prevent duplicate rows
     */
    setData(null);
    /**
     * Reset the page if the user changes the achRejectsPerPage query param
     */
    setPage(1);
    /**
     * Refetch the data if the user changes the achRejectsPerPage query param
     */
    setAchRejectsLoading(LoadingState.Loading);
  }, [perPage, setAchRejectsLoading]);

  /**
   * If we do not yet have data, we can simply return null here
   * since this component does not have an individual loading state
   */
  if (!data || !summary) return null;

  return (
    <Table>
      <TextContainer fontWeight="bold" paddingBottom="8px">
        <Text as="h5">ACH Rejects</Text>
      </TextContainer>
      <table data-testid="statementV3-achRejects">
        <thead>
          <tr>
            <th>ID</th>
            <th>Date</th>
            <th>Clawback Amount</th>
            <th>Fee</th>
          </tr>
        </thead>
        <tbody>
          {data.map((datum, i) => (
            <tr key={i} className="tableRows">
              <td>
                <Link
                  /**
                   * Note: This links to the original transaction instead of the ACH reject transaction itself.
                   *       The original transaction will display as ACH rejected and the ACH reject itself will appear in the activity section.
                   */
                  to={`/activity?id=${datum.original_transaction_id}`}
                  target="_blank"
                >
                  {datum.ach_reject_transaction_id}
                </Link>
              </td>
              <td>
                {/* TODO: Change format to MM/dd/yyyy when we update date-fns */}
                {formatDate(
                  new Date(datum.ach_reject_transaction_created_at),
                  'MM/dd/yyyy'
                )}
              </td>
              {datum.ach_reject_transaction_timing === 'pre_payout' ? (
                <td>--</td>
              ) : (
                <td data-is-negative={datum.ach_reject_transaction_amount > 0}>
                  {currency(-datum.ach_reject_transaction_amount).format()}
                </td>
              )}
              <td data-is-negative={datum.ach_reject_fee_amount > 0}>
                {currency(-datum.ach_reject_fee_amount).format()}
              </td>
            </tr>
          ))}
          {isLoading && !isFirstLoad ? (
            <LoadingRows pagination={pagination} tdWidths={[185, 53, 36, 36]} />
          ) : null}
        </tbody>

        {data.length ? (
          <tfoot>
            <LoadMoreRow
              colSpan={4}
              disabled={isLoading}
              pagination={pagination}
              isLoading={isLoading}
              onClick={() => setPage(page => page + 1)}
            />
            <tr data-is-first-load={isFirstLoad}>
              <td>Total</td>
              <td />
              {/* Treat the below amounts as negative */}
              <td data-is-negative={summary.ach_reject_transaction_amount > 0}>
                {currency(-summary.ach_reject_transaction_amount).format()}
              </td>
              <td data-is-negative={summary.ach_reject_fee_amount > 0}>
                {currency(-summary.ach_reject_fee_amount).format()}
              </td>
            </tr>
          </tfoot>
        ) : null}
      </table>
      {data.length ? null : <TableNullState>No ACH Rejects</TableNullState>}
    </Table>
  );
};
