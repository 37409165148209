import React from 'react';
import styled from 'styled-components';
import {
  Accordion,
  StatusPill,
  TableBody,
  TableRow,
} from '@fattmerchantorg/truffle-components';
import { StatusPillStatus } from '@fattmerchantorg/truffle-components/dist/StatusPill/StatusPill';
import { DisputesSectionData } from '../AdvancedDetailDrawer.types';
import { DisputeStatus } from '@fattmerchantorg/types-omni';
import {
  formatCurrency,
  formatCapitalCase,
  formatReadableDate,
} from '../../../../util/format.util';
import { daysDifference } from '../../../../util/date.util';
import { AccordionWrapper } from '../sharedComponents';

type DisputesDetailsSectionProps = {
  id: string;
  name: string;
  data: DisputesSectionData;
};

const TableAccordion = styled.table`
  width: 100%;
`;

const TableItem = styled.div`
  border: none;
  color: #f8f9fe;
  padding: 0px 8px;
  font-size: 14px;
  font-weight: 400px;
  line-height: 20px;
`;

function mapDisputeStatus(
  status: DisputeStatus,
  hasDeadlinePassed
): {
  label: string;
  status: StatusPillStatus;
} {
  switch (status) {
    case 'WON':
      return {
        label: `${formatCapitalCase(status)}`,
        status: 'success',
      };
    case 'LOST':
    case 'UPLOAD_FAILED':
      return {
        label: `${formatCapitalCase(status)}`,
        status: 'error',
      };
    case 'PENDING':
    case 'INQUIRY':
    case 'OPEN':
    case 'EVIDENCE_UPLOADED':
    case 'PREARBITRATION':
      return {
        label: hasDeadlinePassed ? 'LOST' : `${formatCapitalCase(status)}`,
        status: hasDeadlinePassed ? 'error' : 'neutral',
      };
  }
}

const DisputesDetailsSection: React.FunctionComponent<
  DisputesDetailsSectionProps
> = props => {
  const {
    data,
    name = 'Disputes',
    id = 'disputes-details-drawer-section',
  } = props;

  if (!data) return null;

  const {
    disputeId,
    merchantId,
    status,
    reason,
    createdAt,
    respondBy,
    amount,
    hasDeadlinePassed,
  } = data; // data === transaction.dispute

  return (
    disputeId !== undefined && (
      <AccordionWrapper id={id}>
        <Accordion
          contentStyle={{ padding: '0px' }}
          title={name}
          disabled={!disputeId}
          asyncContent={true}
        >
          <a
            data-testid="payment-dispute-link"
            href={`/#/merchant/${merchantId}/disputes?detailId=${disputeId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <TableAccordion className="table-accordion">
              <TableBody>
                <TableRow style={{ border: 'none' }} key={0}>
                  <td>
                    <TableItem>
                      <StatusPill
                        {...mapDisputeStatus(status, hasDeadlinePassed)}
                      />
                    </TableItem>
                  </td>
                  <td>
                    <TableItem>{formatCapitalCase(reason)}</TableItem>
                  </td>
                  <td>
                    <TableItem>{formatReadableDate(createdAt, true)}</TableItem>
                  </td>
                  <td>
                    <TableItem>
                      {['WON', 'LOST'].includes(status) || hasDeadlinePassed
                        ? 'CLOSED'
                        : `${daysDifference(
                            new Date(respondBy)
                          )} Days to Respond`}
                    </TableItem>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    <TableItem>{formatCurrency(amount)}</TableItem>
                  </td>
                </TableRow>
              </TableBody>
            </TableAccordion>
          </a>
        </Accordion>
      </AccordionWrapper>
    )
  );
};

export default DisputesDetailsSection;
