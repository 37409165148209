import React, { FC, useEffect, useState } from 'react';
import { UnderwritingStatus } from '../../../../@types/UnderwritingStatus';
import MockModalSubTitle from './MockModalSubtitle';
import {
  underwritingStatusApprovedReasons,
  underwritingStatusPendedReasons,
  underwritingStatusRejectedReasons,
} from '../../../../util/underwriting.util';

export const ReasonsSelectionSection: FC<{
  status: string;
  handleSelection: Function;
  defaultReasons: any[];
  defaultStatus: string;
}> = ({ status, handleSelection, defaultReasons, defaultStatus }) => {
  const [checkedReasons, setCheckedReasons] = useState([]);

  // Set default reasons if they exist.
  useEffect(() => {
    if (defaultReasons) {
      setCheckedReasons(defaultReasons.map(x => x.key));
    }
  }, [defaultReasons, status]);

  // Reset reasons on status change.
  useEffect(() => {
    if (status !== defaultStatus) {
      setCheckedReasons([]);
    }
  }, [status, defaultStatus]);

  // Bubble up when reasons are checked on/off.
  useEffect(() => {
    handleSelection(checkedReasons);
  }, [checkedReasons, handleSelection]);

  // Display available reasons based on status.
  let availableReasonsList: object;

  switch (status) {
    case UnderwritingStatus.APPROVED:
      availableReasonsList = underwritingStatusApprovedReasons;
      break;
    case UnderwritingStatus.PENDED:
      availableReasonsList = underwritingStatusPendedReasons;
      break;
    case UnderwritingStatus.REJECTED:
      availableReasonsList = underwritingStatusRejectedReasons;
      break;
    default:
      availableReasonsList = null;
      break;
  }

  const handleReasonsChange = (x: any) => {
    const reason = x.target.value;

    if (x.target.checked) {
      // Add reason.
      setCheckedReasons((reasons: []) => {
        const currentReasons = [...reasons] || [];
        currentReasons.push(reason);
        return currentReasons;
      });
    } else {
      // Remove reason.
      setCheckedReasons((reasons: []) => {
        const currentReasons = [...reasons] || [];
        const index = currentReasons.indexOf(reason);
        currentReasons.splice(index, 1);
        return currentReasons;
      });
    }
  };

  return (
    availableReasonsList && (
      <>
        <MockModalSubTitle>
          Application {UnderwritingStatus[status]} Reason
        </MockModalSubTitle>
        {Object.keys(availableReasonsList).map(reason => (
          <label key={reason + '_' + status} className="d-block mb-0">
            <input
              type="checkbox"
              name="reasons"
              value={reason}
              className="mr-2"
              onChange={handleReasonsChange}
              checked={checkedReasons.indexOf(reason) > -1}
            />
            {availableReasonsList[reason]}
          </label>
        ))}
      </>
    )
  );
};
