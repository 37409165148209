import { LogoPreview } from './LogoPreview';
import {
  SmallPrimaryButton,
  SmallSecondaryButton,
  TextField,
  Tooltip,
} from '@fattmerchantorg/truffle-components';
import { Brand, User } from '@fattmerchantorg/types-omni';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle, faStar } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { isValidEmail, isValidHexCodeColor } from '../../util/validator.util';
import { BoldText } from '../truffle-dashboard/components/shared/BoldText';
import { FileUploader } from './FileUploader';
import { Field, Form as FinalForm } from 'react-final-form';
import { SketchPicker } from 'react-color';
import {
  useAuthToken,
  useClickaway,
  usePermissions,
  useToaster,
} from '../../hooks';

import {
  ColorDiv,
  ColorPickerContainer,
  ColorTextField,
  ContentSeparator,
  DividingLine,
  FormLabel,
  InputSubheader,
  LogoContainer,
  MainContent,
  SettingsHeaderContent,
  SettingsHeaderContentActions,
  SettingsHeaderContentTitle,
  SettingsHeaderContentTitleText,
  SettingsHeaderRow,
  SettingsSubheader,
  StarIcon,
  StyledLogoContainer,
  StyledSpanError,
  StyledSpanInfo,
  TextFieldWrapper,
  TextFieldWrapperColor,
  Wrapper,
  WrapperMarginTop,
} from './SettingsPagesStylings';
import { appserviceapi, coreapi } from '../../api';
import { pick } from '../../util/object.util';
import { faStar as fullStar } from '@fortawesome/pro-solid-svg-icons';
import { AuthStore, PermissionsStore, updateAuthUser } from '../../context';

export interface FormValues {
  platformName?: string;
  externalName?: string;
  primaryColor?: string;
  secondaryColor?: string;
  staxPrimary?: string;
  supportEmail?: string;
  noReplyEmail?: string;
  mailerEmail?: string;
  signupEmail?: string;
  darkLogo?: File;
  logoSquare?: File;
  logoPngWideWhite?: File;
  brandName?: string;
  primaryBrand?: boolean;
}

const isRequired = (value: string): string | undefined => {
  return value ? undefined : 'Required';
};

const validateEmail = (email: string): string | undefined => {
  return email && isValidEmail(email)
    ? undefined
    : 'Enter a Valid Email Address';
};

const isValidColorHexCode = (color: string): string | undefined => {
  return color && isValidHexCodeColor(color)
    ? undefined
    : 'Please enter a valid hex code';
};

interface Props {
  currentBrand: Brand;
  getBrand: () => void;
}

export const BrandData: FunctionComponent<Props> = ({
  currentBrand,
  getBrand,
}) => {
  const { toast, toaster } = useToaster();
  const authToken = useAuthToken();
  const [isSaving, setIsSaving] = useState(false);
  const { permit } = usePermissions();
  const { dispatch: authDispatch, state } = useContext(AuthStore);
  const {
    state: { brands },
  } = useContext(PermissionsStore);

  const [isPrimaryColorPickerOpen, setIsPrimaryColorPickerOpen] =
    useState(false);
  const [isSecondaryColorPickerOpen, setIsSecondaryColorPickerOpen] =
    useState(false);
  const [isStarHover, setIsStarHover] = useState(false);
  const canWrite = permit('godview', 'branding', 'write');
  const currentUser = state?.auth?.user;

  const handleSubmit = useCallback(
    async (formValues: FormValues) => {
      if (!canWrite || !formValues) return;

      setIsSaving(true);

      try {
        if (formValues.primaryBrand && brands?.length > 1) {
          const brandsNames = brands
            .map(br => br?.name)
            .filter(br => br !== currentBrand['brandName']);
          brandsNames.unshift(currentBrand['brandName']);
          await coreapi.put<User>(authToken, `/user/${currentUser?.id}`, {
            brands: brandsNames,
          });
          const { user } = await coreapi.get(authToken, '/self');
          authDispatch(updateAuthUser(user));
        }

        const data = pick(
          formValues,
          'platformName',
          'externalName',
          'primaryColor',
          'staxPrimary',
          'secondaryColor',
          'signupEmail',
          'noReplyEmail',
          'supportEmail',
          'mailerEmail'
        );
        data.primaryColor = data?.primaryColor?.substring(1);
        data.secondaryColor = data?.secondaryColor?.substring(1);
        data.staxPrimary = data?.primaryColor;

        const formData = new FormData();
        formData.append('darkLogo', formValues?.darkLogo);
        formData.append('logoSquare', formValues?.logoSquare);
        formData.append('logoPngWideWhite', formValues?.logoPngWideWhite);
        formData.append('data', JSON.stringify(data));

        await appserviceapi.put(
          authToken,
          `/brand/data/${formValues.brandName}`,
          formData
        );
        getBrand();
        toaster(toast.success('Changes successfully saved', 'Saved'));
      } catch (error) {
        toaster(toast.error(error, 'There was a problem saving your changes.'));
      }
      setIsSaving(false);
    },
    [
      canWrite,
      authToken,
      getBrand,
      toaster,
      toast,
      brands,
      currentBrand,
      currentUser,
      authDispatch,
    ]
  );

  const initialFormData = useMemo(() => {
    return {
      ...currentBrand,
      darkLogo: undefined,
      logoSquare: undefined,
      logoPngWideWhite: undefined,
      primaryColor: `#${currentBrand.primaryColor}`,
      secondaryColor: `#${currentBrand.secondaryColor}`,
      primaryBrand: currentBrand['brandName'] === currentUser.brand,
    };
  }, [currentBrand, currentUser]);

  const clickAwayPrimaryColor = useClickaway(() => {
    setIsPrimaryColorPickerOpen(false);
  }, 'brand-primary-color');

  const clickAwaySecondaryColor = useClickaway(() => {
    setIsSecondaryColorPickerOpen(false);
  }, 'brand-secondary-color');

  return (
    <>
      <FinalForm<FormValues>
        onSubmit={handleSubmit}
        initialValues={initialFormData}
      >
        {({ handleSubmit, dirty, form, hasValidationErrors, values }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <SettingsHeaderContent>
                <SettingsHeaderRow>
                  <SettingsHeaderContentTitle>
                    <SettingsHeaderContentTitleText>
                      Brand
                      {brands?.length > 1 && (
                        <Field name="primaryBrand" parse={() => true}>
                          {props => (
                            <button
                              disabled={props.meta.initial || !canWrite}
                              type="button"
                              style={{ all: 'unset' }}
                              onClick={props.input.onChange}
                              onMouseEnter={() => setIsStarHover(true)}
                              onMouseLeave={() => setIsStarHover(false)}
                            >
                              {!props.input.value ? (
                                <StarIcon
                                  icon={faStar as IconProp}
                                  isHovered={isStarHover}
                                />
                              ) : (
                                <StarIcon
                                  icon={fullStar as IconProp}
                                  isSelected={props.input.value}
                                />
                              )}
                            </button>
                          )}
                        </Field>
                      )}
                    </SettingsHeaderContentTitleText>
                    {canWrite ? (
                      <SettingsSubheader>
                        Change your display names, colors, logos etc.
                      </SettingsSubheader>
                    ) : null}
                  </SettingsHeaderContentTitle>

                  {canWrite && (
                    <SettingsHeaderContentActions>
                      <SmallSecondaryButton
                        onClick={e => {
                          e.preventDefault();
                          form.reset();
                        }}
                      >
                        Cancel
                      </SmallSecondaryButton>
                      <SmallPrimaryButton
                        type="submit"
                        disabled={!dirty}
                        loading={isSaving}
                      >
                        Save
                      </SmallPrimaryButton>
                    </SettingsHeaderContentActions>
                  )}
                </SettingsHeaderRow>
              </SettingsHeaderContent>

              <MainContent>
                <ContentSeparator>
                  <BoldText
                    fontSize={18}
                    marginBottom={8}
                    marginRight={8}
                    lineHeight={24}
                  >
                    Display Names
                  </BoldText>
                  {canWrite ? (
                    <Tooltip
                      content={
                        'Set how you would like the platform\n would be displayed to sub merchants'
                      }
                    >
                      <FontAwesomeIcon
                        size="sm"
                        icon={faInfoCircle as IconProp}
                      />
                    </Tooltip>
                  ) : null}
                </ContentSeparator>
                <DividingLine />

                {canWrite ? (
                  <>
                    <BoldText fontSize={14} marginBottom={4}>
                      Set Platform Name
                    </BoldText>
                    <InputSubheader>
                      Set a platform name to be shown on all areas of the
                      website.
                    </InputSubheader>

                    <Field name="platformName" validate={isRequired}>
                      {props => (
                        <TextFieldWrapper>
                          <TextField
                            {...props.input}
                            className="brand-platform-name"
                            placeholder="Stax Pay"
                            error={!!props.meta.error && props.meta.touched}
                            helperText={
                              props.meta.touched ? props.meta.error : null
                            }
                          />
                        </TextFieldWrapper>
                      )}
                    </Field>
                  </>
                ) : (
                  <>
                    <FormLabel>Platform Name</FormLabel>
                    <div>{initialFormData.displayName ?? '-'}</div>
                  </>
                )}

                {canWrite ? (
                  <>
                    <FormLabel>Set External Name</FormLabel>
                    <InputSubheader>{`Set an external name to be shown through email (Ex: From {External Name}).`}</InputSubheader>
                    <Field name="externalName" validate={isRequired}>
                      {props => (
                        <TextFieldWrapper>
                          <TextField
                            {...props.input}
                            className="brand-external-name"
                            placeholder="Stax Pay"
                            error={!!props.meta.error && props.meta.touched}
                            helperText={
                              props.meta.touched ? props.meta.error : null
                            }
                          />
                        </TextFieldWrapper>
                      )}
                    </Field>
                  </>
                ) : (
                  <>
                    <FormLabel>External Name</FormLabel>
                    <div>{initialFormData.externalName ?? '-'}</div>
                  </>
                )}

                <WrapperMarginTop>
                  <ContentSeparator>
                    <BoldText
                      fontSize={18}
                      marginBottom={8}
                      marginRight={8}
                      lineHeight={24}
                    >
                      Colors
                    </BoldText>
                    {canWrite ? (
                      <Tooltip
                        content={
                          'Set the colors for your \n sub merchants platform'
                        }
                      >
                        <FontAwesomeIcon
                          size="sm"
                          icon={faInfoCircle as IconProp}
                        />
                      </Tooltip>
                    ) : null}
                  </ContentSeparator>

                  <DividingLine />

                  {canWrite ? (
                    <>
                      <FormLabel>Set Primary Color</FormLabel>
                      <InputSubheader>
                        Set a primary color to be shown on customer-facing
                        headers, menu-items, and buttons.
                      </InputSubheader>
                      <Field name="primaryColor" validate={isValidColorHexCode}>
                        {props => (
                          <TextFieldWrapperColor ref={clickAwayPrimaryColor}>
                            <ColorTextField
                              {...props.input}
                              onBlur={event => props.input.onBlur(event)}
                              className="brand-primary-color"
                              inputPrefix={
                                <ColorDiv color={props.input.value} />
                              }
                              placeholder="#B93BE4"
                              onClick={() => setIsPrimaryColorPickerOpen(true)}
                            />

                            {props.meta.touched ? (
                              <StyledSpanError>
                                {props.meta.error}
                              </StyledSpanError>
                            ) : null}
                            <ColorPickerContainer>
                              {isPrimaryColorPickerOpen && (
                                <SketchPicker
                                  color={props.input.value}
                                  onChangeComplete={color => {
                                    props.input.onChange(
                                      (color.hex as String).toUpperCase()
                                    );
                                  }}
                                />
                              )}
                            </ColorPickerContainer>
                          </TextFieldWrapperColor>
                        )}
                      </Field>
                    </>
                  ) : (
                    <>
                      <FormLabel>Primary Color</FormLabel>
                      <div>{initialFormData.primaryColor ?? '-'}</div>
                    </>
                  )}

                  {canWrite ? (
                    <>
                      <FormLabel>Set Secondary Color</FormLabel>
                      <InputSubheader>
                        Set a secondary color to be shown on customer-facing
                        sidebar features and buttons.
                      </InputSubheader>

                      <Field
                        name="secondaryColor"
                        validate={isValidColorHexCode}
                      >
                        {props => (
                          <TextFieldWrapperColor ref={clickAwaySecondaryColor}>
                            <ColorTextField
                              {...props.input}
                              disabled={!canWrite}
                              className="brand-secondary-color"
                              inputPrefix={
                                <ColorDiv color={props.input.value} />
                              }
                              placeholder="#B93BE4"
                              onClick={() =>
                                setIsSecondaryColorPickerOpen(true)
                              }
                            />

                            {props.meta.touched ? (
                              <StyledSpanError>
                                {props.meta.error}
                              </StyledSpanError>
                            ) : null}

                            <ColorPickerContainer>
                              {isSecondaryColorPickerOpen && (
                                <SketchPicker
                                  color={props.input.value}
                                  onChangeComplete={color => {
                                    props.input.onChange(
                                      (color.hex as String).toUpperCase()
                                    );
                                  }}
                                />
                              )}
                            </ColorPickerContainer>
                          </TextFieldWrapperColor>
                        )}
                      </Field>
                    </>
                  ) : (
                    <>
                      <FormLabel>Secondary Color</FormLabel>
                      <div>{initialFormData.secondaryColor ?? '-'}</div>
                    </>
                  )}
                </WrapperMarginTop>

                <WrapperMarginTop>
                  <ContentSeparator style={{ marginTop: '32px' }}>
                    <BoldText
                      fontSize={18}
                      marginBottom={8}
                      marginRight={8}
                      lineHeight={24}
                    >
                      Logos
                    </BoldText>
                    <Tooltip
                      content={
                        'Set the logos that will be used throughout\n the white label platform'
                      }
                    >
                      <FontAwesomeIcon
                        size="sm"
                        icon={faInfoCircle as IconProp}
                      />
                    </Tooltip>
                  </ContentSeparator>

                  <DividingLine />

                  {canWrite ? (
                    <ContentSeparator>
                      <StyledLogoContainer>
                        <BoldText fontSize={14} marginBottom={16}>
                          Set Dark Logo
                        </BoldText>
                        <InputSubheader>
                          PNG is the only accepted file type.
                        </InputSubheader>
                        <FileUploader
                          disabled={!canWrite}
                          name="darkLogo"
                          values={values}
                          currentBrand={currentBrand}
                        />

                        <StyledSpanInfo>
                          The optimal logo dimensions are 175px x 35px.
                        </StyledSpanInfo>
                      </StyledLogoContainer>

                      <LogoContainer>
                        <LogoPreview
                          currentBrand={currentBrand}
                          color={'white'}
                          name={'darkLogo'}
                          values={values}
                        />
                      </LogoContainer>
                    </ContentSeparator>
                  ) : (
                    <>
                      <FormLabel>Dark Logo</FormLabel>
                      <div>
                        <LogoPreview
                          currentBrand={currentBrand}
                          color={'white'}
                          name={'darkLogo'}
                          values={values}
                        />
                      </div>
                    </>
                  )}

                  {canWrite ? (
                    <ContentSeparator>
                      <StyledLogoContainer>
                        <BoldText fontSize={14} marginBottom={16}>
                          Set White Logo
                        </BoldText>
                        <InputSubheader>
                          PNG is the only accepted file type.
                        </InputSubheader>
                        <FileUploader
                          disabled={!canWrite}
                          name="logoPngWideWhite"
                          values={values}
                          currentBrand={currentBrand}
                        />
                        <StyledSpanInfo>
                          The optimal logo dimensions are 175px x 35px.
                        </StyledSpanInfo>
                      </StyledLogoContainer>
                      <LogoContainer>
                        <LogoPreview
                          currentBrand={currentBrand}
                          color={'dark'}
                          name={'logoPngWideWhite'}
                          values={values}
                        />
                      </LogoContainer>
                    </ContentSeparator>
                  ) : (
                    <>
                      <FormLabel>White Logo</FormLabel>
                      <div>
                        <LogoPreview
                          currentBrand={currentBrand}
                          color={'dark'}
                          name={'logoPngWideWhite'}
                          values={values}
                        />
                      </div>
                    </>
                  )}

                  {canWrite ? (
                    <ContentSeparator>
                      <StyledLogoContainer>
                        <BoldText fontSize={14} marginBottom={16}>
                          Set Square Logo
                        </BoldText>
                        <InputSubheader>
                          PNG is the only accepted file type.
                        </InputSubheader>
                        <FileUploader
                          name="logoSquare"
                          values={values}
                          currentBrand={currentBrand}
                          isSmallLogo
                        />
                        <StyledSpanInfo>
                          The optimal logo dimensions are 32px x 32px.
                        </StyledSpanInfo>
                      </StyledLogoContainer>
                      <LogoContainer>
                        <LogoPreview
                          currentBrand={currentBrand}
                          color={'white'}
                          name={'logoSquare'}
                          isSmall
                          values={values}
                        />
                      </LogoContainer>
                    </ContentSeparator>
                  ) : (
                    <>
                      <FormLabel>Square Logo</FormLabel>
                      <div>
                        <LogoPreview
                          currentBrand={currentBrand}
                          color={'white'}
                          name={'logoSquare'}
                          isSmall
                          values={values}
                        />
                      </div>
                    </>
                  )}
                </WrapperMarginTop>

                <WrapperMarginTop>
                  <ContentSeparator>
                    <BoldText
                      fontSize={18}
                      marginBottom={8}
                      marginRight={8}
                      lineHeight={24}
                    >
                      Emails
                    </BoldText>
                    {canWrite ? (
                      <Tooltip
                        content={
                          'Set the the display names from emails for \n transactional emails to your sub merchants'
                        }
                      >
                        <FontAwesomeIcon
                          size="sm"
                          icon={faInfoCircle as IconProp}
                        />
                      </Tooltip>
                    ) : null}
                  </ContentSeparator>
                  <DividingLine />
                </WrapperMarginTop>

                {canWrite ? (
                  <>
                    <FormLabel>Set Support Email</FormLabel>
                    <Wrapper maxWidth={'200px'}>
                      <Field name="supportEmail" validate={validateEmail}>
                        {props => (
                          <TextFieldWrapper>
                            <TextField
                              {...props.input}
                              className="brand-platform-support-email"
                              placeholder="support@fattmerchant.com"
                              error={dirty && props.meta.error}
                              helperText={dirty && props.meta.error}
                            />
                          </TextFieldWrapper>
                        )}
                      </Field>
                    </Wrapper>
                  </>
                ) : (
                  <>
                    <FormLabel>Support Email</FormLabel>
                    <div>{initialFormData.supportEmail ?? '-'}</div>
                  </>
                )}

                {canWrite ? (
                  <>
                    <FormLabel>Set No-Reply Email</FormLabel>
                    <Wrapper maxWidth={'200px'}>
                      <Field name="noReplyEmail" validate={validateEmail}>
                        {props => (
                          <TextFieldWrapper>
                            <TextField
                              {...props.input}
                              className="brand-platform-noreply-email"
                              placeholder="no-reply@fattmerchant.com"
                              error={dirty && props.meta.error}
                              helperText={dirty && props.meta.error}
                            />
                          </TextFieldWrapper>
                        )}
                      </Field>
                    </Wrapper>
                  </>
                ) : (
                  <>
                    <FormLabel>No-Reply Email</FormLabel>
                    <div>{initialFormData.noReplyEmail ?? '-'} </div>
                  </>
                )}

                {canWrite ? (
                  <>
                    <FormLabel>Set Sign Up Email</FormLabel>
                    <Wrapper maxWidth={'200px'}>
                      <Field name="signupEmail" validate={validateEmail}>
                        {props => (
                          <TextFieldWrapper>
                            <TextField
                              {...props.input}
                              className="brand-platform-signup-email"
                              placeholder="signup@fattmerchant.com"
                              error={dirty && props.meta.error}
                              helperText={dirty && props.meta.error}
                            />
                          </TextFieldWrapper>
                        )}
                      </Field>
                    </Wrapper>
                  </>
                ) : (
                  <>
                    <FormLabel>Sign Up Email</FormLabel>
                    <div>{initialFormData.signupEmail ?? '-'}</div>
                  </>
                )}

                {canWrite ? (
                  <>
                    <FormLabel>Set Mailer Email</FormLabel>
                    <Wrapper maxWidth={'200px'}>
                      <Field name="mailerEmail" validate={validateEmail}>
                        {props => (
                          <>
                            <TextFieldWrapper>
                              <TextField
                                {...props.input}
                                className="brand-platform-mailer-email"
                                placeholder="mailer@omnimailer.co"
                                error={dirty && props.meta.error}
                                helperText={dirty && props.meta.error}
                              />
                            </TextFieldWrapper>
                          </>
                        )}
                      </Field>
                    </Wrapper>
                  </>
                ) : (
                  <>
                    <FormLabel>Mailer Email</FormLabel>
                    <div>{initialFormData.mailerEmail ?? '-'}</div>
                  </>
                )}
              </MainContent>
            </form>
          );
        }}
      </FinalForm>
    </>
  );
};
