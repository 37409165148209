import React, { FunctionComponent } from 'react';
import { InfoIcon } from '../shared/InfoIcon';
import {
  ModalContentDescription,
  ModalContentTitle
} from '../shared/TruffleText';

export const MerchantPerformanceInfoIcon: FunctionComponent = () => {
  return (
    <InfoIcon title="Merchant Performance Details">
      {ModalContentTitle('Highest Gross Sales')}
      {ModalContentDescription(
        'Merchants that have processed the most (gross sales) for the given time period.'
      )}
      {ModalContentTitle('Under Performing by Volume')}
      {ModalContentDescription(
        'Merchants that have processed the least for the given time period. Tie-breaker is based on higher approved processing volume. This excludes merchants that did not process at all during the given timeframe'
      )}
    </InfoIcon>
  );
};
