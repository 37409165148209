import React from 'react';

const TITLES = {
  BusinessDBAResponse: 'Business DBA',
  BusinessLegalNameResponse: 'Business Legal Name',
};

export default (reportObj, type) => {
  return (
    <div>
      <strong>Google Results for {TITLES[type]}</strong>
      <ul>
        <li>
          <strong>Code:</strong> {reportObj.status.Code}
        </li>
        <li>
          <strong>Description:</strong> {reportObj.status.Description}
        </li>
      </ul>
      {reportObj.candidates.length > 0 && (
        <div>
          <strong>Potential Matches</strong>
          {reportObj.candidates.map((match, index) => (
            <ul key={`${match.name}-${index}`}>
              <li>Address: {match.formatted_address}</li>
              <li>Business Name: {match.name}</li>
            </ul>
          ))}
        </div>
      )}
    </div>
  );
};
