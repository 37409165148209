import React, { FunctionComponent, useContext } from 'react';
import styled, { withTheme } from 'styled-components';
import {
  TestModeStore,
  updateAutomaticSwitchModalStatus,
  deriveAuthMode,
  AuthStore,
} from '../../../context';
import { Modal } from '../../shared';
import { StaxButton } from '../../shared/Stax/Button';

const TestModeAlert = withTheme(
  styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    border-top: 5px solid ${({ theme }) => theme.colors.core.purple[500].hex};
    text-align: center;
    width: 100%;
    span {
      background: ${({ theme }) => theme.colors.core.purple[500].hex};
      padding: 6px 19px;
      color: white;
      text-transform: uppercase;
      border-radius: 0 0 2px 2px;
      font-family: 'Roboto';
      font-weight: 700;
    }
  `
);

export const TestModeGlobalMessage: FunctionComponent = () => {
  const {
    state: { isAutomaticRedirectModalOpen },
    dispatch: dispatchTestModeAction,
  } = useContext(TestModeStore);
  const { state: authState } = useContext(AuthStore);
  const mode = deriveAuthMode(authState);

  return (
    <>
      {
        /*
         * Displays "Test Mode" global message banner.
         */
        mode === 'TEST_MODE' && (
          <TestModeAlert data-testid="test-mode-global-message">
            <span>Test Mode</span>
          </TestModeAlert>
        )
      }
      {/*
       * Modal to inform user the mode is being automatically changed.
       */}
      <Modal
        isOpen={isAutomaticRedirectModalOpen}
        onClose={() =>
          dispatchTestModeAction(updateAutomaticSwitchModalStatus(false))
        }
        id="automatic-mode-switch-modal"
        data-testid="automatic-mode-switch-modal"
        className="p-4"
      >
        <p className="p-5 mt-3 text-center">
          You have been switched to {mode === 'TEST_MODE' ? 'Test' : 'Live'}{' '}
          Mode.
        </p>
        <div className="text-right">
          <StaxButton
            onClick={() => {
              dispatchTestModeAction(updateAutomaticSwitchModalStatus(false));
            }}
          >
            Continue
          </StaxButton>
        </div>
      </Modal>
    </>
  );
};
