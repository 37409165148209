import React, {
  FunctionComponent,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';

import {
  Input,
  Group,
  FieldError,
  FormHeader,
  FormDivider,
  Select,
  TextArea,
} from '../../../shared';
import { PaymentsFieldsProps } from './PaymentsFields.types';
import { useWindowSize, usePermissions } from '../../../../hooks';
import {
  refundPolicies,
  processingMethods,
} from '../../../../util/registration.util';
import { SelectedMerchantStore } from '../../../../context';
import { PricingPlan } from '@fattmerchantorg/types-omni';
import { EnrollmentField } from '../enrollment-field';

export const PaymentsFields: FunctionComponent<PaymentsFieldsProps> = props => {
  const { isMobile } = useWindowSize();
  const {
    state: { brand, registration },
  } = useContext(SelectedMerchantStore);
  const { permit } = usePermissions();

  /**
   * This is the value of chosen_processing_method that is saved in the merchant's `registrations`
   * database record. It will differ from the chosen_processing_method in the form
   * during the time that the user has selected a different method but has not yet clicked save.
   */
  const initialChosenProcessingMethod = registration?.chosen_processing_method;
  /** This is the form's current value for the processing method. */
  const formValueChosenProcessingMethod = props.values.chosen_processing_method;
  /** This is the form's current value for the pricing plan. */
  const formValuePricingPlan = props.values.pricing_plan;
  /**
   * The associated human readable short name for the form's chosen_processing_method
   * (e.g. "In-Person" instead of "in-person")
   */
  const processingMethodShortName: string | undefined = useMemo(() => {
    return processingMethods.find(
      pm => pm.key === formValueChosenProcessingMethod
    )?.shortName;
  }, [formValueChosenProcessingMethod]);

  // Track which pricing plans are "available" and which are "unavailable".
  const [availablePricingPlans, setAvailablePricingPlans] = useState<
    PricingPlan[]
  >([]);
  const [unavailablePricingPlans, setUnavailablePricingPlans] = useState<
    PricingPlan[]
  >([]);

  /** Track whether the currently selected form pricing plan  */
  const isSelectedPricingPlanUnavailable = useMemo(
    (): boolean =>
      unavailablePricingPlans.findIndex(
        upp => upp.name === formValuePricingPlan
      ) > -1,
    [formValuePricingPlan, unavailablePricingPlans]
  );

  const allowOverridePricing = useMemo(
    (): boolean => permit('godview', 'overridePricing', 'write'),
    [permit]
  );

  const availablePricingPlansGroupLabel: string = `Available Plans${
    processingMethodShortName ? ` for ${processingMethodShortName}` : ''
  }`;
  const unavailablePricingPlansGroupLabel: string = `Unavailable Plans${
    allowOverridePricing ? ' (Available through Override Pricing)' : ''
  }`;

  /**
   * IF there is a registration.chosen_processing_method
   * AND the selected pricing plan is unavailable for the new chosen processing method
   * AND user does not have permissions.godview.overridePricing
   * THEN the pricing plan is invalid
   *
   * IF the pricing plan is not invalid
   * OR the chosen pricing plan has NOT changed from the initial state
   *   (if a user with overridePricing changes the pricing plan to a normally-unavailable pricing plan, we still want normal users to be able to use the form without running into validation errors)
   * THEN the pricing plan is valid
   */
  useEffect(() => {
    const isPricingPlanInvalid =
      formValueChosenProcessingMethod &&
      isSelectedPricingPlanUnavailable &&
      !allowOverridePricing;

    const isPricingPlanValid =
      !isPricingPlanInvalid ||
      formValueChosenProcessingMethod === initialChosenProcessingMethod;

    props.setPricingPlanValidity(isPricingPlanValid ? 'VALID' : 'INVALID');
  }, [
    formValueChosenProcessingMethod,
    isSelectedPricingPlanUnavailable,
    allowOverridePricing,
    initialChosenProcessingMethod,
    props,
  ]);

  /**
   * Recalculates available and unavailable pricing plans when any of the below change.
   * - form's chosen_processing_method
   * - permissions.godview.overridePricing
   * - selectedMerchantContext.state.brand
   */
  useEffect(() => {
    const availablePricingPlans: PricingPlan[] = [];
    const unavailablePricingPlans: PricingPlan[] = [];

    if (brand?.status !== 'IDLE') return;

    brand?.data.filteredPricingFields.forEach(pricingPlan => {
      /**
       * If the pricingPlan.productType array includes the chosen_processing_method,
       * it is an available pricing plan to use
       */
      const isAvailable =
        pricingPlan.productType &&
        pricingPlan.productType.includes(formValueChosenProcessingMethod);

      if (isAvailable) {
        availablePricingPlans.push(pricingPlan);
      } else {
        unavailablePricingPlans.push(pricingPlan);
      }
    });

    setAvailablePricingPlans(availablePricingPlans);
    setUnavailablePricingPlans(unavailablePricingPlans);
  }, [formValueChosenProcessingMethod, allowOverridePricing, brand]);

  return (
    <>
      <FormHeader tag="h1">Payments</FormHeader>
      <Group direction="column">
        <EnrollmentField
          name="reason_for_applying"
          label={
            isMobile
              ? 'Reason for applying'
              : 'Why are you applying for merchant processing?'
          }
        >
          {props => (
            <Select {...props.input}>
              <option value="" disabled />
              <option value="Never Accepted Cards Before">
                Never Accepted Cards Before
              </option>
              <option value="ProcessorChange">Processor Change</option>
            </Select>
          )}
        </EnrollmentField>
        <FieldError name="reason_for_applying" />
      </Group>

      <Group direction="column">
        <EnrollmentField
          name="refund_policy"
          label={isMobile ? 'Refund Policy' : 'What is your refund policy?'}
        >
          {props => (
            <Select {...props.input}>
              <option value="" disabled />
              {refundPolicies.map((e, index) => (
                <option key={index} value={e.key}>
                  {e.description}
                </option>
              ))}
            </Select>
          )}
        </EnrollmentField>
        <FieldError name="refund_policy" />
      </Group>

      <Group direction="column">
        <EnrollmentField
          name="chosen_processing_method"
          label={
            isMobile
              ? 'How do you accept payments?'
              : 'How do you primarily accept payments?'
          }
        >
          {props => (
            <Select {...props.input}>
              <option value="" disabled />
              {processingMethods.map((e, index) => (
                <option key={index} value={e.key}>
                  {e.description}
                </option>
              ))}
            </Select>
          )}
        </EnrollmentField>
        <FieldError name="chosen_processing_method" />
      </Group>

      <Group direction="column">
        <EnrollmentField name="pricing_plan" label="Pricing plan">
          {props => (
            <Select {...props.input}>
              <option value="" disabled />
              {brand?.status === 'IDLE' && (
                <>
                  <optgroup label={availablePricingPlansGroupLabel}>
                    {availablePricingPlans.map(pricingPlan => (
                      <option key={pricingPlan.id} value={pricingPlan.name}>
                        {pricingPlan.displayName}
                      </option>
                    ))}
                  </optgroup>
                  <optgroup
                    label={unavailablePricingPlansGroupLabel}
                    disabled={!allowOverridePricing}
                  >
                    {unavailablePricingPlans.map(pricingPlan => (
                      <option key={pricingPlan.id} value={pricingPlan.name}>
                        {pricingPlan.displayName}
                      </option>
                    ))}
                  </optgroup>
                </>
              )}
            </Select>
          )}
        </EnrollmentField>
        <FieldError name="pricing_plan" />
        {processingMethodShortName && isSelectedPricingPlanUnavailable && (
          <small>
            Note: This pricing plan is normally unavailable for "
            {processingMethodShortName}"
          </small>
        )}
      </Group>

      <Group direction="column">
        <EnrollmentField name="mcc" label="MCC">
          {props => (
            <Select {...props.input}>
              <option value="" disabled />
              {brand?.status === 'IDLE' &&
                brand?.data.merchantCategoryCodes.map(code => (
                  <option key={code.id} value={code.name}>
                    {code.displayName}
                  </option>
                ))}
            </Select>
          )}
        </EnrollmentField>
        <FieldError name="mcc" />
      </Group>

      <Group direction="column">
        <EnrollmentField
          name="service_you_provide"
          label={
            isMobile
              ? 'Service/product you provide'
              : 'What do you sell or what service do you provide?'
          }
        >
          {props => <TextArea {...props.input} />}
        </EnrollmentField>
        <FieldError name="service_you_provide" />
      </Group>

      <Group direction="column">
        <EnrollmentField name="proc_change" label="Processor change?">
          {props => (
            <Select {...props.input}>
              <option value="" disabled />
              <option value="NOT_NOW_PROC">Never accepted cards before</option>
              <option value="PROC_CHANGE">Processor change</option>
            </Select>
          )}
        </EnrollmentField>
        <FieldError name="proc_change" />
      </Group>

      <Group direction={isMobile ? 'column-reverse' : 'row'}>
        <Group direction="column" space="0.5rem/0px" style={{ flex: '50%' }}>
          <Group direction="column">
            <EnrollmentField
              name="annual_volume"
              label="Annual Gross CC Revenue $"
              format={v => (v ? v.replace(/[^0-9.,$%]/g, '') : '')}
            >
              {props => <Input {...props.input} />}
            </EnrollmentField>
            <FieldError name="annual_volume" />
          </Group>

          <Group direction="column">
            <EnrollmentField
              name="avg_trans_size"
              label="Average CC Transaction Size $"
              format={v => (v ? v.replace(/[^0-9.,$%]/g, '') : '')}
            >
              {props => <Input {...props.input} />}
            </EnrollmentField>
            <FieldError name="avg_trans_size" />
          </Group>

          <Group direction="column">
            <EnrollmentField
              name="highest_trans_amount"
              label="Largest CC Transaction Size $"
              format={v => (v ? v.replace(/[^0-9.,$%]/g, '') : '')}
            >
              {props => <Input {...props.input} />}
            </EnrollmentField>
            <FieldError name="highest_trans_amount" />
          </Group>
          <Group direction="column">
            <EnrollmentField
              name="annual_gross_ach_revenue"
              label="Annual Gross ACH Revenue $"
              format={v => (v ? v.replace(/[^0-9.,$%]/g, '') : '')}
            >
              {props => <Input {...props.input} />}
            </EnrollmentField>
            <FieldError name="annual_gross_ach_revenue" />
          </Group>
          <Group direction="column">
            <EnrollmentField
              name="avg_ach_transaction"
              label="Average ACH Transaction Size $"
              format={v => (v ? v.replace(/[^0-9.,$%]/g, '') : '')}
            >
              {props => <Input {...props.input} />}
            </EnrollmentField>
            <FieldError name="avg_ach_transaction" />
          </Group>
          <Group direction="column">
            <EnrollmentField
              name="largest_ach_transaction"
              label="Largest ACH Transaction Size $"
              format={v => (v ? v.replace(/[^0-9.,$%]/g, '') : '')}
            >
              {props => <Input {...props.input} />}
            </EnrollmentField>
            <FieldError name="largest_ach_transaction" />
          </Group>

          <Group direction="column">
            <EnrollmentField
              name="card_present_percent"
              label="Card Present %"
              format={v => (v ? v.replace(/[^0-9.,$%]/g, '') : '')}
            >
              {props => <Input {...props.input} min="0" max="100" />}
            </EnrollmentField>
            <FieldError name="card_present_percent" />
          </Group>

          <Group direction="column">
            <EnrollmentField
              name="b2b_percent"
              label="Business to Business %"
              format={v => (v ? v.replace(/[^0-9.,$%]/g, '') : '')}
            >
              {props => <Input {...props.input} min="0" max="100" />}
            </EnrollmentField>
            <FieldError name="b2b_percent" />
          </Group>

          <Group direction="column">
            <EnrollmentField
              name="international"
              label="International Card %"
              format={v => (v ? v.replace(/[^0-9.,$%]/g, '') : '')}
            >
              {props => <Input {...props.input} min="0" max="100" />}
            </EnrollmentField>
            <FieldError name="international" />
          </Group>
        </Group>
      </Group>

      {props.children}

      <div>
        <FormDivider />
      </div>
    </>
  );
};
