import React, { Dispatch, useState, useContext, useCallback } from 'react';
import styled from 'styled-components';
import {
  BaseModal,
  ModalHeader,
  ModalFooter,
  PrimaryButton,
  SecondaryButton,
} from '@fattmerchantorg/truffle-components';
import { Registration } from '@fattmerchantorg/types-omni';
import { coreapi } from '../../../../api';
import {
  updateSelectedMerchantRegistration,
  AuthStore,
  ModalContext,
} from '../../../../context';
import { useToaster } from '../../../../hooks';
import { ButtonSpinner, Form } from '../../../shared';
import { ProcessingEnvironmentFields } from './ProcessingEnvironmentFields';
import * as processingEnvironmentUtil from './ProcessingEnvironmentForm.util';
import {
  ProcessingEnvironmentFieldsValues,
  ProcessingEnvironmentFieldsErrors,
} from './ProcessingEnvironment.types';
import { BrandWithPricing } from './ProcessingEnvironment';
import * as pricingInfoUtil from '../pricing-info/PricingInfoForm.util';
import { PricingInfoFieldsValues } from '../pricing-info/PricingInfoForm.types';

const ButtonBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  > * {
    margin: 0 0 0 ${({ theme }) => theme.space[3]}px;
  }
`;

const StyledModalContent = styled.div`
  grid-area: content;
  overflow-y: auto;
  padding: ${({ theme }) => theme.space[2]}px ${({ theme }) => theme.space[4]}px;
  color: ${({ theme }) => theme.component.modal.color};
`;

interface ProcessingEnvironmentModal {
  isOpen?: boolean;
  registration: Registration;
  merchantId: string;
  merchantDispatch: Dispatch<any>;
  brand: BrandWithPricing;
}

const validateForm = (
  formValues: ProcessingEnvironmentFieldsValues & PricingInfoFieldsValues
): ProcessingEnvironmentFieldsErrors => {
  const errors = {
    ...processingEnvironmentUtil.validateFormValues(formValues),
  } as ProcessingEnvironmentFieldsErrors;

  return errors;
};

export const ProcessingEnvironmentFormModal: React.FunctionComponent<
  ProcessingEnvironmentModal
> = props => {
  const { merchantId, merchantDispatch, registration, brand } = props;
  const { modalDispatch } = useContext(ModalContext);
  const [isModalVisible] = useState(!!props.isOpen);
  const { state: authState } = useContext(AuthStore);
  const { authToken } = authState;
  const { toaster, toast } = useToaster();
  const [isSaving, setIsSaving] = useState(false);

  const handleCloseModal = useCallback(() => {
    modalDispatch({
      type: 'CLOSE_MODAL',
    });
  }, [modalDispatch]);

  const onSubmit = useCallback(
    async (
      formValues: ProcessingEnvironmentFieldsValues & PricingInfoFieldsValues
    ) => {
      if (!formValues) return;
      setIsSaving(true);
      const payload: Partial<Registration> = {
        ...pricingInfoUtil.mapFormValuesToPayload(formValues),
        ...processingEnvironmentUtil.mapFormValuesToPayload(formValues),
      };

      try {
        const r = await coreapi.put(
          authToken,
          `/merchant/${merchantId}/registration`,
          payload
        );
        merchantDispatch(updateSelectedMerchantRegistration(r));
        toaster(toast.success('Changes successfully saved', 'Saved'));
        handleCloseModal();
      } catch (error) {
        toaster(toast.error(error, 'There was a problem saving your changes.'));
      }
      setIsSaving(false);
    },
    [authToken, merchantId, merchantDispatch, toast, toaster, handleCloseModal]
  );

  return (
    <Form
      initialValues={
        {
          ...pricingInfoUtil.mapFormValuesToPayload(registration),
          ...processingEnvironmentUtil.mapRegistrationToInitialFormValues(
            registration
          ),
        } as PricingInfoFieldsValues & ProcessingEnvironmentFieldsValues
      }
      validate={formValues => {
        const validationErrors = {
          ...validateForm(formValues),
        } as ProcessingEnvironmentFieldsErrors;
        return validationErrors;
      }}
      mutators={{
        setFormValues: ([values], state, utils) => {
          Object.keys(values).forEach(key => {
            const value = values[key];
            utils.changeValue(state, key, () => value);
          });
        },
      }}
      onSubmit={onSubmit}
    >
      {formProps => (
        <BaseModal
          title="Processing Environment"
          isOpen={isModalVisible}
          onRequestClose={handleCloseModal}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={false}
          style={{
            overlay: { pointerEvents: 'auto' },
            content: { maxWidth: '533px' },
          }}
        >
          <ModalHeader
            title="Processing Environment"
            onClose={handleCloseModal}
          />
          <>
            <StyledModalContent>
              <ProcessingEnvironmentFields
                formProps={formProps}
                brand={brand}
                setFormValues={formProps.form.mutators.setFormValues}
              />
            </StyledModalContent>
            <ModalFooter>
              <ButtonBar>
                <SecondaryButton onClick={handleCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  onClick={() => onSubmit(formProps.values)}
                  disabled={
                    // Disable Save if there are validation errors
                    formProps.hasValidationErrors === true
                  }
                >
                  {isSaving ? <ButtonSpinner /> : `Save`}
                </PrimaryButton>
              </ButtonBar>
            </ModalFooter>
          </>
        </BaseModal>
      )}
    </Form>
  );
};
