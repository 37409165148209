import React, { useState, useCallback, useContext } from 'react';

import { Form, Field, FieldError } from '../shared';

import { FormErrors } from '../../@types';

import {
  BaseModal,
  ModalHeader,
  ModalContent,
  TextField,
  DateRangePicker,
  PrimaryButton,
  SecondaryButton,
} from '@fattmerchantorg/truffle-components';

import { useToaster, usePermissions } from '../../hooks';

import {
  ImportHubspotTicketsForm,
  importHubspotTickets,
} from '../../services/apps-service';
import { AuthStore } from '../../context';
import { Tooltip, Icon } from '@fattmerchantorg/truffle-components';
import styled, { withTheme } from 'styled-components';

const getLastYear = (): Date => {
  var today = new Date();
  return new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
};

const initialFormValues: ImportHubspotTicketsForm = {
  companyId: '',
  endDate: new Date(),
  startDate: getLastYear(),
};

const StyledModalContent = styled(ModalContent)`
  min-height: 360px;
  grid-area: content;
  overflow-y: auto;
  padding: ${({ theme }) => theme.space[2]}px ${({ theme }) => theme.space[4]}px;
  color: ${({ theme }) => theme.component.modal.color};
`;

const StyledLabel = styled.label`
  line-height: 20px;
  font-size: 14px;
  margin: 8px 0px;
`;

const StyledIcon = withTheme(
  styled(Icon)`
    width: 14px;
    height: 18px;
    right: 0px;
    top: calc(50% - 18px / 2);
    font-weight: 900;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #009bf2;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 8px;
  `
);

//The Form component has a StyledForm child component that adds padding to all buttons
//This was causing issues with the Textbox and DateRange Selector, making the icons (ex: calendar and clear button) misaligned.
//This styled div just klobbers that Form button style
const FixFormButton = styled.div`
  button {
    margin: 0 0;
  }
`;

const ButtonBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 16px;

  button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 11px 32px;
    flex: none;
    flex-grow: 0;
    margin-right: 16px;
  }

  > * {
    margin: 0 0 0 ${({ theme }) => theme.space[3]}px;
  }
`;

const ImportHubspotTicketsModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { toaster, toast } = useToaster();
  const { state: authState } = useContext(AuthStore);
  const { auth } = authState;

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const validateForm = useCallback((formValues: ImportHubspotTicketsForm) => {
    const formErrors = {} as FormErrors<ImportHubspotTicketsForm>;
    const companyId = formValues.companyId;

    //no null or whitespace(s)
    if (companyId === null || companyId.match(/^ *$/) !== null) {
      formErrors.companyId = 'Required';
    }
    return formErrors;
  }, []);

  const onSubmit = useCallback(
    async formValues => {
      try {
        await importHubspotTickets(auth, formValues);
        toaster(toast.success('Syncing with Hubspot', 'Syncing'));
      } catch (exception) {
        toaster(
          toast.error(exception, 'There was an error syncing with Hubspot.')
        );
      } finally {
        closeModal();
      }
    },
    [auth, toaster, toast]
  );

  const { permit } = usePermissions();

  return permit('godview', 'godviewPermissions') ? (
    <>
      <Tooltip content="Sync with Hubspot" trigger="mouseenter">
        <StyledIcon icon={['fas', 'sync']} onClick={openModal} />
      </Tooltip>

      <BaseModal
        title="Sync with Hubspot"
        isOpen={isOpen}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeModal}
        onCancel={closeModal}
        onConfirm={closeModal}
        style={{
          content: {
            width: '480px',
          },
        }}
      >
        <ModalHeader title="Sync with Hubspot" onClose={closeModal} />
        <Form
          initialValues={initialFormValues}
          validate={validateForm}
          onSubmit={onSubmit}
        >
          <StyledModalContent>
            <FixFormButton>
              <Field name="companyId" label="Company ID">
                {fieldProps => <TextField {...fieldProps.input} />}
              </Field>
            </FixFormButton>
            <FieldError name="companyId" />
            <StyledLabel htmlFor="dateRange">Date Range</StyledLabel>

            <FixFormButton>
              <Field name="dateRange">
                {fieldProps => (
                  <DateRangePicker
                    {...fieldProps.input}
                    startDateFieldName="startDate"
                    endDateFieldName="endDate"
                    initialStart={getLastYear()}
                    initialEnd={new Date()}
                  />
                )}
              </Field>
            </FixFormButton>

            <ButtonBar>
              <SecondaryButton type="button" onClick={closeModal}>
                Cancel
              </SecondaryButton>

              <PrimaryButton type="submit">Sync</PrimaryButton>
            </ButtonBar>
          </StyledModalContent>
        </Form>
      </BaseModal>
    </>
  ) : null;
};

export default ImportHubspotTicketsModal;
