import React, { useContext, useState } from 'react';
import { Text } from '@fattmerchantorg/truffle-components';
import {
  AdjustmentTransactionChildModalProps,
  AdjustmentTransactionFromTransactions,
} from '../AdjustmentTransactions.types';
import { formatCapitalCase, formatCurrency } from '../../../../util';
import { ConfirmationModal } from './ConfirmationModal';
import { catanapi } from '../../../../api';
import { AuthStore } from '../../../../context';
import { useToaster } from '../../../../hooks';
import { getErrorsFromCatanErrorResponse } from '../../../../util/catan.util';

type Props = AdjustmentTransactionFromTransactions &
  AdjustmentTransactionChildModalProps;

export const DeleteAdjustmentTransaction: React.FC<Props> = ({
  action,
  transaction,
  merchant,
  authToken,
  onCancel,
  onDone,
}: Props) => {
  const {
    state: { auth },
  } = useContext(AuthStore);

  const { toaster, toast } = useToaster();

  const [isDeleting, setIsDeleting] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  return (
    <ConfirmationModal
      isOpen={isOpen}
      title={`Delete ${formatCapitalCase(transaction.type)} Adjustment`}
      confirmButtonLabel="Delete Adjustment"
      confirmButtonType="danger"
      onClose={onCancel}
      onConfirm={deleteAdjustment}
      inProgress={isDeleting}
    >
      <Text as="p">
        Are you sure you want to delete the following?
        <br />
        <strong>
          {formatCapitalCase(transaction.type)}{' '}
          {formatCurrency(transaction.amount)}
        </strong>{' '}
        to <strong>{merchant.name}</strong>
      </Text>
    </ConfirmationModal>
  );

  async function deleteAdjustment() {
    setIsDeleting(true);
    try {
      await catanapi.delete(
        authToken,
        `/adjustments/${transaction.transaction_id}`,
        {
          userId: auth?.user?.id,
        }
      );
      toaster(
        toast.success(
          <span>
            <strong>Deleted</strong> {merchant.name}{' '}
            <strong>{formatCapitalCase(transaction.type)} Adjustment</strong>
          </span>,
          'Adjustment deleted'
        )
      );
      setIsDeleting(false);
      setIsOpen(false);
      onDone();
    } catch (error) {
      const catanValidation = getErrorsFromCatanErrorResponse(error);
      const errors = catanValidation.length
        ? catanValidation
        : error
        ? [error.message]
        : [];
      toaster(
        toast.error(
          <div>
            <div>
              Could not <strong>delete</strong> {merchant.name}{' '}
              <strong>{formatCapitalCase(transaction.type)} Adjustment</strong>
            </div>
            {errors.map((e, i) => (
              <div key={i}>{e}</div>
            ))}
          </div>,
          'Error'
        )
      );
      setIsDeleting(false);
      setIsOpen(false);
      onCancel();
    }
  }
};
