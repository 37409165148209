import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { Icon } from '@fattmerchantorg/truffle-components';
import { OmniDispute } from '@fattmerchantorg/types-omni';
import { useToaster } from '../../../hooks';

import { Modal, Container, ModalProps, StaxButton } from '../../shared';
import { MerchantDisputeCombo } from '../Disputes';

const headerText = `Are you sure you want to accept this dispute?`;

const contentText = `It will result in an automatic loss, and you will no longer have the option to submit evidence demonstrating the legitimacy of the transaction.`;

const StyledContainer = styled(Container)`
  padding: 16px;
  align-items: center;
`;

const WarningIcon = styled(Icon)`
  font-size: 70px;
  margin-top: 20px;
  color: ${({ theme }) => theme.colors.core.gray[400].hex};
` as typeof Icon;

const DisclaimerWarning = styled.div`
  margin-bottom: 16px;
  margin-top: 16px;
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`;

const ButtonsGroup = styled.div`
  text-align: right;
`;

const DrawerButton = styled(StaxButton)`
  margin-left: 16px;
  font-weight: 700;
`;

interface AcceptDisputeModalProps extends ModalProps {
  hasDeadlinePassed: boolean;
  disabled?: boolean;
  merchantDisputeCombo: MerchantDisputeCombo;
  postDispute: (disputeId: string) => Promise<OmniDispute>;
  submitDisputeAccept: (dispute: OmniDispute) => void;
}

export const AcceptDisputeModal: FunctionComponent<
  AcceptDisputeModalProps
> = props => {
  const { toaster, toast } = useToaster();

  const [loading, setLoading] = useState<boolean>(false);

  const { onClose, merchantDisputeCombo, postDispute, submitDisputeAccept } =
    props;

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const dispute = await postDispute(merchantDisputeCombo.disputeId);
      if (dispute) {
        submitDisputeAccept?.(dispute);
      }
      toaster(
        toast.success(
          `You've accepted the dispute and forfeited your ability to fight it.`
        )
      );
    } catch (error) {
      toaster(toast.error(error, 'There was an error submitting the dispute.'));
    }
    setLoading(false);
    onClose();
  };

  return (
    <Modal
      width="480px"
      data-testid="accept-dispute-modal"
      {...props}
      title={<strong>Accept Dispute</strong>}
    >
      <StyledContainer>
        <WarningIcon className="icon" icon={['far', 'exclamation-triangle']} />
        <DisclaimerWarning>
          <Text
            id="header-text"
            style={{
              fontWeight: 'bold',
            }}
          >
            {headerText}
          </Text>
          <Text id="content-text">{contentText}</Text>
        </DisclaimerWarning>
        <ButtonsGroup data-testid="accept-dispute-modal-buttons-group">
          <DrawerButton
            id="cancel-button"
            data-testid="cancel-button"
            className="mt-3"
            rank="secondary"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </DrawerButton>
          <DrawerButton
            id="accept-dispute-button"
            data-testid="accept-dispute-button"
            className="mt-3"
            motif="danger"
            onClick={handleSubmit}
            disabled={loading}
          >
            Accept Dispute
          </DrawerButton>
        </ButtonsGroup>
      </StyledContainer>
    </Modal>
  );
};
