import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useState,
} from 'react';
import {
  BPSettingsRow,
  BPSettingsRowDescription,
  BPSettingsRowFields,
  BPSettingsRowTitle,
  FieldWrap,
  SubCol,
  ThreeCol,
  TwoCol,
} from '../components/BPSettingsRow';
import {
  Icon,
  Label,
  RadioButton,
  Select,
  SmallPrimaryButton,
  SmallSecondaryButton,
  TextField,
} from '@fattmerchantorg/truffle-components';
import { DB } from '@fattmerchantorg/types-engine';
import { CTAPageHeader } from '../components/CTAPageHeader';
import { Field, Form } from 'react-final-form';
import { SelectedMerchantStore } from '../../../context';
import { fetchSelectedMerchantBillingProfiles } from '../../../context/selected-merchant/SelectedMerchants.actions';
import { useAsyncEffect, useAuthToken, useToaster } from '../../../hooks';
import { catanapi } from '../../../api';
import { FormApi } from 'final-form';
import * as RecurringFeeFormUtil from './RecurringFeeForm.util';
import {
  RecurringFeeFieldsErrors,
  RecurringFeeFieldsValues,
} from './RecurringFeeForm.types';
import {
  DeleteFormDataModal,
  DeleteModalContext,
  initialDeleteModalState,
} from '../components/DeleteFormDataModal';
import * as billingProfileUtil from '../util/billingProfile.util';
import { formatCentsOrDollars, ordinal } from '../../../util';
import {
  sendOpenSelected,
  useModalReducer,
} from '../../../hooks/useModalReducer';
import get from 'lodash/get';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { Location } from 'history';
import { getErrorsFromCatanErrorResponse } from '../../../util/catan.util';
import { TransactionsLoadingState } from '../util/loadingState';

interface RecurringFeeProps {
  // TODO: The first profile must have the channel locked down to "All"
  isFirstProfile?: boolean;
  editBillingId?: string;
}

const validateForm = (
  formValues: RecurringFeeFieldsValues
): RecurringFeeFieldsErrors => {
  return RecurringFeeFormUtil.validateFormValues(formValues);
};

const initialFormValues: Partial<RecurringFeeFieldsValues> = {
  type: 'TRANSACTION',
  is_gross_settlement: 'true',
  fees_account_id: null, // TODO: Placeholder account
  billing_cycle: 'DAILY',
  billing_cycle_day: 5,
  billing_cycle_month: 1,
};
export function getMonthName(monthNumber) {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString('en-US', { month: 'long' });
}

const DynamicBillingCycle = ({
  billingCycle,
  billingCycleDay,
  billingCycleMonth = new Date().getMonth() + 1,
}) => {
  switch (billingCycle) {
    case 'MONTHLY':
      return (
        <span>
          <strong>Monthly</strong> on the{' '}
          <strong>{ordinal(billingCycleDay)}</strong>
        </span>
      );
    case 'ANNUALLY':
      return (
        <span>
          <strong>Annually</strong> on{' '}
          <strong>
            {getMonthName(ordinal(billingCycleMonth).replace(/\D/g, ''))}{' '}
            {ordinal(billingCycleDay)}
          </strong>
        </span>
      );
    case 'DAILY':
      return <strong>Daily</strong>;
    default:
      return null;
  }
};

type FeeTypeDescriptionProps = {
  feeDescription?: string;
};
const FeeTypeDescription = ({ feeDescription }: FeeTypeDescriptionProps) =>
  feeDescription ? (
    <span>
      This will be a <strong>{feeDescription}</strong> Recurring Fee
    </span>
  ) : (
    <span>Please choose a fee type associated with this recurring fee.</span>
  );

type FeeDescriptionProps = {
  perTransactionAmount?: number;
  feeDescription?: string;
  isGross?: boolean;
  feesAccountId?: string;
  feesAccount?: string;
  billingCycle?: string;
  billingCycleDay?: number;
  billingCycleMonth?: number;
};
const FeeDescription = ({
  perTransactionAmount,
  feeDescription,
  isGross,
  feesAccount,
  billingCycle,
  billingCycleDay,
  billingCycleMonth,
}: FeeDescriptionProps) => {
  const centString = perTransactionAmount
    ? Math.round(perTransactionAmount * 100).toString()
    : null;

  return perTransactionAmount ? (
    <p>
      A <strong>{feeDescription}</strong> of
      <strong> {formatCentsOrDollars(centString)}</strong> will be collected{' '}
      <DynamicBillingCycle
        billingCycle={billingCycle}
        billingCycleDay={billingCycleDay}
        billingCycleMonth={billingCycleMonth}
      />{' '}
      as a <strong>{isGross ? 'Gross' : 'Net'} Settlement</strong>
      {feesAccount ? (
        <>
          {' from'} <strong>{feesAccount}</strong>
        </>
      ) : null}
      .
    </p>
  ) : null;
};

export const RecurringFee: FunctionComponent<RecurringFeeProps> = props => {
  const authToken = useAuthToken();
  const { state, dispatch: selectedMerchantDispatch } = useContext(
    SelectedMerchantStore
  );
  const { merchant } = state;
  const [isLoading, setIsLoading] = useState(false);

  const { toaster, toast } = useToaster();
  const [isTypeSelected, setIsTypeSelected] = useState(false);
  const engineCompanyId = get(state?.registration, 'external_company_id', null);

  const { editBillingId } = props;
  const [existingBilling, setExistingBilling] = useState<DB.Billing | null>(
    null
  );
  const [feeAccountOptions, setFeeAccountOptions] =
    useState<billingProfileUtil.PaymentMethodSelectOption[]>();
  const [feeTypeOptions, setFeeTypeOptions] =
    useState<billingProfileUtil.FeeTypeOption[]>();
  const billingCycleDayOptions =
    billingProfileUtil.billingCycleDaySelectOptions();
  const billingCycleMonthOptions = billingProfileUtil.monthlySelectOptions;
  const [deleteModalState, deleteModalDispatch] =
    useModalReducer<DeleteModalContext>(initialDeleteModalState);
  const [locationUrl, setLocationUrl] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const [fromCancel, setFromCancel] = useState(false);

  useAsyncEffect(async () => {
    if (!authToken) return;

    const pmsFees = await billingProfileUtil.getEnginePaymentMethods(
      authToken,
      engineCompanyId,
      toast,
      toaster
    );
    setIsLoading(true);
    const feeOpt = getFeeAccountOptions(pmsFees);
    setFeeAccountOptions(feeOpt);
    const feeTypes = await billingProfileUtil.getFeeTypes(
      authToken,
      toast,
      toaster
    );
    const feeTypesOpt = getFeeTypes(feeTypes);
    setFeeTypeOptions(feeTypesOpt);
    setIsLoading(false);
  }, [authToken, engineCompanyId]);

  const getFeeAccountOptions = (
    feesOptions
  ): billingProfileUtil.PaymentMethodSelectOption[] => {
    return feesOptions?.data?.reduce((feesOptns, feesOptn) => {
      feesOptns.push({
        value: feesOptn.method_id,
        label: `${feesOptn.person_name} (****${feesOptn.last_four})`,
      });

      return feesOptns;
    }, []);
  };

  const afterBlockedNavigation = () => {
    if (fromCancel) {
      history.goBack();
    } else {
      history.push(locationUrl.pathname);
    }
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!locationUrl) {
      setLocationUrl(nextLocation);
      deleteModalDispatch(sendOpenSelected());
      return false;
    }
    return true;
  };

  const getFeeTypes = (
    feesOptions
  ): RecurringFeeFormUtil.FeeDescriptionOption[] => {
    return feesOptions?.data?.reduce((feesOptns, feesOptn) => {
      feesOptns.push({
        value: feesOptn.fee_description_id,
        label: `${feesOptn.fee_description}`,
      });

      return feesOptns;
    }, []);
  };

  useAsyncEffect(async () => {
    setIsLoading(true);
    if (
      !state ||
      !editBillingId ||
      editBillingId === '' ||
      !state.billingProfiles ||
      !state.billingProfiles.data
    ) {
      return;
    }
    const selectedBP = state.billingProfiles.data.find(
      bp => bp.billing_id === editBillingId
    );
    if (selectedBP === undefined) {
      toaster(
        toast.error(
          'Unexpected error loading Billing Profile',
          'Error loading Billing Profile'
        )
      );
      setIsLoading(false);
    }
    setExistingBilling({ ...selectedBP });
    setIsTypeSelected(true);
    setIsLoading(false);
  }, [editBillingId, state, toaster, toast]);

  const saveProfile = useCallback(
    async formValues => {
      try {
        existingBilling
          ? await catanapi.put(
              authToken,
              `/billing/${existingBilling.billing_id}`,
              {
                ...RecurringFeeFormUtil.mapFormValuesToPayload(formValues),
              }
            )
          : await catanapi.post(authToken, '/billing', {
              ...RecurringFeeFormUtil.mapFormValuesToPayload(formValues),
              company_id: engineCompanyId,
              onboarding_id: null,
            });
        setLocationUrl(location);
        history.push(`/merchant/${merchant?.id}/billing-profiles`);
        toaster(
          existingBilling
            ? toast.success(
                'Your Recurring Fee Profile has been updated',
                'Recurring Fee Updated'
              )
            : toast.success(
                'Your Recurring Fee Profile has been created',
                'Recurring Fee Created'
              )
        );
        selectedMerchantDispatch(
          fetchSelectedMerchantBillingProfiles(engineCompanyId)
        );
      } catch (error) {
        const errors = getErrorsFromCatanErrorResponse(error);
        if (errors.length) {
          const contentNodes = errors.map(errMsg =>
            React.createElement('div', null, errMsg)
          );
          toaster(
            toast.error(
              React.createElement('div', null, contentNodes),
              'Validation Error'
            )
          );
        } else {
          toaster(
            toast.error(
              error,
              'There was a problem saving this billing profile'
            )
          );
        }
        clearForm(formValues.form?.reset);
      }
    },
    [
      authToken,
      engineCompanyId,
      existingBilling,
      history,
      location,
      merchant,
      selectedMerchantDispatch,
      toast,
      toaster,
    ]
  );

  const clearForm = (reset: FormApi['reset'] | undefined) => {
    if (typeof reset === 'function') {
      reset();
    }
  };

  const getInitialValues = useCallback(() => {
    if (existingBilling) {
      if (!feeTypeOptions) {
        return null;
      }
      const feeDescId = feeTypeOptions.filter(
        ft => ft.value === existingBilling.fee_description_id
      )[0];
      return RecurringFeeFormUtil.mapBillingProfileToInitialFormValues({
        billing_cycle_day: existingBilling.billing_cycle_day,
        billing_cycle_month: existingBilling.billing_cycle_month,
        billing_cycle: existingBilling.billing_cycle,
        fees_account_id: existingBilling.fees_account_id,
        fee_description_id: feeDescId,
        is_gross_settlement: existingBilling.is_gross_settlement
          ? 'true'
          : 'false',
        per_transaction_amount: existingBilling.per_transaction_amount,
      });
    }
    return RecurringFeeFormUtil.mapBillingProfileToInitialFormValues({
      ...initialFormValues,
      per_transaction_amount: 0,
      billing_cycle_day: 5,
    });
  }, [existingBilling, feeTypeOptions]);

  const fillFeeAccount = useCallback(() => {
    if (!existingBilling || !feeAccountOptions) {
      return null;
    }
    const feeAcc = feeAccountOptions.find(
      fa => fa.value === existingBilling.fees_account_id
    );
    if (feeAcc === undefined) {
      return null;
    }
    return { label: feeAcc.label, value: feeAcc.value };
  }, [existingBilling, feeAccountOptions]);

  const fillFeeOption = useCallback(() => {
    if (!existingBilling || !feeTypeOptions) {
      return null;
    }
    const billingWithDescription: billingProfileUtil.BillingWithFeeDescription =
      existingBilling as billingProfileUtil.BillingWithFeeDescription;
    const feeOption = feeTypeOptions.find(
      ft => ft.label === billingWithDescription.fee_description
    );
    if (feeOption === undefined) {
      return null;
    }
    return { label: feeOption.label, value: feeOption.value };
  }, [existingBilling, feeTypeOptions]);

  return (
    <div>
      {isLoading && <TransactionsLoadingState></TransactionsLoadingState>}
      {!isLoading && (
        <Form
          initialValues={{
            ...getInitialValues(),
          }}
          onSubmit={saveProfile}
          validate={validateForm}
        >
          {formProps => (
            <form autoComplete="off" onSubmit={formProps.handleSubmit}>
              <Prompt
                message={handleBlockedNavigation}
                when={formProps.dirty}
              />
              <DeleteFormDataModal
                status={deleteModalState.status}
                message={
                  <span>
                    The changes you've made to this recurring fee have{' '}
                    <strong> not been saved.</strong> You will lose all these
                    changes if you leave this page.
                  </span>
                }
                modalDispatch={deleteModalDispatch}
                actionFunc={() => {
                  clearForm(formProps.form.reset);
                  afterBlockedNavigation();
                }}
                cancelText="Stay on this Page"
                actionText="Leave this Page"
              />
              <CTAPageHeader
                headingText={
                  <span>
                    {existingBilling ? 'Edit - ' : 'Add - '}Recurring Fee
                    {formProps.values.fee_description_id ? (
                      <>
                        {' - '}{' '}
                        <strong>
                          {formProps.values.fee_description_id?.label}
                        </strong>
                      </>
                    ) : (
                      ''
                    )}
                  </span>
                }
                subheadingText="Recurring Fee that is collected at a fixed interval"
                ctaArea={
                  formProps.dirty
                    ? [
                        <SmallSecondaryButton
                          onClick={event => {
                            event.preventDefault();
                            setFromCancel(true);
                            if (!existingBilling) {
                              deleteModalDispatch(sendOpenSelected());
                            } else {
                              clearForm(formProps.form.reset);
                            }
                          }}
                          disabled={!formProps.dirty}
                          data-trackingid="bp-recurring-cancel-button"
                          key="bp-recurring-cancel-button"
                          type="button"
                        >
                          {existingBilling ? 'Reset Changes' : 'Cancel'}
                        </SmallSecondaryButton>,
                        <SmallPrimaryButton
                          type="submit"
                          disabled={!formProps.valid}
                          data-trackingid="bp-recurring-create-button"
                          key="bp-recurring-create-button"
                        >
                          {existingBilling
                            ? 'Update Recurring Fee'
                            : 'Create Recurring Fee'}
                        </SmallPrimaryButton>,
                      ]
                    : []
                }
                breadcrumb={{
                  text: 'Billing Profiles',
                  path: `/merchant/${merchant?.id}/billing-profiles`,
                }}
              />
              <BPSettingsRow data-highlight>
                <BPSettingsRowTitle>
                  <h3>Fee Type</h3>
                  <span>A fee collected at a fixed interval</span>
                </BPSettingsRowTitle>
                <BPSettingsRowFields>
                  <FieldWrap style={{ maxWidth: '180px' }}>
                    <Field
                      name="fee_description_id"
                      required={true}
                      clearable={false}
                    >
                      {props => {
                        return (
                          <>
                            {feeTypeOptions ? (
                              <div data-testid="bp-recurring-fee-type">
                                <Select
                                  {...props.input}
                                  options={feeTypeOptions}
                                  disabled={!feeTypeOptions}
                                  onChange={option => {
                                    if (option) {
                                      setIsTypeSelected(true);
                                    }
                                    props.input.onChange(option);
                                  }}
                                  placeholder="Choose a Type"
                                  defaultValue={fillFeeOption()}
                                  isClearable={false}
                                  data-trackingid="bp-recurring-fee-type"
                                  label="Recurring Fee Type"
                                  error={!!formProps.errors?.fee_description_id}
                                  helperText={
                                    formProps.touched?.fee_description_id
                                      ? formProps.errors?.fee_description_id
                                      : null
                                  }
                                />
                              </div>
                            ) : null}
                          </>
                        );
                      }}
                    </Field>
                  </FieldWrap>
                </BPSettingsRowFields>
                <BPSettingsRowDescription>
                  <FeeTypeDescription
                    feeDescription={formProps.values?.fee_description_id?.label}
                  />
                </BPSettingsRowDescription>
              </BPSettingsRow>
              {isTypeSelected ? (
                <BPSettingsRow>
                  <BPSettingsRowTitle>
                    <h3>Fee</h3>
                    <span>
                      The recurring fee that is collected at a fixed interval
                    </span>
                  </BPSettingsRowTitle>
                  <BPSettingsRowFields>
                    <ThreeCol>
                      <SubCol>
                        <Label
                          text="Recurring Fee"
                          info="Fee amount per recurring."
                        />
                        <FieldWrap style={{ margin: '8px 0 0 0' }}>
                          <Field
                            name="per_transaction_amount"
                            format={v =>
                              v !== null && v !== undefined
                                ? v.toString().replace(/[^0-9.,]/g, '')
                                : ''
                            }
                            parse={v =>
                              v !== null && v !== undefined
                                ? v.toString().replace(/[^0-9.,]/g, '')
                                : ''
                            }
                          >
                            {props => {
                              return (
                                <TextField
                                  {...props.input}
                                  label="Amount"
                                  placeholder="0.00"
                                  inputPrefix={
                                    <Icon icon={['fas', 'dollar-sign']} />
                                  }
                                  required
                                />
                              );
                            }}
                          </Field>
                        </FieldWrap>
                      </SubCol>
                      <SubCol>
                        <Label
                          text="Billing Cycle"
                          info="When fees will be billed."
                        />
                        <FieldWrap style={{ margin: '8px 0 0 0' }}>
                          <Field
                            name="billing_cycle"
                            type="radio"
                            value="DAILY"
                          >
                            {props => {
                              return (
                                <RadioButton {...props.input} label="Daily" />
                              );
                            }}
                          </Field>
                          <Field
                            name="billing_cycle"
                            type="radio"
                            value="MONTHLY"
                          >
                            {props => {
                              return (
                                <RadioButton {...props.input} label="Monthly" />
                              );
                            }}
                          </Field>
                          <Field
                            name="billing_cycle"
                            type="radio"
                            value="ANNUALLY"
                          >
                            {props => {
                              return (
                                <RadioButton {...props.input} label="Yearly" />
                              );
                            }}
                          </Field>
                        </FieldWrap>
                        {formProps.values.billing_cycle === 'MONTHLY' ? (
                          <FieldWrap
                            style={{
                              margin: '16px 0 0 0',
                              maxWidth: '100px',
                            }}
                          >
                            <label>Day of Month</label>
                            <Field
                              name="billing_cycle_day"
                              format={value => {
                                return billingCycleDayOptions.find(
                                  option => option?.value === value
                                );
                              }}
                              parse={option => {
                                return option?.value;
                              }}
                              clearable={false}
                            >
                              {props => {
                                return (
                                  <div>
                                    <Select
                                      {...props.input}
                                      options={billingCycleDayOptions}
                                      isClearable={false}
                                      data-trackingid="bp-recurring-billing-cycle-day"
                                    />
                                  </div>
                                );
                              }}
                            </Field>
                          </FieldWrap>
                        ) : null}
                        {formProps.values.billing_cycle === 'ANNUALLY' ? (
                          <FieldWrap
                            style={{
                              margin: '16px 0 0 0',
                              maxWidth: '100px',
                            }}
                          >
                            <label>Month</label>
                            <Field
                              name="billing_cycle_month"
                              format={value => {
                                return billingCycleMonthOptions.find(
                                  option => option?.value === value
                                );
                              }}
                              parse={option => option?.value}
                              clearable={false}
                            >
                              {props => {
                                return (
                                  <div>
                                    <Select
                                      {...props.input}
                                      options={billingCycleMonthOptions}
                                      isClearable={false}
                                      data-trackingid="bp-transaction-billing-cycle-month"
                                      error={
                                        !!formProps.errors?.billing_cycle_month
                                      }
                                      helperText={
                                        formProps.errors?.billing_cycle_month
                                      }
                                    />
                                  </div>
                                );
                              }}
                            </Field>
                            <label>Day of Month</label>
                            <Field
                              name="billing_cycle_day"
                              format={value => {
                                return billingCycleDayOptions.find(
                                  option => option?.value === value
                                );
                              }}
                              parse={option => {
                                return option?.value;
                              }}
                              clearable={false}
                            >
                              {props => {
                                return (
                                  <div>
                                    <Select
                                      {...props.input}
                                      options={billingCycleDayOptions}
                                      isClearable={false}
                                      data-trackingid="bp-transaction-billing-cycle-day"
                                      error={
                                        !!formProps.errors?.billing_cycle_day
                                      }
                                      helperText={
                                        formProps.errors?.billing_cycle_day
                                      }
                                    />
                                  </div>
                                );
                              }}
                            </Field>
                          </FieldWrap>
                        ) : null}
                      </SubCol>
                      <SubCol>
                        <Label
                          text="Settlement"
                          info="Gross separates charge and fee recurrings. Net combines charge and fee recurrings."
                        />
                        <FieldWrap style={{ margin: '8px 0 0 0' }}>
                          <Field
                            name="is_gross_settlement"
                            type="radio"
                            value="true"
                          >
                            {props => {
                              return (
                                <RadioButton {...props.input} label="Gross" />
                              );
                            }}
                          </Field>
                          <Field
                            name="is_gross_settlement"
                            type="radio"
                            value="false"
                          >
                            {props => {
                              return (
                                <RadioButton {...props.input} label="Net" />
                              );
                            }}
                          </Field>
                        </FieldWrap>
                      </SubCol>
                    </ThreeCol>
                    <TwoCol>
                      <FieldWrap style={{ maxWidth: '200px' }}>
                        <Field
                          name="fees_account_id"
                          format={value => {
                            return billingCycleDayOptions.find(
                              option => option?.value === value
                            );
                          }}
                          parse={option => {
                            return option?.value;
                          }}
                          required
                        >
                          {props => {
                            return (
                              <>
                                {feeAccountOptions ? (
                                  <div data-testid="bp-recurring-fee-account">
                                    <Select
                                      {...props.input}
                                      data-trackingid="bp-recurring-fee-account"
                                      label="Default Fee Account"
                                      info="Default account that fees will be taken from."
                                      defaultValue={fillFeeAccount()}
                                      required
                                      options={feeAccountOptions}
                                      isDisabled={!feeAccountOptions}
                                      isClearable={false}
                                      error={
                                        !!formProps.errors?.fees_account_id
                                      }
                                      helperText={
                                        formProps.touched?.fees_account_id
                                          ? formProps.errors?.fees_account_id
                                          : null
                                      }
                                    />
                                  </div>
                                ) : null}
                              </>
                            );
                          }}
                        </Field>
                      </FieldWrap>
                    </TwoCol>
                  </BPSettingsRowFields>
                  <BPSettingsRowDescription>
                    <FeeDescription
                      perTransactionAmount={
                        formProps.values.per_transaction_amount
                      }
                      feeDescription={
                        formProps.values.fee_description_id?.label
                      }
                      isGross={formProps.values.is_gross_settlement === 'true'}
                      feesAccount={
                        feeAccountOptions &&
                        feeAccountOptions.find(
                          account =>
                            account.value === formProps.values.fees_account_id
                        )?.label
                      }
                      billingCycle={formProps.values.billing_cycle}
                      billingCycleDay={formProps.values.billing_cycle_day}
                      billingCycleMonth={formProps.values.billing_cycle_month}
                    />
                  </BPSettingsRowDescription>
                </BPSettingsRow>
              ) : null}
            </form>
          )}
        </Form>
      )}
    </div>
  );
};
