export const AsyncDataStatus = {
  /** The initial status **before** the async data fetch is executed. */
  INITIAL: 'INITIAL' as const,
  /** The status **after** an async data fetch completes successfully. */
  IDLE: 'IDLE' as const,
  /** The status **while** an async data fetch is in-flight. */
  LOADING: 'LOADING' as const,
  /** The status **after** an async data fetch completes with an error. */
  ERROR: 'ERROR' as const,
};
export type AsyncDataStatus = keyof typeof AsyncDataStatus;
export type AsyncData<T> =
  | { status: typeof AsyncDataStatus.IDLE; data: T; error?: Error }
  | { status: typeof AsyncDataStatus.INITIAL; data: null; error?: Error }
  | { status: typeof AsyncDataStatus.ERROR; data: T | null; error?: Error }
  | { status: typeof AsyncDataStatus.LOADING; data: null; error?: Error };

export type AsyncDataNoNull<T> =
  | { status: typeof AsyncDataStatus.IDLE; data: T; error: null }
  | { status: typeof AsyncDataStatus.INITIAL; data: T; error: null }
  | { status: typeof AsyncDataStatus.ERROR; data: T; error: Error }
  | { status: typeof AsyncDataStatus.LOADING; data: T; error: null };
