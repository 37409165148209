import React, { FunctionComponent, cloneElement } from 'react';

import styled from 'styled-components';
import { StepsProps } from './Steps.types';
import { StepProps } from './Step.types';
import { Step } from './Step';

const StyledSteps = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  > * {
    flex-grow: 1;
    flex-basis: 0;
  }
`;

export const Steps: FunctionComponent<StepsProps> & {
  Step: React.FunctionComponent<StepProps>;
} = props => {
  const { children, current } = props;

  return (
    <StyledSteps>
      {children.map((child, index) =>
        cloneElement(child, {
          ...child.props,
          index,
          current: child.props.current ?? current,
          key: `steps-step-${index}`
        })
      )}
    </StyledSteps>
  );
};

Steps.Step = Step;
