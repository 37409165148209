import React from 'react';
import { TableSectionV3 } from '../styles/table-section-component-v3';
import { Text } from '@fattmerchantorg/truffle-components';
import { StatementV3Props } from '../util/statement-v3-utils';
import styled from 'styled-components';
import { FlexibleColumns, WidgetWrapper } from '../styles/dashboard-styles';
import currency from 'currency.js';
import { useStatementSummaryState } from '../../../../context/statements';
import { Link } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextContainer } from '../../components';

const StyledWidgetWrapper = styled(WidgetWrapper)`
  position: relative;
  padding: 16px, 16px, 24px, 16px;
  border: 0px, 0px, 1px, 0px;
  background: ${props => props.theme.colors.core.gray[800].hex};
  height: 130px !important;
  font-size: 24px;
  font-weight: 700;
  box-shadow: 0 0 0 0px;
  &:hover {
    box-shadow: 0px 4px 8px rgba(6, 35, 51, 0.15);
    cursor: pointer;
    border: 1px solid ${props => props.theme.colors.core.green[400].hex};
  }
  .externalLink {
    display: none;
  }
  &:hover .externalLink {
    display: block;
  }
`;

const WidgetItemsContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
`;

const WidgetFlexibleColumns = styled(FlexibleColumns)`
  padding: 0px 0px 16px 0px;
  width: 100%;
  @media print {
    grid-auto-flow: column;
  }
`;

const WidgetItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
`;

const WidgetItemSecondaryText = styled(Text)`
  padding: 2px 0px 16px 0px;
  font-size: 14px;
  font-weight: 500;
  line-height: '14.06px';
  width: 50%;
  color: ${props => props.theme.white};
`;

const AmountText = styled(Text)`
  font-size: 26px;
  padding: 4px 0px 12px 0px;
  font-weight: 700;
  color: ${props => props.theme.colors.core.green[400].hex};
  line-height: 36px;
`;

const StyledExternalLinkIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.status.blue[500].hex} !important;
  float: right;
  margin-right: 4px;
  margin-top: 6px;
`;

export const SummarySection: React.FC<StatementV3Props> = props => {
  let {
    startDate,
    endDate,
    totalFeesRef,
    refundsAdjustmentsRef,
    dailySalesRef,
    merchantId,
  } = props;
  const { statementSummaryState } = useStatementSummaryState();

  return (
    <TableSectionV3>
      <TextContainer fontWeight="bold" paddingBottom="8px">
        <Text as="h5">Summary</Text>
      </TextContainer>
      <WidgetFlexibleColumns>
        <StyledWidgetWrapper>
          <WidgetItemsContainer
            onClick={() =>
              window.scrollTo({
                top: dailySalesRef.current?.offsetTop,
                behavior: 'smooth',
              })
            }
          >
            <WidgetItem>
              <AmountText as="span">
                {statementSummaryState.totalCharges
                  ? statementSummaryState.totalCharges
                  : 0}
              </AmountText>
              <WidgetItemSecondaryText as="span">
                Total Transactions
              </WidgetItemSecondaryText>
            </WidgetItem>
          </WidgetItemsContainer>
        </StyledWidgetWrapper>
        <StyledWidgetWrapper>
          <WidgetItemsContainer
            onClick={() =>
              window.scrollTo({
                top: dailySalesRef.current?.offsetTop,
                behavior: 'smooth',
              })
            }
          >
            <WidgetItem>
              <AmountText as="span">
                {statementSummaryState.totalSales
                  ? currency(statementSummaryState.totalSales).format()
                  : currency(0).format()}
              </AmountText>
              <WidgetItemSecondaryText style={{ width: '40%' }} as="span">
                Total Sales
              </WidgetItemSecondaryText>
            </WidgetItem>
          </WidgetItemsContainer>
        </StyledWidgetWrapper>
        <StyledWidgetWrapper>
          <WidgetItemsContainer
            onClick={() =>
              window.scrollTo({
                top: totalFeesRef.current?.offsetTop,
                behavior: 'smooth',
              })
            }
          >
            <WidgetItem>
              <AmountText as="span">
                {statementSummaryState.totalFees
                  ? currency(statementSummaryState.totalFees).format()
                  : currency(0).format()}
              </AmountText>
              <WidgetItemSecondaryText style={{ width: '40%' }} as="span">
                Total Fees
              </WidgetItemSecondaryText>
            </WidgetItem>
          </WidgetItemsContainer>
        </StyledWidgetWrapper>
        <StyledWidgetWrapper>
          <WidgetItemsContainer
            onClick={() =>
              window.scrollTo({
                top: refundsAdjustmentsRef.current?.offsetTop,
                behavior: 'smooth',
              })
            }
          >
            <WidgetItem>
              <AmountText as="span">
                {statementSummaryState.refundsAdjustments
                  ? currency(statementSummaryState.refundsAdjustments).format()
                  : currency(0).format()}
              </AmountText>
              <WidgetItemSecondaryText style={{ width: '90%' }} as="span">
                Total Refunds & Adjustments
              </WidgetItemSecondaryText>
            </WidgetItem>
          </WidgetItemsContainer>
        </StyledWidgetWrapper>
        <StyledWidgetWrapper>
          <StyledExternalLinkIcon
            className="externalLink"
            icon={faExternalLink as IconProp}
            alt="Twitter"
            size="xs"
          />
          <Link
            target="_blank"
            to={`/merchant/${merchantId}/deposits?startDate=${startDate}&endDate=${endDate}`}
          >
            <WidgetItemsContainer>
              <WidgetItem>
                <AmountText as="span">
                  {statementSummaryState.totalDeposited
                    ? currency(statementSummaryState.totalDeposited).format()
                    : currency(0).format()}
                </AmountText>
                <WidgetItemSecondaryText as="span">
                  Total Deposited
                </WidgetItemSecondaryText>
              </WidgetItem>
            </WidgetItemsContainer>
          </Link>
        </StyledWidgetWrapper>
      </WidgetFlexibleColumns>
    </TableSectionV3>
  );
};
