import React, { useCallback, useContext, useEffect } from 'react';
import { Table } from 'reactstrap';
import styled, { withTheme } from 'styled-components';
import { formatCurrency } from '../../../../util/format.util';
import { TableProps } from './MerchantPerformanceTable.types';
import { BoldText } from '../shared/BoldText';
import { ChartPreRender } from '../shared/ChartPreRender';
import { ChartTypes } from '../../../../@types';
import {
  SelectedMerchantStore,
  updateSelectedMerchant,
} from '../../../../context';
import { history } from '../../../../history';
import { coreapi } from '../../../../api';
import { useAuthToken, useToaster } from '../../../../hooks';

const StyledTr = withTheme(
  styled.tr`
    color: ${({ theme }) => theme.white};

    td {
      line-height: 18px;
      border-top: 1px solid ${({ theme }) => theme.black} !important;
    }

    &:hover {
      background: ${({ theme }) => theme.colors.core.green[800].hex};
    }
  `
);

const StyledTable = withTheme(
  styled(Table)`
    outline: 1px solid ${({ theme }) => theme.colors.core.gray[600].hex} !important;
    table-layout: fixed;
  `
);

const StyledTHead = withTheme(
  styled.thead`
    background: ${({ theme }) => theme.colors.core.gray[800].hex} !important;

    th {
      border: 0 !important;
      text-transform: unset !important;
      color: ${({ theme }) => theme.white} !important;
      font-size: 14px !important;
      font-weight: 700;
    }
  `
);

const NarrowWidthTh = styled.th`
  width: 60px;
`;

const MediumWidthTh = styled.th`
  width: 160px;
  text-align: right;
`;

const StyledMerchantLink = withTheme(
  styled.span`
    cursor: pointer;
    color: ${({ theme }) => theme.white};

    &:hover {
      color: ${({ theme }) => theme.white};
    }
  `
);

export function MerchantPerformanceTable<T>(props: TableProps<T>): JSX.Element {
  const { title, data, dataStatus, performanceType, ...tableProps } = props;
  const hasData: boolean = data === null || data.length > 0;

  const { toast, toaster } = useToaster();

  const authToken = useAuthToken();
  const {
    state: { effect },
    dispatch,
  } = useContext(SelectedMerchantStore);

  const onItemClick = useCallback(
    async (merchantId: string) => {
      try {
        const merchant = await coreapi.get(
          authToken,
          `/merchant/${merchantId}`
        );
        dispatch(updateSelectedMerchant(merchant));
      } catch (error) {
        toaster(toast.error(error, 'Error selecting merchant'));
      }
    },
    [authToken, dispatch, toaster, toast]
  );

  // Side-effects that are triggered by changes to context
  useEffect(() => {
    switch (effect?.type) {
      // this is all we need for now,
      // but can be extended with other effect types if need be in future
      case 'UPDATE_SELECTED_MERCHANT': {
        // Once context has updated after user adds a new merchant,
        // now we may navigate to the enrollment page
        if (!effect?.payload) {
          // highly unusual, but technically possible for effect.payload to be undefined/null
          console.error(
            'Unable to navigate to merchant enrollment page without ID of selected merchant.'
          );
          return;
        }
        const merchantId = effect.payload?.id;

        history.push(`/merchant/${merchantId}/businessinfo`);

        break;
      }
    }
  }, [effect]);

  if (performanceType === 'Highest Gross Sales') {
    return (
      <>
        <BoldText fontSize={14}>{title}</BoldText>

        {ChartPreRender(dataStatus, hasData, ChartTypes.PERFORMANCE, true) ?? (
          <StyledTable className="table-flush" size={'sm'} {...tableProps}>
            <StyledTHead>
              <tr>
                <th>Company</th>
                <th style={{ width: 120 }} className="text-right">
                  Gross Sales
                </th>
              </tr>
            </StyledTHead>
            <tbody>
              {data.length
                ? data.map((item, index) => (
                    <StyledTr key={`collection-row-${index}`}>
                      <td>
                        <StyledMerchantLink
                          onClick={() => onItemClick(item.merchant_id)}
                          className="text-truncate d-block"
                          title={item.company_name}
                        >
                          {item.company_name}
                        </StyledMerchantLink>
                      </td>
                      <td className="text-right">
                        <StyledMerchantLink
                          onClick={() => onItemClick(item.merchant_id)}
                          className="text-truncate d-block"
                          title={item.company_name}
                        >
                          {formatCurrency(item.sum)}
                        </StyledMerchantLink>
                      </td>
                    </StyledTr>
                  ))
                : null}
            </tbody>
          </StyledTable>
        )}
      </>
    );
  } else {
    return (
      <>
        <BoldText fontSize={14}>{title}</BoldText>

        {ChartPreRender(dataStatus, hasData, ChartTypes.PERFORMANCE, true) ?? (
          <StyledTable className="table-flush" size={'sm'} {...tableProps}>
            <StyledTHead>
              <tr>
                <th>Company</th>
                <MediumWidthTh>Approved Volume</MediumWidthTh>
                <MediumWidthTh>Actual Volume</MediumWidthTh>
                <NarrowWidthTh className="text-center">%</NarrowWidthTh>
              </tr>
            </StyledTHead>
            <tbody>
              {data.length
                ? data.map((item, index) => (
                    <StyledTr key={`collection-row-${index}`}>
                      <td>
                        <StyledMerchantLink
                          onClick={() => onItemClick(item.merchant_id)}
                          className="text-truncate d-block"
                          title={item.company_name}
                        >
                          {item.company_name}
                        </StyledMerchantLink>
                      </td>
                      <td className="text-right">
                        <StyledMerchantLink
                          onClick={() => onItemClick(item.merchant_id)}
                          className="text-truncate d-block"
                          title={item.company_name}
                        >
                          {formatCurrency(item.timespan_approved_volume)}
                        </StyledMerchantLink>
                      </td>
                      <td className="text-right">
                        <StyledMerchantLink
                          onClick={() => onItemClick(item.merchant_id)}
                          className="text-truncate d-block"
                          title={item.company_name}
                        >
                          {formatCurrency(item.sum)}
                        </StyledMerchantLink>
                      </td>
                      <td className="text-right font-weight-bold">
                        <StyledMerchantLink
                          onClick={() => onItemClick(item.merchant_id)}
                          className="text-truncate d-block"
                          title={item.company_name}
                        >
                          {((item.sum * 100) / item.annual_volume).toFixed(2)}%
                        </StyledMerchantLink>
                      </td>
                    </StyledTr>
                  ))
                : null}
            </tbody>
          </StyledTable>
        )}
      </>
    );
  }
}
