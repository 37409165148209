import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

// Places animation at the center of the area.
export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;

  #loader-icon {
    color: silver;
  }
`;

/**
 * Displays loading animation only.
 */
export function LoadingAnimation(): JSX.Element {
  return (
    <LoadingWrapper>
      <FontAwesomeIcon
        id="loader-icon"
        icon={faSpinnerThird as IconProp}
        spin={true}
        size="10x"
      />
    </LoadingWrapper>
  );
}
