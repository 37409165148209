import React from 'react';
import { useTheme } from 'styled-components';
import { formatBigNumber } from '../../../../util/dashboard.util';
import { ITick } from './types';
import { withAlpha } from '../../../../util/color.util';

// Function to give the y-axis custom format.
export function LeftAxis(tick: Partial<ITick>): JSX.Element {
  // Pluck the y-axis value, and its position.
  const { value: axisValue, x, y } = tick;
  const theme = useTheme() as any;

  // The styles and <line> in the element below are needed to retain
  // default styles and align with the rest of the non-custom elements.
  return (
    <g transform={`translate(${x},${y})`}>
      <line
        x1="0"
        x2="-5"
        y1="0"
        y2="0"
        style={{ stroke: withAlpha(theme.colors.core.gray[50].hex, 0.1), strokeWidth: 1 }}
      ></line>
      <text
        textAnchor="end"
        dominantBaseline="central"
        transform="translate(-10,0) rotate(0)"
        style={{
          fill: 'rgb(255, 255, 255)',
          fontSize: 11,
        }}
      >
        ${formatBigNumber(parseFloat(axisValue))}
      </text>
    </g>
  );
}
