import { Billing, Reserves } from '@fattmerchantorg/types-engine/DB';
import { pick } from '../../util/object.util';
import {
  ReserveInfoFieldsValues,
  ReserveInfoFieldsErrors,
} from './ReserveInfoForm.Types';

export function mapFormValuesToPayload(
  formValues: ReserveInfoFieldsValues
): Partial<Billing & Reserves> {
  return {
    reserve_flat_amount: formValues.reserve_flat_amount,
    reserve_percent_volume: formValues.reserve_percent_volume,
    reserve_percent_days: formValues.reserve_percent_days,
    balance: formValues.balance,
    target: formValues.target,
  };
}

export function mapRegistrationToInitialFormValues(
  reserves: Billing & Reserves
): ReserveInfoFieldsValues {
  return pick(
    reserves,
    'billing_id',
    'reserve_flat_amount',
    'reserve_percent_volume',
    'reserve_percent_days',
    'balance',
    'target'
  ) as ReserveInfoFieldsValues;
}

export function validateRequiredFormValues(
  values: ReserveInfoFieldsValues
): ReserveInfoFieldsErrors {
  const errors = {} as ReserveInfoFieldsErrors;

  const requiredFields = ['reserve_percent_volume'];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  return errors;
}

export function validateFormValues(
  values: ReserveInfoFieldsValues
): ReserveInfoFieldsErrors {
  const errors = {} as ReserveInfoFieldsErrors;

  return errors;
}
