import { pick } from '../../../util/object.util';
import {
  TransactionProfileFieldsValues,
  TransactionProfileFieldsErrors,
  TransactionProfilePayload,
} from './TransactionProfileForm.types';
import { Mutator } from 'final-form';
import { ChannelSelectOption } from '../util/billingProfile.util';
import { divideAmounts } from '../../../util/currency.util';

export function mapFormValuesToPayload(
  formValues: TransactionProfileFieldsValues
): TransactionProfilePayload {
  return {
    /** Not in UI */
    type: 'TRANSACTION',
    state: 'ACTIVE',
    brand_id: null,
    /** -- */
    billing_cycle_day: formValues.billing_cycle_day,
    billing_cycle: formValues.billing_cycle,
    channel: formValues.channel?.value,
    deposit_account_id: formValues.deposit_account_id,
    fee_cap_amount: formValues.fee_cap_amount,
    fee_percent: divideAmounts(formValues.fee_percent, 100.0, {
      precision: 15,
    }),
    fees_account_id: formValues.fees_account_id,
    funding_schedule_days: formValues.funding_schedule_days,
    is_gross_settlement: formValues.is_gross_settlement === 'true',
    per_transaction_amount: formValues.per_transaction_amount,
    subchannel: formValues.subchannel?.value,
    is_interchange_passthru: formValues.is_interchange_passthru === 'true',
    interchange_dues_assessments: divideAmounts(
      formValues.interchange_dues_assessments,
      100.0,
      { precision: 5 }
    ),
  };
}

export function mapBillingProfileToInitialFormValues(
  billingProfile: Partial<TransactionProfileFieldsValues>
) {
  return pick(
    billingProfile,
    'billing_cycle_day',
    'billing_cycle',
    'channel',
    'deposit_account_id',
    'fee_cap_amount',
    'fee_percent',
    'fees_account_id',
    'funding_schedule_days',
    'is_gross_settlement',
    'is_interchange_passthru',
    'interchange_dues_assessments',
    'per_transaction_amount',
    'subchannel'
  );
}

export function validateFormValues(
  values: TransactionProfileFieldsValues
): TransactionProfileFieldsErrors {
  const errors = {} as TransactionProfileFieldsErrors;

  if (isNaN(+values.fee_percent)) {
    errors.fee_percent = 'Must be a number';
  }
  if (values.fee_percent > 99.999) {
    errors.fee_percent = 'Cannot be greater than 99';
  }
  if (!values.channel) {
    errors.channel = 'A channel must be selected.';
  }

  if (!['BANK', 'ALL'].includes(values.channel?.value) && !values.subchannel) {
    errors.subchannel = 'A subchannel must be selected.';
  }

  if (!values.deposit_account_id) {
    errors.deposit_account_id = 'A deposit account must be selected.';
  }

  if (!values.fees_account_id) {
    errors.fees_account_id = 'A fees account must be selected.';
  }

  if (
    values.funding_schedule_days &&
    (values.funding_schedule_days < 0 || values.funding_schedule_days > 180)
  ) {
    errors.funding_schedule_days =
      'A deposit delay duration within 0-180 days must be entered.';
  }

  if (
    values.billing_cycle_day &&
    (values.billing_cycle_day < 1 || values.billing_cycle_day > 28)
  ) {
    errors.billing_cycle_day =
      'Billing Cycle Day of Month must be set to a value between 1-28.';
  }

  return errors;
}

export const formMutators: {
  [key: string]: Mutator<TransactionProfileFieldsValues>;
} = {
  setDefaultSubchannel: (
    args: ChannelSelectOption['value'][],
    state,
    utils
  ) => {
    const channel = args[0];

    if (['BANK', 'ALL'].includes(channel)) {
      utils.changeValue(state, 'subchannel', () => null);
    }
  },

  unsetFeeCap: (_args, state, utils) => {
    utils.changeValue(state, 'fee_cap_amount', () => {
      return null;
    });
  },
};
