import React, { FC, useContext, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { Widget } from '../../shared/Widget';
import { AsyncData, AsyncDataStatus } from '../../../@types';
import { LoadingSpan } from '../../shared';
import { Billing } from '@fattmerchantorg/types-engine/DB';
import { BaseModal, Icon, Tooltip } from '@fattmerchantorg/truffle-components';
import { ModalContext, SelectedMerchantStore } from '../../../context';
import {
  BillingWithFeeDescription,
  getFeeExtraInfo,
  getFeeValue,
  GroupedBillingProfiles,
  groupRecurringBillingProfiles,
  groupTransactionBillingProfiles,
} from '../../billing-profiles/util/billingProfile.util';
import { getSurchargeRate } from '../../../util/registration.util';

const Container = styled(Widget)`
  background: white;
  min-height: 119px;
`;

const ModalContainer = styled(Container)`
  border-radius: 0;
`;

const StyledTooltip = styled(Tooltip)`
  background-color: ${({ theme }) => theme.colors.core.gray[700].hex};
  display: flex;
  text-align: left;
  align-items: baseline;
  vertical-align: text-bottom;
  .tippy-arrow::before {
    border-left-color: ${({ theme }) =>
      theme.colors.core.gray[700].hex} !important;
  }
`;

const WidgetHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding-bottom: 8px;
  font-size: 18px;
  border-bottom: 1px solid #bdc9cc;
  color: ${({ theme }) => theme.black};
  i {
    margin: 0 0 8px;
  }
`;

const WidgetFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  padding-top: 8px;
  font-size: 18px;
  border-top: 1px solid #bdc9cc;
  color: ${({ theme }) => theme.black};
  i {
    margin: 0 0 8px;
  }
`;

const WidgetHeaderIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Content = withTheme(
  styled.div`
    color: #565656;
    display: flex;
    flex-direction: row;
    margin: 1rem;

    span {
      line-height: 21px;
      font-size: 14px;
      color: ${({ theme }) => theme.black};
      white-space: normal;
    }

    h2 {
      margin: 0px;
      color: ${({ theme }) => theme.black};
    }

    > div {
      flex: 50%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    button {
      color: #32325d;
      padding: 0;
    }

    .billing-profile-content {
      p {
        font-size: 12px;
      }
      table {
        width: 100%;
        color: ${({ theme }) => theme.black};
        thead {
          tr {
            th {
              font-weight: bold;
              font-size: 12px;
              padding: 5px 10px 5px 10px;
              text-align: right;
              &:first-child {
                text-align: left;
              }
              &:last-child {
                padding-left: 0;
              }
            }
          }
        }
        tbody {
          tr {
            &:nth-child(odd) {
              background-color: #ebf9f0;
            }
            td {
              font-weight: bold;
              font-size: 14px;
              padding: 5px 10px 5px 10px;
              text-align: right;
              &:first-child {
                text-align: left;
              }
              &:last-child {
                padding-left: 0;
              }
            }
          }
        }
      }
    }
  `
);

const ConsolidatedContent = withTheme(
  styled.div`
    color: #565656;
    display: flex;
    flex-direction: row;
    margin: 1rem;

    span {
      line-height: 21px;
      font-size: 14px;
      color: ${({ theme }) => theme.black};
      white-space: normal;
    }

    h2 {
      margin: 0px;
      color: ${({ theme }) => theme.black};
    }

    > div {
      flex: 50%;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    button {
      color: #32325d;
      padding: 0;
    }

    .billing-profile-content {
      p {
        font-size: 12px;
      }
      table {
        width: 100%;
        color: ${({ theme }) => theme.black};
        thead {
          tr {
            th {
              font-weight: bold;
              font-size: 12px;
              padding: 5px 5px 5px 5px;
              text-align: right;
              &:first-child {
                text-align: left;
              }
              &:last-child {
                padding-left: 0;
              }
            }
          }
        }
        tbody {
          tr {
            &:nth-child(odd) {
              background-color: #ebf9f0;
            }
            td {
              font-weight: bold;
              font-size: 14px;
              padding: 5px 5px 5px 5px;
              text-align: right;
              &:first-child {
                text-align: left;
              }
              &:last-child {
                padding-left: 0px;
              }
            }
          }
        }
      }
    }
  `
);

const LoadingRow = styled.div`
  display: flex;
  margin-bottom: 8px;

  /* loading spans */
  > div + div {
    margin-left: 3rem;
  }
`;

const StyledModalContent = styled.div`
  grid-area: content;
  overflow-y: auto;
`;

const FeeExtraInfo = withTheme(
  styled.div`
    color: ${({ theme }) => theme.colors.core.green[800].hex};
    font-weight: normal;
  `
);

const SectionHeaderIcon = styled(Icon)`
  position: relative;
  top: 2px;
`;

export const BillingProfileWidget: FC<{
  billingProfiles: AsyncData<Billing[]>;
}> = ({ billingProfiles }) => {
  if (!billingProfiles) {
    return null;
  }

  switch (billingProfiles.status) {
    case AsyncDataStatus.LOADING: {
      return <WidgetLoadingState />;
    }
    case AsyncDataStatus.IDLE: {
      if (!Array.isArray(billingProfiles.data)) {
        return <></>;
      }
      return (
        <>
          <TransactionFeesWidget billingProfiles={billingProfiles.data} />
          <OtherFeesWidget billingProfiles={billingProfiles.data} />
        </>
      );
    }
    default:
      return <></>;
  }
};

const WidgetLoadingState: FC = () => {
  return (
    <>
      <Container>
        <Content>
          <div>
            <WidgetHeader>
              <h2>Transactions Fees</h2>
            </WidgetHeader>
            <LoadingRow>
              <LoadingSpan width="100%" />
            </LoadingRow>
            <LoadingRow>
              <LoadingSpan width="100%" />
            </LoadingRow>
            <LoadingRow>
              <LoadingSpan width="100%" />
            </LoadingRow>
          </div>
        </Content>
      </Container>
      <Container>
        <Content>
          <div>
            <WidgetHeader>
              <h2>Other Fees</h2>
            </WidgetHeader>
            <LoadingRow>
              <LoadingSpan width="100%" />
            </LoadingRow>
            <LoadingRow>
              <LoadingSpan width="100%" />
            </LoadingRow>
            <LoadingRow>
              <LoadingSpan width="100%" />
            </LoadingRow>
          </div>
        </Content>
      </Container>
    </>
  );
};

const TransactionFeesWidget: FC<{ billingProfiles: Billing[] }> = ({
  billingProfiles,
}) => {
  const { modalDispatch } = useContext(ModalContext);
  const {
    state: { registration },
  } = useContext(SelectedMerchantStore);
  const surchargeRate = getSurchargeRate(registration);
  const data: GroupedBillingProfiles = groupTransactionBillingProfiles(
    billingProfiles.filter(b => ['TRANSACTION', 'DISPUTE'].includes(b.type))
  );

  const content = () => {
    const nullState = (
      <p>
        This account's billing profile has not been configured yet. Please reach
        out to your Partner Account Manager.
      </p>
    );

    let table;
    if (!billingProfiles || billingProfiles.length === 0) {
      table = (
        <table>
          <thead>
            <tr>
              <th>Payment Type</th>
              <th>Per Transaction Fee</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(data).map(group_name => {
              const transactionFee = data[group_name].find(
                b => b.type === 'TRANSACTION'
              );
              return (
                <tr key={group_name}>
                  <td>
                    {transactionFee?.funding_schedule_days > 0 && (
                      <StyledTooltip
                        placement="left"
                        content={
                          <span style={{ color: '#ffffff' }}>
                            Deposit Delay
                            <br />
                            <strong>
                              {transactionFee?.funding_schedule_days > 1
                                ? `${
                                    transactionFee?.funding_schedule_days + ' '
                                  }Days`
                                : `${
                                    transactionFee?.funding_schedule_days + ' '
                                  }Day`}
                            </strong>
                          </span>
                        }
                      >
                        <SectionHeaderIcon icon={['fas', 'clock']} fixedWidth />
                      </StyledTooltip>
                    )}
                    {group_name}
                  </td>
                  <td>{getFeeValue(transactionFee)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    } else {
      table = (
        <table>
          <thead>
            <tr>
              <th>Payment Type</th>
              <th>Per Transaction Fee</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(data).map(group_name => {
              const transactionFee = data[group_name].find(
                b => b.type === 'TRANSACTION'
              );
              return (
                <tr key={group_name}>
                  <td>
                    {group_name}
                    {renderPricingType(transactionFee?.is_interchange_passthru)}
                  </td>
                  <td>
                    {getFeeValue(transactionFee)
                      ? getFeeValue(transactionFee)
                      : `0\xA2`}
                    {renderFeeExtraInfo(transactionFee)}
                  </td>
                  <td>
                    {transactionFee?.funding_schedule_days > 0 ? (
                      <StyledTooltip
                        placement="top"
                        content={
                          <span style={{ color: '#ffffff' }}>
                            Deposit Delay
                            <br />
                            <strong>
                              {transactionFee?.funding_schedule_days > 1
                                ? `${
                                    transactionFee?.funding_schedule_days + ' '
                                  }Days`
                                : `${
                                    transactionFee?.funding_schedule_days + ' '
                                  }Day`}
                            </strong>
                          </span>
                        }
                      >
                        <i className="fas fa-clock"></i>
                      </StyledTooltip>
                    ) : transactionFee?.funding_schedule_days === 0 ? (
                      <StyledTooltip
                        placement="top"
                        content={
                          <span style={{ color: '#ffffff' }}>
                            Next Day Funding
                          </span>
                        }
                      >
                        <i className="fas fa-calendar-check"></i>
                      </StyledTooltip>
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
    return Object.keys(data).length === 0 ? nullState : table;
  };

  const launchModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        component: FeesModal,
        props: {
          isOpen: true,
          onClose: closeModal,
          content: (
            <DetailedTransactionFeesWidget
              data={data}
              surchargeRate={surchargeRate}
              onTitleClick={closeModal}
            />
          ),
        },
      },
    });
  };

  const closeModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    modalDispatch({
      type: 'CLOSE_MODAL',
    });
  };

  if (!billingProfiles || billingProfiles.length === 0) {
    return <></>;
  } else {
    return (
      <Container data-testid="transaction-fees-widget-container">
        <ConsolidatedContent>
          <div>
            <WidgetHeader>
              <h2>Transaction Profiles</h2>
              <WidgetHeaderIcon onClick={launchModal}>
                <Icon icon={['fas', 'chevron-right']} color="#009BF2" />
              </WidgetHeaderIcon>
            </WidgetHeader>
            <div className="billing-profile-content">{content()}</div>
            {surchargeRate ? (
              <WidgetFooter>
                <span>
                  <strong>Surcharge</strong>
                </span>
                <span>
                  <strong>{surchargeRate}%</strong>
                </span>
              </WidgetFooter>
            ) : null}
          </div>
        </ConsolidatedContent>
      </Container>
    );
  }
};

const OtherFeesWidget: FC<{ billingProfiles: Billing[] }> = ({
  billingProfiles,
}) => {
  const { modalDispatch } = useContext(ModalContext);
  const groupedRecurringFees: GroupedBillingProfiles =
    groupRecurringBillingProfiles(
      (billingProfiles as BillingWithFeeDescription[]).filter(b =>
        ['RECURRING'].includes(b.type)
      )
    );
  const otherFees = { ...groupedRecurringFees }; // merge any other fees (other than recurring fees) here

  if (Object.keys(otherFees).length === 0) {
    return null;
  }

  const launchModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        component: FeesModal,
        props: {
          isOpen: true,
          onClose: closeModal,
          content: (
            <DetailedOtherFeesWidget
              data={otherFees}
              onTitleClick={closeModal}
            />
          ),
        },
      },
    });
  };

  const closeModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    modalDispatch({
      type: 'CLOSE_MODAL',
    });
  };

  return (
    <Container data-testid="other-fees-widget-container">
      <Content>
        <div>
          <WidgetHeader>
            <h2>Other Fees</h2>
            <WidgetHeaderIcon onClick={launchModal}>
              <Icon icon={['fas', 'chevron-right']} color="#009BF2" />
            </WidgetHeaderIcon>
          </WidgetHeader>
          <div className="billing-profile-content">
            <table>
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Fee</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(otherFees).map(fee_name => {
                  return (
                    <tr key={fee_name}>
                      <td>{fee_name}</td>
                      <td>{getFeeValue(otherFees[fee_name][0])}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </Content>
    </Container>
  );
};

type DetailedTransactionFeesWidgetProps = {
  data: GroupedBillingProfiles;
  surchargeRate: number | null;
  onTitleClick?: Function;
};

const DetailedTransactionFeesWidget: FC<DetailedTransactionFeesWidgetProps> = ({
  data,
  surchargeRate,
  onTitleClick,
}) => {
  const content = () => {
    if (!data || Object.keys(data).length === 0) {
      return (
        <table>
          <thead>
            <tr>
              <th>Payment Type</th>
              <th>Per Transaction Fee</th>
              <th>Dispute Fee</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(data).map(group_name => {
              const transactionFee = data[group_name].find(
                b => b.type === 'TRANSACTION'
              );
              const disputeFee = data[group_name].find(
                b => b.type === 'DISPUTE'
              );
              return (
                <tr key={group_name}>
                  <td>
                    {transactionFee?.funding_schedule_days > 0 && (
                      <StyledTooltip
                        placement="left"
                        content={
                          <span style={{ color: '#ffffff' }}>
                            Deposit Delay
                            <br />
                            <strong>
                              {transactionFee?.funding_schedule_days > 1
                                ? `${
                                    transactionFee?.funding_schedule_days + ' '
                                  }Days`
                                : `${
                                    transactionFee?.funding_schedule_days + ' '
                                  }Day`}
                            </strong>
                          </span>
                        }
                      >
                        <Icon
                          style={{ marginRight: '1px' }}
                          icon={['fas', 'clock']}
                        />
                      </StyledTooltip>
                    )}
                    {group_name}
                  </td>
                  <td>
                    {getFeeValue(transactionFee)}
                    {renderFeeExtraInfo(transactionFee)}
                  </td>
                  <td>
                    {getFeeValue(disputeFee)}
                    {renderFeeExtraInfo(disputeFee)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    } else {
      return (
        <table>
          <thead>
            <tr>
              <th>Payment Type</th>
              <th>Per Transaction Fee</th>
              <th>Dispute Fee</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(data).map(group_name => {
              const transactionFee = data[group_name].find(
                b => b.type === 'TRANSACTION'
              );
              const disputeFee = data[group_name].find(
                b => b.type === 'DISPUTE'
              );
              return (
                <tr key={group_name}>
                  <td>
                    {group_name}
                    {renderPricingType(transactionFee.is_interchange_passthru)}
                  </td>
                  <td>
                    {getFeeValue(transactionFee)}
                    {renderFeeExtraInfo(transactionFee)}
                  </td>
                  <td>
                    {getFeeValue(disputeFee)}
                    {renderFeeExtraInfo(disputeFee)}
                  </td>
                  <td>
                    {transactionFee?.funding_schedule_days > 0 ? (
                      <StyledTooltip
                        placement="top"
                        content={
                          <span style={{ color: '#ffffff' }}>
                            Deposit Delay
                            <br />
                            <strong>
                              {transactionFee?.funding_schedule_days > 1
                                ? `${
                                    transactionFee?.funding_schedule_days + ' '
                                  }Days`
                                : `${
                                    transactionFee?.funding_schedule_days + ' '
                                  }Day`}
                            </strong>
                          </span>
                        }
                      >
                        <i className="fas fa-clock"></i>
                      </StyledTooltip>
                    ) : transactionFee?.funding_schedule_days === 0 ? (
                      <StyledTooltip
                        placement="top"
                        content={
                          <span style={{ color: '#ffffff' }}>
                            Next Day Funding
                          </span>
                        }
                      >
                        <i className="fas fa-calendar-check"></i>
                      </StyledTooltip>
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
  };

  const handleTitleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (typeof onTitleClick === 'function') {
      onTitleClick();
    }
  };

  return (
    <Content>
      <div>
        <WidgetHeader>
          <h2>Transactions Fees</h2>
          <WidgetHeaderIcon onClick={handleTitleClick}>
            <Icon icon={['fas', 'times']} color="#009BF2" />
          </WidgetHeaderIcon>
        </WidgetHeader>
        <div className="billing-profile-content">{content()}</div>
        {surchargeRate ? (
          <WidgetFooter>
            <span>
              <strong>Surcharge</strong>
            </span>
            <span>
              <strong>{surchargeRate}%</strong>
            </span>
          </WidgetFooter>
        ) : null}
      </div>
    </Content>
  );
};

type DetailedOtherFeesWidgetProps = {
  data: GroupedBillingProfiles;
  onTitleClick?: Function;
};

const DetailedOtherFeesWidget: FC<DetailedOtherFeesWidgetProps> = ({
  data,
  onTitleClick,
}) => {
  const content = () => {
    return (
      <table>
        <thead>
          <tr>
            <th>Description</th>
            <th>Fee</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(data).map(fee_name => {
            const fee = data[fee_name][0];
            return (
              <tr key={fee_name}>
                <td>{fee_name}</td>
                <td>
                  {getFeeValue(fee)}
                  {renderFeeExtraInfo(fee)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const handleTitleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (typeof onTitleClick === 'function') {
      onTitleClick();
    }
  };

  return (
    <Content>
      <div>
        <WidgetHeader>
          <h2>Other Fees</h2>
          <WidgetHeaderIcon onClick={handleTitleClick}>
            <Icon icon={['fas', 'times']} color="#009BF2" />
          </WidgetHeaderIcon>
        </WidgetHeader>
        <div className="billing-profile-content">{content()}</div>
      </div>
    </Content>
  );
};

interface FeesModalProps {
  isOpen?: boolean;
  onClose?: Function;
  content: React.ReactNode;
}

export const FeesModal: React.FunctionComponent<FeesModalProps> = props => {
  const [isModalVisible] = useState(!!props.isOpen);
  const { content, onClose } = props;

  const handleCloseModal = () => {
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  return (
    <BaseModal
      isOpen={isModalVisible}
      onRequestClose={handleCloseModal}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={false}
      style={{
        overlay: { pointerEvents: 'auto' },
        content: {
          maxWidth: '388px',
          backgroundColor: 'transparent',
          borderRadius: 0,
        },
      }}
    >
      <StyledModalContent>
        <ModalContainer>{content}</ModalContainer>
      </StyledModalContent>
    </BaseModal>
  );
};

const renderFeeExtraInfo = (profile: Billing) => {
  const v = getFeeExtraInfo(profile);
  return v ? (
    <FeeExtraInfo>{v}</FeeExtraInfo>
  ) : (
    <FeeExtraInfo>- -</FeeExtraInfo>
  );
};

const renderPricingType = (isInterchangePassthrough: boolean) => {
  const isInterchangePassthroughSTR = isInterchangePassthrough
    ? 'Interchange +'
    : 'Flat Rate';
  return <FeeExtraInfo>{isInterchangePassthroughSTR}</FeeExtraInfo>;
};
