import React from 'react';
import styled, { css } from 'styled-components';
import { FormHeaderProps } from './FormHeader.types';

const style = css`
  margin: 0;
  padding: 1% 0px;
  color: white;

  @media (max-width: 1000px) {
    font-size: 150%;
    padding: 1% 2%;
  }
`;

export const FormHeader = styled((props: FormHeaderProps) => {
  const { tag, children, ...rest } = props;
  return React.createElement(tag, rest, children);
})`
  ${style}
`;
