import { useCallback } from 'react';
import { useSearchState } from './useSearchState';

export function useUpdateNumber(): [number, () => void] {
  const [updateNumber, setUpdateNumber] = useSearchState<string>('u');
  const n = parseInt(
    !updateNumber || Number.isNaN(+updateNumber) ? '0' : updateNumber
  );

  const incrementUpdateNumber = useCallback(() => {
    setUpdateNumber(String(n + 1));
  }, [n, setUpdateNumber]);

  return [n, incrementUpdateNumber];
}
