import React, { useCallback, useState, useContext } from 'react';
import styled from 'styled-components';

import {
  BaseModal,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  ModalFooter,
} from '@fattmerchantorg/truffle-components';
import { Form as FinalForm } from 'react-final-form';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { coreapi } from '../../api';
import { AuthStore } from '../../context';
import { Field } from '../shared/Field';
import { MaskedInput } from '../shared/MaskedInput';
import { useToaster } from '../../hooks';
import { testPassword, PasswordTestResult } from '../../util/password-util';
import { ButtonSpinner } from '../shared';

type FormValues = {
  password: string;
  password_confirmation: string;
};

const StyledModalContent = styled.div`
  overflow-y: auto;
  padding: ${({ theme }) => theme.space[4]}px;

  display: flex;
  flex-direction: column;
`;

const StyledInstruction = styled.div`
  color: ${({ theme }) => theme.colors.core.gray[400].hex};
`;

const FormLabel = styled.label`
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 8px;
  margin-top: 8px;
`;

const StyledModalFooter = styled(ModalFooter)`
  justify-content: flex-end;
  padding-top: 0;

  > *:first-child {
    margin-right: 16px;
  }
`;

const List = styled.ul`
  list-style-type: none;

  padding-left: 1rem;

  &:first-child {
    padding: 0.5rem 0 0.5rem 0;
  }

  li {
    color: white;
    svg {
      padding-right: 0.5em;
      color: ${({ theme }) => theme.colors.core.gray[400].hex};

      &.pass {
        color: ${({ theme }) => theme.colors.status.green[500].hex};
      }

      &.fail {
        color: ${({ theme }) => theme.colors.status.red[600].hex};
      }
    }
  }
`;

const StyledForm = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 48px 1fr 72px;
  grid-template-areas: 'header' 'content' 'footer';
  gap: 0px;
`;

interface ReasonProps {
  name: string;
  pass: boolean;
}

const ReasonItem: React.FC<ReasonProps> = props => {
  const { name, pass } = props;

  return (
    <li>
      {pass && (
        <FontAwesomeIcon
          className="pass"
          icon={faCheck as IconProp}
          fixedWidth
        />
      )}

      {!pass && (
        <FontAwesomeIcon
          className="fail"
          icon={faTimes as IconProp}
          fixedWidth
        />
      )}

      {name}
    </li>
  );
};

interface PasswordModalProps {
  onSubmitted: () => void;
  onCancel: () => void;
  isOpen: boolean;
}

export const PasswordModal: React.FunctionComponent<
  PasswordModalProps
> = props => {
  const { state } = useContext(AuthStore);
  const [isSaving, setIsSaving] = useState(false);
  const { toast, toaster } = useToaster();
  const { onSubmitted, onCancel, isOpen } = props;

  const handleSubmit = useCallback(
    async (formValues: FormValues) => {
      if (!formValues) return;

      setIsSaving(true);

      try {
        const authToken = state?.auth?.token || null;

        await coreapi.put(authToken, `/self`, formValues);

        toaster(toast.success('Password updated', 'Saved'));
        onSubmitted();
      } catch (error) {
        toaster(
          toast.error(error, 'There was a problem updating your password.')
        );
      }
      setIsSaving(false);
    },
    [onSubmitted, state, toast, toaster]
  );

  const validate = (values: FormValues) => {
    const errors: Partial<Record<keyof FormValues, string>> = {};

    const newTestResult = testPassword(values.password);
    if (!newTestResult.pass) {
      errors.password = newTestResult as unknown as string;
    }

    if (!values.password_confirmation) {
      errors.password_confirmation = 'Required';
    } else if (values.password_confirmation !== values.password) {
      errors.password_confirmation = 'Passwords must match';
    }

    return errors;
  };

  return (
    <FinalForm<FormValues> onSubmit={handleSubmit} validate={validate}>
      {({ handleSubmit, dirty, hasValidationErrors, submitFailed, form }) => {
        return (
          <BaseModal
            isOpen={isOpen}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={false}
            style={{
              overlay: { pointerEvents: 'auto' },
              content: { maxWidth: '533px', display: 'block' },
            }}
          >
            <StyledForm onSubmit={handleSubmit} noValidate>
              <ModalHeader
                title="Update Password"
                onClose={() => {
                  form.reset();
                  onCancel();
                }}
              />
              <StyledModalContent>
                <StyledInstruction>Choose a new password.</StyledInstruction>
                <Field name="password">
                  {props => {
                    const testResult = props.meta
                      .error as unknown as PasswordTestResult;
                    return (
                      <>
                        <FormLabel>New Passsword</FormLabel>
                        <MaskedInput
                          {...props.input}
                          maskType="password"
                          error={
                            props.meta.dirty &&
                            !!props.meta.error &&
                            props.meta.touched
                          }
                          helperText={
                            props.meta.dirty &&
                            props.meta.touched &&
                            props.meta.error ? (
                              <List>
                                <ReasonItem
                                  pass={testResult.length}
                                  name="At least 8 characters in length"
                                />
                                <ReasonItem
                                  pass={testResult.lower}
                                  name="Lower case letters (a-z)"
                                />
                                <ReasonItem
                                  pass={testResult.upper}
                                  name="Upper case letters (A-Z)"
                                />
                                <ReasonItem
                                  pass={testResult.numeric}
                                  name="Numbers (0-9)"
                                />
                                <ReasonItem
                                  pass={testResult.special}
                                  name="Special characters (!@#$%^&*)"
                                />
                              </List>
                            ) : undefined
                          }
                        />
                      </>
                    );
                  }}
                </Field>

                <Field name="password_confirmation">
                  {props => (
                    <>
                      <FormLabel>Confirm New Passsword</FormLabel>
                      <MaskedInput
                        {...props.input}
                        maskType="password"
                        error={
                          props.meta.dirty &&
                          !!props.meta.error &&
                          props.meta.touched
                        }
                        helperText={
                          props.meta.dirty && props.meta.touched
                            ? props.meta.error
                            : undefined
                        }
                      />
                    </>
                  )}
                </Field>
              </StyledModalContent>

              <StyledModalFooter>
                <SecondaryButton
                  onClick={() => {
                    form.reset();
                    onCancel();
                  }}
                  disabled={isSaving}
                  type="button"
                >
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  type="submit"
                  disabled={hasValidationErrors || !dirty}
                >
                  {isSaving ? <ButtonSpinner /> : 'Update'}
                </PrimaryButton>
              </StyledModalFooter>
            </StyledForm>
          </BaseModal>
        );
      }}
    </FinalForm>
  );
};
