import React, { useEffect, useMemo } from 'react';
import { Column, useSortBy, useTable, usePagination } from 'react-table';
import {
  Pagination,
  SortIndicator,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableTools,
  TableWrapper,
} from '@fattmerchantorg/truffle-components';
import { TableHeaderCell } from '@fattmerchantorg/truffle-components/dist-esm';
import { CollectionQuery, LoadingSpan } from '../../shared';
import { useSearchState } from '../../../hooks';
import { ViewMemberDrawer } from './ViewMemberDrawer';
import { User } from '@fattmerchantorg/types-omni';

interface DataTableProps {
  data: {
    columns: Column[];
    rows: Record<string, unknown>[];
  };
  isLoading: boolean;
  pageSizeOptions: {
    value: number;
    label: string;
  }[];
  count: number;
  total: number;
  currentPage: number;
  lastPage: number;
  defaultPageSize: number;
  handlePagination: (query: CollectionQuery) => void;
}

export const TeamSettingsDataTable: React.FC<DataTableProps> = props => {
  const { rows: rowsData, columns: columnsData } = props.data;
  const {
    isLoading,
    defaultPageSize,
    currentPage,
    lastPage,
    total,
    count,
    handlePagination,
    pageSizeOptions,
  } = props;
  const [userId, setUserId] = useSearchState<string>('userId');

  // Row and column data for table
  const rowData = useMemo(() => rowsData, [rowsData]);
  const columns = useMemo(() => columnsData, [columnsData]);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data: rowData,
      manualPagination: true,
      pageCount: count,
      initialState: {
        sortBy: [
          {
            id: 'created_at',
            desc: true,
          },
        ],
        pageSize: defaultPageSize,
        pageIndex: currentPage,
      },
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setPageSize(defaultPageSize);
  }, [defaultPageSize, setPageSize]);

  return (
    <>
      <ViewMemberDrawer
        isOpen={!!userId}
        setUserId={setUserId}
        userId={userId}
      />
      <TableWrapper>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, index) => (
              <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column: any, index) => (
                  <TableHeaderCell
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={column.style}
                    key={index}
                  >
                    {column.render('Header')}

                    {column.isSorted ? (
                      <SortIndicator
                        sort={column.isSortedDesc ? 'desc' : 'asc'}
                      />
                    ) : column.disableSortBy ? null : (
                      <SortIndicator sort="unsorted" />
                    )}
                  </TableHeaderCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, index) => (
              <React.Fragment key={index}>
                {isLoading
                  ? (prepareRow(row),
                    (
                      <TableRow {...row.getRowProps()} key={index}>
                        {row.cells.map((cell: any, index) => {
                          return (
                            <TableCell
                              {...cell.getCellProps()}
                              key={index}
                              style={cell.column.style}
                            >
                              <LoadingSpan
                                style={cell.column.loadingWidthstyle}
                                width={cell.column.loadingWidth}
                              />
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))
                  : (prepareRow(row),
                    (
                      <TableRow {...row.getRowProps()} key={index}>
                        {row.cells.map((cell: any, index) => {
                          return (
                            <TableCell
                              onClick={() => {
                                const user = row.original as User;
                                setUserId(user.id);
                              }}
                              {...cell.getCellProps()}
                              key={index}
                              style={cell.column.style}
                            >
                              {cell.render('Cell')}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        <TableTools>
          <Pagination
            onNextPage={() => {
              nextPage();
              handlePagination({ page: currentPage + 1 });
            }}
            onPreviousPage={() => {
              previousPage();
              handlePagination({ page: currentPage - 1 });
            }}
            onSkipToEnd={() => {
              gotoPage(count - 1);
              handlePagination({ page: count });
            }}
            onSkipToBeginning={() => {
              gotoPage(0);
              handlePagination({ page: 1 });
            }}
            onGoToPage={(page: number) => {
              gotoPage(page);
              handlePagination({ page: page + 1 });
            }}
            totalPages={count}
            totalRecords={total}
            recordsPerPage={pageSize}
            onSetPageSize={size => {
              setPageSize(size);
              handlePagination({ per_page: size });
            }}
            currentPage={currentPage - 1}
            canNextPage={currentPage === lastPage ? false : true}
            canPreviousPage={currentPage === 1 ? false : true}
            pageSizeOptions={pageSizeOptions}
          />
        </TableTools>
      </TableWrapper>
    </>
  );
};
