import { Merchant } from '@fattmerchantorg/types-omni';

export const getTransactionLimitDefaults = (
  merchant: Merchant,
  defaultValue: number | string
): number | string => {
  if (!defaultValue) {
    defaultValue = 50000;
  }
  const { brand, is_payfac } = merchant
    ? merchant
    : { brand: '', is_payfac: true };
  return is_payfac &&
    !(
      /fattmerchant/.test(brand.toLowerCase()) ||
      /paymentdepot/.test(brand.toLowerCase()) ||
      /sandbox/.test(brand.toLowerCase())
    )
    ? 500000
    : defaultValue;
};
