import React from 'react';
import { CollectionPagination } from '@fattmerchantorg/types-omni';
import { LoadingTr } from './loading-tr';
import { calculateNumberOfLoadingRows } from '../statement-v3/util/statement-v3-utils';

type LoadingRowsProps = {
  pagination: CollectionPagination;
  tdWidths: number[];
};

export function LoadingRows(props: LoadingRowsProps) {
  const { pagination, tdWidths } = props;

  if (!pagination) return null;
  if (pagination.current_page === pagination.last_page) return null;

  const iterator = [...Array(calculateNumberOfLoadingRows(pagination))];
  return (
    <>
      {iterator.map((_, index) => {
        const offsetIndex = index + pagination.to + 1;
        return (
          <LoadingTr key={index} index={offsetIndex} tdWidths={tdWidths} />
        );
      })}
    </>
  );
}
