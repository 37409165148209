import React, {
  FunctionComponent,
  useState,
  useContext,
  useCallback,
} from 'react';
import styled from 'styled-components';
import {
  BPSettingsRow,
  BPSettingsRowTitle,
  BPSettingsRowFields,
  BPSettingsRowDescription,
  FieldWrap,
  TwoCol,
  ThreeCol,
  SubCol,
} from '../components/BPSettingsRow';
import { DB } from '@fattmerchantorg/types-engine';
import {
  SmallPrimaryButton,
  SmallSecondaryButton,
  Select,
  TextField,
  Checkbox,
  RadioButton,
  Label,
  Icon,
} from '@fattmerchantorg/truffle-components';
import { CTAPageHeader } from '../components/CTAPageHeader';
import {
  DeleteFormDataModal,
  initialDeleteModalState,
  DeleteModalContext,
} from '../components/DeleteFormDataModal';
import { Form, Field } from 'react-final-form';
import * as TransactionProfileFormUtil from './TransactionProfileForm.util';
import {
  TransactionProfileFieldsErrors,
  TransactionProfileFieldsValues,
} from './TransactionProfileForm.types';
import {
  channelSelectOptions as _channelSelectOptions,
  subchannelSelectOptions,
  channelAndSubchannelText,
  formatBillingCycleString,
  billingCycleDaySelectOptions,
  getEnginePaymentMethods,
  ChannelSelectOption,
  PaymentMethodSelectOption,
} from '../util/billingProfile.util';
import { ordinal } from '../../../util';
import { SelectedMerchantStore } from '../../../context';
import { fetchSelectedMerchantBillingProfiles } from '../../../context/selected-merchant/SelectedMerchants.actions';
import { useAsyncEffect, useAuthToken, useToaster } from '../../../hooks';
import { catanapi } from '../../../api';
import { FormApi } from 'final-form';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import get from 'lodash/get';
import { Location } from 'history';
import {
  useModalReducer,
  sendOpenSelected,
} from '../../../hooks/useModalReducer';
import { getErrorsFromCatanErrorResponse } from '../../../util/catan.util';
import { TransactionsLoadingState } from '../util/loadingState';
import { multiplyAmounts } from '../../../util/currency.util';

interface TransactionProfileProps {
  initialValues?: {
    channel: DB.Billing['channel'];
    subChannel: DB.Billing['subchannel'];
  };
  isFirstProfile?: boolean;
  editBillingId?: string;
}

const ChannelFields = styled(TwoCol)`
  flex-direction: row;

  > div {
    max-width: calc(50% - 8px);

    &:first-child {
      margin-right: 16px;
    }
  }
`;

type PaymentMethodDescriptionProps = {
  channel?: string;
  subchannel?: string;
};

const PaymentMethodDescription = ({
  channel,
  subchannel,
}: PaymentMethodDescriptionProps) => {
  return channel ? (
    <span>
      This Transaction Profile affects{' '}
      <strong>{channelAndSubchannelText(channel, subchannel)}</strong>{' '}
      transaction methods.
    </span>
  ) : (
    <span>
      Please choose a payment method associated with this transaction profile.
    </span>
  );
};

type FeeDescriptionProps = {
  channel: string;
  subchannel?: string;
  feePercent: number;
  feeCap: number;
  perTransactionAmount: number;
  billingCycle?: string;
  billingCycleDay?: number;
  isGrossSettlement: boolean;
  feesAccount?: {
    label: string;
  };
  isInterchangePassThrough: boolean;
  interchangeDuesAssessments: number;
};

const FeeDescription = ({
  channel,
  subchannel,
  feePercent,
  feeCap,
  perTransactionAmount,
  billingCycle,
  billingCycleDay,
  isGrossSettlement,
  feesAccount,
  isInterchangePassThrough,
  interchangeDuesAssessments,
}: FeeDescriptionProps) => {
  return (
    <>
      <p>
        <strong>{channelAndSubchannelText(channel, subchannel)}</strong>{' '}
        transactions are charged a{' '}
        <strong>
          {feePercent ? feePercent : '0'}% {feeCap ? `($${feeCap} Cap)` : ''} +{' '}
          {`$${
            perTransactionAmount
              ? perTransactionAmount.toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                })
              : '0'
          }`}
        </strong>{' '}
        fee per transaction.
      </p>
      <p>
        The fees are collected <strong>{billingCycle}</strong>{' '}
        {billingCycle === 'Monthly' && (
          <span>
            on the <strong>{ordinal(billingCycleDay)}</strong>
          </span>
        )}{' '}
        as a <strong>{isGrossSettlement ? 'Gross' : 'Net'} Settlement</strong>
        {' from'}{' '}
        {feesAccount ? (
          <>
            <strong>{feesAccount.label}</strong>
          </>
        ) : (
          'an unspecified account'
        )}
        .
      </p>
      <p>
        <strong>{channelAndSubchannelText(channel, subchannel)}</strong>{' '}
        interchange{' '}
        <strong>{isInterchangePassThrough ? 'will' : 'will not'}</strong> be
        passed to the merchant
        {isInterchangePassThrough ? ' plus ' : ' '}
        <strong>
          {isInterchangePassThrough
            ? `${
                !!interchangeDuesAssessments ? interchangeDuesAssessments : '0'
              }%`
            : ''}{' '}
        </strong>
        {isInterchangePassThrough
          ? 'Dues & Assessment fee per transaction.'
          : '.'}
      </p>
    </>
  );
};

type DepositDescriptionProps = {
  channel: string;
  subchannel?: string;
  depositAccount?: {
    label: string;
  };
  depositAccountId?: string;
  fundingDays: number;
};

const DepositDescription = ({
  channel,
  subchannel,
  depositAccount,
  depositAccountId,
  fundingDays,
}: DepositDescriptionProps) => {
  return (
    <span>
      <strong>{channelAndSubchannelText(channel, subchannel)}</strong>{' '}
      transaction deposits will go to{' '}
      <strong>
        {depositAccount
          ? depositAccount.label
          : depositAccountId ?? 'an unspecified account'}{' '}
        {fundingDays > 1 ? fundingDays : 'the next'} {' business day'}
        {fundingDays > 1 ? 's' : ''}
      </strong>{' '}
      after a purchase has been made.
    </span>
  );
};

// TODO: Figure out if this should just be the default configuration for a
// standalone Label component
export const InlineLabel = (props: {
  text: string;
  info?: string;
  required?: boolean;
}) => {
  const { text, info, required } = props;

  return (
    <Label
      text={text}
      info={info}
      required={required}
      customStyles={{
        wrapper: { display: 'flex', alignItems: 'center' },
        label: { margin: '0 4px 0 0' },
      }}
    />
  );
};

const validateForm = (
  formValues: TransactionProfileFieldsValues
): TransactionProfileFieldsErrors => {
  const errors = {
    ...TransactionProfileFormUtil.validateFormValues(formValues),
  } as TransactionProfileFieldsErrors;
  return errors;
};

const initialFormValues: Partial<TransactionProfileFieldsValues> = {
  type: 'TRANSACTION',
  is_gross_settlement: 'true',
  is_interchange_passthru: 'false',
  deposit_account_id: null,
  fees_account_id: null,
  interchange_dues_assessments: null,
  billing_cycle: 'DAILY',
};

export const TransactionProfile: FunctionComponent<
  TransactionProfileProps
> = props => {
  const authToken = useAuthToken();
  const { state, dispatch: selectedMerchantDispatch } = useContext(
    SelectedMerchantStore
  );
  const { merchant } = state;
  const engineCompanyId = get(state?.registration, 'external_company_id', null);
  const { initialValues, isFirstProfile, editBillingId } = props;
  const [existingBilling, setExistingBilling] = useState<DB.Billing | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [locationUrl, setLocationUrl] = useState(null);
  const [deleteModalState, deleteModalDispatch] =
    useModalReducer<DeleteModalContext>(initialDeleteModalState);
  const [channelSelectOptions, setChannelSelectOptions] = useState(
    _channelSelectOptions
  );
  const [accountOptions, setAccountOptions] =
    useState<PaymentMethodSelectOption[]>();
  const { toaster, toast } = useToaster();
  const billingCycleDayOptions = billingCycleDaySelectOptions();
  const history = useHistory();
  const location = useLocation();
  const [fromCancel, setFromCancel] = useState(false);

  if (initialValues && isFirstProfile) {
    initialValues.channel = 'ALL';
  }

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!locationUrl) {
      setLocationUrl(nextLocation);
      deleteModalDispatch(sendOpenSelected());
      return false;
    }
    return true;
  };

  const afterBlockedNavigation = () => {
    if (fromCancel) {
      history.goBack();
    } else {
      history.push(locationUrl.pathname);
    }
  };

  useAsyncEffect(async () => {
    if (existingBilling) {
      return;
    }
    if (initialValues && isFirstProfile) {
      let channelList: ChannelSelectOption[];
      channelList = channelSelectOptions.map(billprofile => {
        if (billprofile.label !== 'All') {
          return { ...billprofile };
        }
        return { ...billprofile, isDisabled: false };
      });
      setChannelSelectOptions(channelList);
    } else {
      setChannelSelectOptions(channelSelectOptions);
    }
  }, [isFirstProfile, existingBilling, initialValues, channelSelectOptions]);

  useAsyncEffect(async () => {
    if (
      !state ||
      !editBillingId ||
      editBillingId === '' ||
      !state.billingProfiles ||
      !state.billingProfiles.data
    ) {
      return;
    }
    setIsLoading(true);
    const selectedBP = state.billingProfiles.data.find(
      bp => bp.billing_id === editBillingId
    );
    if (selectedBP === undefined) {
      toaster(
        toast.error(
          'Unexpected error loading Billing Profile',
          'Error loading Billing Profile'
        )
      );
      setIsLoading(false);
    }
    setExistingBilling({ ...selectedBP });
    setIsLoading(false);
  }, [editBillingId, state]);

  // Fetching the payment methods from catan.
  useAsyncEffect(async () => {
    if (!authToken) return;
    // load the fee account and deposit account dropdowns with ALL payment methods available
    // by company
    setIsLoading(true);

    try {
      const paymentMethods = await getEnginePaymentMethods(
        authToken,
        engineCompanyId,
        toast,
        toaster
      );

      setAccountOptions(getAccountOptions(paymentMethods));
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      return;
    }
  }, [authToken, engineCompanyId, toaster, toast, setAccountOptions]);

  const getAccountOptions = useCallback(
    (accountOptions): PaymentMethodSelectOption[] => {
      return accountOptions?.data?.reduce((accountOptns, accountOptn) => {
        accountOptns.push({
          value: accountOptn.method_id,
          label: `${accountOptn.person_name} (****${accountOptn.last_four})`,
        });

        return accountOptns;
      }, []);
    },
    []
  );

  const saveProfile = useCallback(
    async formValues => {
      try {
        existingBilling
          ? await catanapi.put(
              authToken,
              `/billing/${existingBilling.billing_id}`,
              {
                ...TransactionProfileFormUtil.mapFormValuesToPayload(
                  formValues
                ),
              }
            )
          : await catanapi.post(authToken, '/billing', {
              ...TransactionProfileFormUtil.mapFormValuesToPayload(formValues),
              company_id: engineCompanyId,
              onboarding_id: null,
            });
        setLocationUrl(location);
        history.push(`/merchant/${merchant?.id}/billing-profiles`);
        toaster(
          existingBilling
            ? toast.success(
                'Your Transaction Profile has been updated',
                'Transaction Profile Updated'
              )
            : toast.success(
                'Your Transaction Profile has been created',
                'Transaction Profile Created'
              )
        );
        selectedMerchantDispatch(
          fetchSelectedMerchantBillingProfiles(engineCompanyId)
        );
      } catch (error) {
        const errors = getErrorsFromCatanErrorResponse(error);
        if (errors.length) {
          const contentNodes = errors.map(errMsg =>
            React.createElement('div', null, errMsg)
          );
          toaster(
            toast.error(
              React.createElement('div', null, contentNodes),
              'Validation Error'
            )
          );
        } else {
          toaster(
            toast.error(
              error,
              'There was a problem saving this billing profile'
            )
          );
        }
        clearForm(formValues.form?.reset);
      }
    },
    [
      authToken,
      engineCompanyId,
      existingBilling,
      history,
      location,
      merchant,
      selectedMerchantDispatch,
      toast,
      toaster,
    ]
  );

  const onChannelChange = useCallback(
    (
      channel: DB.Billing['channel'],
      subchannel: DB.Billing['subchannel'],
      form: FormApi
    ): boolean => {
      if (channel !== undefined && subchannel !== undefined) {
        form.change('subchannel', undefined);
        subchannel = undefined;
      }

      // all PayPal (PayPal, Venmo, etc) transactions will have a default 3.49% + 10c
      if (['VENMO', 'PAYPAL'].includes(subchannel)) {
        form.change('fee_percent', 3.49);
        form.change('per_transaction_amount', 0.1);
      }

      /**
       * TODO: logic that will scan existing profiles and see if one that matches a provided channel/subchannel
       * configuration already exists, then load the field values
       */
      return false;
    },
    []
  );

  const onAddFeeCapChange = (value: boolean, input) => {
    input.change('add_fee_cap', value);
    input.change('fee_cap_amount', 0);
  };

  const clearForm = (reset: FormApi['reset'] | undefined) => {
    if (typeof reset === 'function') {
      reset();
    }
  };

  const getInitialValues = useCallback(() => {
    if (existingBilling) {
      const channel = channelSelectOptions.filter(
        chan => existingBilling.channel === chan.value
      )[0];
      const subchannels = subchannelSelectOptions(channel?.value);
      const subchannel = subchannels.filter(
        subchan => existingBilling.subchannel === subchan.value
      )[0];
      return TransactionProfileFormUtil.mapBillingProfileToInitialFormValues({
        channel: channel,
        subchannel: subchannel,
        billing_cycle_day: existingBilling.billing_cycle_day,
        billing_cycle: existingBilling.billing_cycle,
        deposit_account_id: existingBilling.deposit_account_id,
        fee_cap_amount: existingBilling.fee_cap_amount,
        fee_percent: multiplyAmounts(existingBilling.fee_percent, 100.0, {
          precision: 15,
        }),
        fees_account_id: existingBilling.fees_account_id,
        funding_schedule_days: existingBilling.funding_schedule_days,
        is_gross_settlement: existingBilling.is_gross_settlement
          ? 'true'
          : 'false',
        is_interchange_passthru: existingBilling.is_interchange_passthru
          ? 'true'
          : 'false',
        per_transaction_amount: existingBilling.per_transaction_amount,
        interchange_dues_assessments: multiplyAmounts(
          existingBilling.interchange_dues_assessments,
          100.0,
          { precision: 10 }
        ),
      });
    }
    return TransactionProfileFormUtil.mapBillingProfileToInitialFormValues({
      ...initialFormValues,
      fee_percent: 0,
      per_transaction_amount: 0,
      billing_cycle_day: 5,
      interchange_dues_assessments: 0,
    });
  }, [existingBilling, channelSelectOptions]);

  const fillFeeAccount = useCallback(() => {
    if (!existingBilling || !accountOptions) {
      return null;
    }
    const feeAcc = accountOptions.find(
      fa => fa.value === existingBilling.fees_account_id
    );
    if (feeAcc === undefined) {
      return null;
    }
    return { label: feeAcc.label, value: feeAcc.value };
  }, [existingBilling, accountOptions]);

  const fillDepositAccount = useCallback(() => {
    if (!existingBilling || !accountOptions) {
      return null;
    }
    const depositAcc = accountOptions.find(
      da => da.value === existingBilling.deposit_account_id
    );
    if (depositAcc === undefined) {
      return null;
    }
    return { label: depositAcc.label, value: depositAcc.value };
  }, [existingBilling, accountOptions]);

  return (
    <div>
      {isLoading ? (
        <TransactionsLoadingState />
      ) : (
        <Form
          initialValues={{
            ...getInitialValues(),
            add_fee_cap: existingBilling?.fee_cap_amount > 0,
          }}
          validate={validateForm}
          onSubmit={saveProfile}
          mutators={TransactionProfileFormUtil.formMutators}
        >
          {formProps => (
            <form autoComplete="off" onSubmit={formProps.handleSubmit}>
              <Prompt
                message={handleBlockedNavigation}
                when={formProps.dirty}
              />
              {/** The DeleteFormDataModal component could be lazy loaded */}
              <DeleteFormDataModal
                status={deleteModalState.status}
                title="Unsaved Changes"
                message={
                  <span>
                    The changes you've made to this transaction profile have{' '}
                    <strong> not been saved.</strong> You will lose all these
                    changes if you leave this page.
                  </span>
                }
                modalDispatch={deleteModalDispatch}
                actionFunc={() => {
                  clearForm(formProps.form.reset);
                  afterBlockedNavigation();
                }}
                cancelText="Stay on this Page"
                actionText="Leave this Page"
              />
              <CTAPageHeader
                headingText={
                  <span>
                    {editBillingId === '' || editBillingId === undefined
                      ? 'Add - '
                      : 'Edit - '}
                    Transaction Profile
                    {formProps.values.channel ? (
                      <>
                        {' - '}{' '}
                        <strong>
                          {channelAndSubchannelText(
                            formProps.values.channel?.label,
                            formProps.values.subchannel?.label
                          )}
                        </strong>
                      </>
                    ) : (
                      ''
                    )}
                  </span>
                }
                subheadingText={
                  'View or edit a transaction profile by filling out the information below'
                }
                ctaArea={
                  formProps.dirty
                    ? [
                        <SmallSecondaryButton
                          onClick={event => {
                            event.preventDefault();
                            setFromCancel(true);
                            setLocationUrl(null);
                            if (!existingBilling) {
                              deleteModalDispatch(sendOpenSelected());
                            } else {
                              clearForm(formProps.form.reset);
                            }
                          }}
                          disabled={!formProps.dirty}
                          data-trackingid="bp-transaction-cancel-button"
                          key="bp-transaction-cancel-button"
                          type="button"
                        >
                          {existingBilling ? 'Reset Changes' : 'Cancel'}
                        </SmallSecondaryButton>,
                        <SmallPrimaryButton
                          type="submit"
                          disabled={!formProps.valid}
                          data-trackingid="bp-transaction-create-button"
                          key="bp-transaction-create-button"
                        >
                          {existingBilling
                            ? 'Update Transaction Profile'
                            : 'Create Transaction Profile'}
                        </SmallPrimaryButton>,
                      ]
                    : []
                }
                breadcrumb={{
                  text: 'Billing Profiles',
                  path: `/merchant/${merchant?.id}/billing-profiles`,
                }}
              />
              <BPSettingsRow data-highlight>
                <BPSettingsRowTitle>
                  <h3>Payment Method</h3>
                  <span>
                    Determines what payment method this transaction profile is
                    associated with.
                  </span>
                </BPSettingsRowTitle>
                <BPSettingsRowFields>
                  <ChannelFields>
                    <FieldWrap style={{ maxWidth: '180px' }}>
                      <InlineLabel text="Channel" required />
                      <Field name="channel" required={true} clearable={false}>
                        {props => {
                          return (
                            <div data-testid="bp-transaction-channel">
                              <Select
                                {...props.input}
                                options={channelSelectOptions}
                                isDisabled={
                                  existingBilling?.subchannel === 'ALL' &&
                                  existingBilling?.channel === 'ALL'
                                }
                                defaultValue={{ value: 'ALL', label: 'All' }}
                                onChange={(option: ChannelSelectOption) => {
                                  props.input.onChange(option);

                                  onChannelChange(
                                    option?.value,
                                    formProps.values?.subchannel,
                                    formProps.form
                                  );
                                  formProps.form.mutators.setDefaultSubchannel(
                                    option?.value
                                  );
                                }}
                                placeholder="Choose a Channel"
                                isClearable={false}
                                data-trackingid="bp-transaction-channel"
                                isOptionDisabled={option => option.isDisabled}
                              />
                            </div>
                          );
                        }}
                      </Field>
                    </FieldWrap>
                    {formProps.values?.channel &&
                    !['BANK'].includes(formProps.values?.channel?.value) ? (
                      <FieldWrap style={{ maxWidth: '200px' }}>
                        <InlineLabel text="SubChannel" required />
                        <Field name="subchannel" clearable={false}>
                          {fieldProps => {
                            const options = subchannelSelectOptions(
                              formProps.values?.channel?.value
                            );

                            return (
                              <div data-testid="bp-transaction-subchannel">
                                <Select
                                  {...fieldProps.input}
                                  options={options}
                                  isDisabled={
                                    !formProps.values?.channel ||
                                    (existingBilling?.subchannel === 'ALL' &&
                                      existingBilling?.channel === 'ALL')
                                  }
                                  placeholder="Choose a SubChannel"
                                  isClearable={false}
                                  data-trackingid="bp-transaction-subchannel"
                                  error={!!formProps.errors?.subchannel}
                                  helperText={formProps.errors?.subchannel}
                                />
                              </div>
                            );
                          }}
                        </Field>
                      </FieldWrap>
                    ) : null}
                  </ChannelFields>
                </BPSettingsRowFields>
                <BPSettingsRowDescription>
                  <PaymentMethodDescription
                    channel={formProps.values.channel?.label}
                    subchannel={formProps.values.subchannel?.label}
                  />
                </BPSettingsRowDescription>
              </BPSettingsRow>
              {formProps.values?.channel ? (
                <>
                  <BPSettingsRow>
                    <BPSettingsRowTitle>
                      <h3>Fee</h3>
                      <span>
                        The transaction fees taken every time a purchase has
                        been made.
                      </span>
                    </BPSettingsRowTitle>
                    <BPSettingsRowFields>
                      <ThreeCol>
                        <SubCol>
                          <InlineLabel
                            text="Transaction Fee"
                            info="Fee amount per transaction."
                          />
                          <FieldWrap style={{ margin: '8px 0 0 0' }}>
                            <Field
                              name="fee_percent"
                              format={v =>
                                v ? v.toString().replace(/[^0-9.,]/g, '') : ''
                              }
                              parse={v =>
                                v ? v.toString().replace(/[^0-9.,]/g, '') : ''
                              }
                            >
                              {props => {
                                return (
                                  <TextField
                                    {...props.input}
                                    label="Percent"
                                    placeholder="0.0"
                                    inputSuffix={
                                      <Icon icon={['fas', 'percent']} />
                                    }
                                    autoComplete="off"
                                    error={!!formProps.errors?.fee_percent}
                                    helperText={formProps.errors?.fee_percent}
                                  />
                                );
                              }}
                            </Field>
                          </FieldWrap>

                          <FieldWrap style={{ margin: '8px 0 0 0' }}>
                            <Field name="add_fee_cap" type="checkbox">
                              {props => {
                                return (
                                  <Checkbox
                                    {...props.input}
                                    label="Add Percent Cap"
                                    checked={formProps.values.add_fee_cap}
                                    onChange={e => {
                                      onAddFeeCapChange(
                                        e.target.checked,
                                        formProps.form
                                      );
                                    }}
                                  />
                                );
                              }}
                            </Field>
                          </FieldWrap>

                          {formProps.values.add_fee_cap ? (
                            <FieldWrap style={{ margin: '0 0 16px 0' }}>
                              <Field
                                name="fee_cap_amount"
                                format={v =>
                                  v ? v.toString().replace(/[^0-9.,]/g, '') : ''
                                }
                                parse={v =>
                                  v ? v.toString().replace(/[^0-9.,]/g, '') : ''
                                }
                              >
                                {props => {
                                  return (
                                    <TextField
                                      {...props.input}
                                      label="Percent Cap"
                                      info="Maximum fee taken based on percent."
                                      placeholder="0.00"
                                      inputPrefix={
                                        <Icon icon={['fas', 'dollar-sign']} />
                                      }
                                      autoComplete="off"
                                    />
                                  );
                                }}
                              </Field>
                            </FieldWrap>
                          ) : null}
                          <FieldWrap style={{ margin: '8px 0 0 0' }}>
                            <Field
                              name="per_transaction_amount"
                              format={v =>
                                v !== null && v !== undefined
                                  ? v.toString().replace(/[^0-9.,]/g, '')
                                  : ''
                              }
                              parse={v =>
                                v !== null && v !== undefined
                                  ? v.toString().replace(/[^0-9.,]/g, '')
                                  : ''
                              }
                            >
                              {props => {
                                return (
                                  <TextField
                                    {...props.input}
                                    label="Amount"
                                    placeholder="0.00"
                                    inputPrefix={
                                      <Icon icon={['fas', 'dollar-sign']} />
                                    }
                                    autoComplete="off"
                                    error={
                                      !!formProps.errors?.per_transaction_amount
                                    }
                                    helperText={
                                      formProps.errors?.per_transaction_amount
                                    }
                                    required
                                  />
                                );
                              }}
                            </Field>
                          </FieldWrap>
                        </SubCol>
                        <SubCol>
                          <InlineLabel
                            text="Billing Cycle"
                            info="When fees will be billed."
                          />
                          <FieldWrap style={{ margin: '8px 0 0 0' }}>
                            <Field
                              name="billing_cycle"
                              style={{ margin: '8px 0 0 0' }}
                              type="radio"
                              value="DAILY"
                            >
                              {props => {
                                return (
                                  <RadioButton
                                    {...props.input}
                                    label="Daily"
                                    style={{ margin: '8px 0 0 0' }}
                                  />
                                );
                              }}
                            </Field>
                            <Field
                              name="billing_cycle"
                              type="radio"
                              value="MONTHLY"
                            >
                              {props => {
                                return (
                                  <RadioButton
                                    {...props.input}
                                    label="Monthly"
                                  />
                                );
                              }}
                            </Field>
                          </FieldWrap>
                          {formProps.values.billing_cycle === 'MONTHLY' ? (
                            <FieldWrap
                              style={{
                                margin: '16px 0 0 0',
                                maxWidth: '100px',
                              }}
                            >
                              <label>Day of Month</label>
                              <Field
                                name="billing_cycle_day"
                                format={value => {
                                  return billingCycleDayOptions.find(
                                    option => option?.value === value
                                  );
                                }}
                                parse={option => {
                                  return option?.value;
                                }}
                                clearable={false}
                              >
                                {props => {
                                  return (
                                    <div>
                                      <Select
                                        {...props.input}
                                        options={billingCycleDayOptions.filter(
                                          o => o.value >= 5
                                        )}
                                        isClearable={false}
                                        data-trackingid="bp-transaction-billing-cycle-day"
                                        error={
                                          !!formProps.errors?.billing_cycle_day
                                        }
                                        helperText={
                                          formProps.errors?.billing_cycle_day
                                        }
                                      />
                                    </div>
                                  );
                                }}
                              </Field>
                            </FieldWrap>
                          ) : null}
                        </SubCol>
                        <SubCol>
                          <InlineLabel
                            text="Settlement"
                            info="Gross separates charge and fee transactions. Net combines charge and fee transactions."
                          />

                          <FieldWrap style={{ margin: '8px 0 0 0' }}>
                            <Field
                              name="is_gross_settlement"
                              type="radio"
                              value="true"
                            >
                              {props => {
                                return (
                                  <RadioButton {...props.input} label="Gross" />
                                );
                              }}
                            </Field>
                            <Field
                              name="is_gross_settlement"
                              type="radio"
                              value="false"
                            >
                              {props => {
                                return (
                                  <RadioButton {...props.input} label="Net" />
                                );
                              }}
                            </Field>
                          </FieldWrap>
                        </SubCol>
                      </ThreeCol>
                      <TwoCol>
                        <SubCol>
                          <FieldWrap style={{ maxWidth: '200px' }}>
                            <Label
                              text="Default Fee Account"
                              info="Default account that fees will be taken from."
                              required
                            />
                            <Field
                              name="fees_account_id"
                              format={value => {
                                return billingCycleDayOptions.find(
                                  option => option?.value === value
                                );
                              }}
                              parse={option => {
                                return option?.value;
                              }}
                            >
                              {props => {
                                return (
                                  <>
                                    {accountOptions ? (
                                      <div data-testid="bp-transaction-fee-account">
                                        <Select
                                          {...props.input}
                                          options={accountOptions}
                                          data-trackingid="bp-transaction-fee-account"
                                          isClearable={false}
                                          defaultValue={{
                                            ...fillFeeAccount(),
                                          }}
                                          error={
                                            !!formProps.errors?.fees_account_id
                                          }
                                          helperText={
                                            formProps.touched?.fees_account_id
                                              ? formProps.errors
                                                  ?.fees_account_id
                                              : null
                                          }
                                        />
                                      </div>
                                    ) : null}
                                  </>
                                );
                              }}
                            </Field>
                          </FieldWrap>
                        </SubCol>
                        <SubCol>
                          <InlineLabel
                            text="Interchange Passthrough"
                            info="Determines whether or not interchange is passed to merchant."
                          />
                          <FieldWrap style={{ margin: '8px 0 0 0' }}>
                            <Field
                              name="is_interchange_passthru"
                              type="radio"
                              value="true"
                            >
                              {props => {
                                return (
                                  <RadioButton {...props.input} label="On" />
                                );
                              }}
                            </Field>
                            <Field
                              name="is_interchange_passthru"
                              type="radio"
                              value="false"
                            >
                              {props => {
                                return (
                                  <RadioButton {...props.input} label="Off" />
                                );
                              }}
                            </Field>
                          </FieldWrap>
                          {formProps.values?.is_interchange_passthru ===
                          'true' ? (
                            <div>
                              <InlineLabel text="Dues & Assessments Percent" />
                              <FieldWrap style={{ width: '30%' }}>
                                <Field
                                  name="interchange_dues_assessments"
                                  format={v =>
                                    v
                                      ? v.toString().replace(/[^0-9.,]/g, '')
                                      : ''
                                  }
                                  parse={v =>
                                    v
                                      ? v.toString().replace(/[^0-9.,]/g, '')
                                      : ''
                                  }
                                >
                                  {props => {
                                    return (
                                      <TextField
                                        {...props.input}
                                        placeholder="0.0"
                                        inputSuffix={
                                          <Icon icon={['fas', 'percent']} />
                                        }
                                        autoComplete="off"
                                        error={
                                          !!formProps.errors
                                            ?.interchange_dues_assessments
                                        }
                                        helperText={
                                          formProps.errors
                                            ?.interchange_dues_assessments
                                        }
                                      />
                                    );
                                  }}
                                </Field>
                              </FieldWrap>
                            </div>
                          ) : (
                            <input
                              type="hidden"
                              value={
                                (formProps.values.interchange_dues_assessments = 0)
                              }
                            />
                          )}
                        </SubCol>
                      </TwoCol>
                    </BPSettingsRowFields>
                    <BPSettingsRowDescription>
                      <FeeDescription
                        channel={formProps.values.channel?.label}
                        subchannel={formProps.values.subchannel?.label}
                        feePercent={formProps.values.fee_percent}
                        feeCap={formProps.values.fee_cap_amount}
                        perTransactionAmount={
                          formProps.values.per_transaction_amount
                        }
                        billingCycle={formatBillingCycleString(
                          formProps.values.billing_cycle
                        )}
                        billingCycleDay={formProps.values.billing_cycle_day}
                        isGrossSettlement={
                          formProps.values.is_gross_settlement === 'true'
                        }
                        feesAccount={accountOptions.find(
                          ({ value }) =>
                            formProps.values.fees_account_id === value
                        )}
                        isInterchangePassThrough={
                          formProps.values.is_interchange_passthru === 'true'
                        }
                        interchangeDuesAssessments={
                          formProps.values.interchange_dues_assessments
                        }
                      />
                    </BPSettingsRowDescription>
                  </BPSettingsRow>
                  <BPSettingsRow>
                    <BPSettingsRowTitle>
                      <h3>Deposit</h3>
                      <span>
                        Determines when and where the deposits are made.
                      </span>
                    </BPSettingsRowTitle>
                    <BPSettingsRowFields>
                      <TwoCol>
                        <SubCol>
                          <FieldWrap style={{ maxWidth: '200px' }}>
                            <Label
                              text="Default Deposit Account"
                              info="Default account that deposits will be added to."
                              required
                            />
                            <Field
                              name="deposit_account_id"
                              clearable={false}
                              format={value => {
                                return billingCycleDayOptions.find(
                                  option => option?.value === value
                                );
                              }}
                              parse={option => {
                                return option?.value;
                              }}
                            >
                              {props => {
                                return (
                                  <>
                                    {accountOptions ? (
                                      <div data-testid="bp-transaction-deposit-account">
                                        <Select
                                          {...props.input}
                                          options={accountOptions}
                                          isDisabled={!accountOptions}
                                          defaultValue={{
                                            ...fillDepositAccount(),
                                          }}
                                          data-trackingid="bp-transaction-deposit-account"
                                          isClearable={false}
                                          error={
                                            !!formProps.errors
                                              ?.deposit_account_id
                                          }
                                          helperText={
                                            formProps.touched
                                              ?.deposit_account_id
                                              ? formProps.errors
                                                  ?.deposit_account_id
                                              : null
                                          }
                                        />
                                      </div>
                                    ) : null}
                                  </>
                                );
                              }}
                            </Field>
                          </FieldWrap>
                        </SubCol>
                        <SubCol>
                          <FieldWrap style={{ maxWidth: '120px' }}>
                            <Label
                              text="Deposit Delay"
                              info="Number of business days (0-180) it takes for deposits to hit the deposit account. Defaults to the next business day if no value is provided."
                            />
                            <Field
                              name="funding_schedule_days"
                              parse={v => (v ? v.replace(/[^0-9]/g, '') : '')}
                            >
                              {props => {
                                return (
                                  <TextField
                                    {...props.input}
                                    slug={<span>Days</span>}
                                    error={
                                      formProps.errors?.funding_schedule_days
                                    }
                                  />
                                );
                              }}
                            </Field>
                          </FieldWrap>
                        </SubCol>
                      </TwoCol>
                    </BPSettingsRowFields>
                    <BPSettingsRowDescription>
                      <DepositDescription
                        channel={formProps.values.channel?.label}
                        subchannel={formProps.values.subchannel?.label}
                        depositAccount={accountOptions.find(
                          ({ value }) =>
                            formProps.values.deposit_account_id === value
                        )}
                        depositAccountId={formProps.values.deposit_account_id}
                        fundingDays={
                          parseInt(formProps.values.funding_schedule_days) ?? 0
                        }
                      />
                    </BPSettingsRowDescription>
                  </BPSettingsRow>
                </>
              ) : null}
            </form>
          )}
        </Form>
      )}
    </div>
  );
};
