import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';

const FormContainer = styled.div`
  .supportForm {
    background-color: #062333;
    font-family: Roboto, sans-serif;
    font-weight: 300;
    line-height: 1.7;
    color: white;
    padding-bottom: 1px;
    padding-top: 1px;
  }

  .supportForm .field label {
    font-weight: 400;
  }

  .supportForm input,
  .supportForm textarea {
    border: 1px solid rgb(67, 94, 112);
    background: rgb(33, 55, 69);
    border-radius: 2px;
    padding: 8px 10px;
    color: rgb(189, 201, 204);
    width: 100%;
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
  }

  .supportForm textarea {
    margin-bottom: -8px;
  }

  .supportForm input:hover,
  .supportForm input:focus,
  .supportForm textarea:hover,
  .supportForm textarea:focus {
    box-shadow: 0px 0px 5px;
  }

  .supportForm .hs_error_rollup {
    font-weight: bold;
    width: 100%;
    padding: 4px 16px;
    background-color: #ff4646;
    box-sizing: border-box;
  }

  .supportForm .hs-error-msgs {
    color: #ff4646;
    font-size: 12px;
  }

  .supportForm .hs_error_rollup .validationErrorMessage label {
    color: #062333;
  }

  .supportForm .hs-field-desc {
    color: #8d9799;
    font-size: 12px;
    line-height: 18px;
  }

  .supportForm .hs-form-required {
    margin-left: 8px;
    color: #ff4646;
  }

  .supportForm .hs-form-field,
  .supportForm .actions {
    margin: 16px;
  }

  .supportForm .hs-submit {
    height: 100px;
  }

  .supportForm .hs-button.primary.large {
    color: #062333;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    width: 106px;
    height: 42px;
    left: 40px;
    top: 44px;
    background: #ffffff;
    border-radius: 2px;
    float: right;
  }

  .supportForm .hs-button.primary.large:hover {
    background-color: #3e7d65;
    cursor: pointer;
  }

  .supportForm .hs-button.primary.large:active {
    background: #59a97e;
    box-shadow: 0px 0px 6px rgba(255, 255, 255, 0.2);
  }

  .supportForm input .error {
    border: 1px solid #ff4646;
  }

  .supportForm .validationErrorMessage {
    padding: 4px 0px;
    margin: 0px;
    font-size: 12px;
    line-height: 18px;
  }

  .supportForm .validationErrorMessage li {
    color: #ff4646;
    display: block;
  }

  a {
    color: #5e72e4 !important;
  }
`;

export const EscalatedSupportForm: FunctionComponent = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/v2.js';
    script.async = true;
    script.onload = () => {
      if (window['hbspt']) {
        let hubspotFormId = '73209b76-818e-4a26-804c-8de84c7391e6';
        let hubspotPortalId = '467991';

        switch (process.env.REACT_APP_ENV) {
          case 'development':
          case 'qa':
            hubspotPortalId = '20761951';
            hubspotFormId = 'cc74388c-a8ae-4481-a1b7-c35dd60e73dc';
            break;

          default:
            break;
        }
        window['hbspt'].forms?.create({
          target: '#hubspot-support-form',
          region: 'na1',
          portalId: hubspotPortalId,
          formId: hubspotFormId,
          cssClass: 'supportForm',
          errorMessageClass: 'validationErrorMessage',
        });
      }
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <FormContainer id="hubspot-support-form" />;
};
