import styled from 'styled-components';
import { Drawer, Text } from '@fattmerchantorg/truffle-components';
import { Separator as USeparator } from '../../shared';

export const StyledDrawer = styled(Drawer)``;

export const DrawerContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 16px 16px 8px 16px;

  h3 {
    font-weight: normal;
    margin: 0 16px 0 0;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-left: auto;

  > span {
    width: 18px;
    height: 18px;
  }
`;

export const FundingAccountsContainer = styled.div`
  align-items: center;
  display: flex;
  padding: 0 16px 16px 16px;
`;

export const DateText = styled(Text)`
  font-size: 16px;
  margin-bottom: 0px;
`;

export const Separator = styled(USeparator)``;

export const PaymentMethodContainer = styled.div`
  padding: 16px;
`;

export const DetailsHeader = styled(Text)`
  align-items: center;
  display: flex;
  font-size: 1.125rem;
  justify-content: space-between;
  margin: 0;
  padding-bottom: 16px;
`;

export const PaymentDetails = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const TransactionsText = styled(Text)`
  font-size: 14px;
  margin-bottom: 4px;
`;
