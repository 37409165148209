import {
  Billing,
  PaymentMethod,
  Reserves,
} from '@fattmerchantorg/types-engine/DB';
import {
  Merchant,
  APIKey,
  Gateway,
  Registration,
  GodviewBrandData,
} from '@fattmerchantorg/types-omni';
import { Action, ActionType } from './SelectedMerchant.types';

/**
 * This action is only intended to be used to re-fetch the already-selected merchant.
 * It should not be used to switch to a new merchant -- please use the `updateSelectedMerchant` function in those cases
 */
export function fetchSelectedMerchant(merchantId: string): Action {
  const type: ActionType = ActionType.FETCH_SELECTED_MERCHANT;
  return { type: type, payload: merchantId };
}

export function updateSelectedMerchant(payload: Merchant): Action {
  const type: ActionType = payload
    ? ActionType.UPDATE_SELECTED_MERCHANT
    : ActionType.REMOVE_SELECTED_MERCHANT;
  return { type: type, payload };
}

export function addSelectedMerchant(payload: Merchant): Action {
  const type: ActionType = payload
    ? ActionType.ADD_SELECTED_MERCHANT
    : ActionType.REMOVE_SELECTED_MERCHANT;
  return { type: type, payload };
}
export function rehydrateSelectedMerchant(payload: Merchant): Action {
  const type: ActionType = payload
    ? ActionType.REHYDRATE_SELECTED_MERCHANT
    : ActionType.REMOVE_SELECTED_MERCHANT;
  return { type: type, payload };
}

export function updateSelectedMerchantGateways(payload: Gateway[]): Action {
  const type: ActionType = payload
    ? ActionType.UPDATE_SELECTED_MERCHANT_GATEWAYS
    : ActionType.CLEAR_SELECTED_MERCHANT_GATEWAYS;
  return { type: type, payload };
}

export function addSelectedMerchantGateway(payload: Gateway): Action {
  return { type: ActionType.ADD_SELECTED_MERCHANT_GATEWAY, payload };
}

export function updateSelectedMerchantAPIKeys(payload: APIKey[]): Action {
  const type: ActionType = payload
    ? ActionType.UPDATE_SELECTED_MERCHANT_APIKEYS
    : ActionType.CLEAR_SELECTED_MERCHANT_APIKEYS;
  return { type, payload };
}

export function appendSelectedMerchantAPIKey(payload: APIKey): Action {
  return { type: ActionType.APPEND_SELECTED_MERCHANT_APIKEY, payload };
}

export function deleteSelectedMerchantAPIKey(apiKeyID: string): Action {
  return {
    type: ActionType.DELETE_SELECTED_MERCHANT_APIKEY,
    payload: apiKeyID,
  };
}

export function removeSelectedMerchantAPIKey(payload: APIKey): Action {
  return { type: ActionType.REMOVE_SELECTED_MERCHANT_APIKEY, payload };
}

export function updateSelectedMerchantRegistration(
  payload: Registration
): Action {
  const type: ActionType = payload
    ? ActionType.UPDATE_SELECTED_MERCHANT_REGISTRATION
    : ActionType.REMOVE_SELECTED_MERCHANT_REGISTRATION;
  return { type, payload };
}

export function fetchSelectedMerchantBrandData(brandName: string) {
  return {
    type: ActionType.FETCH_SELECTED_MERCHANT_BRAND_DATA,
    payload: brandName,
  };
}

export function updateSelectedMerchantBrandData(
  payload: GodviewBrandData | null
): Action {
  const type: ActionType = payload
    ? ActionType.UPDATE_SELECTED_MERCHANT_BRAND_DATA
    : ActionType.REMOVE_SELECTED_MERCHANT_BRAND_DATA;
  return { type: type, payload };
}

export function updateSelectedMerchantStatus(status: string) {
  return {
    type: ActionType.UPDATE_SELECTED_MERCHANT_STATUS,
    payload: status,
  };
}

/**
 * Alter the existing selected merchant without triggering the side-effects
 * of refetching associated data (registration, gateways, api keys).
 */
export function alterSelectedMerchant(payload: Partial<Merchant>) {
  return {
    type: ActionType.ALTER_SELECTED_MERCHANT,
    payload,
  };
}

export function updateSelectedBrandSwitcherOption(brands: string) {
  return {
    type: ActionType.UPDATE_SELECTED_BRAND_SWITCHER_OPTION,
    payload: brands,
  };
}

export function fetchSelectedMerchantBillingProfiles(engineCompanyId: string) {
  return {
    type: ActionType.FETCH_SELECTED_MERCHANT_BILLING_PROFILES,
    payload: engineCompanyId,
  };
}

export function updateSelectedMerchantBillingProfiles(
  billingProfiles: Billing[] | null
) {
  return {
    type: ActionType.UPDATE_SELECTED_MERCHANT_BILLING_PROFILES,
    payload: billingProfiles,
  };
}

export function fetchSelectedMerchantPaymentMethods(engineCompanyId: string) {
  return {
    type: ActionType.FETCH_SELECTED_MERCHANT_PAYMENT_METHODS,
    payload: engineCompanyId,
  };
}

export function updateSelectedMerchantPaymentMethods(
  paymentMethods: PaymentMethod[] | null
) {
  return {
    type: ActionType.UPDATE_SELECTED_MERCHANT_PAYMENT_METHODS,
    payload: paymentMethods,
  };
}

export function fetchMerchantFundingAccounts(merchantId: string) {
  return {
    type: ActionType.FETCH_MERCHANT_FUNDING_ACCOUNTS,
    payload: merchantId,
  };
}

export function updateMerchantFundingAccounts(merchantFundingAccount) {
  return {
    type: ActionType.UPDATE_MERCHANT_FUNDING_ACCOUNTS,
    payload: merchantFundingAccount,
  };
}

export function fetchSelectedMerchantReserves(engineCompanyId: string) {
  return {
    type: ActionType.FETCH_SELECTED_MERCHANT_RESERVES,
    payload: engineCompanyId,
  };
}

export function updateSelectedMerchantReserves(reserves: Reserves[] | null) {
  return {
    type: ActionType.UPDATE_SELECTED_MERCHANT_RESERVES,
    payload: reserves,
  };
}
