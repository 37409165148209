import React, { useCallback, useState, useContext } from 'react';
import styled from 'styled-components';
import { Form as FinalForm } from 'react-final-form';
import createDecorator from 'final-form-calculate';
import { Link, useHistory } from 'react-router-dom';
import { Icon } from '@fattmerchantorg/truffle-components';
import {
  TextField,
  Label,
  Select,
  Checkbox,
} from '@fattmerchantorg/truffle-components';
import { OptionTypeBase } from 'react-select';

import {
  SmallPrimaryButton,
  SmallSecondaryButton,
} from '@fattmerchantorg/truffle-components';
import { PricingPlan } from '@fattmerchantorg/types-omni';
import omit from 'lodash/omit';

import {
  SettingsHeaderContent,
  SettingsHeaderBreadcrumbs,
  SettingsHeaderRow,
  SettingsHeaderContentTitle,
  SettingsHeaderContentTitleText,
  SettingsSubheader,
  SettingsHeaderContentActions,
  MainContent,
  SettingsSection,
  SettingsSectionHeader,
  SettingsSectionContent,
  VerticalDividingLine,
} from '../SettingsPagesStylings';
import { SelectedMerchantStore, PermissionsStore } from '../../../context';
import { Field, FormSwitch } from '../../shared';
import { isRequired } from '../../../util/form.util';
import { BoldText } from '../../truffle-dashboard/components/shared/BoldText';

type PricingPlansFormValues = Pick<
  PricingPlan,
  | 'name'
  | 'displayName'
  | 'type'
  | 'isPayfac'
  | 'isNdf'
  | 'discountFee'
  | 'perTransactionFee'
  | 'amexDiscountFee'
  | 'amexPerTransactionFee'
  | 'cpDiscountFee'
  | 'cpPerTransactionFee'
  | 'cpAmexPerDiscountFee'
  | 'cpAmexPerTransactionFee'
  | 'cnpCcSurchargeRate'
  | 'achDiscountFee'
  | 'achPerTransactionFee'
  | 'achCappedFee'
> & {
  productType: OptionTypeBase[];
  duplicateTrust: boolean;
  isMirroring: boolean;
  enabledSurcharge: boolean;
};

export type PricingPlanFromForm = Omit<Partial<PricingPlan>, 'id'>;

const pricingPlansRoute = '/settings/pricingplans';

const Columns = styled.div`
  display: flex;
  flex-direction: row;

  & > *:not(:first-child) {
    margin-left: 24px;
  }
`;

const calculator = createDecorator(
  {
    field: 'discountFee', // when discountFee changes...
    updates: {
      cpDiscountFee: (discountFee, allValues: PricingPlansFormValues) =>
        allValues.isMirroring ? discountFee : allValues.cpDiscountFee,
    },
  },
  {
    field: 'perTransactionFee', // when perTransactionFee changes...
    updates: {
      cpPerTransactionFee: (discountFee, allValues: PricingPlansFormValues) =>
        allValues.isMirroring ? discountFee : allValues.cpPerTransactionFee,
    },
  },
  {
    field: 'amexDiscountFee', // amexDiscountFee...
    updates: {
      cpAmexPerDiscountFee: (discountFee, allValues: PricingPlansFormValues) =>
        allValues.isMirroring ? discountFee : allValues.cpAmexPerDiscountFee,
    },
  },
  {
    field: 'amexPerTransactionFee', // amexPerTransactionFee...
    updates: {
      cpAmexPerTransactionFee: (
        discountFee,
        allValues: PricingPlansFormValues
      ) =>
        allValues.isMirroring ? discountFee : allValues.cpAmexPerTransactionFee,
    },
  }
);

type PricingPlansFormProps = {
  pricingPlan?: Partial<PricingPlan>;
  onSubmit: (values: {
    pricingPlan: PricingPlanFromForm;
    duplicateForTrustAccount?: boolean;
  }) => Promise<void>;
};

export const PricingPlansForm = ({ onSubmit }: PricingPlansFormProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const history = useHistory();

  const {
    state: { brands },
  } = useContext(PermissionsStore);

  const {
    state: { selectedBrandSwitcherOption },
  } = useContext(SelectedMerchantStore);

  const brand = brands.find(
    brand => brand?.name === selectedBrandSwitcherOption
  );

  // This shouldn't happen, but we add the guard in any case
  const noBrandSelected = brand === undefined;

  const handleCancel = useCallback(() => {
    history.push(pricingPlansRoute);
  }, [history]);

  const typeOptions = [
    { value: 'Flat Rate', label: 'Flat Rate' },
    { value: 'Interchange', label: 'Interchange+' },
  ];

  const productTypeOptions = [
    { value: 'api', label: 'API' },
    { value: 'online', label: 'Online' },
    { value: 'in-person', label: 'In Person' },
    {
      value: 'shopping-cart',
      label: 'Shopping Cart',
    },
  ];

  const initialValues: PricingPlansFormValues = {
    name: '',
    displayName: '',
    type: 'Flat Rate',
    productType: productTypeOptions,
    isPayfac: true,
    achCappedFee: null,
    duplicateTrust: false,
    isMirroring: true,
    enabledSurcharge: false,
  };

  const handleSubmit = useCallback(
    async (formValues: PricingPlansFormValues) => {
      if (!formValues) return;
      setIsSaving(true);
      let pricingPlan: PricingPlanFromForm = {
        brands: [brand?.id],
        ...omit(formValues, [
          'productType',
          'duplicateTrust',
          'isMirroring',
          'enabledSurcharge',
          'cnpCcSurchargeRate',
        ]),
        productType: formValues.productType.map(v => v.value),
        ...(formValues.enabledSurcharge
          ? {
              cnpCcSurchargeRate: formValues.cnpCcSurchargeRate,
              cpCcSurchargeRate: formValues.cnpCcSurchargeRate,
              cpDebitPerTransactionFee: formValues.cpPerTransactionFee,
              cnpDebitPerTransactionFee: formValues.perTransactionFee,
              cpDebitDiscountRate: formValues.cpDiscountFee,
              cnpDebitDiscountRate: formValues.discountFee,
            }
          : {}),
      };
      pricingPlan = Object.fromEntries(
        Object.entries(pricingPlan).filter(([key, value]) => value !== null)
      );

      await onSubmit({
        pricingPlan,
        duplicateForTrustAccount: formValues.duplicateTrust,
      });

      setIsSaving(false);
    },
    [brand, onSubmit]
  );

  return (
    <FinalForm<PricingPlansFormValues>
      onSubmit={handleSubmit}
      decorators={[calculator]}
      initialValues={initialValues}
      keepDirtyOnReinitialize
    >
      {({ handleSubmit, dirty, values }) => {
        return (
          <form onSubmit={handleSubmit} noValidate>
            <SettingsHeaderContent>
              <SettingsHeaderBreadcrumbs>
                <Link to={pricingPlansRoute}>
                  <Icon icon={['fas', 'caret-left']} />
                  Pricing Plans
                </Link>
              </SettingsHeaderBreadcrumbs>
              <SettingsHeaderRow>
                <SettingsHeaderContentTitle>
                  <SettingsHeaderContentTitleText>
                    <span style={{ fontWeight: 'normal' }}>Add - </span>
                    {values.name}
                  </SettingsHeaderContentTitleText>
                  <SettingsSubheader>
                    Add a new Pricing Plan by filling out the information below.
                  </SettingsSubheader>
                </SettingsHeaderContentTitle>
                <SettingsHeaderContentActions>
                  <SmallSecondaryButton
                    onClick={handleCancel}
                    disabled={isSaving}
                  >
                    Cancel
                  </SmallSecondaryButton>
                  <SmallPrimaryButton
                    type="submit"
                    disabled={!dirty || noBrandSelected}
                    loading={isSaving}
                  >
                    Create Pricing Plan
                  </SmallPrimaryButton>
                </SettingsHeaderContentActions>
              </SettingsHeaderRow>
            </SettingsHeaderContent>

            <MainContent>
              <SettingsSection>
                {/* Information */}

                <SettingsSectionHeader>Information</SettingsSectionHeader>
                <SettingsSectionContent>
                  <Field name="name" validate={isRequired}>
                    {props => (
                      <div style={{ width: 244 }}>
                        <TextField
                          {...props.input}
                          required
                          type="text"
                          label="Plan Name (Enum)"
                          placeholder="Plan Name"
                          error={!!props.meta.error && props.meta.touched}
                          helperText={props.meta.touched && props.meta.error}
                        />
                      </div>
                    )}
                  </Field>

                  <Columns>
                    <Field name="displayName" validate={isRequired}>
                      {props => (
                        <div style={{ width: 244 }}>
                          <TextField
                            {...props.input}
                            required
                            type="text"
                            label="Display Name"
                            placeholder="Display Name"
                            error={!!props.meta.error && props.meta.touched}
                            helperText={props.meta.touched && props.meta.error}
                          />
                        </div>
                      )}
                    </Field>

                    <div style={{ width: 160 }}>
                      <Field
                        name="type"
                        validate={isRequired}
                        format={value => {
                          return typeOptions.find(
                            option => option.value === value
                          );
                        }}
                      >
                        {props => (
                          <>
                            <Label text="Plan Type" required />
                            <Select
                              {...props.input}
                              isRequired
                              options={typeOptions}
                              error={!!props.meta.error && props.meta.touched}
                              helperText={
                                props.meta.touched && props.meta.error
                              }
                            />
                          </>
                        )}
                      </Field>
                    </div>

                    <div style={{ width: 160 }}>
                      <Field name="productType" validate={isRequired}>
                        {props => (
                          <>
                            <Label text="Product Type" required />
                            <Select
                              {...props.input}
                              isRequired
                              isMulti
                              options={productTypeOptions}
                              error={!!props.meta.error && props.meta.touched}
                              helperText={
                                props.meta.touched && props.meta.error
                              }
                            />
                          </>
                        )}
                      </Field>
                    </div>
                  </Columns>

                  <Columns>
                    <Field name="isNdf" type="checkbox">
                      {props => (
                        <Checkbox
                          {...props.input}
                          className="pricing-plan-NDF"
                          label="Next Day Funding"
                        />
                      )}
                    </Field>

                    <Field name="isPayfac" type="checkbox">
                      {props => (
                        <Checkbox
                          {...props.input}
                          className="pricing-plan-ispayfac"
                          label="isPayfac"
                        />
                      )}
                    </Field>

                    <Field name="duplicateTrust" type="checkbox">
                      {props => (
                        <Checkbox
                          {...props.input}
                          className="pricing-plan-trust"
                          label="Duplicate for Trust Account"
                        />
                      )}
                    </Field>
                  </Columns>
                </SettingsSectionContent>

                {/* Credit Cards */}

                <SettingsSectionHeader>Credit Cards</SettingsSectionHeader>
                <SettingsSectionContent>
                  <Columns>
                    {/* CNP */}

                    <div>
                      <BoldText fontSize={16}>CNP</BoldText>
                      <Field name="discountFee" validate={isRequired}>
                        {props => (
                          <div style={{ width: 120 }}>
                            <TextField
                              {...props.input}
                              required
                              type="number"
                              label="Discount Fee"
                              inputSuffix={<span>%</span>}
                              error={!!props.meta.error && props.meta.touched}
                              helperText={
                                props.meta.touched && props.meta.error
                              }
                            />
                          </div>
                        )}
                      </Field>

                      <Field name="perTransactionFee" validate={isRequired}>
                        {props => (
                          <div style={{ width: 120 }}>
                            <TextField
                              {...props.input}
                              required
                              type="number"
                              label="Transaction Fee"
                              inputPrefix={<span>$</span>}
                              error={!!props.meta.error && props.meta.touched}
                              helperText={
                                props.meta.touched && props.meta.error
                              }
                            />
                          </div>
                        )}
                      </Field>
                    </div>

                    {/* CNP AMEX */}

                    <div>
                      <BoldText fontSize={16}>CNP Amex</BoldText>
                      <Field name="amexDiscountFee" validate={isRequired}>
                        {props => (
                          <div style={{ width: 120 }}>
                            <TextField
                              {...props.input}
                              required
                              type="number"
                              label="Discount Fee"
                              inputSuffix={<span>%</span>}
                              error={!!props.meta.error && props.meta.touched}
                              helperText={
                                props.meta.touched && props.meta.error
                              }
                            />
                          </div>
                        )}
                      </Field>

                      <Field name="amexPerTransactionFee" validate={isRequired}>
                        {props => (
                          <div style={{ width: 120 }}>
                            <TextField
                              {...props.input}
                              required
                              type="number"
                              label="Transaction Fee"
                              inputPrefix={<span>$</span>}
                              error={!!props.meta.error && props.meta.touched}
                              helperText={
                                props.meta.touched && props.meta.error
                              }
                            />
                          </div>
                        )}
                      </Field>
                    </div>

                    <VerticalDividingLine />

                    {/* CP */}

                    <div>
                      <BoldText fontSize={16}>CP</BoldText>
                      <Field name="cpDiscountFee" validate={isRequired}>
                        {props => (
                          <div style={{ width: 120 }}>
                            <TextField
                              {...props.input}
                              required
                              type="number"
                              label="Discount Fee"
                              inputSuffix={<span>%</span>}
                              error={!!props.meta.error && props.meta.touched}
                              helperText={
                                props.meta.touched && props.meta.error
                              }
                            />
                          </div>
                        )}
                      </Field>

                      <Field name="cpPerTransactionFee" validate={isRequired}>
                        {props => (
                          <div style={{ width: 120 }}>
                            <TextField
                              {...props.input}
                              required
                              type="number"
                              label="Transaction Fee"
                              inputPrefix={<span>$</span>}
                              error={!!props.meta.error && props.meta.touched}
                              helperText={
                                props.meta.touched && props.meta.error
                              }
                            />
                          </div>
                        )}
                      </Field>
                    </div>

                    {/* CP AMEX */}

                    <div>
                      <BoldText fontSize={16}>CP - AMEX</BoldText>
                      <Field name="cpAmexPerDiscountFee" validate={isRequired}>
                        {props => (
                          <div style={{ width: 120 }}>
                            <TextField
                              {...props.input}
                              required
                              type="number"
                              label="Discount Fee"
                              inputSuffix={<span>%</span>}
                              error={!!props.meta.error && props.meta.touched}
                              helperText={
                                props.meta.touched && props.meta.error
                              }
                            />
                          </div>
                        )}
                      </Field>

                      <Field
                        name="cpAmexPerTransactionFee"
                        validate={isRequired}
                      >
                        {props => (
                          <div style={{ width: 120 }}>
                            <TextField
                              {...props.input}
                              required
                              type="number"
                              label="Transaction Fee"
                              inputPrefix={<span>$</span>}
                              error={!!props.meta.error && props.meta.touched}
                              helperText={
                                props.meta.touched && props.meta.error
                              }
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                  </Columns>

                  <Field name="isMirroring" type="checkbox">
                    {props => (
                      <>
                        <Label
                          text="Mirror CNP Rates"
                          info="Mirror CNP rates into CP fields"
                        />
                        <FormSwitch
                          checked={props.input.checked ?? false}
                          className="pricing-plan-mirrorcnp"
                          {...props.input}
                        />
                      </>
                    )}
                  </Field>
                </SettingsSectionContent>

                {/* Surcharge */}

                <SettingsSectionHeader>Surcharge</SettingsSectionHeader>
                <SettingsSectionContent>
                  <Columns>
                    <div>
                      <Field name="enabledSurcharge" type="checkbox">
                        {props => (
                          <>
                            <Label text="Enable Surcharging" />
                            <FormSwitch
                              checked={props.input.checked ?? false}
                              className="pricing-plan-surcharge"
                              {...props.input}
                            />
                          </>
                        )}
                      </Field>
                    </div>

                    {values.enabledSurcharge ? (
                      <Field name="cnpCcSurchargeRate" validate={isRequired}>
                        {props => (
                          <div style={{ width: 120 }}>
                            <TextField
                              {...props.input}
                              required
                              type="number"
                              label="Discount Fee"
                              inputSuffix={<span>%</span>}
                              error={!!props.meta.error && props.meta.touched}
                              helperText={
                                props.meta.touched && props.meta.error
                              }
                            />
                          </div>
                        )}
                      </Field>
                    ) : undefined}
                  </Columns>
                </SettingsSectionContent>

                {/* ACH */}

                <SettingsSectionHeader>ACH</SettingsSectionHeader>
                <SettingsSectionContent>
                  <Columns>
                    <div>
                      <Field name="achDiscountFee">
                        {props => (
                          <div style={{ width: 120 }}>
                            <TextField
                              {...props.input}
                              type="number"
                              label="Discount Fee"
                              inputSuffix={<span>%</span>}
                            />
                          </div>
                        )}
                      </Field>

                      <Field name="achPerTransactionFee">
                        {props => (
                          <div style={{ width: 120 }}>
                            <TextField
                              {...props.input}
                              type="number"
                              label="Transaction Fee"
                              inputPrefix={<span>$</span>}
                            />
                          </div>
                        )}
                      </Field>
                    </div>

                    <div>
                      <Field name="achCappedFee">
                        {props => (
                          <div style={{ width: 120 }}>
                            <TextField
                              {...props.input}
                              type="number"
                              label="Capped Fee"
                              inputPrefix={<span>$</span>}
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                  </Columns>
                </SettingsSectionContent>
              </SettingsSection>
            </MainContent>
          </form>
        );
      }}
    </FinalForm>
  );
};
