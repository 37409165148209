import { ActionTypes } from './types';
import { SupportTicket } from '../../../@types';

/* Actions */
interface RequestAction<T extends keyof typeof ActionTypes, P = never> {
  type: T;
  payload?: P;
}
interface SuccessAction<T extends keyof typeof ActionTypes> {
  type: T;
  payload: SupportTicket;
}
interface FailedAction<T extends keyof typeof ActionTypes> {
  type: T;
  payload: Error;
}

type FetchRequestedAction = RequestAction<typeof ActionTypes.FETCH_REQUESTED>;
type FetchSuccessAction = SuccessAction<typeof ActionTypes.FETCH_SUCCEEDED>;
type FetchFailedAction = FailedAction<typeof ActionTypes.FETCH_FAILED>;

export type Action =
  | FetchRequestedAction
  | FetchSuccessAction
  | FetchFailedAction;

/** Action Creators */
export function sendSupportTicketIDChanged(): Action {
  return { type: ActionTypes.FETCH_REQUESTED };
}
export function sendFetchSucceeded(payload: SupportTicket): Action {
  return { type: ActionTypes.FETCH_SUCCEEDED, payload };
}
export function sendFetchFailed(payload: Error): Action {
  return { type: ActionTypes.FETCH_FAILED, payload };
}
