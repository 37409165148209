import styled from 'styled-components';

// TODO: use type for "props" once truffle-tokens types are corrected
export const StatementDisclaimer = styled.div`
  color: ${props => props.theme.colors.core.white[0].hex};
  background: ${props => props.theme.colors.status.blue[500].hex};
  padding: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
`;
