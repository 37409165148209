import React, { FunctionComponent } from 'react';
import { Copy } from './DevTools';

export const getRelevantEnvs = () => {
  const env = process.env;

  // Filter only `React_App` envs.
  const relevantEnvs = Object.keys(env)
    .filter(key => key.startsWith('REACT_APP')) // Filter the keys.
    .reduce((newData, key) => {
      newData[key] = env[key]; // Rebuild with env values.
      return newData;
    }, {});

  return relevantEnvs;
};

export const Environment: FunctionComponent = () => {
  const relevantEnvs = getRelevantEnvs();

  return (
    <>
      <h2>Environment</h2>

      <dl>
        {Object.keys(relevantEnvs).map(key => {
          const keyVal: string = relevantEnvs[key];
          return (
            <React.Fragment key={keyVal}>
              <dt>{key}</dt>
              <dd>
                <Copy val={keyVal} label={keyVal} />
              </dd>
            </React.Fragment>
          );
        })}
      </dl>
    </>
  );
};
