import {
  useReducer,
  Reducer,
  useEffect,
  Dispatch,
  useRef,
  MutableRefObject,
} from 'react';
import { CancelTokenSource } from 'axios';
import { produce } from 'immer';
import { AsyncDataNoNull, AsyncDataStatus } from '../../../../@types';
import { queryapi } from '../../../../api';
import { generateCancelSource } from '../../../../util/api.util';
import { useAuthToken, usePrevious } from '../../../../hooks';
import { ActionTypes } from '../../@types/TimeRange';
import { adjustDataForDashboard } from '../../../../util/dashboard.util';

/*
 * Actions
 */
interface FetchSucceededAction {
  type: typeof ActionTypes.FETCH_SUCCEEDED;
  payload: [];
}

interface FetchFailedAction {
  type: typeof ActionTypes.FETCH_FAILED;
  payload: Error;
}

interface UserSelectedTimespan {
  type: typeof ActionTypes.USER_SELECTED_TIMESPAN;
  payload: undefined;
}

export type Action =
  | FetchFailedAction
  | FetchSucceededAction
  | UserSelectedTimespan;

/*
 * Action Creators
 */
export function sendFetchSucceeded(payload: []): Action {
  return { type: ActionTypes.FETCH_SUCCEEDED, payload };
}

export function sendFetchFailed(payload: Error): Action {
  return { type: ActionTypes.FETCH_FAILED, payload };
}

// residuals doesn't use a datetime, just pass an undefined payload on brand change
// and we'll set the status to LOADING while data is fetching
export function sendResidualsUserSelectedTimespan(): Action {
  return { type: ActionTypes.USER_SELECTED_TIMESPAN, payload: undefined };
}

type ReducerState = {
  residuals: AsyncDataNoNull<[]>;
};

const initialState: ReducerState = {
  residuals: {
    status: AsyncDataStatus.INITIAL,
    data: [],
    error: null,
  },
};

/** PrevStatus will be `undefined` during the first render. */
type PrevStatus = AsyncDataStatus | undefined;

async function fetchResiduals(
  authToken: string,
  dispatch: Dispatch<Action>,
  brands: string,
  brandsRef: MutableRefObject<String | null>,
  cancelSourceRef: MutableRefObject<CancelTokenSource>
) {
  // Run once "global brand" is in context.
  if (brands) {
    try {
      const newCancelSource = generateCancelSource();

      brandsRef.current = brands;
      cancelSourceRef.current = newCancelSource;

      const theData: [] = await queryapi
        .get(
          authToken,
          '/brand-residuals',
          { brands },
          undefined,
          undefined,
          newCancelSource
        )
        .then(response => {
          const rawData = response.data.sort((a: any, b: any) =>
            a.activity_period > b.activity_period ? 1 : -1
          );
          const adjustedData = adjustDataForDashboard(rawData);
          return adjustedData;
        })
        .catch(error => {
          dispatch(sendFetchFailed(error));
        });

      dispatch(sendFetchSucceeded(theData));
    } catch (error) {
      // We can still dispatch fetch failed here even on an axios cancel
      // The way the line chart is built, we want to show the "no data" message
      // as opposed to just passing undefined to the data set
      dispatch(sendFetchFailed(error));
    }
  }
}

type TransactionChartReducer = Reducer<ReducerState, Action>;
/**
 * Reducer for the custom useTransactionChartHook.
 * This reducer is written in finite-state-machine style:
 * If the current status accepts the current action, transition to a new status.
 * https://twitter.com/DavidKPiano/status/1171062893984526336
 */
const reducer: Reducer<ReducerState, Action> = (state, action) => {
  // Use immer's produce function to simplify state updates
  return produce<ReducerState>(state, draftState => {
    switch (state.residuals.status) {
      case AsyncDataStatus.INITIAL:
      case AsyncDataStatus.IDLE:
        switch (action.type) {
          case ActionTypes.FETCH_SUCCEEDED: {
            draftState.residuals.status = AsyncDataStatus.IDLE;
            draftState.residuals.data = action.payload;
            draftState.residuals.error = null;
            return;
          }
          case ActionTypes.USER_SELECTED_TIMESPAN: {
            draftState.residuals.status = AsyncDataStatus.LOADING;
            return;
          }
          case ActionTypes.FETCH_FAILED: {
            draftState.residuals.status = AsyncDataStatus.ERROR;
            draftState.residuals.error = action.payload;
            draftState.residuals.data = [];
            return;
          }
          default:
            return;
        }
      case AsyncDataStatus.ERROR:
        switch (action.type) {
          case ActionTypes.FETCH_SUCCEEDED: {
            draftState.residuals.status = AsyncDataStatus.IDLE;
            draftState.residuals.error = null;
            return;
          }
          case ActionTypes.USER_SELECTED_TIMESPAN: {
            draftState.residuals.status = AsyncDataStatus.LOADING;
            return;
          }
          default:
            return;
        }
      case AsyncDataStatus.LOADING:
        switch (action.type) {
          case ActionTypes.FETCH_SUCCEEDED: {
            draftState.residuals.status = AsyncDataStatus.IDLE;
            draftState.residuals.data = action.payload;
            draftState.residuals.error = null;
            return;
          }
          case ActionTypes.FETCH_FAILED: {
            draftState.residuals.status = AsyncDataStatus.ERROR;
            draftState.residuals.error = action.payload;
            draftState.residuals.data = [];
            return;
          }
          default:
            return;
        }
      default:
        return state;
    }
  });
};

export function useResidualsData(
  mode: string,
  brand: string
): [ReducerState, Dispatch<Action>?] {
  const [state, dispatch] = useReducer<TransactionChartReducer>(
    reducer,
    initialState
  );

  const previousStatus: PrevStatus = usePrevious<AsyncDataStatus>(
    state.residuals.status
  );

  const brandsRef = useRef<String>(null); // Track last selected brand(s)
  const cancelSourceRef = useRef<CancelTokenSource>(generateCancelSource()); // Track axios cancel source of each request
  const authToken = useAuthToken();

  const previousBrand: string = usePrevious<string>(brand);

  /*
   * Perform side-effects according to status transitions.
   */
  useEffect(() => {
    // Only fetch data in LIVE MODE.
    if (mode === 'LIVE_MODE') {
      // Trigger on load.
      if (
        isChangingStatus(
          previousStatus,
          state.residuals.status,
          AsyncDataStatus.INITIAL
        ) ||
        previousBrand !== brand // If the user has selected a new brand from the `brand switcher`.
      ) {
        cancelSourceRef.current.cancel();
        fetchResiduals(authToken, dispatch, brand, brandsRef, cancelSourceRef);
      }
    }
  }, [
    authToken,
    previousStatus,
    state.residuals.status,
    state.residuals.error,
    mode,
    brand,
    previousBrand,
  ]);

  return [state, dispatch];
}

/**
 * Compares statuses to determine if there is a transition happening.
 * @param previousStatus
 * @param nextStatus
 * @param statusToCheck
 */
function isChangingStatus(
  previousStatus: PrevStatus,
  nextStatus: AsyncDataStatus,
  statusToCheck: AsyncDataStatus
): boolean {
  return previousStatus !== statusToCheck && nextStatus === statusToCheck;
}
