import React, { ChangeEventHandler, FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { Field, useFormState } from 'react-final-form';
import {
  PartialForm,
  RadioContainer,
} from '../../shared/UnderwritingPartialForm';
import {
  ProcessorNames,
  SubmitApplicationFieldsValues,
} from './SubmitApplicationForm.types';
import { RadioLabel } from '../../shared/UnderwritingPartialForm';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { Icon, RadioButton } from '@fattmerchantorg/truffle-components';
import { Onboarding } from '@fattmerchantorg/types-engine/DB';
import {
  Portfolio,
  hasApprovedAppsOnboarding,
} from '../../../../../util/catan.util';
import { DB } from '@fattmerchantorg/types-engine';

const SubPageHeader = styled.span`
  display: block;
  font-size: 14px;
  margin: 0 0 8px;
`;

interface ProcessorSelectionProps {
  onChangeProcessor?: ChangeEventHandler<HTMLInputElement>;
  processors?: (keyof typeof ProcessorNames)[];
  portfolio?: Portfolio;
  onboardings?: Onboarding[];
  voidView?: boolean;
  novoidView?: boolean;
  setVoidValue?: ChangeEventHandler<HTMLInputElement>;
}
const BypassVoidWarning = () => (
  <StyledBypassVoidWarning>
    <ExclamationCircleIcon icon={faExclamationCircle} />
    <StyledBypassVoidWarningText>
      You've already submitted an application to this processor.
    </StyledBypassVoidWarningText>
  </StyledBypassVoidWarning>
);

const DisabledSubmitError = () => (
  <StyledDisabledApprovedError>
    <TimesCircleIcon icon={faTimesCircle} />
    <StyledApprovedErrorText>
      You cannot resubmit this application because it has already been approved.
    </StyledApprovedErrorText>
  </StyledDisabledApprovedError>
);

const DisableAchSubmitWithPendingCC = () => (
  <StyledDisabledApprovedError>
    <TimesCircleIcon icon={faTimesCircle} />
    <StyledApprovedErrorText>
      You cannot submit APPS ACH because you have an APPS application currently
      processing
    </StyledApprovedErrorText>
  </StyledDisabledApprovedError>
);

const BypassNoVoidWarning = () => (
  <StyledBypassVoidWarning>
    <ExclamationCircleIcon icon={faExclamationCircle} />
    <StyledBypassVoidWarningText>
      You've already submitted an application to this processor. Pressing the
      submit button will submit a new application.
    </StyledBypassVoidWarningText>
  </StyledBypassVoidWarning>
);

const StyledBypassVoidWarning = styled.div`
  background-color: #f8dc3d;
  padding: 8px 16px;
  margin-left: -15px;
  margin-right: -15px;
  font-weight: bold;
`;
const StyledDisabledApprovedError = styled.div`
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 16px;
  margin-left: -15px;
  margin-right: -15px;

  height: 56px;

  /* Status Colors/Dark Red-500 */

  background: #ff4646;

  /* Inside auto layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
`;

const ExclamationCircleIcon = styled(Icon)`
  color: ${({ theme }) => theme.black};
  margin-right: 8px;
  height: 13px;
  font-weight: bold;
`;

const TimesCircleIcon = styled(Icon)`
  /* icon */

  width: 16px;
  height: 18px;

  /* Icons/Outline */

  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  text-align: center;

  /* Gray/0 */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

const NoBorderRadioLabel = styled(RadioLabel)`
  border: 0px;
  flex-wrap: wrap;
  > span {
    label {
      margin-right: 5px;
      input {
        display: none;
      }
    }
  }
`;

const StyledBypassVoidWarningText = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.black};
`;
const StyledApprovedErrorText = styled.span`
  /* text */

  width: 416px;
  height: 40px;

  /* Body/Body 2-Bold */

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  /* Gray/0 */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;
`;

const StyledProcessorDescription = styled.div`
  display: flex;
  flex-basis: 100%;
  padding-left: 20px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.core.gray[400].hex};
`;

export const ProcessorSelection: FunctionComponent<
  ProcessorSelectionProps
> = props => {
  const {
    processors,
    onChangeProcessor,
    portfolio,
    voidView,
    novoidView,
    onboardings,
  } = props;
  const [isApproved, setIsApproved] = useState(false);
  const [isInvalidAchSubmission, setIsInvalidAchSubmission] = useState(false);
  const { values } = useFormState<SubmitApplicationFieldsValues>();

  const isEnabled = (
    processor: SubmitApplicationFieldsValues['processor']
  ): boolean => {
    return true;
  };
  function onCheckApproval(selectedProcessor) {
    const selection =
      onboardings.filter(
        o => o.state === 'APPROVED' && o.processor_name === selectedProcessor
      ).length > 0;
    if (selection) {
      setIsApproved(true);
    } else {
      setIsApproved(false);
    }
  }
  function onValidateAchSubmission(selectedProcessor) {
    if (selectedProcessor !== 'APPS_ACH') {
      setIsInvalidAchSubmission(false);
      return;
    }
    const ccApplication =
      onboardings.filter(
        o =>
          (
            [
              'SUBMITTED',
              'SUBMITTED.FAILED',
              'PENDED',
              'PENDED.FAILED',
            ] as DB.Onboarding['state'][]
          ).includes(o.state) && o.processor_name === 'APPS'
      ).length > 0;
    if (ccApplication) {
      setIsInvalidAchSubmission(true);
    } else {
      setIsInvalidAchSubmission(false);
    }
  }
  return (
    <PartialForm data-testid="uw-status-submit-processor-selection">
      <SubPageHeader>Select a Processor</SubPageHeader>
      <>
        {processors?.map(processorKey => {
          return (
            <NoBorderRadioLabel
              key={processorKey}
              data-selected={values.processor === processorKey}
              data-testid={`uw-status-submit-processor-label-${processorKey}`}
            >
              <Field
                name="processor"
                id={processorKey}
                data-testid={processorKey}
                type="radio"
                component="input"
                value={processorKey}
              >
                {props => {
                  return (
                    <RadioContainer>
                      <RadioButton
                        {...props.input}
                        onChange={e => {
                          props.input.onChange(e);
                          onChangeProcessor(e);
                          onCheckApproval(e.target.value);
                          onValidateAchSubmission(e.target.value);
                        }}
                        disabled={!isEnabled(processorKey)}
                      />
                    </RadioContainer>
                  );
                }}
              </Field>
              <span>{getDisplayName(processorKey)}</span>
              {displayProcessorDescription(processorKey)}
            </NoBorderRadioLabel>
          );
        })}
      </>

      {novoidView && (
        <div>
          <BypassNoVoidWarning />
        </div>
      )}

      {voidView && (
        <div>
          <BypassVoidWarning />

          <NoBorderRadioLabel
            data-selected={values.forceNewApplication === 'update'}
          >
            <Field
              name="forceNewApplication"
              type="radio"
              component="input"
              value="update"
            >
              {props => {
                return (
                  <RadioContainer>
                    <RadioButton
                      {...props.input}
                      onChange={e => {
                        props.input.onChange(e);
                      }}
                    />
                  </RadioContainer>
                );
              }}
            </Field>
            <span>Update Original Application</span>
          </NoBorderRadioLabel>

          <NoBorderRadioLabel
            data-selected={values.forceNewApplication === 'void'}
          >
            <Field
              name="forceNewApplication"
              type="radio"
              component="input"
              value="void"
            >
              {props => {
                return (
                  <RadioContainer>
                    <RadioButton
                      {...props.input}
                      onChange={e => {
                        props.input.onChange(e);
                      }}
                    />
                  </RadioContainer>
                );
              }}
            </Field>
            <span>Void Original & Submit a New Application</span>
          </NoBorderRadioLabel>
        </div>
      )}
      {isApproved && (
        <div>
          <DisabledSubmitError />

          <span></span>
        </div>
      )}
      {isInvalidAchSubmission && (
        <div>
          <DisableAchSubmitWithPendingCC />

          <span></span>
        </div>
      )}
    </PartialForm>
  );

  function getDisplayName(processorId: keyof typeof ProcessorNames) {
    let name = ProcessorNames[processorId] ?? processorId;
    const processor = portfolio.processors.find(
      p => p.processor_name === processorId
    );
    if (processor?.processor_external_account_id) {
      name += ` (${processor.processor_external_account_id})`;
    }
    return name;
  }

  function displayProcessorDescription(
    processorId: keyof typeof ProcessorNames
  ): React.ReactNode {
    let text: string | null = null;
    if (processorId === 'APPS') {
      text =
        'Board merchant for credit card. ACH attempted if volume is sufficient.';
    } else if (processorId === 'APPS_ACH') {
      const isAchOnly = !hasApprovedAppsOnboarding(onboardings);
      text = `Board merchant for ACH${isAchOnly ? ' only' : ''}`;
    }
    if (text) {
      return <StyledProcessorDescription>{text}</StyledProcessorDescription>;
    }
    return null;
  }
};
