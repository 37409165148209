import React, { FC, Dispatch, useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import { FieldError, Form, Group, Field, ResponsivePadding } from '../shared';
import { catanapi } from '../../api';
import { useAsyncEffect, useAuthToken, useToaster } from '../../hooks';
import {
  sendCloseSelected,
  Action as ModalAction,
  Status as ModalStatus,
  ModalState,
} from '../../hooks/useModalReducer';
import { SelectedMerchantStore } from '../../context';

import { StaxButton } from '../shared/Stax/Button';
import {
  BaseModal,
  ModalHeader,
  Select,
} from '@fattmerchantorg/truffle-components';
import { FieldWrap } from '../settings/SettingsPagesStylings';
import { getCompanyOnboardings } from '../../util/catan.util';

interface TerminalFormValues {
  terminal: string | null;
  label: string | null;
}

export type EditModalContext = TerminalFormValues;

/** Initial state for `useModalReducer`. */
export const initialEditModalState: ModalState<EditModalContext> = {
  status: ModalStatus.CLOSED,
  context: { terminal: null, label: 'Dejavoo' },
};
const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
  button {
    margin: 0 0 0 10px;
  }
`;
const closeSelected = sendCloseSelected();

export const AddTerminalModal: FC<{
  terminal?: string;
  status: ModalStatus;
  onTerminalCreated?: () => void;
  modalDispatch: Dispatch<ModalAction>;
}> = ({ terminal, status, onTerminalCreated, modalDispatch }) => {
  const authToken = useAuthToken();
  const { toaster, toast } = useToaster();
  const {
    state: { merchantId },
  } = useContext(SelectedMerchantStore);
  const [selected, setSelected] = useState();
  const { state } = useContext(SelectedMerchantStore);
  const engineCompanyId = state?.registration?.external_company_id;
  const onClose = useCallback(
    () => modalDispatch(closeSelected),
    [modalDispatch]
  );
  useAsyncEffect(async () => {}, [merchantId, engineCompanyId]);
  const onSubmit = useCallback(
    async formValues => {
      try {
        const onboardings = await getCompanyOnboardings(
          authToken,
          engineCompanyId,
          { state: ['APPROVED'], processorName: ['APPS'] }
        );
        const onboardingId = onboardings.data[0].onboarding_id;
        await catanapi.post(
          authToken,
          `/companies/${engineCompanyId}/terminals?onboardingId=${onboardingId}`,
          {
            label: formValues.label,
          }
        );
        toaster(toast.success(`Successfully created a new terminal`));
        modalDispatch(closeSelected);
        if (typeof onTerminalCreated === 'function') {
          onTerminalCreated();
        }
      } catch (error) {
        if (error.errors?.[0]?.error) {
          toaster(toast.error(error.errors[0].error));
        } else {
          toaster(toast.error('Cound Not Create Terminal'));
        }

        modalDispatch(closeSelected);
      }
    },
    [
      authToken,
      engineCompanyId,
      toaster,
      toast,
      modalDispatch,
      onTerminalCreated,
    ]
  );
  return (
    <BaseModal
      data-testid="edit-merchant-termial-modal"
      isOpen={status === ModalStatus.OPEN}
      onClose={onClose}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => onClose()}
      title={<strong>Add Terminal</strong>}
      style={{
        content: {
          width: '480px',

          gridTemplate: `"header" 48px "content" 1fr`,
        },
      }}
    >
      <ModalHeader title="Add Terminal" onClose={onClose} />
      <Form<TerminalFormValues>
        onSubmit={onSubmit}
        initialValues={{ terminal, label: 'Dejavoo' }}
      >
        {formProps => (
          <ResponsivePadding desktop="2.5%" mobile="1rem">
            <Group direction="column">
              <FieldWrap style={{ maxWidth: '199px', paddingBottom: '16px' }}>
                <Field name="label" placeholder="terminal">
                  {props => (
                    <Select
                      options={[
                        {
                          label: 'Authorize.net',
                          value: 'Authorize.net',
                        },
                        {
                          label: 'Dejavoo',
                          value: 'Dejavoo',
                        },
                        {
                          label: 'NMI',
                          value: 'NMI',
                        },
                        {
                          label: 'Ingenico Desk',
                          value: 'Ingenico Desk',
                        },
                        {
                          label: 'TSYS Generic VAR',
                          value: 'TSYS Generic VAR',
                        },
                        {
                          label: 'Swipe Simple',
                          value: 'Swipe Simple',
                        },
                      ]}
                      label="Terminal Type"
                      required
                      onChange={el => {
                        props.input.onChange(el);
                        setSelected(el);
                      }}
                    />
                  )}
                </Field>
              </FieldWrap>
              <FieldError name="terminal" />
            </Group>
            <ButtonRow>
              <Group justify="flex-end" space="16px">
                <StaxButton
                  type="button"
                  rank="secondary"
                  id="save-terminal-submit-button"
                  data-testid="save-terminal-submit-button"
                  onClick={onClose}
                >
                  Cancel
                </StaxButton>
                <StaxButton
                  type="submit"
                  id="save-terminal-submit-button"
                  data-testid="save-terminal-submit-button"
                  loading={formProps.submitting}
                  disabled={formProps.submitting || !selected}
                >
                  <b>Create Terminal</b>
                </StaxButton>
              </Group>
            </ButtonRow>
          </ResponsivePadding>
        )}
      </Form>
    </BaseModal>
  );
};
