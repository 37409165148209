import React, { FunctionComponent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { PageHeaderButton } from '../../shared';

export const ImportAddNewButton: FunctionComponent = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  return (
    <PageHeaderButton
      type="submit"
      icon="fas fa-plus"
      id="create-new-merchant-button"
      data-testid="create-new-merchant-button"
      motif="primary"
      onClick={() => history.push(`${pathname}/new/upload`)}
    >
      <span>Import New</span>
    </PageHeaderButton>
  );
};
