import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { Select, Tooltip } from '@fattmerchantorg/truffle-components';
import { FileRecord } from '@fattmerchantorg/types-omni';
import { useAsyncEffect } from '../../../../hooks';
import { documentTypes } from '../../../../util/registration.util';
import { AsyncIcon } from '../shared/AsyncIcon';
import { createFileDisplayName } from '../../../../util/file.util';
import { format } from 'date-fns';

const documentTypeOptions = documentTypes.map(doc => {
  return {
    label: `${doc.key} - ${doc.label}`,
    value: doc.key.toString(),
  };
});

interface FileRowProps {
  key: string | number;
  file: FileRecord;
  onDelete?: (file: FileRecord) => Promise<any> | any;
  onDownload?: (file: FileRecord) => Promise<any> | any;
  onPreview?: (file: FileRecord) => Promise<any> | any;
  onChangeFileOnboardingMagType?: (
    file: FileRecord,
    type: string,
    label: string
  ) => Promise<any> | any;
}

const ButtonReset = styled.button`
  background: none;
  padding: 0;
  margin: 0;
  border: 0;
`;

const LinkButton = styled(ButtonReset)`
  display: inline-flex;
  color: ${({ theme }) => theme.colors.status.blue[500].hex};
  text-align: left;
  text-decoration: underline;
`;

const FileButton = styled(ButtonReset)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  padding: 0;
  margin: 0;
  border: 0;
  width: 26px;
  height: 26px;

  i {
    color: ${({ theme }) => theme.white};
  }
`;

const PreviewIcon = styled(AsyncIcon)`
  color: ${({ theme }) => theme.white};
`;
const DownloadIcon = styled(AsyncIcon)`
  color: ${({ theme }) => theme.colors.status.blue[500].hex};
`;
const DeleteIcon = styled(AsyncIcon)`
  color: ${({ theme }) => theme.destructiveColor};
`;

const StyledFileRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 120px minmax(240px, 400px) 26px 26px 26px;
  grid-column-gap: 16px;
  width: 100%;
  align-items: center;
`;

const SpaceHolder = styled.div`
  width: 18px;
  height: 18px;
`;

const TypeChanger = styled.div`
  display: grid;
  grid-template-columns: 18px 1fr;
  grid-column-gap: 8px;
  align-items: center;
`;

export const FileRow: FunctionComponent<FileRowProps> = props => {
  const {
    file,
    onDownload,
    onDelete,
    onPreview,
    onChangeFileOnboardingMagType,
  } = props;
  const { id, name, meta, created_at } = file;
  const [state, setState] = useState<
    'idle' | 'deleting' | 'downloading' | 'previewing' | 'changingType'
  >('idle');

  useAsyncEffect(
    async isMounted => {
      switch (state) {
        case 'downloading': {
          const p = onDownload(file);
          if (p instanceof Promise) await p;
          break;
        }
        case 'deleting': {
          const p = onDelete(file);
          if (p instanceof Promise) await p;
          break;
        }
        case 'previewing': {
          const p = onPreview(file);
          if (p instanceof Promise) await p;
          break;
        }
        case 'idle':
          break;
      }

      if (isMounted() && state !== 'idle') {
        setState('idle');
      }
    },
    [state]
  );

  const handleChangeTypeOption = async option => {
    setState('changingType');
    const labelArray = option?.label?.split(' - ') ?? [];
    const label =
      labelArray.length > 1 ? `${labelArray[1].replace(/\s/g, '_')}__` : '';
    const value = option?.value || '';
    await onChangeFileOnboardingMagType(file, value, label);
    setState('idle');
  };

  return (
    <StyledFileRow key={id}>
      {format(new Date(created_at), 'MM/dd/yyyy | h:mm aa')}
      <LinkButton onClick={() => setState('previewing')}>
        {createFileDisplayName(name, created_at)}
      </LinkButton>
      <span>{meta?.filesize}</span>
      <TypeChanger>
        <SpaceHolder>
          {state === 'changingType' ? (
            <i className="fas fa-circle-notch fa-spin"></i>
          ) : null}
        </SpaceHolder>
        <Select
          onChange={handleChangeTypeOption}
          options={documentTypeOptions}
          value={documentTypeOptions.find(
            option => option.value === meta?.onboardingMagType
          )}
          isDisabled={state === 'changingType'}
          placeholder="Select an option"
        />
      </TypeChanger>
      <Tooltip content="Preview">
        <FileButton onClick={() => setState('previewing')}>
          <PreviewIcon icon={['fas', 'eye']} loading={state === 'previewing'} />
        </FileButton>
      </Tooltip>
      <Tooltip content="Download">
        <FileButton
          onClick={() => setState('downloading')}
          disabled={state === 'downloading'}
        >
          <DownloadIcon
            icon={['fas', 'download']}
            loading={state === 'downloading'}
          />
        </FileButton>
      </Tooltip>
      <Tooltip content="Delete">
        <FileButton
          onClick={() => setState('deleting')}
          disabled={state === 'deleting'}
        >
          <DeleteIcon icon={['fas', 'trash']} loading={state === 'deleting'} />
        </FileButton>
      </Tooltip>
    </StyledFileRow>
  );
};
