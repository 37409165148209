import React, { FunctionComponent, useMemo } from 'react';
import {
  TextField,
  Select,
  TextArea,
} from '@fattmerchantorg/truffle-components';
import { Field } from '../../../shared/Field';
import {
  FormRow,
  PartialForm,
  TwoColumn,
} from '../shared/UnderwritingPartialForm';
import { companyTypes } from '../../../../util/registration.util';
import { FormRenderProps } from 'react-final-form';
import {
  formatPhoneNumber,
  formatFederalTaxId,
  formatDate,
} from '../../../../util/format.util';

interface BusinessInfoFieldsProps {
  formProps?: FormRenderProps;
}

export const BusinessInfoFields: FunctionComponent<
  BusinessInfoFieldsProps
> = props => {
  const { formProps } = props;
  const companyTypeOptions = useMemo(() => {
    return companyTypes.map(type => {
      return { label: type.description, value: type.key };
    });
  }, []);
  const entityOwnershipOptions = [
    {
      label: 'Private',
      value: 'PRIVATE',
    },
    {
      label: 'Public',
      value: 'PUBLIC',
    },
  ];

  const currencyOptions = ['USD', 'CAD'].map(value => ({
    label: value,
    value,
  }));

  return (
    <PartialForm>
      <FormRow>
        <Field name="business_legal_name">
          {props => (
            <TextField {...props.input} type="text" label="Legal Name" />
          )}
        </Field>
      </FormRow>
      <FormRow>
        <Field name="business_dba">
          {props => <TextField {...props.input} type="text" label="DBA" />}
        </Field>
      </FormRow>
      <FormRow>
        <label>Company Type</label>
        <Field
          name="company_type"
          format={value => {
            return companyTypeOptions.find(option => option.value === value);
          }}
          clearable={false}
        >
          {props => {
            return (
              <div data-testid="company_type">
                <Select
                  {...props.input}
                  onChange={({ value }) => {
                    props.input.onChange(value);
                    /**
                     * PHO-1697
                     *
                     * Default entity_ownership_type to PUBLIC if Company Type is:
                     * Government (Federal/State/Local) (G)
                     * Tax-Exempt Organization (501C) (E)
                     *
                     * ELSE default entity_ownership_type to PRIVATE
                     */
                    formProps.form.mutators.setFormValues({
                      entity_ownership_type: ['G', 'E'].includes(value)
                        ? 'PUBLIC'
                        : 'PRIVATE',
                    });
                  }}
                  options={companyTypeOptions}
                />
              </div>
            );
          }}
        </Field>
      </FormRow>
      <FormRow>
        <label>Entity Ownership Type</label>
        <Field
          name="entity_ownership_type"
          htmlFor="testInput"
          format={value => {
            return entityOwnershipOptions.find(
              option => option.value === value
            );
          }}
          clearable={false}
        >
          {props => {
            return (
              <div data-testid="entity_ownership_type">
                <Select {...props.input} options={entityOwnershipOptions} />
              </div>
            );
          }}
        </Field>
      </FormRow>
      <FormRow>
        <Field name="business_tax_id" format={formatFederalTaxId}>
          {props => <TextField {...props.input} type="text" label="Tax ID" />}
        </Field>
      </FormRow>
      <FormRow>
        <TwoColumn>
          <div>
            <Field name="business_phone_number" format={formatPhoneNumber}>
              {props => (
                <TextField
                  {...props.input}
                  type="text"
                  label="Phone Number"
                  error={!!formProps.errors?.business_phone_number}
                  helperText={formProps.errors?.business_phone_number}
                />
              )}
            </Field>
          </div>
          <div>
            <Field name="business_fax" format={formatPhoneNumber}>
              {props => (
                <TextField
                  {...props.input}
                  type="text"
                  label="Fax Number"
                  error={!!props.meta.error}
                  helperText={props.meta.error}
                />
              )}
            </Field>
          </div>
        </TwoColumn>
      </FormRow>
      <FormRow>
        <TwoColumn>
          <div>
            <Field name="business_email">
              {props => (
                <TextField
                  {...props.input}
                  type="email"
                  label="Email"
                  error={!!props.meta.error}
                  helperText={props.meta.error}
                />
              )}
            </Field>
          </div>
          <div>
            <Field name="business_website">
              {props => (
                <TextField
                  {...props.input}
                  type="text"
                  label="Website"
                  error={!!props.meta.error}
                  helperText={props.meta.error}
                />
              )}
            </Field>
          </div>
        </TwoColumn>
      </FormRow>
      <FormRow>
        <Field name="business_open_date">
          {props => (
            <TextField
              {...props.input}
              type="text"
              label="Business Incorporation Date"
              onChange={e =>
                props.input.onChange(
                  e.target.value ? formatDate(e.target.value) : null
                )
              }
            />
          )}
        </Field>
      </FormRow>
      <FormRow>
        <Field name="service_you_provide">
          {props => (
            <TextArea {...props.input} type="text" label="Provided Service" />
          )}
        </Field>
      </FormRow>
      <FormRow>
        <Field
          name="merchant.currency"
          label="Currency"
          clearable={false}
          format={value =>
            currencyOptions.find(option => option.value === value)
          }
        >
          {props => (
            <div data-testid="currency">
              <Select {...props.input} options={currencyOptions} />
            </div>
          )}
        </Field>
      </FormRow>
    </PartialForm>
  );
};
