import React, { useContext, useMemo } from 'react';
import { Media, UncontrolledTooltip } from 'reactstrap';
import styled, { createGlobalStyle } from 'styled-components';
import { usePermissions } from '../../../hooks';
import { Merchant } from '@fattmerchantorg/types-omni';
import {
  formatCapitalCase,
  formatReadableDate,
} from '../../../util/format.util';
import { Id, ColumnProps } from '../../shared';
import { SelectedMerchantStore } from '../../../context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { RISK_HOLD_TOOLTIP_TEXT } from '../../../util';
import { AssumeLink } from '../AssumeLink';

const Logo = styled.img`
  max-width: 120px;
  max-height: 49px;
  margin-right: 10px;
`;

const CompanyNameMedia = styled(Media)`
  display: flex;
  align-items: center;
  height: 49px;
`;

const RiskHoldIcon = styled(FontAwesomeIcon)`
  width: 18px;
  height: 18px;
  color: ${({ theme }) => theme.colors.status.yellow[500].hex};
  margin-left: 0.5rem;
`;

const RiskHoldTooltip = styled(UncontrolledTooltip)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;

  a {
    color: inherit;
    text-decoration: none;
  }

  .tooltip-inner {
    background-color: #404750;
    color: #ffffff;
  }
`;

const GlobalStyle = createGlobalStyle<any>`
  .card {
    background: ${({ theme }) => theme.colors.core.gray['800']};
  }

  .bs-tooltip-bottom {
    .arrow {
      &::before {
        border-bottom-color: #404750 !important;
      }
    }
  }

  .bs-tooltip-top {
    .arrow {
      &::before {
        border-top-color: #404750 !important;
      }
    }
  }

  .bs-tooltip-left {
    .arrow {
      &::before {
        border-left-color: #404750 !important;
      }
    }
  }

  .bs-tooltip-right {
    .arrow {
      &::before {
        border-right-color: #404750 !important;
      }
    }
  }
`;

export const useMerchantsTableColumns = () => {
  const { permit, associatedBrands } = usePermissions();
  const canSeeAllBrands = permit('godview', 'canSeeAllBrands', 'read');
  const isSingleBrandUser = associatedBrands.length === 1;
  const canSeeRiskHold =
    permit('godview', 'riskHold', 'write') ||
    permit('godview', 'riskHold', 'read');
  const {
    state: { selectedBrandSwitcherOption },
  } = useContext(SelectedMerchantStore);
  const isAllBrandsSelected = selectedBrandSwitcherOption?.includes(',');

  return useMemo(() => {
    const columns: ColumnProps<Merchant>[] = [];

    /* Push the static columns that are visible to all users */
    columns.push(
      {
        id: 'pmid',
        disableSortBy: true,
        accessor: 'id',
        Header: 'PMID',
        Cell: cell => <Id id={cell.value} />,
      },
      {
        id: 'company_name',
        accessor: 'company_name',
        Header: 'Company',
        Cell: cell => {
          const merchant = cell.row.original;
          return (
            <CompanyNameMedia>
              {merchant.branding && merchant.branding.public_url && (
                <Logo alt="..." src={merchant.branding.public_url} />
              )}
              <Media>
                <strong>{merchant.company_name}</strong>
              </Media>
            </CompanyNameMedia>
          );
        },
      },
      {
        id: 'status',
        disableSortBy: true,
        accessor: 'status',
        Header: () => <>Account Status</>,
        Cell: cell => {
          const merchant = cell.row.original;
          return (
            <>
              {formatCapitalCase(merchant.status)}
              {canSeeRiskHold && merchant.risk_hold && (
                <>
                  <RiskHoldIcon
                    id={`risk-hold-merchant-${merchant.id}`}
                    icon={faExclamationTriangle as IconProp}
                  />
                  <GlobalStyle />
                  <RiskHoldTooltip
                    delay={0}
                    target={`risk-hold-merchant-${merchant.id}`}
                  >
                    {RISK_HOLD_TOOLTIP_TEXT}
                  </RiskHoldTooltip>
                </>
              )}
            </>
          );
        },
      },
      {
        id: 'underwriting_status',
        disableSortBy: true,
        accessor: 'underwriting_status',
        Header: 'Underwriting Status',
        Cell: cell => {
          const merchant = cell.row.original;
          switch (merchant.underwriting_status) {
            case 'APPROVED':
              if (merchant.underwriting_substatuses?.length > 0) {
                return 'Conditionally Approved';
              }
              return 'Fully Approved';
            default:
              return formatCapitalCase(merchant.underwriting_status);
          }
        },
      },

      {
        id: 'created_at',
        accessor: 'created_at',
        Header: 'Created',
        Cell: cell => formatReadableDate(cell.value),
      }
    );

    /* Dynamically push the columns are are dependent on permissions, URL, etc. */

    /**
     * Toggle `brand` column based on `Brand Switcher` selection.
     */
    if ((canSeeAllBrands && isSingleBrandUser) || isAllBrandsSelected) {
      columns.push({
        id: 'brand',
        disableSortBy: true,
        accessor: 'brand',
        Header: 'Brand',
        Cell: cell => formatCapitalCase(cell.value),
      });
    }

    if (permit('godview', 'plan', 'read')) {
      columns.push({
        id: 'plan',
        disableSortBy: true,
        accessor: 'plan',
        Header: 'Plan',
        Cell: cell =>
          formatCapitalCase(cell.row.original?.plan?.name || 'none'),
      });
    }

    if (permit('godview', 'processor', 'read')) {
      columns.push({
        id: 'processor',
        accessor: 'processor',
        Header: 'Processor',
        Cell: cell => formatCapitalCase(cell.value),
      });
    }

    if (permit('godview', 'partnershipType', 'read')) {
      columns.push({
        id: 'is_payfac',
        accessor: 'is_payfac',
        Header: 'Relationship',
        Cell: cell => (cell.value ? 'Payfac' : 'ISO'),
      });
    }

    if (permit('godview', 'assumeSubmerchants', 'read')) {
      columns.push({
        id: 'assume',
        disableSortBy: true,
        Header: '',
        visible: 'hover',
        style: { width: '36px', textAlign: 'right' },
        Cell: cell => <AssumeLink merchant={cell.row.original} />,
      });
    }

    return columns;
  }, [
    permit,
    canSeeRiskHold,
    canSeeAllBrands,
    isSingleBrandUser,
    isAllBrandsSelected,
  ]);
};
