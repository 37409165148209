import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  Gateway,
  DetailedFeeStatement,
  DetailedSettlementStatement,
} from '@fattmerchantorg/types-omni';

export type Summary = {
  sum: number;
  sum_formatted: string;
  average: number;
  average_formatted: string;
  count: number;
  min: number;
  min_formatted: string;
  max: number;
  max_formatted: string;
  max_count: number;
  min_count: number;
  row_type: string;
};

export type DateTransactionDatum = {
  date: string;
  gross_sales_count: number;
  gross_sales_total: number;
  fees_count: number;
  fees_total: number;
  adjustments_count: number;
  adjustments_total: number;
  chargebacks_count: number;
  chargebacks_total: number;
  net_sales_count: number;
  net_sales_total: number;
  surcharge_total: number;
  displayed_total: number;
};

export type CardTransactionDatum = {
  card_type: string;
  basis_points_fees_total: number;
  gross_sales_count: number;
  gross_sales_total: number;
  gross_sale_average: number;
  fixed_fees_total: number;
  interchange_fees_total: number;
  fees_total: number;
  card_app_fees_total: number;
  adjustments_count: number;
  adjustments_total: number;
  chargebacks_count: number;
  chargebacks_total: number;
  rate: string | number;
  surcharge_total: number;
  surcharge_count: number;
};

export type FeeDatum = {
  method_type?: string;
  sum: number;
  sum_formatted: string;
  average: number;
  average_formatted: string;
  count: number;
  min: number;
  min_formatted: string;
  max: number;
  max_formatted: string;
  type: string;
  name: null;
  method: string;
};

export type PayoutConfiguration = Gateway['meta']['payout_config'];
export type PayoutConfigurationPayoutType = PayoutConfiguration['payout_type'];

/**
 * Custom fees in Finix are given the type of "CUSTOM" before they reach our system.
 * The term "CUSTOM" isn't helpful for our users so we rename "CUSTOM" to "PCI NON-COMPLIANCE"
 * as that is the only custom fee type we support, for now. We will likely support additional
 * or different custom fees, at which point we will need to add more cases to this function.
 *
 * @see https://fattmerchant.atlassian.net/browse/KRK-1382
 */
export const getFeeTypeNiceName = (type: string) => {
  switch (type) {
    case 'CUSTOM':
      return 'PCI NON-COMPLIANCE';
    default:
      return type.replace(/_/g, ' ').toUpperCase();
  }
};

export const createFillerRows = (numberOfColumns, numberOfRows = 5) => {
  return Array(numberOfRows)
    .fill(null)
    .map((_, i) => (
      <tr key={i}>
        {Array(numberOfColumns)
          .fill(null)
          .map((_, i) => (
            <td key={i}>
              <Skeleton height={10} width={50} />
            </td>
          ))}
      </tr>
    ));
};

export const createEmptyStatement = (): DetailedFeeStatement & {
  ready: boolean;
} => {
  return {
    ready: false,
    summary: {
      flatRateFees: 0,
      interchangeFees: 0,
      cardBrandAssessments: 0,
      cardProcessingFees: 0,

      achApplicationFees: 0,
      cardApplicationFees: 0,
      otherApplicationFees: 0,

      otherFees: 0,

      totalNetDeposits: 0,
      totalFees: 0,
      effectiveRate: 0,
    },
    allFees: {
      data: [],
      summary: {} as Partial<
        DetailedFeeStatement['allFees']['summary']
      > as DetailedFeeStatement['allFees']['summary'],
    },
    additionalFees: {
      data: [],
      summary: {} as Partial<
        DetailedFeeStatement['additionalFees']['summary']
      > as DetailedFeeStatement['additionalFees']['summary'],
    },
    dateTransactions: {
      data: [],
      summary: {} as Partial<
        DetailedFeeStatement['dateTransactions']['summary']
      > as DetailedFeeStatement['dateTransactions']['summary'],
    },
    cardTransactions: {
      data: [],
      summary: {} as Partial<
        DetailedFeeStatement['cardTransactions']['summary']
      > as DetailedFeeStatement['cardTransactions']['summary'],
    },
    cardPresentTransactions: {
      data: [],
      summary: {} as Partial<
        DetailedFeeStatement['cardPresentTransactions']['summary']
      > as DetailedFeeStatement['cardPresentTransactions']['summary'],
    },
    cardNotPresentTransactions: {
      data: [],
      summary: {} as Partial<
        DetailedFeeStatement['cardNotPresentTransactions']['summary']
      > as DetailedFeeStatement['cardNotPresentTransactions']['summary'],
    },
    statementMessages: [],
    payoutConfiguration: {},
  };
};

export const createEmptySettlementStatement =
  (): DetailedSettlementStatement & {
    ready: boolean;
  } => {
    const emptyStatement = createEmptyStatement();
    return {
      ...emptyStatement,
      summary: {
        ...emptyStatement.summary,
        achFees: 0,
        nextMonthTotalFees: 0,
      },
      dateSettlements: {
        data: [],
        summary:
          {} as DetailedSettlementStatement['dateSettlements']['summary'],
      },
      defaultBillingProfile: null,
    };
  };

// Check if array has duplicate items. In this case, different rates for the same card type.
export const checkForDifferentRates = (arr: any[]): boolean =>
  new Set(arr).size !== arr.length;
