import { omitBy } from '../../../util/object.util';
import { CollectionQuery } from './Collection.types';

/** Remove empty keywords, empty sorts, filters, etc from url */
export const cleanCollectionQuery = (query: CollectionQuery): CollectionQuery =>
  omitBy(
    query,
    v =>
      (Array.isArray(v) && v.length === 0) ||
      (typeof v === 'string' && v === '') ||
      v === null ||
      v === undefined
  );
