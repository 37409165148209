import React, { FunctionComponent, useContext } from 'react';
import { SelectedMerchantStore } from '../../context';
import { CTAPageHeader } from './components/CTAPageHeader';
import {
  SmallPrimaryButton,
  Icon,
  Dropdown,
  DropdownContent,
} from '@fattmerchantorg/truffle-components';
import { Link } from 'react-router-dom';
import { BillingProfileListing } from './listing/BillingProfileListing';
import styled from 'styled-components';

const ContextMenu = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    &:last-child {
      border-top: 1px solid ${({ theme }) => theme.lightNeutralOutline};
    }
  }

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 2px;

    &:hover {
      background: ${({ theme }) => theme.component.table.borderColor};
    }

    a,
    button {
      display: block;
      background: none;
      border: 0;
      margin: 0;
      padding: 8px 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      text-align: left;
      cursor: pointer;
      display: flex;
      color: ${({ theme }) => theme.colors.core.white[0].hex};

      span {
        margin-right: 8px;
        width: 16px;
      }

      &:disabled {
        opacity: 0.5;
      }
    }
  }
`;

interface BillingProfilesProps {}

export const ProfileTypeDropdown = () => {
  const { state } = useContext(SelectedMerchantStore);
  const { merchant } = state;

  if (!merchant) return null;

  const typeListItems = ['Transaction Profile', 'Recurring Fee'].map(
    (type, index) => {
      return (
        <li key={index}>
          <Link
            to={`/merchant/${merchant?.id}/billing-profiles/add?type=${type}`}
          >
            {type}
          </Link>
        </li>
      );
    }
  );

  return (
    <div style={{ position: 'relative' }}>
      <Dropdown
        trigger={(setRef, isOpen, setIsOpen) => (
          <div ref={setRef} onClick={() => setIsOpen(!isOpen)}>
            <SmallPrimaryButton>
              <Icon icon={['fas', 'plus']} />
              Add Billing Profile
            </SmallPrimaryButton>
          </div>
        )}
      >
        <DropdownContent style={{ minWidth: '122px', width: 'auto' }}>
          <ContextMenu>
            <ul>{typeListItems}</ul>
          </ContextMenu>
        </DropdownContent>
      </Dropdown>
    </div>
  );
};

export const BillingProfiles: FunctionComponent<
  BillingProfilesProps
> = props => {
  return (
    <React.Fragment>
      <CTAPageHeader
        headingText="Billing Profiles"
        ctaArea={[<ProfileTypeDropdown />]}
      />
      <BillingProfileListing />
    </React.Fragment>
  );
};
