import { TableTools } from '@fattmerchantorg/truffle-components';
import React from 'react';

import { ListingTable } from '../billing-profiles/listing/components/ListingTable';
import { LoadingSpan } from '../shared';

const LoadingAnimation: React.FC = () => (
  <LoadingSpan width="100%" height="24px" />
);

export const TerminalLoadingState: React.FC = () => {
  return (
    <div>
      <ListingTable
        header={[
          {
            content: 'Terminal Type',
            attributes: { style: { width: '272px' } },
          },
          {
            content: 'Terminal Number',
            attributes: { style: { width: '272px' } },
          },
          {
            content: 'V Number',
            attributes: { style: { width: '272px' } },
          },
        ]}
        rows={[
          [
            {
              content: <LoadingAnimation />,
              attributes: { style: { width: '272px' } },
            },
            {
              content: <LoadingAnimation />,
              attributes: { style: { width: '272px' } },
            },
            {
              content: <LoadingAnimation />,
              attributes: { style: { width: '272px' } },
            },
          ],
          [
            {
              content: <LoadingAnimation />,
              attributes: { style: { width: '272px' } },
            },
            {
              content: <LoadingAnimation />,
              attributes: { style: { width: '272px' } },
            },
            {
              content: <LoadingAnimation />,
              attributes: { style: { width: '272px' } },
            },
          ],
          [
            {
              content: <LoadingAnimation />,
              attributes: { style: { width: '272px' } },
            },
            {
              content: <LoadingAnimation />,
              attributes: { style: { width: '272px' } },
            },
            {
              content: <LoadingAnimation />,
              attributes: { style: { width: '272px' } },
            },
          ],
          [
            {
              content: <LoadingAnimation />,
              attributes: { style: { width: '272px' } },
            },
            {
              content: <LoadingAnimation />,
              attributes: { style: { width: '272px' } },
            },
            {
              content: <LoadingAnimation />,
              attributes: { style: { width: '272px' } },
            },
          ],
          [
            {
              content: <LoadingAnimation />,
              attributes: { style: { width: '272px' } },
            },
            {
              content: <LoadingAnimation />,
              attributes: { style: { width: '272px' } },
            },
            {
              content: <LoadingAnimation />,
              attributes: { style: { width: '272px' } },
            },
          ],
        ]}
      />
      <TableTools></TableTools>
    </div>
  );
};
