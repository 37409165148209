import React, { useContext, useState } from 'react';
import { Container } from 'reactstrap';
import {
  fetchSelectedMerchantBillingProfiles,
  SelectedMerchantStore,
  State,
} from '../../../context';
import { AsyncDataStatus } from '../../../@types';
import { Billing, PaymentMethod } from '@fattmerchantorg/types-engine/DB';
import { LoadingState } from './components/LoadingState';
import {
  BillingWithFeeDescription,
  getFeeValue,
  getFlattenedBillingType,
  getFlattenedTransactionProfileName,
  getPaymentMethodDisplayName,
  GroupedBillingProfiles,
  groupRecurringBillingProfiles,
  groupTransactionBillingProfiles,
} from '../util/billingProfile.util';
import { ListingTable } from './components/ListingTable';
import { formatCapitalCase, ordinal } from '../../../util';
import { OptionsContextMenu } from './components/OptionsContextMenu';
import styled from 'styled-components';
import {
  DeleteFormDataModal,
  initialDeleteModalState,
  DeleteModalContext,
} from '../components/DeleteFormDataModal';
import { catanapi } from '../../../api/catan';
import {
  ModalDispatch,
  useModalReducer,
  useToaster,
  useAuthToken,
} from '../../../hooks';
import { getMonthName } from '../recurring-fee/RecurringFee';

const ContentWrapper = styled.div`
  padding-bottom: 20px;
`;

type BillingProfileListingProps = {};
export const BillingProfileListing: React.FC = (
  props: BillingProfileListingProps
) => {
  const { dispatch, state } = useContext(SelectedMerchantStore);
  const { toaster, toast } = useToaster();
  const authToken = useAuthToken();
  const [billingCtx, setBillingCtx] = useState<Billing | null>(null);
  const [modalState, modalDispatch] = useModalReducer<DeleteModalContext>(
    initialDeleteModalState
  );

  async function onRemove() {
    if (!billingCtx) {
      return;
    }

    if (billingCtx.channel === 'ALL' && billingCtx.type === 'DISPUTE') {
      return;
    }
    try {
      if (billingCtx.type === 'ACHREJECT') {
        return;
      }
      await catanapi.delete(authToken, `/billing/${billingCtx.billing_id}`);
      dispatch(fetchSelectedMerchantBillingProfiles(billingCtx.company_id));
      toaster(
        billingCtx.type === 'RECURRING'
          ? toast.success(
              `${getFlattenedBillingType(
                billingCtx
              )} Profile successfully removed`,
              `Billing Profile Removed`
            )
          : toast.success(
              `${getFlattenedBillingType(
                billingCtx
              )} Profile ${getFlattenedTransactionProfileName(
                billingCtx
              )} successfully removed`,
              `Billing Profile Removed`
            )
      );
    } catch (e) {
      toaster(
        billingCtx.type === 'RECURRING'
          ? toast.error(
              `${getFlattenedBillingType(
                billingCtx
              )} Profile could not be removed`,
              `Billing Profile Not Removed`
            )
          : toast.error(
              `${getFlattenedBillingType(
                billingCtx
              )} Profile ${getFlattenedTransactionProfileName(
                billingCtx
              )} could not be removed`,
              `Billing Profile Not Removed`
            )
      );
    }
    setBillingCtx(null);
  }

  function getFeeDescription(billingCtx: Billing): string {
    const recurringWithFD: BillingWithFeeDescription =
      billingCtx as BillingWithFeeDescription;
    return recurringWithFD.fee_description;
  }

  return (
    <Container fluid>
      {
        <React.Fragment>
          {renderContent(state, modalDispatch, setBillingCtx)}
          {billingCtx ? (
            <DeleteFormDataModal
              status={modalState.status}
              title="Remove Billing Profile"
              primaryActionPreferred={true}
              message={
                <React.Fragment>
                  {billingCtx.type === 'RECURRING' ? (
                    <span>
                      You're about to remove a Recurring{' '}
                      <strong>
                        {getFeeDescription(billingCtx)} - are you sure you want
                        to remove this?
                      </strong>
                    </span>
                  ) : (
                    <span>
                      You're about to remove the{' '}
                      {getFlattenedBillingType(billingCtx)}
                      {' Profile '}
                      <strong>
                        {getFlattenedTransactionProfileName(billingCtx)} - are
                        you sure you want to remove this?
                      </strong>
                    </span>
                  )}
                </React.Fragment>
              }
              modalDispatch={modalDispatch}
              actionFunc={onRemove}
              cancelText="Cancel"
              actionText="Remove Billing Profile"
            />
          ) : (
            <React.Fragment />
          )}
        </React.Fragment>
      }
    </Container>
  );
};

function renderContent(
  merchantState: State,
  modalDispatch: ModalDispatch,
  setBillingCtx: React.Dispatch<Billing>
) {
  const { billingProfiles, paymentMethods, status } = merchantState;

  if (!billingProfiles) {
    return null;
  }

  const dataStatus = [billingProfiles.status, paymentMethods.status, status];

  if (dataStatus.every(i => i === AsyncDataStatus.IDLE)) {
    if (!Array.isArray(billingProfiles.data)) {
      return null;
    }
    return (
      <ContentWrapper>
        {renderTransactionFees(
          billingProfiles.data,
          paymentMethods.data,
          modalDispatch,
          setBillingCtx
        )}
        {renderRecurringFees(
          billingProfiles.data,
          paymentMethods.data,
          modalDispatch,
          setBillingCtx
        )}
        {renderDisputeFees(
          billingProfiles.data,
          paymentMethods.data,
          modalDispatch,
          setBillingCtx
        )}
        {renderACHRejectionFee(
          billingProfiles.data,
          paymentMethods.data,
          modalDispatch,
          setBillingCtx
        )}
      </ContentWrapper>
    );
  } else {
    return <LoadingState />;
  }
}

function renderTransactionFees(
  billingProfiles: Billing[],
  paymentMethods: PaymentMethod[],
  modalDispatch: ModalDispatch,
  setBillingCtx: React.Dispatch<Billing>
) {
  const data: GroupedBillingProfiles = groupTransactionBillingProfiles(
    billingProfiles.filter(b => ['TRANSACTION'].includes(b.type))
  );

  if (Object.keys(data).length) {
    const rows = Object.keys(data).map(group_name => {
      const billing = data[group_name].pop();
      return [
        {
          content: group_name,
          attributes: { style: { fontWeight: 'bold' } },
        },
        {
          content: getFeeValue(billing) === '' ? '$0.00' : getFeeValue(billing),
          attributes: { style: { textAlign: 'right' } },
        },
        getBillingCycleDisplay(billing),
        billing.is_gross_settlement === false ? 'Net' : 'Gross',
        getPaymentMethodDisplayName(
          paymentMethods.find(i => i.method_id === billing.fees_account_id)
        ),
        billing.is_interchange_passthru
          ? `On - ${billing.interchange_dues_assessments * 100}%`
          : 'Off',
        getPaymentMethodDisplayName(
          paymentMethods.find(i => i.method_id === billing.deposit_account_id)
        ),
        billing.funding_schedule_days
          ? `${billing.funding_schedule_days} Day${
              billing.funding_schedule_days > 1 ? 's' : ''
            }`
          : '--',
        {
          content: (
            <OptionsContextMenu
              billing={billing}
              modalDispatch={modalDispatch}
              setBillingCtx={setBillingCtx}
            />
          ),
          attributes: { style: { textAlign: 'right' } },
        },
      ];
    });

    return (
      <ListingTable
        title="Transaction Profiles"
        header={[
          {
            content: 'Payment Method',
            attributes: { style: { width: '121px' } },
          },
          {
            content: 'Transaction Fee',
            attributes: { style: { width: '156px', textAlign: 'right' } },
          },
          {
            content: 'Billing Cycle',
            attributes: { style: { width: '131px' } },
          },
          { content: 'Settlement', attributes: { style: { width: '78px' } } },
          { content: 'Default Fee', attributes: { style: { width: '142px' } } },
          { content: 'Passthrough', attributes: { style: { width: '95px' } } },
          {
            content: 'Default Deposit',
            attributes: { style: { width: '135px' } },
          },
          {
            content: 'Deposit Delay',
            attributes: { style: { width: '133px' } },
          },
          { content: null, attributes: { style: { width: '104px' } } },
        ]}
        rows={rows}
      />
    );
  }
  return null;
}

function renderDisputeFees(
  billingProfiles: Billing[],
  paymentMethods: PaymentMethod[],
  modalDispatch: ModalDispatch,
  setBillingCtx: React.Dispatch<Billing>
) {
  const data: GroupedBillingProfiles = groupTransactionBillingProfiles(
    billingProfiles.filter(b => ['DISPUTE'].includes(b.type))
  );

  const inquiryData = billingProfiles.filter(b => ['INQUIRY'].includes(b.type));

  if (Object.keys(data).length) {
    const rows = Object.keys(data).map(group_name => {
      const billing = data[group_name].pop();

      return [
        {
          content: group_name,
          attributes: { style: { fontWeight: 'bold' } },
        },
        {
          content:
            getFeeValue(inquiryData[0]) === ''
              ? '$0.00'
              : getFeeValue(inquiryData[0]),
          attributes: { style: { textAlign: 'right' } },
        },

        {
          content: getFeeValue(billing) === '' ? '$0.00' : getFeeValue(billing),
          attributes: { style: { textAlign: 'right' } },
        },
        {
          content: '$0.00',
          attributes: { style: { textAlign: 'right' } },
        },
        getBillingCycleDisplay(billing),
        billing.is_gross_settlement === false ? 'Net' : 'Gross',
        getPaymentMethodDisplayName(
          paymentMethods.find(i => i.method_id === billing.fees_account_id)
        ),
        getPaymentMethodDisplayName(
          paymentMethods.find(i => i.method_id === billing.deposit_account_id)
        ),

        {
          content: (
            <OptionsContextMenu
              billing={billing}
              modalDispatch={modalDispatch}
              setBillingCtx={setBillingCtx}
            />
          ),
          attributes: { style: { textAlign: 'right' } },
        },
      ];
    });

    return (
      <ListingTable
        title="Dispute Fees"
        header={[
          {
            content: 'Payment Method',
            attributes: { style: { width: '121px' } },
          },
          {
            content: 'Inquiry Fee',
            attributes: { style: { width: '145px', textAlign: 'right' } },
          },
          {
            content: 'Dispute Fee',
            attributes: { style: { width: '145px', textAlign: 'right' } },
          },
          {
            content: 'Pre-Arbitration Fee',
            attributes: { style: { width: '145px', textAlign: 'right' } },
          },
          {
            content: 'Billing Cycle',
            attributes: { style: { width: '217px' } },
          },
          { content: 'Settlement', attributes: { style: { width: '217px' } } },
          {
            content: 'Default Fee',
            attributes: { style: { width: '217px' } },
          },
          {
            content: 'Default Dispute',
            attributes: { style: { width: '217px' } },
          },
          { content: null, attributes: { style: { width: '104px' } } },
        ]}
        rows={rows}
      />
    );
  }
  return null;
}

function renderRecurringFees(
  billingProfiles: Billing[],
  paymentMethods: PaymentMethod[],
  modalDispatch: ModalDispatch,
  setBillingCtx: React.Dispatch<Billing>
) {
  const filtered: BillingWithFeeDescription[] = billingProfiles.filter(b =>
    ['RECURRING'].includes(b.type)
  ) as BillingWithFeeDescription[];
  const data: GroupedBillingProfiles = groupRecurringBillingProfiles(filtered);

  if (Object.keys(data).length) {
    const rows = Object.keys(data).map(group_name => {
      const billing = data[group_name].pop();
      return [
        {
          content: group_name,
          attributes: { style: { fontWeight: 'bold' } },
        },
        {
          content: getFeeValue(billing) === '' ? '$0.00' : getFeeValue(billing),
          attributes: { style: { textAlign: 'right' } },
        },
        getBillingCycleDisplay(billing),
        billing.is_gross_settlement === false ? 'Net' : 'Gross',
        getPaymentMethodDisplayName(
          paymentMethods.find(i => i.method_id === billing.fees_account_id)
        ),
        {
          content: (
            <OptionsContextMenu
              billing={billing}
              modalDispatch={modalDispatch}
              setBillingCtx={setBillingCtx}
            />
          ),
          attributes: { style: { textAlign: 'right' } },
        },
      ];
    });

    return (
      <ListingTable
        title="Recurring Fees"
        header={[
          { content: 'Fee Type', attributes: { style: { width: '256px' } } },
          {
            content: 'Amount',
            attributes: { style: { width: '168px', textAlign: 'right' } },
          },
          {
            content: 'Billing Cycle',
            attributes: { style: { width: '168px' } },
          },
          { content: 'Settlement', attributes: { style: { width: '168px' } } },
          { content: 'Default Fee', attributes: { style: { width: '168px' } } },
          { content: null, attributes: { style: { width: '104px' } } },
        ]}
        rows={rows}
      />
    );
  }
  return null;
}

function renderACHRejectionFee(
  billingProfiles: Billing[],
  paymentMethods: PaymentMethod[],
  modalDispatch: ModalDispatch,
  setBillingCtx: React.Dispatch<Billing>
) {
  let data: any = groupTransactionBillingProfiles(
    billingProfiles.filter(b => ['ACHREJECT'].includes(b.type))
  );
  if (Object.keys(data).length) {
    const rows = Object.keys(data).map(group_name => {
      const billing = data[group_name].pop();
      return [
        {
          content: 'ACH Reject Fee',
          attributes: { style: { fontWeight: 'bold' } },
        },
        {
          content: getFeeValue(billing) === '' ? '$0.00' : getFeeValue(billing),
          attributes: { style: { textAlign: 'right' } },
        },
        getBillingCycleDisplay(billing),
        billing.is_gross_settlement === false ? 'Net' : 'Gross',
        getPaymentMethodDisplayName(
          paymentMethods.find(i => i.method_id === billing.fees_account_id)
        ),
        getPaymentMethodDisplayName(
          paymentMethods.find(i => i.method_id === billing.deposit_account_id)
        ),
        {
          content: (
            <OptionsContextMenu
              billing={billing}
              modalDispatch={modalDispatch}
              setBillingCtx={setBillingCtx}
            />
          ),
          attributes: { style: { textAlign: 'right' } },
        },
      ];
    });

    return (
      <ListingTable
        title="ACH Reject Fee"
        header={[
          { content: 'Fee Type', attributes: { style: { width: '121px' } } },
          {
            content: 'Amount',
            attributes: { style: { width: '217px', textAlign: 'right' } },
          },
          {
            content: 'Billing Cycle',
            attributes: { style: { width: '217px' } },
          },
          { content: 'Settlement', attributes: { style: { width: '217px' } } },
          { content: 'Default Fee', attributes: { style: { width: '217px' } } },
          {
            content: 'Default ACH Reject',
            attributes: { style: { width: '217px' } },
          },
          { content: null, attributes: { style: { width: '104px' } } },
        ]}
        rows={rows}
      />
    );
  }
  return null;
}

function getBillingCycleDisplay(billing: Billing) {
  const cycle = formatCapitalCase(billing.billing_cycle);
  const day =
    billing.billing_cycle === 'MONTHLY' && billing.billing_cycle_day
      ? `${ordinal(billing.billing_cycle_day)}`
      : billing.billing_cycle === 'ANNUALLY'
      ? `${getMonthName(
          ordinal(billing.billing_cycle_month).replace(/\D/g, '')
        )}
      ${ordinal(billing.billing_cycle_day)}`
      : null;
  return [cycle, ...(day ? [day] : [])].join(' - ');
}
