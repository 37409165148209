import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import {
  BaseModal,
  ModalHeader,
  Icon,
  SmallSecondaryButton,
} from '@fattmerchantorg/truffle-components';
import { ModalContext, SelectedMerchantProvider } from '../../../../context';
import { ExpandablePanel } from '../../../shared';
import {
  BusinessVerificationToOperations,
  BusinessVerificationLabel,
  ReportStatus,
  ReportEntity,
  PersonVerificationLabel,
  PersonVerificationToOperations,
} from './ExternalVerification.types';
import { RegistrationAuditLog, Auth } from '@fattmerchantorg/types-omni';
import { getGiactReport } from '../../../../services/giact-service';
import renderAuditLogDrilldownRecord from './audit-log-drilldown';
import { renderMoreDetailsFromGiact } from './audit-log-drilldown/giact-additional-details';
import { ButtonSpinner } from '../../../shared';
import { useToaster } from '../../../../hooks';

const StyledModalContent = styled.div`
  grid-area: content;
  overflow-y: auto;
  padding: ${({ theme }) => theme.space[4]}px;
  color: ${({ theme }) => theme.component.modal.color};

  > * {
    margin: 0 0 10px;
  }
`;

const LogContent = styled.div`
  font-size: 14px;

  ul {
    padding: 0 0 0 8px;
    margin: 0 0 16px 0;
  }

  ul:empty {
    display: none;
  }

  li {
    list-style: none;
    word-wrap: break-word;
  }

  // Styles the "Potential Matches" next sections within the log content
  div > div > ul {
    background: ${({ theme }) => theme.black};
    padding: 8px 16px;
    border-radius: 2px;
  }
`;

interface AuditLogDetailModalProps {
  isOpen?: boolean;
  entityType: ReportEntity;
  verificationData: RegistrationAuditLog[];
  authToken: Auth['token'];
}

const PassIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.core.green[500].hex};
`;

const WarningIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.status.yellow[700].hex};
`;

const FailIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.status.red[600].hex};
`;

const getReportByOperationLabel = (
  verificationData: RegistrationAuditLog[],
  label: BusinessVerificationLabel | PersonVerificationLabel,
  entityType: ReportEntity
) => {
  return verificationData?.filter(report => {
    const operations = report.operationsRan.split(',');

    return operations.includes(
      entityType === 'business'
        ? BusinessVerificationToOperations[label]
        : PersonVerificationToOperations[label]
    );
  });
};

export const AuditLogDetailModal: React.FC<
  AuditLogDetailModalProps
> = props => {
  const { modalDispatch } = useContext(ModalContext);
  const [isModalVisible] = useState(!!props.isOpen);
  const { verificationData, authToken, entityType } = props;
  const [additionalDetailsIsLoading, setAdditionalDetailsIsLoading] =
    useState(false);
  const [auditLogAdditionalDetails, setAuditLogAdditionalDetails] = useState(
    {}
  );
  const { toast, toaster } = useToaster();

  let auditLogOperationData =
    entityType === 'business'
      ? {
          Entity: getReportByOperationLabel(
            verificationData,
            'Entity',
            entityType
          ),
          'Bank Open': getReportByOperationLabel(
            verificationData,
            'Bank Open',
            entityType
          ),
          'Bank Match': getReportByOperationLabel(
            verificationData,
            'Bank Match',
            entityType
          ),
          TIN: getReportByOperationLabel(verificationData, 'TIN', entityType),
          Location: getReportByOperationLabel(
            verificationData,
            'Location',
            entityType
          ),
        }
      : {
          Identity: getReportByOperationLabel(
            verificationData,
            'Identity',
            entityType
          ),
          Email: getReportByOperationLabel(
            verificationData,
            'Email',
            entityType
          ),
          IP: getReportByOperationLabel(verificationData, 'IP', entityType),
          OFAC: getReportByOperationLabel(verificationData, 'OFAC', entityType),
          SSN: getReportByOperationLabel(verificationData, 'SSN', entityType),
        };

  const handleCloseModal = () => {
    modalDispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const renderStatusIcon = (status: ReportStatus[]) => {
    if (status.includes('success')) {
      return <PassIcon icon={['fas', 'check']} />;
    }
    if (status.includes('warning')) {
      return <WarningIcon icon={['fas', 'exclamation-triangle']} />;
    }
    if (status.includes('failure')) {
      return <FailIcon icon={['fas', 'times']} />;
    }
  };

  const renderViewMoreDetailsBtn = itemReferenceId => {
    return (
      <div className="additional-details-section">
        <SmallSecondaryButton
          disabled={!isEmpty(auditLogAdditionalDetails)}
          onClick={async () => {
            setAdditionalDetailsIsLoading(true);
            try {
              const auditLogAdditionalDetails = await getGiactReport(
                authToken,
                itemReferenceId
              );
              setAuditLogAdditionalDetails(auditLogAdditionalDetails);
            } catch (error) {
              toaster(toast.error(error));
            } finally {
              setAdditionalDetailsIsLoading(false);
            }
          }}
        >
          {additionalDetailsIsLoading ? <ButtonSpinner /> : null} View More
          Details
        </SmallSecondaryButton>
        {!isEmpty(auditLogAdditionalDetails) &&
          renderMoreDetailsFromGiact(auditLogAdditionalDetails)}
      </div>
    );
  };

  const renderPanels = () => {
    return Object.keys(auditLogOperationData).map((reportKey, index) => {
      const reports = auditLogOperationData[reportKey];
      if (reports.length === 0) return null;
      return (
        <ExpandablePanel
          expanded={false}
          triggerContent={reportKey}
          triggerSuffix={renderStatusIcon(reports.map(r => r.status ?? null))}
          key={index}
        >
          <LogContent>
            {reports.length > 0
              ? renderAuditLogDrilldownRecord(
                  reports.map(r => r.result),
                  renderViewMoreDetailsBtn
                )
              : null}
          </LogContent>
        </ExpandablePanel>
      );
    });
  };

  const modalTitle =
    entityType === 'business'
      ? 'Business Verification Details'
      : 'Signer Verification Details';

  return (
    <SelectedMerchantProvider>
      <BaseModal
        title={modalTitle}
        isOpen={isModalVisible}
        onRequestClose={handleCloseModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={false}
        style={{
          overlay: { pointerEvents: 'auto' },
          content: {
            maxWidth: '533px',
            gridTemplateRows: '48px 1fr',
            gridTemplateAreas: `
                "header"
                "content"
              `,
          },
        }}
      >
        <ModalHeader title={modalTitle} onClose={handleCloseModal} />
        <>
          <StyledModalContent data-testid="audit-log-detail-modal">
            {renderPanels()}
          </StyledModalContent>
        </>
      </BaseModal>
    </SelectedMerchantProvider>
  );
};
