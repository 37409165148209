import { PermissionReadWrite } from '@fattmerchantorg/types-omni';
import { getFirstAndLastName } from './string.util';
import { formatSnakeToTitleCase } from './format.util';
import { getTimezoneName } from './date.util';

declare var pendo: any;

export const loadPendoLibrary = (callback?: Function) => {
  let p = window;
  let e = document;
  let n = 'script';
  let d = 'pendo';
  let o;

  var v, w, x, y, z;
  o = p[d] = p[d] || {};
  o._q = o._q || [];
  v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
  for (w = 0, x = v.length; w < x; ++w)
    (function (m) {
      o[m] =
        o[m] ||
        function () {
          o._q[m === v[0] ? 'unshift' : 'push'](
            [m].concat([].slice.call(arguments, 0))
          );
        };
    })(v[w]);
  y = e.createElement(n);
  y.async = !0;
  y.src = `https://cdn.pendo.io/agent/static/${process.env.REACT_APP_PENDO_KEY}/pendo.js`;
  y.onload = callback;
  z = e.getElementsByTagName(n)[0];
  z.parentNode.insertBefore(y, z);
};

export type PendoVisitor = {
  userId: string;
  email: string;
  name: string;
  timezone: string;
  isTestMode: boolean;
  dashboardPermission: PermissionReadWrite;
  submerchantPermission: PermissionReadWrite;
  devModePermission: PermissionReadWrite;
  connectPersona: string | null | undefined;
};

export type PendoAccount = {
  brandId: string;
  name: string;
  displayName: string;
  relationship: string;
  reportType: string;
  externalName: string;
  isAllBrandsMode: boolean;
};

export const initializePendo = (
  pendVisitor: PendoVisitor,
  pendoAccount: PendoAccount
) => {
  const visitor = createVisitorMetadataConfig(pendVisitor);
  const account = createAccountMetadataConfig(pendoAccount);
  pendo.initialize({ visitor, account });
};

const createVisitorMetadataConfig = (visitor: PendoVisitor) => {
  const {
    userId,
    email,
    name,
    timezone,
    isTestMode,
    dashboardPermission,
    submerchantPermission,
    devModePermission,
    connectPersona,
  } = visitor;
  const [firstName, lastName] = getFirstAndLastName(name);
  return {
    id: userId,
    email,
    firstName,
    lastName,
    fullName: name,
    timezone: timezone || getTimezoneName(),
    isTestMode,
    dashboardPermission,
    submerchantPermission,
    devModePermission,
    connectPersona: formatSnakeToTitleCase(connectPersona),
  };
};

const createAccountMetadataConfig = (account: PendoAccount) => {
  const {
    brandId: id,
    name,
    displayName,
    relationship,
    reportType,
    externalName,
    isAllBrandsMode,
  } = account;
  /**
   * id: brand id
   * name: brand name
   * display name: metadata
   * relationship: metadata
   * report type: metdadata
   * external name: metadata
   * is_sandbox: calculate from whether '-sandbox' is appended to brand name
   */
  return {
    id,
    name,
    displayName,
    relationship,
    reportType,
    externalName,
    isSandbox: name?.includes('-sandbox'),
    isAllBrandsMode,
  };
};
