import { DisputeReason } from '@fattmerchantorg/types-omni';
import {
  formatCapitalCase,
  formatTitleCase,
  replaceLongDashU2013,
} from '../../util/format.util';

export function formatReasonAndDescription(
  reason: DisputeReason,
  description: string,
  truncate?: boolean
): string {
  let formattedValue = '';
  if (!description || description === undefined) {
    description = '';
  }
  if (reason && description !== '') {
    formattedValue =
      formatCapitalCase(reason) +
      ' - ' +
      formatTitleCase(replaceLongDashU2013(description));
  } else if (reason) {
    formattedValue = formatCapitalCase(reason);
  } else {
    formattedValue = formatTitleCase(replaceLongDashU2013(description));
  }
  if (truncate && formattedValue.length > 23) {
    formattedValue = formattedValue.substring(0, 23) + '...';
  }
  return formattedValue;
}
