import React, { useContext, useMemo, useState } from 'react';
import { debounceAsync } from '../../../util/functional.util';
import { useAsyncEffect, useToaster } from '../../../hooks';
import { catanapi } from '../../../api/catan';
import styled from 'styled-components';
import {
  BaseModal,
  DateRangePicker,
  Label,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  Select,
  TextField,
} from '@fattmerchantorg/truffle-components';
import { endOfDay, formatDate } from '../../../util/date.util';
import { startOfYesterday } from 'date-fns/esm';
import { ModalContext } from '../../../context';
import {
  FormRow,
  TwoColumn,
} from '../../underwriting/components/shared/UnderwritingPartialForm';
import {
  riskHoldOptions,
  settlementStatusFilterOptions,
} from './filterOptions';

const ButtonBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  > * {
    margin: 0 0 0 ${({ theme }) => theme.space[4]}px;
  }
`;

const StyledModalContent = styled.div`
  grid-area: content;
  overflow-y: visible !important;
  padding: ${({ theme }) => theme.space[4]}px;
  color: ${({ theme }) => theme.component.modal.color};
`;

interface SettlementsFilterModalProps {
  isOpen: boolean;
  handleQueryChange;
  query;
  getData;
  authToken;
  catanQueryData;
  brandList;
  payfacList;
}

export const FILTER_DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss';
type BrandResult = { label: string; value: string }[] | undefined;

const fetchBrandOptions: (authToken: string, keyword: string) => Promise<any> =
  debounceAsync<string, any>((authToken, keyword) =>
    catanapi.get(authToken, `/brands`, { keyword }).then(brands => {
      const options = brands?.data?.map(brand => {
        return { label: brand.brand_name, value: brand.brand_id };
      });
      return options;
    })
  );

export const SettlementsFilterModal: React.FunctionComponent<
  SettlementsFilterModalProps
> = props => {
  const { isOpen, query, handleQueryChange, authToken, payfacList } = props;
  const { toaster, toast } = useToaster();
  const [brandOptions, setBrandOptions] = useState<BrandResult | undefined>();
  const [brandSearchTerm, setBrandSearchTerm] = useState('');
  const { modalDispatch } = useContext(ModalContext);
  const [isModalVisible] = useState(!!isOpen);

  const queryParams = {
    ...query,
    riskHold: query.riskHold ? query.riskHold[0] : undefined,
    approvalState: query.approvalState ? query.approvalState[0] : undefined,
  };

  const [filters, setFilters] = useState<{
    riskHold: string | string[] | undefined;
    keyword: string | string[] | undefined;
    approvalState: string | string[] | undefined;
    brandId: string | string[] | undefined;
    payfacId: string | string[] | undefined;
    startDate: string | string[] | undefined;
    endDate: string | string[] | undefined;
  }>(queryParams || {});

  //Format our dates
  let startDateArray,
    endDateArray = [];
  let _startEntryDate,
    _endEntryDate = new Date();
  if (query?.startDate) {
    startDateArray = query.startDate.split('-');
    const startYear = startDateArray[0];
    const startMonth = parseInt(startDateArray[1], 10) - 1;
    const startDate = startDateArray[2];
    _startEntryDate = new Date(startYear, startMonth, startDate);
  }
  if (query?.endDate) {
    endDateArray = query.endDate.split('-');
    const endYear = endDateArray[0];
    const endMonth = parseInt(endDateArray[1], 10) - 1;
    const endDate = endDateArray[2];
    _endEntryDate = new Date(endYear, endMonth, endDate);
  }
  const handleCloseModal = () => {
    modalDispatch({ type: 'CLOSE_MODAL' });
  };

  const combineFilters = (filters: Record<string, string | string[]>) => {
    setFilters(existingFilters => {
      return { ...existingFilters, ...filters };
    });
  };

  const payfacOptions = useMemo(() => {
    return payfacList?.data?.map(payfac => {
      return { label: payfac.payfac_name, value: payfac.payfac_id };
    });
  }, [payfacList]);

  const applyFilters = () => {
    handleQueryChange({
      ...filters,
      riskHold: filters.riskHold ? [filters.riskHold] : null,
      approvalState: filters.approvalState ? [filters.approvalState] : null,
      page: 1,
    });
    handleCloseModal();
  };
  function createUTCdateForISO(dateString) {
    const offset = new Date().getTimezoneOffset();
    const myDate = Date.parse(dateString) - offset * 60 * 1000;
    const dateAsISO = new Date(myDate).toISOString().slice(0, 10);

    return dateAsISO;
  }
  async function clearFilters() {
    handleQueryChange({
      riskHold: null,
      keyword: null,
      brandId: null,
      page: 1,
      startDate: formatDate(startOfYesterday(), 'yyyy-MM-dd'),
      endDate: formatDate(endOfDay(new Date()), 'yyyy-MM-dd'),
      approvalState: ['ALL'],
      payfacId: null,
    });
    handleCloseModal();
  }

  useAsyncEffect(async () => {
    if (brandSearchTerm === '') {
      setBrandOptions(undefined);
      return;
    }
    try {
      const options = await fetchBrandOptions(authToken, brandSearchTerm);
      setBrandOptions(options);
    } catch (error) {
      setBrandOptions(undefined);
      toaster(toast.error(error, 'Could not set brand filter options'));
    }
  }, [brandSearchTerm, authToken]);

  useAsyncEffect(async () => {
    // if brand id is undefined or empty str return
    if (!(filters.brandId && filters.brandId !== '')) {
      return;
    }
    // if brand id is supplied, make the call to brands for the one brand
    // in order to fill the brand options for that brand
    const brandResp = await catanapi.get(authToken, '/brands', {
      brandId: filters.brandId,
    });
    // there should be one and only one brand returned, but
    // we still want to be safe
    const brand = brandResp.data.find(
      item => item.brand_id === filters.brandId
    );
    const option = {
      label: brand.brand_name,
      value: brand.brand_id,
    };
    setBrandOptions([option]);
  });

  return (
    <div className="modal-body" style={{ overflow: 'visible' }}>
      <BaseModal
        isOpen={isModalVisible}
        onRequestClose={handleCloseModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={false}
        style={{
          overlay: { pointerEvents: 'auto' },
          content: { maxWidth: '533px', overflow: 'visible !important' },
        }}
      >
        <ModalHeader title="Filters" onClose={handleCloseModal} />

        <StyledModalContent>
          <FormRow>
            <Label text="Select Date Range" />
            <DateRangePicker
              dateFormat="MM/dd/yyyy"
              startDateFieldName="startDate"
              endDateFieldName="endDate"
              initialStart={_startEntryDate}
              initialEnd={_endEntryDate}
              // TODO: fix truffle DateRangePicker type...
              // @ts-ignore
              onChange={(value: [Date, Date]) => {
                const [startDate, endDate] = value;

                combineFilters({
                  startDate: startDate ? createUTCdateForISO(startDate) : null,
                  endDate: endDate ? createUTCdateForISO(endDate) : null,
                });
              }}
            />
          </FormRow>
          <FormRow>
            <TwoColumn>
              <div>
                <Label text="Merchant/MID" />
                <TextField
                  value={filters.keyword}
                  onChange={e => combineFilters({ keyword: e.target.value })}
                />
              </div>
              <div>
                <Select
                  options={riskHoldOptions}
                  value={riskHoldOptions.find(
                    option => option.value === filters.riskHold
                  )}
                  onChange={option =>
                    combineFilters({ riskHold: option?.value })
                  }
                  menuShouldScrollIntoView={true}
                  label="Merchant Risk Hold Status"
                />
              </div>
            </TwoColumn>
          </FormRow>
          <FormRow>
            <TwoColumn>
              <div>
                <Select
                  options={brandOptions}
                  value={brandOptions?.find(
                    option => option.value === filters.brandId
                  )}
                  onChange={option =>
                    combineFilters({ brandId: option?.value })
                  }
                  onInputChange={search => setBrandSearchTerm(search)}
                  placeholder="Search..."
                  // remove dropdown indicator for this component as it acts as more of a
                  // search field than a dropdown
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  menuShouldScrollIntoView={true}
                  label="Brand"
                />
              </div>
              <div>
                <Select
                  options={settlementStatusFilterOptions}
                  value={settlementStatusFilterOptions.find(
                    option => option.value === filters.approvalState
                  )}
                  onChange={option => {
                    combineFilters({ approvalState: option?.value });
                  }}
                  menuShouldScrollIntoView={true}
                  label="Settlement Status"
                />
              </div>
            </TwoColumn>
          </FormRow>
          <FormRow>
            <TwoColumn>
              <div>
                <Select
                  options={payfacOptions}
                  value={payfacOptions?.find(
                    option => option.value === filters.payfacId
                  )}
                  onChange={option => {
                    combineFilters({ payfacId: option?.value });
                  }}
                  menuShouldScrollIntoView={true}
                  label="Portfolio"
                />
              </div>
            </TwoColumn>
          </FormRow>
        </StyledModalContent>

        <ModalFooter>
          <ButtonBar>
            <SecondaryButton onClick={clearFilters}>Reset</SecondaryButton>
            <PrimaryButton
              id="settlements-filter-modal-submit-button"
              data-testid="settlements-filter-modal-submit-button"
              onClick={applyFilters}
            >
              Apply
            </PrimaryButton>
          </ButtonBar>
        </ModalFooter>
      </BaseModal>
    </div>
  );
};
