import React, { FunctionComponent } from 'react';
import { RegistrationAuditLog } from '@fattmerchantorg/types-omni';
import styled from 'styled-components';
import { get } from '../../../../util/object.util';
import { format } from 'date-fns';
import { StatusPillStatus } from '@fattmerchantorg/truffle-components/dist/StatusPill/StatusPill';
import { OpenCorisPortalButton } from './coris-log-drilldown/coris.util';
import {
  CorisPillConfigurations,
  CorisStatus,
} from './ExternalVerification.types';
import { StatusPill, TextLink } from '@fattmerchantorg/truffle-components';

const ReportRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
  justify-content: center;
  align-items: left;

  > p {
    font-size: 14px;
  }
`;

const OpenCorisButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 240px;
  min-width: 25%;
`;

const NoRecordsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
  justify-content: center;
  align-items: center;

  ${OpenCorisButton} {
    min-height: 0;
    min-width: 0;
  }

  > p {
    font-size: 16px;
    margin-bottom: 8px;
  }
`;

const BusinessNameText = styled(TextLink)`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 0px;
`;

const Match = styled.div`
  font-weight: 700;
  font-size: 14px !important;
`;

const CorisDate = styled.div`
  font-size: 14px;
  font-weight: 700;
`;

interface AddRCorisButtonProps {
  handleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const pillConfigurations: CorisPillConfigurations = {
  error: { status: 'error', icon: null, disabled: true },
  success: { status: 'success', icon: null, disabled: false },
  warning: {
    status: 'warning',
    icon: null,
    disabled: false,
  },
};

interface ReportGroupProps {
  reports: RegistrationAuditLog[] | {};
  groupTimestamp?: Date;
  onViewReport?: React.MouseEventHandler<HTMLAnchorElement>;
}

const CorisStatusPill: React.FC<{
  status: CorisStatus;
  score: number;
}> = props => {
  const { status, score } = props;
  const config = pillConfigurations[status];
  return (
    <StatusPill
      label={score.toString()}
      status={config.status as StatusPillStatus}
    />
  );
};

export const AuditCorisRow: FunctionComponent<ReportGroupProps> = props => {
  const { reports, onViewReport } = props;

  const pill = match_probability => {
    const status =
      match_probability >= 0 && match_probability <= 50
        ? 'error'
        : match_probability >= 51 && match_probability <= 74
        ? 'warning'
        : match_probability >= 75 && match_probability <= 100
        ? 'success'
        : 'error';
    return <CorisStatusPill status={status} score={match_probability} />;
  };

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    newRep?: boolean
  ) => {
    // Default coris url https://www.coris.ai/
    const corisURL = `https://www.coris.ai/`;
    window.open(corisURL, '_blank');
  };

  return (
    <>
      {Object.keys(reports).length !== 0 ? (
        <div data-testid="merchant-open-coris">
          {Object.keys(reports).map(corisKey => {
            if (
              !get(
                reports[corisKey],
                'result.businessSearch.business_name',
                null
              )
            ) {
              return null;
            }
            const eventTimestamp = new Date(
              get(reports[corisKey], 'createdAt', '')
            );
            eventTimestamp.setSeconds(0);
            return (
              <ReportRow key={corisKey}>
                <BusinessNameText
                  data-testid={`audit-log-coris-business-link-${corisKey}`}
                  onClick={onViewReport}
                >
                  {get(
                    reports[corisKey],
                    'result.businessSearch.business_name',
                    ''
                  )}
                </BusinessNameText>
                <Match data-testid={`coris-match-probability-${corisKey}`}>
                  Match Probability&nbsp;
                  {pill(
                    get(
                      reports[corisKey],
                      'result.businessSearch.match_probability',
                      0
                    )
                  )}
                </Match>
                <CorisDate data-testid={`coris-date-${corisKey}`}>
                  {format(eventTimestamp, 'MM/dd/yyyy hh:mm a')}
                </CorisDate>
              </ReportRow>
            );
          })}
        </div>
      ) : (
        <NoRecordsContainer
          id="no-merchant-open-coris"
          data-testid={'no-merchant-open-coris'}
        >
          <p>No Merchant Found</p>
          <OpenCorisPortalButton
            handleClick={e => handleClick(e, true)}
            ButtonStyle={OpenCorisButton}
            data-testid={`open-coris-portal-no-id`}
          />
        </NoRecordsContainer>
      )}
    </>
  );
};
