import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { UnderwritingSection } from '../shared/UnderwritingSection';
import { ModalContext } from '../../../../context';
import { SelectedMerchantStore } from '../../../../context';
import { OwnerSignerInfoFormModal } from './OwnerSignerInfoFormModal';
import { Icon, Tooltip } from '@fattmerchantorg/truffle-components';
import {
  valueOrNA,
  valueOrHyphen,
  valueOrZero,
} from '../../../../util/string.util';
import { formatPhoneNumber } from '../../../../util/format.util';
import { countries } from '../../../../constants/countries';

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  p {
    font-weight: 400;
    line-height: 1.5;
  }
  a {
    font-size: 14px;
  }
`;

const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 18px 1fr;
  grid-auto-rows: auto;
  grid-column-gap: 8px;
  grid-row-gap: 4px;
  align-items: flex-start;
`;

const OwnerInfo = styled.p`
  margin-bottom: 8px;

  span:first-child {
    margin-right: 8px;
  }
`;

const Address = styled.div`
  p {
    margin: 0;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export interface OwnerSignerInfoProps {}

export function OwnerSignerInfo(props: OwnerSignerInfoProps) {
  const { modalDispatch } = useContext(ModalContext);

  const {
    dispatch,
    state: { merchantId, registration },
  } = useContext(SelectedMerchantStore);

  const [regState, setRegState] = useState(registration);
  const [mId, setmId] = useState(merchantId);

  useEffect(() => {
    setRegState(registration);
    setmId(merchantId);
  }, [registration, merchantId]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        component: OwnerSignerInfoFormModal,
        props: {
          isOpen: true,
          registration: regState,
          merchantId: mId,
          merchantDispatch: dispatch,
        },
      },
    });
  };

  const hasAddress = [
    registration?.owner_address_1,
    registration?.owner_address_2,
    registration?.owner_address_city,
    registration?.owner_address_state,
    registration?.owner_address_zip,
    registration?.owner_address_country,
  ].some(field => field !== null);

  return (
    <UnderwritingSection
      title="Owner/Signer Info"
      id="underwriting-owner-signer-info"
      isEditable={true}
      onEdit={handleClick}
    >
      {({ setToolTipContent }) => {
        setToolTipContent(`Owner/Signer's Information`);
        return (
          <CardBody>
            <OwnerInfo>
              <span data-testid="uw-principal-owners-name">
                {valueOrNA(registration?.principal_owners_name)}
              </span>
              {registration?.title ? (
                <span data-testid="uw-title">{registration?.title} - </span>
              ) : (
                'No Title '
              )}
              <span data-testid="uw-ownership-percentage">
                {`${valueOrZero(
                  registration?.meta?.ownership_percentage?.toString()
                )}%`}
              </span>
            </OwnerInfo>
            <DetailsGrid>
              <IconWrapper>
                <Tooltip content="Birthdate">
                  <Icon icon={['fas', 'birthday-cake']} />
                </Tooltip>
              </IconWrapper>
              <span data-testid="uw-user-dob">
                {valueOrHyphen(registration?.user_dob)}
              </span>
              <IconWrapper>
                <Tooltip content="Citizenship">
                  <Icon icon={['fas', 'passport']} />
                </Tooltip>
              </IconWrapper>
              <span data-testid="uw-owner-citizenship">
                {
                  countries.find(
                    i => i.iso === registration?.meta?.owner_citizenship
                  )?.name
                }
              </span>
              <IconWrapper>
                <Tooltip content="Social Security">
                  <Icon icon={['fas', 'id-card']} />
                </Tooltip>
              </IconWrapper>
              <span data-testid="uw-user-ssn">
                {registration?.user_ssn ? '***-**-****' : '-'}
              </span>
              <IconWrapper>
                <Tooltip content="Phone">
                  <Icon icon={['fas', 'phone']} />
                </Tooltip>
              </IconWrapper>
              <a
                href={
                  registration?.phone_number
                    ? `tel://${registration.phone_number}`
                    : null
                }
                data-testid="uw-phone-number"
              >
                {valueOrHyphen(formatPhoneNumber(registration?.phone_number))}
              </a>
              <IconWrapper>
                <Tooltip content="Email">
                  <Icon icon={['fas', 'envelope']} />
                </Tooltip>
              </IconWrapper>
              <a
                href={
                  registration?.email ? `mailto:${registration.email}` : null
                }
                data-testid="uw-email"
              >
                {valueOrHyphen(registration?.email)}
              </a>
              <IconWrapper>
                <Tooltip content="Address">
                  <Icon icon={['fas', 'map-marker-alt']} />
                </Tooltip>
              </IconWrapper>
              <Address>
                {hasAddress ? (
                  <>
                    {registration?.owner_address_1 ? (
                      <p data-testid="uw-owner-address-1">
                        {registration?.owner_address_1}
                      </p>
                    ) : null}
                    {registration?.owner_address_2 ? (
                      <p data-testid="uw-owner-address-2">
                        {registration?.owner_address_2}
                      </p>
                    ) : null}
                    {registration?.owner_address_city ||
                    registration?.owner_address_state ||
                    registration?.owner_address_zip ? (
                      <p>
                        <span data-testid="uw-owner-address-city">{`${registration?.owner_address_city} `}</span>
                        <span data-testid="uw-owner-address-state">{`${registration?.owner_address_state} `}</span>
                        <span data-testid="uw-owner-address-zip">{`${registration?.owner_address_zip}`}</span>
                      </p>
                    ) : null}
                    {registration?.owner_address_country ? (
                      <p data-testid="uw-owner-address-country">
                        {registration?.owner_address_country}
                      </p>
                    ) : null}
                  </>
                ) : (
                  '-'
                )}
              </Address>
            </DetailsGrid>
          </CardBody>
        );
      }}
    </UnderwritingSection>
  );
}
