import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FundingAccount } from '@fattmerchantorg/types-omni';
import { SecondaryButton } from '@fattmerchantorg/truffle-components';
import { LoadingSpan, Separator as USeparator } from '../../../shared';
import { formatReadableDate } from '../../../../util/format.util';

export const Section = styled.div`
  padding: 16px 16px 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  a,
  span {
    font-size: 14px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:first-child) {
    margin-top: 16px;
  }

  small {
    font-size: 12px;
  }

  > *:not(:first-child) {
    margin-left: 8px;
  }
`;

export const LineItemRow = styled(Row)`
  > *:last-child {
    margin-left: auto;
  }
`;

export const DateRowContainer = styled(LineItemRow)`
  align-items: baseline;

  > small {
    margin-left: 2px !important;
  }
`;

export const DateRow: FunctionComponent<{
  account?: FundingAccount;
  loading?: boolean;
}> = props =>
  props.loading ? (
    <Row>
      <LoadingSpan height="24px" width="160px" style={{ marginLeft: 'auto' }} />
    </Row>
  ) : (
    <DateRowContainer>
      <span>{formatReadableDate(String(props.account?.created_at))}</span>
      {props.children}
    </DateRowContainer>
  );

export const Separator = styled(USeparator)`
  padding-top: 16px;
`;

export const BottomBar = styled.div`
  background: ${({ theme }) => theme.component.drawer.backgroundColor};
  padding: 16px;
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  justify-content: right;

  > *:not(:first-child) {
    margin-left: 16px;
  }
`;

/** TODO: replace with truffle component once it exists */
export const SecondaryDestructiveButton = styled(SecondaryButton)`
  color: ${({ theme }) => theme.colors.status.red[500].hex};
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.status.red[500].hex};
  background: none;

  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.status.red[500].hex};
    box-shadow: inset 0 0 0 1px
      ${({ theme }) => theme.colors.status.red[500].hex};
    background: rgba(227, 0, 41, 0.2);
  }
` as typeof SecondaryButton;
