import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../../@types';

export const StatementContainer: FunctionComponent<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = styled.div`
  margin: 16px 16px 76px 16px;
  color: ${(props: ThemeProps) => props.theme.black};

  > * {
    margin-bottom: 24px;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    display: inline-block;
    margin: 0px auto 0px 0px;
    font-size: 12px;
  }

  h4 {
    font-size: 14px;
  }
`;
