import React, { useState, useContext, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { ModalContext, closeModal } from '../../../../../context/modal';
import {
  BaseModal,
  ModalHeader,
  ModalFooter,
  PrimaryButton,
  SecondaryButton,
} from '@fattmerchantorg/truffle-components';
import {
  Registration,
  GodviewBrandData,
  Merchant,
  Gateway,
} from '@fattmerchantorg/types-omni';
import { Form } from '../../../../shared';
import { coreapi } from '../../../../../api';
import {
  updateSelectedMerchantRegistration,
  AuthStore,
  alterSelectedMerchant,
  Action,
} from '../../../../../context';
import { useToaster } from '../../../../../hooks';
import * as updateUnderwritingStatusForm from './UpdateUnderwritingStatusForm.util';
import { UpdateUnderwritingStatusFieldsValues } from './UpdateUnderwritingStatusForm.types';
import { Dispatch } from 'react';
import { ButtonSpinner } from '../../../../shared';
import { UpdateUnderwritingStatusFields } from './UpdateUnderwritingStatusFields';
import { AsyncData } from '../../../../../@types';

const ButtonBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  > * {
    margin: 0 0 0 ${({ theme }) => theme.space[4]}px;
  }
`;

const StyledModalContent = styled.div`
  grid-area: content;
  overflow-y: auto;
  padding: ${({ theme }) => theme.space[4]}px;
  color: ${({ theme }) => theme.component.modal.color};
`;

interface UpdateUnderwritingStatusFormModalProps {
  isOpen?: boolean;
  registration: Registration;
  brandData: GodviewBrandData;
  merchantId: string;
  gateways: AsyncData<Gateway[]>;
  merchantDispatch: Dispatch<Action>;
}

const validateForm = updateUnderwritingStatusForm.validateFormValues;

export const UpdateUnderwritingStatusFormModal: React.FunctionComponent<
  UpdateUnderwritingStatusFormModalProps
> = props => {
  const { modalDispatch } = useContext(ModalContext);
  const [isModalVisible] = useState(!!props.isOpen);
  const { registration, brandData, merchantId, merchantDispatch } = props;
  const { state: authState } = useContext(AuthStore);
  const { authToken } = authState;
  const { toaster, toast } = useToaster();
  const [isSaving, setIsSaving] = useState(false);

  const handleCloseModal = () => modalDispatch(closeModal());

  const onSubmit = useCallback(
    async (formValues: UpdateUnderwritingStatusFieldsValues) => {
      if (!formValues) return;
      setIsSaving(true);

      const payload: Partial<Registration> =
        updateUnderwritingStatusForm.mapFormValuesToPayload(formValues);

      try {
        const r = await coreapi.put<Registration & { merchant: Merchant }>(
          authToken,
          `/merchant/${merchantId}/registration`,
          payload
        );

        merchantDispatch(updateSelectedMerchantRegistration(r));
        if (r.merchant) merchantDispatch(alterSelectedMerchant(r.merchant));
        toaster(toast.success('Changes successfully saved', 'Saved'));
        modalDispatch(closeModal());
      } catch (error) {
        toaster(toast.error(error, 'There was a problem saving your changes.'));
      }
      setIsSaving(false);
    },
    [authToken, merchantId, merchantDispatch, toast, toaster, modalDispatch]
  );

  const initialValues = useMemo(() => {
    if (!registration) return null;

    return updateUnderwritingStatusForm.mapRegistrationToInitialFormValues(
      registration
    );
  }, [registration]);

  if (!initialValues) return null;

  return (
    <Form
      initialValues={initialValues}
      validate={formValues => validateForm(formValues, registration)}
      onSubmit={onSubmit}
    >
      {formProps => (
        <BaseModal
          title="Underwriting Status"
          isOpen={isModalVisible}
          onRequestClose={handleCloseModal}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={false}
          style={{
            overlay: { pointerEvents: 'auto' },
            content: { maxWidth: '533px' },
          }}
        >
          <ModalHeader title="Underwriting Status" onClose={handleCloseModal} />
          <>
            <StyledModalContent>
              <UpdateUnderwritingStatusFields
                formProps={formProps}
                allowConditionalApproval={
                  brandData?.flags?.allowConditionalApproval
                }
              />
            </StyledModalContent>
            <ModalFooter>
              <ButtonBar>
                <SecondaryButton onClick={handleCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  onClick={() => onSubmit(formProps.values)}
                  disabled={formProps.hasValidationErrors === true}
                >
                  {isSaving ? <ButtonSpinner /> : `Save`}
                </PrimaryButton>
              </ButtonBar>
            </ModalFooter>
          </>
        </BaseModal>
      )}
    </Form>
  );
};
