import React, { FunctionComponent } from 'react';
import { Filter, FilterCheckboxRow } from '../../shared';
import { useLocalSearchState } from '../../../hooks';
import { SupportTicket } from '../../../@types';

export const SupportTicketStatusFilter: FunctionComponent = () => {
  const [status, setStatus] =
    useLocalSearchState<SupportTicket['status']>('status');

  return (
    <Filter hasFilters={!!status} onClose={() => setStatus(status, true)}>
      <FilterCheckboxRow
        checked={status === 'NEW'}
        label="Open"
        onChange={() => setStatus(status === 'NEW' ? null : 'NEW', false)}
      />
      <FilterCheckboxRow
        checked={status === 'PENDING'}
        label="Pending"
        onChange={() =>
          setStatus(status === 'PENDING' ? null : 'PENDING', false)
        }
      />
      <FilterCheckboxRow
        checked={status === 'CLOSED'}
        label="Closed"
        onChange={() => setStatus(status === 'CLOSED' ? null : 'CLOSED', false)}
      />
      <FilterCheckboxRow
        checked={status === 'AWAITING_RESPONSE'}
        label="Awaiting Response"
        onChange={() =>
          setStatus(
            status === 'AWAITING_RESPONSE' ? null : 'AWAITING_RESPONSE',
            false
          )
        }
      />
    </Filter>
  );
};
