import { FormErrors } from '../../../../@types';
import { Registration } from '@fattmerchantorg/types-omni';

export const processingEnvironmentFields = [
  'chosen_processing_method',
  'pricing_plan',
  'plan_txamnt',
  'cp_per_item_rate',
  'amex_mid_trans_fee',
  'cp_amex_per_item_rate',
  'cnp_debit_trans_fee',
  'cp_debit_trans_fee',
  'plan_ach_txamnt',
  'plan_dcamnt',
  'cp_transaction_rate',
  'amex_mid_disc_rate',
  'cp_amex_rate',
  'cp_debit_discount_rate',
  'cnp_debit_discount_rate',
  'plan_ach_dcamnt',
  'plan_ach_cap',
  'cp_credit_surcharge_rate',
  'cnp_credit_surcharge_rate',
  'monthly_minimum_fee',
  'monthly_service_fee',
  'is_flat_rate',
  'mcc',
  'proc_change',
  'bus_type',
  'network',
] as const;

export type ProcessingEnvironmentFields =
  typeof processingEnvironmentFields[number];

export type ProcessingEnvironmentFieldsValues = Pick<
  Registration,
  ProcessingEnvironmentFields
>;

export type ProcessingEnvironmentFieldsErrors =
  FormErrors<ProcessingEnvironmentFieldsValues>;
