import React, { FunctionComponent, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import styled from 'styled-components';
import { StaxButton } from '../shared/Stax/Button';

const StyledJSONWrapper = styled.div`
  background: #fff;
  border: 1px solid #999;
  padding: 8px;
  margin-top: 16px;
  border-radius: 2px;
  overflow-x: scroll;
`;

const StyledWrapper = styled.div`
  margin-top: ${props => (props.hideCollapseButton ? '16px' : '38px')};
`;

interface IShowJSONProps {
  data: object;
  hideCollapseButton?: boolean;
}

export const ShowJSON: FunctionComponent<IShowJSONProps> = ({
  data,
  hideCollapseButton
}) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  useEffect(() => {
    setIsCollapsed(true);
  }, [data]);

  const collapseButton = !hideCollapseButton ? (
    <StaxButton onClick={() => setIsCollapsed(val => !val)}>
      {isCollapsed ? (
        <>
          <i className="fas fa-expand-alt"></i>Expand All
        </>
      ) : (
        <>
          <i className="fas fa-compress-alt"></i> Compact View
        </>
      )}
    </StaxButton>
  ) : null;

  const view = data ? (
    <StyledWrapper hideCollapseButton={hideCollapseButton}>
      {collapseButton}
      <StyledJSONWrapper>
        <ReactJson
          src={data}
          enableClipboard={false}
          collapsed={isCollapsed ? 2 : false}
          displayDataTypes={false}
        />
      </StyledJSONWrapper>
    </StyledWrapper>
  ) : (
    <StyledWrapper>
      <StyledJSONWrapper>
        <div className="font-italic text-light">(empty)</div>
      </StyledJSONWrapper>
    </StyledWrapper>
  );

  return view;
};
