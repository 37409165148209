import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { BigRadioOptionProps } from './BigRadio.types';
import { withAlpha } from '../../../util/color.util';

const Container = styled.label`
  width: calc(100% - 1rem);
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0.5rem;
  transition: all 0.2s ease;
  font-size: 14px;

  display: flex;
  background: white;
  border-radius: 3px;

  &:hover {
    box-shadow: 0 0 0 1px
      ${props => withAlpha(props.theme.colors.primaryAccent, 0.4)};
  }

  &.-checked {
    box-shadow: 0 0 0 2px ${props => props.theme.colors.primaryAccent};
    background: ${props => withAlpha(props.theme.colors.primaryAccent, 0.2)};
  }

  flex-direction: row;
  align-items: center;

  > * {
    margin: 1rem;

    @media (max-width: 1000px) {
      margin: 8px;
    }
  }

  i {
    font-size: 2rem;
    color: gray;
  }

  h4.title {
    margin: 0;
    font-size: 16px;
    font-weight: normal;
  }

  span.subtext {
    color: gray;
  }

  input {
    border: 0 0 0 1px ${props => props.theme.colors.primaryAccent};
    border-radius: 50%;
    content: '';
  }
`;

export const Option: FunctionComponent<BigRadioOptionProps> = props => {
  const isSelected = props.value === props.parentValue;

  return (
    <Container htmlFor={props.id} className={isSelected ? '-checked' : ''}>
      <input
        id={props.id}
        data-testid={props.id}
        type="radio"
        name={props.parentId}
        checked={isSelected}
        value={props.value}
        onChange={props.onChange}
      />
      {props.icon && <i className={props.icon} />}
      {props.children}
    </Container>
  );
};
