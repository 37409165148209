import { useCallback, useContext } from 'react';
import { Omni, Permissions } from '@fattmerchantorg/types-omni';
import { PermissionsState, PermissionsStore } from '../context';

type MerchantPermissions = Permissions['permissions']['merchants'];
type PermissionReadWrite = '' | 'read' | 'write';

const permissionLevels: PermissionReadWrite[] = ['', 'read', 'write'];

const findPermissionLevel = (permission?: PermissionReadWrite): number =>
  permissionLevels.indexOf(permission || '');

export type IncludedPermissions = Pick<
  Omni.Permissions['permissions'],
  'godview' | 'features'
> & {
  merchant: MerchantPermissions;
};

export type Permit = <Key extends keyof IncludedPermissions>(
  prefix: Key,
  action: keyof IncludedPermissions[Key],
  requiredPermission: PermissionReadWrite
) => boolean;

function allow(state: PermissionsState) {
  return function <Key extends keyof IncludedPermissions>(
    prefix: Key,
    action: keyof IncludedPermissions[Key],
    requiredPermission: PermissionReadWrite = 'read'
  ): boolean {
    const { permissions } = state;

    if (!permissions) return false;

    let actualPermission: PermissionReadWrite;

    if (prefix === 'merchant' && permissions.merchants) {
      // just use the first merchant
      const [merchant] = permissions?.merchants;
      actualPermission = merchant?.[String(action)];
    } else if (prefix === 'godview' && permissions.godview) {
      actualPermission = permissions?.godview?.[String(action)];
    } else if (prefix === 'features' && permissions.features) {
      const features = permissions.features;
      actualPermission = features?.[String(action)];
    }

    return (
      findPermissionLevel(actualPermission) >=
      findPermissionLevel(requiredPermission)
    );
  };
}

export function usePermissions() {
  const { state } = useContext(PermissionsStore);
  const permit = useCallback(allow(state), [state]);

  const godviewPermissions = state.permissions?.godview;
  const merchantPermissions = state.permissions?.merchants?.[0];
  const featurePermissions = state.permissions?.features;
  const associatedBrands = state.brands;

  return {
    permit,
    godviewPermissions,
    merchantPermissions,
    featurePermissions,
    associatedBrands,
    /** all permissions */
    permissions: state.permissions,
  };
}
