import { useCallback, useMemo } from 'react';
import { history } from '../history';
import { useLocation } from 'react-router-dom';
import { parse, stringify } from '../util/api.util';
import { omitBy } from '../util/object.util';

type SearchValues<T> = { [key: string]: T };

const cleanQuery = <T>(query: SearchValues<T>): SearchValues<T> =>
  omitBy(
    query,
    v =>
      (Array.isArray(v) && v.length === 0) ||
      (typeof v === 'string' && v === '') ||
      v === null ||
      v === undefined
  );

/**
 * This hook is used to read and write query params in the 'search' portion of
 * the url (the part that follows the `?`).
 *
 * **Usage example**
 *
 * Suppose we must render a modal when a URL query paramer is present, and we
 * must remove this URL parameter when the modal is closed.
 *
 * Previously we would have done that like so:
 * ```
 * const { search, pathname } = useLocation();
 * return (
 * <Modal
 *  isOpen={search.includes('detailId')}
 *  onClose={() =>
 *    // redirect back to list view, minus the detailId param
 *    history.push({
 *      pathname: `/list-view-page`,
 *      search: stringify(omit(query, 'detailId', 'param2', 'param3'))
 *    })
 *  }
 * />
 * );
 * ```
 * With the useSearchState() hook, this is simplified:
 * ```
 * const [
 *   { detailId, param2, param3 },
 *   setSearchState
 * ] = useSearchState();
 * return (
 * <Modal
 *  isOpen={!!detailId}
 *  onClose={() => setSearchState({ detailId: null, param2: null, param3: null })}
 * />
 * );
 * ```
 */
export function useSearchState<T extends string | string[]>(
  key: string
): [T, (value: T) => void];
export function useSearchState<T extends string | string[]>(
  key?: never
): [SearchValues<T>, (values?: SearchValues<T>) => void];
export function useSearchState<T extends string | string[]>(
  keyOrNothing?: string | never
): [T | SearchValues<T>, (valueOrValues?: T | SearchValues<T>) => void] {
  const { pathname, search } = useLocation();

  const query = useMemo(() => {
    return parse(search);
  }, [search]);

  const setValues = useCallback(
    (valueOrValues: T | SearchValues<T>) => {
      let newQuery = { ...query };

      if (typeof keyOrNothing === 'string') {
        const key = keyOrNothing;
        newQuery[key] = valueOrValues as T;
      } else {
        newQuery = {
          ...newQuery,
          ...(valueOrValues as SearchValues<T>),
        };
      }

      history.push(`${pathname}?${stringify(cleanQuery(newQuery))}`);
    },
    [pathname, query, keyOrNothing]
  );

  if (typeof keyOrNothing === 'string') {
    const key = keyOrNothing;
    return [query[key] as T, setValues];
  } else {
    return [query as SearchValues<T>, setValues];
  }
}
