import React, { useState, useContext, useCallback } from 'react';
import styled from 'styled-components';
import { ModalContext } from '../../../../context/modal';
import {
  BaseModal,
  ModalHeader,
  ModalFooter,
  PrimaryButton,
  SecondaryButton,
} from '@fattmerchantorg/truffle-components';
import { AdditionalRepresentativesFields } from './AdditionalRepresentativesFields';
import { Registration } from '@fattmerchantorg/types-omni';
import { Form } from '../../../shared';
import { coreapi } from '../../../../api';
import {
  updateSelectedMerchantRegistration,
  AuthStore,
} from '../../../../context';
import { useToaster } from '../../../../hooks';
import * as additionalRepresentativesFormUtil from './AdditionalRepresentativesForm.util';
import { AdditionalRepresentativesFieldsValues } from './AdditionalRepresentativesForm.types';
import { Dispatch } from 'react';
import { ButtonSpinner } from '../../../shared';
import { useUnderwritingFormSubmission } from '../../context/useUnderwritingFormSubmission';

const ButtonBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  > * {
    margin: 0 0 0 ${({ theme }) => theme.space[4]}px;
  }
`;

const StyledModalContent = styled.div`
  grid-area: content;
  overflow-y: auto;
  padding: ${({ theme }) => theme.space[4]}px;
  color: ${({ theme }) => theme.component.modal.color};
`;

interface AdditionalRepresentativesModalProps {
  isOpen?: boolean;
  registration: Registration;
  merchantId: string;
  merchantDispatch: Dispatch<any>;
  isNewRep?: boolean;
}

export const AdditionalRepresentativesFormModal: React.FunctionComponent<
  AdditionalRepresentativesModalProps
> = props => {
  const { modalDispatch } = useContext(ModalContext);
  const [isModalVisible] = useState(!!props.isOpen);
  const { registration, merchantId, merchantDispatch, isNewRep } = props;
  const { state: authState } = useContext(AuthStore);
  const { authToken } = authState;
  const { toaster, toast } = useToaster();
  const { isSaving, onSubmit } = useUnderwritingFormSubmission({
    merchantDispatch,
    merchantId,
    payloadFormatter: additionalRepresentativesFormUtil.mapFormValuesToPayload,
  });
  const newRepIndex = isNewRep
    ? registration?.meta?.representatives?.length
    : 0;
  const [repIndex, setRepIndex] = useState<number>(newRepIndex || 0);
  const [isRemovingRep, setIsRemovingRep] = useState(false);

  const closeModal = () => {
    modalDispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const handleRepChange = (option: { label: string; value: number }) => {
    setRepIndex(option.value);
  };

  const onRemoveRepresentative = useCallback(
    async (
      formValues: AdditionalRepresentativesFieldsValues,
      removeRepIndex?: number
    ) => {
      if (!formValues) return;
      setIsRemovingRep(true);
      const payload: Partial<Registration> = Object.assign(
        {},
        additionalRepresentativesFormUtil.mapFormValuesToPayload(formValues)
      );

      // Remove the requested rep from the representatives array and re-save
      // the formValues
      if (
        removeRepIndex >= 0 &&
        removeRepIndex in payload.meta.representatives
      ) {
        payload.meta.representatives.splice(removeRepIndex, 1);
      }

      try {
        const r = await coreapi.put(
          authToken,
          `/merchant/${merchantId}/registration`,
          payload
        );
        merchantDispatch(updateSelectedMerchantRegistration(r));
        toaster(toast.success('Representative removed.', 'Saved'));
      } catch (error) {
        toaster(
          toast.error(
            error,
            'There was a problem removing that representative.'
          )
        );
      } finally {
        setIsRemovingRep(false);
        modalDispatch({
          type: 'CLOSE_MODAL',
        });
      }
    },
    [authToken, merchantId, merchantDispatch, toast, toaster, modalDispatch]
  );

  return (
    <Form
      initialValues={additionalRepresentativesFormUtil.mapRegistrationToInitialFormValues(
        registration
      )}
      onSubmit={formValues => {
        onSubmit(formValues);
        closeModal();
      }}
      validate={formValues =>
        additionalRepresentativesFormUtil.validateFormValues(
          formValues,
          repIndex
        )
      }
      mutators={additionalRepresentativesFormUtil.formMutators}
    >
      {formProps => (
        <BaseModal
          title={isNewRep ? 'Add a Representative' : 'Edit Representatives'}
          isOpen={isModalVisible}
          onRequestClose={closeModal}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={false}
          style={{
            overlay: { pointerEvents: 'auto' },
            content: { maxWidth: '533px' },
          }}
        >
          <ModalHeader
            title={isNewRep ? 'Add a Representative' : 'Edit Representatives'}
            onClose={closeModal}
          />
          <>
            <StyledModalContent>
              <AdditionalRepresentativesFields
                formProps={formProps}
                isNewRep={isNewRep}
                onRemoveRep={onRemoveRepresentative}
                isRemovingRep={isRemovingRep}
                onSelectRepresentative={handleRepChange}
                registration={registration}
              />
            </StyledModalContent>
            <ModalFooter>
              <ButtonBar>
                <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
                <PrimaryButton
                  onClick={() => onSubmit(formProps.values)}
                  disabled={
                    // Disable Save if there are validation errors
                    formProps.hasValidationErrors === true
                  }
                >
                  {isSaving ? <ButtonSpinner /> : `Save`}
                </PrimaryButton>
              </ButtonBar>
            </ModalFooter>
          </>
        </BaseModal>
      )}
    </Form>
  );
};
