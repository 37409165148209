import React, { useContext, useState } from 'react';
import { Chip } from '@fattmerchantorg/truffle-components';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { SelectedMerchantStore } from '../../../../context';
import {
  formatDate,
  parseDateStringInUnknownFormat,
} from '../../../../util/date.util';
import styled from 'styled-components';

const SignatureHistoryContainer = styled.div`
  display: flex;
`;

const SignatureDatesContainer = styled.div`
  padding-right: 8px;
`;

const ChipActionButton = styled(Chip)`
  padding: 0;
  > span {
    display: none;
  }
`;

export const MerchantSignatureHistory = (_props: any) => {
  const {
    state: { registration },
  } = useContext(SelectedMerchantStore);
  const [expandedSignatures, setExpandedSignatures] = useState(false);

  const renderTimestamp = (value: string): string =>
    formatDate(
      parseDateStringInUnknownFormat(value),
      'MM-dd-yyyy H:mm:ss zzzz'
    );

  const renderMerchantSignatureHistoryToString = (): string => {
    return registration?.meta?.signature_history?.length
      ? registration?.meta?.signature_history
          .map(signature => signature.timestamp)
          .reduce(
            (prevVal, currVal) =>
              !prevVal
                ? renderTimestamp(currVal)
                : renderTimestamp(prevVal)
                ? renderTimestamp(prevVal).concat(
                    ` | ${renderTimestamp(currVal)}`
                  )
                : prevVal.concat(` | ${renderTimestamp(currVal)}`),
            ''
          )
      : renderTimestamp(registration?.electronic_signature?.timestamp);
  };

  const addBreaksToHistoryString = (history: string) => {
    const pieces = history.split('|');
    return pieces.map(piece => <div>{piece}</div>);
  };

  const renderChip = () => {
    return (
      <div
        style={{ cursor: 'pointer', float: 'right' }}
        onClick={() => setExpandedSignatures(true)}
      >
        <Chip
          icon={faPlus}
          label={`${registration?.meta?.signature_history.length - 1} more`}
        />
      </div>
    );
  };

  const renderExpandedChip = () => {
    return (
      <ChipActionButton
        label={' '}
        onAction={mouseEvent => {
          if (mouseEvent.type === 'click') {
            setExpandedSignatures(false);
          }
        }}
      />
    );
  };

  const renderMerchantSignatureHistory = () => {
    if (registration && registration.meta) {
      if (!registration.meta.signature_history) {
        return renderMerchantSignatureHistoryToString();
      }
      if (registration.meta.signature_history.length > 1) {
        if (!expandedSignatures) {
          return [
            <SignatureDatesContainer>
              {renderTimestamp(
                registration.meta.signature_history[0].timestamp
              )}
            </SignatureDatesContainer>,
            renderChip(),
          ];
        } else {
          return [
            <SignatureDatesContainer>
              {addBreaksToHistoryString(
                renderMerchantSignatureHistoryToString()
              )}
            </SignatureDatesContainer>,
            renderExpandedChip(),
          ];
        }
      }
    }
  };

  return (
    <SignatureHistoryContainer>
      {renderMerchantSignatureHistory()}
    </SignatureHistoryContainer>
  );
};
