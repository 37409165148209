import React from 'react';
import { Icon, Text } from '@fattmerchantorg/truffle-components';
import styled from 'styled-components';
import { ThemeProps } from '../../../../@types/ThemeProps';
import { TableSectionV3 } from '../styles';
import { TextContainer } from '../../components';

type TableErrorBoundaryProps = {
  title: string;
};

const ErrorBoxContainer = styled.div`
  border-top: 1px solid
    ${(props: ThemeProps) => props.theme.lightNeutralOutline};
`;

const ErrorBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  margin-top: 16px;
  background-color: ${(props: ThemeProps) => props.theme.destructiveColorTint};

  p {
    color: ${({ theme }) => theme.colors.status.red['600'].hex};
    font-size: 12px;
    font-weight: bold;
    margin: 0 0 0 6px;
  }

  svg {
    color: ${({ theme }) => theme.colors.status.red['600'].hex};
    margin-right: 4px;
  }
`;

export default class TableErrorBoundary extends React.Component<
  TableErrorBoundaryProps,
  { error: Error | string | unknown | null }
> {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  render() {
    const { children, title } = this.props;

    if (this.state.error) {
      return (
        <TableSectionV3>
          <TextContainer fontWeight="bold" paddingBottom="8px">
            <Text as="h5">{title}</Text>
          </TextContainer>
          <ErrorBoxContainer>
            <ErrorBox>
              <Icon icon={['far', 'times-circle']} />
              <Text as="p">
                Something went wrong while loading this section. Please reach
                out to our support team for assistance.
              </Text>
            </ErrorBox>
          </ErrorBoxContainer>
        </TableSectionV3>
      );
    }

    return children;
  }
}
