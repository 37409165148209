import React from 'react';

const TITLES = {
  MagValidationResponse: 'Mag Validation Response',
  VerificationResponse: 'Verification Response',
  AccountResponseCode: 'Account Response',
  CustomerResponseCode: 'Customer Response',
  payfacSubmissionResponse: 'Payfac Submission Response',
};

export default (reportObj, type) => {
  return (
    <div>
      <strong>{TITLES[type]}</strong>
      <ul>
        {reportObj?.Code && (
          <li>
            <strong>Code:</strong>
            <span
              className={
                'pass' === reportObj.Code.toLowerCase()
                  ? 'pass-log-item-value'
                  : ''
              }
            >{` ${reportObj.Code}`}</span>
          </li>
        )}
        <li>
          <strong>Description:</strong> {reportObj?.Description}
        </li>
        {reportObj?.Status && (
          <li>
            <strong>Status:</strong>{' '}
            <span
              className={
                'pass' === reportObj.Status.toLowerCase()
                  ? 'pass-log-item-value'
                  : ''
              }
            >{` ${reportObj.Status}`}</span>
          </li>
        )}
      </ul>
    </div>
  );
};
