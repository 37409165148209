import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

type LoadingTdProps = {
  index: number;
  width: number;
};

export const LoadingTd: React.FunctionComponent<LoadingTdProps> = props => {
  const { index, width } = props;

  let color = '#ebebeb';
  let highlightColor = '#f5f5f5';

  if (index % 2 === 0) {
    color = '#f9f9f9';
    highlightColor = '#f4f4f4';
  }

  return (
    <td>
      <SkeletonTheme color={color} highlightColor={highlightColor}>
        <Skeleton width={width} />
      </SkeletonTheme>
    </td>
  );
};
