import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import styled from 'styled-components';
import {
  FieldError,
  Form,
  Field,
  TextArea as UnstyledTextArea,
} from '../../shared';
import { coreapi } from '../../../api';
import { useToaster } from '../../../hooks';
import { UnderwritingSection } from './shared/UnderwritingSection';
import {
  SelectedMerchantStore,
  AuthStore,
  updateSelectedMerchantRegistration,
} from '../../../context';
import { debounce } from '../../../util/functional.util';
import { Icon } from '@fattmerchantorg/truffle-components';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
export interface NotesProps {}

const TextArea = styled(UnstyledTextArea)`
  width: 100%;
  height: 200px;
`;
const AutoSaved = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 16px;
  right: 16px;
  z-index: 2;
  background: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  color: #28cb35;
  width: 120px;

  > span > svg {
    margin-right: 8px;
  }
`;

const AUTO_SAVE_INTERVAL = 1000;
const AUTO_SAVE_TIP_LIFETIME = 3000;

type SavingState = 'idle' | 'saving' | 'saved';

const renderAutoSaveTip = (savingState: SavingState): ReactNode => {
  if (savingState === 'saved') {
    return (
      <AutoSaved>
        <Icon icon={['fas', 'check']} /> Note Saved
      </AutoSaved>
    );
  } else if (savingState === 'saving') {
    return (
      <AutoSaved>
        <Icon icon={faSpinnerThird} spin /> Saving Note
      </AutoSaved>
    );
  } else {
    return null;
  }
};

export function Notes(props: NotesProps) {
  const {
    dispatch,
    state: { merchantId, registration },
  } = useContext(SelectedMerchantStore);
  const { state: authState } = useContext(AuthStore);
  const { authToken } = authState;
  const { toaster, toast } = useToaster();
  const note = registration?.note;

  const [savingState, setSavingState] = useState<'idle' | 'saving' | 'saved'>(
    'idle'
  );

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;

    if (savingState === 'saved') {
      timeout = setTimeout(
        () => setSavingState('idle'),
        AUTO_SAVE_TIP_LIFETIME
      );
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [savingState]);

  const onSubmit = useCallback(
    async formValues => {
      setSavingState('saving');

      try {
        const updatedRegistration = await coreapi.put(
          authToken,
          `/merchant/${merchantId}/registration`,
          formValues
        );
        // set to 'saved' to show the 'Note Saved' tip
        setSavingState('saved');
        dispatch(updateSelectedMerchantRegistration(updatedRegistration));
      } catch (error) {
        toaster(toast.error(error, 'There was a problem saving the notes.'));
        // set back to 'idle' to prevent showing the 'Note Saved' tip
        setSavingState('idle');
      }
    },
    [merchantId, authToken, toaster, toast, dispatch]
  );

  const autoSave = useCallback(
    debounce((value: string) => onSubmit({ note: value }), AUTO_SAVE_INTERVAL),
    [onSubmit]
  );

  return (
    <UnderwritingSection
      title="Internal Notes"
      id="underwriting-notes"
      data-testid="underwriting-notes"
    >
      {({ setToolTipContent }) => {
        setToolTipContent('Underwriting Internal Notes');
        return (
          <div
            style={{ width: '100%', margin: '-8px 0 0 -6px' }}
            id="divFormContainer"
          >
            {renderAutoSaveTip(savingState)}
            <Form<NotesProps> onSubmit={onSubmit} initialValues={{ note }}>
              {formProps => (
                <div>
                  <Field name="note" placeholder="Notes">
                    {props => (
                      <TextArea
                        {...props.input}
                        onBlur={e => onSubmit({ note: e.currentTarget.value })}
                        onKeyUp={e => autoSave(e.currentTarget.value)}
                      />
                    )}
                  </Field>
                  <FieldError name="note" />
                </div>
              )}
            </Form>
          </div>
        );
      }}
    </UnderwritingSection>
  );
}
