import React from 'react';
import styled from 'styled-components';
import { staxPay } from '@fattmerchantorg/truffle-tokens';
import { ThemeProvider } from 'styled-components';

const ProgressBarWrapper = styled.div`
  width: 100%;
  max-width: 487px;
  height: 13px;
  background-color: #f0f0f0;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid ${props => props.theme.colors.core.gray['100'].hex};
`;

const ProgressBarInner = styled.div`
  height: 100%;
  transition: width 0.5s ease-in-out;
  width: ${props => props.percent || 0}%;
  background-color: ${props => props.theme.colors.core.purple['500'].hex};
`;

export const ProgressBar = ({ percent }) => {
  return (
    <ThemeProvider theme={staxPay}>
      <ProgressBarWrapper data-testid="statementV3-progress-bar">
        <ProgressBarInner percent={percent} />
      </ProgressBarWrapper>
    </ThemeProvider>
  );
};
