import axios from 'axios';
import get from 'lodash/get';
import { RegistrationAuditLog, Auth } from '@fattmerchantorg/types-omni';
import { getHeaders } from './api.util';

export type AutoVerificationResult = 'FAIL' | 'PASS' | null;
export type ReportsByEntityType = {
  business: RegistrationAuditLog[];
  person: RegistrationAuditLog[];
  internal: RegistrationAuditLog[];
  experian: RegistrationAuditLog[];
  coris: RegistrationAuditLog[];
  autoverificationResult: AutoVerificationResult[];
};

const injectCustomValues = (auditLogData: RegistrationAuditLog[]) => {
  const data = auditLogData || [];

  return data.map(audit => ({
    ...audit,
    status: getStatus(audit),
  }));
};

export type AuditLogReportStatus = 'success' | 'failure' | 'warning';

export const getStatus = (
  audit: RegistrationAuditLog
): AuditLogReportStatus => {
  const { serviceName, result } = audit;

  // get status for mag
  if (serviceName === 'mag') {
    const magStatus = get(result.MagValidationResponse, 'Status', false);
    if (magStatus === 'Pass') return 'success';
  }

  // get status for giact response
  if (serviceName === 'giact') {
    /**
     * render bank verification code _3333 as risk warning, not pass
     * https://fattmerchant.atlassian.net/browse/SPX-191
     */
    const giactCode = get(result.AccountResponseCode, 'Code', false);
    if (giactCode === '_3333') return 'warning';

    const giactStatus = get(result.VerificationResponse, 'Status', false);
    if (giactStatus === 'Reject') return 'failure';
    if (giactStatus === 'Risk Alert') return 'warning';
    if (giactStatus === 'Pass') return 'success';
  }

  // get status for gooleVerifyBusiness response
  if (serviceName === 'google places api') {
    const BusinessLegalNameResponse = get(
      result.BusinessLegalNameResponse,
      'status.Code',
      false
    );
    const BusinessDBAResponse = get(
      result.BusinessDBAResponse,
      'status.Code',
      false
    );

    if (BusinessLegalNameResponse === 'OK' || BusinessDBAResponse === 'OK')
      return 'success';
  }

  // get status for payfac response
  if (serviceName === 'payfac') {
    const payfacStatus = get(result.payfacSubmissionResponse, 'Status', false);
    if (payfacStatus === 'Failed') return 'failure';
    if (payfacStatus === 'Pass') return 'success';
  }

  if (serviceName === 'tincheck') {
    const tinResponseCode = get(
      audit,
      'result.TinCheckResponse.TinNameResponse.Code'
    );
    switch (tinResponseCode) {
      case '0':
      case '5':
      case '13':
        return 'failure';
      case '1':
      case '6':
      case '7':
      case '8':
        return 'success';
      default:
        return 'failure';
    }
  }
  // if nothing was matched, return failure by default
  return 'failure';
};

export const getAllAuditLogs = async (
  jwt: Auth['token'],
  merchantId: string
) => {
  try {
    const auditLog = await axios({
      url: `${process.env.REACT_APP_ONBOARDING_URL}/registration-audit-log/merchant/${merchantId}`,
      method: 'get',
      headers: getHeaders(jwt),
    });

    return injectCustomValues(auditLog.data);
  } catch (error) {
    throw error;
  }
};

export const getAutoVerifyAuditLog = async (
  jwt: Auth['token'],
  merchantId: string
) => {
  try {
    const auditLog = await axios({
      url: `${process.env.REACT_APP_ONBOARDING_URL}/registration-audit-log/auto-verify/merchant/${merchantId}`,
      method: 'get',
      headers: getHeaders(jwt),
    });

    return auditLog.data;
  } catch (error) {
    throw error;
  }
};

export const getAuditLog = async (jwt: Auth['token'], auditLogId: string) => {
  try {
    const auditLog = await axios({
      url: `${process.env.REACT_APP_ONBOARDING_URL}/registration-audit-log/${auditLogId}`,
      method: 'get',
      headers: getHeaders(jwt),
    });

    return await auditLog.data;
  } catch (error) {
    throw error;
  }
};

export const sortReportsByEntityType = (
  reports: RegistrationAuditLog[]
): ReportsByEntityType => {
  const business = [];
  const person = [];
  const internal = [];
  const experian = [];
  const coris = [];
  const autoverificationResult = [];
  reports.forEach((report, index) => {
    let matched = false;
    autoverificationResult.push(report.result?.autoverificationResult);
    const operationsRan = report.operationsRan?.split(',');
    // Tincheck, gIdentify, ESI return an 'EntityType' field which makes sorting easy
    if (
      [
        'tincheck',
        'gIdentify',
        'ESI',
        'mag',
        'gVerify',
        'gAuthenticate',
        'gOFAC',
        'business-search',
      ].some(operation => operationsRan?.includes(operation))
    ) {
      if (report.result?.EntityType === 'business') {
        matched = true;
        business.push(report);
      }
      if (report.result?.EntityType === 'person') {
        matched = true;
        person.push(report);
      }
      if (report.serviceName === 'mag') {
        matched = true;
        internal.push(report);
      }
      if (report.serviceName === 'coris') {
        matched = true;
        coris.push(report);
      }
    } else if (operationsRan?.includes('googleVerifyBusiness')) {
      matched = true;
      business.push(report);
    } else if (report.operationsRan === 'submission') {
      matched = true;
      internal.push(report);
    }
    if (!matched && Array.isArray(report.result?.errors)) {
      if (report.subjectType === 'business') {
        business.push(report);
      } else if (report.subjectType === 'principal_owner') {
        person.push(report);
      } else {
        internal.push(report);
      }
    }
    if (operationsRan?.includes('experian')) {
      experian.push(report);
    }
  });

  return {
    business: business,
    person: person,
    internal: internal,
    experian: experian,
    coris: coris,
    autoverificationResult,
  };
};

export type RegistrationAuditLogFieldError = {
  code: string;
  field: string;
  text: string;
};

export const parseMagFieldErrorsFromAuditLog = (
  auditLog: RegistrationAuditLog
): RegistrationAuditLogFieldError[] => {
  if (
    !auditLog ||
    auditLog.operationsRan !== 'mag' ||
    auditLog.serviceName !== 'mag'
  )
    return [];

  const errors = get(auditLog, 'result.errors', []);

  return errors.filter(error => error.code === 'mag');
};

export const parsePayfacFieldErrorsFromAuditLog = (
  auditLog: RegistrationAuditLog
): RegistrationAuditLogFieldError[] => {
  if (!auditLog || auditLog.serviceName !== 'payfac') return [];

  const errors = get(auditLog, 'result.errors', []);

  return errors.filter(
    error =>
      error.type === 'validation' ||
      error.type === 'CatanValidationError' ||
      error.code === 'payfac'
  );
};
