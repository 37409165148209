import strEnum from '../../util/strEnum';

/** FiniteStateMachine Statuses */
export const Status = strEnum(['OPEN', 'CLOSED']);
/** FiniteStateMachine Status Type */
export type Status = keyof typeof Status;

/* ActionTypes */
export const ActionTypes = strEnum([
  'USER_SELECTED_CLOSE',
  'USER_SELECTED_OPEN'
]);
