import { FormErrors } from '../../../../@types';
import { Registration } from '@fattmerchantorg/types-omni';

export const bankingInfoFields = [
  'bank_account_owner_name',
  'bank_routing_number',
  'bank_account_number',
  'bank_account_type',
  'secondary_bank_account_owner_name',
  'secondary_bank_routing_number',
  'secondary_bank_account_number',
  'secondary_bank_account_type',
  'secondary_bank_account_purpose',
] as const;

export type BankingInfoFields = typeof bankingInfoFields[number];

export type BankingInfoFieldsValues = Pick<Registration, BankingInfoFields>;

export type BankingInfoFieldsErrors = FormErrors<BankingInfoFieldsValues>;

export interface BankAccounts {
  account_id: string;
  name: string;
  mask: string;
  plaid_token_id: string;
  account_type: 'primary' | 'secondary';
}
