import React, { useContext, useState, useEffect } from 'react';
import { SelectedMerchantStore } from '../../../../context';
import { UnderwritingSection } from '../shared/UnderwritingSection';
import styled from 'styled-components';
import { formatCurrency } from '../../../../util/format.util';
import { ModalContext } from '../../../../context';
import { ProcessingInfoFormModal } from './ProcessingInfoFormModal';
import { valueOrHyphen, valueOrNA } from '../../../../util/string.util';
import { FieldGroupTitle } from '../shared/UnderwritingSection';

export interface ProcessingInfoProps {}

const DivRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 0 8px 0;
  border-bottom: 0px solid ${({ theme }) => theme.lightNeutralOutline};
`;

const SectionWrap = styled.div`
  padding: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Div50 = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${DivRow}:last-of-type {
    padding: 0;
  }
`;
const DivLeft = styled.div`
  width: 50%;
`;
const DivRight = styled.div`
  width: 50%;
  text-align: right;
`;
const DivContent = styled.div`
  width: 100%;
  display: block;
  font-size: 14px;

  ${FieldGroupTitle} {
    margin: 0 0 8px;
  }

  ${SectionWrap}:last-of-type {
  }
`;
const DivBottomSeparator = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.lightNeutralOutline};
  margin: 16px 0;
`;
const DivRightSeparator = styled.div`
  border-right: 1px solid ${({ theme }) => theme.lightNeutralOutline};
  margin: 0 8px;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export function ProcessingInfo(props: ProcessingInfoProps) {
  const { modalDispatch } = useContext(ModalContext);
  const {
    dispatch,
    state: { merchantId, registration },
  } = useContext(SelectedMerchantStore);

  const [regState, setRegState] = useState(registration);
  const [mId, setmId] = useState(merchantId);

  useEffect(() => {
    setRegState(registration);
    setmId(merchantId);
  }, [registration, merchantId]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        component: ProcessingInfoFormModal,
        props: {
          isOpen: true,
          registration: regState,
          merchantId: mId,
          merchantDispatch: dispatch,
        },
      },
    });
  };
  const ProcessingInfoShoppingCartCard = () => (
    <>
      <DivBottomSeparator />
      <SectionWrap>
        <Div50>
          <FieldGroupTitle>Shopping Cart</FieldGroupTitle>
          <DivRow>
            <DivLeft>Annual Gross Revenue</DivLeft>
            <DivRight data-testid="uw-card-present-percent">
              {valueOrHyphen(
                formatCurrency(registration?.annual_gross_shopping_cart_revenue)
              )}
            </DivRight>
          </DivRow>
          <DivRow>
            <DivLeft>Average Transaction Size</DivLeft>
            <DivRight data-testid="uw-card-not-present-percent">
              {valueOrHyphen(
                formatCurrency(registration?.avg_shopping_cart_transaction)
              )}
            </DivRight>
          </DivRow>
          <DivRow>
            <DivLeft> Largest Transaction Size</DivLeft>
            <DivRight data-testid="uw-card-swiped-percent">
              {valueOrHyphen(
                formatCurrency(registration?.largest_shopping_cart_transaction)
              )}
            </DivRight>
          </DivRow>
          <DivRow>
            <DivLeft> Card Present</DivLeft>
            <DivRight data-testid="uw-international">
              {registration?.shopping_cart_card_present_percent
                ? registration?.shopping_cart_card_present_percent + '%'
                : '-'}
            </DivRight>
          </DivRow>
          <DivRow>
            <DivLeft> International</DivLeft>
            <DivRight data-testid="uw-international">
              {registration?.shopping_cart_international
                ? registration?.shopping_cart_international + '%'
                : '-'}
            </DivRight>
          </DivRow>
          <DivRow>
            <DivLeft> B2B</DivLeft>
            <DivRight data-testid="uw-b2b-percent">
              {registration?.shopping_cart_b2b_percent
                ? registration?.shopping_cart_b2b_percent + '%'
                : '-'}
            </DivRight>
          </DivRow>
        </Div50>
      </SectionWrap>
    </>
  );
  return (
    <UnderwritingSection
      title="Processing Info"
      id="underwriting-processing-info"
      isEditable={true}
      onEdit={handleClick}
    >
      {({ setToolTipContent }) => {
        setToolTipContent('Processing volumes for credit cards and ACH.');
        return (
          <DivContent>
            <SectionWrap>
              <DivLeft>Refund Policy</DivLeft>
              <DivRight data-testid="uw-refund-policy">
                {valueOrNA(registration?.refund_policy)}
              </DivRight>
            </SectionWrap>
            <DivBottomSeparator />
            <SectionWrap>
              <Div50>
                <FieldGroupTitle>Credit Card</FieldGroupTitle>
                <DivRow>
                  <DivLeft>Annual Gross Revenue</DivLeft>
                  <DivRight data-testid="uw-annual-volume">
                    {valueOrHyphen(formatCurrency(registration?.annual_volume))}
                  </DivRight>
                </DivRow>
                <DivRow>
                  <DivLeft>Average Transaction Size</DivLeft>
                  <DivRight data-testid="uw-avg-trans-size">
                    {valueOrHyphen(
                      formatCurrency(registration?.avg_trans_size)
                    )}
                  </DivRight>
                </DivRow>
                <DivRow>
                  <DivLeft>Largest Transaction Size</DivLeft>
                  <DivRight data-testid="uw-highest-trans-amount">
                    {valueOrHyphen(
                      formatCurrency(registration?.highest_trans_amount)
                    )}
                  </DivRight>
                </DivRow>
              </Div50>
              <DivRightSeparator />
              <Div50>
                <FieldGroupTitle>ACH</FieldGroupTitle>
                <DivRow>
                  <DivLeft>Annual Gross Revenue</DivLeft>
                  <DivRight data-testid="uw-annual-gross-ach-revenue">
                    {valueOrHyphen(
                      formatCurrency(registration?.annual_gross_ach_revenue)
                    )}
                  </DivRight>
                </DivRow>
                <DivRow>
                  <DivLeft>Average Transaction Size</DivLeft>
                  <DivRight data-testid="uw-avg-ach-transaction">
                    {valueOrHyphen(
                      formatCurrency(registration?.avg_ach_transaction)
                    )}
                  </DivRight>
                </DivRow>
                <DivRow>
                  <DivLeft>Largest Transaction Size</DivLeft>
                  <DivRight data-testid="uw-largest-ach-transaction">
                    {valueOrHyphen(
                      formatCurrency(registration?.largest_ach_transaction)
                    )}
                  </DivRight>
                </DivRow>
              </Div50>
            </SectionWrap>
            <DivBottomSeparator></DivBottomSeparator>
            <SectionWrap>
              <Div50>
                <DivRow>
                  <DivLeft>Total Annual Gross Revenue</DivLeft>
                  <DivRight data-testid="uw-total-annual-gross-revenue">
                    {formatCurrency(
                      (+registration?.annual_volume || 0) +
                        (+registration?.annual_gross_ach_revenue || 0)
                    )}
                  </DivRight>
                </DivRow>
              </Div50>
              <DivRightSeparator></DivRightSeparator>
              <Div50>
                <DivRow>
                  <DivLeft>Overall Max Transaction Size</DivLeft>
                  <DivRight data-testid="uw-overall-max-transaction-size">
                    {formatCurrency(
                      Math.max(
                        +registration?.largest_ach_transaction || 0,
                        +registration?.highest_trans_amount || 0
                      )
                    )}
                  </DivRight>
                </DivRow>
              </Div50>
            </SectionWrap>

            {registration?.chosen_processing_method === 'shopping-cart' ? (
              <ProcessingInfoShoppingCartCard />
            ) : null}
            <DivBottomSeparator></DivBottomSeparator>
            <SectionWrap>
              <FieldGroupTitle>Processing Percentage</FieldGroupTitle>
            </SectionWrap>
            <SectionWrap>
              <Column>
                <span>Card Present</span>
                <span data-testid="uw-card-present-percent">
                  {registration?.card_present_percent
                    ? registration?.card_present_percent + '%'
                    : '-'}
                </span>
              </Column>
              <Column>
                <span>Card Not Present</span>
                <span data-testid="uw-card-not-present-percent">
                  {registration?.card_not_present_percent
                    ? registration?.card_not_present_percent + '%'
                    : '-'}
                </span>
              </Column>
              <Column>
                <span> Card Swipe</span>
                <span data-testid="uw-card-swiped-percent">
                  {registration?.card_swiped_percent
                    ? registration?.card_swiped_percent + '%'
                    : '-'}
                </span>
              </Column>
              <Column>
                <span> Moto</span>
                <span data-testid="uw-moto-percent">
                  {registration?.moto_percent
                    ? registration?.moto_percent + '%'
                    : '-'}
                </span>
              </Column>
              <Column>
                <span> Internet</span>
                <span data-testid="uw-internet">
                  {registration?.internet ? registration?.internet + '%' : '-'}
                </span>
              </Column>
              <Column>
                <span> International</span>
                <span data-testid="uw-international">
                  {registration?.international
                    ? registration?.international + '%'
                    : '-'}
                </span>
              </Column>
              <Column>
                <span> B2B</span>
                <span data-testid="uw-b2b-percent">
                  {registration?.b2b_percent
                    ? registration?.b2b_percent + '%'
                    : '-'}
                </span>
              </Column>
            </SectionWrap>
          </DivContent>
        );
      }}
    </UnderwritingSection>
  );
}
