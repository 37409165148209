import {
  Registration,
  RegistrationRepresentative,
} from '@fattmerchantorg/types-omni';
import {
  AdditionalRepresentativesFieldsValues,
  AdditionalRepresentativesFieldsErrors,
} from './AdditionalRepresentativesForm.types';
import { Mutator } from 'final-form';
import {
  isValidEmail,
  isValidPhoneNumber,
  isValidOpenDate,
  isAddressPoBox,
} from '../../../../util/validator.util';
import { formatDate } from '../../../../util';

export function mapFormValuesToPayload(
  formValues: AdditionalRepresentativesFieldsValues
): Partial<Registration> {
  return {
    ...formValues,
    meta: {
      ...formValues,
      representatives: formValues.representatives.map(rep => {
        rep.date_of_birth = formatDate(rep.date_of_birth);
        return rep;
      }),
    },
  };
}

export function mapRegistrationToInitialFormValues(
  registration: Registration
): AdditionalRepresentativesFieldsValues {
  return {
    ...registration?.meta,
    representatives: registration?.meta?.representatives || [],
  } as AdditionalRepresentativesFieldsValues;
}

export const formMutators: {
  [key: string]: Mutator<AdditionalRepresentativesFieldsValues>;
} = {
  setFieldValue: ([name, value], state, utils) => {
    utils.changeValue(state, name, _ => value);
  },
  addAdditionalRepresentative: (_, state, utils) => {
    utils.changeValue(state, 'representatives', representatives => [
      ...representatives,
      {
        title: null,
        date_of_birth: null,
        ssn: null,
        first_name: null,
        last_name: null,
        type: null,
        ownership_percentage: null,
        email: null,
        phone: null,
        address_1: null,
        address_2: null,
        address_city: null,
        address_state: null,
        address_country: null,
        address_zip: null,
        citizenship: 'USA',
      },
    ]);
  },
  removeAdditionalRepresentative: ([index], state, utils) => {
    utils.changeValue(state, 'representatives', representatives => {
      const newRepresentatives = [...representatives];
      newRepresentatives.splice(index, 1);
      return newRepresentatives;
    });
  },
  setSameAsOwner: ([i, stat, registration], state, utils) => {
    utils.changeValue(state, 'representatives', representatives => {
      const newRep = [...representatives];
      if (stat) {
        newRep[i] = {
          title: registration.title,
          date_of_birth: registration.user_dob,
          ssn: registration.user_ssn,
          first_name: registration.first_name,
          last_name: registration.last_name,
          ownership_percentage: registration.meta?.ownership_percentage,
          email: registration.email,
          phone: registration.phone_number,
          address_1: registration.owner_address_1,
          address_2: registration.owner_address_2,
          address_city: registration.owner_address_city,
          address_state: registration.owner_address_state,
          address_zip: registration.owner_address_zip,
          address_country: registration.owner_address_country,
          citizenship: registration.meta?.owner_citizenship,
          type: null,
        };
      } else {
        newRep[i] = {
          title: null,
          date_of_birth: null,
          ssn: null,
          first_name: null,
          last_name: null,
          ownership_percentage: null,
          email: null,
          phone: null,
          address_1: null,
          address_2: null,
          address_city: null,
          address_state: null,
          address_zip: null,
          type: null,
          address_country: null,
          citizenship: 'USA',
        };
      }
      return newRep;
    });
  },
};

export function validateFormValues(
  values: AdditionalRepresentativesFieldsValues,
  repIndex: number
): AdditionalRepresentativesFieldsErrors {
  const errors: AdditionalRepresentativesFieldsErrors = {};

  const makeErrorsRepresentativesIndex = () => {
    if (!errors.representatives) errors.representatives = [];
    if (!errors.representatives[repIndex])
      errors.representatives[repIndex] = {} as any;
  };

  const requiredSignerFields: (keyof RegistrationRepresentative)[] = [
    'type',
    'title',
    'first_name',
    'last_name',
    'ownership_percentage',
    // 'email',
    'date_of_birth',
    'ssn',
    'phone',
    'address_1',
    'address_city',
    // 'address_state', // state is handled manually below because some countries don't have states
    'address_zip',
  ];

  const representative: RegistrationRepresentative =
    values.representatives[repIndex] || ([] as any);

  requiredSignerFields.forEach(requiredField => {
    let error: string;
    const value = representative[requiredField];

    if (requiredField === 'ownership_percentage') {
      if (isNaN(+value)) {
        error = 'Must be a number';
      } else if (+value > 100) {
        error = 'Must not be over 100%';
      }
    } else if (requiredField === 'ssn') {
      const ssnDigits = ((value as string) || '').replace(/[\D]/g, '');
      if (ssnDigits.length !== 9) {
        error = 'Must be 9 characters';
      }
    } else if (requiredField === 'phone') {
      if (!isValidPhoneNumber(value as string)) {
        error = 'Please enter a valid phone number';
      }
    } else if (requiredField === 'date_of_birth') {
      if (value && !isValidOpenDate(formatDate(value as string), 200)) {
        error = 'Must be a valid birthdate';
      }
    }

    if (!value) error = 'Required';

    if (error) {
      makeErrorsRepresentativesIndex();
      errors.representatives[repIndex][requiredField] = error;
    }
  });

  if (representative.email && !isValidEmail(representative.email)) {
    makeErrorsRepresentativesIndex();
    errors.representatives[repIndex]['email'] = 'Please enter a valid email';
  }

  if (
    ['USA', 'CAN'].includes(representative.address_country) &&
    (representative.address_state === '' ||
      representative.address_state == null)
  ) {
    makeErrorsRepresentativesIndex();
    errors.representatives[repIndex]['address_state'] =
      'Please enter a valid state or province';
  }

  if (representative.address_1 && isAddressPoBox(representative.address_1)) {
    makeErrorsRepresentativesIndex();
    errors.representatives[repIndex]['address_1'] =
      'Address cannot be a PO box';
  }

  if (representative.address_2 && isAddressPoBox(representative.address_2)) {
    makeErrorsRepresentativesIndex();
    errors.representatives[repIndex]['address_2'] =
      'Address cannot be a PO box';
  }

  return errors;
}
