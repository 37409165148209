import React, { FC } from 'react';
import styled from 'styled-components';
import { Icon } from '@fattmerchantorg/truffle-components';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import {
  faTimes,
  faExclamationTriangle,
} from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

const Container = styled.div`
  &[data-type='success'] {
    background: ${({ theme }) => theme.colors.status.green[800].hex};
  }

  &[data-type='info'] {
    background: ${({ theme }) => theme.colors.status.blue[500].hex};
  }

  &[data-type='info-alt'] {
    background: ${({ theme }) => theme.colors.status.blue[100].hex};
  }

  &[data-type='warning'] {
    background: ${({ theme }) => theme.colors.status.yellow[500].hex};
  }

  &[data-type='error'] {
    background: ${({ theme }) => theme.colors.status.red[500].hex};
  }

  &[data-type='neutral'] {
    background: ${({ theme }) => theme.colors.core.gray[400].hex};
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  position: relative;

  div,
  span {
    color: ${({ theme }) => theme.black};
  }
`;

const Message = styled.div`
  font-size: 12px;
  font-weight: 700;

  &:not(:first-child) {
    padding-left: 6px;
  }
`;

const Description = styled.div`
  font-size: 12px;
  padding-left: 6px;
`;

export interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Type of Alert styles
   */
  type?: 'success' | 'info' | 'warning' | 'error' | 'info-alt' | 'neutral';
  /** Content of Alert */
  message: React.ReactNode;
  /** Additional content of Alert */
  description?: React.ReactNode;
  /** Whether to show icon */
  showIcon?: boolean;
}

const mapTypeToIcon = (type: AlertProps['type']) => {
  switch (type) {
    case 'success':
      return faCheck;
    case 'warning':
      return faExclamationTriangle;
    case 'error':
      return faTimes;
    case 'info':
    case 'info-alt':
    case 'neutral':
      return faInfoCircle;
  }
};

export const Alert: FC<AlertProps> = props => {
  const { type, message, description, showIcon, ...divProps } = props;

  return (
    <Container {...divProps} data-type={type}>
      {showIcon && (
        <Icon icon={mapTypeToIcon(type) as FontAwesomeIconProps['icon']} />
      )}
      <Message>{message}</Message>
      <Description>{description}</Description>
    </Container>
  );
};

Alert.defaultProps = {
  type: 'info',
  showIcon: true,
};
