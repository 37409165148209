import React, { FunctionComponent, useState, useEffect } from 'react';
import {
  useAsyncEffect,
  useAuthToken,
  useSearchState,
  usePermissions,
} from '../../../hooks';
import styled from 'styled-components';
import { faShieldCheck } from '@fortawesome/pro-regular-svg-icons';
import { DRAWER_CONFIGS, individualSectionConfigs } from './drawer-configs';
import { drawerTypes, TransactionData } from './AdvancedDetailDrawer.types';
import {
  Drawer,
  SecondaryButton,
  Text,
  Icon,
} from '@fattmerchantorg/truffle-components';
import { CopyButton, LoadingSpan, StatusPanel } from '../../shared';
import TransactionStatusPill from '../../transactions/components/TransactionStatusPill';
import {
  getDataForTransactionDrawer,
  mapFeeName,
} from './TransactionDetailDrawer.util';
import useDetailsModalHook, {
  TransactionAsyncDataStatus,
} from '../../transactions/components/useDetailsModalHook';
import {
  formatCurrency,
  formatTruncatedId,
  formatReadableDateWithSeparator,
} from '../../../util/format.util';
import TransactionRefundModal from '../../transactions/components/TransactionRefundModal';
import TransactionVoidModal from '../../transactions/components/TransactionVoidModal';
import { Merchant } from '@fattmerchantorg/types-omni';
import { startCase } from 'lodash';
import { isAdjustmentTransaction } from '../../../util/transaction.util';
import { AdjustmentTransaction } from '../../transactions/AdjustmentTransactions/AdjustmentTransactions.types';

type DrawerProps = {
  configType: keyof typeof drawerTypes;
  getData: ((id: string) => Promise<TransactionData>) | null;
  data?: Partial<TransactionData>;
  isSettlement?: boolean;
  merchant?: Merchant;
  open: boolean;
  onClose?: () => void;
};

const Section = styled.div`
  padding: 16px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
`;
const BottomBar = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:first-child) {
    margin-top: 8px;
  }

  small {
    font-size: 12px;
  }

  > *:not(:first-child) {
    margin-left: 8px;
  }
`;

const LineItemRow = styled(Row)`
  > *:not(:only-child):last-child {
    margin-left: auto;
  }
`;

const DateRowContainer = styled(LineItemRow)`
  align-items: baseline;
  font-size: 14px;
  font-weight: 400px;
  line-height: 20px;

  > small {
    margin-left: 2px !important;
  }
`;

const HeaderText = styled.div`
  font-size: 14px;
  font-weight: 700px;
  line-height: 20px;
`;

const WarningContainer = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.status.yellow['200'].hex};
  display: flex;
  padding: 8px 16px;
  margin-bottom: 16px;
  p {
    color: ${({ theme }) => theme.colors.status.yellow['700'].hex};
    font-size: 12px;
    font-weight: bold;
    margin: 0 0 0 6px;
  }
  svg {
    color: ${({ theme }) => theme.colors.status.yellow['700'].hex};
  }
`;

const TransactionTypeText = styled.span`
  padding: 0;
`;

const DateRow: FunctionComponent<{
  transaction?: Partial<TransactionData>;
}> = props => (
  <DateRowContainer>
    <span>
      {formatReadableDateWithSeparator(String(props.transaction?.created_at))}
    </span>
    {props.children}
  </DateRowContainer>
);

const CopyAuthCodeButton = styled(CopyButton)`
  color: ${({ theme }) => theme.colors.core.gray[200].hex};
  font-size: 16px;

  > span:last-child {
    margin-left: 4px;
  }
`;

const LoadingStateWrapper = styled.div`
  padding: 10px;
`;

const isAVSError = row => {
  return row.message?.toLowerCase() === 'avs rejected' && row.avs_message;
};
const AuthCode: FunctionComponent<{
  transaction: Partial<TransactionData>;
}> = props => {
  const authCode =
    props.transaction?.auth_id ||
    props.transaction?.issuer_auth_code ||
    props.transaction?.authorization_code;
  return authCode ? (
    <CopyAuthCodeButton
      variant="plain"
      content={authCode}
      tooltip="Copy Transaction Auth Code to clipboard"
      icon={<Icon icon={faShieldCheck} />}
    >
      <span>{formatTruncatedId(authCode, 6)}</span>
    </CopyAuthCodeButton>
  ) : null;
};

export const TransactionDetailDrawer: FunctionComponent<
  DrawerProps
> = props => {
  const { configType, getData, data, isSettlement, merchant, ...drawerProps } =
    props;
  const { title, testId, sections } = DRAWER_CONFIGS[configType];
  const [sectionData, setSectionData] = useState({});
  const [state, dispatch] = useDetailsModalHook(getData);
  const loadingTransaction =
    (state.status === TransactionAsyncDataStatus.INITIAL ||
      state.status === TransactionAsyncDataStatus.LOADING) &&
    !isSettlement;
  const transaction = isSettlement
    ? data
    : (state.data as Partial<TransactionData>);
  const token = useAuthToken();
  const isDispute = transaction?.dispute ?? false;
  const { permit } = usePermissions();
  const [loadingTransactionData, setLoadingTransactionData] = useState(false);

  useAsyncEffect(async () => {
    setLoadingTransactionData(true);
    const data = await getDataForTransactionDrawer(
      transaction,
      token,
      isSettlement,
      configType
    );
    setSectionData(data);
    sections.forEach(sectionType => {
      const { requiredDataKey } = individualSectionConfigs[sectionType];
      if (!data[requiredDataKey]) {
        console.warn(`Missing data for key: ${requiredDataKey}`);
      }
    });
    setLoadingTransactionData(false);
  }, [transaction]);
  const [r, setIsRefundModalOpen] = useSearchState('r');
  const [v, setIsVoidModalOpen] = useSearchState('v');

  const isRefundModalOpen = !!+r;
  const isVoidModalOpen = !!+v;

  useEffect(() => {
    if (!transaction) return;

    // prevent user from trying to force the modal open
    // if they're real sneaky
    if (transaction.is_voidable && isRefundModalOpen)
      setIsRefundModalOpen(null);
    if (transaction.is_refundable && isVoidModalOpen) setIsVoidModalOpen(null);
  }, [
    transaction,
    isRefundModalOpen,
    isVoidModalOpen,
    setIsRefundModalOpen,
    setIsVoidModalOpen,
  ]);
  const getDisputePillStatus = {
    LOST: 'error',
    WON: 'success',
    INQUIRY: 'warning',
    OPEN: 'warning',
    PENDING: 'warning',
    EVIDENCE_UPLOADED: 'warning',
    UPLOAD_FAILED: 'warning',
  };
  const getDisputeDescription = (
    status: string
  ): { label?: string; description?: string } => {
    if (status === 'LOST') {
      return { label: 'Dispute Lost', description: 'Dispute was lost.' };
    } else if (status === 'PREARBITRATION') {
      return {
        description:
          'Dispute re-opened in Pre-Arbitration and funds are being held.',
      };
    } else if (status === 'WON') {
      return { label: 'Dispute Won', description: 'Dispute was won.' };
    } else if (status === 'PREARBITRATION') {
      return {
        description:
          'Dispute re-opened in Pre-Arbitration and funds are being held.',
      };
    } else if (status === 'INQUIRY') {
      return {
        description: 'There is an open dispute inquiry on this payment.',
      };
    } else if (
      ['OPEN', 'PENDING', 'EVIDENCE_UPLOADED', 'UPLOAD_FAILED'].includes(status)
    ) {
      return {
        description:
          'A dispute has been opened and funds are being held until a determination has been made.',
      };
    }
    return { label: '', description: '' };
  };
  const renderVoidAlert = () => {
    const { gateway_name, is_voided, method, child_transactions } = transaction;
    if (
      child_transactions?.length > 0 &&
      is_voided &&
      method === 'bank' &&
      ['forte', 'finix', 'finix_sandbox'].indexOf(gateway_name.toLowerCase()) >=
        0
    ) {
      // Use the first child transaction for the meta and description
      const meta = { ...child_transactions[0].meta };
      return (
        <LineItemRow>
          <StatusPanel
            status="warning"
            style={{ marginTop: '16px', width: '100%' }}
          >
            {(meta.forteStatus || meta.achStatus || 'declined').toUpperCase()}:{' '}
            {meta.forteDescription ||
              meta.achDescription ||
              'Initial transaction did not settle.'}
          </StatusPanel>
        </LineItemRow>
      );
    }
    return null;
  };
  const renderDisputeAlert = () => {
    const { dispute } = transaction;
    if (dispute) {
      return (
        <LineItemRow>
          <StatusPanel
            data-testid="dispute-status-panel"
            status={
              getDisputePillStatus[transaction.dispute?.status]
                ? getDisputePillStatus[transaction.dispute.status]
                : 'warning'
            }
            animated
            style={{ marginTop: '16px', width: '100%' }}
          >
            <div style={{ display: 'inline', paddingLeft: '8px' }}>
              {getDisputeDescription(transaction.dispute.status)?.description}
              {permit('godview', 'accessSubmerchants', 'read') && (
                <a
                  data-testid="payment-dispute-link"
                  href={`/#/merchant/${transaction.dispute?.merchant_id}/disputes?detailId=${transaction.dispute?.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: 'inherit',
                    textDecoration: 'underline',
                    paddingLeft: '5px',
                  }}
                >
                  View Dispute
                </a>
              )}
            </div>
          </StatusPanel>
        </LineItemRow>
      );
    }
  };
  return (
    <Drawer
      {...drawerProps}
      anchor="right"
      title={title}
      data-testid={testId}
      customStyles={{
        borderRadius: 0,
        padding: 0,
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
      footer={
        !isSettlement &&
        !isDispute &&
        (transaction?.is_refundable || transaction?.is_voidable) &&
        permit('godview', 'voidOrRefundSubmerchantTransactions') ? (
          <BottomBar>
            {transaction?.is_refundable ? (
              <SecondaryButton onClick={() => setIsRefundModalOpen('1')}>
                Refund
              </SecondaryButton>
            ) : (
              <SecondaryButton onClick={() => setIsVoidModalOpen('1')}>
                Void
              </SecondaryButton>
            )}
          </BottomBar>
        ) : null
      }
    >
      <div data-testid="transaction-details-drawer">
        <TransactionRefundModal
          state={state}
          dispatch={dispatch}
          isOpen={isRefundModalOpen}
          onClose={() => setIsRefundModalOpen(null)}
          merchant={merchant}
        />
        <TransactionVoidModal
          state={state}
          dispatch={dispatch}
          isOpen={isVoidModalOpen}
          onClose={() => setIsVoidModalOpen(null)}
          merchant={merchant}
        />
        {loadingTransaction || loadingTransactionData ? (
          <LoadingStateWrapper>
            <Row>
              <LoadingSpan height="42px" width="30%" />
              <LoadingSpan height="42px" width="50%" />
              <LoadingSpan height="42px" width="20%" />
            </Row>
            <Row>
              <LoadingSpan height="42px" width="30%" />
              <LoadingSpan height="42px" width="50%" />
              <LoadingSpan height="42px" width="20%" />
            </Row>
            <Row>
              <LoadingSpan height="42px" width="30%" />
              <LoadingSpan height="42px" width="50%" />
              <LoadingSpan height="42px" width="20%" />
            </Row>
          </LoadingStateWrapper>
        ) : (
          <>
            {transaction && (
              <Section>
                <LineItemRow>
                  <Text as="h4">
                    {isSettlement && transaction.type === 'HOLD' ? '-' : ''}
                    {formatCurrency(
                      transaction.total || transaction.amount || 0
                    )}
                  </Text>
                  <TransactionStatusPill
                    transaction={transaction}
                    location="drawer"
                    isSettlement={isSettlement}
                  />
                  {isSettlement && transaction.type === 'FEE' && (
                    <HeaderText>
                      {transaction.fee_description ||
                        mapFeeName(transaction.subtype) ||
                        ''}
                    </HeaderText>
                  )}
                  {isSettlement &&
                    (transaction.type === 'HOLD' ||
                      transaction.type === 'RELEASE') && (
                      <HeaderText>Dispute</HeaderText>
                    )}
                  {isAdjustmentTransaction(
                    transaction as unknown as AdjustmentTransaction
                  ) && <TransactionTypeText>Adjustment</TransactionTypeText>}
                  <CopyButton
                    content={
                      transaction.parent_transaction_id ||
                      transaction.id ||
                      transaction.transaction_id
                    }
                    tooltip="Copy Transaction ID to clipboard"
                  >
                    <Icon icon={['fas', 'copy']} size="1x" />
                  </CopyButton>
                </LineItemRow>
                {!isSettlement && !transaction.success && (
                  <LineItemRow>
                    <StatusPanel
                      status="error"
                      animated
                      style={{ marginTop: '16px', width: '100%' }}
                    >
                      {transaction?.message}
                      {isAVSError(transaction)
                        ? `: ${transaction?.avs_message}`
                        : null}
                    </StatusPanel>
                  </LineItemRow>
                )}
                {transaction && renderVoidAlert()}
                {transaction?.dispute && renderDisputeAlert()}
                <DateRow transaction={transaction}>
                  <AuthCode transaction={transaction} />
                </DateRow>
              </Section>
            )}
            {!isSettlement && transaction?.status === 'PENDING' && (
              <WarningContainer>
                <Icon icon={['fas', 'exclamation-triangle']} />
                <Text as="p">
                  Payment Pending: Please do not retry the transaction as it may
                  cause duplicate charges. Your payment will update
                  automatically. If it remains pending for more than 24 hours,
                  please contact customer support.
                </Text>
              </WarningContainer>
            )}
            {sections.map(sectionType => {
              const sectionName = startCase(sectionType as string);
              const SectionComponent =
                individualSectionConfigs[sectionType].component;
              return (
                <SectionComponent
                  key={sectionType}
                  data={
                    sectionData[
                      individualSectionConfigs[sectionType].requiredDataKey
                    ]
                  }
                  name={sectionName}
                  id={individualSectionConfigs[sectionType].id}
                  isSettlement={isSettlement}
                />
              );
            })}
            <br />
          </>
        )}
      </div>
    </Drawer>
  );
};
