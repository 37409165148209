import { FormModalFieldMap } from '../../../../context';
import { businessInfoFields } from '../../../../components/business-info/BusinessInfoForm.types';
import {
  billingAddressFields,
  dbaLocationAddressFields,
} from '../../../../components/addresses/AddressForm.types';
import { additionalRepresentativesFields } from '../../../../components/additional-representatives/AdditionalRepresentativesForm.types';
import { ownerSignerInfoFields } from '../../../../components/owner-signer-info/OwnerSignerInfoForm.types';
import { pricingInfoFields } from '../../../../components/pricing-info/PricingInfoForm.types';
import { processingEnvironmentFields } from '../../../../components/processing-environment/ProcessingEnvironment.types';
import { processingInfoFields } from '../../../../components/processing-info/ProcessingInfoForm.types';
import { bankingInfoFields } from '../../../../components/banking-info/BankingInfoForm.types';
import { SectionTitles } from '../../../../constants/UnderwritingSectionTitles';
import { AdditionalRepresentativesFormModal } from '../../../../components/additional-representatives/AdditionalRepresentativesFormModal';
import { BusinessInfoFormModal } from '../../../../components/business-info/BusinessInfoFormModal';
import { BillingAddressFormModal } from '../../../../components/addresses/BillingAddressFormModal';
import { DBALocationAddressFormModal } from '../../../../components/addresses/DBALocationAddressFormModal';
import { OwnerSignerInfoFormModal } from '../../../../components/owner-signer-info/OwnerSignerInfoFormModal';
import { PricingInfoFormModal } from '../../../../components/pricing-info/PricingInfoFormModal';
import { ProcessingEnvironmentFormModal } from '../../../../components/processing-environment/ProcessingEnvironmentFormModal';
import { ProcessingInfoFormModal } from '../../../../components/processing-info/ProcessingInfoFormModal';
import { BankingInfoFormModal } from '../../../../components/banking-info/BankingInfoFormModal';

/**
 * This data structure enables the UI to understand which registration fields
 * exist in which modals. So, if there are validation issues with the
 * application upon submission we have a way to get the user to the appropriate
 * modal to fix the issue instead of making them hunt for the field.
 */
export const formModalFieldMap: FormModalFieldMap = {
  AdditionalRepresentativesFormModal: {
    fields: additionalRepresentativesFields,
    title: SectionTitles.AdditionalRepresentatives,
    component: AdditionalRepresentativesFormModal,
  },
  BusinessInfoFormModal: {
    fields: businessInfoFields,
    title: SectionTitles.BusinessInfo,
    component: BusinessInfoFormModal,
  },
  BillingAddressFormModal: {
    fields: billingAddressFields,
    title: SectionTitles.BillingAddress,
    component: BillingAddressFormModal,
  },
  DBALocationAddressFormModal: {
    fields: dbaLocationAddressFields,
    title: SectionTitles.DBALocationAddress,
    component: DBALocationAddressFormModal,
  },
  OwnerSignerInfoFormModal: {
    fields: ownerSignerInfoFields,
    title: SectionTitles.OwnerSignerInfo,
    component: OwnerSignerInfoFormModal,
  },
  PricingInfoFormModal: {
    fields: pricingInfoFields,
    title: SectionTitles.PricingInfo,
    component: PricingInfoFormModal,
  },
  ProcessingEnvironmentFormModal: {
    fields: processingEnvironmentFields,
    title: SectionTitles.ProcessingEnvironment,
    component: ProcessingEnvironmentFormModal,
  },
  ProcessingInfoFormModal: {
    fields: processingInfoFields,
    title: SectionTitles.ProcessingInfo,
    component: ProcessingInfoFormModal,
  },
  BankingInfoFormModal: {
    fields: bankingInfoFields,
    title: SectionTitles.BankingInfo,
    component: BankingInfoFormModal,
  },
  ErrorFieldNotFoundInfo: {
    fields: [],
    title: 'Other',
    component: null,
  },
};

/**
 * This function is responsible for taking a registration field name and
 * returning details about the Form Modal component the field exists in.
 *
 * It will return an object containing the fields that exist in the form modal,
 * the title of the UI section the form is responsible for, and a refernce to
 * the actual react modal component.
 *
 * @param fieldNameQuery The registration record field name to search with
 */
export const getModalInfoByRegistrationField = (
  fieldNameQuery: string
): FormModalFieldMap[keyof FormModalFieldMap] => {
  const modalName = Object.keys(formModalFieldMap).find(
    (modalKey: keyof FormModalFieldMap) => {
      return formModalFieldMap[modalKey].fields.some((fieldName: string) => {
        if (fieldNameQuery && fieldName.endsWith('*')) {
          return fieldNameQuery.startsWith(
            fieldName.substring(0, fieldName.length - 1)
          );
        } else {
          return fieldName === fieldNameQuery;
        }
      });
    }
  );

  // add to other if no modal detected
  if (!modalName || !formModalFieldMap.hasOwnProperty(modalName)) {
    return formModalFieldMap['ErrorFieldNotFoundInfo'];
  }

  return formModalFieldMap[modalName];
};
