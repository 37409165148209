import React, { useState, useCallback, useContext } from 'react';
import {
  Registration,
  RegistrationRepresentative,
} from '@fattmerchantorg/types-omni';
import { RadioLabel } from '../shared/UnderwritingPartialForm';
import styled from 'styled-components';
import {
  BaseModal,
  ModalHeader,
  ModalFooter,
  PrimaryButton,
  SecondaryButton,
} from '@fattmerchantorg/truffle-components';
import { ModalContext } from '../../../../context';
import { Auth } from '@fattmerchantorg/types-omni';
import { Signer } from './ExternalVerification.types';

const StyledModalContent = styled.div`
  grid-area: content;
  overflow-y: auto;
  padding: ${({ theme }) => theme.space[4]}px;
  color: ${({ theme }) => theme.component.modal.color};
`;

const ButtonBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  > * {
    margin: 0 0 0 ${({ theme }) => theme.space[4]}px;
  }
`;

interface SignerSelectionModalProps {
  isOpen?: boolean;
  onRunVerification: (selectedSigner: Signer) => any;
  registration: Registration;
  authToken: Auth['token'];
}

export const SignerSelectionModal: React.FunctionComponent<
  SignerSelectionModalProps
> = props => {
  const { registration, onRunVerification } = props;
  const { modalDispatch } = useContext(ModalContext);
  const [isModalVisible] = useState(!!props.isOpen);
  const collectSigners = useCallback((): Signer[] => {
    const beneficialOwners: Signer[] = Array.from(
      registration?.meta?.representatives,
      (rep: RegistrationRepresentative) => {
        return {
          ...rep,
          first_name: rep.first_name,
          last_name: rep.last_name,
          user_dob: rep.date_of_birth,
          email: rep.email,
          address_1: rep.address_1,
          address_2: rep.address_2,
          address_city: rep.address_city,
          address_state: rep.address_state,
          address_zip: rep.address_zip,
          address_country: rep.address_country,
          phone_number: rep.phone,
          user_ssn: rep.ssn,
        };
      }
    );

    const principalOwner: Signer = {
      first_name: registration.first_name,
      last_name: registration.last_name,
      user_dob: registration.user_dob,
      email: registration.email,
      address_1: registration.owner_address_1,
      address_2: registration.owner_address_2,
      address_city: registration.owner_address_city,
      address_state: registration.owner_address_state,
      address_zip: registration.owner_address_zip,
      address_country: registration.owner_address_country,
      phone_number: registration.phone_number,
      user_ssn: registration.user_ssn,
    };

    return [principalOwner, ...beneficialOwners];
  }, [registration]);
  const [signers] = useState(collectSigners());
  const [selectedSigner, setSelectedSigner] = useState<number>(0);

  const handleSelectSigner = (e: React.ChangeEvent<HTMLInputElement>) => {
    const index: number = parseInt(e.target.value, 10);
    setSelectedSigner(index || 0);
  };

  const handleCloseModal = () => {
    modalDispatch({
      type: 'CLOSE_MODAL',
    });
  };

  return (
    <BaseModal
      title="Signer Verification"
      isOpen={isModalVisible}
      onRequestClose={handleCloseModal}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={false}
      style={{
        overlay: { pointerEvents: 'auto' },
        content: {
          maxWidth: '533px',
          gridTemplateRows: '48px 1fr',
        },
      }}
    >
      <ModalHeader title="Signer Verification" onClose={handleCloseModal} />
      <>
        <StyledModalContent data-testid="signer-selection-modal">
          {signers.map((signer, index) => {
            return (
              <RadioLabel key={index} data-selected={selectedSigner === index}>
                <input
                  type="radio"
                  name="signer"
                  value={index}
                  onChange={handleSelectSigner}
                  checked={selectedSigner === index}
                />
                <span>
                  {signer.first_name} {signer.last_name}
                </span>
              </RadioLabel>
            );
          })}
        </StyledModalContent>
        <ModalFooter>
          <ButtonBar>
            <SecondaryButton onClick={handleCloseModal}>Cancel</SecondaryButton>
            <PrimaryButton
              onClick={() => {
                onRunVerification(signers[selectedSigner]);
                handleCloseModal();
              }}
            >
              Run
            </PrimaryButton>
          </ButtonBar>
        </ModalFooter>
      </>
    </BaseModal>
  );
};
