import { HeaderLink } from '../../shared/header-component';
import { Header } from '../../shared/header-component';
import React, { useContext } from 'react';
import moment from 'moment';
import {
  Icon,
  SmallPrimaryButton,
  Text,
} from '@fattmerchantorg/truffle-components';
import { useHistory } from 'react-router-dom';
import { StatementV3Props } from '../util/statement-v3-utils';
import { useStatementLoadingState } from '../../../../context/statements';
import { SelectedMerchantStore } from '../../../../context';

export const HeaderSection: React.FC<StatementV3Props> = props => {
  const { startDate } = props;
  const {
    statementLoadingState: { loadingCount, failedCount },
  } = useStatementLoadingState();
  const history = useHistory();
  const { state } = useContext(SelectedMerchantStore);
  const { merchantId } = state;

  return (
    <div>
      <HeaderLink
        style={{ cursor: 'pointer' }}
        onClick={() => history.push(`/merchant/${merchantId}/feestatements`)}
      >
        <Icon icon={['fas', 'caret-left']} color={'#009BF2'} />{' '}
        <span
          style={{ paddingLeft: '6px', color: '#009BF2', fontWeight: 'bold' }}
        >
          Fee Statements
        </span>
      </HeaderLink>
      <Header className="-print-hide" style={{ padding: '8px 16px 16px 16px' }}>
        <Text as="h4">
          Fee Statement - {moment(startDate).format('MMM YYYY')}
        </Text>
        <div>
          <SmallPrimaryButton
            icon={['fas', 'print']}
            onClick={window.print}
            disabled={loadingCount > 0 || failedCount > 0}
            trackingId="detailed-statement-print"
            data-testid="detailed-statement-print"
          >
            Print
          </SmallPrimaryButton>
        </div>
      </Header>
    </div>
  );
};
