import React, { FunctionComponent } from 'react';
import { Select } from '@fattmerchantorg/truffle-components';
import { FieldProps } from '../../../shared/Field/Field.types';

export interface SelectOption<V = string> {
  label: string;
  value: V;
}

export interface StateSelectProps<FieldValue, T extends HTMLElement>
  extends FieldProps<FieldValue, T> {
  options: SelectOption[];
}

export const StateSelect: FunctionComponent<
  StateSelectProps<SelectOption, HTMLSelectElement>
> = props => {
  return (
    <Select
      styles={{
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        // @TODO - Need to fix this in Truffle's Stax Connect theme
        input: base => ({ ...base, color: 'white', padding: 0 }),
      }}
      menuPortalTarget={document.body}
      menuShouldScrollIntoView={false}
      isSearchable={true}
      menuPosition="fixed"
      {...props} // 'value' and 'options' are in here
    />
  );
};
