import { TextField } from '@fattmerchantorg/truffle-components';
import React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import styled from 'styled-components';

import {
  FormRow,
  PartialForm,
  TwoColumn,
} from '../shared/UnderwritingPartialForm';
import { FieldGroupTitle } from '../shared/UnderwritingSection';
import { formatCurrency, formatPercent } from '../../../../util';

interface PricingInfoFieldsProps {
  formProps?: FormRenderProps;
}

const TransactionAmountSection = styled.div`
  border-right: 1px solid ${({ theme }) => theme.lightNeutralOutline};
  margin: 0 8px;
  padding-right: 0.5rem;
  margin-right: 0;
`;

export const PricingInfoFields: React.FC<PricingInfoFieldsProps> = props => {
  return (
    <PartialForm>
      <TwoColumn>
        <TransactionAmountSection>
          <FieldGroupTitle>Transaction Amount</FieldGroupTitle>
          <FormRow>
            <Field
              name="credit_qual_trans_fee"
              format={val => formatCurrency(val)}
              formatOnBlur
            >
              {props => (
                <TextField {...props.input} type="text" label="Credit Qual" />
              )}
            </Field>
          </FormRow>
          <FormRow>
            <Field
              name="credit_mid_trans_fee"
              format={val => formatCurrency(val)}
              formatOnBlur
            >
              {props => (
                <TextField {...props.input} type="text" label="Credit Mid" />
              )}
            </Field>
          </FormRow>
          <FormRow>
            <Field
              name="credit_non_trans_fee"
              format={val => formatCurrency(val)}
              formatOnBlur
            >
              {props => (
                <TextField {...props.input} type="text" label="Credit Non" />
              )}
            </Field>
          </FormRow>
          <FormRow>
            <Field
              name="amex_qual_trans_fee"
              format={val => formatCurrency(val)}
              formatOnBlur
            >
              {props => (
                <TextField {...props.input} type="text" label="Amex Qual" />
              )}
            </Field>
          </FormRow>
          <FormRow>
            <Field
              name="amex_mid_trans_fee"
              format={val => formatCurrency(val)}
              formatOnBlur
            >
              {props => (
                <TextField {...props.input} type="text" label="Amex Mid" />
              )}
            </Field>
          </FormRow>
          <FormRow>
            <Field
              name="amex_non_trans_fee"
              format={val => formatCurrency(val)}
              formatOnBlur
            >
              {props => (
                <TextField {...props.input} type="text" label="Amex Non" />
              )}
            </Field>
          </FormRow>
        </TransactionAmountSection>
        <div>
          <FieldGroupTitle>Discount Amount</FieldGroupTitle>
          <FormRow>
            <Field
              name="credit_qual_disc_rate"
              format={formatPercent}
              formatOnBlur
            >
              {props => (
                <TextField {...props.input} type="text" label="Credit Qual" />
              )}
            </Field>
          </FormRow>
          <FormRow>
            <Field
              name="credit_mid_disc_rate"
              format={formatPercent}
              formatOnBlur
            >
              {props => (
                <TextField {...props.input} type="text" label="Credit Mid" />
              )}
            </Field>
          </FormRow>
          <FormRow>
            <Field
              name="credit_non_disc_rate"
              format={formatPercent}
              formatOnBlur
            >
              {props => (
                <TextField {...props.input} type="text" label="Credit Non" />
              )}
            </Field>
          </FormRow>
          <FormRow>
            <Field
              name="amex_qual_disc_rate"
              format={formatPercent}
              formatOnBlur
            >
              {props => (
                <TextField {...props.input} type="text" label="Amex Qual" />
              )}
            </Field>
          </FormRow>
          <FormRow>
            <Field
              name="amex_mid_disc_rate"
              format={formatPercent}
              formatOnBlur
            >
              {props => (
                <TextField {...props.input} type="text" label="Amex Mid" />
              )}
            </Field>
          </FormRow>
          <FormRow>
            <Field
              name="amex_non_disc_rate"
              format={formatPercent}
              formatOnBlur
            >
              {props => (
                <TextField {...props.input} type="text" label="Amex Non" />
              )}
            </Field>
          </FormRow>
        </div>
      </TwoColumn>
    </PartialForm>
  );
};
