import React from 'react';
import styled from 'styled-components';
import { LoadingTd } from './loading-td';

export type LoadingTrProps = {
  index: number;
  tdWidths: number[];
};

const Tr = styled.tr`
  animation: fade-in 0.1s ease-in-out both, shift-down 0.2s ease-in-out both;
`;

export const LoadingTr: React.FunctionComponent<LoadingTrProps> = props => {
  const { index, tdWidths } = props;

  return (
    <Tr className="tableRows">
      {tdWidths.map((width, i) => (
        <LoadingTd key={i} index={index} width={width} />
      ))}
    </Tr>
  );
};
