import { PermissionsAction, PermissionsActionType } from './Permissions.types';
import {
  Permissions,
  FeaturePermissions,
  FeatureLevel,
  GodviewPermissions,
  PermissionReadWrite,
} from '@fattmerchantorg/types-omni';

export function updatePermissions(permissions: Permissions): PermissionsAction {
  return {
    type: PermissionsActionType.UPDATE_PERMISSIONS,
    payload: permissions,
  };
}

export function removePermissions(): PermissionsAction {
  return { type: PermissionsActionType.REMOVE_PERMISSIONS };
}

export function updatePermissionsFeaturePermissionValue<
  C extends keyof FeaturePermissions
>(
  category: C,
  feature: keyof FeaturePermissions[C],
  value: FeatureLevel
): PermissionsAction {
  return {
    type: PermissionsActionType.UPDATE_PERMISSIONS_FEATURE_PERMISSION_VALUE,
    payload: {
      category,
      feature,
      value,
    },
  };
}

export function updatePermissionsGodviewPermissionValue(
  permission: keyof GodviewPermissions,
  value: PermissionReadWrite
): PermissionsAction {
  return {
    type: PermissionsActionType.UPDATE_PERMISSIONS_GODVIEW_PERMISSION_VALUE,
    payload: {
      permission,
      value,
    },
  };
}
