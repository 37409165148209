import React, { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { history } from '../../history';
import { Button } from '../shared/Button/Button';

const Container = withTheme(
  styled.div`
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    box-sizing: border-box;
    height: 100vh;
    justify-content: center;
    padding: 0 25px;
    overflow: hidden;
    background: ${({ theme }) => theme.colors.core.gray[800].hex};
    background-size: cover;
    box-shadow: 0px 4px 8px rgba(64, 71, 80, 0.3);
    font-family: Roboto;

    h3 {
      align-items: center;
      text-align: center;
      color: ${({ theme }) => theme.colors.core.green[50].hex};
      text-shadow: 4px 4px 0px #75d697;
      display: flex;
      font-size: 250px;
      font-style: normal;
      font-weight: normal;
      font-weight: 500;
      line-height: 293px;
    }

    button {
      font-style: normal;
      font-weight: normal;
      height: 40px;
      margin-top: 16px;
      padding: 16px 15px;
      width: 110px;
      border-radius: 2px;
      margin: 0 4px;
    }

    img {
      display: none;
    }

    /* Medium devices (tablets, 768px and up) */
    @media (min-width: 768px) {
      h3 {
        font-size: 201px;
        line-height: 235px;
      }
    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {
      align-items: flex-start;
      flex-flow: row nowrap;
      justify-content: center;
      padding-top: 205px;

      & > * {
        /* To avoid bug where the decorative image (position absolute)
       * is in front of all other child elements and prevents clicks,
       * we specify position:relative on all children.
       */
        position: relative;
      }
      img {
        display: initial;
        padding-left: 60px;
        padding-top: 130px;
        position: absolute;
      }
    }
  `
);

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
`;

const MessageSpan = styled.span`
  display: flex;
  font-size: 28px;
  line-height: 42px;
  align-items: center;
  text-align: center;
  max-width: 463px;
  color: #ffffff;
`;

const LinkSpan = styled.span`
  display: flex;
  font-size: 14px;
  line-height: 21px;
  align-items: center;
  text-align: center;
  font-weight: 400;
  text-decoration-line: underline;
  margin-top: 17px;

  a {
    padding: 0 5px;
    color: #009bf2;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  padding-top: 16px;
`;

const StyledDashboardButton = styled(Button)`
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.black};
`;

const StyledLogoutButton = withTheme(
  styled(Button)`
    background: ${({ theme }) => theme.black};
    color: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.white};

    &:hover {
      background: ${({ theme }) => theme.colors.core.green[50].hex};
      color: ${({ theme }) => theme.black};
    }
  `
);

const Message: FC<{ status: any }> = ({ status }) => {
  let message: string = 'We have encountered a general error.';
  switch (status) {
    case '403':
      message = 'Access Denied';
      break;
    case '404':
      message = 'Page not found';
      break;
    case '500':
      message = 'We have encountered a server error';
      break;
  }
  return <MessageSpan>{message}</MessageSpan>;
};

const BackToDashboardButton: FC = () => {
  return (
    <StyledDashboardButton
      id="error-back-to-dash-btn"
      data-testid="error-back-to-dash-btn"
      onClick={() => history.push('/')}
      variant="solid"
    >
      Home
    </StyledDashboardButton>
  );
};

const LogoutButton: FC = () => {
  return (
    <StyledLogoutButton
      id="logout-btn"
      data-testid="logout-btn"
      onClick={() => {
        /**
         * Last resort as user may have a merchant in localstorage it no longer has access to.
         * Ex: Opening a merchant, merchant changing brand and user not having access to that new brand.
         */
        localStorage.clear();
        history.push('/logout');
      }}
      variant="solid"
    >
      Logout
    </StyledLogoutButton>
  );
};

const ContentLinks: FC = () => {
  return (
    <LinkSpan>
      <a href="https://status.paywithomni.com">System Status</a>
    </LinkSpan>
  );
};

interface ErrorPageProps extends RouteComponentProps<{ code: string }> {}
export const ErrorPage: FC<ErrorPageProps> = props => {
  const status: string =
    (props.match && props.match.params && props.match.params.code) || '404';

  return (
    <Container>
      <Content>
        <h3>{status}</h3>
        <Message status={status} />
        <ButtonGroup>
          <BackToDashboardButton />
          <LogoutButton />
        </ButtonGroup>
        <ContentLinks />
      </Content>
    </Container>
  );
};
