import {
  Drawer,
  Icon,
  Text,
  TextLink,
} from '@fattmerchantorg/truffle-components';
import { DrawerProps } from '@fattmerchantorg/truffle-components/dist/Drawer/Drawer';
import {
  OmniDispute as Dispute,
  DisputeFile,
} from '@fattmerchantorg/types-omni';
import get from 'lodash/get';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import {
  formatCurrency,
  formatPaymentMethodTypeDispute,
  formatReadableDate,
} from '../../../util/format.util';
import {
  LoadingSpan,
  Separator as USeparator,
  StaxButton,
  CopyButton,
  Button,
} from '../../shared';
import { CustomerInfoWidget } from '../../transactions/components/CustomerInfoWidget';
import { PaymentMethodIcon as UPaymentMethodIcon } from '../../transactions/components/PaymentMethodIcon';
import { formatReasonAndDescription } from '../Disputes.format';
import {
  mapDisputeStatusToPill,
  formatDisputeEvidenceFileName,
  formatRespondByDate,
} from '../Disputes.util';
import { useAuthToken, useToaster } from '../../../hooks';
import { MerchantDisputeCombo } from '../Disputes';
import { onboardingapi } from '../../../api';
import { downloadData } from '../../../util/documents.util';
import { formatDate } from '../../../util/date.util';

const Section = styled.div`
  padding: 16px 16px 0;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:first-child) {
    margin-top: 16px;
  }

  small {
    font-size: 12px;
  }

  > *:not(:first-child) {
    margin-left: 8px;
  }
`;

/** fill the rest of the horizontal container as a flexbox, and start sticking items
 * (in this case, the copy button) on the right of the container in line with the end
 * of the horizontal parent
 */
const DisputeIdCopyContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row-reverse;
`;

const DisputeDetailsTable = styled.table`
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-size: 14px;
  color: white;

  > tbody {
    > tr {
      > td {
        width: 30%;
        font-weight: 700;
        vertical-align: top;
        padding-bottom: 4px;
      }

      > td:last-child {
        width: 70%;
        font-weight: 400;
      }
    }
  }
`;

const DisputeEvidenceTable = styled.table`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  color: white;

  > tbody {
    > tr {
      > td:nth-child(1) {
        width: 45%;
        vertical-align: top;
        padding-bottom: 4px;
      }

      > td:nth-child(2) {
        width: 35%;
      }

      > td:nth-child(3) {
        width: 20%;
      }
    }
  }
`;

const FileSizeParentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const FileSizeTextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 80%;
`;

const FileSizeButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 20%;
`;

const DisputeCostumerDetails = styled.div`
  width: 100%;
  margin-top: 4px;
  margin-bottom: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-size: 14px;
  color: white;
  padding-top: 8px;
`;

const ButtonsGroup = styled.div`
  background: ${({ theme }) => theme.component.drawer.backgroundColor};
  padding: 16px;
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  justify-content: right;

  > *:not(:first-child) {
    margin-left: 16px;
  }
`;

const ButtonText = styled.span`
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  font-family: 'Roboto';
`;

const LineItemRow = styled(Row)`
  > *:last-child {
    margin-left: auto;
  }
`;

const PaymentMethodIcon = styled(UPaymentMethodIcon)`
  font-size: 32px;
  height: auto;
  line-height: auto;
`;

const DateRowContainer = styled(Row)`
  align-items: baseline;

  > small {
    margin-left: 2px !important;
  }
`;

const Separator = styled(USeparator)`
  padding-top: 16px;
`;

const Details = styled.div`
  > span {
    font-size: 16px;
  }
`;

const GoToPaymentIcon = styled(Icon)`
  position: relative;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.status.blue[500].hex};
`;

const DrawerButton = styled(StaxButton)`
  margin-left: 16px;
`;

const StyledCardLogo = styled.img`
  width: 34px;
  height: auto;
`;

const cardTypesToImagePaths = {
  VISA: require('../../../assets/img/icons/cardbrands/visa.svg'),
  AMERICANEXPRESS: require('../../../assets/img/icons/cardbrands/amex.svg'),
  DISCOVER: require('../../../assets/img/icons/cardbrands/discover.svg'),
  MASTERCARD: require('../../../assets/img/icons/cardbrands/mastercard.svg'),
  BANK: require('../../../assets/img/icons/bankimages/bank.svg'),
};

/** Props accepted by the DetailsModal component */
type DisputeDrawerProps = Partial<DrawerProps> &
  Pick<DrawerProps, 'open'> & {
    dispute: Dispute;
    loading: boolean;
    onUploadDocs: (dispute: Dispute) => void;
    onAcceptDispute: (dispute: Dispute) => void;
    merchantDisputeCombo: MerchantDisputeCombo;
    setMerchantDisputeCombo: React.Dispatch<
      React.SetStateAction<MerchantDisputeCombo>
    >;
    supportingDocuments: DisputeFile[];
    isLoading: boolean;
  };

const DateRow: FC<{
  label?: string;
  created_at?: string;
  loading?: boolean;
}> = props =>
  props.loading ? (
    <Row>
      <LoadingSpan height="24px" width="160px" />
    </Row>
  ) : (
    <DateRowContainer>
      <Text as="h6">{String(props.label)}</Text>
      <span>{formatReadableDate(String(props.created_at), true)}</span>
    </DateRowContainer>
  );

export const DisputeDrawer: FC<DisputeDrawerProps> = props => {
  const {
    loading,
    dispute,
    supportingDocuments,
    merchantDisputeCombo,
    isLoading,
    setMerchantDisputeCombo,
    onUploadDocs,
    onAcceptDispute,
    ...drawerProps
  } = props;

  const authToken = useAuthToken();
  const { toast, toaster } = useToaster();

  const paymentMethodType = formatPaymentMethodTypeDispute(dispute);
  const paymentMethod = dispute?.payment_method;

  const redirectToPaymentPage = (transaction_id: string) => {
    const merchantId = get(dispute, 'merchant_id');
    if (merchantId && transaction_id) {
      const url = `/#/merchant/${merchantId}/transactions?detailId=${transaction_id}`;
      window.open(url, '_blank');
    }
  };

  useEffect(() => {
    if (
      dispute &&
      dispute.id &&
      dispute.merchant_id &&
      (dispute.id !== merchantDisputeCombo?.disputeId ||
        dispute.merchant_id !== merchantDisputeCombo?.merchantId)
    ) {
      setMerchantDisputeCombo(() => ({
        disputeId: dispute.id,
        merchantId: dispute.merchant_id,
      }));
    }
  }, [dispute, merchantDisputeCombo, setMerchantDisputeCombo]);

  async function downloadEvidenceFile(
    fileId: string,
    fileName: string,
    mimeType: string
  ) {
    try {
      const res = await onboardingapi.get(authToken, `/file/${fileId}`);
      downloadData(res, fileName, mimeType);
    } catch (error) {
      toaster(toast.error(error, 'There was a problem downloading the file.'));
    }
  }

  function getEvidenceSectionMaterial() {
    if (loading) {
      return (
        <Row>
          <LoadingSpan height="24px" width="100%" />
        </Row>
      );
    }
    if (
      ['EVIDENCE_UPLOADED', 'WON', 'LOST'].includes(dispute?.status ?? '') &&
      supportingDocuments.length !== 0
    ) {
      return (
        <>
          <Section>
            {isLoading ? (
              <Row>
                <LoadingSpan height="18px" width="70px" />
              </Row>
            ) : (
              <>
                <Text as="h5">Submitted Evidence</Text>
                <DisputeEvidenceTable>
                  <tbody>
                    <tr>
                      <td>
                        <b>File Name</b>
                      </td>
                      <td>
                        <b>Submitted At</b>
                      </td>
                      <td>
                        <b>File Size</b>
                      </td>
                    </tr>
                    {supportingDocuments.map((document, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <TextLink
                              target="_blank"
                              href={document.public_url || ''}
                            >
                              {formatDisputeEvidenceFileName(document.name)}
                            </TextLink>
                          </td>
                          <td>
                            {document.submitted_at
                              ? formatDate(
                                  new Date(document.submitted_at),
                                  'MM/dd/yyyy | h:mm a'
                                )
                              : '--'}
                          </td>
                          <td>
                            <FileSizeParentContainer>
                              {document.meta?.filesize ? (
                                <>
                                  <FileSizeTextContainer>
                                    {document.meta.filesize}
                                  </FileSizeTextContainer>
                                  <FileSizeButtonContainer>
                                    <Button
                                      variant="link"
                                      style={{ cursor: 'pointer' }}
                                      onClick={async e => {
                                        downloadEvidenceFile(
                                          document.id,
                                          document.name,
                                          document.meta?.mime
                                        );
                                        e.stopPropagation();
                                      }}
                                    >
                                      <i className="fas fa-download"></i>
                                    </Button>
                                  </FileSizeButtonContainer>
                                </>
                              ) : (
                                <FileSizeTextContainer>
                                  {'--'}
                                </FileSizeTextContainer>
                              )}
                            </FileSizeParentContainer>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </DisputeEvidenceTable>
              </>
            )}
          </Section>
          <Separator />
        </>
      );
    }
    return null;
  }

  return (
    <Drawer
      {...drawerProps}
      anchor="right"
      title="Dispute Details"
      data-testid="dispute-details-modal"
      customStyles={{
        borderRadius: 0,
        padding: 0,
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Section>
        {loading ? (
          <Row>
            <LoadingSpan height="42px" width="120px" />
            <LoadingSpan height="18px" width="70px" />
          </Row>
        ) : (
          <>
            <Row>
              <Text as="h3">{formatCurrency(dispute.amount)}</Text>
              <small>{mapDisputeStatusToPill(dispute.status)}</small>
              <DisputeIdCopyContainer>
                <CopyButton
                  content={dispute.id}
                  tooltip="Copy Dispute ID to clipboard"
                >
                  <Icon icon={['fas', 'copy']} size="1x" />
                </CopyButton>
              </DisputeIdCopyContainer>
            </Row>
            <DateRow
              label="Created:"
              created_at={dispute.created_at}
              loading={loading}
            />
          </>
        )}
      </Section>
      <Separator />
      <Section>
        <Details>
          <span>
            <b>Details</b>
          </span>
        </Details>
        {loading ? (
          <DisputeDetailsTable>
            <tbody>
              <tr>
                <td>
                  <LoadingSpan height="20px" width="440px" />
                </td>
              </tr>
              <tr>
                <td>
                  <LoadingSpan height="20px" width="440px" />
                </td>
              </tr>
              <tr>
                <td>
                  <LoadingSpan height="20px" width="440px" />
                </td>
              </tr>
            </tbody>
          </DisputeDetailsTable>
        ) : (
          <DisputeDetailsTable data-testid="dispute-details">
            <tbody>
              <tr>
                <td>Reason Code</td>
                <td>
                  <b>{dispute.reason_code ? dispute.reason_code : '--'}</b>
                </td>
              </tr>
              <tr>
                <td>Reason</td>
                <td>
                  {formatReasonAndDescription(
                    dispute.reason,
                    dispute.description
                  )}
                </td>
              </tr>
              <tr>
                <td>Deadline</td>
                <td>
                  {dispute.respond_by ? (
                    ['WON', 'LOST'].includes(dispute.status) ? (
                      <>
                        <b>Closed </b>-{' '}
                        {formatReadableDate(String(dispute.respond_by), true)}
                      </>
                    ) : (
                      <>
                        <b>{formatRespondByDate(dispute.respond_by)}</b> -{' '}
                        {formatReadableDate(String(dispute.respond_by), true)}
                      </>
                    )
                  ) : (
                    '--'
                  )}
                </td>
              </tr>
              <tr>
                <td>Recommendation</td>
                <td>
                  {dispute.required_action ? dispute.required_action : '--'}
                </td>
              </tr>
              <tr>
                <td>Original Payment</td>
                <td>
                  {dispute.transaction?.total ? (
                    <b>{formatCurrency(dispute.transaction?.total)} </b>
                  ) : (
                    '--'
                  )}
                  {dispute.amount && dispute.transaction_id ? (
                    <GoToPaymentIcon
                      icon={['fas', 'external-link']}
                      onClick={() =>
                        redirectToPaymentPage(dispute.transaction_id)
                      }
                    />
                  ) : null}
                </td>
              </tr>
            </tbody>
          </DisputeDetailsTable>
        )}
      </Section>
      <Separator />
      <Section>
        <Text as="h5">Customer Details</Text>
        {loading ? (
          <DisputeCostumerDetails>
            <LoadingSpan
              height="20px"
              width="440px"
              style={{ marginTop: '8px' }}
            />
            <LoadingSpan
              height="20px"
              width="440px"
              style={{ marginTop: '8px' }}
            />
            <LoadingSpan
              height="20px"
              width="440px"
              style={{ marginTop: '8px' }}
            />
            <LoadingSpan
              height="20px"
              width="440px"
              style={{ marginTop: '8px' }}
            />
          </DisputeCostumerDetails>
        ) : (
          <DisputeCostumerDetails data-testid="dispute-customer-details">
            <CustomerInfoWidget
              customer={dispute?.customer}
              loading={loading}
            />
          </DisputeCostumerDetails>
        )}
      </Section>
      <Separator />
      <Section>
        <Text as="h5">Payment Methods</Text>
        {loading ? (
          <Row>
            <LoadingSpan height="24px" width="100%" />
          </Row>
        ) : (
          <LineItemRow data-testid="dispute-payment-methods">
            {cardTypesToImagePaths[paymentMethodType?.toUpperCase()] ? (
              <StyledCardLogo
                src={cardTypesToImagePaths[paymentMethodType.toUpperCase()]}
              />
            ) : (
              <PaymentMethodIcon method={paymentMethodType} />
            )}
            {['cash', 'giftcard', 'check'].includes(paymentMethod?.id) ? (
              <span style={{ marginLeft: '24px' }}>
                {paymentMethod?.nickname}
              </span>
            ) : (
              <span style={{ marginLeft: '24px' }}>
                {paymentMethodType?.toUpperCase()} ending in{' '}
                {paymentMethod?.card_last_four}
                {paymentMethod?.is_default ? ' (Default)' : ''}
              </span>
            )}
            {paymentMethod?.card_exp ? (
              <span>
                Expires {paymentMethod.card_exp.slice(0, 2)}/
                {paymentMethod.card_exp.slice(-2)}
              </span>
            ) : null}
          </LineItemRow>
        )}
      </Section>
      <Separator />
      {getEvidenceSectionMaterial()}
      {[
        'INQUIRY',
        'PENDING',
        'OPEN',
        'UPLOAD_FAILED',
        'PREARBITRATION',
      ].includes(dispute?.status) && (
        <Section>
          <ButtonsGroup data-testid="dispute-buttons-group">
            <DrawerButton
              id="accept-dispute-button"
              data-testid="accept-dispute-button"
              className="mt-3"
              rank="secondary"
              onClick={() => onAcceptDispute(dispute)}
              disabled={loading}
            >
              <ButtonText>Accept Dispute</ButtonText>
            </DrawerButton>
            <DrawerButton
              id="upload-supporting-documents-button"
              data-testid="upload-supporting-documents-button"
              className="mt-3"
              onClick={() => onUploadDocs(dispute)}
              disabled={loading}
            >
              <ButtonText>Upload Evidence</ButtonText>
            </DrawerButton>
          </ButtonsGroup>
        </Section>
      )}
    </Drawer>
  );
};
