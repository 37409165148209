import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import {
  Icon,
  SecondaryButton,
  DestructiveButton,
  PrimaryButton,
} from '@fattmerchantorg/truffle-components';
import { Spinner } from '../../../shared';
import { GenericModal } from './GenericModal';

const WarningIcon = styled(Icon)`
  font-size: 70px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.core.gray[400].hex};
` as typeof Icon;

const ButtonSpinner = styled.span`
  width: 14px;
  height: 14px;
  display: inline-block;
  margin-right: 5px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const ContentWrapper = styled.div`
  text-align: center;
  line-height: 1.6;
`;

type ConfirmButtonType = 'primary' | 'danger';
const confirmButtomComponent: Record<
  ConfirmButtonType,
  typeof PrimaryButton | typeof DestructiveButton
> = {
  primary: PrimaryButton,
  danger: DestructiveButton,
} as const;

type ConfirmationModalProps = {
  isOpen: boolean;
  title: string;
  confirmButtonLabel?: string;
  confirmButtonType?: ConfirmButtonType;
  inProgress?: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const ConfirmationModal: FunctionComponent<
  ConfirmationModalProps
> = props => {
  const {
    isOpen,
    title,
    confirmButtonLabel = 'Confirm',
    confirmButtonType = 'primary',
    onClose,
    onConfirm,
    children,
    inProgress,
  } = props;

  const ConfirmButtonComponent = confirmButtomComponent[confirmButtonType];

  return (
    <GenericModal
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      buttons={
        <React.Fragment>
          <SecondaryButton
            onClick={onClose}
            trackingId="adjustment-confirmation-modal-cancel"
          >
            Cancel
          </SecondaryButton>
          <ConfirmButtonComponent
            onClick={onConfirm}
            trackingId="adjustment-confirmation-modal-confirm"
            disabled={inProgress}
          >
            {inProgress && (
              <ButtonSpinner>
                <Spinner />
              </ButtonSpinner>
            )}
            {confirmButtonLabel}
          </ConfirmButtonComponent>
        </React.Fragment>
      }
      buttonsPosition="center"
    >
      <ContentWrapper>
        <WarningIcon
          className="icon"
          icon={['far', 'exclamation-triangle']}
          width="70px"
          height="70px"
        />
        {children}
      </ContentWrapper>
    </GenericModal>
  );
};
