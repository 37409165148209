import React, { useState, useEffect, useContext } from 'react';
import { UnderwritingSection } from '../shared/UnderwritingSection';
import { SelectedMerchantStore } from '../../../../context';
import styled from 'styled-components';
import { Icon } from '@fattmerchantorg/truffle-components';
import { Tooltip } from '@fattmerchantorg/truffle-components';
import { BillingAddressFormModal } from './BillingAddressFormModal';
import { ModalContext } from '../../../../context';
import { EditToggle } from '../shared/EditToggle';
export interface BillingAddressProps {}
const DivIcon = styled.div`
  padding-right: 8px;
  float: left;
`;
const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  p {
    margin: 0;
    font-weight: 400;
  }
`;

export function BillingAddress(props: BillingAddressProps) {
  const {
    dispatch,
    state: { merchantId, registration },
  } = useContext(SelectedMerchantStore);
  const { modalDispatch } = useContext(ModalContext);
  const [regState, setRegState] = useState(registration);
  const [mId, setmId] = useState(merchantId);

  useEffect(() => {
    setRegState(registration);
    setmId(merchantId);
  }, [registration, merchantId]);

  const address = {
    address_1: registration?.business_address_1,
    address_2: registration?.business_address_2,
    city: registration?.business_address_city,
    state: registration?.business_address_state,
    zip: registration?.business_address_zip,
    country: registration?.business_address_country,
  };
  const hasAddress = [
    registration?.business_address_1,
    registration?.business_address_2,
    registration?.business_address_city,
    registration?.business_address_state,
    registration?.business_address_zip,
    registration?.business_address_country,
  ].some(field => field !== null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        component: BillingAddressFormModal,
        props: {
          isOpen: true,
          registration: regState,
          merchantId: mId,
          merchantDispatch: dispatch,
        },
      },
    });
  };

  return (
    <UnderwritingSection
      title="Billing Address"
      data-testid="underwriting-billing-address"
      id="billingAddressCard"
    >
      {({ setToolTipContent }) => {
        setToolTipContent(
          'Address used to send mail or other materials to the business'
        );
        return (
          <div>
            <EditToggle onClick={handleClick}>
              <Icon icon={['fas', 'pencil']} />
            </EditToggle>
            <DivIcon>
              <Tooltip content="Address">
                <Icon icon={['fas', 'map-marker-alt']} />
              </Tooltip>
            </DivIcon>
            <AddressContainer>
              {hasAddress ? (
                <>
                  {address.address_1 ? <p>{address.address_1}</p> : ''}
                  {address.address_2 ? <p>{address.address_2}</p> : ''}
                  {address.city || address.state || address.zip ? (
                    <p>
                      <span>{`${address.city ?? ''} `}</span>
                      <span>{`${address.state ?? ''} `}</span>
                      <span>{`${address.zip ?? ''}`}</span>
                    </p>
                  ) : (
                    ''
                  )}
                  {address.country ? <p>{address.country}</p> : ''}
                </>
              ) : (
                '-'
              )}
            </AddressContainer>
          </div>
        );
      }}
    </UnderwritingSection>
  );
}
