import React, { FunctionComponent } from 'react';
import { StatusPill } from '@fattmerchantorg/truffle-components';
import { StatusPillStatus } from '@fattmerchantorg/truffle-components/dist/StatusPill/StatusPill';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { FundingAccount } from '@fattmerchantorg/types-omni';

function mapAccountToStatusPillProps(account: FundingAccount): {
  label: string;
  status: StatusPillStatus;
  icon?: FontAwesomeIconProps['icon'];
} {
  switch (account.status) {
    case 'APPROVED':
      if (account.active) {
        return {
          label: 'Active',
          status: 'success',
        };
      }

      return {
        label: 'Inactive',
        status: 'error',
        icon: ['fas', 'times'],
      };
    case 'PENDED':
      return {
        label: 'Pending',
        status: 'warning',
        icon: ['fas', 'ellipsis-h-alt'],
      };
    case 'REJECTED':
      return {
        label: 'Inactive',
        status: 'error',
        icon: ['fas', 'times'],
      };
    default:
      return null;
  }
}

export const BankAccountStatusPill: FunctionComponent<{
  account: FundingAccount;
}> = props => {
  return <StatusPill {...mapAccountToStatusPillProps(props.account)} />;
};
