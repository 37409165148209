import {
  Checkbox,
  Icon,
  Select,
  TextField,
  ToggleSelector,
} from '@fattmerchantorg/truffle-components';
import React, { FC, useState } from 'react';
import { Field } from 'react-final-form';
import styled from 'styled-components';
import { BoldText } from '../../truffle-dashboard/components/shared/BoldText';
import { FormDivider } from '../../underwriting/components/shared/UnderwritingPartialForm';
import {
  FieldWrapCustom,
  FieldWrapFullWidth,
  FlexRow,
  PermissionsRow,
  StyledHideIcon,
  WrapperText,
} from '../SettingsPagesStylings';
import {
  legacyPermissionsSections,
  readOption,
  readWriteOptions,
  validateName,
  writeOption,
} from './AddRoleModal.util';
import { RoleDetails } from './RoleDetails';
import { IncludedPermissions, usePermissions } from '../../../hooks';
import { PermissionReadWrite } from '@fattmerchantorg/types-omni';
import { RegularText } from '../../truffle-dashboard/components/shared/RegularText';

const IconWrapper = styled.span`
  padding-right: 0.5rem;
  vertical-align: middle;
  color: ${({ hasAccess, theme }) =>
    hasAccess
      ? theme.colors.status.green[800].hex
      : theme.colors.status.red[500].hex};
`;

const Check = () => (
  <IconWrapper hasAccess>
    <Icon icon={['fas', 'check-circle']} />
  </IconWrapper>
);

const Cross = () => (
  <IconWrapper>
    <Icon icon={['fas', 'times-circle']} />
  </IconWrapper>
);

const StyledToggleSelectorPermissions = styled(ToggleSelector)`
  display: flex;
  height: 32px;
  margin-left: auto;
  > div {
    border-color: #435e70;
  }
`;

const StyledReadOnlyToggleSelectorPermissions = styled.div`
  display: flex;
  margin-left: 1rem;
  align-items: center;
`;

const NestedPermissionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  margin-bottom: 0.5rem;
`;

const NestedPermissionOptionsContainer = styled.div`
  display: flex;
  margin-left: 32px;
  flex-direction: column;
`;

export type SectionPermission = {
  name: keyof RoleDetails['permissions'];
  label: string;
  options: Array<{ label: string; value: string }>;
  hasChildren?: boolean;
  parent?: keyof RoleDetails['permissions'];
};

export type PermissionSection = {
  label: string;
  forEdit?: boolean;
  permissions: SectionPermission[];
  editPermissionNeeded?: {
    action: keyof IncludedPermissions['godview'];
    permission: PermissionReadWrite;
  };
};

export const permissionsSections: PermissionSection[] = [
  {
    label: 'System',
    forEdit: true,
    editPermissionNeeded: {
      action: 'godviewPermissions',
      permission: 'write',
    },
    permissions: [
      {
        name: 'canSeeAllBrands',
        label: 'See All Brands',
        options: writeOption,
      },
      {
        name: 'godviewPermissions',
        label: 'Admin Permissions',
        options: readWriteOptions,
      },
      {
        name: 'permissions',
        label: 'Permissions',
        options: readWriteOptions,
      },
    ],
  },
  {
    label: 'Page Visibility',
    forEdit: true,
    permissions: [
      {
        name: 'teamSettings',
        label: 'Team Settings',
        options: readWriteOptions,
      },
      {
        hasChildren: true,
        name: 'dashboard',
        label: 'Dashboard',
        options: [],
      },
      {
        name: 'omniConnDashboard',
        label: 'Legacy',
        options: writeOption,
        parent: 'dashboard',
      },
      {
        name: 'omniConnDashboardOperational',
        label: 'Operational',
        options: writeOption,
        parent: 'dashboard',
      },
      {
        name: 'omniConnDashboardExecutive',
        label: 'Executive',
        options: writeOption,
        parent: 'dashboard',
      },
      {
        name: 'accessSubmerchants',
        label: 'Business Info',
        options: readOption,
      },
      {
        name: 'supportTickets',
        label: 'Support Tickets',
        options: readOption,
      },
      {
        name: 'branding',
        label: 'Branding',
        options: readWriteOptions,
      },
    ],
  },
  {
    label: 'Access To Features',
    forEdit: true,
    permissions: [
      {
        name: 'voidOrRefundSubmerchantTransactions',
        label: 'Void/Refund',
        options: writeOption,
      },
      {
        name: 'users',
        label: 'Manage Users',
        options: readWriteOptions,
      },
      {
        name: 'partnerWebhooks',
        label: 'Partner Webhooks',
        options: readWriteOptions,
      },
      {
        name: 'testModeToggle',
        label: 'Test Mode',
        options: writeOption,
      },
      {
        name: 'clone',
        label: 'Clone Merchant',
        options: writeOption,
      },
    ],
  },
];

// all legacy permissions are editable
export const legacyPermissions = Object.values(legacyPermissionsSections)
  .map(legacyPermissionsSection => legacyPermissionsSection.permissions)
  .flat();

export const buildPermissionsFormFromRole = (
  role: Partial<RoleDetails>,
  brandOptions: RoleFormOption[]
) => {
  const permissions: RoleFormState['permissions'] = {};

  let allPermissions = Object.values(permissionsSections)
    .map(permissionsSection => permissionsSection.permissions)
    .flat();

  allPermissions = allPermissions.concat(legacyPermissions);

  allPermissions.forEach(formPermission => {
    if (role?.permissions?.[formPermission.name]) {
      permissions[formPermission.name] = {
        checked: true,
        level: role.permissions[formPermission.name],
      };
    }
  });

  return {
    name: role?.role_name,
    description: role?.description,
    roleBrand: brandOptions.find(
      brandOption => brandOption.value === role?.brand
    ),
    permissions,
  };
};

export const buildRolePermissionsFromForm = (permissions: {
  [key: string]: { checked: boolean; level?: string };
}) => {
  let allPermissions = Object.values(permissionsSections)
    .map(permissionsSection => permissionsSection.permissions)
    .flat();

  allPermissions = allPermissions.concat(legacyPermissions);

  const updatedRolePermissions = {};
  Object.entries(permissions).forEach(([permissionName, permissionValue]) => {
    if (permissionValue.checked) {
      const permissionLevel =
        permissionValue.level ??
        allPermissions.find(p => p.name === permissionName).options[0].value;

      updatedRolePermissions[permissionName] = permissionLevel;
    }
  });

  return updatedRolePermissions;
};

export interface RoleFormOption {
  label?: string;
  value?: string;
}

export interface RoleFormState {
  name?: string;
  description?: string;
  roleBrand?: RoleFormOption;
  permissions?: {
    [key in keyof RoleDetails['permissions']]: {
      checked: boolean;
      level?: string;
    };
  };
}

interface PermissionItemProps {
  permission: SectionPermission;
  readOnly: boolean;
  formValues: RoleFormState;
}

const PermissionItem: FC<PermissionItemProps> = ({
  permission,
  readOnly,
  formValues,
}) => {
  return (
    <PermissionsRow>
      <Field name={`permissions.${permission.name}.checked`} type="checkbox">
        {props =>
          readOnly ? (
            <span>
              {props.input.checked ? <Check /> : <Cross />}
              {permission.label}
            </span>
          ) : (
            <Checkbox
              {...props.input}
              label={permission.label}
              customStyles={{
                wrapper: {
                  height: '32px',
                },
                labelText: {
                  textAlign: 'center',
                  lineHeight: '32px',
                },
              }}
            />
          )
        }
      </Field>

      {formValues?.permissions?.[permission.name]?.checked &&
        permission.options.length > 1 && (
          <Field name={`permissions.${permission.name}.level`}>
            {props => {
              const value =
                permission.options.find(o => o.value === props.input.value) ??
                permission.options[0];

              return readOnly ? (
                <StyledReadOnlyToggleSelectorPermissions>
                  ({value.label})
                </StyledReadOnlyToggleSelectorPermissions>
              ) : (
                <StyledToggleSelectorPermissions
                  options={permission.options}
                  defaultSelected={value.value}
                  disabled={readOnly}
                  onChange={(_, option) => props.input.onChange(option.value)}
                />
              );
            }}
          </Field>
        )}
    </PermissionsRow>
  );
};

interface Props {
  brandOptions: RoleFormOption[];
  formValues: RoleFormState;
  forEdit?: boolean;
  readOnly?: boolean;
}

export const RoleFormFields: FC<Props> = ({
  brandOptions,
  formValues,
  forEdit,
  readOnly,
}) => {
  const [showLegacy, setShowLegacy] = useState<boolean>(false);
  const { permit } = usePermissions();
  const hasAdminPermissions = permit('godview', 'godviewPermissions', 'write');

  const editPermissionSections = permissionsSections.filter(ps => {
    if (!ps.editPermissionNeeded) return ps.forEdit;
    return (
      ps.forEdit &&
      permit(
        'godview',
        ps.editPermissionNeeded.action,
        ps.editPermissionNeeded.permission
      )
    );
  });

  const filteredPermissionsSections = !forEdit
    ? permissionsSections
    : editPermissionSections;

  const getChildren = (
    parentName: string,
    permissions: SectionPermission[]
  ) => {
    return permissions.filter(permission => permission.parent === parentName);
  };

  return (
    <>
      {!forEdit && (
        <>
          <Field name="name" validate={validateName}>
            {props => (
              <FieldWrapFullWidth>
                <TextField
                  {...props.input}
                  className="permissions-new-role-name"
                  placeholder="Name"
                  error={props.meta.dirty && !!props.meta.error}
                  helperText={props.meta.dirty && props.meta.error}
                  label={'Name'}
                  disabled={readOnly}
                  required
                />
              </FieldWrapFullWidth>
            )}
          </Field>

          <Field name="description">
            {props => (
              <FieldWrapFullWidth>
                <TextField
                  {...props.input}
                  className="permissions-new-role-description"
                  placeholder="Description"
                  error={props.meta.dirty && !!props.meta.error}
                  helperText={props.meta.dirty && props.meta.error}
                  disabled={readOnly}
                  label={'Description'}
                />
              </FieldWrapFullWidth>
            )}
          </Field>

          <Field name="roleBrand">
            {props => (
              <FieldWrapFullWidth>
                <Select
                  {...props.input}
                  className="permissions-new-role-brand"
                  label="Brand"
                  name="roleBrand"
                  menuPosition="fixed"
                  placeholder="Select Brand"
                  options={brandOptions}
                  menuShouldScrollIntoView={true}
                  isClearable={false}
                  disabled={readOnly}
                  required
                />
              </FieldWrapFullWidth>
            )}
          </Field>
        </>
      )}

      {filteredPermissionsSections.map((permissionsSection, i) => (
        <div key={permissionsSection.label}>
          <FieldWrapCustom>
            {(!forEdit || i > 0) && <FormDivider />}
            <WrapperText>{permissionsSection.label}</WrapperText>
          </FieldWrapCustom>
          {permissionsSection.permissions.map(permission => (
            <React.Fragment key={permission.label}>
              {!permission.hasChildren && !permission.parent && (
                <FieldWrapFullWidth key={permission.label}>
                  <PermissionItem {...{ permission, formValues, readOnly }} />
                </FieldWrapFullWidth>
              )}
              {permission.hasChildren && (
                <NestedPermissionContainer>
                  <RegularText color="#ffffff" marginBottom={5}>
                    {permission.label}
                  </RegularText>
                  <NestedPermissionOptionsContainer>
                    {getChildren(
                      permission.name,
                      permissionsSection.permissions
                    ).map((permission, idx) => (
                      <FieldWrapFullWidth>
                        <PermissionItem
                          {...{ permission, formValues, readOnly }}
                        />
                      </FieldWrapFullWidth>
                    ))}
                  </NestedPermissionOptionsContainer>
                </NestedPermissionContainer>
              )}
            </React.Fragment>
          ))}
        </div>
      ))}
      {!forEdit || hasAdminPermissions ? (
        <>
          <FlexRow>
            <BoldText fontSize={14} marginBottom={2}>
              Legacy
            </BoldText>
            <StyledHideIcon onClick={() => setShowLegacy(prev => !prev)}>
              {showLegacy ? (
                <>
                  <Icon icon={['fas', 'eye-slash']} /> Hide
                </>
              ) : (
                <>
                  <Icon icon={['fas', 'eye']} />
                  Show
                </>
              )}
            </StyledHideIcon>
          </FlexRow>

          {showLegacy &&
            legacyPermissionsSections.map((permissionsSection, i) => (
              <div key={permissionsSection.label}>
                <FieldWrapCustom>
                  {(!forEdit || i > 0) && <FormDivider />}
                  <WrapperText>{permissionsSection.label}</WrapperText>
                </FieldWrapCustom>
                {permissionsSection.permissions.map(permission => (
                  <FieldWrapFullWidth key={permission.label}>
                    <PermissionItem {...{ permission, readOnly, formValues }} />
                  </FieldWrapFullWidth>
                ))}
              </div>
            ))}
        </>
      ) : null}
    </>
  );
};
