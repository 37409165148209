import styled from 'styled-components';

export const WidgetWrapper = styled.section`
  border-radius: 2px;
  padding: 16px;
  background: ${({ theme }) => theme.white};
  box-shadow: 0px 4px 8px rgba(6, 35, 51, 0.15);
`;

export const FlexibleColumns = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-gap: 16px;
  grid-template-rows: auto;
  padding: 8px 24px 8px;

  @media (max-width: 768px) {
    grid-auto-flow: row;
  }
`;

export const DashboardWidget = styled.section`
  position: relative;
`;

export const WidgetHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 12px;
  color: ${({ theme }) => theme.bodyTextColor};
  text-decoration: none;
`;

export const WidgetTitle = styled.span`
  display: inline-flex;
  align-items: center;
  font-size: ${props => (props.alternate ? '18px' : '14px')};
  line-height: 1;
  color: ${({ theme, alternate }) =>
    alternate ? theme.black : theme.bodyTextColor};
  text-decoration: none;
`;
