import React, { FunctionComponent, useState } from 'react';
import { StaxButton } from '../../../shared/Stax/Button';
import { Tooltip } from '@fattmerchantorg/truffle-components';

interface IMagicLinkButtonProps {
  email: string | null;
  merchant_id: string;
  company_name: string;
  handleMagicLinkGeneration: Function;
}

export const MagicLinkButton: FunctionComponent<
  IMagicLinkButtonProps
> = props => {
  const [copied, setCopied] = useState<boolean>(false);
  const tooltipText = props.email
    ? !copied
      ? 'Copy secure link to clipboard. Share with the merchant to give them access to review and sign.'
      : `Copied secure link to clipboard for ${props.email}`
    : 'Please select a user.';

  return (
    <Tooltip
      hideOnClick={false}
      content={tooltipText}
      onHidden={() => {
        setCopied(false);
      }}
    >
      <StaxButton
        style={{ padding: '7px 16px' }} // To match the MagicLinkUserSelect
        size="small"
        id="copy-magic-link-button"
        data-testid="copy-magic-link-button"
        disabled={!props.email}
        onClick={async () => {
          try {
            await props.handleMagicLinkGeneration(
              props.email,
              props.merchant_id,
              props.company_name
            );
            setCopied(true);
          } catch (e) {
            setCopied(false);
          }
        }}
        icon="fas fa-link"
      >
        COPY SECURE LINK
      </StaxButton>
    </Tooltip>
  );
};
