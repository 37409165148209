// returns an object with only the specified key/value pairs kept
export function pick<T>(object: T, ...keys: Array<keyof T>): Partial<T> {
  return object && typeof object === 'object'
    ? keys.reduce((sum, key) => {
        sum[key] = object[key];
        return sum;
      }, {} as Partial<T>)
    : object;
}

// returns an object by keeping only the values that pass the given predicate
export function pickBy<T>(
  object: T,
  predicate: (value: T[keyof T]) => boolean
): Partial<T> {
  return object && typeof object === 'object'
    ? Object.keys(object).reduce((sum, key) => {
        if (predicate(object[key])) sum[key] = object[key];
        return sum;
      }, {})
    : object;
}

// returns an object with the specified key/value pairs omittied
export function omit<T>(object: T, ...keys: Array<keyof T>): Partial<T> {
  return object && typeof object === 'object'
    ? Object.keys(object).reduce((sum, key) => {
        if (!keys.includes(key as keyof T)) sum[key] = object[key];
        return sum;
      }, {})
    : object;
}

// returns an object with the values that pass the given predicate omittied
export function omitBy<T>(
  object: T,
  predicate: (value: T[keyof T]) => boolean
): Partial<T> {
  return object && typeof object === 'object'
    ? Object.keys(object).reduce((sum, key) => {
        if (!predicate(object[key])) sum[key] = object[key];
        return sum;
      }, {})
    : object;
}

/**
 * Checks if objects are "empty": an object without any properties.
 * Falsey arguments are also considered empty.
 */
export function isEmpty(object: object): boolean {
  return (
    !object || typeof object !== 'object' || Object.keys(object).length === 0
  );
}

export function isShallowEqual(a: object, b: object): boolean {
  if (a && typeof a === 'object' && b && typeof b === 'object') {
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);

    // if the two objects have different lengths, then they are not equal
    if (aKeys.length !== bKeys.length) return false;

    return [...aKeys, ...bKeys].every(key => {
      const aValue = a[key];
      const bValue = b[key];

      // if both values are arrays, perform a slightly deeper check
      if (Array.isArray(aValue) && Array.isArray(bValue)) {
        // if the two arrays have different lengths, then the arrays are not equal
        if (aValue.length !== bValue.length) return false;

        // if not every element in the arrays is equal, then the arrays are not equal
        return aValue.every((aValueEl, i) => aValueEl === bValue[i]);
      }

      return aValue === bValue;
    });
  }

  return false;
}

export function forEach<T = any>(
  object: { key: string; value: T },
  callback: (key: string, value: T) => any
) {
  Object.keys(object).forEach(key => callback(key, object[key]));
}

export function get(obj: object, path: string, fallback?: any) {
  if (!obj || !path) return fallback;

  const paths = Array.isArray(path) ? path : path.split('.');
  let results = obj;
  let i = 0;

  while (i < paths.length && results !== undefined && results !== null) {
    results = results[paths[i]];
    i++;
  }

  if (i === paths.length) {
    return results !== undefined ? results : fallback;
  }

  return results !== undefined && results !== null ? results : fallback;
}

export function set(object: object, path: string, value: any) {
  const [head, ...rest] = path.split('.');

  if (rest.length) {
    if (!object[head]) object[head] = {};
    set(object[head], rest.join('.'), value);
  } else {
    object[head] = value;
  }
}
