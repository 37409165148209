import React, { FunctionComponent, useCallback, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { coreapi } from '../../../../api/core';
import {
  SelectedMerchantStore,
  updateSelectedMerchantRegistration,
} from '../../../../context';
import { HelloSignModal } from '../../../shared/HellosignModal';
import { useToaster, useAuthToken } from '../../../../hooks';
import { Registration } from '@fattmerchantorg/types-omni';
import { getContainerId } from '../../../../../src/components/shared/HellosignModal/HellosignClient';

const getHellosignCountersignSignatureUrl = async (
  merchantId: string,
  authToken: string
) => {
  const signatures = await coreapi.get<{ signer_role: string; url: string }[]>(
    authToken,
    `hellosign/${merchantId}/signatures`
  );
  const counterSignature = signatures.find(
    signature => signature.signer_role !== 'Owner'
  );
  return counterSignature && counterSignature.url;
};

const putMerchantRegistration = async (
  merchantId: string,
  payload,
  authToken: string
): Promise<Registration> => {
  try {
    const response = await coreapi.put(
      authToken,
      `merchant/${merchantId}/registration`,
      payload
    );

    return response;
  } catch (error) {
    throw error;
  }
};

export const CountersignModal: FunctionComponent<{
  isOpen: boolean;
  onClose: () => void;
}> = props => {
  const { isOpen, onClose } = props;
  const match = useRouteMatch<{ merchantId?: string }>();
  const merchantId = match.params.merchantId;
  const { toaster, toast } = useToaster();
  const { dispatch } = useContext(SelectedMerchantStore);

  const authToken = useAuthToken();
  const getSignatureUrl = useCallback(async () => {
    try {
      const signatureUrl = await getHellosignCountersignSignatureUrl(
        merchantId,
        authToken
      );
      return signatureUrl;
    } catch (error) {
      onClose();
      toaster(
        toast.error(`${error.error}`, 'Failed to load countersign signature')
      );
    }
  }, [authToken, merchantId, toaster, toast, onClose]);

  const onSign = async () => {
    onClose();
    const payload = {
      signature_status: 'COUNTER-SIGNED',
      countersigned_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
    };
    try {
      const registration = await putMerchantRegistration(
        merchantId,
        payload,
        authToken
      );
      dispatch(updateSelectedMerchantRegistration(registration));
    } catch (error) {
      toaster(
        toast.error(
          `${error}`,
          'Failed to update signature status and countersignature time'
        )
      );
    }
  };

  return (
    <HelloSignModal
      isOpen={isOpen}
      containerId={getContainerId(merchantId)}
      getSignatureUrl={getSignatureUrl}
      onSign={onSign}
      onClose={onClose}
    />
  );
};
