import React, { FunctionComponent, useCallback, useContext } from 'react';

import { Transaction } from '@fattmerchantorg/types-omni';
import { transactionsapi } from '../../api';
import { TransactionDrawer } from './components/TransactionDrawer';
import { TransactionDetailDrawer } from '../shared/AdvancedDetailDrawer/TransactionDetailDrawer';
import { useAuthToken, useSearchState, useFeatureFlag } from '../../hooks';
import { SearchCollection } from '../shared';
import { TransactionData } from '../shared/AdvancedDetailDrawer/AdvancedDetailDrawer.types';
import { TransactionsProps } from './Transactions.types';
import { SelectedMerchantStore } from '../../context';
import { getColumns } from './Transactions.util';
import { endOfDay, startOfDay } from 'date-fns';
import { formatDate, parseDate } from '../../util/date.util';

export const Transactions: FunctionComponent<TransactionsProps> = props => {
  const authToken = useAuthToken();
  const {
    state: { merchant },
  } = useContext(SelectedMerchantStore);

  const [detailId, setDetailId] = useSearchState<string>('detailId');

  const { available: hasAdvancedDetailDrawer } = useFeatureFlag(
    'AdvancedDetailDrawer',
    'StaxConnect'
  );

  const merchantId = props.merchantId || merchant?.id;

  /** Fetches a set of Transactions filtered according to query parameters. */
  const getData = useCallback(
    query => {
      const startDate = startOfDay(parseDate(query.startDate));
      const endDate = endOfDay(parseDate(query.endDate));
      if ('perPage' in query) {
        query.per_page = query.perPage;
        delete query.perPage;
      }
      return transactionsapi.get(authToken, '/transactions', {
        ...query,
        // startDate & endDate will always be available (either selected or default)
        // format dates to include appropriate time since dates only seem to return inaccurate results, sep the endDate.
        // parseDate fn will take core of timezones
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
      });
    },
    [authToken]
  );

  /** Fetches an individual Transaction via ID. */
  const getTransaction = useCallback(
    (transactionId: string): Promise<TransactionData> => {
      return transactionsapi.get(authToken, `/transactions/${transactionId}`);
    },
    [authToken]
  );

  return (
    <div>
      <SearchCollection<Transaction>
        // if we don't have a merchant id yet
        // we're not ready to get data
        getData={merchantId ? getData : null}
        blacklist={['detailId', 'r', 'v']}
        onRowClick={t => setDetailId(t.id)}
        showExportButton
        columns={getColumns(merchant)}
      />
      {hasAdvancedDetailDrawer ? (
        <TransactionDetailDrawer
          open={!!detailId}
          onClose={() => setDetailId(null)}
          configType="Transaction"
          getData={merchantId ? getTransaction : null}
          isSettlement={false}
          merchant={merchant}
        />
      ) : (
        <TransactionDrawer
          open={!!detailId}
          onClose={() => setDetailId(null)}
          getData={merchantId ? getTransaction : null}
          merchant={merchant}
        />
      )}
    </div>
  );
};
