import React, {
  FunctionComponent,
  useState,
  useContext,
  useCallback,
} from 'react';
import styled from 'styled-components';
import {
  BPSettingsRow,
  BPSettingsRowTitle,
  BPSettingsRowFields,
  BPSettingsRowDescription,
  FieldWrap,
  TwoCol,
  ThreeCol,
  SubCol,
} from '../components/BPSettingsRow';
import { DB } from '@fattmerchantorg/types-engine';
import {
  SmallPrimaryButton,
  SmallSecondaryButton,
  Select,
  TextField,
  RadioButton,
  Label,
  Icon,
} from '@fattmerchantorg/truffle-components';
import { CTAPageHeader } from '../components/CTAPageHeader';
import {
  DeleteFormDataModal,
  initialDeleteModalState,
  DeleteModalContext,
} from '../components/DeleteFormDataModal';
import { Form, Field } from 'react-final-form';
import * as DisputeFeeFormUtil from './DisputeFeeForm.util';

import * as billingProfileUtil from '../util/billingProfile.util';
import { SelectedMerchantStore } from '../../../context';
import { fetchSelectedMerchantBillingProfiles } from '../../../context/selected-merchant/SelectedMerchants.actions';
import { useAsyncEffect, useAuthToken, useToaster } from '../../../hooks';
import { catanapi } from '../../../api';
import { FormApi } from 'final-form';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import { Location } from 'history';
import {
  useModalReducer,
  sendOpenSelected,
} from '../../../hooks/useModalReducer';
import {
  DisputeFeeFieldsErrors,
  DisputeFeeFieldsValues,
} from './DisputeFeeForm.types';
import { get } from 'lodash';
import { getErrorsFromCatanErrorResponse } from '../../../util/catan.util';
import { TransactionsLoadingState } from '../util/loadingState';
import { ordinal } from '../../../util';
import { channelAndSubchannelText } from '../util/billingProfile.util';

interface DisputeFeeProps {
  initialValues?: {
    channel: DB.Billing['channel'];
    subChannel: DB.Billing['subchannel'];
  };
  isFirstProfile?: boolean;
  editBillingId?: string;
}

const ChannelFields = styled(TwoCol)`
  flex-direction: row;

  > div {
    max-width: calc(50% - 8px);

    &:first-child {
      margin-right: 16px;
    }
  }
`;

export const InlineLabel = (props: {
  text: string;
  info?: string;
  required?: boolean;
}) => {
  const { text, info, required } = props;

  return (
    <Label
      text={text}
      info={info}
      required={required}
      customStyles={{
        wrapper: { display: 'flex', alignItems: 'center' },
        label: { margin: '0 4px 0 0' },
      }}
    />
  );
};

const validateForm = (
  formValues: DisputeFeeFieldsValues
): DisputeFeeFieldsErrors => {
  const errors = {
    ...DisputeFeeFormUtil.validateFormValues(formValues),
  } as DisputeFeeFieldsErrors;
  return errors;
};

const initialFormValues: Partial<DisputeFeeFieldsValues> = {
  type: 'TRANSACTION',
  is_gross_settlement: 'true',
  fees_account_id: null,
  billing_cycle: 'DAILY',
  deposit_account_id: null,
};

const DynamicBillingCycle = ({ billingCycle, billingCycleDay }) => {
  console.log(billingCycle, billingCycleDay);
  switch (billingCycle) {
    case 'Monthly':
      return (
        <span>
          <strong>Monthly</strong> on the{' '}
          <strong>{ordinal(billingCycleDay)}</strong>
        </span>
      );
    case 'Daily':
      return <strong>Daily</strong>;
    default:
      return null;
  }
};

type PaymentMethodDescriptionProps = {
  channel: string;
  subchannel?: string;
};
const PaymentMethodDescription = ({
  channel,
  subchannel,
}: PaymentMethodDescriptionProps) =>
  channel ? (
    <span>
      These Dispute Fees affects{' '}
      <strong>
        {channel}
        {subchannel && ` ${subchannel}`}
      </strong>{' '}
      transactions.
    </span>
  ) : (
    <span>
      Please choose a payment method associated with this dispute fee.
    </span>
  );

type FeeDescriptionProps = {
  channel: string;
  subchannel?: string;
  inquiryFee?: number;
  perTransactionAmount: number;
  billingCycle?: string;
  billingCycleDay?: string;
  isGrossSettlement: boolean;
  feesAccount?: string;
};

const FeeDescription = ({
  channel,
  subchannel,
  inquiryFee,
  perTransactionAmount,
  billingCycle,
  billingCycleDay,
  isGrossSettlement,
  feesAccount,
}: FeeDescriptionProps) => {
  return (
    <>
      <p>
        <strong>{channelAndSubchannelText(channel, subchannel)}</strong>{' '}
        disputes are charged a{' '}
        <strong>
          {`$${
            inquiryFee?.toLocaleString('en-US', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) ?? '0.00'
          }`}
        </strong>{' '}
        fee per inquiry,{' '}
        <strong>
          {`$${
            perTransactionAmount.toLocaleString('en-US', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) ?? '0.00'
          }`}
        </strong>{' '}
        fee per dispute, and{' '}
        <strong>
          {`$${
            (0.0).toLocaleString('en-US', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }) ?? '0.00'
          }`}
        </strong>{' '}
        fee if Pre-Arbitration is lost.
      </p>
      <p>
        The fees are collected{' '}
        <DynamicBillingCycle
          billingCycle={billingCycle}
          billingCycleDay={billingCycleDay}
        />{' '}
        as a <strong>{isGrossSettlement ? 'Gross' : 'Net'} Settlement</strong>
        {' from'}{' '}
        {feesAccount ? (
          <>
            <strong>{feesAccount}</strong>
          </>
        ) : channel === 'Bank' ? (
          'an unspecified account'
        ) : (
          ''
        )}
        .
      </p>
    </>
  );
};

type DepositDescriptionProps = {
  depositAccount?: string;
};

const DepositDescription = ({ depositAccount }: DepositDescriptionProps) => {
  if (!depositAccount) return null;

  return (
    <span>
      Dispute amounts will be held or released to{' '}
      <strong>
        {depositAccount ?? 'an unspecified account'} the same business day
      </strong>{' '}
      after a dispute has been opened.
    </span>
  );
};

export const DisputeFee: FunctionComponent<DisputeFeeProps> = props => {
  const authToken = useAuthToken();
  const { state, dispatch: selectedMerchantDispatch } = useContext(
    SelectedMerchantStore
  );
  const { merchant } = state;
  const engineCompanyId = get(state?.registration, 'external_company_id', null);
  const { initialValues, isFirstProfile, editBillingId } = props;
  const [existingBilling, setExistingBilling] = useState<DB.Billing | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedInquiry, setSelectedInquiry] = useState(null);
  const [locationUrl, setLocationUrl] = useState(null);
  const [deleteModalState, deleteModalDispatch] =
    useModalReducer<DeleteModalContext>(initialDeleteModalState);
  const [channelSelectOptions, setChannelSelectOptions] = useState(
    billingProfileUtil.channelSelectOptions
  );
  const [accountOptions, setAccountOptions] =
    useState<billingProfileUtil.PaymentMethodSelectOption[]>();
  const { toaster, toast } = useToaster();
  const billingCycleDayOptions =
    billingProfileUtil.billingCycleDaySelectOptions();
  const history = useHistory();

  if (initialValues && isFirstProfile) {
    initialValues.channel = 'ALL';
  }
  const location = useLocation();
  const [fromCancel, setFromCancel] = useState(false);
  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!locationUrl) {
      setLocationUrl(nextLocation);
      deleteModalDispatch(sendOpenSelected());
      return false;
    }
    return true;
  };
  const afterBlockedNavigation = () => {
    if (fromCancel) {
      history.goBack();
    } else {
      history.push(locationUrl.pathname);
    }
  };

  useAsyncEffect(async () => {
    if (existingBilling) {
      return;
    }
    if (initialValues && isFirstProfile) {
      let channelList: billingProfileUtil.ChannelSelectOption[];
      channelList = billingProfileUtil.channelSelectOptions.map(billprofile => {
        if (billprofile.label !== 'All') {
          return { ...billprofile, isDisabled: true };
        }
        return { ...billprofile, isDisabled: false };
      });
      setChannelSelectOptions(channelList);
    } else {
      setChannelSelectOptions(billingProfileUtil.channelSelectOptions);
    }
  }, [isFirstProfile]);

  useAsyncEffect(async () => {
    if (
      !state ||
      !editBillingId ||
      editBillingId === '' ||
      !state.billingProfiles ||
      !state.billingProfiles.data
    ) {
      return;
    }
    setIsLoading(true);
    const selectedBP = state.billingProfiles.data.find(
      bp => bp.billing_id === editBillingId
    );

    const selectedInquiryBP = state.billingProfiles.data.filter(
      b => b.type === 'INQUIRY'
    );

    if (selectedBP === undefined) {
      toaster(
        toast.error(
          'Unexpected error loading Billing Profile',
          'Error loading Billing Profile'
        )
      );
      setIsLoading(false);
    }
    setSelectedInquiry({ ...selectedInquiryBP });
    setExistingBilling({ ...selectedBP });
    setExistingBilling({ ...selectedBP });

    setIsLoading(false);
  }, [editBillingId, state]);

  useAsyncEffect(async () => {
    if (!authToken) return;
    setIsLoading(true);
    const paymentMethods = await billingProfileUtil.getEnginePaymentMethods(
      authToken,
      engineCompanyId,
      toast,
      toaster
    );
    setAccountOptions(getAccountOptions(paymentMethods));
    setIsLoading(false);
  }, [authToken, engineCompanyId]);

  const getAccountOptions = (
    accountOptions
  ): billingProfileUtil.PaymentMethodSelectOption[] => {
    return accountOptions?.data?.reduce((accountOptns, accountOptn) => {
      accountOptns.push({
        value: accountOptn.method_id,
        label: `${accountOptn.person_name} (****${accountOptn.last_four})`,
      });

      return accountOptns;
    }, []);
  };

  const clearForm = useCallback(
    (reset: FormApi['reset'] | undefined) => {
      // This redirect back to the new route location that is been selected
      if (locationUrl) {
        history.push(`${locationUrl.pathname}${locationUrl.search}`);
      }
      if (typeof reset === 'function') {
        reset();
      }
    },
    [history, locationUrl]
  );

  const saveProfile = useCallback(
    async formValues => {
      const inquiryProfile = formValues;

      try {
        existingBilling
          ? await catanapi.put(
              authToken,
              `/billing/${existingBilling.billing_id}`,
              {
                ...DisputeFeeFormUtil.mapFormValuesToPayload(formValues),
              }
            )
          : await catanapi.post(authToken, '/billing', {
              ...DisputeFeeFormUtil.mapFormValuesToPayload(formValues),
              company_id: engineCompanyId,
              onboarding_id: null,
            });
        inquiryProfile?.inquiry_fee
          ? await catanapi.put(
              authToken,
              `/billing/${inquiryProfile.inquiry_fee_id}`,
              {
                ...DisputeFeeFormUtil.mapInquiryFormValuesToPayload(
                  inquiryProfile
                ),
              }
            )
          : await catanapi.post(authToken, '/billing', {
              ...DisputeFeeFormUtil.mapInquiryFormValuesToPayload(
                inquiryProfile
              ),
              company_id: engineCompanyId,
              onboarding_id: null,
            });
        setLocationUrl(location);
        history.push(`/merchant/${merchant?.id}/billing-profiles`);
        toaster(
          existingBilling
            ? toast.success(
                'Your Dispute Billing configuration has been updated',
                'Dispute Billing Updated'
              )
            : toast.success(
                'Your Dispute Billing profile has been created',
                'Dispute Billing Created'
              )
        );
        selectedMerchantDispatch(
          fetchSelectedMerchantBillingProfiles(engineCompanyId)
        );
      } catch (error) {
        const errors = getErrorsFromCatanErrorResponse(error);
        if (errors.length) {
          const contentNodes = errors.map(errMsg =>
            React.createElement('div', null, errMsg)
          );
          toaster(
            toast.error(
              React.createElement('div', null, contentNodes),
              'Validation Error'
            )
          );
        } else {
          toaster(
            toast.error(
              error,
              'There was a problem saving this billing profile'
            )
          );
        }
        clearForm(formValues.form?.reset);
      }
    },
    [
      authToken,
      clearForm,
      engineCompanyId,
      existingBilling,
      history,
      location,
      merchant,
      selectedMerchantDispatch,
      toast,
      toaster,
    ]
  );

  const onChannelChange = (
    channel: DB.Billing['channel'],
    subchannel: DB.Billing['subchannel'],
    form: FormApi,
    isSubchannelChange: boolean
  ): boolean => {
    if (
      !isSubchannelChange &&
      channel !== undefined &&
      subchannel !== undefined
    ) {
      form.change('subchannel', undefined);
      subchannel = undefined;
    }

    if (isSubchannelChange && ['VENMO', 'PAYPAL'].includes(subchannel)) {
      form.change('per_transaction_amount', 0.0);
    }
    return false;
  };

  const getInitialValues = useCallback(() => {
    if (existingBilling && selectedInquiry) {
      const channel = channelSelectOptions.filter(
        chan => existingBilling.channel === chan.value
      )[0];
      const subchannels = billingProfileUtil.subchannelSelectOptions();
      const subchannel = subchannels.filter(
        subchan => existingBilling.subchannel === subchan.value
      )[0];
      return DisputeFeeFormUtil.mapBillingProfileToInitialFormValues({
        channel: channel,
        subchannel: subchannel,
        billing_cycle_day: existingBilling.billing_cycle_day,
        billing_cycle: existingBilling.billing_cycle,
        deposit_account_id: existingBilling.deposit_account_id,
        fees_account_id: existingBilling.fees_account_id,
        is_gross_settlement: existingBilling.is_gross_settlement
          ? 'true'
          : 'false',
        per_transaction_amount: existingBilling.per_transaction_amount ?? 0,
        inquiry_fee: selectedInquiry[0]?.per_transaction_amount ?? 0,
        inquiry_fee_id: selectedInquiry[0]?.billing_id,
        funding_schedule_days: existingBilling.funding_schedule_days,
      });
    }
    return DisputeFeeFormUtil.mapBillingProfileToInitialFormValues({
      ...initialFormValues,
      per_transaction_amount: 0.0,
      billing_cycle_day: 5,
    });
  }, [existingBilling, selectedInquiry, channelSelectOptions]);

  const fillFeeAccount = () => {
    if (!existingBilling || !accountOptions) {
      return null;
    }
    const feeAcc = accountOptions.find(
      fa => fa.value === existingBilling.fees_account_id
    );
    if (feeAcc === undefined) {
      return null;
    }
    return { label: feeAcc.label, value: feeAcc.value };
  };

  const fillDepositAccount = () => {
    if (!existingBilling || !accountOptions) {
      return null;
    }
    const depositAcc = accountOptions.find(
      da => da.value === existingBilling.deposit_account_id
    );
    if (depositAcc === undefined) {
      return null;
    }
    return { label: depositAcc.label, value: depositAcc.value };
  };

  return (
    <div>
      {isLoading && <TransactionsLoadingState></TransactionsLoadingState>}
      {!isLoading && (
        <Form
          initialValues={{
            ...getInitialValues(),
          }}
          validate={validateForm}
          onSubmit={saveProfile}
          mutators={DisputeFeeFormUtil.formMutators}
        >
          {formProps => (
            <form autoComplete="off" onSubmit={formProps.handleSubmit}>
              <Prompt
                message={handleBlockedNavigation}
                when={formProps.dirty}
              />
              <DeleteFormDataModal
                status={deleteModalState.status}
                title="Unsaved Changes"
                message={
                  <span>
                    The changes you've made to this dispute fee have{' '}
                    <strong> not been saved.</strong> You will lose all these
                    changes if you leave this page.
                  </span>
                }
                modalDispatch={deleteModalDispatch}
                actionFunc={() => {
                  clearForm(formProps.form.reset);
                  afterBlockedNavigation();
                }}
                cancelText="Stay on this Page"
                actionText="Leave this Page"
              />
              <CTAPageHeader
                headingText={
                  <span>
                    {editBillingId === '' || editBillingId === undefined
                      ? 'Add - '
                      : ' '}
                    Dispute Fees
                    {formProps.values.channel?.label ? (
                      <>
                        {' - '}{' '}
                        <strong>
                          {formProps.values.channel?.label}
                          {formProps.values.subchannel?.label &&
                            ` ${formProps.values.subchannel?.label}`}
                        </strong>
                      </>
                    ) : (
                      ''
                    )}
                  </span>
                }
                subheadingText={
                  'View or edit dispute fees by filling out the information below'
                }
                ctaArea={
                  formProps.dirty
                    ? [
                        <SmallSecondaryButton
                          onClick={event => {
                            event.preventDefault();
                            setFromCancel(true);
                            setLocationUrl(null);
                            if (!existingBilling) {
                              deleteModalDispatch(sendOpenSelected());
                            } else {
                              clearForm(formProps.form.reset);
                            }
                          }}
                          disabled={!formProps.dirty}
                          data-trackingid="bp-transaction-cancel-button"
                          key="bp-transaction-cancel-button"
                          type="button"
                        >
                          {existingBilling ? 'Reset Changes' : 'Cancel'}
                        </SmallSecondaryButton>,
                        <SmallPrimaryButton
                          disabled={!formProps.valid}
                          data-trackingid="bp-transaction-create-button"
                          key="bp-transaction-create-button"
                          type="submit"
                        >
                          {existingBilling
                            ? 'Save Changes'
                            : 'Create Dispute Fee'}
                        </SmallPrimaryButton>,
                      ]
                    : []
                }
                breadcrumb={{
                  text: 'Billing Profiles',
                  path: `/merchant/${merchant?.id}/billing-profiles`,
                }}
              />
              <BPSettingsRow data-highlight>
                <BPSettingsRowTitle>
                  <h3>Payment Method</h3>
                  <span>
                    Determines what payment method these dispute fees are
                    associated with.
                  </span>
                </BPSettingsRowTitle>
                <BPSettingsRowFields>
                  <ChannelFields>
                    <FieldWrap style={{ maxWidth: '180px' }}>
                      <InlineLabel text="Channel" required />
                      <Field name="channel" required={true} clearable={false}>
                        {props => {
                          return (
                            <div>
                              <Select
                                {...props.input}
                                options={channelSelectOptions}
                                isDisabled={
                                  existingBilling?.subchannel === 'ALL' &&
                                  existingBilling?.channel === 'ALL'
                                }
                                onChange={(
                                  option: billingProfileUtil.ChannelSelectOption
                                ) => {
                                  props.input.onChange(option);
                                  onChannelChange(
                                    option?.value,
                                    formProps.values?.subchannel,
                                    formProps.form,
                                    false
                                  );
                                  formProps.form.mutators.setDefaultSubchannel(
                                    option?.value
                                  );
                                }}
                                placeholder="Choose a Channel"
                                isClearable={false}
                                data-trackingid="bp-dispute-channel"
                                isOptionDisabled={option => option.isDisabled}
                              />
                            </div>
                          );
                        }}
                      </Field>
                    </FieldWrap>
                    {formProps.values?.channel &&
                    !['BANK'].includes(formProps.values?.channel?.value) ? (
                      <FieldWrap style={{ maxWidth: '200px' }}>
                        <InlineLabel text="SubChannel" required />
                        <Field name="subchannel" clearable={false}>
                          {props => {
                            return (
                              <div data-testid="bp-dispute-subchannel">
                                <Select
                                  {...props.input}
                                  options={billingProfileUtil.subchannelSelectOptions(
                                    formProps.values?.channel?.value
                                  )}
                                  isDisabled={
                                    !formProps.values?.channel ||
                                    (existingBilling?.subchannel === 'ALL' &&
                                      existingBilling?.channel === 'ALL')
                                  }
                                  placeholder="Choose a SubChannel"
                                  isClearable={false}
                                  data-trackingid="bp-dispute-subchannel"
                                  error={!!formProps.errors?.subchannel}
                                  helperText={formProps.errors?.subchannel}
                                />
                              </div>
                            );
                          }}
                        </Field>
                      </FieldWrap>
                    ) : null}
                  </ChannelFields>
                </BPSettingsRowFields>
                <BPSettingsRowDescription>
                  <PaymentMethodDescription
                    channel={formProps.values.channel?.label}
                    subchannel={formProps.values.subchannel?.label}
                  />
                </BPSettingsRowDescription>
              </BPSettingsRow>
              {formProps.values?.channel ? (
                <>
                  <BPSettingsRow>
                    <BPSettingsRowTitle>
                      <h3>Dispute Fees</h3>
                      <span>Various fees associated with disputes.</span>
                    </BPSettingsRowTitle>
                    <BPSettingsRowFields>
                      <ThreeCol>
                        <SubCol>
                          <InlineLabel
                            text="Inquiry Fee"
                            info="When a customer inquires about a purchase"
                          />
                          <FieldWrap style={{ margin: '8px 0 0 0' }}>
                            <Field
                              name="inquiry_fee"
                              format={v =>
                                v !== null && v !== undefined
                                  ? v.toString().replace(/[^0-9.,]/g, '')
                                  : ''
                              }
                              parse={v =>
                                v !== null && v !== undefined
                                  ? v.toString().replace(/[^0-9.,]/g, '')
                                  : ''
                              }
                            >
                              {props => {
                                return (
                                  <TextField
                                    {...props.input}
                                    label="Amount"
                                    placeholder="0.00"
                                    inputPrefix={
                                      <Icon icon={['fas', 'dollar-sign']} />
                                    }
                                    autoComplete="off"
                                    error={!!formProps.errors?.inquiry_fee}
                                    helperText={formProps.errors?.inquiry_fee}
                                  />
                                );
                              }}
                            </Field>
                          </FieldWrap>
                        </SubCol>

                        <SubCol>
                          <InlineLabel
                            text="Dispute Fee"
                            info="When a customer disputes a purchase"
                          />
                          <FieldWrap style={{ margin: '8px 0 0 0' }}>
                            <Field
                              name="per_transaction_amount"
                              format={v =>
                                v !== null && v !== undefined
                                  ? v.toString().replace(/[^0-9.,]/g, '')
                                  : ''
                              }
                              parse={v =>
                                v !== null && v !== undefined
                                  ? v.toString().replace(/[^0-9.,]/g, '')
                                  : ''
                              }
                            >
                              {props => {
                                return (
                                  <TextField
                                    {...props.input}
                                    label="Amount"
                                    placeholder="0.00"
                                    inputPrefix={
                                      <Icon icon={['fas', 'dollar-sign']} />
                                    }
                                    autoComplete="off"
                                    error={
                                      !!formProps.errors?.per_transaction_amount
                                    }
                                    helperText={
                                      formProps.errors?.per_transaction_amount
                                    }
                                    required
                                  />
                                );
                              }}
                            </Field>
                          </FieldWrap>
                        </SubCol>
                        <SubCol>
                          <InlineLabel
                            text="Pre-Arbitration Fee"
                            info="When a merchant loses Pre-Arbitration"
                          />
                          <FieldWrap style={{ margin: '8px 0 0 0' }}>
                            <Field
                              name="pre_arb"
                              format={v =>
                                v ? v.toString().replace(/[^0-9.,]/g, '') : ''
                              }
                              parse={v =>
                                v ? v.toString().replace(/[^0-9.,]/g, '') : ''
                              }
                            >
                              {props => {
                                return (
                                  <TextField
                                    {...props.input}
                                    label="Amount"
                                    placeholder="0.00"
                                    inputPrefix={
                                      <Icon icon={['fas', 'dollar-sign']} />
                                    }
                                    autoComplete="off"
                                    disabled
                                  />
                                );
                              }}
                            </Field>
                          </FieldWrap>
                        </SubCol>
                      </ThreeCol>
                      <TwoCol>
                        <SubCol>
                          <InlineLabel
                            text="Settlement"
                            info="Gross separates charge and fee transactions. Net combines charge and fee transactions."
                          />

                          <FieldWrap style={{ margin: '8px 0 0 0' }}>
                            <Field
                              name="is_gross_settlement"
                              type="radio"
                              value="true"
                            >
                              {props => {
                                return (
                                  <RadioButton {...props.input} label="Gross" />
                                );
                              }}
                            </Field>
                            <Field
                              name="is_gross_settlement"
                              type="radio"
                              value="false"
                            >
                              {props => {
                                return (
                                  <RadioButton {...props.input} label="Net" />
                                );
                              }}
                            </Field>
                          </FieldWrap>
                        </SubCol>
                        <SubCol>
                          <InlineLabel
                            text="Billing Cycle"
                            info="When the fee will be billed."
                          />
                          <FieldWrap style={{ margin: '8px 0 0 0' }}>
                            <Field
                              name="billing_cycle"
                              style={{ margin: '8px 0 0 0' }}
                              type="radio"
                              value="DAILY"
                            >
                              {props => {
                                return (
                                  <RadioButton
                                    {...props.input}
                                    label="Daily"
                                    style={{ margin: '8px 0 0 0' }}
                                  />
                                );
                              }}
                            </Field>
                            <Field
                              name="billing_cycle"
                              type="radio"
                              value="MONTHLY"
                            >
                              {props => {
                                return (
                                  <RadioButton
                                    {...props.input}
                                    label="Monthly"
                                  />
                                );
                              }}
                            </Field>
                          </FieldWrap>
                          {formProps.values.billing_cycle === 'MONTHLY' ? (
                            <FieldWrap
                              style={{
                                margin: '16px 0 0 0',
                                maxWidth: '100px',
                              }}
                            >
                              <label>Day of Month</label>
                              <Field
                                name="billing_cycle_day"
                                format={value => {
                                  return billingCycleDayOptions.find(
                                    option => option?.value === value
                                  );
                                }}
                                parse={option => {
                                  return option?.value;
                                }}
                                clearable={false}
                              >
                                {props => {
                                  return (
                                    <div>
                                      <Select
                                        {...props.input}
                                        options={billingCycleDayOptions}
                                        isClearable={false}
                                        data-trackingid="bp-transaction-billing-cycle-day"
                                        error={
                                          !!formProps.errors?.billing_cycle_day
                                        }
                                        helperText={
                                          formProps.errors?.billing_cycle_day
                                        }
                                      />
                                    </div>
                                  );
                                }}
                              </Field>
                            </FieldWrap>
                          ) : null}
                        </SubCol>
                        <SubCol>
                          <FieldWrap style={{ maxWidth: '200px' }}>
                            <Label
                              text="Default Fee Account"
                              info="Default account that the fee will be taken from."
                              required
                            />
                            <Field
                              name="fees_account_id"
                              format={value => {
                                return billingCycleDayOptions.find(
                                  option => option?.value === value
                                );
                              }}
                              parse={option => {
                                return option?.value;
                              }}
                            >
                              {props => {
                                return (
                                  <>
                                    {accountOptions ? (
                                      <div data-testid="bp-transaction-fee-account">
                                        <Select
                                          {...props.input}
                                          options={accountOptions}
                                          data-trackingid="bp-transaction-fee-account"
                                          isClearable={false}
                                          defaultValue={{ ...fillFeeAccount() }}
                                          error={
                                            !!formProps.errors?.fees_account_id
                                          }
                                          helperText={
                                            formProps.touched?.fees_account_id
                                              ? formProps.errors
                                                  ?.fees_account_id
                                              : null
                                          }
                                        />
                                      </div>
                                    ) : null}
                                  </>
                                );
                              }}
                            </Field>
                          </FieldWrap>
                        </SubCol>
                      </TwoCol>
                    </BPSettingsRowFields>
                    <BPSettingsRowDescription>
                      <FeeDescription
                        channel={formProps.values.channel?.label}
                        subchannel={formProps.values.subchannel?.label}
                        inquiryFee={formProps.values.inquiry_fee}
                        perTransactionAmount={
                          formProps.values.per_transaction_amount
                        }
                        billingCycle={billingProfileUtil.formatBillingCycleString(
                          formProps.values.billing_cycle
                        )}
                        billingCycleDay={formProps.values.billing_cycle_day}
                        isGrossSettlement={
                          formProps.values.is_gross_settlement === 'true'
                        }
                        feesAccount={
                          accountOptions?.find(({ value }) => {
                            return value === formProps.values.fees_account_id;
                          })?.label
                        }
                      />
                    </BPSettingsRowDescription>
                  </BPSettingsRow>
                  <BPSettingsRow>
                    <BPSettingsRowTitle>
                      <h3>Dispute</h3>
                      <span>Determines when and where the dispute occurs.</span>
                    </BPSettingsRowTitle>
                    <BPSettingsRowFields>
                      <SubCol>
                        <FieldWrap style={{ maxWidth: '200px' }}>
                          <Label
                            text="Default Dispute Account"
                            info="Default account that disputes will be held or released from."
                            required
                          />
                          <Field
                            name="deposit_account_id"
                            clearable={false}
                            format={value => {
                              return billingCycleDayOptions.find(
                                option => option?.value === value
                              );
                            }}
                            parse={option => {
                              return option?.value;
                            }}
                          >
                            {props => {
                              return (
                                <>
                                  {accountOptions ? (
                                    <div data-testid="bp-transaction-deposit-account">
                                      <Select
                                        {...props.input}
                                        options={accountOptions}
                                        isDisabled={!accountOptions}
                                        defaultValue={{
                                          ...fillDepositAccount(),
                                        }}
                                        data-trackingid="bp-transaction-deposit-account"
                                        isClearable={false}
                                        error={
                                          !!formProps.errors?.deposit_account_id
                                        }
                                        helperText={
                                          formProps.touched?.deposit_account_id
                                            ? formProps.errors
                                                ?.deposit_account_id
                                            : null
                                        }
                                      />
                                    </div>
                                  ) : null}
                                </>
                              );
                            }}
                          </Field>
                        </FieldWrap>
                      </SubCol>
                    </BPSettingsRowFields>
                    <BPSettingsRowDescription>
                      <DepositDescription
                        depositAccount={
                          accountOptions?.find(({ value }) => {
                            return (
                              value === formProps.values.deposit_account_id
                            );
                          })?.label
                        }
                      />
                    </BPSettingsRowDescription>
                  </BPSettingsRow>
                </>
              ) : null}
            </form>
          )}
        </Form>
      )}
    </div>
  );
};
