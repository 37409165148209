import { Icon, SmallPrimaryButton } from '@fattmerchantorg/truffle-components';
import { PricingPlan } from '@fattmerchantorg/types-omni';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { permissionsapi } from '../../../api';
import { SelectedMerchantStore } from '../../../context';
import {
  useAuthToken,
  usePermissions,
  useSearchState,
  useToaster,
} from '../../../hooks';
import { formatCurrency } from '../../../util';
import {
  CenterContainer,
  GreenIcon,
  MainContent,
  PricingHeader,
  SettingsHeaderBreadcrumbs,
  SettingsHeaderContent,
  SettingsHeaderContentActions,
  SettingsHeaderContentTitle,
  SettingsHeaderContentTitleText,
  SettingsHeaderRow,
  SettingsSubheader,
  StyledTextField,
} from '../SettingsPagesStylings';
import { PricingPlansTable } from './PricingPlansTable';
import { PricingPlanView } from './PricingPlanView';

interface BrandData {
  brandName: string;
  pricingFields: PricingPlan[];
}

export interface PricingPlanDetails {
  pricingPlanFields: PricingPlan;
  pricingPlansRows: {
    name: string;
    discountFee?: string;
    transactionFee?: string;
    cappedFee?: string;
    nextDayFunding?: boolean;
  }[];
}

export const PricingPlansList = () => {
  const { permit } = usePermissions();
  const history = useHistory();
  const {
    state: { selectedBrandSwitcherOption },
  } = useContext(SelectedMerchantStore);

  const authToken = useAuthToken();
  const [pricingPlans, setPricingPlans] = useState<PricingPlanDetails[]>([]);
  const [filteredPricingPlans, setFilteredPricingPlans] = useState<
    PricingPlanDetails[]
  >([]);

  const [isLoading, setIsLoading] = useState(true);
  const { toast, toaster } = useToaster();
  const canWrite = permit('godview', 'pricingPlans', 'write');
  const [query, setQuery] = useState('');
  const [pricingPlanId, setPricingPlanId] =
    useSearchState<string>('pricingPlanId');

  const handleCreateNew = () => {
    history.push('/settings/pricingplans/new');
  };

  const generateLoadingRows = () => {
    return Array(5).fill({});
  };

  const fetchPricingPlans = useCallback(async () => {
    setIsLoading(true);

    try {
      if (
        !selectedBrandSwitcherOption ||
        selectedBrandSwitcherOption.includes(',')
      ) {
        return;
      }
      const merchantBrandData: BrandData = await permissionsapi.get(
        authToken,
        `/brand/data/${selectedBrandSwitcherOption}`
      );

      setPricingPlans(
        merchantBrandData?.pricingFields?.map(mapToPricingPlanDetail)
      );
    } catch (error) {
      toaster(
        toast.error(error, 'There was a problem fetching the pricing plans.')
      );
    } finally {
      setIsLoading(false);
    }
  }, [authToken, selectedBrandSwitcherOption, toast, toaster]);

  const pricingPlan = useMemo(() => {
    if (pricingPlanId && pricingPlans?.length) {
      const filteredPricingPlanDetails = pricingPlans?.find(
        p => p?.pricingPlanFields?.id === pricingPlanId
      );
      const selectedPricingPlan = filteredPricingPlanDetails?.pricingPlanFields;
      const isDuplicateForTrust =
        selectedPricingPlan &&
        pricingPlans?.some(
          e => e.pricingPlanFields.name === `${selectedPricingPlan.name}-trust`
        );
      return { selectedPricingPlan, isDuplicateForTrust };
    }
  }, [pricingPlanId, pricingPlans]);

  useEffect(() => {
    fetchPricingPlans();
  }, [fetchPricingPlans]);

  useEffect(() => {
    const lowerCaseQuery = query.toLowerCase();
    setFilteredPricingPlans(
      pricingPlans.filter(pricingPlan => {
        if (
          pricingPlan.pricingPlanFields.displayName
            .toLowerCase()
            .includes(lowerCaseQuery)
        ) {
          return true;
        }

        return pricingPlan.pricingPlansRows.find(pricingPlanRow => {
          if (
            pricingPlanRow.discountFee &&
            `${pricingPlanRow.discountFee}%`.includes(lowerCaseQuery)
          ) {
            return true;
          }
          if (
            pricingPlanRow.transactionFee &&
            formatCurrency(pricingPlanRow.transactionFee).includes(
              lowerCaseQuery
            )
          ) {
            return true;
          }
          if (
            pricingPlanRow.cappedFee &&
            formatCurrency(pricingPlanRow.cappedFee).includes(lowerCaseQuery)
          ) {
            return true;
          }
          return false;
        });
      })
    );
  }, [pricingPlans, query]);

  return (
    <>
      {!pricingPlanId && (
        <SettingsHeaderContent>
          <SettingsHeaderRow>
            <SettingsHeaderContentTitle>
              <SettingsHeaderContentTitleText>
                Pricing Plans
              </SettingsHeaderContentTitleText>
            </SettingsHeaderContentTitle>
            {canWrite && (
              <SettingsHeaderContentActions>
                <SmallPrimaryButton type="button" onClick={handleCreateNew}>
                  <Icon icon={['fas', 'plus']} /> Add Pricing Plans
                </SmallPrimaryButton>
              </SettingsHeaderContentActions>
            )}
          </SettingsHeaderRow>
          <SettingsHeaderRow>
            <SettingsHeaderContentTitle>
              <SettingsSubheader>
                Templated Pricing Plans that can be attached to merchants.
              </SettingsSubheader>
            </SettingsHeaderContentTitle>
            <div className="d-flex">
              <StyledTextField
                placeholder={'Search by plan name or rate'}
                type="search"
                onChange={e => {
                  setQuery(e?.target?.value ?? '');
                }}
              />
            </div>
          </SettingsHeaderRow>
        </SettingsHeaderContent>
      )}

      {isLoading ? (
        <MainContent>
          <PricingPlansTable
            data={{
              columns: COLUMN_DATA,
              rows: generateLoadingRows(),
            }}
            isLoading={true}
          />
        </MainContent>
      ) : filteredPricingPlans && filteredPricingPlans?.length ? (
        pricingPlanId ? (
          <PricingPlanView
            selectedPricingPlan={pricingPlan.selectedPricingPlan}
            isDuplicateForTrust={pricingPlan.isDuplicateForTrust}
          />
        ) : (
          <MainContent>
            {filteredPricingPlans.map(pricingPlan => (
              <PricingPlansTable
                data={{
                  columns: COLUMN_DATA,
                  rows: pricingPlan?.pricingPlansRows,
                }}
                isLoading={false}
                displayName={pricingPlan.pricingPlanFields.displayName}
                onItemClick={() =>
                  setPricingPlanId(pricingPlan.pricingPlanFields.id)
                }
                key={pricingPlan.pricingPlanFields.id}
              />
            ))}
          </MainContent>
        )
      ) : (
        <NoDataFound />
      )}
    </>
  );
};

const NoDataFound = () => {
  return (
    <CenterContainer>
      <PricingHeader> No Pricing Plans Found</PricingHeader>
      <SettingsHeaderBreadcrumbs>
        <Link to={'/settings/pricingplans/new'}>
          Create Your First Pricing plan
        </Link>
      </SettingsHeaderBreadcrumbs>
    </CenterContainer>
  );
};

const mapToPricingPlanDetail = (row: PricingPlan): PricingPlanDetails => {
  const SURCHARGE = {
    name: 'Surcharge',
    discountFee: row?.cnpCcSurchargeRate,
    nextDayFunding: row?.isNdf,
  };

  const CP_AMEX = {
    name: 'CP-AMEX',
    discountFee: row?.cpAmexPerDiscountFee,
    transactionFee: row?.cpAmexPerTransactionFee,
    nextDayFunding: row?.isNdf,
  };

  const CP = {
    name: 'CP',
    discountFee: row?.cpDiscountFee,
    transactionFee: row?.cpPerTransactionFee,
    nextDayFunding: row?.isNdf,
  };

  const CNP_AMEX = {
    name: 'CNP-AMEX',
    discountFee: row?.amexDiscountFee,
    transactionFee: row?.amexPerTransactionFee,
    nextDayFunding: row?.isNdf,
  };

  const CNP = {
    name: 'CNP',
    discountFee: row?.discountFee,
    transactionFee: row?.perTransactionFee,
    nextDayFunding: row?.isNdf,
  };

  const ACH = {
    name: 'ACH',
    discountFee: row?.achDiscountFee,
    transactionFee: row?.achPerTransactionFee,
    cappedFee: row?.achCappedFee,
    nextDayFunding: row?.isNdf,
  };
  return {
    pricingPlanFields: row,
    pricingPlansRows: [SURCHARGE, CP_AMEX, CP, CNP_AMEX, CNP, ACH],
  };
};

const COLUMN_DATA = [
  {
    Header: 'Payment Method',
    accessor: 'name',
    style: {
      height: '42px',
      width: '320px',
    },
    Cell: cell => <strong>{cell.value}</strong>,
  },
  {
    Header: 'Discount Fee',
    accessor: 'discountFee',
    style: {
      height: '42px',
      textAlign: 'right',
    },
    Cell: cell => (cell.value ? `${cell.value}%` : '--'),
  },
  {
    Header: 'Transaction Fee',
    accessor: 'transactionFee',
    style: {
      height: '42px',
      textAlign: 'right',
    },
    Cell: cell =>
      cell.value ? <span>{formatCurrency(cell.value)}</span> : '--',
  },
  {
    Header: 'Capped Fee',
    accessor: 'cappedFee',
    style: {
      height: '42px',
      textAlign: 'right',
    },
    Cell: cell =>
      cell.value ? <span>{formatCurrency(cell.value)}</span> : '--',
  },
  {
    Header: 'Next Day Funding',
    accessor: 'nextDayFunding',
    style: {
      height: '42px',
      textAlign: 'right',
    },
    Cell: cell => (cell.value ? <GreenIcon icon={['fas', 'check']} /> : '--'),
  },
];
