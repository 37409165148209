import { Dispatch, ReactNode } from 'react';
import { Permissions } from '@fattmerchantorg/types-omni';

export interface PermissionsContextProps {
  children: ReactNode;
}

export interface PermissionsState extends Partial<Permissions> {
  effect: { type: PermissionsEffectType; payload?: any } | null;
}

export enum PermissionsActionType {
  REMOVE_PERMISSIONS = 'REMOVE_PERMISSIONS',
  UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS',
  UPDATE_PERMISSIONS_GODVIEW_PERMISSION_VALUE = 'UPDATE_PERMISSIONS_GODVIEW_PERMISSION_VALUE',
  UPDATE_PERMISSIONS_FEATURE_PERMISSION_VALUE = 'UPDATE_PERMISSIONS_FEATURE_PERMISSION_VALUE',
}

export enum PermissionsEffectType {
  REMOVE_PERMISSIONS = 'REMOVE_PERMISSIONS',
  UPDATE_PERMISSIONS = 'UPDATE_PERMISSIONS',
}

export type PermissionsAction = { type: PermissionsActionType; payload?: any };

export interface PermissionsContext {
  state: PermissionsState;
  dispatch: Dispatch<PermissionsAction>;
}
