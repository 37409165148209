import React, { FunctionComponent } from 'react';
import styled, { withTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import { Icon } from '@fattmerchantorg/truffle-components';

interface CTAPageHeaderProps {
  ctaArea: JSX.Element[];
  headingText?: string | JSX.Element;
  subheadingText?: string | JSX.Element;
  breadcrumb?: {
    text: string;
    path: string;
  };
}

const StyledHeader = styled.header`
  position: relative;
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: 1fr;
  padding: 16px 24px;
  width: 100%;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.12);
  z-index: 2;
`;

const Heading = withTheme(
  styled.h1`
    color: ${({ theme }) => theme.white};
    font-size: 28px;
    line-height: 42px;
    margin: 0;
    font-weight: 700;
  `
);

const Subheading = withTheme(
  styled.span`
    display: block;
    color: ${({ theme }) => theme.white};
    font-size: 14px;
    line-height: 20px;
  `
);

const BreadCrumb = withTheme(
  styled(Link)`
    color: ${({ theme }) => theme.linkColor};
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    font-size: 14px;

    &:hover {
      color: ${({ theme }) => theme.linkColor};

      > span:last-child {
        text-decoration: underline;
      }
    }

    > span:last-child {
      display: inline-block;
      margin: 0 0 0 8px;
    }
  `
);

const ButtonBar = withTheme(
  styled.div`
    position: relative;
    display: flex;
    align-items: center;

    > * {
      margin: 0 0 0 16px;
    }
  `
);

export const CTAPageHeader: FunctionComponent<CTAPageHeaderProps> = props => {
  const { ctaArea, headingText, subheadingText, breadcrumb } = props;
  return (
    <StyledHeader>
      <div>
        {breadcrumb && (
          <BreadCrumb to={breadcrumb.path}>
            <Icon icon={['fas', 'caret-left']} /> <span>{breadcrumb.text}</span>
          </BreadCrumb>
        )}
        <Heading>{headingText}</Heading>
        <Subheading>{subheadingText}</Subheading>
      </div>
      <ButtonBar>{ctaArea}</ButtonBar>
    </StyledHeader>
  );
};
