import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback
} from 'react';
import { TextAreaProps } from './TextArea.types';
import { InputContainer } from '../InputContainer/InputContainer';
import styled from 'styled-components';

const Container = styled(InputContainer)`
  min-height: 40px;
  height: auto;
`;

export const TextArea: FunctionComponent<TextAreaProps> = props => {
  const {
    id,
    value,
    label,
    tooltip,
    className,
    onFocus,
    onBlur,
    ...rest
  } = props;

  const [numberOfRows, setNumberOfRows] = useState(2);
  const [active, setActive] = useState(!!value);

  const setActiveIfValue = useCallback(
    (value: string) => {
      if (value && !active) {
        setActive(true);
      }
    },
    [active]
  );

  useEffect(() => {
    const numberOfRows = Math.floor((value?.length || 84) / 84);
    setNumberOfRows(Math.max(numberOfRows, 2));
    setActiveIfValue(value);
  }, [value, setActiveIfValue]);

  const handleFocus = useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement>) => {
      setActive(true);
      if (onFocus) onFocus(e);
    },
    [onFocus]
  );

  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement>) => {
      setActive(!!e.target.value);
      if (onBlur) onBlur(e);
    },
    [onBlur]
  );

  return (
    <Container className={`ui-input ${active ? '-active' : ''} ${className}`}>
      {label && (
        <div>
          <label htmlFor={id}>{label}</label>
        </div>
      )}
      {tooltip && (
        <i
          className="tooltip fas fa-question-circle"
          data-tip={tooltip}
          onClick={() => alert(tooltip)}
        />
      )}
      <textarea
        {...rest}
        rows={numberOfRows}
        id={id}
        data-testid={id}
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </Container>
  );
};

TextArea.defaultProps = {};
