import { Checkbox, SecondaryButton } from '@fattmerchantorg/truffle-components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Attachments, Container, Modal } from '../../shared';
import { StaxButton } from '../../shared/Stax/Button';
import { SupportingDocumentsModalProps } from './SupportingDocumentsModal.types';
const StyledContainer = styled(Container)`
  padding: 16px;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
`;

const DeadlineWarning = styled.div`
  display: flex;
  background: #ceecfd;
  padding: 8px 16px;
  margin-bottom: 8px;

  /* Icon wrapper */
  div {
    color: #062333;
    display: flex;
    align-items: center;
    padding-right: 16px;
  }

  /* Warning text */
  p {
    color: #062333;
    flex: 1;
    margin-bottom: 0;
    font-family: Roboto;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
  }
`;

export const SupportingDocumentsModal: FunctionComponent<
  SupportingDocumentsModalProps
> = props => {
  const {
    onClose,
    isOpen,
    merchantDisputeCombo,
    hasDeadlinePassed,
    supportingDocuments,
    isLoading,
    onUpload,
    onDelete,
    handleSubmit,
  } = props;

  const [evidenceConformationChecked, setEvidenceConformationChecked] =
    useState<boolean>(false);

  useEffect(() => {
    if (!isOpen) {
      setEvidenceConformationChecked(false);
    }
  }, [isOpen, setEvidenceConformationChecked]);

  const [currentDisputeId, setCurrentDisputeId] = useState<string>();

  const disputeId = merchantDisputeCombo?.disputeId;
  useEffect(() => {
    if (disputeId) {
      setCurrentDisputeId(disputeId);
    }
  }, [disputeId]);

  return (
    <Modal
      width="520px"
      data-testid="supporting-documents-modal"
      {...props}
      title={<strong>Upload Evidence</strong>}
    >
      <StyledContainer>
        <strong>
          Please upload any evidence that confirms the customer authorized the
          disputed transaction.
        </strong>
        <br></br>
        <p>
          Supporting documents include:
          <ul>
            <li>Signed receipts/contracts</li>
            <li>AVS (address verification system) matches</li>
            <li>Proof of Service/Delivery</li>
            <li>Relevant documentation proving authorization</li>
          </ul>
        </p>
        {hasDeadlinePassed && (
          <DeadlineWarning data-testid="submit-supporting-documents-deadline-warning">
            <div>
              <StyledFontAwesomeIcon icon={faInfoCircle as IconProp} />
            </div>
            <p>
              This dispute has passed the respond by date. No new documents can
              be uploaded at this time
            </p>
          </DeadlineWarning>
        )}
        <Attachments
          downloadable
          files={supportingDocuments}
          uploadUrl={`/file/dispute`}
          getDeleteUrl={file => `/file/dispute/${file.id}`}
          onUpload={onUpload}
          onDelete={onDelete}
          postUploadToastMessages={false}
          uploadParams={{ dispute_id: currentDisputeId || null }}
          includeDeleteModal={false}
          maxSize={2097152}
          accept={[
            'image/jpeg',
            'image/jpg',
            'image/png',
            'application/pdf',
            'image/tiff',
            'image/tif',
          ]}
          fileLimit={8}
          includeFileType={false}
          useInlineErrorHandling={true}
        />
        <label style={{ userSelect: 'none' }}>
          <Checkbox
            data-testid="submit-evidence-checkbox"
            checked={evidenceConformationChecked}
            onChange={() =>
              setEvidenceConformationChecked(!evidenceConformationChecked)
            }
            customStyles={{
              wrapper: {
                marginRight: '8px',
              },
            }}
          />
          <p style={{ display: 'inline-block' }}>
            I understand that evidence{' '}
            <strong>can only be submitted once</strong>
          </p>
        </label>
        {!hasDeadlinePassed && (
          <div
            style={{ display: 'flex', justifyContent: 'right', gap: '10px' }}
          >
            <SecondaryButton
              id="close-supporting-documents-button"
              data-testid="close-supporting-documents-button"
              className="mt-3"
              onClick={onClose}
            >
              <strong>Cancel</strong>
            </SecondaryButton>
            <StaxButton
              id="submit-supporting-documents-button"
              data-testid="submit-supporting-documents-button"
              className="mt-3"
              onClick={() => {
                handleSubmit();
                onClose();
              }}
              disabled={
                // disable the button if the evidence checkbox is not checked or there are not supporting documents
                !evidenceConformationChecked ||
                supportingDocuments.length === 0 ||
                isLoading
              }
            >
              <strong>Submit Evidence</strong>
            </StaxButton>
          </div>
        )}
      </StyledContainer>
    </Modal>
  );
};
