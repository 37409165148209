import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@fattmerchantorg/truffle-components';
import React from 'react';
import { Row } from 'reactstrap';
import styled from 'styled-components';
import { TableCellProps } from 'react-table';

type ListingTableCellSimple = string | React.ReactNode;
type ListingTableCellAdvance = {
  content: string | React.ReactNode;
  attributes?: TableCellProps;
};
type ListingTableCellItem = ListingTableCellSimple | ListingTableCellAdvance;
type ListingTableRowItem = ListingTableCellItem[];
type ListingTableProps = {
  title?: string | React.ReactNode;
  header?: ListingTableCellItem[];
  rows: ListingTableRowItem[];
};

const TitleWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  h3 {
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: 700;
  }
`;

const StyledListingTable = styled(Table)`
  thead {
    tr {
      td {
        font-weight: bold;
      }
    }
  }
`;

const StyledTableCell = styled(TableCell)`
  padding: 11px 5px 11px 8px;
`;

export const ListingTable: React.FC<ListingTableProps> = (
  props: ListingTableProps
) => {
  return (
    <React.Fragment>
      {renderTitle(props.title)}
      {renderTable(props.header, props.rows)}
    </React.Fragment>
  );
};

function renderTitle(content: ListingTableProps['title']) {
  if (content) {
    return (
      <Row>
        <TitleWrapper>{renderItem(content, 'h3')}</TitleWrapper>
      </Row>
    );
  }
  return null;
}

function renderTable(
  header: ListingTableProps['header'],
  rows: ListingTableProps['rows']
) {
  return (
    <Row>
      <StyledListingTable>
        {header && renderTableHeader(header)}
        {renderTableBody(rows)}
      </StyledListingTable>
    </Row>
  );
}

function renderTableHeader(content: ListingTableProps['header']) {
  if (content.length) {
    return (
      <TableHead>
        <TableRow>
          {content.map((c, i) => renderTableCell(c, { key: i }))}
        </TableRow>
      </TableHead>
    );
  }
  return null;
}

function renderTableBody(rows: ListingTableProps['rows']) {
  if (rows.length) {
    return (
      <TableBody>
        {rows.map((row, rowIndex) => {
          return (
            <TableRow {...{ key: rowIndex }}>
              {row.map((cell, colIndex) => {
                return renderTableCell(cell, { key: colIndex });
              })}
            </TableRow>
          );
        })}
      </TableBody>
    );
  }
  return null;
}

function renderTableCell(cell: ListingTableCellSimple): React.ReactNode;
function renderTableCell(
  cell: ListingTableCellSimple,
  attributes: TableCellProps
): React.ReactNode;
function renderTableCell(cell: ListingTableCellAdvance): React.ReactNode;
function renderTableCell(
  cell: ListingTableCellAdvance,
  attributes: TableCellProps
): React.ReactNode;
function renderTableCell(
  cell: ListingTableCellItem,
  attributes?: TableCellProps
): React.ReactNode {
  const mergedAttributes = {
    ...(isTableCellAdvance(cell) && cell.attributes ? cell.attributes : {}),
    ...attributes,
  };
  return (
    <StyledTableCell {...mergedAttributes}>
      {renderItem(isTableCellAdvance(cell) ? cell.content : cell)}
    </StyledTableCell>
  );
}

function isTableCellAdvance(obj: any): obj is ListingTableCellAdvance {
  return typeof obj === 'object' && 'content' in obj;
}

function renderItem(
  content: string | React.ReactNode,
  Tag: keyof JSX.IntrinsicElements = 'span'
) {
  if (typeof content === 'string') {
    return <Tag>{content}</Tag>;
  } else if (React.isValidElement(content)) {
    return content;
  }
  return null;
}
