import React, { FunctionComponent, useState } from 'react';
import { Modal } from '@fattmerchantorg/truffle-components';
import styled, { withTheme } from 'styled-components';
import { Button } from '../../shared';

const StyledModal = withTheme(
  styled(Modal)`
    // Remove buttons area as its not needed for this type of modal.
    grid-template-rows: 48px 1fr !important;

    // The default background is white and keeping it creates a white aura around the corners.
    background: none !important;
    border-radius: 2px !important;

    * {
      color: ${({ theme }) => theme.white} !important;
    }

    /* 
   * When the button is hovered, the span inside it has to change color 
   * (the span is the icon wrapper) and the svg ("x") has to change fill color.
  */
    button:hover {
      span {
        background: ${({ theme }) => theme.colors.core.green[50].hex};
        path {
          fill: ${({ theme }) => theme.black};
        }
      }
    }

    // Modal header.
    > div:first-child {
      border-bottom: 1px solid ${({ theme }) => theme.colors.core.gray[200].hex} !important;

      > div {
        padding: 16px;
      }

      background: ${({ theme }) => theme.colors.core.gray[800].hex};

      h1 {
        font-size: 16px;
        font-family: 'Roboto';
        font-weight: 700;
      }
    }

    // Modal content.
    > div:nth-child(2) {
      background: ${({ theme }) => theme.colors.core.gray[800].hex};
      padding: 16px;
      margin: 0;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      max-height: 300px;
      overflow-y: scroll;
    }

    // Modal footer.
    > div:nth-child(3) {
      display: none;
    }
  `
);

/**
 * Displays merchant notes modal when clicked.
 */
export const MerchantNotesModal: FunctionComponent = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Button
        variant="link"
        style={{
          display: 'inline',
          color: '#009BF2',
          textDecoration: 'underline',
        }}
        onClick={openModal}
      >
        See More
      </Button>

      <StyledModal
        title="Notes"
        isOpen={isOpen}
        onClose={closeModal}
        style={{
          content: { maxWidth: 600 },
        }}
      >
        {children}
      </StyledModal>
    </>
  );
};
