import { useLocation, useRouteMatch } from 'react-router-dom';
import { useAuthToken, useSearchState, useToaster } from '../../hooks';
import { queryapi, accountingapi } from '../../api';
import { Deposit, CollectionResponse } from '@fattmerchantorg/types-omni';
import { DepositDetailModal } from './components/DepositDetailModal';
import { history } from '../../history';
import { DepositsProps } from './Deposits.types';
import { parse, stringify } from '../../util/api.util';
import styled, { withTheme } from 'styled-components';
import { getColumns } from './Deposits.util';
import { ExportButton } from '../exports/ExportButton';
import { formatDateForDateInput } from '../../util/date.util';
import {
  SmallPrimaryButton,
  Tooltip,
} from '@fattmerchantorg/truffle-components';

import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
  useContext,
} from 'react';

import {
  Container,
  Row,
  Col,
  InputGroupText,
  InputGroupAddon,
} from 'reactstrap';

import {
  Collection,
  CollectionQuery,
  CollectionWrapper,
  DateInput,
  PageHeader,
  cleanCollectionQuery,
} from '../shared';

import {
  AuthStore,
  deriveAuthMode,
  SelectedMerchantStore,
} from '../../context';
import { format, subDays } from 'date-fns';

const StyledCol = styled(Col)`
  padding-left: 0px;
  padding-right: 10px;
`;

const ButtonHeader = styled.div`
  float: right;
`;

const StyledInputGroupText = withTheme(
  styled(InputGroupText)`
    background-color: ${({ theme }) => theme.colors.core.green[400].hex};
    i {
      color: ${({ theme }) => theme.black};
    }
  `
);

const PurpleButton = styled(SmallPrimaryButton)`
  background-color: ${({ theme }) => theme.colors.core.purple[500].hex};
  border: 1px solid ${({ theme }) => theme.colors.core.purple[600].hex};
  box-sizing: border-box;
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.core.white[0].hex};
  line-height: 20px;
  height: 37px;
  margin-top: 0.5rem;

  :hover {
    background-color: ${({ theme }) => theme.colors.core.purple[600].hex};
    color: ${({ theme }) => theme.colors.core.white[0].hex};
  }
`;

export const Deposits: FunctionComponent<DepositsProps> = props => {
  const [simulatingSettlement, setSimulatingSettlement] = useState(false);
  const authToken = useAuthToken();
  const { search, pathname } = useLocation();
  const { params } = useRouteMatch<{ merchantId?: string }>();
  const merchantId = props.merchantId || params.merchantId;
  const { toaster, toast } = useToaster();
  const { state: authState } = useContext(AuthStore);
  const mode = deriveAuthMode(authState);
  const { state: selectedMerchantState } = useContext(SelectedMerchantStore);
  const [hasFees, setHasFees] = useState(false);
  const [exportDisabled, setExportDisabled] = useState(true);

  const [detailId, setDetailId] = useSearchState('detailId');
  const [deposits, setDeposits] = useState<Deposit[]>(null);
  const selectedDeposit = useMemo(
    () => deposits?.find(d => d.batch_id === detailId),
    [detailId, deposits]
  );

  const handleSimulateSettlement = async () => {
    try {
      setSimulatingSettlement(true);
      const res = await accountingapi.post(
        authToken,
        '/fm/simulatesettlement',
        { merchantId }
      );

      toaster(toast.success(res.message));

      // Trigger data reload.
      const reloadAt = Date.now();
      handleQueryChange({ reloadAt });
    } catch (e) {
      toaster(toast.error(`Couldn't simulate settlements`));
    } finally {
      setSimulatingSettlement(false);
    }
  };

  const query = useMemo((): CollectionQuery => {
    const parsedSearch = parse(search);
    let startDate = parsedSearch.startDate;
    let endDate = parsedSearch.endDate;

    if (!startDate && !endDate) {
      const thirtyDaysAgo = subDays(new Date(), 30);
      startDate = format(thirtyDaysAgo, 'yyyy-MM-dd');
      endDate = format(new Date(), 'yyyy-MM-dd');
    }

    return {
      ...parsedSearch,
      startDate,
      endDate,
      merchantId,
    };
  }, [merchantId, search]);

  const handleQueryChange = useCallback(
    (newQuery: Partial<CollectionQuery> = {}) => {
      const { merchantId, ...queryWithoutMerchantId } = query;
      const newSearch = stringify(
        cleanCollectionQuery({ ...queryWithoutMerchantId, ...newQuery })
      );
      history.push(`${pathname}?${newSearch}`);
    },
    [query, pathname]
  );

  const getData = useCallback(
    async query => {
      const result = await queryapi.get<{ data: Deposit[] }>(
        authToken,
        `/deposit`,
        query
      );

      // Enable export if there is data
      setExportDisabled(result?.data?.length === 0);

      // if we have fee data, show fee column
      setHasFees(result?.data?.some(d => typeof d.fees === 'number'));

      // save deposits to state in order to display the DepositDetailModal when a user clicks a particular deposit
      setDeposits(result.data);

      // cast as CollectionResponse since that's what Collection is expected
      // even though this technically doesn't conform to that type
      // since it's missing pagination data
      return result as CollectionResponse<Deposit>;
    },
    [authToken]
  );

  return (
    <>
      <PageHeader title="Deposits" />
      <DepositDetailModal
        deposit={selectedDeposit}
        isOpen={!!detailId}
        onClose={() => setDetailId(null)}
      />

      <Container fluid>
        <Row className={'pb-3'}>
          <StyledCol xs={12} sm={4} md={3} lg={2}>
            <DateInput
              style={{ borderRadius: '0.375rem' }}
              label="Start Date"
              value={formatDateForDateInput(query.startDate) || ''}
              onChange={e => {
                const startDate = e.currentTarget.value;
                handleQueryChange({ startDate });
              }}
            />
          </StyledCol>

          <StyledCol xs={12} sm={4} md={3} lg={2}>
            <DateInput
              style={{ borderRadius: '0.375rem' }}
              label="End Date"
              value={formatDateForDateInput(query.endDate) || ''}
              onChange={e => {
                const endDate = e.currentTarget.value;
                handleQueryChange({ endDate });
              }}
            />
          </StyledCol>

          <StyledCol xs={6} sm={1} md={1} lg={1}>
            <InputGroupAddon addonType="prepend" style={{ height: '100%' }}>
              <StyledInputGroupText>
                <i className="fas fa-search" />
              </StyledInputGroupText>
            </InputGroupAddon>
          </StyledCol>

          <StyledCol xs={6} sm={3} md={5} lg={7}>
            <ButtonHeader>
              <ExportButton disabled={exportDisabled} />
              {mode === 'TEST_MODE' && (
                <Tooltip content="Simulates a settlement using your transactions that were processed on a TEST gateway.">
                  <PurpleButton
                    onClick={handleSimulateSettlement}
                    disabled={simulatingSettlement}
                    loading={simulatingSettlement}
                    data-testid="simulate-settlement-button"
                    icon={['fas', 'dollar-sign']}
                  >
                    Simulate Settlement
                  </PurpleButton>
                </Tooltip>
              )}
            </ButtonHeader>
          </StyledCol>
        </Row>

        <Row>
          <CollectionWrapper>
            <Collection<Deposit>
              data-testid={`deposit-collection-${merchantId}`}
              query={query}
              blacklist={['detailId', 'at', 'count']}
              getData={getData}
              onRowClick={(deposit: Deposit) => setDetailId(deposit.batch_id)}
              paginated={false}
              columns={getColumns(selectedMerchantState.merchant, hasFees)}
              onReceiveData={() => {
                const { merchantId, ...queryWithoutMerchantId } = query;
                const newSearch = stringify(queryWithoutMerchantId);
                history.push(`${pathname}?${newSearch}`);
              }}
            />
          </CollectionWrapper>
        </Row>
      </Container>
    </>
  );
};
