import styled from 'styled-components';

export const Widget = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.176) 0px 4px 8px;
  background: white;
`;
