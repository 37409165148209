import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { BoldText } from '../shared/BoldText';
import { queryapi } from '../../../../api';
import { OnboardingStages } from '../../../../@types';
import { AuthStore, SelectedMerchantStore } from '../../../../context';
import { mapOnboardingStages } from '../../../../util/dashboard.util';
import { OnboardingStageTile } from './OnboardingStageTile';
import { LoadingAnimation } from '../shared/LoadingAnimation';
import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import styled, { createGlobalStyle, withTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { daysAgo, formatDate, startOfDay } from '../../../../util/date.util';

type OnboardingStagesCountResponse = {
  data: OnboardingStages;
};

const InfoIcon = withTheme(
  styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.core.gray[200].hex};
    margin-left: 8px;
  `
);

const InfoTooltip = styled(UncontrolledTooltip)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;

  a {
    color: inherit;
    text-decoration: none;
  }

  .tooltip-inner {
    background-color: #404750;
    color: #ffffff;
  }
`;

// This is to change the default color of a tooltip
const GlobalStyle = createGlobalStyle`
  .bs-tooltip-bottom {
    .arrow {
      &::before {
        border-bottom-color: #404750 !important;
      }
    }
  }

  .bs-tooltip-top {
    .arrow {
      &::before {
        border-top-color: #404750 !important;
      }
    }
  }

  .bs-tooltip-left {
    .arrow {
      &::before {
        border-left-color: #404750 !important;
      }
    }
  }

  .bs-tooltip-right {
    .arrow {
      &::before {
        border-right-color: #404750 !important;
      }
    }
  }
`;

const RowWithCrossEffect = withTheme(
  styled(Row)`
    > div:not(:last-child) {
      border-right: 1px solid ${({ theme }) => theme.white};
    }

    /* Helps create the cross layout. */
    @media (max-width: 991px) {
      > div:nth-child(2),
      > div:nth-child(3) {
        border-right: 0;
      }
    }
  `
);

const ThinDividingLine = withTheme(
  styled.hr`
    border-top: 1px solid ${({ theme }) => theme.white};
    padding: 0;
    margin: 16px 0;
  `
);

const sixMonthsAgo = formatDate(startOfDay(daysAgo(180)));
const now = formatDate(new Date());

/** Simple implementation of this widget. */
export const RegistrationFlow: FunctionComponent = () => {
  const [onboardingStages, setOnboardingStages] = useState([]);
  const { state: authState } = useContext(AuthStore);
  const { authToken } = authState;

  // Get "global brand" in context.
  const {
    state: { selectedBrandSwitcherOption },
  } = useContext(SelectedMerchantStore);

  useEffect(() => {
    let isSubscribed = true;

    // Run once "global brand" is in context.
    if (selectedBrandSwitcherOption) {
      queryapi
        .get(authToken, `/merchants/onboardingStagesCount/`, {
          brands: selectedBrandSwitcherOption,
          start_date: sixMonthsAgo,
          end_date: now,
        })
        .then((response: OnboardingStagesCountResponse) => {
          if (isSubscribed) {
            setOnboardingStages(mapOnboardingStages(response.data));
          }
        })
        .catch(error => console.error(error));
    }

    return () => (isSubscribed = false);
  }, [authToken, selectedBrandSwitcherOption]);

  return (
    <>
      <Row>
        <Col>
          <BoldText inline={true} fontSize={18}>
            Registration Flow
          </BoldText>
          <InfoIcon
            id="registration-flow-info-icon"
            icon={faInfoCircle as IconProp}
          />
          <GlobalStyle />
          <InfoTooltip
            delay={0}
            target="registration-flow-info-icon"
            placement="right"
          >
            Data may be delayed by one hour.
          </InfoTooltip>
        </Col>
      </Row>
      <RowWithCrossEffect>
        {onboardingStages.length
          ? onboardingStages.map((stage, index) => (
              <React.Fragment key={index}>
                <Col xs={6} lg={3} className="py-2">
                  <OnboardingStageTile
                    label={stage.step}
                    count={stage.count}
                    startDate={sixMonthsAgo}
                    endDate={now}
                  />
                </Col>
                {/* Output the dividing line here so we can have all elements
                 * to create the cross layout. Brief explanation how it works:
                 *
                 * Desktop:
                 * <Col/><Col/><DividingLine hidden/><Col/><Col/>
                 *
                 * Mobile:
                 * <Col/><Col/>
                 * <DividingLine shown/>
                 * <Col/><Col/>
                 */}
                {index === 1 && (
                  <Col xs={12} className="d-block d-lg-none">
                    <ThinDividingLine />
                  </Col>
                )}
              </React.Fragment>
            ))
          : null}

        {!onboardingStages.length ? (
          <Col>
            <LoadingAnimation />
          </Col>
        ) : null}
      </RowWithCrossEffect>
    </>
  );
};
