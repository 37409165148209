import React, { FunctionComponent, useState } from 'react';
import {
  GetCustomMessagesResponse,
  GetCustomMessagesResponseDatum,
} from '@fattmerchantorg/types-omni';
import styled from 'styled-components';
import { useAsyncEffect, useAuthToken } from '../../../../hooks';
import { queryapi } from '../../../../api';
import { addDays } from 'date-fns';
import { format as formatDate } from '../../../../util/date.util';
import { Text } from '@fattmerchantorg/truffle-components';
import {
  LoadingState,
  useStatementLoadingState,
} from '../../../../context/statements';

const Container = styled.div`
  border-top: 1px solid ${props => props.theme.lightNeutralOutline};
  padding: 24px 16px 16px 16px;

  > div {
    &:not(:first-child) {
      margin-top: 16px;
    }

    > p {
      /* TODO: Remove the !important when or if we remove the global p tag style in _index.scss */
      margin: 0 !important;
      font-size: 12px;
      color: ${props => props.theme.black};
    }
  }
`;

type MessagesSectionProps = {
  startDate: string;
};

export const MessagesSection: FunctionComponent<
  MessagesSectionProps
> = props => {
  const { startDate } = props;
  const authToken = useAuthToken();
  const { messagesLoading, setMessagesLoading } = useStatementLoadingState();

  const [data, setData] = useState<GetCustomMessagesResponseDatum[]>([]);

  useAsyncEffect(async () => {
    if (messagesLoading === LoadingState.Loading && startDate && authToken) {
      try {
        const activityPeriod = formatDate(
          // 2024-01-01 -> 2024-01-02 to avoid timezone issues
          addDays(new Date(startDate), 1),
          'yyyy-MM-dd'
        );
        const response = await queryapi.get<GetCustomMessagesResponse>(
          authToken,
          `/messages/fee_statement_v3/${activityPeriod}`,
          null
        );

        setData(response.data);
        setMessagesLoading(LoadingState.Completed);
      } catch (error) {
        setMessagesLoading(LoadingState.Failed);
      }
    }
  }, [messagesLoading, startDate, authToken]);

  if (messagesLoading !== LoadingState.Completed || !data?.length) return null;

  return (
    <Container>
      {data.map((datum, i) => (
        <div key={i}>
          <Text as="p">{datum.message}</Text>
        </div>
      ))}
    </Container>
  );
};
