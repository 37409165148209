import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  Dropdown,
  DropdownContent,
  Icon,
} from '@fattmerchantorg/truffle-components';
import {
  ModalDispatch,
  sendOpenSelected,
  usePermissions,
} from '../../../../hooks';
import { Billing } from '@fattmerchantorg/types-engine/DB';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { SelectedMerchantStore } from '../../../../context';
import { useHistory } from 'react-router-dom';

const IconButton = styled.button`
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  width: 30px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.core.white[0].hex};
`;

const ContextMenu = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    &:last-child {
      border-top: 1px solid ${({ theme }) => theme.lightNeutralOutline};
    }
  }

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 2px;

    &:hover {
      background: ${({ theme }) => theme.component.table.borderColor};
    }

    a,
    button {
      display: block;
      background: none;
      border: 0;
      margin: 0;
      padding: 8px 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      text-align: left;
      cursor: pointer;
      display: flex;
      color: ${({ theme }) => theme.colors.core.white[0].hex};

      span {
        margin-right: 8px;
        width: 16px;
      }

      &:disabled {
        opacity: 0.5;
      }
    }
  }
`;

interface OptionsContextMenuProps {
  billing: Billing;
  modalDispatch?: ModalDispatch;
  setBillingCtx?: React.Dispatch<Billing>;
}

export const OptionsContextMenu: React.FC<OptionsContextMenuProps> = props => {
  const { billing, modalDispatch, setBillingCtx } = props;
  const { state } = useContext(SelectedMerchantStore);
  const { merchant } = state;

  let type = 'TRANSACTION';
  if (billing.type === 'RECURRING') {
    type = 'Recurring Fee';
  }
  if (billing.type === 'TRANSACTION') {
    type = 'Transaction Profile';
  }
  if (billing.type === 'DISPUTE') {
    type = 'Dispute Fee';
  }
  if (billing.type === 'ACHREJECT') {
    type = 'Ach Reject Fee';
  }

  const { permit } = usePermissions();
  const canWrite = permit('godview', 'engineBillingProfiles', 'write');
  const history = useHistory();

  return (
    <Dropdown
      placement="left-start"
      trigger={(setRef, isOpen, setIsOpen) => (
        <IconButton
          ref={setRef}
          onClick={e => {
            // Prevents drawer menu from opening when clicking button
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
          data-testid="billing-table-row-options-context-menu-trigger"
        >
          <Icon icon={['fas', 'ellipsis-h']} />
        </IconButton>
      )}
    >
      <DropdownContent style={{ minWidth: '122px', width: 'auto' }}>
        <ContextMenu>
          <ul>
            {canWrite && (
              <li>
                <button
                  onClick={e => {
                    history.push(
                      `/merchant/${merchant?.id}/billing-profiles/add?type=${type}&edit_billing_id=${billing.billing_id}`
                    );
                  }}
                >
                  <Icon icon={['fas', 'pencil']} /> Edit
                </button>
              </li>
            )}
            {canWrite && !billing.is_default && (
              <li>
                <button
                  onClick={e => {
                    e.preventDefault();
                    setBillingCtx(billing);
                    if (modalDispatch) {
                      modalDispatch(sendOpenSelected());
                    }
                  }}
                >
                  <Icon icon={faBan} /> Remove
                </button>
              </li>
            )}
          </ul>
        </ContextMenu>
      </DropdownContent>
    </Dropdown>
  );
};
