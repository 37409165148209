import dfnsFormat from 'date-fns/format';
import parse from 'date-fns/parse';
import parseISO from 'date-fns/parseISO';
import isValid from 'date-fns/isValid';

export function parseDate(dateString: string, utc: boolean = true): Date {
  const date = new Date(Date.parse(dateString.replace(' ', 'T')));
  const offset = date.getTimezoneOffset() / 60;
  date.setHours(date.getHours() + offset);
  return date;
}

/**
 * Transforms a Date into string format `YYYY-MM-DD hh:mm:ss`,
 * ready for inclusion as a query parameter in API request.
 */
export function formatDate(date: Date | string, format?: string): string {
  if (!date) return '';

  try {
    const d = typeof date === 'string' ? parseDate(date) : date;
    if (format) {
      return dfnsFormat(d, format);
    } else {
      return d.toISOString().replace('T', ' ').split('.')[0];
    }
  } catch (ex) {
    //if we hit an exception parsing, return an empty string
    return '';
  }
}

/**
 * *Transforms a string into a Date object if possible, else returns null.*
 *
 * This is particularly useful if the date string in question is in an unknown format.
 * The use case this function was created for was displaying electronic signature
 * timestamps. Each partner stores their own flavor of date string, so there was not
 * a specific format to target during parsing.
 *
 * This function iterates over possible date formats, returns a valid Date object if
 * one is created from parsing with a format, else returns null.
 */
export function parseDateStringInUnknownFormat(date: string): Date | null {
  if (!date) {
    return null;
  }
  const formats = [
    'yyyy-MM-dd HH:mm:ss',
    'MM-dd-yyyy pp',
    'dd-MMM-yy pp',
    'MM-dd-yyyy HH:mm:ss',
    'MM/dd/yyyy pp',
    't',
    'MM-dd-yyyy pp xxx',
    'yyyy-MM-dd',
  ];
  // try to create a date by parsing using the explicit formats defined above
  for (const format of formats) {
    const fnsFormatDateObject = parse(date, format, new Date());
    if (isValid(fnsFormatDateObject)) {
      return fnsFormatDateObject;
    }
  }

  // try to create a date by parsing as an ISO date string
  const fnsIsoDateObject = parseISO(date);
  if (isValid(fnsIsoDateObject)) {
    return fnsIsoDateObject;
  }

  // try to create a date by using Date constructor
  const dateObject = new Date(date);
  if (isValid(dateObject)) {
    return dateObject;
  }

  return null;
}

export function formatDateForDateInput(date: string): string {
  if (!date || typeof date === undefined) {
    return '';
  }
  const _date = date.slice(0, 10).split('-');
  if (_date.length !== 3) {
    return '';
  }

  return `${_date[1]}/${_date[2]}/${_date[0]}`;
}

export const makeUTC = date => {
  date = new Date(date);
  return date.toISOString();
};

/** Converts date strings to ISO format strings, handling possible undefined. */
export function toISOString(
  dateString: string | undefined
): string | undefined {
  const dateNumber: number = Date.parse(dateString);
  return isNaN(dateNumber) ? undefined : new Date(dateNumber).toISOString();
}

const monthNames: ReadonlyArray<string> = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const shortMonthNames: ReadonlyArray<string> = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
/**
 * Given a Date, returns the month name.
 * Optionally returns the short month name, but by default the full month name is returned (`"January"` vs `"Jan"`).
 */
export function getMonthName(date: Date, length?: 'long' | 'short'): string {
  return length === 'short'
    ? shortMonthNames[date.getMonth()]
    : monthNames[date.getMonth()];
}

export function daysAgo(numberOfDays: number): Date {
  const now = new Date();
  const millisecondsInDay = 8.64e7;
  const then = +now - numberOfDays * millisecondsInDay;

  return new Date(then);
}

export function daysDifference(date: Date): Number {
  const today: Date = new Date();
  const respondByDate: Date = new Date(date);
  const timeDifferenceMs: number = respondByDate.getTime() - today.getTime();
  const differenceInDays: number = Math.floor(
    timeDifferenceMs / (1000 * 60 * 60 * 24)
  );
  return differenceInDays;
}

export function startOfDay(date: Date): Date {
  const clone = new Date(date.getTime());
  clone.setHours(0, 0, 0, 0);
  return clone;
}

export function endOfDay(date: Date): Date {
  const clone = new Date(date.getTime());
  clone.setHours(23, 59, 59, 0);
  return clone;
}

export function dayStart(date) {
  return dfnsFormat(startOfDay(date), 'yyyy-MM-dd HH:mm:ss');
}

export function dayEnd(date) {
  return dfnsFormat(endOfDay(date), 'yyyy-MM-dd HH:mm:ss');
}
export function getTimezoneName(date = new Date()): string {
  try {
    return date
      .toLocaleTimeString('en-us', { timeZoneName: 'short' })
      .split(' ')
      .pop()
      .replace('D', 'S');
  } catch (e) {
    // Default to EST
    return 'EST';
  }
}
export function format(date: Date, f: string = 'yyyy-MM-dd') {
  return dfnsFormat(date, f);
}

export function subDays(date: Date, days: number) {
  date.setDate(date.getDate() - days);
  return date;
}

export function subYears(date: Date, years: number) {
  date.setFullYear(date.getFullYear() - years);
  return date;
}

export function getTimezoneOffsetName(date = new Date()) {
  const regex = new RegExp('GMT(.*)0');
  const [timezoneOffset] = date.toString().match(regex);
  return `${timezoneOffset.slice(0, 6)}:${timezoneOffset.slice(6, 8)}`;
}

export const getAllTimezones = (
  localDate = new Date(),
  isDaylightSavingsEnabled = true
) => {
  let timezones = [
    {
      offset: 'GMT-10:00',
      value: 'HST',
      name: 'Hawaiian Time',
      exampleCity: 'Honolulu',
    },
    {
      offset: 'GMT-9:00',
      value: 'AKST',
      name: 'Alaska Time',
      exampleCity: 'Anchorage',
    },
    {
      offset: 'GMT-8:00',
      value: 'PST',
      name: 'Pacific Time',
      exampleCity: 'Los Angeles',
    },
    {
      offset: 'GMT-7:00',
      value: 'MST',
      name: 'Mountain Time',
      exampleCity: 'Salt Lake City',
    },
    {
      offset: 'GMT-6:00',
      value: 'CST',
      name: 'Central Time',
      exampleCity: 'Chicago',
    },
    {
      offset: 'GMT-5:00',
      value: 'EST',
      name: 'Eastern Time',
      exampleCity: 'New York',
    },
  ];

  // if daylight savings is enabled and the current date is in daylight savings
  // return daylight savings time zones instead
  if (isDaylightSavingsEnabled && getTimezoneName(localDate).match('D')) {
    timezones = [
      {
        // keep as GMT-10:00
        // since Hawaii does not observe daylight savings
        offset: 'GMT-10:00',
        value: 'HST',
        name: 'Hawaiian Time',
        exampleCity: 'Honolulu',
      },
      {
        offset: 'GMT-8:00',
        value: 'AKST',
        name: 'Alaska Time',
        exampleCity: 'Anchorage',
      },
      {
        offset: 'GMT-7:00',
        value: 'PST',
        name: 'Pacific Time',
        exampleCity: 'Los Angeles',
      },
      {
        offset: 'GMT-6:00',
        value: 'MST',
        name: 'Mountain Time',
        exampleCity: 'Salt Lake City',
      },
      {
        offset: 'GMT-5:00',
        value: 'CST',
        name: 'Central Time',
        exampleCity: 'Chicago',
      },
      {
        offset: 'GMT-4:00',
        value: 'EST',
        name: 'Eastern Time',
        exampleCity: 'New York',
      },
    ];
  }

  return timezones;
};
export function now() {
  return format(new Date());
}

const millisecondsInMonth = 2.628e9;
export function monthsAgo(numberOfMonths) {
  const now = new Date();
  const then = +now - numberOfMonths * millisecondsInMonth;

  return format(new Date(then));
}
