import React, { useContext } from 'react';
import AllBrandsIcon from '../../../assets/img/brand/all-brands.svg';
import { PermissionsStore, SelectedMerchantStore } from '../../../context';
import { isAllBrandsSelected } from '../../../util/brandSwitcher.util';
import {
  StyledBigAllBrandsLogo,
  StyledBigInitial,
  StyledBigLogo,
} from './BrandSwitcher';

export const CurrentBrandIcon = () => {
  const {
    state: { brands: userBrands },
  } = useContext(PermissionsStore);

  const {
    state: { selectedBrandSwitcherOption },
  } = useContext(SelectedMerchantStore);

  const brand = userBrands.find(o => o.name === selectedBrandSwitcherOption);

  if (isAllBrandsSelected(selectedBrandSwitcherOption, userBrands))
    return <StyledBigAllBrandsLogo alt="" src={AllBrandsIcon} />;

  if (brand)
    return !!brand.logoSquare?.[0]?.url ? (
      <StyledBigLogo src={brand.logoSquare?.[0]?.url} alt="" />
    ) : (
      <StyledBigInitial>{brand.displayName.charAt(0)}</StyledBigInitial>
    );

  return null;
};
