import { Icon, Text } from '@fattmerchantorg/truffle-components';
import styled from 'styled-components';

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
`;

export const ModalIcon: typeof Icon = styled(Icon)`
  text-align: center;
  font-size: 70px;
`;

export const ModalText: typeof Text = styled(Text)`
  text-align: center;
  line-height: 24px;
  margin: 8px;

  &:nth-child(2) {
    margin-top: 16px;
  }
`;

export const ModalButtonWrapper = styled.div`
  margin-top: 60px;
  text-align: center;
`;
