import React, { FunctionComponent, useContext, useMemo } from 'react';
import {
  PermissionsStore,
  updatePermissionsFeaturePermissionValue,
} from '../../context';

import { Form, Field } from 'react-final-form';
import { ToggleSelector as TToggleSelector } from '@fattmerchantorg/truffle-components';
import styled from 'styled-components';
import { Button } from '../shared';

const featureOptions = [
  {
    label: 'Available',
    value: 'write',
  },
  {
    label: 'Addon',
    value: 'available-addon',
  },
  {
    label: 'none',
    value: '',
  },
];

const ToggleSelector = styled(TToggleSelector)`
  > div[aria-selected='false'] {
    color: white !important;
  }
`;

export const FeaturePermissionsTester: FunctionComponent = () => {
  const { state, dispatch } = useContext(PermissionsStore);
  const features = state.permissions?.features;
  const categories = useMemo(
    () => (features ? Object.keys(features) : []) as (keyof typeof features)[],
    [features]
  );

  return (
    <Form
      onSubmit={formValues => {
        dispatch(
          updatePermissionsFeaturePermissionValue(
            (formValues.category || '').replace(/\s/g, ''),
            (formValues.feature || '').replace(/\s/g, ''),
            formValues.value
          )
        );
      }}
    >
      {formProps => {
        return (
          <form onSubmit={formProps.handleSubmit}>
            <h2>Feature Permissions</h2>
            <div>
              <small>
                This tool can be used to test Feature Permissions. All changes
                will be lost upon switching merchants or refreshing your
                browser.
              </small>
            </div>
            <div>
              <small>
                NOTE: This list only populates with feature permissions
                available to you. If the desired Category or Feature does not
                appear in the list, you can just type it in!
              </small>
            </div>
            <table className="table table-sm table-dark">
              <thead className="thead-light">
                <tr>
                  <th>Category</th>
                  <th>Feature</th>
                  <th>Permission</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Field name="category" component="input" />
                  </td>
                  <td>
                    <Field name="feature" component="input" />
                  </td>
                  <td>
                    <Field name="value">
                      {fieldProps => (
                        <ToggleSelector
                          options={featureOptions}
                          defaultSelected={fieldProps.input.value}
                          onChange={(_, option) =>
                            fieldProps.input.onChange(option.valu)
                          }
                        />
                      )}
                    </Field>
                  </td>
                  <td className="align-middle">
                    <Button size="small" type="submit">
                      Update
                    </Button>
                  </td>
                </tr>
                {categories.map(c => {
                  const featuresInCategory = features[c];
                  const featureNames = Object.keys(
                    featuresInCategory
                  ) as (keyof typeof featuresInCategory)[];
                  return featureNames.map(n => (
                    <tr key={`${c}.${n}`}>
                      <td className="align-middle">{c}</td>
                      <td className="align-middle">{n}</td>
                      <td>
                        <ToggleSelector
                          options={featureOptions}
                          defaultSelected={featuresInCategory[n]}
                          onChange={(_, option) => {
                            dispatch(
                              updatePermissionsFeaturePermissionValue(
                                c,
                                n,
                                option.value
                              )
                            );
                          }}
                        />
                      </td>
                      <td />
                    </tr>
                  ));
                })}
              </tbody>
            </table>
          </form>
        );
      }}
    </Form>
  );
};
