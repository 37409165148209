import { ResponsiveBar } from '@nivo/bar';
import React, { FunctionComponent } from 'react';
import { emptyData } from '../../data/sample';
import { ChartTypes } from '../../../../@types';
import { BottomAxis } from './BottomAxis';
import { LeftAxis } from './LeftAxis';
import { TooltipElement } from './TooltipElement';
import { ChartPreRender } from '../shared/ChartPreRender';
import { verifyTransactionDataKeys } from '../../../../util/dashboard.util';
import { BoldText } from '../shared/BoldText';
import { useTheme } from 'styled-components';
import { withAlpha } from '../../../../util/color.util';

// "Add single x-axis to empty chart"
//
// An empty Nivo chart displays a single x-axis right thru the center of the chart area
// and this doesn't look good with the "empty chart overlay message" we display.
// The UI/UX team asked to push this x-axis line to the bottom of the chart so
// an empty chart looks better with our overlay message.
const showSingleXAxisForEmptyChart = {
  axis: {
    domain: {
      line: {
        stroke: '#dddddd',
        strokeWidth: 1
      }
    }
  }
};

export const BarChart: FunctionComponent<{
  data: any[] | null;
  type: ChartTypes;
  indexBy?: string;
  keys?: string[];
  selectedTimespan: string;
  dataStatus: string;
}> = ({
  data,
  type,
  indexBy = 'year_month',
  keys,
  selectedTimespan,
  dataStatus
}) => {
  const hasData: boolean = data ? data.some(x => x.sum > 0) : false;

  // Extra check to verify all "chart keys" are defined ("sum_ACH","sum_CP", and "sum_CP_OTHER").
  // Nivo doesn't render anything if there's even just 1 of these properties undefined in a single dataset point.
  const dataWithVerifiedKeys: Array<any> = verifyTransactionDataKeys(data);

  // Generate props for x-axis based on selected timespan.
  const axisBottomRendering: object = BottomAxis(selectedTimespan);

  // Truffle chart colors.
  const truffleTheme = {
    sum_ACH: '#52BDFF',
    sum_CP: '#FFD600',
    sum_CNP_OTHER: '#B2DF8A'
  };

  const theme = useTheme() as any;

  // Match Stax theme.
  const themeOverrides = {
    axis: { legend: { text: { fill: '#fff' } } },
    tooltip: {
      container: {
        background: theme.white,
        color: theme.black,
        borderRadius: '2px',
      },
    },
    grid: {
      line: {
        stroke: withAlpha(theme.colors.core.gray[50].hex, 0.1),
        strokeWidth: 1,
      },
    },
  };

  return (
    <div style={{ height: 440 }}>
      <BoldText>
        {
          // Chart title.
          type.toString()
        }
      </BoldText>

      {ChartPreRender(dataStatus, hasData, type) ?? (
        <ResponsiveBar
          theme={hasData ? themeOverrides : showSingleXAxisForEmptyChart} // See "Add single x-axis to empty chart".
          data={dataWithVerifiedKeys ?? emptyData}
          keys={keys}
          indexBy={indexBy}
          margin={{ top: 24, right: 0, bottom: 88, left: 40 }}
          padding={0.3}
          labelTextColor={{ from: indexBy, modifiers: [['darker', 1.6]] }}
          colors={bar => truffleTheme[bar.id]}
          animate={false}
          enableGridY={hasData ? true : false} // See "Add single x-axis to empty chart".
          axisLeft={hasData ? { renderTick: LeftAxis } : null} // See "Add single x-axis to empty chart".
          axisBottom={axisBottomRendering}
          enableLabel={false} // Hide sum overlay for each bar.
          tooltip={ctx => (
            <>
              {TooltipElement(
                truffleTheme['sum_CP'],
                'Card Present',
                Number(ctx.data.sum_CP),
                'cp'
              )}
              {TooltipElement(
                truffleTheme['sum_CNP_OTHER'],
                'Card Not Present',
                Number(ctx.data.sum_CNP_OTHER),
                'cnp'
              )}
              {TooltipElement(
                truffleTheme['sum_ACH'],
                'ACH',
                Number(ctx.data.sum_ACH),
                'ach'
              )}
            </>
          )}
        />
      )}
    </div>
  );
};
