import React, { FunctionComponent, useContext, useMemo } from 'react';
import styled from 'styled-components';
import {
  LatestStatusReasonJSON,
  OnboardingState,
} from '@fattmerchantorg/types-engine/DB';
import {
  StatusPill,
  TextLink,
  Tooltip,
} from '@fattmerchantorg/truffle-components';
import { format } from 'date-fns';
import { ModalContext, SelectedMerchantStore } from '../../../../context';
import { useAuthToken } from '../../../../hooks';
import {
  BusinessVerificationLabel,
  BusinessVerificationToOperations,
  Operation,
  PersonVerificationLabel,
  PersonVerificationToOperations,
  ReportEntity,
  ReportPillConfigurations,
  ReportStatus,
} from './ExternalVerification.types';
import {
  getStateLabel,
  mapStateToStatus,
  OnboardingAuditLogs,
} from '../../../../util/onboardingAutditLog.util';
import { ProcessorNames } from '../status/submit/SubmitApplicationForm.types';
import { OnboardingAuditLogDetailModal } from './OnboardingAuditLogDetailModal';
import { AuditLogDetailModal } from './AuditLogDetailModal';
import { groupReportsByCreatedAtDate } from './external-verification.util';
import { getStatus, ReportsByEntityType } from '../../../../util/auditLog.util';
import { RegistrationAuditLog } from '@fattmerchantorg/types-omni';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { StatusPillStatus } from '@fattmerchantorg/truffle-components/dist/StatusPill/StatusPill';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Portfolio,
  getOnboardingPortfolios,
} from '../../../../util/catan.util';

const AuditLogContainer = styled.div`
  overflow-y: auto;
  scroll-snap-type: y mandatory;

  > div:nth-child(odd) {
    background: #1c2f3b;
  }
`;

const LogSection = styled.div`
  margin: 0 0 16px;
`;

const InternalLogRow = styled.span`
  font-size: 14px !important;
  font-weight: 400;
  white-space: pre-line;
  br {
    line-height: 8px;
  }
`;

const ReportRow = styled.div`
  display: flex;
  padding: 8px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  scroll-snap-align: start;

  span {
    font-size: 12px;
  }

  a {
    font-size: 14px;
  }

  > * {
    margin: 0 16px;

    &:first-child {
      margin: 0 16px 0 0;
    }
  }
`;
const SpanProcessorMid = styled.span`
  padding-left: 5px;
  font-size: 16px;
  font-weight: bold;
`;

type latest_notes = LatestStatusReasonJSON | { [key: string]: string } | null;

const pillConfigurations: ReportPillConfigurations = {
  neutral: { status: 'neutral', icon: null, disabled: true },
  success: { status: 'success', icon: ['fas', 'check'], disabled: false },
  warning: {
    status: 'warning',
    icon: ['fas', 'exclamation-triangle'],
    disabled: false,
  },
  failure: { status: 'error', icon: ['fas', 'times'], disabled: false },
};

const ReportStatusPill: React.FC<{
  reportName: BusinessVerificationLabel | PersonVerificationLabel;
  status: ReportStatus;
}> = props => {
  const { reportName, status } = props;
  const config = pillConfigurations[status];

  return (
    <StatusPill
      label={reportName}
      icon={config.icon as IconProp}
      status={config.status as StatusPillStatus}
      disabled={config.disabled}
    />
  );
};

const RequiredDocNote: React.FC<{
  bucket: any;
  op: string;
  footer?: JSX.Element | null;
}> = props => {
  const { bucket, op, footer } = props;
  return (
    <div style={{ lineHeight: '20px' }}>
      <span style={{ fontSize: '14px' }}>
        <b>{op}</b> of the following are required
        <br></br>
      </span>
      <ul
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          flexDirection: 'column',
        }}
      >
        {bucket.map(v => (
          <li
            style={{
              paddingLeft: '8px',
              listStylePosition: 'inside',
            }}
          >
            <b>{v}</b>
          </li>
        ))}
      </ul>
      {footer ?? footer}
    </div>
  );
};

const RunVerificationLink = styled.button`
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  width: 133px;
  height: 33px;
  background: #75d697;
  border-radius: 2px;
  border: none;
  margin: 16px 0;
`;

interface OnboardingReportGroupProps {
  report: OnboardingAuditLogs;
  groupTimestamp: Date;
  onViewReport: React.MouseEventHandler<HTMLAnchorElement>;
  portfolios?: Portfolio[];
}

const OnboardingReportStatusPill: React.FC<{
  status: OnboardingState;
}> = props => {
  const { status } = props;

  return (
    <StatusPill
      label={getStateLabel(status)}
      status={mapStateToStatus(status)}
      disabled={getStateLabel(status) === 'Inactive'}
    />
  );
};

interface ReportGroupProps {
  group: ReportEntity;
  reports: RegistrationAuditLog[];
  groupTimestamp: Date;
  onViewReport: React.MouseEventHandler<HTMLAnchorElement>;
}

const ReportGroup: React.FunctionComponent<ReportGroupProps> = props => {
  const { groupTimestamp, reports, onViewReport, group } = props;

  const statusPerOperation: { [K in Operation]: ReportStatus } = {
    gIdentify: 'neutral',
    gAuthenticate: 'neutral',
    gVerify: 'neutral',
    tincheck: 'neutral',
    googleVerifyBusiness: 'neutral',
    ESI: 'neutral',
    gOFAC: 'neutral',
  };

  reports.forEach(report => {
    const operationsRan = report.operationsRan.split(',');
    operationsRan.forEach(operation => {
      statusPerOperation[operation] = getStatus(report);
    });
  });

  const pills = Object.keys(
    group === 'business'
      ? BusinessVerificationToOperations
      : PersonVerificationToOperations
  ).map((label: BusinessVerificationLabel | PersonVerificationLabel, index) => {
    const operationRan =
      group === 'business'
        ? BusinessVerificationToOperations[label]
        : PersonVerificationToOperations[label];

    const status = statusPerOperation[operationRan];
    const tooltipContent =
      status === 'neutral'
        ? 'This verification was not run during this log period'
        : operationRan;
    return (
      <Tooltip content={tooltipContent} key={index}>
        <ReportStatusPill reportName={label} status={status} />
      </Tooltip>
    );
  });

  return (
    <ReportRow>
      <TextLink onClick={onViewReport} data-testid="audit-log-detail-link">
        {format(groupTimestamp, 'MM/dd/yyyy hh:mm a')}
      </TextLink>
      {pills}
    </ReportRow>
  );
};

// A date-indexed (by minute at a minimum) group of reports
export const ReportInternalGroup: React.FunctionComponent<
  OnboardingReportGroupProps
> = props => {
  const { groupTimestamp, report, onViewReport, portfolios } = props;
  const [portfolio, setPortfolio] = useState('');

  useEffect(() => {
    const pfolio = portfolios.find(p => p.payfac_id === report.payfac_id);
    setPortfolio(pfolio.payfac_name);
  }, [report, portfolios]);

  if (typeof report.latest_status_reason?.errors === 'string') {
    report.latest_status_reason.errors = [
      { error: report.latest_status_reason?.errors, target: '' },
    ];
  }
  const errors = report.latest_status_reason as latest_notes;

  function formatDocumentName(name: string) {
    const abbreviations = [
      'US',
      'IRS',
      'CC',
      'CNPJ',
      'CPF',
      'CRA',
      'EIN',
      'ID',
      'ITIN',
      'NSW',
      'NREGA',
      'MOA',
      'KYB',
      'SIN',
      'QI',
      'SSN',
      'CP',
      'VAT',
    ];

    let splitName = name.split('_');
    const chunksToMerge = [];
    for (const chunk of splitName) {
      if (chunk === 'USIRS') {
        chunksToMerge.push('US IRS');
      } else if (chunk === 'GSTINUIN') {
        chunksToMerge.push('GST IN/UIN');
      } else if (chunk === 'ITIN') {
        chunksToMerge.push('ITIN Document');
      } else if (chunk === 'W8BENE') {
        chunksToMerge.push('W-8BEN-E');
      } else if (chunk === 'CP2100') {
        chunksToMerge.push('CP2100');
      } else if (abbreviations.indexOf(chunk) > -1) {
        chunksToMerge.push(chunk);
      } else {
        chunksToMerge.push(
          chunk.charAt(0).toUpperCase() + chunk.slice(1).toLowerCase()
        );
      }
    }

    return chunksToMerge.join(' ');
  }

  /* 
    Previously if we got a paypal error as an object, we just showed it naively
    With SPX-778 we use a new error format that comes in an object of the following format

    {
      error: {
        op: "ALL_OF" | "ONE_OF",
        groups: [
          {
            op: "ALL_OF" | "ONE_OF",
            values: [
              <values to display>
            ]
          }
        ]
      }
    }

    The nested structure with each operation type is necessary to display the four possible scenarios for required docs
  */
  function formatNestedError(errorToShow, i) {
    const op = errorToShow['op'];
    const groups = errorToShow['groups'] as any[];
    const firstGroup = groups[0];

    // Critical assumption: PayPal verification groups always return with the same op types
    // i.e. We get the KYC Path verification back, saying we need two groups of documents
    // we assume those two groups will both be ONE_OF or ALL_OF
    const assumedOp = firstGroup['op'];
    const errorStr = [];
    const errorsToKeep = [];
    /*
      Every group of docs we got was required, and every doc within those groups is required too
      So make a big bucket with all the docs and say they're all required together
    */
    if (op === 'ALL_OF' && assumedOp === 'ALL_OF') {
      const bucket = [];
      for (const group of groups) {
        for (const value of group['values']) {
          // This is a missing field error, we don't want to group it
          if (value.indexOf('$') > -1) {
            errorsToKeep.push(<p>{value}</p>);
          } else {
            bucket.push(formatDocumentName(value));
          }
        }
      }
      if (bucket.length > 0)
        errorStr.push(
          <RequiredDocNote
            bucket={bucket}
            op={'All'}
            footer={<br></br>}
          ></RequiredDocNote>
        );
    }

    // /*
    //   Every group of docs we got was required, but only one doc from each group is required
    //   Make each group it's own bucket and denote them as requiring one each
    // */
    else if (op === 'ALL_OF' && assumedOp === 'ONE_OF') {
      const buckets = [];
      for (const group of groups) {
        const bucket = [];
        for (const value of group['values']) {
          if (value.indexOf('$') > -1) {
            errorsToKeep.push(<p>{value}</p>);
          } else {
            bucket.push(formatDocumentName(value));
          }
        }
        if (bucket.length) buckets.push(bucket);
      }
      if (buckets.length > 0)
        errorStr.push(
          <div>
            {buckets.map((b, idx) => (
              <RequiredDocNote
                bucket={b}
                op={'One'}
                footer={
                  idx < buckets.length - 1 ? (
                    <p
                      style={{
                        fontSize: '14px',
                        fontWeight: 'bolder',
                      }}
                    >
                      AND
                      <br></br>
                    </p>
                  ) : (
                    <></>
                  )
                }
              ></RequiredDocNote>
            ))}
          </div>
        );
    }

    // /*
    //   Only one group of docs we got was required, but every doc from each group is required
    //   Make each group it's own bucket and denote them as requiring all of each and delimit them with an OR
    // */
    else if (op === 'ONE_OF' && assumedOp === 'ALL_OF') {
      const buckets = [];
      for (const group of groups) {
        const bucket = [];
        for (const value of group['values']) {
          if (value.indexOf('$') > -1) {
            errorsToKeep.push(<p>{value}</p>);
          } else {
            bucket.push(formatDocumentName(value));
          }
        }
        buckets.push(bucket);
      }
      if (buckets.length > 0)
        errorStr.push(
          <div>
            {buckets.map((b, idx) => (
              <RequiredDocNote
                bucket={b}
                op="All"
                footer={
                  idx < buckets.length - 1 ? (
                    <p
                      style={{
                        fontSize: '14px',
                        fontWeight: 'bolder',
                      }}
                    >
                      OR
                      <br></br>
                    </p>
                  ) : (
                    <></>
                  )
                }
              ></RequiredDocNote>
            ))}
          </div>
        );
    }

    // /*
    //   Only one group of docs we got was required, and only one doc within those groups is required too
    //   So make a big bucket with all the docs and say only one is required from the list
    // */
    else if (op === 'ONE_OF' && assumedOp === 'ONE_OF') {
      const bucket = [];
      for (const group of groups) {
        for (const value of group['values']) {
          if (value.indexOf('$') > -1) {
            errorsToKeep.push(<p>{value}</p>);
          } else {
            bucket.push(formatDocumentName(value));
          }
        }
      }
      if (bucket.length > 0)
        errorStr.push(
          <RequiredDocNote
            bucket={bucket}
            op={'One'}
            footer={<br></br>}
          ></RequiredDocNote>
        );
    }
    for (const elem of errorsToKeep) {
      errorStr.push(elem);
    }
    if (i < errors.errors.length - 1)
      errorStr.push(
        <p style={{ fontSize: '14px', fontWeight: 'bolder' }}>
          AND
          <br></br>
        </p>
      );
    return errorStr;
  }

  function getOnboardingLogs() {
    try {
      if (
        !errors ||
        typeof errors !== 'object' ||
        JSON.stringify(errors) === '{}'
      ) {
        throw new Error('Problem with error structure');
      }

      if (!('errors' in errors)) {
        // no errors object
        if (!Object.keys(errors).length || typeof errors !== 'object') {
          throw new Error('Problem with error structure');
        }
        return Object.keys(errors).map(key => `${key}: ${errors[key]}\n`);
      }

      if (typeof errors.errors === 'string') {
        return `${errors.errors}\n`;
      }

      if (typeof errors.errors !== 'object') {
        throw new Error('Problem with error structure');
      }

      if (!Array.isArray(errors.errors)) {
        return Object.keys(errors.errors).map(
          key => `${key}: ${errors.errors[key]}\n`
        );
      }
      return errors.errors.map((element, i) => {
        if (typeof element !== 'object' || !element.error) {
          return `${element}\n`;
        }

        const errorToShow = element.error;
        if (Array.isArray(errorToShow)) {
          return errorToShow.map(element => `${element}\n`);
        }

        if (typeof errorToShow === 'object') {
          if (errorToShow) {
            if (
              Object.keys(errorToShow) &&
              Object.keys(errorToShow).length > 0
            ) {
              if (errorToShow['op'] && errorToShow['groups']) {
                try {
                  return formatNestedError(errorToShow, i);
                } catch (err) {
                  // Something went wrong, so just default to showing the whole object as we used to
                  return `${errorToShow}\n`;
                }
              }
            }
          }
          return `${errorToShow}\n`;
        }
        return `${errorToShow.replace(/-/g, '')}${
          i !== errors.errors.length - 1 ? `\n` : ''
        }`;
      });
    } catch (e) {
      return 'N/A\n';
    }
  }

  return (
    <ReportRow>
      <InternalLogRow>
        <TextLink onClick={onViewReport}>
          {portfolio}
          {' - '}
          {ProcessorNames[report.processor_name]}
        </TextLink>
        <SpanProcessorMid>
          {report.processor_mid ? ` [${report.processor_mid}] ` : ''}
        </SpanProcessorMid>
        <br />
        {format(groupTimestamp, 'MM/dd/yyyy hh:mm a')}
      </InternalLogRow>
      <InternalLogRow
        style={{ textAlign: 'right' }}
        data-testid="onboarding-error-notes"
      >
        <OnboardingReportStatusPill status={report.state} />
        <br />
        {!['Failed', 'Rejected', 'Pended'].includes(
          getStateLabel(report.state)
        ) ? (
          <></>
        ) : (
          <>{getOnboardingLogs()}</>
        )}
      </InternalLogRow>
    </ReportRow>
  );
};

interface OnboardingAuditLog {
  reports: OnboardingAuditLogs[];
  onRunBusinessVerification: () => void;
  onRunPersonVerification: () => void;
  runningVerification: ReportEntity | null;
  auditLogReports: ReportsByEntityType;
  logType?: string;
}

export const OnboardingLog: FunctionComponent<OnboardingAuditLog> = props => {
  let {
    reports,
    auditLogReports,
    onRunPersonVerification,
    onRunBusinessVerification,
    logType,
  } = props;
  const authToken = useAuthToken();
  auditLogReports = auditLogReports || {
    business: [],
    person: [],
    internal: [],
    experian: [],
    coris: [],
    autoverificationResult: [],
  };

  const [portfolios, setPortfolios] = useState([]);

  useEffect(() => {
    getOnboardingPortfolios(authToken).then(response => {
      setPortfolios(response.data);
    });
  }, [authToken]);

  const { modalDispatch } = useContext(ModalContext);
  const {
    state: { registration },
  } = useContext(SelectedMerchantStore);
  const groupedBusinessReports = useMemo(
    () => groupReportsByCreatedAtDate(auditLogReports.business),
    [auditLogReports.business]
  );

  const groupedPersonReports = useMemo(
    () => groupReportsByCreatedAtDate(auditLogReports.person),
    [auditLogReports.person]
  );

  const handleViewReportGroupClick = (
    timestampKey: string,
    entityType: ReportEntity
  ) => {
    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        component: AuditLogDetailModal,
        props: {
          isOpen: true,
          verificationData:
            entityType === 'business'
              ? groupedBusinessReports[timestampKey]
              : groupedPersonReports[timestampKey],
          authToken: authToken,
          entityType,
        },
      },
    });
  };

  const handleViewReportInternalClick = (onboarding: OnboardingAuditLogs) => {
    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        component: OnboardingAuditLogDetailModal,
        props: {
          isOpen: true,
          onboarding,
          authToken,
          registration,
        },
      },
    });
  };

  return (
    <>
      {logType === 'business' && (
        <LogSection data-testid="business-verification-logs">
          <AuditLogContainer id="underwriting-business-audit-log">
            {Object.keys(groupedBusinessReports).map(timestampKey => {
              return (
                <ReportGroup
                  group="business"
                  groupTimestamp={new Date(timestampKey)}
                  reports={groupedBusinessReports[timestampKey]}
                  key={timestampKey}
                  onViewReport={() =>
                    handleViewReportGroupClick(timestampKey, 'business')
                  }
                />
              );
            })}
          </AuditLogContainer>
          <div>
            <RunVerificationLink
              onClick={() => {
                onRunBusinessVerification();
              }}
            >
              Run Verification
            </RunVerificationLink>
          </div>
        </LogSection>
      )}
      {logType === 'signer' && (
        <LogSection data-testid="signer-verification-logs">
          <AuditLogContainer id="underwriting-person-audit-log">
            {Object.keys(groupedPersonReports).map(timestampKey => {
              return (
                <ReportGroup
                  group="person"
                  groupTimestamp={new Date(timestampKey)}
                  reports={groupedPersonReports[timestampKey]}
                  key={timestampKey}
                  onViewReport={() =>
                    handleViewReportGroupClick(timestampKey, 'person')
                  }
                />
              );
            })}
          </AuditLogContainer>

          <RunVerificationLink
            data-testid="audit-log-signer-verification-link"
            onClick={() => {
              onRunPersonVerification();
            }}
          >
            Run Verification
          </RunVerificationLink>
        </LogSection>
      )}
      {logType === 'onboarding' && (
        <LogSection data-testid="onboarding-verification-logs">
          <AuditLogContainer id="underwriting-application-audit-log">
            {reports &&
              reports.map(onboarding => {
                const { created_at } = onboarding;
                return (
                  <ReportInternalGroup
                    groupTimestamp={new Date(created_at)}
                    report={onboarding}
                    key={created_at}
                    portfolios={portfolios}
                    onViewReport={() =>
                      handleViewReportInternalClick(onboarding)
                    }
                  />
                );
              })}
          </AuditLogContainer>
        </LogSection>
      )}
    </>
  );
};
