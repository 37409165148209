import React from 'react';
import styled from 'styled-components';
import { LoadingSpan } from '../../../shared';
import { ListingTable } from './ListingTable';

const TitleWrapper = styled.div`
  width: 206px;
`;

const LoadingAnimation: React.FC = () => (
  <LoadingSpan width="100%" height="24px" />
);
const Title: React.FC = () => (
  <TitleWrapper>
    <LoadingAnimation />
  </TitleWrapper>
);

export const LoadingState: React.FC = () => {
  return (
    <ListingTable
      title={<Title />}
      rows={[
        [
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: '105px' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: 'auto' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: '115px' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: '62px' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: 'auto' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: '79px' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: 'auto' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: '120px' } },
          },
          {
            content: null,
            attributes: { style: { width: '104px', textAlign: 'right' } },
          },
        ],
        [
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: '105px' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: 'auto' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: '115px' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: '62px' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: 'auto' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: '79px' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: 'auto' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: '120px' } },
          },
          {
            content: null,
            attributes: { style: { width: '104px', textAlign: 'right' } },
          },
        ],
        [
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: '105px' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: 'auto' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: '115px' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: '62px' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: 'auto' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: '79px' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: 'auto' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: '120px' } },
          },
          {
            content: null,
            attributes: { style: { width: '104px', textAlign: 'right' } },
          },
        ],
        [
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: '105px' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: 'auto' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: '115px' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: '62px' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: 'auto' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: '79px' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: 'auto' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: '120px' } },
          },
          {
            content: null,
            attributes: { style: { width: '104px', textAlign: 'right' } },
          },
        ],
        [
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: '105px' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: 'auto' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: '115px' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: '62px' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: 'auto' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: '79px' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: 'auto' } },
          },
          {
            content: <LoadingAnimation />,
            attributes: { style: { width: '120px' } },
          },
          {
            content: null,
            attributes: { style: { width: '104px', textAlign: 'right' } },
          },
        ],
      ]}
    />
  );
};
