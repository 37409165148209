import React, { FC, Dispatch, useCallback, useContext } from 'react';
import { Merchant } from '@fattmerchantorg/types-omni';
import styled from 'styled-components';
import {
  Modal,
  FieldError,
  Form,
  Group,
  Field,
  ResponsivePadding,
  TextArea as UnstyledTextArea
} from '../../shared';
import { coreapi } from '../../../api';
import { useAuthToken, useToaster } from '../../../hooks';
import {
  sendCloseSelected,
  Action as ModalAction,
  Status as ModalStatus,
  ModalState
} from '../../../hooks/useModalReducer';
import { alterSelectedMerchant, SelectedMerchantStore } from '../../../context';

import { StaxButton } from '../../shared/Stax/Button';

const TextArea = styled(UnstyledTextArea)`
  width: 100%;
  height: 200px;
`;
interface NotesFormValues {
  notes: string | null;
}

export type EditModalContext = NotesFormValues;

/** Initial state for `useModalReducer`. */
export const initialEditModalState: ModalState<EditModalContext> = {
  status: ModalStatus.CLOSED,
  context: { notes: null }
};

const closeSelected = sendCloseSelected();

export const EditMerchantNotesModal: FC<{
  notes?: string;
  status: ModalStatus;
  modalDispatch: Dispatch<ModalAction>;
}> = ({ notes, status, modalDispatch }) => {
  const authToken = useAuthToken();
  const { toaster, toast } = useToaster();
  const {
    dispatch: selectedMerchantDispatch,
    state: { merchantId }
  } = useContext(SelectedMerchantStore);

  const onClose = useCallback(() => modalDispatch(closeSelected), [
    modalDispatch
  ]);

  const onSubmit = useCallback(
    async formValues => {
      try {
        const response: Merchant = await coreapi.put(
          authToken,
          `/merchant/${merchantId}`,
          formValues
        );
        selectedMerchantDispatch(alterSelectedMerchant(response));
        toaster(toast.success('Notes successfully saved.', 'Saved'));
        modalDispatch(sendCloseSelected());
      } catch (error) {
        toaster(toast.error(error, 'There was a problem saving the notes.'));
        modalDispatch(sendCloseSelected());
      }
    },
    [
      merchantId,
      authToken,
      selectedMerchantDispatch,
      toaster,
      toast,
      modalDispatch
    ]
  );
  return (
    <Modal
      data-testid="edit-merchant-notes-modal"
      isOpen={status === ModalStatus.OPEN}
      onClose={onClose}
      title={<strong>Edit Merchant Notes</strong>}
    >
      <Form<NotesFormValues> onSubmit={onSubmit} initialValues={{ notes }}>
        {formProps => (
          <ResponsivePadding desktop="2.5%" mobile="1rem">
            <Group direction="column">
              <Field name="notes" placeholder="Notes">
                {props => <TextArea {...props.input} />}
              </Field>
              <FieldError name="notes" />
            </Group>
            <Group justify="flex-end" space="0px">
              <StaxButton
                type="submit"
                icon="fas fa-check"
                id="save-notes-submit-button"
                data-testid="save-notes-submit-button"
                loading={formProps.submitting}
                disabled={formProps.submitting}
              >
                Save
              </StaxButton>
            </Group>
          </ResponsivePadding>
        )}
      </Form>
    </Modal>
  );
};
