import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import {
  BaseModal,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
  Select,
} from '@fattmerchantorg/truffle-components';
import { ModalContext } from '../../../../context';
import { FormRow } from '../../../underwriting/components/shared/UnderwritingPartialForm';
import {
  processorNameOptions,
  transactionPaymentMethodTypeOptions,
  transactionTypeOptions,
} from './filterOptions';

const ButtonBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  > * {
    margin: 0 0 0 ${({ theme }) => theme.space[4]}px;
  }
`;

const StyledModalContent = styled.div`
  grid-area: content;
  overflow-y: visible !important;
  padding: ${({ theme }) => theme.space[4]}px;
  padding-bottom: 0px;
  color: ${({ theme }) => theme.component.modal.color};
`;

interface SettlementTransactionsFilterModalProps {
  isOpen: boolean;
  handleQueryChange;
  query;
  getData;
  authToken;
  catanQueryData;
}

type SettlementTransactionsFiltersState = {
  type: string[];
  processorName: string[];
  methodType: string[];
};

type SettlementTransactionsFiltersStateItems = {
  [key in keyof SettlementTransactionsFiltersState]?: string[];
};

export const SettlementTransactionsFilterModal: React.FunctionComponent<
  SettlementTransactionsFilterModalProps
> = props => {
  const { isOpen, query, handleQueryChange } = props;
  const { modalDispatch } = useContext(ModalContext);
  const [isModalVisible] = useState(!!isOpen);

  const defaultState: SettlementTransactionsFiltersState = { ...query };
  if (!defaultState.type) defaultState.type = ['ALL'];
  if (!defaultState.processorName) defaultState.processorName = ['ALL'];
  if (!defaultState.methodType) defaultState.methodType = ['ALL'];
  const [filters, setFilters] =
    useState<SettlementTransactionsFiltersState>(defaultState);

  const handleCloseModal = () => {
    modalDispatch({ type: 'CLOSE_MODAL' });
  };

  const updateFilters = (options: {
    [key in 'add' | 'remove' | 'set']?: SettlementTransactionsFiltersStateItems;
  }): void => {
    setFilters(existingFilters => {
      const tmp = { ...existingFilters, ...filters };
      if (options.add) {
        Object.keys(options.add).map(
          k => (tmp[k] = tmp[k].concat(options.add[k]))
        );
      }

      if (options.remove) {
        Object.keys(options.remove).map(k => {
          tmp[k] = tmp[k].filter(i => !options.remove[k].includes(i));
          return false;
        });
      }

      if (options.set) {
        Object.keys(options.set).map(k => {
          tmp[k] = [...options.set[k]];
          return false;
        });
      }
      return tmp;
    });
  };

  const applyFilters = () => {
    handleQueryChange({ ...filters });
    handleCloseModal();
  };

  async function clearFilters() {
    handleQueryChange({
      type: ['ALL'],
      processorName: ['ALL'],
      methodType: ['ALL'],
    });
    handleCloseModal();
  }

  const onMultiSelectFilterChange = (
    filter: keyof SettlementTransactionsFiltersState,
    selectedOptions: any[]
  ) => {
    if (Array.isArray(selectedOptions) && selectedOptions.length) {
      const add = addFilterItems(
        filter,
        selectedOptions.map(o => o.value)
      );
      const remove = removeFilterItems(filter, ['ALL']);

      const removed = filters[filter].filter(
        f => !selectedOptions.map(o => o.value).includes(f)
      );
      remove[filter] = remove[filter].concat(removed);

      updateFilters({
        add,
        remove,
        ...(selectedOptions.find(o => o.value === 'ALL') &&
        !filters[filter].find(f => f === 'ALL')
          ? { set: setFilterItems(filter, ['ALL']) }
          : {}),
      });
    } else {
      updateFilters({
        set: setFilterItems(filter, ['ALL']),
      });
    }
  };

  const addFilterItems = (
    filter: keyof SettlementTransactionsFiltersState,
    values: string[]
  ): SettlementTransactionsFiltersStateItems | undefined => {
    const tmp: SettlementTransactionsFiltersStateItems = {};
    tmp[filter] = [];
    values.map((v: string) =>
      !filters[filter]?.includes(v) ? tmp[filter].push(v) : tmp[filter].push()
    );
    return tmp;
  };

  const removeFilterItems = (
    filter: keyof SettlementTransactionsFiltersState,
    values: string[]
  ): SettlementTransactionsFiltersStateItems | undefined => {
    const tmp: SettlementTransactionsFiltersStateItems = {};
    tmp[filter] = [];
    values.map((v: string) =>
      filters[filter]?.includes(v) ? tmp[filter].push(v) : tmp[filter].push()
    );
    return tmp;
  };

  const setFilterItems = (
    filter: keyof SettlementTransactionsFiltersState,
    values: string[]
  ): SettlementTransactionsFiltersStateItems | undefined => {
    const tmp: SettlementTransactionsFiltersStateItems | undefined = {};
    tmp[filter] = values;
    return tmp;
  };

  return (
    <div className="modal-body" style={{ overflow: 'visible' }}>
      <BaseModal
        isOpen={isModalVisible}
        onRequestClose={handleCloseModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={false}
        style={{
          overlay: { pointerEvents: 'auto' },
          content: { maxWidth: '480px', overflow: 'visible !important' },
        }}
      >
        <ModalHeader title="Filters" onClose={handleCloseModal} />

        <StyledModalContent>
          <FormRow>
            <label>Type</label>
            <Select
              options={transactionTypeOptions}
              value={transactionTypeOptions.filter(o =>
                filters.type?.includes(o.value)
              )}
              onChange={(option: any) =>
                onMultiSelectFilterChange('type', option)
              }
              menuShouldScrollIntoView={true}
              isMulti={true}
            />
          </FormRow>
          <FormRow>
            <label>Processor</label>
            <Select
              options={processorNameOptions}
              value={processorNameOptions.filter(o =>
                filters.processorName?.includes(o.value)
              )}
              onChange={(option: any) =>
                onMultiSelectFilterChange('processorName', option)
              }
              menuShouldScrollIntoView={true}
              isMulti={true}
            />
          </FormRow>
          <FormRow>
            <label>Payment Method</label>
            <Select
              options={transactionPaymentMethodTypeOptions}
              value={transactionPaymentMethodTypeOptions.filter(o =>
                filters.methodType?.includes(o.value)
              )}
              onChange={(option: any) =>
                onMultiSelectFilterChange('methodType', option)
              }
              menuShouldScrollIntoView={true}
              isMulti={true}
            />
          </FormRow>
        </StyledModalContent>

        <ModalFooter>
          <ButtonBar>
            <SecondaryButton onClick={clearFilters}>Clear</SecondaryButton>
            <PrimaryButton
              id="settlement-transactions-filter-modal-submit-button"
              data-testid="settlement-transactions-filter-modal-submit-button"
              onClick={applyFilters}
            >
              Apply
            </PrimaryButton>
          </ButtonBar>
        </ModalFooter>
      </BaseModal>
    </div>
  );
};
