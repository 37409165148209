import {
  PaymentsFieldsValues,
  PaymentsFieldsErrors
} from './PaymentsFields.types';
import { Registration } from '@fattmerchantorg/types-omni';
import { isWithinRange } from '../../../../util/validator.util';
import { formatToPlainNumber } from '../../../../util/format.util';

export function mapFormValuesToPayload(
  formValues: PaymentsFieldsValues
): Partial<Registration> {
  // prettier-ignore
  return {
    reason_for_applying: formValues.reason_for_applying,
    refund_policy: formValues.refund_policy,
    service_you_provide: formValues.service_you_provide || '',
    chosen_processing_method: formValues.chosen_processing_method,
    pricing_plan: formValues.pricing_plan,
    proc_change: formValues.proc_change,
    // Save mcc value into both MCC columns
    mcc: formValues.mcc,
    mcc_addl_desc: formValues.mcc,

    annual_volume: formatToPlainNumber(formValues.annual_volume),
    avg_trans_size: formatToPlainNumber(formValues.avg_trans_size),
    highest_trans_amount: formatToPlainNumber(formValues.highest_trans_amount),
    card_present_percent: formatToPlainNumber(formValues.card_present_percent),
    b2b_percent: formatToPlainNumber(formValues.b2b_percent),
    international: formatToPlainNumber(formValues.international),
    card_not_present_percent: formatToPlainNumber(
      100 - +formValues.card_present_percent
    ),
    annual_gross_ach_revenue: formatToPlainNumber(
      formValues.annual_gross_ach_revenue
    ),
    avg_ach_transaction: formatToPlainNumber(formValues.avg_ach_transaction),
    largest_ach_transaction: formatToPlainNumber(
      formValues.largest_ach_transaction
    ),
  };
}

export function mapRegistrationToInitialFormValues(
  registration: Registration
): PaymentsFieldsValues {
  if (!registration) return {} as PaymentsFieldsValues;

  // prettier-ignore
  return {
    reason_for_applying: registration.reason_for_applying,
    refund_policy: registration.refund_policy,
    chosen_processing_method: registration.chosen_processing_method,
    pricing_plan: registration.pricing_plan,
    proc_change: registration.proc_change,
    mcc: registration.mcc,
    service_you_provide: registration.service_you_provide,

    annual_volume: formatToPlainNumber(registration.annual_volume),
    avg_trans_size: formatToPlainNumber(registration.avg_trans_size),
    highest_trans_amount: formatToPlainNumber(
      registration.highest_trans_amount
    ),
    card_present_percent: formatToPlainNumber(
      registration.card_present_percent
    ),
    b2b_percent: formatToPlainNumber(registration.b2b_percent),
    international: formatToPlainNumber(registration.international),
    annual_gross_ach_revenue: formatToPlainNumber(
      registration.annual_gross_ach_revenue
    ),
    avg_ach_transaction: formatToPlainNumber(registration.avg_ach_transaction),
    largest_ach_transaction: formatToPlainNumber(
      registration.largest_ach_transaction
    ),
  };
}

export function validateRequiredFormValues(
  values: PaymentsFieldsValues
): PaymentsFieldsErrors {
  const errors = {} as PaymentsFieldsErrors;

  const requiredFields = [
    'service_you_provide',
    'annual_volume',
    'avg_trans_size',
    'highest_trans_amount',
    'card_present_percent',
    'b2b_percent',
    'international',
    'refund_policy'
  ];

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = 'Required';
  });

  return errors;
}

export function validateFormValues(
  values: PaymentsFieldsValues
): PaymentsFieldsErrors {
  const errors = {} as PaymentsFieldsErrors;

  const percentageFields = [
    'card_present_percent',
    'b2b_percent',
    'international'
  ];

  percentageFields.forEach(field => {
    const value = +formatToPlainNumber(values[field]);
    if (!isWithinRange(value, 0, 100)) {
      errors[field] = 'Must be between 0 and 100';
    }
  });

  const numericFields = [
    'annual_volume',
    'avg_trans_size',
    'highest_trans_amount',
    'card_present_percent',
    'b2b_percent',
    'international'
  ];

  numericFields.forEach(field => {
    const value = formatToPlainNumber(values[field]);
    if (isNaN(+value)) errors[field] = 'Must be a number';
  });

  return errors;
}
