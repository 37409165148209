import { Reducer, useReducer, Dispatch } from 'react';
import { ActionTypes, Status } from './types';
import { Action } from './actions';

export interface ModalState<C> {
  status: Status;
  context?: C;
}
export interface ModalDispatch extends Dispatch<Action> {}

function logUnhandledFSMEvent(status: Status, action: Action) {
  console.error(`Action ${action.type} is not handled by ${status} status`);
}

/**
 * Reducer for the custom useApp Hook.
 * This reducer is written in finite-state-machine style: If the current state
 * accepts the current action, transition to a new state.
 * https://twitter.com/DavidKPiano/status/1171062893984526336
 */
function reducer<C>(state: ModalState<C>, action: Action<C>): ModalState<C> {
  switch (state.status) {
    case Status.OPEN:
      switch (action.type) {
        case ActionTypes.USER_SELECTED_CLOSE:
          return { ...state, status: Status.CLOSED };
        case ActionTypes.USER_SELECTED_OPEN:
          return state;
        default:
          logUnhandledFSMEvent(state.status, action);
          break;
      }
      break;
    case Status.CLOSED:
      switch (action.type) {
        case ActionTypes.USER_SELECTED_OPEN:
          return {
            status: Status.OPEN,
            context: action.payload,
          };
        case ActionTypes.USER_SELECTED_CLOSE:
          return state;
        default:
          logUnhandledFSMEvent(state.status, action);
          break;
      }
      break;
    default:
      logUnhandledFSMEvent(state.status, action);
      break;
  }
  return state;
}

/**
 * Custom hook to track modal state.
 * Implemented internally using reducer pattern.
 */
export function useModalReducer<C = {}>(
  /** By default, the modal begins in a closed state. */
  initialState: ModalState<C> = {
    status: Status.CLOSED,
  }
): [ModalState<C>, ModalDispatch] {
  const [state, dispatch] = useReducer<Reducer<ModalState<C>, Action<C>>>(
    reducer,
    initialState
  );

  return [state, dispatch];
}
