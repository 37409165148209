import React from 'react';
import { TextLink } from '@fattmerchantorg/truffle-components';

/**
 * Parse the name to a more human-readable format
 * first_name + last_name
 */
const parseNames = name => {
  return name.charAt(0) + name.substring(1).toLowerCase();
};

/**
 * special case keys
 */

const specialCasesObject = {
  business_id: 'Business ID',
  entity_id: 'Entity ID',
  ppp_data: 'PPP Data',
  url: 'URL',
  Presence_FAQs_Flag: 'Presence FAQs Flag',
  ToS_Flag: 'ToS Flag',
  license_id: 'License ID',
};

/**
 * Check for a valid URL
 */

const isValidURL = str => {
  const regex =
    /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
  if (!regex.test(str)) {
    return false;
  } else {
    return true;
  }
};

/**
 * Parse the key to a more human-readable format
 */
const parseKey = (
  key: string,
  corisData: { [key: string]: any } | any[] = null,
  corisKey: string = null
) => {
  if (specialCasesObject.hasOwnProperty(key)) {
    return specialCasesObject[key];
  }
  switch (corisKey) {
    case 'Reviews Summary':
      // special case
      return corisData[key]?.review_platform || 'Review';
    case 'Personnel/Officers':
      // special case
      return corisData[key]?.first_name && corisData[key]?.last_name
        ? `${parseNames(corisData[key].first_name)} ${parseNames(
            corisData[key].last_name
          )}`
        : 'Personnel/Officers';
    default:
      /**
       * The purpose of this logic is to convert a given string key
       * from a format with underscores separating words (e.g. "my_key_name")
       * to a more human-readable format with words separated by spaces and each word capitalized
       * (e.g. "My Key Name").
       */
      return key
        .toLowerCase()
        .split('_')
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
  }
};

/**
 * Parse object to a list
 */

const parseObject = (
  data: string | number | boolean | object,
  index: number,
  key: string,
  corisKey: string
) => {
  if (typeof data === 'string' || typeof data === 'number') {
    return (
      <>
        <strong>{parseKey(key)}</strong>
        <ul>
          {typeof data === 'string' && isValidURL(data) ? (
            <React.Fragment>
              <li key={index}>
                <TextLink href={data} target="_blank" rel="noopener noreferrer">
                  {data}
                </TextLink>
              </li>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <li key={index}>{data}</li>
            </React.Fragment>
          )}
        </ul>
      </>
    );
  } else if (data === true || data === false) {
    return (
      <>
        <strong>{parseKey(key)}</strong>
        <ul>
          <li key={index}>{data ? 'True' : 'False'}</li>
        </ul>
      </>
    );
  } else if (
    typeof data === 'object' &&
    !Array.isArray(data) &&
    data !== null
  ) {
    if (Object.keys(data).length === 0) {
      return null;
    }
    return (
      <>
        <strong>{parseKey(key, data, corisKey)}</strong>
        <ul>
          {Object.keys(data).map((nestedKey, nestedIndex) => {
            if (
              data[nestedKey] === null ||
              data[nestedKey] === undefined ||
              data[nestedKey] === '' ||
              // nested-nested-nested object
              (typeof data[nestedKey] === 'object' &&
                Object.keys(data[nestedKey]).length !== 0)
            ) {
              return null;
            }
            if (data[nestedKey] === true || data[nestedKey] === false) {
              return (
                <li key={nestedIndex}>
                  <b>{parseKey(nestedKey)}</b>:&nbsp;
                  {data[nestedKey] ? 'True' : 'False'}
                </li>
              );
            }
            return (
              <li key={nestedIndex}>
                {typeof data[nestedKey] === 'string' &&
                isValidURL(data[nestedKey]) ? (
                  <>
                    <b>{parseKey(nestedKey)}</b>:&nbsp;&nbsp;
                    <TextLink
                      href={data[nestedKey]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {data[nestedKey]}
                    </TextLink>
                  </>
                ) : (
                  <>
                    <b>{parseKey(nestedKey)}</b>:&nbsp;{data[nestedKey]}
                  </>
                )}
              </li>
            );
          })}
        </ul>
      </>
    );
  } else if (typeof data === 'object' && Array.isArray(data) && data !== null) {
    if (data.length === 0) {
      return null;
    }
    return (
      <>
        <strong>{parseKey(key, data, corisKey)}</strong>
        <ul>
          {Object.keys(data).map((nestedKey, nestedIndex) => {
            if (
              data[nestedKey] === null ||
              data[nestedKey] === undefined ||
              data[nestedKey] === ''
            ) {
              return null;
            }
            if (data[nestedKey] === true || data[nestedKey] === false) {
              return (
                <li key={nestedIndex}>
                  <b>{parseKey(nestedKey)}</b>:&nbsp;
                  {data[nestedKey] ? 'True' : 'False'}
                </li>
              );
            }
            return (
              <>
                {typeof data[nestedKey] === 'string' && isValidURL(data) ? (
                  <React.Fragment>
                    <li key={nestedIndex}>
                      <TextLink
                        href={data[nestedKey]}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {data[nestedKey]}
                      </TextLink>
                    </li>
                  </React.Fragment>
                ) : typeof data[nestedKey] === 'string' ? (
                  <li key={nestedIndex}>
                    <b>{parseKey(nestedKey)}</b>:&nbsp;{data[nestedKey]}
                  </li>
                ) : (
                  <></>
                )}
              </>
            );
          })}
        </ul>
      </>
    );
  }
};

/**
 * A JSX function to render the content of a Coris modal
 */
export const renderCorisReport = (
  corisData: { [key: string]: any } | any[],
  corisKey: string,
  parentIndex: number
) => {
  const parseData = () => {
    if (Object.keys(corisData).length === 0) {
      return (
        <div
          key={parentIndex}
          data-testid={`no-merchant-open-coris-${parentIndex}`}
        >
          <p>No {corisKey} Found</p>
        </div>
      );
    }
    return Object.keys(corisData).map((key, index) => {
      if (
        corisData[key] === null ||
        corisData[key] === undefined ||
        corisData[key] === ''
      ) {
        return null;
      }

      return (
        <div
          key={index}
          data-testid={`coris-tab-content-${parentIndex}-${index}`}
        >
          {parseObject(corisData[key], index, key, corisKey)}
        </div>
      );
    });
  };

  return (
    <div key={parentIndex} data-testid={`merchant-open-coris-${parentIndex}`}>
      {parseData()}
    </div>
  );
};
