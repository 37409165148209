import { FormErrors } from '../../../../@types';
import { Registration } from '@fattmerchantorg/types-omni';

export const billingAddressFields = [
  'business_address_1',
  'business_address_2',
  'business_address_city',
  'business_address_state',
  'business_address_zip',
  'business_address_country',
] as const;

export const dbaLocationAddressFields = [
  'business_location_address_1',
  'business_location_address_2',
  'business_location_address_city',
  'business_location_address_state',
  'business_location_address_zip',
  'business_location_address_country',
  'business_location_phone_number',
  'business_location_fax',
  'business_location_email',
] as const;

export type AddressFields =
  | typeof billingAddressFields[number]
  | typeof dbaLocationAddressFields[number];

export type AddressFieldsValues = Pick<Registration, AddressFields>;

export type AddressFieldsErrors = FormErrors<AddressFieldsValues>;
