import React from 'react';
import { useTheme } from 'styled-components';
import Switch, { ReactSwitchProps } from 'react-switch';
import { staxConnect } from '@fattmerchantorg/truffle-tokens';

export const FormSwitch = (props: ReactSwitchProps) => {
  const theme = useTheme() as typeof staxConnect;

  return (
    <Switch
      offColor={theme.component.textField.backgroundColor}
      onColor={theme.component.checkbox.checked.backgroundColor}
      offHandleColor="#fff"
      onHandleColor="#fff"
      height={24}
      width={52}
      borderRadius={24}
      uncheckedIcon={null}
      checkedIcon={null}
      {...props}
    />
  );
};
