import React, {
  FunctionComponent,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import { createPortal } from 'react-dom';
import { FullPageProps } from './FullPage.types';
import styled, { withTheme } from 'styled-components';
import { usePrevious } from '../../../hooks';

const Content = withTheme(
  styled.div`
    background: ${({ theme }) => theme.black};
    min-width: 100%;
    min-height: 100%;
    z-index: 1000;

    &.-entering {
      animation: fade-in 0.15s ease-in both, shift-up 0.1s ease-in both;
    }

    &.-exiting {
      animation: fade-out 0.15s ease-in both, shift-down 0.1s ease-in both;
    }
  `
);

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  overflow-y: auto;

  &.-entering {
    animation: fade-in 0.1s ease-in both;
  }

  &.-exiting {
    animation: fade-out 0.15s ease-in both;
  }
`;

export const FullPage: FunctionComponent<FullPageProps> = props => {
  const { title, isClosable, onClose, isOpen: _, ...rest } = props;

  const [isOpen, setIsOpen] = useState(props.isOpen);
  const fullPageRef = useRef<HTMLDivElement>(null);
  const wasOpen = usePrevious(props.isOpen);

  let className = '';

  if (!isOpen && props.isOpen) {
    className = '-entering';
  } else if (isOpen && !props.isOpen) {
    className = '-exiting';
  }

  const onIsOpenChanged = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        // prevent scrolling of main body when modal is open
        document.body.style.overflow = 'hidden';

        setIsOpen(true);
      } else {
        // restore scrolling behavior
        document.body.style.overflow = 'unset';

        setTimeout(() => {
          // wait for close animation to finish before actually closing
          setIsOpen(false);
        }, 150);
      }
    },
    [setIsOpen]
  );

  useEffect(() => {
    // using ! as to not compare `false` to `undefined`
    if (!props.isOpen !== !wasOpen) {
      return onIsOpenChanged(props.isOpen);
    }

    return () => {
      // restore scrolling behavior
      document.body.style.overflow = 'unset';
    };
  }, [props.isOpen, wasOpen, onIsOpenChanged]);

  return (
    isOpen &&
    createPortal(
      <Overlay aria-modal="true" tabIndex={-1} className={className}>
        <Content ref={fullPageRef} className={className} {...rest}>
          {props.children}
        </Content>
      </Overlay>,
      document.body
    )
  );
};

FullPage.defaultProps = {
  isOpen: true,
  isClosable: false,
};
