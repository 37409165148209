import React from 'react';
import { Field, Form } from '../../../shared';
import {
  AdjustmentTransactionAction,
  AdjustmentTransactionSource,
  AdjustmentTransactionType,
  CreateOrEditFormFields,
} from '../AdjustmentTransactions.types';
import {
  FormRow,
  PartialForm,
  TwoColumn,
} from '../../../underwriting/components/shared/UnderwritingPartialForm';
import {
  Icon,
  Select,
  TextField,
  Label,
} from '@fattmerchantorg/truffle-components';
import styled from 'styled-components';
import { AdjustmentTransactionCategoryList } from '../../../../util/transaction.util';
import { formatCapitalCase } from '../../../../util';
import { AdjustmentTransactionCategory } from '@fattmerchantorg/types-engine';
import { useToaster } from '../../../../hooks';
import { FundingAccount } from '@fattmerchantorg/types-omni';
import { mapFlagsType } from '../../../funding-accounts/BankAccountsPage';
import { FormRenderProps } from 'react-final-form';

type CreateOrEditFormProps = {
  transactionType: AdjustmentTransactionType;
  initialValues: CreateOrEditFormFields;
  fundingAccounts: FundingAccount[];
  mids?: string[];
  merchantName?: string;
  submitRef?: React.MutableRefObject<HTMLButtonElement>;
  onSubmit?: (values: CreateOrEditFormFields, isValid: boolean) => void;
  onInputChange?: (field: string, value: any) => void;
  onValidity?: (valid: boolean) => void;
  source: AdjustmentTransactionSource;
  action: AdjustmentTransactionAction;
};

type CategoryOption = {
  value: AdjustmentTransactionCategory;
  label: string;
};

type GenericOption = {
  value: string | number;
  label: string;
};

const StyledFormWrapper = styled.div`
  width: 100%;
  form {
    width: 100%;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const MerchantName = styled.div`
  font-size: 14px;
  font-weight: 700;
  padding-top: 7px;
`;

export const CreateOrEditForm: React.FC<CreateOrEditFormProps> = ({
  initialValues,
  transactionType,
  submitRef,
  onSubmit,
  onInputChange,
  onValidity,
  fundingAccounts = [],
  mids,
  merchantName,
  source,
  action,
}: CreateOrEditFormProps) => {
  const { toaster, toast } = useToaster();

  const paymentMethodOptions = getPaymentMethodOptions();
  const categoryOptions = getCategoryOptions();
  const midOptions = getMidOptions();

  return (
    <StyledFormWrapper>
      <Form<CreateOrEditFormFields>
        initialValues={initialValues}
        onSubmit={onSubmitLocal}
        validate={validateForm}
        validateOnBlur={false}
        showToastOnFailedSubmit={false}
      >
        {formProps => (
          <React.Fragment>
            {submitRef && (
              <button
                ref={submitRef}
                type="submit"
                style={{ display: 'none' }}
              />
            )}
            <PartialForm>
              {(source === 'global' || action === 'EDIT') && (
                <FormRow>
                  <TwoColumn>
                    <div>
                      <Field name="merchant">
                        {props => (
                          <React.Fragment>
                            <Label text="Merchant" />
                            <MerchantName>{merchantName}</MerchantName>
                          </React.Fragment>
                        )}
                      </Field>
                    </div>
                    <div>
                      <Field name="processorMid">
                        {props => (
                          <Select<CategoryOption>
                            {...props.input}
                            label="Merchant MID"
                            required={true}
                            placeholder="Select a MID"
                            autoComplete="off"
                            options={midOptions}
                            defaultValue={midOptions.find(
                              o =>
                                o.value === formProps.initialValues.processorMid
                            )}
                            value={midOptions.find(
                              o => o.value === formProps.values.processorMid
                            )}
                            isClearable={false}
                            error={getFieldHasErrors(formProps, 'processorMid')}
                          />
                        )}
                      </Field>
                    </div>
                  </TwoColumn>
                </FormRow>
              )}
              <FormRow>
                <TwoColumn>
                  <div>
                    <Field
                      name="amount"
                      format={v => v?.toString()?.replace(/[^0-9.]/g, '') ?? ''}
                      parse={v => v?.toString()?.replace(/[^0-9.]/g, '') ?? ''}
                    >
                      {props => (
                        <TextField
                          {...props.input}
                          label={`${formatCapitalCase(transactionType)} Amount`}
                          required={true}
                          autoComplete="off"
                          inputPrefix={<Icon icon={['fas', 'dollar-sign']} />}
                          error={getFieldHasErrors(formProps, 'amount')}
                          onInput={onInputChangeLocal}
                        />
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field name="category">
                      {props => (
                        <Select<CategoryOption>
                          {...props.input}
                          label="Category"
                          required={true}
                          placeholder="Select a Category"
                          autoComplete="off"
                          options={categoryOptions}
                          defaultValue={categoryOptions.find(
                            o => o.value === formProps.initialValues.category
                          )}
                          value={categoryOptions.find(
                            o => o.value === formProps.values.category
                          )}
                          isClearable={false}
                          error={getFieldHasErrors(formProps, 'category')}
                        />
                      )}
                    </Field>
                  </div>
                </TwoColumn>
              </FormRow>
              <FormRow>
                <Field name="paymentMethodId">
                  {props => (
                    <Select<CategoryOption>
                      {...props.input}
                      label={`${formatCapitalCase(
                        transactionType
                      )} Bank Account`}
                      required={true}
                      placeholder="Select a Bank Account"
                      autoComplete="off"
                      options={paymentMethodOptions}
                      defaultValue={paymentMethodOptions.find(
                        o => o.value === formProps.initialValues.paymentMethodId
                      )}
                      value={paymentMethodOptions.find(
                        o => o.value === formProps.values.paymentMethodId
                      )}
                      isClearable={false}
                      error={getFieldHasErrors(formProps, 'paymentMethodId')}
                    />
                  )}
                </Field>
              </FormRow>
              <FormRow>
                <Field name="description">
                  {props => (
                    <TextField
                      {...props.input}
                      type="text"
                      label="Description"
                      required={true}
                      autoComplete="off"
                      error={getFieldHasErrors(formProps, 'description')}
                    />
                  )}
                </Field>
              </FormRow>
            </PartialForm>
          </React.Fragment>
        )}
      </Form>
    </StyledFormWrapper>
  );

  function onSubmitLocal(values: CreateOrEditFormFields) {
    const validation = validateForm(values);
    const isValid = Object.keys(validation).length === 0;
    if (!isValid) {
      toaster(
        toast.error(
          <div>
            {Object.values(validation).map((error, i) => (
              <div key={i}>{error}</div>
            ))}
          </div>,
          'Errors'
        )
      );
    }
    if (typeof onSubmit === 'function') {
      onSubmit(values, isValid);
    }
  }

  function validateForm(values: CreateOrEditFormFields) {
    const errors: { [key in keyof CreateOrEditFormFields]?: string } = {};
    if (!(Number(values.amount) > 0)) {
      errors.amount = 'Enter an amount greater than 0';
    }
    if (!values.paymentMethodId) {
      errors.paymentMethodId = `Select a ${formatCapitalCase(
        transactionType
      )} Bank Account`;
    }
    if (!values.category) {
      errors.category = 'Select a category';
    }
    if ((source === 'global' || action === 'EDIT') && !values.processorMid) {
      errors.category = 'Select a MID';
    }
    if (!values.description) {
      errors.description = `Enter a description for the ${formatCapitalCase(
        transactionType
      )} Adjustment`;
    }

    // check if field values are the same as initial values or not. This will help to detect if validateForm is being called during the rending of the form
    const initialValuesHaveChanged =
      Object.keys(initialValues)
        .map(k => (values[k] === initialValues[k] ? 'same' : 'diff'))
        .filter(i => i === 'diff').length > 0;

    if (typeof onValidity === 'function' && initialValuesHaveChanged) {
      onValidity(Object.keys(errors).length === 0);
    }

    return errors;
  }

  function getFieldHasErrors(
    form: FormRenderProps,
    field: keyof CreateOrEditFormFields
  ): boolean {
    return (
      form.dirty &&
      !!form.dirtyFields[field] &&
      getFieldErrors(form, field).length > 0
    );
  }

  function getFieldErrors(
    form: FormRenderProps,
    field: keyof CreateOrEditFormFields
  ): string[] {
    const error = form.errors[field];
    return error ? [error] : [];
  }

  function onInputChangeLocal(evt: React.ChangeEvent<HTMLInputElement>) {
    if (typeof onInputChange === 'function') {
      const value = evt.target.value;
      const field = evt.target.getAttribute('name');
      onInputChange(field, value);
    }
  }

  function getCategoryOptions(): CategoryOption[] {
    const categories = AdjustmentTransactionCategoryList;
    const options = categories
      ?.filter(c => {
        if (
          ['ACH', 'DISPUTE', 'FEE'].includes(c) &&
          transactionType !== 'CREDIT'
        ) {
          return undefined;
        }
        return c;
      })
      ?.map(c => ({
        label: c !== 'ACH' ? formatCapitalCase(c.toLowerCase()) : c,
        value: c,
      }));
    return options?.length ? options : [];
  }

  function getPaymentMethodOptions(): GenericOption[] {
    const options = fundingAccounts
      ?.filter(
        pm => transactionType === 'CREDIT' || !pm.flags?.includes('IS_TRUST')
      )
      ?.map(pm => {
        return {
          label: `${pm.bank_name} **${pm.last_four} (${mapFlagsType(
            pm.flags
          )})`,
          value: pm.engine_api_payment_method_id,
        };
      });
    return options?.length ? options : [];
  }

  function getMidOptions(): GenericOption[] {
    const options = mids?.map(m => ({ label: m, value: m }));
    return options?.length ? options : [];
  }
};
