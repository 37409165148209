import React, { FunctionComponent } from 'react';
import { InputGroupAddon, InputGroupText, Input, InputGroup } from 'reactstrap';
import styled from 'styled-components';

import { OmniSearchProps } from './OmniSearch.types';

const StyledInputGroup = styled(InputGroup)`
  font-size: 14px;
  background-color: white !important;

  > input.form-control {
    color: black;
  }

  > .input-group-prepend > .input-group-text > i {
    color: gray;
  }
`;

export const OmniSearch: FunctionComponent<OmniSearchProps> = props => {
  const { inputProps, ...inputGroupProps } = props;

  return (
    <StyledInputGroup {...inputGroupProps}>
      <Input type="text" {...inputProps} />
      <InputGroupAddon addonType="prepend">
        <InputGroupText>
          <i className="fas fa-search" />
        </InputGroupText>
      </InputGroupAddon>
    </StyledInputGroup>
  );
};
