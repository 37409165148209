import { ImportAction, ImportActionType } from './Import.types';
import { Import, ImportMappingRecord } from '../../@types';

export function updateImport(record: Import | null): ImportAction {
  return {
    type: ImportActionType.UPDATE_IMPORT,
    payload: record
  };
}

export function updateMappingColumn(
  name: string,
  mapping: Partial<ImportMappingRecord>
): ImportAction {
  return {
    type: ImportActionType.UPDATE_MAPPING_COLUMN,
    payload: { name, mapping }
  };
}

export function updateTargetColumns(columns: string[] | null): ImportAction {
  return {
    type: ImportActionType.UPDATE_TARGET_COLUMNS,
    payload: columns
  };
}

export function autoSpecifyUnspecifiedMappingColumns() {
  return {
    type: ImportActionType.AUTO_SPECIFY_UNSPECIFIED_MAPPING_COLUMNS
  };
}
