import React, { FunctionComponent, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import styled, { withTheme } from 'styled-components';
import { ButtonProps } from './Button.types';
import { withAlpha } from '../../../util/color.util';

export const StyledButton = styled.button`
  transition: all 0.3s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
  display: ${(props: ButtonProps) => (props.inline ? 'inline-flex' : 'flex')};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  outline: 1px solid transparent;
  outline-offset: -2px;
  flex: 0 1 auto;
  width: auto;

  border-radius: ${(props: ButtonProps) => {
    switch (props.border) {
      case 'responsive': // falls through
      case 'rounded':
        return '2px';
      case 'squared':
      default:
        return '0px';
    }
  }};

  .button-icon:not(:only-child) {
    margin-right: 0.5rem;
  }

  &:hover,
  &:focus {
    span {
      text-decoration: ${(props: ButtonProps) =>
        props.variant === 'link' ? 'underline' : 'none'};
    }

    background: ${(props: ButtonProps) => {
      const theme = props.theme as any;
      switch (props.variant) {
        case 'solid':
          return withAlpha(theme.primaryColor, 0.6);
        case 'outline': // falls through
        case 'link':
        case 'plain':
          return 'transparent';
      }
    }};
    color: ${(props: ButtonProps) => {
      const theme = props.theme as any;
      switch (props.variant) {
        case 'solid':
          return 'white';
        case 'outline':
          return withAlpha(theme.primaryColor, 0.6);
        case 'link':
          return props.motif === 'destructive'
            ? withAlpha(theme.destructiveColor, 0.6)
            : withAlpha(theme.linkColor, 0.6);
        case 'plain':
          return 'inherit';
      }
    }};
    border-color: ${(props: ButtonProps) => {
      const theme = props.theme as any;
      switch (props.variant) {
        case 'solid':
          return 'transparent';
        case 'outline':
          return withAlpha(theme.primaryColor, 0.6);
        case 'link': // falls through
        case 'plain':
          return 'transparent';
      }
    }};
  }

  opacity: ${props => (props.disabled ? 0.6 : 1)};

  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  height: ${(props: ButtonProps) => {
    switch (true) {
      case props.variant === 'link':
      case props.variant === 'plain':
        return 'auto';
      case props.size === 'normal':
        return '3rem';
      case props.size === 'small':
        return '2rem';
      case props.size === 'tiny':
        return '1.4rem';
    }
  }};

  font-size: ${(props: ButtonProps) => {
    switch (true) {
      case props.variant === 'link':
        return 'inherit';
      case props.size === 'tiny':
        return '10px';
      default:
        return '14px';
    }
  }};

  font-weight: ${(props: ButtonProps) =>
    props.variant === 'link' ||
    props.variant === 'plain' ||
    props.size === 'tiny'
      ? 'normal'
      : '600'};

  padding: ${(props: ButtonProps) => {
    if (props.variant === 'link' || props.variant === 'plain') return '0px';
    return props.size === 'tiny' ? '0.3rem' : '0px 1rem';
  }};

  border: ${(props: any) => {
    const theme = props.theme as any;
    return props.variant === 'outline'
      ? `1px solid ${theme.primaryColor}`
      : 'none';
  }};

  color: ${(props: ButtonProps) => {
    const theme = props.theme as any;
    switch (props.variant) {
      case 'solid':
        return 'white';
      case 'outline':
        return theme.primaryColor;
      case 'link':
        return props.motif === 'destructive'
          ? theme.destructiveColor
          : theme.linkColor;
      case 'plain':
        return 'inherit';
    }
  }};

  background: ${(props: ButtonProps) => {
    const theme = props.theme as any;
    switch (props.variant) {
      case 'solid':
        return theme.primaryColor;
      case 'outline': // falls through
      case 'link':
      case 'plain':
        return 'transparent';
    }
  }};

  @media (max-width: 1000px) {
    border-radius: 0px;
  }
`;

export const Button: FunctionComponent<ButtonProps> = withTheme(props => {
  const { theme, motif, icon, loading, children, ...rest } = props;

  const colorsWithMotif = useCallback(() => {
    const colors = { ...theme };

    switch (motif) {
      case 'tertiary':
        colors.primaryColor = colors.tertiaryColor;
        break;
      case 'accent':
        colors.primaryColor = colors.primaryAccent;
        break;
      case 'success':
        // TODO: replace with airtable color once it's made
        colors.primaryColor = '#079940';
        break;
      case 'destructive':
        colors.primaryColor = colors.destructiveColor;
        break;
      case 'primary':
        break;
      default:
        colors.primaryColor = motif;
        break;
    }

    return colors;
  }, [motif, theme]);

  const colors = colorsWithMotif();

  // override primary color for button motifs
  switch (props.motif) {
    case 'tertiary':
      colors.primaryColor = colors.tertiaryColor;
      break;
    case 'accent':
      colors.primaryColor = colors.primaryAccent;
      break;
    case 'success':
      // TODO: replace with airtable color once it's made
      colors.primaryColor = '#079940';
      break;
    case 'destructive':
      colors.primaryColor = colors.destructiveColor;
      break;
    case 'test':
      colors.primaryColor = '#c11e9b';
      break;
    case 'primary':
      break;
    default:
      colors.primaryColor = props.motif;
      break;
  }

  const renderIcon = useCallback(() => {
    if (loading) {
      return (
        <FontAwesomeIcon className="button-icon" icon={faSpinnerThird} spin />
      );
    } else if (typeof icon === 'string') {
      return <i className={`button-icon ${icon}`} />;
    } else {
      return icon;
    }
  }, [loading, icon]);

  return (
    <StyledButton {...rest} motif={motif} theme={{ ...theme, colors }}>
      {renderIcon()}
      {children}
    </StyledButton>
  );
});

Button.defaultProps = {
  type: 'button',
  motif: 'accent',
  size: 'normal',
  variant: 'solid',
  border: 'responsive',
  inline: false,
};
