import React, { FunctionComponent, useState, useEffect } from 'react';
import { FormRenderProps, useForm, FieldRenderProps } from 'react-final-form';
import styled from 'styled-components';
import {
  TextField,
  Icon,
  Select,
  Checkbox,
} from '@fattmerchantorg/truffle-components';
import { Registration } from '@fattmerchantorg/types-omni';
import { Field } from '../../../shared/Field';
import {
  FormRow,
  PartialForm,
  TwoColumn,
} from '../shared/UnderwritingPartialForm';
import { StateSelect, SelectOption } from '../shared/StateSelect';
import { CountrySelect, countryOptions } from '../shared/CountrySelect';
import { AsyncIcon } from '../shared/AsyncIcon';
import { formatDate, formatPhoneNumber } from '../../../../util/format.util';
import { AdditionalRepresentativesFieldsValues } from './AdditionalRepresentativesForm.types';
import { MaskedInput } from '../../../shared/MaskedInput';
import {
  getStateLabel,
  getStateValues,
  mapStateToSelectOption,
} from '../../../../util/countryState.util';

// @TODO: Add built-in labels to Truffle Select component
const SelectLabelAlignmentFix = styled.div`
  display: flex;
`;

const RemoveRep = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.lightNeutralOutline};
  margin: 16px 0 0 0;
  padding: 16px 0;

  > button {
    background: none;
    border: 0;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.status.red[500].hex};
    font-weight: 700;
  }

  svg {
    color: ${({ theme }) => theme.colors.status.red[500].hex};
  }
`;
const CheckboxLabel = styled.label`
  cursor: pointer;

  > span {
    position: relative;
    margin-left: 20px;
    top: -2px;
  }
`;
type Option = {
  label: string;
  value: any;
};

export type RepOptions = Option[];

interface AdditionalRepresentativesFieldsProps {
  formProps?: FormRenderProps<AdditionalRepresentativesFieldsValues>;
  onSelectRepresentative?: (selectedRep: Option) => void;
  isNewRep?: boolean;
  onRemoveRep?: (
    formValues: AdditionalRepresentativesFieldsValues,
    removeRepIndex: number
  ) => void;
  isRemovingRep?: boolean;
  registration?: Registration;
}

const repTypeOptions = [
  {
    label: 'Beneficial Owner',
    value: 'Beneficial Owner',
  },
  {
    label: 'Guarantor',
    value: 'Guarantor',
  },
];

export const AdditionalRepresentativesFields: FunctionComponent<
  AdditionalRepresentativesFieldsProps
> = props => {
  const {
    formProps,
    onSelectRepresentative,
    isNewRep,
    onRemoveRep,
    isRemovingRep,
    registration,
  } = props;
  const mutators = props.formProps.form.mutators;
  const newRepIndex = isNewRep
    ? formProps.values?.representatives.length
    : null;

  const [repIndex, setRepIndex] = useState(newRepIndex || 0);
  const [repOptions, setRepOptions] = useState<RepOptions>([]);
  const form = useForm();
  const [sameFieldsFromOwner, setSameFieldsFromOwner] = useState(false);

  const addressCountry =
    formProps.values?.representatives[repIndex]?.address_country || 'USA';
  const stateLabel = getStateLabel(addressCountry);
  const stateOptions = getStateValues(addressCountry).map(
    mapStateToSelectOption
  );

  const findState = (value: string) =>
    stateOptions.find(option => option.value === value);

  const findCountry = (value: string) =>
    countryOptions.find(option => option.value === value);

  const handleUseFieldsFromOwner = state => {
    form.mutators.setSameAsOwner(repIndex, state, registration);
    setSameFieldsFromOwner(state);
  };

  useEffect(() => {
    const repsValues = formProps.values?.representatives;
    const options = repsValues.map((rep, index) => {
      return {
        label: `${rep.first_name} ${rep.last_name}`,
        value: index,
      };
    });
    setRepOptions(options);
  }, [formProps]);

  const handleRepChange = selectedRep => {
    setRepIndex(selectedRep.value);
    if (onSelectRepresentative) onSelectRepresentative(selectedRep);
  };

  return (
    <PartialForm>
      {!isNewRep ? (
        <FormRow>
          <SelectLabelAlignmentFix>
            <label>Select Representative</label>
          </SelectLabelAlignmentFix>

          <Select
            options={repOptions}
            onChange={handleRepChange}
            value={repOptions[repIndex]}
          />
        </FormRow>
      ) : null}
      <FormRow>
        <Field name="useOwnerSignerInfop">
          {props => (
            <CheckboxLabel>
              <Checkbox
                onChange={e => {
                  handleUseFieldsFromOwner(e.target.checked);
                }}
                checked={sameFieldsFromOwner}
              />
              <span>Use Owner/Signer Info</span>
            </CheckboxLabel>
          )}
        </Field>
      </FormRow>
      <FormRow>
        <TwoColumn>
          <div>
            <Field name={`representatives[${repIndex}].first_name`}>
              {props => (
                <TextField
                  {...props.input}
                  type="text"
                  label="First Name"
                  error={!!(props.meta.error && props.meta.touched)}
                  helperText={props.meta.touched ? props.meta.error : null}
                />
              )}
            </Field>
          </div>
          <div>
            <Field name={`representatives[${repIndex}].last_name`}>
              {props => (
                <TextField
                  {...props.input}
                  type="text"
                  label="Last Name"
                  error={!!(props.meta.error && props.meta.touched)}
                  helperText={props.meta.touched ? props.meta.error : null}
                />
              )}
            </Field>
          </div>
        </TwoColumn>
      </FormRow>
      <FormRow>
        <Field name={`representatives[${repIndex}].title`}>
          {props => (
            <TextField
              {...props.input}
              type="text"
              label="Title"
              error={!!(props.meta.error && props.meta.touched)}
              helperText={props.meta.touched ? props.meta.error : null}
            />
          )}
        </Field>
      </FormRow>

      <FormRow>
        <TwoColumn>
          <div>
            <Field
              name={`representatives[${repIndex}].date_of_birth`}
              format={formatDate}
            >
              {props => (
                // @TODO: Will convert to single date picker
                <TextField
                  {...props.input}
                  type="text"
                  label="Birthdate"
                  error={!!(props.meta.error && props.meta.touched)}
                  helperText={props.meta.touched ? props.meta.error : null}
                />
              )}
            </Field>
          </div>
          <div>
            <Field name={`representatives[${repIndex}].ssn`} type="text">
              {props => (
                <MaskedInput
                  {...props.input}
                  label="Social Security"
                  maskType="ssn"
                />
              )}
            </Field>
          </div>
        </TwoColumn>
      </FormRow>
      <FormRow>
        <div>
          <SelectLabelAlignmentFix>
            <label>Citizenship</label>
          </SelectLabelAlignmentFix>
          <Field
            name={`representatives[${repIndex}].citizenship`}
            format={value =>
              countryOptions.find(option => option.value === value)
            }
            clearable={false}
          >
            {props => (
              <div data-testid={`representatives[${repIndex}].citizenship`}>
                <CountrySelect
                  {...props.input}
                  error={!!(props.meta.error && props.meta.touched)}
                  helperText={props.meta.touched ? props.meta.error : null}
                />
              </div>
            )}
          </Field>
        </div>
      </FormRow>
      <FormRow>
        <TwoColumn>
          <div>
            <div>
              <SelectLabelAlignmentFix>
                <label>Type</label>
              </SelectLabelAlignmentFix>
              <Field
                name={`representatives[${repIndex}].type`}
                format={value =>
                  repTypeOptions.find(option => option.value === value)
                }
                clearable={false}
              >
                {props => (
                  <div data-testid="type">
                    <Select
                      options={repTypeOptions}
                      {...props.input}
                      error={!!(props.meta.error && props.meta.touched)}
                    />
                  </div>
                )}
              </Field>
            </div>
          </div>
          <div>
            <Field
              name={`representatives[${repIndex}].ownership_percentage`}
              parse={value => parseFloat(value)}
            >
              {props => {
                return (
                  <TextField
                    {...props.input}
                    type="number"
                    label="Ownership"
                    min="0"
                    max="100"
                    slug={
                      <Icon
                        icon={['fas', 'percent']}
                        style={{
                          color:
                            !!props.meta.error && props.meta.touched
                              ? '#ff4646'
                              : '#009bf2',
                        }}
                      />
                    }
                    error={!!(props.meta.error && props.meta.touched)}
                    helperText={props.meta.touched ? props.meta.error : null}
                  />
                );
              }}
            </Field>
          </div>
        </TwoColumn>
      </FormRow>

      <FormRow>
        <TwoColumn>
          <div>
            <Field
              name={`representatives[${repIndex}].phone`}
              format={formatPhoneNumber}
            >
              {props => (
                <TextField
                  {...props.input}
                  type="text"
                  label="Phone Number"
                  error={!!(props.meta.error && props.meta.touched)}
                  helperText={props.meta.touched ? props.meta.error : null}
                />
              )}
            </Field>
          </div>
          <div>
            <Field name={`representatives[${repIndex}].email`}>
              {props => (
                <TextField
                  {...props.input}
                  type="text"
                  label="Email"
                  error={!!(props.meta.error && props.meta.touched)}
                  helperText={props.meta.touched ? props.meta.error : null}
                />
              )}
            </Field>
          </div>
        </TwoColumn>
      </FormRow>
      <FormRow>
        <Field name={`representatives[${repIndex}].address_1`}>
          {props => (
            <TextField
              {...props.input}
              type="text"
              label="Home Address 1"
              error={!!(props.meta.error && props.meta.touched)}
              helperText={props.meta.touched ? props.meta.error : null}
            />
          )}
        </Field>
      </FormRow>
      <FormRow>
        <Field name={`representatives[${repIndex}].address_2`}>
          {props => (
            <TextField
              {...props.input}
              type="text"
              label="Home Address 2"
              placeholder="Optional"
              error={!!(props.meta.error && props.meta.touched)}
              helperText={props.meta.touched ? props.meta.error : null}
            />
          )}
        </Field>
      </FormRow>
      <FormRow>
        <TwoColumn>
          <div>
            <Field name={`representatives[${repIndex}].address_city`}>
              {props => (
                <TextField
                  {...props.input}
                  type="text"
                  label="City"
                  error={!!(props.meta.error && props.meta.touched)}
                  helperText={props.meta.touched ? props.meta.error : null}
                />
              )}
            </Field>
          </div>
          <div>
            <SelectLabelAlignmentFix>
              <label>{stateLabel}</label>
            </SelectLabelAlignmentFix>
            <Field
              name={`representatives[${repIndex}].address_state`}
              format={findState}
              clearable={false}
            >
              {(props: FieldRenderProps<SelectOption, HTMLElement>) => (
                <div data-testid={`representatives[${repIndex}].address_state`}>
                  <StateSelect
                    {...props.input}
                    value={
                      findState(props.input?.value?.value) || {
                        label: '',
                        value: '',
                      }
                    }
                    options={stateOptions}
                    error={!!(props.meta.error && props.meta.touched)}
                    helperText={props.meta.touched ? props.meta.error : null}
                  />
                </div>
              )}
            </Field>
          </div>
        </TwoColumn>
      </FormRow>
      <FormRow>
        <TwoColumn>
          <div>
            <Field name={`representatives[${repIndex}].address_zip`}>
              {props => (
                <TextField
                  {...props.input}
                  type="text"
                  label="Zip"
                  error={!!(props.meta.error && props.meta.touched)}
                  helperText={props.meta.touched ? props.meta.error : null}
                />
              )}
            </Field>
          </div>
          <div>
            <SelectLabelAlignmentFix>
              <label>Country</label>
            </SelectLabelAlignmentFix>
            <Field
              name={`representatives[${repIndex}].address_country`}
              format={findCountry}
              clearable={false}
            >
              {props => (
                <div
                  data-testid={`representatives[${repIndex}].address_country`}
                >
                  <CountrySelect
                    {...props.input}
                    onChange={evt => {
                      // if the parent passed an 'onChange' handler via props call it
                      if (props.input.onChange) {
                        props.input.onChange(evt);
                      }
                      mutators.setFieldValue(
                        `representatives[${repIndex}].address_state`,
                        ''
                      );
                    }}
                    error={!!(props.meta.error && props.meta.touched)}
                    helperText={props.meta.touched ? props.meta.error : null}
                  />
                </div>
              )}
            </Field>
          </div>
        </TwoColumn>
      </FormRow>
      {!isNewRep ? (
        <RemoveRep>
          <AsyncIcon icon={['fas', 'trash']} loading={isRemovingRep} />
          <button
            disabled={isRemovingRep}
            onClick={() =>
              onRemoveRep
                ? onRemoveRep(
                    formProps.values as AdditionalRepresentativesFieldsValues,
                    repIndex
                  )
                : null
            }
          >
            Remove Representative
          </button>
        </RemoveRep>
      ) : null}
    </PartialForm>
  );
};
