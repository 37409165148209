import { Omni } from '@fattmerchantorg/types-omni';
import axios from 'axios';
import { getHeaders } from '../util/api.util';

export const getFileById = async (
  fileId: string,
  authToken: Omni.Auth['token']
): Promise<{ data: string }> => {
  try {
    const file = await axios({
      url: `${process.env.REACT_APP_ONBOARDING_URL}/file/${fileId}`,
      method: 'get',
      headers: getHeaders(authToken),
    });
    return file;
  } catch (err) {
    return err;
  }
};
