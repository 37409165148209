export const emptyData = [
  {
    year_month: 'JAN'
  },
  {
    year_month: 'FEB'
  },
  {
    year_month: 'MAR'
  },
  {
    year_month: 'APR'
  },
  {
    year_month: 'MAY'
  },
  {
    year_month: 'JUN'
  },
  {
    year_month: 'JUL'
  },
  {
    year_month: 'AUG'
  },
  {
    year_month: 'SEP'
  },
  {
    year_month: 'OCT'
  },
  {
    year_month: 'NOV'
  },
  {
    year_month: 'DEC'
  }
];

export const yearlyData = [
  {
    year_month: 'JAN',
    CP: 22,
    CNP: 40,
    ACH: 33
  },
  {
    year_month: 'FEB',
    CP: 43,
    CNP: 30,
    ACH: 20
  },
  {
    year_month: 'MAR',
    CP: 22,
    CNP: 40,
    ACH: 33
  },
  {
    year_month: 'APR',
    CP: 43,
    CNP: 30,
    ACH: 20
  },
  {
    year_month: 'MAY',
    CP: 22,
    CNP: 40,
    ACH: 33
  },
  {
    year_month: 'JUN',
    CP: 43,
    CNP: 30,
    ACH: 20
  },
  {
    year_month: 'JUL',
    CP: 22,
    CNP: 40,
    ACH: 33
  },
  {
    year_month: 'AUG',
    CP: 22,
    CNP: 40,
    ACH: 33
  },
  {
    year_month: 'SEP',
    CP: 43,
    CNP: 100,
    ACH: 20
  },
  {
    year_month: 'OCT',
    CP: 43,
    CNP: 30,
    ACH: 20
  },
  {
    year_month: 'NOV',
    CP: 22,
    CNP: 40,
    ACH: 33
  },
  {
    year_month: 'DEC',
    CP: 22,
    CNP: 40,
    ACH: 33
  }
];

export const residualSamples = [
  {
    activity_period: "2020-05",
    residuals: 300.33,
    transaction_count: 89223,
    transaction_volume: 943058.45,
    merchants: 234
  },
  {
    activity_period: "2020-06",
    residuals: 0,
    transaction_count: 0,
    transaction_volume: 0,
    merchants: 234
  },
  {
    activity_period: "2020-07",
    residuals: 266.1,
    transaction_count: 1854,
    transaction_volume: 256383.23,
    merchants: 234
  },
  {
    activity_period: "2020-08",
    residuals: 200.5,
    transaction_count: 504,
    transaction_volume: 156303.25,
    merchants: 234
  },
  {
    activity_period: "2020-09",
    residuals: 100.5,
    transaction_count: 104,
    transaction_volume: 6303.25,
    merchants: 234
  },
  {
    activity_period: "2020-10",
    residuals: 949.1,
    transaction_count: 1504,
    transaction_volume: 496303.25,
    merchants: 234
  }
];

export const emptyResidualSamples = [
  {
    activity_period: "2020-05",
    residuals: null,
    transaction_count: 0,
    transaction_volume: 0,
    merchants: 0
  },
  {
    activity_period: "2020-06",
    residuals: null,
    transaction_count: 0,
    transaction_volume: 0,
    merchants: 0
  },
  {
    activity_period: "2020-07",
    residuals: null,
    transaction_count: 0,
    transaction_volume: 0,
    merchants: 0

  },
  {
    activity_period: "2020-08",
    residuals: null,
    transaction_count: 0,
    transaction_volume: 0,
    merchants: 0

  },
  {
    activity_period: "2020-09",
    residuals: null,
    transaction_count: 0,
    transaction_volume: 0,
    merchants: 0
  },
  {
    activity_period: "2020-10",
    residuals: null,
    transaction_count: 0,
    transaction_volume: 0,
    merchants: 0
  }
];
