// This powers the initial loading state of the table. It just generates an
// array of 20 empty objects to seed the table row structure and show the
// loading skeletons.
export const generateLoadingRows = () => {
  let count = 0;
  const output = [];
  while (count < 20) {
    output.push({});
    count++;
  }

  return output;
};
