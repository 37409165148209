import React, { Context, createContext, useReducer } from 'react';
import {
  ToastContextProps,
  ToastContext,
  ToastAction,
  ToastState,
  ToastActionType,
  ToastProps,
} from './Toast.types';
import { uuid } from '../../util/format.util';
import { BreadBag, Toast } from './components';
import { toast } from './Toast.actions';

export const Toaster = createContext(
  {} as ToastContext
) as Context<ToastContext>;

const initialState: ToastState = { toasts: [] };

type Reducer = (state: ToastState, action: ToastAction) => ToastState;

const storeReducer: Reducer = (state, action) => {
  let newState = state;

  switch (action.type) {
    case ToastActionType.ADD_TOAST:
      const toast = { ...(action.payload as ToastProps) };
      let content = toast.content;

      // handle error formats from core api
      // skip this step if content has a 'type' property
      // because it's likely a react element if it has a 'type' property
      if (
        typeof content === 'object' &&
        content !== null &&
        !(content as any).type
      ) {
        if ((content as any).message) {
          content = (content as any).message;
        } else {
          content = Object.values(content).join('\n');
        }
      }

      toast.content = content;
      toast.id = toast.id || uuid();

      return { ...state, toasts: [...state.toasts, toast] };
    case ToastActionType.REMOVE_TOAST:
      const id = action.payload as string;
      const toasts = state.toasts.filter(toast => toast.id !== id);
      return { ...state, toasts };
    default:
      break;
  }

  return newState;
};

// i am the provider of the toast
export const ToastProvider = (props: ToastContextProps) => {
  const [state, dispatch] = useReducer(storeReducer, initialState);

  const localContext: ToastContext = {
    state,
    dispatch,
    toast,
    toaster: dispatch,
  };

  return (
    <Toaster.Provider value={localContext}>
      <BreadBag>
        {state.toasts.map(props => (
          <Toast key={props.id} {...props} />
        ))}
      </BreadBag>
      {props.children}
    </Toaster.Provider>
  );
};
