import {
  Transaction,
  OmniDispute as Dispute,
} from '@fattmerchantorg/types-omni';
import currency from 'currency.js';

export function formatCapitalCase(str: string): string {
  if (!str) return str;

  const result = str.toLowerCase();
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function formatTitleCase(str: string): string {
  if (!str) return str;
  return str.split(' ').map(formatCapitalCase).join(' ');
}

export function replaceLongDashU2013(str: string): string {
  if (!str) return str;
  return str.replace('–', '-');
}

export function formatSnakeToTitleCase(str: string): string {
  if (!str) return str;
  return str.split('_').map(formatCapitalCase).join(' ');
}

export function formatCamelToTitleCase(str: string): string {
  if (!str) return str;
  const result = str
    .replace(/([A-Z])/g, ' $1') // split before capital letters
    .replace(/([0-9]+)/g, ' $1') // split before numbers
    .replace(/([0-9])([a-z])/g, '$1 $2'); // split between numbers and lowercase letters
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function formatCurrency(
  total: string | number = 0,
  formatOptions?: {
    places?: number;
    decimal?: number;
    separator?: string;
    symbol?: string;
  }
): string {
  const {
    places = 2,
    decimal = '.',
    separator = ',',
    symbol = '$',
  } = formatOptions || {};
  let amount;

  // Strip out anything but digits or decimals if it's a string.
  if (typeof total === 'string') {
    total = total.replace(/[^\d.]/g, '');
  }

  amount = (Math.abs(Number(total)) || 0).toFixed(places);

  const i = `${parseInt(amount, 10)}`;
  const j = i.length > 3 ? i.length % 3 : 0;

  const result =
    symbol +
    (j ? i.substr(0, j) + separator : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${separator}`) +
    (places
      ? decimal +
        Math.abs(+amount - +i)
          .toFixed(places)
          .slice(2)
      : '');

  return +total < 0 ? `(${result})` : result;
}

export function sumAndFormat(
  valueOne: number | string,
  valueTwo: number | string,
  formatter: (val: number | string) => string
) {
  const sum = +valueOne + +valueTwo;
  return formatter(sum);
}

/** Returns a display string, grouping every three digits with a comma. */
export function formatWithComma(value: number): string {
  return value.toLocaleString('en-US', {
    useGrouping: true,
  });
}

export function formatFederalTaxId(value: string): string {
  const number = String(value).replace(/[\D]/g, '');
  const chunks = [number.substring(0, 2), number.substring(2, 9)];

  return chunks[0]
    ? chunks
        .slice(1)
        .reduce((sum, next) => (next ? sum + `-${next}` : sum), chunks[0])
    : number;
}

function isMysqlDateFormat(time: string) {
  return /\d{4}-[01]\d-[0-3]\d \d{2}:\d{2}:\d{2}/.test(time);
}

function isIsoDateFormat(time: string) {
  return /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/.test(
    time
  );
}

/**
 *
 * @param timestamp must be strictly in mysql or iso format
 */
export function formatReadableDate(
  timestamp: string | undefined,
  excludeTime: boolean | undefined = undefined
) {
  if (!isMysqlDateFormat(timestamp) && !isIsoDateFormat(timestamp)) {
    return '';
  }
  const dateString = isMysqlDateFormat(timestamp) ? timestamp + 'Z' : timestamp;
  const parseDate = Date.parse(dateString.replace(/\s+/g, 'T'));
  if (isNaN(parseDate)) {
    return '';
  }
  const dateTime = new Date(parseDate);
  if (excludeTime) {
    return dateTime.toLocaleDateString();
  } else {
    return dateTime.toLocaleString();
  }
}

/**
 *
 * @param timestamp must be strictly in mysql or iso format
 */
export function formatReadableDateWithSeparator(timestamp: string | undefined) {
  if (!isMysqlDateFormat(timestamp) && !isIsoDateFormat(timestamp)) {
    return '';
  }
  const dateString = isMysqlDateFormat(timestamp) ? timestamp + 'Z' : timestamp;
  const parseDate = Date.parse(dateString.replace(/\s+/g, 'T'));
  if (isNaN(parseDate)) {
    return '';
  }
  const dateTime = new Date(parseDate);
  const date = dateTime.toLocaleDateString();
  const time = dateTime.toLocaleTimeString();
  return date + ' | ' + time;
}

export function s4(): string {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

export function uuid(): string {
  return `${s4()}-${s4()}-${s4()}-${s4()}-${s4()}`;
}

export function formatPhoneNumber(number: string | number): string {
  if (!number) return '';

  let formattedNumber = String(number).replace(/[\D]/g, '');

  // remove non-digits
  if (formattedNumber.length <= 10) {
    const firstThree = formattedNumber.substring(0, 3);
    const secondThree = formattedNumber.substring(3, 6);
    const thirdFour = formattedNumber.substring(6, 10);

    if (firstThree) {
      formattedNumber = `(${firstThree}`;
    }

    if (secondThree) {
      formattedNumber += `) ${secondThree}`;
    }

    if (thirdFour) {
      formattedNumber += `-${thirdFour}`;
    }

    return formattedNumber;
  } else {
    // else return international format
    return `+${formattedNumber}`;
  }
}

export function formatExpirationDate(value?: string | null): string {
  if (!value) return '';
  const month = value.substr(0, 2);
  const year = value.substr(4);
  return `${month}/${year}`;
}

export function formatToPlainNumber(n: number | string) {
  return n && String(n) ? String(n).replace(/[^0-9.]/g, '') : '';
}

// Returns a calculated transaction type considering voided and refunded properties.
export function calculateTransactionType(
  type: string,
  is_voided?: boolean,
  total_refunded?: number
): string {
  // The default is exactly what's in the "type" column
  let calculatedType = type;

  // Or it could also be one of these scenarios.
  if (total_refunded > 0) {
    calculatedType = 'refunded';
  } else if (is_voided) {
    calculatedType = 'voided';
  }
  if (type === 'ACHRETURN') {
    calculatedType = 'ACH Return';
  }
  return calculatedType;
}

export function formatPaymentMethodType(transaction: Transaction): string {
  const cardType = transaction?.payment_method?.card_type;
  const method = transaction?.method;
  const source = transaction?.source;

  if (['apple_pay', 'google_pay'].includes(source)) {
    switch (source) {
      case 'apple_pay':
        return 'Apple Pay';
      case 'google_pay':
        return 'Google Pay';
    }
  }

  // some bank methonds have a card_type value set
  // so we need to check if the method is actually card
  if (cardType && method === 'card') {
    switch (cardType) {
      case 'visa':
        return 'Visa';
      case 'mastercard':
        return 'Mastercard';
      case 'amex':
        return 'American Express';
      case 'jcb':
        return 'JCB';
      case 'apple_pay':
        return 'Apple Pay';
      case 'google_pay':
        return 'Google Pay';
      default:
        return formatCapitalCase(cardType);
    }
  } else if (method) {
    // There is no card payment type found, then 'method'
    // will have details on 'bank', 'cash', 'giftcard' and 'check' transactions.
    return formatCapitalCase(method);
  }

  return '';
}

export function formatPaymentMethodTypeDispute(dispute: Dispute): string {
  const cardType = dispute?.payment_method?.card_type;
  const method = dispute?.payment_method?.method;

  if (cardType && method === 'card') {
    switch (cardType) {
      case 'visa':
        return 'Visa';
      case 'mastercard':
        return 'Mastercard';
      case 'amex':
        return 'American Express';
      case 'jcb':
        return 'JCB';
      case 'apple_pay':
        return 'Apple Pay';
      case 'google_pay':
        return 'Google Pay';
      default:
        return formatCapitalCase(cardType);
    }
  } else if (method) {
    // There is no card payment type found, then 'method'
    // will have details on 'bank', 'cash', 'giftcard' and 'check' transactions.
    return formatCapitalCase(method);
  }
}

// sets the default value of data that may have been returned empty/null/undefined to a string of '0'
export const getIfNotEmpty = (value?: string) => {
  if (value === '' || value === null || value === undefined) {
    return '0';
  } else {
    return value;
  }
};

// Outputs dollar or cents based on cents amount.
export function formatCentsOrDollars(cents: string) {
  let res = `${cents}\xA2`;

  if (parseInt(cents) >= 100) {
    const toDollars = (parseInt(cents) / 100).toFixed(2);
    res = `$${toDollars}`;
  }

  return res;
}
// Outputs percentage amount.
export function formatPercentage(amount: string) {
  const val = (parseInt(amount) / 100).toFixed(2);
  let res = val === '0.00' ? `0%` : `${val}%`;
  return res;
}

/** Show comma only when both city and state are present. */
export function formatCityState(city: string | null, state: string | null) {
  let res = city || state;

  if (city === null && state === null) {
    res = null;
  } else if (city && state) {
    res = city + ', ' + state;
  } else if (city === null) {
    res = state;
  } else if (state === null) {
    res = city;
  }

  return res;
}

// set the transaction amounts to cents instead of dollars. Make sure it is a whole number
export function formatPerItemRate(rate: string) {
  const amount = currency(rate);
  return String(amount.intValue);
}

export function formatTruncatedId(id: string, maxLength: number = 8) {
  if (!id) return id;
  return id.slice(0, maxLength) + (id.length > maxLength ? '…' : '');
}

export function formatTruncatedName(name: string, maxLength: number = 19) {
  if (!name) return name;
  return name.slice(0, maxLength) + (name.length > maxLength ? '…' : '');
}

export function formatPercent(value: number | string) {
  if (typeof value === 'string') {
    value = value.replace(/[^\d.]/g, '');
  }
  if (Number.isNaN(Number(value)) || value == null) value = 0;
  return Number(+value / 100).toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 12,
  });
}

// Given a string like '$1,000.00' return '1000.00'
export const toDecimalString = (str: string) =>
  str ? str.replace(/[^\d.]/g, '') : '';

export function formatSocialSecurityNumber(value: string) {
  const number = String(value).replace(/[\D]/g, '');
  const chunks = [
    number.substring(0, 3),
    number.substring(3, 5),
    number.substring(5, 9),
  ];

  return chunks[0]
    ? chunks
        .slice(1)
        .reduce((sum, next) => (next ? sum + `-${next}` : sum), chunks[0])
    : number;
}

export function formatRoutingNumber(n: number | string) {
  return n && String(n)
    ? String(n)
        .replace(/[^0-9.]/g, '')
        .slice(0, 9)
    : '';
}

export function formatDate(value: string) {
  const number = String(value).replace(/[\D]/g, '');
  if (!value || !number) {
    return '';
  }
  if ([2, 4, 8].includes(number.length) || number.length > 8) {
    const chunks = [
      number.substring(0, 2),
      number.substring(2, 4),
      number.substring(4, 8),
    ];

    return chunks[0]
      ? chunks
          .slice(1)
          .reduce((sum, next) => (next ? sum + `/${next}` : sum), chunks[0])
      : number;
  }
  return value;
}

export const leaveAsNull = (fnc: (value: any) => any) => (input: any) => {
  if (!input) {
    return null;
  }
  return fnc(input);
};

export const ordinal = number => {
  const ordinalRules = new Intl.PluralRules('en', {
    type: 'ordinal',
  });
  const suffixes = {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th',
  };
  const suffix = suffixes[ordinalRules.select(number)];
  return number + suffix;
};

export const formatError = (error, defaultMessage) => {
  try {
    if (error) {
      if (error.message) return error.message;
      if (error.error && Array.isArray(error.error))
        return error.error.join('\n');
      if (typeof error === 'object') return Object.values(error).join('\n');
      return error;
    }
  } catch (ex) {}
  return defaultMessage;
};

export function round(value: any, places = 2) {
  return Math.round(value * Math.pow(10, places)) / Math.pow(10, places);
}
