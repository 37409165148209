import React, { FunctionComponent, Fragment, useContext } from 'react';
import {
  Button,
  Input,
  Group,
  Field,
  FieldError,
  FormHeader,
  FormDivider,
  Select,
  ResponsiveMargin,
} from '../../../shared';
import { SelectedMerchantStore } from '../../../../context';
import { FinancialFieldsProps } from './FinancialFields.types';
import { useWindowSize } from '../../../../hooks';
import { RepresentativeFields } from './components/RepresentativeFields';
import styled, { withTheme } from 'styled-components';
import { StaxButton } from '../../../shared/Stax/Button';
import { EnrollmentField } from '../enrollment-field';
import { useUnderwritingStatus } from '../../../../hooks/useUnderwritingStatus';
import { MaskedInput } from '../../../shared/MaskedInput';

const StyledSmall = withTheme(
  styled.small`
    color: ${({ theme }) => theme.white};
    display: block;
  `
);

export const FinancialFields: FunctionComponent<
  FinancialFieldsProps
> = props => {
  const { isMobile } = useWindowSize();
  const { state } = useContext(SelectedMerchantStore);
  const selectedMerchantBrandData = state.brand?.data;
  const { isFullyApproved } = useUnderwritingStatus();
  const shouldShowSecondaryBankAccountFields =
    selectedMerchantBrandData?.flags?.shouldShowSecondaryBankAccountFields;
  const shouldShowTrustAndOperating =
    selectedMerchantBrandData?.flags?.shouldShowTrustAndOperating;
  return (
    <>
      <FormHeader tag="h1">Principal Signer Information</FormHeader>
      <RepresentativeFields mode={props.mode} isMobile={isMobile} />

      {Array.isArray(props.values.representatives) &&
        props.values.representatives.map((_, i) => (
          <Fragment key={i}>
            <div>
              <FormDivider className="-mobile-hide" />
            </div>
            <Group justify="space-between">
              <FormHeader tag="h1">
                Additional Representative {i + 1}
              </FormHeader>
              {isFullyApproved ? null : (
                <Button
                  variant="outline"
                  motif="destructive"
                  id={`remove-additional-rep-${i}-button`}
                  data-testid={`remove-additional-rep-${i}-button`}
                  size="tiny"
                  icon="fas fa-times"
                  onClick={() =>
                    props.form.mutators.removeAdditionalRepresentative(i)
                  }
                >
                  <span>Remove</span>
                </Button>
              )}
            </Group>

            <RepresentativeFields
              mode={props.mode}
              index={i}
              isMobile={isMobile}
              setCalculatedSignerOwnershipPercentage={
                props.form.mutators.setCalculatedSignerOwnershipPercentage
              }
            />
          </Fragment>
        ))}

      <ResponsiveMargin desktop="0px" mobile="0px 8px">
        {isFullyApproved ? null : (
          <StaxButton
            size="small"
            id="add-additional-rep-button"
            data-testid="add-additional-rep-button"
            onClick={() => props.form.mutators.addAdditionalRepresentative()}
          >
            + Additional Representative
          </StaxButton>
        )}
        <StyledSmall>
          Effective May 11, 2018, the Federal FinCen (Financial Crimes
          Enforcement Network) requires all financial institutions to collect
          the Control Owner and Beneficial Owners on entities that are not
          otherwise exempt. Please provide information on any additional
          representatives if any representatives (Beneficial Owner) directly or
          indirectly own 25% or more of the entity.
        </StyledSmall>
      </ResponsiveMargin>

      <div>
        <FormDivider className="-mobile-hide" />
      </div>

      <Group direction={isMobile ? 'column-reverse' : 'row'}>
        <div style={{ flex: '50%' }}>
          <FormHeader tag="h1" data-testid="financial-account-primary-heading">
            Primary Account
          </FormHeader>

          <Group direction="column" space="1rem/0px">
            <Group direction="column">
              <EnrollmentField
                name="bank_account_owner_name"
                label="Name on Bank Account"
              >
                {props => <Input {...props.input} />}
              </EnrollmentField>
              <FieldError name="bank_account_owner_name" />
            </Group>

            <Group direction="column">
              <EnrollmentField
                name="bank_routing_number"
                label="Routing Number"
              >
                {props => <Input {...props.input} />}
              </EnrollmentField>
              <FieldError name="bank_routing_number" />
            </Group>

            <Group direction="column">
              <EnrollmentField
                name="bank_account_number"
                label="Account Number"
                showLastFourChars
              >
                {props => (
                  <MaskedInput {...props.input} maskType="accountNumber" />
                )}
              </EnrollmentField>
              <FieldError name="bank_account_number" />
            </Group>
          </Group>
        </div>
      </Group>

      {shouldShowSecondaryBankAccountFields ? (
        <Group direction={isMobile ? 'column-reverse' : 'row'}>
          <div style={{ flex: '50%' }}>
            <FormHeader
              tag="h1"
              data-testid="financial-account-secondary-heading"
            >
              Secondary Account
            </FormHeader>

            <Group direction="column" space="1rem/0px">
              <Group direction="column">
                <EnrollmentField
                  name="secondary_bank_account_owner_name"
                  label="Name on Bank Account"
                >
                  {props => <Input {...props.input} />}
                </EnrollmentField>
                <FieldError name="secondary_bank_account_owner_name" />
              </Group>

              <Group direction="column">
                <EnrollmentField
                  name="secondary_bank_routing_number"
                  label="Routing Number"
                >
                  {props => <Input {...props.input} type="number" />}
                </EnrollmentField>
                <FieldError name="secondary_bank_routing_number" />
              </Group>

              <Group direction="column">
                <EnrollmentField
                  name="secondary_bank_account_number"
                  label="Account Number"
                  showLastFourChars
                >
                  {props => (
                    <MaskedInput {...props.input} maskType="accountNumber" />
                  )}
                </EnrollmentField>
                <FieldError name="secondary_bank_account_number" />
              </Group>
              <Group direction="column">
                {shouldShowTrustAndOperating ? (
                  <Field
                    type="hidden"
                    name="secondary_bank_account_purpose"
                    defaultValue="trust"
                    value="trust"
                    initialValue="trust"
                  >
                    {props => (
                      <input
                        {...props.input}
                        data-testid="secondary_bank_account_purpose_hidden"
                      />
                    )}
                  </Field>
                ) : (
                  <EnrollmentField
                    name="secondary_bank_account_purpose"
                    label="Account is used for"
                  >
                    {props => (
                      <Select
                        {...props.input}
                        data-testid="secondary_bank_account_purpose"
                      >
                        <option value="" disabled />
                        <option value="discount">Discount</option>
                        <option value="fees">Fees</option>
                        <option value="credits">Credits</option>
                        <option value="chargebacks">Chargebacks</option>
                      </Select>
                    )}
                  </EnrollmentField>
                )}

                <FieldError name="secondary_bank_account_type" />
              </Group>
            </Group>
          </div>
        </Group>
      ) : null}

      {props.children}

      <div>
        <FormDivider />
      </div>
    </>
  );
};
