import React, { FunctionComponent, useState, useContext } from 'react';
import {
  BPSettingsRow,
  BPSettingsRowTitle,
  BPSettingsRowFields,
  BPSettingsRowDescription,
  FieldWrap,
  TwoCol,
  ThreeCol,
  SubCol,
} from '../components/BPSettingsRow';
import { DB } from '@fattmerchantorg/types-engine';
import {
  SmallPrimaryButton,
  SmallSecondaryButton,
  Select,
  TextField,
  RadioButton,
  Label,
  Icon,
} from '@fattmerchantorg/truffle-components';
import { CTAPageHeader } from '../components/CTAPageHeader';
import {
  DeleteFormDataModal,
  initialDeleteModalState,
  DeleteModalContext,
} from '../components/DeleteFormDataModal';
import { Form, Field } from 'react-final-form';
import * as AchRejectionFormUtil from './AchRejectionFeeForm.util';

import * as billingProfileUtil from '../util/billingProfile.util';
import { formatCentsOrDollars, ordinal } from '../../../util';
import { SelectedMerchantStore } from '../../../context';
import { fetchSelectedMerchantBillingProfiles } from '../../../context/selected-merchant/SelectedMerchants.actions';
import { useAsyncEffect, useAuthToken, useToaster } from '../../../hooks';
import { FormApi } from 'final-form';
import { Prompt, useHistory, useLocation } from 'react-router-dom';
import get from 'lodash/get';
import { Location } from 'history';
import {
  useModalReducer,
  sendOpenSelected,
} from '../../../hooks/useModalReducer';
import {
  AchRejectionFeeFieldsErrors,
  AchRejectionFeeFieldsValues,
} from './AchRejectionFeeForm.types';
import { catanapi } from '../../../api';
import { getErrorsFromCatanErrorResponse } from '../../../util/catan.util';
import { TransactionsLoadingState } from '../util/loadingState';

interface AchRejectionFeeProps {
  isFirstProfile?: boolean;
  editBillingId?: string;
}

export const InlineLabel = (props: {
  text: string;
  info?: string;
  required?: boolean;
}) => {
  const { text, info, required } = props;

  return (
    <Label
      text={text}
      info={info}
      required={required}
      customStyles={{
        wrapper: { display: 'flex', alignItems: 'center' },
        label: { margin: '0 4px 0 0' },
      }}
    />
  );
};

const validateForm = (
  formValues: AchRejectionFeeFieldsValues
): AchRejectionFeeFieldsErrors => {
  const errors = {
    ...AchRejectionFormUtil.validateFormValues(formValues),
  } as AchRejectionFeeFieldsErrors;
  return errors;
};

const initialFormValues: Partial<AchRejectionFeeFieldsValues> = {
  type: 'ACHREJECT',
  is_gross_settlement: 'true',
  fees_account_id: null,
  billing_cycle: 'DAILY',
};

export const AchRejectionFee: FunctionComponent<
  AchRejectionFeeProps
> = props => {
  const authToken = useAuthToken();
  const { state, dispatch: selectedMerchantDispatch } = useContext(
    SelectedMerchantStore
  );
  const { merchant } = state;
  const engineCompanyId = get(state?.registration, 'external_company_id', null);
  const { editBillingId } = props;
  const [existingBilling, setExistingBilling] = useState<DB.Billing | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [locationUrl, setLocationUrl] = useState(null);
  const [deleteModalState, deleteModalDispatch] =
    useModalReducer<DeleteModalContext>(initialDeleteModalState);

  const [feeAccountOptions, setFeeAccountOptions] =
    useState<billingProfileUtil.PaymentMethodSelectOption[]>();
  const [depositAccountOptions, setDepositAccountOptions] =
    useState<billingProfileUtil.PaymentMethodSelectOption[]>();

  const { toaster, toast } = useToaster();
  const billingCycleDayOptions =
    billingProfileUtil.billingCycleDaySelectOptions();
  const history = useHistory();
  const location = useLocation();
  const [fromCancel, setFromCancel] = useState(false);

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!locationUrl) {
      setLocationUrl(nextLocation);
      deleteModalDispatch(sendOpenSelected());
      return false;
    }
    return true;
  };

  const afterBlockedNavigation = () => {
    if (fromCancel) {
      history.goBack();
    } else {
      history.push(locationUrl.pathname);
    }
  };

  useAsyncEffect(async () => {
    if (
      !state ||
      !editBillingId ||
      editBillingId === '' ||
      !state.billingProfiles ||
      !state.billingProfiles.data
    ) {
      return;
    }
    setIsLoading(true);
    const selectedBP = state.billingProfiles.data.find(
      bp => bp.billing_id === editBillingId
    );
    if (selectedBP === undefined) {
      toaster(
        toast.error(
          'Unexpected error loading Billing Profile',
          'Error loading Billing Profile'
        )
      );
      setIsLoading(false);
    }
    setExistingBilling({ ...selectedBP });
    setIsLoading(false);
  }, [editBillingId, state]);

  useAsyncEffect(async () => {
    if (!authToken) return;
    setIsLoading(true);
    const paymentMethods = await billingProfileUtil.getEnginePaymentMethods(
      authToken,
      engineCompanyId,
      toast,
      toaster
    );
    setFeeAccountOptions(getAccountOptions(paymentMethods));
    setDepositAccountOptions(getAccountOptions(paymentMethods));
    setIsLoading(false);
  }, [authToken, engineCompanyId]);

  const getAccountOptions = (
    accountOptions
  ): billingProfileUtil.PaymentMethodSelectOption[] => {
    return accountOptions?.data?.reduce((accountOptns, accountOptn) => {
      accountOptns.push({
        value: accountOptn.method_id,
        label: `${accountOptn.person_name} (****${accountOptn.last_four})`,
      });

      return accountOptns;
    }, []);
  };

  const saveProfile = async formValues => {
    try {
      await catanapi.put(authToken, `/billing/${existingBilling.billing_id}`, {
        ...AchRejectionFormUtil.mapFormValuesToPayload(formValues),
      });
      setLocationUrl(location);
      history.push(`/merchant/${merchant?.id}/billing-profiles`);
      toaster(
        toast.success(
          'Your ACH Reject Billing configuration has been updated',
          'ACH Reject Billing Updated'
        )
      );

      selectedMerchantDispatch(
        fetchSelectedMerchantBillingProfiles(engineCompanyId)
      );
    } catch (error) {
      const errors = getErrorsFromCatanErrorResponse(error);
      if (errors.length) {
        const contentNodes = errors.map(errMsg =>
          React.createElement('div', null, errMsg)
        );
        toaster(
          toast.error(
            React.createElement('div', null, contentNodes),
            'Validation Error'
          )
        );
      } else {
        toaster(
          toast.error(error, 'There was a problem saving this billing profile')
        );
      }
      clearForm(formValues.form?.reset);
    }
  };

  const clearForm = (reset: FormApi['reset']) => {
    reset();
  };

  const getRowDescription = (
    section: 'fee' | 'deposit',
    formValues: Partial<AchRejectionFeeFieldsValues>
  ): JSX.Element => {
    const {
      per_transaction_amount,
      billing_cycle,
      billing_cycle_day,
      is_gross_settlement,
      fees_account_id,
      deposit_account_id,
      funding_schedule_days,
    } = formValues;

    const dynamicBillingCycle = () => {
      return billing_cycle === 'MONTHLY' ? (
        <>
          <strong>Monthly</strong> on the{' '}
          <strong>{ordinal(billing_cycle_day)}</strong>
        </>
      ) : (
        <strong>Daily</strong>
      );
    };

    const dynamicFundingDays = () => {
      const days = parseInt(funding_schedule_days as unknown as string, 10);
      if (days === 0) {
        return `the same business day`;
      } else if (days === 1) {
        return `the next business day`;
      } else {
        if (isNaN(days)) {
          return `2 business days`;
        }
        return `${days} business days`;
      }
    };

    if (section === 'fee') {
      return (
        <>
          <p>
            <strong>ACH Reject Fee</strong> is a{' '}
            <strong>
              {' '}
              {formatCentsOrDollars(`${per_transaction_amount * 100}`)}
            </strong>{' '}
            fee per unsuccessful ACH debit or credit transaction.
          </p>
          <p>
            The fees are collected {dynamicBillingCycle()} as a{' '}
            <strong>
              {is_gross_settlement === 'true' ? 'Gross' : 'Net'} Settlement
            </strong>
            {' from'}{' '}
            {fees_account_id ? (
              <>
                <strong>
                  {feeAccountOptions?.filter(fee => {
                    return fee.value === fees_account_id;
                  })[0]?.label ?? fees_account_id}
                </strong>
              </>
            ) : (
              'an unspecified account'
            )}
            .
          </p>
        </>
      );
    } else if (section === 'deposit') {
      if (!deposit_account_id) return null;

      return (
        <span>
          An ACH Reject amount will be pulled from{' '}
          <strong>
            {depositAccountOptions?.filter(dep => {
              return dep.value === deposit_account_id;
            })[0]?.label ??
              deposit_account_id ??
              'an unspecified account'}{' '}
            {dynamicFundingDays()}
          </strong>{' '}
          after an ACH Reject is initiated.
        </span>
      );
    }
  };

  const getInitialValues = () => {
    if (existingBilling) {
      return AchRejectionFormUtil.mapBillingProfileToInitialFormValues({
        billing_cycle_day: existingBilling.billing_cycle_day,
        billing_cycle: existingBilling.billing_cycle,
        fees_account_id: existingBilling.fees_account_id,
        is_gross_settlement: existingBilling.is_gross_settlement
          ? 'true'
          : 'false',
        per_transaction_amount: existingBilling.per_transaction_amount ?? 0,
        funding_schedule_days: existingBilling.funding_schedule_days,
        deposit_account_id: existingBilling.deposit_account_id,
      });
    }
    return AchRejectionFormUtil.mapBillingProfileToInitialFormValues({
      ...initialFormValues,
      per_transaction_amount: 0,
      billing_cycle_day: 1,
    });
  };

  const fillFeeAccount = () => {
    if (!existingBilling || !feeAccountOptions) {
      return null;
    }
    const feeAcc = feeAccountOptions.find(
      fa => fa.value === existingBilling.fees_account_id
    );
    if (feeAcc === undefined) {
      return null;
    }
    return { label: feeAcc.label, value: feeAcc.value };
  };

  const fillDepositAccount = () => {
    if (!existingBilling || !depositAccountOptions) {
      return null;
    }
    const depositAcc = depositAccountOptions.find(
      da => da.value === existingBilling.deposit_account_id
    );
    if (depositAcc === undefined) {
      return null;
    }
    return { label: depositAcc.label, value: depositAcc.value };
  };

  return (
    <div>
      {isLoading && <TransactionsLoadingState></TransactionsLoadingState>}
      {!isLoading && (
        <Form
          initialValues={{
            ...getInitialValues(),
          }}
          validate={validateForm}
          onSubmit={saveProfile}
        >
          {formProps => (
            <form autoComplete="off" onSubmit={formProps.handleSubmit}>
              <Prompt
                message={handleBlockedNavigation}
                when={formProps.dirty}
              />
              <DeleteFormDataModal
                status={deleteModalState.status}
                message={
                  <span>
                    The changes you've made to the ACH Reject fee have{' '}
                    <strong> not been saved.</strong> You will lose all these
                    changes if you leave this page.
                  </span>
                }
                modalDispatch={deleteModalDispatch}
                actionFunc={() => {
                  clearForm(formProps.form.reset);
                  afterBlockedNavigation();
                }}
                cancelText="Stay on this Page"
                actionText="Leave this Page"
              />
              <CTAPageHeader
                headingText={<span>ACH Reject Fee</span>}
                subheadingText="Edit the ACH Reject Fee by filling out the information below"
                ctaArea={
                  formProps.dirty
                    ? [
                        <SmallSecondaryButton
                          onClick={event => {
                            event.preventDefault();
                            setFromCancel(true);
                            if (!existingBilling) {
                              deleteModalDispatch(sendOpenSelected());
                            } else {
                              clearForm(formProps.form.reset);
                            }
                          }}
                          disabled={!formProps.dirty}
                          data-trackingid="bp-recurring-cancel-button"
                          key="bp-recurring-cancel-button"
                          type="button"
                        >
                          {existingBilling ? 'Reset Changes' : 'Cancel'}
                        </SmallSecondaryButton>,
                        <SmallPrimaryButton
                          disabled={!formProps.valid}
                          data-trackingid="bp-recurring-create-button"
                          key="bp-recurring-create-button"
                          type="submit"
                        >
                          {existingBilling ? 'Save Changes' : 'Save'}
                        </SmallPrimaryButton>,
                      ]
                    : []
                }
                breadcrumb={{
                  text: 'Billing Profiles',
                  path: `/merchant/${merchant?.id}/billing-profiles`,
                }}
              />
              <>
                <BPSettingsRow>
                  <BPSettingsRowTitle>
                    <h3>ACH Reject Fee</h3>
                    <span>
                      The ACH Reject Fee happens when an ACH debit or credit
                      attempt is unsuccessful.
                    </span>
                  </BPSettingsRowTitle>
                  <BPSettingsRowFields>
                    <ThreeCol>
                      <SubCol>
                        <Label text="ACH Reject Fee" />
                        <FieldWrap style={{ margin: '8px 0 0 0' }}>
                          <Field
                            name="per_transaction_amount"
                            format={v =>
                              v !== null && v !== undefined
                                ? v.toString().replace(/[^0-9.,]/g, '')
                                : ''
                            }
                            parse={v =>
                              v !== null && v !== undefined
                                ? v.toString().replace(/[^0-9.,]/g, '')
                                : ''
                            }
                          >
                            {props => {
                              return (
                                <TextField
                                  {...props.input}
                                  label="Amount"
                                  placeholder="0.00"
                                  inputPrefix={
                                    <Icon icon={['fas', 'dollar-sign']} />
                                  }
                                />
                              );
                            }}
                          </Field>
                        </FieldWrap>
                      </SubCol>
                      <SubCol>
                        <Label
                          text="Billing Cycle"
                          info="When the fee will be billed."
                        />
                        <FieldWrap style={{ margin: '8px 0 0 0' }}>
                          <Field
                            name="billing_cycle"
                            type="radio"
                            value="DAILY"
                          >
                            {props => {
                              return (
                                <RadioButton {...props.input} label="Daily" />
                              );
                            }}
                          </Field>
                          <Field
                            name="billing_cycle"
                            type="radio"
                            value="MONTHLY"
                          >
                            {props => {
                              return (
                                <RadioButton {...props.input} label="Monthly" />
                              );
                            }}
                          </Field>
                        </FieldWrap>
                        {formProps.values.billing_cycle === 'MONTHLY' ? (
                          <FieldWrap
                            style={{ margin: '16px 0 0 0', maxWidth: '100px' }}
                          >
                            <label>Day of Month</label>
                            <Field
                              name="billing_cycle_day"
                              format={value => {
                                return billingCycleDayOptions.find(
                                  option => option?.value === value
                                );
                              }}
                              parse={option => {
                                return option?.value;
                              }}
                              clearable={false}
                            >
                              {props => {
                                return (
                                  <div>
                                    <Select
                                      {...props.input}
                                      options={billingCycleDayOptions}
                                      isClearable={false}
                                      data-trackingid="bp-recurring-billing-cycle-day"
                                    />
                                  </div>
                                );
                              }}
                            </Field>
                          </FieldWrap>
                        ) : null}
                      </SubCol>
                      <SubCol>
                        <Label
                          text="Settlement"
                          info="Gross separates charge and fee transactions. Net combines charge and fee transactions."
                        />
                        <FieldWrap style={{ margin: '8px 0 0 0' }}>
                          <Field
                            name="is_gross_settlement"
                            type="radio"
                            value="true"
                          >
                            {props => {
                              return (
                                <RadioButton {...props.input} label="Gross" />
                              );
                            }}
                          </Field>
                          <Field
                            name="is_gross_settlement"
                            type="radio"
                            value="false"
                          >
                            {props => {
                              return (
                                <RadioButton {...props.input} label="Net" />
                              );
                            }}
                          </Field>
                        </FieldWrap>
                      </SubCol>
                    </ThreeCol>
                    <TwoCol>
                      <FieldWrap style={{ maxWidth: '200px' }}>
                        <Field
                          name="fees_account_id"
                          format={value => {
                            return billingCycleDayOptions.find(
                              option => option?.value === value
                            );
                          }}
                          parse={option => {
                            return option?.value;
                          }}
                          required
                        >
                          {props => {
                            return (
                              <>
                                {feeAccountOptions ? (
                                  <div data-testid="bp-recurring-fee-account">
                                    <Select
                                      {...props.input}
                                      data-trackingid="bp-recurring-fee-account"
                                      label="Default Fee Account"
                                      info="Default account that fees will be taken from."
                                      defaultValue={fillFeeAccount()}
                                      required
                                      options={feeAccountOptions}
                                      isDisabled={!feeAccountOptions}
                                      isClearable={false}
                                      error={
                                        !!formProps.errors?.fees_account_id
                                      }
                                      helperText={
                                        formProps.touched?.fees_account_id
                                          ? formProps.errors?.fees_account_id
                                          : null
                                      }
                                    />
                                  </div>
                                ) : null}
                              </>
                            );
                          }}
                        </Field>
                      </FieldWrap>
                    </TwoCol>
                  </BPSettingsRowFields>
                  <BPSettingsRowDescription>
                    {getRowDescription('fee', formProps.values)}
                  </BPSettingsRowDescription>
                </BPSettingsRow>
                <BPSettingsRow>
                  <BPSettingsRowTitle>
                    <h3>ACH Reject</h3>
                    <span>
                      Determines when and where the ACH Reject occurs.
                    </span>
                  </BPSettingsRowTitle>
                  <BPSettingsRowFields>
                    <SubCol>
                      <FieldWrap style={{ maxWidth: '200px' }}>
                        <Label
                          text="Default ACH Reject Account"
                          info="Default account that ACH Rejects will be pulled from."
                          required
                        />
                        <Field
                          name="deposit_account_id"
                          clearable={false}
                          format={value => {
                            return billingCycleDayOptions.find(
                              option => option?.value === value
                            );
                          }}
                          parse={option => {
                            return option?.value;
                          }}
                        >
                          {props => {
                            return (
                              <>
                                {depositAccountOptions ? (
                                  <div data-testid="bp-transaction-deposit-account">
                                    <Select
                                      {...props.input}
                                      options={depositAccountOptions}
                                      isDisabled={!depositAccountOptions}
                                      defaultValue={{
                                        ...fillDepositAccount(),
                                      }}
                                      data-trackingid="bp-transaction-deposit-account"
                                      isClearable={false}
                                      error={
                                        !!formProps.errors?.deposit_account_id
                                      }
                                      helperText={
                                        formProps.touched?.deposit_account_id
                                          ? formProps.errors?.deposit_account_id
                                          : null
                                      }
                                    />
                                  </div>
                                ) : null}
                              </>
                            );
                          }}
                        </Field>
                      </FieldWrap>
                    </SubCol>
                  </BPSettingsRowFields>
                  <BPSettingsRowDescription>
                    {getRowDescription('deposit', formProps.values)}
                  </BPSettingsRowDescription>
                </BPSettingsRow>
              </>
            </form>
          )}
        </Form>
      )}
    </div>
  );
};
