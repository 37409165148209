import { Reducer, useReducer, useEffect } from 'react';

type State = {
  status: 'idle' | 'working';
  prevStatus?: State['status'];
  event?: any;
};

type Action = { status: 'idle' } | { status: 'working'; event?: any };

const reducer: Reducer<State, Action> = (state, action) => {
  const { status, event } = state;
  const newState = { prevStatus: status, status: action.status, event };
  if (action.status === 'working') newState.event = action.event;
  return newState;
};

/**
 * Hook that abstracts some of the logic to prevent executing async logic more than once if a button is clicked many times
 * @param onClick
 */
export const useAsyncOnClick = <T = void>(onClick: (e?: any) => Promise<T>) => {
  const [state, dispatch] = useReducer(reducer, { status: 'idle' });

  useEffect(() => {
    // if work has started, but was not previously started
    if (state.status === 'working' && state.prevStatus !== 'working') {
      (async function () {
        await onClick();
        dispatch({ status: 'idle' });
      })();
    }
  }, [onClick, state.status, state.prevStatus]);

  return (e?: any) => dispatch({ status: 'working', event: e });
};
