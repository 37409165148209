import React, {
  FunctionComponent,
  useContext,
  useCallback,
  useState,
} from 'react';
import styled, { withTheme } from 'styled-components';
import { Tooltip } from '@fattmerchantorg/truffle-components';
import {
  RegistrationFileRecord,
  RegistrationDocument,
  GroupedRegistrationDocumentResults,
} from '@fattmerchantorg/types-omni';

import {
  Group,
  Files,
  FormDivider,
  ResponsivePadding,
  FormHeader,
} from '../../../shared';
import { coreapi, onboardingapi } from '../../../../api';
import { useToaster, useAuthToken, useAsyncEffect } from '../../../../hooks';
import { SelectedMerchantStore } from '../../../../context';
import { downloadData } from '../../../../util/documents.util';
import { formatTitleCase } from '../../../../util/format.util';
import { useUnderwritingStatus } from '../../../../hooks/useUnderwritingStatus';

const StyledDocumentTitle = withTheme(
  styled.label`
    color: ${({ theme }) => theme.white};
  `
);

export const Documents: FunctionComponent<
  React.HTMLProps<HTMLInputElement>
> = props => {
  const { accept } = props;
  const {
    state: { merchantId },
  } = useContext(SelectedMerchantStore);
  const authToken = useAuthToken();
  const { toaster, toast } = useToaster();
  const { isFullyApproved } = useUnderwritingStatus();

  const [registrationDocuments, setRegistrationDocuments] =
    useState<GroupedRegistrationDocumentResults>({});

  const registrationDocumentSubjects = Object.keys(registrationDocuments);

  useAsyncEffect(async () => {
    getRegistrationDocuments();
  }, [authToken]);

  const getRegistrationDocuments = useCallback(async () => {
    try {
      const registrationDocuments: GroupedRegistrationDocumentResults =
        await onboardingapi.get(
          authToken,
          `/merchant/${merchantId}/registration/documents`
        );

      setRegistrationDocuments(registrationDocuments);
    } catch (error) {
      console.log(error);
    }
  }, [authToken, merchantId]);

  const deleteFile = useCallback(
    async (file: RegistrationFileRecord) => {
      try {
        await coreapi.delete(
          authToken,
          `/merchant/${merchantId}/registration/file/${file.id}`
        );
        toaster(toast.success('File successfully deleted.', 'Deleted'));
      } catch (error) {
        toaster(toast.error(error, 'There was a problem deleting the file.'));
      } finally {
        getRegistrationDocuments();
      }
    },
    [authToken, merchantId, toaster, toast, getRegistrationDocuments]
  );

  const downloadFile = useCallback(
    async (file: RegistrationFileRecord) => {
      try {
        const res = await onboardingapi.get(authToken, `/file/${file.id}`);
        downloadData(res, file.name, file.meta.mime);
      } catch (error) {
        toaster(
          toast.error(error, 'There was a problem downloading the file.')
        );
      }
    },
    [authToken, toaster, toast]
  );

  const uploadFiles = useCallback(
    async (files: File[], registrationDocumentId: string | null = null) => {
      const uploads = files.map(async file => {
        try {
          const body: { [key: string]: any } = { file };

          if (registrationDocumentId) {
            body.registration_document_id = registrationDocumentId;
          }

          await coreapi.post(
            authToken,
            `/merchant/${merchantId}/registration/file?return=file`,
            body
          );
        } catch (error) {
          toaster(
            toast.error(error, 'There was a problem uploading the file.')
          );
        }
      });

      await Promise.all(uploads);

      getRegistrationDocuments();
    },
    [authToken, merchantId, toast, toaster, getRegistrationDocuments]
  );

  return (
    <ResponsivePadding desktop="0 200px 0 0" mobile="1rem">
      <Group direction="column" space="50px">
        {registrationDocumentSubjects.map((subject, i) => {
          const documents: RegistrationDocument[] =
            registrationDocuments[subject];
          const id = `${subject}-uploader-group`;

          if (!documents || !documents.length || !Array.isArray(documents))
            return null;

          return (
            <Group
              direction="column"
              space="4px"
              key={id}
              id={id}
              data-testid={id}
            >
              <FormHeader tag="h2">
                {formatTitleCase(subject.replace(/_/g, ' '))}
              </FormHeader>
              <Group direction="column" space="16px">
                {documents.map(doc => {
                  const { id, title, meta, files } = doc;
                  let tooltip: string = '';

                  if (meta && typeof meta === 'object') {
                    tooltip = meta.tooltip;
                  }

                  return (
                    <Group
                      direction="column"
                      space="4px"
                      key={id}
                      id={`${id}-uploader`}
                      data-testid={`${id}-uploader`}
                    >
                      <StyledDocumentTitle>
                        {title}{' '}
                        {tooltip ? (
                          <Tooltip content={tooltip}>
                            <i className="fas fa-info-circle" />
                          </Tooltip>
                        ) : null}
                      </StyledDocumentTitle>
                      <Files
                        files={files}
                        accept={accept}
                        onDrop={files => uploadFiles(files, id)}
                        onDownload={downloadFile}
                        onDelete={deleteFile}
                        disabled={isFullyApproved}
                      />
                    </Group>
                  );
                })}

                {i !== registrationDocumentSubjects.length - 1 && (
                  <FormDivider className="-mobile-hide" />
                )}
              </Group>
            </Group>
          );
        })}
      </Group>
    </ResponsivePadding>
  );
};
