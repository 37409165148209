import { FunctionComponent } from 'react';
import styled from 'styled-components';

type TextContainerProps = {
  align?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit';
  textAlign?: TextContainerProps['align'];
  fontWeight: 'normal' | 'bold' | 'bolder' | 'lighter' | number;
  paddingBottom?: string;
};

/**
 * To add styles to <Text>
 * Truffle has a bug where adding any props
 * besides "as" throws a TS error
 */
export const TextContainer = styled.span`
  * {
    ${({ align, textAlign }) =>
      (align || textAlign) && `text-align: ${align || textAlign};`}
    ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
    ${({ paddingBottom }) =>
      paddingBottom && `padding-bottom: ${paddingBottom};`}
  }
` as FunctionComponent<TextContainerProps>;
