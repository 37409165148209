import { StatusPillStatus } from '@fattmerchantorg/truffle-components/dist/StatusPill/StatusPill';
import { DB } from '@fattmerchantorg/types-engine';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

export function mapFundsType(type: DB.Payout['state']): {
  label: string;
  status: StatusPillStatus;
  icon?: FontAwesomeIconProps['icon'];
} {
  switch (type) {
    case 'SENT':
      return {
        label: 'Sent',
        status: 'success',
      };
    case 'PENDING':
    case 'PENDING.PROCESSOR':
      return {
        label: 'Pending',
        status: 'warning',
      };
    case 'REJECTED':
      return {
        status: 'error',
        label: 'ACH Rejected',
      };
    case 'PENDING.PAYOUT_ERROR':
      return {
        status: 'error',
        label: 'Pending Payout Error',
        icon: ['fas', 'exclamation-triangle'],
      };
    default:
      return null;
  }
}
