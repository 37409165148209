import { Dispatch, ReactNode } from 'react';

export interface TicketsContextProps {
  children: ReactNode;
}

export interface TicketsCountType {
  AWAITING_RESPONSE: number;
  CLOSED: number;
  NEW: number;
  PENDING: number;
}

export interface TicketsState {
  ticketsCount: TicketsCountType | null;
  effect: { type: TicketsEffectType; payload?: any } | null;
}

export enum TicketsActionType {
  SWITCH_BRAND = 'SWITCH_BRAND',
}

export enum TicketsEffectType {
  SWITCH_BRAND = 'SWITCH_BRAND',
}

export type TicketsAction = { type: TicketsActionType; payload?: any };

export interface TicketsContext {
  state: TicketsState;
  dispatch: Dispatch<TicketsAction>;
}
