import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  height: 100%;
  border: 1px solid ${props => props.theme.colors.core.gray[600].hex};
`;

export const BulkActionMenuContent: FunctionComponent = props => {
  return <Container {...props} />;
};
