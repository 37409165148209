import React from 'react';
import { InvoiceDetailsSectionData } from '../AdvancedDetailDrawer.types';
import { Accordion } from '@fattmerchantorg/truffle-components';
import styled from 'styled-components';
import { formatCurrency } from '../../../../util';
import { AccordionWrapper } from '../sharedComponents';

const InvoiceWrapper = styled.div`
  font-size: 14px;
`;

const LineItemsTable = styled.table`
  width: 100%;
`;

const NameHeader = styled.th`
  padding-bottom: 4px;
`;

const QuantityHeader = styled.th`
  padding-bottom: 4px;
`;

const AmountHeader = styled.th`
  padding-bottom: 4px;
  text-align: right;
`;

const BreakLine = styled.hr`
  margin: auto;
  margin-left: -20px;
  margin-bottom: 5px;
  width: 400%;
  border-bottom: 1px solid #435e70;
`;

const LineItemAmount = styled.td`
  text-align: right;
`;

const InvoiceChargesTable = styled.table`
  width: 100%;
`;
const SubtotalAmount = styled.td`
  text-align: right;
`;

const SurchargeAmount = styled.td`
  text-align: right;
`;

const TaxAmount = styled.td`
  text-align: right;
`;

const TipAmount = styled.td`
  text-align: right;
`;
const ShippingAmount = styled.td`
  text-align: right;
`;
const InvoiceTotalTable = styled.table`
  width: 100%;
`;
const TotalRow = styled.tr`
  font-weight: bold;
`;

const TotalAmount = styled.td`
  text-align: right;
`;

type InvoiceDetailsSectionProps = {
  id: string;
  name: string;
  data: InvoiceDetailsSectionData;
};

const InvoiceDetailsSection: React.FunctionComponent<
  InvoiceDetailsSectionProps
> = props => {
  const {
    data,
    name = 'Invoice Details',
    id = 'invoice-details-drawer-section',
  } = props;

  if (!data) return null;

  return (
    <AccordionWrapper id={id}>
      <Accordion title={name} asyncContent={true}>
        <InvoiceWrapper>
          <LineItemsTable>
            <thead>
              <NameHeader>Name</NameHeader>
              <QuantityHeader>Quantity</QuantityHeader>
              <AmountHeader>Amount</AmountHeader>
            </thead>
            {data?.lineItems?.length > 0 && (
              <>
                <BreakLine />
                <tbody>
                  {data?.lineItems?.map((lineItem, index) => (
                    <tr key={index}>
                      <td>{lineItem.item}</td>
                      <td>{lineItem.quantity}</td>
                      <LineItemAmount>
                        {' '}
                        {formatCurrency(parseFloat(lineItem.price))}{' '}
                      </LineItemAmount>
                    </tr>
                  ))}
                </tbody>
              </>
            )}
          </LineItemsTable>
          <BreakLine />
          <InvoiceChargesTable>
            <tbody>
              <tr>
                <td>Subtotal</td>
                <SubtotalAmount>{formatCurrency(data.subtotal)}</SubtotalAmount>
              </tr>
              {data.surcharge > 0 && (
                <tr>
                  <td>Surcharge</td>
                  <SurchargeAmount>
                    {formatCurrency(data.surcharge)}
                  </SurchargeAmount>
                </tr>
              )}
              {data.tax > 0 && (
                <tr>
                  <td>Tax</td>
                  <TaxAmount>{formatCurrency(data.tax)}</TaxAmount>
                </tr>
              )}
              {data.isTipEnabled && (
                <tr>
                  <td>Tip</td>
                  <TipAmount>{formatCurrency(data.tip)}</TipAmount>
                </tr>
              )}
              {data.shippingAmount > 0 && (
                <tr>
                  <td>Shipping</td>
                  <ShippingAmount>
                    {formatCurrency(data.shippingAmount)}
                  </ShippingAmount>
                </tr>
              )}
            </tbody>
          </InvoiceChargesTable>
          <BreakLine />
          <InvoiceTotalTable>
            <tbody>
              <TotalRow>
                <td>Total Paid</td>
                <TotalAmount>{formatCurrency(data.total)}</TotalAmount>
              </TotalRow>
            </tbody>
          </InvoiceTotalTable>
        </InvoiceWrapper>
      </Accordion>
    </AccordionWrapper>
  );
};

export default InvoiceDetailsSection;
