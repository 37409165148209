import React, { FunctionComponent } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { ContentSeparator, PageContent } from '../SettingsPagesStylings';

import { PricingPlansList } from './PricingPlansList';
import { PricingPlansNew } from './PricingPlansNew';

export const PricingPlansPage: FunctionComponent = () => {
  const match = useRouteMatch();

  return (
    <ContentSeparator>
      <PageContent>
        <Switch>
          <Route path={`${match.path}/new`}>
            <PricingPlansNew />
          </Route>
          <Route exact path={`${match.path}/`}>
            <PricingPlansList />
          </Route>
        </Switch>
      </PageContent>
    </ContentSeparator>
  );
};
