import styled from 'styled-components';
import { TableRow as TTableRow } from '@fattmerchantorg/truffle-components';
import { TableMenuCell } from './TableMenuCell';
import { TableCell } from './TableCell';

export const TableRow = styled(TTableRow)`
  cursor: pointer;

  ${TableMenuCell} > button {
    margin: 15px auto;
    visibility: hidden;
  }

  > ${TableCell}[data-visible='false'] {
    visibility: hidden;
  }

  > ${TableCell}[data-visible='hover'] {
    opacity: 0;
  }

  &:hover {
    ${TableMenuCell} {
      > button {
        visibility: visible;
      }
    }

    > ${TableCell}[data-visible='hover'] {
      opacity: 100;
    }
  }
`;
