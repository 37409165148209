import React, { Dispatch, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  AuthStore,
  ModalContext,
  updateSelectedMerchantRegistration,
  Action,
} from '../../../../../context';
import {
  BaseModal,
  ModalFooter,
  ModalHeader,
  PrimaryButton,
  SecondaryButton,
} from '@fattmerchantorg/truffle-components';
import { Gateway, Merchant, Registration } from '@fattmerchantorg/types-omni';
import { ButtonSpinner, Form } from '../../../../shared';
import { useToaster } from '../../../../../hooks';
import {
  ProcessorNames,
  ProcessorValue,
  SubmitApplicationFieldsValues,
} from './SubmitApplicationForm.types';
import { ProcessorSelection } from './ProcessorSelection';
import { CoreFinixConfigurations } from '../../../../../@types/UnderwritingStatus';
import {
  formMutators,
  handleApplicationSubmit,
  endLongLoadingMonitor,
  startLongLoadingMonitor,
} from './SubmitApplicationForm.util';
import { ProcessorConfig } from './ProcessorConfig';
import {
  getAllAuditLogs,
  sortReportsByEntityType,
} from '../../../../../util/auditLog.util';
import { PortfolioSelection } from './PortfolioSelection';
import { coreapi } from '../../../../../api';
import { AsyncData } from '../../../../../@types';
import { UnderwritingAction } from '../../../context';
import get from 'lodash/get';
import {
  Portfolio,
  getAllCompanyOnboardings,
  getCompanyOnboardings,
  getErrorsFromCatanErrorResponse,
  hasApprovedAppsOnboarding,
  isEnginePayfacId,
} from '../../../../../util/catan.util';
import { DB } from '@fattmerchantorg/types-engine';
import { LongLoadingState } from './LongLoadingState';
import { ConfirmCancelModal } from '../../../../modals/ConfirmCancelModal';

const ButtonBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  > * {
    margin: 0 0 0 ${({ theme }) => theme.space[4]}px;
  }
`;

const StyledModalContent = styled.div`
  grid-area: content;
  overflow-y: auto;
  padding: ${({ theme }) => theme.space[4]}px;
  color: ${({ theme }) => theme.component.modal.color};
`;

interface SubmitApplicationModalProps {
  isOpen?: boolean;
  registration: Registration;
  merchantId: string;
  merchantDispatch: Dispatch<Action>;
  underwritingDispatch: Dispatch<UnderwritingAction>;
  portfolios: Portfolio[];
  gateways?: AsyncData<Gateway[]>;
  merchant?: Merchant;
}

type FormPage = 'selectPortfolio' | 'selectProcessor' | 'configureSubmission';

/**
 * This is a 'wizard-like' form component with a few requirements that make it
 * somewhat complex:
 * - If the current auth has access to multiple portfolios show the portfolio
 *   select page first.
 * - If the current auth only has access to one portfolio, DO NOT show the
 *   portfolio select UI. Instead, assume the portfolio selection and show the
 *   processor selection UI as the first page.
 * - If the selected processor requires extra configuration before submission,
 *   show the submission configuration UI as the last step before submitting.
 * - If a next page is available, the primary action should be 'Continue'
 * - If no next page is available, the primary action should be 'Submit'
 * - If a previous page is available, the secondary action should be 'Back'
 * - If no previous page is available, the secondary action should be 'Cancel'
 */
export const SubmitApplicationFormModal: React.FunctionComponent<
  SubmitApplicationModalProps
> = props => {
  const {
    portfolios,
    merchantId,
    underwritingDispatch,
    merchantDispatch,
    gateways,
    merchant,
  } = props;
  let { registration } = props;
  //We need to remove the HTML content to protect us from overly large payloads
  delete registration?.electronic_signature?.html_content;
  const { modalDispatch } = useContext(ModalContext);
  const { state: authState } = useContext(AuthStore);
  const { toaster, toast } = useToaster();
  const { auth, authToken } = authState;
  const [isModalVisible] = useState(!!props.isOpen);
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [isContinueEnabled, setIsContinueEnabled] = useState(true);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  const [selectedPortfolio, setSelectedPortfolio] = useState<Portfolio | null>(
    null
  );
  const [selectedFinixCredentialId, setSelectedFinixCredentialId] = useState<
    string | null
  >(null);
  const [isLongLoading, setIsLongLoading] = useState(false);
  const [isCancelConfirmationVisible, setIsCancelConfirmationVisible] =
    useState(false);

  const engineCompanyId = get(registration, 'external_company_id', null);

  const [enabledPages, setEnabledPages] = useState<FormPage[] | []>([
    'selectPortfolio',
    'selectProcessor',
  ]);

  const [portfolioOnboardings, setPortfolioOnboardings] = useState([]);

  const [processors, setProcessors] = useState<ProcessorValue[]>();
  const [voidView, setVoidView] = useState<boolean>(false);

  const [novoidView, setNoVoidView] = useState<boolean>(false);
  const [novoidViewConfig, setNoVoidViewConfig] = useState<boolean>(false);
  const [getProccessor, setProccessor] = useState<boolean>(false);
  const [selectedProcessor, setSelectedProcessor] =
    useState<ProcessorValue>(null);
  const currentPage = enabledPages[currentPageIndex];

  // Prevent out-of-bounds page selection
  useEffect(() => {
    if (currentPageIndex < 0) {
      setCurrentPageIndex(0);
    }

    if (currentPageIndex + 1 > enabledPages.length) {
      setCurrentPageIndex(enabledPages.length - 1);
    }
  }, [currentPageIndex, enabledPages]);

  useEffect(() => {
    if (currentPage === 'selectProcessor' && selectedPortfolio === null) {
      //check to see the processorname
      const currentPortfolio = portfolios[0];
      setSelectedPortfolio(currentPortfolio);
    }
  }, [currentPage, portfolios, selectedPortfolio, setSelectedPortfolio]);

  useEffect(() => {
    if (selectedPortfolio) {
      setSelectedFinixCredentialId(
        selectedPortfolio.finix_credential_id ?? null
      );

      const processors = selectedPortfolio.processors.map(
        v => v.processor_name
      ) as ProcessorValue[];

      //add FIS ISO only if portfolio is Stax ISO - revert SPX-27
      if (selectedPortfolio.payfac_name.toUpperCase() === 'STAX ISO') {
        processors.push('FIS');
      }

      setProcessors(processors);
    }
  }, [selectedPortfolio]);

  // (Re)build the list of enabled pages based upon the available portfolios,
  // the available processors, and the currently selected processor.
  useEffect(() => {
    let pageList: FormPage[] = [];

    // If there is more than one portfolio available, show the portfolio select UI.
    if (portfolios?.length > 1) {
      pageList.push('selectPortfolio');
    }

    const areProcessorsAvailable = processors?.length > 0;
    const isOnlyPortfolio = portfolios?.length === 1;
    // We *should* always show the processor select UI.
    if (areProcessorsAvailable || isOnlyPortfolio) {
      pageList.push('selectProcessor');
    }

    // Show the submission configuration UI if the selected processor requires it.
    // TODO: GFN-190 Some logic to determine which processors need extra configuration
    const processorsWithConfig: ProcessorValue[] = [
      'CORE_FINIX',
      'LITLE_FINIX',
    ];

    if (processorsWithConfig.includes(selectedProcessor)) {
      pageList.push('configureSubmission');
    }

    setEnabledPages(pageList);
  }, [portfolios, processors, setEnabledPages, selectedProcessor]);

  // For the processors that don't require configuration, enable submit Button and display notification from
  // the processor selection UI
  useEffect(() => {
    const checkForApproved = (selectedProcessor: ProcessorValue): boolean => {
      if (selectedProcessor === 'APPS_ACH') {
        const ccApplication =
          portfolioOnboardings.filter(
            o =>
              (
                [
                  'SUBMITTED',
                  'SUBMITTED.FAILED',
                  'PENDED',
                  'PENDED.FAILED',
                ] as DB.Onboarding['state'][]
              ).includes(o.state) && o.processor_name === 'APPS'
          ).length > 0;
        if (ccApplication) {
          return true;
        }
      }
      if (
        portfolioOnboardings.find(
          o => o.processor_name === selectedProcessor && o.state === 'APPROVED'
        )
      ) {
        return true;
      }
      return false;
    };
    let checkProccessor = (name, value): boolean => {
      setProccessor(false);
      if (gateways?.data) {
        gateways?.data.map(g => {
          if (
            g?.name === name &&
            g?.keys?.credentials?.processorName === value &&
            g?.keys?.credentials?.payfacId === selectedPortfolio?.payfac_id
          ) {
            setProccessor(true);
            return getProccessor;
          }
        });
      }
      return getProccessor;
    };

    if (currentPage !== 'selectProcessor') return;

    switch (selectedProcessor) {
      case 'PAYPAL':
      case 'APPS':
      case 'APPS_ACH':
      case 'TSYS':
        if (checkProccessor('engine', selectedProcessor)) {
          getProccessor && setVoidView(!checkForApproved(selectedProcessor));
        } else {
          /**
           * By default the voidView is set to false, but if a portfolio has multiple processors,
           * and it was set to true for one processor, it will remain true also for the other processors of the portfolio , so we reset it to false if it doesn't match the validation.
           */
          setVoidView(false);
        }
        setNoVoidView(false);
        setIsSubmitEnabled(!checkForApproved(selectedProcessor));
        break;
      case 'FIS':
      case 'CORE':
        if (checkProccessor('engine', 'CORE')) {
          getProccessor && setVoidView(!checkForApproved(selectedProcessor));
        }
        setVoidView(false);
        setIsSubmitEnabled(!checkForApproved(selectedProcessor));
        break;
      case 'FAKE':
      case 'FORTE':
        setVoidView(false);
        setNoVoidView(false);
        setIsSubmitEnabled(!checkForApproved(selectedProcessor));

        break;
      default:
        setVoidView(false);
        setNoVoidView(false);
        setIsSubmitEnabled(false);
    }
  }, [
    selectedProcessor,
    currentPage,
    gateways,
    getProccessor,
    selectedPortfolio,
    authToken,
    merchantId,
    portfolioOnboardings,
  ]);

  // For the processors that don't require configuration, enable submit from
  // the processor selection UI
  useEffect(() => {
    let checkProccessor = (name): boolean => {
      setProccessor(false);
      if (gateways?.data) {
        gateways?.data.map(g => {
          if (
            g?.name.startsWith(name) &&
            g?.keys?.credentials?.finixMerchantId
          ) {
            setProccessor(true);
            return getProccessor;
          }
        });
      }
      return getProccessor;
    };

    if (currentPage === 'configureSubmission') {
      setNoVoidViewConfig(false);
      switch (selectedProcessor) {
        case 'CORE_FINIX':
        case 'LITLE_FINIX':
          checkProccessor('finix') &&
            getProccessor &&
            setNoVoidViewConfig(true);
          break;
        default:
          setNoVoidViewConfig(false);
          setIsSubmitEnabled(false);
      }
    }
  }, [selectedProcessor, currentPage, gateways, getProccessor]);

  const handleCloseModal = () => {
    if (isLongLoading) {
      setIsCancelConfirmationVisible(true);
    } else {
      modalDispatch({
        type: 'CLOSE_MODAL',
      });
    }
  };

  const handleBack = () => {
    setCurrentPageIndex(current => current - 1);
  };

  const handleContinue = () => {
    setCurrentPageIndex(current => current + 1);
  };

  const handleCancelSubmission = () => {
    setIsLongLoading(false);
    setIsCancelConfirmationVisible(false);
    modalDispatch({
      type: 'CLOSE_MODAL',
    });
  };

  const handleSubmit = async (values: SubmitApplicationFieldsValues) => {
    try {
      const longLoadingId = startLongLoadingMonitor({
        dispatcher: setIsLongLoading,
      });

      await handleApplicationSubmit(
        values,
        portfolios,
        registration,
        auth,
        { toaster, toast },
        setIsSaving,
        merchant,
        selectedFinixCredentialId,
        handleCloseModal
      );

      endLongLoadingMonitor(longLoadingId);
      setIsLongLoading(false);

      //get latest registration
      const latest_registration: Registration = await coreapi.get(
        authToken,
        `/merchant/${merchantId}/registration`
      );

      merchantDispatch(updateSelectedMerchantRegistration(latest_registration));

      if (Object.keys(ProcessorNames).includes(values.processor)) {
        const onboardings = await getAllCompanyOnboardings(
          auth.token,
          latest_registration.external_company_id
        );

        if (!!onboardings)
          underwritingDispatch({
            type: 'SET_ONBOARDING_LOGS',
            payload: {
              onboardingLogs: onboardings.data ? onboardings.data : null,
            },
          });
      }

      const auditLogs = await getAllAuditLogs(auth.token, merchantId);

      underwritingDispatch({
        type: 'SET_AUDIT_LOGS',
        payload: {
          auditLogs: sortReportsByEntityType(auditLogs),
        },
      });
    } catch (e) {
      handleCloseModal();
      const errors = getErrorsFromCatanErrorResponse(e);
      if (errors.length && e.name === 'CatanValidationError') {
        const contentNodes = errors.map(errMsg =>
          React.createElement('div', null, errMsg)
        );
        toaster(
          toast.error(
            React.createElement('div', null, contentNodes),
            'Validation Error'
          )
        );
      } else {
        toaster(
          toast.error(e, `There was a problem provisioning ${values.processor}`)
        );
      }
    }
  };
  const updatePortfolioOnboardings = async (portfolio: Portfolio) => {
    setPortfolioOnboardings([]);
    if (portfolio && engineCompanyId && isEnginePayfacId(portfolio.payfac_id)) {
      // disable continue and submit buttons until the request succeeds
      setIsContinueEnabled(false);
      setIsSubmitEnabled(false);
      try {
        const onboardings = await getCompanyOnboardings(
          authToken,
          engineCompanyId,
          {
            payfacId: portfolio.payfac_id,
          }
        );
        if (onboardings.data.length) {
          setPortfolioOnboardings(onboardings.data);
        }
        setIsContinueEnabled(true);
      } catch (error) {
        const errors = getErrorsFromCatanErrorResponse(error);
        if (errors.length) {
          const contentNodes = errors.map(errMsg =>
            React.createElement('div', null, errMsg)
          );
          toaster(
            toast.error(
              React.createElement('div', null, contentNodes),
              'Validation Error'
            )
          );
        } else {
          toaster(
            toast.error(
              error,
              `There was a problem fetching onboarding for ${portfolio.payfac_name}`
            )
          );
        }
      }
    }
  };

  return (
    <Form<SubmitApplicationFieldsValues>
      initialValues={{
        processor: null,
        processingInfo: null,
        payfacApplication: null,
        payfacConfiguration: CoreFinixConfigurations.CORE_CNP,
        portfolioId: selectedPortfolio?.payfac_id,
        forceNewApplication: 'update',
        achOnly: false,
      }}
      onSubmit={handleSubmit}
      mutators={formMutators}
    >
      {formProps => (
        <React.Fragment>
          <BaseModal
            isOpen={isModalVisible}
            onRequestClose={handleCloseModal}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={false}
            style={{
              overlay: { pointerEvents: 'auto' },
              content: { maxWidth: '533px' },
            }}
          >
            <ModalHeader
              title="Submit Application"
              onClose={handleCloseModal}
            />
            <React.Fragment>
              {isLongLoading ? (
                <LongLoadingState />
              ) : (
                <React.Fragment>
                  <StyledModalContent>
                    {currentPage === 'selectPortfolio' ? (
                      <PortfolioSelection
                        onChangePortfolio={e => {
                          const selectedPortfolio = portfolios.find(
                            v => v.payfac_id === e.target.value
                          );

                          setSelectedPortfolio(selectedPortfolio);
                          setSelectedProcessor(null);
                          updatePortfolioOnboardings(selectedPortfolio);
                        }}
                        portfolios={portfolios}
                      />
                    ) : null}
                    {currentPage === 'selectProcessor' ? (
                      <ProcessorSelection
                        onChangeProcessor={e => {
                          const $processor = e.target.value as ProcessorValue;
                          setSelectedProcessor($processor);
                          if ($processor === 'APPS_ACH') {
                            formProps.form.mutators.setAchOnly(
                              !hasApprovedAppsOnboarding(portfolioOnboardings)
                            );
                          } else {
                            formProps.form.mutators.setAchOnly(false);
                          }
                        }}
                        processors={processors}
                        portfolio={selectedPortfolio}
                        onboardings={portfolioOnboardings}
                        voidView={voidView}
                        novoidView={novoidView}
                      />
                    ) : null}

                    {currentPage === 'configureSubmission' ? (
                      <ProcessorConfig
                        finixCredentialId={selectedFinixCredentialId}
                        registration={registration}
                        setIsSubmitEnabled={setIsSubmitEnabled}
                        processor={formProps.values.processor}
                        novoidViewConfig={novoidViewConfig}
                      />
                    ) : null}
                  </StyledModalContent>
                  <ModalFooter>
                    <ButtonBar>
                      <SecondaryButton
                        data-testid="uw-status-submit-secondary-action"
                        onClick={
                          currentPageIndex === 0 ? handleCloseModal : handleBack
                        }
                      >
                        {currentPageIndex === 0 ? 'Cancel' : 'Back'}
                      </SecondaryButton>
                      {currentPageIndex + 1 === enabledPages.length ? (
                        <PrimaryButton
                          data-testid="uw-status-submit-primary-action"
                          className="ui-button -outline-primary"
                          type="submit"
                          disabled={!isSubmitEnabled}
                          onClick={() => handleSubmit(formProps.values)}
                        >
                          {isSaving ? <ButtonSpinner /> : `Submit`}
                        </PrimaryButton>
                      ) : (
                        <PrimaryButton
                          data-testid="uw-status-submit-primary-action"
                          type="button"
                          onClick={handleContinue}
                          disabled={
                            currentPageIndex === 0 && isContinueEnabled
                              ? !formProps.values.portfolioId
                              : !formProps.values.processor
                          }
                        >
                          Continue
                        </PrimaryButton>
                      )}
                    </ButtonBar>
                  </ModalFooter>
                </React.Fragment>
              )}
            </React.Fragment>
          </BaseModal>
          <ConfirmCancelModal
            isOpen={isCancelConfirmationVisible}
            message="Are you sure you want to cancel submission?"
            title={<span>Cancel Submission</span>}
            cancelButtonText="No, Go Back"
            onCancel={() => setIsCancelConfirmationVisible(false)}
            confirmButtonText="Yes, Cancel Submission"
            onConfirm={handleCancelSubmission}
          ></ConfirmCancelModal>
        </React.Fragment>
      )}
    </Form>
  );
};
