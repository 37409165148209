import React, { FC, Fragment } from 'react';
import styled, { withTheme } from 'styled-components';
import { Gateway, PlanName } from '@fattmerchantorg/types-omni';
import { Widget } from '../../shared/Widget';
import { AsyncData, AsyncDataStatus } from '../../../@types';
import {
  formatCapitalCase,
  formatReadableDate,
} from '../../../util/format.util';
import { LoadingSpan } from '../../shared';

const Container = withTheme(
  styled(Widget)`
    background: ${({ theme }) => theme.colors.core.green[800].hex};
    color: white;
    padding: 1rem;

    h2 {
      color: white;
    }

    i {
      padding-right: 0.25rem;
    }

    > div {
      display: flex;
      border-bottom: 1px solid ${({ theme }) => theme.black};

      > span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 50%;
      }
    }
  `
);

const IdDiv = styled.div`
  font-weight: bold;
  padding-top: 20px;
`;

/** Container component with heading */
const GatewayContainer: FC<{ headerText?: string }> = ({
  headerText = 'Gateway',
  children,
}) => {
  return (
    <Container>
      <h2>
        <i className="fas fa-cloud" /> {headerText}
      </h2>
      {children}
    </Container>
  );
};

const LoadingRow = withTheme(
  styled.div`
    display: flex;
    border-bottom: 1px solid ${({ theme }) => theme.black};

    /* loading spans */
    > div + div {
      margin-left: 3rem;
    }
  `
);

/** Displays the key-value data of a Gateway */
const GatewayKVPairs: FC<{ gateway: Gateway }> = ({ gateway }) => {
  const values: any = {
    ID: gateway.id,
    Name: formatCapitalCase(gateway.name),
    'Type (primary)': gateway.type,
    Vendor: gateway.vendor,
    Created: formatReadableDate(gateway.created_at),
  };

  if (gateway.keys && Object.keys(gateway.keys).length) {
    const keys = gateway.keys;

    if (keys.token) values.Token = keys.token;
    if (keys.refreshed_at) values['Last Refreshed'] = keys.refreshed_at;
  }

  return (
    <>
      {Object.keys(values).map((key, i) => (
        <Fragment key={i}>
          {key === 'ID' ? (
            <IdDiv>
              <span>{key}</span>
              <span>{values[key]}</span>
            </IdDiv>
          ) : (
            <div>
              <span>{key}</span>
              <span>{values[key]}</span>
            </div>
          )}
        </Fragment>
      ))}
    </>
  );
};

/**
 * Displays a merchant's Gateways.
 * Renders different UI according to the value of the AsyncData `status`.
 */
export const GatewayWidget: FC<{
  gateways: AsyncData<Gateway[]>;
  plan: PlanName;
}> = ({ gateways, plan }) => {
  // Render different UI according to current status of async data
  switch (gateways.status) {
    case AsyncDataStatus.INITIAL:
    case AsyncDataStatus.LOADING: {
      return (
        <GatewayContainer>
          <LoadingRow>
            <LoadingSpan width="7rem" />
            <LoadingSpan width="7rem" />
          </LoadingRow>
          <LoadingRow>
            <LoadingSpan width="7rem" />
            <LoadingSpan width="7rem" />
          </LoadingRow>
          <LoadingRow>
            <LoadingSpan width="7rem" />
            <LoadingSpan width="7rem" />
          </LoadingRow>
          <LoadingRow>
            <LoadingSpan width="7rem" />
            <LoadingSpan width="7rem" />
          </LoadingRow>
          <LoadingRow>
            <LoadingSpan width="7rem" />
            <LoadingSpan width="7rem" />
          </LoadingRow>
          <LoadingRow>
            <LoadingSpan width="7rem" />
            <LoadingSpan width="7rem" />
          </LoadingRow>
        </GatewayContainer>
      );
    }
    case AsyncDataStatus.IDLE: {
      const { data } = gateways;
      const headerText = data.length > 1 ? 'Gateways' : 'Gateway';
      return (
        <GatewayContainer headerText={headerText}>
          {data.length === 0 &&
            (plan === 'portal' ? (
              <p>
                This merchant's account has been set up as a portal account and
                therefore will not have a gateway.
              </p>
            ) : (
              <p>
                This account has not been configured to process transactions
                yet. Please reach out to your Partner Account Manager.
              </p>
            ))}
          {data.map((gateway, i) => (
            <GatewayKVPairs key={i} gateway={gateway} />
          ))}
        </GatewayContainer>
      );
    }
    case AsyncDataStatus.ERROR:
      return (
        <GatewayContainer>
          <p>
            An error occurred while retrieving gateway data. Please reach out to
            your Partner Account Manager.
          </p>
        </GatewayContainer>
      );
  }
};
