import React, { FunctionComponent } from 'react';
import {
  SettingsHeaderContent,
  SettingsHeaderBreadcrumbs,
  SettingsHeaderRow,
  SettingsHeaderContentTitle,
  SettingsHeaderContentTitleText,
  MainContent,
  SettingsSection,
  SettingsSectionHeader,
  VerticalDividingLine,
  StyledLabelText,
  SettingsSectionContent,
} from '../SettingsPagesStylings';
import { Link } from 'react-router-dom';
import { Icon } from '@fattmerchantorg/truffle-components';
import { PricingPlan } from '@fattmerchantorg/types-omni';
import styled from 'styled-components';
import { BoldText } from '../../truffle-dashboard/components/shared/BoldText';
import { formatCurrency } from '../../../util';

const pricingPlansRoute = '/settings/pricingplans';

const Columns = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  flex-wrap: wrap;
`;

export const PricingPlanView: FunctionComponent<{
  selectedPricingPlan: PricingPlan | undefined;
  isDuplicateForTrust: boolean;
}> = ({ selectedPricingPlan, isDuplicateForTrust }) => {
  return (
    <>
      <SettingsHeaderContent>
        <SettingsHeaderBreadcrumbs>
          <Link to={pricingPlansRoute}>
            <Icon icon={['fas', 'caret-left']} />
            Pricing Plans
          </Link>
        </SettingsHeaderBreadcrumbs>
        <SettingsHeaderRow>
          <SettingsHeaderContentTitle>
            <SettingsHeaderContentTitleText>
              <span style={{ fontWeight: 'normal' }}>
                {selectedPricingPlan?.name}
              </span>
            </SettingsHeaderContentTitleText>
          </SettingsHeaderContentTitle>
        </SettingsHeaderRow>
      </SettingsHeaderContent>
      <MainContent>
        <SettingsSection>
          <SettingsSectionHeader>Information</SettingsSectionHeader>
          <SettingsSectionContent>
            <div>
              <StyledLabelText>Display Name</StyledLabelText>
              <BoldText fontSize={14} lineHeight={20}>
                {selectedPricingPlan?.displayName}
              </BoldText>
            </div>
            <Columns>
              <div style={{ minWidth: '100px' }}>
                <StyledLabelText>Plan Name (Enum)</StyledLabelText>
                <BoldText fontSize={14} lineHeight={20}>
                  {selectedPricingPlan?.name}
                </BoldText>
              </div>
              <div>
                <StyledLabelText>Plan Type</StyledLabelText>
                <BoldText fontSize={14} lineHeight={20}>
                  {selectedPricingPlan?.type}
                </BoldText>
              </div>
              <div>
                <StyledLabelText>Product Type</StyledLabelText>
                <BoldText fontSize={14} lineHeight={20}>
                  {selectedPricingPlan?.productType.join(', ')}
                </BoldText>
              </div>
              <div>
                <StyledLabelText>Next Day Funding</StyledLabelText>
                <BoldText fontSize={14} lineHeight={20}>
                  {selectedPricingPlan?.isNdf ? 'ON' : 'OFF'}
                </BoldText>
              </div>
              <div>
                <StyledLabelText>isPayfac</StyledLabelText>
                <BoldText fontSize={14} lineHeight={20}>
                  {selectedPricingPlan?.isPayfac ? 'ON' : 'OFF'}
                </BoldText>
              </div>
              <div>
                <StyledLabelText>Duplicate for trust Account</StyledLabelText>
                <BoldText fontSize={14} lineHeight={20}>
                  {isDuplicateForTrust ? 'ON' : 'OFF'}
                </BoldText>
              </div>
            </Columns>
          </SettingsSectionContent>

          <SettingsSectionHeader>Credit Cards</SettingsSectionHeader>
          <SettingsSectionContent>
            <Columns>
              <div>
                <BoldText fontSize={16}>CNP</BoldText>
                <div>
                  <StyledLabelText>Discount Fee</StyledLabelText>
                  <BoldText fontSize={14} lineHeight={20}>
                    {selectedPricingPlan?.discountFee
                      ? `${selectedPricingPlan?.discountFee}%`
                      : '--'}
                  </BoldText>
                </div>
                <div style={{ marginTop: '12px' }}>
                  <StyledLabelText>Transaction Fee</StyledLabelText>
                  <BoldText fontSize={14} lineHeight={20}>
                    {selectedPricingPlan?.perTransactionFee
                      ? formatCurrency(selectedPricingPlan?.perTransactionFee)
                      : '--'}
                  </BoldText>
                </div>
              </div>
              <div>
                <BoldText fontSize={16}>CNP - AMEX</BoldText>
                <div>
                  <StyledLabelText>Discount Fee</StyledLabelText>
                  <BoldText fontSize={14} lineHeight={20}>
                    {selectedPricingPlan?.amexDiscountFee
                      ? `${selectedPricingPlan?.amexDiscountFee}%`
                      : '--'}
                  </BoldText>
                </div>
                <div style={{ marginTop: '12px' }}>
                  <StyledLabelText>Transaction Fee</StyledLabelText>
                  <BoldText fontSize={14} lineHeight={20}>
                    {selectedPricingPlan?.amexPerTransactionFee
                      ? formatCurrency(
                          selectedPricingPlan?.amexPerTransactionFee
                        )
                      : '--'}
                  </BoldText>
                </div>
              </div>
              <VerticalDividingLine />
              <div>
                <BoldText fontSize={16}>CP</BoldText>
                <div>
                  <StyledLabelText>Discount Fee</StyledLabelText>
                  <BoldText fontSize={14} lineHeight={20}>
                    {selectedPricingPlan?.cpDiscountFee
                      ? `${selectedPricingPlan?.cpDiscountFee}%`
                      : '--'}
                  </BoldText>
                </div>
                <div style={{ marginTop: '12px' }}>
                  <StyledLabelText>Transaction Fee</StyledLabelText>
                  <BoldText fontSize={14} lineHeight={20}>
                    {selectedPricingPlan?.cpPerTransactionFee
                      ? formatCurrency(selectedPricingPlan?.cpPerTransactionFee)
                      : '--'}
                  </BoldText>
                </div>
              </div>
              <div>
                <BoldText fontSize={16}>CP - AMEX</BoldText>
                <div>
                  <StyledLabelText>Discount Fee</StyledLabelText>
                  <BoldText fontSize={14} lineHeight={20}>
                    {selectedPricingPlan?.cpAmexPerDiscountFee
                      ? `${selectedPricingPlan?.cpAmexPerDiscountFee}%`
                      : '--'}
                  </BoldText>
                </div>
                <div style={{ marginTop: '12px' }}>
                  <StyledLabelText>Transaction Fee</StyledLabelText>
                  <BoldText fontSize={14} lineHeight={20}>
                    {selectedPricingPlan?.cpAmexPerTransactionFee
                      ? formatCurrency(
                          selectedPricingPlan?.cpAmexPerTransactionFee
                        )
                      : '--'}
                  </BoldText>
                </div>
              </div>
            </Columns>
          </SettingsSectionContent>
          <SettingsSectionHeader>Surcharge</SettingsSectionHeader>
          <SettingsSectionContent>
            <div>
              <StyledLabelText>Discount Fee</StyledLabelText>
              <BoldText fontSize={14} lineHeight={20}>
                {selectedPricingPlan?.cnpCcSurchargeRate
                  ? `${selectedPricingPlan?.cnpCcSurchargeRate}%`
                  : '--'}
              </BoldText>
            </div>
          </SettingsSectionContent>
          <SettingsSectionHeader>ACH</SettingsSectionHeader>
          <SettingsSectionContent>
            <Columns>
              <div>
                <StyledLabelText>Discount Fee</StyledLabelText>
                <BoldText fontSize={14} lineHeight={20} marginBottom={2}>
                  {selectedPricingPlan?.achDiscountFee
                    ? `${selectedPricingPlan?.achDiscountFee}%`
                    : '--'}
                </BoldText>
              </div>
              <VerticalDividingLine />
              <div>
                <StyledLabelText>Capped Fee</StyledLabelText>
                <BoldText fontSize={14} lineHeight={20} marginBottom={2}>
                  {selectedPricingPlan?.achCappedFee
                    ? formatCurrency(selectedPricingPlan?.achCappedFee)
                    : '--'}
                </BoldText>
              </div>
            </Columns>
            <div style={{ marginTop: '5px' }}>
              <StyledLabelText>Transaction Fee</StyledLabelText>
              <BoldText fontSize={14} lineHeight={20} marginBottom={2}>
                {selectedPricingPlan?.achPerTransactionFee
                  ? formatCurrency(selectedPricingPlan?.achPerTransactionFee)
                  : '--'}
              </BoldText>
            </div>
          </SettingsSectionContent>
        </SettingsSection>
      </MainContent>
    </>
  );
};
