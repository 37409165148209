export const riskHoldOptions = [
  {
    label: 'All',
    value: 'ALL',
  },
  {
    label: 'On',
    value: 'RISKHOLD',
  },
  {
    label: 'Off',
    value: 'OFF',
  },
];

//This is a static field that will only work in production
export const staticBrandOptions = [
  {
    label: 'STAX',
    value: 'BR11ec029bb03bd5330506bac0d5d0393d',
  },
];

export const settlementStatusFilterOptions = [
  {
    label: 'All',
    value: 'ALL',
  },
  {
    label: 'Open',
    value: 'OPEN',
  },
  {
    label: 'Pending',
    value: 'PENDING',
  },
  {
    label: 'Approved',
    value: 'APPROVED',
  },
  {
    label: 'Funded',
    value: 'FUNDED',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
  },
];
